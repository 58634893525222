/* eslint-disable import/no-cycle */
/* eslint-disable import/prefer-default-export */
import { createPromiseRequest } from './util/createPromiseRequest';
import { hasAccessToken } from '../../scanline/utils/token';

export const getCompanies = (params, callback) => {
	const url = '/companies';

	if (hasAccessToken()) {
		const fetchCompanies = createPromiseRequest({
			url,
			method: 'GET'
		});

		fetchCompanies()
			.then(res => {
				callback(null, res);
			})
			.catch(err => {
				callback(err);
			});
	}
};
