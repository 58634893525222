/* eslint-disable import/no-cycle */
import { createActions } from 'redux-actions';
import { LOAD_ERROR_MESSAGE } from './constants';
import { transform } from './transform';
import {
	selectIsLoadingSurveysNoReadings,
	selectIsAllSurveysNoReadingsLoaded
} from './selectors';
import BatchRequestManager from './NoReadingsBatchRequestManager';
import JL_FILTERS_SELECTORS from '../filters/selectors'; /// same import when trying to use selected customer values - use selector selectCustomerSelectedList

const selectCustomerFilters = state => {
  const { DITSurveysNoReadings } = state;
  const customerObjects = JL_FILTERS_SELECTORS.selectCustomerSelectedList(DITSurveysNoReadings);
  return customerObjects.map(c => c.name);
}

const {
	clearItems,
	requestItems,
	receiveItems,
	setTotalCount,
	setLoadItemsErrorMessage,
	clearLoadItemsErrorMessage
} = createActions(
	{
		CLEAR_ITEMS: () => ({}),
		REQUEST_ITEMS: () => ({}),
		RECEIVE_ITEMS: items => ({ items }),
		SET_TOTAL_COUNT: totalCount => ({ totalCount }),
		SET_LOAD_ITEMS_ERROR_MESSAGE: message => ({ message }),
		CLEAR_LOAD_ITEMS_ERROR_MESSAGE: () => ({})
	},
	{ prefix: 'DashboardCisvItems' }
);

const loadInitialItems = () => (dispatch, getState) => {
	dispatch(clearItems());
	dispatch(requestItems());

	BatchRequestManager.reset();

  const customers = selectCustomerFilters(getState())

  BatchRequestManager.nextBatch({customers})
		.then(res => {
			const { totalCount, items } = res;
			dispatch(clearLoadItemsErrorMessage());
			dispatch(setTotalCount(totalCount));
			dispatch(receiveItems(transform(items)));
		})
		.catch(err => {
			console.error(err);
			dispatch(setLoadItemsErrorMessage(LOAD_ERROR_MESSAGE));
		});
};

const loadNextItems = () => (dispatch, getState) => {
	const { scanItemssList: _state } = getState();
	const shouldIgnoreRequest =
		selectIsLoadingSurveysNoReadings(_state) ||
		selectIsAllSurveysNoReadingsLoaded(_state);

	if (!shouldIgnoreRequest) {
		dispatch(requestItems());

		BatchRequestManager.nextBatch()
			.then(res => {
				const { items } = res;
				dispatch(clearLoadItemsErrorMessage());
				dispatch(receiveItems(transform(items)));
			})
			.catch(err => {
				console.error(err);
				dispatch(setLoadItemsErrorMessage(LOAD_ERROR_MESSAGE));
			});
	}
};

export {
	loadInitialItems,
	loadNextItems,
	clearItems,
	requestItems,
	receiveItems,
	setTotalCount,
	setLoadItemsErrorMessage,
	clearLoadItemsErrorMessage
};
