import * as MapProcessingUtil from 'aegion_common_utilities/lib/MapProcessingUtil';
import { MiscUtils } from 'aegion_common_utilities';

const defaultState = {
	// Will be the indexes of gps readings to smooth by dat file name
	smoothedPointsLedgerByDatFileName: {
		/* Will look like this:
        ['tiny.dat']: [1, 5, etc]
        */
	},
	smoothedPoints: [
		/* Will look like this:
        oldCoordinates: [],
        newCoordinates: [],
        newPath: [],
        datFile: null,
        gpsReading: null,
        gpsReadingIndex: null,
        */
	],
	segments: [
		/* Will look like:
        ...gpsreadings (sorted by prevGap)
        */
	],
	cachedData: [], // A copy of "data"
	data: [], // This is an array of dat files
	hoveredPointCoords: null // Would just be coords ([lat, lng])
};

const _extendReadingsWithInfo = (datFile, gpsreadings) => {
	return gpsreadings.map((gpsreading, i) => {
		const originalIndex = i;
		return MiscUtils.shallowCopy(gpsreading, { originalIndex, datFile });
	});
};

const createOriginalCachedData = data => {
	return data.map(datFile => {
		return MiscUtils.shallowCopy(datFile, {
			gpsreadings: _extendReadingsWithInfo(datFile, datFile.gpsreadings)
		});
	});
};

const _extendDatFileWithReadingsInfo = datFiles => {
	const extendedReadings = datFiles.map(datFile =>
		_extendReadingsWithInfo(datFile, datFile.gpsreadings)
	);
	const flatExtendedReadings = extendedReadings.flat();
	return flatExtendedReadings;
};

const _hasNextGap = reading => {
	return reading.nextGap !== undefined;
};

const _getSegments = (datFiles, previousSegments = []) => {
	// Makes a shallow copy of gpsreadings
	const allGpsReadings = _extendDatFileWithReadingsInfo(datFiles);
	const segments = allGpsReadings.concat(previousSegments);
	segments.sort((a, b) => {
		// It looks like we are including the first item - so that we can smooth into it but we want it display this at the bottom of the list
		if (a.prevGap === undefined) {
			return 1;
		}
		if (b.prevGap === undefined) {
			return -1;
		}

		// Smoothed items always get priority
		if (a.isSmoothed && !b.isSmoothed) {
			return -1;
		}
		if (b.isSmoothed && !a.isSmoothed) {
			return 1;
		}

		// Then compare prevGaps
		return b.prevGap - a.prevGap;
	});

	return segments.filter(MapProcessingUtil.isGps);
};

const _getSegmentsFromSmoothedPoints = (data, smoothedPoints) => {
	const smoothedSegments = smoothedPoints.map(smoothedPoint => ({
		...smoothedPoint.gpsReading,
		isSmoothed: true,
		datFile: smoothedPoint.datFile,
		originalIndex: smoothedPoint.gpsReadingIndex
	}));

	return _getSegments(data, smoothedSegments);
};

const createDefaultState = data => {
	const cachedData = createOriginalCachedData(data);
	const segments = _getSegments(cachedData);
	return {
		...defaultState,
		data,
		segments,
		cachedData
	};
};

const getCoordsOfInternalReadings = (newCachedGpsReadings, readingIndex) => {
	const {
		i: previousGpsReadingIndex,
		gpsReading: previousGpsReading
	} = MapProcessingUtil.getPreviousGpsReading(
		newCachedGpsReadings,
		readingIndex
	);

	const { i: nextGpsReadingIndex } = MapProcessingUtil.getNextGpsReading(
		newCachedGpsReadings,
		readingIndex
	);

	const gapCount = nextGpsReadingIndex - previousGpsReadingIndex;

	// We ignore the first reading because that is already placed on the map
	const startIndex = previousGpsReadingIndex + 1;
	const endIndex = nextGpsReadingIndex; // Up to but not including

	const coords = [];
	for (let i = startIndex; i < endIndex; i += 1) {
		if (i !== readingIndex) {
			const coordinate = MapProcessingUtil.calculateInterpolatedPosition(
				previousGpsReading.coordinates[1],
				previousGpsReading.coordinates[0],
				previousGpsReading.nextDistance,
				previousGpsReading.nextAzimuth,
				i - previousGpsReadingIndex,
				gapCount
			);

			coords.push(coordinate);
		}
	}

	return coords;
};

const _calculateSmoothedPoint = (
	datFile,
	oldCachedGpsReadings,
	newCachedGpsReadings,
	originalGpsReadings,
	gpsReadingIndex
) => {
	const gpsReading = oldCachedGpsReadings[gpsReadingIndex];
	const originalGpsReading = originalGpsReadings[gpsReadingIndex];

	const newGpsReading = newCachedGpsReadings[gpsReadingIndex];

	const { gpsReading: nextGpsReading } = MapProcessingUtil.getNextGpsReading(
		newCachedGpsReadings,
		gpsReadingIndex
	);

	const {
		gpsReading: newPreviousGpsReading
	} = MapProcessingUtil.getPreviousGpsReading(
		newCachedGpsReadings,
		gpsReadingIndex
	);

	const oldCoordinates = originalGpsReading.coordinates;
	const newCoordinates = newGpsReading.coordinates;

	const newPath = [
		newPreviousGpsReading.coordinates,
		nextGpsReading.coordinates
	];

	const immediatelyPreviousCoords =
		newCachedGpsReadings[gpsReadingIndex - 1].coordinates;

	const immediatelyNextCoords =
		newCachedGpsReadings[gpsReadingIndex + 1].coordinates;

	const distanceFromFirstPointNew = MapProcessingUtil.calculateDistance(
		newCoordinates,
		immediatelyPreviousCoords
	);
	const distanceFromNextPointNew = MapProcessingUtil.calculateDistance(
		newCoordinates,
		immediatelyNextCoords
	);
	const distanceFromFirstPointOld = MapProcessingUtil.calculateDistance(
		oldCoordinates,
		immediatelyPreviousCoords
	);
	const distanceFromNextPointOld = MapProcessingUtil.calculateDistance(
		oldCoordinates,
		immediatelyNextCoords
	);

	const otherReadingCoordinates = getCoordsOfInternalReadings(
		newCachedGpsReadings,
		gpsReadingIndex
	);

	return {
		oldCoordinates,
		newCoordinates,
		newPath,
		datFile,
		gpsReading,
		gpsReadingIndex,
		distanceFromFirstPointNew,
		distanceFromNextPointNew,
		distanceFromFirstPointOld,
		distanceFromNextPointOld,
		otherReadingCoordinates
	};
};

const _getCachedGpsReadingsForDatFile = (cachedData, datFile) => {
	const { fileName } = datFile;

	for (let i = 0; i < cachedData.length; i += 1) {
		const cachedDatFile = cachedData[i];
		if (cachedDatFile.fileName === fileName) {
			return cachedDatFile.gpsreadings;
		}
	}
	return datFile.gpsreadings;
};

const recalculateSmoothedPoints = (
	smoothedPoints,
	newSmoothedPoint,
	newCachedGpsReadings
) => {
	const { datFile: datFileForSmoothedPoint } = newSmoothedPoint;
	const smoothPointsSortedByDatFile = [...smoothedPoints].sort((a, b) => {
		return a.datFile.fileName.localeCompare(b.datFile.fileName);
	});

	const newFileName = datFileForSmoothedPoint.fileName;

	const recalculatedSmoothPoints = smoothPointsSortedByDatFile.map(
		smoothedPoint => {
			const { gpsReadingIndex, datFile } = smoothedPoint;

			// The new smoothedPoint is for a different dat file, nothing to do here
			if (datFile.fileName !== newFileName) {
				return smoothedPoint;
			}

			const { i: nextGpsReadingIndex } = MapProcessingUtil.getNextGpsReading(
				newCachedGpsReadings,
				gpsReadingIndex
			);

			const newGpsReading = newCachedGpsReadings[gpsReadingIndex];

			const thisIsTheLastReading = gpsReadingIndex + 1 === nextGpsReadingIndex;

			// This is the last reading, nothing new to add, let's keep as-is
			if (thisIsTheLastReading) {
				return {
					...smoothedPoint,
					gpsReadingIndex
				};
			}

			const newCoords = newGpsReading.coordinates;

			const immediatelyPreviousCoords =
				newCachedGpsReadings[gpsReadingIndex - 1].coordinates;

			const distanceFromFirstPointNew = MapProcessingUtil.calculateDistance(
				newCoords,
				immediatelyPreviousCoords
			);

			const otherReadingCoordinates = getCoordsOfInternalReadings(
				newCachedGpsReadings,
				gpsReadingIndex
			);

			return {
				...smoothedPoint,
				gpsReadingIndex,
				newPath: null, // We do not need this as we can use the next smoothedPoint in this gpsReading
				newCoordinates: newCoords,
				distanceFromFirstPointNew,
				distanceFromFirstPointOld: null,
				otherReadingCoordinates
			};
		}
	);

	return recalculatedSmoothPoints.concat([newSmoothedPoint]);
};

const calculateNewSmoothedDataFromNewSmoothedPoint = (
	datFile,
	cachedData,
	smoothedPoints,
	smoothedPointsLedgerByDatFileName,
	originalIndex
) => {
	const cachedGpsReadings = _getCachedGpsReadingsForDatFile(
		cachedData,
		datFile
	);

	const { fileName } = datFile;
	const currentLedger = smoothedPointsLedgerByDatFileName[fileName] || [];

	// Dang it Javscript, just learn how to sort numbers please
	const newSmoothedPointsLedger = [...currentLedger, originalIndex].sort(
		(a, b) => a - b
	);

	const newSmoothedPointsLedgerByDatFileName = {
		...smoothedPointsLedgerByDatFileName,
		[fileName]: newSmoothedPointsLedger
	};

	const newCachedGpsReadings = MapProcessingUtil.getSmoothedGpsReadingsFromSegmentGap(
		cachedGpsReadings,
		[originalIndex]
	);

	const newSmoothedPoint = _calculateSmoothedPoint(
		datFile,
		cachedGpsReadings,
		newCachedGpsReadings,
		datFile.gpsreadings,
		originalIndex
	);

	const newCachedData = cachedData.map(_datFile => {
		if (_datFile.fileName !== fileName) {
			return _datFile;
		}

		return {
			..._datFile,
			gpsreadings: _extendReadingsWithInfo(_datFile, newCachedGpsReadings)
		};
	});

	// Will change the gps info for preceding smoothedPoints
	const newSmoothedPoints = recalculateSmoothedPoints(
		smoothedPoints,
		newSmoothedPoint,
		newCachedGpsReadings
	);

	const segments = _getSegmentsFromSmoothedPoints(
		newCachedData,
		newSmoothedPoints
	);

	return {
		newSmoothedPointsLedgerByDatFileName,
		newSmoothedPoints,
		newCachedData,
		segments
	};
};

const _getDatFileByFileName = (datFiles, fileName) => {
	for (let i = 0; i < datFiles.length; i += 1) {
		const datFile = datFiles[i];
		if (datFile.fileName === fileName) {
			return datFile;
		}
	}

	return null;
};

const addGapSmooth = (state, datFile, gpsReading) => {
	const {
		cachedData,
		smoothedPoints,
		smoothedPointsLedgerByDatFileName,
		data
	} = state;

	const originalDatFile = _getDatFileByFileName(data, datFile.fileName);

	const { originalIndex } = gpsReading;

	// Not sure how the first item was selected to be smoothed, but that is not possible
	if (originalIndex === 0) {
		return state;
	}

	const {
		newSmoothedPointsLedgerByDatFileName,
		newSmoothedPoints,
		newCachedData,
		segments
	} = calculateNewSmoothedDataFromNewSmoothedPoint(
		originalDatFile,
		cachedData,
		smoothedPoints,
		smoothedPointsLedgerByDatFileName,
		originalIndex
	);

	return {
		...state,
		smoothedPointsLedgerByDatFileName: newSmoothedPointsLedgerByDatFileName,
		smoothedPoints: newSmoothedPoints,
		cachedData: newCachedData,
		segments,
		hoveredPointCoords: null
	};
};

const setHoveredPoint = (state, hoveredPointCoords) => {
	return { ...state, hoveredPointCoords };
};

// We reset the segments anytime that the data was updated
const setUpdatedData = data => {
	return createDefaultState(data);
};

const undoItem = (state, gpsReading) => {
	const { data, smoothedPointsLedgerByDatFileName: oldLedger } = state;
	const originalDatFileName = gpsReading.datFile.fileName;
	const originalIndexToRemove = gpsReading.originalIndex;

	let {
		cachedData,
		smoothedPointsLedgerByDatFileName,
		smoothedPoints,
		segments
	} = createDefaultState(data);

	Object.keys(oldLedger).forEach(datFileName => {
		const datFile = _getDatFileByFileName(data, datFileName);
		const oldIndices = oldLedger[datFileName];
		oldIndices.forEach(gpsReadingIndex => {
			if (
				originalDatFileName === datFileName &&
				gpsReadingIndex === originalIndexToRemove
			) {
				return;
			}

			const {
				newSmoothedPointsLedgerByDatFileName,
				newSmoothedPoints,
				newCachedData,
				segments: newSegments
			} = calculateNewSmoothedDataFromNewSmoothedPoint(
				datFile,
				cachedData,
				smoothedPoints,
				smoothedPointsLedgerByDatFileName,
				gpsReadingIndex
			);

			cachedData = newCachedData;
			smoothedPointsLedgerByDatFileName = newSmoothedPointsLedgerByDatFileName;
			smoothedPoints = newSmoothedPoints;
			segments = newSegments;
		});
	});

	// Lots of things to do here:
	// First we should remove from ledger
	// Then we should iterate through the new ledger and iteratively recreate each smooth point and cachedData
	// const newSmoothedPoints = smoothedPoints.filter(
	// 	smoothedPoint => smoothedPoint.gpsReading !== gpsReading
	// );
	return {
		...state,
		cachedData,
		smoothedPointsLedgerByDatFileName,
		smoothedPoints,
		segments,
		hoveredPointCoords: null
	};
};

const segmentGapAnalysis = (state = defaultState, action) => {
	switch (action.type) {
		case 'CISV_SEGMENT_GAP_ADD_GAP_SMOOTH': {
			return addGapSmooth(state, action.datFile, action.gpsReading);
		}
		case 'CISV_SEGMENT_GAP_ADD_HOVERD_POINT': {
			return setHoveredPoint(state, action.hoveredPointCoords);
		}
		case 'CISV_SEGMENT_GAP_UDPATE_DATA':
			return setUpdatedData(action.data);
		case 'CISV_SEGMENT_GAP_RESET': {
			return createDefaultState(state.data);
		}
		case 'CISV_SEGMENT_UNDO_ITEM':
			return undoItem(state, action.gpsReading);
		default:
			return state;
	}
};

export default segmentGapAnalysis;
