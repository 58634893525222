import React, { useMemo } from 'react';
import { connect } from 'react-redux';
import { selectApplicationsListKey } from '../../../../../scanline/redux/WebsiteExperimental/selectors';
import { getApplicationsListComponent } from '../../variants.ApplicationsList';

const mapStateToProps = state => {
	const { websiteExperimental } = state;
	return {
		compKey: selectApplicationsListKey(websiteExperimental)
	};
};

const ApplicationsList = ({ compKey }) => {
	const Component = useMemo(() => getApplicationsListComponent(compKey), [
		compKey
	]);

	return <Component />;
};

export default connect(mapStateToProps)(ApplicationsList);
