/* eslint import/no-cycle:0 */

import SurveyUtil from '../../../utils/SurveyUtil';

const NS_MAPS = 'cisview_job_';

export const INPROGRESS = 'In Progress';
export const COMPLETE = 'Complete';
export const NEW = 'New';

export const SET_GLOBAL_DATA = `${NS_MAPS}SET_DATA`;
export const setGlobalData = (globalData, data) => {
	return {
		type: SET_GLOBAL_DATA,
		payload: SurveyUtil.extendAllDataWithSurveyType(globalData, data)
	};
};

export const SET_GPS_READINGS_STILL_LOADING = `${NS_MAPS}SET_GPS_READINGS_STILL_LOADING`;
export const setGpsReadingsStillLoading = (stillLoading = false) => {
	return {
		type: SET_GPS_READINGS_STILL_LOADING,
		payload: { stillLoading }
	};
};

export const SET_SAVING_COMPLETE = `${NS_MAPS}SET_SAVING_COMPLETE`;
export const setSavingComplete = (data, globalData) => {
	return {
		type: SET_SAVING_COMPLETE,
		payload: {
			data,
			globalData
		}
	};
};

export const REMOVE_DAT = `${NS_MAPS}REMOVE_DAT`;
export const setRemoveDat = (globalData, data) => {
	return {
		type: REMOVE_DAT,
		payload: { globalData, data }
	};
};

export const SET_DAT_VISIBILITY = `${NS_MAPS}SET_DAT_VISIBILITY`;
export const setDatVisibility = (data, globalData) => ({
	type: SET_DAT_VISIBILITY,
	payload: {
		data,
		globalData
	}
});

export const SET_DAT_TRIMS = `${NS_MAPS}SET_DAT_TRIMS`;
export const setDatTrims = (data, globalData) => ({
	type: SET_DAT_TRIMS,
	payload: {
		data,
		globalData
	}
});

export const SET_READING_COMMENT = `${NS_MAPS}SET_READING_COMMENT`;
export const setReadingComment = (data, globalData) => ({
	type: SET_READING_COMMENT,
	payload: {
		data,
		globalData
	}
});

export const SET_REPLACE_READING = `${NS_MAPS}SET_REPLACE_READING`;
export const setReplaceReading = (data, globalData) => ({
	type: SET_REPLACE_READING,
	payload: {
		data,
		globalData
	}
});

export const SET_DELETE_READING = `${NS_MAPS}SET_DELETE_READING`;
export const setDeleteReading = (data, globalData) => ({
	type: SET_DELETE_READING,
	payload: {
		data,
		globalData
	}
});

export const SET_DELETE_SKIP = `${NS_MAPS}SET_DELETE_SKIP`;
export const setDeleteSkip = (data, globalData) => ({
	type: SET_DELETE_SKIP,
	payload: {
		data,
		globalData
	}
});

export const SET_ADD_GPS_POINT = `${NS_MAPS}SET_ADD_GPS_POINT`;
export const setAddGpsPoint = (data, globalData) => ({
	type: SET_ADD_GPS_POINT,
	payload: {
		data,
		globalData
	}
});

export const SET_ADD_SKIP = `${NS_MAPS}SET_ADD_SKIP`;
export const setAddSkip = (data, globalData) => ({
	type: SET_ADD_SKIP,
	payload: {
		data,
		globalData
	}
});

export const ADD_DAT_FILE_EDITED_COUNT = `${NS_MAPS}ADD_DAT_FILE_EDITED_COUNT`;
export const setAddDatFileCount = () => ({
	type: ADD_DAT_FILE_EDITED_COUNT
});

export const REMOVE_DAT_FILE_EDITED_COUNT = `${NS_MAPS}REMOVE_DAT_FILE_EDITED_COUNT`;
export const setRemoveDatFileCount = (datFilesBeingSavedCount, isSaving) => ({
	type: REMOVE_DAT_FILE_EDITED_COUNT,
	payload: {
		datFilesBeingSavedCount,
		isSaving
	}
});

export const SET_MAP_EDIT_IN_PROCESS = `${NS_MAPS}SET_MAP_EDIT_IN_PROCESS`;
export const setMapEditInProcess = ({
	isMapEditInProcess,
	isMoveToolActivated,
	isSmoothToolAcitvated
}) => ({
	type: SET_MAP_EDIT_IN_PROCESS,
	payload: {
		isMapEditInProcess,
		isMoveToolActivated,
		isSmoothToolAcitvated
	}
});

// This is so that we don't call `onReadingsChanged` multiple times
export const SET_READINGS_CHANGED_CACHED_DATA = `${NS_MAPS}SET_READINGS_CHANGED_CACHED_DATA`;
export const setCachedReadingsData = readingsCachedData => ({
	type: SET_READINGS_CHANGED_CACHED_DATA,
	payload: {
		readingsCachedData
	}
});

export const SET_IS_UNDOING = `${NS_MAPS}SET_IS_UNDOING`;
export const setIsUndoing = isUndoing => {
	return {
		type: SET_IS_UNDOING,
		payload: isUndoing
	};
};
