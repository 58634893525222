/* eslint-disable import/no-cycle */
/* eslint-disable camelcase */
import {
	isDefined,
	injectItemIntoArray,
	removeItemFromArray,
	updateArrayItemOrder
} from './util';
import { addChart, removeChart, updateChartOrder } from './actions';

// ************
// PARTS
// ************
const reducerParts = {
	[addChart]: (state, { payload: { chartId, index } }) => {
		const { chartIds = [] } = state;
		const idx = isDefined(index) || chartIds.length;

		return {
			...state,
			chartIds: injectItemIntoArray(chartIds, chartId, idx)
		};
	},
	[removeChart]: (state, { payload: { chartId } }) => {
		const { chartIds = [] } = state;
		return {
			...state,
			chartIds: removeItemFromArray(chartIds, chartId)
		};
	},
	[updateChartOrder]: (state, { payload: { chartId, index } }) => {
		const { chartIds = [] } = state;
		const idx = isDefined(index) || chartIds.length;
		return {
			...state,
			chartIds: updateArrayItemOrder(chartIds, chartId, idx)
		};
	}
};

export default reducerParts;
