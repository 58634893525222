// eslint-disable-next-line import/no-cycle
import { createFiltersRedux } from '../../../../../commons/redux/dynamic/Filters';
import { ROOT_SLICE_DASH_CISV } from '../constants';

const filtersRedux = createFiltersRedux({
	namespace: 'dar-cisv-dash-jobline-filters',
	rootSliceKey: ROOT_SLICE_DASH_CISV,
	filtersStatePropKey: 'jobLinesFilters'
});

export default filtersRedux;
