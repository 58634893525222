/* eslint-disable import/no-cycle */
/* eslint-disable import/prefer-default-export */
import { createPromiseRequest } from './util/createPromiseRequest';
import { hasAccessToken } from '../../scanline/utils/token';

export const getPermissionDefinitions = (params, callback) => {
	const url = '/permission-definitions';

	if (hasAccessToken()) {
		const fetchPermissionDefinitions = createPromiseRequest({
			url,
			method: 'GET'
		});

		fetchPermissionDefinitions()
			.then(res => {
				callback(null, res);
			})
			.catch(err => {
				callback(err);
			});
	}
};
