import { EMPTY_OBJECT } from './constants';

import {
	selectChartPrimarySurvey,
	selectThreshold,
	selectUseShadow,
	selectSeriesVisibility
} from '../ChartsSettings/selectors';

export const selectPrimarySurveyBcReadingKey = (
	stateApp = EMPTY_OBJECT,
	chartId
) => {
	const primarySurvey = selectChartPrimarySurvey(stateApp, { chartId });
	if (!primarySurvey) {
		debugger;
	}
	const { meta } = primarySurvey;
	const readingKeysByBcPriority = meta.selectReadingKeysByBcPriority();
	return readingKeysByBcPriority.find(readingKey => {
		return selectSeriesVisibility(
			stateApp,
			{ chartId },
			{
				dataSetKey: primarySurvey.id,
				propKey: readingKey.value
			}
		);
	});
};

// @todo - determine if this data structure is truly needed
// @todo - determine if it might be best to replace with property specific selectors
export const selectLegacyCriterionInfo = (stateApp = EMPTY_OBJECT, chartId) => {
	const identifiers = { chartId };
	const threshold = selectThreshold(stateApp, identifiers);
	const useShadow = selectUseShadow(stateApp, identifiers);
	const bcReadingKey = selectPrimarySurveyBcReadingKey(stateApp, chartId);

	if (!bcReadingKey) {
		return undefined;
	}

	return {
		type: useShadow ? 'shadowline' : 'threshold', // should be either 'threshold' or 'shadowline'
		series: bcReadingKey.label, // ex: 'ON', 'OFF'
		key: bcReadingKey.value, // readinve value prop - ex: 'on', 'off'
		threshold // current threshold value located in chart threshold input - ex: -850
	};
};
// ToDo: Add granular selectors

export const selectChartsBelowCriterionDataSets = (stateApp = EMPTY_OBJECT) => {
	const { chartsBelowCriterions = EMPTY_OBJECT } = stateApp;
	return chartsBelowCriterions;
};

export const selectChartBelowCriterionDataSets = (
	stateApp = EMPTY_OBJECT,
	chartId
) => {
	const chartsBelowCriterions = selectChartsBelowCriterionDataSets(stateApp);
	const chartBelowCriterionDataSets =
		chartsBelowCriterions[chartId] || EMPTY_OBJECT;

	return chartBelowCriterionDataSets;
};

export const selectBCReadings = (stateApp = EMPTY_OBJECT, { chartId }) => {
	const chartBelowCriterionDataSets = selectChartBelowCriterionDataSets(
		stateApp,
		chartId
	);
	return chartBelowCriterionDataSets.bcReadings;
};

export const selectBCReadingsWithGaps = (
	stateApp = EMPTY_OBJECT,
	{ chartId }
) => {
	const chartBelowCriterionDataSets = selectChartBelowCriterionDataSets(
		stateApp,
		chartId
	);
	return chartBelowCriterionDataSets.bcReadingsWithGaps;
};

export default {
	selectChartBelowCriterionDataSets
};
