import { listSurveysNoReadings } from '../../api/SurveysNoReadings';
import { BATCH_REQUEST_SIZE } from './constants';

class NoReadingsBatchRequestManager {
	constructor() {
		this.hasPendingBatchRequest = false;
		this.previousBatchRequest = -1;
	}

  nextBatch(filters = {}) {
    const { customers } = filters;
		return new Promise((resolve, reject) => {
			if (!this.hasPendingBatchRequest) {
				const batchRequest = this.previousBatchRequest + 1;
				const limit = BATCH_REQUEST_SIZE;
				const offset = batchRequest * BATCH_REQUEST_SIZE;
        this.hasPendingBatchRequest = true;
        
        const payload = { customers }; // todo - modify api from GET to POST - pass payload to backed // else // todo - you could also ignore all this stuff and just filter directly in the browser and not care about modifying backend

				listSurveysNoReadings({ limit, offset }, (err, res) => {
					this.hasPendingBatchRequest = false;
					if (err) {
						reject(err);
					} else {
						this.previousBatchRequest += 1;
						resolve(res);
					}
				});
			}
		});
	}

	reset() {
		this.currentPendingRequestBatch = -1;
		this.hasPendingBatchRequest = false;
	}
}

export default new NoReadingsBatchRequestManager();
