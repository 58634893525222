const defaultState = {
	overlapDataByDatFile: {
		/**
		 * Will look like:
		 * 'tinydatfile': {startComment: {...}}
		 */
	}
};

const setNewOverlapData = (state, overlapDataByDatFile) => {
	return {
		...state,
		overlapDataByDatFile
	};
};

const reducer = (state = defaultState, action) => {
	const { type } = action;
	switch (type) {
		case 'CISV_OVERLAP_NEW_DATA':
			return setNewOverlapData(state, action.overlapDataByDatFile);
		default:
			return state;
	}
};

export default reducer;
