import React, { useMemo, useEffect } from 'react';
import { withRouter } from 'react-router';

import { getTabPrefixSuffix } from '../../config/getAipUrls';

const [PREFIX, SUFFIX] = getTabPrefixSuffix();

const AppLists = new Map([
	['cisline', 'CISView™'],
	['applications', 'Corrpro'],
	['fieldlinereact', 'FieldLine®'],
	['scanline', 'ScanLine®'],
	['chargeline', 'ChargeLine™'],
	['liveline', 'LiveLine™'],
	['assetview', 'AssetView®']
]);

function ComputeDocumentTitle(ApplicationName = '') {
	const computedAppName = AppLists.get(ApplicationName.toLowerCase());

	const parts = computedAppName
		? [PREFIX, computedAppName, SUFFIX]
		: [PREFIX, 'Corrpro', SUFFIX];

	return parts.filter(v => v).join(' - ');
}

const AppTitle = ({ location }) => {
	const AppName = useMemo(() => {
		return (location.pathname.split('/') || []).filter(v => v)[0];
	}, [location]);

	useEffect(() => {
		document.title = ComputeDocumentTitle(AppName);
	}, [AppName]);
	return <></>;
};

export default withRouter(AppTitle);
