/* eslint-disable no-use-before-define */
import { createSelector } from 'reselect';
import { ACTION_PLAN_STATE_SLICE_KEY } from './constants';

export const APP_STATE_ACTION_PLAN_VERSION_RECOMPUTE = 1.1;

const EMPTY_OBJECT = {};
const EMPTY_ARRAY = [];

const helperSelectActionPlanComparator = (key, actionPlanVersion) => {
	const validComparators = {
		GREATER_THAN: 'GREATER_THAN',
		GREATER_THAN_EQUAL_TO: 'GREATER_THAN_EQUAL_TO'
	};

	const result = validComparators[key];

	return (
		result ||
		(actionPlanVersion < APP_STATE_ACTION_PLAN_VERSION_RECOMPUTE
			? 'GREATER_THAN_EQUAL_TO'
			: 'GREATER_THAN')
	);
};

export const selectStateSlice = state => {
	const { app } = state;
	return app;
};

export const selectActionPlanStateSlice = stateApp => {
	const actionPlanState = stateApp[ACTION_PLAN_STATE_SLICE_KEY] || EMPTY_OBJECT;
	return actionPlanState;
};

// ACTION PLAN STATE META
export const selectActionPlanStateMeta = (stateApp = {}) => {
	const actionPlanState = selectActionPlanStateSlice(stateApp);
	const { meta = EMPTY_OBJECT } = actionPlanState;

	return meta;
};

// ACTION PLAN STATE ACTION PLAN VERSION
export const selectActionPlanStateVersion = (stateApp = {}) => {
	const meta = selectActionPlanStateMeta(stateApp);
	const { actionPlanVersion = 0 } = meta;

	return actionPlanVersion;
};

// ACTION PLAN STATE ACTION PLAN VERSION
export const selectActionPlanStateComparator = (stateApp = {}) => {
	const meta = selectActionPlanStateMeta(stateApp);
	const { actionPlanComparator = '', actionPlanVersion = 0 } = meta;

	return helperSelectActionPlanComparator(
		actionPlanComparator,
		actionPlanVersion
	);
};

// ACTION PLAN STATE THRESHOLD
export const selectActionPlanStateThreshold = (stateApp = {}) => {
	const meta = selectActionPlanStateMeta(stateApp);
	const { threshold = -850 } = meta;

	return threshold;
};

// ACTION PLAN STATE USE SHADOW
export const selectActionPlanStateUseShadow = (stateApp = {}) => {
	const meta = selectActionPlanStateMeta(stateApp);
	const { useShadow = true } = meta;

	return useShadow;
};

// ACTION PLAN STATE REMEDIATION ACTIONS
export const selectActionPlanStateRemediationActions = (stateApp = {}) => {
	const meta = selectActionPlanStateMeta(stateApp);
	const { remediationActions = EMPTY_OBJECT } = meta;

	return remediationActions && Object.keys(remediationActions).length > 0
		? remediationActions
		: undefined;
};

// ACTION PLAN STATE REMEDIATION ACTIONS
export const selectActionPlanStateRemediationActionTypesByGroup = (
	stateApp = {}
) => {
	const meta = selectActionPlanStateMeta(stateApp);
	const { remediationActionTypesByGroup = EMPTY_OBJECT } = meta;

	return remediationActionTypesByGroup &&
		Object.keys(remediationActionTypesByGroup).length > 0
		? remediationActionTypesByGroup
		: undefined;
};

// ACTION PLAN STATE CUSTOM GROUPS
export const selectActionPlanStateCustomGroups = (stateApp = {}) => {
	const meta = selectActionPlanStateMeta(stateApp);
	const { customGroups = EMPTY_ARRAY } = meta;

	return customGroups && customGroups.length > 0 ? customGroups : undefined;
};

// ACTION PLAN STATE REMDIATION GROUPS
export const selectActionPlanStateRemediationGroups = (stateApp = {}) => {
	const meta = selectActionPlanStateMeta(stateApp);
	const { remediationGroups = EMPTY_ARRAY } = meta;

	return remediationGroups && remediationGroups.length > 0
		? remediationGroups
		: undefined;
};

// ACTION PLAN STATE USE INTERPOLATED DEPOL
export const selectActionPlanStateUseInterpolatedDepol = (stateApp = {}) => {
	const meta = selectActionPlanStateMeta(stateApp);
	const { useInterpolatedDepol = false } = meta;

	return useInterpolatedDepol;
};

// ACTION PLAN STATE USE NORMALIZED DEPOL
export const selectActionPlanStateUseNormalizedDepol = (stateApp = {}) => {
	const meta = selectActionPlanStateMeta(stateApp);
	const { useNormalizedDepol = true } = meta;

	return useNormalizedDepol;
};

// ACTION PLAN STATE REPORT DATA
export const selectActionPlanStateReportData = (stateApp = {}) => {
	const actionPlanState = selectActionPlanStateSlice(stateApp);
	const { meta, ...remainingProps } = actionPlanState;

	return remainingProps || EMPTY_OBJECT;
};

// IS APP STATE LOADED
export const selectIsActionPlanStateLoaded = (state = {}) => {
	const { isActionPlanStateLoaded } = state;

	return isActionPlanStateLoaded;
};

// ACTION PLAN STATE META EXCEPTIONS
export const selectActionPlanStateMetaExceptions = (stateApp = {}) => {
	const meta = selectActionPlanStateMeta(stateApp);
	const { exceptions = EMPTY_OBJECT } = meta;

	return exceptions;
};

// ACTION PLAN STATE FORMDATA EXCEPTIONS
export const selectActionPlanStateFormDataExceptionProps = createSelector(
	selectActionPlanStateSlice,
	actionPlanState => {
		const {
			'exceptions.belowThreshold': belowThreshold,
			'exceptions.criterion': criterion,
			'exceptions.isBelowCriterion': isBelowCriterion,
			'exceptions.list': list,
			'exceptions.series': series,
			'exceptions.suggestedBelowThreshold': suggestedBelowThreshold,
			'exceptions.suggestedIsBelowCriterion': suggestedIsBelowCriterion,
			'exceptions.totalDistance': totalDistance
		} = actionPlanState;

		return {
			'exceptions.belowThreshold': belowThreshold,
			'exceptions.criterion': criterion,
			'exceptions.isBelowCriterion': isBelowCriterion,
			'exceptions.list': list,
			'exceptions.series': series,
			'exceptions.suggestedBelowThreshold': suggestedBelowThreshold,
			'exceptions.suggestedIsBelowCriterion': suggestedIsBelowCriterion,
			'exceptions.totalDistance': totalDistance
		};
	}
);

// ACTION PLAN STATE ACTION PLAN VERSION
// @todo - this should be tracked from within the actionPlanState object
export const selectActionReportVersion = (state = {}) => {
	const { actionReportVersion = 0 } = state;

	return actionReportVersion;
};

// ACTION PLAN STATE ACTION PLAN VERSION
// @todo - this should be tracked from within the actionPlanState object
export const selectActionReportComparator = (state = {}) => {
	const { actionReportComparator = '', actionReportVersion = 0 } = state;

	return helperSelectActionPlanComparator(
		actionReportComparator,
		actionReportVersion
	);
};

// ACTION REPORT THRESHOLD
// @todo - this should be tracked from within the actionPlanState object
export const selectActionReportThreshold = (state = {}) => {
	const { actionReportThreshold = -850 } = state;

	return actionReportThreshold;
};

// ACTION REPORT USE SHADOW
// @todo - this should be tracked from within the actionPlanState object
export const selectActionReportUseShadow = (state = {}) => {
	const { actionReportUseShadow = true } = state;

	return actionReportUseShadow;
};

// ACTION REPORT CUSTOM GROUPS
// @todo - this should be tracked from within the actionPlanState object
export const selectActionReportCustomGroups = (state = {}) => {
	const { actionReportCustomGroups } = state;

	return actionReportCustomGroups;
};

export const selectS3Data = (state = {}) => {
	const meta = selectActionPlanStateMeta(state);
	const { s3Data } = meta;
	return s3Data;
};

export const selectS3SurveyData = (
	state = {},
	{ targetKey, parentKey, modifierKey }
) => {
	const s3Data = selectS3Data(state) || {};
	return (
		((s3Data[targetKey] || {})[parentKey] || {})[modifierKey] || EMPTY_OBJECT
	);
};

export const selectIsSyncEnabled = (stateApp = {}) => {
	const { isSyncEnabled } = selectActionPlanStateMeta(stateApp);
	return isSyncEnabled;
};

const isValidKey = k => !!k && k !== 'undefined';

const transformS3DataToPayloads = s3Data => {
	const targetKeys = Object.keys(s3Data || {}).filter(isValidKey);

	return targetKeys.reduce((acc, targetKey) => {
		const parentKeys = Object.keys(s3Data[targetKey]).filter(isValidKey);
		parentKeys.forEach(parentKey => {
			const modifierKeys = Object.keys(s3Data[targetKey][parentKey]).filter(
				isValidKey
			);
			modifierKeys.forEach(modifierKey => {
				const s3Keys = s3Data[targetKey][parentKey][modifierKey];
				acc.push({
					targetKey,
					parentKey,
					modifierKey,
					s3Keys
				});
			});
		});

		return acc;
	}, []);
};

export const selectS3DataRequestInfo = state => {
	const s3Data = selectS3Data(state);
	return transformS3DataToPayloads(s3Data);
};
