/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import {
	KEY_SUITE,
	KEY_SCANLINE,
	KEY_FIELDLINE,
	KEY_ASSETVIEW,
	KEY_LIVELINE,
	KEY_CHARGELINE,
	DEFAULT_KEY
} from '../../content';

import './ScheduleFreeTrialButton.scss';

const mailToMeta = {
	[KEY_SUITE]: {
		email: 'aip@corrpro.com',
		subject: 'Request: Schedule AIM Platform Free Trial',
		body: [
			'Hello,',
			"I'd like to schedule an AIM Platform free trial. Is your team available to discuss on {changeme:MM/DD/YYYY} at {changeme:TIME} ?",
			'Thank you,'
		].join('\n\n')
	},
	[KEY_SCANLINE]: {
		email: 'aip@corrpro.com',
		subject: 'Request: Schedule SCANLINE Free Trial',
		body: [
			'Hello,',
			"I'd like to schedule a SCANLINE free trial. Is your team available to discuss on {changeme:MM/DD/YYYY} at {changeme:TIME} ?",
			'Thank you,'
		].join('\n\n')
	},
	[KEY_FIELDLINE]: {
		email: 'aip@corrpro.com',
		subject: 'Request: Schedule FIELDLINE Free Trial',
		body: [
			'Hello,',
			"I'd like to schedule a FIELDLINE free trial. Is your team available to discuss on {changeme:MM/DD/YYYY} at {changeme:TIME} ?",
			'Thank you,'
		].join('\n\n')
	},
	[KEY_ASSETVIEW]: {
		email: 'aip@corrpro.com',
		subject: 'Request: Schedule ASSETVIEW Free Trial',
		body: [
			'Hello,',
			"I'd like to schedule an ASSETVIEW free trial. Is your team available to discuss on {changeme:MM/DD/YYYY} at {changeme:TIME} ?",
			'Thank you,'
		].join('\n\n')
	},
	[KEY_LIVELINE]: {
		email: 'aip@corrpro.com',
		subject: 'Request: Schedule LIVELINE Free Trial',
		body: [
			'Hello,',
			"I'd like to schedule a LIVELINE free trial. Is your team available to discuss on {changeme:MM/DD/YYYY} at {changeme:TIME} ?",
			'Thank you,'
		].join('\n\n')
	},
	[KEY_CHARGELINE]: {
		email: 'aip@corrpro.com',
		subject: 'Request: Schedule CHARGELINE Free Trial',
		body: [
			'Hello,',
			"I'd like to schedule a CHARGELINE free trial. Is your team available to discuss on {changeme:MM/DD/YYYY} at {changeme:TIME} ?",
			'Thank you,'
		].join('\n\n')
	}
};

const selectMailToMeta = appKey =>
	mailToMeta[appKey] || mailToMeta[DEFAULT_KEY];

const createMailToString = appKey => {
	const mailTo = selectMailToMeta(appKey);
	const to = `mailto:${mailTo.email.trim()}`;
	const subject = `subject=${encodeURIComponent(mailTo.subject.trim())}`;
	const body = `body=${encodeURIComponent(mailTo.body.trim())}`;
	return `${to}?${subject}&${body}`;
};

const RequestMoreInfoButton = ({ appKey }) => {
	return (
		<a className="schedule-free-trial-button" href={createMailToString(appKey)}>
			Schedule Free Trial
		</a>
	);
};

export default RequestMoreInfoButton;
