/* eslint-disable no-unused-vars */
/* eslint-disable no-console */
import ajax from '../../scanline/utils/ajax';
import { hasAccessToken } from '../../scanline/utils/token';
import { getInAppChatToEmails } from './api.inappchat.emails';
import { getCustomer, getUserName, getUserUUID } from '../../util/user';

const apiUtils = {
	sendNotificationOpenedInAppChat(applicationName) {
		if (hasAccessToken()) {
			const _callback = (err, res) => {
				if (err) {
					console.error(err);
				} else {
					console.log('notification sent - opened in app chat');
				}
			};
			const agentEmails = getInAppChatToEmails(applicationName);

			const payload = {
				userId: getUserUUID(),
				userFullName: getUserName(),
				customer: getCustomer(),
				applicationName,
				agentEmails,
				currentUrl: window ? window.location.href : 'unknown'
			};

			ajax(
				'/opened-in-app-chat',
				JSON.stringify(payload),
				'POST',
				_callback,
				false,
				'notifications'
			);
		}
	}
};

export default apiUtils;
