import { SET_EDITING } from './actions';

const initialState = {
	editing: false
};

const reducer = (state = initialState, action) => {
	const { type, payload } = action;
	switch (type) {
		case SET_EDITING:
			return { ...state, editing: payload };
		default:
			return state;
	}
};

export default reducer;
