import {
	calculateDistance,
	config
} from 'aegion_common_utilities/lib/MapProcessingUtil';

// This is the equivalent of calling turf.distance(point1, point2)
const calculateSimpleDistance = (point1, point2) => {
	return calculateDistance(point1, point2, {
		algorithm: config.GEO_ALGORITHMS.TURF,
		reverse: false,
		to: config.UNITS.DISTANCE.KILOMETERS
	});
};

export default calculateSimpleDistance;
