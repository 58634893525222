/* eslint-disable import/no-cycle */
/* eslint-disable no-use-before-define */
import { createActions } from 'redux-actions';
import fieldlineUtils from '../scanlineUtils';

const { generateShareLink, receiveShareLink, requestShareLink } = createActions(
	{
		GENERATE_SHARE_LINK: (url, data) => doGenerateShareLink(url, data),
		RECEIVE_SHARE_LINK: json => ({ json }),
		REQUEST_SHARE_LINK: () => ({})
	}
);

const doGenerateShareLink = (url, data, callback = () => {}) => {
	return dispatch => {
		dispatch(requestShareLink());

		return fieldlineUtils.generateShareLink(url, data, (err, res) => {
			if (err) {
				callback(err);
				// console.error(err);
			} else {
				callback(null, res);
				dispatch(receiveShareLink(res));
			}
		});
	};
};

export {
	generateShareLink,
	receiveShareLink,
	requestShareLink,
	doGenerateShareLink
};
