/* eslint import/no-cycle:0 */
import { reverse } from 'dns';
import { latLngBounds } from 'leaflet';
import { debounce } from 'lodash';

import { MiscUtils } from 'aegion_common_utilities';

// eslint-disable-next-line import/no-webpack-loader-syntax, import/extensions, import/no-unresolved
// import ReadingWorker from 'workerize-loader?inline!./workers/readings.worker.js';
import {
	getConvertedData,
	getUpdateFilteredReadings
} from './workers/readings.worker';
import ReadingsUtils from './workers/readings.worker.utils';

import JobUtil from '../../utils/JobUtil/JobUtil';
import SurveyUtil from '../../utils/SurveyUtil';
import { extentChanged } from './maps';
import { getBoundingBox } from '../../utils/DatFiles/index';
import {
	setSelectedDataForStationAlignment,
	setSelectedSurveyIsLinkedForStationAlignment
} from './stationAlignment';
import {
	selectGlobalAlignedTo,
	selectGlobalJobNumber,
	selectGlobalLinkedTo
} from '../selectors/globalData';
import { getAdditionalSurvey } from '../selectors/additionalSurveys';
import { selectGlobalDataCustomer } from '../selectors/readings';

// const readingWorker = ReadingWorker();

const NS_MAPS = 'cisview_additionalSurveys_';

const getWithDatFilesByTimeuuid = surveys => {
	const datFilesByTimeuuid = {};

	surveys.forEach(survey => {
		const { MasterLST } = survey;
		const datFiles = Object.values(MasterLST).map(datFile =>
			SurveyUtil.extendDataWithSurveyType(datFile, survey)
		);

		datFilesByTimeuuid[survey.timeuuid] = datFiles;
	});

	return datFilesByTimeuuid;
};

const _sortSurveys = surveys => {
	return [...surveys] // Make copy
		.sort((a, b) => {
			const nameA = a.surveyType.toUpperCase();
			const nameB = b.surveyType.toUpperCase();
			if (nameA < nameB) {
				return -1;
			}
			if (nameA > nameB) {
				return 1;
			}

			return 0;
		});
};

export const SET_FILTERS = `${NS_MAPS}SET_FILTERS`;
const setFiltersByTimeuuid = filtersByTimeuuid => dispatch => {
	// dispatch(setMapSelected(ALIGNMENT_READING_TABLE_TYPES.editableType))
	dispatch({
		type: SET_FILTERS,
		payload: {
			filtersByTimeuuid
		}
	});
};

export const SET_SURVEYS = `${NS_MAPS}SET_SURVEYS`;
const setSurveys = rawSurveys => dispatch => {
	const surveysWithSurveyType = rawSurveys.map(
		SurveyUtil.extendSurveyFilesWithSurveyType
	);
	const surveys = _sortSurveys(surveysWithSurveyType);
	const datFilesByTimeuuid = getWithDatFilesByTimeuuid(surveys);

	dispatch({
		type: SET_SURVEYS,
		payload: { surveys, datFilesByTimeuuid }
	});
};

export const SET_CONVERTED_READINGS = `${NS_MAPS}SET_CONVERTED_READINGS`;
const setConvertedReadings = convertedReadingsByTimeuuid => ({
	type: SET_CONVERTED_READINGS,
	payload: convertedReadingsByTimeuuid
});

// This is where we seet visible surveys - not sure if this is super helpful though
export const SET_VISIBLE_SURVEYS = `${NS_MAPS}SET_VISIBLE_SURVEYS`;
const setVisibleSurveys = surveys => {
	return {
		type: SET_VISIBLE_SURVEYS,
		payload: surveys
	};
};

// This will pull all survey data metadata (not gpsreadings, etc)
export const getSurveys = lineName => (dispatch, getState) => {
	const customer = selectGlobalDataCustomer(getState());

	JobUtil.getSurveys({ lineName, customer }).then(({ jobs = [] } = {}) => {
		const job = selectGlobalJobNumber(getState());
		const splitSurveys = jobs.reduce(
			(acc, j) => {
				// eslint-disable-next-line eqeqeq
				if (j.job == job) {
					acc.thisJob = [...acc.thisJob, ...(j.surveys || [])];
				} else {
					acc.otherJobs = [...acc.otherJobs, ...(j.surveys || [])];
				}
				return acc;
			},
			{ thisJob: [], otherJobs: [] }
		);
		const surveys = [...splitSurveys.thisJob, ...splitSurveys.otherJobs]
			.filter(s => s.MasterLST)
			.map((survey, idx) => {
				const color = MiscUtils.getColorByIndexOrRandom(idx, reverse);

				return {
					...survey,
					color,
					MasterLST: SurveyUtil.cleanMasterLST(survey.MasterLST)
				};
			});

		dispatch(setSurveys(surveys));

		// set selected survey for alignment
		const alignedTo = selectGlobalAlignedTo(getState());
		if (alignedTo) {
			const selectedAlignedToSurvey = getAdditionalSurvey(
				getState(),
				alignedTo
			);
			if (selectedAlignedToSurvey) {
				dispatch(
					setSelectedDataForStationAlignment(selectedAlignedToSurvey, 'static')
				);
				const isLinked = !!selectGlobalLinkedTo(getState());
				dispatch(
					setSelectedSurveyIsLinkedForStationAlignment(isLinked, 'static')
				);
				// eslint-disable-next-line no-use-before-define
				dispatch(setVisible({ timeuuid: alignedTo }));
			}
		}
	});
};

const _getSurvey = (timeuuid, getState) => {
	const state = getState();
	const { cisview } = state;
	const { additionalSurveys } = cisview;
	const { surveys } = additionalSurveys;

	return (surveys || []).find(s => s.timeuuid === timeuuid);
};

const _getBoundingBoxForSurvey = (timeuuid, getState) => {
	const survey = _getSurvey(timeuuid, getState);
	if (!survey) {
		return null;
	}

	const { MasterLST } = survey;
	return Object.keys(MasterLST).reduce((acc, fileName) => {
		const { [fileName]: dat } = MasterLST;
		const { bounds } = dat;
		if (!bounds) {
			return acc;
		}
		if (acc === null) {
			return latLngBounds(bounds.getSouthWest(), bounds.getNorthEast());
		}
		acc.extend(bounds);
		return acc;
	}, null);
};

const _extendSurveyWithExistingColor = (survey, color) => {
	const newMasterLST = {};
	Object.keys(survey.MasterLST).forEach(fileName => {
		newMasterLST[fileName] = {
			...survey.MasterLST[fileName],
			color
		};
	});

	return {
		...survey,
		MasterLST: newMasterLST,
		color
	};
};

const _pullAllDataForSurvey = survey => {
	const { color: originalColor } = survey;
	return new Promise((res, rej) => {
		JobUtil.getJobData(survey.timeuuid)
			.then(newSurvey => {
				const newSurveyWithOriginalColor = _extendSurveyWithExistingColor(
					newSurvey,
					originalColor
				);

				const withBounds = Object.keys(
					newSurveyWithOriginalColor.MasterLST
				).reduce((acc, key) => {
					const { gpsreadings } = newSurveyWithOriginalColor.MasterLST[key];
					const bounds = ReadingsUtils.getBounds(gpsreadings);
					const bbox = getBoundingBox(bounds);
					return {
						...acc,
						[key]: {
							...newSurveyWithOriginalColor.MasterLST[key],
							bounds: bbox
						}
					};
				}, {});

				return res({
					...newSurveyWithOriginalColor,
					MasterLST: withBounds,
					loaded: true
				});
			})
			.catch(e => {
				rej(e);
			});
	});
};

const _mergeSurveyWithOthers = (survey, getState) => {
	const state = getState();
	const { cisview } = state;
	const { additionalSurveys } = cisview;
	const { surveys } = additionalSurveys;

	const otherSurveys = surveys.filter(s => s.timeuuid !== survey.timeuuid);

	return [...otherSurveys, survey];
};

const _mergeConvertedReadings = (
	convertedReadingObject,
	timeuuid,
	getState
) => {
	const state = getState();
	const { cisview } = state;
	const { additionalSurveys } = cisview;
	const { convertedReadingsByTimeuuid } = additionalSurveys;

	return {
		...convertedReadingsByTimeuuid,
		[timeuuid]: convertedReadingObject
	};
};

const _getAlreadyConvertedReadings = (getState, timeuuid) => {
	const state = getState();
	const { cisview } = state;
	const { additionalSurveys } = cisview;
	const { convertedReadingsByTimeuuid } = additionalSurveys;

	if (!convertedReadingsByTimeuuid[timeuuid]) {
		return null;
	}

	return convertedReadingsByTimeuuid[timeuuid];
};

const _setConvertedReadingsFromSurvey = survey => async (
	dispatch,
	getState
) => {
	const fileNames = Object.keys(survey.MasterLST);
	const { timeuuid, surveyType } = survey;

	const datFiles = fileNames.map(fileName => {
		return {
			...survey.MasterLST[fileName],
			fileName,
			timeuuid,
			surveyType
		};
	});
	const datFilesWithGpsReadings = datFiles.filter(
		datFile => datFile.gpsreadings
	);
	if (datFilesWithGpsReadings.length === 0) {
		return;
	}
	const convertedReadingsAlreadyExist = _getAlreadyConvertedReadings(
		getState,
		timeuuid
	);
	if (convertedReadingsAlreadyExist) {
		return;
	}

	const { convertedReadings } = await getConvertedData(datFilesWithGpsReadings);

	const mergedConvertedReadings = _mergeConvertedReadings(
		convertedReadings,
		timeuuid,
		getState
	);

	dispatch(setConvertedReadings(mergedConvertedReadings));
};

const _setSurveyLoading = (survey, loading = true) => (dispatch, getState) => {
	const surveyLoading = {
		...survey,
		loading
	};

	dispatch(setSurveys(_mergeSurveyWithOthers(surveyLoading, getState)));

	return surveyLoading;
};

const _loadSurvey = timeuuid => async (dispatch, getState) => {
	const survey = _getSurvey(timeuuid, getState);

	if (!survey) {
		return;
	}

	if (survey.loaded || survey.loading) {
		return;
	}
	const loadingSurvey = _setSurveyLoading(survey, true)(dispatch, getState);
	const newSurvey = await _pullAllDataForSurvey(loadingSurvey);
	const finishedLoadingSurvey = _setSurveyLoading(newSurvey, false)(
		dispatch,
		getState
	);

	dispatch(setSurveys(_mergeSurveyWithOthers(finishedLoadingSurvey, getState)));
	await _setConvertedReadingsFromSurvey(finishedLoadingSurvey)(
		dispatch,
		getState
	);
};

const hideSurvey = () => () => {};

const _getVisibleSurveys = getState => {
	const state = getState();
	const { cisview } = state;
	const { additionalSurveys } = cisview;
	const { visibleSurveys } = additionalSurveys;

	return visibleSurveys;
};

const _isSurveyVisbile = timeuuid => getState => {
	const visibleSurveys = _getVisibleSurveys(getState);

	return visibleSurveys.indexOf(timeuuid) !== -1;
};

export const setVisible = survey => async (dispatch, getState) => {
	const { timeuuid } = survey || {}; // we can't use default param values because some code passes null to this function
	if (timeuuid) {
		const visibleSurveys = _getVisibleSurveys(getState);
		if (!_isSurveyVisbile(timeuuid)(getState)) {
			dispatch(setVisibleSurveys([...visibleSurveys, timeuuid]));
			await _loadSurvey(timeuuid)(dispatch, getState);
		} else {
			const index = visibleSurveys.indexOf(timeuuid);
			dispatch(
				setVisibleSurveys(visibleSurveys.filter((_, idx) => idx !== index))
			);
			dispatch(hideSurvey(timeuuid));
		}
	}
};

// This is the "editable" type (as found in Station Alignment)
export const setConvertedReadingsForAdditionalSurvey = convertedReadings => (
	dispatch,
	getState
) => {
	const state = getState();
	const { cisview } = state;
	const { job } = cisview;

	const { globalData } = job;
	const { timeuuid } = globalData;

	// First we want to go ahead and update the survey from globalData
	const surveys = _mergeSurveyWithOthers(globalData, getState);
	dispatch(setSurveys(surveys));

	const mergedConvertedReadings = _mergeConvertedReadings(
		convertedReadings,
		timeuuid,
		getState
	);

	dispatch(setConvertedReadings(mergedConvertedReadings));
};

const _isGlobalSurvey = (getState, timeuuid) => {
	const state = getState();
	const { cisview } = state;
	const { job } = cisview;

	const { globalData } = job;
	return globalData.timeuuid === timeuuid;
};

export const zoomTo = (timeuuid, force = true) => (dispatch, getState) => {
	const state = getState();
	const { cisview } = state;
	const { maps } = cisview;
	const { map } = maps;

	const isVisible = _isSurveyVisbile(timeuuid)(getState);
	if (!isVisible) {
		dispatch(setVisible({ timeuuid }));
	}

	const bbox = _getBoundingBoxForSurvey(timeuuid, getState);
	if (!bbox) {
		return;
	}

	const level = map.getBoundsZoom(bbox);
	const center = bbox.getCenter();
	const { lat, lng } = center;

	if (!force && level < map.getZoom()) {
		return;
	}

	dispatch(extentChanged([lat, lng], level));
};

// For the readings table, we want to have the equivalent "convertedReadings" (flattened array structure)
// The functions within should be smart enough to only work when needed (ie. we can call this function as many times as we want without a problem)
export const loadSurveyForReadingsTable = (
	{ timeuuid } = {},
	force = true
) => async (dispatch, getState) => {
	// We want globalData to be updated automatically (through the readings action)
	if (_isGlobalSurvey(getState, timeuuid)) {
		return;
	}

	// First pull all data for surveys - only call if not visible (otherwise it will actually hide the survey)
	if (!_isSurveyVisbile(timeuuid)(getState)) {
		await setVisible({ timeuuid })(dispatch, getState);
	}

	// Then zoom to show survey on map
	zoomTo(timeuuid, force)(dispatch, getState);

	// Then get convertedReadings for all dat files in surveys
	const survey = _getSurvey(timeuuid, getState);
	if (survey.loading) {
		return;
	}
	_setConvertedReadingsFromSurvey(survey)(dispatch, getState);
};

const skeletonFilterOptions = () => {
	return {
		filterText: '',
		filteredReadings: []
	};
};

const getFilterText = (getState, timeuuid) => {
	const { filtersByTimeuuid } = getState().cisview.additionalSurveys;
	if (!filtersByTimeuuid[timeuuid]) {
		return '';
	}

	return filtersByTimeuuid[timeuuid].filterText;
};

const _updateReadingsFilter = async (filter, timeuuid, dispatch, getState) => {
	const state = getState();
	const { cisview } = state;
	const { additionalSurveys } = cisview;

	const { convertedReadingsByTimeuuid, filtersByTimeuuid } = additionalSurveys;

	const convertedReadings = convertedReadingsByTimeuuid[timeuuid];
	if (!convertedReadings) {
		return;
	}

	const startFilterText = getFilterText(getState, timeuuid);

	const filteredReadings = await getUpdateFilteredReadings(
		filter,
		convertedReadings
	);

	// The filterText has changed, abort
	if (startFilterText !== getFilterText(getState, timeuuid)) {
		return;
	}

	const thisFilter = filtersByTimeuuid[timeuuid] || skeletonFilterOptions();
	const newFilter = {
		...thisFilter,
		filteredReadings
	};
	const newFiltersByTimeuuid = {
		...filtersByTimeuuid,
		[timeuuid]: newFilter
	};

	dispatch(setFiltersByTimeuuid(newFiltersByTimeuuid));
};

// We debounce this logic as the user might be typing quickly and it might take a while to finish
const _updateReadingsFilterDebounce = debounce(_updateReadingsFilter, 100);

const _updateFilterValueByTimeuuid = (filter, timeuuid, dispatch, getState) => {
	const state = getState();
	const { cisview } = state;
	const { additionalSurveys } = cisview;
	const { filtersByTimeuuid } = additionalSurveys;

	const thisFilter = filtersByTimeuuid[timeuuid] || skeletonFilterOptions();
	const newFilter = {
		...thisFilter,
		filterText: filter
	};

	const newFiltersByTimeuuid = {
		...filtersByTimeuuid,
		[timeuuid]: newFilter
	};

	dispatch(setFiltersByTimeuuid(newFiltersByTimeuuid));
};

export const updateReadingsFilter = (filter, timeuuid) => (
	dispatch,
	getState
) => {
	// We immediatelly set the filter value so they user can keep typing
	_updateFilterValueByTimeuuid(filter, timeuuid, dispatch, getState);
	// We debounce the logic as it might take a while to finish
	_updateReadingsFilterDebounce(filter, timeuuid, dispatch, getState);
};
