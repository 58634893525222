import { createActions } from 'redux-actions';

const {
	setFocusedChart,
	setHoveredChart,
	setJobsModalVisibility,
	setAllReadingsFlag,
	setExpandedChart
} = createActions({
	SET_FOCUSED_CHART: CHART_ID => ({ CHART_ID }),
	SET_HOVERED_CHART: CHART_ID => ({ CHART_ID }),
	SET_JOBS_MODAL_VISIBILITY: bool => ({ bool }),
	SET_ALL_READINGS_FLAG: bool => ({ bool }),
	SET_EXPANDED_CHART: chartId => ({ chartId }),
	UPDATE_CHART_META: (surveyId, meta) => ({ surveyId, meta })
});

export {
	setFocusedChart,
	setHoveredChart,
	setJobsModalVisibility,
	setAllReadingsFlag,
	setExpandedChart
};
