import { selectSliceCisview } from '../../selectors/HELPERS';
import { ROOT_SLICE } from './constants';

const EMPTY_ARRAY = [];

export const selectRootSlice = state => {
	const appSlice = selectSliceCisview(state);
	return appSlice[ROOT_SLICE] || state;
};

export const selectData = state => {
	const slice = selectRootSlice(state);
	const { data } = slice;
	return data;
};

export const selectDataByCustomer = (state, { customer }) => {
	const data = selectData(state);
	return data[customer] || EMPTY_ARRAY;
};

export const selectIsLoading = state => {
	const slice = selectRootSlice(state);
	const { isLoading } = slice;
	return isLoading;
};

export const selectIsLoaded = state => {
	const slice = selectRootSlice(state);
	const { isLoaded } = slice;
	return isLoaded;
};

export const selectErrorMessage = state => {
	const slice = selectRootSlice(state);
	const { errorMessage } = slice;
	return errorMessage;
};

export default {
	selectData,
	selectDataByCustomer,
	selectIsLoading,
	selectIsLoaded,
	selectErrorMessage
};
