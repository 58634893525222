/* eslint-disable no-param-reassign */
import {
	config,
	calculateDistance
} from 'aegion_common_utilities/lib/MapProcessingUtil';
import { REGEX_CIS_COMMENTS } from 'aegion_common_utilities/lib/RegEx';
import { formatStationId } from '../../stationId';

const { COMMENT_KEYS, hasComment, parseComment } = REGEX_CIS_COMMENTS;

/** ******************
 * HELPERS
 ******************* */

const isDefined = v => v !== undefined && v !== null;

export const assumeIsInMV = val => {
	return !val || (val && Math.abs(val) >= 10);
};

export const computeShadow = r => {
	if (r.on) {
		r.sh = r.on - 100;
	}
};

/** ******************
 * SPATIAL HELPERS
 ******************* */

const matchRectifierFromComment = comment => {
	return hasComment({
		text: comment,
		commentKey: COMMENT_KEYS.RECTIFIER
	});
};

const matchAerialFromComment = comment => {
	return hasComment({
		text: comment,
		commentKey: COMMENT_KEYS.AERIAL
	});
};

const matchValveFromComment = comment => {
	return hasComment({
		text: comment,
		commentKey: COMMENT_KEYS.VALVE
	});
};

const matchTestStationFromComment = comment => {
	return hasComment({
		text: comment,
		commentKey: COMMENT_KEYS.TEST_STATION
	});
};

const matchLineMarkerFromComment = comment => {
	return hasComment({
		text: comment,
		commentKey: COMMENT_KEYS.LINE_MARKER
	});
};

const matchForeignLineFromComment = comment => {
	return hasComment({
		text: comment,
		commentKey: COMMENT_KEYS.FOREIGN_LINE
	});
};

const SPATIAL_CHART_Y_VALUES = {
	LINE_MARKERS: -1,
	FOREIGN_LINE_CROSSING: 10,
	RECTIFIER: 20,
	AERIAL: 30,
	VALVES: 40,
	TEST_STATIONS: 50
};

/** ******************
 * MUTATORS
 ******************* */

export const computeMvTransform = (r, key) => {
	if (r && key && !assumeIsInMV(r[key])) {
		r[key] *= 1000;
	}
};

export const computeUuid = (r, idx) => {
	r.uuid = r.uuid || `${r.id}~${idx}`;
};

// TODO: Delete
global.count = 0;

export const computeDistance = (
	prevReading,
	currReading,
	{ dstKey = 'dst', getLoc = r => r.loc } = {}
) => {
	if (!prevReading) {
		currReading[dstKey] = 0.0;
	} else {
		currReading[dstKey] = calculateDistance(
			getLoc(prevReading),
			getLoc(currReading),
			{
				algorithm: config.GEO_ALGORITHMS.TURF,
				roundTo: 2
			}
		);
	}
};

export const computeFormattedStationId = reading => {
	reading.formattedStationId = formatStationId(reading.id);
};

export const computePipeDepth = (reading = {}) => {
	const { cmt } = reading;

	if (cmt) {
		const pipeDepth = parseComment({
			text: cmt,
			commentKey: COMMENT_KEYS.PIPE_DEPTH
		});
		if (isDefined(pipeDepth)) {
			reading.topDep = pipeDepth;
			reading.elcDep = pipeDepth;

			// @depricated
			reading.doc = pipeDepth;
		}
	}
};

export const computeAC = (reading = {}) => {
	const WARNING_THRESHOLD = 2;
	const PROBLEM_THRESHOLD = 15;
	const { cmt } = reading;

	if (cmt) {
		const ac = parseComment({
			text: cmt,
			commentKey: COMMENT_KEYS.AC
		});
		if (isDefined(ac)) {
			reading.ac = ac;

			if (ac >= PROBLEM_THRESHOLD) {
				reading.acProblem = ac;
			} else if (ac >= WARNING_THRESHOLD) {
				reading.acWarning = ac;
			} else {
				reading.acPure = ac;
			}
		}
	}
};

export const computeSpatial = (reading = {}) => {
	const { cmt } = reading;

	if (cmt) {
		// Rectifier
		const rectifier = matchRectifierFromComment(cmt);
		if (rectifier) {
			reading.rectifier = SPATIAL_CHART_Y_VALUES.RECTIFIER;
			reading.isSpatial = true;
		}

		// Aerial
		const aerial = matchAerialFromComment(cmt);
		if (aerial) {
			reading.aerial = SPATIAL_CHART_Y_VALUES.AERIAL;
			reading.isSpatial = true;
		}

		// Valve
		const valve = matchValveFromComment(cmt);
		if (valve) {
			reading.valve = SPATIAL_CHART_Y_VALUES.VALVES;
			reading.isSpatial = true;
		}

		// Test Station
		const testStation = matchTestStationFromComment(cmt);
		if (testStation) {
			reading.testStation = SPATIAL_CHART_Y_VALUES.TEST_STATIONS;
			reading.isSpatial = true;
		}

		// Line Marker
		const lineMarker = matchLineMarkerFromComment(cmt);
		if (lineMarker) {
			reading.lineMarker = SPATIAL_CHART_Y_VALUES.LINE_MARKERS;
			reading.isSpatial = true;
		}

		// Other Spatial Data
		const foreignLineCrossing = matchForeignLineFromComment(cmt);
		if (foreignLineCrossing) {
			reading.foreignLineCrossing =
				SPATIAL_CHART_Y_VALUES.FOREIGN_LINE_CROSSING;
			reading.isSpatial = true;
		}
	}
};

export const defaultComputeProps = (
	survey,
	_prevReading,
	currReading,
	currIdx
) => {
	computeUuid(currReading, currIdx);
	computeFormattedStationId(currReading);
};
