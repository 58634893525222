const serializeError = error => {
	if (!error) {
		return null;
	}
	const errorMessage = error.message || error.errorMessage || error;

	const serializedError = {
		errorMessage
	};

	if (error.stack) {
		serializedError.stack = error.stack;
	}

	if (error.name) {
		serializedError.name = error.name;
	}

	return serializedError;
};

export default serializeError;
