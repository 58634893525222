export const initialState = {
	data: {},
	assetviewSettingsMap: {},
	avMatchTermMap: {},
	isLoading: false,
	isLoaded: false,
	errorMessage: undefined
};

export default initialState;
