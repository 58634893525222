"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.calculateDestination = calculateDestination;
exports["default"] = void 0;

var _geographiclib = _interopRequireDefault(require("geographiclib"));

var _destination = _interopRequireDefault(require("@turf/destination"));

var _config = require("./config");

var _MiscUtils = _interopRequireDefault(require("../MiscUtils"));

var _mapUtilsMisc = require("./mapUtilsMisc");

var _transformDistanceWithOptions = _interopRequireDefault(require("./transformDistanceWithOptions"));

var _UnknownDistanceAlgorithmError = _interopRequireDefault(require("../ErrorClasses/UnknownDistanceAlgorithmError"));

var _excluded = ["algorithm", "from", "roundDistance", "roundCoords", "reverseResponse"];

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    "default": obj
  };
}

function ownKeys(object, enumerableOnly) {
  var keys = Object.keys(object);

  if (Object.getOwnPropertySymbols) {
    var symbols = Object.getOwnPropertySymbols(object);
    enumerableOnly && (symbols = symbols.filter(function (sym) {
      return Object.getOwnPropertyDescriptor(object, sym).enumerable;
    })), keys.push.apply(keys, symbols);
  }

  return keys;
}

function _objectSpread(target) {
  for (var i = 1; i < arguments.length; i++) {
    var source = null != arguments[i] ? arguments[i] : {};
    i % 2 ? ownKeys(Object(source), !0).forEach(function (key) {
      _defineProperty(target, key, source[key]);
    }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) {
      Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key));
    });
  }

  return target;
}

function _defineProperty(obj, key, value) {
  if (key in obj) {
    Object.defineProperty(obj, key, {
      value: value,
      enumerable: true,
      configurable: true,
      writable: true
    });
  } else {
    obj[key] = value;
  }

  return obj;
}

function _objectWithoutProperties(source, excluded) {
  if (source == null) return {};

  var target = _objectWithoutPropertiesLoose(source, excluded);

  var key, i;

  if (Object.getOwnPropertySymbols) {
    var sourceSymbolKeys = Object.getOwnPropertySymbols(source);

    for (i = 0; i < sourceSymbolKeys.length; i++) {
      key = sourceSymbolKeys[i];
      if (excluded.indexOf(key) >= 0) continue;
      if (!Object.prototype.propertyIsEnumerable.call(source, key)) continue;
      target[key] = source[key];
    }
  }

  return target;
}

function _objectWithoutPropertiesLoose(source, excluded) {
  if (source == null) return {};
  var target = {};
  var sourceKeys = Object.keys(source);
  var key, i;

  for (i = 0; i < sourceKeys.length; i++) {
    key = sourceKeys[i];
    if (excluded.indexOf(key) >= 0) continue;
    target[key] = source[key];
  }

  return target;
}

function _slicedToArray(arr, i) {
  return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _unsupportedIterableToArray(arr, i) || _nonIterableRest();
}

function _nonIterableRest() {
  throw new TypeError("Invalid attempt to destructure non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method.");
}

function _unsupportedIterableToArray(o, minLen) {
  if (!o) return;
  if (typeof o === "string") return _arrayLikeToArray(o, minLen);
  var n = Object.prototype.toString.call(o).slice(8, -1);
  if (n === "Object" && o.constructor) n = o.constructor.name;
  if (n === "Map" || n === "Set") return Array.from(o);
  if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen);
}

function _arrayLikeToArray(arr, len) {
  if (len == null || len > arr.length) len = arr.length;

  for (var i = 0, arr2 = new Array(len); i < len; i++) {
    arr2[i] = arr[i];
  }

  return arr2;
}

function _iterableToArrayLimit(arr, i) {
  var _i = arr == null ? null : typeof Symbol !== "undefined" && arr[Symbol.iterator] || arr["@@iterator"];

  if (_i == null) return;
  var _arr = [];
  var _n = true;
  var _d = false;

  var _s, _e;

  try {
    for (_i = _i.call(arr); !(_n = (_s = _i.next()).done); _n = true) {
      _arr.push(_s.value);

      if (i && _arr.length === i) break;
    }
  } catch (err) {
    _d = true;
    _e = err;
  } finally {
    try {
      if (!_n && _i["return"] != null) _i["return"]();
    } finally {
      if (_d) throw _e;
    }
  }

  return _arr;
}

function _arrayWithHoles(arr) {
  if (Array.isArray(arr)) return arr;
}

var _UNITS$DISTANCE = _config.UNITS.DISTANCE,
    KILOMETERS = _UNITS$DISTANCE.KILOMETERS,
    METERS = _UNITS$DISTANCE.METERS;
var geod = _geographiclib["default"].Geodesic.WGS84;

var formatResponseCoordinates = function formatResponseCoordinates(coords, roundTo) {
  if (roundTo === undefined) {
    return coords;
  }

  var _coords = _slicedToArray(coords, 2),
      x = _coords[0],
      y = _coords[1];

  return [_MiscUtils["default"].roundToDigitPlace(x, roundTo), _MiscUtils["default"].roundToDigitPlace(y, roundTo)];
};

var verifyOptions = function verifyOptions(options) {
  var keys = Object.keys(options);

  if (keys.length === 0) {
    return;
  }

  throw new Error("Unkown options: \"".concat(keys.join('", "'), "\""));
};

function getGeodDestination(coord, distance, azimuth) {
  // Geod expects lat, lon
  var point = geod.Direct(coord[1], coord[0], azimuth, distance);
  return [point.lon2, point.lat2];
}

function getTurfDestination(coord, distance, azimuth) {
  var destination = (0, _destination["default"])(coord, distance, azimuth);
  return destination.geometry.coordinates;
}

function getDestinationFromAlgo(algo, coord, distance, azimuth) {
  switch (algo) {
    case _config.GEO_ALGORITHMS.TURF:
      return getTurfDestination(coord, distance, azimuth);

    case _config.GEO_ALGORITHMS.GEOD:
      return getGeodDestination(coord, distance, azimuth);

    default:
      throw new _UnknownDistanceAlgorithmError["default"]("Unkown algorithm \"".concat(algo, "\""));
  }
}

function getUnitOutputedFromAlgorithm(algo) {
  switch (algo) {
    case _config.GEO_ALGORITHMS.TURF:
      return KILOMETERS;

    case _config.GEO_ALGORITHMS.GEOD:
      return METERS;

    default:
      throw new _UnknownDistanceAlgorithmError["default"]("Unkown algorithm \"".concat(algo, "\""));
  }
}
/**
 *
 * @param {[lon, lat]} coord
 * @param {float} distanceRaw
 * @param {float} azimuth
 * @param {{}} options
 * @returns {[lat, lon]} by default - to change, specify {reverseResponse: false} in @options
 */


function calculateDestination(coord, // expected to be [lon, lat]
distanceRaw, azimuth) {
  var options = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : {};

  var _options$algorithm = options.algorithm,
      algorithm = _options$algorithm === void 0 ? _config.GEO_ALGORITHMS.GEOD : _options$algorithm,
      _options$from = options.from,
      from = _options$from === void 0 ? _config.UNITS.DISTANCE.FEET : _options$from,
      roundDistance = options.roundDistance,
      roundCoords = options.roundCoords,
      _options$reverseRespo = options.reverseResponse,
      reverseResponse = _options$reverseRespo === void 0 ? true : _options$reverseRespo,
      otherOptions = _objectWithoutProperties(options, _excluded);

  var to = getUnitOutputedFromAlgorithm(algorithm);
  verifyOptions(otherOptions);
  var distance = (0, _transformDistanceWithOptions["default"])(distanceRaw, _objectSpread({
    from: from,
    to: to,
    roundTo: roundDistance
  }, otherOptions));
  var destinationCoords = getDestinationFromAlgo(algorithm, (0, _mapUtilsMisc.formatCoords)(coord), distance, azimuth);
  var coords = formatResponseCoordinates(destinationCoords, roundCoords);

  if (!reverseResponse) {
    return coords;
  }

  return [coords[1], coords[0]];
}

var _default = calculateDestination;
exports["default"] = _default;