/* eslint-disable import/prefer-default-export */

const createIsRemediationReading = (
	bcReadingById = {},
	customExceptionsMap = {}
) => r => !!bcReadingById[r.id] || !!customExceptionsMap[r.id];

export const createGetSegmentsWithExceptions = computeReadingDistance => (
	readings,
	key,
	threshold,
	bcSeries,
	bcReadingById, // @todo - bad variable name - should be called remediationReadingsMap - as it contains both computed bc and custom bc
	shadowMap,
	computedBcMap,
	customExceptionsMap,
	useSpatialDistance = false
) => {
	// console.log(bcReadingById);
	// console.log(customExceptionsMap);
	// debugger;

	const isRemediationReading = createIsRemediationReading(
		bcReadingById,
		customExceptionsMap
	);
	const segments = [];

	let segment = null;
	let reading;
	let prevReading;

	// const logMaps = (key, reading) => {
	// 	const r = reading || {};
	// 	console.log(
	// 		`${key} -> ${r.id || 'undefined'} - ${!!bcReadingById[
	// 			r.id
	// 		]} - ${!!customExceptionsMap[r.id]}`
	// 	);
	// };

	// console.log('----------');
	// console.log('----------');
	// console.log('----------');
	// console.log('bcReadingById');
	// console.log(bcReadingById);
	// console.log('computedBcMap');
	// console.log(computedBcMap);
	// console.log('customExceptionsMap');
	// console.log(customExceptionsMap);

	// @note - prepend function name with "x" to prevent transpiler from breaking when it assumes usePreviousReading is a react hook.
	const xUsePreviousReading = currIdx => {
		const prevIdx = currIdx - 1;
		// const prevPrevIdx = prevIdx - 1;

		const currReading = readings[currIdx];
		const localPrevReading = prevIdx > -1 ? readings[prevIdx] : undefined;
		// const prevPrevReading = prevPrevIdx > -1 ? readings[prevPrevIdx] : undefined;

		// console.log('***');
		// logMaps('currReading', currReading);
		// logMaps('localPrevReading', localPrevReading);
		// logMaps('prevPrevReading', prevPrevReading);

		const result =
			customExceptionsMap[currReading.id] ||
			(localPrevReading && customExceptionsMap[localPrevReading.id]);

		/**
		 * @todo - replace above result boolean logic with below.  The above appears to be bugged, but we don't want to fix until after release of Protected Action Plans unless we can be certain it will not break a single pre-existing action plan.
		 * @NewImplementation = const result = customExceptionsMap[currReading.id] && localPrevReading;
		 */

		// console.log((currReading || {}).id);
		// console.log((localPrevReading || {}).id);
		// console.log(result);
		// debugger;

		return result;
	};

	// TODO: n^2 complexity - not great - let's use a hash table
	let i = 0;
	while (i < readings.length) {
		prevReading = reading;
		reading = readings[i];

		// We are only interested in a single key (on or off typically) - if this value is null or 0 ignore this reading
		if (!reading || !reading[key]) {
			i += 1;
			continue;
		}

		if (segment === null) {
			segment = {};
		}

		const found = isRemediationReading(reading);

		// if (reading && reading.id && reading.id > 325 && reading.id < 338) {
		// 	debugger;
		// }

		if (found) {
			if (!segment.start) {
				segment.start = reading;
				segment.count = 0;
			}
			segment.count += 1;
		} else if (segment.start) {
			if (xUsePreviousReading(i)) {
				segment.end = prevReading;
			} else {
				segment.end = reading;
				segment.count += 1;
			}
			if (segment.start && segment.end) {
				segments.push(segment);
			}
			prevReading = undefined;
			segment = null;
		}
		i += 1;
	} // while end

	// finish incomplete segment
	if (segment && segment.start && !segment.end && reading) {
		if (xUsePreviousReading(i - 1)) {
			segment.end = prevReading;
		} else {
			segment.end = reading;
			segment.count += 1;
		}
		if (segment.start && segment.end) {
			segments.push(segment);
		}
		prevReading = undefined;
		reading = undefined;
	}
	segment = null;

	// console.log(segments);
	// debugger;

	// console.log('----------');
	// console.log('----------');
	// console.log('----------');

	return segments.map(s => {
		const { start, end, count } = s;
		const length = computeReadingDistance(start, end, count, {
			useSpatialDistance
		});
		return {
			start: { id: start.id, uuid: start.uuid, loc: start.loc },
			end: { id: end.id, uuid: end.uuid, loc: end.loc },
			length
		};
	});
};
