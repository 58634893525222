export const ALIGNMENT_READING_TABLE_TYPES = {
	staticType: 'static', // Left table
	editableType: 'editable', // Right table
	ledgerType: 'ledgerTable' // Combined tables above
};

export class StationAlignmentValidationError extends Error {
	name = 'StationAlignmentValidationError';
}
// additionalSurveys includes data that would be found in `globalData` (the actual `globalData` found in the redux store)
const _getFileInfoFromAdditionalSurveys = additionalSurveys => {
	if (!additionalSurveys || !additionalSurveys.length) {
		return [];
	}

	return additionalSurveys.map(survey => {
		return survey.surveyType;
	});
};

export const getStationAlignmentFileDropdownInfo = additionalSurveys => {
	return _getFileInfoFromAdditionalSurveys(additionalSurveys);
};

export const getSurveyBySurveyType = (additionalSurveys, surveyType) => {
	if (!additionalSurveys) {
		return null;
	}
	for (let i = 0; i < additionalSurveys.length; i += 1) {
		const survey = additionalSurveys[i];

		if (survey.surveyType === surveyType) {
			return survey;
		}
	}

	return null;
};

const createStationIdBackup = (reading, backupDate, stationId) => {
	const previousOldStns = reading.oldStns || [];
	const { StationId } = reading;

	if (stationId === StationId) {
		return {};
	}

	const originalStationId = reading.StationId;

	reading.StationId = stationId;
	reading.oldStns = [
		...(previousOldStns || []),
		{
			old: originalStationId,
			new: stationId,
			dt: backupDate
		}
	];
};

const _getAverage = numbers => {
	return (
		numbers.reduce((totalCount, number) => totalCount + number, 0) /
		numbers.length
	);
};

const _createAlignedStationIdsStatistics = (
	stationIdDifferences,
	allDataCount
) => {
	const percentChanged = (stationIdDifferences.length / allDataCount) * 100;

	const averageDistance = _getAverage(stationIdDifferences);

	return {
		percentChanged,
		averageDistance,
		stationIdDifferences,
		allDataCount
	};
};

const verifyAlignedDataProperties = (propertiesToVerify, currentCount) => {
	Object.keys(propertiesToVerify).forEach(propertyName => {
		const properties = propertiesToVerify[propertyName];

		if (properties[0] !== properties[1]) {
			throw new StationAlignmentValidationError(
				`Expected ${propertyName} to be ${properties[0]} but found ${properties[1]} at count ${currentCount}`
			);
		}
	});
};

const verifyAlignedData = (currentCount, originalReading, computedReading) => {
	const { comments: originalComment } = originalReading;
	const computedComment = computedReading.comments;

	const propertiesToVerify = {
		computedComment: [computedComment, originalComment]
	};

	verifyAlignedDataProperties(propertiesToVerify, currentCount);
};

const setDefaultNewData = (newData, computedReading, originalData) => {
	const { datIndex, gpsIndex } = computedReading;

	if (!newData[datIndex]) {
		newData[datIndex] = {
			...originalData[datIndex],
			gpsreadings: []
		};
	}
	const { gpsreadings } = newData[datIndex];
	const originalGpsReadings = originalData[datIndex].gpsreadings;
	if (!gpsreadings[gpsIndex]) {
		gpsreadings[gpsIndex] = {
			...originalGpsReadings[gpsIndex]
		};
	}

	const datFile = originalData[datIndex];
	const reading = gpsreadings[gpsIndex];
	const originalReading = originalGpsReadings[gpsIndex];
	return { datFile, reading, originalReading };
};

// This has to unzip all of the "computedReadings"
export const calculateStartEndStnFlat = realignedComputedReadings => {
	const alignedComputedReadingsByDat = [];
	realignedComputedReadings.forEach(r => {
		const { datIndex, computedStationId } = r;
		if (!alignedComputedReadingsByDat[datIndex]) {
			alignedComputedReadingsByDat[datIndex] = [];
		}

		alignedComputedReadingsByDat[datIndex].push(computedStationId);
	});

	const startEndStnByDatIndex = alignedComputedReadingsByDat.map(stations => {
		const startStn = Math.min(...stations);
		const endStn = Math.max(...stations);

		return {
			startStn,
			endStn
		};
	});

	return startEndStnByDatIndex;
};

export const verifyDataSizes = (data, convertedReadings) => {
	let dataCount = 0;
	data.forEach(datFile => {
		const { gpsreadings } = datFile;
		dataCount += gpsreadings.length;
	});

	if (dataCount !== convertedReadings.length) {
		throw new StationAlignmentValidationError(
			`Datasets are of different lengths - dat files have ${dataCount} readings and convertedReadings have ${convertedReadings.length} readings`
		);
	}
};

export const getReadingsWithAlignedStationIds = (
	originalData,
	originalGlobalData,
	realignedComputedReadings
) => {
	const startEndStationsByDatIndex = calculateStartEndStnFlat(
		realignedComputedReadings
	);
	verifyDataSizes(originalData, realignedComputedReadings);
	const newData = [];
	const backupDate = new Date().toISOString();

	const stationIdDifferences = [];

	realignedComputedReadings.forEach((computedReading, currentCount) => {
		const { datFile, reading, originalReading } = setDefaultNewData(
			newData,
			computedReading,
			originalData
		);

		const { reverse } = datFile;

		const { stationId } = computedReading;

		const { computedStationId, datIndex } = computedReading;
		const { startStn, endStn } = startEndStationsByDatIndex[datIndex];

		const stationIdDiff = computedStationId - stationId;

		let newStation;
		if (reverse) {
			newStation = endStn - computedStationId;
		} else {
			newStation = computedStationId - startStn;
		}

		verifyAlignedData(currentCount, originalReading, computedReading);

		if (computedStationId === stationId) {
			return;
		}

		createStationIdBackup(reading, backupDate, newStation);

		stationIdDifferences.push(Math.abs(stationIdDiff));
	});
	const newGlobalData = {
		...originalGlobalData,
		MasterLST: {
			...originalGlobalData.MasterLST
		}
	};

	const newDataWithEditFlags = newData.map((datFile, i) => ({
		...datFile,
		isEdited: true,
		...startEndStationsByDatIndex[i]
	}));

	newDataWithEditFlags.forEach(datFile => {
		const { fileName } = datFile;

		newGlobalData.MasterLST[fileName] = datFile;
	});

	return {
		newData: newDataWithEditFlags,
		newGlobalData,
		stats: _createAlignedStationIdsStatistics(
			stationIdDifferences,
			realignedComputedReadings.length
		),
		startEndStationsByDatIndex
	};
};
