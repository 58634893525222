/* eslint-disable import/no-cycle */
import { createActions } from 'redux-actions';

export const buildActions = namespace => {
  const {
    setIsLoadingFilters,
    setIsLoadedFilters,
    setLoadFiltersErrorMessage
  } = createActions(
    {
      SET_IS_LOADING_FILTERS: isLoading => ({ isLoading }),
      SET_IS_LOADED_FILTERS: isLoaded => ({ isLoaded }),
      SET_LOAD_FILTERS_ERROR_MESSAGE: message => ({ message }),
    },
    { prefix: namespace }
  );
  
  return {
    setIsLoadingFilters,
    setIsLoadedFilters,
    setLoadFiltersErrorMessage
  };
};

export default {
  buildActions
};
