export function getCommentsToExcludeRegex(commentExclude) {
	return Object.values(commentExclude)
		.filter(obj => obj.checked)
		.map(obj => obj.pattern);
}

export function removeRegexFromText(commentText, commentRegex) {
	return commentText.replace(new RegExp(commentRegex, 'gi'), '');
}

export function filterCommentText(commentRaw, commentPatternToExclude) {
	// const { commentPatternToExclude } = this.props;
	let comment = commentRaw;
	commentPatternToExclude.forEach(commentRegex => {
		comment = removeRegexFromText(comment, commentRegex);
	});

	return comment;
}
