/* eslint-disable import/prefer-default-export */
export const readDataWrapper = (
	dataStore = {},
	{ targetKey, parentKey = 'NONE', modifierKey = 'NONE' }
) => {
	const targetStore = dataStore[targetKey] || {};
	const parentStore = targetStore[parentKey] || {};
	const dataWrapper = parentStore[modifierKey];

	return dataWrapper;
};

export const writeDataWrapper = (
	dataStore = {},
	{ targetKey, parentKey = 'NONE', modifierKey = 'NONE', dataWrapper = {} }
) => {
	const targetStore = dataStore[targetKey] || {};
	const parentStore = targetStore[parentKey] || {};
	const modifierStore = parentStore[modifierKey] || {};

	return {
		...dataStore,
		[targetKey]: {
			...targetStore,
			[parentKey]: {
				...parentStore,
				[modifierKey]: {
					...modifierStore,
					...dataWrapper
				}
			}
		}
	};
};

export const deleteDataWrapper = (
	dataStore = {},
	{ targetKey, parentKey = 'NONE', modifierKey = 'NONE' }
) => {
	const targetStore = dataStore[targetKey] || {};
	const parentStore = targetStore[parentKey] || {};
	const dataWrapper = parentStore[modifierKey] || {};

	const dataWrapperExists = Object.keys(dataWrapper);
	if (!dataWrapperExists) {
		return dataStore;
	}

	const clone = {
		...dataStore,
		[targetKey]: {
			...targetStore,
			[parentKey]: {
				...parentStore
			}
		}
	};

	delete clone[targetKey][parentKey][modifierKey];

	if (Object.keys(clone[targetKey][parentKey]).length === 0) {
		delete clone[targetKey][parentKey];

		if (Object.keys(clone[targetKey]).length === 0) {
			delete clone[targetKey];
		}
	}

	return clone;
};

export const deleteAllDataWrappers = (dataStore = {}, { targetKey }) => {
	const targetStore = dataStore[targetKey] || {};

	const exists = Object.keys(targetStore);
	if (!exists) {
		return dataStore;
	}

	const clone = {
		...dataStore
	};

	delete clone[targetKey];
	return clone;
};
