/* eslint-disable jsx-a11y/control-has-associated-label */
import React from 'react';
import { withRouter } from 'react-router';
import BoxChat from './Box-Chat';

import './InAppChat.hacky.styles.scss'; // remove this once DnD is re-implemented

const parseRootPath = (urlPath = '') => urlPath.split('/').filter(v => !!v)[0];

const appPropsMap = {
	DEFAULT: {
		appName: 'AIM',
		groupId: '68776e11-b7c7-4c8e-b42e-21c1ddaef0d2'
	},
	CISLINE: {
		appName: 'CISVIEW',
		groupId: '79ec2127-ad55-4134-b95e-4e8cb37fa100',
		wrapperClassName: 'in-app-chat-cisv-overrides'
	},
	DATAINTEGRITY: {
		appName: 'DIT',
		groupId: '68776e11-b7c7-4c8e-b42e-21c1ddaef0d2'
	},
	SCANLINE: {
		appName: 'SCANLINE',
		groupId: '79ec2127-ad55-4134-b95e-4e8cb37fa100'
	},
	LIVELINE: {
		appName: 'AIM',
		groupId: '68776e11-b7c7-4c8e-b42e-21c1ddaef0d2',
		wrapperClassName: 'in-app-chat-liveline-overrides'
	}
};

const selectAppProps = (key = '') => {
	return appPropsMap[key.toUpperCase()] || appPropsMap.DEFAULT;
};

const InAppChat = ({ history, ...remainingProps }) => {
	const rootPath = (
		parseRootPath(history.location.pathname) || ''
	).toUpperCase();

	const appProps = selectAppProps(rootPath);
	return <BoxChat {...remainingProps} {...appProps} />;
};

export default withRouter(InAppChat);
