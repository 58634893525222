/* eslint-disable import/no-cycle */
/* eslint no-param-reassign:0 */

import { combineReducers } from 'redux';
import { routerReducer } from 'react-router-redux';
import assetViewActions from '../actions/actions';
import { SET_ASSET } from '../redux/Map/actionTypes';

import {
	destroyLocalRefs,
	setLocalRefs
} from '../localRefsForAssetViewReducer';
import { APP_VIEW } from '../components/utils/AppView';
import {
	DETAILS_MODE,
	ASSET_PANELS,
	FILTER_ORDER,
	APPROVAL_MODE
} from '../constants';

const initialState = {
	filters: [],
	filtersOrder: { Customers: FILTER_ORDER.alphaAsc },
	selected: { Status: { active: [] } },
	filtersChangeCounter: 0,
	list: [],
	listFrom: 0,
	listSort: 'desc',
	listSortProp: 'date',
	search: {
		autocompleteData: [],
		value: ''
	},
	zoomItem: undefined,
	dataTab: 'assets',
	s3ObjectDownload: null,
	downloadFiles: null,
	height: window.innerHeight,
	showAssetPage: false,
	showDownloads: false,
	isPageOpened: false,
	isExporting: false,
	isCloudSaving: false,
	isCorrelatorSaving: false,
	pageItem: {},
	savedCloud: undefined,
	saveCorrelatorData: undefined,
	testStationCriteria: {
		floor: 9999,
		warning: -850,
		safe: -1200
	},
	multiwireTestStationCriteria: {
		floor: 9999,
		warning: -850,
		safe: -1200
	},
	isManager: false,
	appView: APP_VIEW.map,
	managerCounts: undefined,
	managerSettings: undefined,
	detailsMode: DETAILS_MODE.fullwindow,
	assetPanel: ASSET_PANELS.details,
	groupClassCode: 'charts',
	listTotal: { value: 0 },
	filtersUpdating: false,
	isZippingImages: false,
	zipTemplates: [],
	zipTemplate: undefined,
	isApprovalMode: APPROVAL_MODE.approved,
	exportMessages: []
};

const reducer = (state = initialState, action) => {
	const { payload } = action;
	const { localRefs = {} } = state;
	switch (action.type) {
		case assetViewActions.FILTERS_UPDATING:
			return { ...state, filtersUpdating: true };

		case assetViewActions.RESET_STATE:
			return {
				...state,
				...initialState,
				localRefs: destroyLocalRefs(localRefs)
			};

		case assetViewActions.SET_VIEWPORT:
			return {
				...state,
				localRefs: setLocalRefs(payload, localRefs)
			};

		case assetViewActions.WINDOW_RESIZE:
			return {
				...state,
				height: action.height
			};
		case assetViewActions.SET_ACCESS:
			return { ...state, s3Object: action.s3Object };
		case assetViewActions.SET_IS_MANAGER:
			return { ...state, isManager: payload };
		case assetViewActions.SET_APP_VIEW:
			return { ...state, appView: payload, listFrom: 0 };
		case assetViewActions.SET_DOWNLOAD_ACCESS:
			return { ...state, s3ObjectDownload: action.s3Object };

		case assetViewActions.SET_DOWNLOADS:
			return { ...state, downloadFiles: action.data };
		case assetViewActions.SELECT_ASSET:
			return {
				...state,
				modal: { ...state.modal, id: action.id, from: action.from }
			};
		case assetViewActions.FILTERS_UPDATED:
			return {
				...state,
				filters: [...action.filters],
				filtersChangeCounter: state.filtersChangeCounter + 1,
				filtersUpdating: false
			};
		case assetViewActions.LIST_UPDATED:
			return {
				...state,
				list: action.list,
				listTotal: action.total,
				listFrom: action.list.length,
				filtersUpdating: false
			};
		case assetViewActions.SET_LIST_FROM:
			return { ...state, listFrom: payload };
		case assetViewActions.FILTERS_REFINED: {
			return { ...state, filters: action.filters };
		}
		case assetViewActions.SHOW_IMAGE:
			return { ...state, modal: { title: 'Image', url: action.url } };
		case assetViewActions.SET_ASSETS_REFRESH:
			return { ...state, modal: { refresh: true } };
		case SET_ASSET:
		case assetViewActions.SHOW_ASSETS:
			return {
				...state,
				modal: {
					key: action.key,
					title: action.key,
					items: action.items,
					id: action.id
				}
			};
		case assetViewActions.HIDE_MODAL:
			delete state.modal;
			return { ...state };
		case assetViewActions.MAP_ITEM:
			return { ...state, zoomItem: action.item };
		case assetViewActions.GOT_MAP_ITEM:
			return { ...state, zoomItem: undefined };
		case assetViewActions.SEARCH_CHANGED:
			return { ...state, search: { ...state.search, value: action.value } };
		case assetViewActions.SEARCH_UPDATED:
			return {
				...state,
				search: { ...state.search, autocompleteData: action.values }
			};
		case assetViewActions.SHOW_DOWNLOADS_TAB:
			return { ...state, dataTab: 'downloads' };
		case assetViewActions.SHOW_ASSETS_TAB:
			return { ...state, dataTab: 'assets' };
		case assetViewActions.UPDATE_PAGE_ITEM:
			return {
				...state,
				pageItem: payload
			};
		case assetViewActions.SET_ASSETCARD_ASSET:
			return {
				...state,
				showAssetPage: true,
				isPageOpened: true,
				pageItem: { ...action.values },
				zoomItem: state.zoomItem || action.zoomItem
			};
		case assetViewActions.CLEAR_CARD:
			return {
				...state,
				showAssetPage: false,
				showDownloads: false,
				isPageOpened: false,
				pageItem: {},
				modal: { items: [] }
			};
		case assetViewActions.EXPORTING:
			return { ...state, isExporting: true, exportMessages: [] };
		case assetViewActions.EXPORTED:
			return { ...state, isExporting: false, exportMessages: [] };
		case assetViewActions.CLOUD_SAVING:
			return { ...state, isCloudSaving: true };
		case assetViewActions.CLOUD_SAVED:
			return { ...state, isCloudSaving: false, savedCloud: action.values };
		case assetViewActions.ZIPPING_IMAGES:
			return { ...state, isZippingImages: true };
		case assetViewActions.ZIPPED_IMAGES:
			return { ...state, isZippingImages: false };
		case assetViewActions.SAVING_CORRELATOR:
			return { ...state, isCorrelatorSaving: true };
		case assetViewActions.SAVED_CORRELATOR:
			return {
				...state,
				isCorrelatorSaving: false,
				saveCorrelatorData: action.values
			};
		case assetViewActions.SET_LIST_ORDER:
			return {
				...state,
				listSort: action.listSort,
				listSortProp: action.listSortProp,
				list: [],
				listFrom: 0
			};
		case assetViewActions.SET_CRITERIA:
			return {
				...state,
				[payload.criteriaName]: payload.criteria,
				selected: payload.selected
			};
		case assetViewActions.SET_MANAGER_COUNTS:
			return {
				...state,
				managerCounts: payload
			};

		case assetViewActions.SET_MANAGER_SETTINGS:
			return {
				...state,
				managerSettings: payload.settings
			};
		case assetViewActions.SET_DETAILS_MODE:
			return {
				...state,
				detailsMode: payload
			};

		case assetViewActions.SET_ASSET_PANEL:
			return {
				...state,
				assetPanel: payload
			};
		case assetViewActions.SET_GROUP_CLASS_CODE:
			return {
				...state,
				groupClassCode: payload
			};
		case assetViewActions.SET_SELECTION:
			return {
				...state,
				listFrom: 0,
				selected: payload
			};

		case assetViewActions.SET_FILTERS_ORDER:
			return {
				...state,
				filtersOrder: payload
			};

		case assetViewActions.SET_ZIP_TEMPLATES:
			return {
				...state,
				zipTemplates: payload
			};
		case assetViewActions.SET_ZIP_TEMPLATE:
			return {
				...state,
				zipTemplate: payload
			};
		case assetViewActions.SET_APPROVAL_MODE:
			return { ...state, isApprovalMode: payload };
		case assetViewActions.TOGGLE_LIST_PHOTO_CHECKER:
			return { ...state, listPhotoCheckerEnabled: payload };
		case assetViewActions.ADD_EXPORT_MESSAGE:
			return { ...state, exportMessages: [...state.exportMessages, payload] };
		default:
			return state;
	}
};

export default class AssetViewReducers {
	reducer = reducer;

	app = combineReducers({
		filter: this.reducer,
		routing: routerReducer
	});
}
