import React from 'react';
import moment from 'moment';

export const addMessageBox = (message, successState) => ({
	type: 'CISV_MESSAGE_BOX_ADD',
	message,
	successState
});

export const closeMessageBox = id => ({
	type: 'CISV_MESSAGE_BOX_CLOSE',
	id
});

// TODO: I'm not a huge fan of injecting components into store - refactor
export const displayDatSaveMessage = (err, res, item) => dispatch => {
	const errorExists = !!err;
	let message;
	let metadata;
	const timestamp = moment().format('LTS');

	if (item) {
		const { fileName, files } = item;
		let { fileNames } = item;

		let fileNamesText;
		if (files) {
			fileNames = files.map(f => f.name || f.fileName);
		}
		if (fileName) {
			fileNamesText = `"${fileName}"`;
		} else if (fileNames) {
			fileNamesText = `"${fileNames.join('", "')}"`; // Create quotes around each fileName
		}
		metadata = fileNamesText
			? `Data/Metadata Related to DAT File(s) ${fileNamesText} (at ${timestamp})`
			: null;
	}
	if (errorExists) {
		const errorText =
			err.responseBody ||
			err.responseText ||
			err.statusText ||
			err.message ||
			err;
		message = (
			<div>
				<b>Error When Processing {metadata}</b>
				<br />
				<i>{errorText}</i>
			</div>
		);
	} else if (res) {
		message = (
			<b>
				{res} (at {timestamp})
			</b>
		);
	} else {
		message = <b>{metadata} Saved Successfully</b>;
	}

	const successState = errorExists ? 'danger' : 'info';
	dispatch(addMessageBox(message, successState));
};

export const displayAdminRelatedMessage = (
	err,
	res,
	item,
	calledFromPage
) => dispatch => {
	const errorExists = !!err;
	let message;
	if (err) {
		const errorText =
			err.responseBody ||
			err.responseText ||
			err.responseJSON ||
			err.statusText ||
			err.message ||
			err;
		message = (
			<div>
				<b>Error on {calledFromPage} Job </b>
				<br />
				<i>{errorText}</i>
			</div>
		);
	} else if (res) {
		message = <b>{res}</b>;
	} else {
		message = <b>Job: {calledFromPage}d Successfully</b>;
	}

	const successState = errorExists ? 'danger' : 'info';
	dispatch(addMessageBox(message, successState));
};
