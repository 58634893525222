/* eslint-disable import/no-cycle */
/* eslint-disable import/prefer-default-export */
import _ from 'lodash';
import {
	getApplications,
	updateApplication,
	deleteApplication,
	insertApplication
} from '../../../../api/applications';
import {
	setApplications,
	setSelectedApplication,
	setSelectedApplicationChangesClone,
	setErrorMessageSaveEditedApplication,
	setIsSavingEditedApplication
} from './actions';

import {
	selectSelectedApplicationChangesClone,
	selectHasChanges,
	selectApplications
} from '../selectors';

import { createNewApplicationObject } from '../util';

import { selectRootSliceAdmin } from '../../../Main/selectors';

const loadApplications = () => dispatch => {
	getApplications().then(apps => {
		dispatch(setApplications(apps));
	});
};

const loadApplicationsIfMissing = () => (dispatch, getState) => {
	const stateAdmin = selectRootSliceAdmin(getState());
	const applications = selectApplications(stateAdmin);
	if (applications.length === 0) {
		dispatch(loadApplications());
	}
};

const thunkSetSelectedApplication = application => dispatch => {
	const clone = application ? _.cloneDeep(application) : application;
	dispatch(setSelectedApplication(application));
	dispatch(setSelectedApplicationChangesClone(clone));
};

const thunkSetErrorMessageSaveEditedApplication = error => dispatch => {
	if (typeof error === 'string') {
		dispatch(setErrorMessageSaveEditedApplication(error));
	} else if (error instanceof Object) {
		const { message } = error;
		if (message) {
			dispatch(setErrorMessageSaveEditedApplication(message));
		} else {
			dispatch(
				setErrorMessageSaveEditedApplication('An unknown error occured')
			);
		}
	} else {
		dispatch(setErrorMessageSaveEditedApplication('An unknown error occured'));
	}
};

const _thunkReplaceApplicationWithUpdatedApplication = (
	updatedApplication = {}
) => (dispatch, getState) => {
	if (updatedApplication.id) {
		const stateAdmin = selectRootSliceAdmin(getState());
		const newApplications = [...selectApplications(stateAdmin)];

		const currentIndex = newApplications.findIndex(
			app => app.id === updatedApplication.id
		);
		if (currentIndex > -1) {
			newApplications[currentIndex] = updatedApplication;
		} else {
			newApplications.push(updatedApplication);
		}

		dispatch(setApplications(newApplications));
	}
};

const _thunkRemoveApplication = (updatedApplication = {}) => (
	dispatch,
	getState
) => {
	if (updatedApplication.id) {
		const stateAdmin = selectRootSliceAdmin(getState());
		const newApplications = [...selectApplications(stateAdmin)];

		const currentIndex = newApplications.findIndex(
			app => app.id === updatedApplication.id
		);
		if (currentIndex > -1) {
			newApplications.splice(currentIndex, 1);
		}

		dispatch(setApplications(newApplications));
	}
};

const thunkSaveEditedApplication = () => (dispatch, getState) => {
	const stateAdmin = selectRootSliceAdmin(getState());
	const editedApplication = selectSelectedApplicationChangesClone(stateAdmin);
	const hasChanges = selectHasChanges(stateAdmin);

	if (hasChanges && editedApplication) {
		dispatch(setErrorMessageSaveEditedApplication(undefined));
		dispatch(setIsSavingEditedApplication(true));
		let promise;
		if (editedApplication.id) {
			promise = updateApplication({ application: editedApplication });
		} else {
			promise = insertApplication({ application: editedApplication });
		}
		promise
			.then(updatedApplication => {
				dispatch(
					_thunkReplaceApplicationWithUpdatedApplication(updatedApplication)
				);
				dispatch(thunkSetSelectedApplication(updatedApplication));
			})
			.catch(err => {
				dispatch(thunkSetErrorMessageSaveEditedApplication(err));
			})
			.finally(() => {
				dispatch(setIsSavingEditedApplication(false));
			});
	}
};

const thunkDeleteApplication = () => (dispatch, getState) => {
	// eslint-disable-next-line no-alert
	const userRes = window.prompt("Please confirm delete by typing in 'Delete'");
	if (userRes !== 'Delete') {
		return;
	}
	const stateAdmin = selectRootSliceAdmin(getState());
	const editedApplication = selectSelectedApplicationChangesClone(stateAdmin);

	if (editedApplication && editedApplication.id) {
		dispatch(setErrorMessageSaveEditedApplication(undefined));
		dispatch(setIsSavingEditedApplication(true));

		deleteApplication({ application: editedApplication })
			.then(deletedApplication => {
				dispatch(_thunkRemoveApplication(deletedApplication));
				dispatch(thunkSetSelectedApplication(undefined));
			})
			.catch(err => {
				dispatch(thunkSetErrorMessageSaveEditedApplication(err));
			})
			.finally(() => {
				dispatch(setIsSavingEditedApplication(false));
			});
	}
};

const thunkInitNewApplication = () => dispatch => {
	dispatch(thunkSetSelectedApplication(createNewApplicationObject()));
};

export {
	loadApplications,
	loadApplicationsIfMissing,
	thunkSetSelectedApplication,
	thunkSaveEditedApplication,
	thunkDeleteApplication,
	thunkInitNewApplication
};
