/* eslint-disable import/no-cycle */
import {
	SET_TEMPLATE,
	SET_SOCKETTE,
	ADD_WEB_SOCKET_NEXT,
	SET_WEB_SOCKET_CONNECTION_ID,
	SET_S3,
	SET_S3_LOCATION,
	SET_FILES,
	ADD_FILES_COUNT,
	SUBTRACT_FILES_COUNT,
	SET_ACTION,
	SET_SELECTED_FILE,
	SET_DEFAULT_CHECKED,
	SET_IS_INIT,
	SET_IS_SHOWING,
	SET_VERIFY_UPLOAD,
	SET_SUBMIT_FILE_EXTERNAL,
	SET_ON_CLOSE,
	SET_WEB_SOCKET,
	SET_IS_EDITING,
	SET_EDITING_RESULTS
} from './actions';

import { IntakeAction } from '../containers/utils';

const defaultState = {
	template: undefined,
	isShowing: false,
	sockette: undefined,
	webSocket: undefined,
	wsNext: 0,
	wsReveived: 0,
	connectionId: undefined,
	s3: undefined,
	folder: undefined,
	bucket: undefined,
	files: {},
	filesCount: 0,
	selectedFile: undefined,
	action: IntakeAction.UPLOAD,
	defaultChecked: true,
	isInit: true,
	verifyUpload: undefined,
	submitFileExternal: undefined,
	onClose: undefined,
	isEditing: false,
	editingResults: undefined
};

const reducer = (state = defaultState, action) => {
	const { type, payload } = action;
	switch (type) {
		case SET_TEMPLATE:
			return { ...state, template: payload };
		case SET_IS_SHOWING:
			return { ...state, isShowing: payload };
		case SET_SOCKETTE:
			return { ...state, sockette: payload, webSocket: undefined };
		case SET_WEB_SOCKET:
			return { ...state, webSocket: payload };
		case ADD_WEB_SOCKET_NEXT:
			return { ...state, wsNext: state.wsNext + 1 };
		case SET_WEB_SOCKET_CONNECTION_ID:
			return { ...state, connectionId: payload };
		case SET_S3:
			return { ...state, s3: payload };
		case SET_S3_LOCATION:
			return { ...state, folder: payload.folder, bucket: payload.bucket };
		case SET_FILES:
			return { ...state, files: payload };
		case ADD_FILES_COUNT:
			return { ...state, filesCount: state.filesCount + 1 };
		case SUBTRACT_FILES_COUNT:
			return { ...state, filesCount: state.filesCount - 1 };
		case SET_ACTION:
			return { ...state, action: payload };
		case SET_SELECTED_FILE:
			return { ...state, selectedFile: payload };
		case SET_DEFAULT_CHECKED:
			return { ...state, defaultChecked: payload };
		case SET_IS_INIT:
			return { ...state, isInit: payload };
		case SET_VERIFY_UPLOAD:
			return { ...state, verifyUpload: payload };
		case SET_SUBMIT_FILE_EXTERNAL:
			return { ...state, submitFileExternal: payload };
		case SET_ON_CLOSE:
			return { ...state, onClose: payload };
		case SET_IS_EDITING:
			return { ...state, isEditing: payload };
		case SET_EDITING_RESULTS:
			return { ...state, editingResults: payload };
		default:
			return state;
	}
};

export default reducer;
