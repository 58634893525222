"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = exports.createSortText = exports.createSortNumeric = exports.createSortDate = exports.TEST = exports.PARTS = void 0; // helpers

var isDefined = function isDefined(v) {
  return v !== undefined && v !== null;
};

var isDate = function isDate(v) {
  return isDefined(v) && !Number.isNaN(new Date(v).getTime());
};

var toString = function toString(v) {
  return isDefined(v) && v.toString ? v.toString() : v;
};

var toUpperCase = function toUpperCase(v) {
  return typeof v === 'string' ? v.toUpperCase() : v;
};

var isNum = function isNum(v) {
  try {
    return isDefined(v) && !Number.isNaN(Number.parseFloat(v));
  } catch (e) {
    return false;
  }
};

var toNumber = function toNumber(v) {
  return isNum(v) ? Number.parseFloat(v) : v;
};

var toDate = function toDate(v) {
  return isDate(v) ? new Date(v) : v;
}; // CORE


var sortNullAndUndefinedNullFirst = function sortNullAndUndefinedNullFirst(v1, v2) {
  if (v1 === null && v2 === undefined) {
    return -1; // return null first
  }

  if (v2 === null && v1 === undefined) {
    return 1; // return null first
  }

  return 0;
};

var dumbSortAsc = function dumbSortAsc(v1, v2) {
  if (v1 < v2) {
    return -1;
  }

  if (v2 < v1) {
    return 1;
  }

  return 0;
};

var dumbSortDesc = function dumbSortDesc(v1, v2) {
  return dumbSortAsc(v1, v2) * -1;
}; // PURE
// PROTECT


var protectDefinedAsc = function protectDefinedAsc(_v1, _v2) {
  var _ref = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : {},
      _ref$getValue = _ref.getValue,
      getValue = _ref$getValue === void 0 ? function (v) {
    return v;
  } : _ref$getValue,
      _ref$f = _ref.f,
      f = _ref$f === void 0 ? function () {
    return 0;
  } : _ref$f;

  var v1 = getValue(_v1);
  var v2 = getValue(_v2);

  if (isDefined(v1) && isDefined(v2)) {
    return f(v1, v2);
  }

  if (!isDefined(v1) && !isDefined(v2)) {
    return sortNullAndUndefinedNullFirst(v1, v2); // since sort on flat values always return undefined last, we will always return undefined after null
  }

  if (isDefined(v1)) {
    return -1; // if arrayitem === undefined, protectDefinedAsc will not be called and undefined values will always be last
  }

  if (isDefined(v2)) {
    return 1; // if arrayitem === undefined, protectDefinedAsc will not be called and undefined values will always be last
  }

  return 0; // if arrayitem === undefined, protectDefinedAsc will not be called and undefined values will always be last
};

var protectDefinedDesc = function protectDefinedDesc(_v1, _v2) {
  var _ref2 = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : {},
      _ref2$invalidLast = _ref2.invalidLast,
      invalidLast = _ref2$invalidLast === void 0 ? false : _ref2$invalidLast,
      _ref2$getValue = _ref2.getValue,
      getValue = _ref2$getValue === void 0 ? function (v) {
    return v;
  } : _ref2$getValue,
      _ref2$f = _ref2.f,
      f = _ref2$f === void 0 ? function () {
    return 0;
  } : _ref2$f;

  var v1 = getValue(_v1);
  var v2 = getValue(_v2);

  if (isDefined(v1) && isDefined(v2)) {
    return f(v1, v2);
  }

  if (!isDefined(v1) && !isDefined(v2)) {
    return sortNullAndUndefinedNullFirst(v1, v2); // since sort on flat values always return undefined last, we will always return undefined after null
  }

  if (isDefined(v1)) {
    return invalidLast ? -1 : 1; // if arrayitem === undefined, protectDefinedDesc will not be called and undefined values will always be lastast
  }

  if (isDefined(v2)) {
    return invalidLast ? 1 : -1; // if arrayitem === undefined, protectDefinedDesc will not be called and undefined values will always be last
  }

  return 0; // if arrayitem === undefined, protectDefinedDesc will not be called and undefined values will always be last
}; // EXPORTS


var createSortText = function createSortText() {
  var direction = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : 'ASC';

  var _ref3 = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {},
      _ref3$getValue = _ref3.getValue,
      getValue = _ref3$getValue === void 0 ? function (i) {
    return i;
  } : _ref3$getValue,
      _ref3$caseSensitive = _ref3.caseSensitive,
      caseSensitive = _ref3$caseSensitive === void 0 ? true : _ref3$caseSensitive,
      _ref3$invalidLast = _ref3.invalidLast,
      invalidLast = _ref3$invalidLast === void 0 ? false : _ref3$invalidLast;

  return function (i1, i2) {
    var v1 = toString(getValue(i1));
    var v2 = toString(getValue(i2));

    if (!caseSensitive) {
      v1 = toUpperCase(v1);
      v2 = toUpperCase(v2);
    }

    var protectDefined = protectDefinedAsc;
    var dumbSort = dumbSortAsc;

    if (direction === 'DESC') {
      protectDefined = protectDefinedDesc;
      dumbSort = dumbSortDesc;
    }

    return protectDefined(v1, v2, {
      invalidLast: invalidLast,
      f: function f(_v1, _v2) {
        return dumbSort(_v1, _v2);
      }
    });
  };
};

exports.createSortText = createSortText;

var createSortNumeric = function createSortNumeric() {
  var direction = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : 'ASC';

  var _ref4 = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {},
      _ref4$getValue = _ref4.getValue,
      getValue = _ref4$getValue === void 0 ? function (i) {
    return i;
  } : _ref4$getValue,
      _ref4$caseSensitive = _ref4.caseSensitive,
      caseSensitive = _ref4$caseSensitive === void 0 ? false : _ref4$caseSensitive,
      _ref4$invalidLast = _ref4.invalidLast,
      invalidLast = _ref4$invalidLast === void 0 ? false : _ref4$invalidLast;

  return function (i1, i2) {
    var v1 = toNumber(getValue(i1));
    var v2 = toNumber(getValue(i2));
    var protectDefined = protectDefinedAsc;
    var dumbSort = dumbSortAsc;

    if (direction === 'DESC') {
      protectDefined = protectDefinedDesc;
      dumbSort = dumbSortDesc;
    }

    if (isNum(v1) && isNum(v2)) {
      return protectDefined(v1, v2, {
        invalidLast: invalidLast,
        f: function f(_v1, _v2) {
          return dumbSort(_v1, _v2);
        }
      });
    }

    if (isNum(v1)) {
      return direction === 'DESC' ? 1 : -1;
    }

    if (isNum(v2)) {
      return direction === 'DESC' ? -1 : 1;
    }

    return createSortText(direction, {
      getValue: getValue,
      caseSensitive: caseSensitive
    })(i1, i2);
  };
};

exports.createSortNumeric = createSortNumeric;

var createSortDate = function createSortDate() {
  var direction = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : 'ASC';

  var _ref5 = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {},
      _ref5$getValue = _ref5.getValue,
      getValue = _ref5$getValue === void 0 ? function (i) {
    return i;
  } : _ref5$getValue,
      caseSensitive = _ref5.caseSensitive,
      _ref5$invalidLast = _ref5.invalidLast,
      invalidLast = _ref5$invalidLast === void 0 ? false : _ref5$invalidLast;

  return function (i1, i2) {
    var v1 = toDate(getValue(i1));
    var v2 = toDate(getValue(i2));
    var protectDefined = protectDefinedAsc;
    var dumbSort = dumbSortAsc;

    if (direction === 'DESC') {
      protectDefined = protectDefinedDesc;
      dumbSort = dumbSortDesc;
    }

    if (isDate(v1) && isDate(v2)) {
      return protectDefined(v1, v2, {
        f: function f(_v1, _v2) {
          return dumbSort(_v1, _v2);
        }
      });
    }

    if (isDate(v1)) {
      return direction === 'DESC' && !invalidLast ? 1 : -1;
    }

    if (isDate(v2)) {
      return direction === 'DESC' && !invalidLast ? -1 : 1;
    }

    return createSortText(direction, {
      getValue: getValue,
      caseSensitive: caseSensitive,
      invalidLast: invalidLast
    })(i1, i2);
  };
};

exports.createSortDate = createSortDate;
var TEST = {
  dumbSortAsc: dumbSortAsc,
  dumbSortDesc: dumbSortDesc,
  protectDefinedAsc: protectDefinedAsc,
  protectDefinedDesc: protectDefinedDesc,
  createSortText: createSortText,
  createSortNumeric: createSortNumeric,
  createSortDate: createSortDate
}; // parts can be used to create your own customized highlevel sorts

exports.TEST = TEST;
var PARTS = {
  dumbSortAsc: dumbSortAsc,
  dumbSortDesc: dumbSortDesc,
  protectDefinedAsc: protectDefinedAsc,
  protectDefinedDesc: protectDefinedDesc
};
exports.PARTS = PARTS;
var _default = {
  TEST: TEST,
  PARTS: PARTS,
  createSortText: createSortText,
  createSortNumeric: createSortNumeric,
  createSortDate: createSortDate
};
exports["default"] = _default;