import React, { useMemo } from 'react';

import HelpButton from './components/HelpButton';
import EmailCustomerSupport from './components/EmailCustomerSupport';
import InAppChat from './components/InAppChat';

import './CustomerSupport.scss';

const isBusinessHours = () => {
	const date = new Date();
	const isWeekday = date.getDay() > 0 && date.getDay() <= 5;
	const isWorkingHours = date.getHours() >= 8 && date.getHours() < 17;
	return isWeekday && isWorkingHours;
};

const ContactButton = () => {
	const isBusinessOpen = useMemo(() => isBusinessHours(), []);
	return isBusinessOpen ? <InAppChat /> : <EmailCustomerSupport />;
};

const CustomerSupport = () => (
	<div style={{ display: 'inline-block' }}>
		<div className="customer-support-flex">
			<ContactButton />
			<HelpButton />
		</div>
	</div>
);

export default CustomerSupport;
