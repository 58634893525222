import { selectConvertedReadings } from '../selectors/readings';

const NS = 'CISV_STATION_ALIGNMENT_';

export const setSelectedDataForStationAlignment = (survey, tableType) => ({
	type: 'CISV_STATION_ALIGNMENT_SET_DAT_FILE',
	survey,
	tableType
});

export const setSelectedSurveyIsLinkedForStationAlignment = isLinked => ({
	type: 'CISV_STATION_ALIGNMENT_SET_SELECTED_SURVEY_IS_LINKED',
	isLinked,
	tableType: 'static'
});

export const selectReadingForStationAlignment = (
	tableType,
	readingIndex,
	readingObject
) => (dispatch, getState) => {
	const convertedReadings = selectConvertedReadings(getState());
	return dispatch({
		type: 'CISV_STATION_ALIGNMENT_SET_READING_SELECTED',
		tableType,
		readingIndex,
		readingObject,
		convertedReadings
	});
};

export const resetSurveyAlignment = data => {
	return {
		type: 'CISV_STATION_ALIGNMENT_RESET',
		data
	};
};

export const setEditableSurvey = survey => {
	return {
		type: 'CISV_STATION_ALIGNMENT_SET_EDITABLE_SURVEY',
		survey
	};
};

export const SET_MAP_SELECTED = `${NS}SET_MAP_SELECTED`;
export const setMapSelected = (tableType, selected) => {
	return {
		type: SET_MAP_SELECTED,
		payload: { tableType, selected }
	};
};
