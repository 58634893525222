const injectSelectAutoCorrectReadingsFromLibrary = L => {
	L.Control.SelectAutoCorrectReadings = L.Control.extend({
		options: {},
		onAdd(map) {
			this.map = map;

			const drawControl = new L.Control.Draw({
				draw: {
					marker: false,
					polygon: false,
					polyline: false,
					rectangle: true,
					circle: false
				},
				edit: false
			});

			this.map.addControl(drawControl);

			this.map.on(
				L.Draw.Event.CREATED,
				this.options.removeAutoCorrectionsWithinShape
			);

			this.container = drawControl._container;
			this.autoCorrectEditControl = drawControl;

			drawControl._container.style.visibility = 'hidden';

			return this.container;
		},
		getAutoCorrectionDrawShape() {
			return this.autoCorrectEditControl._toolbars.draw._modes.rectangle
				.handler;
		},
		isAutoCorrectionDrawEnabled() {
			try {
				return this.getAutoCorrectionDrawShape()._enabled || false;
			} catch (e) {
				return false;
			}
		},
		enableAutoCorrectDrawShape() {
			try {
				const drawShape = this.getAutoCorrectionDrawShape();
				drawShape.enable();
			} finally {
				/* continue regardless of error */
			}
		},
		disableAutoCorrectDrawShape() {
			try {
				const drawShape = this.getAutoCorrectionDrawShape();
				drawShape.disable();
			} finally {
				/* continue regardless of error */
			}
		}
	});

	L.control.selectautocorrectedreadings = options => {
		return new L.Control.SelectAutoCorrectReadings(options);
	};
};

export default injectSelectAutoCorrectReadingsFromLibrary;
