import aipurls, { URL, TAB_PREFIX, TAB_SUFFIX } from './configs.urls';

const getAipUrls = () => {
	return aipurls;
};

function getURL(path) {
	return `${URL}/${path}`;
}

function getTabPrefixSuffix() {
	return [TAB_PREFIX, TAB_SUFFIX];
}

export default getAipUrls;

export { getURL, getTabPrefixSuffix };
