function executeLastCall(func, wait = 1) {
	let timeout;

	function wrapperFunction(...args) {
		const context = this;
		// eslint-disable-next-line prefer-rest-params
		clearTimeout(timeout);

		function delayedCall() {
			func.bind(context)(...args);
		}

		timeout = setTimeout(delayedCall, wait);
	}

	return wrapperFunction;
}

export default executeLastCall;
