/* eslint-disable no-unused-vars */
/* eslint-disable no-console */
/* eslint-disable no-use-before-define */
import { createActions } from 'redux-actions';

const {
	setReadingsDownloadMessage,
	clearReadingsDownloadMessages,
	setSurveysDownloadMessage,
	clearSurveysDownloadMessages,
	toggleDataDownloadMessagesCollapse
} = createActions({
	SET_READINGS_DOWNLOAD_MESSAGE: (key, message) => ({ key, message }),
	CLEAR_READINGS_DOWNLOAD_MESSAGES: () => ({}),
	SET_SURVEYS_DOWNLOAD_MESSAGE: (key, message) => ({ key, message }),
	CLEAR_SURVEYS_READINGS_DOWNLOAD_MESSAGES: () => ({}),
	TOGGLE_DATA_DOWNLOAD_MESSAGES_COLLAPSE: () => ({})
});

export {
	setReadingsDownloadMessage,
	clearReadingsDownloadMessages,
	setSurveysDownloadMessage,
	clearSurveysDownloadMessages,
	toggleDataDownloadMessagesCollapse
};
