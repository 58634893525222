export const selectedReadingRowHighlightColor = '#d7a2e0';

const DEFAULT_COLORS = {
	PREPROCESSED_ON: '#549154', // '#5A5A5A', // .4 Alpha = 66
	PREPROCESSED_OFF: '#497391', // '#5A5A5A', // .4 Alpha = 66
	SELECTED: selectedReadingRowHighlightColor,
	MINOR_ON: '#71c771',
	MINOR_OFF: '#5e93b8',
	NEW_ON: '#2ca02c',
	NEW_OFF: '#1f77b4',
	MINOR_AC_ON: '#ffe69e',
	MINOR_AC_OFF: '#b99bc9',
	NEW_AC_ON: '#fcba03',
	NEW_AC_OFF: '#a503fc',
	HIGHLIGHTED: '#800080'
};

// traces like "nextOn", etc
const minorTracesOptions = {
	dash: 'dot'
};

const highlightOptions = {
	size: 15
};

const selectedRowOptions = {
	size: 10
};

export const DEFAULT_TRACE_INFO_BY_NAME = {
	preprocessedOnTrace: {
		color: DEFAULT_COLORS.PREPROCESSED_ON,
		display: 'Preprocessed On',
		options: { width: 1.5, dash: 'dashdot' }
	},
	preprocessedOffTrace: {
		color: DEFAULT_COLORS.PREPROCESSED_OFF,
		display: 'Preprocessed Off',
		options: { width: 1.5, dash: 'dashdot' }
	},
	oldOnTrace: { color: DEFAULT_COLORS.MINOR_ON, display: 'Original On' },
	oldOffTrace: { color: DEFAULT_COLORS.MINOR_OFF, display: 'Original Off' },
	newOnTrace: { color: DEFAULT_COLORS.NEW_ON, display: 'Current On' },
	newOffTrace: { color: DEFAULT_COLORS.NEW_OFF, display: 'Current Off' },
	oldAcOnTrace: {
		color: DEFAULT_COLORS.MINOR_AC_ON,
		display: 'Original AC On',
		yaxis: 'y2'
	},
	oldAcOffTrace: {
		color: DEFAULT_COLORS.MINOR_AC_OFF,
		display: 'Original AC Off',
		yaxis: 'y2'
	},
	newAcOnTrace: {
		color: DEFAULT_COLORS.NEW_AC_ON,
		display: 'Current AC On',
		yaxis: 'y2'
	},
	newAcOffTrace: {
		color: DEFAULT_COLORS.NEW_AC_OFF,
		display: 'Current AC Off',
		yaxis: 'y2'
	},
	nextOnTrace: {
		color: DEFAULT_COLORS.MINOR_ON,
		display: 'Next On',
		options: minorTracesOptions
	},
	nextOffTrace: {
		color: DEFAULT_COLORS.MINOR_OFF,
		display: 'Next Off',
		options: minorTracesOptions
	},
	nextAcOnTrace: {
		color: DEFAULT_COLORS.MINOR_AC_ON,
		display: 'Next AC On',
		options: minorTracesOptions,
		yaxis: 'y2'
	},
	nextAcOffTrace: {
		color: DEFAULT_COLORS.MINOR_AC_OFF,
		display: 'Next AC Off',
		options: minorTracesOptions,
		yaxis: 'y2'
	},
	previousOnTrace: {
		color: DEFAULT_COLORS.MINOR_ON,
		display: 'Previous On',
		options: minorTracesOptions
	},
	previousOffTrace: {
		color: DEFAULT_COLORS.MINOR_OFF,
		display: 'Previous Off',
		options: minorTracesOptions
	},
	previousAcOnTrace: {
		color: DEFAULT_COLORS.MINOR_AC_ON,
		display: 'Previous AC On',
		options: minorTracesOptions,
		yaxis: 'y2'
	},
	previousAcOffTrace: {
		color: DEFAULT_COLORS.MINOR_AC_OFF,
		display: 'Previous AC Off',
		options: minorTracesOptions,
		yaxis: 'y2'
	},
	readingRowSelectedOnTraces: {
		color: DEFAULT_COLORS.SELECTED,
		display: 'Selected On',
		options: selectedRowOptions
	},
	readingRowSelectedOffTraces: {
		color: DEFAULT_COLORS.SELECTED,
		display: 'Selected Off',
		options: selectedRowOptions
	},
	readingRowSelectedAcOnTraces: {
		color: DEFAULT_COLORS.SELECTED,
		display: 'Selected On',
		options: selectedRowOptions,
		yaxis: 'y2'
	},
	readingRowSelectedAcOffTraces: {
		color: DEFAULT_COLORS.SELECTED,
		display: 'Selected Off',
		options: selectedRowOptions,
		yaxis: 'y2'
	},
	highlightedOnTrace: {
		color: DEFAULT_COLORS.NEW_ON,
		display: 'Highlighted On',
		options: highlightOptions
	},
	highlightedOffTrace: {
		color: DEFAULT_COLORS.NEW_OFF,
		display: 'Highlighted Off',
		options: highlightOptions
	},
	highlightedAcOnTrace: {
		color: DEFAULT_COLORS.NEW_AC_ON,
		display: 'Highlighted On',
		options: highlightOptions,
		yaxis: 'y2'
	},
	highlightedAcOffTrace: {
		color: DEFAULT_COLORS.NEW_AC_OFF,
		display: 'Highlighted Off',
		options: highlightOptions,
		yaxis: 'y2'
	}
	// These traces already have options in other places
	// oldOnCommentTrace,
	// minCriteriaLine: minCriteriaLineTrace,
	// maxCriteriaLine: maxCriteriaLineTrace,
};

export const showCommentsOptions = [
	{
		value: 'always',
		label: 'Always'
	},
	{
		value: 'optimum-zoom',
		label: 'Optimum Zoom'
	},
	{
		value: 'never',
		label: 'Never'
	}
];

export const showOriginalLineOptions = [
	{
		value: 'always',
		label: 'Always'
	},
	{
		value: 'optimum-zoom',
		label: 'Optimum Zoom'
	}
];

export const OPTIMUM_ZOOM_IN_FEET = 1000;

export const TRACES_TO_ALWAYS_SHOW = [
	'newOnTrace',
	'newOffTrace',
	'nextOnTrace',
	'nextOffTrace',
	'previousOnTrace',
	'previousOffTrace',
	'newAcOnTrace',
	'newAcOffTrace',
	'nextAcOnTrace',
	'nextAcOffTrace',
	'previousAcOnTrace',
	'previousAcOffTrace',
	'minCriteriaLine',
	'maxCriteriaLine'
];

export const TRACES_TO_SHOW_IN_OPTIMUM_ZOOM = [
	'preprocessedOnTrace',
	'preprocessedOffTrace',
	'oldOnTrace',
	'oldOffTrace',
	'oldAcOnTrace',
	'oldAcOffTrace',
	'readingRowSelectedOnTraces',
	'readingRowSelectedOffTraces',
	'highlightedOnTrace',
	'highlightedOffTrace',
	'readingRowSelectedAcOnTraces',
	'readingRowSelectedAcOffTraces',
	'highlightedAcOnTrace',
	'highlightedAcOffTrace'
];

export const COMMENTS_TRACES = ['oldOnCommentTrace'];

export const DEFAULT_MIN_Y_RANGE_MV = -2100;
export const DEFAULT_MIN_Y_RANGE_VOLTS = -2.1;

export const LINE_STYLE_OPTIONS = [
	{
		value: 'dash',
		label: 'Dash'
	},
	{
		value: 'dot',
		label: 'Dot'
	},
	{
		value: 'longdash',
		label: 'Long Dash'
	},
	{
		value: 'dashdot',
		label: 'Dash Dot'
	},
	{
		value: 'longdashdot',
		label: 'Long Dash Dot'
	},
	{
		value: 'solid',
		label: 'Solid'
	}
];

export const getOnName = surveyType => {
	switch (surveyType.toLowerCase()) {
		case 'depol':
			return 'Depol';
		case 'native':
			return 'Native';
		case 'on':
		case 'on-off':
			return 'On';
		// TOOD: What do we do with TS-Data, DCVG, ACCA, etc?
		default:
			return '';
	}
};
export const defaultState = {
	showSettingModal: false,
	selectedOriginalLineConfig: showOriginalLineOptions[1].value,
	selectedCommentConfig: showCommentsOptions[1].value,
	oldData: null,
	newData: null,
	useMv: false,
	defaultMv: false,
	previousChartData: null,
	nextChartData: null,
	layout: null,
	currentYRange: null,
	manualYRange: null,
	displayMinColorPicker: false,
	displayMaxColorPicker: false,
	minCritValue: -850,
	maxCritValue: -1200,
	feetPerPage: OPTIMUM_ZOOM_IN_FEET,
	gridAxesSetting: {},
	paginationStatus: null,
	selectActions: null,
	highlightedPoint: null,
	previousDatFile: {},
	nextDatFile: {},
	selectedDatFile: null,
	selectedDatFileIndex: null,
	traceOptionsByName: DEFAULT_TRACE_INFO_BY_NAME,
	readingRowsSelectedByDatFile: {
		/* will look like:
		 * [0]: [1, 2, 5, etc]
		 */
	},
	zoomPointFromReadings: {
		/* will look like:
		 * stationId: 123,
		 * datFileName: "bp080",
		 * feet
		 */
	},
	annotations: null,
	allAnnotations: null,
	allTraces: null,
	traces: null,
	navigationChangesByDatFile: {
		/* will look like:
		 * [tiny-dat]: [{layout}]
		 */
	}
};

export const DEFAULT_SELECT_ACTIONS = [
	{
		name: 'Interpolate',
		value: 'interpolate',
		checked: true,
		tooltip:
			'Interpolate the selected readings between the upstream and downstream values'
	},
	{
		name: 'Remove Autocorrection',
		value: 'remove-autocorrection',
		checked: false,
		disabled: true,
		tooltip:
			'Remove auto corrections from selected readings (this is disabled until auto correction is in process)'
	}
];

export const DEFAULT_MINIMUM_CRITERIA_LINE_TRACE = {
	x: [],
	y: [],
	type: 'scatter',
	mode: 'lines',
	name: 'Min criteria',
	line: {
		dash: 'dash',
		width: 10,
		color: 'lightgray'
	}
};

export const DEFAULT_MAXIMUM_CRITERIA_LINE_TRACE = {
	x: [],
	y: [],
	type: 'scatter',
	mode: 'lines',
	name: 'Max criteria',
	line: {
		dash: 'dash',
		width: 1,
		color: 'rgb(240,128,128)'
	}
};

export const FILE_TYPE = {
	ON: 0,
	ON_OFF: 1,
	ON_OFF_GPS_SYNC: 2,
	OTHER: 3
};
export const EDIT_TYPE = {
	ON: 0,
	OFF: 1
};
