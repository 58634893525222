import SubscriptionManager from './util/SubscriptionManager';

class StationIdHoverSync {
	constructor() {
		this.subscriptionManager = new SubscriptionManager();
		this.stationId = undefined;
	}

	hasChanges({ stationId = this.stationId }) {
		return this.stationId !== stationId;
	}

	updateStationId(stationId, callingObj) {
		if (this.hasChanges({ stationId })) {
			// console.log(`CHANGE --> ${callingObj.trackingId}`);

			this.stationId = stationId;

			const subscribed = this.subscriptionManager.getSubscribed({
				ignore: [callingObj]
			});
			subscribed.forEach(({ f: func }) => {
				func(stationId);
			});
		}
	}

	subscribe(obj, fnRecieveZoomUpdate) {
		this.subscriptionManager.subscribe(obj, fnRecieveZoomUpdate);
	}

	unsubscribe(obj) {
		this.subscriptionManager.unsubscribe(obj);
	}
}

export default new StationIdHoverSync();
