/* eslint-disable import/no-cycle */
import { PROJECTS_PER_PAGE, PROJECTS_QUERY_ITEM_LIMIT } from '../constants';
import scanlineUtils from '../../../scanlineUtils';

export const fetchProjects = (reqObj = {}, callback) => {
	// for some reason old code was implemented with error param as first param, response is second param
	// for this initial pass we're ignoring errors
	const _callback = (err, res) => {
		if (err) {
			// console.log(err);
			return callback(undefined, err);
		}
		return callback(res);
	};
	scanlineUtils.fetchProjects(reqObj, _callback);
};

export const fetchProjectsNameList = callback => {
	const _callback = (err, res) => {
		if (err) {
			// console.log(err);
			return callback(undefined, err);
		}
		return callback(res);
	};
	scanlineUtils.fetchProjectsNameList(_callback);
};

export const fetchProjectsWithFilters = (reqObj = {}, callback) => {
	// for some reason old code was implemented with error param as first param, response is second param
	// for this initial pass we're ignoring errors
	const _callback = (err, res) => {
		if (err) {
			// console.log(err);
			return callback(undefined, err);
		}
		return callback(res);
	};
	scanlineUtils.fetchProjectsWithFilters(reqObj, _callback);
};

export const fetchProjectsMetaData = (reqObj = [], callback) => {
	const _callback = (err, res) => {
		if (err) {
			// console.log(err);
			return callback(undefined, err);
		}
		return callback(res);
	};
	scanlineUtils.getProjectsMetadata(reqObj, _callback);
};

export const createFetchProjectsRequestObject = ({
	pageStart = 1,
	pageEnd
}) => {
	const offset = PROJECTS_PER_PAGE * (pageStart - 1);
	const end = pageEnd || pageStart + 5;
	const limit =
		(Math.max(pageStart, end) - pageStart) * PROJECTS_PER_PAGE ||
		PROJECTS_PER_PAGE;

	return {
		limit,
		offset
	};
};

export const createFetchProjectsMetaDataRequestObject = (projects = []) => {
	return projects.map(project => ({
		job: project.jobid,
		line: project.linename,
		num: project.id,
		desc: project.title
	}));
};

// Will not work until paging is implemented on backend.
export const getPagesFromFetchProjectsRequestObject = requestObject => {
	const { limit = PROJECTS_QUERY_ITEM_LIMIT, offset = 0 } = requestObject;
	const pageCount = limit / PROJECTS_PER_PAGE;
	const startingPage = offset / PROJECTS_PER_PAGE + 1;
	const result = [];

	for (let x = 0; x < pageCount; x += 1) {
		result.push(x + startingPage);
	}
	return result;
};

// export const getPagesFromProjectsArray = (projects = []) => {
//     const projectCount = projects.length;
//     const pageCount = Math.ceil(projectCount / PROJECTS_PER_PAGE);
//     const startingPage = 1;
//     const result = [];

//     for (var x = 0; x < pageCount; x+=1) {
//         result.push(x + startingPage);
//     }
//     return result;
// }

const isPreviousPageTheLastPage = (page, projectsByPage, pages) => {
	return (
		pages.indexOf(page) > 0 &&
		projectsByPage[page - 1].length < PROJECTS_PER_PAGE
	);
};

export const createProjectsByPage = (projects = [], pages) => {
	const firstPage = pages[0];
	let foundLastPage = false;
	return pages.reduce((projectsByPage, page) => {
		const startIndex = (page - firstPage) * PROJECTS_PER_PAGE;
		const endIndex = startIndex + PROJECTS_PER_PAGE;

		foundLastPage =
			foundLastPage || isPreviousPageTheLastPage(page, projectsByPage, pages);
		if (foundLastPage) {
			return projectsByPage;
		}
		return { ...projectsByPage, [page]: projects.slice(startIndex, endIndex) };
	}, {});
};

export const createMetaByProjectId = (responseProjectsMeta = []) => {
	return responseProjectsMeta.reduce((projectsMeta, meta) => {
		const [reqParams, mapThumbnail, zips, pdfs] = meta;

		if (reqParams && reqParams.job) {
			const jobId = reqParams.job;
			return {
				...projectsMeta,
				[jobId]: {
					mapThumbnail,
					zips: zips instanceof Array ? zips : [],
					pdfs: pdfs instanceof Array ? pdfs : []
				}
			};
		}

		return projectsMeta;
	}, {});
};
