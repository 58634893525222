/* eslint-disable import/no-cycle */
import { createActions } from 'redux-actions';
import scanlineUtils from '../../../scanlineUtils';
import { thunkLoadActionPlanReadings } from '../ActionPlanReadings/actions';
import { setIsSyncEnabled } from '../ActionPlanSync/actions';
import { selectIsSyncEnabled } from './selectors';

const isDefined = v => v !== undefined && v !== null;

const {
	requestActionPlanState,
	receiveActionPlanState,
	setActionReportThreshold,
	setActionReportUseShadow,
	addActionReportCustomGroup,
	removeActionReportCustomGroup,
	clearActionReportCustomGroup,
	setActionReportVersion,
	setActionReportComparator
} = createActions(
	{
		REQUEST_ACTION_PLAN_STATE: () => ({}),
		RECEIVE_ACTION_PLAN_STATE: actionPlanState => ({
			actionPlanState
		}),
		SET_ACTION_REPORT_THRESHOLD: threshold => ({ threshold }),
		SET_ACTION_REPORT_USE_SHADOW: useShadow => ({ useShadow }),
		ADD_ACTION_REPORT_CUSTOM_GROUP: group => ({ group }),
		REMOVE_ACTION_REPORT_CUSTOM_GROUP: group => ({ group }),
		CLEAR_ACTION_REPORT_CUSTOM_GROUP: () => ({}),
		SET_ACTION_REPORT_VERSION: version => ({ version }),
		SET_ACTION_REPORT_COMPARATOR: comparator => ({ comparator })
	},
	{ prefix: 'ActionPlanStateV2' }
);

const thunkInitProjectSync = () => (dispatch, getState) => {
	const { app } = getState();
	const isSyncEnabled = selectIsSyncEnabled(app);
	if (isDefined(isSyncEnabled)) {
		dispatch(setIsSyncEnabled(isSyncEnabled));
	}
};

const thunkLoadActionPlanStateV2 = (
	jobId,
	lineId,
	projectid,
	callback = () => {}
) => {
	return dispatch => {
		dispatch(requestActionPlanState());

		return scanlineUtils.getReportDataV2(
			jobId,
			lineId,
			projectid,
			'ACTION_PLAN',
			(err, res) => {
				if (err) {
					callback(err);
				} else {
					dispatch(receiveActionPlanState(res.content));
					dispatch(thunkInitProjectSync());
					dispatch(thunkLoadActionPlanReadings());
					callback(null, res);
				}
			}
		);
	};
};
export {
	thunkLoadActionPlanStateV2 as loadActionPlanStateV2,
	receiveActionPlanState,
	requestActionPlanState,
	setActionReportThreshold,
	setActionReportUseShadow,
	addActionReportCustomGroup,
	removeActionReportCustomGroup,
	clearActionReportCustomGroup,
	setActionReportVersion,
	setActionReportComparator
};
