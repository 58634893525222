/* eslint-disable consistent-return */
/* eslint-disable no-param-reassign */
import { isGap } from '../../../../../../../utils/reading';
import { filterByStdDev } from '../../../../../../../workers/FilterByStdDev';
import { createDerivedBcDatasources } from './derivedDataSources';

const createGapReading = (r, idx) => {
	return { id: r.id + 0.01, uuid: `${r.uuid}~${idx}`, isGap: true };
};

export const getComparator = key => {
	const greaterThanEqualTo = (readingValue, threshold) =>
		readingValue >= threshold;
	const greaterThan = (readingValue, threshold) => readingValue > threshold;

	const comparators = {
		GREATER_THAN_EQUAL_TO: greaterThanEqualTo,
		GREATER_THAN: greaterThan
	};

	return comparators[key] || greaterThan;
};

export const forceSinglePointRender = r => {
	return {
		...r,
		id: r.id + 0.5,
		uuid: `${r.uuid}~${r.id + 0.5}`,
		isFauxDataPoint: true
	};
};

// eslint-disable-next-line no-unused-vars
const getByUuid = (uuid, readings, readingsIndexMap) => {
	const idx = readingsIndexMap[uuid];
	if (idx !== undefined) {
		return readings && readings.length > idx ? readings[idx] : undefined;
	}

	return undefined;
};

const getNext = (curr, readings, readingsIndexMap) => {
	const idx = readingsIndexMap[curr.uuid];
	if (idx !== undefined) {
		return readings && readings.length - 1 > idx
			? readings[idx + 1]
			: undefined;
	}

	return undefined;
};

// eslint-disable-next-line no-unused-vars
const getPrev = (curr, readings, readingsIndexMap) => {
	const idx = readingsIndexMap[curr.uuid];
	if (idx !== undefined) {
		return readings && readings.length - 1 >= idx && idx > 0
			? readings[idx - 1]
			: undefined;
	}

	return undefined;
};

const push = (
	reading,
	readings,
	readingsMap,
	readingsIndexMap,
	readingsUuidMap
) => {
	readings.push(reading);
	if (!reading.isGap && !reading.isFauxDataPoint) {
		readingsMap[reading.id] = reading;
		readingsIndexMap[reading.uuid] = readings.length - 1;
		readingsUuidMap[reading.uuid] = reading;
	}
};

export const coreComputeBelowCriterions = (
	surveyType,
	readings,
	readingsWithGaps = [],
	readingsWithGapsIndexMap = {},
	key,
	getThreshold = () => -850,
	canCompare = () => true,
	calcIsZeroReading = () => false,
	comparator = (readingValue, threshold) => readingValue > threshold
) => {
	// eslint-disable-next-line no-console
	// console.log(`
	// 	--- PARAMS ---
	// 	\n surveyType: ${surveyType}
	// 	\n readings: ${readings.length}
	// 	\n readingsWithGaps: ${readingsWithGaps.length}
	// 	\n readingsWithGapsIndexMap: ${Object.keys(readingsWithGapsIndexMap).length}
	// 	\n key: ${key}
	// 	\n getThreshold: ${getThreshold}
	// 	\n canCompare: ${canCompare}
	// 	\n calcIsZeroReading: ${calcIsZeroReading}
	// 	\n comparator: ${comparator}
	// `);

	// results
	const bcReadings = [];
	const bcReadingsMap = {};
	const bcReadingsIndexMap = {};
	const bcReadingsUuidMap = {};
	const bcReadingsWithGaps = [];
	const bcReadingsWithGapsMap = {};
	const bcReadingsWithGapsIndexMap = {};
	const bcReadingsWithGapsUuidMap = {};

	let lastNonZeroReadingIsBc = false;
	let lastInjectedReadingWasNotGap = false;

	const computeIsBc = r => {
		return canCompare(r) && comparator(r[key], getThreshold(r));
	};

	const pushReading = _r => {
		push(_r, bcReadings, bcReadingsMap, bcReadingsIndexMap, bcReadingsUuidMap);
	};

	const pushReadingWithGaps = _r => {
		push(
			_r,
			bcReadingsWithGaps,
			bcReadingsWithGapsMap,
			bcReadingsWithGapsIndexMap,
			bcReadingsWithGapsUuidMap
		);
	};

	const isSurroundedByGap = (prev, curr, next) => {
		return (
			(!prev || prev.isGap || !computeIsBc(prev)) &&
			!!curr &&
			!curr.isGap &&
			(!next || next.isGap || !computeIsBc(prev))
		);
	};

	const getLastBC = () => {
		return bcReadingsWithGaps && bcReadingsWithGaps.length > 0
			? bcReadingsWithGaps[bcReadingsWithGaps.length - 1]
			: undefined;
	};

	readingsWithGaps.forEach((r, idx) => {
		if (isGap(r)) {
			if (lastInjectedReadingWasNotGap) {
				pushReadingWithGaps({ ...r });
				lastInjectedReadingWasNotGap = false;
			}
			return false;
		}

		const isBc = computeIsBc(r, key);
		const isZeroReading = calcIsZeroReading(r);

		// if (!isBc && !isZeroReading) {
		// 	if (lastInjectedReadingWasNotGap) {
		// 		pushReadingWithGaps(createGapReading(r, -1));
		// 		lastInjectedReadingWasNotGap = false;
		// 	}
		// 	return false;
		// }

		const injectBc = isBc;
		const injectBcEnd =
			!isBc &&
			lastNonZeroReadingIsBc &&
			lastInjectedReadingWasNotGap &&
			!isZeroReading;

		let prev;
		let next;

		// inject into dataset readingsWithGaps
		if (injectBc) {
			prev = getLastBC();
			next = getNext(r, readingsWithGaps, readingsWithGapsIndexMap);
			pushReadingWithGaps(r);
			if (isSurroundedByGap(prev, r, next)) {
				pushReadingWithGaps(forceSinglePointRender(r));
			}
			lastInjectedReadingWasNotGap = true;
		} else if (injectBcEnd) {
			prev = getLastBC();
			next = getNext(r, readingsWithGaps, readingsWithGapsIndexMap);
			pushReadingWithGaps(r);
			if (isSurroundedByGap(prev, r, next)) {
				pushReadingWithGaps(forceSinglePointRender(r));
			}
			pushReadingWithGaps(createGapReading(r, idx));
			lastInjectedReadingWasNotGap = false;
		}

		lastNonZeroReadingIsBc = isZeroReading ? lastNonZeroReadingIsBc : isBc;
		const isInBc = isBc || (lastNonZeroReadingIsBc && isZeroReading);

		// inject into dataset readings
		if (isInBc && !isZeroReading) {
			pushReading(r);
			// if (idx === readingsWithGaps.length - 1) {
			// 	debugger;
			// }
		} else if (idx === readingsWithGaps.length - 1) {
			// debugger;
		}
	});

	const {
		bcReadingsWithGeoGaps,
		bcReadingsWithGeoGapsMap,
		bcReadingsWithGeoGapsIndexMap,
		bcReadingsWithGeoGapsUuidMap,
		areaBcReadings
	} = createDerivedBcDatasources(readings, key, bcReadings);

	const bcReadingsWithGapsDecimated = filterByStdDev({
		readings: bcReadingsWithGaps,
		propsOverride: [key]
	});
	return {
		bcReadings,
		bcReadingsMap,
		bcReadingsIndexMap,
		bcReadingsUuidMap,
		bcReadingsWithGaps,
		bcReadingsWithGapsDecimated,
		bcReadingsWithGapsMap,
		bcReadingsWithGapsIndexMap,
		bcReadingsWithGapsUuidMap,
		bcReadingsWithGeoGaps,
		bcReadingsWithGeoGapsMap,
		bcReadingsWithGeoGapsIndexMap,
		bcReadingsWithGeoGapsUuidMap,
		areaBcReadings
	};
};
