import { point as turfPoint } from '@turf/turf';

import {
	config,
	calculateDirection,
	calculateDistance
} from 'aegion_common_utilities/lib/MapProcessingUtil';

import * as smoothToolUtil from './SmoothTool/smoothTool';

const getTurfPoint = gpsReading => {
	const [lat, lng] = gpsReading.coordinates;
	return turfPoint([lng, lat]);
};

// eslint-disable-next-line import/prefer-default-export
export const getCommentsWithCoordinate = (readings, dat, firstIdx, lastIdx) => {
	const firstGPSReading = dat.gpsreadings[firstIdx];
	const lastGPSReading = dat.gpsreadings[lastIdx];
	const firstPoint = getTurfPoint(firstGPSReading);
	const lastPoint = getTurfPoint(lastGPSReading);

	const distance = calculateDistance(firstPoint, lastPoint, {
		reverse: false,
		to: config.UNITS.DISTANCE.KILOMETERS
	});
	const bearing = calculateDirection(firstPoint, lastPoint, {
		reverse: false
	});

	return readings
		.filter(reading => {
			const { skip = false } = reading;
			return reading.comments != null && reading.comments !== '' && !skip;
		})
		.reduce((acc, reading) => {
			const indx = readings.indexOf(reading);
			let dist = distance;
			if (readings.length > 1) {
				dist = distance * (indx / (readings.length - 1));
			}
			const position = smoothToolUtil.getInterpolatedPoint(
				firstPoint,
				dist,
				bearing
			);
			position.reverse();
			acc.push({ position, text: reading.comments });
			return acc;
		}, []);
};
