export default async () =>
	JSON.parse(`{
    "type": "FeatureCollection",
    "name": "manholes",
    "crs": { "type": "name", "properties": { "name": "urn:ogc:def:crs:OGC:1.3:CRS84" } },
    "features": [
    { "type": "Feature", "properties": { "OBJECTID": 1, "Coating": "None", "Constructi": "Precast", "Date_Inspe": "02/18/09", "Depth": 7.75, "Infiltrati": "No", "Inflow": "No", "Lift_Stati": 6.0, "Manhole_Id": "LS6-010", "Rating": "Good", "SHAWN": 0.0, "LAYER": "SANMH", "FeatId1": 1010.0 }, "geometry": { "type": "Point", "coordinates": [ -80.610690630494886, 28.30090558381097 ] } },
    { "type": "Feature", "properties": { "OBJECTID": 2, "Coating": "None", "Constructi": "Precast", "Date_Inspe": "02/18/09", "Depth": 6.0, "Infiltrati": "No", "Inflow": "No", "Lift_Stati": 6.0, "Manhole_Id": "LS6-009", "Rating": "Good", "SHAWN": 0.0, "LAYER": "SANMH", "FeatId1": 1009.0 }, "geometry": { "type": "Point", "coordinates": [ -80.611411856070305, 28.30060081962753 ] } },
    { "type": "Feature", "properties": { "OBJECTID": 3, "Coating": "None", "Constructi": "Precast", "Date_Inspe": "02/18/09", "Depth": 5.42, "Infiltrati": "No", "Inflow": "No", "Lift_Stati": 6.0, "Manhole_Id": "LS6-008", "Rating": "Good", "SHAWN": 0.0, "LAYER": "SANMH", "FeatId1": 1008.0 }, "geometry": { "type": "Point", "coordinates": [ -80.611577725461871, 28.299740081614456 ] } },
    { "type": "Feature", "properties": { "OBJECTID": 4, "Coating": "None", "Constructi": "Precast", "Date_Inspe": "01/18/09", "Depth": 3.83, "Infiltrati": "No", "Inflow": "No", "Lift_Stati": 6.0, "Manhole_Id": "LS6-007", "Rating": "Good", "SHAWN": 0.0, "LAYER": "SANMH", "FeatId1": 1007.0 }, "geometry": { "type": "Point", "coordinates": [ -80.611620070993808, 28.299173399507975 ] } },
    { "type": "Feature", "properties": { "OBJECTID": 5, "Coating": "None", "Constructi": "Precast", "Date_Inspe": "02/17/09", "Depth": 5.08, "Infiltrati": "No", "Inflow": "No", "Lift_Stati": 6.0, "Manhole_Id": "LS6-004", "Rating": "Good", "SHAWN": 0.0, "LAYER": "SANMH", "FeatId1": 1006.0 }, "geometry": { "type": "Point", "coordinates": [ -80.611505584651951, 28.298759952209508 ] } },
    { "type": "Feature", "properties": { "OBJECTID": 6, "Coating": "Mortar", "Constructi": "Brick/Mort", "Date_Inspe": "02/17/09", "Depth": 5.92, "Infiltrati": "No", "Inflow": "No", "Lift_Stati": 6.0, "Manhole_Id": "LS6-003", "Rating": "Good", "SHAWN": 0.0, "LAYER": "SANMH", "FeatId1": 1005.0 }, "geometry": { "type": "Point", "coordinates": [ -80.610522296458086, 28.298734916701978 ] } },
    { "type": "Feature", "properties": { "OBJECTID": 7, "Coating": "None", "Constructi": "Brick/Mortar", "Date_Inspe": "02/17/09", "Depth": 5.25, "Infiltrati": "Yes", "Inflow": "No", "Lift_Stati": 6.0, "Manhole_Id": "LS6-005", "Rating": "Fair", "SHAWN": 0.0, "LAYER": "SANMH", "FeatId1": 1004.0 }, "geometry": { "type": "Point", "coordinates": [ -80.610526124611212, 28.299004896211709 ] } },
    { "type": "Feature", "properties": { "OBJECTID": 8, "Coating": "Mortar", "Constructi": "Brick/Mortar", "Date_Inspe": "02/18/09", "Depth": 7.75, "Infiltrati": "Probable", "Inflow": "No", "Lift_Stati": 6.0, "Manhole_Id": "LS6-006", "Rating": "Fair", "SHAWN": 0.0, "LAYER": "SANMH", "FeatId1": 1003.0 }, "geometry": { "type": "Point", "coordinates": [ -80.610554998208144, 28.300109740205642 ] } },
    { "type": "Feature", "properties": { "OBJECTID": 9, "Coating": "None", "Constructi": "Precast", "Date_Inspe": "02/17/09", "Depth": 8.25, "Infiltrati": "Probable", "Inflow": "No", "Lift_Stati": 6.0, "Manhole_Id": "LS6-001", "Rating": "Fair", "SHAWN": 0.0, "LAYER": "SANMH", "FeatId1": 1002.0 }, "geometry": { "type": "Point", "coordinates": [ -80.609567748270962, 28.297727731957973 ] } },
    { "type": "Feature", "properties": { "OBJECTID": 10, "Coating": "Mortar", "Constructi": "Brick/Mortar", "Date_Inspe": "02/17/09", "Depth": 5.17, "Infiltrati": "No", "Inflow": "No", "Lift_Stati": 6.0, "Manhole_Id": "LS6-002", "Rating": "Good", "SHAWN": 0.0, "LAYER": "SANMH", "FeatId1": 1001.0 }, "geometry": { "type": "Point", "coordinates": [ -80.610396925721176, 28.298337028598866 ] } },
    { "type": "Feature", "properties": { "OBJECTID": 11, "Coating": "None", "Constructi": "Brick/Mortar", "Date_Inspe": "03/24/09", "Depth": 12.0, "Infiltrati": "No", "Inflow": "No", "Lift_Stati": 7.0, "Manhole_Id": "LS7-011", "Rating": "Good", "SHAWN": 0.0, "LAYER": "SANMH", "FeatId1": 1000.0 }, "geometry": { "type": "Point", "coordinates": [ -80.61300942886966, 28.319367153447402 ] } },
    { "type": "Feature", "properties": { "OBJECTID": 12, "Coating": "None", "Constructi": "Brick/Mortar", "Date_Inspe": "03/23/09", "Depth": 7.33, "Infiltrati": "No", "Inflow": "No", "Lift_Stati": 7.0, "Manhole_Id": "LS7-008", "Rating": "Good", "SHAWN": 0.0, "LAYER": "SANMH", "FeatId1": 999.0 }, "geometry": { "type": "Point", "coordinates": [ -80.614467508737633, 28.320676010578694 ] } },
    { "type": "Feature", "properties": { "OBJECTID": 13, "Coating": "None", "Constructi": "Brick/Mortar", "Date_Inspe": "03/23/09", "Depth": 9.75, "Infiltrati": "No", "Inflow": "No", "Lift_Stati": 7.0, "Manhole_Id": "LS7-010", "Rating": "Good", "SHAWN": 0.0, "LAYER": "SANMH", "FeatId1": 998.0 }, "geometry": { "type": "Point", "coordinates": [ -80.613917981747562, 28.320210111130812 ] } },
    { "type": "Feature", "properties": { "OBJECTID": 14, "Coating": "None", "Constructi": "Brick/Mortar", "Date_Inspe": "03/23/09", "Depth": 6.083, "Infiltrati": "No", "Inflow": "No", "Lift_Stati": 7.0, "Manhole_Id": "LS7-007", "Rating": "Good", "SHAWN": 0.0, "LAYER": "SANMH", "FeatId1": 997.0 }, "geometry": { "type": "Point", "coordinates": [ -80.615089538594532, 28.320955145241772 ] } },
    { "type": "Feature", "properties": { "OBJECTID": 15, "Coating": "None", "Constructi": "Brick/Mortar", "Date_Inspe": "03/23/09", "Depth": 4.66, "Infiltrati": "No", "Inflow": "No", "Lift_Stati": 7.0, "Manhole_Id": "LS7-006", "Rating": "Good", "SHAWN": 0.0, "LAYER": "SANMH", "FeatId1": 996.0 }, "geometry": { "type": "Point", "coordinates": [ -80.615810602443219, 28.321367435927996 ] } },
    { "type": "Feature", "properties": { "OBJECTID": 16, "Coating": "None", "Constructi": "Brick/Mortar", "Date_Inspe": "03/23/09", "Depth": 9.25, "Infiltrati": "No", "Inflow": "No", "Lift_Stati": 7.0, "Manhole_Id": "LS7-009", "Rating": "Good", "SHAWN": 0.0, "LAYER": "SANMH", "FeatId1": 995.0 }, "geometry": { "type": "Point", "coordinates": [ -80.613908409280938, 28.320431479182844 ] } },
    { "type": "Feature", "properties": { "OBJECTID": 17, "Coating": "None", "Constructi": "Brick/Mortar", "Date_Inspe": "03/23/09", "Depth": 9.0, "Infiltrati": "No", "Inflow": "No", "Lift_Stati": 7.0, "Manhole_Id": "LS7-005", "Rating": "Good", "SHAWN": 0.0, "LAYER": "SANMH", "FeatId1": 994.0 }, "geometry": { "type": "Point", "coordinates": [ -80.613889038474042, 28.321101247728752 ] } },
    { "type": "Feature", "properties": { "OBJECTID": 18, "Coating": "None", "Constructi": "Brick/Mortar", "Date_Inspe": "03/23/09", "Depth": 7.92, "Infiltrati": "No", "Inflow": "No", "Lift_Stati": 7.0, "Manhole_Id": " LS7-004", "Rating": "Good", "SHAWN": 0.0, "LAYER": "SANMH", "FeatId1": 993.0 }, "geometry": { "type": "Point", "coordinates": [ -80.613868975794901, 28.321716316309018 ] } },
    { "type": "Feature", "properties": { "OBJECTID": 19, "Coating": "None", "Constructi": "Brick/Mortar", "Date_Inspe": "03/23/09", "Depth": 6.42, "Infiltrati": "No", "Inflow": "No", "Lift_Stati": 7.0, "Manhole_Id": "LS7-003", "Rating": "Good", "SHAWN": 0.0, "LAYER": "SANMH", "FeatId1": 992.0 }, "geometry": { "type": "Point", "coordinates": [ -80.614803688178299, 28.321719399827124 ] } },
    { "type": "Feature", "properties": { "OBJECTID": 20, "Coating": "None", "Constructi": "Brick/Mortar", "Date_Inspe": "03/23/09", "Depth": 5.5, "Infiltrati": "No", "Inflow": "No", "Lift_Stati": 7.0, "Manhole_Id": "LS7-002", "Rating": "Good", "SHAWN": 0.0, "LAYER": "SANMH", "FeatId1": 991.0 }, "geometry": { "type": "Point", "coordinates": [ -80.615734807931759, 28.321721114178363 ] } },
    { "type": "Feature", "properties": { "OBJECTID": 21, "Coating": "None", "Constructi": "Brick/Mortar", "Date_Inspe": "03/23/09", "Depth": 4.25, "Infiltrati": "No", "Inflow": "No", "Lift_Stati": 7.0, "Manhole_Id": "LS7-001", "Rating": "Good", "SHAWN": 0.0, "LAYER": "SANMH", "FeatId1": 990.0 }, "geometry": { "type": "Point", "coordinates": [ -80.616608716992033, 28.321723956370839 ] } },
    { "type": "Feature", "properties": { "OBJECTID": 22, "Coating": "None", "Constructi": "Brick/Mortar", "Date_Inspe": "03/24/09", "Depth": 11.0, "Infiltrati": "No", "Inflow": "No", "Lift_Stati": 7.0, "Manhole_Id": "LS7-012", "Rating": "Good", "SHAWN": 0.0, "LAYER": "SANMH", "FeatId1": 989.0 }, "geometry": { "type": "Point", "coordinates": [ -80.612984705466957, 28.320193428826901 ] } },
    { "type": "Feature", "properties": { "OBJECTID": 23, "Coating": "None", "Constructi": "Brick/Mortar", "Date_Inspe": "03/25/09", "Depth": 1.92, "Infiltrati": "No", "Inflow": "No", "Lift_Stati": 7.0, "Manhole_Id": "LS7-026", "Rating": "Fair", "SHAWN": 0.0, "LAYER": "SANMH", "FeatId1": 988.0 }, "geometry": { "type": "Point", "coordinates": [ -80.612871605571783, 28.322260657849558 ] } },
    { "type": "Feature", "properties": { "OBJECTID": 24, "Coating": "Spectrashield", "Constructi": "Brick/Mortar", "Date_Inspe": "03/25/09", "Depth": 6.08, "Infiltrati": "No", "Inflow": "No", "Lift_Stati": 7.0, "Manhole_Id": "LS7-027", "Rating": "Good", "SHAWN": 0.0, "LAYER": "SANMH", "FeatId1": 987.0 }, "geometry": { "type": "Point", "coordinates": [ -80.612866322673355, 28.322425211299009 ] } },
    { "type": "Feature", "properties": { "OBJECTID": 25, "Coating": "Spectrashield", "Constructi": "Brick/Mortar", "Date_Inspe": " ", "Depth": 9.67, "Infiltrati": "No", "Inflow": "No", "Lift_Stati": 7.0, "Manhole_Id": "LS7-014", "Rating": "Good", "SHAWN": 0.0, "LAYER": "SANMH", "FeatId1": 986.0 }, "geometry": { "type": "Point", "coordinates": [ -80.6128854959086, 28.32242497151384 ] } },
    { "type": "Feature", "properties": { "OBJECTID": 26, "Coating": "Spectrashield", "Constructi": "Brick/Mortar", "Date_Inspe": "03/24/09", "Depth": 4.92, "Infiltrati": "No", "Inflow": "No", "Lift_Stati": 7.0, "Manhole_Id": "LS7-018", "Rating": "Good", "SHAWN": 0.0, "LAYER": "SANMH", "FeatId1": 985.0 }, "geometry": { "type": "Point", "coordinates": [ -80.613947148877187, 28.322419655350902 ] } },
    { "type": "Feature", "properties": { "OBJECTID": 27, "Coating": "Spectrashield", "Constructi": "Brick/Mortar", "Date_Inspe": "03/25/09", "Depth": 3.33, "Infiltrati": "No", "Inflow": "No", "Lift_Stati": 7.0, "Manhole_Id": "LS7-019", "Rating": "Good", "SHAWN": 0.0, "LAYER": "SANMH", "FeatId1": 984.0 }, "geometry": { "type": "Point", "coordinates": [ -80.615042684446507, 28.322420190832208 ] } },
    { "type": "Feature", "properties": { "OBJECTID": 28, "Coating": "None", "Constructi": "Brick/Mortar", "Date_Inspe": "03/25/09", "Depth": 2.33, "Infiltrati": "No", "Inflow": "No", "Lift_Stati": 7.0, "Manhole_Id": "LS7-020", "Rating": "Fair", "SHAWN": 0.0, "LAYER": "SANMH", "FeatId1": 983.0 }, "geometry": { "type": "Point", "coordinates": [ -80.616078432025418, 28.322415043993086 ] } },
    { "type": "Feature", "properties": { "OBJECTID": 29, "Coating": "None", "Constructi": "Brick/Mortar", "Date_Inspe": "03/25/09", "Depth": 2.33, "Infiltrati": "No", "Inflow": "No", "Lift_Stati": 7.0, "Manhole_Id": "LS7-021", "Rating": "Good", "SHAWN": 0.0, "LAYER": "SANMH", "FeatId1": 982.0 }, "geometry": { "type": "Point", "coordinates": [ -80.616423823293204, 28.322727544214672 ] } },
    { "type": "Feature", "properties": { "OBJECTID": 30, "Coating": "Spectrashield", "Constructi": "Brick/Mortar", "Date_Inspe": "03/25/09", "Depth": 2.67, "Infiltrati": "Yes", "Inflow": "No", "Lift_Stati": 7.0, "Manhole_Id": "LS7-022", "Rating": "Good", "SHAWN": 0.0, "LAYER": "SANMH", "FeatId1": 981.0 }, "geometry": { "type": "Point", "coordinates": [ -80.616474288125701, 28.323116187947743 ] } },
    { "type": "Feature", "properties": { "OBJECTID": 31, "Coating": "None", "Constructi": "Brick/Mortar", "Date_Inspe": "03/25/09", "Depth": 2.67, "Infiltrati": "No", "Inflow": "No", "Lift_Stati": 7.0, "Manhole_Id": "LS7-023", "Rating": "Good", "SHAWN": 0.0, "LAYER": "SANMH", "FeatId1": 980.0 }, "geometry": { "type": "Point", "coordinates": [ -80.616468427529469, 28.323249170588284 ] } },
    { "type": "Feature", "properties": { "OBJECTID": 32, "Coating": "Spectrashield", "Constructi": "Brick/Mortar", "Date_Inspe": "03/25/09", "Depth": 4.67, "Infiltrati": "No", "Inflow": "No", "Lift_Stati": 7.0, "Manhole_Id": "LS7-024", "Rating": "Good", "SHAWN": 0.0, "LAYER": "SANMH", "FeatId1": 979.0 }, "geometry": { "type": "Point", "coordinates": [ -80.615275583331041, 28.323121055319241 ] } },
    { "type": "Feature", "properties": { "OBJECTID": 33, "Coating": "Spectrashield", "Constructi": "Brick/Mortar", "Date_Inspe": " ", "Depth": 6.0, "Infiltrati": "No", "Inflow": "No", "Lift_Stati": 7.0, "Manhole_Id": "LS7-025", "Rating": "Good", "SHAWN": 0.0, "LAYER": "SANMH", "FeatId1": 978.0 }, "geometry": { "type": "Point", "coordinates": [ -80.61403535818728, 28.323123612815845 ] } },
    { "type": "Feature", "properties": { "OBJECTID": 34, "Coating": "Mortar", "Constructi": "Brick/Mortar", "Date_Inspe": "04/07/09", "Depth": 7.33, "Infiltrati": "No", "Inflow": "No", "Lift_Stati": 7.0, "Manhole_Id": "LS7-028", "Rating": "Fair", "SHAWN": 0.0, "LAYER": "SANMH", "FeatId1": 977.0 }, "geometry": { "type": "Point", "coordinates": [ -80.612838916016955, 28.323128540077914 ] } },
    { "type": "Feature", "properties": { "OBJECTID": 35, "Coating": "Spectrashield", "Constructi": "Brick/Mortar", "Date_Inspe": " ", "Depth": 8.53, "Infiltrati": "No", "Inflow": "No", "Lift_Stati": 7.0, "Manhole_Id": "LS7-015", "Rating": "Good", "SHAWN": 0.0, "LAYER": "SANMH", "FeatId1": 976.0 }, "geometry": { "type": "Point", "coordinates": [ -80.612859352605426, 28.323128089117834 ] } },
    { "type": "Feature", "properties": { "OBJECTID": 36, "Coating": "Mortar", "Constructi": "Brick/Mortar", "Date_Inspe": "03/24/09", "Depth": 8.25, "Infiltrati": "No", "Inflow": "No", "Lift_Stati": 7.0, "Manhole_Id": "LS7-016", "Rating": "Good", "SHAWN": 0.0, "LAYER": "SANMH", "FeatId1": 975.0 }, "geometry": { "type": "Point", "coordinates": [ -80.612839292360491, 28.323554659938093 ] } },
    { "type": "Feature", "properties": { "OBJECTID": 37, "Coating": "Spectrashield", "Constructi": "Brick/Mortar", "Date_Inspe": "04/08/09", "Depth": 8.17, "Infiltrati": "Probable", "Inflow": "No", "Lift_Stati": 7.0, "Manhole_Id": "LS7-017", "Rating": "Good", "SHAWN": 0.0, "LAYER": "SANMH", "FeatId1": 974.0 }, "geometry": { "type": "Point", "coordinates": [ -80.612819247873077, 28.323637410647134 ] } },
    { "type": "Feature", "properties": { "OBJECTID": 38, "Coating": "Spectrashield", "Constructi": "Brick/Mortar", "Date_Inspe": " ", "Depth": 7.33, "Infiltrati": "No", "Inflow": "No", "Lift_Stati": 7.0, "Manhole_Id": "LS7-036", "Rating": "Good", "SHAWN": 0.0, "LAYER": "SANMH", "FeatId1": 973.0 }, "geometry": { "type": "Point", "coordinates": [ -80.612801137219577, 28.324047074785479 ] } },
    { "type": "Feature", "properties": { "OBJECTID": 39, "Coating": "Spectrashield", "Constructi": "Brick/Mortar", "Date_Inspe": "03/30/09", "Depth": 7.0, "Infiltrati": "Yes", "Inflow": "No", "Lift_Stati": 7.0, "Manhole_Id": "LS7-037", "Rating": "Good", "SHAWN": 0.0, "LAYER": "SANMH", "FeatId1": 972.0 }, "geometry": { "type": "Point", "coordinates": [ -80.612775785070028, 28.324748257061998 ] } },
    { "type": "Feature", "properties": { "OBJECTID": 40, "Coating": "Spectrashield", "Constructi": "Brick/Mortar", "Date_Inspe": "03/26/09", "Depth": 4.92, "Infiltrati": "Probable", "Inflow": "No", "Lift_Stati": 7.0, "Manhole_Id": "LS7-035", "Rating": "Good", "SHAWN": 0.0, "LAYER": "SANMH", "FeatId1": 971.0 }, "geometry": { "type": "Point", "coordinates": [ -80.613948231053271, 28.324746901849089 ] } },
    { "type": "Feature", "properties": { "OBJECTID": 41, "Coating": "Spectrashield", "Constructi": "Brick/Mortar", "Date_Inspe": "03/26/09", "Depth": 2.33, "Infiltrati": "Probable", "Inflow": "No", "Lift_Stati": 7.0, "Manhole_Id": "LS7-033", "Rating": "Good", "SHAWN": 0.0, "LAYER": "SANMH", "FeatId1": 970.0 }, "geometry": { "type": "Point", "coordinates": [ -80.616327215091928, 28.324738850969631 ] } },
    { "type": "Feature", "properties": { "OBJECTID": 42, "Coating": "Mortar", "Constructi": "Brick/Mortar", "Date_Inspe": "03/26/09", "Depth": 5.33, "Infiltrati": "No", "Inflow": "No", "Lift_Stati": 7.0, "Manhole_Id": "LS7-030", "Rating": "Good", "SHAWN": 0.0, "LAYER": "SANMH", "FeatId1": 969.0 }, "geometry": { "type": "Point", "coordinates": [ -80.613968185292237, 28.32404507869121 ] } },
    { "type": "Feature", "properties": { "OBJECTID": 43, "Coating": "Mortar", "Constructi": "Brick/Mortar", "Date_Inspe": "03/26/09", "Depth": 3.5, "Infiltrati": "No", "Inflow": "No", "Lift_Stati": 7.0, "Manhole_Id": "LS7-031", "Rating": "Good", "SHAWN": 0.0, "LAYER": "SANMH", "FeatId1": 968.0 }, "geometry": { "type": "Point", "coordinates": [ -80.615138729215914, 28.324042504959227 ] } },
    { "type": "Feature", "properties": { "OBJECTID": 44, "Coating": "Mortar", "Constructi": "Brick/Mortar", "Date_Inspe": "03/26/09", "Depth": 2.08, "Infiltrati": "No", "Inflow": "No", "Lift_Stati": 7.0, "Manhole_Id": "LS7-032", "Rating": "Good", "SHAWN": 0.0, "LAYER": "SANMH", "FeatId1": 967.0 }, "geometry": { "type": "Point", "coordinates": [ -80.616390508328919, 28.32403725566461 ] } },
    { "type": "Feature", "properties": { "OBJECTID": 45, "Coating": "Spectrashield", "Constructi": "Brick/Mortar", "Date_Inspe": "03/26/09", "Depth": 3.67, "Infiltrati": "Probable", "Inflow": "No", "Lift_Stati": 7.0, "Manhole_Id": "LS7-034", "Rating": "Good", "SHAWN": 0.0, "LAYER": "SANMH", "FeatId1": 966.0 }, "geometry": { "type": "Point", "coordinates": [ -80.615009005253754, 28.324733766011445 ] } },
    { "type": "Feature", "properties": { "OBJECTID": 46, "Coating": "Mortar", "Constructi": "Brick/Mortar", "Date_Inspe": "00/00/00", "Depth": 4.5, "Infiltrati": "No", "Inflow": "No", "Lift_Stati": 7.0, "Manhole_Id": "LS7-068", "Rating": "Good", "SHAWN": 0.0, "LAYER": "SANMH", "FeatId1": 965.0 }, "geometry": { "type": "Point", "coordinates": [ -80.613664984032795, 28.325672722541054 ] } },
    { "type": "Feature", "properties": { "OBJECTID": 47, "Coating": "Spectrashield", "Constructi": "Brick/Mortar", "Date_Inspe": "00/00/00", "Depth": 3.17, "Infiltrati": "Probabl", "Inflow": "No", "Lift_Stati": 7.0, "Manhole_Id": "LS7-067", "Rating": "Good", "SHAWN": 0.0, "LAYER": "SANMH", "FeatId1": 964.0 }, "geometry": { "type": "Point", "coordinates": [ -80.614831642773524, 28.325671643010665 ] } },
    { "type": "Feature", "properties": { "OBJECTID": 48, "Coating": "None", "Constructi": "Brick/Mortar", "Date_Inspe": "00/00/00", "Depth": 2.33, "Infiltrati": "Probable", "Inflow": "No", "Lift_Stati": 7.0, "Manhole_Id": "LS7-066", "Rating": "Fair", "SHAWN": 0.0, "LAYER": "SANMH", "FeatId1": 963.0 }, "geometry": { "type": "Point", "coordinates": [ -80.615783841545351, 28.325672701181116 ] } },
    { "type": "Feature", "properties": { "OBJECTID": 49, "Coating": "Spectrashield", "Constructi": "Brick/Mortar", "Date_Inspe": "04/01/09", "Depth": 1.5, "Infiltrati": "Probable", "Inflow": "No", "Lift_Stati": 7.0, "Manhole_Id": "LS7-055", "Rating": "Good", "SHAWN": 0.0, "LAYER": "SANMH", "FeatId1": 962.0 }, "geometry": { "type": "Point", "coordinates": [ -80.616288676578989, 28.325401141864845 ] } },
    { "type": "Feature", "properties": { "OBJECTID": 50, "Coating": "Spectrashield", "Constructi": "Brick/Mortar", "Date_Inspe": "03/30/09", "Depth": 6.75, "Infiltrati": "Probable", "Inflow": "No", "Lift_Stati": 7.0, "Manhole_Id": "LS7-029", "Rating": "Good", "SHAWN": 0.0, "LAYER": "SANMH", "FeatId1": 961.0 }, "geometry": { "type": "Point", "coordinates": [ -80.61276722531565, 28.325280310158682 ] } },
    { "type": "Feature", "properties": { "OBJECTID": 51, "Coating": "Mortar", "Constructi": "Brick/Mortar", "Date_Inspe": "03/24/09", "Depth": 10.167, "Infiltrati": "No", "Inflow": "No", "Lift_Stati": 7.0, "Manhole_Id": "LS7-013", "Rating": "Good", "SHAWN": 0.0, "LAYER": "SANMH", "FeatId1": 960.0 }, "geometry": { "type": "Point", "coordinates": [ -80.612943472796175, 28.321331176375029 ] } },
    { "type": "Feature", "properties": { "OBJECTID": 52, "Coating": "None", "Constructi": "Brick/Mortar", "Date_Inspe": "00/00/00", "Depth": 6.08, "Infiltrati": "No", "Inflow": "No", "Lift_Stati": 7.0, "Manhole_Id": "LS7-069", "Rating": "Good", "SHAWN": 0.0, "LAYER": "SANMH", "FeatId1": 959.0 }, "geometry": { "type": "Point", "coordinates": [ -80.612746248249849, 28.325674008589168 ] } },
    { "type": "Feature", "properties": { "OBJECTID": 53, "Coating": "Mortar", "Constructi": "Brick/Mortar", "Date_Inspe": "03/31/09", "Depth": 6.83, "Infiltrati": "No", "Inflow": "No", "Lift_Stati": 7.0, "Manhole_Id": "LS7-043", "Rating": "Good", "SHAWN": 0.0, "LAYER": "SANMH", "FeatId1": 958.0 }, "geometry": { "type": "Point", "coordinates": [ -80.612092586860612, 28.318880427470578 ] } },
    { "type": "Feature", "properties": { "OBJECTID": 54, "Coating": "None", "Constructi": "Brick/Mortar", "Date_Inspe": "00/00/00", "Depth": 0.0, "Infiltrati": "No", "Inflow": "No", "Lift_Stati": 7.0, "Manhole_Id": "LS7-042", "Rating": "Good", "SHAWN": 0.0, "LAYER": "SANMH", "FeatId1": 957.0 }, "geometry": { "type": "Point", "coordinates": [ -80.612795159560164, 28.318877945800086 ] } },
    { "type": "Feature", "properties": { "OBJECTID": 55, "Coating": "Mortar", "Constructi": "Brick/Mortar", "Date_Inspe": "03/31/09", "Depth": 7.17, "Infiltrati": "No", "Inflow": "No", "Lift_Stati": 7.0, "Manhole_Id": "LS7-045", "Rating": "Good", "SHAWN": 0.0, "LAYER": "SANMH", "FeatId1": 956.0 }, "geometry": { "type": "Point", "coordinates": [ -80.612074414873817, 28.319510191844749 ] } },
    { "type": "Feature", "properties": { "OBJECTID": 56, "Coating": "Mortar", "Constructi": "Brick/Mortar", "Date_Inspe": "03/31/09", "Depth": 10.33, "Infiltrati": "Probable", "Inflow": "No", "Lift_Stati": 7.0, "Manhole_Id": "LS7-046", "Rating": "Fair", "SHAWN": 0.0, "LAYER": "SANMH", "FeatId1": 955.0 }, "geometry": { "type": "Point", "coordinates": [ -80.612047740020657, 28.320204311439355 ] } },
    { "type": "Feature", "properties": { "OBJECTID": 57, "Coating": "Spectrashield", "Constructi": "Brick/Mortar", "Date_Inspe": "07/01/09", "Depth": 8.75, "Infiltrati": "No", "Inflow": "No", "Lift_Stati": 7.0, "Manhole_Id": "LS7-047", "Rating": "Good", "SHAWN": 0.0, "LAYER": "SANMH", "FeatId1": 954.0 }, "geometry": { "type": "Point", "coordinates": [ -80.612025494908124, 28.321087361504794 ] } },
    { "type": "Feature", "properties": { "OBJECTID": 58, "Coating": "Mortar", "Constructi": "Brick/Mortar", "Date_Inspe": "03/31/09", "Depth": 7.92, "Infiltrati": "No", "Inflow": "No", "Lift_Stati": 7.0, "Manhole_Id": "LS7-048", "Rating": "Good", "SHAWN": 0.0, "LAYER": "SANMH", "FeatId1": 953.0 }, "geometry": { "type": "Point", "coordinates": [ -80.611997957403204, 28.321979245023208 ] } },
    { "type": "Feature", "properties": { "OBJECTID": 59, "Coating": "Mortar", "Constructi": "Brick/Mortar", "Date_Inspe": "03/31/09", "Depth": 7.17, "Infiltrati": "No", "Inflow": "No", "Lift_Stati": 7.0, "Manhole_Id": "LS7-049", "Rating": "Good", "SHAWN": 0.0, "LAYER": "SANMH", "FeatId1": 952.0 }, "geometry": { "type": "Point", "coordinates": [ -80.611963431309618, 28.322873557572212 ] } },
    { "type": "Feature", "properties": { "OBJECTID": 60, "Coating": "Mortar", "Constructi": "Brick/Mortar", "Date_Inspe": "03/31/09", "Depth": 6.0, "Infiltrati": "No", "Inflow": "No", "Lift_Stati": 7.0, "Manhole_Id": "LS7-050", "Rating": "Good", "SHAWN": 0.0, "LAYER": "SANMH", "FeatId1": 951.0 }, "geometry": { "type": "Point", "coordinates": [ -80.61192959618991, 28.323752077816266 ] } },
    { "type": "Feature", "properties": { "OBJECTID": 61, "Coating": "Mortar", "Constructi": "Brick/Mortar", "Date_Inspe": "03/31/09", "Depth": 4.75, "Infiltrati": "No", "Inflow": "No", "Lift_Stati": 7.0, "Manhole_Id": "LS7-051", "Rating": "Good", "SHAWN": 0.0, "LAYER": "SANMH", "FeatId1": 950.0 }, "geometry": { "type": "Point", "coordinates": [ -80.611900281857871, 28.324524252343966 ] } },
    { "type": "Feature", "properties": { "OBJECTID": 62, "Coating": "Mortar", "Constructi": "Brick/Mortar", "Date_Inspe": "3/31/09", "Depth": 3.67, "Infiltrati": "No", "Inflow": "No", "Lift_Stati": 7.0, "Manhole_Id": "LS7-052", "Rating": "Good", "SHAWN": 0.0, "LAYER": "SANMH", "FeatId1": 949.0 }, "geometry": { "type": "Point", "coordinates": [ -80.611870249308026, 28.32534690496993 ] } },
    { "type": "Feature", "properties": { "OBJECTID": 63, "Coating": "Mortar", "Constructi": "Brick/Mortar", "Date_Inspe": "03/31/09", "Depth": 4.0, "Infiltrati": "No", "Inflow": "No", "Lift_Stati": 7.0, "Manhole_Id": "LS7-044", "Rating": "Good", "SHAWN": 0.0, "LAYER": "SANMH", "FeatId1": 948.0 }, "geometry": { "type": "Point", "coordinates": [ -80.611317268130762, 28.318876269004626 ] } },
    { "type": "Feature", "properties": { "OBJECTID": 64, "Coating": "Mortar", "Constructi": "Brick/Mortar", "Date_Inspe": "04/06/09", "Depth": 9.92, "Infiltrati": "No", "Inflow": "No", "Lift_Stati": 7.0, "Manhole_Id": "LS7-063", "Rating": "Good", "SHAWN": 0.0, "LAYER": "SANMH", "FeatId1": 947.0 }, "geometry": { "type": "Point", "coordinates": [ -80.611118621768526, 28.320202446883719 ] } },
    { "type": "Feature", "properties": { "OBJECTID": 65, "Coating": "Mortar", "Constructi": "Brick/Mortar", "Date_Inspe": "04/06/09", "Depth": 7.83, "Infiltrati": "No", "Inflow": "No", "Lift_Stati": 7.0, "Manhole_Id": "LS7-062", "Rating": "Good", "SHAWN": 0.0, "LAYER": "SANMH", "FeatId1": 946.0 }, "geometry": { "type": "Point", "coordinates": [ -80.611087234122806, 28.321095312818596 ] } },
    { "type": "Feature", "properties": { "OBJECTID": 66, "Coating": "Mortar", "Constructi": "Brick/Mortar", "Date_Inspe": "04/06/09", "Depth": 7.92, "Infiltrati": "No", "Inflow": "No", "Lift_Stati": 7.0, "Manhole_Id": "LS7-061", "Rating": "Good", "SHAWN": 0.0, "LAYER": "SANMH", "FeatId1": 945.0 }, "geometry": { "type": "Point", "coordinates": [ -80.611058811300083, 28.321988455078923 ] } },
    { "type": "Feature", "properties": { "OBJECTID": 67, "Coating": "Mortar", "Constructi": "Brick/Mortar", "Date_Inspe": "04/06/09", "Depth": 6.92, "Infiltrati": "No", "Inflow": "No", "Lift_Stati": 7.0, "Manhole_Id": "LS7-060", "Rating": "Good", "SHAWN": 0.0, "LAYER": "SANMH", "FeatId1": 944.0 }, "geometry": { "type": "Point", "coordinates": [ -80.61103100414887, 28.322809792157834 ] } },
    { "type": "Feature", "properties": { "OBJECTID": 68, "Coating": "Mortar", "Constructi": "Brick/Mortar", "Date_Inspe": "04/06/09", "Depth": 5.5, "Infiltrati": "No", "Inflow": "No", "Lift_Stati": 7.0, "Manhole_Id": "LS7-059", "Rating": "Good", "SHAWN": 0.0, "LAYER": "SANMH", "FeatId1": 943.0 }, "geometry": { "type": "Point", "coordinates": [ -80.610961378541845, 28.323641067712892 ] } },
    { "type": "Feature", "properties": { "OBJECTID": 69, "Coating": "None", "Constructi": "Brick/Mortar", "Date_Inspe": "04/06/09", "Depth": 4.25, "Infiltrati": "Probable", "Inflow": "No", "Lift_Stati": 7.0, "Manhole_Id": "LS7-058", "Rating": "Poor", "SHAWN": 0.0, "LAYER": "SANMH", "FeatId1": 942.0 }, "geometry": { "type": "Point", "coordinates": [ -80.611003482045504, 28.323637006159768 ] } },
    { "type": "Feature", "properties": { "OBJECTID": 70, "Coating": "Mortar", "Constructi": "Brick/Mortar", "Date_Inspe": "04/06/09", "Depth": 5.25, "Infiltrati": "No", "Inflow": "No", "Lift_Stati": 7.0, "Manhole_Id": "LS7-057", "Rating": "Good", "SHAWN": 0.0, "LAYER": "SANMH", "FeatId1": 941.0 }, "geometry": { "type": "Point", "coordinates": [ -80.610959871034666, 28.324423458466079 ] } },
    { "type": "Feature", "properties": { "OBJECTID": 71, "Coating": "Mortar", "Constructi": "Brick/Mortar", "Date_Inspe": "04/06/09", "Depth": 3.83, "Infiltrati": "No", "Inflow": "No", "Lift_Stati": 7.0, "Manhole_Id": "LS7-056", "Rating": "Good", "SHAWN": 0.0, "LAYER": "SANMH", "FeatId1": 940.0 }, "geometry": { "type": "Point", "coordinates": [ -80.610931706619581, 28.325343024109767 ] } },
    { "type": "Feature", "properties": { "OBJECTID": 72, "Coating": "Mortar", "Constructi": "Brick/Mortar", "Date_Inspe": "04/07/09", "Depth": 4.08, "Infiltrati": "No", "Inflow": "No", "Lift_Stati": 7.0, "Manhole_Id": "LS7-064", "Rating": "Good", "SHAWN": 0.0, "LAYER": "SANMH", "FeatId1": 939.0 }, "geometry": { "type": "Point", "coordinates": [ -80.611150009937816, 28.319268626844366 ] } },
    { "type": "Feature", "properties": { "OBJECTID": 73, "Coating": "None", "Constructi": "Precast", "Date_Inspe": "12/31/08", "Depth": 5.25, "Infiltrati": "No", "Inflow": "Yes", "Lift_Stati": 15.0, "Manhole_Id": "LS15-022", "Rating": "Good", "SHAWN": 0.0, "LAYER": "SANMH", "FeatId1": 938.0 }, "geometry": { "type": "Point", "coordinates": [ -80.606910088021635, 28.272017669016929 ] } },
    { "type": "Feature", "properties": { "OBJECTID": 74, "Coating": "None", "Constructi": "Precast", "Date_Inspe": "12/31/08", "Depth": 3.17, "Infiltrati": "No", "Inflow": "No", "Lift_Stati": 15.0, "Manhole_Id": "LS15-001", "Rating": "Good", "SHAWN": 0.0, "LAYER": "SANMH", "FeatId1": 937.0 }, "geometry": { "type": "Point", "coordinates": [ -80.606653119798239, 28.272217926091795 ] } },
    { "type": "Feature", "properties": { "OBJECTID": 75, "Coating": "None", "Constructi": "Precast", "Date_Inspe": "12/30/08", "Depth": 4.67, "Infiltrati": "No", "Inflow": "No", "Lift_Stati": 15.0, "Manhole_Id": "LS15-003", "Rating": "Good", "SHAWN": 0.0, "LAYER": "SANMH", "FeatId1": 936.0 }, "geometry": { "type": "Point", "coordinates": [ -80.606713803989479, 28.27326181815171 ] } },
    { "type": "Feature", "properties": { "OBJECTID": 76, "Coating": "None", "Constructi": "Precast", "Date_Inspe": "12/31/08", "Depth": 4.75, "Infiltrati": "No", "Inflow": "No", "Lift_Stati": 15.0, "Manhole_Id": "LS15-004", "Rating": "Good", "SHAWN": 0.0, "LAYER": "SANMH", "FeatId1": 935.0 }, "geometry": { "type": "Point", "coordinates": [ -80.606752076936118, 28.273261853418195 ] } },
    { "type": "Feature", "properties": { "OBJECTID": 77, "Coating": "None", "Constructi": "Precast", "Date_Inspe": "12/30/08", "Depth": 4.17, "Infiltrati": "No", "Inflow": "No", "Lift_Stati": 15.0, "Manhole_Id": "LS15-051", "Rating": "Good", "SHAWN": 0.0, "LAYER": "SANMH", "FeatId1": 934.0 }, "geometry": { "type": "Point", "coordinates": [ -80.606263391115945, 28.27325895256746 ] } },
    { "type": "Feature", "properties": { "OBJECTID": 78, "Coating": "None", "Constructi": "Precast", "Date_Inspe": "12/31/08", "Depth": 6.75, "Infiltrati": "No", "Inflow": "No", "Lift_Stati": 15.0, "Manhole_Id": "LS15-024", "Rating": "Good", "SHAWN": 0.0, "LAYER": "SANMH", "FeatId1": 933.0 }, "geometry": { "type": "Point", "coordinates": [ -80.607004493154903, 28.273248012672873 ] } },
    { "type": "Feature", "properties": { "OBJECTID": 79, "Coating": "None", "Constructi": "Precast", "Date_Inspe": "12/30/08", "Depth": 5.33, "Infiltrati": "No", "Inflow": "No", "Lift_Stati": 15.0, "Manhole_Id": "LS15-023", "Rating": "Good", "SHAWN": 0.0, "LAYER": "SANMH", "FeatId1": 932.0 }, "geometry": { "type": "Point", "coordinates": [ -80.606946406154393, 28.27252157527138 ] } },
    { "type": "Feature", "properties": { "OBJECTID": 80, "Coating": "None", "Constructi": "Precast", "Date_Inspe": "12/31/08", "Depth": 4.5, "Infiltrati": "No", "Inflow": "No", "Lift_Stati": 15.0, "Manhole_Id": "LS15-002", "Rating": "Good", "SHAWN": 0.0, "LAYER": "SANMH", "FeatId1": 931.0 }, "geometry": { "type": "Point", "coordinates": [ -80.606716197305204, 28.273111384864059 ] } },
    { "type": "Feature", "properties": { "OBJECTID": 81, "Coating": "None", "Constructi": "Precast", "Date_Inspe": "12/31/08", "Depth": 5.42, "Infiltrati": "No", "Inflow": "No", "Lift_Stati": 15.0, "Manhole_Id": "LS15-005", "Rating": "Good", "SHAWN": 0.0, "LAYER": "SANMH", "FeatId1": 930.0 }, "geometry": { "type": "Point", "coordinates": [ -80.606812954355519, 28.274130537330514 ] } },
    { "type": "Feature", "properties": { "OBJECTID": 82, "Coating": "None", "Constructi": "Precast", "Date_Inspe": "12/31/08", "Depth": 7.0, "Infiltrati": "No", "Inflow": "No", "Lift_Stati": 15.0, "Manhole_Id": "LS15-025", "Rating": "Good", "SHAWN": 0.0, "LAYER": "SANMH", "FeatId1": 929.0 }, "geometry": { "type": "Point", "coordinates": [ -80.607076074372443, 28.274232749990286 ] } },
    { "type": "Feature", "properties": { "OBJECTID": 83, "Coating": "None", "Constructi": "Precast", "Date_Inspe": "12/31/08", "Depth": 9.08, "Infiltrati": "No", "Inflow": "No", "Lift_Stati": 15.0, "Manhole_Id": "LS15-026", "Rating": "Good", "SHAWN": 0.0, "LAYER": "SANMH", "FeatId1": 928.0 }, "geometry": { "type": "Point", "coordinates": [ -80.607138253697144, 28.275054697149248 ] } },
    { "type": "Feature", "properties": { "OBJECTID": 84, "Coating": "None", "Constructi": "Precast", "Date_Inspe": "12/31/08", "Depth": 6.67, "Infiltrati": "No", "Inflow": "No", "Lift_Stati": 15.0, "Manhole_Id": "LS15-006", "Rating": "Good", "SHAWN": 0.0, "LAYER": "SANMH", "FeatId1": 927.0 }, "geometry": { "type": "Point", "coordinates": [ -80.606882567507199, 28.27506628539787 ] } },
    { "type": "Feature", "properties": { "OBJECTID": 85, "Coating": "None", "Constructi": "Precast", "Date_Inspe": "12/31/08", "Depth": 6.67, "Infiltrati": "No", "Inflow": "No", "Lift_Stati": 15.0, "Manhole_Id": "LS15-007", "Rating": "Good", "SHAWN": 0.0, "LAYER": "SANMH", "FeatId1": 926.0 }, "geometry": { "type": "Point", "coordinates": [ -80.606913303907177, 28.27546960493541 ] } },
    { "type": "Feature", "properties": { "OBJECTID": 86, "Coating": "None", "Constructi": "Precast", "Date_Inspe": "12/31/08", "Depth": 10.17, "Infiltrati": "No", "Inflow": "No", "Lift_Stati": 15.0, "Manhole_Id": "LS15-027", "Rating": "Good", "SHAWN": 0.0, "LAYER": "SANMH", "FeatId1": 925.0 }, "geometry": { "type": "Point", "coordinates": [ -80.607206824992232, 28.27599166737728 ] } },
    { "type": "Feature", "properties": { "OBJECTID": 87, "Coating": "None", "Constructi": "Precast", "Date_Inspe": "13/31/08", "Depth": 8.42, "Infiltrati": "No", "Inflow": "No", "Lift_Stati": 15.0, "Manhole_Id": "LS15-008", "Rating": "Good", "SHAWN": 0.0, "LAYER": "SANMH", "FeatId1": 924.0 }, "geometry": { "type": "Point", "coordinates": [ -80.606954001073674, 28.276008187321722 ] } },
    { "type": "Feature", "properties": { "OBJECTID": 88, "Coating": "None", "Constructi": "Precast", "Date_Inspe": "01/05/09", "Depth": 10.5, "Infiltrati": "No", "Inflow": "No", "Lift_Stati": 15.0, "Manhole_Id": "LS15-039", "Rating": "Good", "SHAWN": 0.0, "LAYER": "SANMH", "FeatId1": 923.0 }, "geometry": { "type": "Point", "coordinates": [ -80.607253404908576, 28.276639827340187 ] } },
    { "type": "Feature", "properties": { "OBJECTID": 89, "Coating": "None", "Constructi": "Precast", "Date_Inspe": "01/05/09", "Depth": 4.5, "Infiltrati": "No", "Inflow": "No", "Lift_Stati": 15.0, "Manhole_Id": "LS15-050", "Rating": "Good", "SHAWN": 0.0, "LAYER": "SANMH", "FeatId1": 922.0 }, "geometry": { "type": "Point", "coordinates": [ -80.607589445689698, 28.276636235081067 ] } },
    { "type": "Feature", "properties": { "OBJECTID": 90, "Coating": "None", "Constructi": "Precast", "Date_Inspe": "01/05/09", "Depth": 7.17, "Infiltrati": "No", "Inflow": "No", "Lift_Stati": 15.0, "Manhole_Id": "LS15-015", "Rating": "Good", "SHAWN": 0.0, "LAYER": "SANMH", "FeatId1": 921.0 }, "geometry": { "type": "Point", "coordinates": [ -80.606998091071304, 28.276632809001569 ] } },
    { "type": "Feature", "properties": { "OBJECTID": 91, "Coating": "None", "Constructi": "Precast", "Date_Inspe": "01/05/09", "Depth": 5.67, "Infiltrati": "No", "Inflow": "No", "Lift_Stati": 15.0, "Manhole_Id": "LS15-014", "Rating": "Good", "SHAWN": 0.0, "LAYER": "SANMH", "FeatId1": 920.0 }, "geometry": { "type": "Point", "coordinates": [ -80.607040149024556, 28.277259926148215 ] } },
    { "type": "Feature", "properties": { "OBJECTID": 92, "Coating": "None", "Constructi": "Precast", "Date_Inspe": "01/05/09", "Depth": 11.33, "Infiltrati": "No", "Inflow": "No", "Lift_Stati": 15.0, "Manhole_Id": "LS15-038", "Rating": "Good", "SHAWN": 0.0, "LAYER": "SANMH", "FeatId1": 919.0 }, "geometry": { "type": "Point", "coordinates": [ -80.607299917535599, 28.277275069560567 ] } },
    { "type": "Feature", "properties": { "OBJECTID": 93, "Coating": "None", "Constructi": "Precast", "Date_Inspe": "01/05/09", "Depth": 5.5, "Infiltrati": "No", "Inflow": "Yes", "Lift_Stati": 15.0, "Manhole_Id": "LS15-049", "Rating": "Good", "SHAWN": 0.0, "LAYER": "SANMH", "FeatId1": 918.0 }, "geometry": { "type": "Point", "coordinates": [ -80.607557986004849, 28.277274610070503 ] } },
    { "type": "Feature", "properties": { "OBJECTID": 94, "Coating": "None", "Constructi": "Precast", "Date_Inspe": "01/05/09", "Depth": 3.92, "Infiltrati": "No", "Inflow": "No", "Lift_Stati": 15.0, "Manhole_Id": "LS15-020", "Rating": "Good", "SHAWN": 0.0, "LAYER": "SANMH", "FeatId1": 917.0 }, "geometry": { "type": "Point", "coordinates": [ -80.606672166877857, 28.277263512614148 ] } },
    { "type": "Feature", "properties": { "OBJECTID": 95, "Coating": "None", "Constructi": "Precast", "Date_Inspe": "01/05/09", "Depth": 6.0, "Infiltrati": "No", "Inflow": "No", "Lift_Stati": 15.0, "Manhole_Id": "LS15-013", "Rating": "Good", "SHAWN": 0.0, "LAYER": "SANMH", "FeatId1": 916.0 }, "geometry": { "type": "Point", "coordinates": [ -80.607097376165967, 28.27789839630092 ] } },
    { "type": "Feature", "properties": { "OBJECTID": 96, "Coating": "None", "Constructi": "Precast", "Date_Inspe": "01/05/09", "Depth": 6.25, "Infiltrati": "No", "Inflow": "No", "Lift_Stati": 15.0, "Manhole_Id": "LS15-019", "Rating": "Good", "SHAWN": 0.0, "LAYER": "SANMH", "FeatId1": 915.0 }, "geometry": { "type": "Point", "coordinates": [ -80.606661218802728, 28.277908469609088 ] } },
    { "type": "Feature", "properties": { "OBJECTID": 97, "Coating": "None", "Constructi": "Precast", "Date_Inspe": "01/05/09", "Depth": 12.25, "Infiltrati": "No", "Inflow": "No", "Lift_Stati": 15.0, "Manhole_Id": "LS15-037", "Rating": "Good", "SHAWN": 0.0, "LAYER": "SANMH", "FeatId1": 914.0 }, "geometry": { "type": "Point", "coordinates": [ -80.607346167880635, 28.277895804401986 ] } },
    { "type": "Feature", "properties": { "OBJECTID": 98, "Coating": "None", "Constructi": "Precast", "Date_Inspe": "01/07/09", "Depth": 5.25, "Infiltrati": "No", "Inflow": "Yes", "Lift_Stati": 15.0, "Manhole_Id": "LS15-048", "Rating": "Good", "SHAWN": 0.0, "LAYER": "SANMH", "FeatId1": 913.0 }, "geometry": { "type": "Point", "coordinates": [ -80.607713714687094, 28.277890607759005 ] } },
    { "type": "Feature", "properties": { "OBJECTID": 99, "Coating": "None", "Constructi": "Precast", "Date_Inspe": "01/06/09", "Depth": 13.5, "Infiltrati": "No", "Inflow": "No", "Lift_Stati": 15.0, "Manhole_Id": "LS15-036", "Rating": "Good", "SHAWN": 0.0, "LAYER": "SANMH", "FeatId1": 912.0 }, "geometry": { "type": "Point", "coordinates": [ -80.607395659220828, 28.27854849340661 ] } },
    { "type": "Feature", "properties": { "OBJECTID": 100, "Coating": "None", "Constructi": "Precast", "Date_Inspe": "01/06/09", "Depth": 13.33, "Infiltrati": "No", "Inflow": "No", "Lift_Stati": 15.0, "Manhole_Id": "LS15-035", "Rating": "Good", "SHAWN": 0.0, "LAYER": "SANMH", "FeatId1": 911.0 }, "geometry": { "type": "Point", "coordinates": [ -80.607483483234788, 28.279749479673917 ] } },
    { "type": "Feature", "properties": { "OBJECTID": 101, "Coating": "None", "Constructi": "Precast", "Date_Inspe": "01/06/09", "Depth": 3.58, "Infiltrati": "No", "Inflow": "No", "Lift_Stati": 15.0, "Manhole_Id": "LS15-047", "Rating": "Good", "SHAWN": 0.0, "LAYER": "SANMH", "FeatId1": 910.0 }, "geometry": { "type": "Point", "coordinates": [ -80.607991232705785, 28.279751622548076 ] } },
    { "type": "Feature", "properties": { "OBJECTID": 102, "Coating": "None", "Constructi": "Precast", "Date_Inspe": "01/06/09", "Depth": 2.92, "Infiltrati": "No", "Inflow": "No", "Lift_Stati": 15.0, "Manhole_Id": "LS15-018", "Rating": "Good", "SHAWN": 0.0, "LAYER": "SANMH", "FeatId1": 909.0 }, "geometry": { "type": "Point", "coordinates": [ -80.606808843731613, 28.279756720310303 ] } },
    { "type": "Feature", "properties": { "OBJECTID": 103, "Coating": "None", "Constructi": "Precast", "Date_Inspe": "01/06/09", "Depth": 5.08, "Infiltrati": "No", "Inflow": "No", "Lift_Stati": 15.0, "Manhole_Id": "LS15-010", "Rating": "Good", "SHAWN": 0.0, "LAYER": "SANMH", "FeatId1": 908.0 }, "geometry": { "type": "Point", "coordinates": [ -80.607271179728357, 28.280396170587593 ] } },
    { "type": "Feature", "properties": { "OBJECTID": 104, "Coating": "None", "Constructi": "Precast", "Date_Inspe": "01/06/09", "Depth": 4.75, "Infiltrati": "No", "Inflow": "No", "Lift_Stati": 15.0, "Manhole_Id": "LS15-017", "Rating": "Good", "SHAWN": 0.0, "LAYER": "SANMH", "FeatId1": 907.0 }, "geometry": { "type": "Point", "coordinates": [ -80.606887976411741, 28.280398545223367 ] } },
    { "type": "Feature", "properties": { "OBJECTID": 105, "Coating": "None", "Constructi": "Precast", "Date_Inspe": "01/06/09", "Depth": 12.58, "Infiltrati": "No", "Inflow": "No", "Lift_Stati": 15.0, "Manhole_Id": "LS15-034", "Rating": "Good", "SHAWN": 0.0, "LAYER": "SANMH", "FeatId1": 906.0 }, "geometry": { "type": "Point", "coordinates": [ -80.607530090035908, 28.280385291215257 ] } },
    { "type": "Feature", "properties": { "OBJECTID": 106, "Coating": "None", "Constructi": "Precast", "Date_Inspe": "01/06/09", "Depth": 5.42, "Infiltrati": "No", "Inflow": "No", "Lift_Stati": 15.0, "Manhole_Id": "LS15-046", "Rating": "Good", "SHAWN": 0.0, "LAYER": "SANMH", "FeatId1": 905.0 }, "geometry": { "type": "Point", "coordinates": [ -80.608219043043334, 28.280396738343732 ] } },
    { "type": "Feature", "properties": { "OBJECTID": 107, "Coating": "None", "Constructi": "Precast", "Date_Inspe": "01/06/09", "Depth": 4.25, "Infiltrati": "No", "Inflow": "No", "Lift_Stati": 15.0, "Manhole_Id": "LS15-009", "Rating": "Good", "SHAWN": 0.0, "LAYER": "SANMH", "FeatId1": 904.0 }, "geometry": { "type": "Point", "coordinates": [ -80.60731327595667, 28.281036958388423 ] } },
    { "type": "Feature", "properties": { "OBJECTID": 108, "Coating": "None", "Constructi": "Precast", "Date_Inspe": "01/06/09", "Depth": 12.83, "Infiltrati": "No", "Inflow": "No", "Lift_Stati": 15.0, "Manhole_Id": "LS15-033", "Rating": "Good", "SHAWN": 0.0, "LAYER": "SANMH", "FeatId1": 903.0 }, "geometry": { "type": "Point", "coordinates": [ -80.607572987416034, 28.281029577042709 ] } },
    { "type": "Feature", "properties": { "OBJECTID": 109, "Coating": "None", "Constructi": "Precast", "Date_Inspe": "01/06/09", "Depth": 5.5, "Infiltrati": "No", "Inflow": "No", "Lift_Stati": 15.0, "Manhole_Id": "LS15-045", "Rating": "Good", "SHAWN": 0.0, "LAYER": "SANMH", "FeatId1": 902.0 }, "geometry": { "type": "Point", "coordinates": [ -80.608328661077593, 28.281031119551454 ] } },
    { "type": "Feature", "properties": { "OBJECTID": 110, "Coating": "None", "Constructi": "Precast", "Date_Inspe": "01/06/09", "Depth": 12.0, "Infiltrati": "No", "Inflow": "No", "Lift_Stati": 15.0, "Manhole_Id": "LS15-032", "Rating": "Good", "SHAWN": 0.0, "LAYER": "SANMH", "FeatId1": 901.0 }, "geometry": { "type": "Point", "coordinates": [ -80.607617150284014, 28.281669415931773 ] } },
    { "type": "Feature", "properties": { "OBJECTID": 111, "Coating": "None", "Constructi": "Precast", "Date_Inspe": "01/06/09", "Depth": 4.67, "Infiltrati": "No", "Inflow": "No", "Lift_Stati": 15.0, "Manhole_Id": "LS15-044", "Rating": "Good", "SHAWN": 0.0, "LAYER": "SANMH", "FeatId1": 900.0 }, "geometry": { "type": "Point", "coordinates": [ -80.608588371823586, 28.281664845215861 ] } },
    { "type": "Feature", "properties": { "OBJECTID": 112, "Coating": "None", "Constructi": "Precast", "Date_Inspe": "01/06/09", "Depth": 3.42, "Infiltrati": "No", "Inflow": "No", "Lift_Stati": 15.0, "Manhole_Id": "LS15-054", "Rating": "Good", "SHAWN": 0.0, "LAYER": "SANMH", "FeatId1": 899.0 }, "geometry": { "type": "Point", "coordinates": [ -80.607337455491475, 28.281668519292261 ] } },
    { "type": "Feature", "properties": { "OBJECTID": 113, "Coating": "None", "Constructi": "Precast", "Date_Inspe": "01/06/09", "Depth": 5.17, "Infiltrati": "No", "Inflow": "No", "Lift_Stati": 15.0, "Manhole_Id": "LS15-055", "Rating": "Good", "SHAWN": 0.0, "LAYER": "SANMH", "FeatId1": 898.0 }, "geometry": { "type": "Point", "coordinates": [ -80.606962608123084, 28.281664039335187 ] } },
    { "type": "Feature", "properties": { "OBJECTID": 114, "Coating": "None", "Constructi": "Precast", "Date_Inspe": "01/07/09", "Depth": 12.33, "Infiltrati": "No", "Inflow": "No", "Lift_Stati": 15.0, "Manhole_Id": "LS15-031", "Rating": "Good", "SHAWN": 0.0, "LAYER": "SANMH", "FeatId1": 897.0 }, "geometry": { "type": "Point", "coordinates": [ -80.607653538838747, 28.282279234841752 ] } },
    { "type": "Feature", "properties": { "OBJECTID": 115, "Coating": "None", "Constructi": "Precast", "Date_Inspe": "01/07/09", "Depth": 3.33, "Infiltrati": "No", "Inflow": "No", "Lift_Stati": 15.0, "Manhole_Id": "LS15-043", "Rating": "Good", "SHAWN": 0.0, "LAYER": "SANMH", "FeatId1": 896.0 }, "geometry": { "type": "Point", "coordinates": [ -80.608847015925903, 28.282284720383402 ] } },
    { "type": "Feature", "properties": { "OBJECTID": 116, "Coating": "None", "Constructi": "Precast", "Date_Inspe": "01/26/09", "Depth": 4.92, "Infiltrati": "No", "Inflow": "No", "Lift_Stati": 14.0, "Manhole_Id": "LS14-071", "Rating": "Good", "SHAWN": 0.0, "LAYER": "SANMH", "FeatId1": 895.0 }, "geometry": { "type": "Point", "coordinates": [ -80.607419378622978, 28.283115805174681 ] } },
    { "type": "Feature", "properties": { "OBJECTID": 117, "Coating": "None", "Constructi": "Precast", "Date_Inspe": "01/07/09", "Depth": 11.75, "Infiltrati": "No", "Inflow": "No", "Lift_Stati": 15.0, "Manhole_Id": "LS15-030", "Rating": "Good", "SHAWN": 0.0, "LAYER": "SANMH", "FeatId1": 894.0 }, "geometry": { "type": "Point", "coordinates": [ -80.607706933542232, 28.283071085844345 ] } },
    { "type": "Feature", "properties": { "OBJECTID": 118, "Coating": "None", "Constructi": "Precast", "Date_Inspe": "01/07/09", "Depth": 2.75, "Infiltrati": "No", "Inflow": "No", "Lift_Stati": 15.0, "Manhole_Id": "LS15-042", "Rating": "Good", "SHAWN": 0.0, "LAYER": "SANMH", "FeatId1": 893.0 }, "geometry": { "type": "Point", "coordinates": [ -80.609006006588558, 28.283067629370365 ] } },
    { "type": "Feature", "properties": { "OBJECTID": 119, "Coating": "None", "Constructi": "Precast", "Date_Inspe": "00/00/00", "Depth": 0.0, "Infiltrati": "No", "Inflow": "No", "Lift_Stati": 15.0, "Manhole_Id": "LS15-041", "Rating": "Good", "SHAWN": 0.0, "LAYER": "SANMH", "FeatId1": 892.0 }, "geometry": { "type": "Point", "coordinates": [ -80.609427535043466, 28.283116031194474 ] } },
    { "type": "Feature", "properties": { "OBJECTID": 120, "Coating": "None", "Constructi": "Precast", "Date_Inspe": "01/07/09", "Depth": 11.0, "Infiltrati": "No", "Inflow": "No", "Lift_Stati": 15.0, "Manhole_Id": "LS15-029", "Rating": "Good", "SHAWN": 0.0, "LAYER": "SANMH", "FeatId1": 891.0 }, "geometry": { "type": "Point", "coordinates": [ -80.607733692723954, 28.283539641202815 ] } },
    { "type": "Feature", "properties": { "OBJECTID": 121, "Coating": "None", "Constructi": "Brick/Mortar", "Date_Inspe": "03/24/09", "Depth": 11.0, "Infiltrati": "No", "Inflow": "No", "Lift_Stati": 15.0, "Manhole_Id": "LS15-060", "Rating": "Good", "SHAWN": 0.0, "LAYER": "SANMH", "FeatId1": 890.0 }, "geometry": { "type": "Point", "coordinates": [ -80.609403255036739, 28.283622901260593 ] } },
    { "type": "Feature", "properties": { "OBJECTID": 122, "Coating": "None", "Constructi": "Brick/Mortar", "Date_Inspe": "03/24/09", "Depth": 11.0, "Infiltrati": "No", "Inflow": "No", "Lift_Stati": 7.0, "Manhole_Id": "LS15-059", "Rating": "Good", "SHAWN": 0.0, "LAYER": "SANMH", "FeatId1": 889.0 }, "geometry": { "type": "Point", "coordinates": [ -80.608963054289362, 28.283632350254049 ] } },
    { "type": "Feature", "properties": { "OBJECTID": 123, "Coating": "None", "Constructi": "Brick/Mortar", "Date_Inspe": "03/24/09", "Depth": 11.0, "Infiltrati": "No", "Inflow": "No", "Lift_Stati": 15.0, "Manhole_Id": "LS15-058", "Rating": "Good", "SHAWN": 0.0, "LAYER": "SANMH", "FeatId1": 888.0 }, "geometry": { "type": "Point", "coordinates": [ -80.60789097246645, 28.283629413230798 ] } },
    { "type": "Feature", "properties": { "OBJECTID": 124, "Coating": "None", "Constructi": "Precast", "Date_Inspe": "01/26/09", "Depth": 8.5, "Infiltrati": "No", "Inflow": "No", "Lift_Stati": 14.0, "Manhole_Id": "LS14-073", "Rating": "Good", "SHAWN": 0.0, "LAYER": "SANMH", "FeatId1": 887.0 }, "geometry": { "type": "Point", "coordinates": [ -80.607646326179136, 28.286019459100512 ] } },
    { "type": "Feature", "properties": { "OBJECTID": 125, "Coating": "None", "Constructi": "Precast", "Date_Inspe": "01/26/09", "Depth": 4.17, "Infiltrati": "No", "Inflow": "No", "Lift_Stati": 14.0, "Manhole_Id": "LS14-068", "Rating": "Good", "SHAWN": 0.0, "LAYER": "SANMH", "FeatId1": 886.0 }, "geometry": { "type": "Point", "coordinates": [ -80.608150432220683, 28.286009144704249 ] } },
    { "type": "Feature", "properties": { "OBJECTID": 126, "Coating": "None", "Constructi": "Precast", "Date_Inspe": "01/26/09", "Depth": 4.08, "Infiltrati": "No", "Inflow": "No", "Lift_Stati": 14.0, "Manhole_Id": "LS14-066", "Rating": "Good", "SHAWN": 0.0, "LAYER": "SANMH", "FeatId1": 885.0 }, "geometry": { "type": "Point", "coordinates": [ -80.608565039078997, 28.28596606312226 ] } },
    { "type": "Feature", "properties": { "OBJECTID": 127, "Coating": "Spectrashield", "Constructi": "Fiberglass", "Date_Inspe": "09/11/13", "Depth": 4.75, "Infiltrati": "No", "Inflow": "No", "Lift_Stati": 14.0, "Manhole_Id": "LS14-059", "Rating": "Good", "SHAWN": 0.0, "LAYER": "SANMH", "FeatId1": 884.0 }, "geometry": { "type": "Point", "coordinates": [ -80.608809786391404, 28.285949750066049 ] } },
    { "type": "Feature", "properties": { "OBJECTID": 128, "Coating": "None", "Constructi": "Brick/Mortar", "Date_Inspe": "03/24/09", "Depth": 11.0, "Infiltrati": "No", "Inflow": "No", "Lift_Stati": 14.0, "Manhole_Id": "LS14-085", "Rating": "Good", "SHAWN": 0.0, "LAYER": "SANMH", "FeatId1": 883.0 }, "geometry": { "type": "Point", "coordinates": [ -80.609454609038821, 28.286580160858556 ] } },
    { "type": "Feature", "properties": { "OBJECTID": 129, "Coating": "None", "Constructi": "Precast", "Date_Inspe": "04/23/09", "Depth": 3.83, "Infiltrati": "No", "Inflow": "Yes", "Lift_Stati": 14.0, "Manhole_Id": "LS14-083", "Rating": "Good", "SHAWN": 0.0, "LAYER": "SANMH", "FeatId1": 882.0 }, "geometry": { "type": "Point", "coordinates": [ -80.609458279235767, 28.286690979068215 ] } },
    { "type": "Feature", "properties": { "OBJECTID": 130, "Coating": "Mortar", "Constructi": "Brick/Mortar", "Date_Inspe": "01/22/09", "Depth": 4.67, "Infiltrati": "No", "Inflow": "No", "Lift_Stati": 14.0, "Manhole_Id": "LS14-053", "Rating": "Good", "SHAWN": 0.0, "LAYER": "SANMH", "FeatId1": 881.0 }, "geometry": { "type": "Point", "coordinates": [ -80.610389456004228, 28.286589794292617 ] } },
    { "type": "Feature", "properties": { "OBJECTID": 131, "Coating": "None", "Constructi": "Brick/Mortar", "Date_Inspe": "03/24/09", "Depth": 11.0, "Infiltrati": "No", "Inflow": "No", "Lift_Stati": 14.0, "Manhole_Id": "LS14-084", "Rating": "Good", "SHAWN": 0.0, "LAYER": "SANMH", "FeatId1": 880.0 }, "geometry": { "type": "Point", "coordinates": [ -80.609038583093152, 28.286123458518443 ] } },
    { "type": "Feature", "properties": { "OBJECTID": 132, "Coating": "None", "Constructi": "Brick/Mortar", "Date_Inspe": "03/24/09", "Depth": 11.0, "Infiltrati": "No", "Inflow": "No", "Lift_Stati": 14.0, "Manhole_Id": "LS4-086", "Rating": "Good", "SHAWN": 0.0, "LAYER": "SANMH", "FeatId1": 879.0 }, "geometry": { "type": "Point", "coordinates": [ -80.609844267341714, 28.286539096672318 ] } },
    { "type": "Feature", "properties": { "OBJECTID": 133, "Coating": "Spectrashield", "Constructi": "Brick/Mortar", "Date_Inspe": "09/11/13", "Depth": 5.08, "Infiltrati": "No", "Inflow": "No", "Lift_Stati": 14.0, "Manhole_Id": "LS14-058", "Rating": "Good", "SHAWN": 0.0, "LAYER": "SANMH", "FeatId1": 878.0 }, "geometry": { "type": "Point", "coordinates": [ -80.609143279134699, 28.286725283179376 ] } },
    { "type": "Feature", "properties": { "OBJECTID": 134, "Coating": "None", "Constructi": "Precast", "Date_Inspe": "01/26/09", "Depth": 4.5, "Infiltrati": "No", "Inflow": "No", "Lift_Stati": 14.0, "Manhole_Id": "LS14-061", "Rating": "Good", "SHAWN": 0.0, "LAYER": "SANMH", "FeatId1": 877.0 }, "geometry": { "type": "Point", "coordinates": [ -80.608914081742299, 28.286749823153613 ] } },
    { "type": "Feature", "properties": { "OBJECTID": 135, "Coating": "None", "Constructi": "Precast", "Date_Inspe": "01/26/09", "Depth": 8.75, "Infiltrati": "No", "Inflow": "No", "Lift_Stati": 14.0, "Manhole_Id": "LS14-062", "Rating": "Good", "SHAWN": 0.0, "LAYER": "SANMH", "FeatId1": 876.0 }, "geometry": { "type": "Point", "coordinates": [ -80.607983533303752, 28.286847943316825 ] } },
    { "type": "Feature", "properties": { "OBJECTID": 136, "Coating": "None", "Constructi": "Precast", "Date_Inspe": "01/26/09", "Depth": 8.83, "Infiltrati": "No", "Inflow": "No", "Lift_Stati": 14.0, "Manhole_Id": "LS14-074", "Rating": "Good", "SHAWN": 0.0, "LAYER": "SANMH", "FeatId1": 875.0 }, "geometry": { "type": "Point", "coordinates": [ -80.607748317251151, 28.28687207784095 ] } },
    { "type": "Feature", "properties": { "OBJECTID": 137, "Coating": "None", "Constructi": "Precast", "Date_Inspe": "04/23/09", "Depth": 4.0, "Infiltrati": "No", "Inflow": "No", "Lift_Stati": 14.0, "Manhole_Id": "LS14-082", "Rating": "Good", "SHAWN": 0.0, "LAYER": "SANMH", "FeatId1": 874.0 }, "geometry": { "type": "Point", "coordinates": [ -80.608414337908926, 28.287544558853799 ] } },
    { "type": "Feature", "properties": { "OBJECTID": 138, "Coating": "None", "Constructi": "Precast", "Date_Inspe": "01/27/09", "Depth": 3.67, "Infiltrati": "No", "Inflow": "Yes", "Lift_Stati": 14.0, "Manhole_Id": "LS14-065", "Rating": "Good", "SHAWN": 0.0, "LAYER": "SANMH", "FeatId1": 873.0 }, "geometry": { "type": "Point", "coordinates": [ -80.609096694886091, 28.287474429265973 ] } },
    { "type": "Feature", "properties": { "OBJECTID": 139, "Coating": "None", "Constructi": "Precast", "Date_Inspe": "01/21/09", "Depth": 3.75, "Infiltrati": "No", "Inflow": "No", "Lift_Stati": 14.0, "Manhole_Id": "LS14-057", "Rating": "Good", "SHAWN": 0.0, "LAYER": "SANMH", "FeatId1": 872.0 }, "geometry": { "type": "Point", "coordinates": [ -80.609626454008122, 28.28745271824863 ] } },
    { "type": "Feature", "properties": { "OBJECTID": 140, "Coating": "Spectrashield", "Constructi": "Brick/Mortar", "Date_Inspe": "09/11/13", "Depth": 5.17, "Infiltrati": "No", "Inflow": "No", "Lift_Stati": 14.0, "Manhole_Id": "LS14-054", "Rating": "Good", "SHAWN": 0.0, "LAYER": "SANMH", "FeatId1": 871.0 }, "geometry": { "type": "Point", "coordinates": [ -80.61037421532761, 28.287398325181005 ] } },
    { "type": "Feature", "properties": { "OBJECTID": 141, "Coating": "None", "Constructi": "Precast", "Date_Inspe": "01/26/09", "Depth": 6.33, "Infiltrati": "No", "Inflow": "No", "Lift_Stati": 14.0, "Manhole_Id": "LS14-072", "Rating": "Good", "SHAWN": 0.0, "LAYER": "SANMH", "FeatId1": 870.0 }, "geometry": { "type": "Point", "coordinates": [ -80.607543040852377, 28.284795733993441 ] } },
    { "type": "Feature", "properties": { "OBJECTID": 142, "Coating": " ", "Constructi": " ", "Date_Inspe": " ", "Depth": 0.0, "Infiltrati": " ", "Inflow": " ", "Lift_Stati": 14.0, "Manhole_Id": "LS14-084", "Rating": " ", "SHAWN": 0.0, "LAYER": "SANMH", "FeatId1": 869.0 }, "geometry": { "type": "Point", "coordinates": [ -80.607586899615967, 28.285131782587868 ] } },
    { "type": "Feature", "properties": { "OBJECTID": 143, "Coating": "None", "Constructi": "Fiberglass", "Date_Inspe": "01/22/09", "Depth": 3.5, "Infiltrati": "Probable", "Inflow": "No", "Lift_Stati": 14.0, "Manhole_Id": "LS14-060", "Rating": "Fair", "SHAWN": 0.0, "LAYER": "SANMH", "FeatId1": 868.0 }, "geometry": { "type": "Point", "coordinates": [ -80.60843937509874, 28.285264983677465 ] } },
    { "type": "Feature", "properties": { "OBJECTID": 144, "Coating": "None", "Constructi": "Precast", "Date_Inspe": "01/22/09", "Depth": 5.75, "Infiltrati": "No", "Inflow": "No", "Lift_Stati": 14.0, "Manhole_Id": "LS14-051", "Rating": "Good", "SHAWN": 0.0, "LAYER": "SANMH", "FeatId1": 867.0 }, "geometry": { "type": "Point", "coordinates": [ -80.610795076636578, 28.287124588485757 ] } },
    { "type": "Feature", "properties": { "OBJECTID": 145, "Coating": "None", "Constructi": "Precast", "Date_Inspe": "01/22/09", "Depth": 4.6, "Infiltrati": "No", "Inflow": "No", "Lift_Stati": 14.0, "Manhole_Id": "LS14-050", "Rating": "Good", "SHAWN": 0.0, "LAYER": "SANMH", "FeatId1": 866.0 }, "geometry": { "type": "Point", "coordinates": [ -80.611509310750776, 28.288017108159877 ] } },
    { "type": "Feature", "properties": { "OBJECTID": 146, "Coating": "None", "Constructi": "Precast", "Date_Inspe": "01/22/09", "Depth": 2.67, "Infiltrati": "No", "Inflow": "No", "Lift_Stati": 14.0, "Manhole_Id": "LS14-049", "Rating": "Good", "SHAWN": 0.0, "LAYER": "SANMH", "FeatId1": 865.0 }, "geometry": { "type": "Point", "coordinates": [ -80.612125236027694, 28.288973321297576 ] } },
    { "type": "Feature", "properties": { "OBJECTID": 147, "Coating": "None", "Constructi": "Precast", "Date_Inspe": "01/27/09", "Depth": 5.67, "Infiltrati": "No", "Inflow": "No", "Lift_Stati": 14.0, "Manhole_Id": "LS14-063", "Rating": "Good", "SHAWN": 0.0, "LAYER": "SANMH", "FeatId1": 864.0 }, "geometry": { "type": "Point", "coordinates": [ -80.608540076481873, 28.288391979609365 ] } },
    { "type": "Feature", "properties": { "OBJECTID": 148, "Coating": "None", "Constructi": "Precast", "Date_Inspe": "01/20/09", "Depth": 9.92, "Infiltrati": "No", "Inflow": "No", "Lift_Stati": 14.0, "Manhole_Id": "LS14-043", "Rating": "Good", "SHAWN": 0.0, "LAYER": "SANMH", "FeatId1": 863.0 }, "geometry": { "type": "Point", "coordinates": [ -80.609400608213591, 28.289935878785677 ] } },
    { "type": "Feature", "properties": { "OBJECTID": 149, "Coating": "None", "Constructi": "Precast", "Date_Inspe": "01/20/09", "Depth": 3.92, "Infiltrati": "No", "Inflow": "No", "Lift_Stati": 14.0, "Manhole_Id": "LS14-044", "Rating": "Good", "SHAWN": 0.0, "LAYER": "SANMH", "FeatId1": 862.0 }, "geometry": { "type": "Point", "coordinates": [ -80.609781799480047, 28.289876525758238 ] } },
    { "type": "Feature", "properties": { "OBJECTID": 150, "Coating": "None", "Constructi": "Precast", "Date_Inspe": "01/27/09", "Depth": 3.5, "Infiltrati": "No", "Inflow": "No", "Lift_Stati": 14.0, "Manhole_Id": "LS14-064", "Rating": "Good", "SHAWN": 0.0, "LAYER": "SANMH", "FeatId1": 861.0 }, "geometry": { "type": "Point", "coordinates": [ -80.609169360398269, 28.288320981096795 ] } },
    { "type": "Feature", "properties": { "OBJECTID": 151, "Coating": "None", "Constructi": "Precast", "Date_Inspe": "01/22/09", "Depth": 4.83, "Infiltrati": "No", "Inflow": "No", "Lift_Stati": 14.0, "Manhole_Id": "LS14-056", "Rating": "Good", "SHAWN": 0.0, "LAYER": "SANMH", "FeatId1": 860.0 }, "geometry": { "type": "Point", "coordinates": [ -80.609589878719689, 28.288283100229425 ] } },
    { "type": "Feature", "properties": { "OBJECTID": 152, "Coating": "None", "Constructi": "Brick/Mortar", "Date_Inspe": "01/22/09", "Depth": 5.75, "Infiltrati": "No", "Inflow": "Yes", "Lift_Stati": 14.0, "Manhole_Id": "LS14-055", "Rating": "Good", "SHAWN": 0.0, "LAYER": "SANMH", "FeatId1": 859.0 }, "geometry": { "type": "Point", "coordinates": [ -80.610355488049336, 28.288211296523823 ] } },
    { "type": "Feature", "properties": { "OBJECTID": 153, "Coating": "None", "Constructi": "Precast", "Date_Inspe": "01/21/09", "Depth": 8.75, "Infiltrati": "No", "Inflow": "No", "Lift_Stati": 14.0, "Manhole_Id": "LS14-045", "Rating": "Good", "SHAWN": 0.0, "LAYER": "SANMH", "FeatId1": 858.0 }, "geometry": { "type": "Point", "coordinates": [ -80.609386110425433, 28.289277416209025 ] } },
    { "type": "Feature", "properties": { "OBJECTID": 154, "Coating": "None", "Constructi": "Precast", "Date_Inspe": "01/22/09", "Depth": 7.08, "Infiltrati": "No", "Inflow": "Yes", "Lift_Stati": 14.0, "Manhole_Id": "LS14-046", "Rating": "Good", "SHAWN": 0.0, "LAYER": "SANMH", "FeatId1": 857.0 }, "geometry": { "type": "Point", "coordinates": [ -80.610363104640257, 28.289272048569469 ] } },
    { "type": "Feature", "properties": { "OBJECTID": 155, "Coating": "None", "Constructi": "Unknown", "Date_Inspe": "00/00/00", "Depth": 0.0, "Infiltrati": "Unknown", "Inflow": "Unknown", "Lift_Stati": 14.0, "Manhole_Id": "LS14-047", "Rating": "Buried", "SHAWN": 0.0, "LAYER": "SANMH", "FeatId1": 856.0 }, "geometry": { "type": "Point", "coordinates": [ -80.611456246243577, 28.289276509095625 ] } },
    { "type": "Feature", "properties": { "OBJECTID": 156, "Coating": "None", "Constructi": "Brick/Mortar", "Date_Inspe": "02/09/09", "Depth": 1.25, "Infiltrati": "No", "Inflow": "No", "Lift_Stati": 3.0, "Manhole_Id": "LS3-026", "Rating": "Good", "SHAWN": 0.0, "LAYER": "SANMH", "FeatId1": 855.0 }, "geometry": { "type": "Point", "coordinates": [ -80.608841454458016, 28.289867207902926 ] } },
    { "type": "Feature", "properties": { "OBJECTID": 157, "Coating": "None", "Constructi": "Brick/Mortar", "Date_Inspe": "00/00/00", "Depth": 0.0, "Infiltrati": "No", "Inflow": "No", "Lift_Stati": 3.0, "Manhole_Id": "LS3-027", "Rating": "Good", "SHAWN": 0.0, "LAYER": "SANMH", "FeatId1": 854.0 }, "geometry": { "type": "Point", "coordinates": [ -80.608843745167235, 28.289753134803387 ] } },
    { "type": "Feature", "properties": { "OBJECTID": 158, "Coating": "None", "Constructi": "Brick/Mortar", "Date_Inspe": "02/04/09", "Depth": 2.08, "Infiltrati": "No", "Inflow": "No", "Lift_Stati": 3.0, "Manhole_Id": "LS3-013", "Rating": "Good", "SHAWN": 0.0, "LAYER": "SANMH", "FeatId1": 853.0 }, "geometry": { "type": "Point", "coordinates": [ -80.608111706309671, 28.28983857357164 ] } },
    { "type": "Feature", "properties": { "OBJECTID": 159, "Coating": "None", "Constructi": "Precast", "Date_Inspe": "01/27/09", "Depth": 6.5, "Infiltrati": "No", "Inflow": "No", "Lift_Stati": 14.0, "Manhole_Id": "LS14-075", "Rating": "Good", "SHAWN": 0.0, "LAYER": "SANMH", "FeatId1": 852.0 }, "geometry": { "type": "Point", "coordinates": [ -80.6080329691831, 28.289036615457348 ] } },
    { "type": "Feature", "properties": { "OBJECTID": 160, "Coating": " ", "Constructi": " ", "Date_Inspe": " ", "Depth": 0.0, "Infiltrati": " ", "Inflow": " ", "Lift_Stati": 14.0, "Manhole_Id": "LS14-079", "Rating": " ", "SHAWN": 0.0, "LAYER": "SANMH", "FeatId1": 851.0 }, "geometry": { "type": "Point", "coordinates": [ -80.607482610833358, 28.283969144126839 ] } },
    { "type": "Feature", "properties": { "OBJECTID": 161, "Coating": "None", "Constructi": "Precast", "Date_Inspe": "01/07/09", "Depth": 8.42, "Infiltrati": "No", "Inflow": "No", "Lift_Stati": 15.0, "Manhole_Id": "LS15-028", "Rating": "Good", "SHAWN": 0.0, "LAYER": "SANMH", "FeatId1": 850.0 }, "geometry": { "type": "Point", "coordinates": [ -80.607953538502699, 28.284438901930631 ] } },
    { "type": "Feature", "properties": { "OBJECTID": 162, "Coating": "None", "Constructi": "Brick/Mortar", "Date_Inspe": "01/08/09", "Depth": 4.42, "Infiltrati": "No", "Inflow": "No", "Lift_Stati": 15.0, "Manhole_Id": "LS15-057", "Rating": "Good", "SHAWN": 0.0, "LAYER": "SANMH", "FeatId1": 849.0 }, "geometry": { "type": "Point", "coordinates": [ -80.60895818082561, 28.284439515870524 ] } },
    { "type": "Feature", "properties": { "OBJECTID": 163, "Coating": "None", "Constructi": "Precast", "Date_Inspe": "01/13/09", "Depth": 10.0, "Infiltrati": "No", "Inflow": "No", "Lift_Stati": 14.0, "Manhole_Id": "LS14-019", "Rating": "Good", "SHAWN": 0.0, "LAYER": "SANMH", "FeatId1": 848.0 }, "geometry": { "type": "Point", "coordinates": [ -80.610366334915497, 28.294259688330403 ] } },
    { "type": "Feature", "properties": { "OBJECTID": 164, "Coating": "None", "Constructi": "Precast", "Date_Inspe": "01/12/09", "Depth": 9.42, "Infiltrati": "No", "Inflow": "No", "Lift_Stati": 14.0, "Manhole_Id": "LS14-004", "Rating": "Good", "SHAWN": 0.0, "LAYER": "SANMH", "FeatId1": 847.0 }, "geometry": { "type": "Point", "coordinates": [ -80.610388965721597, 28.295144536124415 ] } },
    { "type": "Feature", "properties": { "OBJECTID": 165, "Coating": "None", "Constructi": "Precast", "Date_Inspe": "01/12/09", "Depth": 7.67, "Infiltrati": "No", "Inflow": "No", "Lift_Stati": 14.0, "Manhole_Id": "LS14-005", "Rating": "Good", "SHAWN": 0.0, "LAYER": "SANMH", "FeatId1": 846.0 }, "geometry": { "type": "Point", "coordinates": [ -80.611162063856881, 28.295143729303678 ] } },
    { "type": "Feature", "properties": { "OBJECTID": 166, "Coating": "None", "Constructi": "Precast", "Date_Inspe": "01/12/09", "Depth": 6.83, "Infiltrati": "No", "Inflow": "No", "Lift_Stati": 14.0, "Manhole_Id": "LS14-006", "Rating": "Good", "SHAWN": 0.0, "LAYER": "SANMH", "FeatId1": 845.0 }, "geometry": { "type": "Point", "coordinates": [ -80.611908979745621, 28.295144237864704 ] } },
    { "type": "Feature", "properties": { "OBJECTID": 167, "Coating": "None", "Constructi": "Precast", "Date_Inspe": "01/13/09", "Depth": 4.25, "Infiltrati": "No", "Inflow": "No", "Lift_Stati": 14.0, "Manhole_Id": "LS14-014", "Rating": "Good", "SHAWN": 0.0, "LAYER": "SANMH", "FeatId1": 844.0 }, "geometry": { "type": "Point", "coordinates": [ -80.612449648437121, 28.295113751772547 ] } },
    { "type": "Feature", "properties": { "OBJECTID": 168, "Coating": "None", "Constructi": "Precast", "Date_Inspe": "01/13/09", "Depth": 5.5, "Infiltrati": "No", "Inflow": "No", "Lift_Stati": 14.0, "Manhole_Id": "LS14-015", "Rating": "Good", "SHAWN": 0.0, "LAYER": "SANMH", "FeatId1": 843.0 }, "geometry": { "type": "Point", "coordinates": [ -80.611823169323699, 28.29456281083619 ] } },
    { "type": "Feature", "properties": { "OBJECTID": 169, "Coating": "None", "Constructi": "Precast", "Date_Inspe": "01/12/09", "Depth": 4.75, "Infiltrati": "No", "Inflow": "No", "Lift_Stati": 14.0, "Manhole_Id": "LS14-003", "Rating": "Good", "SHAWN": 0.0, "LAYER": "SANMH", "FeatId1": 842.0 }, "geometry": { "type": "Point", "coordinates": [ -80.609977230362489, 28.295277146369411 ] } },
    { "type": "Feature", "properties": { "OBJECTID": 170, "Coating": "None", "Constructi": "Brick/Mortar", "Date_Inspe": "00/00/00", "Depth": 0.0, "Infiltrati": "No", "Inflow": "No", "Lift_Stati": 3.0, "Manhole_Id": "LS3-028", "Rating": "Good", "SHAWN": 0.0, "LAYER": "SANMH", "FeatId1": 841.0 }, "geometry": { "type": "Point", "coordinates": [ -80.609536437724415, 28.295170828156792 ] } },
    { "type": "Feature", "properties": { "OBJECTID": 171, "Coating": "None", "Constructi": "Brick/Mortar", "Date_Inspe": "02/09/09", "Depth": 8.5, "Infiltrati": "Probable", "Inflow": "No", "Lift_Stati": 3.0, "Manhole_Id": "LS3-020", "Rating": "Fair", "SHAWN": 0.0, "LAYER": "SANMH", "FeatId1": 840.0 }, "geometry": { "type": "Point", "coordinates": [ -80.608965346848336, 28.295178586834112 ] } },
    { "type": "Feature", "properties": { "OBJECTID": 172, "Coating": "None", "Constructi": "Brick/Mortar", "Date_Inspe": "02/04/09", "Depth": 8.08, "Infiltrati": "No", "Inflow": "No", "Lift_Stati": 3.0, "Manhole_Id": "LS3-007", "Rating": "Good", "SHAWN": 0.0, "LAYER": "SANMH", "FeatId1": 839.0 }, "geometry": { "type": "Point", "coordinates": [ -80.608282888587297, 28.295188018857175 ] } },
    { "type": "Feature", "properties": { "OBJECTID": 173, "Coating": "None", "Constructi": "Precast", "Date_Inspe": "01/12/09", "Depth": 5.58, "Infiltrati": "No", "Inflow": "No", "Lift_Stati": 14.0, "Manhole_Id": "LS14-001", "Rating": "Good", "SHAWN": 0.0, "LAYER": "SANMH", "FeatId1": 838.0 }, "geometry": { "type": "Point", "coordinates": [ -80.609526243615875, 28.29583987295571 ] } },
    { "type": "Feature", "properties": { "OBJECTID": 174, "Coating": "None", "Constructi": "Brick/Mortar", "Date_Inspe": "02/04/09", "Depth": 8.17, "Infiltrati": "No", "Inflow": "No", "Lift_Stati": 3.0, "Manhole_Id": "LS3-008", "Rating": "Good", "SHAWN": 0.0, "LAYER": "SANMH", "FeatId1": 837.0 }, "geometry": { "type": "Point", "coordinates": [ -80.608262762905454, 28.29434516987585 ] } },
    { "type": "Feature", "properties": { "OBJECTID": 175, "Coating": "None", "Constructi": "Precast", "Date_Inspe": "01/13/09", "Depth": 6.25, "Infiltrati": "No", "Inflow": "No", "Lift_Stati": 14.0, "Manhole_Id": "LS14-016", "Rating": "Good", "SHAWN": 0.0, "LAYER": "SANMH", "FeatId1": 836.0 }, "geometry": { "type": "Point", "coordinates": [ -80.61107625807567, 28.293893825979701 ] } },
    { "type": "Feature", "properties": { "OBJECTID": 176, "Coating": "None", "Constructi": "Precast", "Date_Inspe": "01/13/09", "Depth": 11.67, "Infiltrati": "No", "Inflow": "No", "Lift_Stati": 14.0, "Manhole_Id": "LS14-017", "Rating": "Good", "SHAWN": 0.0, "LAYER": "SANMH", "FeatId1": 835.0 }, "geometry": { "type": "Point", "coordinates": [ -80.610803890233029, 28.293325013473073 ] } },
    { "type": "Feature", "properties": { "OBJECTID": 177, "Coating": "None", "Constructi": "Precast", "Date_Inspe": "01/13/09", "Depth": 11.0, "Infiltrati": "No", "Inflow": "No", "Lift_Stati": 14.0, "Manhole_Id": "LS14-018", "Rating": "Good", "SHAWN": 0.0, "LAYER": "SANMH", "FeatId1": 834.0 }, "geometry": { "type": "Point", "coordinates": [ -80.610347970503057, 28.293341075925731 ] } },
    { "type": "Feature", "properties": { "OBJECTID": 178, "Coating": "None", "Constructi": "Brick/Mortar", "Date_Inspe": "02/04/09", "Depth": 6.83, "Infiltrati": "No", "Inflow": "No", "Lift_Stati": 3.0, "Manhole_Id": "LS3-009", "Rating": "Good", "SHAWN": 0.0, "LAYER": "SANMH", "FeatId1": 833.0 }, "geometry": { "type": "Point", "coordinates": [ -80.608241031736924, 28.293407945282993 ] } },
    { "type": "Feature", "properties": { "OBJECTID": 179, "Coating": "None", "Constructi": "Brick/Mortar", "Date_Inspe": "02/09/09", "Depth": 7.68, "Infiltrati": "No", "Inflow": "No", "Lift_Stati": 3.0, "Manhole_Id": "LS3-022", "Rating": "Good", "SHAWN": 0.0, "LAYER": "SANMH", "FeatId1": 832.0 }, "geometry": { "type": "Point", "coordinates": [ -80.60892651776912, 28.293395704853225 ] } },
    { "type": "Feature", "properties": { "OBJECTID": 180, "Coating": "None", "Constructi": "Brick/Mortar", "Date_Inspe": "00/00/00", "Depth": 0.0, "Infiltrati": "No", "Inflow": "No", "Lift_Stati": 3.0, "Manhole_Id": "LS3-029", "Rating": "Good", "SHAWN": 0.0, "LAYER": "SANMH", "FeatId1": 831.0 }, "geometry": { "type": "Point", "coordinates": [ -80.609848116516545, 28.295168146491132 ] } },
    { "type": "Feature", "properties": { "OBJECTID": 181, "Coating": "None", "Constructi": "Precast", "Date_Inspe": "01/13/09", "Depth": 11.83, "Infiltrati": "No", "Inflow": "No", "Lift_Stati": 14.0, "Manhole_Id": "LS14-020", "Rating": "Good", "SHAWN": 0.0, "LAYER": "SANMH", "FeatId1": 830.0 }, "geometry": { "type": "Point", "coordinates": [ -80.61046488829956, 28.292588562663497 ] } },
    { "type": "Feature", "properties": { "OBJECTID": 182, "Coating": "None", "Constructi": "Brick/Mortar", "Date_Inspe": "02/10/09", "Depth": 3.33, "Infiltrati": "No", "Inflow": "No", "Lift_Stati": 3.0, "Manhole_Id": "LS3-012", "Rating": "Good", "SHAWN": 0.0, "LAYER": "SANMH", "FeatId1": 829.0 }, "geometry": { "type": "Point", "coordinates": [ -80.608181393430655, 28.290932148525638 ] } },
    { "type": "Feature", "properties": { "OBJECTID": 183, "Coating": "None", "Constructi": "Precast", "Date_Inspe": "01/13/09", "Depth": 12.0, "Infiltrati": "No", "Inflow": "No", "Lift_Stati": 14.0, "Manhole_Id": "LS14-021", "Rating": "Good", "SHAWN": 0.0, "LAYER": "SANMH", "FeatId1": 828.0 }, "geometry": { "type": "Point", "coordinates": [ -80.61010036058147, 28.291814632982064 ] } },
    { "type": "Feature", "properties": { "OBJECTID": 184, "Coating": "None", "Constructi": "Precast", "Date_Inspe": "01/13/09", "Depth": 11.83, "Infiltrati": "No", "Inflow": "No", "Lift_Stati": 14.0, "Manhole_Id": "LS14-022", "Rating": "Good", "SHAWN": 0.0, "LAYER": "SANMH", "FeatId1": 827.0 }, "geometry": { "type": "Point", "coordinates": [ -80.609831716156947, 28.291076055938714 ] } },
    { "type": "Feature", "properties": { "OBJECTID": 185, "Coating": "None", "Constructi": "Precast", "Date_Inspe": "01/14/09", "Depth": 11.0, "Infiltrati": "No", "Inflow": "No", "Lift_Stati": 14.0, "Manhole_Id": "LS14-023", "Rating": "Good", "SHAWN": 0.0, "LAYER": "SANMH", "FeatId1": 826.0 }, "geometry": { "type": "Point", "coordinates": [ -80.609966961668661, 28.290693241029377 ] } },
    { "type": "Feature", "properties": { "OBJECTID": 186, "Coating": "None", "Constructi": "Precast", "Date_Inspe": "01/14/09", "Depth": 9.83, "Infiltrati": "No", "Inflow": "No", "Lift_Stati": 14.0, "Manhole_Id": "LS14-024", "Rating": "Good", "SHAWN": 0.0, "LAYER": "SANMH", "FeatId1": 825.0 }, "geometry": { "type": "Point", "coordinates": [ -80.61036005398303, 28.29042485075637 ] } },
    { "type": "Feature", "properties": { "OBJECTID": 187, "Coating": "None", "Constructi": "Precast", "Date_Inspe": "01/14/09", "Depth": 9.67, "Infiltrati": "No", "Inflow": "No", "Lift_Stati": 14.0, "Manhole_Id": "LS14-025", "Rating": "Good", "SHAWN": 0.0, "LAYER": "SANMH", "FeatId1": 824.0 }, "geometry": { "type": "Point", "coordinates": [ -80.610972252140868, 28.290415114168834 ] } },
    { "type": "Feature", "properties": { "OBJECTID": 188, "Coating": "None", "Constructi": "Precast", "Date_Inspe": "01/14/09", "Depth": 9.17, "Infiltrati": "No", "Inflow": "No", "Lift_Stati": 14.0, "Manhole_Id": "LS14-026", "Rating": "Good", "SHAWN": 0.0, "LAYER": "SANMH", "FeatId1": 823.0 }, "geometry": { "type": "Point", "coordinates": [ -80.611582179978299, 28.290586225379368 ] } },
    { "type": "Feature", "properties": { "OBJECTID": 189, "Coating": "None", "Constructi": "Precast", "Date_Inspe": "01/14/09", "Depth": 7.33, "Infiltrati": "No", "Inflow": "No", "Lift_Stati": 14.0, "Manhole_Id": "LS14-034", "Rating": "Good", "SHAWN": 0.0, "LAYER": "SANMH", "FeatId1": 822.0 }, "geometry": { "type": "Point", "coordinates": [ -80.612063950106304, 28.291045134264923 ] } },
    { "type": "Feature", "properties": { "OBJECTID": 190, "Coating": "None", "Constructi": "Precast", "Date_Inspe": "01/14/09", "Depth": 6.25, "Infiltrati": "No", "Inflow": "No", "Lift_Stati": 14.0, "Manhole_Id": "LS14-036", "Rating": "Good", "SHAWN": 0.0, "LAYER": "SANMH", "FeatId1": 821.0 }, "geometry": { "type": "Point", "coordinates": [ -80.612663235569585, 28.29180021019333 ] } },
    { "type": "Feature", "properties": { "OBJECTID": 191, "Coating": "None", "Constructi": "Precast", "Date_Inspe": "01/14/09", "Depth": 4.17, "Infiltrati": "No", "Inflow": "No", "Lift_Stati": 14.0, "Manhole_Id": "LS14-035", "Rating": "Good", "SHAWN": 0.0, "LAYER": "SANMH", "FeatId1": 820.0 }, "geometry": { "type": "Point", "coordinates": [ -80.612899718687402, 28.29153563208498 ] } },
    { "type": "Feature", "properties": { "OBJECTID": 192, "Coating": "None", "Constructi": "Precast", "Date_Inspe": "01/14/09", "Depth": 5.08, "Infiltrati": "No", "Inflow": "No", "Lift_Stati": 14.0, "Manhole_Id": "LS14-038", "Rating": "Good", "SHAWN": 0.0, "LAYER": "SANMH", "FeatId1": 819.0 }, "geometry": { "type": "Point", "coordinates": [ -80.613249628646813, 28.292740432598723 ] } },
    { "type": "Feature", "properties": { "OBJECTID": 193, "Coating": "None", "Constructi": "Precast", "Date_Inspe": "01/14/09", "Depth": 4.42, "Infiltrati": "No", "Inflow": "No", "Lift_Stati": 14.0, "Manhole_Id": "LS14-037", "Rating": "Good", "SHAWN": 0.0, "LAYER": "SANMH", "FeatId1": 818.0 }, "geometry": { "type": "Point", "coordinates": [ -80.613743077962084, 28.292375116040596 ] } },
    { "type": "Feature", "properties": { "OBJECTID": 194, "Coating": "None", "Constructi": "Precast", "Date_Inspe": "01/14/09", "Depth": 4.67, "Infiltrati": "No", "Inflow": "No", "Lift_Stati": 14.0, "Manhole_Id": "LS14-039", "Rating": "Good", "SHAWN": 0.0, "LAYER": "SANMH", "FeatId1": 817.0 }, "geometry": { "type": "Point", "coordinates": [ -80.613490177495052, 28.292919941131981 ] } },
    { "type": "Feature", "properties": { "OBJECTID": 195, "Coating": "None", "Constructi": "Precast", "Date_Inspe": "01/14/09", "Depth": 3.5, "Infiltrati": "No", "Inflow": "No", "Lift_Stati": 14.0, "Manhole_Id": "LS14-040", "Rating": "Good", "SHAWN": 0.0, "LAYER": "SANMH", "FeatId1": 816.0 }, "geometry": { "type": "Point", "coordinates": [ -80.614271035906668, 28.293242443934076 ] } },
    { "type": "Feature", "properties": { "OBJECTID": 196, "Coating": "None", "Constructi": "Precast", "Date_Inspe": "01/14/09", "Depth": 7.75, "Infiltrati": "No", "Inflow": "No", "Lift_Stati": 14.0, "Manhole_Id": "LS14-027", "Rating": "Good", "SHAWN": 0.0, "LAYER": "SANMH", "FeatId1": 815.0 }, "geometry": { "type": "Point", "coordinates": [ -80.61215670080044, 28.290304891392022 ] } },
    { "type": "Feature", "properties": { "OBJECTID": 197, "Coating": "None", "Constructi": "Precast", "Date_Inspe": "01/14/09", "Depth": 7.33, "Infiltrati": "No", "Inflow": "No", "Lift_Stati": 14.0, "Manhole_Id": "LS14-028", "Rating": "Good", "SHAWN": 0.0, "LAYER": "SANMH", "FeatId1": 814.0 }, "geometry": { "type": "Point", "coordinates": [ -80.61320688888415, 28.290313502261608 ] } },
    { "type": "Feature", "properties": { "OBJECTID": 198, "Coating": "None", "Constructi": "Precast", "Date_Inspe": "01/14/09", "Depth": 6.67, "Infiltrati": "No", "Inflow": "No", "Lift_Stati": 14.0, "Manhole_Id": "LS14-029", "Rating": "Good", "SHAWN": 0.0, "LAYER": "SANMH", "FeatId1": 813.0 }, "geometry": { "type": "Point", "coordinates": [ -80.613699992028955, 28.29068145586654 ] } },
    { "type": "Feature", "properties": { "OBJECTID": 199, "Coating": "None", "Constructi": "Precast", "Date_Inspe": "01/14/09", "Depth": 5.5, "Infiltrati": "No", "Inflow": "No", "Lift_Stati": 14.0, "Manhole_Id": "LS14-030", "Rating": "Good", "SHAWN": 0.0, "LAYER": "SANMH", "FeatId1": 812.0 }, "geometry": { "type": "Point", "coordinates": [ -80.614553028229409, 28.291459372695869 ] } },
    { "type": "Feature", "properties": { "OBJECTID": 200, "Coating": "None", "Constructi": "Precast", "Date_Inspe": "01/14/09", "Depth": 4.5, "Infiltrati": "No", "Inflow": "No", "Lift_Stati": 14.0, "Manhole_Id": "LS14-031", "Rating": "Good", "SHAWN": 0.0, "LAYER": "SANMH", "FeatId1": 811.0 }, "geometry": { "type": "Point", "coordinates": [ -80.615263939027784, 28.292365420448018 ] } },
    { "type": "Feature", "properties": { "OBJECTID": 201, "Coating": "None", "Constructi": "Precast", "Date_Inspe": "01/14/09", "Depth": 4.25, "Infiltrati": "No", "Inflow": "No", "Lift_Stati": 14.0, "Manhole_Id": "LS14-032", "Rating": "Good", "SHAWN": 0.0, "LAYER": "SANMH", "FeatId1": 810.0 }, "geometry": { "type": "Point", "coordinates": [ -80.615864149512419, 28.293005794435135 ] } },
    { "type": "Feature", "properties": { "OBJECTID": 202, "Coating": "None", "Constructi": "Precast", "Date_Inspe": "01/14/09", "Depth": 3.17, "Infiltrati": "No", "Inflow": "No", "Lift_Stati": 14.0, "Manhole_Id": "LS14-033", "Rating": "Good", "SHAWN": 0.0, "LAYER": "SANMH", "FeatId1": 809.0 }, "geometry": { "type": "Point", "coordinates": [ -80.615734981400934, 28.293924355983126 ] } },
    { "type": "Feature", "properties": { "OBJECTID": 203, "Coating": "None", "Constructi": "Brick/Mortar", "Date_Inspe": "02/09/09", "Depth": 6.08, "Infiltrati": "No", "Inflow": "No", "Lift_Stati": 3.0, "Manhole_Id": "LS3-024", "Rating": "Good", "SHAWN": 0.0, "LAYER": "SANMH", "FeatId1": 808.0 }, "geometry": { "type": "Point", "coordinates": [ -80.608897486421156, 28.291606735994858 ] } },
    { "type": "Feature", "properties": { "OBJECTID": 204, "Coating": "None", "Constructi": "Brick/Mortar", "Date_Inspe": "02/04/09", "Depth": 4.42, "Infiltrati": "No", "Inflow": "No", "Lift_Stati": 3.0, "Manhole_Id": "LS3-011", "Rating": "Good", "SHAWN": 0.0, "LAYER": "SANMH", "FeatId1": 807.0 }, "geometry": { "type": "Point", "coordinates": [ -80.608199642438521, 28.29161683748254 ] } },
    { "type": "Feature", "properties": { "OBJECTID": 205, "Coating": "None", "Constructi": "Brick/Mortar", "Date_Inspe": "02/04/09", "Depth": 5.83, "Infiltrati": "No", "Inflow": "No", "Lift_Stati": 3.0, "Manhole_Id": "LS3-010", "Rating": "Good", "SHAWN": 0.0, "LAYER": "SANMH", "FeatId1": 806.0 }, "geometry": { "type": "Point", "coordinates": [ -80.608218718381437, 28.292432950131435 ] } },
    { "type": "Feature", "properties": { "OBJECTID": 206, "Coating": "None", "Constructi": "Precast", "Date_Inspe": "01/12/09", "Depth": 3.92, "Infiltrati": "No", "Inflow": "No", "Lift_Stati": 14.0, "Manhole_Id": "LS14-011", "Rating": "Good", "SHAWN": 0.0, "LAYER": "SANMH", "FeatId1": 805.0 }, "geometry": { "type": "Point", "coordinates": [ -80.614791261094226, 28.295863691153446 ] } },
    { "type": "Feature", "properties": { "OBJECTID": 207, "Coating": "None", "Constructi": "Precast", "Date_Inspe": "01/12/09", "Depth": 4.33, "Infiltrati": "No", "Inflow": "No", "Lift_Stati": 14.0, "Manhole_Id": "LS14-010", "Rating": "Good", "SHAWN": 0.0, "LAYER": "SANMH", "FeatId1": 804.0 }, "geometry": { "type": "Point", "coordinates": [ -80.614885331822592, 28.296078223677451 ] } },
    { "type": "Feature", "properties": { "OBJECTID": 208, "Coating": "None", "Constructi": "Precast", "Date_Inspe": "01/12/09", "Depth": 4.67, "Infiltrati": "No", "Inflow": "No", "Lift_Stati": 14.0, "Manhole_Id": "LS14-009", "Rating": "Good", "SHAWN": 0.0, "LAYER": "SANMH", "FeatId1": 803.0 }, "geometry": { "type": "Point", "coordinates": [ -80.614727631645593, 28.296760367747009 ] } },
    { "type": "Feature", "properties": { "OBJECTID": 209, "Coating": "None", "Constructi": "Precast", "Date_Inspe": "01/12/09", "Depth": 4.67, "Infiltrati": "No", "Inflow": "No", "Lift_Stati": 14.0, "Manhole_Id": "LS14-008", "Rating": "Good", "SHAWN": 0.0, "LAYER": "SANMH", "FeatId1": 802.0 }, "geometry": { "type": "Point", "coordinates": [ -80.613508152804755, 28.296497833761276 ] } },
    { "type": "Feature", "properties": { "OBJECTID": 210, "Coating": "None", "Constructi": "Brick/Mortar", "Date_Inspe": "02/09/09", "Depth": 8.5, "Infiltrati": "Probable", "Inflow": "No", "Lift_Stati": 3.0, "Manhole_Id": "LS3-018", "Rating": "Fair", "SHAWN": 0.0, "LAYER": "SANMH", "FeatId1": 801.0 }, "geometry": { "type": "Point", "coordinates": [ -80.60900796920923, 28.296948136753688 ] } },
    { "type": "Feature", "properties": { "OBJECTID": 211, "Coating": "None", "Constructi": "Brick/Mortar", "Date_Inspe": "01/04/09", "Depth": 6.08, "Infiltrati": "No", "Inflow": "No", "Lift_Stati": 3.0, "Manhole_Id": "LS3-005", "Rating": "Good", "SHAWN": 0.0, "LAYER": "SANMH", "FeatId1": 800.0 }, "geometry": { "type": "Point", "coordinates": [ -80.608324462249897, 28.296973874449296 ] } },
    { "type": "Feature", "properties": { "OBJECTID": 212, "Coating": "None", "Constructi": "Brick/Mortar", "Date_Inspe": "02/03/09", "Depth": 4.5, "Infiltrati": "No", "Inflow": "No", "Lift_Stati": 3.0, "Manhole_Id": "LS3-004", "Rating": "Good", "SHAWN": 0.0, "LAYER": "SANMH", "FeatId1": 799.0 }, "geometry": { "type": "Point", "coordinates": [ -80.608345409886027, 28.297871437107567 ] } },
    { "type": "Feature", "properties": { "OBJECTID": 213, "Coating": "None", "Constructi": "Brick/Mortar", "Date_Inspe": "02/03/09", "Depth": 4.17, "Infiltrati": "No", "Inflow": "No", "Lift_Stati": 3.0, "Manhole_Id": "LS3-003", "Rating": "Good", "SHAWN": 0.0, "LAYER": "SANMH", "FeatId1": 798.0 }, "geometry": { "type": "Point", "coordinates": [ -80.608365407989595, 28.298748002367741 ] } },
    { "type": "Feature", "properties": { "OBJECTID": 214, "Coating": "None", "Constructi": "Brick/Mortar", "Date_Inspe": "02/09/09", "Depth": 4.67, "Infiltrati": "Probable", "Inflow": "No", "Lift_Stati": 3.0, "Manhole_Id": "LS3-016", "Rating": "Fair", "SHAWN": 0.0, "LAYER": "SANMH", "FeatId1": 797.0 }, "geometry": { "type": "Point", "coordinates": [ -80.609049831981295, 28.298759825654209 ] } },
    { "type": "Feature", "properties": { "OBJECTID": 215, "Coating": "None", "Constructi": "Precast", "Date_Inspe": "01/13/09", "Depth": 5.17, "Infiltrati": "No", "Inflow": "No", "Lift_Stati": 14.0, "Manhole_Id": "LS14-013", "Rating": "Good", "SHAWN": 0.0, "LAYER": "SANMH", "FeatId1": 796.0 }, "geometry": { "type": "Point", "coordinates": [ -80.61346213233098, 28.295404153936232 ] } },
    { "type": "Feature", "properties": { "OBJECTID": 216, "Coating": "None", "Constructi": "Precast", "Date_Inspe": "01/12/09", "Depth": 3.92, "Infiltrati": "No", "Inflow": "No", "Lift_Stati": 14.0, "Manhole_Id": "LS14-012", "Rating": "Good", "SHAWN": 0.0, "LAYER": "SANMH", "FeatId1": 795.0 }, "geometry": { "type": "Point", "coordinates": [ -80.613999568186898, 28.295606763084542 ] } },
    { "type": "Feature", "properties": { "OBJECTID": 217, "Coating": "None", "Constructi": "Precast", "Date_Inspe": "01/12/09", "Depth": 6.0, "Infiltrati": "No", "Inflow": "No", "Lift_Stati": 14.0, "Manhole_Id": "LS14-007", "Rating": "Good", "SHAWN": 0.0, "LAYER": "SANMH", "FeatId1": 794.0 }, "geometry": { "type": "Point", "coordinates": [ -80.612661301327378, 28.295706996407912 ] } },
    { "type": "Feature", "properties": { "OBJECTID": 218, "Coating": "None", "Constructi": "Precast", "Date_Inspe": "01/06/09", "Depth": 5.5, "Infiltrati": "No", "Inflow": "No", "Lift_Stati": 15.0, "Manhole_Id": "LS15-012", "Rating": "Good", "SHAWN": 0.0, "LAYER": "SANMH", "FeatId1": 793.0 }, "geometry": { "type": "Point", "coordinates": [ -80.607136812197396, 28.278530557254626 ] } },
    { "type": "Feature", "properties": { "OBJECTID": 219, "Coating": "None", "Constructi": "Precast", "Date_Inspe": "01/06/09", "Depth": 4.5, "Infiltrati": "No", "Inflow": "No", "Lift_Stati": 15.0, "Manhole_Id": "LS15-011", "Rating": "Good", "SHAWN": 0.0, "LAYER": "SANMH", "FeatId1": 792.0 }, "geometry": { "type": "Point", "coordinates": [ -80.607228476393587, 28.279749001193974 ] } },
    { "type": "Feature", "properties": { "OBJECTID": 220, "Coating": "None", "Constructi": "Precast", "Date_Inspe": "01/06/09", "Depth": 5.0, "Infiltrati": "No", "Inflow": "No", "Lift_Stati": 15.0, "Manhole_Id": "LS15-053", "Rating": "Good", "SHAWN": 0.0, "LAYER": "SANMH", "FeatId1": 791.0 }, "geometry": { "type": "Point", "coordinates": [ -80.607182281864567, 28.279168065810147 ] } },
    { "type": "Feature", "properties": { "OBJECTID": 221, "Coating": "None", "Constructi": "Precast", "Date_Inspe": "01/06/09", "Depth": 14.0, "Infiltrati": "No", "Inflow": "No", "Lift_Stati": 15.0, "Manhole_Id": "LS15-052", "Rating": "Good", "SHAWN": 0.0, "LAYER": "SANMH", "FeatId1": 790.0 }, "geometry": { "type": "Point", "coordinates": [ -80.607440088218368, 28.279151654570342 ] } },
    { "type": "Feature", "properties": { "OBJECTID": 222, "Coating": "None", "Constructi": "Precast", "Date_Inspe": "01/07/09", "Depth": 11.0, "Infiltrati": "No", "Inflow": "No", "Lift_Stati": 15.0, "Manhole_Id": "LS15-056", "Rating": "Good", "SHAWN": 0.0, "LAYER": "SANMH", "FeatId1": 789.0 }, "geometry": { "type": "Point", "coordinates": [ -80.60672006682141, 28.278546367896254 ] } },
    { "type": "Feature", "properties": { "OBJECTID": 223, "Coating": "None", "Constructi": "Precast", "Date_Inspe": "01/05/09", "Depth": 3.67, "Infiltrati": "No", "Inflow": "No", "Lift_Stati": 15.0, "Manhole_Id": "LS15-021", "Rating": "Good", "SHAWN": 0.0, "LAYER": "SANMH", "FeatId1": 788.0 }, "geometry": { "type": "Point", "coordinates": [ -80.606538286733084, 28.276634817998147 ] } },
    { "type": "Feature", "properties": { "OBJECTID": 224, "Coating": "None", "Constructi": "Precast", "Date_Inspe": "12/31/08", "Depth": 4.08, "Infiltrati": "No", "Inflow": "Yes", "Lift_Stati": 15.0, "Manhole_Id": "LS15-041", "Rating": "Good", "SHAWN": 0.0, "LAYER": "SANMH", "FeatId1": 787.0 }, "geometry": { "type": "Point", "coordinates": [ -80.607665342675375, 28.273259091777778 ] } },
    { "type": "Feature", "properties": { "OBJECTID": 225, "Coating": "None", "Constructi": "Precast", "Date_Inspe": "01/13/09", "Depth": 3.08, "Infiltrati": "No", "Inflow": "No", "Lift_Stati": 14.0, "Manhole_Id": "LS14-002", "Rating": "Good", "SHAWN": 0.0, "LAYER": "SANMH", "FeatId1": 786.0 }, "geometry": { "type": "Point", "coordinates": [ -80.609658466304936, 28.29549288228268 ] } },
    { "type": "Feature", "properties": { "OBJECTID": 226, "Coating": "None", "Constructi": "Precast", "Date_Inspe": "01/20/09", "Depth": 6.08, "Infiltrati": "No", "Inflow": "No", "Lift_Stati": 14.0, "Manhole_Id": "LS14-041", "Rating": "Good", "SHAWN": 0.0, "LAYER": "SANMH", "FeatId1": 785.0 }, "geometry": { "type": "Point", "coordinates": [ -80.609490012176465, 28.293348845545914 ] } },
    { "type": "Feature", "properties": { "OBJECTID": 227, "Coating": "Mortar", "Constructi": "Brick/Mortar", "Date_Inspe": "02/19/09", "Depth": 9.0, "Infiltrati": "Probable", "Inflow": "No", "Lift_Stati": 6.0, "Manhole_Id": "LS6-011", "Rating": "Fair", "SHAWN": 0.0, "LAYER": "SANMH", "FeatId1": 784.0 }, "geometry": { "type": "Point", "coordinates": [ -80.610572809585719, 28.301203815163028 ] } },
    { "type": "Feature", "properties": { "OBJECTID": 228, "Coating": "None", "Constructi": "Precast", "Date_Inspe": "01/20/09", "Depth": 11.75, "Infiltrati": "No", "Inflow": "Yes", "Lift_Stati": 14.0, "Manhole_Id": "LS14-042", "Rating": "Good", "SHAWN": 0.0, "LAYER": "SANMH", "FeatId1": 783.0 }, "geometry": { "type": "Point", "coordinates": [ -80.60942370652441, 28.291030792602378 ] } },
    { "type": "Feature", "properties": { "OBJECTID": 229, "Coating": "None", "Constructi": "Precast", "Date_Inspe": "01/26/09", "Depth": 4.67, "Infiltrati": "No", "Inflow": "No", "Lift_Stati": 14.0, "Manhole_Id": "LS14-069", "Rating": "Good", "SHAWN": 0.0, "LAYER": "SANMH", "FeatId1": 782.0 }, "geometry": { "type": "Point", "coordinates": [ -80.607072917709161, 28.282302434426576 ] } },
    { "type": "Feature", "properties": { "OBJECTID": 230, "Coating": "None", "Constructi": "Precast", "Date_Inspe": "01/26/09", "Depth": 3.33, "Infiltrati": "No", "Inflow": "No", "Lift_Stati": 14.0, "Manhole_Id": "LS14-070", "Rating": "Good", "SHAWN": 0.0, "LAYER": "SANMH", "FeatId1": 781.0 }, "geometry": { "type": "Point", "coordinates": [ -80.607374866899349, 28.282301132435276 ] } },
    { "type": "Feature", "properties": { "OBJECTID": 231, "Coating": "None", "Constructi": "Precast", "Date_Inspe": "01/26/09", "Depth": 4.0, "Infiltrati": "No", "Inflow": "No", "Lift_Stati": 14.0, "Manhole_Id": "LS14-067", "Rating": "Good", "SHAWN": 0.0, "LAYER": "SANMH", "FeatId1": 780.0 }, "geometry": { "type": "Point", "coordinates": [ -80.608283676368046, 28.285482231898754 ] } },
    { "type": "Feature", "properties": { "OBJECTID": 232, "Coating": "Mortar", "Constructi": "Brick/Mortar", "Date_Inspe": "03/30/10", "Depth": 5.5, "Infiltrati": "Probable", "Inflow": "Probable", "Lift_Stati": 14.0, "Manhole_Id": "LS14-052", "Rating": "Fair", "SHAWN": 0.0, "LAYER": "SANMH", "FeatId1": 779.0 }, "geometry": { "type": "Point", "coordinates": [ -80.610389224120453, 28.287101251077576 ] } },
    { "type": "Feature", "properties": { "OBJECTID": 233, "Coating": " ", "Constructi": " ", "Date_Inspe": " ", "Depth": 11.0, "Infiltrati": " ", "Inflow": " ", "Lift_Stati": 14.0, "Manhole_Id": "LS14-080", "Rating": " ", "SHAWN": 0.0, "LAYER": "SANMH", "FeatId1": 778.0 }, "geometry": { "type": "Point", "coordinates": [ -80.607868156542025, 28.287945133089988 ] } },
    { "type": "Feature", "properties": { "OBJECTID": 234, "Coating": "None", "Constructi": "Precast", "Date_Inspe": "02/10/09", "Depth": 3.5, "Infiltrati": "No", "Inflow": "No", "Lift_Stati": 3.0, "Manhole_Id": "LS3-030", "Rating": "Good", "SHAWN": 0.0, "LAYER": "SANMH", "FeatId1": 777.0 }, "geometry": { "type": "Point", "coordinates": [ -80.607952304411739, 28.290863932329213 ] } },
    { "type": "Feature", "properties": { "OBJECTID": 235, "Coating": " ", "Constructi": " ", "Date_Inspe": "02/17/09", "Depth": 0.0, "Infiltrati": " ", "Inflow": " ", "Lift_Stati": 3.0, "Manhole_Id": "LS3-006", "Rating": " ", "SHAWN": 0.0, "LAYER": "SANMH", "FeatId1": 776.0 }, "geometry": { "type": "Point", "coordinates": [ -80.608308046636537, 28.296084085063228 ] } },
    { "type": "Feature", "properties": { "OBJECTID": 236, "Coating": "None", "Constructi": "Brick/Mortar", "Date_Inspe": "02/03/09", "Depth": 2.67, "Infiltrati": "No", "Inflow": "No", "Lift_Stati": 3.0, "Manhole_Id": "LS3-001", "Rating": "Good", "SHAWN": 0.0, "LAYER": "SANMH", "FeatId1": 775.0 }, "geometry": { "type": "Point", "coordinates": [ -80.608408765112983, 28.300548253145955 ] } },
    { "type": "Feature", "properties": { "OBJECTID": 237, "Coating": "None", "Constructi": "Brick/Mortar", "Date_Inspe": "02/03/09", "Depth": 3.42, "Infiltrati": "No", "Inflow": "No", "Lift_Stati": 3.0, "Manhole_Id": "LS3-002", "Rating": "Good", "SHAWN": 0.0, "LAYER": "SANMH", "FeatId1": 774.0 }, "geometry": { "type": "Point", "coordinates": [ -80.608387743835308, 28.299656949471444 ] } },
    { "type": "Feature", "properties": { "OBJECTID": 238, "Coating": " ", "Constructi": " ", "Date_Inspe": " ", "Depth": 0.0, "Infiltrati": " ", "Inflow": " ", "Lift_Stati": 3.0, "Manhole_Id": "LS3-014", "Rating": " ", "SHAWN": 0.0, "LAYER": "SANMH", "FeatId1": 773.0 }, "geometry": { "type": "Point", "coordinates": [ -80.609094559596613, 28.300464969463388 ] } },
    { "type": "Feature", "properties": { "OBJECTID": 239, "Coating": "None", "Constructi": "Brick/Mortar", "Date_Inspe": "03/24/09", "Depth": 1.83, "Infiltrati": "Probable", "Inflow": "No", "Lift_Stati": 3.0, "Manhole_Id": "LS3-015", "Rating": "Fair", "SHAWN": 0.0, "LAYER": "SANMH", "FeatId1": 772.0 }, "geometry": { "type": "Point", "coordinates": [ -80.60907400134306, 28.2996537985826 ] } },
    { "type": "Feature", "properties": { "OBJECTID": 240, "Coating": "None", "Constructi": "Brick/Mortar", "Date_Inspe": "03/10/09", "Depth": 6.33, "Infiltrati": "Probable", "Inflow": "No", "Lift_Stati": 6.0, "Manhole_Id": "LS6-090", "Rating": "Good", "SHAWN": 0.0, "LAYER": "SANMH", "FeatId1": 771.0 }, "geometry": { "type": "Point", "coordinates": [ -80.619797982604354, 28.311221111153802 ] } },
    { "type": "Feature", "properties": { "OBJECTID": 241, "Coating": "None", "Constructi": "Brick/Mortar", "Date_Inspe": "03/10/09", "Depth": 3.16, "Infiltrati": "No", "Inflow": "No", "Lift_Stati": 6.0, "Manhole_Id": "LS6-088", "Rating": "Fair", "SHAWN": 0.0, "LAYER": "SANMH", "FeatId1": 770.0 }, "geometry": { "type": "Point", "coordinates": [ -80.619804245559592, 28.312489506583393 ] } },
    { "type": "Feature", "properties": { "OBJECTID": 242, "Coating": "None", "Constructi": "Brick/Mortar", "Date_Inspe": "03/10/09", "Depth": 3.42, "Infiltrati": "No", "Inflow": "No", "Lift_Stati": 6.0, "Manhole_Id": "LS6-096", "Rating": "Good", "SHAWN": 0.0, "LAYER": "SANMH", "FeatId1": 769.0 }, "geometry": { "type": "Point", "coordinates": [ -80.619915359357378, 28.313614441814263 ] } },
    { "type": "Feature", "properties": { "OBJECTID": 243, "Coating": "Mortar", "Constructi": "Brick/Mortar", "Date_Inspe": "03/10/09", "Depth": 9.58, "Infiltrati": "No", "Inflow": "No", "Lift_Stati": 6.0, "Manhole_Id": "LS6-092", "Rating": "Good", "SHAWN": 0.0, "LAYER": "SANMH", "FeatId1": 768.0 }, "geometry": { "type": "Point", "coordinates": [ -80.618552698218764, 28.312058434465634 ] } },
    { "type": "Feature", "properties": { "OBJECTID": 244, "Coating": "Mortar", "Constructi": "Brick/Mortar", "Date_Inspe": "03/10/09", "Depth": 7.67, "Infiltrati": "No", "Inflow": "No", "Lift_Stati": 6.0, "Manhole_Id": "LS6-093", "Rating": "Good", "SHAWN": 0.0, "LAYER": "SANMH", "FeatId1": 767.0 }, "geometry": { "type": "Point", "coordinates": [ -80.618568534854319, 28.313149758334696 ] } },
    { "type": "Feature", "properties": { "OBJECTID": 245, "Coating": "Mortar", "Constructi": "Brick/Mortar", "Date_Inspe": "03/10/09", "Depth": 4.67, "Infiltrati": "No", "Inflow": "No", "Lift_Stati": 6.0, "Manhole_Id": "LS6-095", "Rating": "Good", "SHAWN": 0.0, "LAYER": "SANMH", "FeatId1": 766.0 }, "geometry": { "type": "Point", "coordinates": [ -80.619856837581523, 28.314256157645122 ] } },
    { "type": "Feature", "properties": { "OBJECTID": 246, "Coating": "Mortar", "Constructi": "Brick/Mortar", "Date_Inspe": "03/10/09", "Depth": 6.17, "Infiltrati": "No", "Inflow": "No", "Lift_Stati": 6.0, "Manhole_Id": "LS6-094", "Rating": "Good", "SHAWN": 0.0, "LAYER": "SANMH", "FeatId1": 765.0 }, "geometry": { "type": "Point", "coordinates": [ -80.618608849210972, 28.314244158021655 ] } },
    { "type": "Feature", "properties": { "OBJECTID": 247, "Coating": "Spectrashield", "Constructi": "Brick/Mortar", "Date_Inspe": "03/10/09", "Depth": 10.17, "Infiltrati": "No", "Inflow": "No", "Lift_Stati": 6.0, "Manhole_Id": "LS6-091", "Rating": "Good", "SHAWN": 0.0, "LAYER": "SANMH", "FeatId1": 764.0 }, "geometry": { "type": "Point", "coordinates": [ -80.618555103217261, 28.31122574715539 ] } },
    { "type": "Feature", "properties": { "OBJECTID": 248, "Coating": "None", "Constructi": "Brick/Mortar", "Date_Inspe": "03/11/09", "Depth": 12.67, "Infiltrati": "Yes", "Inflow": "No", "Lift_Stati": 6.0, "Manhole_Id": "LS6-097", "Rating": "Poor", "SHAWN": 0.0, "LAYER": "SANMH", "FeatId1": 763.0 }, "geometry": { "type": "Point", "coordinates": [ -80.61789365247806, 28.311229528971793 ] } },
    { "type": "Feature", "properties": { "OBJECTID": 249, "Coating": "Spectrashield", "Constructi": "Brick/Mortar", "Date_Inspe": "03/11/09", "Depth": 12.67, "Infiltrati": "No", "Inflow": "No", "Lift_Stati": 6.0, "Manhole_Id": "LS6-098", "Rating": "Good", "SHAWN": 0.0, "LAYER": "SANMH", "FeatId1": 762.0 }, "geometry": { "type": "Point", "coordinates": [ -80.617249089216173, 28.311230429451253 ] } },
    { "type": "Feature", "properties": { "OBJECTID": 250, "Coating": "Mortar", "Constructi": "Brick/Mortar", "Date_Inspe": "03/11/09", "Depth": 6.67, "Infiltrati": "No", "Inflow": "No", "Lift_Stati": 6.0, "Manhole_Id": "LS6-099", "Rating": "Good", "SHAWN": 0.0, "LAYER": "SANMH", "FeatId1": 761.0 }, "geometry": { "type": "Point", "coordinates": [ -80.617252604268657, 28.312329438733631 ] } },
    { "type": "Feature", "properties": { "OBJECTID": 251, "Coating": "Mortar", "Constructi": "Brick/Mortar", "Date_Inspe": "03/11/09", "Depth": 5.58, "Infiltrati": "No", "Inflow": "No", "Lift_Stati": 6.0, "Manhole_Id": "LS6-100", "Rating": "Good", "SHAWN": 0.0, "LAYER": "SANMH", "FeatId1": 760.0 }, "geometry": { "type": "Point", "coordinates": [ -80.617264979187723, 28.31343413053769 ] } },
    { "type": "Feature", "properties": { "OBJECTID": 252, "Coating": "None", "Constructi": "Brick/Mortar", "Date_Inspe": "03/11/09", "Depth": 3.83, "Infiltrati": "No", "Inflow": "No", "Lift_Stati": 6.0, "Manhole_Id": "LS6-101", "Rating": "Good", "SHAWN": 0.0, "LAYER": "SANMH", "FeatId1": 759.0 }, "geometry": { "type": "Point", "coordinates": [ -80.617275560768107, 28.314437955726991 ] } },
    { "type": "Feature", "properties": { "OBJECTID": 253, "Coating": "None", "Constructi": "Brick/Mortar", "Date_Inspe": "03/11/09", "Depth": 4.17, "Infiltrati": "No", "Inflow": "No", "Lift_Stati": 6.0, "Manhole_Id": "LS6-105", "Rating": "Good", "SHAWN": 0.0, "LAYER": "SANMH", "FeatId1": 758.0 }, "geometry": { "type": "Point", "coordinates": [ -80.615959891435978, 28.314442233784607 ] } },
    { "type": "Feature", "properties": { "OBJECTID": 254, "Coating": "None", "Constructi": "Brick/Mortar", "Date_Inspe": "03/11/09", "Depth": 4.33, "Infiltrati": "No", "Inflow": "No", "Lift_Stati": 6.0, "Manhole_Id": "LS6-104", "Rating": "Good", "SHAWN": 0.0, "LAYER": "SANMH", "FeatId1": 757.0 }, "geometry": { "type": "Point", "coordinates": [ -80.615948885197895, 28.31343687155222 ] } },
    { "type": "Feature", "properties": { "OBJECTID": 255, "Coating": "Mortar", "Constructi": "Brick/Mortar", "Date_Inspe": "03/11/09", "Depth": 6.58, "Infiltrati": "No", "Inflow": "No", "Lift_Stati": 6.0, "Manhole_Id": "LS6-103", "Rating": "Good", "SHAWN": 0.0, "LAYER": "SANMH", "FeatId1": 756.0 }, "geometry": { "type": "Point", "coordinates": [ -80.615938790012009, 28.312335459031079 ] } },
    { "type": "Feature", "properties": { "OBJECTID": 256, "Coating": " ", "Constructi": " ", "Date_Inspe": "Lid Welded", "Depth": 0.0, "Infiltrati": " ", "Inflow": " ", "Lift_Stati": 6.0, "Manhole_Id": "LS6-102", "Rating": " ", "SHAWN": 0.0, "LAYER": "SANMH", "FeatId1": 755.0 }, "geometry": { "type": "Point", "coordinates": [ -80.615928704995071, 28.311236627138975 ] } },
    { "type": "Feature", "properties": { "OBJECTID": 257, "Coating": "Spectrashield", "Constructi": "Brick/Mortar", "Date_Inspe": "03/11/09", "Depth": 12.67, "Infiltrati": "No", "Inflow": "No", "Lift_Stati": 6.0, "Manhole_Id": "LS6-106", "Rating": "Good", "SHAWN": 0.0, "LAYER": "SANMH", "FeatId1": 754.0 }, "geometry": { "type": "Point", "coordinates": [ -80.614685018716145, 28.311243330857952 ] } },
    { "type": "Feature", "properties": { "OBJECTID": 258, "Coating": "None", "Constructi": "Brick/Mortar", "Date_Inspe": "03/11/09", "Depth": 6.08, "Infiltrati": "No", "Inflow": "No", "Lift_Stati": 6.0, "Manhole_Id": "LS6-107", "Rating": "Good", "SHAWN": 0.0, "LAYER": "SANMH", "FeatId1": 753.0 }, "geometry": { "type": "Point", "coordinates": [ -80.614692893196022, 28.312344535158044 ] } },
    { "type": "Feature", "properties": { "OBJECTID": 259, "Coating": "None", "Constructi": "Brick/Mortar", "Date_Inspe": "03/11/09", "Depth": 4.0, "Infiltrati": "No", "Inflow": "No", "Lift_Stati": 6.0, "Manhole_Id": "LS6-108", "Rating": "Good", "SHAWN": 0.0, "LAYER": "SANMH", "FeatId1": 752.0 }, "geometry": { "type": "Point", "coordinates": [ -80.614704268743282, 28.313439650953455 ] } },
    { "type": "Feature", "properties": { "OBJECTID": 260, "Coating": "None", "Constructi": "Brick/Mortar", "Date_Inspe": "03/11/09", "Depth": 3.17, "Infiltrati": "No", "Inflow": "No", "Lift_Stati": 6.0, "Manhole_Id": "LS6-109", "Rating": "Good", "SHAWN": 0.0, "LAYER": "SANMH", "FeatId1": 751.0 }, "geometry": { "type": "Point", "coordinates": [ -80.614715991718256, 28.314457069072738 ] } },
    { "type": "Feature", "properties": { "OBJECTID": 261, "Coating": "Mortar", "Constructi": "Brick/Mortar", "Date_Inspe": "03/16/09", "Depth": 4.5, "Infiltrati": "No", "Inflow": "No", "Lift_Stati": 6.0, "Manhole_Id": "LS6-112", "Rating": "Good", "SHAWN": 0.0, "LAYER": "SANMH", "FeatId1": 750.0 }, "geometry": { "type": "Point", "coordinates": [ -80.613596120874135, 28.312523767687829 ] } },
    { "type": "Feature", "properties": { "OBJECTID": 262, "Coating": "Mortar", "Constructi": "Brick/Mortar", "Date_Inspe": "03/16/09", "Depth": 4.83, "Infiltrati": "No", "Inflow": "No", "Lift_Stati": 6.0, "Manhole_Id": "LS6-111", "Rating": "Good", "SHAWN": 0.0, "LAYER": "SANMH", "FeatId1": 749.0 }, "geometry": { "type": "Point", "coordinates": [ -80.613590260766657, 28.312022210675881 ] } },
    { "type": "Feature", "properties": { "OBJECTID": 263, "Coating": "None", "Constructi": "Precast", "Date_Inspe": "03/12/09", "Depth": 16.0, "Infiltrati": "No", "Inflow": "No", "Lift_Stati": 6.0, "Manhole_Id": "LS6-110", "Rating": "Good", "SHAWN": 0.0, "LAYER": "SANMH", "FeatId1": 748.0 }, "geometry": { "type": "Point", "coordinates": [ -80.614295978866181, 28.311244762436129 ] } },
    { "type": "Feature", "properties": { "OBJECTID": 264, "Coating": "Mortar", "Constructi": "Brick/Mortar", "Date_Inspe": "03/16/09", "Depth": 8.92, "Infiltrati": "No", "Inflow": "No", "Lift_Stati": 6.0, "Manhole_Id": "LS6-113", "Rating": "Good", "SHAWN": 0.0, "LAYER": "SANMH", "FeatId1": 747.0 }, "geometry": { "type": "Point", "coordinates": [ -80.613132663891022, 28.311250107145653 ] } },
    { "type": "Feature", "properties": { "OBJECTID": 265, "Coating": "Mortar", "Constructi": "Brick/Mortar", "Date_Inspe": "03/16/09", "Depth": 8.83, "Infiltrati": "No", "Inflow": "No", "Lift_Stati": 6.0, "Manhole_Id": "LS6-114", "Rating": "Good", "SHAWN": 0.0, "LAYER": "SANMH", "FeatId1": 746.0 }, "geometry": { "type": "Point", "coordinates": [ -80.612585631699147, 28.311253295008562 ] } },
    { "type": "Feature", "properties": { "OBJECTID": 266, "Coating": "Mortar", "Constructi": "Brick/Mortar", "Date_Inspe": "03/16/09", "Depth": 6.42, "Infiltrati": "No", "Inflow": "No", "Lift_Stati": 6.0, "Manhole_Id": "LS6-115", "Rating": "Good", "SHAWN": 0.0, "LAYER": "SANMH", "FeatId1": 745.0 }, "geometry": { "type": "Point", "coordinates": [ -80.612590359167328, 28.3123384985599 ] } },
    { "type": "Feature", "properties": { "OBJECTID": 267, "Coating": "Mortar", "Constructi": "Brick/Mortar", "Date_Inspe": "03/16/09", "Depth": 5.0, "Infiltrati": "No", "Inflow": "No", "Lift_Stati": 6.0, "Manhole_Id": "LS6-116", "Rating": "Good", "SHAWN": 0.0, "LAYER": "SANMH", "FeatId1": 744.0 }, "geometry": { "type": "Point", "coordinates": [ -80.612592039822886, 28.313430738087693 ] } },
    { "type": "Feature", "properties": { "OBJECTID": 268, "Coating": "None", "Constructi": "Brick/Mortar", "Date_Inspe": "03/16/09", "Depth": 3.08, "Infiltrati": "No", "Inflow": "No", "Lift_Stati": 6.0, "Manhole_Id": "LS6-117", "Rating": "Good", "SHAWN": 0.0, "LAYER": "SANMH", "FeatId1": 743.0 }, "geometry": { "type": "Point", "coordinates": [ -80.612596381486625, 28.314509820334536 ] } },
    { "type": "Feature", "properties": { "OBJECTID": 269, "Coating": "None", "Constructi": "Brick/Mortar", "Date_Inspe": "03/16/09", "Depth": 5.5, "Infiltrati": "No", "Inflow": "No", "Lift_Stati": 6.0, "Manhole_Id": "LS6-119", "Rating": "Good", "SHAWN": 0.0, "LAYER": "SANMH", "FeatId1": 742.0 }, "geometry": { "type": "Point", "coordinates": [ -80.613431336322662, 28.313534034855131 ] } },
    { "type": "Feature", "properties": { "OBJECTID": 270, "Coating": "None", "Constructi": "Precast", "Date_Inspe": "03/17/09", "Depth": 9.08, "Infiltrati": "No", "Inflow": "No", "Lift_Stati": 6.0, "Manhole_Id": "LS6-124", "Rating": "Good", "SHAWN": 0.0, "LAYER": "SANMH", "FeatId1": 741.0 }, "geometry": { "type": "Point", "coordinates": [ -80.612254474368854, 28.3112552080743 ] } },
    { "type": "Feature", "properties": { "OBJECTID": 271, "Coating": "Mortar", "Constructi": "Brick/Mortar", "Date_Inspe": "03/18/09", "Depth": 9.0, "Infiltrati": "No", "Inflow": "No", "Lift_Stati": 6.0, "Manhole_Id": "LS6-125", "Rating": "Good", "SHAWN": 0.0, "LAYER": "SANMH", "FeatId1": 740.0 }, "geometry": { "type": "Point", "coordinates": [ -80.612136006003681, 28.311254306639203 ] } },
    { "type": "Feature", "properties": { "OBJECTID": 272, "Coating": "None", "Constructi": "Precast", "Date_Inspe": "03/17/09", "Depth": 9.25, "Infiltrati": "No", "Inflow": "No", "Lift_Stati": 6.0, "Manhole_Id": "LS6-127", "Rating": "Good", "SHAWN": 0.0, "LAYER": "SANMH", "FeatId1": 739.0 }, "geometry": { "type": "Point", "coordinates": [ -80.612252328833506, 28.311352821190262 ] } },
    { "type": "Feature", "properties": { "OBJECTID": 273, "Coating": "None", "Constructi": "Precast", "Date_Inspe": "03/18/09", "Depth": 8.25, "Infiltrati": "No", "Inflow": "No", "Lift_Stati": 6.0, "Manhole_Id": "LS6-132", "Rating": "Good", "SHAWN": 0.0, "LAYER": "SANMH", "FeatId1": 738.0 }, "geometry": { "type": "Point", "coordinates": [ -80.611898115995473, 28.311886163363631 ] } },
    { "type": "Feature", "properties": { "OBJECTID": 274, "Coating": "None", "Constructi": "Precast", "Date_Inspe": "03/18/09", "Depth": 8.42, "Infiltrati": "No", "Inflow": "No", "Lift_Stati": 6.0, "Manhole_Id": "LS6-133", "Rating": "Good", "SHAWN": 0.0, "LAYER": "SANMH", "FeatId1": 737.0 }, "geometry": { "type": "Point", "coordinates": [ -80.612273878217422, 28.31189288180326 ] } },
    { "type": "Feature", "properties": { "OBJECTID": 275, "Coating": "Mortar", "Constructi": "Brick/Mortar", "Date_Inspe": "03/18/09", "Depth": 8.83, "Infiltrati": "No", "Inflow": "No", "Lift_Stati": 6.0, "Manhole_Id": "LS6-126", "Rating": "Good", "SHAWN": 0.0, "LAYER": "SANMH", "FeatId1": 736.0 }, "geometry": { "type": "Point", "coordinates": [ -80.611686544774571, 28.311255236043387 ] } },
    { "type": "Feature", "properties": { "OBJECTID": 276, "Coating": "Spectrashield", "Constructi": "Brick/Mortar", "Date_Inspe": "02/24/09", "Depth": 13.75, "Infiltrati": "No", "Inflow": "No", "Lift_Stati": 6.0, "Manhole_Id": "LS6-033", "Rating": "Good", "SHAWN": 0.0, "LAYER": "SANMH", "FeatId1": 735.0 }, "geometry": { "type": "Point", "coordinates": [ -80.612873950033702, 28.309021208740887 ] } },
    { "type": "Feature", "properties": { "OBJECTID": 277, "Coating": "None", "Constructi": "Precast", "Date_Inspe": "04/08/09", "Depth": 2.83, "Infiltrati": "No", "Inflow": "No", "Lift_Stati": 6.0, "Manhole_Id": "LS6-141", "Rating": "Good", "SHAWN": 0.0, "LAYER": "SANMH", "FeatId1": 734.0 }, "geometry": { "type": "Point", "coordinates": [ -80.61223533581591, 28.309081623707371 ] } },
    { "type": "Feature", "properties": { "OBJECTID": 278, "Coating": "Mortar", "Constructi": "Brick/Mortar", "Date_Inspe": "02/24/09", "Depth": 14.33, "Infiltrati": "Yes", "Inflow": "No", "Lift_Stati": 6.0, "Manhole_Id": "LS6-031", "Rating": "Poor", "SHAWN": 0.0, "LAYER": "SANMH", "FeatId1": 733.0 }, "geometry": { "type": "Point", "coordinates": [ -80.611935051138758, 28.309024586206018 ] } },
    { "type": "Feature", "properties": { "OBJECTID": 279, "Coating": "Spectrashield", "Constructi": "Brick/Mortar", "Date_Inspe": "02/24/09", "Depth": 13.67, "Infiltrati": "No", "Inflow": "No", "Lift_Stati": 6.0, "Manhole_Id": "LS6-034", "Rating": "Good", "SHAWN": 0.0, "LAYER": "SANMH", "FeatId1": 732.0 }, "geometry": { "type": "Point", "coordinates": [ -80.613830814990251, 28.309017472280761 ] } },
    { "type": "Feature", "properties": { "OBJECTID": 280, "Coating": "Mortar", "Constructi": "Brick/Mortar", "Date_Inspe": "02/24/09", "Depth": 15.92, "Infiltrati": "Yes", "Inflow": "No", "Lift_Stati": 6.0, "Manhole_Id": "LS6-029", "Rating": "Poor", "SHAWN": 0.0, "LAYER": "SANMH", "FeatId1": 731.0 }, "geometry": { "type": "Point", "coordinates": [ -80.610949612467223, 28.30834225203952 ] } },
    { "type": "Feature", "properties": { "OBJECTID": 281, "Coating": "Spectrashield", "Constructi": "Brick/Mortar", "Date_Inspe": "02/24/09", "Depth": 16.0, "Infiltrati": "No", "Inflow": "No", "Lift_Stati": 6.0, "Manhole_Id": "LS6-030", "Rating": "Good", "SHAWN": 0.0, "LAYER": "SANMH", "FeatId1": 730.0 }, "geometry": { "type": "Point", "coordinates": [ -80.610998511537332, 28.309029396898612 ] } },
    { "type": "Feature", "properties": { "OBJECTID": 282, "Coating": "Mortar", "Constructi": "Brick/Mortar", "Date_Inspe": "02/24/09", "Depth": 15.08, "Infiltrati": "Probable", "Inflow": "No", "Lift_Stati": 6.0, "Manhole_Id": "LS6-028", "Rating": "Good", "SHAWN": 0.0, "LAYER": "SANMH", "FeatId1": 729.0 }, "geometry": { "type": "Point", "coordinates": [ -80.610902730850327, 28.307680581329986 ] } },
    { "type": "Feature", "properties": { "OBJECTID": 283, "Coating": "Spectrashield", "Constructi": "Brick/Mortar", "Date_Inspe": "04/15/09", "Depth": 9.0, "Infiltrati": "Yes", "Inflow": "No", "Lift_Stati": 1.0, "Manhole_Id": "LS1-012", "Rating": "Good", "SHAWN": 0.0, "LAYER": "SANMH", "FeatId1": 728.0 }, "geometry": { "type": "Point", "coordinates": [ -80.608986075624415, 28.311221380569844 ] } },
    { "type": "Feature", "properties": { "OBJECTID": 284, "Coating": "Fiberglass", "Constructi": "Brick/Mortar", "Date_Inspe": "04/22/09", "Depth": 3.66, "Infiltrati": "Probable", "Inflow": "No", "Lift_Stati": 1.0, "Manhole_Id": "LS1-019", "Rating": "Poor", "SHAWN": 0.0, "LAYER": "SANMH", "FeatId1": 727.0 }, "geometry": { "type": "Point", "coordinates": [ -80.610100573616549, 28.311271121575437 ] } },
    { "type": "Feature", "properties": { "OBJECTID": 285, "Coating": "None", "Constructi": "Precast", "Date_Inspe": "03/18/09", "Depth": 8.5, "Infiltrati": "No", "Inflow": "No", "Lift_Stati": 6.0, "Manhole_Id": "LS6-134", "Rating": "Good", "SHAWN": 0.0, "LAYER": "SANMH", "FeatId1": 726.0 }, "geometry": { "type": "Point", "coordinates": [ -80.61190073000185, 28.312671897880637 ] } },
    { "type": "Feature", "properties": { "OBJECTID": 286, "Coating": "None", "Constructi": "Precast", "Date_Inspe": "03/18/09", "Depth": 5.75, "Infiltrati": "No", "Inflow": "No", "Lift_Stati": 6.0, "Manhole_Id": "LS6-135", "Rating": "Good", "SHAWN": 0.0, "LAYER": "SANMH", "FeatId1": 725.0 }, "geometry": { "type": "Point", "coordinates": [ -80.61190706606277, 28.313457267504575 ] } },
    { "type": "Feature", "properties": { "OBJECTID": 287, "Coating": "None", "Constructi": "Brick/Mortar", "Date_Inspe": "04/30/09", "Depth": 5.4, "Infiltrati": "Probable", "Inflow": "No", "Lift_Stati": 1.0, "Manhole_Id": "LS1-066", "Rating": "Fair", "SHAWN": 0.0, "LAYER": "SANMH", "FeatId1": 724.0 }, "geometry": { "type": "Point", "coordinates": [ -80.609488006530611, 28.318878464863246 ] } },
    { "type": "Feature", "properties": { "OBJECTID": 288, "Coating": "Spectrashield", "Constructi": "Brick/Mortar", "Date_Inspe": "05/07/09", "Depth": 7.33, "Infiltrati": "Yes", "Inflow": "No", "Lift_Stati": 1.0, "Manhole_Id": "LS1-084", "Rating": "Good", "SHAWN": 0.0, "LAYER": "SANMH", "FeatId1": 723.0 }, "geometry": { "type": "Point", "coordinates": [ -80.610152542192282, 28.318882815440734 ] } },
    { "type": "Feature", "properties": { "OBJECTID": 289, "Coating": "Fiberglass", "Constructi": "Brick/Mortar", "Date_Inspe": "05/13/09", "Depth": 9.16, "Infiltrati": "No", "Inflow": "No", "Lift_Stati": 1.0, "Manhole_Id": "LS1-102", "Rating": "Good", "SHAWN": 0.0, "LAYER": "SANMH", "FeatId1": 722.0 }, "geometry": { "type": "Point", "coordinates": [ -80.612070616089611, 28.318399140134972 ] } },
    { "type": "Feature", "properties": { "OBJECTID": 290, "Coating": "None", "Constructi": "Precast", "Date_Inspe": "05/13/09", "Depth": 2.75, "Infiltrati": "No", "Inflow": "No", "Lift_Stati": 1.0, "Manhole_Id": "LS1-105", "Rating": "Good", "SHAWN": 0.0, "LAYER": "SANMH", "FeatId1": 721.0 }, "geometry": { "type": "Point", "coordinates": [ -80.613284933950553, 28.315558270700695 ] } },
    { "type": "Feature", "properties": { "OBJECTID": 291, "Coating": "None", "Constructi": "Precast", "Date_Inspe": "05/13/09", "Depth": 3.66, "Infiltrati": "Probable", "Inflow": "No", "Lift_Stati": 1.0, "Manhole_Id": "LS1-104", "Rating": "Good", "SHAWN": 0.0, "LAYER": "SANMH", "FeatId1": 720.0 }, "geometry": { "type": "Point", "coordinates": [ -80.613288416957275, 28.316467325907677 ] } },
    { "type": "Feature", "properties": { "OBJECTID": 292, "Coating": "None", "Constructi": "Precast", "Date_Inspe": "05/13/09", "Depth": 4.75, "Infiltrati": "No", "Inflow": "No", "Lift_Stati": 1.0, "Manhole_Id": "LS1-103", "Rating": "Good", "SHAWN": 0.0, "LAYER": "SANMH", "FeatId1": 719.0 }, "geometry": { "type": "Point", "coordinates": [ -80.613291129640444, 28.317399887316899 ] } },
    { "type": "Feature", "properties": { "OBJECTID": 293, "Coating": "Spectrashield", "Constructi": "Brick/Mortar", "Date_Inspe": "09/11/13", "Depth": 6.33, "Infiltrati": "Yes", "Inflow": "No", "Lift_Stati": 1.0, "Manhole_Id": "LS1-106", "Rating": "Good", "SHAWN": 0.0, "LAYER": "SANMH", "FeatId1": 718.0 }, "geometry": { "type": "Point", "coordinates": [ -80.613291532927875, 28.318333772147373 ] } },
    { "type": "Feature", "properties": { "OBJECTID": 294, "Coating": "Fiberglass", "Constructi": "Brick/Mortar", "Date_Inspe": "05/13/09", "Depth": 6.08, "Infiltrati": "No", "Inflow": "No", "Lift_Stati": 1.0, "Manhole_Id": "LS1-107", "Rating": "Good", "SHAWN": 0.0, "LAYER": "SANMH", "FeatId1": 717.0 }, "geometry": { "type": "Point", "coordinates": [ -80.613292602848659, 28.318395991675427 ] } },
    { "type": "Feature", "properties": { "OBJECTID": 295, "Coating": "None", "Constructi": "Brick/Mortar", "Date_Inspe": "00/00/00", "Depth": 0.0, "Infiltrati": "No", "Inflow": "No", "Lift_Stati": 1.0, "Manhole_Id": "LS1-116", "Rating": "Good", "SHAWN": 0.0, "LAYER": "SANMH", "FeatId1": 716.0 }, "geometry": { "type": "Point", "coordinates": [ -80.614528118665703, 28.31839468785417 ] } },
    { "type": "Feature", "properties": { "OBJECTID": 296, "Coating": "Fiberglass", "Constructi": "Brick/Mortar", "Date_Inspe": "05/13/09", "Depth": 6.08, "Infiltrati": "No", "Inflow": "No", "Lift_Stati": 1.0, "Manhole_Id": "LS1-108", "Rating": "Good", "SHAWN": 0.0, "LAYER": "SANMH", "FeatId1": 715.0 }, "geometry": { "type": "Point", "coordinates": [ -80.614534514867202, 28.318334591935191 ] } },
    { "type": "Feature", "properties": { "OBJECTID": 297, "Coating": "None", "Constructi": "Precast", "Date_Inspe": "4.67", "Depth": 4.67, "Infiltrati": "No", "Inflow": "No", "Lift_Stati": 1.0, "Manhole_Id": "LS1-109", "Rating": "Good", "SHAWN": 0.0, "LAYER": "SANMH", "FeatId1": 714.0 }, "geometry": { "type": "Point", "coordinates": [ -80.614534226934254, 28.317398508286367 ] } },
    { "type": "Feature", "properties": { "OBJECTID": 298, "Coating": "None", "Constructi": "Precast", "Date_Inspe": "05/13/09", "Depth": 3.5, "Infiltrati": "No", "Inflow": "No", "Lift_Stati": 1.0, "Manhole_Id": "LS1-110", "Rating": "Good", "SHAWN": 0.0, "LAYER": "SANMH", "FeatId1": 713.0 }, "geometry": { "type": "Point", "coordinates": [ -80.61453129726317, 28.316459771304153 ] } },
    { "type": "Feature", "properties": { "OBJECTID": 299, "Coating": "None", "Constructi": "Precast", "Date_Inspe": "05/13/09", "Depth": 2.33, "Infiltrati": "No", "Inflow": "No", "Lift_Stati": 1.0, "Manhole_Id": "LS1-111", "Rating": "Good", "SHAWN": 0.0, "LAYER": "SANMH", "FeatId1": 712.0 }, "geometry": { "type": "Point", "coordinates": [ -80.614528044873822, 28.315554807143506 ] } },
    { "type": "Feature", "properties": { "OBJECTID": 300, "Coating": "Mortar", "Constructi": "Brick/Mortar", "Date_Inspe": "05/18/09", "Depth": 6.08, "Infiltrati": "No", "Inflow": "No", "Lift_Stati": 1.0, "Manhole_Id": "LS1-119", "Rating": "Good", "SHAWN": 0.0, "LAYER": "SANMH", "FeatId1": 711.0 }, "geometry": { "type": "Point", "coordinates": [ -80.615776862972169, 28.317413130971822 ] } },
    { "type": "Feature", "properties": { "OBJECTID": 301, "Coating": "None", "Constructi": "Brick/Mortar", "Date_Inspe": "00/00/00", "Depth": 0.0, "Infiltrati": "No", "Inflow": "No", "Lift_Stati": 1.0, "Manhole_Id": "LS1-117", "Rating": "Good", "SHAWN": 0.0, "LAYER": "SANMH", "FeatId1": 710.0 }, "geometry": { "type": "Point", "coordinates": [ -80.615383843851433, 28.318392585582536 ] } },
    { "type": "Feature", "properties": { "OBJECTID": 302, "Coating": "None", "Constructi": "Brick/Mortar", "Date_Inspe": "00/00/00", "Depth": 0.0, "Infiltrati": "No", "Inflow": "No", "Lift_Stati": 1.0, "Manhole_Id": "LS1-118", "Rating": "Good", "SHAWN": 0.0, "LAYER": "SANMH", "FeatId1": 709.0 }, "geometry": { "type": "Point", "coordinates": [ -80.61578062898846, 28.318390580935567 ] } },
    { "type": "Feature", "properties": { "OBJECTID": 303, "Coating": "None", "Constructi": "Brick/Mortar", "Date_Inspe": "00/00/00", "Depth": 0.0, "Infiltrati": "No", "Inflow": "No", "Lift_Stati": 1.0, "Manhole_Id": "LS1-126", "Rating": "Good", "SHAWN": 0.0, "LAYER": "SANMH", "FeatId1": 708.0 }, "geometry": { "type": "Point", "coordinates": [ -80.616192350234655, 28.318389043956973 ] } },
    { "type": "Feature", "properties": { "OBJECTID": 304, "Coating": "None", "Constructi": "Brick/Mortar", "Date_Inspe": "00/00/00", "Depth": 0.0, "Infiltrati": "No", "Inflow": "No", "Lift_Stati": 1.0, "Manhole_Id": "LS1-127", "Rating": "Good", "SHAWN": 0.0, "LAYER": "SANMH", "FeatId1": 707.0 }, "geometry": { "type": "Point", "coordinates": [ -80.616943314056584, 28.318389222560032 ] } },
    { "type": "Feature", "properties": { "OBJECTID": 305, "Coating": "Mortar", "Constructi": "Brick/Mortar", "Date_Inspe": "05/18/09", "Depth": 7.58, "Infiltrati": "Probable", "Inflow": "No", "Lift_Stati": 1.0, "Manhole_Id": "LS1-122", "Rating": "Fair", "SHAWN": 0.0, "LAYER": "SANMH", "FeatId1": 706.0 }, "geometry": { "type": "Point", "coordinates": [ -80.617023922151489, 28.318217706358443 ] } },
    { "type": "Feature", "properties": { "OBJECTID": 306, "Coating": "None", "Constructi": "Brick/Mortar", "Date_Inspe": "05/18/09", "Depth": 6.08, "Infiltrati": "Probable", "Inflow": "No", "Lift_Stati": 1.0, "Manhole_Id": "LS1-123", "Rating": "Fair", "SHAWN": 0.0, "LAYER": "SANMH", "FeatId1": 705.0 }, "geometry": { "type": "Point", "coordinates": [ -80.617019939154204, 28.317125743314268 ] } },
    { "type": "Feature", "properties": { "OBJECTID": 307, "Coating": "None", "Constructi": "Brick/Mortar", "Date_Inspe": "05/18/09", "Depth": 4.92, "Infiltrati": "Probable", "Inflow": "No", "Lift_Stati": 1.0, "Manhole_Id": "LS1-124", "Rating": "Fair", "SHAWN": 0.0, "LAYER": "SANMH", "FeatId1": 704.0 }, "geometry": { "type": "Point", "coordinates": [ -80.617019716345254, 28.316296857353457 ] } },
    { "type": "Feature", "properties": { "OBJECTID": 308, "Coating": "Mortar", "Constructi": "Brick/Mortar", "Date_Inspe": "05/26/09", "Depth": 3.5, "Infiltrati": "No", "Inflow": "No", "Lift_Stati": 1.0, "Manhole_Id": "LS1-133", "Rating": "Good", "SHAWN": 0.0, "LAYER": "SANMH", "FeatId1": 703.0 }, "geometry": { "type": "Point", "coordinates": [ -80.618390711003329, 28.315444456432559 ] } },
    { "type": "Feature", "properties": { "OBJECTID": 309, "Coating": "None", "Constructi": "Brick/Mortar", "Date_Inspe": "00/00/00", "Depth": 0.0, "Infiltrati": "No", "Inflow": "No", "Lift_Stati": 1.0, "Manhole_Id": "LS1-128", "Rating": "Good", "SHAWN": 0.0, "LAYER": "SANMH", "FeatId1": 702.0 }, "geometry": { "type": "Point", "coordinates": [ -80.617717080792048, 28.318387113839929 ] } },
    { "type": "Feature", "properties": { "OBJECTID": 310, "Coating": "None", "Constructi": "Brick/Mortar", "Date_Inspe": "00/00/00", "Depth": 0.0, "Infiltrati": "No", "Inflow": "No", "Lift_Stati": 1.0, "Manhole_Id": "LS1-129", "Rating": "Good", "SHAWN": 0.0, "LAYER": "SANMH", "FeatId1": 701.0 }, "geometry": { "type": "Point", "coordinates": [ -80.61813123175186, 28.318416763678915 ] } },
    { "type": "Feature", "properties": { "OBJECTID": 311, "Coating": "None", "Constructi": "Brick/Mortar", "Date_Inspe": "00/00/00", "Depth": 0.0, "Infiltrati": "No", "Inflow": "No", "Lift_Stati": 1.0, "Manhole_Id": "LS1-130", "Rating": "Good", "SHAWN": 0.0, "LAYER": "SANMH", "FeatId1": 700.0 }, "geometry": { "type": "Point", "coordinates": [ -80.618397492679691, 28.318416710678754 ] } },
    { "type": "Feature", "properties": { "OBJECTID": 312, "Coating": "Spectrashield", "Constructi": "Brick/Mortar", "Date_Inspe": "06/03/09", "Depth": 6.92, "Infiltrati": "No", "Inflow": "No", "Lift_Stati": 1.0, "Manhole_Id": "LS1-150", "Rating": "Good", "SHAWN": 0.0, "LAYER": "SANMH", "FeatId1": 699.0 }, "geometry": { "type": "Point", "coordinates": [ -80.621341438641409, 28.318905430280754 ] } },
    { "type": "Feature", "properties": { "OBJECTID": 313, "Coating": "Spectrashield", "Constructi": "Brick/Mortar", "Date_Inspe": "05/28/09", "Depth": 6.83, "Infiltrati": "No", "Inflow": "No", "Lift_Stati": 1.0, "Manhole_Id": "LS1-144", "Rating": "Good", "SHAWN": 0.0, "LAYER": "SANMH", "FeatId1": 698.0 }, "geometry": { "type": "Point", "coordinates": [ -80.619731331857011, 28.319053150909909 ] } },
    { "type": "Feature", "properties": { "OBJECTID": 314, "Coating": "None", "Constructi": "Brick/Mortar", "Date_Inspe": "00/00/00", "Depth": 11.0, "Infiltrati": "No", "Inflow": "No", "Lift_Stati": 1.0, "Manhole_Id": "LS1-153", "Rating": "Good", "SHAWN": 0.0, "LAYER": "SANMH", "FeatId1": 697.0 }, "geometry": { "type": "Point", "coordinates": [ -80.621674755763394, 28.31842768132131 ] } },
    { "type": "Feature", "properties": { "OBJECTID": 315, "Coating": "None", "Constructi": "Brick/Mortar", "Date_Inspe": "00/00/00", "Depth": 11.0, "Infiltrati": "No", "Inflow": "No", "Lift_Stati": 1.0, "Manhole_Id": "LS1-154", "Rating": "Good", "SHAWN": 0.0, "LAYER": "SANMH", "FeatId1": 696.0 }, "geometry": { "type": "Point", "coordinates": [ -80.621832323317292, 28.318439734181887 ] } },
    { "type": "Feature", "properties": { "OBJECTID": 316, "Coating": "Fiberglass", "Constructi": "Brick/Mortar", "Date_Inspe": "05/28/09", "Depth": 9.5, "Infiltrati": "No", "Inflow": "No", "Lift_Stati": 1.0, "Manhole_Id": "LS1-143", "Rating": "Good", "SHAWN": 0.0, "LAYER": "SANMH", "FeatId1": 695.0 }, "geometry": { "type": "Point", "coordinates": [ -80.619656722967747, 28.318420446609068 ] } },
    { "type": "Feature", "properties": { "OBJECTID": 317, "Coating": "Fiberglass", "Constructi": "Brick/Mortar", "Date_Inspe": "05/28/09", "Depth": 9.5, "Infiltrati": "No", "Inflow": "No", "Lift_Stati": 1.0, "Manhole_Id": "LS1-142", "Rating": "Good", "SHAWN": 0.0, "LAYER": "SANMH", "FeatId1": 694.0 }, "geometry": { "type": "Point", "coordinates": [ -80.619344520680883, 28.318422251775097 ] } },
    { "type": "Feature", "properties": { "OBJECTID": 318, "Coating": "None", "Constructi": "Brick/Mortar", "Date_Inspe": "04/20/09", "Depth": 5.66, "Infiltrati": "Probable", "Inflow": "No", "Lift_Stati": 1.0, "Manhole_Id": "LS1-022", "Rating": "Fair", "SHAWN": 0.0, "LAYER": "SANMH", "FeatId1": 693.0 }, "geometry": { "type": "Point", "coordinates": [ -80.608837352768333, 28.316630451058 ] } },
    { "type": "Feature", "properties": { "OBJECTID": 319, "Coating": " ", "Constructi": " ", "Date_Inspe": " ", "Depth": 0.0, "Infiltrati": " ", "Inflow": " ", "Lift_Stati": 6.0, "Manhole_Id": "LS6-144", "Rating": " ", "SHAWN": 0.0, "LAYER": "SANMH", "FeatId1": 692.0 }, "geometry": { "type": "Point", "coordinates": [ -80.610653487561407, 28.299099199836618 ] } },
    { "type": "Feature", "properties": { "OBJECTID": 320, "Coating": " ", "Constructi": " ", "Date_Inspe": " ", "Depth": 0.0, "Infiltrati": " ", "Inflow": " ", "Lift_Stati": 6.0, "Manhole_Id": "LS6-145", "Rating": " ", "SHAWN": 0.0, "LAYER": "SANMH", "FeatId1": 691.0 }, "geometry": { "type": "Point", "coordinates": [ -80.610676395564013, 28.300019608395971 ] } },
    { "type": "Feature", "properties": { "OBJECTID": 321, "Coating": "Mortar", "Constructi": "Brick/Mortar", "Date_Inspe": "02/26/09", "Depth": 5.0, "Infiltrati": "Probable", "Inflow": "No", "Lift_Stati": 6.0, "Manhole_Id": "LS6-060", "Rating": "Fair", "SHAWN": 0.0, "LAYER": "SANMH", "FeatId1": 690.0 }, "geometry": { "type": "Point", "coordinates": [ -80.610142851404675, 28.300544993812494 ] } },
    { "type": "Feature", "properties": { "OBJECTID": 322, "Coating": "None", "Constructi": "Precast", "Date_Inspe": "02/19/09", "Depth": 7.83, "Infiltrati": "No", "Inflow": "No", "Lift_Stati": 6.0, "Manhole_Id": "LS6-013", "Rating": "Good", "SHAWN": 0.0, "LAYER": "SANMH", "FeatId1": 689.0 }, "geometry": { "type": "Point", "coordinates": [ -80.610714659228535, 28.301728303796207 ] } },
    { "type": "Feature", "properties": { "OBJECTID": 323, "Coating": "Spectrashield", "Constructi": "Brick/Mortar", "Date_Inspe": "09/11/13", "Depth": 9.75, "Infiltrati": "No", "Inflow": "No", "Lift_Stati": 6.0, "Manhole_Id": "LS6-012", "Rating": "Good", "SHAWN": 0.0, "LAYER": "SANMH", "FeatId1": 688.0 }, "geometry": { "type": "Point", "coordinates": [ -80.610618807358733, 28.301728987895938 ] } },
    { "type": "Feature", "properties": { "OBJECTID": 324, "Coating": "None", "Constructi": "Precast", "Date_Inspe": "02/19/09", "Depth": 5.5, "Infiltrati": "No", "Inflow": "No", "Lift_Stati": 6.0, "Manhole_Id": "LS6-014", "Rating": "Good", "SHAWN": 0.0, "LAYER": "SANMH", "FeatId1": 687.0 }, "geometry": { "type": "Point", "coordinates": [ -80.611332115262442, 28.30176048804395 ] } },
    { "type": "Feature", "properties": { "OBJECTID": 325, "Coating": "None", "Constructi": "Precast", "Date_Inspe": "02/19/09", "Depth": 4.58, "Infiltrati": "No", "Inflow": "No", "Lift_Stati": 6.0, "Manhole_Id": "LS6-015", "Rating": "Good", "SHAWN": 0.0, "LAYER": "SANMH", "FeatId1": 686.0 }, "geometry": { "type": "Point", "coordinates": [ -80.611484713243257, 28.302040851192032 ] } },
    { "type": "Feature", "properties": { "OBJECTID": 326, "Coating": "None", "Constructi": "Precast", "Date_Inspe": "02/23/09", "Depth": 3.83, "Infiltrati": "No", "Inflow": "No", "Lift_Stati": 6.0, "Manhole_Id": "LS6-016", "Rating": "Good", "SHAWN": 0.0, "LAYER": "SANMH", "FeatId1": 685.0 }, "geometry": { "type": "Point", "coordinates": [ -80.611497972131843, 28.302536664760115 ] } },
    { "type": "Feature", "properties": { "OBJECTID": 327, "Coating": "None", "Constructi": "Brick/Mortar", "Date_Inspe": "02/23/09", "Depth": 2.92, "Infiltrati": "Probable", "Inflow": "No", "Lift_Stati": 6.0, "Manhole_Id": "LS6-017", "Rating": "Fair", "SHAWN": 0.0, "LAYER": "SANMH", "FeatId1": 684.0 }, "geometry": { "type": "Point", "coordinates": [ -80.610908341500505, 28.302546528874107 ] } },
    { "type": "Feature", "properties": { "OBJECTID": 328, "Coating": "None", "Constructi": "Brick/Mortar", "Date_Inspe": "02/23/09", "Depth": 3.0, "Infiltrati": "Probable", "Inflow": "No", "Lift_Stati": 6.0, "Manhole_Id": "LS6-018", "Rating": "Fair", "SHAWN": 0.0, "LAYER": "SANMH", "FeatId1": 683.0 }, "geometry": { "type": "Point", "coordinates": [ -80.611516773881263, 28.303359370483793 ] } },
    { "type": "Feature", "properties": { "OBJECTID": 329, "Coating": "None", "Constructi": "Brick/Mortar", "Date_Inspe": "02/23/09", "Depth": 2.75, "Infiltrati": "No", "Inflow": "No", "Lift_Stati": 6.0, "Manhole_Id": "LS6-019", "Rating": "Good", "SHAWN": 0.0, "LAYER": "SANMH", "FeatId1": 682.0 }, "geometry": { "type": "Point", "coordinates": [ -80.610924856523511, 28.303372985599289 ] } },
    { "type": "Feature", "properties": { "OBJECTID": 330, "Coating": "None", "Constructi": "Brick/Mortar", "Date_Inspe": "02/23/09", "Depth": 11.17, "Infiltrati": "Probable", "Inflow": "No", "Lift_Stati": 6.0, "Manhole_Id": "LS6-020", "Rating": "Fair", "SHAWN": 0.0, "LAYER": "SANMH", "FeatId1": 681.0 }, "geometry": { "type": "Point", "coordinates": [ -80.610627787556524, 28.302144326778802 ] } },
    { "type": "Feature", "properties": { "OBJECTID": 331, "Coating": "Mortar", "Constructi": "Brick/Mortar", "Date_Inspe": "02/23/09", "Depth": 10.67, "Infiltrati": "Probable", "Inflow": "No", "Lift_Stati": 6.0, "Manhole_Id": "LS6-021", "Rating": "Poor", "SHAWN": 0.0, "LAYER": "SANMH", "FeatId1": 680.0 }, "geometry": { "type": "Point", "coordinates": [ -80.610634778732219, 28.302326630042256 ] } },
    { "type": "Feature", "properties": { "OBJECTID": 332, "Coating": "Spectrashield", "Constructi": "Brick/Mortar", "Date_Inspe": "02/23/09", "Depth": 10.58, "Infiltrati": "No", "Inflow": "No", "Lift_Stati": 6.0, "Manhole_Id": "LS6-022", "Rating": "Good", "SHAWN": 0.0, "LAYER": "SANMH", "FeatId1": 679.0 }, "geometry": { "type": "Point", "coordinates": [ -80.610653240979246, 28.302371710068329 ] } },
    { "type": "Feature", "properties": { "OBJECTID": 333, "Coating": "Spectrashield", "Constructi": "Brick/Mortar", "Date_Inspe": "03/02/09", "Depth": 10.5, "Infiltrati": "No", "Inflow": "No", "Lift_Stati": 6.0, "Manhole_Id": "LS6-064", "Rating": "Good", "SHAWN": 0.0, "LAYER": "SANMH", "FeatId1": 678.0 }, "geometry": { "type": "Point", "coordinates": [ -80.610179935249207, 28.304119992252446 ] } },
    { "type": "Feature", "properties": { "OBJECTID": 334, "Coating": "Spectrashield", "Constructi": "Brick/Mortar", "Date_Inspe": "02/23/09", "Depth": 12.5, "Infiltrati": "No", "Inflow": "No", "Lift_Stati": 6.0, "Manhole_Id": "LS6-024", "Rating": "Good", "SHAWN": 0.0, "LAYER": "SANMH", "FeatId1": 677.0 }, "geometry": { "type": "Point", "coordinates": [ -80.610659847488151, 28.304102891019255 ] } },
    { "type": "Feature", "properties": { "OBJECTID": 335, "Coating": "None", "Constructi": "Precast", "Date_Inspe": "03/02/09", "Depth": 6.5, "Infiltrati": "No", "Inflow": "No", "Lift_Stati": 6.0, "Manhole_Id": "LS6-065", "Rating": "Good", "SHAWN": 0.0, "LAYER": "SANMH", "FeatId1": 676.0 }, "geometry": { "type": "Point", "coordinates": [ -80.610178615124909, 28.304195173056808 ] } },
    { "type": "Feature", "properties": { "OBJECTID": 336, "Coating": "Spectrashield", "Constructi": "Brick/Mortar", "Date_Inspe": "02/23/09", "Depth": 12.5, "Infiltrati": "No", "Inflow": "No", "Lift_Stati": 6.0, "Manhole_Id": "LS6-025", "Rating": "Good", "SHAWN": 0.0, "LAYER": "SANMH", "FeatId1": 675.0 }, "geometry": { "type": "Point", "coordinates": [ -80.610720464056115, 28.304987556177547 ] } },
    { "type": "Feature", "properties": { "OBJECTID": 337, "Coating": "None", "Constructi": "Brick/Mortar", "Date_Inspe": "02/23/09", "Depth": 12.92, "Infiltrati": "Probable", "Inflow": "No", "Lift_Stati": 6.0, "Manhole_Id": "LS6-026", "Rating": "Fair", "SHAWN": 0.0, "LAYER": "SANMH", "FeatId1": 674.0 }, "geometry": { "type": "Point", "coordinates": [ -80.610782105664228, 28.305876017687108 ] } },
    { "type": "Feature", "properties": { "OBJECTID": 338, "Coating": "Spectrashield", "Constructi": "Brick/Mortar", "Date_Inspe": "02/23/09", "Depth": 14.5, "Infiltrati": "No", "Inflow": "No", "Lift_Stati": 6.0, "Manhole_Id": "LS6-027", "Rating": "Good", "SHAWN": 0.0, "LAYER": "SANMH", "FeatId1": 673.0 }, "geometry": { "type": "Point", "coordinates": [ -80.610840702677223, 28.306768393886159 ] } },
    { "type": "Feature", "properties": { "OBJECTID": 339, "Coating": "Spectrashield", "Constructi": "Brick/Mortar", "Date_Inspe": "02/23/09", "Depth": 11.67, "Infiltrati": "No", "Inflow": "No", "Lift_Stati": 6.0, "Manhole_Id": "LS6-023", "Rating": "Good", "SHAWN": 0.0, "LAYER": "SANMH", "FeatId1": 672.0 }, "geometry": { "type": "Point", "coordinates": [ -80.61064959285595, 28.303197383828831 ] } },
    { "type": "Feature", "properties": { "OBJECTID": 340, "Coating": "None", "Constructi": "Precast", "Date_Inspe": "02/24/09", "Depth": 7.0, "Infiltrati": "No", "Inflow": "No", "Lift_Stati": 6.0, "Manhole_Id": "LS6-032", "Rating": "Good", "SHAWN": 0.0, "LAYER": "SANMH", "FeatId1": 671.0 }, "geometry": { "type": "Point", "coordinates": [ -80.611933767311939, 28.309061421793484 ] } },
    { "type": "Feature", "properties": { "OBJECTID": 341, "Coating": "Mortar", "Constructi": "Brick/Mortar", "Date_Inspe": "03/10/09", "Depth": 4.67, "Infiltrati": "No", "Inflow": "No", "Lift_Stati": 6.0, "Manhole_Id": "LS6-089", "Rating": "Fair", "SHAWN": 0.0, "LAYER": "SANMH", "FeatId1": 670.0 }, "geometry": { "type": "Point", "coordinates": [ -80.619798788161546, 28.311850749062522 ] } },
    { "type": "Feature", "properties": { "OBJECTID": 342, "Coating": " ", "Constructi": " ", "Date_Inspe": "00/00/00", "Depth": 0.0, "Infiltrati": " ", "Inflow": " ", "Lift_Stati": 6.0, "Manhole_Id": "LS6-150", "Rating": " ", "SHAWN": 0.0, "LAYER": "SANMH", "FeatId1": 669.0 }, "geometry": { "type": "Point", "coordinates": [ -80.61422193679995, 28.311312714586819 ] } },
    { "type": "Feature", "properties": { "OBJECTID": 343, "Coating": "Mortar", "Constructi": "Brick/Mortar", "Date_Inspe": "03/17/09", "Depth": 8.75, "Infiltrati": "No", "Inflow": "No", "Lift_Stati": 6.0, "Manhole_Id": "LS6-121", "Rating": "Good", "SHAWN": 0.0, "LAYER": "SANMH", "FeatId1": 668.0 }, "geometry": { "type": "Point", "coordinates": [ -80.61422667248172, 28.312058890858804 ] } },
    { "type": "Feature", "properties": { "OBJECTID": 344, "Coating": " ", "Constructi": " ", "Date_Inspe": " ", "Depth": 0.0, "Infiltrati": " ", "Inflow": " ", "Lift_Stati": 6.0, "Manhole_Id": "LS6-143", "Rating": " ", "SHAWN": 0.0, "LAYER": "SANMH", "FeatId1": 667.0 }, "geometry": { "type": "Point", "coordinates": [ -80.614226007880916, 28.312013054034828 ] } },
    { "type": "Feature", "properties": { "OBJECTID": 345, "Coating": " ", "Constructi": " ", "Date_Inspe": " ", "Depth": 0.0, "Infiltrati": " ", "Inflow": " ", "Lift_Stati": 6.0, "Manhole_Id": "LS6-037", "Rating": " ", "SHAWN": 0.0, "LAYER": "SANMH", "FeatId1": 666.0 }, "geometry": { "type": "Point", "coordinates": [ -80.614250701891194, 28.310430459029536 ] } },
    { "type": "Feature", "properties": { "OBJECTID": 346, "Coating": "Spectrashield", "Constructi": "Brick/Mortar", "Date_Inspe": "02/24/09", "Depth": 14.18, "Infiltrati": "No", "Inflow": "No", "Lift_Stati": 6.0, "Manhole_Id": "LS6-036", "Rating": "Good", "SHAWN": 0.0, "LAYER": "SANMH", "FeatId1": 665.0 }, "geometry": { "type": "Point", "coordinates": [ -80.614210790601632, 28.309636280888547 ] } },
    { "type": "Feature", "properties": { "OBJECTID": 347, "Coating": "Spectrashield", "Constructi": "Brick/Mortar", "Date_Inspe": "02/24/09", "Depth": 13.67, "Infiltrati": "No", "Inflow": "No", "Lift_Stati": 6.0, "Manhole_Id": "LS6-035", "Rating": "Good", "SHAWN": 0.0, "LAYER": "SANMH", "FeatId1": 664.0 }, "geometry": { "type": "Point", "coordinates": [ -80.613884631708046, 28.309635286587884 ] } },
    { "type": "Feature", "properties": { "OBJECTID": 348, "Coating": " ", "Constructi": " ", "Date_Inspe": " ", "Depth": 0.0, "Infiltrati": " ", "Inflow": " ", "Lift_Stati": 6.0, "Manhole_Id": "LS6-148", "Rating": " ", "SHAWN": 0.0, "LAYER": "SANMH", "FeatId1": 663.0 }, "geometry": { "type": "Point", "coordinates": [ -80.611261871160863, 28.312793722644276 ] } },
    { "type": "Feature", "properties": { "OBJECTID": 349, "Coating": "None", "Constructi": "Precast", "Date_Inspe": "03/18/09", "Depth": 5.25, "Infiltrati": "No", "Inflow": "Yes", "Lift_Stati": 6.0, "Manhole_Id": "LS6-131", "Rating": "Good", "SHAWN": 0.0, "LAYER": "SANMH", "FeatId1": 662.0 }, "geometry": { "type": "Point", "coordinates": [ -80.611569781238856, 28.311895346850768 ] } },
    { "type": "Feature", "properties": { "OBJECTID": 350, "Coating": "Mortar", "Constructi": "Brick/Mortar", "Date_Inspe": "03/17/09", "Depth": 3.75, "Infiltrati": "No", "Inflow": "No", "Lift_Stati": 6.0, "Manhole_Id": "LS6-123", "Rating": "Good", "SHAWN": 0.0, "LAYER": "SANMH", "FeatId1": 661.0 }, "geometry": { "type": "Point", "coordinates": [ -80.613593006215666, 28.311586189096943 ] } },
    { "type": "Feature", "properties": { "OBJECTID": 351, "Coating": "Mortar", "Constructi": "Brick/Mortar", "Date_Inspe": "03/16/09", "Depth": 7.5, "Infiltrati": "No", "Inflow": "No", "Lift_Stati": 6.0, "Manhole_Id": "LS6-120", "Rating": "Good", "SHAWN": 0.0, "LAYER": "SANMH", "FeatId1": 660.0 }, "geometry": { "type": "Point", "coordinates": [ -80.614232117991023, 28.31315909284428 ] } },
    { "type": "Feature", "properties": { "OBJECTID": 352, "Coating": "None", "Constructi": "Brick/Mortar", "Date_Inspe": "03/16/09", "Depth": 3.42, "Infiltrati": "No", "Inflow": "No", "Lift_Stati": 6.0, "Manhole_Id": "LS6-118", "Rating": "Good", "SHAWN": 0.0, "LAYER": "SANMH", "FeatId1": 659.0 }, "geometry": { "type": "Point", "coordinates": [ -80.613438596819108, 28.314558073661829 ] } },
    { "type": "Feature", "properties": { "OBJECTID": 353, "Coating": "None", "Constructi": "Precast", "Date_Inspe": "03/18/09", "Depth": 4.0, "Infiltrati": "No", "Inflow": "No", "Lift_Stati": 6.0, "Manhole_Id": "LS6-136", "Rating": "Good", "SHAWN": 0.0, "LAYER": "SANMH", "FeatId1": 658.0 }, "geometry": { "type": "Point", "coordinates": [ -80.611914477644802, 28.314242988411085 ] } },
    { "type": "Feature", "properties": { "OBJECTID": 354, "Coating": "None", "Constructi": "Precast", "Date_Inspe": "03/18/09", "Depth": 6.0, "Infiltrati": "No", "Inflow": "No", "Lift_Stati": 6.0, "Manhole_Id": "LS6-130", "Rating": "Good", "SHAWN": 0.0, "LAYER": "SANMH", "FeatId1": 657.0 }, "geometry": { "type": "Point", "coordinates": [ -80.611256426162413, 28.311854048559365 ] } },
    { "type": "Feature", "properties": { "OBJECTID": 355, "Coating": " ", "Constructi": " ", "Date_Inspe": " ", "Depth": 0.0, "Infiltrati": " ", "Inflow": " ", "Lift_Stati": 6.0, "Manhole_Id": "LS6-129", "Rating": " ", "SHAWN": 0.0, "LAYER": "SANMH", "FeatId1": 656.0 }, "geometry": { "type": "Point", "coordinates": [ -80.611250145798024, 28.311510907507103 ] } },
    { "type": "Feature", "properties": { "OBJECTID": 356, "Coating": " ", "Constructi": " ", "Date_Inspe": " ", "Depth": 0.0, "Infiltrati": " ", "Inflow": " ", "Lift_Stati": 6.0, "Manhole_Id": "LS6-146", "Rating": " ", "SHAWN": 0.0, "LAYER": "SANMH", "FeatId1": 655.0 }, "geometry": { "type": "Point", "coordinates": [ -80.611569943526575, 28.311853485505019 ] } },
    { "type": "Feature", "properties": { "OBJECTID": 357, "Coating": "None", "Constructi": "Brick/Mortar", "Date_Inspe": "02/26/09", "Depth": 11.5, "Infiltrati": "Yes", "Inflow": "No", "Lift_Stati": 6.0, "Manhole_Id": "LS6-062", "Rating": "Poor", "SHAWN": 0.0, "LAYER": "SANMH", "FeatId1": 654.0 }, "geometry": { "type": "Point", "coordinates": [ -80.610145886400815, 28.302331039035078 ] } },
    { "type": "Feature", "properties": { "OBJECTID": 358, "Coating": "None", "Constructi": "Brick/Mortar", "Date_Inspe": "03/03/09", "Depth": 6.4, "Infiltrati": "Probable", "Inflow": "No", "Lift_Stati": 6.0, "Manhole_Id": "LS6-066", "Rating": "Fair", "SHAWN": 0.0, "LAYER": "SANMH", "FeatId1": 653.0 }, "geometry": { "type": "Point", "coordinates": [ -80.609681513075998, 28.30233527363378 ] } },
    { "type": "Feature", "properties": { "OBJECTID": 359, "Coating": "None", "Constructi": "Brick/Mortar", "Date_Inspe": "03/03/09", "Depth": 6.0, "Infiltrati": "Probable", "Inflow": "No", "Lift_Stati": 6.0, "Manhole_Id": "LS6-068", "Rating": "Fair", "SHAWN": 0.0, "LAYER": "SANMH", "FeatId1": 652.0 }, "geometry": { "type": "Point", "coordinates": [ -80.60950023156326, 28.302335150523987 ] } },
    { "type": "Feature", "properties": { "OBJECTID": 360, "Coating": "Spectrashield", "Constructi": "Brick/Mortar", "Date_Inspe": "03/03/09", "Depth": 5.75, "Infiltrati": "No", "Inflow": "No", "Lift_Stati": 6.0, "Manhole_Id": "LS6-072", "Rating": "Good", "SHAWN": 0.0, "LAYER": "SANMH", "FeatId1": 651.0 }, "geometry": { "type": "Point", "coordinates": [ -80.609131143319715, 28.302331566887148 ] } },
    { "type": "Feature", "properties": { "OBJECTID": 361, "Coating": "None", "Constructi": "Brick/Mortar", "Date_Inspe": "00/00/00", "Depth": 0.0, "Infiltrati": "No", "Inflow": "No", "Lift_Stati": 6.0, "Manhole_Id": "LS6-081", "Rating": "Good", "SHAWN": 0.0, "LAYER": "SANMH", "FeatId1": 650.0 }, "geometry": { "type": "Point", "coordinates": [ -80.608664746951689, 28.302331682841775 ] } },
    { "type": "Feature", "properties": { "OBJECTID": 362, "Coating": "None", "Constructi": "Brick/Mortar", "Date_Inspe": "03/04/09", "Depth": 9.16, "Infiltrati": "Yes", "Inflow": "No", "Lift_Stati": 6.0, "Manhole_Id": "LS6-080", "Rating": "Poor", "SHAWN": 0.0, "LAYER": "SANMH", "FeatId1": 649.0 }, "geometry": { "type": "Point", "coordinates": [ -80.608469216171102, 28.302334877046356 ] } },
    { "type": "Feature", "properties": { "OBJECTID": 363, "Coating": "None", "Constructi": "Brick/Mortar", "Date_Inspe": "03/05/09", "Depth": 6.5, "Infiltrati": "Probable", "Inflow": "No", "Lift_Stati": 6.0, "Manhole_Id": "LS6-083", "Rating": "Fair", "SHAWN": 0.0, "LAYER": "SANMH", "FeatId1": 648.0 }, "geometry": { "type": "Point", "coordinates": [ -80.608490997139057, 28.304130181377502 ] } },
    { "type": "Feature", "properties": { "OBJECTID": 364, "Coating": "Spectrashield", "Constructi": "Brick/Mortar", "Date_Inspe": "03/03/09", "Depth": 9.75, "Infiltrati": "No", "Inflow": "No", "Lift_Stati": 6.0, "Manhole_Id": "LS6-074", "Rating": "Good", "SHAWN": 0.0, "LAYER": "SANMH", "FeatId1": 647.0 }, "geometry": { "type": "Point", "coordinates": [ -80.609176194861348, 28.304120220563728 ] } },
    { "type": "Feature", "properties": { "OBJECTID": 365, "Coating": "Spectrashield", "Constructi": "Brick/Mortar", "Date_Inspe": "03/03/09", "Depth": 9.4, "Infiltrati": "No", "Inflow": "No", "Lift_Stati": 6.0, "Manhole_Id": "LS6-069", "Rating": "Good", "SHAWN": 0.0, "LAYER": "SANMH", "FeatId1": 646.0 }, "geometry": { "type": "Point", "coordinates": [ -80.609533403539402, 28.304115849143308 ] } },
    { "type": "Feature", "properties": { "OBJECTID": 366, "Coating": "Spectrashield", "Constructi": "Brick/Mortar", "Date_Inspe": "03/03/09", "Depth": 10.0, "Infiltrati": "No", "Inflow": "No", "Lift_Stati": 6.0, "Manhole_Id": "LS6-067", "Rating": "Good", "SHAWN": 0.0, "LAYER": "SANMH", "FeatId1": 645.0 }, "geometry": { "type": "Point", "coordinates": [ -80.609780114046984, 28.304115501419762 ] } },
    { "type": "Feature", "properties": { "OBJECTID": 367, "Coating": "None", "Constructi": "Brick/Mortar", "Date_Inspe": "03/09/09", "Depth": 4.66, "Infiltrati": "Probable", "Inflow": "No", "Lift_Stati": 6.0, "Manhole_Id": "LS6-085", "Rating": "Fair", "SHAWN": 0.0, "LAYER": "SANMH", "FeatId1": 644.0 }, "geometry": { "type": "Point", "coordinates": [ -80.608536092470274, 28.305903492760642 ] } },
    { "type": "Feature", "properties": { "OBJECTID": 368, "Coating": "None", "Constructi": "Brick/Mortar", "Date_Inspe": "04/04/09", "Depth": 5.75, "Infiltrati": "No", "Inflow": "No", "Lift_Stati": 6.0, "Manhole_Id": "LS6-078", "Rating": "Good", "SHAWN": 0.0, "LAYER": "SANMH", "FeatId1": 643.0 }, "geometry": { "type": "Point", "coordinates": [ -80.609274835590114, 28.307689404346856 ] } },
    { "type": "Feature", "properties": { "OBJECTID": 369, "Coating": "None", "Constructi": "Precast", "Date_Inspe": "03/18/09", "Depth": 3.92, "Infiltrati": "No", "Inflow": "No", "Lift_Stati": 6.0, "Manhole_Id": "LS6-128", "Rating": "Good", "SHAWN": 0.0, "LAYER": "SANMH", "FeatId1": 642.0 }, "geometry": { "type": "Point", "coordinates": [ -80.611715782598097, 28.311345502894028 ] } },
    { "type": "Feature", "properties": { "OBJECTID": 370, "Coating": "None", "Constructi": "Brick/Mortar", "Date_Inspe": "00/00/00", "Depth": 11.0, "Infiltrati": "No", "Inflow": "No", "Lift_Stati": 6.0, "Manhole_Id": "LS6-149", "Rating": "Good", "SHAWN": 0.0, "LAYER": "SANMH", "FeatId1": 641.0 }, "geometry": { "type": "Point", "coordinates": [ -80.612586254731724, 28.311494155380842 ] } },
    { "type": "Feature", "properties": { "OBJECTID": 371, "Coating": "None", "Constructi": "Brick/Mortar", "Date_Inspe": "03/19/09", "Depth": 7.0, "Infiltrati": "Yes", "Inflow": "No", "Lift_Stati": 6.0, "Manhole_Id": "LS6-082", "Rating": "Fair", "SHAWN": 0.0, "LAYER": "SANMH", "FeatId1": 640.0 }, "geometry": { "type": "Point", "coordinates": [ -80.608488874925285, 28.303161362825719 ] } },
    { "type": "Feature", "properties": { "OBJECTID": 372, "Coating": "None", "Constructi": "Brick/Mortar", "Date_Inspe": "03/19/09", "Depth": 7.08, "Infiltrati": "Yes", "Inflow": "No", "Lift_Stati": 6.0, "Manhole_Id": "LS6-140", "Rating": "Fair", "SHAWN": 0.0, "LAYER": "SANMH", "FeatId1": 639.0 }, "geometry": { "type": "Point", "coordinates": [ -80.608473367898654, 28.303235000488112 ] } },
    { "type": "Feature", "properties": { "OBJECTID": 373, "Coating": "None", "Constructi": "Brick/Mortar", "Date_Inspe": "03/04/09", "Depth": 5.33, "Infiltrati": "Probable", "Inflow": "No", "Lift_Stati": 6.0, "Manhole_Id": "LS6-073", "Rating": "Fair", "SHAWN": 0.0, "LAYER": "SANMH", "FeatId1": 638.0 }, "geometry": { "type": "Point", "coordinates": [ -80.609146220756642, 28.302949483698498 ] } },
    { "type": "Feature", "properties": { "OBJECTID": 374, "Coating": "None", "Constructi": "Brick/Mortar", "Date_Inspe": "00/00/00", "Depth": 0.0, "Infiltrati": "Yes", "Inflow": "No", "Lift_Stati": 6.0, "Manhole_Id": "LS6-063", "Rating": "Good", "SHAWN": 0.0, "LAYER": "SANMH", "FeatId1": 637.0 }, "geometry": { "type": "Point", "coordinates": [ -80.61016493329403, 28.303293138332307 ] } },
    { "type": "Feature", "properties": { "OBJECTID": 375, "Coating": "None", "Constructi": "Brick/Mortar", "Date_Inspe": "09/09/09", "Depth": 4.0, "Infiltrati": "Probable", "Inflow": "No", "Lift_Stati": 6.0, "Manhole_Id": "LS6-139", "Rating": "Fair", "SHAWN": 0.0, "LAYER": "SANMH", "FeatId1": 636.0 }, "geometry": { "type": "Point", "coordinates": [ -80.608417256524888, 28.300895628755271 ] } },
    { "type": "Feature", "properties": { "OBJECTID": 376, "Coating": "None", "Constructi": "Brick/Mortar", "Date_Inspe": "09/09/09", "Depth": 5.58, "Infiltrati": "Probable", "Inflow": "Yes", "Lift_Stati": 6.0, "Manhole_Id": "LS6-137", "Rating": "Fair", "SHAWN": 0.0, "LAYER": "SANMH", "FeatId1": 635.0 }, "geometry": { "type": "Point", "coordinates": [ -80.608457955668044, 28.301856256446204 ] } },
    { "type": "Feature", "properties": { "OBJECTID": 377, "Coating": "None", "Constructi": "Brick/Mortar", "Date_Inspe": "03/19/09", "Depth": 5.33, "Infiltrati": "Yes", "Inflow": "Yes", "Lift_Stati": 6.0, "Manhole_Id": "LS6-138", "Rating": "Poor", "SHAWN": 0.0, "LAYER": "SANMH", "FeatId1": 634.0 }, "geometry": { "type": "Point", "coordinates": [ -80.608439523205504, 28.301791620945753 ] } },
    { "type": "Feature", "properties": { "OBJECTID": 378, "Coating": "None", "Constructi": "Brick/Mortar", "Date_Inspe": "03/19/09", "Depth": 1.5, "Infiltrati": "No", "Inflow": "No", "Lift_Stati": 6.0, "Manhole_Id": "LS6-070", "Rating": "Good", "SHAWN": 0.0, "LAYER": "SANMH", "FeatId1": 633.0 }, "geometry": { "type": "Point", "coordinates": [ -80.609093489352276, 28.300749166181323 ] } },
    { "type": "Feature", "properties": { "OBJECTID": 379, "Coating": "None", "Constructi": "Brick/Mortar", "Date_Inspe": "05/04/09", "Depth": 5.6, "Infiltrati": "Probable", "Inflow": "No", "Lift_Stati": 1.0, "Manhole_Id": "LS1-072", "Rating": "Fair", "SHAWN": 0.0, "LAYER": "SANMH", "FeatId1": 632.0 }, "geometry": { "type": "Point", "coordinates": [ -80.609380220604734, 28.323646846247076 ] } },
    { "type": "Feature", "properties": { "OBJECTID": 380, "Coating": " ", "Constructi": " ", "Date_Inspe": "00/00/00", "Depth": 0.0, "Infiltrati": " ", "Inflow": " ", "Lift_Stati": 1.0, "Manhole_Id": "LS1-072A", "Rating": " ", "SHAWN": 0.0, "LAYER": "SANMH", "FeatId1": 631.0 }, "geometry": { "type": "Point", "coordinates": [ -80.609456207763387, 28.324713677343169 ] } },
    { "type": "Feature", "properties": { "OBJECTID": 381, "Coating": "Mortar", "Constructi": "Brick/Mortar", "Date_Inspe": "05/18/09", "Depth": 4.4, "Infiltrati": "No", "Inflow": "No", "Lift_Stati": 1.0, "Manhole_Id": "LS1-120", "Rating": "Good", "SHAWN": 0.0, "LAYER": "SANMH", "FeatId1": 630.0 }, "geometry": { "type": "Point", "coordinates": [ -80.61577492485263, 28.316431246360018 ] } },
    { "type": "Feature", "properties": { "OBJECTID": 382, "Coating": "Mortar", "Constructi": "Brick/Mortar", "Date_Inspe": "05/26/09", "Depth": 5.4, "Infiltrati": "No", "Inflow": "No", "Lift_Stati": 1.0, "Manhole_Id": "LS1-132", "Rating": "Good", "SHAWN": 0.0, "LAYER": "SANMH", "FeatId1": 629.0 }, "geometry": { "type": "Point", "coordinates": [ -80.618395218038373, 28.316436441493387 ] } },
    { "type": "Feature", "properties": { "OBJECTID": 383, "Coating": "Mortar", "Constructi": "Brick/Mortar", "Date_Inspe": "05/19/09", "Depth": 7.0, "Infiltrati": "No", "Inflow": "No", "Lift_Stati": 1.0, "Manhole_Id": "LS1-131", "Rating": "Good", "SHAWN": 0.0, "LAYER": "SANMH", "FeatId1": 628.0 }, "geometry": { "type": "Point", "coordinates": [ -80.618396362634812, 28.317426264860639 ] } },
    { "type": "Feature", "properties": { "OBJECTID": 384, "Coating": "None", "Constructi": "Brick/Mortar", "Date_Inspe": "05/18/09", "Depth": 4.0, "Infiltrati": "Probable", "Inflow": "No", "Lift_Stati": 1.0, "Manhole_Id": "LS1-125", "Rating": "Fair", "SHAWN": 0.0, "LAYER": "SANMH", "FeatId1": 627.0 }, "geometry": { "type": "Point", "coordinates": [ -80.617012938580217, 28.315345685508884 ] } },
    { "type": "Feature", "properties": { "OBJECTID": 385, "Coating": "Mortar", "Constructi": "Brick/Mortar", "Date_Inspe": "05/18/09", "Depth": 3.33, "Infiltrati": "Probable", "Inflow": "No", "Lift_Stati": 1.0, "Manhole_Id": "LS1-121", "Rating": "Fair", "SHAWN": 0.0, "LAYER": "SANMH", "FeatId1": 626.0 }, "geometry": { "type": "Point", "coordinates": [ -80.615773569375236, 28.315507951355286 ] } },
    { "type": "Feature", "properties": { "OBJECTID": 386, "Coating": "Spectrashield", "Constructi": "Brick/Mortar", "Date_Inspe": "06/04/09", "Depth": 5.92, "Infiltrati": "No", "Inflow": "No", "Lift_Stati": 1.0, "Manhole_Id": "LS1-151", "Rating": "Good", "SHAWN": 0.0, "LAYER": "SANMH", "FeatId1": 625.0 }, "geometry": { "type": "Point", "coordinates": [ -80.622044479132583, 28.319939185300584 ] } },
    { "type": "Feature", "properties": { "OBJECTID": 387, "Coating": "None", "Constructi": "Brick/Mortar", "Date_Inspe": "06/01/09", "Depth": 4.33, "Infiltrati": "No", "Inflow": "No", "Lift_Stati": 1.0, "Manhole_Id": "LS1-146", "Rating": "Good", "SHAWN": 0.0, "LAYER": "SANMH", "FeatId1": 624.0 }, "geometry": { "type": "Point", "coordinates": [ -80.620725957088979, 28.320463815689941 ] } },
    { "type": "Feature", "properties": { "OBJECTID": 388, "Coating": "None", "Constructi": "Brick/Mortar", "Date_Inspe": "05/29/09", "Depth": 6.25, "Infiltrati": "Probable", "Inflow": "No", "Lift_Stati": 1.0, "Manhole_Id": "LS1-145", "Rating": "Fair", "SHAWN": 0.0, "LAYER": "SANMH", "FeatId1": 623.0 }, "geometry": { "type": "Point", "coordinates": [ -80.620234405959536, 28.319747083483477 ] } },
    { "type": "Feature", "properties": { "OBJECTID": 389, "Coating": "None", "Constructi": "Brick/Mortar", "Date_Inspe": "05/26/09", "Depth": 6.66, "Infiltrati": "No", "Inflow": "No", "Lift_Stati": 1.0, "Manhole_Id": "LS1-134", "Rating": "Good", "SHAWN": 0.0, "LAYER": "SANMH", "FeatId1": 622.0 }, "geometry": { "type": "Point", "coordinates": [ -80.616192964521829, 28.319013934086218 ] } },
    { "type": "Feature", "properties": { "OBJECTID": 390, "Coating": "None", "Constructi": "Brick/Mortar", "Date_Inspe": "03/26/09", "Depth": 3.58, "Infiltrati": "No", "Inflow": "No", "Lift_Stati": 1.0, "Manhole_Id": "LS1-135", "Rating": "Good", "SHAWN": 0.0, "LAYER": "SANMH", "FeatId1": 621.0 }, "geometry": { "type": "Point", "coordinates": [ -80.616920368600418, 28.320497304777856 ] } },
    { "type": "Feature", "properties": { "OBJECTID": 391, "Coating": "None", "Constructi": "Brick/Mortar", "Date_Inspe": "06/02/09", "Depth": 3.5, "Infiltrati": "Probable", "Inflow": "No", "Lift_Stati": 1.0, "Manhole_Id": "LS1-147", "Rating": "Fair", "SHAWN": 0.0, "LAYER": "SANMH", "FeatId1": 620.0 }, "geometry": { "type": "Point", "coordinates": [ -80.620797323879984, 28.321101923571138 ] } },
    { "type": "Feature", "properties": { "OBJECTID": 392, "Coating": "None", "Constructi": "Brick/Mortar", "Date_Inspe": "05/27/09", "Depth": 5.75, "Infiltrati": "No", "Inflow": "No", "Lift_Stati": 1.0, "Manhole_Id": "LS1-137", "Rating": "Good", "SHAWN": 0.0, "LAYER": "SANMH", "FeatId1": 619.0 }, "geometry": { "type": "Point", "coordinates": [ -80.617721130640902, 28.319336905128797 ] } },
    { "type": "Feature", "properties": { "OBJECTID": 393, "Coating": "None", "Constructi": "Brick/Mortar", "Date_Inspe": "05/27/09", "Depth": 4.25, "Infiltrati": "No", "Inflow": "No", "Lift_Stati": 1.0, "Manhole_Id": "LS1-138", "Rating": "Good", "SHAWN": 0.0, "LAYER": "SANMH", "FeatId1": 618.0 }, "geometry": { "type": "Point", "coordinates": [ -80.618345013112531, 28.319793680395772 ] } },
    { "type": "Feature", "properties": { "OBJECTID": 394, "Coating": "None", "Constructi": "Brick/Mortar", "Date_Inspe": "05/27/09", "Depth": 5.0, "Infiltrati": "No", "Inflow": "No", "Lift_Stati": 1.0, "Manhole_Id": "LS1-141", "Rating": "Good", "SHAWN": 0.0, "LAYER": "SANMH", "FeatId1": 617.0 }, "geometry": { "type": "Point", "coordinates": [ -80.615962147900163, 28.319789216085301 ] } },
    { "type": "Feature", "properties": { "OBJECTID": 395, "Coating": "Mortar", "Constructi": "Brick/Mortar", "Date_Inspe": "05/14/09", "Depth": 4.6, "Infiltrati": "No", "Inflow": "Yes", "Lift_Stati": 1.0, "Manhole_Id": "LS1-112", "Rating": "Good", "SHAWN": 0.0, "LAYER": "SANMH", "FeatId1": 616.0 }, "geometry": { "type": "Point", "coordinates": [ -80.612031218888617, 28.318176800999819 ] } },
    { "type": "Feature", "properties": { "OBJECTID": 396, "Coating": "Mortar", "Constructi": "Brick/Mortar", "Date_Inspe": "05/14/09", "Depth": 4.0, "Infiltrati": "No", "Inflow": "No", "Lift_Stati": 1.0, "Manhole_Id": "LS1-113", "Rating": "Good", "SHAWN": 0.0, "LAYER": "SANMH", "FeatId1": 615.0 }, "geometry": { "type": "Point", "coordinates": [ -80.611911635178018, 28.317166348925074 ] } },
    { "type": "Feature", "properties": { "OBJECTID": 397, "Coating": "Mortar", "Constructi": "Brick/Mortar", "Date_Inspe": "05/15/09", "Depth": 2.92, "Infiltrati": "No", "Inflow": "Yes", "Lift_Stati": 1.0, "Manhole_Id": "LS1-114", "Rating": "Good", "SHAWN": 0.0, "LAYER": "SANMH", "FeatId1": 614.0 }, "geometry": { "type": "Point", "coordinates": [ -80.611905135879809, 28.316126578090653 ] } },
    { "type": "Feature", "properties": { "OBJECTID": 398, "Coating": "Mortar", "Constructi": "Brick/Mortar", "Date_Inspe": "05/15/09", "Depth": 2.0, "Infiltrati": "No", "Inflow": "Yes", "Lift_Stati": 1.0, "Manhole_Id": "LS1-115", "Rating": "Good", "SHAWN": 0.0, "LAYER": "SANMH", "FeatId1": 613.0 }, "geometry": { "type": "Point", "coordinates": [ -80.611897825922824, 28.315320199760993 ] } },
    { "type": "Feature", "properties": { "OBJECTID": 399, "Coating": "Spectrashield", "Constructi": "Brick/Mortar", "Date_Inspe": "05/12/09", "Depth": 7.0, "Infiltrati": "No", "Inflow": "No", "Lift_Stati": 1.0, "Manhole_Id": "LS1-101", "Rating": "Good", "SHAWN": 0.0, "LAYER": "SANMH", "FeatId1": 612.0 }, "geometry": { "type": "Point", "coordinates": [ -80.611940459449244, 28.318325650329221 ] } },
    { "type": "Feature", "properties": { "OBJECTID": 400, "Coating": "None", "Constructi": "Brick/Mortar", "Date_Inspe": "00/00/00", "Depth": 0.0, "Infiltrati": "No", "Inflow": "No", "Lift_Stati": 1.0, "Manhole_Id": "LS1-136", "Rating": "Good", "SHAWN": 0.0, "LAYER": "SANMH", "FeatId1": 611.0 }, "geometry": { "type": "Point", "coordinates": [ -80.617606360818215, 28.321107032250477 ] } },
    { "type": "Feature", "properties": { "OBJECTID": 401, "Coating": "None", "Constructi": "Brick/Mortar", "Date_Inspe": "05/27/09", "Depth": 3.08, "Infiltrati": "No", "Inflow": "No", "Lift_Stati": 1.0, "Manhole_Id": "LS1-140", "Rating": "Good", "SHAWN": 0.0, "LAYER": "SANMH", "FeatId1": 610.0 }, "geometry": { "type": "Point", "coordinates": [ -80.619223037569625, 28.321106205829292 ] } },
    { "type": "Feature", "properties": { "OBJECTID": 402, "Coating": "None", "Constructi": "Brick/Mortar", "Date_Inspe": "05/27/09", "Depth": 4.0, "Infiltrati": "No", "Inflow": "No", "Lift_Stati": 1.0, "Manhole_Id": "LS1-139", "Rating": "Good", "SHAWN": 0.0, "LAYER": "SANMH", "FeatId1": 609.0 }, "geometry": { "type": "Point", "coordinates": [ -80.618977103618505, 28.320255897161886 ] } },
    { "type": "Feature", "properties": { "OBJECTID": 403, "Coating": "None", "Constructi": "Brick/Mortar", "Date_Inspe": "06/04/09", "Depth": 3.5, "Infiltrati": "Yes", "Inflow": "Yes", "Lift_Stati": 1.0, "Manhole_Id": "LS1-152", "Rating": "Fair", "SHAWN": 0.0, "LAYER": "SANMH", "FeatId1": 608.0 }, "geometry": { "type": "Point", "coordinates": [ -80.622414266527372, 28.321118840354295 ] } },
    { "type": "Feature", "properties": { "OBJECTID": 404, "Coating": "Fiberglass", "Constructi": "Brick/Mortar", "Date_Inspe": "06/02/09", "Depth": 10.0, "Infiltrati": "No", "Inflow": "No", "Lift_Stati": 1.0, "Manhole_Id": "LS1-148", "Rating": "Good", "SHAWN": 0.0, "LAYER": "SANMH", "FeatId1": 607.0 }, "geometry": { "type": "Point", "coordinates": [ -80.620748168103162, 28.318422008353743 ] } },
    { "type": "Feature", "properties": { "OBJECTID": 405, "Coating": "Spectrashield", "Constructi": "Brick/Mortar", "Date_Inspe": "09/11/13", "Depth": 10.0, "Infiltrati": "No", "Inflow": "No", "Lift_Stati": 1.0, "Manhole_Id": "LS1-149", "Rating": "Good", "SHAWN": 0.0, "LAYER": "SANMH", "FeatId1": 606.0 }, "geometry": { "type": "Point", "coordinates": [ -80.621366053990982, 28.318421753977947 ] } },
    { "type": "Feature", "properties": { "OBJECTID": 406, "Coating": "Spectrashield", "Constructi": "Brick/Mortar", "Date_Inspe": "09/11/13", "Depth": 11.0, "Infiltrati": "No", "Inflow": "No", "Lift_Stati": 1.0, "Manhole_Id": "LS1-155", "Rating": "Good", "SHAWN": 0.0, "LAYER": "SANMH", "FeatId1": 605.0 }, "geometry": { "type": "Point", "coordinates": [ -80.623850242658818, 28.318453354064236 ] } },
    { "type": "Feature", "properties": { "OBJECTID": 407, "Coating": "Spectrashield", "Constructi": "Brick/Mortar", "Date_Inspe": "09/11/13", "Depth": 11.0, "Infiltrati": "No", "Inflow": "No", "Lift_Stati": 1.0, "Manhole_Id": "LS1-156", "Rating": "Good", "SHAWN": 0.0, "LAYER": "SANMH", "FeatId1": 604.0 }, "geometry": { "type": "Point", "coordinates": [ -80.625338908438394, 28.318450257628399 ] } },
    { "type": "Feature", "properties": { "OBJECTID": 408, "Coating": "None", "Constructi": "Brick/Mortar", "Date_Inspe": "00/00/00", "Depth": 11.0, "Infiltrati": "No", "Inflow": "No", "Lift_Stati": 1.0, "Manhole_Id": "LS1-157", "Rating": "Good", "SHAWN": 0.0, "LAYER": "SANMH", "FeatId1": 603.0 }, "geometry": { "type": "Point", "coordinates": [ -80.626570420273723, 28.318448362766933 ] } },
    { "type": "Feature", "properties": { "OBJECTID": 409, "Coating": "None", "Constructi": "Brick/Mortar", "Date_Inspe": "00/00/00", "Depth": 11.0, "Infiltrati": "No", "Inflow": "No", "Lift_Stati": 1.0, "Manhole_Id": "LS1-158", "Rating": "Good", "SHAWN": 0.0, "LAYER": "SANMH", "FeatId1": 602.0 }, "geometry": { "type": "Point", "coordinates": [ -80.628114139578614, 28.318465782024258 ] } },
    { "type": "Feature", "properties": { "OBJECTID": 410, "Coating": "None", "Constructi": "Brick/Mortar", "Date_Inspe": "03/04/09", "Depth": 6.83, "Infiltrati": "Yes", "Inflow": "Yes", "Lift_Stati": 6.0, "Manhole_Id": "LS6-075", "Rating": "Poor", "SHAWN": 0.0, "LAYER": "SANMH", "FeatId1": 601.0 }, "geometry": { "type": "Point", "coordinates": [ -80.609200362959953, 28.305068573203759 ] } },
    { "type": "Feature", "properties": { "OBJECTID": 411, "Coating": "None", "Constructi": "Brick/Mortar", "Date_Inspe": "03/04/09", "Depth": 7.75, "Infiltrati": "Probable", "Inflow": "No", "Lift_Stati": 6.0, "Manhole_Id": "LS6-076", "Rating": "Fair", "SHAWN": 0.0, "LAYER": "SANMH", "FeatId1": 600.0 }, "geometry": { "type": "Point", "coordinates": [ -80.609222083495851, 28.305838032014226 ] } },
    { "type": "Feature", "properties": { "OBJECTID": 412, "Coating": "None", "Constructi": "Brick/Mortar", "Date_Inspe": "03/04/09", "Depth": 6.4, "Infiltrati": "Probable", "Inflow": "Yes", "Lift_Stati": 6.0, "Manhole_Id": "LS6-077", "Rating": "Poor", "SHAWN": 0.0, "LAYER": "SANMH", "FeatId1": 599.0 }, "geometry": { "type": "Point", "coordinates": [ -80.609240822260858, 28.306722639014815 ] } },
    { "type": "Feature", "properties": { "OBJECTID": 413, "Coating": "None", "Constructi": "Brick/Mortar", "Date_Inspe": "00/00/00", "Depth": 0.0, "Infiltrati": "No", "Inflow": "No", "Lift_Stati": 6.0, "Manhole_Id": "LS6-142", "Rating": "Good", "SHAWN": 0.0, "LAYER": "SANMH", "FeatId1": 598.0 }, "geometry": { "type": "Point", "coordinates": [ -80.608604901323403, 28.307475876070264 ] } },
    { "type": "Feature", "properties": { "OBJECTID": 414, "Coating": "None", "Constructi": "Brick/Mortar", "Date_Inspe": "06/08/09", "Depth": 6.66, "Infiltrati": "Yes", "Inflow": "No", "Lift_Stati": 4.0, "Manhole_Id": "LS4-005", "Rating": "Fair", "SHAWN": 0.0, "LAYER": "SANMH", "FeatId1": 597.0 }, "geometry": { "type": "Point", "coordinates": [ -80.612703281606599, 28.326360786736 ] } },
    { "type": "Feature", "properties": { "OBJECTID": 415, "Coating": "None", "Constructi": "Brick/Mortar", "Date_Inspe": "06/08/09", "Depth": 6.58, "Infiltrati": "No", "Inflow": "No", "Lift_Stati": 4.0, "Manhole_Id": "LS4-004", "Rating": "Good", "SHAWN": 0.0, "LAYER": "SANMH", "FeatId1": 596.0 }, "geometry": { "type": "Point", "coordinates": [ -80.613598585469191, 28.326372750906931 ] } },
    { "type": "Feature", "properties": { "OBJECTID": 416, "Coating": "None", "Constructi": "Brick/Mortar", "Date_Inspe": "06/08/09", "Depth": 2.42, "Infiltrati": "No", "Inflow": "No", "Lift_Stati": 4.0, "Manhole_Id": "LS4-001", "Rating": "Fair", "SHAWN": 0.0, "LAYER": "SANMH", "FeatId1": 595.0 }, "geometry": { "type": "Point", "coordinates": [ -80.616184215089092, 28.325926972635671 ] } },
    { "type": "Feature", "properties": { "OBJECTID": 417, "Coating": "None", "Constructi": "Brick/Mortar", "Date_Inspe": "06/08/09", "Depth": 4.75, "Infiltrati": "Probable", "Inflow": "No", "Lift_Stati": 4.0, "Manhole_Id": "LS4-007", "Rating": "Fair", "SHAWN": 0.0, "LAYER": "SANMH", "FeatId1": 594.0 }, "geometry": { "type": "Point", "coordinates": [ -80.615551459076059, 28.327195878272573 ] } },
    { "type": "Feature", "properties": { "OBJECTID": 418, "Coating": "None", "Constructi": "Brick/Mortar", "Date_Inspe": "06/09/09", "Depth": 6.0, "Infiltrati": "No", "Inflow": "No", "Lift_Stati": 4.0, "Manhole_Id": "LS4-008", "Rating": "Fair", "SHAWN": 0.0, "LAYER": "SANMH", "FeatId1": 593.0 }, "geometry": { "type": "Point", "coordinates": [ -80.614553481228839, 28.327197656972206 ] } },
    { "type": "Feature", "properties": { "OBJECTID": 419, "Coating": "Spectrashield", "Constructi": "Brick/Mortar", "Date_Inspe": "09/11/13", "Depth": 7.33, "Infiltrati": "No", "Inflow": "No", "Lift_Stati": 4.0, "Manhole_Id": "LS4-009", "Rating": "Good", "SHAWN": 0.0, "LAYER": "SANMH", "FeatId1": 592.0 }, "geometry": { "type": "Point", "coordinates": [ -80.613510127632566, 28.327119333882614 ] } },
    { "type": "Feature", "properties": { "OBJECTID": 420, "Coating": "Spectrashield", "Constructi": "Brick/Mortar", "Date_Inspe": "09/11/13", "Depth": 7.92, "Infiltrati": "No", "Inflow": "No", "Lift_Stati": 4.0, "Manhole_Id": "LS4-010", "Rating": "Good", "SHAWN": 0.0, "LAYER": "SANMH", "FeatId1": 591.0 }, "geometry": { "type": "Point", "coordinates": [ -80.612454090093067, 28.327117002068906 ] } },
    { "type": "Feature", "properties": { "OBJECTID": 421, "Coating": "None", "Constructi": "Brick/Mortar", "Date_Inspe": "06/08/09", "Depth": 4.92, "Infiltrati": "No", "Inflow": "No", "Lift_Stati": 4.0, "Manhole_Id": "LS4-003", "Rating": "Good", "SHAWN": 0.0, "LAYER": "SANMH", "FeatId1": 590.0 }, "geometry": { "type": "Point", "coordinates": [ -80.614505426371821, 28.326446078416854 ] } },
    { "type": "Feature", "properties": { "OBJECTID": 422, "Coating": "Spectrashield", "Constructi": "Brick/Mortar", "Date_Inspe": "09/11/13", "Depth": 7.83, "Infiltrati": "No", "Inflow": "No", "Lift_Stati": 4.0, "Manhole_Id": "LS4-016", "Rating": "Good", "SHAWN": 0.0, "LAYER": "SANMH", "FeatId1": 589.0 }, "geometry": { "type": "Point", "coordinates": [ -80.612132403766424, 28.327857333072878 ] } },
    { "type": "Feature", "properties": { "OBJECTID": 423, "Coating": "None", "Constructi": "Brick/Mortar", "Date_Inspe": "06/10/09", "Depth": 7.3, "Infiltrati": "Probable", "Inflow": "No", "Lift_Stati": 4.0, "Manhole_Id": "LS4-015", "Rating": "Fair", "SHAWN": 0.0, "LAYER": "SANMH", "FeatId1": 588.0 }, "geometry": { "type": "Point", "coordinates": [ -80.613048212253304, 28.327858251983081 ] } },
    { "type": "Feature", "properties": { "OBJECTID": 424, "Coating": "None", "Constructi": "Brick/Mortar", "Date_Inspe": "06/09/09", "Depth": 4.4, "Infiltrati": "No", "Inflow": "No", "Lift_Stati": 4.0, "Manhole_Id": "LS4-013", "Rating": "Fair", "SHAWN": 0.0, "LAYER": "SANMH", "FeatId1": 587.0 }, "geometry": { "type": "Point", "coordinates": [ -80.614877435592092, 28.32793516231742 ] } },
    { "type": "Feature", "properties": { "OBJECTID": 425, "Coating": "None", "Constructi": "Brick/Mortar", "Date_Inspe": "06/09/09", "Depth": 3.4, "Infiltrati": "Probable", "Inflow": "Yes", "Lift_Stati": 4.0, "Manhole_Id": "LS4-012", "Rating": "Fair", "SHAWN": 0.0, "LAYER": "SANMH", "FeatId1": 586.0 }, "geometry": { "type": "Point", "coordinates": [ -80.615447696505484, 28.327933716955133 ] } },
    { "type": "Feature", "properties": { "OBJECTID": 426, "Coating": "None", "Constructi": "Brick/Mortar", "Date_Inspe": "06/10/09", "Depth": 3.08, "Infiltrati": "No", "Inflow": "No", "Lift_Stati": 4.0, "Manhole_Id": "LS4-017", "Rating": "Good", "SHAWN": 0.0, "LAYER": "SANMH", "FeatId1": 585.0 }, "geometry": { "type": "Point", "coordinates": [ -80.615429300784314, 28.328397066784287 ] } },
    { "type": "Feature", "properties": { "OBJECTID": 427, "Coating": "None", "Constructi": "Brick/Mortar", "Date_Inspe": "06/10/09", "Depth": 3.83, "Infiltrati": "No", "Inflow": "No", "Lift_Stati": 4.0, "Manhole_Id": "LS4-018", "Rating": "Good", "SHAWN": 0.0, "LAYER": "SANMH", "FeatId1": 584.0 }, "geometry": { "type": "Point", "coordinates": [ -80.615341812016808, 28.328705892679746 ] } },
    { "type": "Feature", "properties": { "OBJECTID": 428, "Coating": "None", "Constructi": "Brick/Mortar", "Date_Inspe": "06/10/09", "Depth": 4.0, "Infiltrati": "No", "Inflow": "No", "Lift_Stati": 4.0, "Manhole_Id": "LS4-019", "Rating": "Fair", "SHAWN": 0.0, "LAYER": "SANMH", "FeatId1": 583.0 }, "geometry": { "type": "Point", "coordinates": [ -80.614553359305944, 28.328656967600367 ] } },
    { "type": "Feature", "properties": { "OBJECTID": 429, "Coating": "None", "Constructi": "Brick/Mortar", "Date_Inspe": "06/10/09", "Depth": 5.83, "Infiltrati": "No", "Inflow": "No", "Lift_Stati": 4.0, "Manhole_Id": "LS4-020", "Rating": "Good", "SHAWN": 0.0, "LAYER": "SANMH", "FeatId1": 582.0 }, "geometry": { "type": "Point", "coordinates": [ -80.613304434420854, 28.328626772698712 ] } },
    { "type": "Feature", "properties": { "OBJECTID": 430, "Coating": "None", "Constructi": "Brick/Mortar", "Date_Inspe": "06/11/09", "Depth": 8.33, "Infiltrati": "No", "Inflow": "No", "Lift_Stati": 4.0, "Manhole_Id": "LS4-021", "Rating": "Good", "SHAWN": 0.0, "LAYER": "SANMH", "FeatId1": 581.0 }, "geometry": { "type": "Point", "coordinates": [ -80.612117349226409, 28.328609338307928 ] } },
    { "type": "Feature", "properties": { "OBJECTID": 431, "Coating": "Spectrashield", "Constructi": "Brick/Mortar", "Date_Inspe": "06/11/09", "Depth": 7.83, "Infiltrati": "Yes", "Inflow": "No", "Lift_Stati": 4.0, "Manhole_Id": "LS4-022", "Rating": "Good", "SHAWN": 0.0, "LAYER": "SANMH", "FeatId1": 580.0 }, "geometry": { "type": "Point", "coordinates": [ -80.612120970038376, 28.328440388501459 ] } },
    { "type": "Feature", "properties": { "OBJECTID": 432, "Coating": "None", "Constructi": "Brick/Mortar", "Date_Inspe": "07/09/09", "Depth": 3.4, "Infiltrati": "No", "Inflow": "No", "Lift_Stati": 4.0, "Manhole_Id": "LS4-083", "Rating": "Good", "SHAWN": 0.0, "LAYER": "SANMH", "FeatId1": 579.0 }, "geometry": { "type": "Point", "coordinates": [ -80.611613104897728, 28.327881248638914 ] } },
    { "type": "Feature", "properties": { "OBJECTID": 433, "Coating": "None", "Constructi": "Brick/Mortar", "Date_Inspe": "06/12/09", "Depth": 10.5, "Infiltrati": "No", "Inflow": "No", "Lift_Stati": 4.0, "Manhole_Id": "LS4-026", "Rating": "Poor", "SHAWN": 0.0, "LAYER": "SANMH", "FeatId1": 578.0 }, "geometry": { "type": "Point", "coordinates": [ -80.612330970698082, 28.329970542317454 ] } },
    { "type": "Feature", "properties": { "OBJECTID": 434, "Coating": "Mortar", "Constructi": "Brick/Mortar", "Date_Inspe": "06/12/09", "Depth": 10.5, "Infiltrati": "No", "Inflow": "No", "Lift_Stati": 4.0, "Manhole_Id": "LS4-027", "Rating": "Good", "SHAWN": 0.0, "LAYER": "SANMH", "FeatId1": 577.0 }, "geometry": { "type": "Point", "coordinates": [ -80.612351238938118, 28.32997581377516 ] } },
    { "type": "Feature", "properties": { "OBJECTID": 435, "Coating": "None", "Constructi": "Brick/Mortar", "Date_Inspe": "06/12/09", "Depth": 11.0, "Infiltrati": "Probable", "Inflow": "No", "Lift_Stati": 4.0, "Manhole_Id": "LS4-025", "Rating": "Fair", "SHAWN": 0.0, "LAYER": "SANMH", "FeatId1": 576.0 }, "geometry": { "type": "Point", "coordinates": [ -80.61213060155869, 28.330010187784318 ] } },
    { "type": "Feature", "properties": { "OBJECTID": 436, "Coating": "None", "Constructi": "Brick/Mortar", "Date_Inspe": "06/12/09", "Depth": 9.5, "Infiltrati": "No", "Inflow": "No", "Lift_Stati": 4.0, "Manhole_Id": "LS4-028", "Rating": "Good", "SHAWN": 0.0, "LAYER": "SANMH", "FeatId1": 575.0 }, "geometry": { "type": "Point", "coordinates": [ -80.612632998076535, 28.330052602253915 ] } },
    { "type": "Feature", "properties": { "OBJECTID": 437, "Coating": "Mortar", "Constructi": "Brick/Mortar", "Date_Inspe": "06/15/09", "Depth": 8.66, "Infiltrati": "No", "Inflow": "No", "Lift_Stati": 4.0, "Manhole_Id": "LS4-033", "Rating": "Good", "SHAWN": 0.0, "LAYER": "SANMH", "FeatId1": 574.0 }, "geometry": { "type": "Point", "coordinates": [ -80.612565163714549, 28.331123589381829 ] } },
    { "type": "Feature", "properties": { "OBJECTID": 438, "Coating": "None", "Constructi": "Brick/Mortar", "Date_Inspe": "06/19/09", "Depth": 4.0, "Infiltrati": "No", "Inflow": "No", "Lift_Stati": 4.0, "Manhole_Id": "LS4-044", "Rating": "Good", "SHAWN": 0.0, "LAYER": "SANMH", "FeatId1": 573.0 }, "geometry": { "type": "Point", "coordinates": [ -80.613393406351449, 28.331291386648566 ] } },
    { "type": "Feature", "properties": { "OBJECTID": 439, "Coating": "None", "Constructi": "Brick/Mortar", "Date_Inspe": "06/18/09", "Depth": 4.4, "Infiltrati": "No", "Inflow": "No", "Lift_Stati": 4.0, "Manhole_Id": "LS4-045", "Rating": "Good", "SHAWN": 0.0, "LAYER": "SANMH", "FeatId1": 572.0 }, "geometry": { "type": "Point", "coordinates": [ -80.613415068435856, 28.330933845502152 ] } },
    { "type": "Feature", "properties": { "OBJECTID": 440, "Coating": "None", "Constructi": "Brick/Mortar", "Date_Inspe": "06/12/09", "Depth": 8.75, "Infiltrati": "No", "Inflow": "No", "Lift_Stati": 4.0, "Manhole_Id": "LS4-029", "Rating": "Good", "SHAWN": 0.0, "LAYER": "SANMH", "FeatId1": 571.0 }, "geometry": { "type": "Point", "coordinates": [ -80.613484468782275, 28.329836351660383 ] } },
    { "type": "Feature", "properties": { "OBJECTID": 441, "Coating": "None", "Constructi": "Brick/Mortar", "Date_Inspe": "06/12/09", "Depth": 8.4, "Infiltrati": "No", "Inflow": "No", "Lift_Stati": 4.0, "Manhole_Id": "LS4-030", "Rating": "Good", "SHAWN": 0.0, "LAYER": "SANMH", "FeatId1": 570.0 }, "geometry": { "type": "Point", "coordinates": [ -80.614330494251803, 28.329834812990086 ] } },
    { "type": "Feature", "properties": { "OBJECTID": 442, "Coating": "None", "Constructi": "Brick/Mortar", "Date_Inspe": "06/23/09", "Depth": 3.75, "Infiltrati": "No", "Inflow": "No", "Lift_Stati": 4.0, "Manhole_Id": "LS4-049", "Rating": "Fair", "SHAWN": 0.0, "LAYER": "SANMH", "FeatId1": 569.0 }, "geometry": { "type": "Point", "coordinates": [ -80.614252339759901, 28.330943438535446 ] } },
    { "type": "Feature", "properties": { "OBJECTID": 443, "Coating": "None", "Constructi": "Brick/Mortar", "Date_Inspe": "06/12/09", "Depth": 7.75, "Infiltrati": "No", "Inflow": "No", "Lift_Stati": 4.0, "Manhole_Id": "LS4-031", "Rating": "Good", "SHAWN": 0.0, "LAYER": "SANMH", "FeatId1": 568.0 }, "geometry": { "type": "Point", "coordinates": [ -80.615489782150675, 28.330070374719842 ] } },
    { "type": "Feature", "properties": { "OBJECTID": 444, "Coating": "None", "Constructi": "Brick/Mortar", "Date_Inspe": "06/12/09", "Depth": 7.66, "Infiltrati": "No", "Inflow": "No", "Lift_Stati": 4.0, "Manhole_Id": "LS4-032", "Rating": "Good", "SHAWN": 0.0, "LAYER": "SANMH", "FeatId1": 567.0 }, "geometry": { "type": "Point", "coordinates": [ -80.616080955742063, 28.329858383178312 ] } },
    { "type": "Feature", "properties": { "OBJECTID": 445, "Coating": "Mortar", "Constructi": "Brick/Mortar", "Date_Inspe": "06/25/09", "Depth": 6.66, "Infiltrati": "No", "Inflow": "No", "Lift_Stati": 4.0, "Manhole_Id": "LS4-051", "Rating": "Good", "SHAWN": 0.0, "LAYER": "SANMH", "FeatId1": 566.0 }, "geometry": { "type": "Point", "coordinates": [ -80.615505489678455, 28.330902804655661 ] } },
    { "type": "Feature", "properties": { "OBJECTID": 446, "Coating": "None", "Constructi": "Brick/Mortar", "Date_Inspe": "06/25/09", "Depth": 5.75, "Infiltrati": "No", "Inflow": "No", "Lift_Stati": 4.0, "Manhole_Id": "LS4-052", "Rating": "Good", "SHAWN": 0.0, "LAYER": "SANMH", "FeatId1": 565.0 }, "geometry": { "type": "Point", "coordinates": [ -80.615457004424528, 28.3316417560861 ] } },
    { "type": "Feature", "properties": { "OBJECTID": 447, "Coating": "Mortar", "Constructi": "Brick/Mortar", "Date_Inspe": "06/30/09", "Depth": 4.75, "Infiltrati": "No", "Inflow": "No", "Lift_Stati": 4.0, "Manhole_Id": "LS4-064", "Rating": "Good", "SHAWN": 0.0, "LAYER": "SANMH", "FeatId1": 564.0 }, "geometry": { "type": "Point", "coordinates": [ -80.616012827242628, 28.33169013711019 ] } },
    { "type": "Feature", "properties": { "OBJECTID": 448, "Coating": "Mortar", "Constructi": "Brick/Mortar", "Date_Inspe": "07/01/09", "Depth": 6.16, "Infiltrati": "No", "Inflow": "No", "Lift_Stati": 4.0, "Manhole_Id": "LS4-071", "Rating": "Good", "SHAWN": 0.0, "LAYER": "SANMH", "FeatId1": 563.0 }, "geometry": { "type": "Point", "coordinates": [ -80.616120903578746, 28.330944409412293 ] } },
    { "type": "Feature", "properties": { "OBJECTID": 449, "Coating": "None", "Constructi": "Brick/Mortar", "Date_Inspe": "07/01/09", "Depth": 6.08, "Infiltrati": "Probable", "Inflow": "No", "Lift_Stati": 4.0, "Manhole_Id": "LS4-070", "Rating": "Fair", "SHAWN": 0.0, "LAYER": "SANMH", "FeatId1": 562.0 }, "geometry": { "type": "Point", "coordinates": [ -80.616615082149949, 28.33116246614647 ] } },
    { "type": "Feature", "properties": { "OBJECTID": 450, "Coating": "Mortar", "Constructi": "Brick/Mortar", "Date_Inspe": "06/30/09", "Depth": 5.4, "Infiltrati": "Yes", "Inflow": "No", "Lift_Stati": 4.0, "Manhole_Id": "LS4-063", "Rating": "Poor", "SHAWN": 0.0, "LAYER": "SANMH", "FeatId1": 561.0 }, "geometry": { "type": "Point", "coordinates": [ -80.617076610297033, 28.330522531148503 ] } },
    { "type": "Feature", "properties": { "OBJECTID": 451, "Coating": "Mortar", "Constructi": "Brick/Mortar", "Date_Inspe": "06/24/09", "Depth": 7.58, "Infiltrati": "No", "Inflow": "No", "Lift_Stati": 4.0, "Manhole_Id": "LS4-050", "Rating": "Good", "SHAWN": 0.0, "LAYER": "SANMH", "FeatId1": 560.0 }, "geometry": { "type": "Point", "coordinates": [ -80.612524299121603, 28.331734490157469 ] } },
    { "type": "Feature", "properties": { "OBJECTID": 452, "Coating": "None", "Constructi": "Brick/Mortar", "Date_Inspe": "06/19/09", "Depth": 4.16, "Infiltrati": "No", "Inflow": "No", "Lift_Stati": 4.0, "Manhole_Id": "LS4-043", "Rating": "Good", "SHAWN": 0.0, "LAYER": "SANMH", "FeatId1": 559.0 }, "geometry": { "type": "Point", "coordinates": [ -80.61336538203733, 28.331729316026959 ] } },
    { "type": "Feature", "properties": { "OBJECTID": 453, "Coating": "Mortar", "Constructi": "Brick/Mortar", "Date_Inspe": "06/23/09", "Depth": 4.0, "Infiltrati": "No", "Inflow": "No", "Lift_Stati": 4.0, "Manhole_Id": "LS4-047", "Rating": "Good", "SHAWN": 0.0, "LAYER": "SANMH", "FeatId1": 558.0 }, "geometry": { "type": "Point", "coordinates": [ -80.614200859841333, 28.331752952380953 ] } },
    { "type": "Feature", "properties": { "OBJECTID": 454, "Coating": "Mortar", "Constructi": "Brick/Mortar", "Date_Inspe": "06/19/09", "Depth": 5.58, "Infiltrati": "No", "Inflow": "No", "Lift_Stati": 4.0, "Manhole_Id": "LS4-042", "Rating": "Good", "SHAWN": 0.0, "LAYER": "SANMH", "FeatId1": 557.0 }, "geometry": { "type": "Point", "coordinates": [ -80.6133273956072, 28.332318541306492 ] } },
    { "type": "Feature", "properties": { "OBJECTID": 455, "Coating": "None", "Constructi": "Brick/Mortar", "Date_Inspe": "06/15/09", "Depth": 6.66, "Infiltrati": "No", "Inflow": "No", "Lift_Stati": 4.0, "Manhole_Id": "LS4-034", "Rating": "Good", "SHAWN": 0.0, "LAYER": "SANMH", "FeatId1": 556.0 }, "geometry": { "type": "Point", "coordinates": [ -80.612485713170855, 28.332317616176777 ] } },
    { "type": "Feature", "properties": { "OBJECTID": 456, "Coating": "Mortar", "Constructi": "Brick/Mortar", "Date_Inspe": "06/26/09", "Depth": 2.9, "Infiltrati": "No", "Inflow": "No", "Lift_Stati": 4.0, "Manhole_Id": "LS4-055", "Rating": "Good", "SHAWN": 0.0, "LAYER": "SANMH", "FeatId1": 555.0 }, "geometry": { "type": "Point", "coordinates": [ -80.616970941588789, 28.333179878172114 ] } },
    { "type": "Feature", "properties": { "OBJECTID": 457, "Coating": "Mortar", "Constructi": "Brick/Mortar", "Date_Inspe": "06/26/09", "Depth": 4.0, "Infiltrati": "No", "Inflow": "No", "Lift_Stati": 4.0, "Manhole_Id": "LS4-054", "Rating": "Good", "SHAWN": 0.0, "LAYER": "SANMH", "FeatId1": 554.0 }, "geometry": { "type": "Point", "coordinates": [ -80.616163540432595, 28.332719250235542 ] } },
    { "type": "Feature", "properties": { "OBJECTID": 458, "Coating": "Mortar", "Constructi": "Brick/Mortar", "Date_Inspe": "07/01/09", "Depth": 4.25, "Infiltrati": "No", "Inflow": "No", "Lift_Stati": 4.0, "Manhole_Id": "LS4-065", "Rating": "Good", "SHAWN": 0.0, "LAYER": "SANMH", "FeatId1": 553.0 }, "geometry": { "type": "Point", "coordinates": [ -80.616757079859482, 28.332185583280129 ] } },
    { "type": "Feature", "properties": { "OBJECTID": 459, "Coating": "None", "Constructi": "Brick/Mortar", "Date_Inspe": "06/29/09", "Depth": 4.75, "Infiltrati": "No", "Inflow": "No", "Lift_Stati": 4.0, "Manhole_Id": "LS4-062", "Rating": "Fair", "SHAWN": 0.0, "LAYER": "SANMH", "FeatId1": 552.0 }, "geometry": { "type": "Point", "coordinates": [ -80.617808538008319, 28.33100976070909 ] } },
    { "type": "Feature", "properties": { "OBJECTID": 460, "Coating": "None", "Constructi": "Brick/Mortar", "Date_Inspe": "06/29/09", "Depth": 4.0, "Infiltrati": "Probable", "Inflow": "No", "Lift_Stati": 4.0, "Manhole_Id": "LS4-061", "Rating": "Fair", "SHAWN": 0.0, "LAYER": "SANMH", "FeatId1": 551.0 }, "geometry": { "type": "Point", "coordinates": [ -80.618546957800191, 28.331501778927862 ] } },
    { "type": "Feature", "properties": { "OBJECTID": 461, "Coating": "None", "Constructi": "Brick/Mortar", "Date_Inspe": "06/29/09", "Depth": 2.4, "Infiltrati": "No", "Inflow": "No", "Lift_Stati": 4.0, "Manhole_Id": "LS4-060", "Rating": "Good", "SHAWN": 0.0, "LAYER": "SANMH", "FeatId1": 550.0 }, "geometry": { "type": "Point", "coordinates": [ -80.619277280766838, 28.331986344089263 ] } },
    { "type": "Feature", "properties": { "OBJECTID": 462, "Coating": "None", "Constructi": "Brick/Mortar", "Date_Inspe": "07/01/09", "Depth": 4.5, "Infiltrati": "No", "Inflow": "No", "Lift_Stati": 4.0, "Manhole_Id": "LS4-068", "Rating": "Good", "SHAWN": 0.0, "LAYER": "SANMH", "FeatId1": 549.0 }, "geometry": { "type": "Point", "coordinates": [ -80.618388764417304, 28.332353057256856 ] } },
    { "type": "Feature", "properties": { "OBJECTID": 463, "Coating": "None", "Constructi": "Brick/Mortar", "Date_Inspe": "07/01/09", "Depth": 4.66, "Infiltrati": "Probable", "Inflow": "No", "Lift_Stati": 4.0, "Manhole_Id": "LS4-069", "Rating": "Fair", "SHAWN": 0.0, "LAYER": "SANMH", "FeatId1": 548.0 }, "geometry": { "type": "Point", "coordinates": [ -80.61750404637516, 28.331757580579115 ] } },
    { "type": "Feature", "properties": { "OBJECTID": 464, "Coating": "None", "Constructi": "Brick/Mortar", "Date_Inspe": "06/29/09", "Depth": 2.8, "Infiltrati": "No", "Inflow": "No", "Lift_Stati": 4.0, "Manhole_Id": "LS4-057", "Rating": "Good", "SHAWN": 0.0, "LAYER": "SANMH", "FeatId1": 547.0 }, "geometry": { "type": "Point", "coordinates": [ -80.61791337157635, 28.332753089661324 ] } },
    { "type": "Feature", "properties": { "OBJECTID": 465, "Coating": "Mortar", "Constructi": "Brick/Mortar", "Date_Inspe": "07/01/09", "Depth": 2.75, "Infiltrati": "No", "Inflow": "No", "Lift_Stati": 4.0, "Manhole_Id": "LS4-066", "Rating": "Good", "SHAWN": 0.0, "LAYER": "SANMH", "FeatId1": 546.0 }, "geometry": { "type": "Point", "coordinates": [ -80.617488865082933, 28.332672628218063 ] } },
    { "type": "Feature", "properties": { "OBJECTID": 466, "Coating": "Mortar", "Constructi": "Brick/Mortar", "Date_Inspe": "06/11/09", "Depth": 8.08, "Infiltrati": "Yes", "Inflow": "No", "Lift_Stati": 4.0, "Manhole_Id": "LS4-023", "Rating": "Good", "SHAWN": 0.0, "LAYER": "SANMH", "FeatId1": 545.0 }, "geometry": { "type": "Point", "coordinates": [ -80.611865510385584, 28.329219162771462 ] } },
    { "type": "Feature", "properties": { "OBJECTID": 467, "Coating": "Spectrashield", "Constructi": "Brick/Mortar", "Date_Inspe": "06/11/09", "Depth": 10.16, "Infiltrati": "No", "Inflow": "No", "Lift_Stati": 4.0, "Manhole_Id": "LS4-024", "Rating": "Good", "SHAWN": 0.0, "LAYER": "SANMH", "FeatId1": 544.0 }, "geometry": { "type": "Point", "coordinates": [ -80.611618292400792, 28.329769827145359 ] } },
    { "type": "Feature", "properties": { "OBJECTID": 468, "Coating": "Mortar", "Constructi": "Brick/Mortar", "Date_Inspe": "07/22/09", "Depth": 3.75, "Infiltrati": "No", "Inflow": "No", "Lift_Stati": 4.0, "Manhole_Id": "LS4-090", "Rating": "Good", "SHAWN": 0.0, "LAYER": "SANMH", "FeatId1": 543.0 }, "geometry": { "type": "Point", "coordinates": [ -80.610526927572863, 28.330883359091917 ] } },
    { "type": "Feature", "properties": { "OBJECTID": 469, "Coating": "None", "Constructi": "Brick/Mortar", "Date_Inspe": "06/25/09", "Depth": 4.33, "Infiltrati": "No", "Inflow": "No", "Lift_Stati": 4.0, "Manhole_Id": "LS4-041", "Rating": "Fair", "SHAWN": 0.0, "LAYER": "SANMH", "FeatId1": 542.0 }, "geometry": { "type": "Point", "coordinates": [ -80.616141032941997, 28.334806334560579 ] } },
    { "type": "Feature", "properties": { "OBJECTID": 470, "Coating": "None", "Constructi": "Brick/Mortar", "Date_Inspe": "06/08/09", "Depth": 3.0, "Infiltrati": "No", "Inflow": "No", "Lift_Stati": 4.0, "Manhole_Id": "LS4-002", "Rating": "Fair", "SHAWN": 0.0, "LAYER": "SANMH", "FeatId1": 541.0 }, "geometry": { "type": "Point", "coordinates": [ -80.615413150738135, 28.326447430471742 ] } },
    { "type": "Feature", "properties": { "OBJECTID": 471, "Coating": "None", "Constructi": "Brick/Mortar", "Date_Inspe": "06/08/09", "Depth": 4.08, "Infiltrati": "Probable", "Inflow": "Yes", "Lift_Stati": 4.0, "Manhole_Id": "LS4-006", "Rating": "Fair", "SHAWN": 0.0, "LAYER": "SANMH", "FeatId1": 540.0 }, "geometry": { "type": "Point", "coordinates": [ -80.615836767100504, 28.32685550116592 ] } },
    { "type": "Feature", "properties": { "OBJECTID": 472, "Coating": "None", "Constructi": "Brick/Mortar", "Date_Inspe": "06/09/09", "Depth": 4.4, "Infiltrati": "No", "Inflow": "No", "Lift_Stati": 4.0, "Manhole_Id": "LS4-011", "Rating": "Fair", "SHAWN": 0.0, "LAYER": "SANMH", "FeatId1": 539.0 }, "geometry": { "type": "Point", "coordinates": [ -80.615465055245949, 28.327546209045057 ] } },
    { "type": "Feature", "properties": { "OBJECTID": 473, "Coating": "None", "Constructi": "Brick/Mortar", "Date_Inspe": "06/26/09", "Depth": 1.8, "Infiltrati": "No", "Inflow": "No", "Lift_Stati": 4.0, "Manhole_Id": "LS4-056", "Rating": "Good", "SHAWN": 0.0, "LAYER": "SANMH", "FeatId1": 538.0 }, "geometry": { "type": "Point", "coordinates": [ -80.617400376634706, 28.332960515742478 ] } },
    { "type": "Feature", "properties": { "OBJECTID": 474, "Coating": "None", "Constructi": "Brick/Mortar", "Date_Inspe": "06/29/09", "Depth": 3.33, "Infiltrati": "Yes", "Inflow": "No", "Lift_Stati": 4.0, "Manhole_Id": "LS4-058", "Rating": "Poor", "SHAWN": 0.0, "LAYER": "SANMH", "FeatId1": 537.0 }, "geometry": { "type": "Point", "coordinates": [ -80.618316418996571, 28.332650027759758 ] } },
    { "type": "Feature", "properties": { "OBJECTID": 475, "Coating": "None", "Constructi": "Brick/Mortar", "Date_Inspe": "07/01/09", "Depth": 2.75, "Infiltrati": "Probable", "Inflow": "No", "Lift_Stati": 4.0, "Manhole_Id": "LS4-067", "Rating": "Fair", "SHAWN": 0.0, "LAYER": "SANMH", "FeatId1": 536.0 }, "geometry": { "type": "Point", "coordinates": [ -80.618723738120096, 28.33257430477763 ] } },
    { "type": "Feature", "properties": { "OBJECTID": 476, "Coating": "None", "Constructi": "Brick/Mortar", "Date_Inspe": "06/29/09", "Depth": 2.58, "Infiltrati": "Probable", "Inflow": "No", "Lift_Stati": 4.0, "Manhole_Id": "LS4-059", "Rating": "Fair", "SHAWN": 0.0, "LAYER": "SANMH", "FeatId1": 535.0 }, "geometry": { "type": "Point", "coordinates": [ -80.619175431543979, 28.332330603407321 ] } },
    { "type": "Feature", "properties": { "OBJECTID": 477, "Coating": "None", "Constructi": "Brick/Mortar", "Date_Inspe": "06/25/09", "Depth": 5.08, "Infiltrati": "Yes", "Inflow": "No", "Lift_Stati": 4.0, "Manhole_Id": "LS4-053", "Rating": "Poor", "SHAWN": 0.0, "LAYER": "SANMH", "FeatId1": 534.0 }, "geometry": { "type": "Point", "coordinates": [ -80.615414108454104, 28.332231079188876 ] } },
    { "type": "Feature", "properties": { "OBJECTID": 478, "Coating": "Mortar", "Constructi": "Brick/Mortar", "Date_Inspe": "06/23/09", "Depth": 3.16, "Infiltrati": "No", "Inflow": "No", "Lift_Stati": 4.0, "Manhole_Id": "LS4-048", "Rating": "Good", "SHAWN": 0.0, "LAYER": "SANMH", "FeatId1": 533.0 }, "geometry": { "type": "Point", "coordinates": [ -80.614226539726729, 28.331338031946721 ] } },
    { "type": "Feature", "properties": { "OBJECTID": 479, "Coating": "Mortar", "Constructi": "Brick/Mortar", "Date_Inspe": "06/19/09", "Depth": 4.4, "Infiltrati": "No", "Inflow": "No", "Lift_Stati": 4.0, "Manhole_Id": "LS4-046", "Rating": "Good", "SHAWN": 0.0, "LAYER": "SANMH", "FeatId1": 532.0 }, "geometry": { "type": "Point", "coordinates": [ -80.614083043560143, 28.332227791950732 ] } },
    { "type": "Feature", "properties": { "OBJECTID": 480, "Coating": "None", "Constructi": "Brick/Mortar", "Date_Inspe": "09/11/13", "Depth": 9.75, "Infiltrati": "No", "Inflow": "No", "Lift_Stati": 4.0, "Manhole_Id": "LS4-035", "Rating": "Good", "SHAWN": 0.0, "LAYER": "SANMH", "FeatId1": 531.0 }, "geometry": { "type": "Point", "coordinates": [ -80.612540626157738, 28.33341628687079 ] } },
    { "type": "Feature", "properties": { "OBJECTID": 481, "Coating": "Spectrashield", "Constructi": "Brick/Mortar", "Date_Inspe": "09/11/13", "Depth": 8.5, "Infiltrati": "No", "Inflow": "No", "Lift_Stati": 4.0, "Manhole_Id": "LS4-036", "Rating": "Good", "SHAWN": 0.0, "LAYER": "SANMH", "FeatId1": 530.0 }, "geometry": { "type": "Point", "coordinates": [ -80.613392321931116, 28.333465711610192 ] } },
    { "type": "Feature", "properties": { "OBJECTID": 482, "Coating": "Mortar", "Constructi": "Brick/Mortar", "Date_Inspe": "09/11/13", "Depth": 7.25, "Infiltrati": "No", "Inflow": "No", "Lift_Stati": 4.0, "Manhole_Id": "LS4-037", "Rating": "Good", "SHAWN": 0.0, "LAYER": "SANMH", "FeatId1": 529.0 }, "geometry": { "type": "Point", "coordinates": [ -80.614244970183393, 28.33346709715433 ] } },
    { "type": "Feature", "properties": { "OBJECTID": 483, "Coating": "Spectrashield", "Constructi": "Brick/Mortar", "Date_Inspe": "06/24/09", "Depth": 5.83, "Infiltrati": "Yes", "Inflow": "Yes", "Lift_Stati": 4.0, "Manhole_Id": "LS4-038", "Rating": "Good", "SHAWN": 0.0, "LAYER": "SANMH", "FeatId1": 528.0 }, "geometry": { "type": "Point", "coordinates": [ -80.615089400463873, 28.333467923595023 ] } },
    { "type": "Feature", "properties": { "OBJECTID": 484, "Coating": "Spectrashield", "Constructi": "Brick/Mortar", "Date_Inspe": "06/24/09", "Depth": 5.25, "Infiltrati": "Yes", "Inflow": "No", "Lift_Stati": 4.0, "Manhole_Id": "LS4-039", "Rating": "Good", "SHAWN": 0.0, "LAYER": "SANMH", "FeatId1": 527.0 }, "geometry": { "type": "Point", "coordinates": [ -80.615644807041505, 28.333842882269494 ] } },
    { "type": "Feature", "properties": { "OBJECTID": 485, "Coating": "None", "Constructi": "Brick/Mortar", "Date_Inspe": "06/25/09", "Depth": 4.83, "Infiltrati": "Yes", "Inflow": "No", "Lift_Stati": 4.0, "Manhole_Id": "LS4-040", "Rating": "Fair", "SHAWN": 0.0, "LAYER": "SANMH", "FeatId1": 526.0 }, "geometry": { "type": "Point", "coordinates": [ -80.616267548256346, 28.334265179440962 ] } },
    { "type": "Feature", "properties": { "OBJECTID": 486, "Coating": "Fiberglass Sleeve", "Constructi": "Brick/Mortar", "Date_Inspe": "07/06/09", "Depth": 14.0, "Infiltrati": "No", "Inflow": "No", "Lift_Stati": 4.0, "Manhole_Id": "LS4-072", "Rating": "Good", "SHAWN": 0.0, "LAYER": "SANMH", "FeatId1": 525.0 }, "geometry": { "type": "Point", "coordinates": [ -80.612115876208009, 28.330269744216402 ] } },
    { "type": "Feature", "properties": { "OBJECTID": 487, "Coating": "Fiberglass", "Constructi": "Brick/Mortar", "Date_Inspe": "07/06/09", "Depth": 12.0, "Infiltrati": "No", "Inflow": "No", "Lift_Stati": 4.0, "Manhole_Id": "LS4-073", "Rating": "Good", "SHAWN": 0.0, "LAYER": "SANMH", "FeatId1": 524.0 }, "geometry": { "type": "Point", "coordinates": [ -80.612034395389998, 28.3315321744094 ] } },
    { "type": "Feature", "properties": { "OBJECTID": 488, "Coating": "Spectrashield", "Constructi": "Brick/Mortar", "Date_Inspe": "09/09/11", "Depth": 12.0, "Infiltrati": "No", "Inflow": "No", "Lift_Stati": 4.0, "Manhole_Id": "LS4-074", "Rating": "Good", "SHAWN": 0.0, "LAYER": "SANMH", "FeatId1": 523.0 }, "geometry": { "type": "Point", "coordinates": [ -80.611946995241311, 28.332857779290752 ] } },
    { "type": "Feature", "properties": { "OBJECTID": 489, "Coating": "Fiberglass", "Constructi": "Brick/Mortar", "Date_Inspe": "07/07/09", "Depth": 12.0, "Infiltrati": "No", "Inflow": "No", "Lift_Stati": 4.0, "Manhole_Id": "LS4-075", "Rating": "Good", "SHAWN": 0.0, "LAYER": "SANMH", "FeatId1": 522.0 }, "geometry": { "type": "Point", "coordinates": [ -80.61189526130913, 28.333876081604444 ] } },
    { "type": "Feature", "properties": { "OBJECTID": 490, "Coating": "Fiberglass", "Constructi": "Brick/Mortar", "Date_Inspe": "07/06/09", "Depth": 12.75, "Infiltrati": "No", "Inflow": "No", "Lift_Stati": 4.0, "Manhole_Id": "LS4-076", "Rating": "Good", "SHAWN": 0.0, "LAYER": "SANMH", "FeatId1": 521.0 }, "geometry": { "type": "Point", "coordinates": [ -80.611046485769506, 28.333888885711623 ] } },
    { "type": "Feature", "properties": { "OBJECTID": 491, "Coating": "Mortar", "Constructi": "Brick/Mortar", "Date_Inspe": "09/03/09", "Depth": 12.58, "Infiltrati": "No", "Inflow": "No", "Lift_Stati": 4.0, "Manhole_Id": "LS4-077", "Rating": "Good", "SHAWN": 0.0, "LAYER": "SANMH", "FeatId1": 520.0 }, "geometry": { "type": "Point", "coordinates": [ -80.610671167106673, 28.333891778447917 ] } },
    { "type": "Feature", "properties": { "OBJECTID": 492, "Coating": "None", "Constructi": "Brick/Mortar", "Date_Inspe": "07/07/09", "Depth": 7.08, "Infiltrati": "No", "Inflow": "No", "Lift_Stati": 4.0, "Manhole_Id": "LS4-078", "Rating": "Good", "SHAWN": 0.0, "LAYER": "SANMH", "FeatId1": 519.0 }, "geometry": { "type": "Point", "coordinates": [ -80.612064832534159, 28.33420607101279 ] } },
    { "type": "Feature", "properties": { "OBJECTID": 493, "Coating": "Mortar", "Constructi": "Brick/Mortar", "Date_Inspe": "07/08/09", "Depth": 5.75, "Infiltrati": "Probable", "Inflow": "No", "Lift_Stati": 4.0, "Manhole_Id": "LS4-079", "Rating": "Fair", "SHAWN": 0.0, "LAYER": "SANMH", "FeatId1": 518.0 }, "geometry": { "type": "Point", "coordinates": [ -80.613142019237401, 28.334210504739424 ] } },
    { "type": "Feature", "properties": { "OBJECTID": 494, "Coating": "Mortar", "Constructi": "Brick/Mortar", "Date_Inspe": "07/08/09", "Depth": 4.75, "Infiltrati": "No", "Inflow": "No", "Lift_Stati": 4.0, "Manhole_Id": "LS4-080", "Rating": "Good", "SHAWN": 0.0, "LAYER": "SANMH", "FeatId1": 517.0 }, "geometry": { "type": "Point", "coordinates": [ -80.614067487158579, 28.334208936898936 ] } },
    { "type": "Feature", "properties": { "OBJECTID": 495, "Coating": "Mortar", "Constructi": "Brick/Mortar", "Date_Inspe": "07/08/09", "Depth": 4.16, "Infiltrati": "No", "Inflow": "No", "Lift_Stati": 4.0, "Manhole_Id": "LS4-081", "Rating": "Good", "SHAWN": 0.0, "LAYER": "SANMH", "FeatId1": 516.0 }, "geometry": { "type": "Point", "coordinates": [ -80.614939396833108, 28.334200313045574 ] } },
    { "type": "Feature", "properties": { "OBJECTID": 496, "Coating": "None", "Constructi": "Brick/Mortar", "Date_Inspe": "09/11/13", "Depth": 12.16, "Infiltrati": "No", "Inflow": "No", "Lift_Stati": 4.0, "Manhole_Id": "LS4-101", "Rating": "Good", "SHAWN": 0.0, "LAYER": "SANMH", "FeatId1": 515.0 }, "geometry": { "type": "Point", "coordinates": [ -80.610610310767456, 28.334652189356579 ] } },
    { "type": "Feature", "properties": { "OBJECTID": 497, "Coating": "Spectrashield", "Constructi": "Brick/Mortar", "Date_Inspe": "09/11/13", "Depth": 11.16, "Infiltrati": "No", "Inflow": "No", "Lift_Stati": 4.0, "Manhole_Id": "LS4-102", "Rating": "Good", "SHAWN": 0.0, "LAYER": "SANMH", "FeatId1": 514.0 }, "geometry": { "type": "Point", "coordinates": [ -80.610580297999434, 28.335193193068516 ] } },
    { "type": "Feature", "properties": { "OBJECTID": 498, "Coating": "None", "Constructi": "Brick/Mortar", "Date_Inspe": "09/11/13", "Depth": 11.5, "Infiltrati": "No", "Inflow": "No", "Lift_Stati": 4.0, "Manhole_Id": "LS4-103", "Rating": "Good", "SHAWN": 0.0, "LAYER": "SANMH", "FeatId1": 513.0 }, "geometry": { "type": "Point", "coordinates": [ -80.610574990813831, 28.335239840169816 ] } },
    { "type": "Feature", "properties": { "OBJECTID": 499, "Coating": "Mortar", "Constructi": "Brick/Mortar", "Date_Inspe": "09/08/09", "Depth": 8.0, "Infiltrati": "No", "Inflow": "No", "Lift_Stati": 4.0, "Manhole_Id": "LS4-104", "Rating": "Good", "SHAWN": 0.0, "LAYER": "SANMH", "FeatId1": 512.0 }, "geometry": { "type": "Point", "coordinates": [ -80.611416651749693, 28.335190739182949 ] } },
    { "type": "Feature", "properties": { "OBJECTID": 500, "Coating": "Mortar", "Constructi": "Brick/Mortar", "Date_Inspe": "09/10/09", "Depth": 5.83, "Infiltrati": "No", "Inflow": "No", "Lift_Stati": 4.0, "Manhole_Id": "LS4-105", "Rating": "Good", "SHAWN": 0.0, "LAYER": "SANMH", "FeatId1": 511.0 }, "geometry": { "type": "Point", "coordinates": [ -80.612645787646599, 28.335186601260173 ] } },
    { "type": "Feature", "properties": { "OBJECTID": 501, "Coating": "Mortar", "Constructi": "Brick/Mortar", "Date_Inspe": "09/10/09", "Depth": 4.9, "Infiltrati": "No", "Inflow": "No", "Lift_Stati": 4.0, "Manhole_Id": "LS4-106", "Rating": "Good", "SHAWN": 0.0, "LAYER": "SANMH", "FeatId1": 510.0 }, "geometry": { "type": "Point", "coordinates": [ -80.613871898466783, 28.335183628508428 ] } },
    { "type": "Feature", "properties": { "OBJECTID": 502, "Coating": "None", "Constructi": "Brick/Mortar", "Date_Inspe": "00/00/00", "Depth": 0.0, "Infiltrati": "No", "Inflow": "No", "Lift_Stati": 4.0, "Manhole_Id": "LS4-113", "Rating": "Good", "SHAWN": 0.0, "LAYER": "SANMH", "FeatId1": 509.0 }, "geometry": { "type": "Point", "coordinates": [ -80.614191560411456, 28.335403381893542 ] } },
    { "type": "Feature", "properties": { "OBJECTID": 503, "Coating": "None", "Constructi": "Brick/Mortar", "Date_Inspe": "09/10/09", "Depth": 3.0, "Infiltrati": "No", "Inflow": "No", "Lift_Stati": 4.0, "Manhole_Id": "LS4-112", "Rating": "Good", "SHAWN": 0.0, "LAYER": "SANMH", "FeatId1": 508.0 }, "geometry": { "type": "Point", "coordinates": [ -80.614971377928541, 28.335325424025605 ] } },
    { "type": "Feature", "properties": { "OBJECTID": 504, "Coating": "None", "Constructi": "Brick/Mortar", "Date_Inspe": "09/09/11", "Depth": 4.0, "Infiltrati": "No", "Inflow": "No", "Lift_Stati": 4.0, "Manhole_Id": "LS4-138", "Rating": "Good", "SHAWN": 0.0, "LAYER": "SANMH", "FeatId1": 507.0 }, "geometry": { "type": "Point", "coordinates": [ -80.615069312053564, 28.335595801147576 ] } },
    { "type": "Feature", "properties": { "OBJECTID": 505, "Coating": "Mortar", "Constructi": "Brick/Mortar", "Date_Inspe": "09/10/09", "Depth": 4.5, "Infiltrati": "No", "Inflow": "No", "Lift_Stati": 4.0, "Manhole_Id": "LS4-111", "Rating": "Good", "SHAWN": 0.0, "LAYER": "SANMH", "FeatId1": 506.0 }, "geometry": { "type": "Point", "coordinates": [ -80.61489965515652, 28.335699928278217 ] } },
    { "type": "Feature", "properties": { "OBJECTID": 506, "Coating": "None", "Constructi": "Brick/Mortar", "Date_Inspe": "09/10/09", "Depth": 3.33, "Infiltrati": "No", "Inflow": "No", "Lift_Stati": 4.0, "Manhole_Id": "LS4-110", "Rating": "Good", "SHAWN": 0.0, "LAYER": "SANMH", "FeatId1": 505.0 }, "geometry": { "type": "Point", "coordinates": [ -80.614193718123161, 28.336151669721655 ] } },
    { "type": "Feature", "properties": { "OBJECTID": 507, "Coating": "None", "Constructi": "Brick/Mortar", "Date_Inspe": "09/10/09", "Depth": 3.0, "Infiltrati": "No", "Inflow": "No", "Lift_Stati": 4.0, "Manhole_Id": "LS4-109", "Rating": "Good", "SHAWN": 0.0, "LAYER": "SANMH", "FeatId1": 504.0 }, "geometry": { "type": "Point", "coordinates": [ -80.613808784427249, 28.336207246535128 ] } },
    { "type": "Feature", "properties": { "OBJECTID": 508, "Coating": "Mortar", "Constructi": "Brick/Mortar", "Date_Inspe": "09/10/09", "Depth": 5.0, "Infiltrati": "No", "Inflow": "No", "Lift_Stati": 4.0, "Manhole_Id": "LS4-108", "Rating": "Good", "SHAWN": 0.0, "LAYER": "SANMH", "FeatId1": 503.0 }, "geometry": { "type": "Point", "coordinates": [ -80.612602022552821, 28.336207342695239 ] } },
    { "type": "Feature", "properties": { "OBJECTID": 509, "Coating": "Mortar", "Constructi": "Brick/Mortar", "Date_Inspe": "09/10/09", "Depth": 6.66, "Infiltrati": "No", "Inflow": "No", "Lift_Stati": 4.0, "Manhole_Id": "LS4-107", "Rating": "Good", "SHAWN": 0.0, "LAYER": "SANMH", "FeatId1": 502.0 }, "geometry": { "type": "Point", "coordinates": [ -80.611349223348967, 28.336200954312584 ] } },
    { "type": "Feature", "properties": { "OBJECTID": 510, "Coating": "Fiberglass Sleeve", "Constructi": "Brick/Mortar", "Date_Inspe": "09/16/09", "Depth": 0.0, "Infiltrati": "No", "Inflow": "No", "Lift_Stati": 4.0, "Manhole_Id": "LS4-114", "Rating": "Good", "SHAWN": 0.0, "LAYER": "SANMH", "FeatId1": 501.0 }, "geometry": { "type": "Point", "coordinates": [ -80.610457694291028, 28.337049497000223 ] } },
    { "type": "Feature", "properties": { "OBJECTID": 511, "Coating": "Mortar", "Constructi": "Brick/Mortar", "Date_Inspe": "09/17/09", "Depth": 8.0, "Infiltrati": "No", "Inflow": "No", "Lift_Stati": 4.0, "Manhole_Id": "LS4-115", "Rating": "Good", "SHAWN": 0.0, "LAYER": "SANMH", "FeatId1": 500.0 }, "geometry": { "type": "Point", "coordinates": [ -80.61168657127412, 28.33701557010864 ] } },
    { "type": "Feature", "properties": { "OBJECTID": 512, "Coating": "Mortar", "Constructi": "Brick/Mortar", "Date_Inspe": "09/17/09", "Depth": 7.58, "Infiltrati": "Yes", "Inflow": "No", "Lift_Stati": 4.0, "Manhole_Id": "LS4-116", "Rating": "Fair", "SHAWN": 0.0, "LAYER": "SANMH", "FeatId1": 499.0 }, "geometry": { "type": "Point", "coordinates": [ -80.612929268832019, 28.337027316631605 ] } },
    { "type": "Feature", "properties": { "OBJECTID": 513, "Coating": "Spectrashield", "Constructi": "Brick/Mortar", "Date_Inspe": "09/11/13", "Depth": 6.0, "Infiltrati": "No", "Inflow": "No", "Lift_Stati": 4.0, "Manhole_Id": "LS4-117", "Rating": "Good", "SHAWN": 0.0, "LAYER": "SANMH", "FeatId1": 498.0 }, "geometry": { "type": "Point", "coordinates": [ -80.614039151516991, 28.33709403902543 ] } },
    { "type": "Feature", "properties": { "OBJECTID": 514, "Coating": "None", "Constructi": "Brick/Mortar", "Date_Inspe": "09/18/09", "Depth": 4.83, "Infiltrati": "Probable", "Inflow": "No", "Lift_Stati": 4.0, "Manhole_Id": "LS4-118", "Rating": "Good", "SHAWN": 0.0, "LAYER": "SANMH", "FeatId1": 497.0 }, "geometry": { "type": "Point", "coordinates": [ -80.61496497582678, 28.337592645949407 ] } },
    { "type": "Feature", "properties": { "OBJECTID": 515, "Coating": "None", "Constructi": "Brick/Mortar", "Date_Inspe": "09/18/09", "Depth": 3.08, "Infiltrati": "No", "Inflow": "No", "Lift_Stati": 4.0, "Manhole_Id": "LS4-119", "Rating": "Good", "SHAWN": 0.0, "LAYER": "SANMH", "FeatId1": 496.0 }, "geometry": { "type": "Point", "coordinates": [ -80.615892383033753, 28.338091109399041 ] } },
    { "type": "Feature", "properties": { "OBJECTID": 516, "Coating": "Fiberglass Sleeve", "Constructi": "Brick/Mortar", "Date_Inspe": "10/15/09", "Depth": 9.75, "Infiltrati": "No", "Inflow": "No", "Lift_Stati": 4.0, "Manhole_Id": "LS4-120", "Rating": "Good", "SHAWN": 0.0, "LAYER": "SANMH", "FeatId1": 495.0 }, "geometry": { "type": "Point", "coordinates": [ -80.610388328401939, 28.338090022555125 ] } },
    { "type": "Feature", "properties": { "OBJECTID": 517, "Coating": "Spectrashield", "Constructi": "Brick/Mortar", "Date_Inspe": "09/11/13", "Depth": 4.33, "Infiltrati": "No", "Inflow": "No", "Lift_Stati": 4.0, "Manhole_Id": "LS4-121", "Rating": "Good", "SHAWN": 0.0, "LAYER": "SANMH", "FeatId1": 494.0 }, "geometry": { "type": "Point", "coordinates": [ -80.61161940070447, 28.338108897337708 ] } },
    { "type": "Feature", "properties": { "OBJECTID": 518, "Coating": "None", "Constructi": "Brick/Mortar", "Date_Inspe": "09/11/13", "Depth": 4.56, "Infiltrati": "No", "Inflow": "No", "Lift_Stati": 4.0, "Manhole_Id": "LS4-122", "Rating": "Good", "SHAWN": 0.0, "LAYER": "SANMH", "FeatId1": 493.0 }, "geometry": { "type": "Point", "coordinates": [ -80.612859667707681, 28.338127842150534 ] } },
    { "type": "Feature", "properties": { "OBJECTID": 519, "Coating": "None", "Constructi": "Brick/Mortar", "Date_Inspe": "10/15/09", "Depth": 5.0, "Infiltrati": "Probable", "Inflow": "No", "Lift_Stati": 4.0, "Manhole_Id": "LS4-123", "Rating": "Fair", "SHAWN": 0.0, "LAYER": "SANMH", "FeatId1": 492.0 }, "geometry": { "type": "Point", "coordinates": [ -80.613458922511384, 28.338262837032584 ] } },
    { "type": "Feature", "properties": { "OBJECTID": 520, "Coating": "None", "Constructi": "Brick/Mortar", "Date_Inspe": "10/15/09", "Depth": 4.0, "Infiltrati": "No", "Inflow": "No", "Lift_Stati": 4.0, "Manhole_Id": "LS4-124", "Rating": "Good", "SHAWN": 0.0, "LAYER": "SANMH", "FeatId1": 491.0 }, "geometry": { "type": "Point", "coordinates": [ -80.614464564904395, 28.338779563755114 ] } },
    { "type": "Feature", "properties": { "OBJECTID": 521, "Coating": "None", "Constructi": "Brick/Mortar", "Date_Inspe": "10/15/09", "Depth": 3.67, "Infiltrati": "No", "Inflow": "No", "Lift_Stati": 4.0, "Manhole_Id": "LS4-125", "Rating": "Good", "SHAWN": 0.0, "LAYER": "SANMH", "FeatId1": 490.0 }, "geometry": { "type": "Point", "coordinates": [ -80.615474005372377, 28.339296476978479 ] } },
    { "type": "Feature", "properties": { "OBJECTID": 522, "Coating": "None", "Constructi": "Brick/Mortar", "Date_Inspe": "10/15/09", "Depth": 3.25, "Infiltrati": "No", "Inflow": "No", "Lift_Stati": 4.0, "Manhole_Id": "LS4-126", "Rating": "Good", "SHAWN": 0.0, "LAYER": "SANMH", "FeatId1": 489.0 }, "geometry": { "type": "Point", "coordinates": [ -80.615911485785475, 28.339444318554666 ] } },
    { "type": "Feature", "properties": { "OBJECTID": 523, "Coating": "None", "Constructi": "Brick/Mortar", "Date_Inspe": "10/15/09", "Depth": 3.5, "Infiltrati": "No", "Inflow": "No", "Lift_Stati": 4.0, "Manhole_Id": "LS4-127", "Rating": "Good", "SHAWN": 0.0, "LAYER": "SANMH", "FeatId1": 488.0 }, "geometry": { "type": "Point", "coordinates": [ -80.616644476644694, 28.339224919855909 ] } },
    { "type": "Feature", "properties": { "OBJECTID": 524, "Coating": "Fiberglass Sleeve", "Constructi": "Brick/Mortar", "Date_Inspe": "10/15/09", "Depth": 9.67, "Infiltrati": "No", "Inflow": "No", "Lift_Stati": 4.0, "Manhole_Id": "LS4-128", "Rating": "Good", "SHAWN": 0.0, "LAYER": "SANMH", "FeatId1": 487.0 }, "geometry": { "type": "Point", "coordinates": [ -80.610301125759577, 28.339400830312467 ] } },
    { "type": "Feature", "properties": { "OBJECTID": 525, "Coating": "None", "Constructi": "Brick/Mortar", "Date_Inspe": "10/08/09", "Depth": 8.42, "Infiltrati": "No", "Inflow": "No", "Lift_Stati": 4.0, "Manhole_Id": "LS4-129", "Rating": "Good", "SHAWN": 0.0, "LAYER": "SANMH", "FeatId1": 486.0 }, "geometry": { "type": "Point", "coordinates": [ -80.611138600677918, 28.339380178147284 ] } },
    { "type": "Feature", "properties": { "OBJECTID": 526, "Coating": "None", "Constructi": "Brick/Mortar", "Date_Inspe": "10/08/09", "Depth": 7.58, "Infiltrati": "No", "Inflow": "No", "Lift_Stati": 4.0, "Manhole_Id": "LS4-130", "Rating": "Good", "SHAWN": 0.0, "LAYER": "SANMH", "FeatId1": 485.0 }, "geometry": { "type": "Point", "coordinates": [ -80.612000461060674, 28.339394725603338 ] } },
    { "type": "Feature", "properties": { "OBJECTID": 527, "Coating": "None", "Constructi": "Brick/Mortar", "Date_Inspe": "10/08/09", "Depth": 7.25, "Infiltrati": "No", "Inflow": "No", "Lift_Stati": 4.0, "Manhole_Id": "LS4-131", "Rating": "Good", "SHAWN": 0.0, "LAYER": "SANMH", "FeatId1": 484.0 }, "geometry": { "type": "Point", "coordinates": [ -80.612854291303393, 28.339408885686129 ] } },
    { "type": "Feature", "properties": { "OBJECTID": 528, "Coating": "None", "Constructi": "Brick/Mortar", "Date_Inspe": "10/08/09", "Depth": 6.67, "Infiltrati": "No", "Inflow": "No", "Lift_Stati": 4.0, "Manhole_Id": "LS4-132", "Rating": "Good", "SHAWN": 0.0, "LAYER": "SANMH", "FeatId1": 483.0 }, "geometry": { "type": "Point", "coordinates": [ -80.613411039810259, 28.339560304140718 ] } },
    { "type": "Feature", "properties": { "OBJECTID": 529, "Coating": "None", "Constructi": "Brick/Mortar", "Date_Inspe": "10/08/09", "Depth": 4.83, "Infiltrati": "No", "Inflow": "No", "Lift_Stati": 4.0, "Manhole_Id": "LS4-133", "Rating": "Good", "SHAWN": 0.0, "LAYER": "SANMH", "FeatId1": 482.0 }, "geometry": { "type": "Point", "coordinates": [ -80.614267027993492, 28.340058348761737 ] } },
    { "type": "Feature", "properties": { "OBJECTID": 530, "Coating": "None", "Constructi": "Brick/Mortar", "Date_Inspe": "10/08/09", "Depth": 4.0, "Infiltrati": "No", "Inflow": "No", "Lift_Stati": 4.0, "Manhole_Id": "LS4-134", "Rating": "Good", "SHAWN": 0.0, "LAYER": "SANMH", "FeatId1": 481.0 }, "geometry": { "type": "Point", "coordinates": [ -80.615119088178332, 28.340551330017764 ] } },
    { "type": "Feature", "properties": { "OBJECTID": 531, "Coating": "None", "Constructi": "Brick/Mortar", "Date_Inspe": "10/08/09", "Depth": 3.67, "Infiltrati": "No", "Inflow": "No", "Lift_Stati": 4.0, "Manhole_Id": "LS4-135", "Rating": "Good", "SHAWN": 0.0, "LAYER": "SANMH", "FeatId1": 480.0 }, "geometry": { "type": "Point", "coordinates": [ -80.615798844716139, 28.34066797561367 ] } },
    { "type": "Feature", "properties": { "OBJECTID": 532, "Coating": "Fiberglass Liner", "Constructi": "Brick/Mortar", "Date_Inspe": "10/08/09", "Depth": 8.16, "Infiltrati": "No", "Inflow": "No", "Lift_Stati": 4.0, "Manhole_Id": "LS4-136", "Rating": "Good", "SHAWN": 0.0, "LAYER": "SANMH", "FeatId1": 479.0 }, "geometry": { "type": "Point", "coordinates": [ -80.61026811314585, 28.339894358766902 ] } },
    { "type": "Feature", "properties": { "OBJECTID": 533, "Coating": "Spectrashield", "Constructi": "Brick/Mortar", "Date_Inspe": "10/8/09", "Depth": 8.16, "Infiltrati": "No", "Inflow": "No", "Lift_Stati": 4.0, "Manhole_Id": "LS4-137", "Rating": "Good", "SHAWN": 0.0, "LAYER": "SANMH", "FeatId1": 478.0 }, "geometry": { "type": "Point", "coordinates": [ -80.610229945311218, 28.340491605655973 ] } },
    { "type": "Feature", "properties": { "OBJECTID": 534, "Coating": " ", "Constructi": " ", "Date_Inspe": " ", "Depth": 0.0, "Infiltrati": " ", "Inflow": " ", "Lift_Stati": 4.0, "Manhole_Id": "LS4-138", "Rating": " ", "SHAWN": 0.0, "LAYER": "SANMH", "FeatId1": 477.0 }, "geometry": { "type": "Point", "coordinates": [ -80.611462839957383, 28.340539172538239 ] } },
    { "type": "Feature", "properties": { "OBJECTID": 535, "Coating": " ", "Constructi": " ", "Date_Inspe": "03/24/09", "Depth": 0.0, "Infiltrati": " ", "Inflow": " ", "Lift_Stati": 4.0, "Manhole_Id": "LS4-139", "Rating": " ", "SHAWN": 0.0, "LAYER": "SANMH", "FeatId1": 476.0 }, "geometry": { "type": "Point", "coordinates": [ -80.612690783788167, 28.340557288994123 ] } },
    { "type": "Feature", "properties": { "OBJECTID": 536, "Coating": " ", "Constructi": " ", "Date_Inspe": " ", "Depth": 0.0, "Infiltrati": " ", "Inflow": " ", "Lift_Stati": 4.0, "Manhole_Id": "LS4-140", "Rating": " ", "SHAWN": 0.0, "LAYER": "SANMH", "FeatId1": 475.0 }, "geometry": { "type": "Point", "coordinates": [ -80.613110851511024, 28.340663657986003 ] } },
    { "type": "Feature", "properties": { "OBJECTID": 537, "Coating": " ", "Constructi": " ", "Date_Inspe": " ", "Depth": 0.0, "Infiltrati": " ", "Inflow": " ", "Lift_Stati": 4.0, "Manhole_Id": "LS4-141", "Rating": " ", "SHAWN": 0.0, "LAYER": "SANMH", "FeatId1": 474.0 }, "geometry": { "type": "Point", "coordinates": [ -80.614052017903333, 28.341201366211049 ] } },
    { "type": "Feature", "properties": { "OBJECTID": 538, "Coating": " ", "Constructi": " ", "Date_Inspe": " ", "Depth": 0.0, "Infiltrati": " ", "Inflow": " ", "Lift_Stati": 4.0, "Manhole_Id": "LS4-142", "Rating": " ", "SHAWN": 0.0, "LAYER": "SANMH", "FeatId1": 473.0 }, "geometry": { "type": "Point", "coordinates": [ -80.614986984558371, 28.341740450939326 ] } },
    { "type": "Feature", "properties": { "OBJECTID": 539, "Coating": " ", "Constructi": " ", "Date_Inspe": " ", "Depth": 0.0, "Infiltrati": " ", "Inflow": " ", "Lift_Stati": 4.0, "Manhole_Id": "LS4-144", "Rating": " ", "SHAWN": 0.0, "LAYER": "SANMH", "FeatId1": 472.0 }, "geometry": { "type": "Point", "coordinates": [ -80.61609085472719, 28.341771255605302 ] } },
    { "type": "Feature", "properties": { "OBJECTID": 540, "Coating": " ", "Constructi": " ", "Date_Inspe": " ", "Depth": 0.0, "Infiltrati": " ", "Inflow": " ", "Lift_Stati": 4.0, "Manhole_Id": "LS4-145", "Rating": " ", "SHAWN": 0.0, "LAYER": "SANMH", "FeatId1": 471.0 }, "geometry": { "type": "Point", "coordinates": [ -80.616360650174229, 28.341871870385209 ] } },
    { "type": "Feature", "properties": { "OBJECTID": 541, "Coating": " ", "Constructi": " ", "Date_Inspe": " ", "Depth": 0.0, "Infiltrati": " ", "Inflow": " ", "Lift_Stati": 4.0, "Manhole_Id": "LS4-143", "Rating": " ", "SHAWN": 0.0, "LAYER": "SANMH", "FeatId1": 470.0 }, "geometry": { "type": "Point", "coordinates": [ -80.615564592328568, 28.341786608067878 ] } },
    { "type": "Feature", "properties": { "OBJECTID": 542, "Coating": "None", "Constructi": "Brick/Mortar", "Date_Inspe": "00/00/00", "Depth": 0.0, "Infiltrati": "No", "Inflow": "No", "Lift_Stati": 8.0, "Manhole_Id": "LS8-001", "Rating": "Good", "SHAWN": 0.0, "LAYER": "SANMH", "FeatId1": 469.0 }, "geometry": { "type": "Point", "coordinates": [ -80.608967588448422, 28.370442755408444 ] } },
    { "type": "Feature", "properties": { "OBJECTID": 543, "Coating": "None", "Constructi": "Brick/Mortar", "Date_Inspe": "00/00/00", "Depth": 0.0, "Infiltrati": "No", "Inflow": "No", "Lift_Stati": 8.0, "Manhole_Id": "LS8-002", "Rating": "Good", "SHAWN": 0.0, "LAYER": "SANMH", "FeatId1": 468.0 }, "geometry": { "type": "Point", "coordinates": [ -80.608967843381919, 28.370170936941637 ] } },
    { "type": "Feature", "properties": { "OBJECTID": 544, "Coating": "None", "Constructi": "Brick/Mortar", "Date_Inspe": "00/00/00", "Depth": 0.0, "Infiltrati": "No", "Inflow": "No", "Lift_Stati": 8.0, "Manhole_Id": "LS8-003", "Rating": "Good", "SHAWN": 0.0, "LAYER": "SANMH", "FeatId1": 467.0 }, "geometry": { "type": "Point", "coordinates": [ -80.60936176571542, 28.370173872811225 ] } },
    { "type": "Feature", "properties": { "OBJECTID": 545, "Coating": "None", "Constructi": "Brick/Mortar", "Date_Inspe": "00/00/00", "Depth": 0.0, "Infiltrati": "No", "Inflow": "No", "Lift_Stati": 8.0, "Manhole_Id": "LS8-004", "Rating": "Good", "SHAWN": 0.0, "LAYER": "SANMH", "FeatId1": 466.0 }, "geometry": { "type": "Point", "coordinates": [ -80.610029121864471, 28.370192283145489 ] } },
    { "type": "Feature", "properties": { "OBJECTID": 546, "Coating": "None", "Constructi": "Brick/Mortar", "Date_Inspe": "00/00/00", "Depth": 0.0, "Infiltrati": "No", "Inflow": "No", "Lift_Stati": 8.0, "Manhole_Id": "LS8-005", "Rating": "Good", "SHAWN": 0.0, "LAYER": "SANMH", "FeatId1": 465.0 }, "geometry": { "type": "Point", "coordinates": [ -80.611265338319285, 28.370360240330307 ] } },
    { "type": "Feature", "properties": { "OBJECTID": 547, "Coating": "None", "Constructi": "Brick/Mortar", "Date_Inspe": "00/00/00", "Depth": 0.0, "Infiltrati": "No", "Inflow": "No", "Lift_Stati": 8.0, "Manhole_Id": "LS8-011", "Rating": "Good", "SHAWN": 0.0, "LAYER": "SANMH", "FeatId1": 464.0 }, "geometry": { "type": "Point", "coordinates": [ -80.608968334549289, 28.369549471251325 ] } },
    { "type": "Feature", "properties": { "OBJECTID": 548, "Coating": "None", "Constructi": "Brick/Mortar", "Date_Inspe": "00/00/00", "Depth": 0.0, "Infiltrati": "No", "Inflow": "No", "Lift_Stati": 8.0, "Manhole_Id": "LS8-010", "Rating": "Good", "SHAWN": 0.0, "LAYER": "SANMH", "FeatId1": 463.0 }, "geometry": { "type": "Point", "coordinates": [ -80.608968057131477, 28.369963216682304 ] } },
    { "type": "Feature", "properties": { "OBJECTID": 549, "Coating": "None", "Constructi": "Brick/Mortar", "Date_Inspe": "00/00/00", "Depth": 0.0, "Infiltrati": "No", "Inflow": "No", "Lift_Stati": 5.0, "Manhole_Id": "LS5-011", "Rating": "Good", "SHAWN": 0.0, "LAYER": "SANMH", "FeatId1": 462.0 }, "geometry": { "type": "Point", "coordinates": [ -80.607168126094379, 28.365224375978876 ] } },
    { "type": "Feature", "properties": { "OBJECTID": 550, "Coating": "None", "Constructi": "Brick/Mortar", "Date_Inspe": "00/00/00", "Depth": 0.0, "Infiltrati": "No", "Inflow": "No", "Lift_Stati": 5.0, "Manhole_Id": "LS5-009", "Rating": "Good", "SHAWN": 0.0, "LAYER": "SANMH", "FeatId1": 461.0 }, "geometry": { "type": "Point", "coordinates": [ -80.609325539252268, 28.36550404204333 ] } },
    { "type": "Feature", "properties": { "OBJECTID": 551, "Coating": "None", "Constructi": "Brick/Mortar", "Date_Inspe": "00/00/00", "Depth": 0.0, "Infiltrati": "No", "Inflow": "No", "Lift_Stati": 5.0, "Manhole_Id": "LS5-013", "Rating": "Good", "SHAWN": 0.0, "LAYER": "SANMH", "FeatId1": 460.0 }, "geometry": { "type": "Point", "coordinates": [ -80.610649306667398, 28.365251450577915 ] } },
    { "type": "Feature", "properties": { "OBJECTID": 552, "Coating": "None", "Constructi": "Brick/Mortar", "Date_Inspe": "00/00/00", "Depth": 0.0, "Infiltrati": "No", "Inflow": "No", "Lift_Stati": 5.0, "Manhole_Id": "LS5-014", "Rating": "Good", "SHAWN": 0.0, "LAYER": "SANMH", "FeatId1": 459.0 }, "geometry": { "type": "Point", "coordinates": [ -80.611877040057152, 28.36540913681063 ] } },
    { "type": "Feature", "properties": { "OBJECTID": 553, "Coating": "None", "Constructi": "Brick/Mortar", "Date_Inspe": "00/00/00", "Depth": 0.0, "Infiltrati": "No", "Inflow": "No", "Lift_Stati": 5.0, "Manhole_Id": "LS5-015", "Rating": "Good", "SHAWN": 0.0, "LAYER": "SANMH", "FeatId1": 458.0 }, "geometry": { "type": "Point", "coordinates": [ -80.613102583429296, 28.365566930320107 ] } },
    { "type": "Feature", "properties": { "OBJECTID": 554, "Coating": "None", "Constructi": "Brick/Mortar", "Date_Inspe": "00/00/00", "Depth": 0.0, "Infiltrati": "No", "Inflow": "No", "Lift_Stati": 5.0, "Manhole_Id": "LS5-016", "Rating": "Good", "SHAWN": 0.0, "LAYER": "SANMH", "FeatId1": 457.0 }, "geometry": { "type": "Point", "coordinates": [ -80.614437448076785, 28.365696328719078 ] } },
    { "type": "Feature", "properties": { "OBJECTID": 555, "Coating": "None", "Constructi": "Brick/Mortar", "Date_Inspe": "00/00/00", "Depth": 0.0, "Infiltrati": "No", "Inflow": "No", "Lift_Stati": 5.0, "Manhole_Id": "LS5-017", "Rating": "Good", "SHAWN": 0.0, "LAYER": "SANMH", "FeatId1": 456.0 }, "geometry": { "type": "Point", "coordinates": [ -80.614496341355817, 28.364677800400003 ] } },
    { "type": "Feature", "properties": { "OBJECTID": 556, "Coating": "None", "Constructi": "Brick/Mortar", "Date_Inspe": "00/00/00", "Depth": 0.0, "Infiltrati": "No", "Inflow": "No", "Lift_Stati": 5.0, "Manhole_Id": "LS5-035", "Rating": "Good", "SHAWN": 0.0, "LAYER": "SANMH", "FeatId1": 455.0 }, "geometry": { "type": "Point", "coordinates": [ -80.613280307392074, 28.364394029723808 ] } },
    { "type": "Feature", "properties": { "OBJECTID": 557, "Coating": "None", "Constructi": "Brick/Mortar", "Date_Inspe": "00/00/00", "Depth": 0.0, "Infiltrati": "No", "Inflow": "No", "Lift_Stati": 5.0, "Manhole_Id": "LS5-031", "Rating": "Good", "SHAWN": 0.0, "LAYER": "SANMH", "FeatId1": 454.0 }, "geometry": { "type": "Point", "coordinates": [ -80.612885298430086, 28.364511098377246 ] } },
    { "type": "Feature", "properties": { "OBJECTID": 558, "Coating": "None", "Constructi": "Brick/Mortar", "Date_Inspe": "00/00/00", "Depth": 0.0, "Infiltrati": "No", "Inflow": "No", "Lift_Stati": 5.0, "Manhole_Id": "LS5-030", "Rating": "Good", "SHAWN": 0.0, "LAYER": "SANMH", "FeatId1": 453.0 }, "geometry": { "type": "Point", "coordinates": [ -80.611651300594644, 28.364351525606455 ] } },
    { "type": "Feature", "properties": { "OBJECTID": 559, "Coating": "None", "Constructi": "Brick/Mortar", "Date_Inspe": "00/00/00", "Depth": 0.0, "Infiltrati": "No", "Inflow": "No", "Lift_Stati": 5.0, "Manhole_Id": "LS5-029", "Rating": "Good", "SHAWN": 0.0, "LAYER": "SANMH", "FeatId1": 452.0 }, "geometry": { "type": "Point", "coordinates": [ -80.610343570321248, 28.364679780783167 ] } },
    { "type": "Feature", "properties": { "OBJECTID": 560, "Coating": "None", "Constructi": "Brick/Mortar", "Date_Inspe": "00/00/00", "Depth": 0.0, "Infiltrati": "No", "Inflow": "No", "Lift_Stati": 5.0, "Manhole_Id": "LS5-012", "Rating": "Good", "SHAWN": 0.0, "LAYER": "SANMH", "FeatId1": 451.0 }, "geometry": { "type": "Point", "coordinates": [ -80.60939355284016, 28.365088850815106 ] } },
    { "type": "Feature", "properties": { "OBJECTID": 561, "Coating": "None", "Constructi": "Brick/Mortar", "Date_Inspe": "00/00/00", "Depth": 0.0, "Infiltrati": "No", "Inflow": "No", "Lift_Stati": 5.0, "Manhole_Id": "LS5-018", "Rating": "Good", "SHAWN": 0.0, "LAYER": "SANMH", "FeatId1": 450.0 }, "geometry": { "type": "Point", "coordinates": [ -80.609454536478353, 28.364720337527036 ] } },
    { "type": "Feature", "properties": { "OBJECTID": 562, "Coating": "None", "Constructi": "Brick/Mortar", "Date_Inspe": "00/00/00", "Depth": 0.0, "Infiltrati": "No", "Inflow": "No", "Lift_Stati": 5.0, "Manhole_Id": "LS5-019", "Rating": "Good", "SHAWN": 0.0, "LAYER": "SANMH", "FeatId1": 449.0 }, "geometry": { "type": "Point", "coordinates": [ -80.608376083763559, 28.364582119002414 ] } },
    { "type": "Feature", "properties": { "OBJECTID": 563, "Coating": "None", "Constructi": "Brick/Mortar", "Date_Inspe": "00/00/00", "Depth": 0.0, "Infiltrati": "No", "Inflow": "No", "Lift_Stati": 5.0, "Manhole_Id": "LS5-020", "Rating": "Good", "SHAWN": 0.0, "LAYER": "SANMH", "FeatId1": 448.0 }, "geometry": { "type": "Point", "coordinates": [ -80.60730037451404, 28.364443497412164 ] } },
    { "type": "Feature", "properties": { "OBJECTID": 564, "Coating": "None", "Constructi": "Brick/Mortar", "Date_Inspe": "00/00/00", "Depth": 0.0, "Infiltrati": "No", "Inflow": "No", "Lift_Stati": 5.0, "Manhole_Id": "LS5-028", "Rating": "Good", "SHAWN": 0.0, "LAYER": "SANMH", "FeatId1": 447.0 }, "geometry": { "type": "Point", "coordinates": [ -80.610375433555006, 28.364480162857053 ] } },
    { "type": "Feature", "properties": { "OBJECTID": 565, "Coating": "None", "Constructi": "Brick/Mortar", "Date_Inspe": "00/00/00", "Depth": 0.0, "Infiltrati": "No", "Inflow": "No", "Lift_Stati": 5.0, "Manhole_Id": "LS5-027", "Rating": "Good", "SHAWN": 0.0, "LAYER": "SANMH", "FeatId1": 446.0 }, "geometry": { "type": "Point", "coordinates": [ -80.610423592904823, 28.364192181642668 ] } },
    { "type": "Feature", "properties": { "OBJECTID": 566, "Coating": "None", "Constructi": "Brick/Mortar", "Date_Inspe": "00/00/00", "Depth": 0.0, "Infiltrati": "No", "Inflow": "No", "Lift_Stati": 5.0, "Manhole_Id": "LS5-022", "Rating": "Good", "SHAWN": 0.0, "LAYER": "SANMH", "FeatId1": 445.0 }, "geometry": { "type": "Point", "coordinates": [ -80.609586583983798, 28.363928002304391 ] } },
    { "type": "Feature", "properties": { "OBJECTID": 567, "Coating": "None", "Constructi": "Brick/Mortar", "Date_Inspe": "00/00/00", "Depth": 0.0, "Infiltrati": "No", "Inflow": "No", "Lift_Stati": 5.0, "Manhole_Id": "LS5-023", "Rating": "Good", "SHAWN": 0.0, "LAYER": "SANMH", "FeatId1": 444.0 }, "geometry": { "type": "Point", "coordinates": [ -80.608510154847764, 28.363792855095475 ] } },
    { "type": "Feature", "properties": { "OBJECTID": 568, "Coating": "None", "Constructi": "Brick/Mortar", "Date_Inspe": "00/00/00", "Depth": 0.0, "Infiltrati": "No", "Inflow": "No", "Lift_Stati": 5.0, "Manhole_Id": "LS5-024", "Rating": "Good", "SHAWN": 0.0, "LAYER": "SANMH", "FeatId1": 443.0 }, "geometry": { "type": "Point", "coordinates": [ -80.607434670830486, 28.363656402724107 ] } },
    { "type": "Feature", "properties": { "OBJECTID": 569, "Coating": "None", "Constructi": "Brick/Mortar", "Date_Inspe": "00/00/00", "Depth": 0.0, "Infiltrati": "No", "Inflow": "No", "Lift_Stati": 5.0, "Manhole_Id": "LS5-037", "Rating": "Good", "SHAWN": 0.0, "LAYER": "SANMH", "FeatId1": 442.0 }, "geometry": { "type": "Point", "coordinates": [ -80.607564100528094, 28.362865573353037 ] } },
    { "type": "Feature", "properties": { "OBJECTID": 570, "Coating": "None", "Constructi": "Brick/Mortar", "Date_Inspe": "00/00/00", "Depth": 0.0, "Infiltrati": "No", "Inflow": "No", "Lift_Stati": 5.0, "Manhole_Id": "LS5-036", "Rating": "Good", "SHAWN": 0.0, "LAYER": "SANMH", "FeatId1": 441.0 }, "geometry": { "type": "Point", "coordinates": [ -80.608644808084492, 28.363004793666832 ] } },
    { "type": "Feature", "properties": { "OBJECTID": 571, "Coating": "None", "Constructi": "Brick/Mortar", "Date_Inspe": "00/00/00", "Depth": 0.0, "Infiltrati": "No", "Inflow": "No", "Lift_Stati": 5.0, "Manhole_Id": "LS5-025", "Rating": "Good", "SHAWN": 0.0, "LAYER": "SANMH", "FeatId1": 440.0 }, "geometry": { "type": "Point", "coordinates": [ -80.60971243422992, 28.363140381999489 ] } },
    { "type": "Feature", "properties": { "OBJECTID": 572, "Coating": "None", "Constructi": "Brick/Mortar", "Date_Inspe": "00/00/00", "Depth": 0.0, "Infiltrati": "No", "Inflow": "No", "Lift_Stati": 5.0, "Manhole_Id": "LS5-026", "Rating": "Good", "SHAWN": 0.0, "LAYER": "SANMH", "FeatId1": 439.0 }, "geometry": { "type": "Point", "coordinates": [ -80.610541849159063, 28.363486525697397 ] } },
    { "type": "Feature", "properties": { "OBJECTID": 573, "Coating": "None", "Constructi": "Brick/Mortar", "Date_Inspe": "00/00/00", "Depth": 0.0, "Infiltrati": "No", "Inflow": "No", "Lift_Stati": 5.0, "Manhole_Id": "LS5-032", "Rating": "Good", "SHAWN": 0.0, "LAYER": "SANMH", "FeatId1": 438.0 }, "geometry": { "type": "Point", "coordinates": [ -80.611429861854958, 28.363599885644497 ] } },
    { "type": "Feature", "properties": { "OBJECTID": 574, "Coating": "None", "Constructi": "Brick/Mortar", "Date_Inspe": "00/00/00", "Depth": 0.0, "Infiltrati": "No", "Inflow": "No", "Lift_Stati": 5.0, "Manhole_Id": "LS5-033", "Rating": "Good", "SHAWN": 0.0, "LAYER": "SANMH", "FeatId1": 437.0 }, "geometry": { "type": "Point", "coordinates": [ -80.612350991101749, 28.363719805720432 ] } },
    { "type": "Feature", "properties": { "OBJECTID": 575, "Coating": "None", "Constructi": "Brick/Mortar", "Date_Inspe": "00/00/00", "Depth": 0.0, "Infiltrati": "No", "Inflow": "No", "Lift_Stati": 5.0, "Manhole_Id": "LS5-034", "Rating": "Good", "SHAWN": 0.0, "LAYER": "SANMH", "FeatId1": 436.0 }, "geometry": { "type": "Point", "coordinates": [ -80.61327498231266, 28.363838781124759 ] } },
    { "type": "Feature", "properties": { "OBJECTID": 576, "Coating": "None", "Constructi": "Brick/Mortar", "Date_Inspe": "00/00/00", "Depth": 0.0, "Infiltrati": "No", "Inflow": "No", "Lift_Stati": 5.0, "Manhole_Id": "LS5-047", "Rating": "Good", "SHAWN": 0.0, "LAYER": "SANMH", "FeatId1": 435.0 }, "geometry": { "type": "Point", "coordinates": [ -80.614487991347858, 28.363458022783465 ] } },
    { "type": "Feature", "properties": { "OBJECTID": 577, "Coating": "None", "Constructi": "Brick/Mortar", "Date_Inspe": "00/00/00", "Depth": 0.0, "Infiltrati": "No", "Inflow": "No", "Lift_Stati": 5.0, "Manhole_Id": "LS5-050", "Rating": "Good", "SHAWN": 0.0, "LAYER": "SANMH", "FeatId1": 434.0 }, "geometry": { "type": "Point", "coordinates": [ -80.61311288863773, 28.363096807636307 ] } },
    { "type": "Feature", "properties": { "OBJECTID": 578, "Coating": "None", "Constructi": "Brick/Mortar", "Date_Inspe": "00/00/00", "Depth": 0.0, "Infiltrati": "No", "Inflow": "No", "Lift_Stati": 5.0, "Manhole_Id": "LS5-049", "Rating": "Good", "SHAWN": 0.0, "LAYER": "SANMH", "FeatId1": 433.0 }, "geometry": { "type": "Point", "coordinates": [ -80.61188515622905, 28.362936372328143 ] } },
    { "type": "Feature", "properties": { "OBJECTID": 579, "Coating": "None", "Constructi": "Brick/Mortar", "Date_Inspe": "00/00/00", "Depth": 0.0, "Infiltrati": "No", "Inflow": "No", "Lift_Stati": 5.0, "Manhole_Id": "LS5-048", "Rating": "Good", "SHAWN": 0.0, "LAYER": "SANMH", "FeatId1": 432.0 }, "geometry": { "type": "Point", "coordinates": [ -80.610655445285929, 28.362776829015605 ] } },
    { "type": "Feature", "properties": { "OBJECTID": 580, "Coating": "None", "Constructi": "Brick/Mortar", "Date_Inspe": "00/00/00", "Depth": 0.0, "Infiltrati": "No", "Inflow": "No", "Lift_Stati": 5.0, "Manhole_Id": "LS5-038", "Rating": "Good", "SHAWN": 0.0, "LAYER": "SANMH", "FeatId1": 431.0 }, "geometry": { "type": "Point", "coordinates": [ -80.609857746791718, 28.362231667791214 ] } },
    { "type": "Feature", "properties": { "OBJECTID": 581, "Coating": "None", "Constructi": "Brick/Mortar", "Date_Inspe": "00/00/00", "Depth": 0.0, "Infiltrati": "No", "Inflow": "No", "Lift_Stati": 5.0, "Manhole_Id": "LS5-041", "Rating": "Good", "SHAWN": 0.0, "LAYER": "SANMH", "FeatId1": 430.0 }, "geometry": { "type": "Point", "coordinates": [ -80.609951463110761, 28.36165748203975 ] } },
    { "type": "Feature", "properties": { "OBJECTID": 582, "Coating": "None", "Constructi": "Brick/Mortar", "Date_Inspe": "00/00/00", "Depth": 0.0, "Infiltrati": "No", "Inflow": "No", "Lift_Stati": 5.0, "Manhole_Id": "LS5-042", "Rating": "Good", "SHAWN": 0.0, "LAYER": "SANMH", "FeatId1": 429.0 }, "geometry": { "type": "Point", "coordinates": [ -80.60995409058124, 28.36162047374351 ] } },
    { "type": "Feature", "properties": { "OBJECTID": 583, "Coating": "None", "Constructi": "Brick/Mortar", "Date_Inspe": "00/00/00", "Depth": 0.0, "Infiltrati": "No", "Inflow": "No", "Lift_Stati": 5.0, "Manhole_Id": "LS5-051", "Rating": "Good", "SHAWN": 0.0, "LAYER": "SANMH", "FeatId1": 428.0 }, "geometry": { "type": "Point", "coordinates": [ -80.610008999910235, 28.361307914361927 ] } },
    { "type": "Feature", "properties": { "OBJECTID": 584, "Coating": "None", "Constructi": "Brick/Mortar", "Date_Inspe": "00/00/00", "Depth": 0.0, "Infiltrati": "No", "Inflow": "No", "Lift_Stati": 5.0, "Manhole_Id": "LS5-043", "Rating": "Good", "SHAWN": 0.0, "LAYER": "SANMH", "FeatId1": 427.0 }, "geometry": { "type": "Point", "coordinates": [ -80.610824103650387, 28.361770086303761 ] } },
    { "type": "Feature", "properties": { "OBJECTID": 585, "Coating": "None", "Constructi": "Brick/Mortar", "Date_Inspe": "00/00/00", "Depth": 0.0, "Infiltrati": "No", "Inflow": "No", "Lift_Stati": 5.0, "Manhole_Id": "LS5-044", "Rating": "Good", "SHAWN": 0.0, "LAYER": "SANMH", "FeatId1": 426.0 }, "geometry": { "type": "Point", "coordinates": [ -80.61205400444203, 28.36192760999436 ] } },
    { "type": "Feature", "properties": { "OBJECTID": 586, "Coating": "None", "Constructi": "Brick/Mortar", "Date_Inspe": "00/00/00", "Depth": 0.0, "Infiltrati": "No", "Inflow": "No", "Lift_Stati": 5.0, "Manhole_Id": "LS5-045", "Rating": "Good", "SHAWN": 0.0, "LAYER": "SANMH", "FeatId1": 425.0 }, "geometry": { "type": "Point", "coordinates": [ -80.613284832249292, 28.362085959024494 ] } },
    { "type": "Feature", "properties": { "OBJECTID": 587, "Coating": "None", "Constructi": "Brick/Mortar", "Date_Inspe": "00/00/00", "Depth": 0.0, "Infiltrati": "No", "Inflow": "No", "Lift_Stati": 5.0, "Manhole_Id": "LS5-046", "Rating": "Good", "SHAWN": 0.0, "LAYER": "SANMH", "FeatId1": 424.0 }, "geometry": { "type": "Point", "coordinates": [ -80.614423086764049, 28.362233292587057 ] } },
    { "type": "Feature", "properties": { "OBJECTID": 588, "Coating": "None", "Constructi": "Brick/Mortar", "Date_Inspe": "00/00/00", "Depth": 0.0, "Infiltrati": "No", "Inflow": "No", "Lift_Stati": 5.0, "Manhole_Id": "LS5-040", "Rating": "Good", "SHAWN": 0.0, "LAYER": "SANMH", "FeatId1": 423.0 }, "geometry": { "type": "Point", "coordinates": [ -80.607708417436356, 28.361950070187596 ] } },
    { "type": "Feature", "properties": { "OBJECTID": 589, "Coating": "None", "Constructi": "Brick/Mortar", "Date_Inspe": "00/00/00", "Depth": 0.0, "Infiltrati": "No", "Inflow": "No", "Lift_Stati": 5.0, "Manhole_Id": "LS5-052", "Rating": "Good", "SHAWN": 0.0, "LAYER": "SANMH", "FeatId1": 422.0 }, "geometry": { "type": "Point", "coordinates": [ -80.608940176200605, 28.361173578388218 ] } },
    { "type": "Feature", "properties": { "OBJECTID": 590, "Coating": "None", "Constructi": "Brick/Mortar", "Date_Inspe": "00/00/00", "Depth": 0.0, "Infiltrati": "No", "Inflow": "No", "Lift_Stati": 5.0, "Manhole_Id": "LS5-053", "Rating": "Good", "SHAWN": 0.0, "LAYER": "SANMH", "FeatId1": 421.0 }, "geometry": { "type": "Point", "coordinates": [ -80.607922635905737, 28.361046409122533 ] } },
    { "type": "Feature", "properties": { "OBJECTID": 591, "Coating": "None", "Constructi": "Brick/Mortar", "Date_Inspe": "00/00/00", "Depth": 0.0, "Infiltrati": "No", "Inflow": "No", "Lift_Stati": 5.0, "Manhole_Id": "LS5-086", "Rating": "Good", "SHAWN": 0.0, "LAYER": "SANMH", "FeatId1": 420.0 }, "geometry": { "type": "Point", "coordinates": [ -80.610125437525298, 28.360562921092484 ] } },
    { "type": "Feature", "properties": { "OBJECTID": 592, "Coating": "None", "Constructi": "Brick/Mortar", "Date_Inspe": "00/00/00", "Depth": 0.0, "Infiltrati": "No", "Inflow": "No", "Lift_Stati": 5.0, "Manhole_Id": "LS5-087", "Rating": "Good", "SHAWN": 0.0, "LAYER": "SANMH", "FeatId1": 419.0 }, "geometry": { "type": "Point", "coordinates": [ -80.609039409415828, 28.360418369821339 ] } },
    { "type": "Feature", "properties": { "OBJECTID": 593, "Coating": "None", "Constructi": "Brick/Mortar", "Date_Inspe": "00/00/00", "Depth": 0.0, "Infiltrati": "No", "Inflow": "No", "Lift_Stati": 5.0, "Manhole_Id": "LS5-088", "Rating": "Good", "SHAWN": 0.0, "LAYER": "SANMH", "FeatId1": 418.0 }, "geometry": { "type": "Point", "coordinates": [ -80.607981144850839, 28.360281823310803 ] } },
    { "type": "Feature", "properties": { "OBJECTID": 594, "Coating": "None", "Constructi": "Brick/Mortar", "Date_Inspe": "00/00/00", "Depth": 0.0, "Infiltrati": "No", "Inflow": "No", "Lift_Stati": 5.0, "Manhole_Id": "LS5-093", "Rating": "Good", "SHAWN": 0.0, "LAYER": "SANMH", "FeatId1": 417.0 }, "geometry": { "type": "Point", "coordinates": [ -80.610252221379312, 28.359774379398761 ] } },
    { "type": "Feature", "properties": { "OBJECTID": 595, "Coating": "None", "Constructi": "Brick/Mortar", "Date_Inspe": "00/00/00", "Depth": 0.0, "Infiltrati": "No", "Inflow": "No", "Lift_Stati": 5.0, "Manhole_Id": "LS5-094", "Rating": "Good", "SHAWN": 0.0, "LAYER": "SANMH", "FeatId1": 416.0 }, "geometry": { "type": "Point", "coordinates": [ -80.609220714325616, 28.359633254694291 ] } },
    { "type": "Feature", "properties": { "OBJECTID": 596, "Coating": "None", "Constructi": "Brick/Mortar", "Date_Inspe": "00/00/00", "Depth": 0.0, "Infiltrati": "No", "Inflow": "No", "Lift_Stati": 5.0, "Manhole_Id": "LS5-095", "Rating": "Good", "SHAWN": 0.0, "LAYER": "SANMH", "FeatId1": 415.0 }, "geometry": { "type": "Point", "coordinates": [ -80.608373683417966, 28.359518703357487 ] } },
    { "type": "Feature", "properties": { "OBJECTID": 597, "Coating": "None", "Constructi": "Brick/Mortar", "Date_Inspe": "00/00/00", "Depth": 0.0, "Infiltrati": "No", "Inflow": "No", "Lift_Stati": 5.0, "Manhole_Id": "LS5-102", "Rating": "Good", "SHAWN": 0.0, "LAYER": "SANMH", "FeatId1": 414.0 }, "geometry": { "type": "Point", "coordinates": [ -80.610442330577058, 28.358867537256714 ] } },
    { "type": "Feature", "properties": { "OBJECTID": 598, "Coating": "None", "Constructi": "Brick/Mortar", "Date_Inspe": "00/00/00", "Depth": 0.0, "Infiltrati": "No", "Inflow": "No", "Lift_Stati": 5.0, "Manhole_Id": "LS5-103", "Rating": "Good", "SHAWN": 0.0, "LAYER": "SANMH", "FeatId1": 413.0 }, "geometry": { "type": "Point", "coordinates": [ -80.610468676176154, 28.358728808348555 ] } },
    { "type": "Feature", "properties": { "OBJECTID": 599, "Coating": "None", "Constructi": "Brick/Mortar", "Date_Inspe": "00/00/00", "Depth": 0.0, "Infiltrati": "No", "Inflow": "No", "Lift_Stati": 5.0, "Manhole_Id": "LS5-104", "Rating": "Good", "SHAWN": 0.0, "LAYER": "SANMH", "FeatId1": 412.0 }, "geometry": { "type": "Point", "coordinates": [ -80.609698559621734, 28.35873491521194 ] } },
    { "type": "Feature", "properties": { "OBJECTID": 600, "Coating": "None", "Constructi": "Brick/Mortar", "Date_Inspe": "00/00/00", "Depth": 0.0, "Infiltrati": "No", "Inflow": "No", "Lift_Stati": 5.0, "Manhole_Id": "LS5-105", "Rating": "Good", "SHAWN": 0.0, "LAYER": "SANMH", "FeatId1": 411.0 }, "geometry": { "type": "Point", "coordinates": [ -80.60902146338303, 28.35874308919221 ] } },
    { "type": "Feature", "properties": { "OBJECTID": 601, "Coating": "None", "Constructi": "Brick/Mortar", "Date_Inspe": "00/00/00", "Depth": 0.0, "Infiltrati": "No", "Inflow": "No", "Lift_Stati": 5.0, "Manhole_Id": "LS5-106", "Rating": "Good", "SHAWN": 0.0, "LAYER": "SANMH", "FeatId1": 410.0 }, "geometry": { "type": "Point", "coordinates": [ -80.608378993544079, 28.358750367561832 ] } },
    { "type": "Feature", "properties": { "OBJECTID": 602, "Coating": "None", "Constructi": "Brick/Mortar", "Date_Inspe": "00/00/00", "Depth": 0.0, "Infiltrati": "No", "Inflow": "No", "Lift_Stati": 5.0, "Manhole_Id": "LS5-096", "Rating": "Good", "SHAWN": 0.0, "LAYER": "SANMH", "FeatId1": 409.0 }, "geometry": { "type": "Point", "coordinates": [ -80.608114938179767, 28.359482684828322 ] } },
    { "type": "Feature", "properties": { "OBJECTID": 603, "Coating": "None", "Constructi": "Brick/Mortar", "Date_Inspe": "00/00/00", "Depth": 0.0, "Infiltrati": "No", "Inflow": "No", "Lift_Stati": 5.0, "Manhole_Id": "LS5-001", "Rating": "Good", "SHAWN": 0.0, "LAYER": "SANMH", "FeatId1": 408.0 }, "geometry": { "type": "Point", "coordinates": [ -80.613059856373496, 28.368697642332812 ] } },
    { "type": "Feature", "properties": { "OBJECTID": 604, "Coating": "None", "Constructi": "Brick/Mortar", "Date_Inspe": "00/00/00", "Depth": 0.0, "Infiltrati": "No", "Inflow": "No", "Lift_Stati": 5.0, "Manhole_Id": "LS5-002", "Rating": "Good", "SHAWN": 0.0, "LAYER": "SANMH", "FeatId1": 407.0 }, "geometry": { "type": "Point", "coordinates": [ -80.611791834948576, 28.368526992279527 ] } },
    { "type": "Feature", "properties": { "OBJECTID": 605, "Coating": "None", "Constructi": "Brick/Mortar", "Date_Inspe": "00/00/00", "Depth": 0.0, "Infiltrati": "No", "Inflow": "No", "Lift_Stati": 5.0, "Manhole_Id": "LS5-003", "Rating": "Good", "SHAWN": 0.0, "LAYER": "SANMH", "FeatId1": 406.0 }, "geometry": { "type": "Point", "coordinates": [ -80.610562034473688, 28.368361533472576 ] } },
    { "type": "Feature", "properties": { "OBJECTID": 606, "Coating": "None", "Constructi": "Brick/Mortar", "Date_Inspe": "00/00/00", "Depth": 0.0, "Infiltrati": "No", "Inflow": "No", "Lift_Stati": 5.0, "Manhole_Id": "LS5-004", "Rating": "Good", "SHAWN": 0.0, "LAYER": "SANMH", "FeatId1": 405.0 }, "geometry": { "type": "Point", "coordinates": [ -80.609318906217908, 28.368354512582602 ] } },
    { "type": "Feature", "properties": { "OBJECTID": 607, "Coating": "None", "Constructi": "Brick/Mortar", "Date_Inspe": "00/00/00", "Depth": 0.0, "Infiltrati": "No", "Inflow": "No", "Lift_Stati": 5.0, "Manhole_Id": "LS5-006", "Rating": "Good", "SHAWN": 0.0, "LAYER": "SANMH", "FeatId1": 404.0 }, "geometry": { "type": "Point", "coordinates": [ -80.608974570340266, 28.367997199048226 ] } },
    { "type": "Feature", "properties": { "OBJECTID": 608, "Coating": "None", "Constructi": "Brick/Mortar", "Date_Inspe": "00/00/00", "Depth": 0.0, "Infiltrati": "No", "Inflow": "No", "Lift_Stati": 5.0, "Manhole_Id": "LS5-007", "Rating": "Good", "SHAWN": 0.0, "LAYER": "SANMH", "FeatId1": 403.0 }, "geometry": { "type": "Point", "coordinates": [ -80.608975696116602, 28.367806996177976 ] } },
    { "type": "Feature", "properties": { "OBJECTID": 609, "Coating": "None", "Constructi": "Brick/Mortar", "Date_Inspe": "00/00/00", "Depth": 0.0, "Infiltrati": "No", "Inflow": "No", "Lift_Stati": 5.0, "Manhole_Id": "LS5-008", "Rating": "Good", "SHAWN": 0.0, "LAYER": "SANMH", "FeatId1": 402.0 }, "geometry": { "type": "Point", "coordinates": [ -80.609188661782682, 28.366509249064293 ] } },
    { "type": "Feature", "properties": { "OBJECTID": 610, "Coating": "None", "Constructi": "Brick/Mortar", "Date_Inspe": "00/00/00", "Depth": 0.0, "Infiltrati": "No", "Inflow": "No", "Lift_Stati": 5.0, "Manhole_Id": "LS5-010", "Rating": "Good", "SHAWN": 0.0, "LAYER": "SANMH", "FeatId1": 401.0 }, "geometry": { "type": "Point", "coordinates": [ -80.608256795329254, 28.365357631643583 ] } },
    { "type": "Feature", "properties": { "OBJECTID": 611, "Coating": "None", "Constructi": "Brick/Mortar", "Date_Inspe": "00/00/00", "Depth": 0.0, "Infiltrati": "No", "Inflow": "No", "Lift_Stati": 5.0, "Manhole_Id": "LS5-021", "Rating": "Good", "SHAWN": 0.0, "LAYER": "SANMH", "FeatId1": 400.0 }, "geometry": { "type": "Point", "coordinates": [ -80.609618610207391, 28.36436464598717 ] } },
    { "type": "Feature", "properties": { "OBJECTID": 612, "Coating": "None", "Constructi": "Brick/Mortar", "Date_Inspe": "00/00/00", "Depth": 0.0, "Infiltrati": "No", "Inflow": "No", "Lift_Stati": 5.0, "Manhole_Id": "LS5-089", "Rating": "Good", "SHAWN": 0.0, "LAYER": "SANMH", "FeatId1": 399.0 }, "geometry": { "type": "Point", "coordinates": [ -80.611181886280136, 28.360710822804485 ] } },
    { "type": "Feature", "properties": { "OBJECTID": 613, "Coating": "None", "Constructi": "Brick/Mortar", "Date_Inspe": "00/00/00", "Depth": 0.0, "Infiltrati": "No", "Inflow": "No", "Lift_Stati": 5.0, "Manhole_Id": "LS5-090", "Rating": "Good", "SHAWN": 0.0, "LAYER": "SANMH", "FeatId1": 398.0 }, "geometry": { "type": "Point", "coordinates": [ -80.612243804505894, 28.360847312501427 ] } },
    { "type": "Feature", "properties": { "OBJECTID": 614, "Coating": "None", "Constructi": "Brick/Mortar", "Date_Inspe": "00/00/00", "Depth": 0.0, "Infiltrati": "No", "Inflow": "No", "Lift_Stati": 5.0, "Manhole_Id": "LS5-091", "Rating": "Good", "SHAWN": 0.0, "LAYER": "SANMH", "FeatId1": 397.0 }, "geometry": { "type": "Point", "coordinates": [ -80.61330281829089, 28.360984051904168 ] } },
    { "type": "Feature", "properties": { "OBJECTID": 615, "Coating": "None", "Constructi": "Brick/Mortar", "Date_Inspe": "00/00/00", "Depth": 0.0, "Infiltrati": "No", "Inflow": "No", "Lift_Stati": 5.0, "Manhole_Id": "LS5-092", "Rating": "Good", "SHAWN": 0.0, "LAYER": "SANMH", "FeatId1": 396.0 }, "geometry": { "type": "Point", "coordinates": [ -80.614402182265039, 28.361126566756099 ] } },
    { "type": "Feature", "properties": { "OBJECTID": 616, "Coating": "None", "Constructi": "Brick/Mortar", "Date_Inspe": "00/00/00", "Depth": 0.0, "Infiltrati": "No", "Inflow": "No", "Lift_Stati": 5.0, "Manhole_Id": "LS5-101", "Rating": "Good", "SHAWN": 0.0, "LAYER": "SANMH", "FeatId1": 395.0 }, "geometry": { "type": "Point", "coordinates": [ -80.614185817524444, 28.359924767128465 ] } },
    { "type": "Feature", "properties": { "OBJECTID": 617, "Coating": "None", "Constructi": "Brick/Mortar", "Date_Inspe": "00/00/00", "Depth": 0.0, "Infiltrati": "No", "Inflow": "No", "Lift_Stati": 5.0, "Manhole_Id": "LS5-100", "Rating": "Good", "SHAWN": 0.0, "LAYER": "SANMH", "FeatId1": 394.0 }, "geometry": { "type": "Point", "coordinates": [ -80.613286384125686, 28.359809852540373 ] } },
    { "type": "Feature", "properties": { "OBJECTID": 618, "Coating": "None", "Constructi": "Brick/Mortar", "Date_Inspe": "00/00/00", "Depth": 0.0, "Infiltrati": "No", "Inflow": "No", "Lift_Stati": 5.0, "Manhole_Id": "LS5-099", "Rating": "Good", "SHAWN": 0.0, "LAYER": "SANMH", "FeatId1": 393.0 }, "geometry": { "type": "Point", "coordinates": [ -80.611446265062426, 28.359571337267276 ] } },
    { "type": "Feature", "properties": { "OBJECTID": 619, "Coating": "None", "Constructi": "Brick/Mortar", "Date_Inspe": "00/00/00", "Depth": 0.0, "Infiltrati": "No", "Inflow": "No", "Lift_Stati": 5.0, "Manhole_Id": "LS5-098", "Rating": "Good", "SHAWN": 0.0, "LAYER": "SANMH", "FeatId1": 392.0 }, "geometry": { "type": "Point", "coordinates": [ -80.610541187252593, 28.359454781414001 ] } },
    { "type": "Feature", "properties": { "OBJECTID": 620, "Coating": "None", "Constructi": "Brick/Mortar", "Date_Inspe": "00/00/00", "Depth": 0.0, "Infiltrati": "No", "Inflow": "No", "Lift_Stati": 5.0, "Manhole_Id": "LS5-005", "Rating": "Good", "SHAWN": 0.0, "LAYER": "SANMH", "FeatId1": 391.0 }, "geometry": { "type": "Point", "coordinates": [ -80.60897421100448, 28.368351226952697 ] } },
    { "type": "Feature", "properties": { "OBJECTID": 621, "Coating": "None", "Constructi": "Brick/Mortar", "Date_Inspe": "00/00/00", "Depth": 0.0, "Infiltrati": "No", "Inflow": "No", "Lift_Stati": 5.0, "Manhole_Id": "LS5-054", "Rating": "Good", "SHAWN": 0.0, "LAYER": "SANMH", "FeatId1": 390.0 }, "geometry": { "type": "Point", "coordinates": [ -80.607803542577642, 28.361082014941459 ] } },
    { "type": "Feature", "properties": { "OBJECTID": 622, "Coating": "None", "Constructi": "Brick/Mortar", "Date_Inspe": "00/00/00", "Depth": 0.0, "Infiltrati": "No", "Inflow": "No", "Lift_Stati": 5.0, "Manhole_Id": "LS5-055", "Rating": "Good", "SHAWN": 0.0, "LAYER": "SANMH", "FeatId1": 389.0 }, "geometry": { "type": "Point", "coordinates": [ -80.607509947709161, 28.361047032883608 ] } },
    { "type": "Feature", "properties": { "OBJECTID": 623, "Coating": "None", "Constructi": "Brick/Mortar", "Date_Inspe": "00/00/00", "Depth": 0.0, "Infiltrati": "No", "Inflow": "No", "Lift_Stati": 5.0, "Manhole_Id": "LS5-056", "Rating": "Good", "SHAWN": 0.0, "LAYER": "SANMH", "FeatId1": 388.0 }, "geometry": { "type": "Point", "coordinates": [ -80.607356521411958, 28.360973323848604 ] } },
    { "type": "Feature", "properties": { "OBJECTID": 624, "Coating": "None", "Constructi": "Brick/Mortar", "Date_Inspe": "00/00/00", "Depth": 0.0, "Infiltrati": "No", "Inflow": "No", "Lift_Stati": 5.0, "Manhole_Id": "LS5-057", "Rating": "Good", "SHAWN": 0.0, "LAYER": "SANMH", "FeatId1": 387.0 }, "geometry": { "type": "Point", "coordinates": [ -80.606437910308259, 28.360851614239785 ] } },
    { "type": "Feature", "properties": { "OBJECTID": 625, "Coating": "None", "Constructi": "Brick/Mortar", "Date_Inspe": "00/00/00", "Depth": 0.0, "Infiltrati": "No", "Inflow": "No", "Lift_Stati": 5.0, "Manhole_Id": "LS5-058", "Rating": "Good", "SHAWN": 0.0, "LAYER": "SANMH", "FeatId1": 386.0 }, "geometry": { "type": "Point", "coordinates": [ -80.605024888343578, 28.360673311215127 ] } },
    { "type": "Feature", "properties": { "OBJECTID": 626, "Coating": "None", "Constructi": "Brick/Mortar", "Date_Inspe": "00/00/00", "Depth": 0.0, "Infiltrati": "No", "Inflow": "No", "Lift_Stati": 5.0, "Manhole_Id": "LS5-059", "Rating": "Good", "SHAWN": 0.0, "LAYER": "SANMH", "FeatId1": 385.0 }, "geometry": { "type": "Point", "coordinates": [ -80.604879517839279, 28.361561569801413 ] } },
    { "type": "Feature", "properties": { "OBJECTID": 627, "Coating": "None", "Constructi": "Brick/Mortar", "Date_Inspe": "00/00/00", "Depth": 0.0, "Infiltrati": "No", "Inflow": "No", "Lift_Stati": 5.0, "Manhole_Id": "LS5-060", "Rating": "Good", "SHAWN": 0.0, "LAYER": "SANMH", "FeatId1": 384.0 }, "geometry": { "type": "Point", "coordinates": [ -80.605960658909197, 28.361698439342831 ] } },
    { "type": "Feature", "properties": { "OBJECTID": 628, "Coating": "None", "Constructi": "Brick/Mortar", "Date_Inspe": "00/00/00", "Depth": 0.0, "Infiltrati": "No", "Inflow": "No", "Lift_Stati": 5.0, "Manhole_Id": "LS5-061", "Rating": "Good", "SHAWN": 0.0, "LAYER": "SANMH", "FeatId1": 383.0 }, "geometry": { "type": "Point", "coordinates": [ -80.60703513569814, 28.361832976488699 ] } },
    { "type": "Feature", "properties": { "OBJECTID": 629, "Coating": "None", "Constructi": "Brick/Mortar", "Date_Inspe": "00/00/00", "Depth": 0.0, "Infiltrati": "No", "Inflow": "No", "Lift_Stati": 5.0, "Manhole_Id": "LS5-064", "Rating": "Good", "SHAWN": 0.0, "LAYER": "SANMH", "FeatId1": 382.0 }, "geometry": { "type": "Point", "coordinates": [ -80.606907282426903, 28.362734598422733 ] } },
    { "type": "Feature", "properties": { "OBJECTID": 630, "Coating": "None", "Constructi": "Brick/Mortar", "Date_Inspe": "00/00/00", "Depth": 0.0, "Infiltrati": "No", "Inflow": "No", "Lift_Stati": 5.0, "Manhole_Id": "LS5-063", "Rating": "Good", "SHAWN": 0.0, "LAYER": "SANMH", "FeatId1": 381.0 }, "geometry": { "type": "Point", "coordinates": [ -80.605813119488772, 28.362593671437576 ] } },
    { "type": "Feature", "properties": { "OBJECTID": 631, "Coating": "None", "Constructi": "Brick/Mortar", "Date_Inspe": "00/00/00", "Depth": 0.0, "Infiltrati": "No", "Inflow": "No", "Lift_Stati": 5.0, "Manhole_Id": "LS5-062", "Rating": "Good", "SHAWN": 0.0, "LAYER": "SANMH", "FeatId1": 380.0 }, "geometry": { "type": "Point", "coordinates": [ -80.604736901740068, 28.362453819111266 ] } },
    { "type": "Feature", "properties": { "OBJECTID": 632, "Coating": "None", "Constructi": "Brick/Mortar", "Date_Inspe": "00/00/00", "Depth": 0.0, "Infiltrati": "No", "Inflow": "No", "Lift_Stati": 5.0, "Manhole_Id": "LS5-065", "Rating": "Good", "SHAWN": 0.0, "LAYER": "SANMH", "FeatId1": 379.0 }, "geometry": { "type": "Point", "coordinates": [ -80.604593532176253, 28.363322165994791 ] } },
    { "type": "Feature", "properties": { "OBJECTID": 633, "Coating": "None", "Constructi": "Brick/Mortar", "Date_Inspe": "00/00/00", "Depth": 0.0, "Infiltrati": "No", "Inflow": "No", "Lift_Stati": 5.0, "Manhole_Id": "LS5-066", "Rating": "Good", "SHAWN": 0.0, "LAYER": "SANMH", "FeatId1": 378.0 }, "geometry": { "type": "Point", "coordinates": [ -80.605674264049, 28.363454950814056 ] } },
    { "type": "Feature", "properties": { "OBJECTID": 634, "Coating": "None", "Constructi": "Brick/Mortar", "Date_Inspe": "00/00/00", "Depth": 0.0, "Infiltrati": "No", "Inflow": "No", "Lift_Stati": 8.0, "Manhole_Id": "LS8-009", "Rating": "Good", "SHAWN": 0.0, "LAYER": "SANMH", "FeatId1": 377.0 }, "geometry": { "type": "Point", "coordinates": [ -80.609368514941366, 28.369348963284931 ] } },
    { "type": "Feature", "properties": { "OBJECTID": 635, "Coating": "None", "Constructi": "Brick/Mortar", "Date_Inspe": "00/00/00", "Depth": 0.0, "Infiltrati": "No", "Inflow": "No", "Lift_Stati": 8.0, "Manhole_Id": "LS8-008", "Rating": "Good", "SHAWN": 0.0, "LAYER": "SANMH", "FeatId1": 376.0 }, "geometry": { "type": "Point", "coordinates": [ -80.610631529788662, 28.369410908694782 ] } },
    { "type": "Feature", "properties": { "OBJECTID": 636, "Coating": "None", "Constructi": "Brick/Mortar", "Date_Inspe": "00/00/00", "Depth": 0.0, "Infiltrati": "No", "Inflow": "No", "Lift_Stati": 8.0, "Manhole_Id": "LS8-007", "Rating": "Good", "SHAWN": 0.0, "LAYER": "SANMH", "FeatId1": 375.0 }, "geometry": { "type": "Point", "coordinates": [ -80.611853584889872, 28.369577193102465 ] } },
    { "type": "Feature", "properties": { "OBJECTID": 637, "Coating": "None", "Constructi": "Brick/Mortar", "Date_Inspe": "00/00/00", "Depth": 0.0, "Infiltrati": "No", "Inflow": "No", "Lift_Stati": 8.0, "Manhole_Id": "LS8-006", "Rating": "Good", "SHAWN": 0.0, "LAYER": "SANMH", "FeatId1": 374.0 }, "geometry": { "type": "Point", "coordinates": [ -80.611671509748788, 28.370055630939685 ] } },
    { "type": "Feature", "properties": { "OBJECTID": 638, "Coating": "None", "Constructi": "Brick/Mortar", "Date_Inspe": "00/00/00", "Depth": 0.0, "Infiltrati": "No", "Inflow": "No", "Lift_Stati": 5.0, "Manhole_Id": "LS5-039", "Rating": "Good", "SHAWN": 0.0, "LAYER": "SANMH", "FeatId1": 373.0 }, "geometry": { "type": "Point", "coordinates": [ -80.608791722777511, 28.362083614589636 ] } },
    { "type": "Feature", "properties": { "OBJECTID": 639, "Coating": "None", "Constructi": "Brick/Mortar", "Date_Inspe": "00/00/00", "Depth": 0.0, "Infiltrati": "No", "Inflow": "No", "Lift_Stati": 3.0, "Manhole_Id": "LS3-031", "Rating": "Good", "SHAWN": 0.0, "LAYER": "SANMH", "FeatId1": 372.0 }, "geometry": { "type": "Point", "coordinates": [ -80.608872678406655, 28.290240535475238 ] } },
    { "type": "Feature", "properties": { "OBJECTID": 640, "Coating": "None", "Constructi": "Brick/Mortar", "Date_Inspe": "02/11/09", "Depth": 7.5, "Infiltrati": "No", "Inflow": "No", "Lift_Stati": 3.0, "Manhole_Id": "LS3-023", "Rating": "Good", "SHAWN": 0.0, "LAYER": "SANMH", "FeatId1": 371.0 }, "geometry": { "type": "Point", "coordinates": [ -80.608914332370006, 28.292642533575464 ] } },
    { "type": "Feature", "properties": { "OBJECTID": 641, "Coating": "None", "Constructi": "Brick/Mortar", "Date_Inspe": "02/11/09", "Depth": 5.8, "Infiltrati": "Probable", "Inflow": "No", "Lift_Stati": 3.0, "Manhole_Id": "LS3-019", "Rating": "Fair", "SHAWN": 0.0, "LAYER": "SANMH", "FeatId1": 370.0 }, "geometry": { "type": "Point", "coordinates": [ -80.608982606021499, 28.29601032917979 ] } },
    { "type": "Feature", "properties": { "OBJECTID": 642, "Coating": "None", "Constructi": "Brick/Mortar", "Date_Inspe": "02/11/09", "Depth": 3.75, "Infiltrati": "No", "Inflow": "No", "Lift_Stati": 3.0, "Manhole_Id": "LS3-017", "Rating": "Good", "SHAWN": 0.0, "LAYER": "SANMH", "FeatId1": 369.0 }, "geometry": { "type": "Point", "coordinates": [ -80.609027914405132, 28.29784538516288 ] } },
    { "type": "Feature", "properties": { "OBJECTID": 643, "Coating": "None", "Constructi": "Brick/Mortar", "Date_Inspe": "02/09/09", "Depth": 4.66, "Infiltrati": "No", "Inflow": "No", "Lift_Stati": 3.0, "Manhole_Id": "LS3-025", "Rating": "Good", "SHAWN": 0.0, "LAYER": "SANMH", "FeatId1": 368.0 }, "geometry": { "type": "Point", "coordinates": [ -80.608869010543856, 28.291243993970625 ] } },
    { "type": "Feature", "properties": { "OBJECTID": 644, "Coating": "None", "Constructi": "Brick/Mortar", "Date_Inspe": "02/11/09", "Depth": 6.0, "Infiltrati": "Probable", "Inflow": "No", "Lift_Stati": 3.0, "Manhole_Id": "LS3-021", "Rating": "Fair", "SHAWN": 0.0, "LAYER": "SANMH", "FeatId1": 367.0 }, "geometry": { "type": "Point", "coordinates": [ -80.608968443960038, 28.29433120953729 ] } },
    { "type": "Feature", "properties": { "OBJECTID": 645, "Coating": "Spectrashield", "Constructi": "Brick/Mortar", "Date_Inspe": "09/11/13", "Depth": 6.4, "Infiltrati": "No", "Inflow": "No", "Lift_Stati": 4.0, "Manhole_Id": "LS4-014", "Rating": "Good", "SHAWN": 0.0, "LAYER": "SANMH", "FeatId1": 366.0 }, "geometry": { "type": "Point", "coordinates": [ -80.613961904104812, 28.327872040952876 ] } },
    { "type": "Feature", "properties": { "OBJECTID": 646, "Coating": "None", "Constructi": "Brick/Mortar", "Date_Inspe": "03/23/09", "Depth": 3.5, "Infiltrati": "Probable", "Inflow": "No", "Lift_Stati": 6.0, "Manhole_Id": "LS6-084", "Rating": "Fair", "SHAWN": 0.0, "LAYER": "SANMH", "FeatId1": 365.0 }, "geometry": { "type": "Point", "coordinates": [ -80.60851291419678, 28.305059271066828 ] } },
    { "type": "Feature", "properties": { "OBJECTID": 647, "Coating": "None", "Constructi": "Brick/Mortar", "Date_Inspe": "00/00/00", "Depth": 0.0, "Infiltrati": "No", "Inflow": "No", "Lift_Stati": 6.0, "Manhole_Id": "LS6-086", "Rating": "Good", "SHAWN": 0.0, "LAYER": "SANMH", "FeatId1": 364.0 }, "geometry": { "type": "Point", "coordinates": [ -80.608570909583491, 28.306900969182553 ] } },
    { "type": "Feature", "properties": { "OBJECTID": 648, "Coating": "None", "Constructi": "Brick/Mortar", "Date_Inspe": "00/00/00", "Depth": 0.0, "Infiltrati": "No", "Inflow": "No", "Lift_Stati": 6.0, "Manhole_Id": "LS6-087", "Rating": "Good", "SHAWN": 0.0, "LAYER": "SANMH", "FeatId1": 363.0 }, "geometry": { "type": "Point", "coordinates": [ -80.608583122361736, 28.307003765148302 ] } },
    { "type": "Feature", "properties": { "OBJECTID": 649, "Coating": "Spectrashield", "Constructi": "Brick/Mortar", "Date_Inspe": "04/13/09", "Depth": 3.5, "Infiltrati": "Yes", "Inflow": "No", "Lift_Stati": 1.0, "Manhole_Id": "LS1-001", "Rating": "Good", "SHAWN": 0.0, "LAYER": "SANMH", "FeatId1": 362.0 }, "geometry": { "type": "Point", "coordinates": [ -80.608603126575659, 28.30787076413263 ] } },
    { "type": "Feature", "properties": { "OBJECTID": 650, "Coating": "None", "Constructi": "Brick/Mortar", "Date_Inspe": "00/00/00", "Depth": 0.0, "Infiltrati": "No", "Inflow": "No", "Lift_Stati": 6.0, "Manhole_Id": "LS6-079", "Rating": "Good", "SHAWN": 0.0, "LAYER": "SANMH", "FeatId1": 361.0 }, "geometry": { "type": "Point", "coordinates": [ -80.609314013449392, 28.308439997350089 ] } },
    { "type": "Feature", "properties": { "OBJECTID": 651, "Coating": "Spectrashield", "Constructi": "Brick/Mortar", "Date_Inspe": "04/13/09", "Depth": 3.75, "Infiltrati": "Yes", "Inflow": "Yes", "Lift_Stati": 1.0, "Manhole_Id": "LS1-002", "Rating": "Good", "SHAWN": 0.0, "LAYER": "SANMH", "FeatId1": 360.0 }, "geometry": { "type": "Point", "coordinates": [ -80.608641298794169, 28.308670584960861 ] } },
    { "type": "Feature", "properties": { "OBJECTID": 652, "Coating": "None", "Constructi": "Brick/Mortar", "Date_Inspe": "00/00/00", "Depth": 0.0, "Infiltrati": "No", "Inflow": "No", "Lift_Stati": 1.0, "Manhole_Id": "LS1-003", "Rating": "Good", "SHAWN": 0.0, "LAYER": "SANMH", "FeatId1": 359.0 }, "geometry": { "type": "Point", "coordinates": [ -80.608670697399745, 28.309113864345353 ] } },
    { "type": "Feature", "properties": { "OBJECTID": 653, "Coating": "None", "Constructi": "Brick/Mortar", "Date_Inspe": "00/00/00", "Depth": 0.0, "Infiltrati": "No", "Inflow": "No", "Lift_Stati": 6.0, "Manhole_Id": "LS6-151", "Rating": "Good", "SHAWN": 0.0, "LAYER": "SANMH", "FeatId1": 358.0 }, "geometry": { "type": "Point", "coordinates": [ -80.609352555895754, 28.309269473576123 ] } },
    { "type": "Feature", "properties": { "OBJECTID": 654, "Coating": "None", "Constructi": "Brick/Mortar", "Date_Inspe": "03/25/09", "Depth": 3.16, "Infiltrati": "Probable", "Inflow": "No", "Lift_Stati": 6.0, "Manhole_Id": "LS6-071", "Rating": "Poor", "SHAWN": 0.0, "LAYER": "SANMH", "FeatId1": 357.0 }, "geometry": { "type": "Point", "coordinates": [ -80.609108519481978, 28.301722518568354 ] } },
    { "type": "Feature", "properties": { "OBJECTID": 655, "Coating": "None", "Constructi": "Brick/Mortar", "Date_Inspe": "05/11/09", "Depth": 2.8, "Infiltrati": "No", "Inflow": "No", "Lift_Stati": 1.0, "Manhole_Id": "LS1-099", "Rating": "Good", "SHAWN": 0.0, "LAYER": "SANMH", "FeatId1": 356.0 }, "geometry": { "type": "Point", "coordinates": [ -80.609407900220887, 28.326538923294123 ] } },
    { "type": "Feature", "properties": { "OBJECTID": 656, "Coating": "Mortar", "Constructi": "Brick/Mortar", "Date_Inspe": "07/21/09", "Depth": 1.83, "Infiltrati": "No", "Inflow": "No", "Lift_Stati": 4.0, "Manhole_Id": "LS4-085", "Rating": "Good", "SHAWN": 0.0, "LAYER": "SANMH", "FeatId1": 355.0 }, "geometry": { "type": "Point", "coordinates": [ -80.609768761528031, 28.327465659777367 ] } },
    { "type": "Feature", "properties": { "OBJECTID": 657, "Coating": "None", "Constructi": "Brick/Mortar", "Date_Inspe": "07/09/09", "Depth": 2.0, "Infiltrati": "No", "Inflow": "No", "Lift_Stati": 4.0, "Manhole_Id": "LS4-082", "Rating": "Good", "SHAWN": 0.0, "LAYER": "SANMH", "FeatId1": 354.0 }, "geometry": { "type": "Point", "coordinates": [ -80.611512223914346, 28.327538493319203 ] } },
    { "type": "Feature", "properties": { "OBJECTID": 658, "Coating": "None", "Constructi": "Brick/Mortar", "Date_Inspe": "07/09/09", "Depth": 3.08, "Infiltrati": "Probable", "Inflow": "Yes", "Lift_Stati": 4.0, "Manhole_Id": "LS4-084", "Rating": "Fair", "SHAWN": 0.0, "LAYER": "SANMH", "FeatId1": 353.0 }, "geometry": { "type": "Point", "coordinates": [ -80.611593661836295, 28.328415205074311 ] } },
    { "type": "Feature", "properties": { "OBJECTID": 659, "Coating": "Mortar", "Constructi": "Brick/Mortar", "Date_Inspe": "07/22/09", "Depth": 1.66, "Infiltrati": "No", "Inflow": "No", "Lift_Stati": 4.0, "Manhole_Id": "LS4-086", "Rating": "Good", "SHAWN": 0.0, "LAYER": "SANMH", "FeatId1": 352.0 }, "geometry": { "type": "Point", "coordinates": [ -80.610011777122097, 28.328134997605698 ] } },
    { "type": "Feature", "properties": { "OBJECTID": 660, "Coating": "Mortar", "Constructi": "Brick/Mortar", "Date_Inspe": "07/22/09", "Depth": 1.83, "Infiltrati": "No", "Inflow": "No", "Lift_Stati": 4.0, "Manhole_Id": "LS4-087", "Rating": "Good", "SHAWN": 0.0, "LAYER": "SANMH", "FeatId1": 351.0 }, "geometry": { "type": "Point", "coordinates": [ -80.610212719374985, 28.328798987368849 ] } },
    { "type": "Feature", "properties": { "OBJECTID": 661, "Coating": "None", "Constructi": "Brick/Mortar", "Date_Inspe": "07/22/09", "Depth": 2.08, "Infiltrati": "No", "Inflow": "No", "Lift_Stati": 4.0, "Manhole_Id": "LS4-088", "Rating": "Good", "SHAWN": 0.0, "LAYER": "SANMH", "FeatId1": 350.0 }, "geometry": { "type": "Point", "coordinates": [ -80.610369821859749, 28.329487072944087 ] } },
    { "type": "Feature", "properties": { "OBJECTID": 662, "Coating": "None", "Constructi": "Brick/Mortar", "Date_Inspe": "07/22/09", "Depth": 2.58, "Infiltrati": "No", "Inflow": "No", "Lift_Stati": 4.0, "Manhole_Id": "LS4-089", "Rating": "Good", "SHAWN": 0.0, "LAYER": "SANMH", "FeatId1": 349.0 }, "geometry": { "type": "Point", "coordinates": [ -80.610477489986337, 28.330186414064492 ] } },
    { "type": "Feature", "properties": { "OBJECTID": 663, "Coating": "None", "Constructi": "Brick/Mortar", "Date_Inspe": "09/11/13", "Depth": 4.4, "Infiltrati": "No", "Inflow": "No", "Lift_Stati": 4.0, "Manhole_Id": "LS4-091", "Rating": "Good", "SHAWN": 0.0, "LAYER": "SANMH", "FeatId1": 348.0 }, "geometry": { "type": "Point", "coordinates": [ -80.610526151978888, 28.331586995238993 ] } },
    { "type": "Feature", "properties": { "OBJECTID": 664, "Coating": "None", "Constructi": "Brick/Mortar", "Date_Inspe": "07/31/09", "Depth": 5.75, "Infiltrati": "Probable", "Inflow": "No", "Lift_Stati": 4.0, "Manhole_Id": "LS4-092", "Rating": "Fair", "SHAWN": 0.0, "LAYER": "SANMH", "FeatId1": 347.0 }, "geometry": { "type": "Point", "coordinates": [ -80.610502251767628, 28.331954869652336 ] } },
    { "type": "Feature", "properties": { "OBJECTID": 665, "Coating": "Mortar", "Constructi": "Brick/Mortar", "Date_Inspe": "07/31/09", "Depth": 7.0, "Infiltrati": "No", "Inflow": "No", "Lift_Stati": 4.0, "Manhole_Id": "LS4-093", "Rating": "Good", "SHAWN": 0.0, "LAYER": "SANMH", "FeatId1": 346.0 }, "geometry": { "type": "Point", "coordinates": [ -80.610460946563634, 28.332557968338403 ] } },
    { "type": "Feature", "properties": { "OBJECTID": 666, "Coating": "Fiberglass", "Constructi": "Brick/Mortar", "Date_Inspe": "08/27/09", "Depth": 7.58, "Infiltrati": "No", "Inflow": "No", "Lift_Stati": 4.0, "Manhole_Id": "LS4-094", "Rating": "Good", "SHAWN": 0.0, "LAYER": "SANMH", "FeatId1": 345.0 }, "geometry": { "type": "Point", "coordinates": [ -80.610438390940061, 28.332905180530631 ] } },
    { "type": "Feature", "properties": { "OBJECTID": 667, "Coating": "Fiberglass", "Constructi": "Brick/Mortar", "Date_Inspe": "08/22/09", "Depth": 8.0, "Infiltrati": "No", "Inflow": "No", "Lift_Stati": 4.0, "Manhole_Id": "LS4-095", "Rating": "Good", "SHAWN": 0.0, "LAYER": "SANMH", "FeatId1": 344.0 }, "geometry": { "type": "Point", "coordinates": [ -80.610372784550989, 28.333883826869386 ] } },
    { "type": "Feature", "properties": { "OBJECTID": 668, "Coating": "Mortar", "Constructi": "Brick/Mortar", "Date_Inspe": "08/28/09", "Depth": 8.33, "Infiltrati": "No", "Inflow": "No", "Lift_Stati": 4.0, "Manhole_Id": "LS4-096", "Rating": "Good", "SHAWN": 0.0, "LAYER": "SANMH", "FeatId1": 343.0 }, "geometry": { "type": "Point", "coordinates": [ -80.610312401108757, 28.334789382759897 ] } },
    { "type": "Feature", "properties": { "OBJECTID": 669, "Coating": "Mortar", "Constructi": "Brick/Mortar", "Date_Inspe": "08/31/09", "Depth": 6.66, "Infiltrati": "No", "Inflow": "No", "Lift_Stati": 4.0, "Manhole_Id": "LS4-097", "Rating": "Good", "SHAWN": 0.0, "LAYER": "SANMH", "FeatId1": 342.0 }, "geometry": { "type": "Point", "coordinates": [ -80.610249859090132, 28.335691977081929 ] } },
    { "type": "Feature", "properties": { "OBJECTID": 670, "Coating": "Mortar", "Constructi": "Brick/Mortar", "Date_Inspe": "08/31/09", "Depth": 6.08, "Infiltrati": "No", "Inflow": "No", "Lift_Stati": 4.0, "Manhole_Id": "LS4-098", "Rating": "Good", "SHAWN": 0.0, "LAYER": "SANMH", "FeatId1": 341.0 }, "geometry": { "type": "Point", "coordinates": [ -80.610189280470166, 28.336600650683124 ] } },
    { "type": "Feature", "properties": { "OBJECTID": 671, "Coating": "Mortar", "Constructi": "Brick/Mortar", "Date_Inspe": "09/01/09", "Depth": 5.0, "Infiltrati": "No", "Inflow": "No", "Lift_Stati": 4.0, "Manhole_Id": "LS4-099", "Rating": "Good", "SHAWN": 0.0, "LAYER": "SANMH", "FeatId1": 340.0 }, "geometry": { "type": "Point", "coordinates": [ -80.610132025338501, 28.337471675199527 ] } },
    { "type": "Feature", "properties": { "OBJECTID": 672, "Coating": "Mortar", "Constructi": "Brick/Mortar", "Date_Inspe": "09/03/09", "Depth": 4.0, "Infiltrati": "No", "Inflow": "No", "Lift_Stati": 4.0, "Manhole_Id": "LS4-100", "Rating": "Good", "SHAWN": 0.0, "LAYER": "SANMH", "FeatId1": 339.0 }, "geometry": { "type": "Point", "coordinates": [ -80.610076094905153, 28.338327378486081 ] } },
    { "type": "Feature", "properties": { "OBJECTID": 673, "Coating": "None", "Constructi": "Brick/Mortar", "Date_Inspe": "00/00/00", "Depth": 0.0, "Infiltrati": "No", "Inflow": "No", "Lift_Stati": 4.0, "Manhole_Id": "LS4-146", "Rating": "Good", "SHAWN": 0.0, "LAYER": "SANMH", "FeatId1": 338.0 }, "geometry": { "type": "Point", "coordinates": [ -80.60998119632626, 28.339883834750317 ] } },
    { "type": "Feature", "properties": { "OBJECTID": 674, "Coating": "None", "Constructi": "Precast", "Date_Inspe": "05/11/09", "Depth": 6.08, "Infiltrati": "No", "Inflow": "No", "Lift_Stati": 1.0, "Manhole_Id": "LS1-097", "Rating": "Good", "SHAWN": 0.0, "LAYER": "SANMH", "FeatId1": 337.0 }, "geometry": { "type": "Point", "coordinates": [ -80.609128897235394, 28.325731412718543 ] } },
    { "type": "Feature", "properties": { "OBJECTID": 675, "Coating": "None", "Constructi": "Brick/Mortar", "Date_Inspe": "05/12/09", "Depth": 5.67, "Infiltrati": "Probable", "Inflow": "No", "Lift_Stati": 1.0, "Manhole_Id": "LS1-098", "Rating": "Fair", "SHAWN": 0.0, "LAYER": "SANMH", "FeatId1": 336.0 }, "geometry": { "type": "Point", "coordinates": [ -80.60933886420375, 28.325713112975958 ] } },
    { "type": "Feature", "properties": { "OBJECTID": 676, "Coating": "Spectrashield", "Constructi": "Brick/Mortar", "Date_Inspe": "05/12/09", "Depth": 3.67, "Infiltrati": "Probable", "Inflow": "No", "Lift_Stati": 1.0, "Manhole_Id": "LS1-092", "Rating": "Good", "SHAWN": 0.0, "LAYER": "SANMH", "FeatId1": 335.0 }, "geometry": { "type": "Point", "coordinates": [ -80.609732052726997, 28.325682198551817 ] } },
    { "type": "Feature", "properties": { "OBJECTID": 677, "Coating": "Mortar", "Constructi": "Brick/Mortar", "Date_Inspe": "05/12/09", "Depth": 4.08, "Infiltrati": "No", "Inflow": "No", "Lift_Stati": 1.0, "Manhole_Id": "LS1-091", "Rating": "Fair", "SHAWN": 0.0, "LAYER": "SANMH", "FeatId1": 334.0 }, "geometry": { "type": "Point", "coordinates": [ -80.609943354100906, 28.325682138535733 ] } },
    { "type": "Feature", "properties": { "OBJECTID": 678, "Coating": "None", "Constructi": "Brick/Mortar", "Date_Inspe": "04/21/09", "Depth": 3.92, "Infiltrati": "Probable", "Inflow": "No", "Lift_Stati": 1.0, "Manhole_Id": "LS1-015", "Rating": "Fair", "SHAWN": 0.0, "LAYER": "SANMH", "FeatId1": 333.0 }, "geometry": { "type": "Point", "coordinates": [ -80.60936957296866, 28.309706382729452 ] } },
    { "type": "Feature", "properties": { "OBJECTID": 679, "Coating": "Spectrashield", "Constructi": "Brick/Mortar", "Date_Inspe": "04/14/09", "Depth": 6.0, "Infiltrati": "Yes", "Inflow": "No", "Lift_Stati": 1.0, "Manhole_Id": "LS1-005", "Rating": "Good", "SHAWN": 0.0, "LAYER": "SANMH", "FeatId1": 332.0 }, "geometry": { "type": "Point", "coordinates": [ -80.608712768596504, 28.310065784209932 ] } },
    { "type": "Feature", "properties": { "OBJECTID": 680, "Coating": "None", "Constructi": "Brick/Mortar", "Date_Inspe": "04/16/09", "Depth": 2.4, "Infiltrati": "Probable", "Inflow": "Yes", "Lift_Stati": 1.0, "Manhole_Id": "LS1-016", "Rating": "Fair", "SHAWN": 0.0, "LAYER": "SANMH", "FeatId1": 331.0 }, "geometry": { "type": "Point", "coordinates": [ -80.609415021470298, 28.310529636864647 ] } },
    { "type": "Feature", "properties": { "OBJECTID": 681, "Coating": "Spectrashield", "Constructi": "Brick/Mortar", "Date_Inspe": "04/15/09", "Depth": 9.5, "Infiltrati": "Yes", "Inflow": "No", "Lift_Stati": 1.0, "Manhole_Id": "LS1-007", "Rating": "Good", "SHAWN": 0.0, "LAYER": "SANMH", "FeatId1": 330.0 }, "geometry": { "type": "Point", "coordinates": [ -80.608805998901275, 28.311229243142243 ] } },
    { "type": "Feature", "properties": { "OBJECTID": 682, "Coating": "None", "Constructi": "Precast", "Date_Inspe": "04/15/09", "Depth": 8.0, "Infiltrati": "No", "Inflow": "No", "Lift_Stati": 1.0, "Manhole_Id": "LS1-013", "Rating": "Good", "SHAWN": 0.0, "LAYER": "SANMH", "FeatId1": 329.0 }, "geometry": { "type": "Point", "coordinates": [ -80.609017878729247, 28.311221001527574 ] } },
    { "type": "Feature", "properties": { "OBJECTID": 683, "Coating": "None", "Constructi": "Brick/Mortar", "Date_Inspe": "04/15/09", "Depth": 4.58, "Infiltrati": "Probable", "Inflow": "No", "Lift_Stati": 1.0, "Manhole_Id": "LS1-014", "Rating": "Fair", "SHAWN": 0.0, "LAYER": "SANMH", "FeatId1": 328.0 }, "geometry": { "type": "Point", "coordinates": [ -80.609449303994253, 28.311213728066946 ] } },
    { "type": "Feature", "properties": { "OBJECTID": 684, "Coating": "None", "Constructi": "Precast", "Date_Inspe": "04/16/09", "Depth": 3.25, "Infiltrati": "No", "Inflow": "No", "Lift_Stati": 1.0, "Manhole_Id": "LS1-017", "Rating": "Good", "SHAWN": 0.0, "LAYER": "SANMH", "FeatId1": 327.0 }, "geometry": { "type": "Point", "coordinates": [ -80.609896444719695, 28.311209606814948 ] } },
    { "type": "Feature", "properties": { "OBJECTID": 685, "Coating": "None", "Constructi": "Brick/Mortar", "Date_Inspe": "09/11/13", "Depth": 3.08, "Infiltrati": "No", "Inflow": "No", "Lift_Stati": 1.0, "Manhole_Id": "LS1-018", "Rating": "Good", "SHAWN": 0.0, "LAYER": "SANMH", "FeatId1": 326.0 }, "geometry": { "type": "Point", "coordinates": [ -80.609925708596279, 28.311259336984602 ] } },
    { "type": "Feature", "properties": { "OBJECTID": 686, "Coating": "Spectrashield", "Constructi": "Brick/Mortar", "Date_Inspe": "04/20/09", "Depth": 8.08, "Infiltrati": "Yes", "Inflow": "Yes", "Lift_Stati": 1.0, "Manhole_Id": "LS1-008", "Rating": "Good", "SHAWN": 0.0, "LAYER": "SANMH", "FeatId1": 325.0 }, "geometry": { "type": "Point", "coordinates": [ -80.608808151745208, 28.312108616944808 ] } },
    { "type": "Feature", "properties": { "OBJECTID": 687, "Coating": "None", "Constructi": "Brick/Mortar", "Date_Inspe": "09/11/13", "Depth": 6.75, "Infiltrati": "No", "Inflow": "No", "Lift_Stati": 1.0, "Manhole_Id": "LS1-009", "Rating": "Good", "SHAWN": 0.0, "LAYER": "SANMH", "FeatId1": 324.0 }, "geometry": { "type": "Point", "coordinates": [ -80.608814190111971, 28.313069455897899 ] } },
    { "type": "Feature", "properties": { "OBJECTID": 688, "Coating": "None", "Constructi": "Brick/Mortar", "Date_Inspe": "04/15/09", "Depth": 5.5, "Infiltrati": "Probable", "Inflow": "No", "Lift_Stati": 1.0, "Manhole_Id": "LS1-010", "Rating": "Fair", "SHAWN": 0.0, "LAYER": "SANMH", "FeatId1": 323.0 }, "geometry": { "type": "Point", "coordinates": [ -80.608819963008372, 28.314040701876685 ] } },
    { "type": "Feature", "properties": { "OBJECTID": 689, "Coating": "None", "Constructi": "Brick/Mortar", "Date_Inspe": "04/15/09", "Depth": 4.66, "Infiltrati": "Probable", "Inflow": "No", "Lift_Stati": 1.0, "Manhole_Id": "LS1-011", "Rating": "Fair", "SHAWN": 0.0, "LAYER": "SANMH", "FeatId1": 322.0 }, "geometry": { "type": "Point", "coordinates": [ -80.60882453420227, 28.314724520023532 ] } },
    { "type": "Feature", "properties": { "OBJECTID": 690, "Coating": "None", "Constructi": "Brick/Mortar", "Date_Inspe": "04/20/09", "Depth": 3.5, "Infiltrati": "Probable", "Inflow": "No", "Lift_Stati": 1.0, "Manhole_Id": "LS1-020", "Rating": "Fair", "SHAWN": 0.0, "LAYER": "SANMH", "FeatId1": 321.0 }, "geometry": { "type": "Point", "coordinates": [ -80.608827340499516, 28.315053848387475 ] } },
    { "type": "Feature", "properties": { "OBJECTID": 691, "Coating": "Spectrashield", "Constructi": "Brick/Mortar", "Date_Inspe": "04/20/09", "Depth": 4.33, "Infiltrati": "Probable", "Inflow": "Yes", "Lift_Stati": 1.0, "Manhole_Id": "LS1-021", "Rating": "Good", "SHAWN": 0.0, "LAYER": "SANMH", "FeatId1": 320.0 }, "geometry": { "type": "Point", "coordinates": [ -80.608832732087393, 28.315864577887304 ] } },
    { "type": "Feature", "properties": { "OBJECTID": 692, "Coating": "None", "Constructi": "Brick/Mortar", "Date_Inspe": "04/21/09", "Depth": 4.16, "Infiltrati": "Probable", "Inflow": "Yes", "Lift_Stati": 1.0, "Manhole_Id": "LS1-029", "Rating": "Fair", "SHAWN": 0.0, "LAYER": "SANMH", "FeatId1": 319.0 }, "geometry": { "type": "Point", "coordinates": [ -80.609526169189465, 28.316796001973525 ] } },
    { "type": "Feature", "properties": { "OBJECTID": 693, "Coating": "Spectrashield", "Constructi": "Brick/Mortar", "Date_Inspe": "04/15/09", "Depth": 6.66, "Infiltrati": "Yes", "Inflow": "Yes", "Lift_Stati": 1.0, "Manhole_Id": "LS1-006", "Rating": "Good", "SHAWN": 0.0, "LAYER": "SANMH", "FeatId1": 318.0 }, "geometry": { "type": "Point", "coordinates": [ -80.608703584319073, 28.31058494411969 ] } },
    { "type": "Feature", "properties": { "OBJECTID": 694, "Coating": "None", "Constructi": "Brick/Mortar", "Date_Inspe": "08/01/13", "Depth": 3.66, "Infiltrati": "No", "Inflow": "No", "Lift_Stati": 4.0, "Manhole_Id": "LS4-151", "Rating": "Good", "SHAWN": 0.0, "LAYER": "SANMH", "FeatId1": 317.0 }, "geometry": { "type": "Point", "coordinates": [ -80.613210974766986, 28.34317375908299 ] } },
    { "type": "Feature", "properties": { "OBJECTID": 695, "Coating": "None", "Constructi": "Brick/Mortar", "Date_Inspe": "08/01/13", "Depth": 6.25, "Infiltrati": "No", "Inflow": "No", "Lift_Stati": 10.0, "Manhole_Id": "LS10-005", "Rating": "Good", "SHAWN": 0.0, "LAYER": "SANMH", "FeatId1": 316.0 }, "geometry": { "type": "Point", "coordinates": [ -80.614434934241828, 28.343535764479491 ] } },
    { "type": "Feature", "properties": { "OBJECTID": 696, "Coating": "Mortar", "Constructi": "Brick/Mortar", "Date_Inspe": "08/01/13", "Depth": 6.83, "Infiltrati": "No", "Inflow": "No", "Lift_Stati": 10.0, "Manhole_Id": "LS10-004", "Rating": "Good", "SHAWN": 0.0, "LAYER": "SANMH", "FeatId1": 315.0 }, "geometry": { "type": "Point", "coordinates": [ -80.615236495291157, 28.343454404680951 ] } },
    { "type": "Feature", "properties": { "OBJECTID": 697, "Coating": "None", "Constructi": "Brick/Mortar", "Date_Inspe": "08/01/13", "Depth": 4.83, "Infiltrati": "No", "Inflow": "No", "Lift_Stati": 10.0, "Manhole_Id": "LS10-003", "Rating": "Good", "SHAWN": 0.0, "LAYER": "SANMH", "FeatId1": 314.0 }, "geometry": { "type": "Point", "coordinates": [ -80.616471378963965, 28.343367948939424 ] } },
    { "type": "Feature", "properties": { "OBJECTID": 698, "Coating": "None", "Constructi": "Brick/Mortar", "Date_Inspe": "08/01/13", "Depth": 4.33, "Infiltrati": "No", "Inflow": "No", "Lift_Stati": 10.0, "Manhole_Id": "LS10-002", "Rating": "Good", "SHAWN": 0.0, "LAYER": "SANMH", "FeatId1": 313.0 }, "geometry": { "type": "Point", "coordinates": [ -80.617646741448979, 28.343004362095339 ] } },
    { "type": "Feature", "properties": { "OBJECTID": 699, "Coating": "None", "Constructi": "Brick/Mortar", "Date_Inspe": "12/13/10", "Depth": 3.5, "Infiltrati": "No", "Inflow": "No", "Lift_Stati": 10.0, "Manhole_Id": "LS10-001", "Rating": "Good", "SHAWN": 0.0, "LAYER": "SANMH", "FeatId1": 312.0 }, "geometry": { "type": "Point", "coordinates": [ -80.618714893745377, 28.342445931660624 ] } },
    { "type": "Feature", "properties": { "OBJECTID": 700, "Coating": "None", "Constructi": "Brick/Mortar", "Date_Inspe": "00/00/00", "Depth": 0.0, "Infiltrati": "No", "Inflow": "No", "Lift_Stati": 10.0, "Manhole_Id": "LS10-013", "Rating": "Good", "SHAWN": 0.0, "LAYER": "SANMH", "FeatId1": 311.0 }, "geometry": { "type": "Point", "coordinates": [ -80.617598935203475, 28.344733577790311 ] } },
    { "type": "Feature", "properties": { "OBJECTID": 701, "Coating": "None", "Constructi": "Brick/Mortar", "Date_Inspe": "00/00/00", "Depth": 0.0, "Infiltrati": "No", "Inflow": "No", "Lift_Stati": 10.0, "Manhole_Id": "LS10-011", "Rating": "Good", "SHAWN": 0.0, "LAYER": "SANMH", "FeatId1": 310.0 }, "geometry": { "type": "Point", "coordinates": [ -80.616397843056774, 28.345016836195658 ] } },
    { "type": "Feature", "properties": { "OBJECTID": 702, "Coating": "None", "Constructi": "Brick/Mortar", "Date_Inspe": "00/00/00", "Depth": 0.0, "Infiltrati": "No", "Inflow": "No", "Lift_Stati": 10.0, "Manhole_Id": "LS10-010", "Rating": "Good", "SHAWN": 0.0, "LAYER": "SANMH", "FeatId1": 309.0 }, "geometry": { "type": "Point", "coordinates": [ -80.615792472392997, 28.344978863644979 ] } },
    { "type": "Feature", "properties": { "OBJECTID": 703, "Coating": "None", "Constructi": "Brick/Mortar", "Date_Inspe": "00/00/00", "Depth": 0.0, "Infiltrati": "No", "Inflow": "No", "Lift_Stati": 10.0, "Manhole_Id": "LS10-009", "Rating": "Good", "SHAWN": 0.0, "LAYER": "SANMH", "FeatId1": 308.0 }, "geometry": { "type": "Point", "coordinates": [ -80.614549655046005, 28.344979599384061 ] } },
    { "type": "Feature", "properties": { "OBJECTID": 704, "Coating": "None", "Constructi": "Brick/Mortar", "Date_Inspe": "12/13/10", "Depth": 11.5, "Infiltrati": "No", "Inflow": "No", "Lift_Stati": 10.0, "Manhole_Id": "LS10-007", "Rating": "Good", "SHAWN": 0.0, "LAYER": "SANMH", "FeatId1": 307.0 }, "geometry": { "type": "Point", "coordinates": [ -80.613182393447531, 28.345032159228058 ] } },
    { "type": "Feature", "properties": { "OBJECTID": 705, "Coating": "None", "Constructi": "Brick/Mortar", "Date_Inspe": "08/01/13", "Depth": 11.6, "Infiltrati": "No", "Inflow": "No", "Lift_Stati": 10.0, "Manhole_Id": "LS10-006", "Rating": "Good", "SHAWN": 0.0, "LAYER": "SANMH", "FeatId1": 306.0 }, "geometry": { "type": "Point", "coordinates": [ -80.613408256387245, 28.344325950917757 ] } },
    { "type": "Feature", "properties": { "OBJECTID": 706, "Coating": "None", "Constructi": "Brick/Mortar", "Date_Inspe": "00/00/00", "Depth": 0.0, "Infiltrati": "No", "Inflow": "No", "Lift_Stati": 10.0, "Manhole_Id": "LS10-017", "Rating": "Good", "SHAWN": 0.0, "LAYER": "SANMH", "FeatId1": 305.0 }, "geometry": { "type": "Point", "coordinates": [ -80.619440557048208, 28.343840213241286 ] } },
    { "type": "Feature", "properties": { "OBJECTID": 707, "Coating": "None", "Constructi": "Brick/Mortar", "Date_Inspe": "00/00/00", "Depth": 0.0, "Infiltrati": "No", "Inflow": "No", "Lift_Stati": 10.0, "Manhole_Id": "LS10-016", "Rating": "Good", "SHAWN": 0.0, "LAYER": "SANMH", "FeatId1": 304.0 }, "geometry": { "type": "Point", "coordinates": [ -80.618507689822067, 28.344338023193849 ] } },
    { "type": "Feature", "properties": { "OBJECTID": 708, "Coating": "None", "Constructi": "Brick/Mortar", "Date_Inspe": "00/00/00", "Depth": 0.0, "Infiltrati": "No", "Inflow": "No", "Lift_Stati": 10.0, "Manhole_Id": "LS10-014", "Rating": "Good", "SHAWN": 0.0, "LAYER": "SANMH", "FeatId1": 303.0 }, "geometry": { "type": "Point", "coordinates": [ -80.62015726360066, 28.344991323540906 ] } },
    { "type": "Feature", "properties": { "OBJECTID": 709, "Coating": "None", "Constructi": "Brick/Mortar", "Date_Inspe": "00/00/00", "Depth": 0.0, "Infiltrati": "No", "Inflow": "No", "Lift_Stati": 10.0, "Manhole_Id": "LS10-015", "Rating": "Good", "SHAWN": 0.0, "LAYER": "SANMH", "FeatId1": 302.0 }, "geometry": { "type": "Point", "coordinates": [ -80.619038226392618, 28.345034279364196 ] } },
    { "type": "Feature", "properties": { "OBJECTID": 710, "Coating": "None", "Constructi": "Brick/Mortar", "Date_Inspe": "00/00/00", "Depth": 0.0, "Infiltrati": "No", "Inflow": "No", "Lift_Stati": 10.0, "Manhole_Id": "LS10-012", "Rating": "Good", "SHAWN": 0.0, "LAYER": "SANMH", "FeatId1": 301.0 }, "geometry": { "type": "Point", "coordinates": [ -80.61773605388592, 28.344931395111384 ] } },
    { "type": "Feature", "properties": { "OBJECTID": 711, "Coating": "None", "Constructi": "Brick/Mortar", "Date_Inspe": "03/24/09", "Depth": 11.0, "Infiltrati": "No", "Inflow": "No", "Lift_Stati": 10.0, "Manhole_Id": "LS10-018", "Rating": "Good", "SHAWN": 0.0, "LAYER": "SANMH", "FeatId1": 300.0 }, "geometry": { "type": "Point", "coordinates": [ -80.613642449996505, 28.343674799177826 ] } },
    { "type": "Feature", "properties": { "OBJECTID": 712, "Coating": "None", "Constructi": "Brick/Mortar", "Date_Inspe": "04/27/09", "Depth": 4.5, "Infiltrati": "Probable", "Inflow": "No", "Lift_Stati": 1.0, "Manhole_Id": "LS1-044", "Rating": "Good", "SHAWN": 0.0, "LAYER": "SANMH", "FeatId1": 299.0 }, "geometry": { "type": "Point", "coordinates": [ -80.610522783239134, 28.310443241723675 ] } },
    { "type": "Feature", "properties": { "OBJECTID": 713, "Coating": "None", "Constructi": "Brick/Mortar", "Date_Inspe": "04/27/09", "Depth": 3.5, "Infiltrati": "Probable", "Inflow": "No", "Lift_Stati": 1.0, "Manhole_Id": "LS1-045", "Rating": "Fair", "SHAWN": 0.0, "LAYER": "SANMH", "FeatId1": 298.0 }, "geometry": { "type": "Point", "coordinates": [ -80.610562185007979, 28.311267105347593 ] } },
    { "type": "Feature", "properties": { "OBJECTID": 714, "Coating": "Fiberglass", "Constructi": "Brick/Mortar", "Date_Inspe": "04/27/09", "Depth": 4.25, "Infiltrati": "Yes", "Inflow": "No", "Lift_Stati": 1.0, "Manhole_Id": "LS1-046", "Rating": "Poor", "SHAWN": 0.0, "LAYER": "SANMH", "FeatId1": 297.0 }, "geometry": { "type": "Point", "coordinates": [ -80.611080622652253, 28.311627369873353 ] } },
    { "type": "Feature", "properties": { "OBJECTID": 715, "Coating": "Fiberglass", "Constructi": "Brick/Mortar", "Date_Inspe": "04/22/09", "Depth": 3.7, "Infiltrati": "No", "Inflow": "No", "Lift_Stati": 1.0, "Manhole_Id": "LS1-038", "Rating": "Good", "SHAWN": 0.0, "LAYER": "SANMH", "FeatId1": 296.0 }, "geometry": { "type": "Point", "coordinates": [ -80.610104347079002, 28.312193009758804 ] } },
    { "type": "Feature", "properties": { "OBJECTID": 716, "Coating": "None", "Constructi": "Brick/Mortar", "Date_Inspe": "04/27/09", "Depth": 4.4, "Infiltrati": "Probable", "Inflow": "No", "Lift_Stati": 1.0, "Manhole_Id": "LS1-047", "Rating": "Fair", "SHAWN": 0.0, "LAYER": "SANMH", "FeatId1": 295.0 }, "geometry": { "type": "Point", "coordinates": [ -80.61108745368395, 28.312682016065189 ] } },
    { "type": "Feature", "properties": { "OBJECTID": 717, "Coating": "Fiberglass", "Constructi": "Brick/Mortar", "Date_Inspe": "04/22/09", "Depth": 4.75, "Infiltrati": "Probable", "Inflow": "No", "Lift_Stati": 1.0, "Manhole_Id": "LS1-039", "Rating": "Poor", "SHAWN": 0.0, "LAYER": "SANMH", "FeatId1": 294.0 }, "geometry": { "type": "Point", "coordinates": [ -80.610106951841217, 28.31307215738649 ] } },
    { "type": "Feature", "properties": { "OBJECTID": 718, "Coating": "Mortar", "Constructi": "Brick/Mortar", "Date_Inspe": "04/27/09", "Depth": 3.8, "Infiltrati": "No", "Inflow": "No", "Lift_Stati": 1.0, "Manhole_Id": "LS1-048", "Rating": "Good", "SHAWN": 0.0, "LAYER": "SANMH", "FeatId1": 293.0 }, "geometry": { "type": "Point", "coordinates": [ -80.611093815299384, 28.313746013811432 ] } },
    { "type": "Feature", "properties": { "OBJECTID": 719, "Coating": "Spectrashield", "Constructi": "Brick/Mortar", "Date_Inspe": "04/23/09", "Depth": 5.4, "Infiltrati": "Yes", "Inflow": "Yes", "Lift_Stati": 1.0, "Manhole_Id": "LS1-040", "Rating": "Good", "SHAWN": 0.0, "LAYER": "SANMH", "FeatId1": 292.0 }, "geometry": { "type": "Point", "coordinates": [ -80.610116891690396, 28.313962313095256 ] } },
    { "type": "Feature", "properties": { "OBJECTID": 720, "Coating": "None", "Constructi": "Brick/Mortar", "Date_Inspe": "04/22/09", "Depth": 4.5, "Infiltrati": "Probable", "Inflow": "No", "Lift_Stati": 1.0, "Manhole_Id": "LS1-033", "Rating": "Fair", "SHAWN": 0.0, "LAYER": "SANMH", "FeatId1": 291.0 }, "geometry": { "type": "Point", "coordinates": [ -80.609473167072366, 28.314841214560289 ] } },
    { "type": "Feature", "properties": { "OBJECTID": 721, "Coating": "None", "Constructi": "Brick/Mortar", "Date_Inspe": "04/22/09", "Depth": 3.08, "Infiltrati": "No", "Inflow": "No", "Lift_Stati": 1.0, "Manhole_Id": "LS1-034", "Rating": "Fair", "SHAWN": 0.0, "LAYER": "SANMH", "FeatId1": 290.0 }, "geometry": { "type": "Point", "coordinates": [ -80.609948124131051, 28.314837765684924 ] } },
    { "type": "Feature", "properties": { "OBJECTID": 722, "Coating": "Spectrashield", "Constructi": "Brick/Mortar", "Date_Inspe": "04/22/09", "Depth": 5.83, "Infiltrati": "Yes", "Inflow": "No", "Lift_Stati": 1.0, "Manhole_Id": "LS1-035", "Rating": "Good", "SHAWN": 0.0, "LAYER": "SANMH", "FeatId1": 289.0 }, "geometry": { "type": "Point", "coordinates": [ -80.610118149280908, 28.314833390375217 ] } },
    { "type": "Feature", "properties": { "OBJECTID": 723, "Coating": "Spectrashield", "Constructi": "Brick/Mortar", "Date_Inspe": "09/18/13", "Depth": 4.08, "Infiltrati": "Probable", "Inflow": "No", "Lift_Stati": 1.0, "Manhole_Id": "LS1-049", "Rating": "Good", "SHAWN": 0.0, "LAYER": "SANMH", "FeatId1": 288.0 }, "geometry": { "type": "Point", "coordinates": [ -80.611100533090806, 28.314807122120783 ] } },
    { "type": "Feature", "properties": { "OBJECTID": 724, "Coating": "Spectrashield", "Constructi": "Brick/Mortar", "Date_Inspe": "04/22/09", "Depth": 6.08, "Infiltrati": "Yes", "Inflow": "Yes", "Lift_Stati": 1.0, "Manhole_Id": "LS1-036", "Rating": "Good", "SHAWN": 0.0, "LAYER": "SANMH", "FeatId1": 287.0 }, "geometry": { "type": "Point", "coordinates": [ -80.610134156760935, 28.315014692184203 ] } },
    { "type": "Feature", "properties": { "OBJECTID": 725, "Coating": "None", "Constructi": "Brick/Mortar", "Date_Inspe": "00/00/00", "Depth": 11.0, "Infiltrati": "No", "Inflow": "No", "Lift_Stati": 1.0, "Manhole_Id": "LS1-183", "Rating": "Good", "SHAWN": 0.0, "LAYER": "SANMH", "FeatId1": 286.0 }, "geometry": { "type": "Point", "coordinates": [ -80.610136470497821, 28.315666660117113 ] } },
    { "type": "Feature", "properties": { "OBJECTID": 726, "Coating": "None", "Constructi": "Brick/Mortar", "Date_Inspe": "00/00/00", "Depth": 11.0, "Infiltrati": "No", "Inflow": "No", "Lift_Stati": 1.0, "Manhole_Id": "LS1-184", "Rating": "Good", "SHAWN": 0.0, "LAYER": "SANMH", "FeatId1": 285.0 }, "geometry": { "type": "Point", "coordinates": [ -80.610139723046785, 28.316138383464445 ] } },
    { "type": "Feature", "properties": { "OBJECTID": 727, "Coating": "Spectrashield", "Constructi": "Brick/Mortar", "Date_Inspe": "04/28/09", "Depth": 4.6, "Infiltrati": "Yes", "Inflow": "Yes", "Lift_Stati": 1.0, "Manhole_Id": "LS1-050", "Rating": "Good", "SHAWN": 0.0, "LAYER": "SANMH", "FeatId1": 284.0 }, "geometry": { "type": "Point", "coordinates": [ -80.611109946094402, 28.315860615637845 ] } },
    { "type": "Feature", "properties": { "OBJECTID": 728, "Coating": "Spectrashield", "Constructi": "Brick/Mortar", "Date_Inspe": "00/00/00", "Depth": 11.0, "Infiltrati": "No", "Inflow": "No", "Lift_Stati": 1.0, "Manhole_Id": "LS1-185", "Rating": "Good", "SHAWN": 0.0, "LAYER": "SANMH", "FeatId1": 283.0 }, "geometry": { "type": "Point", "coordinates": [ -80.610134417505563, 28.316646110297754 ] } },
    { "type": "Feature", "properties": { "OBJECTID": 729, "Coating": "Spectrashield", "Constructi": "Brick/Mortar", "Date_Inspe": "04/28/09", "Depth": 5.6, "Infiltrati": "No", "Inflow": "No", "Lift_Stati": 1.0, "Manhole_Id": "LS1-051", "Rating": "Good", "SHAWN": 0.0, "LAYER": "SANMH", "FeatId1": 282.0 }, "geometry": { "type": "Point", "coordinates": [ -80.61112018329483, 28.316919652786577 ] } },
    { "type": "Feature", "properties": { "OBJECTID": 730, "Coating": "None", "Constructi": "Brick/Mortar", "Date_Inspe": "00/00/00", "Depth": 11.0, "Infiltrati": "No", "Inflow": "No", "Lift_Stati": 1.0, "Manhole_Id": "LS1-186", "Rating": "Good", "SHAWN": 0.0, "LAYER": "SANMH", "FeatId1": 281.0 }, "geometry": { "type": "Point", "coordinates": [ -80.610138823948063, 28.317325376184076 ] } },
    { "type": "Feature", "properties": { "OBJECTID": 731, "Coating": "None", "Constructi": "Brick/Mortar", "Date_Inspe": "04/21/09", "Depth": 4.8, "Infiltrati": "Probable", "Inflow": "No", "Lift_Stati": 1.0, "Manhole_Id": "LS1-028", "Rating": "Fair", "SHAWN": 0.0, "LAYER": "SANMH", "FeatId1": 280.0 }, "geometry": { "type": "Point", "coordinates": [ -80.609522094488014, 28.31735369636846 ] } },
    { "type": "Feature", "properties": { "OBJECTID": 732, "Coating": "None", "Constructi": "Brick/Mortar", "Date_Inspe": "04/20/09", "Depth": 5.8, "Infiltrati": "Probable", "Inflow": "No", "Lift_Stati": 1.0, "Manhole_Id": "LS1-023", "Rating": "Fair", "SHAWN": 0.0, "LAYER": "SANMH", "FeatId1": 279.0 }, "geometry": { "type": "Point", "coordinates": [ -80.608831240443166, 28.317252996399418 ] } },
    { "type": "Feature", "properties": { "OBJECTID": 733, "Coating": "None", "Constructi": "Brick/Mortar", "Date_Inspe": "04/29/09", "Depth": 5.75, "Infiltrati": "No", "Inflow": "No", "Lift_Stati": 1.0, "Manhole_Id": "LS1-060", "Rating": "Good", "SHAWN": 0.0, "LAYER": "SANMH", "FeatId1": 278.0 }, "geometry": { "type": "Point", "coordinates": [ -80.608235952073557, 28.318458490741147 ] } },
    { "type": "Feature", "properties": { "OBJECTID": 734, "Coating": "None", "Constructi": "Brick/Mortar", "Date_Inspe": "04/29/09", "Depth": 5.08, "Infiltrati": "Probable", "Inflow": "No", "Lift_Stati": 1.0, "Manhole_Id": "LS1-059", "Rating": "Fair", "SHAWN": 0.0, "LAYER": "SANMH", "FeatId1": 277.0 }, "geometry": { "type": "Point", "coordinates": [ -80.60884006261314, 28.318460401171613 ] } },
    { "type": "Feature", "properties": { "OBJECTID": 735, "Coating": "None", "Constructi": "Brick/Mortar", "Date_Inspe": "09/11/13", "Depth": 6.9, "Infiltrati": "No", "Inflow": "No", "Lift_Stati": 1.0, "Manhole_Id": "LS1-024", "Rating": "Good", "SHAWN": 0.0, "LAYER": "SANMH", "FeatId1": 276.0 }, "geometry": { "type": "Point", "coordinates": [ -80.60883513152892, 28.317942083912737 ] } },
    { "type": "Feature", "properties": { "OBJECTID": 736, "Coating": "Spectrashield", "Constructi": "Brick/Mortar", "Date_Inspe": "04/20/09", "Depth": 8.0, "Infiltrati": "Yes", "Inflow": "No", "Lift_Stati": 1.0, "Manhole_Id": "LS1-025", "Rating": "Good", "SHAWN": 0.0, "LAYER": "SANMH", "FeatId1": 275.0 }, "geometry": { "type": "Point", "coordinates": [ -80.609033152421219, 28.317948092560044 ] } },
    { "type": "Feature", "properties": { "OBJECTID": 737, "Coating": "None", "Constructi": "Brick/Mortar", "Date_Inspe": "04/21/09", "Depth": 5.0, "Infiltrati": "Probable", "Inflow": "No", "Lift_Stati": 1.0, "Manhole_Id": "LS1-026", "Rating": "Fair", "SHAWN": 0.0, "LAYER": "SANMH", "FeatId1": 274.0 }, "geometry": { "type": "Point", "coordinates": [ -80.609523312975611, 28.317955782525967 ] } },
    { "type": "Feature", "properties": { "OBJECTID": 738, "Coating": "None", "Constructi": "Brick/Mortar", "Date_Inspe": "04/21/09", "Depth": 5.5, "Infiltrati": "Probable", "Inflow": "No", "Lift_Stati": 1.0, "Manhole_Id": "LS1-027", "Rating": "Fair", "SHAWN": 0.0, "LAYER": "SANMH", "FeatId1": 273.0 }, "geometry": { "type": "Point", "coordinates": [ -80.609970135333839, 28.317979290840501 ] } },
    { "type": "Feature", "properties": { "OBJECTID": 739, "Coating": "Spectrashield", "Constructi": "Brick/Mortar", "Date_Inspe": "04/21/09", "Depth": 7.67, "Infiltrati": "No", "Inflow": "No", "Lift_Stati": 1.0, "Manhole_Id": "LS1-030", "Rating": "Good", "SHAWN": 0.0, "LAYER": "SANMH", "FeatId1": 272.0 }, "geometry": { "type": "Point", "coordinates": [ -80.610141615362735, 28.317940848977365 ] } },
    { "type": "Feature", "properties": { "OBJECTID": 740, "Coating": "None", "Constructi": "Brick/Mortar", "Date_Inspe": "04/29/09", "Depth": 5.0, "Infiltrati": "Probable", "Inflow": "No", "Lift_Stati": 1.0, "Manhole_Id": "LS1-055", "Rating": "Fair", "SHAWN": 0.0, "LAYER": "SANMH", "FeatId1": 271.0 }, "geometry": { "type": "Point", "coordinates": [ -80.610561540723936, 28.317971409943219 ] } },
    { "type": "Feature", "properties": { "OBJECTID": 741, "Coating": "Spectrashield", "Constructi": "Brick/Mortar", "Date_Inspe": "04/28/09", "Depth": 6.7, "Infiltrati": "No", "Inflow": "No", "Lift_Stati": 1.0, "Manhole_Id": "LS1-052", "Rating": "Good", "SHAWN": 0.0, "LAYER": "SANMH", "FeatId1": 270.0 }, "geometry": { "type": "Point", "coordinates": [ -80.611131444864185, 28.317974065346068 ] } },
    { "type": "Feature", "properties": { "OBJECTID": 742, "Coating": "Fiberglass", "Constructi": "Brick/Mortar", "Date_Inspe": "04/29/09", "Depth": 11.0, "Infiltrati": "No", "Inflow": "No", "Lift_Stati": 1.0, "Manhole_Id": "LS1-056", "Rating": "Fair", "SHAWN": 0.0, "LAYER": "SANMH", "FeatId1": 269.0 }, "geometry": { "type": "Point", "coordinates": [ -80.611172183912814, 28.318202623167046 ] } },
    { "type": "Feature", "properties": { "OBJECTID": 743, "Coating": "Spectrashield", "Constructi": "Brick/Mortar", "Date_Inspe": "09/11/13", "Depth": 6.5, "Infiltrati": "No", "Inflow": "No", "Lift_Stati": 1.0, "Manhole_Id": "LS1-057", "Rating": "Good", "SHAWN": 0.0, "LAYER": "SANMH", "FeatId1": 268.0 }, "geometry": { "type": "Point", "coordinates": [ -80.611215904134141, 28.318205969165351 ] } },
    { "type": "Feature", "properties": { "OBJECTID": 744, "Coating": "None", "Constructi": "Precast", "Date_Inspe": "04/29/09", "Depth": 6.7, "Infiltrati": "No", "Inflow": "No", "Lift_Stati": 1.0, "Manhole_Id": "LS1-058", "Rating": "Good", "SHAWN": 0.0, "LAYER": "SANMH", "FeatId1": 267.0 }, "geometry": { "type": "Point", "coordinates": [ -80.611072006601873, 28.318298400262893 ] } },
    { "type": "Feature", "properties": { "OBJECTID": 745, "Coating": "None", "Constructi": "Brick/Mortar", "Date_Inspe": "00/00/00", "Depth": 7.0, "Infiltrati": "No", "Inflow": "No", "Lift_Stati": 1.0, "Manhole_Id": "LS1-187", "Rating": "Good", "SHAWN": 0.0, "LAYER": "SANMH", "FeatId1": 266.0 }, "geometry": { "type": "Point", "coordinates": [ -80.611220087189693, 28.318332346931793 ] } },
    { "type": "Feature", "properties": { "OBJECTID": 746, "Coating": "None", "Constructi": "Brick/Mortar", "Date_Inspe": "00/00/00", "Depth": 8.8, "Infiltrati": "No", "Inflow": "No", "Lift_Stati": 1.0, "Manhole_Id": "LS1-189", "Rating": "Good", "SHAWN": 0.0, "LAYER": "SANMH", "FeatId1": 265.0 }, "geometry": { "type": "Point", "coordinates": [ -80.611337768432591, 28.318390617745475 ] } },
    { "type": "Feature", "properties": { "OBJECTID": 747, "Coating": "None", "Constructi": "Brick/Mortar", "Date_Inspe": "12/13/10", "Depth": 11.0, "Infiltrati": "No", "Inflow": "No", "Lift_Stati": 10.0, "Manhole_Id": "LS10-008", "Rating": "Good", "SHAWN": 0.0, "LAYER": "SANMH", "FeatId1": 264.0 }, "geometry": { "type": "Point", "coordinates": [ -80.613204878783208, 28.345420913044542 ] } },
    { "type": "Feature", "properties": { "OBJECTID": 748, "Coating": "None", "Constructi": "Brick/Mortar", "Date_Inspe": "05/07/09", "Depth": 5.0, "Infiltrati": "No", "Inflow": "No", "Lift_Stati": 1.0, "Manhole_Id": "LS1-094", "Rating": "Good", "SHAWN": 0.0, "LAYER": "SANMH", "FeatId1": 263.0 }, "geometry": { "type": "Point", "coordinates": [ -80.610928086952441, 28.318882291560215 ] } },
    { "type": "Feature", "properties": { "OBJECTID": 749, "Coating": "None", "Constructi": "Brick/Mortar", "Date_Inspe": "04/30/09", "Depth": 5.0, "Infiltrati": "No", "Inflow": "No", "Lift_Stati": 1.0, "Manhole_Id": "LS1-061", "Rating": "Good", "SHAWN": 0.0, "LAYER": "SANMH", "FeatId1": 262.0 }, "geometry": { "type": "Point", "coordinates": [ -80.60919351287464, 28.31887567553499 ] } },
    { "type": "Feature", "properties": { "OBJECTID": 750, "Coating": "None", "Constructi": "Brick/Mortar", "Date_Inspe": "04/30/09", "Depth": 3.75, "Infiltrati": "No", "Inflow": "No", "Lift_Stati": 1.0, "Manhole_Id": "LS1-062", "Rating": "Good", "SHAWN": 0.0, "LAYER": "SANMH", "FeatId1": 261.0 }, "geometry": { "type": "Point", "coordinates": [ -80.608819134210052, 28.318887733402761 ] } },
    { "type": "Feature", "properties": { "OBJECTID": 751, "Coating": "None", "Constructi": "Brick/Mortar", "Date_Inspe": "04/30/09", "Depth": 2.25, "Infiltrati": "No", "Inflow": "No", "Lift_Stati": 1.0, "Manhole_Id": "LS1-063", "Rating": "Good", "SHAWN": 0.0, "LAYER": "SANMH", "FeatId1": 260.0 }, "geometry": { "type": "Point", "coordinates": [ -80.608808971162972, 28.31919545509248 ] } },
    { "type": "Feature", "properties": { "OBJECTID": 752, "Coating": "None", "Constructi": "Brick/Mortar", "Date_Inspe": "04/30/09", "Depth": 5.83, "Infiltrati": "Probable", "Inflow": "No", "Lift_Stati": 1.0, "Manhole_Id": "LS1-067", "Rating": "Fair", "SHAWN": 0.0, "LAYER": "SANMH", "FeatId1": 259.0 }, "geometry": { "type": "Point", "coordinates": [ -80.609474987251005, 28.319412762160383 ] } },
    { "type": "Feature", "properties": { "OBJECTID": 753, "Coating": "Spectrashield", "Constructi": "Brick/Mortar", "Date_Inspe": "05/07/09", "Depth": 7.25, "Infiltrati": "Yes", "Inflow": "No", "Lift_Stati": 1.0, "Manhole_Id": "LS1-083", "Rating": "Good", "SHAWN": 0.0, "LAYER": "SANMH", "FeatId1": 258.0 }, "geometry": { "type": "Point", "coordinates": [ -80.61013188798492, 28.319723074097691 ] } },
    { "type": "Feature", "properties": { "OBJECTID": 754, "Coating": "Spectrashield", "Constructi": "Brick/Mortar", "Date_Inspe": "05/06/09", "Depth": 7.0, "Infiltrati": "Yes", "Inflow": "No", "Lift_Stati": 1.0, "Manhole_Id": "LS1-082", "Rating": "Good", "SHAWN": 0.0, "LAYER": "SANMH", "FeatId1": 257.0 }, "geometry": { "type": "Point", "coordinates": [ -80.610108236514861, 28.320208025727496 ] } },
    { "type": "Feature", "properties": { "OBJECTID": 755, "Coating": "Spectrashield", "Constructi": "Brick/Mortar", "Date_Inspe": "05/06/09", "Depth": 7.67, "Infiltrati": "Yes", "Inflow": "No", "Lift_Stati": 1.0, "Manhole_Id": "LS1-093", "Rating": "Good", "SHAWN": 0.0, "LAYER": "SANMH", "FeatId1": 256.0 }, "geometry": { "type": "Point", "coordinates": [ -80.610128394371046, 28.320274327046612 ] } },
    { "type": "Feature", "properties": { "OBJECTID": 756, "Coating": "None", "Constructi": "Brick/Mortar", "Date_Inspe": "04/30/09", "Depth": 5.6, "Infiltrati": "No", "Inflow": "No", "Lift_Stati": 1.0, "Manhole_Id": "LS1-068", "Rating": "Fair", "SHAWN": 0.0, "LAYER": "SANMH", "FeatId1": 255.0 }, "geometry": { "type": "Point", "coordinates": [ -80.609469065402081, 28.320142047147819 ] } },
    { "type": "Feature", "properties": { "OBJECTID": 757, "Coating": "None", "Constructi": "Brick/Mortar", "Date_Inspe": "04/30/09", "Depth": 2.67, "Infiltrati": "No", "Inflow": "No", "Lift_Stati": 1.0, "Manhole_Id": "LS1-064", "Rating": "Good", "SHAWN": 0.0, "LAYER": "SANMH", "FeatId1": 254.0 }, "geometry": { "type": "Point", "coordinates": [ -80.608785900258184, 28.320153688402716 ] } },
    { "type": "Feature", "properties": { "OBJECTID": 758, "Coating": "None", "Constructi": "Brick/Mortar", "Date_Inspe": "04/30/09", "Depth": 2.25, "Infiltrati": "No", "Inflow": "No", "Lift_Stati": 1.0, "Manhole_Id": "LS1-065", "Rating": "Good", "SHAWN": 0.0, "LAYER": "SANMH", "FeatId1": 253.0 }, "geometry": { "type": "Point", "coordinates": [ -80.608770083001957, 28.320822293132451 ] } },
    { "type": "Feature", "properties": { "OBJECTID": 759, "Coating": "None", "Constructi": "Brick/Mortar", "Date_Inspe": "04/30/09", "Depth": 3.0, "Infiltrati": "Probable", "Inflow": "No", "Lift_Stati": 1.0, "Manhole_Id": "LS1-069", "Rating": "Poor", "SHAWN": 0.0, "LAYER": "SANMH", "FeatId1": 252.0 }, "geometry": { "type": "Point", "coordinates": [ -80.609440925902405, 28.320926714471163 ] } },
    { "type": "Feature", "properties": { "OBJECTID": 760, "Coating": "Spectrashield", "Constructi": "Brick/Mortar", "Date_Inspe": "05/06/09", "Depth": 6.67, "Infiltrati": "Yes", "Inflow": "No", "Lift_Stati": 1.0, "Manhole_Id": "LS1-081", "Rating": "Good", "SHAWN": 0.0, "LAYER": "SANMH", "FeatId1": 251.0 }, "geometry": { "type": "Point", "coordinates": [ -80.610105755677168, 28.321167269115531 ] } },
    { "type": "Feature", "properties": { "OBJECTID": 761, "Coating": "None", "Constructi": "Brick/Mortar", "Date_Inspe": "05/06/09", "Depth": 6.5, "Infiltrati": "Probable", "Inflow": "No", "Lift_Stati": 1.0, "Manhole_Id": "LS1-080", "Rating": "Fair", "SHAWN": 0.0, "LAYER": "SANMH", "FeatId1": 250.0 }, "geometry": { "type": "Point", "coordinates": [ -80.610079022386699, 28.32199225107302 ] } },
    { "type": "Feature", "properties": { "OBJECTID": 762, "Coating": "None", "Constructi": "Brick/Mortar", "Date_Inspe": "05/04/09", "Depth": 5.25, "Infiltrati": "Probable", "Inflow": "No", "Lift_Stati": 1.0, "Manhole_Id": "LS1-070", "Rating": "Fair", "SHAWN": 0.0, "LAYER": "SANMH", "FeatId1": 249.0 }, "geometry": { "type": "Point", "coordinates": [ -80.609416868879165, 28.322002973654453 ] } },
    { "type": "Feature", "properties": { "OBJECTID": 763, "Coating": "None", "Constructi": "Brick/Mortar", "Date_Inspe": "05/04/09", "Depth": 4.4, "Infiltrati": "Probable", "Inflow": "No", "Lift_Stati": 1.0, "Manhole_Id": "LS1-074", "Rating": "Fair", "SHAWN": 0.0, "LAYER": "SANMH", "FeatId1": 248.0 }, "geometry": { "type": "Point", "coordinates": [ -80.608719817478956, 28.321985556410414 ] } },
    { "type": "Feature", "properties": { "OBJECTID": 764, "Coating": "Fiberglass", "Constructi": "Brick/Mortar", "Date_Inspe": "05/06/09", "Depth": 6.83, "Infiltrati": "No", "Inflow": "No", "Lift_Stati": 1.0, "Manhole_Id": "LS1-079", "Rating": "Good", "SHAWN": 0.0, "LAYER": "SANMH", "FeatId1": 247.0 }, "geometry": { "type": "Point", "coordinates": [ -80.610047401008188, 28.322812176525851 ] } },
    { "type": "Feature", "properties": { "OBJECTID": 765, "Coating": "None", "Constructi": "Brick/Mortar", "Date_Inspe": "05/04/09", "Depth": 4.83, "Infiltrati": "Probable", "Inflow": "No", "Lift_Stati": 1.0, "Manhole_Id": "LS1-075", "Rating": "Fair", "SHAWN": 0.0, "LAYER": "SANMH", "FeatId1": 246.0 }, "geometry": { "type": "Point", "coordinates": [ -80.608683163376597, 28.32304218782652 ] } },
    { "type": "Feature", "properties": { "OBJECTID": 766, "Coating": "Spectrashield", "Constructi": "Brick/Mortar", "Date_Inspe": "09/11/13", "Depth": 6.9, "Infiltrati": "No", "Inflow": "No", "Lift_Stati": 1.0, "Manhole_Id": "LS1-076", "Rating": "Good", "SHAWN": 0.0, "LAYER": "SANMH", "FeatId1": 245.0 }, "geometry": { "type": "Point", "coordinates": [ -80.608705917258334, 28.323653466263696 ] } },
    { "type": "Feature", "properties": { "OBJECTID": 767, "Coating": "Spectrashield", "Constructi": "Brick/Mortar", "Date_Inspe": "05/06/09", "Depth": 6.0, "Infiltrati": "No", "Inflow": "No", "Lift_Stati": 1.0, "Manhole_Id": "LS1-078", "Rating": "Good", "SHAWN": 0.0, "LAYER": "SANMH", "FeatId1": 244.0 }, "geometry": { "type": "Point", "coordinates": [ -80.610015041068493, 28.323642075432197 ] } },
    { "type": "Feature", "properties": { "OBJECTID": 768, "Coating": "None", "Constructi": "Brick/Mortar", "Date_Inspe": "00/00/00", "Depth": 11.0, "Infiltrati": "No", "Inflow": "No", "Lift_Stati": 1.0, "Manhole_Id": "LS1-191", "Rating": "Good", "SHAWN": 0.0, "LAYER": "SANMH", "FeatId1": 243.0 }, "geometry": { "type": "Point", "coordinates": [ -80.609988578435491, 28.32440057772407 ] } },
    { "type": "Feature", "properties": { "OBJECTID": 769, "Coating": "None", "Constructi": "Brick/Mortar", "Date_Inspe": "05/06/09", "Depth": 4.5, "Infiltrati": "No", "Inflow": "No", "Lift_Stati": 1.0, "Manhole_Id": "LS1-089", "Rating": "Good", "SHAWN": 0.0, "LAYER": "SANMH", "FeatId1": 242.0 }, "geometry": { "type": "Point", "coordinates": [ -80.609964811432079, 28.325085665609542 ] } },
    { "type": "Feature", "properties": { "OBJECTID": 770, "Coating": "None", "Constructi": "Brick/Mortar", "Date_Inspe": "05/04/09", "Depth": 5.4, "Infiltrati": "No", "Inflow": "No", "Lift_Stati": 1.0, "Manhole_Id": "LS1-086", "Rating": "Good", "SHAWN": 0.0, "LAYER": "SANMH", "FeatId1": 241.0 }, "geometry": { "type": "Point", "coordinates": [ -80.609079502397023, 28.325509701280314 ] } },
    { "type": "Feature", "properties": { "OBJECTID": 771, "Coating": "None", "Constructi": "Brick/Mortar", "Date_Inspe": "05/12/09", "Depth": 3.0, "Infiltrati": "Probable", "Inflow": "No", "Lift_Stati": 1.0, "Manhole_Id": "LS1-100", "Rating": "Poor", "SHAWN": 0.0, "LAYER": "SANMH", "FeatId1": 240.0 }, "geometry": { "type": "Point", "coordinates": [ -80.6104209710345, 28.325680361731678 ] } },
    { "type": "Feature", "properties": { "OBJECTID": 772, "Coating": "None", "Constructi": "Brick/Mortar", "Date_Inspe": "03/24/09", "Depth": 11.0, "Infiltrati": "No", "Inflow": "No", "Lift_Stati": 7.0, "Manhole_Id": "LS7-012", "Rating": "Good", "SHAWN": 0.0, "LAYER": "SANMH", "FeatId1": 239.0 }, "geometry": { "type": "Point", "coordinates": [ -80.610812826772985, 28.325666674698347 ] } },
    { "type": "Feature", "properties": { "OBJECTID": 773, "Coating": "None", "Constructi": "Brick/Mortar", "Date_Inspe": "03/24/09", "Depth": 11.0, "Infiltrati": "No", "Inflow": "No", "Lift_Stati": 7.0, "Manhole_Id": "LS7-012", "Rating": "Good", "SHAWN": 0.0, "LAYER": "SANMH", "FeatId1": 238.0 }, "geometry": { "type": "Point", "coordinates": [ -80.611031824126428, 28.32566445843478 ] } },
    { "type": "Feature", "properties": { "OBJECTID": 774, "Coating": "None", "Constructi": "Brick/Mortar", "Date_Inspe": "03/24/09", "Depth": 11.0, "Infiltrati": "No", "Inflow": "No", "Lift_Stati": 7.0, "Manhole_Id": "LS7-012", "Rating": "Good", "SHAWN": 0.0, "LAYER": "SANMH", "FeatId1": 237.0 }, "geometry": { "type": "Point", "coordinates": [ -80.610986811759787, 28.325846285273538 ] } },
    { "type": "Feature", "properties": { "OBJECTID": 775, "Coating": "None", "Constructi": "Brick/Mortar", "Date_Inspe": "00/00/00", "Depth": 11.0, "Infiltrati": "No", "Inflow": "No", "Lift_Stati": 4.0, "Manhole_Id": "LS4-187", "Rating": "Good", "SHAWN": 0.0, "LAYER": "SANMH", "FeatId1": 236.0 }, "geometry": { "type": "Point", "coordinates": [ -80.60638847580428, 28.351340765604679 ] } },
    { "type": "Feature", "properties": { "OBJECTID": 776, "Coating": "None", "Constructi": "Brick/Mortar", "Date_Inspe": "00/00/00", "Depth": 11.0, "Infiltrati": "No", "Inflow": "No", "Lift_Stati": 4.0, "Manhole_Id": "LS4-195", "Rating": "Good", "SHAWN": 0.0, "LAYER": "SANMH", "FeatId1": 235.0 }, "geometry": { "type": "Point", "coordinates": [ -80.60865875104966, 28.351785516436056 ] } },
    { "type": "Feature", "properties": { "OBJECTID": 777, "Coating": "None", "Constructi": "Brick/Mortar", "Date_Inspe": "00/00/00", "Depth": 11.0, "Infiltrati": "No", "Inflow": "No", "Lift_Stati": 4.0, "Manhole_Id": "LS4-194", "Rating": "Good", "SHAWN": 0.0, "LAYER": "SANMH", "FeatId1": 234.0 }, "geometry": { "type": "Point", "coordinates": [ -80.60856364172237, 28.352579140342797 ] } },
    { "type": "Feature", "properties": { "OBJECTID": 778, "Coating": "None", "Constructi": "Brick/Mortar", "Date_Inspe": "00/00/00", "Depth": 11.0, "Infiltrati": "No", "Inflow": "No", "Lift_Stati": 4.0, "Manhole_Id": "LS4-193", "Rating": "Good", "SHAWN": 0.0, "LAYER": "SANMH", "FeatId1": 233.0 }, "geometry": { "type": "Point", "coordinates": [ -80.607634565493754, 28.352491236012547 ] } },
    { "type": "Feature", "properties": { "OBJECTID": 779, "Coating": "None", "Constructi": "Brick/Mortar", "Date_Inspe": "00/00/00", "Depth": 11.0, "Infiltrati": "No", "Inflow": "No", "Lift_Stati": 4.0, "Manhole_Id": "LS4-186", "Rating": "Good", "SHAWN": 0.0, "LAYER": "SANMH", "FeatId1": 232.0 }, "geometry": { "type": "Point", "coordinates": [ -80.606708939666007, 28.352401653306977 ] } },
    { "type": "Feature", "properties": { "OBJECTID": 780, "Coating": "None", "Constructi": "Brick/Mortar", "Date_Inspe": "00/00/00", "Depth": 11.0, "Infiltrati": "No", "Inflow": "No", "Lift_Stati": 4.0, "Manhole_Id": "LS4-185", "Rating": "Good", "SHAWN": 0.0, "LAYER": "SANMH", "FeatId1": 231.0 }, "geometry": { "type": "Point", "coordinates": [ -80.606277440497053, 28.352244455504426 ] } },
    { "type": "Feature", "properties": { "OBJECTID": 781, "Coating": "None", "Constructi": "Brick/Mortar", "Date_Inspe": "00/00/00", "Depth": 11.0, "Infiltrati": "No", "Inflow": "No", "Lift_Stati": 4.0, "Manhole_Id": "LS4-184", "Rating": "Good", "SHAWN": 0.0, "LAYER": "SANMH", "FeatId1": 230.0 }, "geometry": { "type": "Point", "coordinates": [ -80.606166463697917, 28.353131477795824 ] } },
    { "type": "Feature", "properties": { "OBJECTID": 782, "Coating": "None", "Constructi": "Brick/Mortar", "Date_Inspe": "00/00/00", "Depth": 11.0, "Infiltrati": "No", "Inflow": "No", "Lift_Stati": 4.0, "Manhole_Id": "LS4-189", "Rating": "Good", "SHAWN": 0.0, "LAYER": "SANMH", "FeatId1": 229.0 }, "geometry": { "type": "Point", "coordinates": [ -80.608475429891243, 28.353429099487155 ] } },
    { "type": "Feature", "properties": { "OBJECTID": 783, "Coating": "None", "Constructi": "Brick/Mortar", "Date_Inspe": "00/00/00", "Depth": 11.0, "Infiltrati": "No", "Inflow": "No", "Lift_Stati": 4.0, "Manhole_Id": "LS4-188", "Rating": "Good", "SHAWN": 0.0, "LAYER": "SANMH", "FeatId1": 228.0 }, "geometry": { "type": "Point", "coordinates": [ -80.608438593111686, 28.353734032287619 ] } },
    { "type": "Feature", "properties": { "OBJECTID": 784, "Coating": "None", "Constructi": "Brick/Mortar", "Date_Inspe": "00/00/00", "Depth": 11.0, "Infiltrati": "No", "Inflow": "No", "Lift_Stati": 4.0, "Manhole_Id": "LS4-181", "Rating": "Good", "SHAWN": 0.0, "LAYER": "SANMH", "FeatId1": 227.0 }, "geometry": { "type": "Point", "coordinates": [ -80.608382199483799, 28.354175216311493 ] } },
    { "type": "Feature", "properties": { "OBJECTID": 785, "Coating": "None", "Constructi": "Brick/Mortar", "Date_Inspe": "00/00/00", "Depth": 11.0, "Infiltrati": "No", "Inflow": "No", "Lift_Stati": 4.0, "Manhole_Id": "LS4-173", "Rating": "Good", "SHAWN": 0.0, "LAYER": "SANMH", "FeatId1": 226.0 }, "geometry": { "type": "Point", "coordinates": [ -80.608236432494138, 28.355166400190146 ] } },
    { "type": "Feature", "properties": { "OBJECTID": 786, "Coating": "None", "Constructi": "Brick/Mortar", "Date_Inspe": "00/00/00", "Depth": 11.0, "Infiltrati": "No", "Inflow": "No", "Lift_Stati": 4.0, "Manhole_Id": "LS4-175", "Rating": "Good", "SHAWN": 0.0, "LAYER": "SANMH", "FeatId1": 225.0 }, "geometry": { "type": "Point", "coordinates": [ -80.608140398616086, 28.355917525722734 ] } },
    { "type": "Feature", "properties": { "OBJECTID": 787, "Coating": "None", "Constructi": "Brick/Mortar", "Date_Inspe": "00/00/00", "Depth": 11.0, "Infiltrati": "No", "Inflow": "No", "Lift_Stati": 4.0, "Manhole_Id": "LS4-176", "Rating": "Good", "SHAWN": 0.0, "LAYER": "SANMH", "FeatId1": 224.0 }, "geometry": { "type": "Point", "coordinates": [ -80.607336239312261, 28.355852320601528 ] } },
    { "type": "Feature", "properties": { "OBJECTID": 788, "Coating": "None", "Constructi": "Brick/Mortar", "Date_Inspe": "00/00/00", "Depth": 11.0, "Infiltrati": "No", "Inflow": "No", "Lift_Stati": 4.0, "Manhole_Id": "LS4-225", "Rating": "Good", "SHAWN": 0.0, "LAYER": "SANMH", "FeatId1": 223.0 }, "geometry": { "type": "Point", "coordinates": [ -80.605779611648813, 28.355573654693078 ] } },
    { "type": "Feature", "properties": { "OBJECTID": 789, "Coating": "None", "Constructi": "Brick/Mortar", "Date_Inspe": "00/00/00", "Depth": 11.0, "Infiltrati": "No", "Inflow": "No", "Lift_Stati": 4.0, "Manhole_Id": "LS4-174", "Rating": "Good", "SHAWN": 0.0, "LAYER": "SANMH", "FeatId1": 222.0 }, "geometry": { "type": "Point", "coordinates": [ -80.607313419252023, 28.355078633686947 ] } },
    { "type": "Feature", "properties": { "OBJECTID": 790, "Coating": "None", "Constructi": "Brick/Mortar", "Date_Inspe": "00/00/00", "Depth": 11.0, "Infiltrati": "No", "Inflow": "No", "Lift_Stati": 4.0, "Manhole_Id": "LS4-183", "Rating": "Good", "SHAWN": 0.0, "LAYER": "SANMH", "FeatId1": 221.0 }, "geometry": { "type": "Point", "coordinates": [ -80.606060713305567, 28.353945391055198 ] } },
    { "type": "Feature", "properties": { "OBJECTID": 791, "Coating": "None", "Constructi": "Brick/Mortar", "Date_Inspe": "00/00/00", "Depth": 11.0, "Infiltrati": "No", "Inflow": "No", "Lift_Stati": 4.0, "Manhole_Id": "LS4-180", "Rating": "Good", "SHAWN": 0.0, "LAYER": "SANMH", "FeatId1": 220.0 }, "geometry": { "type": "Point", "coordinates": [ -80.605698147933111, 28.356155620180949 ] } },
    { "type": "Feature", "properties": { "OBJECTID": 792, "Coating": "None", "Constructi": "Brick/Mortar", "Date_Inspe": "00/00/00", "Depth": 0.0, "Infiltrati": "No", "Inflow": "No", "Lift_Stati": 5.0, "Manhole_Id": "LS5-085", "Rating": "Good", "SHAWN": 0.0, "LAYER": "SANMH", "FeatId1": 219.0 }, "geometry": { "type": "Point", "coordinates": [ -80.605418146675248, 28.358004770509996 ] } },
    { "type": "Feature", "properties": { "OBJECTID": 793, "Coating": "None", "Constructi": "Brick/Mortar", "Date_Inspe": "00/00/00", "Depth": 0.0, "Infiltrati": "No", "Inflow": "No", "Lift_Stati": 5.0, "Manhole_Id": "LS5-082", "Rating": "Good", "SHAWN": 0.0, "LAYER": "SANMH", "FeatId1": 218.0 }, "geometry": { "type": "Point", "coordinates": [ -80.60538651499887, 28.358522690198239 ] } },
    { "type": "Feature", "properties": { "OBJECTID": 794, "Coating": "None", "Constructi": "Brick/Mortar", "Date_Inspe": "00/00/00", "Depth": 0.0, "Infiltrati": "No", "Inflow": "No", "Lift_Stati": 5.0, "Manhole_Id": "LS5-083", "Rating": "Good", "SHAWN": 0.0, "LAYER": "SANMH", "FeatId1": 217.0 }, "geometry": { "type": "Point", "coordinates": [ -80.606557367293817, 28.358549402240808 ] } },
    { "type": "Feature", "properties": { "OBJECTID": 795, "Coating": "None", "Constructi": "Brick/Mortar", "Date_Inspe": "00/00/00", "Depth": 0.0, "Infiltrati": "No", "Inflow": "No", "Lift_Stati": 5.0, "Manhole_Id": "LS5-084", "Rating": "Good", "SHAWN": 0.0, "LAYER": "SANMH", "FeatId1": 216.0 }, "geometry": { "type": "Point", "coordinates": [ -80.607420861391034, 28.358541302602749 ] } },
    { "type": "Feature", "properties": { "OBJECTID": 796, "Coating": "None", "Constructi": "Brick/Mortar", "Date_Inspe": "00/00/00", "Depth": 0.0, "Infiltrati": "No", "Inflow": "No", "Lift_Stati": 5.0, "Manhole_Id": "LS5-079", "Rating": "Good", "SHAWN": 0.0, "LAYER": "SANMH", "FeatId1": 215.0 }, "geometry": { "type": "Point", "coordinates": [ -80.605287498839218, 28.359113491731691 ] } },
    { "type": "Feature", "properties": { "OBJECTID": 797, "Coating": "None", "Constructi": "Brick/Mortar", "Date_Inspe": "00/00/00", "Depth": 0.0, "Infiltrati": "No", "Inflow": "No", "Lift_Stati": 5.0, "Manhole_Id": "LS5-080", "Rating": "Good", "SHAWN": 0.0, "LAYER": "SANMH", "FeatId1": 214.0 }, "geometry": { "type": "Point", "coordinates": [ -80.60636295370918, 28.359253237182095 ] } },
    { "type": "Feature", "properties": { "OBJECTID": 798, "Coating": "None", "Constructi": "Brick/Mortar", "Date_Inspe": "00/00/00", "Depth": 0.0, "Infiltrati": "No", "Inflow": "No", "Lift_Stati": 5.0, "Manhole_Id": "LS5-081", "Rating": "Good", "SHAWN": 0.0, "LAYER": "SANMH", "FeatId1": 213.0 }, "geometry": { "type": "Point", "coordinates": [ -80.607439928174671, 28.359392480437617 ] } },
    { "type": "Feature", "properties": { "OBJECTID": 799, "Coating": "None", "Constructi": "Brick/Mortar", "Date_Inspe": "00/00/00", "Depth": 0.0, "Infiltrati": "No", "Inflow": "No", "Lift_Stati": 5.0, "Manhole_Id": "LS5-076", "Rating": "Good", "SHAWN": 0.0, "LAYER": "SANMH", "FeatId1": 212.0 }, "geometry": { "type": "Point", "coordinates": [ -80.605160136306424, 28.359862110051044 ] } },
    { "type": "Feature", "properties": { "OBJECTID": 800, "Coating": "Spectrashield", "Constructi": "Brick/Mortar", "Date_Inspe": "05/07/09", "Depth": 5.75, "Infiltrati": "Yes", "Inflow": "No", "Lift_Stati": 1.0, "Manhole_Id": "LS1-085", "Rating": "Good", "SHAWN": 0.0, "LAYER": "SANMH", "FeatId1": 211.0 }, "geometry": { "type": "Point", "coordinates": [ -80.609989918470475, 28.318882266015496 ] } },
    { "type": "Feature", "properties": { "OBJECTID": 801, "Coating": "None", "Constructi": "Brick/Mortar", "Date_Inspe": "05/06/09", "Depth": 6.0, "Infiltrati": "Probable", "Inflow": "No", "Lift_Stati": 1.0, "Manhole_Id": "LS1-088", "Rating": "Fair", "SHAWN": 0.0, "LAYER": "SANMH", "FeatId1": 210.0 }, "geometry": { "type": "Point", "coordinates": [ -80.609796503570976, 28.323644882459181 ] } },
    { "type": "Feature", "properties": { "OBJECTID": 802, "Coating": "None", "Constructi": "Brick/Mortar", "Date_Inspe": "00/00/00", "Depth": 11.0, "Infiltrati": "No", "Inflow": "No", "Lift_Stati": 1.0, "Manhole_Id": "LS1-041", "Rating": "Good", "SHAWN": 0.0, "LAYER": "SANMH", "FeatId1": 209.0 }, "geometry": { "type": "Point", "coordinates": [ -80.609471987669011, 28.313810892007986 ] } },
    { "type": "Feature", "properties": { "OBJECTID": 803, "Coating": "None", "Constructi": "Brick/Mortar", "Date_Inspe": "04/22/09", "Depth": 3.5, "Infiltrati": "No", "Inflow": "Yes", "Lift_Stati": 1.0, "Manhole_Id": "LS1-032", "Rating": "Good", "SHAWN": 0.0, "LAYER": "SANMH", "FeatId1": 208.0 }, "geometry": { "type": "Point", "coordinates": [ -80.609484412235034, 28.315654806911816 ] } },
    { "type": "Feature", "properties": { "OBJECTID": 804, "Coating": "None", "Constructi": "Brick/Mortar", "Date_Inspe": "04/21/09", "Depth": 2.0, "Infiltrati": "No", "Inflow": "No", "Lift_Stati": 1.0, "Manhole_Id": "LS1-031", "Rating": "Good", "SHAWN": 0.0, "LAYER": "SANMH", "FeatId1": 207.0 }, "geometry": { "type": "Point", "coordinates": [ -80.609489457990534, 28.316340777509936 ] } },
    { "type": "Feature", "properties": { "OBJECTID": 805, "Coating": "None", "Constructi": "Brick/Mortar", "Date_Inspe": "00/00/00", "Depth": 0.0, "Infiltrati": "No", "Inflow": "No", "Lift_Stati": 3.0, "Manhole_Id": "LS3-033", "Rating": "Good", "SHAWN": 0.0, "LAYER": "SANMH", "FeatId1": 206.0 }, "geometry": { "type": "Point", "coordinates": [ -80.609863847904705, 28.295011694991143 ] } },
    { "type": "Feature", "properties": { "OBJECTID": 806, "Coating": "None", "Constructi": "Brick/Mortar", "Date_Inspe": "00/00/00", "Depth": 0.0, "Infiltrati": "No", "Inflow": "No", "Lift_Stati": 3.0, "Manhole_Id": "LS3-031", "Rating": "Good", "SHAWN": 0.0, "LAYER": "SANMH", "FeatId1": 205.0 }, "geometry": { "type": "Point", "coordinates": [ -80.609852934762756, 28.29432325736677 ] } },
    { "type": "Feature", "properties": { "OBJECTID": 807, "Coating": "None", "Constructi": "Brick/Mortar", "Date_Inspe": "00/00/00", "Depth": 0.0, "Infiltrati": "No", "Inflow": "No", "Lift_Stati": 3.0, "Manhole_Id": "LS3-032", "Rating": "Good", "SHAWN": 0.0, "LAYER": "SANMH", "FeatId1": 204.0 }, "geometry": { "type": "Point", "coordinates": [ -80.609838187573388, 28.293629473899625 ] } },
    { "type": "Feature", "properties": { "OBJECTID": 808, "Coating": "None", "Constructi": "Brick/Mortar", "Date_Inspe": "00/00/00", "Depth": 11.0, "Infiltrati": "No", "Inflow": "No", "Lift_Stati": 1.0, "Manhole_Id": "LS1-042", "Rating": "Good", "SHAWN": 0.0, "LAYER": "SANMH", "FeatId1": 203.0 }, "geometry": { "type": "Point", "coordinates": [ -80.609469155928764, 28.31316670807265 ] } },
    { "type": "Feature", "properties": { "OBJECTID": 809, "Coating": "None", "Constructi": "Brick/Mortar", "Date_Inspe": "00/00/00", "Depth": 11.0, "Infiltrati": "No", "Inflow": "No", "Lift_Stati": 1.0, "Manhole_Id": "LS1-159", "Rating": "Good", "SHAWN": 0.0, "LAYER": "SANMH", "FeatId1": 202.0 }, "geometry": { "type": "Point", "coordinates": [ -80.629397833208657, 28.318461962660813 ] } },
    { "type": "Feature", "properties": { "OBJECTID": 810, "Coating": "None", "Constructi": "Brick/Mortar", "Date_Inspe": "00/00/00", "Depth": 11.0, "Infiltrati": "No", "Inflow": "No", "Lift_Stati": 1.0, "Manhole_Id": "LS1-160", "Rating": "Good", "SHAWN": 0.0, "LAYER": "SANMH", "FeatId1": 201.0 }, "geometry": { "type": "Point", "coordinates": [ -80.630664956136798, 28.318466501880305 ] } },
    { "type": "Feature", "properties": { "OBJECTID": 811, "Coating": "None", "Constructi": "Brick/Mortar", "Date_Inspe": "00/00/00", "Depth": 11.0, "Infiltrati": "No", "Inflow": "No", "Lift_Stati": 1.0, "Manhole_Id": "LS1-161", "Rating": "Good", "SHAWN": 0.0, "LAYER": "SANMH", "FeatId1": 200.0 }, "geometry": { "type": "Point", "coordinates": [ -80.631418765571723, 28.318577165035393 ] } },
    { "type": "Feature", "properties": { "OBJECTID": 812, "Coating": "Spectrashield", "Constructi": "Brick/Mortar", "Date_Inspe": "09/11/13", "Depth": 16.0, "Infiltrati": "No", "Inflow": "No", "Lift_Stati": 1.0, "Manhole_Id": "LS1-162", "Rating": "Good", "SHAWN": 0.0, "LAYER": "SANMH", "FeatId1": 199.0 }, "geometry": { "type": "Point", "coordinates": [ -80.632961309487555, 28.319202924555665 ] } },
    { "type": "Feature", "properties": { "OBJECTID": 813, "Coating": "None", "Constructi": "Brick/Mortar", "Date_Inspe": "00/00/00", "Depth": 11.0, "Infiltrati": "No", "Inflow": "No", "Lift_Stati": 1.0, "Manhole_Id": "LS1-163", "Rating": "Good", "SHAWN": 0.0, "LAYER": "SANMH", "FeatId1": 198.0 }, "geometry": { "type": "Point", "coordinates": [ -80.633664900769972, 28.319615350536679 ] } },
    { "type": "Feature", "properties": { "OBJECTID": 814, "Coating": "None", "Constructi": "Brick/Mortar", "Date_Inspe": "00/00/00", "Depth": 11.0, "Infiltrati": "No", "Inflow": "No", "Lift_Stati": 1.0, "Manhole_Id": "LS1-164", "Rating": "Good", "SHAWN": 0.0, "LAYER": "SANMH", "FeatId1": 197.0 }, "geometry": { "type": "Point", "coordinates": [ -80.634324367296969, 28.319022290585302 ] } },
    { "type": "Feature", "properties": { "OBJECTID": 815, "Coating": "None", "Constructi": "Brick/Mortar", "Date_Inspe": "00/00/00", "Depth": 11.0, "Infiltrati": "No", "Inflow": "No", "Lift_Stati": 1.0, "Manhole_Id": "LS1-165", "Rating": "Good", "SHAWN": 0.0, "LAYER": "SANMH", "FeatId1": 196.0 }, "geometry": { "type": "Point", "coordinates": [ -80.635520952774172, 28.318772465921349 ] } },
    { "type": "Feature", "properties": { "OBJECTID": 816, "Coating": "None", "Constructi": "Brick/Mortar", "Date_Inspe": "00/00/00", "Depth": 11.0, "Infiltrati": "No", "Inflow": "No", "Lift_Stati": 1.0, "Manhole_Id": "LS1-166", "Rating": "Good", "SHAWN": 0.0, "LAYER": "SANMH", "FeatId1": 195.0 }, "geometry": { "type": "Point", "coordinates": [ -80.636762440941396, 28.318753413973401 ] } },
    { "type": "Feature", "properties": { "OBJECTID": 817, "Coating": "None", "Constructi": "Brick/Mortar", "Date_Inspe": "00/00/00", "Depth": 11.0, "Infiltrati": "No", "Inflow": "No", "Lift_Stati": 1.0, "Manhole_Id": "LS1-167", "Rating": "Good", "SHAWN": 0.0, "LAYER": "SANMH", "FeatId1": 194.0 }, "geometry": { "type": "Point", "coordinates": [ -80.638006591328505, 28.318744574420585 ] } },
    { "type": "Feature", "properties": { "OBJECTID": 818, "Coating": "None", "Constructi": "Brick/Mortar", "Date_Inspe": "00/00/00", "Depth": 11.0, "Infiltrati": "No", "Inflow": "No", "Lift_Stati": 1.0, "Manhole_Id": "LS1-168", "Rating": "Good", "SHAWN": 0.0, "LAYER": "SANMH", "FeatId1": 193.0 }, "geometry": { "type": "Point", "coordinates": [ -80.633975064891757, 28.320566097896602 ] } },
    { "type": "Feature", "properties": { "OBJECTID": 819, "Coating": "None", "Constructi": "Brick/Mortar", "Date_Inspe": "00/00/00", "Depth": 11.0, "Infiltrati": "No", "Inflow": "No", "Lift_Stati": 1.0, "Manhole_Id": "LS1-169", "Rating": "Good", "SHAWN": 0.0, "LAYER": "SANMH", "FeatId1": 192.0 }, "geometry": { "type": "Point", "coordinates": [ -80.63426689044455, 28.321634186325284 ] } },
    { "type": "Feature", "properties": { "OBJECTID": 820, "Coating": "None", "Constructi": "Brick/Mortar", "Date_Inspe": "00/00/00", "Depth": 11.0, "Infiltrati": "No", "Inflow": "No", "Lift_Stati": 1.0, "Manhole_Id": "LS1-170", "Rating": "Good", "SHAWN": 0.0, "LAYER": "SANMH", "FeatId1": 191.0 }, "geometry": { "type": "Point", "coordinates": [ -80.635073751283002, 28.322306155859128 ] } },
    { "type": "Feature", "properties": { "OBJECTID": 821, "Coating": "None", "Constructi": "Brick/Mortar", "Date_Inspe": "00/00/00", "Depth": 11.0, "Infiltrati": "No", "Inflow": "No", "Lift_Stati": 1.0, "Manhole_Id": "LS1-171", "Rating": "Good", "SHAWN": 0.0, "LAYER": "SANMH", "FeatId1": 190.0 }, "geometry": { "type": "Point", "coordinates": [ -80.635695335370613, 28.321613692464911 ] } },
    { "type": "Feature", "properties": { "OBJECTID": 822, "Coating": "None", "Constructi": "Brick/Mortar", "Date_Inspe": "00/00/00", "Depth": 11.0, "Infiltrati": "No", "Inflow": "No", "Lift_Stati": 1.0, "Manhole_Id": "LS1-172", "Rating": "Good", "SHAWN": 0.0, "LAYER": "SANMH", "FeatId1": 189.0 }, "geometry": { "type": "Point", "coordinates": [ -80.636766635098226, 28.321533228551889 ] } },
    { "type": "Feature", "properties": { "OBJECTID": 823, "Coating": "None", "Constructi": "Brick/Mortar", "Date_Inspe": "00/00/00", "Depth": 11.0, "Infiltrati": "No", "Inflow": "No", "Lift_Stati": 1.0, "Manhole_Id": "LS1-174", "Rating": "Good", "SHAWN": 0.0, "LAYER": "SANMH", "FeatId1": 188.0 }, "geometry": { "type": "Point", "coordinates": [ -80.639256586810546, 28.321420812073075 ] } },
    { "type": "Feature", "properties": { "OBJECTID": 824, "Coating": "None", "Constructi": "Brick/Mortar", "Date_Inspe": "00/00/00", "Depth": 11.0, "Infiltrati": "No", "Inflow": "No", "Lift_Stati": 1.0, "Manhole_Id": "LS1-175", "Rating": "Good", "SHAWN": 0.0, "LAYER": "SANMH", "FeatId1": 187.0 }, "geometry": { "type": "Point", "coordinates": [ -80.640404011116601, 28.321034262851299 ] } },
    { "type": "Feature", "properties": { "OBJECTID": 825, "Coating": "None", "Constructi": "Brick/Mortar", "Date_Inspe": "00/00/00", "Depth": 11.0, "Infiltrati": "No", "Inflow": "No", "Lift_Stati": 1.0, "Manhole_Id": "LS1-176", "Rating": "Good", "SHAWN": 0.0, "LAYER": "SANMH", "FeatId1": 186.0 }, "geometry": { "type": "Point", "coordinates": [ -80.641039600377681, 28.321462641338947 ] } },
    { "type": "Feature", "properties": { "OBJECTID": 826, "Coating": "None", "Constructi": "Brick/Mortar", "Date_Inspe": "00/00/00", "Depth": 11.0, "Infiltrati": "No", "Inflow": "No", "Lift_Stati": 1.0, "Manhole_Id": "LS1-177", "Rating": "Good", "SHAWN": 0.0, "LAYER": "SANMH", "FeatId1": 185.0 }, "geometry": { "type": "Point", "coordinates": [ -80.641702150043344, 28.321856265214471 ] } },
    { "type": "Feature", "properties": { "OBJECTID": 827, "Coating": "None", "Constructi": "Brick/Mortar", "Date_Inspe": "00/00/00", "Depth": 11.0, "Infiltrati": "No", "Inflow": "No", "Lift_Stati": 1.0, "Manhole_Id": "LS1-179", "Rating": "Good", "SHAWN": 0.0, "LAYER": "SANMH", "FeatId1": 184.0 }, "geometry": { "type": "Point", "coordinates": [ -80.640837758645816, 28.318947008268129 ] } },
    { "type": "Feature", "properties": { "OBJECTID": 828, "Coating": "None", "Constructi": "Brick/Mortar", "Date_Inspe": "00/00/00", "Depth": 11.0, "Infiltrati": "No", "Inflow": "No", "Lift_Stati": 1.0, "Manhole_Id": "LS1-180", "Rating": "Good", "SHAWN": 0.0, "LAYER": "SANMH", "FeatId1": 183.0 }, "geometry": { "type": "Point", "coordinates": [ -80.641570328725521, 28.318729787199779 ] } },
    { "type": "Feature", "properties": { "OBJECTID": 829, "Coating": "None", "Constructi": "Brick/Mortar", "Date_Inspe": "00/00/00", "Depth": 11.0, "Infiltrati": "No", "Inflow": "No", "Lift_Stati": 1.0, "Manhole_Id": "LS1-181", "Rating": "Good", "SHAWN": 0.0, "LAYER": "SANMH", "FeatId1": 182.0 }, "geometry": { "type": "Point", "coordinates": [ -80.642234638987929, 28.319082253373608 ] } },
    { "type": "Feature", "properties": { "OBJECTID": 830, "Coating": "None", "Constructi": "Brick/Mortar", "Date_Inspe": "00/00/00", "Depth": 11.0, "Infiltrati": "No", "Inflow": "No", "Lift_Stati": 1.0, "Manhole_Id": "LS1-182", "Rating": "Good", "SHAWN": 0.0, "LAYER": "SANMH", "FeatId1": 181.0 }, "geometry": { "type": "Point", "coordinates": [ -80.642078697183237, 28.320170432848894 ] } },
    { "type": "Feature", "properties": { "OBJECTID": 831, "Coating": "None", "Constructi": "Brick/Mortar", "Date_Inspe": "00/00/00", "Depth": 11.0, "Infiltrati": "No", "Inflow": "No", "Lift_Stati": 1.0, "Manhole_Id": "LS1-178", "Rating": "Good", "SHAWN": 0.0, "LAYER": "SANMH", "FeatId1": 180.0 }, "geometry": { "type": "Point", "coordinates": [ -80.640812936238717, 28.320041861337511 ] } },
    { "type": "Feature", "properties": { "OBJECTID": 832, "Coating": "None", "Constructi": "Brick/Mortar", "Date_Inspe": "00/00/00", "Depth": 11.0, "Infiltrati": "No", "Inflow": "No", "Lift_Stati": 1.0, "Manhole_Id": "LS1-173", "Rating": "Good", "SHAWN": 0.0, "LAYER": "SANMH", "FeatId1": 179.0 }, "geometry": { "type": "Point", "coordinates": [ -80.638100322471573, 28.321464305943881 ] } },
    { "type": "Feature", "properties": { "OBJECTID": 833, "Coating": "None", "Constructi": "Brick/Mortar", "Date_Inspe": "05/04/09", "Depth": 5.83, "Infiltrati": "Probable", "Inflow": "Yes", "Lift_Stati": 1.0, "Manhole_Id": "LS1-071", "Rating": "Fair", "SHAWN": 0.0, "LAYER": "SANMH", "FeatId1": 178.0 }, "geometry": { "type": "Point", "coordinates": [ -80.60938455823964, 28.323026530624457 ] } },
    { "type": "Feature", "properties": { "OBJECTID": 834, "Coating": "Spectrashield", "Constructi": "Brick/Mortar", "Date_Inspe": "09/11/13", "Depth": 4.3, "Infiltrati": "No", "Inflow": "No", "Lift_Stati": 1.0, "Manhole_Id": "LS1-077", "Rating": "Good", "SHAWN": 0.0, "LAYER": "SANMH", "FeatId1": 177.0 }, "geometry": { "type": "Point", "coordinates": [ -80.61051803503149, 28.323639699378425 ] } },
    { "type": "Feature", "properties": { "OBJECTID": 835, "Coating": "None", "Constructi": "Brick/Mortar", "Date_Inspe": "08/01/13", "Depth": 3.6, "Infiltrati": "No", "Inflow": "No", "Lift_Stati": 4.0, "Manhole_Id": "LS4-150", "Rating": "Fair", "SHAWN": 0.0, "LAYER": "SANMH", "FeatId1": 176.0 }, "geometry": { "type": "Point", "coordinates": [ -80.612724261740325, 28.342840746329738 ] } },
    { "type": "Feature", "properties": { "OBJECTID": 836, "Coating": "None", "Constructi": "Brick/Mortar", "Date_Inspe": "08/01/13", "Depth": 3.83, "Infiltrati": "No", "Inflow": "No", "Lift_Stati": 4.0, "Manhole_Id": "LS4-152", "Rating": "Good", "SHAWN": 0.0, "LAYER": "SANMH", "FeatId1": 175.0 }, "geometry": { "type": "Point", "coordinates": [ -80.611987044656047, 28.342941822364221 ] } },
    { "type": "Feature", "properties": { "OBJECTID": 837, "Coating": "Fiberglass", "Constructi": "Brick/Mortar", "Date_Inspe": "08/02/13", "Depth": 4.5, "Infiltrati": "No", "Inflow": "No", "Lift_Stati": 4.0, "Manhole_Id": "LS4-149", "Rating": "Good", "SHAWN": 0.0, "LAYER": "SANMH", "FeatId1": 174.0 }, "geometry": { "type": "Point", "coordinates": [ -80.611482387734839, 28.342764043974853 ] } },
    { "type": "Feature", "properties": { "OBJECTID": 838, "Coating": "None", "Constructi": "Brick/Mortar", "Date_Inspe": "00/00/00", "Depth": 11.0, "Infiltrati": "No", "Inflow": "No", "Lift_Stati": 4.0, "Manhole_Id": "LS4-148", "Rating": "Good", "SHAWN": 0.0, "LAYER": "SANMH", "FeatId1": 173.0 }, "geometry": { "type": "Point", "coordinates": [ -80.610086305349441, 28.342676329251006 ] } },
    { "type": "Feature", "properties": { "OBJECTID": 839, "Coating": "None", "Constructi": "Brick/Mortar", "Date_Inspe": "00/00/00", "Depth": 11.0, "Infiltrati": "No", "Inflow": "No", "Lift_Stati": 4.0, "Manhole_Id": "LS4-153", "Rating": "Good", "SHAWN": 0.0, "LAYER": "SANMH", "FeatId1": 172.0 }, "geometry": { "type": "Point", "coordinates": [ -80.610067804469082, 28.342946700718112 ] } },
    { "type": "Feature", "properties": { "OBJECTID": 840, "Coating": "None", "Constructi": "Brick/Mortar", "Date_Inspe": "00/00/00", "Depth": 11.0, "Infiltrati": "No", "Inflow": "No", "Lift_Stati": 4.0, "Manhole_Id": "LS4-154", "Rating": "Good", "SHAWN": 0.0, "LAYER": "SANMH", "FeatId1": 171.0 }, "geometry": { "type": "Point", "coordinates": [ -80.609933848035425, 28.344024207944667 ] } },
    { "type": "Feature", "properties": { "OBJECTID": 841, "Coating": "None", "Constructi": "Brick/Mortar", "Date_Inspe": "00/00/00", "Depth": 11.0, "Infiltrati": "No", "Inflow": "No", "Lift_Stati": 4.0, "Manhole_Id": "LS4-155", "Rating": "Good", "SHAWN": 0.0, "LAYER": "SANMH", "FeatId1": 170.0 }, "geometry": { "type": "Point", "coordinates": [ -80.609788843178933, 28.345183294910822 ] } },
    { "type": "Feature", "properties": { "OBJECTID": 842, "Coating": "None", "Constructi": "Brick/Mortar", "Date_Inspe": "00/00/00", "Depth": 11.0, "Infiltrati": "No", "Inflow": "No", "Lift_Stati": 4.0, "Manhole_Id": "LS4-156", "Rating": "Good", "SHAWN": 0.0, "LAYER": "SANMH", "FeatId1": 169.0 }, "geometry": { "type": "Point", "coordinates": [ -80.609777552883614, 28.345461023082965 ] } },
    { "type": "Feature", "properties": { "OBJECTID": 843, "Coating": "None", "Constructi": "Brick/Mortar", "Date_Inspe": "00/00/00", "Depth": 11.0, "Infiltrati": "No", "Inflow": "No", "Lift_Stati": 4.0, "Manhole_Id": "LS4-157", "Rating": "Good", "SHAWN": 0.0, "LAYER": "SANMH", "FeatId1": 168.0 }, "geometry": { "type": "Point", "coordinates": [ -80.609643150471527, 28.346344371956828 ] } },
    { "type": "Feature", "properties": { "OBJECTID": 844, "Coating": "None", "Constructi": "Brick/Mortar", "Date_Inspe": "00/00/00", "Depth": 11.0, "Infiltrati": "No", "Inflow": "No", "Lift_Stati": 4.0, "Manhole_Id": "LS4-158", "Rating": "Good", "SHAWN": 0.0, "LAYER": "SANMH", "FeatId1": 167.0 }, "geometry": { "type": "Point", "coordinates": [ -80.609546366235435, 28.347124195319964 ] } },
    { "type": "Feature", "properties": { "OBJECTID": 845, "Coating": "None", "Constructi": "Brick/Mortar", "Date_Inspe": "00/00/00", "Depth": 11.0, "Infiltrati": "No", "Inflow": "No", "Lift_Stati": 4.0, "Manhole_Id": "LS4-159", "Rating": "Good", "SHAWN": 0.0, "LAYER": "SANMH", "FeatId1": 166.0 }, "geometry": { "type": "Point", "coordinates": [ -80.609490604091917, 28.347557611833164 ] } },
    { "type": "Feature", "properties": { "OBJECTID": 846, "Coating": "None", "Constructi": "Brick/Mortar", "Date_Inspe": "00/00/00", "Depth": 11.0, "Infiltrati": "No", "Inflow": "No", "Lift_Stati": 4.0, "Manhole_Id": "LS4-160", "Rating": "Good", "SHAWN": 0.0, "LAYER": "SANMH", "FeatId1": 165.0 }, "geometry": { "type": "Point", "coordinates": [ -80.609441236437746, 28.347959359179466 ] } },
    { "type": "Feature", "properties": { "OBJECTID": 847, "Coating": "Spectrashield", "Constructi": "Brick/Mortar", "Date_Inspe": "09/11/13", "Depth": 10.0, "Infiltrati": "No", "Inflow": "No", "Lift_Stati": 4.0, "Manhole_Id": "LS4-161", "Rating": "Good", "SHAWN": 0.0, "LAYER": "SANMH", "FeatId1": 164.0 }, "geometry": { "type": "Point", "coordinates": [ -80.609390699721175, 28.348353512445865 ] } },
    { "type": "Feature", "properties": { "OBJECTID": 848, "Coating": "None", "Constructi": "Brick/Mortar", "Date_Inspe": "00/00/00", "Depth": 11.0, "Infiltrati": "No", "Inflow": "No", "Lift_Stati": 4.0, "Manhole_Id": "LS4-162", "Rating": "Good", "SHAWN": 0.0, "LAYER": "SANMH", "FeatId1": 163.0 }, "geometry": { "type": "Point", "coordinates": [ -80.609355791253734, 28.348646170999046 ] } },
    { "type": "Feature", "properties": { "OBJECTID": 849, "Coating": "None", "Constructi": "Brick/Mortar", "Date_Inspe": "00/00/00", "Depth": 11.0, "Infiltrati": "No", "Inflow": "No", "Lift_Stati": 4.0, "Manhole_Id": "LS4-163", "Rating": "Good", "SHAWN": 0.0, "LAYER": "SANMH", "FeatId1": 162.0 }, "geometry": { "type": "Point", "coordinates": [ -80.610278480360847, 28.348737310833553 ] } },
    { "type": "Feature", "properties": { "OBJECTID": 850, "Coating": "None", "Constructi": "Brick/Mortar", "Date_Inspe": "00/00/00", "Depth": 11.0, "Infiltrati": "No", "Inflow": "No", "Lift_Stati": 4.0, "Manhole_Id": "LS4-164", "Rating": "Good", "SHAWN": 0.0, "LAYER": "SANMH", "FeatId1": 161.0 }, "geometry": { "type": "Point", "coordinates": [ -80.611216706235652, 28.348828042053338 ] } },
    { "type": "Feature", "properties": { "OBJECTID": 851, "Coating": "None", "Constructi": "Brick/Mortar", "Date_Inspe": "00/00/00", "Depth": 11.0, "Infiltrati": "No", "Inflow": "No", "Lift_Stati": 4.0, "Manhole_Id": "LS4-165", "Rating": "Good", "SHAWN": 0.0, "LAYER": "SANMH", "FeatId1": 160.0 }, "geometry": { "type": "Point", "coordinates": [ -80.609193361794965, 28.349930566571235 ] } },
    { "type": "Feature", "properties": { "OBJECTID": 852, "Coating": "None", "Constructi": "Brick/Mortar", "Date_Inspe": "00/00/00", "Depth": 11.0, "Infiltrati": "No", "Inflow": "No", "Lift_Stati": 4.0, "Manhole_Id": "LS4-237", "Rating": "Good", "SHAWN": 0.0, "LAYER": "SANMH", "FeatId1": 159.0 }, "geometry": { "type": "Point", "coordinates": [ -80.609306338047233, 28.350201045390794 ] } },
    { "type": "Feature", "properties": { "OBJECTID": 853, "Coating": "None", "Constructi": "Brick/Mortar", "Date_Inspe": "00/00/00", "Depth": 11.0, "Infiltrati": "No", "Inflow": "No", "Lift_Stati": 4.0, "Manhole_Id": "LS4-166", "Rating": "Good", "SHAWN": 0.0, "LAYER": "SANMH", "FeatId1": 158.0 }, "geometry": { "type": "Point", "coordinates": [ -80.609094289759767, 28.350717675508719 ] } },
    { "type": "Feature", "properties": { "OBJECTID": 854, "Coating": "None", "Constructi": "Brick/Mortar", "Date_Inspe": "00/00/00", "Depth": 11.0, "Infiltrati": "No", "Inflow": "No", "Lift_Stati": 4.0, "Manhole_Id": "LS4-238", "Rating": "Good", "SHAWN": 0.0, "LAYER": "SANMH", "FeatId1": 157.0 }, "geometry": { "type": "Point", "coordinates": [ -80.610233235552755, 28.35029310994997 ] } },
    { "type": "Feature", "properties": { "OBJECTID": 855, "Coating": "None", "Constructi": "Brick/Mortar", "Date_Inspe": "03/24/09", "Depth": 11.0, "Infiltrati": "No", "Inflow": "No", "Lift_Stati": 7.0, "Manhole_Id": "LS7-012", "Rating": "Good", "SHAWN": 0.0, "LAYER": "SANMH", "FeatId1": 156.0 }, "geometry": { "type": "Point", "coordinates": [ -80.611234169439101, 28.350382182200644 ] } },
    { "type": "Feature", "properties": { "OBJECTID": 856, "Coating": "None", "Constructi": "Brick/Mortar", "Date_Inspe": "00/00/00", "Depth": 11.0, "Infiltrati": "No", "Inflow": "No", "Lift_Stati": 4.0, "Manhole_Id": "LS4-167", "Rating": "Good", "SHAWN": 0.0, "LAYER": "SANMH", "FeatId1": 155.0 }, "geometry": { "type": "Point", "coordinates": [ -80.608994645999175, 28.351513971404778 ] } },
    { "type": "Feature", "properties": { "OBJECTID": 857, "Coating": "None", "Constructi": "Brick/Mortar", "Date_Inspe": "00/00/00", "Depth": 11.0, "Infiltrati": "No", "Inflow": "No", "Lift_Stati": 4.0, "Manhole_Id": "LS4-168", "Rating": "Good", "SHAWN": 0.0, "LAYER": "SANMH", "FeatId1": 154.0 }, "geometry": { "type": "Point", "coordinates": [ -80.608843147515984, 28.352757408335268 ] } },
    { "type": "Feature", "properties": { "OBJECTID": 858, "Coating": "None", "Constructi": "Brick/Mortar", "Date_Inspe": "00/00/00", "Depth": 11.0, "Infiltrati": "No", "Inflow": "No", "Lift_Stati": 4.0, "Manhole_Id": "LS4-169", "Rating": "Good", "SHAWN": 0.0, "LAYER": "SANMH", "FeatId1": 153.0 }, "geometry": { "type": "Point", "coordinates": [ -80.608765299914381, 28.353404380034668 ] } },
    { "type": "Feature", "properties": { "OBJECTID": 859, "Coating": "None", "Constructi": "Brick/Mortar", "Date_Inspe": "00/00/00", "Depth": 11.0, "Infiltrati": "No", "Inflow": "No", "Lift_Stati": 4.0, "Manhole_Id": "LS4-170", "Rating": "Good", "SHAWN": 0.0, "LAYER": "SANMH", "FeatId1": 152.0 }, "geometry": { "type": "Point", "coordinates": [ -80.608751807846446, 28.353503407306867 ] } },
    { "type": "Feature", "properties": { "OBJECTID": 860, "Coating": "None", "Constructi": "Brick/Mortar", "Date_Inspe": "00/00/00", "Depth": 11.0, "Infiltrati": "No", "Inflow": "No", "Lift_Stati": 4.0, "Manhole_Id": "LS4-215", "Rating": "Good", "SHAWN": 0.0, "LAYER": "SANMH", "FeatId1": 151.0 }, "geometry": { "type": "Point", "coordinates": [ -80.609428253776002, 28.353384559478286 ] } },
    { "type": "Feature", "properties": { "OBJECTID": 861, "Coating": "None", "Constructi": "Brick/Mortar", "Date_Inspe": "00/00/00", "Depth": 11.0, "Infiltrati": "No", "Inflow": "No", "Lift_Stati": 4.0, "Manhole_Id": "LS4-216", "Rating": "Good", "SHAWN": 0.0, "LAYER": "SANMH", "FeatId1": 150.0 }, "geometry": { "type": "Point", "coordinates": [ -80.610586156688328, 28.353497912104267 ] } },
    { "type": "Feature", "properties": { "OBJECTID": 862, "Coating": "None", "Constructi": "Brick/Mortar", "Date_Inspe": "00/00/00", "Depth": 11.0, "Infiltrati": "No", "Inflow": "No", "Lift_Stati": 4.0, "Manhole_Id": "LS4-217", "Rating": "Good", "SHAWN": 0.0, "LAYER": "SANMH", "FeatId1": 149.0 }, "geometry": { "type": "Point", "coordinates": [ -80.611120198195493, 28.35352761677909 ] } },
    { "type": "Feature", "properties": { "OBJECTID": 863, "Coating": "None", "Constructi": "Brick/Mortar", "Date_Inspe": "00/00/00", "Depth": 0.0, "Infiltrati": "No", "Inflow": "No", "Lift_Stati": 5.0, "Manhole_Id": "LS5-125", "Rating": "Good", "SHAWN": 0.0, "LAYER": "SANMH", "FeatId1": 148.0 }, "geometry": { "type": "Point", "coordinates": [ -80.611635203415943, 28.353598927286431 ] } },
    { "type": "Feature", "properties": { "OBJECTID": 864, "Coating": "None", "Constructi": "Brick/Mortar", "Date_Inspe": "00/00/00", "Depth": 0.0, "Infiltrati": "No", "Inflow": "No", "Lift_Stati": 5.0, "Manhole_Id": "LS5-127", "Rating": "Good", "SHAWN": 0.0, "LAYER": "SANMH", "FeatId1": 147.0 }, "geometry": { "type": "Point", "coordinates": [ -80.611781273283057, 28.352466092516568 ] } },
    { "type": "Feature", "properties": { "OBJECTID": 865, "Coating": "None", "Constructi": "Brick/Mortar", "Date_Inspe": "00/00/00", "Depth": 0.0, "Infiltrati": "No", "Inflow": "No", "Lift_Stati": 5.0, "Manhole_Id": "LS5-128", "Rating": "Good", "SHAWN": 0.0, "LAYER": "SANMH", "FeatId1": 146.0 }, "geometry": { "type": "Point", "coordinates": [ -80.611784454271984, 28.352446883929087 ] } },
    { "type": "Feature", "properties": { "OBJECTID": 866, "Coating": "None", "Constructi": "Brick/Mortar", "Date_Inspe": "00/00/00", "Depth": 0.0, "Infiltrati": "No", "Inflow": "No", "Lift_Stati": 5.0, "Manhole_Id": "LS5-121", "Rating": "Good", "SHAWN": 0.0, "LAYER": "SANMH", "FeatId1": 145.0 }, "geometry": { "type": "Point", "coordinates": [ -80.611319457428564, 28.356334317741581 ] } },
    { "type": "Feature", "properties": { "OBJECTID": 867, "Coating": "None", "Constructi": "Brick/Mortar", "Date_Inspe": "00/00/00", "Depth": 0.0, "Infiltrati": "No", "Inflow": "No", "Lift_Stati": 5.0, "Manhole_Id": "LS5-120", "Rating": "Good", "SHAWN": 0.0, "LAYER": "SANMH", "FeatId1": 144.0 }, "geometry": { "type": "Point", "coordinates": [ -80.610600874687037, 28.356977886047076 ] } },
    { "type": "Feature", "properties": { "OBJECTID": 868, "Coating": "None", "Constructi": "Brick/Mortar", "Date_Inspe": "00/00/00", "Depth": 11.0, "Infiltrati": "No", "Inflow": "No", "Lift_Stati": 4.0, "Manhole_Id": "LS4-232", "Rating": "Good", "SHAWN": 0.0, "LAYER": "SANMH", "FeatId1": 143.0 }, "geometry": { "type": "Point", "coordinates": [ -80.609083552145023, 28.356944212643661 ] } },
    { "type": "Feature", "properties": { "OBJECTID": 869, "Coating": "None", "Constructi": "Brick/Mortar", "Date_Inspe": "00/00/00", "Depth": 11.0, "Infiltrati": "No", "Inflow": "No", "Lift_Stati": 4.0, "Manhole_Id": "LS4-233", "Rating": "Good", "SHAWN": 0.0, "LAYER": "SANMH", "FeatId1": 142.0 }, "geometry": { "type": "Point", "coordinates": [ -80.608326219778419, 28.356937565620271 ] } },
    { "type": "Feature", "properties": { "OBJECTID": 870, "Coating": "None", "Constructi": "Brick/Mortar", "Date_Inspe": "00/00/00", "Depth": 11.0, "Infiltrati": "No", "Inflow": "No", "Lift_Stati": 4.0, "Manhole_Id": "LS4-231", "Rating": "Good", "SHAWN": 0.0, "LAYER": "SANMH", "FeatId1": 141.0 }, "geometry": { "type": "Point", "coordinates": [ -80.609047052118285, 28.357391494187517 ] } },
    { "type": "Feature", "properties": { "OBJECTID": 871, "Coating": "None", "Constructi": "Brick/Mortar", "Date_Inspe": "00/00/00", "Depth": 11.0, "Infiltrati": "No", "Inflow": "No", "Lift_Stati": 4.0, "Manhole_Id": "LS4-230", "Rating": "Good", "SHAWN": 0.0, "LAYER": "SANMH", "FeatId1": 140.0 }, "geometry": { "type": "Point", "coordinates": [ -80.609050746703673, 28.357728712414559 ] } },
    { "type": "Feature", "properties": { "OBJECTID": 872, "Coating": "None", "Constructi": "Brick/Mortar", "Date_Inspe": "00/00/00", "Depth": 0.0, "Infiltrati": "No", "Inflow": "No", "Lift_Stati": 5.0, "Manhole_Id": "LS5-112", "Rating": "Good", "SHAWN": 0.0, "LAYER": "SANMH", "FeatId1": 139.0 }, "geometry": { "type": "Point", "coordinates": [ -80.609815224996936, 28.357724866980341 ] } },
    { "type": "Feature", "properties": { "OBJECTID": 873, "Coating": "None", "Constructi": "Brick/Mortar", "Date_Inspe": "00/00/00", "Depth": 0.0, "Infiltrati": "No", "Inflow": "No", "Lift_Stati": 5.0, "Manhole_Id": "LS5-111", "Rating": "Good", "SHAWN": 0.0, "LAYER": "SANMH", "FeatId1": 138.0 }, "geometry": { "type": "Point", "coordinates": [ -80.61043965517301, 28.357707614197238 ] } },
    { "type": "Feature", "properties": { "OBJECTID": 874, "Coating": "None", "Constructi": "Brick/Mortar", "Date_Inspe": "00/00/00", "Depth": 0.0, "Infiltrati": "No", "Inflow": "No", "Lift_Stati": 5.0, "Manhole_Id": "LS5-119", "Rating": "Good", "SHAWN": 0.0, "LAYER": "SANMH", "FeatId1": 137.0 }, "geometry": { "type": "Point", "coordinates": [ -80.610386496812509, 28.357584820358813 ] } },
    { "type": "Feature", "properties": { "OBJECTID": 875, "Coating": "None", "Constructi": "Brick/Mortar", "Date_Inspe": "00/00/00", "Depth": 0.0, "Infiltrati": "No", "Inflow": "No", "Lift_Stati": 5.0, "Manhole_Id": "LS5-118", "Rating": "Good", "SHAWN": 0.0, "LAYER": "SANMH", "FeatId1": 136.0 }, "geometry": { "type": "Point", "coordinates": [ -80.610466664443678, 28.357549632536653 ] } },
    { "type": "Feature", "properties": { "OBJECTID": 876, "Coating": "None", "Constructi": "Brick/Mortar", "Date_Inspe": "00/00/00", "Depth": 0.0, "Infiltrati": "No", "Inflow": "No", "Lift_Stati": 5.0, "Manhole_Id": "LS5-113", "Rating": "Good", "SHAWN": 0.0, "LAYER": "SANMH", "FeatId1": 135.0 }, "geometry": { "type": "Point", "coordinates": [ -80.610588054248652, 28.357703547897234 ] } },
    { "type": "Feature", "properties": { "OBJECTID": 877, "Coating": "None", "Constructi": "Brick/Mortar", "Date_Inspe": "00/00/00", "Depth": 0.0, "Infiltrati": "No", "Inflow": "No", "Lift_Stati": 5.0, "Manhole_Id": "LS5-114", "Rating": "Good", "SHAWN": 0.0, "LAYER": "SANMH", "FeatId1": 134.0 }, "geometry": { "type": "Point", "coordinates": [ -80.611758171160275, 28.357691502654994 ] } },
    { "type": "Feature", "properties": { "OBJECTID": 878, "Coating": "None", "Constructi": "Brick/Mortar", "Date_Inspe": "00/00/00", "Depth": 0.0, "Infiltrati": "No", "Inflow": "No", "Lift_Stati": 5.0, "Manhole_Id": "LS5-115", "Rating": "Good", "SHAWN": 0.0, "LAYER": "SANMH", "FeatId1": 133.0 }, "geometry": { "type": "Point", "coordinates": [ -80.612920396755428, 28.357681633052817 ] } },
    { "type": "Feature", "properties": { "OBJECTID": 879, "Coating": "None", "Constructi": "Brick/Mortar", "Date_Inspe": "00/00/00", "Depth": 0.0, "Infiltrati": "No", "Inflow": "No", "Lift_Stati": 5.0, "Manhole_Id": "LS5-116", "Rating": "Good", "SHAWN": 0.0, "LAYER": "SANMH", "FeatId1": 132.0 }, "geometry": { "type": "Point", "coordinates": [ -80.613377175300883, 28.357675182091722 ] } },
    { "type": "Feature", "properties": { "OBJECTID": 880, "Coating": "None", "Constructi": "Brick/Mortar", "Date_Inspe": "00/00/00", "Depth": 0.0, "Infiltrati": "No", "Inflow": "No", "Lift_Stati": 5.0, "Manhole_Id": "LS5-117", "Rating": "Good", "SHAWN": 0.0, "LAYER": "SANMH", "FeatId1": 131.0 }, "geometry": { "type": "Point", "coordinates": [ -80.614007219800826, 28.357669923997811 ] } },
    { "type": "Feature", "properties": { "OBJECTID": 881, "Coating": "None", "Constructi": "Brick/Mortar", "Date_Inspe": "00/00/00", "Depth": 0.0, "Infiltrati": "No", "Inflow": "No", "Lift_Stati": 5.0, "Manhole_Id": "LS5-108", "Rating": "Good", "SHAWN": 0.0, "LAYER": "SANMH", "FeatId1": 130.0 }, "geometry": { "type": "Point", "coordinates": [ -80.610592687804015, 28.358173343053615 ] } },
    { "type": "Feature", "properties": { "OBJECTID": 882, "Coating": "None", "Constructi": "Brick/Mortar", "Date_Inspe": "00/00/00", "Depth": 0.0, "Infiltrati": "No", "Inflow": "No", "Lift_Stati": 5.0, "Manhole_Id": "LS5-107", "Rating": "Good", "SHAWN": 0.0, "LAYER": "SANMH", "FeatId1": 129.0 }, "geometry": { "type": "Point", "coordinates": [ -80.610544539854956, 28.35826376579837 ] } },
    { "type": "Feature", "properties": { "OBJECTID": 883, "Coating": "None", "Constructi": "Brick/Mortar", "Date_Inspe": "03/24/09", "Depth": 11.0, "Infiltrati": "No", "Inflow": "No", "Lift_Stati": 7.0, "Manhole_Id": "LS7-012", "Rating": "Good", "SHAWN": 0.0, "LAYER": "SANMH", "FeatId1": 128.0 }, "geometry": { "type": "Point", "coordinates": [ -80.611847007787148, 28.358037239707439 ] } },
    { "type": "Feature", "properties": { "OBJECTID": 884, "Coating": "None", "Constructi": "Brick/Mortar", "Date_Inspe": "03/24/09", "Depth": 11.0, "Infiltrati": "No", "Inflow": "No", "Lift_Stati": 7.0, "Manhole_Id": "LS7-012", "Rating": "Good", "SHAWN": 0.0, "LAYER": "SANMH", "FeatId1": 127.0 }, "geometry": { "type": "Point", "coordinates": [ -80.61240487044293, 28.35803231865302 ] } },
    { "type": "Feature", "properties": { "OBJECTID": 885, "Coating": "None", "Constructi": "Brick/Mortar", "Date_Inspe": "03/24/09", "Depth": 11.0, "Infiltrati": "No", "Inflow": "No", "Lift_Stati": 7.0, "Manhole_Id": "LS7-012", "Rating": "Good", "SHAWN": 0.0, "LAYER": "SANMH", "FeatId1": 126.0 }, "geometry": { "type": "Point", "coordinates": [ -80.612886116197473, 28.358148800144541 ] } },
    { "type": "Feature", "properties": { "OBJECTID": 886, "Coating": "None", "Constructi": "Brick/Mortar", "Date_Inspe": "03/24/09", "Depth": 11.0, "Infiltrati": "No", "Inflow": "No", "Lift_Stati": 7.0, "Manhole_Id": "LS7-012", "Rating": "Good", "SHAWN": 0.0, "LAYER": "SANMH", "FeatId1": 125.0 }, "geometry": { "type": "Point", "coordinates": [ -80.614053355028247, 28.358138755581649 ] } },
    { "type": "Feature", "properties": { "OBJECTID": 887, "Coating": "None", "Constructi": "Brick/Mortar", "Date_Inspe": "04/27/09", "Depth": 2.8, "Infiltrati": "Probable", "Inflow": "No", "Lift_Stati": 1.0, "Manhole_Id": "LS1-043", "Rating": "Fair", "SHAWN": 0.0, "LAYER": "SANMH", "FeatId1": 124.0 }, "geometry": { "type": "Point", "coordinates": [ -80.610437874618114, 28.309551726547294 ] } },
    { "type": "Feature", "properties": { "OBJECTID": 888, "Coating": "None", "Constructi": "Brick/Mortar", "Date_Inspe": "05/04/09", "Depth": 2.83, "Infiltrati": "No", "Inflow": "No", "Lift_Stati": 1.0, "Manhole_Id": "LS1-073", "Rating": "Fair", "SHAWN": 0.0, "LAYER": "SANMH", "FeatId1": 123.0 }, "geometry": { "type": "Point", "coordinates": [ -80.608720648875632, 28.321393908781733 ] } },
    { "type": "Feature", "properties": { "OBJECTID": 889, "Coating": "None", "Constructi": "Brick/Mortar", "Date_Inspe": "05/05/09", "Depth": 4.0, "Infiltrati": "No", "Inflow": "No", "Lift_Stati": 1.0, "Manhole_Id": "LS1-087", "Rating": "Good", "SHAWN": 0.0, "LAYER": "SANMH", "FeatId1": 122.0 }, "geometry": { "type": "Point", "coordinates": [ -80.608819604146163, 28.324538034616012 ] } },
    { "type": "Feature", "properties": { "OBJECTID": 890, "Coating": "None", "Constructi": "Brick/Mortar", "Date_Inspe": "05/07/09", "Depth": 2.9, "Infiltrati": "No", "Inflow": "No", "Lift_Stati": 1.0, "Manhole_Id": "LS1-090", "Rating": "Good", "SHAWN": 0.0, "LAYER": "SANMH", "FeatId1": 121.0 }, "geometry": { "type": "Point", "coordinates": [ -80.609762470442732, 28.32506493728604 ] } },
    { "type": "Feature", "properties": { "OBJECTID": 891, "Coating": "None", "Constructi": "Brick/Mortar", "Date_Inspe": "00/00/00", "Depth": 8.0, "Infiltrati": "No", "Inflow": "No", "Lift_Stati": 1.0, "Manhole_Id": "LS1-190", "Rating": "Good", "SHAWN": 0.0, "LAYER": "SANMH", "FeatId1": 120.0 }, "geometry": { "type": "Point", "coordinates": [ -80.610180893587511, 28.31840887338852 ] } },
    { "type": "Feature", "properties": { "OBJECTID": 892, "Coating": "Fiberglass", "Constructi": "Brick/Mortar", "Date_Inspe": "08/02/13", "Depth": 8.6, "Infiltrati": "No", "Inflow": "No", "Lift_Stati": 4.0, "Manhole_Id": "LS4-147", "Rating": "Good", "SHAWN": 0.0, "LAYER": "SANMH", "FeatId1": 119.0 }, "geometry": { "type": "Point", "coordinates": [ -80.610150956052692, 28.341696251382576 ] } },
    { "type": "Feature", "properties": { "OBJECTID": 893, "Coating": "None", "Constructi": "Brick/Mortar", "Date_Inspe": "00/00/00", "Depth": 0.0, "Infiltrati": "No", "Inflow": "No", "Lift_Stati": 5.0, "Manhole_Id": "LS5-077", "Rating": "Good", "SHAWN": 0.0, "LAYER": "SANMH", "FeatId1": 118.0 }, "geometry": { "type": "Point", "coordinates": [ -80.60623770345336, 28.359989853100153 ] } },
    { "type": "Feature", "properties": { "OBJECTID": 894, "Coating": "None", "Constructi": "Brick/Mortar", "Date_Inspe": "00/00/00", "Depth": 0.0, "Infiltrati": "No", "Inflow": "No", "Lift_Stati": 5.0, "Manhole_Id": "LS5-078", "Rating": "Good", "SHAWN": 0.0, "LAYER": "SANMH", "FeatId1": 117.0 }, "geometry": { "type": "Point", "coordinates": [ -80.607316913940025, 28.360126649394566 ] } },
    { "type": "Feature", "properties": { "OBJECTID": 895, "Coating": "None", "Constructi": "Brick/Mortar", "Date_Inspe": "00/00/00", "Depth": 0.0, "Infiltrati": "No", "Inflow": "No", "Lift_Stati": 5.0, "Manhole_Id": "LS5-067", "Rating": "Good", "SHAWN": 0.0, "LAYER": "SANMH", "FeatId1": 116.0 }, "geometry": { "type": "Point", "coordinates": [ -80.606748385261312, 28.363591008857103 ] } },
    { "type": "Feature", "properties": { "OBJECTID": 896, "Coating": "None", "Constructi": "Brick/Mortar", "Date_Inspe": "00/00/00", "Depth": 11.0, "Infiltrati": "No", "Inflow": "No", "Lift_Stati": 8.0, "Manhole_Id": "LS8-035", "Rating": "Good", "SHAWN": 0.0, "LAYER": "SANMH", "FeatId1": 115.0 }, "geometry": { "type": "Point", "coordinates": [ -80.603056961874771, 28.368695395136058 ] } },
    { "type": "Feature", "properties": { "OBJECTID": 897, "Coating": "None", "Constructi": "Brick/Mortar", "Date_Inspe": "00/00/00", "Depth": 11.0, "Infiltrati": "No", "Inflow": "No", "Lift_Stati": 8.0, "Manhole_Id": "LS8-034", "Rating": "Good", "SHAWN": 0.0, "LAYER": "SANMH", "FeatId1": 114.0 }, "geometry": { "type": "Point", "coordinates": [ -80.603461705827783, 28.368698038431425 ] } },
    { "type": "Feature", "properties": { "OBJECTID": 898, "Coating": "None", "Constructi": "Brick/Mortar", "Date_Inspe": "00/00/00", "Depth": 11.0, "Infiltrati": "No", "Inflow": "No", "Lift_Stati": 8.0, "Manhole_Id": "LS8-033", "Rating": "Good", "SHAWN": 0.0, "LAYER": "SANMH", "FeatId1": 113.0 }, "geometry": { "type": "Point", "coordinates": [ -80.60430170643788, 28.368701775807626 ] } },
    { "type": "Feature", "properties": { "OBJECTID": 899, "Coating": "None", "Constructi": "Brick/Mortar", "Date_Inspe": "00/00/00", "Depth": 11.0, "Infiltrati": "No", "Inflow": "No", "Lift_Stati": 8.0, "Manhole_Id": "LS8-032", "Rating": "Good", "SHAWN": 0.0, "LAYER": "SANMH", "FeatId1": 112.0 }, "geometry": { "type": "Point", "coordinates": [ -80.605497792236989, 28.368707835576949 ] } },
    { "type": "Feature", "properties": { "OBJECTID": 900, "Coating": "None", "Constructi": "Brick/Mortar", "Date_Inspe": "00/00/00", "Depth": 11.0, "Infiltrati": "No", "Inflow": "No", "Lift_Stati": 8.0, "Manhole_Id": "LS8-029", "Rating": "Good", "SHAWN": 0.0, "LAYER": "SANMH", "FeatId1": 111.0 }, "geometry": { "type": "Point", "coordinates": [ -80.605456507874365, 28.369575222748207 ] } },
    { "type": "Feature", "properties": { "OBJECTID": 901, "Coating": "None", "Constructi": "Brick/Mortar", "Date_Inspe": "00/00/00", "Depth": 11.0, "Infiltrati": "No", "Inflow": "No", "Lift_Stati": 8.0, "Manhole_Id": "LS8-030", "Rating": "Good", "SHAWN": 0.0, "LAYER": "SANMH", "FeatId1": 110.0 }, "geometry": { "type": "Point", "coordinates": [ -80.604406909635955, 28.36957705744695 ] } },
    { "type": "Feature", "properties": { "OBJECTID": 902, "Coating": "None", "Constructi": "Brick/Mortar", "Date_Inspe": "00/00/00", "Depth": 11.0, "Infiltrati": "No", "Inflow": "No", "Lift_Stati": 8.0, "Manhole_Id": "LS8-031", "Rating": "Good", "SHAWN": 0.0, "LAYER": "SANMH", "FeatId1": 109.0 }, "geometry": { "type": "Point", "coordinates": [ -80.603551063957951, 28.369578132002705 ] } },
    { "type": "Feature", "properties": { "OBJECTID": 903, "Coating": "None", "Constructi": "Brick/Mortar", "Date_Inspe": "00/00/00", "Depth": 11.0, "Infiltrati": "No", "Inflow": "No", "Lift_Stati": 8.0, "Manhole_Id": "LS8-050", "Rating": "Good", "SHAWN": 0.0, "LAYER": "SANMH", "FeatId1": 108.0 }, "geometry": { "type": "Point", "coordinates": [ -80.602755528042863, 28.370389008588734 ] } },
    { "type": "Feature", "properties": { "OBJECTID": 904, "Coating": "None", "Constructi": "Brick/Mortar", "Date_Inspe": "00/00/00", "Depth": 11.0, "Infiltrati": "No", "Inflow": "No", "Lift_Stati": 8.0, "Manhole_Id": "LS8-049", "Rating": "Good", "SHAWN": 0.0, "LAYER": "SANMH", "FeatId1": 107.0 }, "geometry": { "type": "Point", "coordinates": [ -80.603996526343678, 28.370406759139193 ] } },
    { "type": "Feature", "properties": { "OBJECTID": 905, "Coating": "None", "Constructi": "Brick/Mortar", "Date_Inspe": "00/00/00", "Depth": 11.0, "Infiltrati": "No", "Inflow": "No", "Lift_Stati": 8.0, "Manhole_Id": "LS8-048", "Rating": "Good", "SHAWN": 0.0, "LAYER": "SANMH", "FeatId1": 106.0 }, "geometry": { "type": "Point", "coordinates": [ -80.605228895700122, 28.370411695974624 ] } },
    { "type": "Feature", "properties": { "OBJECTID": 906, "Coating": "None", "Constructi": "Brick/Mortar", "Date_Inspe": "00/00/00", "Depth": 11.0, "Infiltrati": "No", "Inflow": "No", "Lift_Stati": 8.0, "Manhole_Id": "LS8-047", "Rating": "Good", "SHAWN": 0.0, "LAYER": "SANMH", "FeatId1": 105.0 }, "geometry": { "type": "Point", "coordinates": [ -80.605479255566905, 28.37041108704814 ] } },
    { "type": "Feature", "properties": { "OBJECTID": 907, "Coating": "None", "Constructi": "Brick/Mortar", "Date_Inspe": "00/00/00", "Depth": 11.0, "Infiltrati": "No", "Inflow": "No", "Lift_Stati": 8.0, "Manhole_Id": "LS8-053", "Rating": "Good", "SHAWN": 0.0, "LAYER": "SANMH", "FeatId1": 104.0 }, "geometry": { "type": "Point", "coordinates": [ -80.605186178690587, 28.371219363138337 ] } },
    { "type": "Feature", "properties": { "OBJECTID": 908, "Coating": "None", "Constructi": "Brick/Mortar", "Date_Inspe": "00/00/00", "Depth": 11.0, "Infiltrati": "No", "Inflow": "No", "Lift_Stati": 8.0, "Manhole_Id": "LS8-052", "Rating": "Good", "SHAWN": 0.0, "LAYER": "SANMH", "FeatId1": 103.0 }, "geometry": { "type": "Point", "coordinates": [ -80.604470898585987, 28.3712166219415 ] } },
    { "type": "Feature", "properties": { "OBJECTID": 909, "Coating": "None", "Constructi": "Brick/Mortar", "Date_Inspe": "00/00/00", "Depth": 11.0, "Infiltrati": "No", "Inflow": "No", "Lift_Stati": 8.0, "Manhole_Id": "LS8-051", "Rating": "Good", "SHAWN": 0.0, "LAYER": "SANMH", "FeatId1": 102.0 }, "geometry": { "type": "Point", "coordinates": [ -80.603815872668093, 28.371215518453482 ] } },
    { "type": "Feature", "properties": { "OBJECTID": 910, "Coating": "None", "Constructi": "Brick/Mortar", "Date_Inspe": "00/00/00", "Depth": 11.0, "Infiltrati": "No", "Inflow": "No", "Lift_Stati": 8.0, "Manhole_Id": "LS8-054", "Rating": "Good", "SHAWN": 0.0, "LAYER": "SANMH", "FeatId1": 101.0 }, "geometry": { "type": "Point", "coordinates": [ -80.603077021811686, 28.371192154682952 ] } },
    { "type": "Feature", "properties": { "OBJECTID": 911, "Coating": "None", "Constructi": "Brick/Mortar", "Date_Inspe": "00/00/00", "Depth": 11.0, "Infiltrati": "No", "Inflow": "No", "Lift_Stati": 8.0, "Manhole_Id": "LS8-058", "Rating": "Good", "SHAWN": 0.0, "LAYER": "SANMH", "FeatId1": 100.0 }, "geometry": { "type": "Point", "coordinates": [ -80.602695981309168, 28.372057884923635 ] } },
    { "type": "Feature", "properties": { "OBJECTID": 912, "Coating": "None", "Constructi": "Brick/Mortar", "Date_Inspe": "00/00/00", "Depth": 11.0, "Infiltrati": "No", "Inflow": "No", "Lift_Stati": 8.0, "Manhole_Id": "LS8-055", "Rating": "Good", "SHAWN": 0.0, "LAYER": "SANMH", "FeatId1": 99.0 }, "geometry": { "type": "Point", "coordinates": [ -80.603619997054011, 28.372065835593396 ] } },
    { "type": "Feature", "properties": { "OBJECTID": 913, "Coating": "None", "Constructi": "Brick/Mortar", "Date_Inspe": "00/00/00", "Depth": 11.0, "Infiltrati": "No", "Inflow": "No", "Lift_Stati": 8.0, "Manhole_Id": "LS8-056", "Rating": "Good", "SHAWN": 0.0, "LAYER": "SANMH", "FeatId1": 98.0 }, "geometry": { "type": "Point", "coordinates": [ -80.60443011548098, 28.372041731561676 ] } },
    { "type": "Feature", "properties": { "OBJECTID": 914, "Coating": "None", "Constructi": "Brick/Mortar", "Date_Inspe": "00/00/00", "Depth": 11.0, "Infiltrati": "No", "Inflow": "No", "Lift_Stati": 8.0, "Manhole_Id": "LS8-057", "Rating": "Good", "SHAWN": 0.0, "LAYER": "SANMH", "FeatId1": 97.0 }, "geometry": { "type": "Point", "coordinates": [ -80.605259377676958, 28.372042829652468 ] } },
    { "type": "Feature", "properties": { "OBJECTID": 915, "Coating": "None", "Constructi": "Brick/Mortar", "Date_Inspe": "00/00/00", "Depth": 0.0, "Infiltrati": "No", "Inflow": "No", "Lift_Stati": 5.0, "Manhole_Id": "LS5-133", "Rating": "Good", "SHAWN": 0.0, "LAYER": "SANMH", "FeatId1": 96.0 }, "geometry": { "type": "Point", "coordinates": [ -80.606407972946315, 28.368532800414936 ] } },
    { "type": "Feature", "properties": { "OBJECTID": 916, "Coating": "None", "Constructi": "Brick/Mortar", "Date_Inspe": "00/00/00", "Depth": 0.0, "Infiltrati": "No", "Inflow": "No", "Lift_Stati": 5.0, "Manhole_Id": "LS5-132", "Rating": "Good", "SHAWN": 0.0, "LAYER": "SANMH", "FeatId1": 95.0 }, "geometry": { "type": "Point", "coordinates": [ -80.607650608416435, 28.368538336793716 ] } },
    { "type": "Feature", "properties": { "OBJECTID": 917, "Coating": "None", "Constructi": "Brick/Mortar", "Date_Inspe": "00/00/00", "Depth": 0.0, "Infiltrati": "No", "Inflow": "No", "Lift_Stati": 5.0, "Manhole_Id": "LS5-131", "Rating": "Good", "SHAWN": 0.0, "LAYER": "SANMH", "FeatId1": 94.0 }, "geometry": { "type": "Point", "coordinates": [ -80.608891207571304, 28.368543104337395 ] } },
    { "type": "Feature", "properties": { "OBJECTID": 918, "Coating": "None", "Constructi": "Brick/Mortar", "Date_Inspe": "00/00/00", "Depth": 0.0, "Infiltrati": "No", "Inflow": "No", "Lift_Stati": 5.0, "Manhole_Id": "LS5-130", "Rating": "Good", "SHAWN": 0.0, "LAYER": "SANMH", "FeatId1": 93.0 }, "geometry": { "type": "Point", "coordinates": [ -80.608970658317247, 28.368546676395702 ] } },
    { "type": "Feature", "properties": { "OBJECTID": 919, "Coating": "None", "Constructi": "Brick/Mortar", "Date_Inspe": "00/00/00", "Depth": 0.0, "Infiltrati": "No", "Inflow": "No", "Lift_Stati": 5.0, "Manhole_Id": "LS5-129", "Rating": "Good", "SHAWN": 0.0, "LAYER": "SANMH", "FeatId1": 92.0 }, "geometry": { "type": "Point", "coordinates": [ -80.609021198259882, 28.368749394290418 ] } },
    { "type": "Feature", "properties": { "OBJECTID": 920, "Coating": "None", "Constructi": "Brick/Mortar", "Date_Inspe": "00/00/00", "Depth": 0.0, "Infiltrati": "No", "Inflow": "No", "Lift_Stati": 8.0, "Manhole_Id": "LS8-017", "Rating": "Good", "SHAWN": 0.0, "LAYER": "SANMH", "FeatId1": 91.0 }, "geometry": { "type": "Point", "coordinates": [ -80.60860726755449, 28.369971195023037 ] } },
    { "type": "Feature", "properties": { "OBJECTID": 921, "Coating": "None", "Constructi": "Brick/Mortar", "Date_Inspe": "00/00/00", "Depth": 0.0, "Infiltrati": "No", "Inflow": "No", "Lift_Stati": 8.0, "Manhole_Id": "LS8-018", "Rating": "Good", "SHAWN": 0.0, "LAYER": "SANMH", "FeatId1": 90.0 }, "geometry": { "type": "Point", "coordinates": [ -80.60797980652562, 28.369955477731953 ] } },
    { "type": "Feature", "properties": { "OBJECTID": 922, "Coating": "None", "Constructi": "Brick/Mortar", "Date_Inspe": "00/00/00", "Depth": 11.0, "Infiltrati": "No", "Inflow": "No", "Lift_Stati": 8.0, "Manhole_Id": "LS8-020", "Rating": "Good", "SHAWN": 0.0, "LAYER": "SANMH", "FeatId1": 89.0 }, "geometry": { "type": "Point", "coordinates": [ -80.607879586670819, 28.369959887196309 ] } },
    { "type": "Feature", "properties": { "OBJECTID": 923, "Coating": "None", "Constructi": "Brick/Mortar", "Date_Inspe": "00/00/00", "Depth": 11.0, "Infiltrati": "No", "Inflow": "No", "Lift_Stati": 8.0, "Manhole_Id": "LS8-025", "Rating": "Good", "SHAWN": 0.0, "LAYER": "SANMH", "FeatId1": 88.0 }, "geometry": { "type": "Point", "coordinates": [ -80.60750978359053, 28.369962026401602 ] } },
    { "type": "Feature", "properties": { "OBJECTID": 924, "Coating": "None", "Constructi": "Brick/Mortar", "Date_Inspe": "00/00/00", "Depth": 11.0, "Infiltrati": "No", "Inflow": "No", "Lift_Stati": 8.0, "Manhole_Id": "LS8-021", "Rating": "Good", "SHAWN": 0.0, "LAYER": "SANMH", "FeatId1": 87.0 }, "geometry": { "type": "Point", "coordinates": [ -80.607507050520013, 28.370150517286344 ] } },
    { "type": "Feature", "properties": { "OBJECTID": 925, "Coating": "None", "Constructi": "Brick/Mortar", "Date_Inspe": "00/00/00", "Depth": 11.0, "Infiltrati": "No", "Inflow": "No", "Lift_Stati": 8.0, "Manhole_Id": "LS8-022", "Rating": "Good", "SHAWN": 0.0, "LAYER": "SANMH", "FeatId1": 86.0 }, "geometry": { "type": "Point", "coordinates": [ -80.60725601200636, 28.370390981009379 ] } },
    { "type": "Feature", "properties": { "OBJECTID": 926, "Coating": "None", "Constructi": "Brick/Mortar", "Date_Inspe": "00/00/00", "Depth": 11.0, "Infiltrati": "No", "Inflow": "No", "Lift_Stati": 8.0, "Manhole_Id": "LS8-023", "Rating": "Good", "SHAWN": 0.0, "LAYER": "SANMH", "FeatId1": 85.0 }, "geometry": { "type": "Point", "coordinates": [ -80.606618795484977, 28.370396303075783 ] } },
    { "type": "Feature", "properties": { "OBJECTID": 927, "Coating": "None", "Constructi": "Brick/Mortar", "Date_Inspe": "00/00/00", "Depth": 11.0, "Infiltrati": "No", "Inflow": "No", "Lift_Stati": 8.0, "Manhole_Id": "LS8-026", "Rating": "Good", "SHAWN": 0.0, "LAYER": "SANMH", "FeatId1": 84.0 }, "geometry": { "type": "Point", "coordinates": [ -80.606788794661398, 28.369953710714043 ] } },
    { "type": "Feature", "properties": { "OBJECTID": 928, "Coating": "None", "Constructi": "Brick/Mortar", "Date_Inspe": "00/00/00", "Depth": 11.0, "Infiltrati": "No", "Inflow": "No", "Lift_Stati": 8.0, "Manhole_Id": "LS8-024", "Rating": "Good", "SHAWN": 0.0, "LAYER": "SANMH", "FeatId1": 83.0 }, "geometry": { "type": "Point", "coordinates": [ -80.606028508637948, 28.370388360439467 ] } },
    { "type": "Feature", "properties": { "OBJECTID": 929, "Coating": "None", "Constructi": "Brick/Mortar", "Date_Inspe": "00/00/00", "Depth": 11.0, "Infiltrati": "No", "Inflow": "No", "Lift_Stati": 8.0, "Manhole_Id": "LS8-027", "Rating": "Good", "SHAWN": 0.0, "LAYER": "SANMH", "FeatId1": 82.0 }, "geometry": { "type": "Point", "coordinates": [ -80.605732252386517, 28.369954933687058 ] } },
    { "type": "Feature", "properties": { "OBJECTID": 930, "Coating": "None", "Constructi": "Brick/Mortar", "Date_Inspe": "00/00/00", "Depth": 11.0, "Infiltrati": "No", "Inflow": "No", "Lift_Stati": 8.0, "Manhole_Id": "LS8-028", "Rating": "Good", "SHAWN": 0.0, "LAYER": "SANMH", "FeatId1": 81.0 }, "geometry": { "type": "Point", "coordinates": [ -80.605495792013002, 28.369953845361561 ] } },
    { "type": "Feature", "properties": { "OBJECTID": 931, "Coating": "None", "Constructi": "Brick/Mortar", "Date_Inspe": "03/24/09", "Depth": 11.0, "Infiltrati": "No", "Inflow": "No", "Lift_Stati": 8.0, "Manhole_Id": "LS8-019", "Rating": "Good", "SHAWN": 0.0, "LAYER": "SANMH", "FeatId1": 80.0 }, "geometry": { "type": "Point", "coordinates": [ -80.607938744388022, 28.369423143329076 ] } },
    { "type": "Feature", "properties": { "OBJECTID": 932, "Coating": "None", "Constructi": "Brick/Mortar", "Date_Inspe": "00/00/00", "Depth": 11.0, "Infiltrati": "No", "Inflow": "No", "Lift_Stati": 4.0, "Manhole_Id": "LS4-196", "Rating": "Good", "SHAWN": 0.0, "LAYER": "SANMH", "FeatId1": 79.0 }, "geometry": { "type": "Point", "coordinates": [ -80.608754884876845, 28.351001953047351 ] } },
    { "type": "Feature", "properties": { "OBJECTID": 933, "Coating": "None", "Constructi": "Brick/Mortar", "Date_Inspe": "00/00/00", "Depth": 11.0, "Infiltrati": "No", "Inflow": "No", "Lift_Stati": 4.0, "Manhole_Id": "LS4-197", "Rating": "Good", "SHAWN": 0.0, "LAYER": "SANMH", "FeatId1": 78.0 }, "geometry": { "type": "Point", "coordinates": [ -80.608854119999904, 28.35021146191384 ] } },
    { "type": "Feature", "properties": { "OBJECTID": 934, "Coating": "None", "Constructi": "Brick/Mortar", "Date_Inspe": "00/00/00", "Depth": 11.0, "Infiltrati": "No", "Inflow": "No", "Lift_Stati": 4.0, "Manhole_Id": "LS4-198", "Rating": "Good", "SHAWN": 0.0, "LAYER": "SANMH", "FeatId1": 77.0 }, "geometry": { "type": "Point", "coordinates": [ -80.608948196337579, 28.349417927481891 ] } },
    { "type": "Feature", "properties": { "OBJECTID": 935, "Coating": "None", "Constructi": "Brick/Mortar", "Date_Inspe": "00/00/00", "Depth": 11.0, "Infiltrati": "No", "Inflow": "No", "Lift_Stati": 4.0, "Manhole_Id": "LS4-199", "Rating": "Good", "SHAWN": 0.0, "LAYER": "SANMH", "FeatId1": 76.0 }, "geometry": { "type": "Point", "coordinates": [ -80.608988206384183, 28.349107129037833 ] } },
    { "type": "Feature", "properties": { "OBJECTID": 936, "Coating": "None", "Constructi": "Brick/Mortar", "Date_Inspe": "00/00/00", "Depth": 11.0, "Infiltrati": "No", "Inflow": "No", "Lift_Stati": 4.0, "Manhole_Id": "LS4-200", "Rating": "Good", "SHAWN": 0.0, "LAYER": "SANMH", "FeatId1": 75.0 }, "geometry": { "type": "Point", "coordinates": [ -80.607941984639709, 28.349102006028755 ] } },
    { "type": "Feature", "properties": { "OBJECTID": 937, "Coating": "None", "Constructi": "Brick/Mortar", "Date_Inspe": "00/00/00", "Depth": 11.0, "Infiltrati": "No", "Inflow": "No", "Lift_Stati": 4.0, "Manhole_Id": "LS4-201", "Rating": "Good", "SHAWN": 0.0, "LAYER": "SANMH", "FeatId1": 74.0 }, "geometry": { "type": "Point", "coordinates": [ -80.60695926663827, 28.349096332508104 ] } },
    { "type": "Feature", "properties": { "OBJECTID": 938, "Coating": "None", "Constructi": "Brick/Mortar", "Date_Inspe": "00/00/00", "Depth": 11.0, "Infiltrati": "No", "Inflow": "No", "Lift_Stati": 4.0, "Manhole_Id": "LS4-202", "Rating": "Good", "SHAWN": 0.0, "LAYER": "SANMH", "FeatId1": 73.0 }, "geometry": { "type": "Point", "coordinates": [ -80.606919603320634, 28.34937175684394 ] } },
    { "type": "Feature", "properties": { "OBJECTID": 939, "Coating": "None", "Constructi": "Brick/Mortar", "Date_Inspe": "00/00/00", "Depth": 11.0, "Infiltrati": "No", "Inflow": "No", "Lift_Stati": 4.0, "Manhole_Id": "LS4-203", "Rating": "Good", "SHAWN": 0.0, "LAYER": "SANMH", "FeatId1": 72.0 }, "geometry": { "type": "Point", "coordinates": [ -80.609077841875916, 28.348428832501835 ] } },
    { "type": "Feature", "properties": { "OBJECTID": 940, "Coating": "Mortar", "Constructi": "Brick/Mortar", "Date_Inspe": "08/13/13", "Depth": 4.42, "Infiltrati": "No", "Inflow": "No", "Lift_Stati": 4.0, "Manhole_Id": "LS4-204", "Rating": "Good", "SHAWN": 0.0, "LAYER": "SANMH", "FeatId1": 71.0 }, "geometry": { "type": "Point", "coordinates": [ -80.609317100043285, 28.346503613567595 ] } },
    { "type": "Feature", "properties": { "OBJECTID": 941, "Coating": "None", "Constructi": "Brick/Mortar", "Date_Inspe": "08/13/13", "Depth": 2.33, "Infiltrati": "No", "Inflow": "No", "Lift_Stati": 4.0, "Manhole_Id": "LS4-205", "Rating": "Good", "SHAWN": 0.0, "LAYER": "SANMH", "FeatId1": 70.0 }, "geometry": { "type": "Point", "coordinates": [ -80.609463196507676, 28.345356159273255 ] } },
    { "type": "Feature", "properties": { "OBJECTID": 942, "Coating": "Mortar", "Constructi": "Brick/Mortar", "Date_Inspe": "08/13/13", "Depth": 3.5, "Infiltrati": "No", "Inflow": "No", "Lift_Stati": 4.0, "Manhole_Id": "LS4-206", "Rating": "Good", "SHAWN": 0.0, "LAYER": "SANMH", "FeatId1": 69.0 }, "geometry": { "type": "Point", "coordinates": [ -80.609597426159127, 28.344266542907903 ] } },
    { "type": "Feature", "properties": { "OBJECTID": 943, "Coating": "None", "Constructi": "Brick/Mortar", "Date_Inspe": "08/13/13", "Depth": 3.33, "Infiltrati": "Yes", "Inflow": "No", "Lift_Stati": 4.0, "Manhole_Id": "LS4-207", "Rating": "Good", "SHAWN": 0.0, "LAYER": "SANMH", "FeatId1": 68.0 }, "geometry": { "type": "Point", "coordinates": [ -80.609710789298958, 28.343320495038334 ] } },
    { "type": "Feature", "properties": { "OBJECTID": 944, "Coating": "None", "Constructi": "Brick/Mortar", "Date_Inspe": "08/13/13", "Depth": 3.5, "Infiltrati": "Probable", "Inflow": "No", "Lift_Stati": 4.0, "Manhole_Id": "LS4-208", "Rating": "Good", "SHAWN": 0.0, "LAYER": "SANMH", "FeatId1": 67.0 }, "geometry": { "type": "Point", "coordinates": [ -80.609732272115977, 28.343173836478883 ] } },
    { "type": "Feature", "properties": { "OBJECTID": 945, "Coating": "None", "Constructi": "Brick/Mortar", "Date_Inspe": "08/13/13", "Depth": 3.5, "Infiltrati": "No", "Inflow": "No", "Lift_Stati": 4.0, "Manhole_Id": "LS4-209", "Rating": "Good", "SHAWN": 0.0, "LAYER": "SANMH", "FeatId1": 66.0 }, "geometry": { "type": "Point", "coordinates": [ -80.609759384167916, 28.342806105577562 ] } },
    { "type": "Feature", "properties": { "OBJECTID": 946, "Coating": "None", "Constructi": "Brick/Mortar", "Date_Inspe": "08/06/13", "Depth": 4.5, "Infiltrati": "Probable", "Inflow": "No", "Lift_Stati": 4.0, "Manhole_Id": "LS4-210", "Rating": "Fair", "SHAWN": 0.0, "LAYER": "SANMH", "FeatId1": 65.0 }, "geometry": { "type": "Point", "coordinates": [ -80.609799720588413, 28.342330088969966 ] } },
    { "type": "Feature", "properties": { "OBJECTID": 947, "Coating": "Mortar", "Constructi": "Brick/Mortar", "Date_Inspe": "08/06/13", "Depth": 4.58, "Infiltrati": "No", "Inflow": "No", "Lift_Stati": 4.0, "Manhole_Id": "LS4-211", "Rating": "Good", "SHAWN": 0.0, "LAYER": "SANMH", "FeatId1": 64.0 }, "geometry": { "type": "Point", "coordinates": [ -80.609822809238935, 28.342079942716516 ] } },
    { "type": "Feature", "properties": { "OBJECTID": 948, "Coating": "None", "Constructi": "Brick/Mortar", "Date_Inspe": "08/06/13", "Depth": 5.33, "Infiltrati": "Yes", "Inflow": "No", "Lift_Stati": 4.0, "Manhole_Id": "LS4-212", "Rating": "Poor", "SHAWN": 0.0, "LAYER": "SANMH", "FeatId1": 63.0 }, "geometry": { "type": "Point", "coordinates": [ -80.609832651287618, 28.341869305615536 ] } },
    { "type": "Feature", "properties": { "OBJECTID": 949, "Coating": "None", "Constructi": "Brick/Mortar", "Date_Inspe": "08/06/13", "Depth": 5.5, "Infiltrati": "No", "Inflow": "No", "Lift_Stati": 4.0, "Manhole_Id": "LS4-213", "Rating": "Good", "SHAWN": 0.0, "LAYER": "SANMH", "FeatId1": 62.0 }, "geometry": { "type": "Point", "coordinates": [ -80.609895257832363, 28.340983290787321 ] } },
    { "type": "Feature", "properties": { "OBJECTID": 950, "Coating": "None", "Constructi": "Brick/Mortar", "Date_Inspe": "08/06/13", "Depth": 5.0, "Infiltrati": "Probable", "Inflow": "No", "Lift_Stati": 4.0, "Manhole_Id": "LS4-214", "Rating": "Fair", "SHAWN": 0.0, "LAYER": "SANMH", "FeatId1": 61.0 }, "geometry": { "type": "Point", "coordinates": [ -80.609896713823005, 28.340917253546984 ] } },
    { "type": "Feature", "properties": { "OBJECTID": 951, "Coating": "None", "Constructi": "Brick/Mortar", "Date_Inspe": "00/00/00", "Depth": 11.0, "Infiltrati": "No", "Inflow": "No", "Lift_Stati": 4.0, "Manhole_Id": "LS4-177", "Rating": "Good", "SHAWN": 0.0, "LAYER": "SANMH", "FeatId1": 60.0 }, "geometry": { "type": "Point", "coordinates": [ -80.607280155426579, 28.35627678590242 ] } },
    { "type": "Feature", "properties": { "OBJECTID": 952, "Coating": "None", "Constructi": "Brick/Mortar", "Date_Inspe": "00/00/00", "Depth": 11.0, "Infiltrati": "No", "Inflow": "No", "Lift_Stati": 4.0, "Manhole_Id": "LS4-178", "Rating": "Good", "SHAWN": 0.0, "LAYER": "SANMH", "FeatId1": 59.0 }, "geometry": { "type": "Point", "coordinates": [ -80.606532502839116, 28.356196704476829 ] } },
    { "type": "Feature", "properties": { "OBJECTID": 953, "Coating": "None", "Constructi": "Brick/Mortar", "Date_Inspe": "00/00/00", "Depth": 11.0, "Infiltrati": "No", "Inflow": "No", "Lift_Stati": 4.0, "Manhole_Id": "LS4-179", "Rating": "Good", "SHAWN": 0.0, "LAYER": "SANMH", "FeatId1": 58.0 }, "geometry": { "type": "Point", "coordinates": [ -80.606448950973288, 28.356208764391173 ] } },
    { "type": "Feature", "properties": { "OBJECTID": 954, "Coating": "None", "Constructi": "Brick/Mortar", "Date_Inspe": "00/00/00", "Depth": 11.0, "Infiltrati": "No", "Inflow": "No", "Lift_Stati": 4.0, "Manhole_Id": "LS4-182", "Rating": "Good", "SHAWN": 0.0, "LAYER": "SANMH", "FeatId1": 57.0 }, "geometry": { "type": "Point", "coordinates": [ -80.607298983697646, 28.35406837477186 ] } },
    { "type": "Feature", "properties": { "OBJECTID": 955, "Coating": "None", "Constructi": "Brick/Mortar", "Date_Inspe": "00/00/00", "Depth": 11.0, "Infiltrati": "No", "Inflow": "No", "Lift_Stati": 4.0, "Manhole_Id": "LS4-172", "Rating": "Good", "SHAWN": 0.0, "LAYER": "SANMH", "FeatId1": 56.0 }, "geometry": { "type": "Point", "coordinates": [ -80.608364950025077, 28.354283433238294 ] } },
    { "type": "Feature", "properties": { "OBJECTID": 956, "Coating": "None", "Constructi": "Brick/Mortar", "Date_Inspe": "00/00/00", "Depth": 11.0, "Infiltrati": "No", "Inflow": "No", "Lift_Stati": 4.0, "Manhole_Id": "LS4-171", "Rating": "Good", "SHAWN": 0.0, "LAYER": "SANMH", "FeatId1": 55.0 }, "geometry": { "type": "Point", "coordinates": [ -80.608651502403191, 28.354311208534085 ] } },
    { "type": "Feature", "properties": { "OBJECTID": 957, "Coating": "None", "Constructi": "Brick/Mortar", "Date_Inspe": "00/00/00", "Depth": 11.0, "Infiltrati": "No", "Inflow": "No", "Lift_Stati": 4.0, "Manhole_Id": "LS4-190", "Rating": "Good", "SHAWN": 0.0, "LAYER": "SANMH", "FeatId1": 54.0 }, "geometry": { "type": "Point", "coordinates": [ -80.60850152436231, 28.353255136450368 ] } },
    { "type": "Feature", "properties": { "OBJECTID": 958, "Coating": "None", "Constructi": "Brick/Mortar", "Date_Inspe": "00/00/00", "Depth": 11.0, "Infiltrati": "No", "Inflow": "No", "Lift_Stati": 4.0, "Manhole_Id": "LS4-191", "Rating": "Good", "SHAWN": 0.0, "LAYER": "SANMH", "FeatId1": 53.0 }, "geometry": { "type": "Point", "coordinates": [ -80.607481852353033, 28.353333559875331 ] } },
    { "type": "Feature", "properties": { "OBJECTID": 959, "Coating": "None", "Constructi": "Brick/Mortar", "Date_Inspe": "00/00/00", "Depth": 11.0, "Infiltrati": "No", "Inflow": "No", "Lift_Stati": 4.0, "Manhole_Id": "LS4-192", "Rating": "Good", "SHAWN": 0.0, "LAYER": "SANMH", "FeatId1": 52.0 }, "geometry": { "type": "Point", "coordinates": [ -80.606442104505675, 28.353232974558949 ] } },
    { "type": "Feature", "properties": { "OBJECTID": 960, "Coating": "None", "Constructi": "Brick/Mortar", "Date_Inspe": "00/00/00", "Depth": 11.0, "Infiltrati": "No", "Inflow": "No", "Lift_Stati": 4.0, "Manhole_Id": "LS4-223", "Rating": "Good", "SHAWN": 0.0, "LAYER": "SANMH", "FeatId1": 51.0 }, "geometry": { "type": "Point", "coordinates": [ -80.609255556421104, 28.349438977121476 ] } },
    { "type": "Feature", "properties": { "OBJECTID": 961, "Coating": "None", "Constructi": "Brick/Mortar", "Date_Inspe": "00/00/00", "Depth": 11.0, "Infiltrati": "No", "Inflow": "No", "Lift_Stati": 4.0, "Manhole_Id": "LS4-226", "Rating": "Good", "SHAWN": 0.0, "LAYER": "SANMH", "FeatId1": 50.0 }, "geometry": { "type": "Point", "coordinates": [ -80.605971641554078, 28.354967533813216 ] } },
    { "type": "Feature", "properties": { "OBJECTID": 962, "Coating": "None", "Constructi": "Brick/Mortar", "Date_Inspe": "00/00/00", "Depth": 11.0, "Infiltrati": "No", "Inflow": "No", "Lift_Stati": 4.0, "Manhole_Id": "LS4-224", "Rating": "Good", "SHAWN": 0.0, "LAYER": "SANMH", "FeatId1": 49.0 }, "geometry": { "type": "Point", "coordinates": [ -80.605619084389303, 28.356802217028804 ] } },
    { "type": "Feature", "properties": { "OBJECTID": 963, "Coating": "None", "Constructi": "Brick/Mortar", "Date_Inspe": "00/00/00", "Depth": 11.0, "Infiltrati": "No", "Inflow": "No", "Lift_Stati": 4.0, "Manhole_Id": "LS4-227", "Rating": "Good", "SHAWN": 0.0, "LAYER": "SANMH", "FeatId1": 48.0 }, "geometry": { "type": "Point", "coordinates": [ -80.607064710629686, 28.357393406143348 ] } },
    { "type": "Feature", "properties": { "OBJECTID": 964, "Coating": "None", "Constructi": "Brick/Mortar", "Date_Inspe": "00/00/00", "Depth": 11.0, "Infiltrati": "No", "Inflow": "No", "Lift_Stati": 4.0, "Manhole_Id": "LS4-228", "Rating": "Good", "SHAWN": 0.0, "LAYER": "SANMH", "FeatId1": 47.0 }, "geometry": { "type": "Point", "coordinates": [ -80.607990890491166, 28.357384299541081 ] } },
    { "type": "Feature", "properties": { "OBJECTID": 965, "Coating": "None", "Constructi": "Brick/Mortar", "Date_Inspe": "00/00/00", "Depth": 11.0, "Infiltrati": "No", "Inflow": "No", "Lift_Stati": 4.0, "Manhole_Id": "LS4-229", "Rating": "Good", "SHAWN": 0.0, "LAYER": "SANMH", "FeatId1": 46.0 }, "geometry": { "type": "Point", "coordinates": [ -80.608059439415939, 28.356819085856689 ] } },
    { "type": "Feature", "properties": { "OBJECTID": 966, "Coating": "None", "Constructi": "Brick/Mortar", "Date_Inspe": "00/00/00", "Depth": 11.0, "Infiltrati": "No", "Inflow": "No", "Lift_Stati": 4.0, "Manhole_Id": "LS4-234", "Rating": "Good", "SHAWN": 0.0, "LAYER": "SANMH", "FeatId1": 45.0 }, "geometry": { "type": "Point", "coordinates": [ -80.608444607369321, 28.355954834243708 ] } },
    { "type": "Feature", "properties": { "OBJECTID": 967, "Coating": "None", "Constructi": "Brick/Mortar", "Date_Inspe": "00/00/00", "Depth": 11.0, "Infiltrati": "No", "Inflow": "No", "Lift_Stati": 4.0, "Manhole_Id": "LS4-235", "Rating": "Good", "SHAWN": 0.0, "LAYER": "SANMH", "FeatId1": 44.0 }, "geometry": { "type": "Point", "coordinates": [ -80.608529962434872, 28.355273976442142 ] } },
    { "type": "Feature", "properties": { "OBJECTID": 968, "Coating": "None", "Constructi": "Brick/Mortar", "Date_Inspe": "00/00/00", "Depth": 11.0, "Infiltrati": "No", "Inflow": "No", "Lift_Stati": 4.0, "Manhole_Id": "LS4-239", "Rating": "Good", "SHAWN": 0.0, "LAYER": "SANMH", "FeatId1": 43.0 }, "geometry": { "type": "Point", "coordinates": [ -80.610074665977862, 28.32755032080961 ] } },
    { "type": "Feature", "properties": { "OBJECTID": 969, "Coating": "None", "Constructi": "Brick/Mortar", "Date_Inspe": "00/00/00", "Depth": 11.0, "Infiltrati": "No", "Inflow": "No", "Lift_Stati": 4.0, "Manhole_Id": "LS4-237", "Rating": "Good", "SHAWN": 0.0, "LAYER": "SANMH", "FeatId1": 42.0 }, "geometry": { "type": "Point", "coordinates": [ -80.610668621846003, 28.329652192664554 ] } },
    { "type": "Feature", "properties": { "OBJECTID": 970, "Coating": "None", "Constructi": "Brick/Mortar", "Date_Inspe": "00/00/00", "Depth": 11.0, "Infiltrati": "No", "Inflow": "No", "Lift_Stati": 4.0, "Manhole_Id": "LS4-236", "Rating": "Good", "SHAWN": 0.0, "LAYER": "SANMH", "FeatId1": 41.0 }, "geometry": { "type": "Point", "coordinates": [ -80.61116248819981, 28.329647283421945 ] } },
    { "type": "Feature", "properties": { "OBJECTID": 971, "Coating": "None", "Constructi": "Brick/Mortar", "Date_Inspe": "00/00/00", "Depth": 11.0, "Infiltrati": "No", "Inflow": "No", "Lift_Stati": 4.0, "Manhole_Id": "LS4-236", "Rating": "Good", "SHAWN": 0.0, "LAYER": "SANMH", "FeatId1": 40.0 }, "geometry": { "type": "Point", "coordinates": [ -80.605892560038185, 28.357402323683132 ] } },
    { "type": "Feature", "properties": { "OBJECTID": 972, "Coating": "None", "Constructi": "Brick/Mortar", "Date_Inspe": "00/00/00", "Depth": 11.0, "Infiltrati": "No", "Inflow": "No", "Lift_Stati": 4.0, "Manhole_Id": "LS4-238", "Rating": "Good", "SHAWN": 0.0, "LAYER": "SANMH", "FeatId1": 39.0 }, "geometry": { "type": "Point", "coordinates": [ -80.610404978764791, 28.328478465268418 ] } },
    { "type": "Feature", "properties": { "OBJECTID": 973, "Coating": "None", "Constructi": "Brick/Mortar", "Date_Inspe": "00/00/00", "Depth": 0.0, "Infiltrati": "No", "Inflow": "No", "Lift_Stati": 1.0, "Manhole_Id": "LS1-192", "Rating": "Good", "SHAWN": 0.0, "LAYER": "SANMH", "FeatId1": 38.0 }, "geometry": { "type": "Point", "coordinates": [ -80.633368703437796, 28.318481960598053 ] } },
    { "type": "Feature", "properties": { "OBJECTID": 974, "Coating": "None", "Constructi": "Brick/Mortar", "Date_Inspe": "00/00/00", "Depth": 0.0, "Infiltrati": "No", "Inflow": "No", "Lift_Stati": 1.0, "Manhole_Id": "LS1-193", "Rating": "Good", "SHAWN": 0.0, "LAYER": "SANMH", "FeatId1": 37.0 }, "geometry": { "type": "Point", "coordinates": [ -80.633647674957643, 28.317522931310865 ] } },
    { "type": "Feature", "properties": { "OBJECTID": 975, "Coating": "None", "Constructi": "Brick/Mortar", "Date_Inspe": "00/00/00", "Depth": 0.0, "Infiltrati": "No", "Inflow": "No", "Lift_Stati": 1.0, "Manhole_Id": "LS1-188", "Rating": "Good", "SHAWN": 0.0, "LAYER": "SANMH", "FeatId1": 36.0 }, "geometry": { "type": "Point", "coordinates": [ -80.610235271778507, 28.31829872680408 ] } },
    { "type": "Feature", "properties": { "OBJECTID": 976, "Coating": "None", "Constructi": "Brick/Mortar", "Date_Inspe": "00/00/00", "Depth": 0.0, "Infiltrati": "No", "Inflow": "No", "Lift_Stati": 1.0, "Manhole_Id": "LS1-194", "Rating": "Good", "SHAWN": 0.0, "LAYER": "SANMH", "FeatId1": 35.0 }, "geometry": { "type": "Point", "coordinates": [ -80.610233039042782, 28.317999405781563 ] } },
    { "type": "Feature", "properties": { "OBJECTID": 977, "Coating": "None", "Constructi": "Brick/Mortar", "Date_Inspe": "00/00/00", "Depth": 0.0, "Infiltrati": "No", "Inflow": "No", "Lift_Stati": 5.0, "Manhole_Id": "LS5-073", "Rating": "Good", "SHAWN": 0.0, "LAYER": "SANMH", "FeatId1": 34.0 }, "geometry": { "type": "Point", "coordinates": [ -80.606493800739102, 28.365127023800692 ] } },
    { "type": "Feature", "properties": { "OBJECTID": 978, "Coating": "None", "Constructi": "Brick/Mortar", "Date_Inspe": "00/00/00", "Depth": 0.0, "Infiltrati": "No", "Inflow": "No", "Lift_Stati": 5.0, "Manhole_Id": "LS5-072", "Rating": "Good", "SHAWN": 0.0, "LAYER": "SANMH", "FeatId1": 33.0 }, "geometry": { "type": "Point", "coordinates": [ -80.605573535472047, 28.364998275828132 ] } },
    { "type": "Feature", "properties": { "OBJECTID": 979, "Coating": "None", "Constructi": "Brick/Mortar", "Date_Inspe": "00/00/00", "Depth": 0.0, "Infiltrati": "No", "Inflow": "No", "Lift_Stati": 5.0, "Manhole_Id": "LS5-071", "Rating": "Good", "SHAWN": 0.0, "LAYER": "SANMH", "FeatId1": 32.0 }, "geometry": { "type": "Point", "coordinates": [ -80.604343867992739, 28.364815872979278 ] } },
    { "type": "Feature", "properties": { "OBJECTID": 980, "Coating": "None", "Constructi": "Brick/Mortar", "Date_Inspe": "00/00/00", "Depth": 0.0, "Infiltrati": "No", "Inflow": "No", "Lift_Stati": 5.0, "Manhole_Id": "LS5-074", "Rating": "Good", "SHAWN": 0.0, "LAYER": "SANMH", "FeatId1": 31.0 }, "geometry": { "type": "Point", "coordinates": [ -80.604244925200959, 28.365425720393084 ] } },
    { "type": "Feature", "properties": { "OBJECTID": 981, "Coating": "None", "Constructi": "Brick/Mortar", "Date_Inspe": "00/00/00", "Depth": 0.0, "Infiltrati": "No", "Inflow": "No", "Lift_Stati": 5.0, "Manhole_Id": "LS5-075", "Rating": "Good", "SHAWN": 0.0, "LAYER": "SANMH", "FeatId1": 30.0 }, "geometry": { "type": "Point", "coordinates": [ -80.604123277220822, 28.365604041154725 ] } },
    { "type": "Feature", "properties": { "OBJECTID": 982, "Coating": "None", "Constructi": "Brick/Mortar", "Date_Inspe": "00/00/00", "Depth": 0.0, "Infiltrati": "No", "Inflow": "No", "Lift_Stati": 5.0, "Manhole_Id": "LS5-070", "Rating": "Good", "SHAWN": 0.0, "LAYER": "SANMH", "FeatId1": 29.0 }, "geometry": { "type": "Point", "coordinates": [ -80.606630988069597, 28.364307513802672 ] } },
    { "type": "Feature", "properties": { "OBJECTID": 983, "Coating": "None", "Constructi": "Brick/Mortar", "Date_Inspe": "00/00/00", "Depth": 0.0, "Infiltrati": "No", "Inflow": "No", "Lift_Stati": 5.0, "Manhole_Id": "LS5-069", "Rating": "Good", "SHAWN": 0.0, "LAYER": "SANMH", "FeatId1": 28.0 }, "geometry": { "type": "Point", "coordinates": [ -80.605551060961261, 28.364167953983173 ] } },
    { "type": "Feature", "properties": { "OBJECTID": 984, "Coating": "None", "Constructi": "Brick/Mortar", "Date_Inspe": "00/00/00", "Depth": 0.0, "Infiltrati": "No", "Inflow": "No", "Lift_Stati": 5.0, "Manhole_Id": "LS5-068", "Rating": "Good", "SHAWN": 0.0, "LAYER": "SANMH", "FeatId1": 27.0 }, "geometry": { "type": "Point", "coordinates": [ -80.604475777504334, 28.36402947020111 ] } },
    { "type": "Feature", "properties": { "OBJECTID": 985, "Coating": "None", "Constructi": "Brick/Mortar", "Date_Inspe": "00/00/00", "Depth": 0.0, "Infiltrati": "No", "Inflow": "No", "Lift_Stati": 5.0, "Manhole_Id": "LS5-124", "Rating": "Good", "SHAWN": 0.0, "LAYER": "SANMH", "FeatId1": 26.0 }, "geometry": { "type": "Point", "coordinates": [ -80.611557678939192, 28.354260231970759 ] } },
    { "type": "Feature", "properties": { "OBJECTID": 986, "Coating": "None", "Constructi": "Brick/Mortar", "Date_Inspe": "03/24/09", "Depth": 11.0, "Infiltrati": "No", "Inflow": "No", "Lift_Stati": 7.0, "Manhole_Id": "LS7-012", "Rating": "Good", "SHAWN": 0.0, "LAYER": "SANMH", "FeatId1": 25.0 }, "geometry": { "type": "Point", "coordinates": [ -80.613161985688848, 28.325376486044298 ] } },
    { "type": "Feature", "properties": { "OBJECTID": 987, "Coating": "None", "Constructi": "Brick/Mortar", "Date_Inspe": "03/24/09", "Depth": 11.0, "Infiltrati": "No", "Inflow": "No", "Lift_Stati": 7.0, "Manhole_Id": "LS7-054", "Rating": "Good", "SHAWN": 0.0, "LAYER": "SANMH", "FeatId1": 24.0 }, "geometry": { "type": "Point", "coordinates": [ -80.61425104399396, 28.325384448848343 ] } },
    { "type": "Feature", "properties": { "OBJECTID": 988, "Coating": "None", "Constructi": "Brick/Mortar", "Date_Inspe": "03/24/09", "Depth": 11.0, "Infiltrati": "No", "Inflow": "No", "Lift_Stati": 7.0, "Manhole_Id": "LS7-065", "Rating": "Good", "SHAWN": 0.0, "LAYER": "SANMH", "FeatId1": 23.0 }, "geometry": { "type": "Point", "coordinates": [ -80.615352372135391, 28.325391958389499 ] } },
    { "type": "Feature", "properties": { "OBJECTID": 989, "Coating": "None", "Constructi": "Brick/Mortar", "Date_Inspe": "00/00/00", "Depth": 0.0, "Infiltrati": "No", "Inflow": "No", "Lift_Stati": 5.0, "Manhole_Id": "LS5-123", "Rating": "Good", "SHAWN": 0.0, "LAYER": "SANMH", "FeatId1": 22.0 }, "geometry": { "type": "Point", "coordinates": [ -80.611394576161445, 28.355516002547969 ] } },
    { "type": "Feature", "properties": { "OBJECTID": 990, "Coating": "None", "Constructi": "Brick/Mortar", "Date_Inspe": "00/00/00", "Depth": 0.0, "Infiltrati": "No", "Inflow": "No", "Lift_Stati": 5.0, "Manhole_Id": "LS5-126", "Rating": "Good", "SHAWN": 0.0, "LAYER": "SANMH", "FeatId1": 21.0 }, "geometry": { "type": "Point", "coordinates": [ -80.611900234685109, 28.35361693495657 ] } },
    { "type": "Feature", "properties": { "OBJECTID": 991, "Coating": "None", "Constructi": "Brick/Mortar", "Date_Inspe": "00/00/00", "Depth": 0.0, "Infiltrati": "No", "Inflow": "No", "Lift_Stati": 5.0, "Manhole_Id": "LS5-122", "Rating": "Good", "SHAWN": 0.0, "LAYER": "SANMH", "FeatId1": 20.0 }, "geometry": { "type": "Point", "coordinates": [ -80.611531269750174, 28.356407002066639 ] } },
    { "type": "Feature", "properties": { "OBJECTID": 992, "Coating": "None", "Constructi": "Brick/Mortar", "Date_Inspe": "00/00/00", "Depth": 0.0, "Infiltrati": "No", "Inflow": "No", "Lift_Stati": 5.0, "Manhole_Id": "LS5-109", "Rating": "Good", "SHAWN": 0.0, "LAYER": "SANMH", "FeatId1": 19.0 }, "geometry": { "type": "Point", "coordinates": [ -80.610576222806145, 28.358053899872946 ] } },
    { "type": "Feature", "properties": { "OBJECTID": 993, "Coating": "None", "Constructi": "Brick/Mortar", "Date_Inspe": "00/00/00", "Depth": 0.0, "Infiltrati": "No", "Inflow": "No", "Lift_Stati": 5.0, "Manhole_Id": "LS5-110", "Rating": "Good", "SHAWN": 0.0, "LAYER": "SANMH", "FeatId1": 18.0 }, "geometry": { "type": "Point", "coordinates": [ -80.610477964161404, 28.357829299906744 ] } },
    { "type": "Feature", "properties": { "OBJECTID": 994, "Coating": "None", "Constructi": "Brick/Mortar", "Date_Inspe": "00/00/00", "Depth": 0.0, "Infiltrati": "No", "Inflow": "No", "Lift_Stati": 5.0, "Manhole_Id": "LS5-097", "Rating": "Good", "SHAWN": 0.0, "LAYER": "SANMH", "FeatId1": 17.0 }, "geometry": { "type": "Point", "coordinates": [ -80.610334975682136, 28.359436119770344 ] } },
    { "type": "Feature", "properties": { "OBJECTID": 995, "Coating": "None", "Constructi": "Brick/Mortar", "Date_Inspe": "00/00/00", "Depth": 11.0, "Infiltrati": "No", "Inflow": "No", "Lift_Stati": 8.0, "Manhole_Id": "LS8-042", "Rating": "Good", "SHAWN": 0.0, "LAYER": "SANMH", "FeatId1": 16.0 }, "geometry": { "type": "Point", "coordinates": [ -80.603448378153615, 28.367109230187431 ] } },
    { "type": "Feature", "properties": { "OBJECTID": 996, "Coating": "None", "Constructi": "Brick/Mortar", "Date_Inspe": "00/00/00", "Depth": 11.0, "Infiltrati": "No", "Inflow": "No", "Lift_Stati": 8.0, "Manhole_Id": "LS8-041", "Rating": "Good", "SHAWN": 0.0, "LAYER": "SANMH", "FeatId1": 15.0 }, "geometry": { "type": "Point", "coordinates": [ -80.603811402084261, 28.367111580500904 ] } },
    { "type": "Feature", "properties": { "OBJECTID": 997, "Coating": "None", "Constructi": "Brick/Mortar", "Date_Inspe": "00/00/00", "Depth": 11.0, "Infiltrati": "No", "Inflow": "No", "Lift_Stati": 8.0, "Manhole_Id": "LS8-040", "Rating": "Good", "SHAWN": 0.0, "LAYER": "SANMH", "FeatId1": 14.0 }, "geometry": { "type": "Point", "coordinates": [ -80.604308167995882, 28.367114322589881 ] } },
    { "type": "Feature", "properties": { "OBJECTID": 998, "Coating": "None", "Constructi": "Brick/Mortar", "Date_Inspe": "00/00/00", "Depth": 11.0, "Infiltrati": "No", "Inflow": "No", "Lift_Stati": 8.0, "Manhole_Id": "LS8-044", "Rating": "Good", "SHAWN": 0.0, "LAYER": "SANMH", "FeatId1": 13.0 }, "geometry": { "type": "Point", "coordinates": [ -80.604884549933089, 28.366297123693737 ] } },
    { "type": "Feature", "properties": { "OBJECTID": 999, "Coating": "None", "Constructi": "Brick/Mortar", "Date_Inspe": "00/00/00", "Depth": 11.0, "Infiltrati": "No", "Inflow": "No", "Lift_Stati": 8.0, "Manhole_Id": "LS8-043", "Rating": "Good", "SHAWN": 0.0, "LAYER": "SANMH", "FeatId1": 12.0 }, "geometry": { "type": "Point", "coordinates": [ -80.604308156412415, 28.366295452053439 ] } },
    { "type": "Feature", "properties": { "OBJECTID": 1000, "Coating": "None", "Constructi": "Brick/Mortar", "Date_Inspe": "00/00/00", "Depth": 11.0, "Infiltrati": "No", "Inflow": "No", "Lift_Stati": 8.0, "Manhole_Id": "LS8-045", "Rating": "Good", "SHAWN": 0.0, "LAYER": "SANMH", "FeatId1": 11.0 }, "geometry": { "type": "Point", "coordinates": [ -80.605319661540918, 28.366291483618848 ] } },
    { "type": "Feature", "properties": { "OBJECTID": 1001, "Coating": "None", "Constructi": "Brick/Mortar", "Date_Inspe": "00/00/00", "Depth": 11.0, "Infiltrati": "No", "Inflow": "No", "Lift_Stati": 8.0, "Manhole_Id": "LS8-046", "Rating": "Good", "SHAWN": 0.0, "LAYER": "SANMH", "FeatId1": 10.0 }, "geometry": { "type": "Point", "coordinates": [ -80.605346660224086, 28.366361558866419 ] } },
    { "type": "Feature", "properties": { "OBJECTID": 1002, "Coating": "None", "Constructi": "Brick/Mortar", "Date_Inspe": "00/00/00", "Depth": 11.0, "Infiltrati": "No", "Inflow": "No", "Lift_Stati": 8.0, "Manhole_Id": "LS8-037", "Rating": "Good", "SHAWN": 0.0, "LAYER": "SANMH", "FeatId1": 9.0 }, "geometry": { "type": "Point", "coordinates": [ -80.605315945683515, 28.367927897335065 ] } },
    { "type": "Feature", "properties": { "OBJECTID": 1003, "Coating": "None", "Constructi": "Brick/Mortar", "Date_Inspe": "00/00/00", "Depth": 11.0, "Infiltrati": "No", "Inflow": "No", "Lift_Stati": 8.0, "Manhole_Id": "LS8-036", "Rating": "Good", "SHAWN": 0.0, "LAYER": "SANMH", "FeatId1": 8.0 }, "geometry": { "type": "Point", "coordinates": [ -80.604304261074461, 28.367932062727231 ] } },
    { "type": "Feature", "properties": { "OBJECTID": 1004, "Coating": "None", "Constructi": "Brick/Mortar", "Date_Inspe": "00/00/00", "Depth": 11.0, "Infiltrati": "No", "Inflow": "No", "Lift_Stati": 8.0, "Manhole_Id": "LS8-038", "Rating": "Good", "SHAWN": 0.0, "LAYER": "SANMH", "FeatId1": 7.0 }, "geometry": { "type": "Point", "coordinates": [ -80.60361669355504, 28.367930266608461 ] } },
    { "type": "Feature", "properties": { "OBJECTID": 1005, "Coating": "None", "Constructi": "Brick/Mortar", "Date_Inspe": "00/00/00", "Depth": 11.0, "Infiltrati": "No", "Inflow": "No", "Lift_Stati": 8.0, "Manhole_Id": "LS8-039", "Rating": "Good", "SHAWN": 0.0, "LAYER": "SANMH", "FeatId1": 6.0 }, "geometry": { "type": "Point", "coordinates": [ -80.602955909092444, 28.367928732716432 ] } },
    { "type": "Feature", "properties": { "OBJECTID": 1006, "Coating": "None", "Constructi": "Brick/Mortar", "Date_Inspe": "00/00/00", "Depth": 11.0, "Infiltrati": "No", "Inflow": "No", "Lift_Stati": 6.0, "Manhole_Id": "LS6-147", "Rating": "Good", "SHAWN": 0.0, "LAYER": "SANMH", "FeatId1": 5.0 }, "geometry": { "type": "Point", "coordinates": [ -80.611625131986528, 28.310312522160398 ] } },
    { "type": "Feature", "properties": { "OBJECTID": 1007, "Coating": "None", "Constructi": "Brick/Mortar", "Date_Inspe": "00/00/00", "Depth": 11.0, "Infiltrati": "No", "Inflow": "No", "Lift_Stati": 14.0, "Manhole_Id": "LS14-048", "Rating": "Good", "SHAWN": 0.0, "LAYER": "SANMH", "FeatId1": 4.0 }, "geometry": { "type": "Point", "coordinates": [ -80.612066467514097, 28.289324898159222 ] } },
    { "type": "Feature", "properties": { "OBJECTID": 1008, "Coating": "None", "Constructi": "Brick/Mortar", "Date_Inspe": "00/00/00", "Depth": 11.0, "Infiltrati": "No", "Inflow": "No", "Lift_Stati": 1.0, "Manhole_Id": "LS1-195", "Rating": "Good", "SHAWN": 0.0, "LAYER": "SANMH", "FeatId1": 3.0 }, "geometry": { "type": "Point", "coordinates": [ -80.611910835430379, 28.318067652081638 ] } },
    { "type": "Feature", "properties": { "OBJECTID": 1009, "Coating": "None", "Constructi": "Brick/Mortar", "Date_Inspe": "00/00/00", "Depth": 11.0, "Infiltrati": "No", "Inflow": "No", "Lift_Stati": 1.0, "Manhole_Id": "LS1-196", "Rating": "Good", "SHAWN": 0.0, "LAYER": "SANMH", "FeatId1": 2.0 }, "geometry": { "type": "Point", "coordinates": [ -80.613077013339492, 28.318396422914493 ] } },
    { "type": "Feature", "properties": { "OBJECTID": 1010, "Coating": "None", "Constructi": "Brick/Mortar", "Date_Inspe": "00/00/00", "Depth": 11.0, "Infiltrati": "No", "Inflow": "No", "Lift_Stati": 4.0, "Manhole_Id": "LS4-239", "Rating": "Good", "SHAWN": 0.0, "LAYER": "SANMH", "FeatId1": 1.0 }, "geometry": { "type": "Point", "coordinates": [ -80.610768512800703, 28.348070064518819 ] } },
    { "type": "Feature", "properties": { "OBJECTID": 1010, "Coating": "None", "Constructi": "Brick/Mortar", "Date_Inspe": "00/00/00", "Depth": 11.0, "Infiltrati": "No", "Inflow": "No", "Lift_Stati": 4.0, "Manhole_Id": "MH-150819", "Rating": "Good", "SHAWN": 0.0, "LAYER": "SANMH", "FeatId1": 1.0 }, "geometry": { "type": "Point", "coordinates": [ -81.40291305718044, 28.308161337684883 ] } },
    { "type": "Feature", "properties": { "OBJECTID": 1010, "Coating": "None", "Constructi": "Brick/Mortar", "Date_Inspe": "00/00/00", "Depth": 11.0, "Infiltrati": "No", "Inflow": "No", "Lift_Stati": 4.0, "Manhole_Id": "MH-150820", "Rating": "Good", "SHAWN": 0.0, "LAYER": "SANMH", "FeatId1": 1.0 }, "geometry": { "type": "Point", "coordinates": [ -81.402183, 28.308149 ] } }
    ]
}`);
