import _ from 'lodash';
import { SLICE_ROOT_KEY } from './constants';
import initialState from './initialState';
import {
	setIsComputingDiffs,
	setErrors,
	setProcessingMessage,
	reset
} from './actions';
import { selectSliceState } from './selectors';
import { getProcessingMessage } from './util';

const reducerParts = {
	[setIsComputingDiffs]: (state, { payload: { isComputingDiffs } = {} }) => {
		const slice = selectSliceState(state);

		return {
			...state,
			[SLICE_ROOT_KEY]: {
				...slice,
				isComputingDiffs
			}
		};
	},

	[setErrors]: (state, { payload: { errors } = {} }) => {
		const slice = selectSliceState(state);

		return {
			...state,
			[SLICE_ROOT_KEY]: {
				...slice,
				errors
			}
		};
	},

	[setProcessingMessage]: (
		state,
		{ payload: { processingMessageKey } = {} }
	) => {
		const message = getProcessingMessage(processingMessageKey);
		const slice = selectSliceState(state);

		return {
			...state,
			[SLICE_ROOT_KEY]: {
				...slice,
				processingMessage: message
			}
		};
	},

	[reset]: state => {
		return {
			...state,
			[SLICE_ROOT_KEY]: _.cloneDeep(initialState)
		};
	}
};

export default reducerParts;
