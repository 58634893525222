export const selectLocalRef = (key, state, sliceNameSpace) => {
	const { localRefs } = state;
	const myLocalRefs = localRefs[sliceNameSpace] || {};
	return myLocalRefs[key];
};

export const setLocalRefs = (
	newValues = {},
	localRefs = {},
	sliceNameSpace
) => {
	const myLocalRefs = localRefs[sliceNameSpace] || {};
	return {
		...localRefs,
		[sliceNameSpace]: {
			...myLocalRefs,
			...newValues
		}
	};
};

export const destroyLocalRefs = (localRefs = {}, sliceNameSpace) => {
	return {
		...localRefs,
		[sliceNameSpace]: undefined
	};
};
