export const initialState = {
	applications: [],
	isLoadingApplications: false,
	isLoadedApplications: false,
	hasLoadingErrorApplications: false,
	selectedApplication: undefined,
	selectedApplicationChangesClone: undefined
};

export default initialState;
