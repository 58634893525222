/* eslint-disable no-use-before-define */
import { createActions } from 'redux-actions';
import fieldlineUtils from '../scanlineUtils';

const {
	getRating,
	receiveGetRating,
	requestGetRating,
	saveRating,
	receiveSaveRating,
	requestSaveRating
} = createActions({
	GET_RATING: (jobId, lineName) => doGetRating(jobId, lineName),
	RECEIVE_GET_RATING: json => ({ json }),
	REQUEST_GET_RATING: () => ({}),
	SAVE_RATING: (jobId, lineName, data) => doSaveRating(jobId, lineName, data),
	RECEIVE_SAVE_RATING: json => ({ json }),
	REQUEST_SAVE_RATING: () => ({})
});

const doGetRating = (jobId, lineName, callback = () => {}) => {
	return dispatch => {
		dispatch(requestGetRating());

		return fieldlineUtils.getRating(jobId, lineName, (err, res) => {
			if (err) {
				callback(err);
				// console.error(err);
			} else {
				callback(null, res);
				dispatch(receiveGetRating(res));
			}
		});
	};
};

const doSaveRating = (jobId, lineName, data, callback = () => {}) => {
	return dispatch => {
		dispatch(requestSaveRating());

		return fieldlineUtils.saveRating(jobId, lineName, data, (err, res) => {
			if (err) {
				callback(err);
				// console.error(err);
			} else {
				callback(null, res);
				dispatch(receiveSaveRating(res));
			}
		});
	};
};

export {
	getRating,
	receiveGetRating,
	requestGetRating,
	saveRating,
	receiveSaveRating,
	requestSaveRating,
	doGetRating,
	doSaveRating
};
