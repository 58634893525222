/* eslint-disable no-continue */
/* eslint-disable no-confusing-arrow */

import { createIterArray } from '../../../../utils/iterArray';

/* eslint-disable no-use-before-define */
export const deriveRecomputedDataSourcesFromCustomExceptionGroups = (
	belowCriterions = [],
	bcPropKey,
	customGroups = [],
	cePropKey,
	belowCriterionDataMap,
	readingsIndexMap,
	readingsWithChartGapsIndexMap,
	readings,
	readingsWithChartGaps
) => {
	const newCustomExceptions = computeCustomExceptionReadings(
		customGroups,
		readingsWithChartGaps,
		belowCriterionDataMap
	);

	const newCustomExceptionsWithGaps = createWithGaps(
		newCustomExceptions,
		readingsIndexMap,
		readings,
		readingsWithChartGaps,
		readingsWithChartGapsIndexMap,
		cePropKey
	);

	const {
		merged: newRemediationReadings,
		tMap: newCustomExceptionsMap,
		sMap: newComputedBcMap
	} = injectValidItemsInOrder(
		belowCriterions,
		bcPropKey,
		newCustomExceptions,
		cePropKey,
		isNonZeroReading,
		stationIdIsLessThan
	);

	return {
		newComputedBcMap,
		newCustomExceptions,
		newCustomExceptionsMap,
		newCustomExceptionsWithGaps,
		newRemediationReadings
	};
};

/* eslint-disable no-use-before-define */
export const deriveNewDataSourcesFromNewExceptionsGroup = (
	selectedDataValues = [],
	belowCriterions = [],
	bcPropKey,
	customExceptions = [],
	cePropKey,
	belowCriterionDataMap,
	readingsIndexMap,
	readingsWithChartGapsIndexMap,
	readings,
	readingsWithChartGaps
) => {
	const selectedDataValuesNoGaps = selectedDataValues.filter(r => !r.isGap);
	const selectedDataValuesMap = createReadingsIdMap(selectedDataValuesNoGaps);
	const addToCustomExceptions = filterNotInBelowCriterion(
		selectedDataValuesNoGaps,
		belowCriterionDataMap
	);
	const { merged: newCustomExceptions } = injectValidItemsInOrder(
		customExceptions,
		cePropKey,
		addToCustomExceptions,
		cePropKey,
		isNotGap,
		stationIdIsLessThan
	);
	const newCustomExceptionsWithGaps = createWithGaps(
		newCustomExceptions,
		readingsIndexMap,
		readings,
		readingsWithChartGaps,
		readingsWithChartGapsIndexMap,
		cePropKey
	);
	const {
		merged: newRemediationReadings,
		tMap: newCustomExceptionsMap,
		sMap: newComputedBcMap
	} = injectValidItemsInOrder(
		belowCriterions,
		bcPropKey,
		newCustomExceptions,
		cePropKey,
		isNonZeroReading,
		stationIdIsLessThan
	);

	return {
		selectedDataValuesMap,
		newComputedBcMap,
		newCustomExceptions,
		newCustomExceptionsMap,
		newCustomExceptionsWithGaps,
		newRemediationReadings
	};
};

export const deriveNewDataSourcesFromDestroyedExceptionsGroup = (
	selectedDataValues = [],
	belowCriterions = [],
	bcPropKey,
	customExceptions = [],
	cePropKey,
	belowCriterionDataMap,
	readingsIndexMap,
	readingsWithChartGapsIndexMap,
	readings,
	readingsWithChartGaps
) => {
	const selectedDataValuesNoGaps = selectedDataValues.filter(r => !r.isGap);
	const selectedDataValuesMap = createReadingsIdMap(selectedDataValuesNoGaps);
	const removeFromCustomExceptions = filterNotInBelowCriterion(
		selectedDataValuesNoGaps,
		belowCriterionDataMap
	);
	const removeFromCustomExceptionsMap = createReadingsIdMap(
		removeFromCustomExceptions
	);
	const newCustomExceptions = customExceptions.filter(
		reading => !removeFromCustomExceptionsMap[reading.id] && !reading.isGap
	);
	const newCustomExceptionsWithGaps = createWithGaps(
		newCustomExceptions,
		readingsIndexMap,
		readings,
		readingsWithChartGaps,
		readingsWithChartGapsIndexMap,
		cePropKey
	);
	const {
		merged: newRemediationReadings,
		tMap: newCustomExceptionsMap,
		sMap: newComputedBcMap
	} = injectValidItemsInOrder(
		belowCriterions,
		bcPropKey,
		newCustomExceptions,
		cePropKey,
		isNonZeroReading,
		stationIdIsLessThan
	);

	return {
		selectedDataValuesMap,
		newComputedBcMap,
		newCustomExceptions,
		newCustomExceptionsMap,
		newCustomExceptionsWithGaps,
		newRemediationReadings
	};
};

const isDefined = v => v !== undefined && v !== null;

const stationIdIsLessThan = (r1, r2) => r1.id < r2.id;

const isNonZeroReading = (reading, propKey) => !!reading[propKey];

const isNotGap = (reading, propKey) => reading[propKey] !== undefined;

const getFirstItem = (arr = []) => (arr && arr.length ? arr[0] : undefined);

const getLastItem = (arr = []) =>
	arr && arr.length > 1 ? arr[arr.length - 1] : undefined;

const getId = (val = {}) => {
	if (typeof val === 'object') {
		return val.id;
	}

	return val;
};

const isIdxOutOfBounds = (arr = [], idx) => idx >= arr.length;

const hasStationId = r => isDefined(r) && isDefined(r.id);

const isWithinGroup = (r, group = []) => {
	const startId = getId(getFirstItem(group));
	const endId = getId(getLastItem(group));

	return r.id >= startId && r.id <= endId;
};

const isLeftOfGroup = (r, group = []) => {
	const startId = getId(getFirstItem(group));
	return hasStationId(r) && r.id < startId;
};

const isRightOfGroup = (r, group = []) => {
	const endId = getId(getLastItem(group));
	return hasStationId(r) && r.id > endId;
};

const computeCustomExceptionReadings = (
	paramCustomGroups = [],
	readings = [],
	belowCriterionDataMap
) => {
	const customGroups = paramCustomGroups.filter(([g1, g2]) => g1 && g2);
	const customExceptionReadings = createIterArray(customGroups.length).map(
		() => []
	);

	const isBc = r => !!belowCriterionDataMap[r.id];

	let done = false;
	let readingIdx = 0;
	let groupIdx = 0;

	while (!done) {
		// ESCAPE - break loop
		if (
			isIdxOutOfBounds(readings, readingIdx) ||
			isIdxOutOfBounds(customGroups, groupIdx)
		) {
			done = true;
			break;
		}

		const reading = readings[readingIdx];
		const group = customGroups[groupIdx];

		// ESCAPE - skip this reading - move on to next reading
		if (!hasStationId(reading) || isLeftOfGroup(reading, group)) {
			readingIdx += 1;
			continue;
		}

		// ESCAPE - move on to next group;
		if (isRightOfGroup(reading, group)) {
			groupIdx += 1;
			continue;
		}

		if (!isWithinGroup(reading, group)) {
			debugger;
			// this should never be hit as we've already checked for valid station id and whether it is to left or right of group
			throw Error(
				'ERROR: dreiveCustomDataSources.computeCustomExceptionReadings() - unknown edge case occurred.'
			);
		}

		// MAIN
		if (!isBc(reading)) {
			customExceptionReadings.push(reading);
		}
		readingIdx += 1;
	}

	return customExceptionReadings;
};

// expects some less than comparitor which should return true if source item is less than target item.
const injectValidItemsInOrder = (
	sourceArr = [],
	sPropKey,
	targetArr = [],
	tPropKey,
	// eslint-disable-next-line no-unused-vars
	isItemValid = (item, propKey) => true,
	isLessThan = (a, b) => a < b
) => {
	let merged = [];
	const mergedMap = {};
	const sMap = {};
	const tMap = {};
	let sIdx = 0;
	let tIdx = 0;

	const hasBeenMerged = item => !!mergedMap[item.id];

	const isValidSourceItem = item =>
		isItemValid(item, sPropKey) && !hasBeenMerged(item);
	const isValidTargetItem = item =>
		isItemValid(item, tPropKey) && !hasBeenMerged(item);

	// shuffle in items from each array until one index passes length
	while (sIdx < sourceArr.length && tIdx < targetArr.length) {
		const sItem = sourceArr[sIdx];
		const tItem = targetArr[tIdx];

		if (!isValidSourceItem(sItem)) {
			sIdx += 1;
		} else if (!isValidTargetItem(tItem)) {
			tIdx += 1;
		} else if (isLessThan(sItem, tItem)) {
			merged.push(sItem);
			mergedMap[sItem.id] = sItem;
			sMap[sItem.id] = sItem;
			sIdx += 1;
		} else if (isLessThan(tItem, sItem)) {
			merged.push(tItem);
			mergedMap[tItem.id] = tItem;
			tMap[tItem.id] = tItem;
			tIdx += 1;
		} else {
			merged.push(sItem);
			mergedMap[sItem.id] = sItem;
			sMap[sItem.id] = sItem;
			sIdx += 1;
			tIdx += 1;
		}
	}

	// assume all items from target have been added and merge in remaing source items
	if (sIdx < sourceArr.length) {
		const sourceRemaining = sourceArr
			.slice(sIdx, sourceArr.length)
			.filter(isValidSourceItem);
		merged = [...merged, ...sourceRemaining];
		sourceRemaining.forEach(item => {
			sMap[item.id] = item;
		});
	}

	// assume all items from source have been added and merge in remaing target items
	if (tIdx < targetArr.length) {
		const targetRemaining = targetArr
			.slice(tIdx, targetArr.length)
			.filter(isValidTargetItem);
		merged = [...merged, ...targetRemaining];
		targetRemaining.forEach(item => {
			tMap[item.id] = item;
		});
	}

	return {
		merged,
		sMap,
		tMap
	};
};

const filterNotInBelowCriterion = (
	selectedDataValues = [],
	belowCriterionDataMap = {}
) => {
	const connectGap = (reading, prev) => {
		return (
			!!prev &&
			belowCriterionDataMap[reading.id] &&
			!belowCriterionDataMap[prev.id]
		);
	};

	let prev;
	return selectedDataValues.filter(r => {
		const result = connectGap(r, prev) || !belowCriterionDataMap[r.id];
		prev = r;
		return result;
	});
};

const createReadingsIdMap = (readings = []) => {
	return readings.reduce((acc, reading) => {
		acc[reading.id] = reading;
		return acc;
	}, {});
};

const createWithGaps = (
	customExceptionReadings,
	readingsIndexMap = {},
	readings = [],
	readingsWithChartGaps = [],
	readingsWithChartGapsIndexMap = {},
	key
) => {
	const getNextReadingFromWithGaps = reading => {
		let idx = readingsWithChartGapsIndexMap[reading.uuid] + 1;
		let nextReading;
		while (idx < readingsWithChartGaps.length && !nextReading) {
			const r = readingsWithChartGaps[idx];

			if (!!r[key] || !!r.isGap) {
				nextReading = r;
			}

			idx += 1;
		}
		return nextReading;
	};

	const computeSuperSetHasReadingsBetween = (
		prev,
		prevTrueIndex,
		currTrueIndex
	) => {
		if (!prev || currTrueIndex - prevTrueIndex === 1) {
			return false;
		}

		const valuesBetween = readings.slice(prevTrueIndex + 1, currTrueIndex);
		return valuesBetween.filter(r => !!r[key] && !r.isGap).length > 0;
	};

	const getGapBetween = (prev, prevGapIndex, currGapIndex) => {
		if (!prev || currGapIndex - prevGapIndex === 1) {
			return false;
		}

		const valuesBetween = readingsWithChartGaps.slice(
			prevGapIndex + 1,
			currGapIndex
		);
		const gaps = valuesBetween.filter(r => !!r && r.isGap);

		return gaps.length > 0 ? gaps[0] : undefined;
	};

	const getLastValue = arr => {
		return arr.length > 0 ? arr[arr.length - 1] : undefined;
	};

	const getSecondToLastValue = arr => {
		return arr.length > 1 ? arr[arr.length - 2] : undefined;
	};

	const push = (arr, val) => {
		arr.push(val);
	};

	const pushGap = (arr, gap) => {
		const lastValue = getLastValue(arr);
		if (lastValue && !lastValue.isGap) {
			push(arr, gap);
		}
	};

	let prev;
	const result = customExceptionReadings
		.filter(i => !i.isGap)
		.reduce((acc, r) => {
			const nextReadingFromWithGaps = getNextReadingFromWithGaps(r);

			const prevTrueIndex = !!prev && readingsIndexMap[prev.uuid];
			const prevGapIndex = !!prev && readingsWithChartGapsIndexMap[prev.uuid];

			const currTrueIndex = readingsIndexMap[r.uuid];
			const currGapIndex = readingsWithChartGapsIndexMap[r.uuid];

			const supersetHasReadingsBetween = computeSuperSetHasReadingsBetween(
				prev,
				prevTrueIndex,
				currTrueIndex
			);
			const gapBetween = getGapBetween(prev, prevGapIndex, currGapIndex);

			const hasValue = !!r[key];

			if (hasValue) {
				if (supersetHasReadingsBetween || gapBetween) {
					const prevTrueReading = readings[prevTrueIndex + 1];
					const readingGapToAdd = gapBetween || prevTrueReading;
					pushGap(acc, createGapReading(readingGapToAdd, acc.length));
					push(acc, r);
					prev = r;
				} else {
					push(acc, r);
					prev = r;
				}

				if (nextReadingFromWithGaps && nextReadingFromWithGaps.isGap) {
					const secondToLastValue = getSecondToLastValue(acc);
					const lastValue = getLastValue(acc);
					if (lastValue && (!secondToLastValue || secondToLastValue.isGap)) {
						push(acc, forceSinglePointRender(lastValue));
					}
					pushGap(acc, nextReadingFromWithGaps);
				}
			}

			// @todo - fix this, currently we are losing one remediation point per group in chart render
			// if (src.length === idx + 1) {
			//     const endToInject = readings[currTrueIndex + 1];
			//     if (endToInject) {
			//         const lastNonZeroValue = r[key] || getLastNonZeroValue(endToInject, key, readings, readingsIndexMap);
			//         acc.push(forceNonZero(endToInject, key, lastNonZeroValue));
			//     }
			// }

			return acc;
		}, []);

	return result;
};

// const logIds = arr => {
// 	console.log(arr.map(i => i.id).join(', '));
// 	console.log(arr.map(i => i.uuid).join(', '));
// 	console.log(arr.map(i => i.isGap).join(', '));
// };

const createGapReading = (r, idx) => {
	return !r.isGap
		? { id: r.id + 0.01, uuid: `${r.uuid}~${idx}`, isGap: true }
		: r;
};

const forceSinglePointRender = r => {
	return {
		...r,
		id: r.id + 0.5,
		uuid: `${r.uuid}~${r.id + 0.5}`,
		isFauxDataPoint: true
	};
};

// const getLastNonZeroValue = (reading, key, readings, readingsIndexMap) => {
// 	let idx = readingsIndexMap[reading.uuid];
// 	let result;

// 	while (!result && idx >= 0) {
// 		result = readings[idx][key];
// 		idx--;
// 	}

// 	return result || undefined;
// };

// const forceNonZero = (r, key, fallbackValue) => {
// 	return r[key] ? r : { ...r, [key]: fallbackValue };
// };

const _exports = {
	deriveNewDataSourcesFromNewExceptionsGroup,
	deriveNewDataSourcesFromDestroyedExceptionsGroup
};

export const TESTS = {
	..._exports,
	createWithGaps,
	forceSinglePointRender
};

export default {
	..._exports
};
