const defaultState = {
	settings: {}
};

const setDeveloperSettings = (state, settings) => {
	return {
		settings
	};
};

const reducer = (state = defaultState, action) => {
	const { type } = action;
	switch (type) {
		case 'CISV_DEVELOPER_MODE_ADD_SETTINGS':
			return setDeveloperSettings(state, action.settings);
		default:
			return state;
	}
};

export default reducer;
