/* eslint-disable import/prefer-default-export */
const { MAX_RECORDS } = require('../../../../../../workers/FilterByStdDev');

export const computeShowDecimatedData = readingsMeta => {
	const hasDatasetsExceedingMaxCount =
		readingsMeta.filter(dataset => {
			const { readings, readingsIndexMap, startReading, endReading } = dataset;
			if (readings.length < MAX_RECORDS) {
				return false;
			}
			if (!startReading || !endReading) {
				return false;
			}
			const startIndex = readingsIndexMap[startReading.uuid];
			const endIndex = readingsIndexMap[endReading.uuid];

			return endIndex - startIndex > MAX_RECORDS;
		}).length > 0;

	// show decimated data
	return hasDatasetsExceedingMaxCount;
};
