/* eslint-disable import/no-cycle */

import ajax from '../../../../scanline/utils/ajax'; // '../../scanline/utils/ajax';

const api = {
	getAccess() {
		return ajax(
			'',
			undefined,
			'get',
			undefined,
			false,
			'uploaderbucketaccessurl'
		);
	}
};

export default api;
