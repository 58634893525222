import React from 'react';

import Unstyled from './components/ApplicationsList/variants/Unstyled';
import Original from './components/ApplicationsList/variants/Original';
import NarrowTallV1 from './components/ApplicationsList/variants/NarrowTall.v1';
import NarrowTallV2 from './components/ApplicationsList/variants/NarrowTall.v2';
import NarrowTallV3 from './components/ApplicationsList/variants/NarrowTall.v3';
import NarrowTallV4 from './components/ApplicationsList/variants/NarrowTall.v4';

export const KEY_PLACEHOLDER = 'placeholder';
export const KEY_UNSTYLED = 'unstyled';
export const KEY_ORIGINAL = 'original';
export const KEY_NARROWTALL_V1 = 'narrowtall-v1';
export const KEY_NARROWTALL_V2 = 'narrowtall-v2';
export const KEY_NARROWTALL_V3 = 'narrowtall-v3';
export const KEY_NARROWTALL_V4 = 'narrowtall-v4';
export const DEFAULT_KEY = KEY_NARROWTALL_V2;

const Placeholder = () => <div>LIST: placeholder</div>;

export const applicationListVariants = [
	{
		displayText: 'LIST: placeholder',
		key: KEY_PLACEHOLDER,
		Component: Placeholder
	},
	{
		displayText: 'LIST: unstyled',
		key: KEY_UNSTYLED,
		Component: Unstyled
	},
	{
		displayText: 'LIST: original',
		key: KEY_ORIGINAL,
		Component: Original
	},
	{
		displayText: 'LIST: narrow tall - v1',
		key: KEY_NARROWTALL_V1,
		Component: NarrowTallV1
	},
	{
		displayText: 'LIST: narrow tall - v2',
		key: KEY_NARROWTALL_V2,
		Component: NarrowTallV2
	},
	{
		displayText: 'LIST: narrow tall - v3',
		key: KEY_NARROWTALL_V3,
		Component: NarrowTallV3
	},
	{
		displayText: 'LIST: narrow tall - v4',
		key: KEY_NARROWTALL_V4,
		Component: NarrowTallV4
	}
];

export const applicationListVariantsMap = applicationListVariants.reduce(
	(acc, obj) => {
		return {
			...acc,
			[obj.key]: obj
		};
	},
	{}
);

export const getApplicationsListComponent = key => {
	const obj =
		applicationListVariantsMap[key] || applicationListVariantsMap[DEFAULT_KEY];
	return obj.Component;
};

export default {
	applicationListVariants,
	applicationListVariantsMap,
	getApplicationsListComponent
};
