import { NAME_SPACE as ns } from './constants';

export const SET_ASSET = `${ns}_SET_ASSET`;

export const SET_ASSET_DATA = `${ns}_SET_ASSET_DATA`;

export const HIGHLIGHT = `${ns}_HIGHLIGHT`;

export const CHECK_CHANGED = `${ns}_CHECK_CHANGED`;

export const SET_PARAMS = `${ns}_SET_PARAMS`;

export const SET_PARAM_DATA = `${ns}_SET_PARAM_DATA`;

export const RESET_SELECTED = `${ns}_RESET_SELECTED`;
