import { createActions } from 'redux-actions';

const {
	addChartIsLoading,
	removeChartIsLoading,
	addChartIsRendering,
	removeChartIsRendering
} = createActions({
	ADD_CHART_IS_LOADING: CHART_ID => ({ CHART_ID }),
	REMOVE_CHART_IS_LOADING: CHART_ID => ({ CHART_ID }),
	ADD_CHART_IS_RENDERING: CHART_ID => ({ CHART_ID }),
	REMOVE_CHART_IS_RENDERING: CHART_ID => ({ CHART_ID })
});

export {
	addChartIsLoading,
	removeChartIsLoading,
	addChartIsRendering,
	removeChartIsRendering
};
