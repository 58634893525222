import {
	addGroupToRangeGroups as coreAddGroupToRangeGroups,
	removeGroupFromRangeGroups as coreRemoveGroupFromRangeGroups
} from './rangeGroups';

const interval = 0.01;
const selector = (r = {}) => r.id;
const adder = (r, i) => (!r ? r : { ...r, id: r.id + i });
const subtractor = (r, i) => (!r ? r : { ...r, id: r.id - i });

export const addGroupToRangeGroups = (group, rangeGroups) => {
	return coreAddGroupToRangeGroups(group, rangeGroups, selector);
};

export const removeGroupFromRangeGroups = (group, rangeGroups) => {
	return coreRemoveGroupFromRangeGroups(
		group,
		rangeGroups,
		selector,
		adder,
		subtractor,
		interval
	);
};
