import React from 'react';

import Unstyled from './components/Grid/variants/Unstyled';
import Original from './components/Grid/variants/Original';
import OriginalWMW from './components/Grid/variants/Original.wMW';
import OriginalWMWWVC from './components/Grid/variants/Original.wMW.wVC';

export const KEY_PLACEHOLDER = 'placeholder';
export const KEY_UNSTYLED = 'unstyled';
export const KEY_ORIGINAL = 'original';
export const KEY_ORIGINAL_WMW = 'original-wmw';
export const KEY_ORIGINAL_WMW_WVC = 'original-wmw-wvc';
export const DEFAULT_KEY = KEY_ORIGINAL_WMW_WVC;

const Placeholder = () => <div>GRID: placeholder</div>;

export const gridVariants = [
	{
		displayText: 'GRID: placeholder',
		key: KEY_PLACEHOLDER,
		Component: Placeholder
	},
	{
		displayText: 'GRID: unstyled',
		key: KEY_UNSTYLED,
		Component: Unstyled
	},
	{
		displayText: 'GRID: original',
		key: KEY_ORIGINAL,
		Component: Original
	},
	{
		displayText: 'GRID: original w/ max-width',
		key: KEY_ORIGINAL_WMW,
		Component: OriginalWMW
	},
	{
		displayText: 'GRID: original w/ max-width & vert-center',
		key: KEY_ORIGINAL_WMW_WVC,
		Component: OriginalWMWWVC
	}
];

export const gridVariantsMap = gridVariants.reduce((acc, obj) => {
	return {
		...acc,
		[obj.key]: obj
	};
}, {});

export const getGridComponent = key => {
	const obj = gridVariantsMap[key] || gridVariantsMap[DEFAULT_KEY];
	return obj.Component;
};

export default {
	gridVariants,
	gridVariantsMap,
	getGridComponent
};
