/* eslint-disable import/prefer-default-export */
/* eslint-disable import/no-cycle */
import { createActions } from 'redux-actions';
import {
	MSG_DAS_CHECKING_FOR_CHANGES,
	MSG_DAS_COLLECTING_CHANGE_NOTES,
	MSG_DAS_COMPUTING_MD5_KEYS,
	MSG_DAS_SAVING_PROJECT,
	MSG_DAS_ARCHIVING_SURVEYS,
	MSG_DAS_SAVING_REPORT,
	MSG_DAS_DOWNLOADING_REPORT
} from './constants';
import { selectStateSlice, selectIsNotificationExpanded } from './selectors';

const { setActionPlanMessage, setNotificationExpanded } = createActions(
	{
		SET_ACTION_PLAN_MESSAGE: message => ({ message }),
		SET_NOTIFICATION_EXPANDED: expanded => ({ expanded })
	},
	{ prefix: 'ActionPlanMessages' }
);

const clearActionPlanMessage = () => dispatch =>
	dispatch(setActionPlanMessage(undefined));

const setMessageCheckingForChanges = () => dispatch => {
	dispatch(setActionPlanMessage(MSG_DAS_CHECKING_FOR_CHANGES));
};

const setMessageCollectingChangeNotes = () => dispatch => {
	dispatch(setActionPlanMessage(MSG_DAS_COLLECTING_CHANGE_NOTES));
};

const setMessageComputingMD5Keys = () => dispatch => {
	dispatch(setActionPlanMessage(MSG_DAS_COMPUTING_MD5_KEYS));
};

const setMessageSavingProject = () => dispatch => {
	dispatch(setActionPlanMessage(MSG_DAS_SAVING_PROJECT));
};

const setMessageArchivingSurveys = () => dispatch => {
	dispatch(setActionPlanMessage(MSG_DAS_ARCHIVING_SURVEYS));
};

const setMessageSavingReport = () => dispatch => {
	dispatch(setActionPlanMessage(MSG_DAS_SAVING_REPORT));
};

const setMessageDownloadingReport = () => dispatch => {
	dispatch(setActionPlanMessage(MSG_DAS_DOWNLOADING_REPORT));
};

const toggleNotificationExpand = () => (dispatch, getState) => {
	const slice = selectStateSlice(getState());
	const isNotificationExpanded = selectIsNotificationExpanded(slice);
	dispatch(setNotificationExpanded(!isNotificationExpanded));
};

export {
	setActionPlanMessage,
	setMessageCheckingForChanges,
	setMessageCollectingChangeNotes,
	setMessageComputingMD5Keys,
	setMessageSavingProject,
	setMessageArchivingSurveys,
	setMessageSavingReport,
	setMessageDownloadingReport,
	clearActionPlanMessage,
	setNotificationExpanded,
	toggleNotificationExpand
};
