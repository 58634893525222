import _ from 'lodash';
import { createSortNumeric } from 'aegion_common_utilities/lib/Sort';

const sortByOrder = createSortNumeric('ASC', { getValue: (o = {}) => o.order });

export const transformCloudDefinitionsToClientDefinitions = (
	paramPermissionDefinitions = {}
) => {
	const permissionDefinitions = _.cloneDeep(paramPermissionDefinitions);
	const appKeys = Object.keys(permissionDefinitions);
	appKeys.forEach(appKey => {
		const app = permissionDefinitions[appKey];
		app.key = appKey;
		const permissionKeys = Object.keys(app.permissions || {});
		permissionKeys.forEach(permissionKey => {
			const permission = app.permissions[permissionKey];
			permission.key = permissionKey;
			permission.appKey = appKey;
		});
	}, {});
	return permissionDefinitions;
};

export const createMapPermissionsByGroupName = (permissionDefinitions = {}) => {
	const map = {};
	const appKeys = Object.keys(permissionDefinitions);
	appKeys.forEach(appKey => {
		const app = permissionDefinitions[appKey];
		const permissionKeys = Object.keys(app.permissions || {});
		permissionKeys.forEach(permissionKey => {
			const permission = app.permissions[permissionKey];
			map[permission.awsGroupName] = permission;
		});
	});
	return map;
};

export const createListPermissionDefinitions = (permissionDefinitions = {}) => {
	const appKeys = Object.keys(permissionDefinitions);
	const list = appKeys.map(k => permissionDefinitions[k]);
	list.sort(sortByOrder);
	return list;
};

export const createMapPermissionsListByAppKey = (
	permissionDefinitions = {}
) => {
	const map = {};
	const appKeys = Object.keys(permissionDefinitions);
	appKeys.forEach(appKey => {
		const app = permissionDefinitions[appKey];
		const permissionKeys = Object.keys(app.permissions || {});
		const list = permissionKeys.map(
			permissionKey => app.permissions[permissionKey]
		);
		list.sort(sortByOrder);
		map[app.key] = list;
	});
	return map;
};

export default {
	transformCloudDefinitionsToClientDefinitions,
	createMapPermissionsByGroupName,
	createListPermissionDefinitions,
	createMapPermissionsListByAppKey
};
