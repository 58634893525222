import { GEO_ALGORITHMS } from 'aegion_common_utilities/lib/MapProcessing/config';

// eslint-disable-next-line import/no-webpack-loader-syntax, import/extensions, import/no-unresolved
// import ReadingWorker from 'workerize-loader?inline!../../redux/actions/workers/readings.worker.js';
import { flattenGpsReadings } from '../../redux/actions/workers/readings.worker';

// eslint-disable-next-line no-unused-vars, import/no-cycle
import L from '../Leaflet';

// eslint-disable-next-line import/no-webpack-loader-syntax, import/extensions, import/no-unresolved

// eslint-disable-next-line import/no-cycle
import { setCacheJson, getCacheJson, onGetError } from '../Cache/JobCache';

import {
	FILE_INFO_KEY_BY_URL,
	DAT_NON_MAP_URL_PROPERTIES
} from './JobUtil.constants';
import {
	getStringWithoutUrlPostscript,
	getJsonFromDatFileUrl
} from './JobUtil.misc';

// const readingWorker = ReadingWorker();

// Will turn things like "gpsreadingsurl" into "gpsreadings"
const setDatFieldValues = async (urlKey, json) => {
	const newFieldName = getStringWithoutUrlPostscript(urlKey);

	const data = json;

	return {
		[newFieldName]: data
	};
};

const asyncValidateDatFileProperty = (propertyName, datFile) => {
	if (propertyName in datFile) {
		return Promise.resolve(datFile[propertyName].fileVersionId);
	}

	return Promise.reject(
		new Error(`'${propertyName}' not found in datFile "${datFile.fileName}"`)
	);
};

const asyncGetPropertyFromDatFile = (propertyName, datFile) => {
	return asyncValidateDatFileProperty(propertyName, datFile).then(
		() => datFile[propertyName].fileVersionId
	);
};

const asyncValidateFieldsAndReturnVersion = (urlFieldName, datFile) => {
	return asyncValidateDatFileProperty(urlFieldName, datFile).then(() => {
		const fileVersionKey = FILE_INFO_KEY_BY_URL[urlFieldName];
		return asyncGetPropertyFromDatFile(fileVersionKey, datFile);
	});
};

// Hopefully this rarely/never gets called - this means things broke
const getDataFromDatFileFieldWithoutCache = url => {
	return getJsonFromDatFileUrl(url).then(({ json }) => json);
};

const getDataFromDatFileFieldWithCache = async (url, fileVersion, urlKey) => {
	const jsonResults = await getCacheJson(url, fileVersion);

	if (jsonResults) {
		return jsonResults;
	}

	return getJsonFromDatFileUrl(url).then(({ json, contentLength }) => {
		if (['gpsreadingsurl', 'preprocessedGPSReadingsurl'].includes(urlKey)) {
			return Promise.resolve(
				flattenGpsReadings(json, {
					algorithm: GEO_ALGORITHMS.GEOD
				})
			).then(data => {
				setCacheJson(url, data, fileVersion, parseInt(contentLength, 10), {
					updateReason: 'pageload'
				});
				return data;
			});
		}
		setCacheJson(url, json, fileVersion, parseInt(contentLength, 10), {
			updateReason: 'pageload'
		});
		return json;
	});
};

const getDataFromDatFileField = async (urlKey, datFile) => {
	const url = datFile[urlKey];
	let fileVersion;
	try {
		fileVersion = await asyncValidateFieldsAndReturnVersion(urlKey, datFile);

		return getDataFromDatFileFieldWithCache(url, fileVersion, urlKey);
	} catch (e) {
		onGetError(fileVersion, e);
		return getDataFromDatFileFieldWithoutCache(url);
	}
};

export const getDatData = datFile => {
	let newDatFile = datFile;
	// Will return a promise for each
	const fieldsWithUrls = DAT_NON_MAP_URL_PROPERTIES;
	const urlFieldsToPull = fieldsWithUrls.filter(
		field => field !== 'envelopeurl'
	);

	const timeStart = new Date();
	return Promise.all(
		urlFieldsToPull.map(urlKey => {
			return getDataFromDatFileField(urlKey, datFile).then(json => {
				return setDatFieldValues(urlKey, json).then(dataFieldValue => {
					newDatFile = {
						...newDatFile,
						...dataFieldValue
					};
				});
			});
		})
	).then(() => {
		const totalTimePassed = new Date() - timeStart;
		return { newDatFile, totalTimePassed };
	});
};

export const getNewGlobalDataFromNewDatFiles = (globalData, newDatFiles) => {
	const newDatFilesByFileName = {};
	newDatFiles.forEach(datFile => {
		const { fileName } = datFile;
		newDatFilesByFileName[fileName] = datFile;
	});

	const newMasterLst = {};
	Object.keys(globalData.MasterLST).forEach(key => {
		// We are updating this whenever a datFile is updated, so we probably just have one datFile
		const newDatFile = newDatFilesByFileName[key];
		if (newDatFile) {
			newMasterLst[key] = newDatFilesByFileName[key];
		} else {
			newMasterLst[key] = globalData.MasterLST[key];
		}
	});

	const newGlobalData = {
		...globalData,
		MasterLST: newMasterLst
	};

	return newGlobalData;
};
