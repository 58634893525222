import { NAME_SPACE as ns } from './constants';

export const FETCH_JOB_START = `${ns}FETCH_JOB_START`;
export const FETCH_JOB_SUCCESS = `${ns}FETCH_JOB_SUCCESS`;
export const FETCH_JOB_FAIL = `${ns}FETCH_JOB_FAIL`;

export const FETCH_JOB_METADATA_SUCCESS = `${ns}FETCH_JOB_METADATA_SUCCESS`;
export const FETCH_JOB_LINES_SUCCESS = `${ns}FETCH_JOB_LINES_SUCCESS`;
export const FETCH_JOB_POLYGON_SUCCESS = `${ns}FETCH_JOB_POLYGON_SUCCESS`;
export const FETCH_JOB_DATA_SUCCESS = `${ns}FETCH_JOB_DATA_SUCCESS`;

export const VIEWPORT_CHANGED = `${ns}VIEWPORT_CHANGED`;
