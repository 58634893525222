import { fetch } from 'whatwg-fetch';

class S3Signed {
	fetchJSON = signedUrl => {
		if (!signedUrl) {
			return Promise.reject(
				new Error('S3Signed.get : s3key missing from arguments')
			);
		}
		return fetch(signedUrl).then(res => res.json());
	};
}

export default new S3Signed();
