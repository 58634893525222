"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.parseDateAndClearTime = exports.parseDate = exports.DATE_RE = void 0;

function _slicedToArray(arr, i) {
  return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _unsupportedIterableToArray(arr, i) || _nonIterableRest();
}

function _nonIterableRest() {
  throw new TypeError("Invalid attempt to destructure non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method.");
}

function _unsupportedIterableToArray(o, minLen) {
  if (!o) return;
  if (typeof o === "string") return _arrayLikeToArray(o, minLen);
  var n = Object.prototype.toString.call(o).slice(8, -1);
  if (n === "Object" && o.constructor) n = o.constructor.name;
  if (n === "Map" || n === "Set") return Array.from(o);
  if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen);
}

function _arrayLikeToArray(arr, len) {
  if (len == null || len > arr.length) len = arr.length;

  for (var i = 0, arr2 = new Array(len); i < len; i++) {
    arr2[i] = arr[i];
  }

  return arr2;
}

function _iterableToArrayLimit(arr, i) {
  var _i = arr == null ? null : typeof Symbol !== "undefined" && arr[Symbol.iterator] || arr["@@iterator"];

  if (_i == null) return;
  var _arr = [];
  var _n = true;
  var _d = false;

  var _s, _e;

  try {
    for (_i = _i.call(arr); !(_n = (_s = _i.next()).done); _n = true) {
      _arr.push(_s.value);

      if (i && _arr.length === i) break;
    }
  } catch (err) {
    _d = true;
    _e = err;
  } finally {
    try {
      if (!_n && _i["return"] != null) _i["return"]();
    } finally {
      if (_d) throw _e;
    }
  }

  return _arr;
}

function _arrayWithHoles(arr) {
  if (Array.isArray(arr)) return arr;
}
/* eslint-disable no-useless-escape */


var baseMonthDayYear = '\\d{1,2}[\\/-]\\d{1,2}[\\/-](\\d{2}|\\d{4})';
var baseYearMonthDay = '\\d{4}[\\/-]\\d{1,2}[\\/-]\\d{1,2}';
var nextCharNotNumeric = '(?=($|\\D))';
var DATE_RE = {
  isMonthDayYear: new RegExp("^".concat(baseMonthDayYear, "$"), 'i'),
  isYearMonthDay: new RegExp("^".concat(baseYearMonthDay, "$"), 'i'),
  containsMonthDayYear: new RegExp("^".concat(baseMonthDayYear).concat(nextCharNotNumeric), 'i'),
  containsYearMonthDay: new RegExp("^".concat(baseYearMonthDay).concat(nextCharNotNumeric), 'i')
};
exports.DATE_RE = DATE_RE;

var parseDateAndClearTime = function parseDateAndClearTime(str) {
  var result;

  if (typeof str !== 'string') {
    return str;
  }

  if (DATE_RE.containsMonthDayYear.test(str)) {
    var _str$match = str.match(DATE_RE.containsMonthDayYear);

    var _str$match2 = _slicedToArray(_str$match, 1);

    result = _str$match2[0];

    if (DATE_RE.isMonthDayYear.test(result)) {
      return "".concat(result, " 00:00:00");
    }
  }

  if (DATE_RE.containsYearMonthDay.test(str)) {
    var _str$match3 = str.match(DATE_RE.containsYearMonthDay);

    var _str$match4 = _slicedToArray(_str$match3, 1);

    result = _str$match4[0];

    if (DATE_RE.isYearMonthDay.test(result)) {
      return "".concat(result, " 00:00:00");
    }
  }

  return str;
};

exports.parseDateAndClearTime = parseDateAndClearTime;

var parseDate = function parseDate(str) {
  var result;

  if (typeof str !== 'string') {
    return str;
  }

  if (DATE_RE.containsMonthDayYear.test(str)) {
    var _str$match5 = str.match(DATE_RE.containsMonthDayYear);

    var _str$match6 = _slicedToArray(_str$match5, 1);

    result = _str$match6[0];

    if (DATE_RE.isMonthDayYear.test(result)) {
      return result;
    }
  }

  if (DATE_RE.containsYearMonthDay.test(str)) {
    var _str$match7 = str.match(DATE_RE.containsYearMonthDay);

    var _str$match8 = _slicedToArray(_str$match7, 1);

    result = _str$match8[0];

    if (DATE_RE.isYearMonthDay.test(result)) {
      return result;
    }
  }

  return str;
};

exports.parseDate = parseDate;