import { MiscUtils } from 'aegion_common_utilities';
import { isValidReadingValue } from '../../components/TabPages/utils/spikeeditor';
// eslint-disable-next-line import/no-cycle
import { getFileType } from '../../redux/actions/util/spike-editor';
import { FILE_TYPE } from '../../redux/actions/util/spike-editor-config';
import { convert } from '../../components/misc/utils/readings';

function capitalizeFirstLetter(string) {
	return string.charAt(0).toUpperCase() + string.slice(1);
}

function getOriginalFieldName(fieldName) {
	return `original${capitalizeFirstLetter(fieldName)}`;
}

const ReadingsAutoCorrectionUtil = {
	setReadingsIsMovedFromFlags(gpsreadings, readingsLedger) {
		const {
			readings1: readings1Ledger,
			readings2: readings2Ledger
		} = readingsLedger;

		// Sppeds up process 25 times (or more by using a hash)
		const readings1Hash = {};
		const readings2Hash = {};

		readings1Ledger.forEach(r => {
			readings1Hash[r] = true;
		});
		readings2Ledger.forEach(r => {
			readings2Hash[r] = true;
		});

		// TODO: The response should eventually have multiple gpsReadingsSets
		gpsreadings.forEach((reading, i) => {
			const isReading1Moved = readings1Hash[i];
			const isReading2Moved = readings2Hash[i];
			if (isReading1Moved) {
				reading.is_reading_1_moved = true;
			}
			if (isReading2Moved) {
				reading.is_reading_2_moved = true;
			}
		});
	},

	// Reading indexes from the ledger start at the beginning and make no distinction between on/off readings
	// ... This changes those indexes (from the ledger) and makes the changes be for the on/off readings
	getChangesAlignedByDataType(gpsreadings, readingField, ledgerEntry) {
		const newIndexes = [];

		let counterByField = 0;

		// Use hash trick for faster indexing
		const ledgerByIndexValue = {};
		ledgerEntry.forEach(rawIndex => {
			ledgerByIndexValue[rawIndex] = true;
		});

		for (let i = 0; i < gpsreadings.length; i += 1) {
			const reading = gpsreadings[i];
			if (ledgerByIndexValue[i]) {
				newIndexes.push(counterByField);
			}

			if (newIndexes.length === ledgerEntry.length) {
				break;
			}

			if (reading[readingField]) {
				counterByField += 1;
			}
		}

		return newIndexes;
	},

	// Makes a copy of gpsreadings and then mutates that copy (in essence, immutable)
	mergeReadingsWithFlags(originalDatFile, readingsLedger, isGPSSync) {
		const { surveyType } = originalDatFile;
		// Make a copy
		// TODO: This is a pretty big change, we need to make sure that gpsreadings is never bloated or this will get crazy
		const gpsreadings = JSON.parse(JSON.stringify(originalDatFile.gpsreadings)); // Create a copy

		if (!readingsLedger) {
			return gpsreadings;
		}

		const changes = [
			[
				this.getChangesAlignedByDataType(
					gpsreadings,
					'reading1',
					readingsLedger.readings1
				),
				this.getChangesAlignedByDataType(
					gpsreadings,
					'reading2',
					readingsLedger.readings2
				)
			]
		];

		this.interpolateReadings(gpsreadings, changes, surveyType, isGPSSync, true);

		return gpsreadings;
	},

	countReadingsFromGpsReadings(
		gpsReadings,
		readingField,
		surveyType,
		isGPSSync
	) {
		const readingsWithGivenField = gpsReadings.filter(r =>
			isValidReadingValue(r, readingField, surveyType, isGPSSync)
		);

		return readingsWithGivenField.length;
	},

	getNextReading(
		gpsReadings,
		currentGpsReadingIndex,
		readingField,
		surveyType,
		isGPSSync
	) {
		// This needs to be set to 0 after we go through the first gpsReading
		const readingIndexToStart = currentGpsReadingIndex + 1;
		for (let i = readingIndexToStart; i < gpsReadings.length; i += 1) {
			const reading = gpsReadings[i];
			// We don't want undefined, null, or 0
			if (isValidReadingValue(reading, readingField, surveyType, isGPSSync)) {
				return reading;
			}
		}

		return null;
	},

	// We will recursively go through this until we find a matching readings (or we give up)
	getPreviousReading(
		gpsReadings,
		gpsReadingIndexToStart,
		readingField,
		surveyType,
		isGPSSync
	) {
		// This will start at the immediately previous reading (gpsReadingIndexToStart is the index before the "current" reading index)
		for (let i = gpsReadingIndexToStart; i >= 0; i -= 1) {
			const reading = gpsReadings[i];

			// We don't want undefined, null, or 0
			if (isValidReadingValue(reading, readingField, surveyType, isGPSSync)) {
				return reading;
			}
		}

		return null;
	},

	roundValue(originalValue, useMv = false) {
		if (useMv) {
			return Math.round(originalValue);
		}

		return MiscUtils.roundToDigitPlace(originalValue, 3);
	},

	interpolateReadingsInGroup(
		useMv,
		group,
		readingField,
		surveyType,
		isGPSSync,
		setIsMoved = false
	) {
		if (group.length < 3) {
			return; // We need two points to surround the point selected - This can only occur if the user selected the first or last reading
		}

		const firstField = group[0];
		const lastField = group[group.length - 1];
		const firstReadingFieldValue = convert(firstField[readingField]);
		const lastReadingFieldValue = convert(lastField[readingField]);

		const firstStationId = firstField.StationId;
		const lastStationId = lastField.StationId;

		// Everything in between the first and last field
		const readingsToInterpolate = group.slice(1, -1);

		// y = mx + b
		const deltaX = lastStationId - firstStationId; // the better way to do this is subtract stationId
		const deltaY = lastReadingFieldValue - firstReadingFieldValue;
		const m = deltaY / deltaX;
		const b = firstReadingFieldValue;
		readingsToInterpolate.forEach(reading => {
			const { StationId } = reading;
			const x = StationId - firstStationId; // x should be calculated by taking current stationId minus first stationId
			const y = m * x + b;

			if (setIsMoved) {
				switch (readingField) {
					case 'reading1':
						reading.is_reading_1_moved = true;
						reading.old_reading1 = reading.reading1;
						break;
					case 'reading2':
						reading.is_reading_2_moved = true;
						reading.old_reading2 = reading.reading2;
						break;
					default:
						throw new Error(`Unkown reading field "${readingField}"`);
				}
			}

			const roundedY = ReadingsAutoCorrectionUtil.roundValue(
				y,
				useMv &&
					(readingField === 'reading1' ||
						readingField === 'reading2' ||
						readingField === 'on' ||
						readingField === 'off')
			);

			const originalField = getOriginalFieldName(readingField);
			if (reading[originalField] === undefined) {
				reading[originalField] = reading[readingField];
			}
			reading[readingField] = roundedY;
		});
	},

	averageReadingsInGroup(useMv, group, readingField) {
		if (group.length < 3) {
			return; // We need two points to surround the point selected - This can only occur if the user selected the first or last reading
		}

		const firstReadingFieldValue = convert(group[0][readingField]);
		const lastReadingFieldValue = convert(group.slice(-1)[0][readingField]);

		const readingsToChange = group.slice(1, -1);
		const averagedValue = (firstReadingFieldValue + lastReadingFieldValue) / 2;

		const roundedAverageValue = ReadingsAutoCorrectionUtil.roundValue(
			averagedValue,
			useMv && (readingField === 'on' || readingField === 'off')
		);

		readingsToChange.forEach(reading => {
			const originalField = getOriginalFieldName(readingField);
			if (reading[originalField] === undefined) {
				reading[originalField] = reading[readingField];
			}
			reading[readingField] = roundedAverageValue;
		});
	},

	createChangeGroupsForSpikeEditorChanges(
		gpsReadings,
		indexes,
		readingField,
		surveyType,
		isGPSSync
	) {
		if (indexes.length === 0) {
			return [];
		}

		const allChangeGroups = [];
		let currentReadingsGroup = [];

		const readingsCount = this.countReadingsFromGpsReadings(
			gpsReadings,
			readingField,
			surveyType,
			isGPSSync
		);

		// We are starting with the highest numbers and going down
		let currentReadingIndex = readingsCount;
		for (let index = gpsReadings.length - 1; index >= 0; index -= 1) {
			const reading = gpsReadings[index];

			if (isValidReadingValue(reading, readingField, surveyType, isGPSSync)) {
				currentReadingIndex -= 1;
			}

			if (!isValidReadingValue(reading, readingField, surveyType, isGPSSync)) {
				// Do nothing
			} else if (
				currentReadingIndex !== 0 &&
				indexes.includes(currentReadingIndex)
			) {
				// Add the next item - we need this for interpolation
				if (currentReadingsGroup.length === 0) {
					const nextReading = this.getNextReading(
						gpsReadings,
						index,
						readingField,
						surveyType,
						isGPSSync
					);

					// If it is not found, then this `reading` will be used as the interpolated value
					if (nextReading) {
						currentReadingsGroup.push(nextReading);
					}
				}

				currentReadingsGroup.push(reading);
				// We have reached the end of the group - now we add this group to all and then reset
			} else if (currentReadingsGroup.length > 0) {
				// Add the previous item - we need this for interpolation
				const previousReading = this.getPreviousReading(
					gpsReadings,
					index,
					readingField,
					surveyType,
					isGPSSync
				);

				if (previousReading !== null) {
					currentReadingsGroup.push(previousReading);
				}

				allChangeGroups.push(currentReadingsGroup);

				// Reset the group
				currentReadingsGroup = [];
			}
		}

		return allChangeGroups;
	},

	getSpikeEditorChangeGroupsFromChanges(
		gpsReadings,
		changes,
		surveyType,
		isGPSSync
	) {
		const [
			onIndexes,
			offIndexes,
			acOnIndexes,
			acOffIndexes
		] = this.getUniqueIndexesFromUserChanges(changes);

		const readingFieldsToUpdate = [
			['reading1', onIndexes],
			['reading2', offIndexes],
			['acOn', acOnIndexes],
			['acOff', acOffIndexes]
		];

		const changeGroupsByReadingType = readingFieldsToUpdate.map(
			([readingField, indexes]) => [
				readingField,
				this.createChangeGroupsForSpikeEditorChanges(
					gpsReadings,
					indexes,
					readingField,
					surveyType,
					isGPSSync
				)
			]
		);

		return changeGroupsByReadingType;
	},

	isReadingGreaterThanTen(readingValue) {
		const { reading1, reading2 } = readingValue;
		if (reading1 && Math.abs(reading1) > 10) {
			return true;
		}

		if (reading2 && Math.abs(reading2) > 10) {
			return true;
		}

		return false;
	},

	// Deprecated
	areReadingsMv(/* gpsReadings */) {
		return true;
		/* const middleGpsReadingIndex = Math.round(gpsReadings.length / 2);
		const lastGpsReadingIndex = gpsReadings.length - 1;
		const gpsReadingsToCheck = [
			gpsReadings[0],
			gpsReadings[middleGpsReadingIndex],
			gpsReadings[lastGpsReadingIndex]
		];

		const countGreaterThanTen = gpsReadingsToCheck.filter(
			ReadingsAutoCorrectionUtil.isReadingGreaterThanTen
		).length;

		// if, on average, readings are greater than 10, assume MV
		if (countGreaterThanTen / gpsReadingsToCheck.length > 0.5) {
			return true;
		}

		return false; */
	},

	// This does mutate data directly, but a copy should have made before this point
	interpolateReadings(gpsReadings, changes, surveyType, isGPSSync, setIsMoved) {
		const useMv = true; // ReadingsAutoCorrectionUtil.areReadingsMv(gpsReadings);
		const changeGroupsByReadingType = this.getSpikeEditorChangeGroupsFromChanges(
			gpsReadings,
			changes,
			surveyType,
			isGPSSync
		);

		changeGroupsByReadingType.forEach(([readingField, changeGroups]) => {
			changeGroups.forEach(changeGroup => {
				// Use algebra to find the interpolated readings of each group
				this.interpolateReadingsInGroup(
					useMv,
					changeGroup,
					readingField,
					surveyType,
					isGPSSync,
					setIsMoved
				);
			});
		});
	},

	setManualValueInGroup(
		gpsReadings,
		indexes,
		readingField,
		value,
		surveyType,
		isGPSSync
	) {
		let count = -1;
		// Make copy and sort ascending
		let indexesLeft = indexes.slice().sort((a, b) => a - b);

		const gpsReadingsLength = gpsReadings.length;

		for (let i = 0; i < gpsReadingsLength; i += 1) {
			const reading = gpsReadings[i];
			if (isValidReadingValue(reading, readingField, surveyType, isGPSSync)) {
				count += 1;

				if (indexesLeft.includes(count)) {
					const originalField = getOriginalFieldName(readingField);
					if (reading[originalField] === undefined) {
						reading[originalField] = reading[readingField];
					}
					reading[readingField] = value;
					// reset the number indexes left
					indexesLeft = indexesLeft.slice(1);

					// Stop if there are no more indexes;
					if (indexesLeft.length === 0) {
						return;
					}
				}
			}
		}
	},

	createManualValueForReadings(
		gpsReadings,
		userChanges,
		surveyType,
		isGPSSync
	) {
		userChanges.forEach(
			({ onIndexes, offIndexes, acOnIndexes, acOffIndexes, value }) => {
				if (onIndexes.length) {
					this.setManualValueInGroup(
						gpsReadings,
						onIndexes,
						'reading1',
						value,
						surveyType,
						isGPSSync
					);
				}
				if (offIndexes.length) {
					this.setManualValueInGroup(
						gpsReadings,
						offIndexes,
						'reading2',
						value,
						surveyType,
						isGPSSync
					);
				}
				if (acOnIndexes.length) {
					this.setManualValueInGroup(
						gpsReadings,
						acOnIndexes,
						'acOn',
						value,
						surveyType,
						isGPSSync
					);
				}
				if (acOffIndexes.length) {
					this.setManualValueInGroup(
						gpsReadings,
						acOffIndexes,
						'acOff',
						value,
						surveyType,
						isGPSSync
					);
				}
			}
		);
	},

	createAverageForReadings(gpsReadings, userChanges, surveyType, isGPSSync) {
		const useMv = true; // ReadingsAutoCorrectionUtil.areReadingsMv(gpsReadings);

		const changeGroupsByReadingType = this.getSpikeEditorChangeGroupsFromChanges(
			gpsReadings,
			userChanges,
			surveyType,
			isGPSSync
		);

		changeGroupsByReadingType.forEach(([readingField, changeGroups]) => {
			changeGroups.forEach(changeGroup => {
				this.averageReadingsInGroup(useMv, changeGroup, readingField);
			});
		});
	},

	// This does mutate data directly, but a copy should have made before this point
	revertSpikes(autoCorrectedReadingsData, userChanges /* , surveyType */) {
		const [onIndexes, offIndexes] = this.getUniqueIndexesFromUserChanges(
			userChanges
		);
		if (autoCorrectedReadingsData === null) {
			return;
		}
		let onCounter = 0;
		let offCounter = 0;

		const maxOnIndex = onIndexes.slice(-1)[0];
		const maxOffIndex = offIndexes.slice(-1)[0];

		let allOnReadingsFound = maxOnIndex !== 0 && !maxOnIndex;
		let allOffReadingsFound = maxOffIndex !== 0 && !maxOffIndex;

		for (let index = 0; index < autoCorrectedReadingsData.length; index += 1) {
			const reading = autoCorrectedReadingsData[index];
			if (!allOnReadingsFound && reading.reading1) {
				if (onIndexes.includes(onCounter) && reading.is_reading_1_moved) {
					reading.reading1 = reading.old_reading1;
				}
				if (onCounter === maxOnIndex) {
					allOnReadingsFound = true;
				}

				onCounter += 1;
			}
			if (!allOffReadingsFound && reading.reading2) {
				if (offIndexes.includes(offCounter) && reading.is_reading_2_moved) {
					reading.reading2 = reading.old_reading2;
				}

				if (offCounter === maxOffIndex) {
					allOffReadingsFound = true;
				}

				offCounter += 1;
			}

			if (allOnReadingsFound && allOffReadingsFound) {
				return;
			}
		}
	},

	getSortedUniqueFields(arrayItems) {
		// This makes a copy so that we can sort without issues
		const uniqueItems = [...new Set(arrayItems)];
		// Sort ascending
		const uniqueSortedItems = uniqueItems.sort((a, b) => a - b);

		return uniqueSortedItems;
	},

	getUniqueIndexesFromUserChanges(userChanges) {
		let allOnIndexes = [];
		let allOffIndexes = [];
		let allAcOnIndexes = [];
		let allAcOffIndexes = [];
		userChanges.forEach(
			([onIndexes, offIndexes, acOnIndexes, acOffIndexes]) => {
				allOnIndexes = allOnIndexes.concat(onIndexes);
				allOffIndexes = allOffIndexes.concat(offIndexes);
				allAcOnIndexes = allAcOnIndexes.concat(acOnIndexes);
				allAcOffIndexes = allAcOffIndexes.concat(acOffIndexes);
			}
		);

		const uniqueOnIndexes = this.getSortedUniqueFields(allOnIndexes);
		const uniqueOffIndexes = this.getSortedUniqueFields(allOffIndexes);
		const uniqueAcOnIndexes = this.getSortedUniqueFields(allAcOnIndexes);
		const uniqueAcOffIndexes = this.getSortedUniqueFields(allAcOffIndexes);

		return [
			uniqueOnIndexes,
			uniqueOffIndexes,
			uniqueAcOnIndexes,
			uniqueAcOffIndexes
		];
	},

	applyUserEdits(
		gpsreadings,
		{ fileName, surveyType, isGPSSync },
		spikeEditorUserChanges
	) {
		if (!gpsreadings || !fileName) {
			return;
		}

		if (spikeEditorUserChanges.length === 0) {
			return;
		}

		// TODO: add "average" and "set-value"
		const revertSpikeChanges = [];
		const interpolateReadingsChanges = [];
		const createAverageForReadingsChanges = [];
		const createManualReadingValueChanges = [];

		// TODO: This is an expensive operation O(n^2) - flatten spikeEditorUserChanges first
		for (let i = 0; i < spikeEditorUserChanges.length; i += 1) {
			const {
				onIndexes,
				offIndexes,
				acOnIndexes,
				acOffIndexes,
				type,
				value
			} = spikeEditorUserChanges[i];

			// Note that reveerSpikes will directly mutate the gpsreadings
			switch (type) {
				case 'remove-auto-correction':
					revertSpikeChanges.push([
						onIndexes,
						offIndexes,
						acOnIndexes,
						acOffIndexes
					]);
					break;
				case 'interpolate-readings':
					interpolateReadingsChanges.push([
						onIndexes,
						offIndexes,
						acOnIndexes,
						acOffIndexes
					]);
					break;
				case 'average-readings':
					createAverageForReadingsChanges.push([
						onIndexes,
						offIndexes,
						acOnIndexes,
						acOffIndexes
					]);
					break;
				case 'manual-value':
					createManualReadingValueChanges.push({
						onIndexes,
						offIndexes,
						acOnIndexes,
						acOffIndexes,
						value
					});
					break;
				default:
					throw new Error(`Unkown user edit change type ${type}`);
			}
		}

		// Run this one first as its position never changes
		this.createManualValueForReadings(
			gpsreadings,
			createManualReadingValueChanges,
			surveyType,
			isGPSSync
		);
		this.revertSpikes(gpsreadings, revertSpikeChanges, surveyType, isGPSSync);
		this.interpolateReadings(
			gpsreadings,
			interpolateReadingsChanges,
			surveyType,
			isGPSSync
		);
		this.createAverageForReadings(
			gpsreadings,
			createAverageForReadingsChanges,
			surveyType,
			isGPSSync
		);
	},

	mergeReadingsWithFlagsAndUserChanges(
		datFile,
		readingsLedgersByFile,
		spikeEditorUserChangesByFile
	) {
		// const { readingsLedgersByFile } = this.props;
		const { fileName, surveyType } = datFile;

		const fileType = getFileType(datFile);
		const isGPSSync = fileType === FILE_TYPE.ON_OFF_GPS_SYNC;
		const readingsLedger = readingsLedgersByFile[fileName];
		const spikeEditorUserChanges = spikeEditorUserChangesByFile[fileName] || [];
		if (!readingsLedger && !spikeEditorUserChanges.length) {
			return datFile.gpsreadings;
		}

		// This actually makes a copy
		const mergedReadings = ReadingsAutoCorrectionUtil.mergeReadingsWithFlags(
			datFile,
			readingsLedger,
			isGPSSync
		);

		this.applyUserEdits(
			mergedReadings,
			{ fileName, surveyType, isGPSSync },
			spikeEditorUserChanges
		);

		return mergedReadings;
	},

	getStationIdsOfChangedReadings(originalGpsReadings, newGpsReadings) {
		const changesByStationId = {};

		originalGpsReadings.forEach((originalGpsReading, i) => {
			const newReading = newGpsReadings[i];

			const {
				reading1: onOld,
				reading2: offOld,
				StationId: oldStationId
			} = originalGpsReading;
			const {
				reading1: onNew,
				reading2: offNew,
				StationId: newStationId
			} = newReading;

			// Validate data
			if (oldStationId !== newStationId) {
				throw new Error(
					`Station ids do not match. This is a programming error old=${oldStationId} and new=${newStationId}`
				);
			}

			// Compare on/off readings between new/old data - This is a bit messy but I would rather be verbose and abstract things too much
			if (onOld !== onNew) {
				changesByStationId[oldStationId] =
					changesByStationId[oldStationId] || {};
				changesByStationId[oldStationId].on = [onOld, onNew];
			}
			if (offOld !== offNew) {
				changesByStationId[oldStationId] =
					changesByStationId[oldStationId] || {};
				changesByStationId[oldStationId].off = [offOld, offNew];
			}
		});

		return changesByStationId;
	},

	// Note: This makes a copy of gpsreadings (and mutates the copy)
	prepareSaveAutoCorrectionReadings(
		datFile,
		readingsLedgersByFile,
		spikeEditorUserChangesByFile
	) {
		// Create a copy with all applied changes (from ML and user)
		const newReadings = ReadingsAutoCorrectionUtil.mergeReadingsWithFlagsAndUserChanges(
			datFile,
			readingsLedgersByFile,
			spikeEditorUserChangesByFile
		);

		// replace data from oldReadings with selected items from newReadings (just reading1 and reading2)
		newReadings.forEach(gpsReading => {
			if (gpsReading.is_reading_1_moved) {
				delete gpsReading.is_reading_1_moved;
			}
			if (gpsReading.is_reading_2_moved) {
				delete gpsReading.is_reading_2_moved;
			}
			if (gpsReading.is_moved) {
				delete gpsReading.is_moved;
			}
		});

		return newReadings;
	},
	// Making this its own object because of the number of dependent methods
	SaveAutoCorrectionSendEvent: {
		// This only combines 'remove-auto-correction' types of userChanges
		combineUserChangesToRevertIndexes(userChangesAll) {
			const userChangeSets = [];
			userChangesAll.forEach(userChangesSet => {
				const { type } = userChangesSet;
				if (type !== 'remove-auto-correction') {
					return;
				}

				userChangeSets.push([
					userChangesSet.onIndexes,
					userChangesSet.offIndexes
				]);
			});

			const [
				onIndexes,
				offIndexes
			] = ReadingsAutoCorrectionUtil.getUniqueIndexesFromUserChanges(
				userChangeSets
			);

			return {
				onIndexes,
				offIndexes
			};
		},

		getFlattenedReadingsChanges(datFile, readingsLedger) {
			if (!readingsLedger) {
				return null;
			}
			const { gpsreadings } = datFile;
			const readings1 = ReadingsAutoCorrectionUtil.getChangesAlignedByDataType(
				gpsreadings,
				'reading1',
				readingsLedger.readings1
			);
			const readings2 = ReadingsAutoCorrectionUtil.getChangesAlignedByDataType(
				gpsreadings,
				'reading2',
				readingsLedger.readings2
			);

			return { readings1, readings2 };
		},

		// This gets all user changes except for "remove-auto-correction" (should be any of interpolate-readings average-readings, manual-value)
		countOtherUserChanges(userChangesAll) {
			const userChangesByType = {};

			userChangesAll.forEach(userChangesSet => {
				const { type } = userChangesSet;
				if (type === 'remove-auto-correction') {
					return;
				}
				if (!userChangesByType[type]) {
					userChangesByType[type] = {
						on: 0,
						off: 0
					};
				}

				userChangesByType[type].on += userChangesSet.onIndexes.length;
				userChangesByType[type].off += userChangesSet.offIndexes.length;
			});

			return userChangesByType;
		},

		countActualUserChanges(readingByTypeLedgerHash, userChangesIndexesRaw) {
			const combinedUserChangesByType = ReadingsAutoCorrectionUtil.SaveAutoCorrectionSendEvent.combineUserChangesToRevertIndexes(
				userChangesIndexesRaw
			);

			const actualCountUserChangesByType = {
				offIndexes: 0,
				onIndexes: 0
			};
			Object.keys(combinedUserChangesByType).forEach(readingType => {
				const combinedUserChanges = combinedUserChangesByType[readingType];
				combinedUserChanges.forEach(userChangesIndex => {
					if (readingByTypeLedgerHash[readingType][userChangesIndex]) {
						actualCountUserChangesByType[readingType] += 1;
					}
				});
			});

			return actualCountUserChangesByType;
		},

		createReadingLedgerHash(readingLedgerIndexesByType) {
			const readingByTypeLedgerHash = {
				onIndexes: {},
				offIndexes: {}
			};
			Object.keys(readingLedgerIndexesByType).forEach(readingType => {
				const indexes = readingLedgerIndexesByType[readingType];
				let readingTypeFormatted;
				switch (readingType) {
					case 'readings1':
						readingTypeFormatted = 'onIndexes';
						break;
					case 'readings2':
						readingTypeFormatted = 'offIndexes';
						break;
					case 'coordinates':
					default:
						return;
				}
				indexes.forEach(index => {
					readingByTypeLedgerHash[readingTypeFormatted][index] = true;
				});
			});

			return readingByTypeLedgerHash;
		},

		getAutoCorrectionCountVersusUserReverts(
			actualCountUserChangesByType,
			readingLedgerIndexesByType
		) {
			const statsByType = {};

			['onIndexes', 'offIndexes'].forEach(readingType => {
				let formattedType;
				switch (readingType) {
					case 'onIndexes':
						formattedType = 'readings1';
						break;
					case 'offIndexes':
						formattedType = 'readings2';
						break;
					default:
						return;
				}
				const autoCorrectionsCount =
					readingLedgerIndexesByType[formattedType].length;

				const userRemovalsCount = actualCountUserChangesByType[readingType];

				statsByType[formattedType] = {
					autoCorrectionsCount,
					userRemovalsCount,
					actualAutoCorrections: autoCorrectionsCount - userRemovalsCount
				};
			});

			return statsByType;
		},

		getAutoCorrectedStats(readingLedgerIndexesByType, userChangesIndexesRaw) {
			if (!readingLedgerIndexesByType || !userChangesIndexesRaw) {
				return null;
			}

			const readingByTypeLedgerHash = ReadingsAutoCorrectionUtil.SaveAutoCorrectionSendEvent.createReadingLedgerHash(
				readingLedgerIndexesByType
			);

			const actualCountUserChangesByType = ReadingsAutoCorrectionUtil.SaveAutoCorrectionSendEvent.countActualUserChanges(
				readingByTypeLedgerHash,
				userChangesIndexesRaw
			);

			const autocorrectedStatsByReadingType = this.getAutoCorrectionCountVersusUserReverts(
				actualCountUserChangesByType,
				readingLedgerIndexesByType
			);

			return autocorrectedStatsByReadingType;
		},

		countChangesForSpikeEditor(
			readingLedgerIndexesByType,
			userChangesIndexesRaw
		) {
			const otherUserChangesCounts = ReadingsAutoCorrectionUtil.SaveAutoCorrectionSendEvent.countOtherUserChanges(
				userChangesIndexesRaw
			);

			const autocorrectedStatsByReadingType = this.getAutoCorrectedStats(
				readingLedgerIndexesByType,
				userChangesIndexesRaw
			);

			const output = {};
			if (autocorrectedStatsByReadingType) {
				output.autocorrectedStatsByReadingType = autocorrectedStatsByReadingType;
			}
			if (otherUserChangesCounts) {
				output.otherUserChangesCounts = otherUserChangesCounts;
			}

			return output;
		},

		getStatsForAnalyticsOnSave(
			readingsLedgersByFile,
			spikeEditorUserChangesByFile,
			datFile
		) {
			const { fileName } = datFile;

			const userChanges = spikeEditorUserChangesByFile[fileName];
			const readingLedger = readingsLedgersByFile[fileName];
			const flattenedReadingsChanges = ReadingsAutoCorrectionUtil.SaveAutoCorrectionSendEvent.getFlattenedReadingsChanges(
				datFile,
				readingLedger
			);

			const response = ReadingsAutoCorrectionUtil.SaveAutoCorrectionSendEvent.countChangesForSpikeEditor(
				flattenedReadingsChanges,
				userChanges
			);

			return response;
		}
	},
	reverse: {
		getLengthOfTraces(traces) {
			// const { traces } = this.props;
			const tracesToFind = [
				traces.newOnTrace,
				traces.newOffTrace,
				traces.newAcOnTrace,
				traces.newAcOffTrace
			];

			const tracePointsThatHaveValues = tracesToFind.map(trace =>
				trace.y.filter(p => p)
			);

			const traceLengths = tracePointsThatHaveValues.map(
				tracePoints => tracePoints.length
			);

			return {
				onLength: traceLengths[0],
				offLength: traceLengths[1],
				acOnLength: traceLengths[2],
				acOffLength: traceLengths[3]
			};
		},
		reversePointIndex(originalIndexValue, indexesCount) {
			const indexOffset = indexesCount - 1;

			return indexOffset - originalIndexValue;
		},
		reverseSelectedPointsIfNecessary(
			rawOnIndexes,
			rawOffIndexes,
			rawAcOnIndexes,
			rawAcOffIndexes,
			selectedDatFile,
			traces
		) {
			const { reverse } = selectedDatFile;
			if (!reverse) {
				return {
					sortedOnIndexes: rawOnIndexes,
					sortedOffIndexes: rawOffIndexes,
					sortedAcOnIndexes: rawAcOnIndexes,
					sortedAcOffIndexes: rawAcOffIndexes
				};
			}

			const {
				onLength,
				offLength,
				acOnLength,
				acOffLength
			} = ReadingsAutoCorrectionUtil.reverse.getLengthOfTraces(traces);

			const sortedOnIndexes = rawOnIndexes.map(index =>
				ReadingsAutoCorrectionUtil.reverse.reversePointIndex(index, onLength)
			);
			const sortedOffIndexes = rawOffIndexes.map(index =>
				ReadingsAutoCorrectionUtil.reverse.reversePointIndex(index, offLength)
			);
			const sortedAcOnIndexes = rawAcOnIndexes.map(index =>
				ReadingsAutoCorrectionUtil.reverse.reversePointIndex(index, acOnLength)
			);
			const sortedAcOffIndexes = rawAcOffIndexes.map(index =>
				ReadingsAutoCorrectionUtil.reverse.reversePointIndex(index, acOffLength)
			);

			return {
				sortedOnIndexes,
				sortedOffIndexes,
				sortedAcOnIndexes,
				sortedAcOffIndexes
			};
		}
	}
};

export default ReadingsAutoCorrectionUtil;
