"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
Object.defineProperty(exports, "MiscUtils", {
  enumerable: true,
  get: function get() {
    return _MiscUtils["default"];
  }
});

var _MiscUtils = _interopRequireDefault(require("./MiscUtils.js"));

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    "default": obj
  };
}