export const createNewApplicationObject = () => ({
	name: '',
	summary: '',
	learnmore: '',
	icon: '',
	path: '',
	order: 0,
	external: false
});

export default { createNewApplicationObject };
