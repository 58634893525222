export const isDefined = v => v !== null && v !== undefined;

export const createInitialChartBelowCriterionsDatasets = () => ({
	// @todo - refactor to seriesArea
	// @background - used for brush chart (range chart)
	areaBcSeriesDs: [],

	bcReadings: [],
	bcReadingsMap: {},
	bcReadingsIndexMap: {},
	bcReadingsUuidMap: {},

	bcReadingsWithGaps: [],
	bcReadingsWithGapsMap: {},
	bcReadingsWithGapsIndexMap: {},
	bcReadingsWithGapsUuidMap: {},

	bcReadingsGeoGaps: [],
	bcReadingsGeoGapsMap: {},
	bcReadingsGeoGapsIndexMap: {},
	bcReadingsGeoGapsUuidMap: {}
});

/**
 * removed props from chartsBcSeriesDs
 * - calculatedByDepol - should be tracked via chartsSettings useShadow, useThreshold
 * - threshold - should be tracked in chartsSettings threshold
 * - remediationGroups - should be tracked in chartsRemediations remediationGroupRanges
 * */

export default { isDefined, createInitialChartBelowCriterionsDatasets };

/**
 * Reference Original redux.chartsBcSeriesDs props
 *
 * bcSeriesDs -
 * bcWithGaps
 * areaBcSeriesDs
 * mapBcSeriesDs
 * rawBcSeriesDs
 * bcReadingById
 * remediationGroups - should be managed by ChartsRemediations slice
 * bcDataSetIdentifier
 * threshold - is managed by ChartsSettings slice
 * calculatedByDepol
 * computedBcMap - this may need to be added above
 * customExceptionsMap - this may need to be added to a diff slice
 */

/*******
 * reference expectiations
 */

// export const createInitialChartBelowCriterionsDatasets = () => ({
// 	// @todo - refactor to seriesArea
// 	// @background - used for brush chart (range chart)
// 	areaBcSeriesDs: [],

// 	// @todo - refactor to datasetIdentifiers
// 	// @background - str list of ids, used by ChartGroupComponent to determine if below criterions data set changed // may no longer be needed
// 	bcDataSetIdentifier: '',

// 	// @todo - refactor to readingById
// 	// @background - simply map mapping to reading objects using station id as key. ?would be better to use UUID?
// 	bcReadingById: {},

// 	// @todo - refactor to series
// 	// @background - does not appear to be in use at the moment
// 	bcSeriesDs: [],

// 	// @todo - refactor to readingsWithGaps
// 	// @background - raw dataset with gaps injected where needed
// 	bcWithGaps: [],

// 	// @todo - refactor to readingsWithGeoGaps
// 	// @background - array of arrays - to be used in geo map
// 	mapBcSeriesDs: [],

// 	// @todo - refactor to rawPrimaryDatasetReadings
// 	// @background - copy of original primary readings datasource - ex ON_OFF - probably should get rid of this - maybe track survey id instead
// 	rawBcSeriesDs: [],

// 	calculatedByDepol: false
// });
