/* eslint-disable import/no-cycle */
import { fetch } from 'whatwg-fetch';

import { ajaxNew as ajax } from '../../../scanline/utils/ajax';

function cleanPath(customer) {
	return encodeURIComponent(customer.replace(/\s/, '_'));
}

const getKMZsRequest = () => {
	return ajax({
		aip: 'kmz',
		url: `/files`,
		method: 'GET'
	})
		.then(res => {
			return res;
		})
		.catch(err => {
			throw err;
		});
};

const getUploadURLRequest = (customer, fileName) => {
	return ajax({
		aip: 'kmz',
		url: `/upload/${cleanPath(customer)}`,
		method: 'POST',
		data: JSON.stringify({ fileName: cleanPath(fileName) })
	})
		.then(res => {
			return res.message;
		})
		.catch(err => {
			// eslint-disable-next-line no-console
			console.log(err);
			throw err;
		});
};

const putKMZFileRequest = (url, body) => {
	const myHeaders = {
		'Content-Type': 'binary/octet-stream'
	};
	return fetch(url, {
		method: 'PUT',
		headers: myHeaders,
		body
	});
};

const postKMZUrlRequest = key => {
	return ajax({
		aip: 'kmz',
		url: `/file`,
		method: 'POST',
		data: JSON.stringify({ key })
	})
		.then(res => {
			return res.message;
		})
		.catch(err => {
			// eslint-disable-next-line no-console
			console.log(err);
			throw err;
		});
};

export {
	getKMZsRequest,
	getUploadURLRequest,
	putKMZFileRequest,
	postKMZUrlRequest
};
