"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

var _ = require("..");

var _BadLocationError = _interopRequireDefault(require("../ErrorClasses/BadLocationError"));

var _UnknownGpsReadingPropError = _interopRequireDefault(require("../ErrorClasses/UnknownGpsReadingPropError"));

var _excluded = ["old_reading1", "old_reading2"];

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    "default": obj
  };
}

function _objectWithoutProperties(source, excluded) {
  if (source == null) return {};

  var target = _objectWithoutPropertiesLoose(source, excluded);

  var key, i;

  if (Object.getOwnPropertySymbols) {
    var sourceSymbolKeys = Object.getOwnPropertySymbols(source);

    for (i = 0; i < sourceSymbolKeys.length; i++) {
      key = sourceSymbolKeys[i];
      if (excluded.indexOf(key) >= 0) continue;
      if (!Object.prototype.propertyIsEnumerable.call(source, key)) continue;
      target[key] = source[key];
    }
  }

  return target;
}

function _objectWithoutPropertiesLoose(source, excluded) {
  if (source == null) return {};
  var target = {};
  var sourceKeys = Object.keys(source);
  var key, i;

  for (i = 0; i < sourceKeys.length; i++) {
    key = sourceKeys[i];
    if (excluded.indexOf(key) >= 0) continue;
    target[key] = source[key];
  }

  return target;
}

var isProd = _.MiscUtils.isProd(); // Mutates in place


var cleanEmptyValues = function cleanEmptyValues(obj) {
  Object.keys(obj).forEach(function (key) {
    var value = obj[key];

    if ([null, undefined].includes(value)) {
      delete obj[key];
    }
  });
};

var getWarningErrorProps = function getWarningErrorProps(propsObject) {
  var old_reading1 = propsObject.old_reading1,
      old_reading2 = propsObject.old_reading2,
      errorProps = _objectWithoutProperties(propsObject, _excluded);

  var warningProps = {
    old_reading1: old_reading1,
    old_reading2: old_reading2
  };
  cleanEmptyValues(warningProps);
  return {
    warningProps: warningProps,
    errorProps: errorProps
  };
};

var propsWarned = {};

var getPropsNotWarnedYet = function getPropsNotWarnedYet(props) {
  return props.filter(function (prop) {
    return !propsWarned[prop];
  });
};

var tryRaiseBadPropsErrorOrWarning = function tryRaiseBadPropsErrorOrWarning(props, title) {
  var error = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : true;

  if (props.length === 0) {
    return;
  }

  var message = "Unkown propert(y|ies) for ".concat(title, ": \"").concat(props.join('", "'), "\"");

  if (error) {
    throw new _UnknownGpsReadingPropError["default"](message);
  } // eslint-disable-next-line no-console


  console.warn(message);
};

var tryWarnBadProps = function tryWarnBadProps(keys, title) {
  var props = Object.keys(keys);

  if (props.length === 0) {
    return;
  }

  var propsToWarn = getPropsNotWarnedYet(props);

  if (propsToWarn.length === 0) {
    return;
  }

  propsToWarn.forEach(function (prop) {
    propsWarned[prop] = true;
  });
  tryRaiseBadPropsErrorOrWarning(propsToWarn, title, false);
};

var tryThrowBadPropsError = function tryThrowBadPropsError(keys, title) {
  var props = Object.keys(keys);
  tryRaiseBadPropsErrorOrWarning(props, title, true);
};

var ReadingsUtilMisc = {
  UnknownGpsReadingPropError: _UnknownGpsReadingPropError["default"],
  BadLocationError: _BadLocationError["default"],
  validateEmptyProps: function validateEmptyProps(title, propsObject) {
    if (isProd) {
      return;
    }

    var keys = Object.keys(propsObject);

    if (keys.length === 0) {
      return;
    }

    var _getWarningErrorProps = getWarningErrorProps(propsObject),
        warningProps = _getWarningErrorProps.warningProps,
        errorProps = _getWarningErrorProps.errorProps;

    tryThrowBadPropsError(errorProps, title); // Do warnings first as errors will stop program

    tryWarnBadProps(warningProps, title);
  },
  cleanEmptyValues: cleanEmptyValues
};
var _default = ReadingsUtilMisc;
exports["default"] = _default;