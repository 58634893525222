/* eslint-disable import/prefer-default-export */
/* eslint-disable import/no-cycle */
import { createActions } from 'redux-actions';

const {
	setDataXYMinMax,
	bulkSetDataXYMinMax,
	setZoomXYMinMax,
	setInputXYMinMax,
	clearInputXYMinMax,
	setStoreZoomXYMinMaxSlice
} = createActions(
	{
		SET_DATA_X_Y_MIN_MAX: (key, { xMin, xMax, yMin, yMax }) => ({
			key,
			xMin,
			xMax,
			yMin,
			yMax
		}),
		BULK_SET_DATA_X_Y_MIN_MAX: NewStoreKeyValues => ({ NewStoreKeyValues }),
		SET_ZOOM_X_Y_MIN_MAX: (key, { xMin, xMax, yMin, yMax }) => ({
			key,
			xMin,
			xMax,
			yMin,
			yMax
		}),
		SET_INPUT_X_Y_MIN_MAX: (key, { xMin, xMax, yMin, yMax }) => ({
			key,
			xMin,
			xMax,
			yMin,
			yMax
		}),
		CLEAR_INPUT_X_Y_MIN_MAX: key => ({ key }),
		SET_STORE_ZOOM_X_Y_MIN_MAX_SLICE: newZoomStore => ({ zoom: newZoomStore })
	},
	{ prefix: 'StoreXYMinMax' }
);

export {
	setDataXYMinMax,
	bulkSetDataXYMinMax,
	setZoomXYMinMax,
	setInputXYMinMax,
	clearInputXYMinMax,
	setStoreZoomXYMinMaxSlice
};

export default {
	setDataXYMinMax,
	bulkSetDataXYMinMax,
	setZoomXYMinMax,
	setInputXYMinMax,
	clearInputXYMinMax,
	setStoreZoomXYMinMaxSlice
};
