import { NAME_SPACE } from './constants';
import * as core from '../scanline/redux/Line/utils/localRefs';

export const selectLocalRef = (key, state) =>
	core.selectLocalRef(key, state, NAME_SPACE);

export const setLocalRefs = (newValues, localRefs) =>
	core.setLocalRefs(newValues, localRefs, NAME_SPACE);

export const destroyLocalRefs = localRefs =>
	core.destroyLocalRefs(localRefs, NAME_SPACE);
