/* eslint-disable no-use-before-define */
import { createActions } from 'redux-actions';
import scanlineUtils from '../scanlineUtils';

const {
	clearMapLines,
	receiveMapLines,
	requestMapLines,
	fetchMapLines
} = createActions({
	CLEAR_MAP_LINES: () => ({}),
	RECEIVE_MAP_LINES: (surveyId, results) => {
		return { surveyId, mapLines: results.lines };
	},
	REQUEST_MAP_LINES: () => ({}),
	FETCH_MAP_LINES: (jobId, linename) => doFetchMapLines(jobId, linename)
});

const doFetchMapLines = (
	surveyGuid,
	surveyId,
	linename,
	callback
) => dispatch => {
	dispatch(requestMapLines());

	return scanlineUtils.getMapLines(surveyGuid, linename, 0, (err, res) => {
		const { alert = () => {} } = window || {};
		if (err) {
			alert(err);
			// console.error(err);
		} else {
			dispatch(receiveMapLines(surveyId, res));
		}
		callback();
	});
};

export {
	clearMapLines,
	receiveMapLines,
	requestMapLines,
	fetchMapLines,
	doFetchMapLines
};
