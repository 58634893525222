/* eslint-disable import/no-cycle */
/* eslint-disable camelcase */
import { requestPageLoadState, receivePageLoadState } from './actions';

// ************
// PARTS
// ************
const reducerParts = {
	[requestPageLoadState]: state => {
		return {
			...state,
			isPageLoadStateLoaded: false,
			isPageLoadStateLoading: true
		};
	},
	[receivePageLoadState]: (state, { payload: { pageLoadState } }) => {
		return {
			...state,
			pageLoadState,
			isPageLoadStateLoaded: true,
			isPageLoadStateLoading: false
		};
	}
};

export default reducerParts;
