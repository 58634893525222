import ajax from '../../../../scanline/utils/ajax';
import { APPROVAL_MODE } from '../../../constants';

export const getAssetDetail = (
	name,
	template,
	approval = APPROVAL_MODE.approved
) => {
	return ajax(
		`/asset/detail/${template}/${encodeURIComponent(name)}/${approval}`,
		undefined,
		'GET',
		undefined,
		false,
		'assetview'
	).then(res => {
		const { display = [], ...rest } = res;
		return { ...rest, display };
	});
};

export const extra = '';
