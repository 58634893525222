export const selectSliceUploader = state => {
	const { uploader } = state;
	return uploader;
};

export const selectBucket = stateUploader => {
	const { bucket } = stateUploader;
	return bucket;
};

export const selectIsShowing = stateUploader => {
	const { isShowing } = stateUploader;
	return isShowing;
};

export const selectFiles = stateUploader => {
	const { files } = stateUploader;
	return files;
};

export const selectFile = (stateUploader, fileType, filename) => {
	const files = selectFiles(stateUploader);
	const list = files[fileType] || [];
	return list.find(f => f.name === filename);
};

export const selectDefaultChecked = stateUploader => {
	const { defaultChecked } = stateUploader;
	return defaultChecked;
};
