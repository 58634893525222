/* eslint-disable import/prefer-default-export */
import { ROOT_SLICE } from './constants';

export const selectRootSlice = stateAdmin => stateAdmin[ROOT_SLICE];

export const selectPermissionDefinitions = stateAdmin => {
	const slice = selectRootSlice(stateAdmin);
	const { permissionDefinitions } = slice;
	return permissionDefinitions;
};

export const selectMapPermissionsByGroupName = stateAdmin => {
	const slice = selectRootSlice(stateAdmin);
	const { mapPermissionsByGroupName } = slice;
	return mapPermissionsByGroupName;
};

export const selectPermissionByGroupName = (stateAdmin, groupName) => {
	const map = selectMapPermissionsByGroupName(stateAdmin);
	return map[groupName];
};

export const selectListPermissionDefinitions = stateAdmin => {
	const slice = selectRootSlice(stateAdmin);
	const { listPermissionDefinitions } = slice;
	return listPermissionDefinitions;
};

export const selectMapPermissionsListByAppKey = stateAdmin => {
	const slice = selectRootSlice(stateAdmin);
	const { mapPermissionsListByAppKey } = slice;
	return mapPermissionsListByAppKey;
};

export const selectListPermissions = (stateAdmin, appKey) => {
	return selectMapPermissionsListByAppKey(stateAdmin)[appKey];
};

export const selectIsLoadingPermissionDefinitions = stateAdmin => {
	const slice = selectRootSlice(stateAdmin);
	const { isLoadingPermissionDefinitions } = slice;
	return isLoadingPermissionDefinitions;
};

export const selectIsLoadedPermissionDefinitions = stateAdmin => {
	const slice = selectRootSlice(stateAdmin);
	const { isLoadedPermissionDefinitions } = slice;
	return isLoadedPermissionDefinitions;
};

export const selectHasLoadingErrorPermissionDefinitions = stateAdmin => {
	const slice = selectRootSlice(stateAdmin);
	const { hasLoadingErrorPermissionDefinitions } = slice;
	return hasLoadingErrorPermissionDefinitions;
};

export const selectListEnabledPermissionDefinitionsByGroupName = (
	stateAdmin,
	mapEnabledGroupNames
) => {
	const resultList = [];
	const enabledAppKeys = {};
	const isEnabledGroupNamesEmpty =
		Object.keys(mapEnabledGroupNames).length === 0;

	if (isEnabledGroupNamesEmpty) {
		return resultList;
	}

	// identify enabled apps
	const appList = selectListPermissionDefinitions(stateAdmin);
	appList.forEach(app => {
		const { key } = app;
		const plist = selectListPermissions(stateAdmin, key);
		if (plist.some(p => mapEnabledGroupNames[p.awsGroupName])) {
			enabledAppKeys[key] = true;
		}
	});

	// build unique permission defintions consisting only of enabled apps and permissions.
	appList.forEach(app => {
		if (enabledAppKeys[app.key]) {
			const clone = { ...app };
			const pList = selectListPermissions(stateAdmin, clone.key);
			clone.permissions = pList.filter(
				({ awsGroupName }) => mapEnabledGroupNames[awsGroupName]
			);
			resultList.push(clone);
		}
	});

	return resultList;
};
