import {
	sortSuveysByDateASC,
	sortSuveysByDateDESC,
	sortSurveysByType,
	sortGroupSurveysByJobId,
	isDerivedSurvey,
	isCisvSurvey,
	isSupportedExternalSurvey,
	isUsnupportedExternalSurvey
} from './base';

const EMPTY_ARRAY = [];

// const sortSurveyPrioritizeONOFFAndON = (surveys = []) => {
//   const prioritySurveyTypes = `${SURVEY_ON_OFF},${SURVEY_ON}`
//       .split(',')
//       .map(v => v.trim());

//   const firstSurvey = surveys.find(s => {
//       const type = typeof s === 'string' ? s : s.survey_subtype;
//       if (prioritySurveyTypes.indexOf(type) > -1) {
//           return s;
//       }
//       return false;
//   });

//   if (!firstSurvey) {
//       return surveys;
//   }

//   const firstSurveys = [firstSurvey];
//   const remainingSurveys = [];

//   surveys.forEach(s => {
//       if (s.id !== firstSurvey.id) {
//           if (s.jobId === firstSurvey.jobId) {
//               firstSurveys.push(s);
//           } else {
//               remainingSurveys.push(s);
//           }
//       }
//   });
//   return [...firstSurveys, ...remainingSurveys];
// };

const splitSurveys = (surveys = []) => {
	const cisvSurveys = [];
	const supportedExternalSurveys = [];
	const usnupportedExternalSurveys = [];
	const derivedSurveys = [];

	surveys.forEach(s => {
		switch (true) {
			case isCisvSurvey(s):
				cisvSurveys.push(s);
				break;
			case isSupportedExternalSurvey(s):
				supportedExternalSurveys.push(s);
				break;
			case isUsnupportedExternalSurvey(s):
				usnupportedExternalSurveys.push(s);
				break;
			case isDerivedSurvey(s):
				derivedSurveys.push(s);
				break;
			default:
			// console.log('Failed to identify survey source', s);
		}
	});
	derivedSurveys.forEach(s => {
		switch (true) {
			case cisvSurveys.findIndex(_s => _s.jobId === s.jobId) > -1:
				cisvSurveys.push(s);
				break;
			case supportedExternalSurveys.findIndex(_s => _s.jobId === s.jobId) > -1:
				supportedExternalSurveys.push(s);
				break;
			case usnupportedExternalSurveys.findIndex(_s => _s.jobId === s.jobId) >
				-1:
				usnupportedExternalSurveys.push(s);
				break;
			default:
			// console.log('Failed to identify parent survey to derived survey', s);
		}
	});
	return [cisvSurveys, supportedExternalSurveys, usnupportedExternalSurveys];
};

const sortCisvSurveys = surveys => {
	const surveysByDateDesc = sortSuveysByDateDESC(surveys || EMPTY_ARRAY);
	const surveysByType = sortSurveysByType(surveysByDateDesc);
	const surveysByJob = sortGroupSurveysByJobId(surveysByType);

	return surveysByJob;
};

const sortSupportedExternalSurveys = surveys => {
	const surveysByDateAsc = sortSuveysByDateASC(surveys || EMPTY_ARRAY);
	const surveysByType = sortSurveysByType(surveysByDateAsc);
	const surveysByJob = sortGroupSurveysByJobId(surveysByType);

	return surveysByJob;
};

const sortUnsupportedExternalSurveys = surveys => {
	const surveysByDateAsc = sortSuveysByDateASC(surveys || EMPTY_ARRAY);
	const surveysByType = sortSurveysByType(surveysByDateAsc);
	const surveysByJob = sortGroupSurveysByJobId(surveysByType);

	return surveysByJob;
};

const universalSortSurveys = (surveys = []) => {
	const [
		cisvSurveys,
		supportedExternalSurveys,
		usnupportedExternalSurveys
	] = splitSurveys(surveys);

	return [
		...sortCisvSurveys(cisvSurveys),
		...sortSupportedExternalSurveys(supportedExternalSurveys),
		...sortUnsupportedExternalSurveys(usnupportedExternalSurveys)
	];
};

const TESTS = {
	sortCisvSurveys,
	sortSupportedExternalSurveys,
	sortUnsupportedExternalSurveys,
	universalSortSurveys
};

export {
	sortCisvSurveys,
	sortSupportedExternalSurveys,
	sortUnsupportedExternalSurveys,
	universalSortSurveys,
	TESTS
};

export default {
	sortCisvSurveys,
	sortSupportedExternalSurveys,
	sortUnsupportedExternalSurveys,
	universalSortSurveys,
	TESTS
};
