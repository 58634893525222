/* eslint-disable import/no-cycle */
import { handleActions } from 'redux-actions';

// MAIN
import mainReducerParts from './Main/reducerParts';
import { initialState as mainInitialState } from './Main/initialState';

const initialState = {
	...mainInitialState
};

const reducerParts = {
	...mainReducerParts
};

export default handleActions(reducerParts, initialState);
