import { capitalize } from '../util';

export const buildReducerParts = (
  {
    setIsLoadingFilters,
    setIsLoadedFilters,
    setLoadFiltersErrorMessage
  },
  filtersStatePropKey
) => {
  const propKey = filtersStatePropKey;
  const capPropKey = capitalize(propKey);

  const KEY_isLoadingFilters = `isLoading${capPropKey}`;
  const KEY_isLoadedFilters = `isLoaded${capPropKey}`;
  const KEY_loadFiltersErrorMessage = `load${capPropKey}ErrorMessage`;

  return {    
    [setIsLoadingFilters]: (state, { payload: { isLoading } }) => { 
      return {
        ...state,
        [KEY_isLoadingFilters]: !!isLoading
      }
    },
    
    [setIsLoadedFilters]: (state, { payload: { isLoaded } }) => { 
      return {
        ...state,
        [KEY_isLoadedFilters]: !!isLoaded
      }
    },

    [setLoadFiltersErrorMessage]: (state, { payload: { message } }) => { 
      return {
        ...state,
        [KEY_loadFiltersErrorMessage]: message
      }
    }
  }
};

export default {
  buildReducerParts
};
