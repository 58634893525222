import { LatLngBounds, LatLng } from 'leaflet';

export const getDatFile = (data, fileName) => {
	const [dat = {}] = data.filter(({ fileName: fn }) => fn === fileName);
	return dat;
};

const getDatFilesAlreadySmoothed = (
	data,
	boundsByFile,
	currentSmoothPoints
) => {
	if (!currentSmoothPoints || currentSmoothPoints.length === 0) {
		return { data, boundsByFile };
	}

	// If there is already a single point smoothed, just take that datFile
	const { point } = currentSmoothPoints[0];
	const { dat: { fileName } = {} } = point;

	const datFile = getDatFile(data, fileName);
	const datIndx = data.indexOf(datFile);
	return { data: [datFile], boundsByFile: [boundsByFile[datIndx]] };
};

export const getDatFilesAlreadySmoothedByState = state => {
	const { cisview } = state;
	const {
		job,
		editorToolbar: { smoothTool },
		readings: { boundsByFile }
	} = cisview;
	const { currentSmoothPoints } = smoothTool;
	const { data } = job;

	return getDatFilesAlreadySmoothed(data, boundsByFile, currentSmoothPoints);
};

export const getBoundingBox = bounds => {
	if (bounds === undefined) {
		return undefined;
	}
	const { minx, miny, maxx, maxy } = bounds;

	const corner1 = new LatLng(miny, minx);
	const corner2 = new LatLng(maxy, maxx);
	return new LatLngBounds(corner1, corner2);
};
const getBounds = (dat, boundsByFile, index) => {
	if (dat.bounds) {
		return dat.bounds;
	}
	return getBoundingBox(boundsByFile[index]);
};

export const getDatsinExtent = (dats, mapBounds, boundsByFile) => {
	return dats.filter((dat, index) => {
		const boundingBox = getBounds(dat, boundsByFile, index);
		return dat.visible && boundingBox.overlaps(mapBounds);
	});
};
