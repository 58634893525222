export const initialState = {
	permissionDefinitions: {},
	mapPermissionsByGroupName: {},
	listPermissionDefinitions: [],
	mapPermissionsListByAppKey: {},

	isLoadingPermissionDefinitions: false,
	isLoadedPermissionDefinitions: false,
	hasLoadingErrorPermissionDefinitions: false
};

export default initialState;
