import { calculateDistance } from 'aegion_common_utilities/lib/MapProcessingUtil';

export const computeSpatialDistance = (reading1, reading2, options = {}) => {
	return calculateDistance(reading1.loc, reading2.loc, {
		roundTo: 2,
		...options
	});
};

export const isIdNumeric = reading => {
	if (!reading) {
		return false;
	}

	return typeof reading.id === 'number';
};

const computeReadingDistanceByReadingIds = (reading1, reading2) => {
	if (![reading1, reading2].every(isIdNumeric)) {
		return null;
	}

	return reading2.id - reading1.id;
};

export const computeReadingDistance = (
	reading1,
	reading2,
	count,
	options = {}
) => {
	const { useSpatialDistance, algorithm } = options;
	if (useSpatialDistance) {
		return computeSpatialDistance(reading1, reading2, { algorithm });
	}

	const distanceById = computeReadingDistanceByReadingIds(reading1, reading2);
	if (distanceById !== null) {
		return distanceById;
	}

	// calc by fall back
	return +(count * 2.5).toFixed(2);
};

export default computeReadingDistance;
