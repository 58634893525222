import { isDate } from 'aegion_common_utilities/lib/DateUtil';

export const getLattitude = (view, index) => view.getFloat64(index);

export const getLongitude = (view, index) => view.getFloat64(index + 8);

export const getReadingValue1 = (view, index) => view.getInt32(index + 16);

export const getReadingValue2 = (view, index) => view.getInt32(index + 20);

export const getSurveyType = (view, index) => view.getInt8(index + 24);

export const getDateCreated = (view, index) => {
	const epoch = view.getInt32(index + 25) * 1000;
	if (isDate(epoch) && epoch > 0) {
		return new Date(epoch).toISOString();
	}
	return undefined;
};

export const getId = (view, index) => {
	// TODO: This is not working with the current data
	return view.getInt32(index + 29) / 100;
};

export const PARSERS = {
	getLattitude,
	getLongitude,
	getReadingValue1,
	getReadingValue2,
	getSurveyType,
	getDateCreated,
	getId
};
