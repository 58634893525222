import { SLICE_ROOT_KEY } from './constants';
import {
	setFileMeta,
	setFileContent,
	setFileDocJson,
	setFileErrors,
	clearFile,
	clearAllFiles
} from './actions';
import {
	selectSliceState,
	selectAllFileMeta,
	selectAllFileContent,
	selectAllFileDocJson,
	selectAllFileErrors
} from './selectors';
import { isValidKey } from './util';

const reducerParts = {
	[setFileMeta]: (state, { payload: { key, meta } = {} }) => {
		if (!isValidKey(key)) {
			return state;
		}

		const slice = selectSliceState(state);
		const fileMeta = selectAllFileMeta(state);

		return {
			...state,
			[SLICE_ROOT_KEY]: {
				...slice,
				fileMeta: {
					...fileMeta,
					[key]: meta
				}
			}
		};
	},

	[setFileContent]: (state, { payload: { key, content } = {} }) => {
		if (!isValidKey(key)) {
			return state;
		}

		const slice = selectSliceState(state);
		const fileContent = selectAllFileContent(state);

		return {
			...state,
			[SLICE_ROOT_KEY]: {
				...slice,
				fileContent: {
					...fileContent,
					[key]: content
				}
			}
		};
	},

	[setFileDocJson]: (state, { payload: { key, docJson } = {} }) => {
		if (!isValidKey(key)) {
			return state;
		}

		const slice = selectSliceState(state);
		const fileDocJson = selectAllFileDocJson(state);

		return {
			...state,
			[SLICE_ROOT_KEY]: {
				...slice,
				docJson: {
					...fileDocJson,
					[key]: docJson
				}
			}
		};
	},

	[setFileErrors]: (state, { payload: { key, errors } = {} }) => {
		if (!isValidKey(key)) {
			return state;
		}

		const slice = selectSliceState(state);
		const fileErrors = selectAllFileErrors(state);

		return {
			...state,
			[SLICE_ROOT_KEY]: {
				...slice,
				fileErrors: { ...fileErrors, [key]: errors }
			}
		};
	},

	[clearFile]: (state, { payload: { key } = {} }) => {
		if (!isValidKey(key)) {
			return state;
		}

		const slice = selectSliceState(state);
		const fileMeta = selectAllFileMeta(state);
		const fileContent = selectAllFileContent(state);
		const fileDocJson = selectAllFileDocJson(state);
		const fileErrors = selectAllFileErrors(state);

		return {
			...state,
			[SLICE_ROOT_KEY]: {
				...slice,
				fileMeta: { ...fileMeta, [key]: undefined },
				fileContent: { ...fileContent, [key]: undefined },
				fileDocJson: { ...fileDocJson, [key]: undefined },
				fileErrors: { ...fileErrors, [key]: undefined }
			}
		};
	},

	[clearAllFiles]: state => {
		const slice = selectSliceState(state);
		return {
			...state,
			[SLICE_ROOT_KEY]: {
				...slice,
				fileMeta: {
					deleted: undefined,
					modified: undefined,
					added: undefined
				},
				fileContent: {
					deleted: undefined,
					modified: undefined,
					added: undefined
				},
				fileDocJson: {
					deleted: undefined,
					modified: undefined,
					added: undefined
				},
				fileErrors: {
					deleted: undefined,
					modified: undefined,
					added: undefined
				}
			}
		};
	}
};

export default reducerParts;
