/* import {
} from './actionTypes'; */

import { SET_IMAGES } from './actionTypes';

const initialState = {
	images: []
};

const reducer = (state = initialState, action) => {
	const { type, payload } = action;
	switch (type) {
		case SET_IMAGES:
			return {
				...state,
				images: payload.photos,
				id: payload.id,
				historicalId: payload.historicalId
			};
		default:
			return state;
	}
};

export default reducer;
