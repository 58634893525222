import { buildSelectors } from './buildSelectors';
// eslint-disable-next-line import/no-cycle
import { buildActions } from './buildActions';
import { buildReducerParts } from './buildReducerParts';
import { initialState } from './initialState';

const buildRedux = ({
	namespace,
	rootSliceKey,
	mainRedux,
	filtersStatePropKey,
	filterStatePropKey = 'customers'
}) => {
	const selectors = buildSelectors(mainRedux, filterStatePropKey);
	const actions = buildActions(
		namespace,
		rootSliceKey,
		mainRedux,
		selectors,
		filtersStatePropKey,
		filterStatePropKey
	);
	const reducerParts = buildReducerParts(
		actions,
		filtersStatePropKey,
		filterStatePropKey
	);

	return {
		selectors,
		actions,
		reducerParts,
		initialState
	};
};

export default buildRedux;
