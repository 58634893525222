/* eslint-disable array-callback-return */
// import {
// 	generateLinkCisvJobLine,
// 	generateLinkFlJobLine,
// 	generateLinkScanline,
// 	generateLinkCisvSurveyLink
// } from '../../../../../commons/util/links';

export const transformJobLines = (jobLines = []) => {
	console.log(JSON.stringify(jobLines));
	return jobLines;

	// return jobLines.map(jl => {
	// 	const jobLine = { ...jl };
	// 	const { job = {}, line = {}, surveys = [] } = jobLine;
	// 	jobLine.links = {
	// 		cisv: generateLinkCisvJobLine(job.jobNumber, line.name),
	// 		fl: generateLinkFlJobLine(job.jobNumber, line.name),
	// 		scan: generateLinkScanline(line.scanId, line.name),
	// 	};
	// 	jobLine.surveys = surveys.map(s => {
	// 		const survey = { ...s };
	// 		survey.links = {
	// 			cisv: generateLinkCisvSurveyLink(job.jobNumber, line.name, survey.type),
	// 		}
	// 	});
	// });
};

export default {
	transformJobLines
};
