/* eslint-disable import/no-cycle */
/* eslint-disable no-use-before-define */
import { createActions } from 'redux-actions';
import scanlineUtils from '../scanlineUtils';

const {
	// eslint-disable-next-line no-unused-vars
	loadAppState,
	requestAppState,
	receiveAppState,
	setActionReportThreshold,
	setActionReportUseShadow,
	addActionReportCustomGroup,
	removeActionReportCustomGroup,
	clearActionReportCustomGroup,
	setActionReportVersion,
	setActionReportComparator
} = createActions({
	LOAD_APP_STATE: (jobId, lineId, projectid) =>
		thunkLoadAppState(jobId, lineId, projectid),
	REQUEST_APP_STATE: () => ({}),
	RECEIVE_APP_STATE: appState => ({ appState }),
	SET_ACTION_REPORT_THRESHOLD: threshold => ({ threshold }),
	SET_ACTION_REPORT_USE_SHADOW: useShadow => ({ useShadow }),
	ADD_ACTION_REPORT_CUSTOM_GROUP: group => ({ group }),
	REMOVE_ACTION_REPORT_CUSTOM_GROUP: group => ({ group }),
	CLEAR_ACTION_REPORT_CUSTOM_GROUP: () => ({}),
	SET_ACTION_REPORT_VERSION: version => ({ version }),
	SET_ACTION_REPORT_COMPARATOR: comparator => ({ comparator })
});

const thunkLoadAppState = (jobId, lineId, projectid, callback = () => {}) => {
	return dispatch => {
		dispatch(requestAppState());

		return scanlineUtils.getReportDataV2(
			jobId,
			lineId,
			projectid,
			'ACTION_PLAN',
			(err, res) => {
				if (err) {
					callback(err);
				} else {
					dispatch(receiveAppState(res.content));
					callback(null, res);
				}
			}
		);
	};
};

export {
	thunkLoadAppState as loadAppState,
	receiveAppState,
	requestAppState,
	setActionReportThreshold,
	setActionReportUseShadow,
	addActionReportCustomGroup,
	removeActionReportCustomGroup,
	clearActionReportCustomGroup,
	setActionReportVersion,
	setActionReportComparator
};
