export const isNum = val => !Number.isNaN(parseFloat(val, 10));

export const formatNumber = (val, { scale = 2 } = {}) => {
	if (isNum(val)) {
		return Number.parseFloat(Number.parseFloat(val, 10).toFixed(scale), 10);
	}
	return val;
};

const formatStrNumber = (val, fnNumberFormatter) => {
	const strVal = `${val || ''}`.trim();
	const strParts = strVal
		.split(' ')
		.filter(v => !!v)
		.map(v => fnNumberFormatter(v));
	return strParts.join(' ');
};

export const formatGeoNum = val => {
	return formatNumber(val, { scale: 6 });
};

export const formatMillivolt = val => {
	return formatNumber(val, { scale: 2 });
};

export const formatStrMillivolt = val => {
	return formatStrNumber(val, formatMillivolt);
};

export const formatMiles = val => {
	return formatNumber(val, { scale: 4 });
};

export const formatStrMiles = val => {
	return formatStrNumber(val, formatMiles);
};

export default {
	isNum,
	formatNumber,
	formatGeoNum,
	formatMillivolt,
	formatStrMillivolt,
	formatMiles,
	formatStrMiles
};
