/* eslint-disable import/no-cycle */

import L from 'leaflet';

import {
	getPreviousGpsReading,
	getNextGpsReading
} from 'aegion_common_utilities/lib/MapProcessingUtil';

import { getDatFile } from '../../../utils/DatFiles';
import { getCommentsWithCoordinate } from './comments';

// Returns an object that is keyed from the reading index.
// The keyed object will be empty or contain coordinates based
// on if they point has been edited
const getGpsReadingIndexesForEditLine = (datPnts, dat) => {
	return datPnts.reduce((acc, { gpsIndx, editLatLng }) => {
		// First get previous gpsreadings
		if (gpsIndx > 0) {
			const { i } = getPreviousGpsReading(dat.gpsreadings, gpsIndx);
			if (!acc[i]) {
				acc[i] = {};
			}
		}
		// Then set this gpsreading
		acc[gpsIndx] = { editLatLng };
		// Finally get next gpsreadings
		if (gpsIndx < dat.gpsreadings.length - 1) {
			const { i } = getNextGpsReading(dat.gpsreadings, gpsIndx + 1);
			acc[i] = {};
		}
		return acc;
	}, {});
};

// if it's the first key or current and last are missing editLatLng
// add new segment to line string
// this is done so one line can have many different non-connected segements
const _shouldCreateNewSegment = (gpsIndexes, gpsIndx, indx) => {
	const isFirstItem = indx === 0;

	if (isFirstItem) {
		return true;
	}

	const currentEditLatLngMissing = gpsIndexes[gpsIndx].editLatLng === undefined;

	const previousIndexKey = Object.keys(gpsIndexes)[indx - 1];
	const lastEditLatLngMissing =
		gpsIndexes[previousIndexKey].editLatLng === undefined;

	if (currentEditLatLngMissing && lastEditLatLngMissing) {
		return true;
	}

	return false;
};

// These are the coordinates that will connect the points from lines drawn by using the move tool
const getLineCoordinates = (gpsIndexes, dat) => {
	return Object.keys(gpsIndexes).reduce((acc, gpsIndx, indx) => {
		if (_shouldCreateNewSegment(gpsIndexes, gpsIndx, indx)) {
			acc.push([]);
		}
		const gpsr = dat.gpsreadings[gpsIndx];
		const { editLatLng } = gpsIndexes[gpsIndx];
		// if edited point use new coordinate
		if (editLatLng) {
			const { lat, lng } = editLatLng;
			acc[acc.length - 1].push([lat, lng]);
		} else {
			// use existing coordinates
			const { coordinates } = gpsr;
			acc[acc.length - 1].push([coordinates[0], coordinates[1]]);
		}

		return acc;
	}, []);
};

function reduceComments(acc, gpsIndx, indx, segment, gpsIndexes, dat) {
	// console.log(acc, gpsIndx, indx, segment, gpsIndexes, dat);
	const { editLatLng } = gpsIndexes[gpsIndx];
	if (editLatLng) {
		const reading = dat.gpsreadings[gpsIndx];
		if (reading.coordinates && reading.comments) {
			acc.push({
				position: [editLatLng.lat, editLatLng.lng],
				text: reading.comments
			});
		}
	}
	if (indx < segment.length - 1) {
		const currentGPSIndx = parseInt(segment[indx], 10);
		const nextGPSIndx = parseInt(segment[indx + 1], 10);
		if (nextGPSIndx - currentGPSIndx > 1) {
			const readings = dat.gpsreadings.slice(currentGPSIndx, nextGPSIndx + 1);
			const { editLatLng: editLatLngStart } = gpsIndexes[currentGPSIndx];
			if (editLatLngStart) {
				const reading = readings[0];
				readings.splice(0, 1, {
					...reading,
					coordinates: [editLatLngStart.lat, editLatLngStart.lng],
					skip: true
				});
			}
			const { editLatLng: editLatLngEnd } = gpsIndexes[nextGPSIndx];

			if (editLatLngEnd) {
				const reading = readings[readings.length - 1];
				readings.splice(readings.length - 1, 1, {
					...reading,
					coordinates: [editLatLngEnd.lat, editLatLngEnd.lng],
					skip: true
				});
			}
			const comments = getCommentsWithCoordinate(
				readings,
				{ gpsreadings: readings },
				0,
				readings.length - 1
			).map(comment => ({ ...comment, color: dat.color }));
			acc.push(...comments);
		}
	}
	return acc;
}

function reduceSegment(acc, segment, gpsIndexes, dat) {
	acc.push(
		...segment.reduce((sAcc, gpsIndx, indx) => {
			return reduceComments(sAcc, gpsIndx, indx, segment, gpsIndexes, dat);
		}, [])
	);
	return acc;
}

// These are the coordinates that will connect the points from lines drawn by using the move tool
const getComments = (datPnts, dat) => {
	const gpsIndexes = getGpsReadingIndexesForEditLine(datPnts, dat);
	const segments = Object.keys(gpsIndexes).reduce((acc, gpsIndx, indx) => {
		if (_shouldCreateNewSegment(gpsIndexes, gpsIndx, indx)) {
			acc.push([]);
		}
		acc[acc.length - 1].push(gpsIndx);

		return acc;
	}, []);
	const comments = segments.reduce((acc, segment) => {
		return reduceSegment(acc, segment, gpsIndexes, dat);
	}, []);
	return comments;
};

export function getSingleEditLineFromMovedPoints(movedPoints, dats) {
	return Object.keys(movedPoints).reduce((acc, fileName) => {
		const dat = getDatFile(dats, fileName);
		const movedPoint = movedPoints[fileName];
		const indexes = getGpsReadingIndexesForEditLine(movedPoint, dat);
		const positions = getLineCoordinates(indexes, dat);
		acc.push({ positions, color: dat.color });
		return acc;
	}, []);
}

export function getCommentPoints(movedPoints, dats) {
	return Object.keys(movedPoints).reduce((acc, fileName) => {
		const dat = getDatFile(dats, fileName);
		const movedPoint = movedPoints[fileName];
		const comments = getComments(movedPoint, dat);
		acc.push(...comments);
		return acc;
	}, []);
}

const _getIndexOfPointInMovedPoints = (point, allPoints) => {
	const [lat, lon] = point.properties.gpsReading.coordinates;
	for (let i = 0; i < allPoints.length; i += 1) {
		const thisPoint = allPoints[i];
		const [lat2, lon2] = thisPoint.properties.gpsReading.coordinates;

		if (lon === lon2 && lat === lat2) {
			return i;
		}
	}

	return -1;
};

export const getNewPointWithEditLatLng = (point, latlng) => {
	return {
		...point,
		editLatLng: latlng
	};
};

export const isPointInMovedPoints = (point, allPoints) => {
	const index = _getIndexOfPointInMovedPoints(point, allPoints);
	return index > -1;
};

export const getNewMovedPointsFromLatLng = (
	point,
	movedPointsByDatIndex,
	fileName,
	latlng
) => {
	const movedPoints = movedPointsByDatIndex[fileName];
	const pointIndex = _getIndexOfPointInMovedPoints(point, movedPoints);

	return movedPoints.map((currentPoint, i) => {
		if (i !== pointIndex) {
			return currentPoint;
		}

		return getNewPointWithEditLatLng(currentPoint, latlng);
	});
};

export const getMovedPointsInfo = movedPoints => {
	const statsByFileName = {};
	const datFilesByFileName = {};
	Object.keys(movedPoints).forEach(_fileName => {
		const movedPointsGroup = movedPoints[_fileName];
		movedPointsGroup.forEach(movedPoint => {
			const { dat, stationNum } = movedPoint;
			const { fileName } = dat;

			if (!datFilesByFileName[fileName]) {
				datFilesByFileName[fileName] = dat;
			}
			if (!statsByFileName[fileName]) {
				statsByFileName[fileName] = {
					stationIdsMoved: []
				};
			}

			const { stationIdsMoved } = statsByFileName[fileName];

			stationIdsMoved.push(stationNum);
		});
	});
	const datFiles = Object.values(datFilesByFileName);
	const statsPerFile = Object.keys(statsByFileName).map(fileName => ({
		fileName,
		...statsByFileName[fileName]
	}));

	const allMovedCount = statsPerFile.reduce(
		(count, stat) => count + stat.stationIdsMoved.length,
		0
	);

	const stats = {
		statsPerFile,
		allMovedCount
	};

	return {
		datFiles,
		stats
	};
};

export const getPixelDistance = (point, latlng, map) => {
	const [lng, lat] = point.geometry.coordinates;
	const rlatlng = L.latLng([lat, lng]);
	const nearestPixel = map.latLngToLayerPoint(rlatlng);
	const layerPoint = map.latLngToLayerPoint(latlng);
	const pixelDistance = Math.sqrt(
		(layerPoint.x - nearestPixel.x) ** 2 + (layerPoint.y - nearestPixel.y) ** 2
	);

	return pixelDistance;
};

// exports for unit tests
export { getGpsReadingIndexesForEditLine, getLineCoordinates };
