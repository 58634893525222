import { SET_APPLICATIONS } from './actions';

const defaultState = {
	applications: []
};

const reducer = (state = defaultState, action) => {
	const { type, payload } = action;
	switch (type) {
		case SET_APPLICATIONS:
			return { ...state, applications: payload };
		default:
			return state;
	}
};

export default reducer;
