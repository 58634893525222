import scanlineMapUtils from '../../../../../scanline/mapUtils';
import { FLAT_KEYS } from './initialState';

const formatDate = v => {
	if (!v) {
		return undefined;
	}

	return scanlineMapUtils.formatDateMMDDYYYY(new Date(v));
};

export const transformItems = (items = []) => {
	return items;
};

export const toFlatItems = (items = []) => {
	return items.reduce((acc, item) => {
		acc.push({
			[FLAT_KEYS.line]: (item.line || {}).name,
			[FLAT_KEYS.jobNumber]: (item.job || {}).jobNumber,
			[FLAT_KEYS.jobStartDate]: formatDate((item.job || {}).startDate),
			[FLAT_KEYS.jobEndDate]: formatDate((item.job || {}).endDate),
			[FLAT_KEYS.jobUpdatedDate]: formatDate((item.job || {}).updatedDate),
			[FLAT_KEYS.customer]: (item.customer || {}).name,
			[FLAT_KEYS.entity]: (item.entity || {}).name,
			[FLAT_KEYS.projectManager]: (item.customerPm || {}).fullName,
			[FLAT_KEYS.corrosionTechnician]: (item.customerTech || {}).fullName,
			[FLAT_KEYS.aegionPm]: (item.aegionPm || {}).userid,
			[FLAT_KEYS.aegionDps]: (item.aegionDps || [])
				.map((p = {}) => p.userid)
				.join(', '),
			[FLAT_KEYS.surveys]: (item.surveys || [])
				.filter(
					(s = {}) => s.countSegmentsUploaded && s.countSegmentsUploaded > 0
				)
				.map((s = {}) => s.type)
				.join(', ')
		});
		return acc;
	}, []);
};

export default {
	transformItems,
	toFlatItems
};
