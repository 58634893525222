/* eslint-disable jsx-a11y/control-has-associated-label */
import React, { useMemo, useCallback } from 'react';
import { withRouter } from 'react-router';
import { createMailToString } from '../../../../../scanline/utils/mailto';
import { EMAIL_CUSTOMER_SUPPORT } from '../../../../constants/emails';
import { getLocation } from '../../../../../scanline/utils/location';

import './EmailCustomerSupport.scss';

const parseRootPath = (urlPath = '') => urlPath.split('/').filter(v => !!v)[0];
const parseSubPath = (urlPath = '') =>
	urlPath.substring(urlPath.lastIndexOf('/') + 1);

const openInNewTab = url => {
	const newWindow = window.open(url, '_blank', 'noopener,noreferrer');
	if (newWindow) {
		newWindow.opener = null;
	}
};

const selectAppInfo = (rootPath, subPath) => {
	let appName;

	switch (rootPath) {
		case 'fieldlinereact':
			appName = 'FieldLine®';
			break;
		case 'scanline':
			appName = 'ScanLine®';
			break;
		case 'assetview':
			appName = 'AssetView®';
			break;
		case 'chargeline':
			appName = 'ChargeLine™';
			break;
		case 'liveline':
			appName = 'LiveLine™';
			break;
		case 'cisline':
			switch (subPath) {
				case 'spikeeditor':
					appName = 'CISView™ Spike Editor';
					break;
				case 'surveyfiles':
					appName = 'CISView™ Survey Files';
					break;

				default:
					appName = 'CISView™';
			}
			break;
		default:
			appName = 'AIM';
	}

	return { appName };
};

const createMailToHref = appName => {
	return createMailToString({
		mailTo: EMAIL_CUSTOMER_SUPPORT,
		subject: `${appName} Customer Support`,
		body: [
			'',
			'Hello',
			'I am experiencing the below problem:',
			'',
			'',
			'Could someone please email me to help resolve.',
			'Thanks,',
			'',
			'---',
			'Page Reference:',
			getLocation().href
		]
	});
};

const EmailCustomerSupport = ({ history }) => {
	const rootPath = (
		parseRootPath(history.location.pathname) || ''
	).toLowerCase();

	const subPath = (parseSubPath(history.location.pathname) || '').toLowerCase();

	const { appName } = useMemo(() => selectAppInfo(rootPath, subPath), [
		rootPath,
		subPath
	]);

	const onClick = useCallback(() => openInNewTab(createMailToHref(appName)), [
		appName
	]);

	return (
		// eslint-disable-next-line jsx-a11y/anchor-is-valid
		<a
			type="button"
			id="customer-support-button"
			className="col-static btn btn-link"
			// eslint-disable-next-line no-script-url
			href="javascript:void(0)"
			onClick={onClick}
			title="Click to email customer support"
		>
			<i className="fa fa-envelope" />
		</a>
	);
};

export default withRouter(EmailCustomerSupport);
