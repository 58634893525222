import { SLICE_ROOT_KEY } from './constants';
import { isValidKey } from './util';

export const selectSliceState = appState => appState[SLICE_ROOT_KEY];

// META
export const selectAllFileMeta = appState => {
	const sliceState = selectSliceState(appState);
	const { fileMeta } = sliceState;
	return fileMeta;
};

export const selectFileMeta = (appState, key) => {
	if (!isValidKey) {
		return undefined;
	}

	const fileMeta = selectAllFileMeta(appState);
	return fileMeta[key];
};

// CONTENT
export const selectAllFileContent = appState => {
	const sliceState = selectSliceState(appState);
	const { fileContent } = sliceState;
	return fileContent;
};

export const selectFileContent = (appState, key) => {
	if (!isValidKey) {
		return undefined;
	}

	const fileContent = selectAllFileContent(appState);
	return fileContent[key];
};

// DOC JSON
export const selectAllFileDocJson = appState => {
	const sliceState = selectSliceState(appState);
	const { fileDocJson } = sliceState;
	return fileDocJson;
};

export const selectFileDocJson = (appState, key) => {
	if (!isValidKey) {
		return undefined;
	}

	const fileDocJson = selectAllFileDocJson(appState);
	return fileDocJson[key];
};

// ERRORS
export const selectAllFileErrors = appState => {
	const sliceState = selectSliceState(appState);
	const { fileErrors } = sliceState;
	return fileErrors;
};

export const selectFileErrors = (appState, key) => {
	if (!isValidKey) {
		return undefined;
	}

	const fileErrors = selectAllFileErrors(appState);
	return fileErrors[key];
};

export const selectHasFileErrors = (appState, key) => {
	const fileErrors = selectFileErrors(appState, key);
	return !!fileErrors && fileErrors.length > 0;
};

// PROCESSING MESSAGES
export const selectAllFileProcessingMessages = appState => {
	const sliceState = selectSliceState(appState);
	const { fileProcessingMessages } = sliceState;
	return fileProcessingMessages;
};

export const selectFileProcessingMessages = (appState, key) => {
	if (!isValidKey) {
		return undefined;
	}

	const fileProcessingMessages = selectAllFileProcessingMessages(appState);
	return fileProcessingMessages[key];
};

export const selectHasFileProcessingMessages = (appState, key) => {
	const fileProcessingMessage = selectFileProcessingMessages(appState, key);
	return !!fileProcessingMessage && fileProcessingMessage.length > 0;
};

// Has File
export const selectHasFile = (appState, key) => {
	const meta = selectFileMeta(appState, key);
	const hasProcessingMessage = selectHasFileProcessingMessages(appState, key);
	const hasError = selectHasFileErrors(appState, key);

	return !!meta && !!meta.name && !hasProcessingMessage && !hasError;
};
