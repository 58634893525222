/* eslint-disable camelcase */
import scanlineMapUtils from '../../mapUtils';
import { splitArray } from '../../utils/array';
import { getChartId } from '../../utils/chart';
import { universalSortSurveys } from '../../utils/surveys.sort';
import { goodEnoughUUID } from '../../utils/uuid';

export const removeSurveyS3Key = (survey, s3Key, keysMap) => {
	const { survey_guid } = survey;
	const keys = (keysMap[survey_guid] || []).filter(k => k !== s3Key);

	return {
		...keysMap,
		[survey_guid]: keys && keys.length ? keys : undefined
	};
};

export const removeSurvey = (survey, paramSurveys = []) => {
	const surveys = [...paramSurveys];
	const idx = surveys.findIndex(s => s.id === survey.id);
	surveys.splice(idx, 1);
	return surveys;
};

export const removeSurveysByJobId = (surveys = [], jobId) => {
	return surveys.filter(s => s.survey_guid !== jobId);
};

export const computeSupportedAndUnsupportedSurveys = (surveys = []) => {
	// @note-supported-constant-needs-abstraction
	const supportedSurveyTypes = [
		'ON_OFF',
		'ON-OFF',
		'DEPOL',
		'ON',
		'NATIVE',
		'DOC',
		'REL',
		'DCVG',
		'ACVG',
		'ACCA'
	];

	const isSupported = s => supportedSurveyTypes.indexOf(s.survey_subtype) > -1;

	const [supportedSurveys, unsupportedSurveys] = splitArray(
		surveys,
		isSupported
	);
	return [supportedSurveys, unsupportedSurveys];
};

export const parseUnsupportedReadingsHeader = (header, data = []) => {
	return {
		...header,
		computedType: header.survey_type,
		computedSubtype:
			header.onoff === 'T' ? 'ON_OFF' : header.survey_subtype || data.length
	};
};

// @note-supported-constant-needs-abstraction
const SUPPORTED_FALLBACK_SURVEYS = [
	'ON_OFF',
	'ON-OFF',
	'DEPOL',
	'NATIVE',
	'ON',
	'DOC',
	'REL',
	'ACCA',
	'DCVG',
	'ACCA',
	'ACVG',
	'ACCD',
	'ACPS',
	'DCCD',
	'DCPS',
	'SRES',
	'ILI', // @todo ? do i really need this ?
	'ILI-exMtlLoss',
	'ILI-inMtlLoss'
];

// TODO: Add back once approved by KM - 'ILI-swl', 'ILI-dent',

const isFallbackSurveySupported = header => {
	const { computedSubtype } = header;
	return SUPPORTED_FALLBACK_SURVEYS.indexOf(computedSubtype) > -1;
};

const VALID_DEFAULT_SURVEY_TYPES = ['ON_OFF', 'ON-OFF', 'ON'];

export const computeDefaultSurveyProps = (
	currentDefaultSurvey,
	surveysWithKnownType
) => {
	let newDefaultSurvey = currentDefaultSurvey;
	let newDefaultSurveyJobId = (currentDefaultSurvey || {}).job_id;

	if (
		!currentDefaultSurvey &&
		surveysWithKnownType &&
		surveysWithKnownType.length
	) {
		const surveys = universalSortSurveys(surveysWithKnownType);
		const firstSurvey = surveys[0] || {};
		if (VALID_DEFAULT_SURVEY_TYPES.indexOf(firstSurvey.survey_subtype) > -1) {
			newDefaultSurvey = firstSurvey;
			newDefaultSurveyJobId = firstSurvey.job_id;
		}
	}

	return [newDefaultSurvey, newDefaultSurveyJobId];
};

export const cleanupUnsupportedSurveys = state => {
	let {
		unsupportedSurveysToProcess,
		loadingUnsupportReadingsHeaders,
		loadingUnsupportReadings,
		unsupportedSurveyS3KeysToLoad,
		unsupportedSurveyS3KeysLoading
	} = state;

	const unsupportedSurveysToRemove = unsupportedSurveysToProcess.filter(s => {
		const { id } = s;
		return loadingUnsupportReadingsHeaders[id].some(
			header => !isFallbackSurveySupported(header)
		);
	});

	unsupportedSurveysToRemove.forEach(s => {
		const { id, survey_guid } = s;
		unsupportedSurveysToProcess = removeSurvey(s, unsupportedSurveysToProcess);
		loadingUnsupportReadingsHeaders = {
			...loadingUnsupportReadingsHeaders,
			[survey_guid]: undefined
		};
		loadingUnsupportReadings = {
			...loadingUnsupportReadings,
			[survey_guid]: undefined
		};
		unsupportedSurveyS3KeysToLoad = {
			...unsupportedSurveyS3KeysToLoad,
			[id]: undefined
		};
		unsupportedSurveyS3KeysLoading = {
			...unsupportedSurveyS3KeysLoading,
			[id]: undefined
		};
	});

	return {
		unsupportedSurveysToProcess,
		loadingUnsupportReadingsHeaders,
		loadingUnsupportReadings,
		unsupportedSurveyS3KeysToLoad,
		unsupportedSurveyS3KeysLoading
	};
};

export const createCoreSurveyFromUnsupportedSurvey = (
	unsupportedSurvey,
	headers,
	readingsCount,
	isFirstSurveyOfGroup = true
) => {
	const { survey_guid, x } = unsupportedSurvey;
	const header = headers[0] || {};
	const { computedType, computedSubtype, parsedFrom } = header;

	const surveyId = scanlineMapUtils.createSurveyId(
		computedType,
		computedSubtype,
		survey_guid
	);
	const isCIS = scanlineMapUtils.isCis(surveyId);

	const survey = {
		...unsupportedSurvey,
		displayName: `${computedSubtype}(${parsedFrom}-${readingsCount})`,
		survey_type: computedType,
		type: computedType,
		survey_guid,
		survey_subtype: computedSubtype,
		subtype: computedSubtype,
		parsedFrom,
		id: surveyId,
		CHART_ID: getChartId(surveyId),
		checked: true,
		withThreshold: isCIS,
		withDepol: false,
		isUploaded: true,
		job_id: survey_guid,
		jobId: survey_guid,
		x,
		isFirstSurveyOfGroup
	};
	return survey;
};

const splitILIHeader = header => {
	// for ILI, we need to break the response into individual surveys
	const importantSurveyFields = ['exMtlLoss', 'inMtlLoss', 'swl', 'dent'];
	const surveys = importantSurveyFields.map(importantSurveyField => {
		return parseUnsupportedReadingsHeader({
			...header,
			survey_subtype: `${header.survey_subtype}-${importantSurveyField}`
		});
	});

	return surveys;
};

const splitSurveyHeader = header => {
	const splitHeaders = (header.survey_subtypes || [])
		.map(subType => {
			if (subType === 'REL') {
				return undefined;
			}

			const newHeader = {
				...header,
				survey_subtype: subType
			};

			return parseUnsupportedReadingsHeader(newHeader);
		})
		.filter(h => h);

	// Check to see if there is any ILI data mixed in there
	for (let i = 0; i < splitHeaders.length; i += 1) {
		const hdr = splitHeaders[i];

		if ((hdr.survey_subtype || '').match(/ILI$/)) {
			splitHeaders.splice(i, 1); // Remove this item as we will already get the wanted ILI subtypes
			splitILIHeader(hdr).forEach(s => splitHeaders.push(s));
			break;
		}
	}

	return splitHeaders;
};

export const createSurveysFromUnsupportedCoreSurvey = (hdrs, paramSurvey) => {
	const header = (hdrs || [])[0];
	let headers = [header];

	if ((header.survey_subtypes || []).length > 1) {
		headers = splitSurveyHeader(header);
	} else if ((header.survey_subtype || '').match(/ILI$/)) {
		headers = splitILIHeader(header);
	}

	// @TODO: add back in ILI-swl and ILI-dent once approved by KM
	headers = headers.filter(
		s => s.survey_subtype !== 'ILI-swl' && s.survey_subtype !== 'ILI-dent'
	);

	const survey = {
		...paramSurvey,
		job_number: paramSurvey.job_number || `faux-${goodEnoughUUID()}`
	};

	const surveys = headers.map((hdr, idx) => {
		const readingsCount = 0;
		const isFirstSurveyOfGroup = idx === 0;

		return createCoreSurveyFromUnsupportedSurvey(
			survey,
			[hdr],
			readingsCount,
			isFirstSurveyOfGroup
		);
	});

	return surveys;
};
