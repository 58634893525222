/* eslint-disable camelcase */
import { createActions } from 'redux-actions';

// eslint-disable-next-line import/no-webpack-loader-syntax
import ComputeDiffsWorker from 'workerize-loader?inline!../../../webworkers/ComputeFileDiff';

import { ACTIONS_NAME_SPACE, ERROR_KEY_UNKNOWN_ERROR } from './constants';
import { getErrorMessage } from './util';
import { selectAppState } from '../../selectors';
import { selectIsComputingDiffs } from './selectors';
import {
	selectHasFile,
	selectFileMeta,
	selectFileContent
} from '../UploadedFiles/selectors';

const {
	setIsComputingDiffs,
	setErrors,
	setProcessingMessage,
	reset
} = createActions(
	{
		SET_IS_COMPUTING_DIFFS: isComputingDiffs => ({ isComputingDiffs }),
		SET_ERRORS: errors => ({ errors }),
		SET_PROCESSING_MESSAGE: processingMessageKey => ({
			processingMessageKey
		}),
		RESET: () => ({})
	},
	{ prefix: ACTIONS_NAME_SPACE }
);

const _helper_createFileParams = (name, ext, content) => {
	return {
		fileName: [name, ext].filter(v => v).join('.'),
		content
	};
};

const _helper_downloadFile = (fileName, mimeType, content) => {
	const anchor = document.createElement('a');
	anchor.href = `data:${mimeType},${encodeURI(content)}`;
	anchor.target = '_self';
	anchor.rel = 'noopener';
	anchor.download = fileName;
	document.body.appendChild(anchor);
	anchor.click();
	document.body.removeChild(anchor);
};

const computeDiffsWorker = new ComputeDiffsWorker();
const thunkComputeDiffs = () => (dispatch, getState) => {
	const fileKeys = ['A', 'B'];
	const appState = selectAppState(getState());

	const canComputeDiffs =
		!selectIsComputingDiffs(appState) &&
		fileKeys.every(k => selectHasFile(appState, k));

	if (canComputeDiffs) {
		dispatch(setIsComputingDiffs(true));
		const fileMetaA = selectFileMeta(appState, 'A');
		const fileContentA = selectFileContent(appState, 'A');
		const fileMetaB = selectFileMeta(appState, 'B');
		const fileContentB = selectFileContent(appState, 'B');

		const fileParamsA = _helper_createFileParams(
			fileMetaA.name,
			fileMetaA.ext,
			fileContentA
		);

		const fileParamsB = _helper_createFileParams(
			fileMetaB.name,
			fileMetaB.ext,
			fileContentB
		);

		computeDiffsWorker
			.computeDiff(fileParamsA, fileParamsB, {
				idProp: 'MSID'
			})
			.then(({ result, error }) => {
				if (error) {
					dispatch(setErrors([error]));
				} else {
					const { deleted, modified, added } = result;
					_helper_downloadFile(
						deleted.filename,
						deleted.mimeType,
						deleted.content
					);
					_helper_downloadFile(
						modified.filename,
						modified.mimeType,
						modified.content
					);
					_helper_downloadFile(added.filename, added.mimeType, added.content);
				}
				dispatch(setIsComputingDiffs(false));
			})
			.catch(() => {
				const errorMessage = getErrorMessage(ERROR_KEY_UNKNOWN_ERROR);
				dispatch(setErrors([errorMessage]));
				dispatch(setIsComputingDiffs(false));
			});
	}
};

export {
	setIsComputingDiffs,
	setErrors,
	setProcessingMessage,
	reset,
	thunkComputeDiffs
};
