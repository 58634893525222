import { SET_MAP_BOUNDS } from './actionTypes';

const initialState = {
	mapBounds: undefined
};

const reducer = (state = initialState, action) => {
	const { type, payload } = action;
	switch (type) {
		case SET_MAP_BOUNDS:
			return { ...state, mapBounds: payload };
		default:
			return state;
	}
};

export default reducer;
