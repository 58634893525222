import React from 'react';

import Unstyled from './components/Details/variants/Unstyled';
import Original from './components/Details/variants/Original';
import OriginalV2 from './components/Details/variants/Original.v2';

export const KEY_PLACEHOLDER = 'placeholder';
export const KEY_UNSTYLED = 'unstyled';
export const KEY_ORIGINAL = 'original';
export const KEY_ORIGINAL_V2 = 'original-v2';
export const DEFAULT_KEY = KEY_ORIGINAL_V2;

const Placeholder = () => <div>DETAILS: placeholder</div>;

export const detailsVariants = [
	{
		displayText: 'DETAILS: placeholder',
		key: KEY_PLACEHOLDER,
		Component: Placeholder
	},
	{
		displayText: 'DETAILS: unstyled',
		key: KEY_UNSTYLED,
		Component: Unstyled
	},
	{
		displayText: 'DETAILS: original',
		key: KEY_ORIGINAL,
		Component: Original
	},
	{
		displayText: 'DETAILS: original - v2',
		key: KEY_ORIGINAL_V2,
		Component: OriginalV2
	}
];

export const detailsVariantsMap = detailsVariants.reduce((acc, obj) => {
	return {
		...acc,
		[obj.key]: obj
	};
}, {});

export const getDetailsComponent = key => {
	const obj = detailsVariantsMap[key] || detailsVariantsMap[DEFAULT_KEY];
	return obj.Component;
};

export default {
	detailsVariants,
	detailsVariantsMap,
	getDetailsComponent
};
