/* eslint-disable no-use-before-define */
import _ from 'lodash';
import { ROOT_SLICE } from './constants';

export const selectRootSlice = state => state[ROOT_SLICE];

export const selectIsLoading = state => {
	const slice = selectRootSlice(state);
	const { isLoading } = slice;
	return isLoading;
};

export const selectIsLoaded = state => {
	const slice = selectRootSlice(state);
	const { isLoaded } = slice;
	return isLoaded;
};

// @todo - the data structure for s3 data cache appears to be bugged, where the target id and parent id are not in proper place.
// @todo - will need to address this confirm / fix if need be, in regression ticket.
export const hackSelectReadings = (
	stateApp,
	{ surveyId, key = 'readings' }
) => {
	const slice = selectRootSlice(stateApp);
	const { data } = slice;
	return data[surveyId].NONE.NONE[key];
};

// @todo - the data structure for s3 data cache appears to be bugged, where the target id and parent id are not in proper place.
// @todo - will need to address this confirm / fix if need be, in regression ticket.
export const hackSelectReadingsMap = (
	stateApp,
	{ surveyId, key = 'readingsMap' }
) => {
	const slice = selectRootSlice(stateApp);
	const { data } = slice;
	return data[surveyId].NONE.NONE[key];
};

export const selectReadings = (
	state,
	{ targetKey, parentKey = 'NONE', modifierKey = 'NONE' }
) => {
	const slice = selectRootSlice(state);
	const { data } = slice;

	return _.get(
		data,
		`${targetKey}.${parentKey}.${modifierKey}.readings`,
		undefined
	);
};
