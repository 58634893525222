class ConfirmUndoQueue {
	constructor() {
		this.confirmQueue = [];
		this.undoQueue = [];
	}

	addToConfirmQueue = fn => {
		this.confirmQueue.push(fn);
	};

	addToUndoQueue = fn => {
		this.undoQueue.push(fn);
	};

	executeConfirmQueue = () => {
		this.confirmQueue.forEach(fn => fn());
		this.clear();
	};

	executeUndoQueue = () => {
		this.undoQueue.forEach(fn => fn());
		this.clear();
	};

	clearConfirmQueue = () => {
		this.confirmQueue = [];
	};

	clearUndoQueue = () => {
		this.undoQueue = [];
	};

	clear = () => {
		this.clearConfirmQueue();
		this.clearUndoQueue();
	};
}

export default new ConfirmUndoQueue();
