import sphereKnn from 'sphere-knn';

/** ***********
 * MAIN - GET IDS FOR ALIGNING SEGMENTS
 * This actually aligns by gps coordinates
 */

// TODO: We are using KNN in several different places - we need to consolidate
export const getIdsForSegmentsToAlign = (
	targetReadings,
	cisReadings,
	requireComments = true
) => {
	const cisRefIds = {};
	const targetRefIds = {};

	// We are only getting readings where comments contain something related to "test station"
	const withComment = ({ cmt }) => /\btest ?station\b|\bts\b/gi.test(cmt);
	const maxDistance = 20 * 0.3048; // feet to meters

	let lastAligned = -1;

	const spatialIndex = sphereKnn(
		targetReadings.map((reading, index) => ({
			lat: reading.loc[0],
			lon: reading.loc[1],
			index
		}))
	);

	// loop through cis readings with comments filtered by withComment
	const cisReadingsToAlign = requireComments
		? cisReadings.filter(withComment)
		: cisReadings;
	for (let i = 0, n = cisReadingsToAlign.length; i < n; i += 1) {
		const cisReading = cisReadingsToAlign[i];

		// get the nearest target readings within maxDistance
		// of the current cis reading
		const nearestTargetReadings = spatialIndex(
			cisReading.loc[0],
			cisReading.loc[1],
			Infinity,
			maxDistance
		);

		// find the nearest target reading that is not yet aligned
		// and align it to the current cis reading
		// else we simply skip the alignment
		for (let j = 0, m = nearestTargetReadings.length; j < m; j += 1) {
			const nearbyIndex = nearestTargetReadings[j].index;
			const targetReading = targetReadings[nearbyIndex];
			// Makes sure that the reading has a comment and comes after the last reading selected
			if (
				(!requireComments || withComment(targetReading)) &&
				nearbyIndex > lastAligned
			) {
				lastAligned = nearbyIndex;
				cisRefIds[cisReading.id] = true;
				targetRefIds[targetReading.id] = true;
				break;
			}
		}
	}

	return {
		cisRefIds,
		targetRefIds
	};
};

export default getIdsForSegmentsToAlign;
