import { convertToBool } from '../../redux/utils';

import { normalizeFiles } from './normalizeFiles';

const FileHandlerUtils = {
	isFileEditable(fileFormat) {
		if (!fileFormat) {
			return false;
		}
		switch (fileFormat.toLowerCase()) {
			case 'png':
			case 'pdf':
			case 'zip':
			case 'jpg':
			case 'xls':
			case 'xlsx':
			case 'kmz':
			case 'kml':
				return false;
			default:
				return true;
		}
	},
	getFileFormatTitle(fileName) {
		if (!fileName) {
			return ''; // TODO: This should never happen - maybe we should throw an error?
		}
		switch (fileName.toLowerCase()) {
			case 'xls':
			case 'xlsx':
				return 'Final reports';
			case 'png':
			case 'jpg':
				return 'Image Files';
			case 'dat':
			case 'rev':
				return 'DAT Files';
			default:
				return `${fileName.toUpperCase()} Files`;
		}
	},
	getFileResultStatus(
		fileObj,
		{ surveyType = null, showWarnings = true } = {}
	) {
		if (surveyType === 'TS Data') {
			return 'Success';
		}
		if (convertToBool(fileObj.errors)) {
			return 'Errors';
		}
		if (showWarnings && convertToBool(fileObj.warnings)) {
			return 'Warnings';
		}

		return 'Success';
	},
	normalizeFiles
};

export default FileHandlerUtils;
