/* eslint-disable no-use-before-define */
import { createActions } from 'redux-actions';
import fieldlineUtils from '../scanlineUtils';

const {
	createProject,
	requestCompleteCreateProject,
	requestCreateProject,
	getProjectData,
	// eslint-disable-next-line no-unused-vars
	getExternalProjectData,
	receiveProjectData,
	requestProjectData,
	saveProjectData,
	receiveSaveProjectData,
	requestSaveProjectData,
	// eslint-disable-next-line no-unused-vars
	deleteMyProject,
	deleteProjectData,
	receiveDeleteProjectData,
	requestDeleteProjectData,
	removeSurveyByJobId
} = createActions({
	CREATE_PROJECT: (jobId, data, lineName) =>
		doCreateProject(jobId, data, lineName),
	REQUEST_COMPLETE_CREATE_PROJECT: json => ({ json }),
	REQUEST_CREATE_PROJECT: () => ({}),
	GET_PROJECT_DATA: jobId => doGetProjectData(jobId),
	GET_EXTERNAL_PROJECT_DATA: projectId => doGetExternalProjectData(projectId),
	RECEIVE_PROJECT_DATA: json => ({ json }),
	REQUEST_PROJECT_DATA: () => ({}),
	SAVE_PROJECT_DATA: jobId => doSaveProjectData(jobId),
	RECEIVE_SAVE_PROJECT_DATA: json => ({ json }),
	REQUEST_SAVE_PROJECT_DATA: () => ({}),
	DELETE_MY_PROJECT: projectId => doDeleteMyProject(projectId),
	DELETE_PROJECT_DATA: jobId => doDeleteProjectData(jobId),
	RECEIVE_DELETE_PROJECT_DATA: json => ({ json }),
	REQUEST_DELETE_PROJECT_DATA: () => ({}),
	REMOVE_SURVEY_BY_JOB_ID: jobId => ({ jobId })
});

const doCreateProject = (jobId, data, lineName, callback = () => {}) => {
	return dispatch => {
		dispatch(requestCreateProject());

		return fieldlineUtils.createProject(jobId, data, lineName, (err, res) => {
			if (res) {
				callback(null, res);
				dispatch(requestCompleteCreateProject(res));
				// console.error(res);
			} else {
				callback(err, res);
				dispatch(requestCompleteCreateProject(err));
			}
		});
	};
};

const doGetProjectData = (jobId, callback = () => {}) => {
	return dispatch => {
		dispatch(requestProjectData());

		return fieldlineUtils.getProjectData(jobId, (res, err) => {
			if (res) {
				callback(res);
			} else {
				callback(null, err);
				dispatch(receiveProjectData(err));
			}
		});
	};
};

const doGetExternalProjectData = (projectId, callback = () => {}) => {
	return dispatch => {
		dispatch(requestProjectData());

		return fieldlineUtils.getExternalProjectData(projectId, (err, res) => {
			if (err) {
				callback(err);
				// console.error(err);
			} else {
				callback(null, res);
				dispatch(receiveProjectData(res));
			}
		});
	};
};

const createProjectData = key => {
	return [
		{
			type: 'UPLOADED_SURVEY',
			s3Key: key,
			data: {}
		}
	];
};

const doSaveProjectData = (jobId, projectId, key, callback = () => {}) => {
	return dispatch => {
		dispatch(requestSaveProjectData());

		const projectData = createProjectData(key);
		return fieldlineUtils.saveProjectData(
			jobId,
			projectId,
			projectData,
			(err, res) => {
				if (err) {
					callback(err);
					// console.error(err);
				} else {
					callback(null, res);
					dispatch(receiveSaveProjectData(res));
				}
			}
		);
	};
};

const createProjectDataIds = id => {
	return [id];
};

const doDeleteMyProject = (projectId, callback = () => {}) => {
	// @wbnote - this is in use
	return dispatch => {
		dispatch(requestDeleteProjectData());

		return fieldlineUtils.deleteMyProject(projectId, (err, res) => {
			if (err) {
				callback(err);
				// console.error(err);
			} else {
				callback(null, res);
			}
		});
	};
};

const doDeleteProjectData = (
	surveyExternalId,
	options = {},
	callback = () => {}
) => {
	const { silent = false } = options;
	// @wbnote - this is in use
	return dispatch => {
		if (!silent) {
			dispatch(requestDeleteProjectData());
		}

		const projectDataIds = createProjectDataIds(surveyExternalId);
		return fieldlineUtils.deleteProjectData(projectDataIds, (err, res) => {
			if (err) {
				callback(err);
				// console.error(err);
			} else {
				callback(null, res);
				if (!silent) {
					dispatch(receiveDeleteProjectData(res));
					dispatch(removeSurveyByJobId(surveyExternalId));
				}
			}
		});
	};
};

export {
	createProject,
	requestCompleteCreateProject,
	requestCreateProject,
	doCreateProject,
	getProjectData,
	receiveProjectData,
	requestProjectData,
	doGetProjectData,
	doGetExternalProjectData,
	saveProjectData,
	receiveSaveProjectData,
	requestSaveProjectData,
	doSaveProjectData,
	doDeleteMyProject,
	deleteProjectData,
	receiveDeleteProjectData,
	requestDeleteProjectData,
	doDeleteProjectData,
	removeSurveyByJobId
};
