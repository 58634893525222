// TODO: We should actually pull these images from an endpoint eventually

import imagesFromVideo1 from './images_from_video_1.json';
import imagesFromVideo2 from './images_from_video_2.json';

const IMAGE_SETS = [imagesFromVideo1, imagesFromVideo2];

const _sortImages = (a, b) =>
	Math.abs(a.videoLocation) - Math.abs(b.videoLocation);

const loadImages = (imageSetIndex = 0) => {
	const imageSet = IMAGE_SETS[imageSetIndex];
	const sortedImageSet = imageSet.sort(_sortImages);
	return sortedImageSet;
};

export default loadImages;
