/* eslint-disable camelcase */
const initialState_actionPlanDownloadErrors = {
	// ACTION PLAN PDF DOWNLOAD LINK
	isDownloadingActionPlanPdf: false,
	downloadActionPlanPdfLink: undefined,
	downloadActionPlanPdfError: undefined,
	isHiddenDownloadActionPlanPdfError: undefined,

	// ACTION PLAN PDF ZIP DOWNLOAD LINK
	isDownloadingActionPlanPdfZip: false,
	downloadActionPlanPdfZipLink: undefined,
	downloadActionPlanPdfZipError: undefined,
	isHiddenDownloadActionPlanPdfZipError: undefined,

	// ACTION PLAN DOC DOWNLOAD LINK
	isDownloadingActionPlanDoc: false,
	downloadActionPlanDocLink: undefined,
	downloadActionPlanDocError: undefined,
	isHiddenDownloadActionPlanDocError: undefined,

	// ACTION PLAN DOC ZIP DOWNLOAD LINK
	isDownloadingActionPlanDocZip: false,
	downloadActionPlanDocZipLink: undefined,
	downloadActionPlanDocZipError: undefined,
	isHiddenDownloadActionPlanDocZipError: undefined,

	// ACTION PLAN PDF DOWNLOAD LINK
	isDownloadingActionPlanD40Pdf: false,
	downloadActionPlanD40PdfLink: undefined,
	downloadActionPlanD40PdfError: undefined,
	isHiddenDownloadActionPlanD40PdfError: undefined
};

export default initialState_actionPlanDownloadErrors;
