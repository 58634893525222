"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.SUPPORTED_FILE_TYPES = exports.SUPPORTED_FILE_EXTENSIONS = exports.KEY_GEOJSON = exports.KEY_CSV = exports.FILE_TYPE_KEYS = exports.FILE_TYPE_DESCRIPTIONS = void 0;

var _FILE_TYPE_KEYS, _FILE_TYPE_DESCRIPTIO;

function _defineProperty(obj, key, value) {
  if (key in obj) {
    Object.defineProperty(obj, key, {
      value: value,
      enumerable: true,
      configurable: true,
      writable: true
    });
  } else {
    obj[key] = value;
  }

  return obj;
}

var KEY_GEOJSON = 'GEOJSON';
exports.KEY_GEOJSON = KEY_GEOJSON;
var KEY_CSV = 'CSV';
exports.KEY_CSV = KEY_CSV;
var FILE_TYPE_KEYS = (_FILE_TYPE_KEYS = {}, _defineProperty(_FILE_TYPE_KEYS, KEY_GEOJSON, KEY_GEOJSON), _defineProperty(_FILE_TYPE_KEYS, KEY_CSV, KEY_CSV), _FILE_TYPE_KEYS);
exports.FILE_TYPE_KEYS = FILE_TYPE_KEYS;
var FILE_TYPE_DESCRIPTIONS = (_FILE_TYPE_DESCRIPTIO = {}, _defineProperty(_FILE_TYPE_DESCRIPTIO, KEY_GEOJSON, {
  fileType: KEY_GEOJSON,
  fileExtension: 'geojson'
}), _defineProperty(_FILE_TYPE_DESCRIPTIO, KEY_CSV, {
  fileType: KEY_CSV,
  fileExtension: 'csv'
}), _FILE_TYPE_DESCRIPTIO);
exports.FILE_TYPE_DESCRIPTIONS = FILE_TYPE_DESCRIPTIONS;
var SUPPORTED_FILE_TYPES = Object.keys(FILE_TYPE_DESCRIPTIONS).map(function (k) {
  return FILE_TYPE_DESCRIPTIONS[k].fileType;
});
exports.SUPPORTED_FILE_TYPES = SUPPORTED_FILE_TYPES;
var SUPPORTED_FILE_EXTENSIONS = Object.keys(FILE_TYPE_DESCRIPTIONS).map(function (k) {
  return FILE_TYPE_DESCRIPTIONS[k].fileExtension;
});
exports.SUPPORTED_FILE_EXTENSIONS = SUPPORTED_FILE_EXTENSIONS;