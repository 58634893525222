import React from 'react';

function Loading(props) {
	const { title } = props;
	return (
		<div className="loading">
			<i className="	fa fa-cog fa-spin" aria-hidden="true" />
			{title}
		</div>
	);
}

export default Loading;
