/* eslint-disable import/no-cycle */
import { createActions } from 'redux-actions';
import jobListUtils from '../utils/utils';

const {
	flRequestJobs,
	flReceiveJobs,
	flShowJob,
	flReceiveLines,
	flChangePage,
	flReceiveFilters,
	flSetFilter,
	flUpdateJobMetadata,
	flWindowResize,
	flResetJobsMap
} = createActions({
	FL_REQUEST_JOBS: customer => ({ customer }),
	FL_RECEIVE_JOBS: (customer, json) => {
		return { customer, jobs: json.jobs, filters: json.filters };
	},
	FL_SHOW_JOB: jobId => ({ jobId }),
	FL_RECEIVE_LINES: results => {
		return { lines: results.lines, linesindex: results.index };
	},
	FL_CHANGE_PAGE: page => ({ page }),
	FL_RECEIVE_FILTERS: (filters, filteredJobs, visibleJobs) => ({
		filters,
		filteredJobs,
		visibleJobs
	}),
	FL_SET_FILTER: filter => ({ filter }),
	FL_UPDATE_JOB_METADATA: metadata => ({ metadata }),
	FL_WINDOW_RESIZE: () => ({ height: window.innerHeight }),
	FL_RESET_JOBS_MAP: () => ({})
});

export const partialFilterJobs = (partialText, history) => (
	dispatch,
	getState
) => {
	const {
		fieldlineJobs: { availableJobs, currentPage }
	} = getState();
	const startValue = (currentPage - 1) * 6;
	const endValue = startValue + 6;
	if (partialText.length > 2) {
		const filteredJobs = availableJobs.filter(job => {
			return (
				Object.keys(job).filter(key => {
					return (
						job[key] &&
						job[key]
							.toString()
							.toLowerCase()
							.indexOf(partialText.toLowerCase()) !== -1
					);
				}).length > 0
			);
		});
		const visibleJobs = filteredJobs.slice(startValue, endValue);
		dispatch(flReceiveFilters([partialText], filteredJobs, visibleJobs));
	}
	if (history) {
		const path =
			partialText.length > 2 ? `?q=${encodeURIComponent(partialText)}` : '?';
		history.push(path);
	}
};

const doFilterJobs = (standardfilters, history, urlFilters) => {
	return (dispatch, getState) => {
		const startValue = (getState().fieldlineJobs.currentPage - 1) * 6;
		const endValue = startValue + 6;

		let filteredJobs = [];
		const filters =
			standardfilters && standardfilters.length
				? standardfilters
				: urlFilters || [];

		if (filters.length > 0) {
			const [filterProp, ...filterParts] = filters[0].split(':');
			const filterValue = filterParts.join(':');
			if (filterValue === undefined || filterValue === '') {
				dispatch(partialFilterJobs(filterProp, history));
				return;
			}
			filteredJobs = getState().fieldlineJobs.availableJobs.filter(j => {
				const prop = filterProp === 'aegion_job_id' ? 'id' : filterProp;
				const val = (j[prop] || '').toString();
				return val === filterValue;
			});
		} else {
			filteredJobs = getState().fieldlineJobs.availableJobs;
		}

		if (history) {
			const path =
				filters.length > 0 ? `?q=${encodeURIComponent(filters[0])}` : '?';
			history.push(path);
		}

		const visibleJobs = filteredJobs.slice(startValue, endValue);
		dispatch(flReceiveFilters(filters, filteredJobs, visibleJobs));
	};
};

const fetchJobsMetadata = () => {
	return (dispatch, getState) => {
		const jobs = getState().fieldlineJobs.visibleJobs.map(job => ({
			customer: job.customer,
			job: job.jobid,
			line: job.linename,
			num: job.id,
			desc: job.title
		}));
		return jobListUtils.getJobsMetadata(jobs, (err, res) => {
			(res || []).forEach(metadata => {
				const metadataJob = metadata[0];
				const filteredJobs = getState().fieldlineJobs.visibleJobs.filter(
					job =>
						job.customer === metadataJob.customer &&
						job.jobid === metadataJob.job &&
						job.linename === metadataJob.line
				);
				if (filteredJobs.length > 0) {
					const [, thumbnail, zip, pdf] = metadata;
					filteredJobs[0].thumbnail = thumbnail;
					filteredJobs[0].zip = zip;
					filteredJobs[0].pdf = pdf;
				}
			});
			const state = getState();
			const { fieldlineJobs } = state;
			const { filters } = fieldlineJobs;
			return dispatch(doFilterJobs(filters));
		});
	};
};

/*
const doFetchLines = (jobId, lineName, index = 0) => {
	return dispatch => {
		return jobListUtils.getLines(jobId, lineName, index, (err, res) => {
			if (err) {
				console.error(err);
			} else {
				dispatch(flReceiveLines(res));
				if (res.hasMore) {
					dispatch(doFetchLines(jobId, lineName, res.index));
				}
			}
		});
	};
};
*/

export const updateJobs = (customer, jobs, urlFilters, pageNum) => (
	dispatch,
	getState
) => {
	dispatch(flReceiveJobs(customer, jobs));
	if (pageNum !== undefined) {
		dispatch(flChangePage(pageNum));
	}
	dispatch(
		doFilterJobs(getState().fieldlineJobs.filters, undefined, urlFilters)
	);
	dispatch(fetchJobsMetadata());
};

const doFetchJobs = (customer, urlFilters) => {
	return dispatch => {
		dispatch(flRequestJobs(customer));

		return jobListUtils.getJobs(customer, (err, res) => {
			if (err) {
				console.log(err); // eslint-disable-line no-console
			} else {
				dispatch(updateJobs(customer, res, urlFilters, 1));
			}
		});
	};
};

const shouldFetchJobs = state => {
	const jobs = state.fieldlineJobs.availableJobs;
	return jobs.length === 0;
};

const fetchJobsIfNeeded = urlFilters => {
	return (dispatch, getState) => {
		if (shouldFetchJobs(getState())) {
			dispatch(
				doFetchJobs(getState().fieldlineJobs.currentCustomer, urlFilters)
			);
		}
	};
};

export {
	flShowJob,
	flChangePage,
	doFilterJobs,
	fetchJobsMetadata,
	flSetFilter,
	fetchJobsIfNeeded,
	flResetJobsMap,
	flRequestJobs,
	flWindowResize,
	flUpdateJobMetadata,
	flReceiveJobs,
	flReceiveLines,
	flReceiveFilters
};
