import {
	CUSTOMER_UNKNOWN,
	CUSTOMER_KINDER_MORGAN,
	CUSTOMER_ENERGY_TRANSFER,
	CUSTOMER_TALLGRASS
} from './constants';

import customerDefault from './customer.meta.default';
import customerKinderMorgan from './customer.meta.kinderMorgan';
import customerEnergyTransfer from './customer.meta.energyTransfer';
import customerTallgrass from './customer.meta.tallgrass';

import {
	RemediationActions as RemediationActionsDefault,
	RemediationActionTypes as RemediationActionTypesDefault,
	RemediationActionFields as RemediationActionFieldsDefault,
	RemediationActionsKeys as RemediationActionsKeysDefault
} from './customer.remdiation.actions.default';

import {
	RemediationActions as RemediationActionsKinderMorgan,
	RemediationActionTypes as RemediationActionTypesKinderMorgan,
	RemediationActionFields as RemediationActionFieldsKinderMorgan,
	RemediationActionsKeys as RemediationActionsKeysKinderMorgan
} from './customer.remediation.actions.kinderMorgan';

import {
	RemediationActions as RemediationActionsEnergyTransfer,
	RemediationActionTypes as RemediationActionTypesEnergyTransfer,
	RemediationActionFields as RemediationActionFieldsEnergyTransfer,
	RemediationActionsKeys as RemediationActionsKeysEnergyTransfer
} from './customer.remediation.actions.energyTransfer';

import {
	RemediationActions as RemediationActionsTallgrass,
	RemediationActionTypes as RemediationActionTypesTallgrass,
	RemediationActionFields as RemediationActionFieldsTallgrass,
	RemediationActionsKeys as RemediationActionsKeysTallgrass
} from './customer.remediation.actions.tallgrass';

// HELPERS
const cleanCustomerKey = key => (key || '').trim().toLowerCase();

// CUSTOMER META
const customerMetaMap = {
	[CUSTOMER_UNKNOWN]: customerDefault,
	[CUSTOMER_KINDER_MORGAN]: customerKinderMorgan,
	[CUSTOMER_ENERGY_TRANSFER]: customerEnergyTransfer,
	[CUSTOMER_TALLGRASS]: customerTallgrass
};
export const getCustomerMeta = key => {
	const customerKey = cleanCustomerKey(key);
	return customerMetaMap[customerKey] || customerDefault;
};

// CUSTOMER REMEDIATION ACTIONS
const customerRemediationActionsMap = {
	[CUSTOMER_UNKNOWN]: RemediationActionsDefault,
	[CUSTOMER_KINDER_MORGAN]: RemediationActionsKinderMorgan,
	[CUSTOMER_ENERGY_TRANSFER]: RemediationActionsEnergyTransfer,
	[CUSTOMER_TALLGRASS]: RemediationActionsTallgrass
};
export const getCustomerRemediationActions = key => {
	const customerKey = cleanCustomerKey(key);
	return (
		customerRemediationActionsMap[customerKey] || RemediationActionsDefault
	);
};

// CUSTOMER REMEDIATION ACTION TYPES
const customerRemediationActionTypesMap = {
	[CUSTOMER_UNKNOWN]: RemediationActionTypesDefault,
	[CUSTOMER_KINDER_MORGAN]: RemediationActionTypesKinderMorgan,
	[CUSTOMER_ENERGY_TRANSFER]: RemediationActionTypesEnergyTransfer,
	[CUSTOMER_TALLGRASS]: RemediationActionTypesTallgrass
};
export const getCustomerRemediationActionTypes = key => {
	const customerKey = cleanCustomerKey(key);
	return (
		customerRemediationActionTypesMap[customerKey] ||
		RemediationActionTypesDefault
	);
};

// CUSTOMER REMEDIATION ACTION FIELDS
const customerRemediationActionFieldsMap = {
	[CUSTOMER_UNKNOWN]: RemediationActionFieldsDefault,
	[CUSTOMER_KINDER_MORGAN]: RemediationActionFieldsKinderMorgan,
	[CUSTOMER_ENERGY_TRANSFER]: RemediationActionFieldsEnergyTransfer,
	[CUSTOMER_TALLGRASS]: RemediationActionFieldsTallgrass
};
export const getCustomerRemediationActionFields = key => {
	const customerKey = cleanCustomerKey(key);
	return (
		customerRemediationActionFieldsMap[customerKey] ||
		RemediationActionFieldsDefault
	);
};

// CUSTOMER REMEDIATION ACTION KEYS
const customerRemediationActionsKeysMap = {
	[CUSTOMER_UNKNOWN]: RemediationActionsKeysDefault,
	[CUSTOMER_KINDER_MORGAN]: RemediationActionsKeysKinderMorgan,
	[CUSTOMER_ENERGY_TRANSFER]: RemediationActionsKeysEnergyTransfer,
	[CUSTOMER_TALLGRASS]: RemediationActionsKeysTallgrass
};
export const getCustomerRemediationActionsKeys = key => {
	const customerKey = cleanCustomerKey(key);
	return (
		customerRemediationActionsKeysMap[customerKey] ||
		RemediationActionsKeysDefault
	);
};
