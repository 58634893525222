/* eslint-disable import/no-cycle */
/* eslint-disable import/prefer-default-export */
import { createPromiseRequest } from './util/createPromiseRequest';
import { hasAccessToken } from '../../scanline/utils/token';

export const getApplications = () => {
	const url = '/applications';

	if (hasAccessToken()) {
		const fetchData = createPromiseRequest({
			url,
			method: 'GET'
		});

		return fetchData();
	}
	return Promise.reject(new Error('Missing Token'));
};

export const updateApplication = ({ application } = {}) => {
	if (!application || !application.id) {
		return Promise.reject(
			new Error(
				'Invalid application object passed to api/applications .updateApplication()'
			)
		);
	}

	const postAppInvite = createPromiseRequest({
		url: '/applications',
		method: 'POST',
		data: application
	});

	return postAppInvite();
};

export const deleteApplication = ({ application } = {}) => {
	if (!application || !application.id) {
		return Promise.reject(
			new Error(
				'Invalid application object passed to api/applications .deleteApplication()'
			)
		);
	}

	const postAppInvite = createPromiseRequest({
		url: '/applications',
		method: 'DELETE',
		data: application
	});

	return postAppInvite();
};

export const insertApplication = ({ application } = {}) => {
	if (!application || !application.name) {
		return Promise.reject(
			new Error(
				'Invalid application object passed to api/applications .updateApplication()'
			)
		);
	}

	const postAppInvite = createPromiseRequest({
		url: '/applications',
		method: 'PUT',
		data: application
	});

	return postAppInvite();
};
