/* eslint-disable import/no-named-as-default-member */
/* eslint-disable camelcase */
/* eslint-disable import/prefer-default-export */
import _ from 'lodash';
import { createSelector } from 'reselect';
import { ROOT_SLICE } from './constants';
import PSQL_USERS from '../Sync/PsqlUsers/selectors';

export const selectRootSlice = stateAdmin => stateAdmin[ROOT_SLICE];

export const selectUsers = stateAdmin => {
	const slice = selectRootSlice(stateAdmin);
	const { users } = slice;
	return users;
};

export const selectIsLoadingUsers = stateAdmin => {
	const slice = selectRootSlice(stateAdmin);
	const { isLoadingUsers } = slice;
	return isLoadingUsers;
};

export const selectIsLoadedUsers = stateAdmin => {
	const slice = selectRootSlice(stateAdmin);
	const { isLoadedUsers } = slice;
	return isLoadedUsers;
};

export const selectHasLoadingErrorUsers = stateAdmin => {
	const slice = selectRootSlice(stateAdmin);
	const { hasLoadingErrorUsers } = slice;
	return hasLoadingErrorUsers;
};

export const selectUsersTotalCount = stateAdmin => {
	const slice = selectRootSlice(stateAdmin);
	const { usersTotalCount } = slice;
	return usersTotalCount;
};

export const selectIsLoadedUsersCount = createSelector(
	selectUsers,
	users => (users || []).length
);

export const selectSelectedUser = stateAdmin => {
	const slice = selectRootSlice(stateAdmin);
	const { selectedUser } = slice;
	return selectedUser;
};

export const selectSelectedUserChangesClone = stateAdmin => {
	const slice = selectRootSlice(stateAdmin);
	const { selectedUserChangesClone } = slice;
	return selectedUserChangesClone;
};

export const selectIsSavingUser = stateAdmin => {
	const slice = selectRootSlice(stateAdmin);
	const { isSavingUser } = slice;
	return isSavingUser;
};

export const selectHasChanges = createSelector(
	selectSelectedUser,
	selectSelectedUserChangesClone,
	(selectedUser, selectedClone) => {
		const user = _.cloneDeep(selectedUser) || {};
		const user_hasGroupHashMap = Object.keys(user.hasGroupHashMap || {}).reduce(
			(acc, k) => {
				if (user.hasGroupHashMap[k]) {
					acc[k] = true;
				}
				return acc;
			},
			{}
		);
		delete user.hasGroupHashMap;

		const clone = _.cloneDeep(selectedClone) || {};
		const clone_hasGroupHashMap = Object.keys(
			clone.hasGroupHashMap || {}
		).reduce((acc, k) => {
			if (clone.hasGroupHashMap[k]) {
				acc[k] = true;
			}
			return acc;
		}, {});
		delete clone.hasGroupHashMap;

		return (
			user &&
			clone &&
			(!_.isEqual(user, clone) ||
				!_.isEqual(user_hasGroupHashMap, clone_hasGroupHashMap))
		);
	}
);

export const selectNewUser = stateAdmin => {
	const slice = selectRootSlice(stateAdmin);
	const { newUser } = slice;
	return newUser;
};

export const selectIsSavingNewUser = stateAdmin => {
	const slice = selectRootSlice(stateAdmin);
	const { isSavingNewUser } = slice;
	return isSavingNewUser;
};

export const selectErrorMessageSaveNewUser = stateAdmin => {
	const slice = selectRootSlice(stateAdmin);
	const { errorMessageSaveNewUser } = slice;
	return errorMessageSaveNewUser;
};

export const selectIsSavingEditedUser = stateAdmin => {
	const slice = selectRootSlice(stateAdmin);
	const { isSavingEditedUser } = slice;
	return isSavingEditedUser;
};

export const selectErrorMessageSaveEditedUser = stateAdmin => {
	const slice = selectRootSlice(stateAdmin);
	const { errorMessageSaveEditedUser } = slice;
	return errorMessageSaveEditedUser;
};

export const selectIsDeletingUser = stateAdmin => {
	const slice = selectRootSlice(stateAdmin);
	const { isDeletingUser } = slice;
	return isDeletingUser;
};

export const selectErrorMessageDeleteUser = stateAdmin => {
	const slice = selectRootSlice(stateAdmin);
	const { errorMessageDeleteUser } = slice;
	return errorMessageDeleteUser;
};

export const selectShowConfirmation = stateAdmin => {
	const slice = selectRootSlice(stateAdmin);
	const { showConfirmation } = slice;
	return showConfirmation;
};

export const selectConfirmationMessageKey = stateAdmin => {
	const slice = selectRootSlice(stateAdmin);
	const { confirmationMessageKey } = slice;
	return confirmationMessageKey;
};
export const selectUserIsInPsql = (stateAdmin, { username = '' } = {}) => {
	const boolMap = PSQL_USERS.selectBoolMap(stateAdmin);
	return boolMap[username];
};

export const selectIsOutOfSync = (stateAdmin, { username = '' } = {}) => {
	return !selectUserIsInPsql(stateAdmin, { username });
};
