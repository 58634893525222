import {
	SET_MANHOLES,
	SET_SEWER_LINES,
	SET_SELECTED_MANHOLES,
	SET_TRACKER,
	SET_CENTER
} from '../actions/maps';

const defaultState = {
	center: [
		(28.298759952209508 - 28.298734916701978) / 2 + 28.298759952209508,
		(-80.610522296458086 - -80.611505584651951) / 2 + -80.611505584651951
	],
	level: 20,
	manholes: undefined,
	sewerLines: undefined,
	selectedManholes: undefined,
	tracker: undefined
};

const reducer = (state = defaultState, action) => {
	const { type, payload } = action;
	switch (type) {
		case SET_CENTER:
			return { ...state, center: payload };
		case SET_MANHOLES:
			return { ...state, manholes: payload };
		case SET_SEWER_LINES:
			return { ...state, sewerLines: payload };
		case SET_SELECTED_MANHOLES:
			return {
				...state,
				selectedManholes: { ...state.selectedManholes, ...payload }
			};
		case SET_TRACKER:
			return { ...state, tracker: payload };
		default:
			return state;
	}
};
export default reducer;
