export default [
	{
		username: 'mrodriguez@aegion.com',
		userStatus: 'CONFIRMED',
		userCreateDate: '2019-12-18T14:50:15.397Z',
		userLastModifiedDate: '2020-07-27T22:29:55.126Z',
		enabled: true,
		sub: '5f548d04-08d2-4511-8734-6e7f7c3c6c5c',
		emailVerified: 'true',
		phoneNumberVerified: 'true',
		phoneNumber: '+17132045178',
		givenName: 'Marlane',
		familyName: 'Rodriguez',
		email: 'mrodriguez@aegion.com',
		customer: 'Aegion'
	},
	{
		username: 'gstreit@aegion.com',
		userStatus: 'FORCE_CHANGE_PASSWORD',
		userCreateDate: '2021-10-12T19:02:34.877Z',
		userLastModifiedDate: '2021-10-12T19:02:34.877Z',
		enabled: true,
		sub: 'e97e04f0-c6a5-49a4-8e41-0c8387cf0a45',
		emailVerified: 'True',
		email: 'gstreit@aegion.com',
		customer: 'Aegion'
	},
	{
		username: 'bkerley@aegion.com',
		userStatus: 'FORCE_CHANGE_PASSWORD',
		userCreateDate: '2020-06-25T19:11:44.361Z',
		userLastModifiedDate: '2020-07-27T22:29:55.098Z',
		enabled: true,
		sub: '1efbb448-e5f1-4b9a-b375-9c4091ca5a02',
		emailVerified: 'True',
		email: 'bkerley@aegion.com',
		customer: 'Aegion'
	},
	{
		username: 'cnorthrop@aegion.com',
		userStatus: 'CONFIRMED',
		userCreateDate: '2018-03-07T16:40:15.888Z',
		userLastModifiedDate: '2020-07-27T22:29:55.115Z',
		enabled: false,
		sub: 'b1529bf9-b033-42c3-8e47-0cd5f1c3e3df',
		emailVerified: 'true',
		phoneNumberVerified: 'false',
		givenName: 'Clayton',
		familyName: 'Northrop',
		email: 'cnorthrop@aegion.com',
		customer: 'Aegion'
	},
	{
		username: 'swalters@aegion.com',
		userStatus: 'CONFIRMED',
		userCreateDate: '2017-10-26T15:48:08.960Z',
		userLastModifiedDate: '2020-07-27T22:29:55.100Z',
		enabled: true,
		sub: '9d3504b1-3095-47d2-91ad-e60ea5add0f0',
		emailVerified: 'True',
		phoneNumberVerified: 'true',
		phoneNumber: '+18325151723',
		givenName: 'Sharon',
		familyName: 'Walters',
		email: 'swalters@aegion.com',
		customer: 'Aegion'
	},
	{
		username: 'sgoodremote@aegion.com',
		userStatus: 'CONFIRMED',
		userCreateDate: '2021-03-11T18:42:50.113Z',
		userLastModifiedDate: '2021-03-11T18:50:46.188Z',
		enabled: true,
		sub: '8e2a7680-25da-4ef5-8efb-d89c5b953926',
		emailVerified: 'True',
		phoneNumberVerified: 'false',
		phoneNumber: '+17157642209',
		givenName: 'Samantha',
		familyName: 'Goodremote',
		email: 'sgoodremote@aegion.com',
		customer: 'Aegion'
	},
	{
		username: 'aip@corrpro.com',
		userStatus: 'CONFIRMED',
		userCreateDate: '2020-01-23T22:26:45.398Z',
		userLastModifiedDate: '2020-07-27T22:29:55.258Z',
		enabled: true,
		sub: '910b4df2-8846-4135-8a73-72d086184106',
		emailVerified: 'true',
		phoneNumberVerified: 'false',
		givenName: 'Will',
		familyName: 'Barnes',
		email: 'aip@corrpro.com',
		customer: 'Aegion',
		hasGroupHashMap: {
			'Permission:Cisv.Administrator': true,
			'Permission:Cisv.System_Administrator': true
		}
	},
	{
		username: 'rholcomb@aegion.com',
		userStatus: 'CONFIRMED',
		userCreateDate: '2018-03-13T14:41:25.847Z',
		userLastModifiedDate: '2020-07-27T22:29:55.111Z',
		enabled: true,
		sub: '2fa7f595-7058-409b-bcb0-81ebe3634366',
		phoneNumberVerified: 'false',
		givenName: 'Ryan',
		familyName: 'Holcomb',
		email: 'rholcomb@aegion.com',
		customer: 'Aegion'
	},
	{
		username: 'ymasson@aegion.com',
		userStatus: 'CONFIRMED',
		userCreateDate: '2017-11-30T16:27:04.652Z',
		userLastModifiedDate: '2020-07-27T22:29:55.291Z',
		enabled: true,
		sub: 'cb6d5cae-1e7e-4cf3-a12a-c8af023dd9e9',
		emailVerified: 'true',
		phoneNumberVerified: 'true',
		phoneNumber: '+13462626781',
		givenName: 'Yesenia',
		familyName: 'Masson',
		email: 'ymasson@aegion.com',
		customer: 'Aegion'
	},
	{
		username: 'wb_aegion_admin',
		userStatus: 'CONFIRMED',
		userCreateDate: '2021-09-08T15:16:43.527Z',
		userLastModifiedDate: '2021-09-08T15:18:18.944Z',
		enabled: true,
		sub: 'a2b65cbb-e645-4a9a-82c8-4b1e0d324d3b',
		emailVerified: 'true',
		phoneNumberVerified: 'false',
		givenName: 'william',
		familyName: 'barnes',
		email: 'aip@corrpro.com',
		customer: 'Aegion'
	},
	{
		username: 'fqadar@aegion.com',
		userStatus: 'CONFIRMED',
		userCreateDate: '2019-05-31T21:23:35.201Z',
		userLastModifiedDate: '2020-07-27T22:29:55.572Z',
		enabled: true,
		sub: '315791d3-329c-47d2-9ed8-e1f272cf4172',
		emailVerified: 'True',
		phoneNumberVerified: 'false',
		phoneNumber: '+15877477965',
		givenName: 'Fazal',
		familyName: 'Jana',
		email: 'fqadar@aegion.com',
		customer: 'Aegion'
	},
	{
		username: 'wb_aegion_dp',
		userStatus: 'CONFIRMED',
		userCreateDate: '2021-09-08T15:19:58.452Z',
		userLastModifiedDate: '2021-09-08T15:20:59.505Z',
		enabled: true,
		sub: '4a307a5c-b1d2-4bb7-aab4-d505de76dbf2',
		emailVerified: 'true',
		phoneNumberVerified: 'false',
		givenName: 'willy',
		familyName: 'barnes',
		email: 'aip@corrpro.com',
		customer: 'Aegion'
	},
	{
		username: 'cpalomares425@aegion.com',
		userStatus: 'CONFIRMED',
		userCreateDate: '2017-10-26T15:46:07.778Z',
		userLastModifiedDate: '2020-07-27T22:29:55.061Z',
		enabled: true,
		sub: 'd1081b1f-2420-45d0-8278-4dc68d464cc0',
		emailVerified: 'true',
		phoneNumberVerified: 'false',
		givenName: 'Crystal',
		familyName: 'Palomares',
		email: 'cpalomares425@aegion.com',
		customer: 'Aegion'
	},
	{
		username: 'mhearn@aegion.com',
		userStatus: 'CONFIRMED',
		userCreateDate: '2017-09-12T16:54:04.318Z',
		userLastModifiedDate: '2020-07-27T22:29:55.114Z',
		enabled: true,
		sub: 'ad7a0122-4cc5-476d-a4d9-0b6b0aad7cca',
		emailVerified: 'True',
		phoneNumberVerified: 'true',
		phoneNumber: '+18322640249',
		givenName: 'Matthew',
		familyName: 'Hearn',
		email: 'mhearn@aegion.com',
		customer: 'Aegion'
	},
	{
		username: 'hhernandez@aegion.com',
		userStatus: 'FORCE_CHANGE_PASSWORD',
		userCreateDate: '2020-01-07T19:26:27.758Z',
		userLastModifiedDate: '2020-07-27T22:29:55.141Z',
		enabled: false,
		sub: '205f764e-7c8b-4c4b-8c38-df24b43305d5',
		emailVerified: 'True',
		email: 'hhernandez@aegion.com',
		customer: 'Aegion'
	},
	{
		username: 'dlindemuth@aegion.com',
		userStatus: 'FORCE_CHANGE_PASSWORD',
		userCreateDate: '2020-04-14T17:41:10.736Z',
		userLastModifiedDate: '2020-07-27T22:29:55.184Z',
		enabled: true,
		sub: 'f0f7837f-c08c-4735-98b4-00d34cd67bbf',
		emailVerified: 'true',
		email: 'dlindemuth@aegion.com',
		customer: 'Aegion'
	},
	{
		username: 'srafique@aegion.com',
		userStatus: 'CONFIRMED',
		userCreateDate: '2019-05-31T21:22:53.751Z',
		userLastModifiedDate: '2020-07-27T22:29:55.253Z',
		enabled: false,
		sub: 'e370dd23-b11e-436b-b5ce-e4da356ca572',
		emailVerified: 'True',
		phoneNumberVerified: 'true',
		phoneNumber: '+17802640882',
		givenName: 'Safwan',
		familyName: 'Rafique',
		email: 'srafique@aegion.com',
		customer: 'Aegion'
	},
	{
		username: 'eimordi@aegion.com',
		userStatus: 'CONFIRMED',
		userCreateDate: '2018-11-19T16:53:49.555Z',
		userLastModifiedDate: '2020-07-27T22:29:55.165Z',
		enabled: true,
		sub: '533b4ac9-e91f-4836-8cbf-f3306a347fa8',
		emailVerified: 'true',
		phoneNumberVerified: 'true',
		phoneNumber: '+18323927734',
		givenName: 'ERIC',
		familyName: 'IMORDI',
		email: 'eimordi@aegion.com',
		customer: 'Aegion'
	},
	{
		username: 'jgeisinger@aegion.com',
		userStatus: 'CONFIRMED',
		userCreateDate: '2018-01-04T18:11:09.819Z',
		userLastModifiedDate: '2020-07-27T22:29:55.102Z',
		enabled: true,
		sub: 'c4f327bb-d2cb-4435-9687-bb9e1e3410d5',
		emailVerified: 'true',
		phoneNumberVerified: 'false',
		givenName: 'Jared',
		familyName: 'Geisinger',
		email: 'jgeisinger@aegion.com',
		customer: 'Aegion'
	},
	{
		username: 'sarora@aegion.com',
		userStatus: 'CONFIRMED',
		userCreateDate: '2017-05-01T19:27:45.897Z',
		userLastModifiedDate: '2021-08-24T19:35:29.962Z',
		enabled: true,
		sub: '62d3320b-f4b0-4929-af29-6a5573119793',
		emailVerified: 'true',
		phoneNumberVerified: 'true',
		phoneNumber: '+13462406898',
		givenName: 'Shweta',
		familyName: 'Arora',
		email: 'sarora@aegion.com',
		customer: 'Aegion'
	},
	{
		username: 'hwernstrom@itnexus.com',
		userStatus: 'CONFIRMED',
		userCreateDate: '2020-08-28T18:55:48.120Z',
		userLastModifiedDate: '2021-05-10T21:24:31.441Z',
		enabled: true,
		sub: '4ef68fc1-88bc-4e00-a682-af54688c3ecd',
		emailVerified: 'true',
		'custom:correlatorAreas':
			'["CA-Calgary","CA-Edmonton","CA-FortStJohn","CA-Lloydminster","CA-Toronto","US-Atlanta","US-Chicago","US-Farmington","US-Hayward","US-Houston","US-LosAngeles","US-Malvern","US-Medina","US-NewOrleans","US-SanDiego","US-Tulsa"]',
		phoneNumberVerified: 'true',
		phoneNumber: '+18014719660',
		'custom:correlatorUsername': 'hwernstrom',
		givenName: 'Hyrum',
		familyName: 'Ernstrom',
		email: 'hwernstrom@itnexus.com',
		customer: 'Aegion'
	},
	{
		username: 'bsanderson@aegion.com',
		userStatus: 'CONFIRMED',
		userCreateDate: '2021-05-10T21:33:02.195Z',
		userLastModifiedDate: '2021-07-06T17:39:40.997Z',
		enabled: true,
		sub: '4dd626f3-8e2d-492c-bf14-49e8a45a5562',
		emailVerified: 'true',
		phoneNumberVerified: 'false',
		phoneNumber: '+17135045224',
		givenName: 'Bruce',
		familyName: 'Sanderson',
		email: 'bsanderson@aegion.com',
		customer: 'Aegion'
	},
	{
		username: 'jgravett@aegion.com',
		userStatus: 'CONFIRMED',
		userCreateDate: '2020-01-31T15:27:34.540Z',
		userLastModifiedDate: '2020-07-27T22:29:55.094Z',
		enabled: true,
		sub: 'ebf887e5-f4a9-43ac-b71e-4ebe51139f85',
		emailVerified: 'true',
		phoneNumberVerified: 'false',
		givenName: 'Jeff',
		familyName: 'Gravett',
		email: 'jgravett@aegion.com',
		customer: 'Aegion'
	},
	{
		username: 'gstephens@aegion.com',
		userStatus: 'CONFIRMED',
		userCreateDate: '2018-04-09T17:52:34.346Z',
		userLastModifiedDate: '2020-07-28T15:51:34.151Z',
		enabled: true,
		sub: 'f2b2575e-40bc-4d61-8fc5-cf4595733a55',
		emailVerified: 'true',
		phoneNumberVerified: 'false',
		givenName: 'Gerald',
		familyName: 'Stephens',
		email: 'gstephens@aegion.com',
		customer: 'Aegion'
	},
	{
		username: 'dmawhinney@aegion.com',
		userStatus: 'FORCE_CHANGE_PASSWORD',
		userCreateDate: '2021-06-11T11:19:44.167Z',
		userLastModifiedDate: '2021-06-11T11:19:44.167Z',
		enabled: true,
		sub: '780bf35f-ba35-46ea-803b-9eec3cfffd30',
		emailVerified: 'true',
		email: 'dmawhinney@aegion.com',
		customer: 'Aegion'
	},
	{
		username: 'ewilliams@aegion.com',
		userStatus: 'CONFIRMED',
		userCreateDate: '2018-03-02T16:25:25.676Z',
		userLastModifiedDate: '2020-07-27T22:29:55.587Z',
		enabled: true,
		sub: 'cabe4a02-5ad2-4669-a886-785207d152ba',
		emailVerified: 'true',
		phoneNumberVerified: 'false',
		phoneNumber: '+17134606029',
		givenName: 'Edward',
		familyName: 'Williams',
		email: 'ewilliams@aegion.com',
		customer: 'Aegion'
	},
	{
		username: 'mvanegmond@aegion.com',
		userStatus: 'CONFIRMED',
		userCreateDate: '2021-10-08T18:00:50.037Z',
		userLastModifiedDate: '2022-02-09T15:31:51.441Z',
		enabled: true,
		sub: 'f6983692-b1e8-46ca-9623-a18d9c45113e',
		emailVerified: 'true',
		phoneNumberVerified: 'false',
		givenName: 'Maurice',
		familyName: 'van Egmond',
		email: 'mvanegmond@aegion.com',
		customer: 'Aegion'
	},
	{
		username: 'jherrera@aegion.com',
		userStatus: 'CONFIRMED',
		userCreateDate: '2018-01-23T17:05:23.941Z',
		userLastModifiedDate: '2020-07-27T22:29:55.162Z',
		enabled: true,
		sub: '75a70ce1-ea2f-4ffa-8d39-fb0a229e25db',
		emailVerified: 'True',
		phoneNumberVerified: 'true',
		phoneNumber: '+18327685040',
		givenName: 'Juan',
		familyName: 'Herrera',
		email: 'jherrera@aegion.com',
		customer: 'Aegion'
	},
	{
		username: 'kfatunde@aegion.com',
		userStatus: 'CONFIRMED',
		userCreateDate: '2019-09-09T21:59:08.995Z',
		userLastModifiedDate: '2021-07-21T20:23:53.791Z',
		enabled: true,
		sub: '99422915-e9f2-4160-910d-889e7056fd59',
		emailVerified: 'true',
		'custom:correlatorAreas':
			'["CA-Calgary","CA-Edmonton","CA-FortStJohn","CA-Lloydminster","UK-All","US-Atlanta","US-Chicago","US-Farmington","US-Hayward","US-Houston","US-LosAngeles","US-Malvern","US-Medina","US-NewOrleans","US-SanDiego","US-Tulsa"]',
		phoneNumberVerified: 'true',
		phoneNumber: '+19512364200',
		'custom:correlatorUsername': 'kfatunde',
		givenName: 'Keji',
		familyName: 'Fatunde',
		email: 'kfatunde@aegion.com',
		customer: 'Aegion'
	},
	{
		username: 'cdauzat@aegion.com',
		userStatus: 'CONFIRMED',
		userCreateDate: '2018-03-19T18:32:54.388Z',
		userLastModifiedDate: '2020-07-27T22:29:55.553Z',
		enabled: true,
		sub: '12733fe2-828d-457a-b7fb-acfca1496087',
		emailVerified: 'true',
		phoneNumberVerified: 'false',
		givenName: 'Chris',
		familyName: 'Dauzat',
		email: 'cdauzat@aegion.com',
		customer: 'Aegion'
	},
	{
		username: 'hwernstrom',
		userStatus: 'CONFIRMED',
		userCreateDate: '2020-09-02T21:03:55.487Z',
		userLastModifiedDate: '2020-09-15T19:48:52.416Z',
		enabled: true,
		sub: '84882d25-dcaa-45d4-93c7-4f16078403a0',
		emailVerified: 'true',
		'custom:correlatorAreas':
			'["CA-Calgary","CA-FortStJohn","CA-Lloydminster","US-Atlanta","US-Farmington","US-Hayward","US-Houston","US-LosAngeles","US-Malvern","US-Medina","US-NewOrleans","US-SanDiego","US-Tulsa","CA-Toronto","CA-Edmonton","US-Chicago"]',
		profile: 'IT Nexus',
		phoneNumber: '+18014719660',
		'custom:correlatorUsername': 'hwernstrom',
		givenName: 'Hyrum',
		familyName: 'Ernstrom',
		email: 'hyrumernstrom@gmail.com',
		customer: 'Aegion'
	},
	{
		username: 'eminjarez@aegion.com',
		userStatus: 'CONFIRMED',
		userCreateDate: '2021-02-01T20:46:29.335Z',
		userLastModifiedDate: '2021-02-01T22:24:42.722Z',
		enabled: true,
		sub: '715d557b-c3a9-43f1-b839-56f1c81fc40b',
		emailVerified: 'true',
		phoneNumberVerified: 'false',
		phoneNumber: '+12819600661',
		givenName: 'Elizabeth ',
		familyName: 'Minjarez',
		email: 'eminjarez@aegion.com',
		customer: 'Aegion'
	},
	{
		username: 'tcompton@aegion.com',
		userStatus: 'CONFIRMED',
		userCreateDate: '2017-07-20T14:07:24.923Z',
		userLastModifiedDate: '2020-07-27T22:29:55.275Z',
		enabled: true,
		sub: 'c738751b-a48b-4639-8878-ac77e1d9e603',
		emailVerified: 'True',
		phoneNumberVerified: 'true',
		phoneNumber: '+19709462001',
		givenName: 'Ted',
		familyName: 'Compton',
		email: 'tcompton@aegion.com',
		customer: 'Aegion'
	},
	{
		username: 'nvijayasankaran@aegion.com',
		userStatus: 'CONFIRMED',
		userCreateDate: '2017-06-13T15:51:28.492Z',
		userLastModifiedDate: '2022-03-04T14:23:14.150Z',
		enabled: true,
		sub: '1d8aea41-c8fa-4797-bcb9-e1ddb877880a',
		emailVerified: 'true',
		phoneNumberVerified: 'false',
		givenName: 'Naga',
		familyName: 'Vijayasankaran',
		email: 'nvijayasankaran@aegion.com',
		customer: 'Aegion'
	},
	{
		username: 'pseudoken@gmail.com',
		userStatus: 'CONFIRMED',
		userCreateDate: '2017-08-23T20:48:40.605Z',
		userLastModifiedDate: '2020-07-27T22:29:55.179Z',
		enabled: true,
		sub: '3c9ca1b8-9293-4f96-b5c4-43659a29a51f',
		emailVerified: 'True',
		phoneNumberVerified: 'true',
		phoneNumber: '+12813015361',
		givenName: 'Ken',
		familyName: 'Dellinger',
		email: 'pseudoken@gmail.com',
		customer: 'Aegion'
	},
	{
		username: 'jgarniss@aegion.com',
		userStatus: 'CONFIRMED',
		userCreateDate: '2019-08-29T22:02:10.918Z',
		userLastModifiedDate: '2020-07-27T22:29:55.180Z',
		enabled: true,
		sub: 'c3d865ac-a6b3-47fb-ab92-a71385e15493',
		emailVerified: 'true',
		phoneNumberVerified: 'false',
		phoneNumber: '+17803061042',
		givenName: 'Jeff',
		familyName: 'Garniss',
		email: 'jgarniss@aegion.com',
		customer: 'Aegion'
	},
	{
		username: 'jcorona@aegion.com',
		userStatus: 'CONFIRMED',
		userCreateDate: '2018-03-05T16:28:11.939Z',
		userLastModifiedDate: '2020-07-27T22:29:55.143Z',
		enabled: false,
		sub: '4f13a927-3416-4eb0-a828-1bf76df2ee25',
		emailVerified: 'True',
		phoneNumberVerified: 'true',
		phoneNumber: '+15628432481',
		givenName: 'Jesus',
		familyName: 'Corona',
		email: 'jcorona@aegion.com',
		customer: 'Aegion'
	},
	{
		username: 'rchumchal@aegion.com',
		userStatus: 'CONFIRMED',
		userCreateDate: '2018-03-13T18:51:18.406Z',
		userLastModifiedDate: '2020-07-27T22:29:55.561Z',
		enabled: true,
		sub: 'e8050805-efff-466d-9a60-228cd8242c55',
		emailVerified: 'true',
		phoneNumberVerified: 'false',
		givenName: 'Ross',
		familyName: 'Chumchal',
		email: 'rchumchal@aegion.com',
		customer: 'Aegion'
	},
	{
		username: 'mspeights@aegion.com',
		userStatus: 'CONFIRMED',
		userCreateDate: '2018-01-08T17:05:03.298Z',
		userLastModifiedDate: '2020-07-27T22:29:55.141Z',
		enabled: true,
		sub: 'a1e3de4e-b6ed-443e-932e-10555c5dfd7b',
		emailVerified: 'true',
		phoneNumberVerified: 'true',
		phoneNumber: '+12817708790',
		givenName: 'Matthew ',
		familyName: 'Speights ',
		email: 'mspeights@aegion.com',
		customer: 'Aegion'
	},
	{
		username: 'nparker@aegion.com',
		userStatus: 'FORCE_CHANGE_PASSWORD',
		userCreateDate: '2017-10-26T15:50:32.475Z',
		userLastModifiedDate: '2020-07-27T22:29:55.107Z',
		enabled: false,
		sub: 'ffc4fff0-164b-4ae9-94d6-4dcc7a5ca6ad',
		emailVerified: 'True',
		email: 'nparker@aegion.com',
		customer: 'Aegion'
	},
	{
		username: 'jvafaei@aegion.com',
		userStatus: 'CONFIRMED',
		userCreateDate: '2019-02-12T18:37:34.897Z',
		userLastModifiedDate: '2020-07-27T22:29:55.176Z',
		enabled: true,
		sub: '24ba9d14-b18d-4a17-9ca2-846c7ea258e6',
		emailVerified: 'true',
		phoneNumberVerified: 'true',
		phoneNumber: '+15873376386',
		givenName: 'James',
		familyName: 'Vafaei',
		email: 'jvafaei@aegion.com',
		customer: 'Aegion'
	},
	{
		username: 'nbortolussi@aegion.com',
		userStatus: 'CONFIRMED',
		userCreateDate: '2018-05-25T14:31:59.415Z',
		userLastModifiedDate: '2020-07-27T22:29:55.617Z',
		enabled: true,
		sub: 'f147f412-a683-4f04-a49f-020383aa57cb',
		emailVerified: 'True',
		phoneNumberVerified: 'false',
		givenName: 'Niccole',
		familyName: 'Bortolussi',
		email: 'nbortolussi@aegion.com',
		customer: 'Aegion'
	},
	{
		username: 'NA',
		userStatus: 'FORCE_CHANGE_PASSWORD',
		userCreateDate: '2019-03-19T17:28:40.756Z',
		userLastModifiedDate: '2020-07-27T22:29:55.086Z',
		enabled: true,
		sub: 'bbd9c904-4317-48ae-a948-6828e748c8bc',
		customer: 'Aegion'
	},
	{
		username: 'hernstrom@aegion.com',
		userStatus: 'CONFIRMED',
		userCreateDate: '2020-09-21T21:41:56.873Z',
		userLastModifiedDate: '2020-09-21T21:42:18.507Z',
		enabled: true,
		sub: 'a3cd10b0-9efc-4d82-8a21-e6714d7f1210',
		emailVerified: 'true',
		email: 'hernstrom@aegion.com',
		customer: 'Aegion'
	},
	{
		username: 'bnejad@aegion.com',
		userStatus: 'CONFIRMED',
		userCreateDate: '2020-01-07T19:24:54.822Z',
		userLastModifiedDate: '2020-07-27T22:29:55.219Z',
		enabled: true,
		sub: '605ac16f-cc48-4a4b-b0cf-3988f7f9ecb9',
		emailVerified: 'True',
		phoneNumberVerified: 'false',
		email: 'bnejad@aegion.com',
		customer: 'Aegion'
	},
	{
		username: 'mtaylor@aegion.com',
		userStatus: 'CONFIRMED',
		userCreateDate: '2018-01-10T19:12:35.948Z',
		userLastModifiedDate: '2020-07-27T22:29:55.604Z',
		enabled: true,
		sub: 'd9ff958b-cea2-4ba0-a214-c802e513f81a',
		emailVerified: 'True',
		phoneNumberVerified: 'true',
		phoneNumber: '+17138547985',
		givenName: 'Mark',
		familyName: 'Taylor',
		email: 'mtaylor@aegion.com',
		customer: 'Aegion'
	},
	{
		username: 'mwegleitner@aegion.com',
		userStatus: 'CONFIRMED',
		userCreateDate: '2019-07-31T20:15:15.349Z',
		userLastModifiedDate: '2020-07-27T22:29:55.115Z',
		enabled: false,
		sub: 'f067a793-7489-4050-b182-98815718ab3e',
		emailVerified: 'true',
		phoneNumberVerified: 'true',
		phoneNumber: '+12503295666',
		givenName: 'Marvin',
		familyName: 'Wegleitner',
		email: 'mwegleitner@aegion.com',
		customer: 'Aegion'
	},
	{
		username: 'mzebley@aegion.com',
		userStatus: 'CONFIRMED',
		userCreateDate: '2020-08-11T20:12:59.542Z',
		userLastModifiedDate: '2020-08-12T11:38:40.063Z',
		enabled: true,
		sub: 'a53c4fc1-40ce-4cb1-9f6f-97cae94547c7',
		emailVerified: 'True',
		email: 'mzebley@aegion.com',
		customer: 'Aegion'
	},
	{
		username: 'amiller@aegion.com',
		userStatus: 'CONFIRMED',
		userCreateDate: '2020-10-14T04:40:26.141Z',
		userLastModifiedDate: '2020-10-14T14:11:55.209Z',
		enabled: true,
		sub: 'b769f760-130b-46d9-b228-98865f651bfc',
		emailVerified: 'true',
		email: 'amiller@aegion.com',
		customer: 'Aegion'
	},
	{
		username: 'rmorgan@aegion.com',
		userStatus: 'CONFIRMED',
		userCreateDate: '2018-01-10T19:12:55.624Z',
		userLastModifiedDate: '2020-07-27T22:29:55.086Z',
		enabled: true,
		sub: '3fc9d47b-e0bf-46a5-afdd-7d524787cc19',
		emailVerified: 'True',
		phoneNumberVerified: 'true',
		phoneNumber: '+12816351321',
		givenName: 'Mark',
		familyName: 'Morgan',
		email: 'rmorgan@aegion.com',
		customer: 'Aegion'
	},
	{
		username: 'komer@aegion.com',
		userStatus: 'FORCE_CHANGE_PASSWORD',
		userCreateDate: '2021-07-27T11:15:43.347Z',
		userLastModifiedDate: '2021-07-27T11:15:43.347Z',
		enabled: true,
		sub: '82baa624-b300-426d-86f9-a40d1a490a04',
		email: 'komer@aegion.com',
		customer: 'Aegion'
	},
	{
		username: 'struckner@aegion.com',
		userStatus: 'CONFIRMED',
		userCreateDate: '2017-11-30T16:28:17.449Z',
		userLastModifiedDate: '2020-07-27T22:29:55.124Z',
		enabled: true,
		sub: '79b7dd47-cc3d-4680-826a-7d900a9dbda1',
		emailVerified: 'true',
		phoneNumberVerified: 'true',
		phoneNumber: '+17137750445',
		givenName: 'Shelley',
		familyName: 'Truckner',
		email: 'struckner@aegion.com',
		customer: 'Aegion'
	},
	{
		username: 'adelosreyes@aegion.com',
		userStatus: 'CONFIRMED',
		userCreateDate: '2021-10-08T14:22:40.484Z',
		userLastModifiedDate: '2021-10-08T14:24:34.462Z',
		enabled: true,
		sub: '2248f4b0-dd86-4630-83b3-57f29c219a24',
		emailVerified: 'true',
		phoneNumberVerified: 'false',
		givenName: 'Adolfo De Los',
		familyName: 'Reyes',
		email: 'adelosreyes@aegion.com',
		customer: 'Aegion'
	},
	{
		username: 'sarora1',
		userStatus: 'FORCE_CHANGE_PASSWORD',
		userCreateDate: '2021-09-08T14:48:05.954Z',
		userLastModifiedDate: '2021-09-08T14:48:05.954Z',
		enabled: true,
		sub: '9703c85f-0869-43dd-8805-567379ed8e27',
		emailVerified: 'true',
		email: 'sarora@aegion.com',
		customer: 'Aegion'
	},
	{
		username: 'jhernandez@aegion.com',
		userStatus: 'CONFIRMED',
		userCreateDate: '2018-01-22T15:18:51.884Z',
		userLastModifiedDate: '2020-07-28T15:51:21.881Z',
		enabled: true,
		sub: '5a947ece-88ef-49e7-b558-e749dd428b91',
		emailVerified: 'true',
		phoneNumberVerified: 'false',
		givenName: 'Jose',
		familyName: 'Hernandez',
		email: 'jhernandez@aegion.com',
		customer: 'Aegion'
	},
	{
		username: 'hyrum',
		userStatus: 'CONFIRMED',
		userCreateDate: '2021-09-08T14:57:27.518Z',
		userLastModifiedDate: '2021-09-08T14:59:26.278Z',
		enabled: true,
		sub: '2a04c494-fe3f-4cf9-ba93-ea8b1c2849c1',
		emailVerified: 'true',
		email: 'hwernstrom@itnexus.com',
		customer: 'Aegion'
	},
	{
		username: 'aip@corrpro.com',
		userStatus: 'FORCE_CHANGE_PASSWORD',
		userCreateDate: '2021-06-11T11:20:48.816Z',
		userLastModifiedDate: '2021-06-11T11:20:48.816Z',
		enabled: true,
		sub: '626e51f3-8916-4bc5-8c30-ef969063a0be',
		emailVerified: 'true',
		email: 'aip@corrpro.com',
		customer: 'Aegion'
	},
	{
		username: 'knevils@aegion.com',
		userStatus: 'CONFIRMED',
		userCreateDate: '2019-08-06T20:43:36.710Z',
		userLastModifiedDate: '2020-07-27T22:29:55.090Z',
		enabled: true,
		sub: 'ff9065b2-cf9f-4703-8bd0-f4618788c5de',
		emailVerified: 'true',
		email: 'knevils@aegion.com',
		customer: 'Aegion'
	},
	{
		username: 'ngordon@aegion.com',
		userStatus: 'FORCE_CHANGE_PASSWORD',
		userCreateDate: '2019-09-11T15:42:33.306Z',
		userLastModifiedDate: '2020-07-27T22:29:55.087Z',
		enabled: true,
		sub: '0aebd807-40c0-469f-bd73-11409edd47e1',
		customer: 'Aegion'
	},
	{
		username: 'spadden@aegion.com',
		userStatus: 'FORCE_CHANGE_PASSWORD',
		userCreateDate: '2018-05-24T17:11:19.959Z',
		userLastModifiedDate: '2020-07-27T22:29:55.149Z',
		enabled: true,
		sub: '73fa5181-589a-48a7-92a7-f6b131f5c238',
		emailVerified: 'true',
		email: 'spadden@aegion.com',
		customer: 'Aegion'
	},
	{
		username: 'AMHammoud@Corrpro.com',
		userStatus: 'FORCE_CHANGE_PASSWORD',
		userCreateDate: '2020-03-11T14:40:50.732Z',
		userLastModifiedDate: '2020-07-27T22:29:55.015Z',
		enabled: true,
		sub: '63493603-3143-41a2-8158-755490c76b6a',
		customer: 'Aegion'
	},
	{
		username: 'wb_aegion_pm',
		userStatus: 'CONFIRMED',
		userCreateDate: '2021-09-08T15:02:11.431Z',
		userLastModifiedDate: '2021-09-08T15:05:33.507Z',
		enabled: true,
		sub: 'c85516c0-ec9c-4341-a2de-560830ec67ac',
		emailVerified: 'true',
		phoneNumberVerified: 'false',
		givenName: 'liam',
		familyName: 'barnes',
		email: 'wbarnes@aegion.com',
		customer: 'Aegion'
	},
	{
		username: 'cphan@aegion.com',
		userStatus: 'CONFIRMED',
		userCreateDate: '2019-07-19T18:29:34.380Z',
		userLastModifiedDate: '2020-07-27T22:29:55.175Z',
		enabled: true,
		sub: '96f12cae-3152-4d65-8ea8-ab64aefbf783',
		emailVerified: 'true',
		phoneNumberVerified: 'true',
		phoneNumber: '+15877787692',
		givenName: 'Calvin',
		familyName: 'Phan',
		email: 'cphan@aegion.com',
		customer: 'Aegion'
	},
	{
		username: 'adrian_t@unitedpipeline.com.mx',
		userStatus: 'CONFIRMED',
		userCreateDate: '2018-03-15T13:32:57.799Z',
		userLastModifiedDate: '2020-07-27T22:29:55.068Z',
		enabled: false,
		sub: '6ea3f3b8-d814-4f5c-a70d-a2ca3c7f3b92',
		emailVerified: 'true',
		phoneNumberVerified: 'false',
		givenName: 'Adrian',
		familyName: 'Trejo',
		email: 'adrian_t@unitedpipeline.com.mx',
		customer: 'Aegion'
	}
];
