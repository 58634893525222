/* eslint-disable import/no-cycle */
/* eslint-disable import/prefer-default-export */

import scanlineMapUtils from '../../../../../../mapUtils';
import { getSurveyIdFromChartId } from '../../../../../../utils/chart';
import { selectMyReadingDataStructures } from '../../../../../selectors/alignedReadings';
import { selectBoundaryXMinXMax } from '../../../../../selectors/boundaries';
import { selectMyDepolReadingDataStructures } from '../../../../../selectors/depolReadings';
import { selectClosestReadingBySurveyId } from '../../../../../selectors/readings';
import {
	selectDerivedSurvey,
	selectSurvey
} from '../../../../../selectors/surveys';
import { selectAppZoomXMinMax } from '../../../../StoreXYMinMax/selectors';
import { selectChartIds } from '../../../Main/selectors';
import { thunkSetChartShowDecimatedData } from './thunks';
import { computeShowDecimatedData } from './util';

const EMPTY_OBJECT = {};

const isDefined = v => v !== undefined && v !== null;

const getStartReading = (surveyId, readings, xMin, xMax, stateApp) => {
	if (!readings || readings.length === 0) {
		return undefined;
	}
	const firstReading = readings[0];
	const lastReading = readings[readings.length - 1];
	if (xMin > lastReading.id) {
		return undefined;
	}
	if (xMax < firstReading.id) {
		return undefined;
	}
	if (xMin < firstReading.id) {
		return firstReading;
	}
	return selectClosestReadingBySurveyId(stateApp, surveyId, xMin, 50);
};

const getEndReading = (surveyId, readings, xMin, xMax, stateApp) => {
	if (!readings || readings.length === 0) {
		return undefined;
	}
	const firstReading = readings[0];
	const lastReading = readings[readings.length - 1];
	if (xMin > lastReading.id) {
		return undefined;
	}
	if (xMax < firstReading.id) {
		return undefined;
	}
	if (xMax > lastReading.id) {
		return lastReading;
	}
	return selectClosestReadingBySurveyId(stateApp, surveyId, xMax, 50);
};

const _createReadingsMeta = (
	surveyId,
	dataStructures,
	xMin,
	xMax,
	stateApp
) => {
	const {
		readings,
		readingsIndexMap,
		depolReadings,
		depolReadingsIndexMap
	} = dataStructures;

	const startReading = getStartReading(
		surveyId,
		readings || depolReadings,
		xMin,
		xMax,
		stateApp
	);
	const endReading = getEndReading(
		surveyId,
		readings || depolReadings,
		xMin,
		xMax,
		stateApp
	);

	return {
		readings: readings || depolReadings,
		readingsIndexMap: readingsIndexMap || depolReadingsIndexMap,
		startReading,
		endReading
	};
};

const _selectReadingsMetas = (chartId, xMin, xMax, stateApp) => {
	const surveyId = getSurveyIdFromChartId(chartId);
	const survey = selectSurvey(stateApp, surveyId);
	const surveyMeta = scanlineMapUtils.getSurveyMeta(survey);

	const depolSurvey = surveyMeta.canHaveCompositeDepol
		? selectDerivedSurvey(stateApp, survey, 'DEPOL')
		: undefined;

	const depolId = depolSurvey
		? scanlineMapUtils.createSurveyId(
				survey.survey_type,
				'DEPOL',
				survey.job_id
		  )
		: undefined;

	const readingDataStructures = selectMyReadingDataStructures(
		survey,
		stateApp,
		true
	);

	const depolReadingDataStructures = depolSurvey
		? selectMyDepolReadingDataStructures(stateApp, survey, depolId)
		: EMPTY_OBJECT;

	const readingsMetas = [
		_createReadingsMeta(surveyId, readingDataStructures, xMin, xMax, stateApp)
	];
	if (depolSurvey) {
		readingsMetas.push(
			_createReadingsMeta(
				depolId,
				depolReadingDataStructures,
				xMin,
				xMax,
				stateApp
			)
		);
	}

	return readingsMetas.filter(rm => rm.startReading && rm.endReading);
};

const firstDefined = arr => arr.filter(v => isDefined(v))[0];

export const thunkComputeAllShowDecimatedData = (paramXMin, paramXMax) => (
	dispatch,
	getState
) => {
	const { xMin: currXMin, xMax: currXMax } =
		selectBoundaryXMinXMax(getState().app) || {};
	const { xMin: currZoomXMin, xMax: currZoomXMax } =
		selectAppZoomXMinMax(getState().app) || {};
	const xMin = firstDefined([paramXMin, currZoomXMin, currXMin]);
	const xMax = firstDefined([paramXMax, currZoomXMax, currXMax]);
	if (isDefined(xMin) && isDefined(xMax)) {
		const chartIds = selectChartIds(getState().app);
		const readingsMetasByCID = chartIds.reduce((acc, chartId) => {
			acc[chartId] = _selectReadingsMetas(chartId, xMin, xMax, getState().app);
			return acc;
		}, {});
		chartIds.forEach(cid => {
			const showDecimatedData = computeShowDecimatedData(
				readingsMetasByCID[cid],
				cid
			);
			dispatch(thunkSetChartShowDecimatedData(cid, showDecimatedData));
		});
	}
};
