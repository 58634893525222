/* eslint-disable camelcase */
import _ from 'lodash';

import { initialState } from './initialState';

// ************
// PARTS
// ************
export const buildReducerParts = (
	{
		reInitStateProcessors,
		requestProcessors,
		receiveProcessors,
		clearSelectedProcessors,
		setSelectedProcessors,
		addSelectedProcessor,
		removeSelectedProcessor,
		setLoadProcessorsErrorMessage,
		clearLoadProcessorsErrorMessage
	},
	filtersStatePropKey,
	filterStatePropKey = 'processors'
) => {
	const KEY_filters = filtersStatePropKey;
	const KEY_filter = filterStatePropKey;

	return {
		[reInitStateProcessors]: state => {
			return {
				...state,
				[KEY_filters]: {
					...state[KEY_filters],
					[KEY_filter]: { ...initialState }
				}
			};
		},

		[requestProcessors]: state => {
			return {
				...state,
				[KEY_filters]: {
					...state[KEY_filters],
					[KEY_filter]: {
						...state[KEY_filters][KEY_filter],
						isLoading: true,
						loadErrorMessage: undefined
					}
				}
			};
		},

		[receiveProcessors]: (state, { payload: { list } }) => {
			const newList = [...state[KEY_filters][KEY_filter].list, ...list];
			return {
				...state,
				[KEY_filters]: {
					...state[KEY_filters],
					[KEY_filter]: {
						...state[KEY_filters][KEY_filter],
						list: newList,
						listCount: newList.length,
						isLoading: false,
						isLoaded: true
					}
				}
			};
		},

		[clearSelectedProcessors]: state => {
			return {
				...state,
				[KEY_filters]: {
					...state[KEY_filters],
					[KEY_filter]: {
						...state[KEY_filters][KEY_filter],
						selected: [],
						selectedCount: 0
					}
				}
			};
		},

		[setSelectedProcessors]: (state, { payload: { selectedList = [] } }) => {
			return {
				...state,
				[KEY_filters]: {
					...state[KEY_filters],
					[KEY_filter]: {
						...state[KEY_filters][KEY_filter],
						selected: selectedList,
						selectedCount: selectedList.length
					}
				}
			};
		},

		[addSelectedProcessor]: (state, { payload: { item } }) => {
			const newSelectedList = _.union(state[KEY_filters][KEY_filter].selected, [
				item
			]);

			return {
				...state,
				[KEY_filters]: {
					...state[KEY_filters],
					[KEY_filter]: {
						...state[KEY_filters][KEY_filter],
						selected: newSelectedList,
						selectedCount: newSelectedList.length
					}
				}
			};
		},

		[removeSelectedProcessor]: (state, { payload: { item } }) => {
			const newSelectedList = _.without(
				state[KEY_filters][KEY_filter].selected,
				item
			);
			return {
				...state,
				[KEY_filters]: {
					...state[KEY_filters],
					[KEY_filter]: {
						...state[KEY_filters][KEY_filter],
						selected: newSelectedList,
						selectedCount: newSelectedList.length
					}
				}
			};
		},

		[setLoadProcessorsErrorMessage]: (state, { payload: { message } }) => {
			return {
				...state,
				[KEY_filters]: {
					...state[KEY_filters],
					[KEY_filter]: {
						...state[KEY_filters][KEY_filter],
						loadErrorMessage: message
					}
				}
			};
		},

		[clearLoadProcessorsErrorMessage]: state => {
			return {
				...state,
				[KEY_filters]: {
					...state[KEY_filters],
					[KEY_filter]: {
						...state[KEY_filters][KEY_filter],
						loadErrorMessage: undefined
					}
				}
			};
		}
	};
};

export default {
	buildReducerParts
};
