/* eslint-disable import/no-cycle */
import Sockette from 'sockette';
import getAipUrls from '../../config/getAipUrls';
import {
	selectWsMessage,
	isWsErrorMessage
} from '../../commons/util/aip-tools/messageUtil.ws';

import AccountUtils from '../../account/components/utils/AccountUtil';
import { isTokenExpired } from '../../util/user';
import { getAccessToken } from '../utils/token';

const IMPORT_ACTION = 'importFile';

const isConnectionOpenedMessage = e => {
	const { data } = e;
	return data === 'Message Received';
};

const isInternalErrorMessage = e => {
	const { data } = e;
	return data.includes('"message": "Internal server error"');
};

const ignoreMessage = e =>
	isConnectionOpenedMessage(e) || isInternalErrorMessage(e);

const selectTokenFromMessage = (error, result) => {
	const { requestToken } = error || result;
	return requestToken;
};

const selectKeyFromMessage = (error, result) => {
	const { key } = error || result;
	return key;
};

class ClosureFileDownloadWebSocket {
	constructor() {
		this.ws = undefined;
		this.requestTokenCnt = 0;
		this.pendingRequestTokens = []; // array of tokens pending completion
		this.callbackStore = {};
		this.pendingPayloadRequests = [];
		this.isWebsocketReady = false;
		this.isRefreshingAuthentication = false;
	}

	initWebsocket = () => {
		if (!this.ws) {
			const baseUrl = getAipUrls().parseFileWS;
			const token = getAccessToken();
			const websocketURL = `${baseUrl}?token=${token}`;

			this.ws = new Sockette(websocketURL, {
				maxAttempts: 5,
				onopen: () => this._sendPendingRequests(),
				onmessage: e => this._receiveMessage(e)
			});
		}
	};

	startImport = (payload, key, handleResponse) => {
		const self = this;
		if (isTokenExpired()) {
			if (!this.isRefreshingAuthentication) {
				this.isRefreshingAuthentication = true;

				AccountUtils.refresh().then(() => {
					self.isRefreshingAuthentication = false;
					self.initWebsocket();
				});
			}
		} else {
			this.initWebsocket();
		}
		this._send(undefined, payload, key, handleResponse);
	};

	destroy = () => {
		this._destroyWebSocket();
	};

	_sendPendingRequests = () => {
		const self = this;
		const sendPending = () => {
			self.isWebsocketReady = true;
			while (self.pendingPayloadRequests.length) {
				const payload = self.pendingPayloadRequests.pop();
				const { key } = payload.params;
				const handleResponse = this.callbackStore[key];
				self._send(undefined, payload, key, handleResponse);
			}
		};

		setTimeout(sendPending, 1);
	};

	_send = (_e, { params, authParams }, key, handleResponse) => {
		if (!this.isWebsocketReady) {
			this.pendingPayloadRequests.push({ params, authParams });
			this.callbackStore[key] = handleResponse;
			return;
		}

		this.callbackStore[key] = handleResponse;
		this.requestTokenCnt += 1;
		this.addTokenToPending(this.requestTokenCnt);

		const payload = {
			action: IMPORT_ACTION,
			params,
			authParams,
			requestToken: this.requestTokenCnt
		};

		this.ws.send(JSON.stringify(payload));
	};

	_receiveMessage = wsEvent => {
		if (ignoreMessage(wsEvent)) {
			return;
		}

		const wsMessage = selectWsMessage(wsEvent);

		const error = isWsErrorMessage(wsMessage) ? wsMessage : undefined;
		const result = isWsErrorMessage(wsMessage) ? undefined : wsMessage;

		const token = selectTokenFromMessage(error, result);
		const key = selectKeyFromMessage(error, result);
		this.removeTokenFromPending(token);

		if (this.callbackStore[key]) {
			this.callbackStore[key](error, result);
			this.callbackStore[key] = undefined;
			delete this.callbackStore[key];
		}

		if (!this.hasPendingRequests()) {
			this._destroyWebSocket();
		}
	};

	_destroyWebSocket = () => {
		if (this.ws && !this.hasPendingRequests()) {
			this.callbackStore = {};
			this.pendingPayloadRequests = [];
			this.isWebsocketReady = false;
			this.ws.close();
			this.ws = undefined;
		}
	};

	addTokenToPending = token => {
		this.pendingRequestTokens.push(token);
	};

	removeTokenFromPending = token => {
		const idx = this.pendingRequestTokens.indexOf(token);
		if (idx > -1) {
			this.pendingRequestTokens.splice(idx, 1);
		}
	};

	hasPendingRequests = () =>
		this.pendingRequestTokens.length > 0 ||
		this.pendingPayloadRequests.length > 0;
}

export default new ClosureFileDownloadWebSocket();
