/* eslint-disable import/prefer-default-export */
/* eslint-disable no-param-reassign */
const isDefined = v => v !== undefined && v !== null;

export const createGetXYMinMax = props => {
	return (item, currentXMin, currentXMax, currentYMin, currentYMax) => {
		let xMin = currentXMin;
		let xMax = currentXMax;
		let yMin = currentYMin;
		let yMax = currentYMax;

		props.forEach(p => {
			const val = item[p];

			if (!isDefined(yMin)) {
				yMin = val;
			}

			if (!isDefined(yMax)) {
				yMax = val;
			}

			if (isDefined(val)) {
				if (val < yMin) {
					yMin = val;
				}
				if (val > yMax) {
					yMax = val;
				}
			}
		});

		const x = item.id;
		if (!isDefined(xMin)) {
			xMin = x;
		}
		if (!isDefined(xMax)) {
			xMax = x;
		}
		if (isDefined(x) && isDefined(xMin) && xMin > x) {
			xMin = x;
		}
		if (isDefined(x) && isDefined(xMax) && xMax < x) {
			xMax = x;
		}

		return { xMin, xMax, yMin, yMax };
	};
};

export const createComputeYMinYMax = readingKeys => {
	const props = readingKeys.map(rk => rk.value);
	const getXYMinMax = createGetXYMinMax(props);
	return (survey, reading) => {
		const {
			xMin: currentXMin,
			xMax: currentXMax,
			yMin: currentYMin,
			yMax: currentYMax
		} = survey;
		const { xMin, xMax, yMin, yMax } = getXYMinMax(
			reading,
			currentXMin,
			currentXMax,
			currentYMin,
			currentYMax
		);
		survey.xMin = xMin;
		survey.xMax = xMax;
		survey.yMin = yMin;
		survey.yMax = yMax;
	};
};

export const createComputeYMinYMaxWithZeroYMin = readingKeys => {
	const computeYMinYMax = createComputeYMinYMax(readingKeys);
	return (survey, reading) => {
		computeYMinYMax(survey, reading);
		if (isDefined(survey.yMin)) {
			survey.yMin = Math.min(survey.yMin, 0);
		}
	};
};
