import { SET_TYPE, SET_FROM_AND_TO } from './actionTypes';
import initialState from './initialState';

const reducer = (state = initialState, action) => {
	const { type, payload } = action;
	switch (type) {
		case SET_TYPE:
			return { ...state, type: payload };

		case SET_FROM_AND_TO:
			return { ...state, from: payload.from, to: payload.to };

		default:
			return state;
	}
};

export default reducer;
