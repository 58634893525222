/* eslint-disable import/prefer-default-export */
/* eslint-disable import/no-cycle */
import { createActions } from 'redux-actions';
import { ROOT_SLICE } from '../constants';

const {
	setSelectedCustomer,
	setSelectedCustomerChangesClone,
	setIsSavingEditedCustomer,
	setIsDeletingCustomer,
	setErrorMessageSaveEditedCustomer,
	setShowConfirmation,
	setConfirmationMessageKey
} = createActions(
	{
		SET_SELECTED_CUSTOMER: selectedCustomer => ({ selectedCustomer }),
		SET_SELECTED_CUSTOMER_CHANGES_CLONE: selectedCustomerChangesClone => ({
			selectedCustomerChangesClone
		}),
		SET_IS_SAVING_EDITED_CUSTOMER: isSavingEditedCustomer => ({
			isSavingEditedCustomer
		}),
		SET_IS_DELETING_CUSTOMER: isDeletingCustomer => ({
			isDeletingCustomer
		}),
		SET_ERROR_MESSAGE_SAVE_EDITED_CUSTOMER: errorMessageSaveEditedCustomer => ({
			errorMessageSaveEditedCustomer
		}),
		SET_SHOW_CONFIRMATION: showConfirmation => {
			return { showConfirmation };
		},
		SET_CONFIRMATION_MESSAGE_KEY: confirmationMessageKey => {
			return { confirmationMessageKey };
		}
	},
	{ prefix: ROOT_SLICE }
);

export {
	setSelectedCustomer,
	setSelectedCustomerChangesClone,
	setIsSavingEditedCustomer,
	setIsDeletingCustomer,
	setErrorMessageSaveEditedCustomer,
	setShowConfirmation,
	setConfirmationMessageKey
};
