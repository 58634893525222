/* eslint-disable import/prefer-default-export */
import { GROUP_TYPES } from '../constants';
import colors from '../../../../Colors';

export const generateReadingKeyStructures = () => {
	const COLOR_ON = colors.getColor();
	const COLOR_OFF = colors.getColor();

	const computeReadingKeysMeta = rks => {
		const rkProps = rks.map(k => k.value);
		const rkByBcPriority = [...rks].sort((a, b) => a.bcPriority - b.bcPriority);
		return [rkProps, rkByBcPriority];
	};

	const _all = [
		{
			label: 'ON',
			value: 'on',
			seriesType: 'Line',
			color: COLOR_ON,
			bcPriority: 1,
			groups: [GROUP_TYPES.DEFAULT]
		},
		{
			label: 'OFF',
			value: 'off',
			seriesType: 'Line',
			color: COLOR_OFF,
			bcPriority: 0,
			groups: [GROUP_TYPES.DEFAULT]
		},
		{
			label: 'SAWTOOTH',
			value: 'sawtooth',
			seriesType: 'Line',
			color: COLOR_ON,
			bcPriority: 0,
			groups: [GROUP_TYPES.SAWTOOTH]
		}
	];

	const _default = _all.filter(
		rk => rk.groups.indexOf(GROUP_TYPES.DEFAULT) > -1
	);

	const _sawtooth = _all.filter(
		rk => rk.groups.indexOf(GROUP_TYPES.SAWTOOTH) > -1
	);

	const [_allProps, _allByBcPriority] = computeReadingKeysMeta(_all);
	const [_defaultProps, _defaultByBcPriority] = computeReadingKeysMeta(
		_default
	);
	const [_sawtoothProps, _sawtoothByBcPriority] = computeReadingKeysMeta(
		_sawtooth
	);

	const READING_KEYS = {
		_all,
		_allProps,
		_allByBcPriority,

		_default,
		_defaultProps,
		_defaultByBcPriority,

		_sawtooth,
		_sawtoothProps,
		_sawtoothByBcPriority
	};

	return READING_KEYS;
};

export const createGetReadingKeys = READING_KEYS => ({
	all,
	useSawtooth
} = {}) => {
	if (all) {
		return READING_KEYS._all;
	}
	if (useSawtooth) {
		return READING_KEYS._sawtooth;
	}
	return READING_KEYS._default;
};

export const createGetReadingKeyProps = READING_KEYS => ({
	all,
	useSawtooth
} = {}) => {
	if (all) {
		return READING_KEYS._allProps;
	}
	if (useSawtooth) {
		return READING_KEYS._sawtoothProps;
	}
	return READING_KEYS._defaultProps;
};

export const createGetReadingKeysByBcPriority = READING_KEYS => ({
	all,
	useSawtooth
} = {}) => {
	if (all) {
		return READING_KEYS._allByBcPriority;
	}
	if (useSawtooth) {
		return READING_KEYS._sawtoothByBcPriority;
	}
	return READING_KEYS._defaultByBcPriority;
};

export const createGetSurveyColor = getReadingKeys => flags =>
	getReadingKeys(flags)[0].color;
