/* eslint-disable import/no-cycle */
import { setSelectedEntity, setSelectedEntityChangesClone } from './actions';
import { ROOT_SLICE } from './constants';
import { selectRootSlice } from './selectors';

// ************
// PARTS
// ************
const reducerParts = {
	[setSelectedEntity]: (state, { payload: { selectedEntity } }) => {
		const rootSlice = selectRootSlice(state);
		return {
			...state,
			[ROOT_SLICE]: { ...rootSlice, selectedEntity }
		};
	},

	[setSelectedEntityChangesClone]: (
		state,
		{ payload: { selectedEntityChangesClone } }
	) => {
		const rootSlice = selectRootSlice(state);
		return {
			...state,
			[ROOT_SLICE]: { ...rootSlice, selectedEntityChangesClone }
		};
	}
};

export default reducerParts;
