/* eslint-disable no-use-before-define */
import { createActions } from 'redux-actions';
import jobListUtils from '../utils/utils';
import { fetchLines, fetchCustomerTags } from '../../../redux/Lines/actions';
import {
	fetchProjects,
	fetchProjectsNameList
} from '../../../redux/Projects/actions';

const {
	selectCustomer,
	invalidateCustomer,
	fetchCustomers,
	receiveCustomers,
	requestCustomers
} = createActions({
	SELECT_CUSTOMER: customer => ({ customer }),
	INVALIDATE_CUSTOMER: customer => ({ customer }),
	FETCH_CUSTOMERS: () => doFetchCustomers(),
	RECEIVE_CUSTOMERS: json => {
		return { customers: json.customers };
	},
	REQUEST_CUSTOMERS: () => ({})
});

const doFetchCustomers = () => {
	return dispatch => {
		dispatch(requestCustomers());

		return jobListUtils.getCustomers((err, res) => {
			const { alert = () => {} } = window || {};
			if (err) {
				alert(err);
				// console.error(err);
			} else {
				dispatch(receiveCustomers(res));
				dispatch(fetchCustomerTags());
				dispatch(fetchLines({}));
				dispatch(fetchProjects({}));
				dispatch(fetchProjectsNameList({}));
			}
		});
	};
};

function shouldFetchCustomers(state) {
	return state.availableCustomers.length === 0;
}

function fetchCustomersIfNeeded() {
	return (dispatch, getState) => {
		if (shouldFetchCustomers(getState().jobs)) {
			return dispatch(doFetchCustomers());
		}
		return Promise.resolve();
	};
}

export {
	selectCustomer,
	invalidateCustomer,
	fetchCustomers,
	receiveCustomers,
	requestCustomers,
	fetchCustomersIfNeeded
};
