import React from 'react';
import { connect } from 'react-redux';

import { isTokenValid } from '../../../util/user';
import UnauthorizedRoutes from './UnauthorizedRoutes';
import AuthorizedRoutes from './AuthorizedRoutes';
import { page } from '../../../util/analytics/segment';

const RouteAutorizer = () => {
	page();
	if (isTokenValid()) {
		return <AuthorizedRoutes />;
	}
	return <UnauthorizedRoutes />;
};

const MainRouter = () => {
	return <RouteAutorizer />;
};

export default connect()(MainRouter);
