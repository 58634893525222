import { LINES_PER_PAGE } from '../constants';

const getTotalPages = state => {
	const { lines = [] } = state;
	const itemCount = lines.length;
	return Math.ceil(itemCount / LINES_PER_PAGE);
};

export const getPrevPage = state => {
	const { currentPage } = state;
	const assumedPrevPage = (currentPage || 0) - 1;
	return Math.max(assumedPrevPage, 1);
};

export const getNextPage = state => {
	const { currentPage } = state;
	const assumedNextPage = (currentPage || 0) + 1;
	return Math.min(assumedNextPage, getTotalPages(state));
};

export const getLoadedPages = state => {
	const { linesByPage } = state;
	return Object.keys(linesByPage)
		.map(page => parseInt(page, 10))
		.sort((a, b) => a - b);
};

export const getDirectPageButtons = state => {
	const pages = getLoadedPages(state);
	const startIndex = Math.max(pages.indexOf(state.currentPage) - 2, 0);
	return pages.slice(startIndex, startIndex + 5);
};

export const getItemsForCurrentPage = state => {
	const { linesByPage, currentPage } = state;
	return linesByPage[currentPage];
};

export const convertLinesByPageToArray = (linesByPage = {}) => {
	return Object.keys(linesByPage)
		.map(page => parseInt(page, 10))
		.sort((a, b) => a - b)
		.reduce((result, page) => [...result, ...linesByPage[page]], []);
};

export const getLargestPage = state => {
	const { linesByPage } = state;
	return getLoadedPages(state)
		.reverse((a, b) => a - b)
		.find(page => linesByPage[page] && linesByPage[page].length > 0);
};

export const getLastPage = state => {
	const { linesByPage } = state;
	const [pageB, pageA] = getLoadedPages(state)
		.reverse((a, b) => a - b)
		.slice(0, 2);

	const linesA = pageA ? linesByPage[pageA] : undefined;
	const linesB = pageB ? linesByPage[pageB] : undefined;

	const isPageALastPage =
		linesA &&
		(linesA.length < LINES_PER_PAGE ||
			(linesA.length === LINES_PER_PAGE && linesB && linesB.length === 0));
	if (isPageALastPage) {
		return pageA;
	}

	const isPageBLastPage = linesB && linesB.length < LINES_PER_PAGE;
	if (isPageBLastPage) {
		return pageB;
	}

	return undefined;
};
