/* eslint-disable camelcase */
import { createDerivedSurveys as createACDerivedSurveys } from './util/AC';

import { createDerivedSurveys as createDOCDerivedSurveys } from './util/DOC';

import { createDerivedSurveys as createSpatialDerivedSurveys } from './util/SPATIAL';

const combineDataSources = dataSources =>
	dataSources.reduce(
		(acc, obj) => ({
			surveys: [...acc.surveys, ...obj.surveys],
			readingsMeta: { ...acc.readingsMeta, ...obj.readingsMeta },
			readings: { ...acc.readings, ...obj.readings },
			readingsIndexMap: { ...acc.readingsIndexMap, ...obj.readingsIndexMap },
			readingsMap: {
				...acc.readingsMap,
				...obj.readingsMap
			},
			readingsWithChartGaps: {
				...acc.readingsWithChartGaps,
				...obj.readingsWithChartGaps
			},
			readingsWithChartGapsIndexMap: {
				...acc.readingsWithChartGapsIndexMap,
				...obj.readingsWithChartGapsIndexMap
			},
			readingsWithGeoGapsIndexMap: {
				...acc.readingsWithGeoGapsIndexMap,
				...obj.readingsWithGeoGapsIndexMap
			},
			readingsWithGeoGaps: {
				...acc.readingsWithGeoGaps,
				...obj.readingsWithGeoGaps
			},
			series: { ...acc.series, ...obj.series },
			seriesIndexMap: { ...acc.seriesIndexMap, ...obj.seriesIndexMap },
			readingsStationIdIntegers: {
				...acc.readingsStationIdIntegers,
				...obj.readingsStationIdIntegers
			}
		}),
		{
			surveys: [],
			readingsMeta: {},
			readings: {},
			readingsIndexMap: {},
			readingsMap: {},
			readingsWithChartGaps: {},
			readingsWithChartGapsIndexMap: {},
			readingsWithGeoGapsIndexMap: {},
			readingsWithGeoGaps: {},
			series: {},
			seriesIndexMap: {},
			readingsStationIdIntegers: {}
		}
	);

export const createDerivedSurveys = (
	processedReadings,
	processedReadingsWithChartGaps,
	processedReadingsWithGeoGaps,
	type,
	surveyReadings,
	jobId,
	meta,
	isParentSurveyChecked,
	end_date,
	parent_survey_subtype,
	job_number
) => {
	const acDataSources = createACDerivedSurveys(
		processedReadings,
		processedReadingsWithChartGaps,
		processedReadingsWithGeoGaps,
		type,
		surveyReadings,
		jobId,
		meta,
		isParentSurveyChecked,
		end_date,
		parent_survey_subtype,
		job_number
	);
	const docDataSources = createDOCDerivedSurveys(
		processedReadings,
		processedReadingsWithChartGaps,
		processedReadingsWithGeoGaps,
		type,
		surveyReadings,
		jobId,
		meta,
		end_date,
		parent_survey_subtype,
		job_number
	);
	const spatialDataSources = createSpatialDerivedSurveys(
		processedReadings,
		processedReadingsWithChartGaps,
		processedReadingsWithGeoGaps,
		type,
		surveyReadings,
		jobId,
		meta,
		end_date,
		parent_survey_subtype,
		job_number
	);

	return combineDataSources([
		docDataSources,
		acDataSources,
		spatialDataSources
	]);
};

export default { createDerivedSurveys };
