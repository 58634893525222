const videos = () => {
	return [
		{
			imageIndex: 0,
			city: 'Cocoa Beach',
			state: 'FL',
			date: '6/11/2015',
			url:
				'https://aegion-aip-local.s3-us-west-2.amazonaws.com/vidline/encoded/secondvideo_Generic_Hd_Mp4_Avc_Aac_16x9_1280x720p_24Hz_4.5Mbps.mp4',
			manholes: { upstream: 'LS6-004', downstream: 'LS6-003' },
			center: [
				(28.298759952209508 - 28.298734916701978) / 2 + 28.298759952209508,
				(-80.610522296458086 - -80.611505584651951) / 2 + -80.611505584651951
			]
		},
		{
			imageIndex: 1,
			city: 'Kissimmee',
			state: 'FL',
			date: '6/11/2015',
			url:
				'https://aegion-aip-local.s3-us-west-2.amazonaws.com/vidline/encoded/150819_150820_20180508150819_150820_20180508.mp4',
			manholes: { upstream: 'MH-150819', downstream: 'MH-150820' },
			center: [
				(28.308166 - 28.308149) / 2 + 28.308166,
				(-81.403189 - -81.402183) / 2 + -81.402183
			]
		}
	];
};

export default videos;
