/* eslint-disable import/no-cycle */
import { getAssetDetail } from './utils/api';
import { SET_ASSET_DETAILS } from './actionTypes';
import { createPayloadMetaAction as cpma } from '../../../scanline/redux/util';

export const setAssetDetails = cpma(SET_ASSET_DETAILS);

export const getAssetDetails = payload => (dispatch, getState) => {
	const {
		assetViewMain: { isApprovalMode }
	} = getState();
	getAssetDetail(payload.name, payload.template, isApprovalMode).then(res => {
		dispatch(setAssetDetails(res));
	});
};
