const isDefined = v => v !== undefined && v !== null;

export const arrKeyToValueMap = (arr = [], { prop, valueProp } = {}) => {
	return arr.reduce((acc, ko) => {
		if (isDefined(prop)) {
			acc[ko[prop]] = valueProp ? ko[valueProp] : true;
		} else {
			acc[ko] = true;
		}
		return acc;
	}, {});
};

export const arrPropToBoolMap = (arr = [], { prop } = {}) => {
	return arr.reduce((acc, ko) => {
		if (isDefined(prop)) {
			acc[ko[prop]] = true;
		} else {
			acc[ko] = true;
		}
		return acc;
	}, {});
};

export const objectsPropToBoolMap = (data = {}, { prop } = {}) => {
	const keys = Object.keys(data);
	return keys.reduce((acc, k) => {
		const isArray = data[k] instanceof Array;
		acc[k] = isArray
			? arrPropToBoolMap(data[k], { prop })
			: arrPropToBoolMap(data[k][prop]);
		return acc;
	}, {});
};

export const createObjectsMap = (arrObjects = [], { prop } = {}) => {
	return arrObjects.reduce((acc, o) => {
		const key = o[prop];
		acc[key] = o;
		return acc;
	}, {});
};
