import { history } from '../../../scanline/history';
import {
	clearFromQueryString,
	createNewQueryString,
	getLocation,
	parseQueryString
} from '../../../scanline/utils/location';
import { KEY_FILTERS } from './constants';

const isDefined = v => v !== undefined && v !== null;

export const createSearchFromDeleteFilter = (key, paramSearch) => {
	let newSearch;
	if (isDefined(key)) {
		const location = getLocation();
		const origSearch = isDefined(paramSearch) ? paramSearch : location.search;
		const urlParams = parseQueryString(origSearch);
		const strFilters = urlParams[KEY_FILTERS] || '';
		if (strFilters) {
			const filters = JSON.parse(strFilters);
			delete filters[key];
			if (!Object.keys(filters).length) {
				newSearch = clearFromQueryString(KEY_FILTERS, origSearch);
			} else {
				newSearch = createNewQueryString(
					[KEY_FILTERS, JSON.stringify(filters)],
					origSearch
				);
			}
		}
	}
	return newSearch;
};

export const deleteFilter = (key, search) => {
	if (isDefined(key)) {
		const newSearch = createSearchFromDeleteFilter(key, search);

		if (isDefined(newSearch)) {
			history.push(newSearch);
		}
	}
};

export const createSearchFromUpdateFilter = (key, value, paramSearch) => {
	if (!isDefined(value)) {
		return createSearchFromDeleteFilter(key);
	}
	const location = getLocation();
	const origSearch = isDefined(paramSearch) ? paramSearch : location.search;
	const urlParams = parseQueryString(origSearch);
	const strFilters = urlParams[KEY_FILTERS] || '';
	const filters = strFilters ? JSON.parse(strFilters) : {};
	filters[key] = value;
	const newSearch = createNewQueryString(
		[KEY_FILTERS, JSON.stringify(filters)],
		origSearch
	);
	return newSearch;
};

export const updateFilter = (key, value, search) => {
	if (!isDefined(value)) {
		deleteFilter(key, search);
	} else {
		const newSearch = createSearchFromUpdateFilter(key, value, search);
		history.push(newSearch);
	}
};

export const getFilters = () => {
	const location = getLocation();
	const urlParams = parseQueryString(location.search);
	const strFilters = urlParams[KEY_FILTERS] || '{}';
	return JSON.parse(strFilters);
};

export default {
	deleteFilter,
	updateFilter,
	getFilters
};
