/* eslint-disable import/prefer-default-export */

const cleanEmailList = list => {
	return list.replace(/\s/gi, '').replace(/,/gi, ';');
};

const formatMailTo = mailTo => {
	if (!mailTo) {
		return undefined;
	}
	return `mailto:${cleanEmailList(mailTo)}`;
};

const formatCc = cc => {
	if (!cc) {
		return undefined;
	}
	return `cc=${cleanEmailList(cc)}`;
};

const formatBcc = bcc => {
	if (!bcc) {
		return undefined;
	}
	return `bcc=${cleanEmailList(bcc)}`;
};

const formatSubject = subject => {
	if (!subject) {
		return undefined;
	}

	// assume string
	return `subject=${encodeURIComponent(subject.trim())}`;
};

const formatBody = body => {
	let strBody = body;
	if (Array.isArray(body)) {
		strBody = body.join('\n\n');
	}

	if (!strBody || !strBody.trim()) {
		return undefined;
	}

	return `body=${encodeURIComponent(strBody.trim())}`;
};

export const createMailToString = ({ mailTo, cc, bcc, subject, body }) => {
	const formattedMailTo = formatMailTo(mailTo);

	if (!formattedMailTo) {
		return '';
	}

	const parts = [
		formatCc(cc),
		formatBcc(bcc),
		formatSubject(subject),
		formatBody(body)
	]
		.filter(v => !!v)
		.join('&');

	if (parts && parts.length) {
		return `${formattedMailTo}?${parts}`;
	}

	return formattedMailTo;
};
