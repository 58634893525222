import { PROJECTS_PER_PAGE } from '../constants';

const getTotalPages = state => {
	const { projects = [] } = state;
	const itemCount = projects.length;
	return Math.ceil(itemCount / PROJECTS_PER_PAGE);
};

export const getPrevPage = state => {
	const { currentPage } = state;
	const assumedPrevPage = (currentPage || 0) - 1;
	return Math.max(assumedPrevPage, 1);
};

export const getNextPage = state => {
	const { currentPage } = state;
	const assumedNextPage = (currentPage || 0) + 1;
	return Math.min(assumedNextPage, getTotalPages(state));
};

export const getLoadedPages = state => {
	const { projectsByPage } = state;
	return Object.keys(projectsByPage)
		.map(page => parseInt(page, 10))
		.sort((a, b) => a - b);
};

export const getDirectPageButtons = state => {
	const pages = getLoadedPages(state);
	const startIndex = Math.max(pages.indexOf(state.currentPage) - 2, 0);
	return pages.slice(startIndex, startIndex + 5);
};

export const getItemsForCurrentPage = state => {
	const { projectsByPage, currentPage } = state;
	return projectsByPage[currentPage];
};

export const convertProjectsByPageToArray = (projectsByPage = {}) => {
	return Object.keys(projectsByPage)
		.map(page => parseInt(page, 10))
		.sort((a, b) => a - b)
		.reduce((result, page) => [...result, ...projectsByPage[page]], []);
};

export const getLargestPage = state => {
	const { projectsByPage } = state;
	return getLoadedPages(state)
		.reverse((a, b) => a - b)
		.find(page => projectsByPage[page] && projectsByPage[page].length > 0);
};

export const getLastPage = state => {
	const { projectsByPage } = state;
	const [pageB, pageA] = getLoadedPages(state)
		.reverse((a, b) => a - b)
		.slice(0, 2);

	const projectsA = pageA ? projectsByPage[pageA] : undefined;
	const projectsB = pageB ? projectsByPage[pageB] : undefined;

	const isPageALastPage =
		projectsA &&
		(projectsA.length < PROJECTS_PER_PAGE ||
			(projectsA.length === PROJECTS_PER_PAGE &&
				projectsB &&
				projectsB.length === 0));
	if (isPageALastPage) {
		return pageA;
	}

	const isPageBLastPage = projectsB && projectsB.length < PROJECTS_PER_PAGE;
	if (isPageBLastPage) {
		return pageB;
	}

	return undefined;
};
