/* eslint-disable camelcase */
import scanlineMapUtils from '../../../../mapUtils';
import { createIsValidReading, deriveDataSetsFromProps } from './_BASE';

export const createDerivedSurveys = (
	processedReadings,
	processedReadingsWithChartGaps,
	processedReadingsWithGeoGaps,
	type,
	surveyReadings,
	jobId,
	meta,
	isParentSurveyChecked,
	end_date,
	parent_survey_subtype,
	job_number
) => {
	const readings = {};
	const readingsWithChartGaps = {};
	const readingsWithGeoGaps = {};
	const readingsMap = {};
	const readingsIndexMap = {};
	const readingsWithChartGapsIndexMap = {};
	const readingsWithGeoGapsIndexMap = {};

	const series = {};
	const seriesIndexMap = {};
	const readingsStationIdIntegers = {};

	const readingsMeta = {};
	const surveys = [];
	const { s3Key } = meta || {};

	const survey = scanlineMapUtils.createSurveyTemplate(type, 'AC', {
		primaryXAxis: {
			minimum: surveyReadings[0].id,
			maximum: surveyReadings[surveyReadings.length - 1].id
		},
		jobId,
		s3Key,
		end_date,
		parent_survey_subtype,
		job_number
	});
	scanlineMapUtils.initSurveyMeta(survey);
	survey.isDerived = true;
	const readingKeyProps = survey.meta.getReadingKeyProps();
	const isValidReading = createIsValidReading(readingKeyProps);

	if (processedReadings.filter(isValidReading).length) {
		const {
			readings: thisReadings,
			readingsIndexMap: thisReadingsIndexMap,
			readingsMap: thisReadingsMap,
			readingsWithChartGaps: thisReadingsWithChartGaps,
			readingsWithChartGapsIndexMap: thisReadingsWithChartGapsIndexMap,
			readingsWithGeoGapsIndexMap: thisReadingsWithGeoGapsIndexMap,
			readingsWithGeoGaps: thisReadingsWithGeoGaps,
			series: thisSeries,
			seriesIndexMap: thisSeriesIndexMap,
			readingsStationIdIntegers: thisReadingsStationIdIntegers
		} = deriveDataSetsFromProps(
			survey,
			readingKeyProps,
			processedReadings,
			processedReadingsWithChartGaps,
			processedReadingsWithGeoGaps,
			{ surveyMeta: survey.meta }
		);

		// add new AC survey
		if (isParentSurveyChecked) {
			const hasWarnings =
				thisSeries &&
				thisSeries.acWarning &&
				thisSeries.acWarning.filter(r => !r.isGap).length > 0;
			const hasProblems =
				thisSeries &&
				thisSeries.acProblem &&
				thisSeries.acProblem.filter(r => !r.isGap).length > 0;
			survey.checked = hasWarnings || hasProblems;
		} else {
			survey.checked = false;
		}

		readings[survey.id] = thisReadings;
		readingsWithChartGaps[survey.id] = thisReadingsWithChartGaps;
		readingsWithGeoGaps[survey.id] = thisReadingsWithGeoGaps;
		readingsIndexMap[survey.id] = thisReadingsIndexMap;
		readingsWithChartGapsIndexMap[
			survey.id
		] = thisReadingsWithChartGapsIndexMap;
		readingsWithGeoGapsIndexMap[survey.id] = thisReadingsWithGeoGapsIndexMap;
		readingsMap[survey.id] = thisReadingsMap;
		series[survey.id] = thisSeries;
		seriesIndexMap[survey.id] = thisSeriesIndexMap;
		readingsMeta[survey.id] = meta;
		readingsStationIdIntegers[survey.id] = thisReadingsStationIdIntegers;

		surveys.push(survey);
	}

	return {
		readings,
		readingsWithChartGaps,
		readingsWithGeoGaps,
		readingsMap,
		readingsIndexMap,
		readingsWithChartGapsIndexMap,
		readingsWithGeoGapsIndexMap,
		readingsMeta,
		surveys,
		series,
		seriesIndexMap,
		readingsStationIdIntegers
	};
};

export default { createDerivedSurveys };
