import { transformPageLoadStateFromServer as transformEmpty } from './transform_EMPTY';
import { transformPageLoadStateFromServer as transformV0 } from './transform_v_0';
import { transformPageLoadStateFromServer as transformV1 } from './transform_v_1';

const EMPTY = 'EMPTY';

const computeServerPageLoadStateVersion = (serverPageLoadState = {}) => {
	const {
		appStateVersion,
		actionPlanVersion,
		actionPlanComparator
	} = serverPageLoadState;

	if (Object.keys(serverPageLoadState).length === 0) {
		return EMPTY;
	}

	if (appStateVersion) {
		// return appStateVersion as is
		return appStateVersion;
	}

	if (actionPlanVersion) {
		// return Major for all action plan versions
		return Math.floor(actionPlanVersion);
	}

	if (actionPlanComparator) {
		// return 1 for all versions 1.x
		return Math.floor(1.1);
	}

	if (!actionPlanVersion && !actionPlanComparator) {
		// return 0 for all versions below 1.0
		return Math.floor(0.9);
	}

	return 0;
};

const TRANSFORMS_BY_VERSION = {
	EMPTY: transformEmpty,
	0: transformV0,
	1: transformV0,
	1.1: transformV1
};

export const transformPageLoadStateFromServer = (
	serverPageLoadState,
	getState
) => {
	const version = computeServerPageLoadStateVersion(serverPageLoadState);
	const transform = TRANSFORMS_BY_VERSION[version] || transformV1;

	return transform(serverPageLoadState, getState);
};

export default {
	transformPageLoadStateFromServer
};
