/* eslint-disable import/no-cycle */
import {
	setPermissionDefinitions,
	setIsLoadingLoadedPermissionDefinitions,
	setHasLoadingErrorPermissionDefinitions
} from './actions';
import { ROOT_SLICE } from './constants';
import {
	selectRootSlice,
	selectIsLoadingPermissionDefinitions,
	selectIsLoadedPermissionDefinitions,
	selectHasLoadingErrorPermissionDefinitions
} from './selectors';

const isDefined = v => v !== undefined && v !== null;

// ************
// PARTS
// ************
const reducerParts = {
	[setPermissionDefinitions]: (
		state,
		{
			payload: {
				permissionDefinitions,
				mapPermissionsByGroupName,
				listPermissionDefinitions,
				mapPermissionsListByAppKey
			}
		}
	) => {
		const rootSlice = selectRootSlice(state);
		return {
			...state,
			[ROOT_SLICE]: {
				...rootSlice,
				permissionDefinitions,
				mapPermissionsByGroupName,
				listPermissionDefinitions,
				mapPermissionsListByAppKey
			}
		};
	},

	[setHasLoadingErrorPermissionDefinitions]: (
		state,
		{ payload: { hasError } }
	) => {
		const rootSlice = selectRootSlice(state);
		const currentHasError = selectHasLoadingErrorPermissionDefinitions(state);

		if (hasError === currentHasError) {
			return state;
		}

		return {
			...state,
			[ROOT_SLICE]: {
				...rootSlice,
				hasLoadingErrorPermissionDefinitions: hasError
			}
		};
	},

	[setIsLoadingLoadedPermissionDefinitions]: (
		state,
		{
			payload: {
				isLoadingPermissionDefinitions: newIsLoadingPermissionDefinitions,
				isLoadedPermissionDefinitions: newIsLoadedPermissionDefinitions
			}
		}
	) => {
		const rootSlice = selectRootSlice(state);
		const isLoadingPermissionDefinitions = selectIsLoadingPermissionDefinitions(
			state
		);
		const isLoadedPermissionDefinitions = selectIsLoadedPermissionDefinitions(
			state
		);

		const isLoadingChanged =
			isDefined(newIsLoadingPermissionDefinitions) &&
			newIsLoadingPermissionDefinitions !== isLoadingPermissionDefinitions;
		const isLoadedChanged =
			isDefined(newIsLoadedPermissionDefinitions) &&
			newIsLoadedPermissionDefinitions !== isLoadedPermissionDefinitions;

		if (!isLoadingChanged && !isLoadedChanged) {
			return state;
		}

		return {
			...state,
			[ROOT_SLICE]: {
				...rootSlice,
				isLoadingPermissionDefinitions: isLoadingChanged
					? newIsLoadingPermissionDefinitions
					: isLoadingPermissionDefinitions,
				isLoadedPermissionDefinitions: isLoadedChanged
					? newIsLoadedPermissionDefinitions
					: isLoadedPermissionDefinitions
			}
		};
	}
};

export default reducerParts;
