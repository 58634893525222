// eslint-disable-next-line import/no-cycle
import { clearUpdates } from '../../actions/manager';

const NS_MAPS = 'assetview_editassetcard_';

export const SET_EDITING = `${NS_MAPS}SET_EDITING`;
export const setEditing = (checkClose = false) => (dispatch, getState) => {
	const {
		assetview: {
			editAssetCard: { editing },
			manager: { updates }
		}
	} = getState();
	if (editing && checkClose && updates && Object.keys(updates).length > 0) {
		if (
			// eslint-disable-next-line no-alert
			!window.confirm(
				'There are unsaved edits are you sure you want to cancel editing?'
			)
		) {
			return true;
		}
		dispatch(clearUpdates());
	}
	if (checkClose && !editing) {
		return false;
	}
	dispatch({
		type: SET_EDITING,
		payload: !editing
	});
	return false;
};
