import { selectSliceCisview } from './HELPERS';

export const selectKMZ = state => {
	const sliceCisview = selectSliceCisview(state);
	const { kmz } = sliceCisview;
	return kmz;
};

export const selectSelectedKMZs = state => {
	const { selectedKMZs } = selectKMZ(state);
	return selectedKMZs;
};

export const selectSelectedKMZ = (state, key) => {
	const selectedKMZs = selectSelectedKMZs(state);
	const index = selectedKMZs.findIndex(({ key: k }) => k === key);
	return selectedKMZs[index];
};
