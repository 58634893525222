export const deleteProps = (origObj, propKeys) => {
	if (!origObj || !propKeys) {
		return origObj;
	}

	const obj = { ...origObj };
	propKeys.forEach(prop => {
		delete obj[prop];
	});

	return obj;
};

export default {
	deleteProps
};
