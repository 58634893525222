export const injectBodyClassName = clazzName => {
	const { body } = document || {};
	if (body && clazzName && clazzName.trim().length > 0) {
		const { className = '' } = body;
		body.className = [...className.split(' '), clazzName].join(' ');
	}
};

export const removeBodyClassName = clazzName => {
	const { body } = document || {};
	if (body && clazzName && clazzName.trim().length > 0) {
		const { className = '' } = body;
		const newClassName = clazzName
			.split(' ')
			.reduce((acc, cn) => {
				if (cn && cn.trim().length > 0) {
					return acc.replace(cn, '');
				}

				return acc;
			}, className)
			.replace(/\s+/gi, ' ');
		body.className = newClassName;
	}
};
