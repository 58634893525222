export const selectGridKey = state => {
	const {
		dev: {
			components: { gridKey }
		}
	} = state;
	return gridKey;
};

export const selectApplicationsListKey = state => {
	const {
		dev: {
			components: { applicationsListKey }
		}
	} = state;
	return applicationsListKey;
};

export const selectDetailsKey = state => {
	const {
		dev: {
			components: { detailsKey }
		}
	} = state;
	return detailsKey;
};

export const selectIsSideNavExpanded = state => {
	const {
		dev: { isSideNavExpanded }
	} = state;
	return isSideNavExpanded;
};

export const selectSelectedAppKey = state => {
	const { selectedAppKey } = state;
	return selectedAppKey;
};
