import createDepol from './DEPOL';

const MAP_LABELS_DEPOL_TO_NATIVE = {
	DEPOL: 'NATIVE',
	Depol: 'Native',
	depol: 'native'
};

export const toDisplayLabel = txt => MAP_LABELS_DEPOL_TO_NATIVE[txt] || txt;

export default _survey => {
	const depolMeta = createDepol(_survey);

	return {
		...depolMeta,
		toDisplayLabel,
		isNative: true
	};
};
