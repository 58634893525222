import TimeoutError from '../errors/ErrorClasses/TimeoutError';
import serializeError from '../errors/serializeError';

import { summarizeStatistics } from './helpers/summaryStatistics';

const createEmptyLogs = () => {
	return {
		keysSet: [],
		keysFound: [],
		keysNotFound: [],
		keysDeleted: [],
		errors: [],
		keysPurgedFromCleanup: []
	};
};

const getGlobalIndexedDbObject = () => {
	// Taken from https://developer.mozilla.org/en-US/docs/Web/API/IDBFactory/open
	return (
		global.indexedDB ||
		global.mozIndexedDB ||
		global.webkitIndexedDB ||
		global.msIndexedDB
	);
};
/**
 * This is an abstraction around the IndexedDB database
 *
 * Note that this is built to handle any errors internally (expect for instantiation of the class).
 *  - You can choose to handle promise rejects by passing in `returnRejectedPromise:true` as an option...otherwise, you will only have to handle .then operations
 *    - Note that it is recommended to pass `returnRejectedPromise:true` on your local, but set to false in prod
 *  - You can also create a callback when an error is detected by passing in `onError` as callback optional property
 *    - Note that onError will only be called once if there is an issue with creating the database, otherwise this callback may be hit multiple times
 *  - For each operation (get/set/delete) you can pass in an optional parameter "returnAllErrors=true" which will force the system to always return errors
 */

const FIELDS_TO_INVALIDATE_DATA = {
	DATE_ADDED: 'dateAdded',
	DATE_LAST_ACCESSED: 'dateLastAccessed'
};

const indexedDbBaseVersions = [
	1, // Original when first setting up indexeDb
	2 // When adding admin metadata store
];

const getDefaultDatabaseVersion = () => {
	// Get the latest (last) version
	return indexedDbBaseVersions.slice(-1)[0];
};
export default class IndexeddbCache {
	/** How we keep track of the db throughout */
	dbPromise = null;

	indexedDB = getGlobalIndexedDbObject();

	/** Database Parameters */
	databaseName = null;

	storeName = null;

	primaryKey = null;

	/** Options */
	verbose = false;

	// We let the application deside to return errors (ie so they can use .catch)...otherwise all errors return success (so that .then is always callable)
	returnRejectedPromise = false;

	// Application can decide to send in an `onError` callback - this is useful for loggging errors
	onError = null;

	onCleanup = null;

	maxTimeAllotedToDBStartup = 3000;

	maxAge = null;

	delayForCleanup = 1000 * 20; // 20 seconds

	dbVersion = getDefaultDatabaseVersion();

	fieldNameToInvalidate = FIELDS_TO_INVALIDATE_DATA.DATE_LAST_ACCESSED;

	/** Global Constants */

	ADMIN_METADATA_STORE_NAME = '__indexedDb_info';

	REQUIRED_ADMIN_STORE_FIELDS = ['dateAdded', 'storeName'];

	ADMIN_STORE_FIELDS_NEEDING_GET_UPDATES = ['dateLastAccessed'];

	FIELDS_TO_INVALIDATE_DATA = FIELDS_TO_INVALIDATE_DATA;

	/** Bells and Whistles */
	logs = createEmptyLogs();

	cleanupTimout = null;

	cleanupStarted = false;

	constructor(databaseName, storeName, primaryKey, options = {}) {
		if (!databaseName) {
			throw new TypeError('databaseName is a required parameter');
		}
		if (!storeName) {
			throw new TypeError('storeName is a required parameter');
		}
		if (!primaryKey) {
			throw new TypeError(
				'primaryKey is a required parameter - it is used as the search parameter to find an object later'
			);
		}
		this.databaseName = databaseName;
		this.storeName = storeName;
		this.primaryKey = primaryKey;

		const {
			maxTimeAllotedToDBStartup,
			verbose = false,
			onError,
			onCleanup,
			returnRejectedPromise = false,
			dbVersion,
			fieldNameToInvalidate,
			// time in milliseconds
			maxAge = null,
			delayForCleanup
		} = options;

		this.verbose = verbose;
		this.onError = onError;
		this.onCleanup = onCleanup;
		this.returnRejectedPromise = returnRejectedPromise;

		if (maxTimeAllotedToDBStartup) {
			this.maxTimeAllotedToDBStartup = maxTimeAllotedToDBStartup;
		}

		if (maxAge) {
			this.maxAge = maxAge;
		}

		if (delayForCleanup) {
			this.delayForCleanup = delayForCleanup;
		}

		if (dbVersion) {
			this.dbVersion = dbVersion;
		}

		if (fieldNameToInvalidate) {
			this.setFieldToInvalidateData(fieldNameToInvalidate);
		}

		this.dbPromise = this.initDatabase();
		this.setCleanupTimeout();
	}

	setFieldToInvalidateData = fieldName => {
		// This should actually bubble up
		const isFieldNameRecognized = Object.values(
			this.FIELDS_TO_INVALIDATE_DATA
		).includes(fieldName);

		if (!isFieldNameRecognized) {
			throw new TypeError(
				`Cannot set field "${fieldName}" to invalidate data - this field needs to be configured`
			);
		}

		const isFieldNameIncludedInMetadata = [
			...this.REQUIRED_ADMIN_STORE_FIELDS,
			...this.ADMIN_STORE_FIELDS_NEEDING_GET_UPDATES
		].includes(fieldName);

		if (!isFieldNameIncludedInMetadata) {
			throw new TypeError(
				`Cannot set field "${fieldName}" to invalidate data - this field is not included in metadata`
			);
		}

		this.fieldNameToInvalidate = fieldName;
	};

	initDatabase = () => {
		let initDbTimeout = null;
		const _clearTimeout = () => {
			if (initDbTimeout === null) {
				return;
			}

			clearTimeout(initDbTimeout);
			initDbTimeout = null;
		};
		// If we reject this here, it will always bubble down and possibly mess up our data
		return new Promise((res, rej) => {
			let request;

			let processFinished = false;
			let timeoutError = false;
			initDbTimeout = setTimeout(() => {
				if (processFinished) {
					return;
				}

				timeoutError = true;
				const errorMessage = `IndexedDB took longer than ${this.maxTimeAllotedToDBStartup} milliseconds and had to be stopped`;
				const error = new TimeoutError(errorMessage);
				this._handleError(error, { step: 'init-timeout' });
				if (this.returnRejectedPromise) {
					rej(error);
					return;
				}
				res();
			}, this.maxTimeAllotedToDBStartup);

			try {
				request = this.indexedDB.open(this.databaseName, this.dbVersion);
			} catch (e) {
				_clearTimeout();
				this._handleError(e, { step: 'init-open-db' });

				if (timeoutError) {
					return;
				}

				if (this.returnRejectedPromise) {
					rej(e);
					return;
				}

				res();
				return;
			}
			request.onerror = event => {
				_clearTimeout();
				processFinished = true;
				this._handleError(event, { step: 'init-open-db' });
				if (timeoutError) {
					return;
				}
				if (this.returnRejectedPromise) {
					rej(event);
				}

				res();
			};
			// If this is called, then we need to create datastore
			request.onupgradeneeded = event => {
				_clearTimeout();
				processFinished = true;
				const db = event.target.result;

				if (timeoutError) {
					return;
				}

				try {
					this.createDataStores(db);
				} catch (e) {
					this._handleError(e, { step: 'init-create-data-store' });
					if (this.returnRejectedPromise) {
						rej(event);
						return;
					}

					res();
				}
			};
			// If this is called, datastore is created already and upgrade has already happened
			request.onsuccess = event => {
				_clearTimeout();
				processFinished = true;

				const db = event.target.result;

				if (timeoutError) {
					return;
				}

				res(db);
			};
		});
	};

	clearCleanupTimeout = () => {
		if (this.cleanupTimout !== null) {
			clearTimeout(this.cleanupTimout);

			this.cleanupTimout = null;
		}
	};

	// Essentially a debounce
	setCleanupTimeout = () => {
		this.clearCleanupTimeout();

		if (this.cleanupStarted) {
			return;
		}

		this.cleanupTimout = setTimeout(this.cleanupDatabase, this.delayForCleanup);
	};

	cleanupDatabase = async dbFromProps => {
		this.clearCleanupTimeout();
		this._printLog('Cleanup Started');
		this.cleanupStarted = true;

		let db;
		if (dbFromProps) {
			db = dbFromProps;
		} else {
			try {
				db = await this.dbPromise;
			} catch {
				return;
			}
		}

		try {
			const { timeSpentWithCleanup, keysToPurge } = await this._purgeOldItems(
				db
			);

			if (this.onCleanup) {
				this.onCleanup({ timeSpentWithCleanup, keysToPurge });
			}
			this._printLog(
				`Cleanup Finished - Took ${timeSpentWithCleanup} ms to cleanup ${keysToPurge.length} keys`
			);
		} catch (error) {
			this._printLog('Error With Cleanup');
			const additionalErrorInfo = {
				step: 'cleanup'
			};

			this._handleError(error, additionalErrorInfo);
		}
	};

	createDataStores = db => {
		const storeNames = [this.storeName, this.ADMIN_METADATA_STORE_NAME];
		return storeNames.map(storeName => {
			if (db.objectStoreNames.contains(storeName)) {
				return null;
			}

			return db.createObjectStore(storeName, {
				keyPath: this.primaryKey
			});
		});
	};

	// A "transaction" in indexeddb is a wrapper that can contain multiple stores
	getTransaction(db, mode, storeName = this.storeName) {
		// There was an error but it has already been handled somewhere
		if (!db) {
			return Promise.resolve();
		}
		try {
			// Our transaction is always just pulling a single store, but we could send an array of stores if we wanted
			const transaction = db.transaction(storeName, mode);
			return Promise.resolve(transaction);
		} catch (error) {
			// debugger;
			return Promise.reject(error);
		}
	}

	getDataStore = (db, write = false, options = {}) => {
		const { storeName = this.storeName } = options;
		const mode = write ? 'readwrite' : 'readonly';

		return this.getTransaction(db, mode, storeName).then(transaction => {
			// There was an error but it has already been handled somewhere
			if (!transaction) {
				return null;
			}

			return transaction.objectStore(storeName);
		});
	};

	/** Helper methods related to Admin Metadata - a store that handles metadata for all other user-defined stores */
	_purgeOldItems = async db => {
		const { maxAge } = this;
		if (!maxAge) {
			return db;
		}

		const {
			items: allMetadataRecords
		} = await this._getAllAdminMetadataStoreInfo(db);

		// TODO: have a sanity check that the count of records are same between the

		const currentTime = new Date();

		const itemsToPurge = allMetadataRecords.filter(r => {
			const dateToInvalidate = r[this.fieldNameToInvalidate];
			const timeDiff = currentTime - dateToInvalidate;

			return timeDiff > maxAge;
		});

		const keysToPurge = itemsToPurge.map(
			metadataObject => metadataObject.primaryKeyValue
		);

		const purgePromises = keysToPurge.map(primaryKeyValue =>
			this.delete(primaryKeyValue, { db })
		);

		await Promise.all(purgePromises);

		keysToPurge.forEach(primaryKeyValue => {
			this.logs.keysPurgedFromCleanup.push(primaryKeyValue);
		});

		const timeSpentWithCleanup = new Date() - currentTime;

		return {
			timeSpentWithCleanup,
			keysToPurge
		};
	};

	_getAllAdminMetadataStoreInfo = async db => {
		const storeName = this.ADMIN_METADATA_STORE_NAME;
		const objectStoreAction = objectStore => objectStore.getAll();

		const request = await this._performAsyncOperationOnDb(
			true,
			objectStoreAction,
			{
				storeName,
				db,
				additionalErrorInfo: {
					step: 'admin-pull-all'
				}
			}
		);

		const allItems = request.result;

		const itemsInThisStore = allItems.filter(
			item => item.storeName === this.storeName
		);

		return { items: itemsInThisStore, allItemsCount: allItems.length };
	};

	_getAdminMetadataStoreInfoForObject = async primaryKeyValue => {
		await this._validatePrimaryKeyValue(primaryKeyValue);

		const storeName = this.ADMIN_METADATA_STORE_NAME;

		const id = this._createAdminMetadataId({
			[this.primaryKey]: primaryKeyValue
		});

		return this._getObjectWithPromise(id, {
			returnAllErrors: true,
			quiet: true,
			storeName
		});
	};

	_getMetadataFieldValue = fieldName => {
		switch (fieldName) {
			case 'dateLastAccessed':
			case 'dateAdded':
				return new Date();
			case 'storeName':
				return this.storeName;
			default:
				throw new Error(`Unknown field name "${fieldName}"`);
		}
	};

	_extendMetadataWithRequiredInfo = (metadataObject, options = {}) => {
		const { inplace = false } = options;

		let newMetadataObject;
		if (inplace) {
			newMetadataObject = metadataObject;
		} else {
			newMetadataObject = { ...metadataObject };
		}

		this.REQUIRED_ADMIN_STORE_FIELDS.forEach(fieldName => {
			if (newMetadataObject[fieldName] !== undefined) {
				return;
			}
			newMetadataObject[fieldName] = this._getMetadataFieldValue(fieldName);
		});

		this._setMetadataForGetTrigger(newMetadataObject);

		return newMetadataObject;
	};

	_setMetadataForGetTrigger(metadataObject) {
		this.ADMIN_STORE_FIELDS_NEEDING_GET_UPDATES.forEach(fieldName => {
			// eslint-disable-next-line no-param-reassign
			metadataObject[fieldName] = this._getMetadataFieldValue(fieldName);
		});
	}

	_ensureMetadataIsUpdatedForObject = async object => {
		const primaryKeyValue = object[this.primaryKey];

		const metadataObject = await this._getAdminMetadataStoreInfoForObject(
			primaryKeyValue
		);

		if (!metadataObject) {
			return this._setAdminMetadataForObject(object);
		}

		return this._updateMetadataWithMissingData(metadataObject, object);
	};

	_deleteAdminMetadataObjectById = async (primaryKeyValue, db) => {
		const storeName = this.ADMIN_METADATA_STORE_NAME;

		await this._deleteObjectWithPromise(primaryKeyValue, {
			returnAllErrors: true,
			storeName,
			db,
			// TODO: This is a big deal
			updateMetadata: false
		});
	};

	_triggerAdminMetadataDelete = async (primaryKeyValue, db) => {
		const id = this._createAdminMetadataId({
			[this.primaryKey]: primaryKeyValue
		});
		try {
			return this._deleteAdminMetadataObjectById(id, db);
		} catch (error) {
			const additionalErrorInfo = {
				primaryKeyValue,
				step: 'metadata-delete'
			};
			return this._handlePromiseError(error, additionalErrorInfo).then(
				() => db
			);
		}
	};

	_deleteAdminMetadataFromObject = async object => {
		const primaryKeyValue = object[this.primaryKey];
		const id = this._createAdminMetadataId({
			[this.primaryKey]: primaryKeyValue
		});

		return this._deleteAdminMetadataObjectById(id);
	};

	_setAdminMetadataForObject = async (object, options = {}) => {
		const { replace } = options;

		if (replace) {
			await this._deleteAdminMetadataFromObject(object);
		}

		this._createAdminMetadataForObject(object);
	};

	_updateMetadataWithMissingData = async (metadataObject, object) => {
		const newMetadataInfo = this._extendMetadataWithRequiredInfo(
			metadataObject
		);

		await this._deleteAdminMetadataFromObject(object);

		await this._setMetadataInfo(newMetadataInfo);
	};

	_setMetadataInfo = async metadataObject => {
		const storeName = this.ADMIN_METADATA_STORE_NAME;
		await this._setObjectWithPromise(metadataObject, {
			returnAllErrors: true,
			storeName
		});
	};

	_createAdminMetadataId = object => {
		const { storeName, primaryKey } = this;
		const primaryKeyValue = object[primaryKey];

		return `${storeName}-${primaryKeyValue}`;
	};

	_createAdminMetadataForObject = async object => {
		const primaryKeyValue = object[this.primaryKey];

		const id = this._createAdminMetadataId(object);

		const metadataObject = {
			// TODO: Id should be created using storeName+primaryKeyValue
			id,
			primaryKeyName: this.primaryKey,
			primaryKeyValue,
			storeName: this.storeName
		};

		this._extendMetadataWithRequiredInfo(metadataObject, {
			inplace: true
		});

		this._setMetadataInfo(metadataObject);
	};

	/** Triggers for Admin Metadata - any changes to a user-defined store should call one of these */
	_triggerAdminMetadataSet = async object => {
		try {
			return this._setAdminMetadataForObject(object, {
				replace: true
			});
		} catch (error) {
			const primaryKeyValue = object[this.primaryKey];
			const additionalErrorInfo = {
				primaryKeyValue,
				step: 'metadata-set'
			};

			return this._handlePromiseError(error, additionalErrorInfo);
		}
	};

	_triggerAdminMetadataGet = async object => {
		try {
			return this._ensureMetadataIsUpdatedForObject(object);
		} catch (error) {
			const primaryKeyValue = object[this.primaryKey];
			const additionalErrorInfo = {
				primaryKeyValue,
				step: 'metadata-get'
			};
			return this._handlePromiseError(error, additionalErrorInfo);
		}
	};

	/** Helper methods for data control */
	_validatePrimaryKeyValue = (primaryKeyValue, returnAllErrors = false) => {
		if (primaryKeyValue === undefined) {
			const additionalErrorInfo = {
				step: 'validate-primary-key-undefined'
			};
			return this._handlePromiseError(
				new TypeError('primaryKey must be valid in order to delete'),
				additionalErrorInfo,
				returnAllErrors
			);
		}

		const validTypes = ['string', 'number'];
		if (!validTypes.includes(typeof primaryKeyValue)) {
			const additionalErrorInfo = {
				primaryKeyValue,
				step: 'validate-primary-key-invalid'
			};
			return this._handlePromiseError(
				new TypeError(`primaryKey must be a ${validTypes.join(' or ')}`),
				additionalErrorInfo,
				returnAllErrors
			);
		}

		return Promise.resolve();
	};

	_printLog = (...args) => {
		if (!this.verbose) {
			return;
		}

		// eslint-disable-next-line no-console
		console.log(...args);
	};

	_methodPrintLogs = (message, storeName) => {
		const isMetadata = storeName === this.ADMIN_METADATA_STORE_NAME;

		const newMessage = `${isMetadata ? 'metadata' : 'object'} ${message}`;

		this._printLog(newMessage);
	};

	_handleError = (error, additionalErrorInfo = {}) => {
		const realError = serializeError(error);
		this.logs.errors.push({ error: realError, additionalErrorInfo });

		this._printLog(realError);

		if (!this.onError) {
			return;
		}

		this.onError(realError, additionalErrorInfo);
	};

	_handlePromiseError = (
		error,
		additionalErrorInfo,
		returnAllErrors = false
	) => {
		this._handleError(error, additionalErrorInfo);
		if (this.returnRejectedPromise || returnAllErrors) {
			return Promise.reject(error);
		}

		return Promise.resolve();
	};

	_performAsyncOperationOnDb = (returnAllErrors, fn, options = {}) => {
		const {
			write = false,
			storeName = this.storeName,
			db: dbFromProps,
			additionalErrorInfo
		} = options;

		const dbPromise = dbFromProps
			? Promise.resolve(dbFromProps)
			: this.dbPromise;
		return dbPromise
			.catch(e => {
				// We don't usually want to return db init errors within get/set/delete operations unless explicitly instructed to
				if (returnAllErrors) {
					throw e;
				}
			})
			.then(db => {
				// This should be set every time we do anything with the database
				this.setCleanupTimeout();

				return this.getDataStore(db, write, { storeName }).then(objectStore => {
					return new Promise((res, rej) => {
						if (!objectStore) {
							// There was an error but it has already been handled somewhere
							res();
							return;
						}

						const request = fn(objectStore);

						request.onerror = event => {
							this._handleError(event, additionalErrorInfo);
							if (this.returnRejectedPromise) {
								rej(event);
								return;
							}

							res(request);
						};
						request.onsuccess = () => res(request);
					});
				});
			});
	};

	/** Private methods to handle get/set/delete operations */
	_setObjectWithPromise = (object, options = {}) => {
		const {
			returnAllErrors = false,
			storeName = this.storeName,
			updateMetadata = false
		} = options;
		const primaryKeyValue = object[this.primaryKey];
		const additionalErrorInfo = {
			primaryKeyValue,
			step: 'set',
			updateMetadata
		};
		const objectStoreAction = objectStore => objectStore.add(object);
		return this._performAsyncOperationOnDb(returnAllErrors, objectStoreAction, {
			write: true,
			storeName,
			additionalErrorInfo
		})
			.then(() => {
				this._methodPrintLogs('set', storeName);
				this.logs.keysSet.push(primaryKeyValue);

				if (updateMetadata) {
					return this._triggerAdminMetadataSet(object);
				}

				return null;
			})
			.catch(e => this._handlePromiseError(e, additionalErrorInfo));
	};

	_getObjectWithPromise = (primaryKeyValue, options = {}) => {
		const {
			returnAllErrors = false,
			quiet = false,
			storeName = this.storeName,
			updateMetadata = false
		} = options;
		const objectStoreAction = objectStore => objectStore.get(primaryKeyValue);

		const additionalErrorInfo = {
			primaryKeyValue,
			step: 'get',
			updateMetadata
		};

		return this._performAsyncOperationOnDb(returnAllErrors, objectStoreAction, {
			storeName,
			additionalErrorInfo
		})
			.then(request => {
				if (!request.result) {
					this.logs.keysNotFound.push(primaryKeyValue);
					if (!quiet) {
						this._methodPrintLogs('not found', storeName);
					}
					return undefined;
				}

				if (!quiet) {
					this._methodPrintLogs('found', storeName);
				}
				const object = request.result;

				this.logs.keysFound.push(primaryKeyValue);

				if (updateMetadata) {
					return this._triggerAdminMetadataGet(object).then(() => object);
				}

				return object;
			})
			.catch(e => this._handlePromiseError(e, additionalErrorInfo));
	};

	_deleteObjectWithPromise = (primaryKeyValue, options = {}) => {
		const {
			returnAllErrors,
			storeName = this.storeName,
			updateMetadata = false,
			db
		} = options;
		this._methodPrintLogs('deleted', storeName);

		const objectStoreAction = objectStore =>
			objectStore.delete(primaryKeyValue);

		const additionalErrorInfo = {
			primaryKeyValue,
			step: 'delete',
			updateMetadata
		};
		return this._performAsyncOperationOnDb(returnAllErrors, objectStoreAction, {
			write: true,
			storeName,
			db,
			additionalErrorInfo
		})
			.then(() => {
				this.logs.keysDeleted.push(primaryKeyValue);

				if (updateMetadata) {
					return this._triggerAdminMetadataDelete(primaryKeyValue, db);
				}

				return null;
			})
			.catch(e => this._handlePromiseError(e, additionalErrorInfo));
	};

	/** Public functions */
	set = async (object, options = {}) => {
		const { returnAllErrors = false, replace = false } = options;
		if (!object || Object.keys(object).length === 0) {
			return this._handlePromiseError(
				new TypeError('Can only store objects with valid keys'),
				{
					step: 'set'
				},
				returnAllErrors
			);
		}
		const primaryKeyValue = object[this.primaryKey];
		if (replace) {
			await this.delete(primaryKeyValue, {
				returnAllErrors,
				updateMetadata: true
			});
		}

		return this._validatePrimaryKeyValue(primaryKeyValue).then(() =>
			this._setObjectWithPromise(object, {
				returnAllErrors,
				updateMetadata: true
			})
		);
	};

	get = (primaryKeyValue, options = {}) => {
		const { returnAllErrors = false } = options;
		return this._validatePrimaryKeyValue(primaryKeyValue).then(() =>
			this._getObjectWithPromise(primaryKeyValue, {
				returnAllErrors,
				updateMetadata: true
			})
		);
	};

	delete = (primaryKeyValue, options = {}) => {
		const { returnAllErrors = false, db } = options;
		return this._validatePrimaryKeyValue(primaryKeyValue).then(() =>
			this._deleteObjectWithPromise(primaryKeyValue, {
				returnAllErrors,
				updateMetadata: true,
				db
			})
		);
	};

	resetLogs = () => {
		this.logs = createEmptyLogs();
	};

	getStats = (verbose = false) => {
		const {
			primaryKey,
			dbVersion,
			storeName,
			databaseName,
			maxAge,
			logs
		} = this;
		const statsFull = {
			primaryKey,
			dbVersion,
			storeName,
			databaseName,
			maxAge,
			...logs
		};

		if (verbose) {
			return statsFull;
		}

		return summarizeStatistics(statsFull);
	};

	// TODO: This is untested
	deleteThisDatabase = () => {
		this.indexedDB.deleteDatabase(this.databaseName);
	};
}
