import ajax from '../../../../scanline/utils/ajax';

export const holder = () => {};

export const saveNewInspections = (inspectionDate, newInspections) => {
	return ajax(
		`/manager/save/inspections`,
		JSON.stringify({ inspectionDate, newInspections }),
		'POST',
		undefined,
		false,
		'assetview'
	);
};
