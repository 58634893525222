import _ from 'lodash';
import sphereKnn from 'sphere-knn';

export const createKnnCluster = readings => {
	return sphereKnn(
		readings.map((reading, index) => ({
			lat: reading.loc[0],
			lon: reading.loc[1],
			index
		}))
	);
};

export const memCreateKnnCluster = _.memoize(createKnnCluster);
