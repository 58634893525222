export const getContentLengthOfJson = json => {
	return JSON.stringify(json).length;
};

export const getFileExtension = fileName => {
	const filePieces = (fileName || '').split('.');
	if (filePieces.length < 2) {
		return undefined;
	}

	return filePieces.pop();
};
