export const NS_MAPS = 'cisview_maps_';

export const SET_HIGHLIGHT = `${NS_MAPS}SET_HIGHLIGHT`;
export const setHighlight = highlight => {
	return {
		type: SET_HIGHLIGHT,
		payload: highlight
	};
};

export const EXTENT_CHANGED = `${NS_MAPS}EXTENT_CHANGED`;
export const extentChanged = (center, level) => {
	return {
		type: EXTENT_CHANGED,
		payload: { center, level }
	};
};

export const SET_MAP = `${NS_MAPS}SET_MAP`;
export const setMap = map => {
	return {
		type: SET_MAP,
		payload: map
	};
};

export const SET_SHOW_COMMENTS_MODAL = `${NS_MAPS}SET_SHOW_COMMENTS_MODAL`;
export const setShowCommentsModal = (showcommentsModal = false) => {
	return {
		type: SET_SHOW_COMMENTS_MODAL,
		payload: {
			showcommentsModal
		}
	};
};

export const SET_TOGGLE_COMMENT_ICONS = `${NS_MAPS}SET_TOGGLE_COMMENT_ICONS`;
export const toggleCommentIcons = key => {
	return {
		type: SET_TOGGLE_COMMENT_ICONS,
		payload: {
			key
		}
	};
};

export const SET_TOGGLE_EXCLUDE_COMMENT = `${NS_MAPS}SET_TOGGLE_EXCLUDE_COMMENT`;
export const toggleExcludeComment = key => {
	return {
		type: SET_TOGGLE_EXCLUDE_COMMENT,
		payload: {
			key
		}
	};
};

export const SET_TOGGLE_ALL_COMMENT_ICONS = `${NS_MAPS}SET_TOGGLE_ALL_COMMENT_ICONS`;
export const toggleAllIcons = () => {
	return {
		type: SET_TOGGLE_ALL_COMMENT_ICONS
	};
};
