/* eslint-disable import/no-cycle */
import { point as turfPoint } from '@turf/turf';

// eslint-disable-next-line import/no-cycle
import Memoize from 'aegion_common_utilities/lib/memoize';
import * as MapProcessingUtil from 'aegion_common_utilities/lib/MapProcessingUtil';
import LocationType from 'aegion_common_utilities/lib/ReadingsUtil/LocationType';
import AutoCorrectionUtil from '../../../../utils/AutoCorrectionUtil';
import mapUtils from '../../../../../scanline/mapUtils';
import { getDatFile, getDatsinExtent } from '../../../../utils/DatFiles';
import * as toolbarUtils from './toolbarUtils';

export const getAllIndexBetweenStartAndEnd = (x, y) => {
	const numbers = [];
	for (let i = x; i <= y; i += 1) {
		numbers.push(i);
	}
	return numbers;
};

export const tryToApplyMachineLearningCorrections = datFile => {
	if (datFile.coordinateLedger) {
		return AutoCorrectionUtil.mergeCoordinatesWithFlags(datFile); // Merges haven't not been made yet but they do need to be applied
	}

	return datFile.gpsreadings;
};

const getGPSReadingFromStationNum = (dat, stationNum) => {
	const [gpsReading = {}] = dat.gpsreadings.filter(
		gpsR => toolbarUtils.getStationNumGPSReading(gpsR) === stationNum
	);

	return gpsReading;
};

export const getIndexFromDat = (dat, dataPoint) => {
	const { point } = dataPoint;
	const { gpsIndx, stationNum } = point;
	if (
		dat.gpsreadings.indexOf(dataPoint.point.properties.gpsReading) === gpsIndx
	) {
		return gpsIndx;
	}
	const gpsReading = getGPSReadingFromStationNum(dat, stationNum);
	if (gpsReading) {
		return dat.gpsreadings.indexOf(gpsReading);
	}
	return null;
};

// A bit arbitrary, but we don't want to remember too many items - cap at 20
const memoizedGpsReadingsForSmoothing = new Memoize({ max: 20 });

// This makes a cache of the data
const _getGpsReadingsForSmoothing = dat => {
	const readingsFromCache = memoizedGpsReadingsForSmoothing.get([dat]);
	if (readingsFromCache) {
		return readingsFromCache;
	}
	const readings = tryToApplyMachineLearningCorrections(dat);

	const gpsReadings = readings.filter(
		gpsReading => gpsReading.locationType === LocationType.GPS
	);

	memoizedGpsReadingsForSmoothing.set([dat], gpsReadings);

	return gpsReadings;
};

export const getGPSReading = (data, fileName) => {
	const [dat = {}] = data.filter(({ fileName: fn }) => fn === fileName);
	return dat;
};

export const getClosestGPSReading = ({
	dats,
	mapBounds,
	latlng,
	movedPoints,
	boundsByFile
}) => {
	// Use code from editToolbar

	const datsInExtent = getDatsinExtent(dats, mapBounds, boundsByFile);
	// TODO: Get only the datFile from another smooth point

	const closestReadingWithGPS = datsInExtent
		.map(dat => {
			const gpsReadings = _getGpsReadingsForSmoothing(dat);
			return toolbarUtils.getDatClosestGPSReading({
				dat,
				mapBounds,
				latlng,
				movedPoints,
				gpsReadings
			});
		})
		.reduce((acc, datPoint) => {
			if (datPoint) {
				return acc.concat(datPoint);
			}
			return acc;
		}, []);
	const targetPoint = turfPoint([latlng.lng, latlng.lat]);

	const { point } = mapUtils.nearestPoint(targetPoint, closestReadingWithGPS);

	return point;
};

export const buildEditsArray = (indexArray, dat) => {
	const edits = [];
	indexArray.forEach(i => {
		const editObj = {};
		editObj.reading = {};
		editObj.type = 2;
		editObj.index = i;
		const correctObjfromdat = dat.gpsreadings[i];
		if (correctObjfromdat) {
			editObj.reading.coordinates = [...correctObjfromdat.coordinates];
			editObj.reading.nextGap = correctObjfromdat.nextGap;
			editObj.reading.prevGap = correctObjfromdat.prevGap;
			editObj.reading.prevAzimuth = correctObjfromdat.prevAzimuth;
			editObj.reading.locationType = correctObjfromdat.locationType;
			editObj.reading.nextAzimuth = correctObjfromdat.nextAzimuth;
			editObj.reading.prevDistance = correctObjfromdat.prevDistance;
			editObj.reading.nextDistance = correctObjfromdat.nextDistance;

			edits.push(editObj);
		}
	});
	return edits;
};

// The purpose of this file is to mutate the data. We need to send this function a copy of the data (at least gpsreadings)
// No movedPoints are being sent
// TODO: Should interact with redux
export const prepareSaveMovedPoints = (movedPoints, oldDats, oldGlobalData) => {
	const dats = oldDats.map(dat => ({
		...dat,
		gpsreadings: [...dat.gpsreadings]
	}));
	const globalData = {
		...oldGlobalData,
		MasterLST: {
			...oldGlobalData.MasterLST
		}
	};
	const editedReadings = [];
	const editedReading = {};

	Object.keys(movedPoints).forEach(key => {
		const dat = getDatFile(dats, key); // dat is a copy from above
		editedReading.name = key;
		editedReading.key = dat.fileKey;

		const indexArray = [];

		movedPoints[key].forEach(movedPoint => {
			const gpsRIndx = getIndexFromDat(dat, { point: movedPoint });
			const originalGpsReading = dat.gpsreadings[gpsRIndx];
			// console.log("%s %sO", "that.movedPoints", movedPoint.point.properties.gpsReading);
			if (originalGpsReading && movedPoint.editLatLng) {
				const distance = MapProcessingUtil.calculateDistance(
					originalGpsReading.coordinates.slice(0, 2).reverse(),
					[movedPoint.editLatLng.lng, movedPoint.editLatLng.lat]
				);
				const coordinates = [...originalGpsReading.coordinates.slice(0, 3)];
				if (distance > 20) {
					coordinates.splice(2);
				}
				const newGPSReading = {
					...originalGpsReading,
					coordinates
				};
				// didn't add to the copy since there are other items in the array coordinates
				newGPSReading.coordinates[0] = movedPoint.editLatLng.lat;
				newGPSReading.coordinates[1] = movedPoint.editLatLng.lng;
				// replace the gpsReading with new Reading
				dat.gpsreadings.splice(gpsRIndx, 1, newGPSReading);
				dat.isEdited = true; // dat is a copy from above
			}
			try {
				const prevGpsReading = MapProcessingUtil.getPreviousGpsReading(
					dat.gpsreadings,
					gpsRIndx - 1
				);
				indexArray.push(
					...getAllIndexBetweenStartAndEnd(prevGpsReading.i, gpsRIndx).filter(
						i => indexArray.indexOf(i) === -1
					)
				);
			} catch (err) {
				console.log('No Prev Reading');
			}
			try {
				const nextGpsReading = MapProcessingUtil.getNextGpsReading(
					dat.gpsreadings,
					gpsRIndx + 1
				);
				indexArray.push(
					...getAllIndexBetweenStartAndEnd(gpsRIndx, nextGpsReading.i).filter(
						i => indexArray.indexOf(i) === -1
					)
				);
			} catch (err) {
				console.log('No Next Reading');
			}
		});
		indexArray.sort((a, b) => a - b);

		// need to update all points before calc if new differences
		movedPoints[key].forEach(movedPoint => {
			// get gpsReadings
			const originalGpsReadings = dat.gpsreadings;

			const index = getIndexFromDat(dat, { point: movedPoint });

			MapProcessingUtil.updateGPSMeasurements(originalGpsReadings, {
				index,
				isUpdateInterpolatedCoords: true,
				createCopy: true
			});
		});
		const edits = buildEditsArray(indexArray, dat);
		editedReading.edits = edits;
		editedReadings.push(editedReading);

		const { datFile } = dat;

		globalData.MasterLST[datFile] = {
			...oldGlobalData.MasterLST[datFile],
			gpsreadings: dat.gpsreadings,
			isEdited: dat.isEdited
		};
	});

	return [dats, globalData, editedReadings];

	// that.undoSmoothControl.classList.remove('leaflet-disabled');
	// that.clear()
	// return isEdited;
};

// TODO: Should interact with redux
export const prepareSaveSmoothedPoints = (
	smoothedPoints,
	oldDats,
	oldGlobalData
) => {
	const dats = oldDats.map(dat => ({
		...dat,
		gpsreadings: [...dat.gpsreadings]
	}));
	const globalData = {
		...oldGlobalData,
		MasterLST: {
			...oldGlobalData.MasterLST
		}
	};
	const editedReadings = [];
	const editedReading = {};
	smoothedPoints.forEach(sp => {
		if (sp.length > 1) {
			const { dat: { fileName, fileKey } = {} } = sp[0].point;
			const dat = getDatFile(dats, fileName);
			editedReading.name = fileName;
			editedReading.key = fileKey;
			editedReading.edits = [];

			sp.forEach((pnt, idx) => {
				if (idx < sp.length - 1) {
					const sPos = getIndexFromDat(dat, pnt);
					const ePos = getIndexFromDat(dat, sp[idx + 1]);
					const interpolatedPoints = MapProcessingUtil.switchToInterpolated(
						dat.gpsreadings.slice(sPos + 1, ePos)
					);

					const indexArray = getAllIndexBetweenStartAndEnd(sPos, ePos);
					dat.gpsreadings.splice(
						sPos + 1,
						ePos - sPos - 1,
						...interpolatedPoints
					);

					MapProcessingUtil.updateGPSMeasurements(dat.gpsreadings, {
						index: ePos,
						isUpdateInterpolatedCoords: true,
						createCopy: true
					});

					dat.isEdited = true;
					const edits = buildEditsArray(indexArray, dat);
					editedReading.edits.push(...edits);
				}
			});
			editedReadings.push(editedReading);

			const { datFile } = dat;
			globalData.MasterLST[datFile] = {
				...oldGlobalData.MasterLST[datFile],
				gpsreadings: dat.gpsreadings,
				isEdited: dat.isEdited
			};
		}
	});
	// return array of all edited readings
	return [dats, globalData, editedReadings];
};

export { toolbarUtils };
