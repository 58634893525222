/* eslint-disable no-confusing-arrow */
import { getSurveyIdFromChartId } from '../../../../../utils/chart';
import { INITIAL_THRESHOLDS } from './constants';

export const isDefined = v => v !== null && v !== undefined;

export const hasThresholds = thresholds =>
	isDefined(thresholds) && thresholds.filter(t => isDefined(t)).length > 0;

export const getId = (val = {}) => {
	if (typeof val === 'object') {
		return val.id;
	}

	return val;
};

export const getFirstItem = (arr = []) =>
	arr && arr.length ? arr[0] : undefined;

export const getLastItem = (arr = []) =>
	arr && arr.length > 1 ? arr[arr.length - 1] : undefined;

export const findGroup = (readingGroups = [], paramGroup) => {
	const start = getFirstItem(paramGroup);
	const end = getLastItem(paramGroup);

	return (
		readingGroups.find(g => {
			const s = getFirstItem(g);
			const e = getLastItem(g);
			return getId(s) === getId(start) && getId(e) === getId(end);
		}) || undefined
	);
};

export const findGroupByReading = (readingGroups = [], reading) => {
	const id = getId(reading);

	return (
		readingGroups.find(g => {
			const s = getFirstItem(g);
			const e = getLastItem(g);
			return getId(s) <= id && getId(e) >= id;
		}) || undefined
	);
};

// export const createXYMinMaxObject = (
// 	{ xMin, xMax, yMin, yMax },
// 	originalXYMinMaxObject = {}
// ) => {
// 	return {
// 		xMin: xMin !== undefined ? xMin : originalXYMinMaxObject.xMin,
// 		xMax: xMax !== undefined ? xMax : originalXYMinMaxObject.xMax,
// 		yMin: yMin !== undefined ? yMin : originalXYMinMaxObject.yMin,
// 		yMax: yMax !== undefined ? yMax : originalXYMinMaxObject.yMax
// 	};
// };

export const createInitialChartSettings = chartId => {
	const surveyId = getSurveyIdFromChartId(chartId);
	return {
		thresholds: INITIAL_THRESHOLDS,
		thresholdsPendingThresholdsValue: undefined,
		thresholdsPendingChangeRequiresConfirmation: false,
		useThreshold: false,
		useShadow: false,
		useSawtooth: false,
		showSpatial: false,
		primarySurveyId: surveyId,
		depolSurveyId: undefined,
		belowCriterionComparator: 'GREATER_THAN',
		useNormalizedDepol: true,
		useInterpolatedDepol: false,
		selectedRemediationGroup: undefined,
		selectedRemediationGroupIndex: -1,
		selectedRemediationGroupRange: undefined,
		selectedRemediationGroupRangeIndex: -1,
		isVisible: false,
		inputThresholdResetToken: undefined, // sometimes we reject the threshold value change made by user, this allows us to inform the html input element to change back to prev value
		seriesVisibilityResetToken: undefined, // sometimes we reject the chart series visibility toggles made by user, this allows us to inform the chart to reset series visibility to current redux state

		// format = { [surveyIdOrSeriesName]: { [plottedValueKey]: blnIsVisible }}
		seriesVisibility: {},
		showConfirmation: false,
		confirmationMessageKey: undefined,
		showDecimatedData: false,

		seriesCache: []
	};
};

export default { isDefined, hasThresholds, createInitialChartSettings };
