import _ from 'lodash';
import initialState from './initialState';
import {
	CLEAR,
	SET_OLD_ASSET,
	SET_NEW_ASSET,
	SET_MERGE_PREVIEW,
	SET_MERGE_PREVIEW_DIFF,
	SET_IS_MERGING,
	SET_IS_MERGED,
	SET_MERGE_ERROR_MESSAGE,
	SET_COPY_MISSING_PROPS,
	SET_IS_MERGE_APPROVED
} from './actionTypes';

const reducer = (state = initialState, action) => {
	const { type, payload } = action;
	switch (type) {
		case CLEAR:
			return _.cloneDeep(initialState);

		case SET_OLD_ASSET:
			return { ...state, oldAsset: payload };

		case SET_NEW_ASSET:
			return { ...state, newAsset: payload };

		case SET_MERGE_PREVIEW:
			return { ...state, mergePreview: payload };

		case SET_MERGE_PREVIEW_DIFF:
			return { ...state, mergePreviewDiff: payload };

		case SET_IS_MERGING:
			return { ...state, isMerging: payload };

		case SET_IS_MERGED:
			return { ...state, isMerged: payload };

		case SET_MERGE_ERROR_MESSAGE:
			return { ...state, mergeErrorMessage: payload };

		case SET_COPY_MISSING_PROPS:
			return { ...state, copyMissingProps: payload };

		case SET_IS_MERGE_APPROVED:
			return { ...state, isMergeApproved: payload };

		default:
			return state;
	}
};

export default reducer;
