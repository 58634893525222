const USERS = {
	DEVELOPERS: {
		LOCAL_DEVELOPER: 'TESTUSERNAME', // local developer
		SHWETA: 'sarora@aegion.com', // Shweta
		KEITH: 'knevils@aegion.com' // Keith
	},
	ADMIN: {
		MATTHEW: 'mhearn@aegion.com', // Matthew
		KEITH: 'knevils@aegion.com' // Keith
	},
	DATA_PROCESSORS: {
		MAURICE: 'mvanegmond@aegion.com', // Maurice Van Egmond
		JOSE: 'jhernandez@aegion.com', // Jose Hernandez
		GERALD: 'gstephens@aegion.com', // Gerald Stephens
		ADOLFO: 'adelosreyes@aegion.com', // Adolfo De Los Reyes
		KAISHER: 'komer@aegion.com', // Kaisher Alphonse
		SHELLEY: 'struckner@aegion.com', // Shelley Truckner
		DAVID: 'dziss@aegion.com',
		MADCHEN: 'mziss@aegion.com',
		KEITH: 'knevils@aegion.com' // Keith
	},
	OTHER_STATION_ALIGNMENT_USERS: {
		ADOLFO: 'adelosreyes@aegion.com', // Adolfo De Los Reyes
		KAISHER: 'komer@aegion.com', // Kaisher Alphonse
		SHELLEY: 'struckner@aegion.com', // Shelley Truckner
		SAMANTHA: 'sgoodremote@aegion.com' // Samantha Goodremote
	}
};

export const COMMON_DEVELOPER_USERS = Object.values(USERS.DEVELOPERS);

export const COMMON_ADMIN_USERS = Object.values(USERS.ADMIN);

export const COMMON_DATA_PROCESSORS = Object.values(USERS.DATA_PROCESSORS);

export const OTHER_STATION_ALIGNMENT_USERS = Object.values(
	USERS.OTHER_STATION_ALIGNMENT_USERS
);

const getUnique = arr => {
	return [...new Set(arr)];
};

export const COMMON_BETA_TESTERS = getUnique([
	...COMMON_DEVELOPER_USERS,
	...COMMON_ADMIN_USERS,
	...COMMON_DATA_PROCESSORS
]);

export const SPIKE_EDITOR_MAGIC_WAND_BETA_TESTERS = COMMON_BETA_TESTERS;

export const STATION_ALIGNMENT_BETA_TESTERS = getUnique([
	...COMMON_BETA_TESTERS,
	...OTHER_STATION_ALIGNMENT_USERS
]);
