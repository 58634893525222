import DocJSON from 'aegion_common_utilities/lib/DocJSON';

const { GEOJSON, CSV } = DocJSON;

/* eslint-disable prefer-destructuring */
/* eslint-disable import/prefer-default-export */
export const parseFileName = (fileName = '') => {
	const parts = fileName.split('.');
	let name;
	let ext;
	if (parts.length > 0) {
		name = fileName;
		if (parts.length > 1) {
			ext = parts.splice(parts.length - 1)[0];
			name = parts.join('.');
		}
	}
	return { name, ext };
};

export const selectDocJSONType = ext => {
	const lcaseExtension = ext.toLowerCase();
	switch (lcaseExtension) {
		case 'geojson':
			return GEOJSON;
		case 'csv':
			return CSV;
		default:
			return undefined;
	}
};
