/* eslint-disable import/no-cycle */
import buildReduxMain from './Main/index';
// eslint-disable-next-line import/no-cycle
import buildReduxCustomers from './Customers/index';
import buildReduxProcessors from './Processors/index';

export const createFiltersRedux = ({
	namespace = '',
	rootSliceKey = '',
	filtersStatePropKey = 'filters'
}) => {
	const mainRedux = buildReduxMain({
		namespace,
		rootSliceKey,
		filtersStatePropKey
	});
	const customersRedux = buildReduxCustomers({
		namespace,
		rootSliceKey,
		mainRedux,
		filtersStatePropKey,
		filterStatePropKey: 'customers'
	});

	const processorsRedux = buildReduxProcessors({
		namespace,
		rootSliceKey,
		mainRedux,
		filtersStatePropKey,
		filterStatePropKey: 'processors'
	});

	const selectors = {
		...customersRedux.selectors,
		...processorsRedux.selectors,
		...mainRedux.selectors
	};

	const actions = {
		...customersRedux.actions,
		...processorsRedux.actions,
		...mainRedux.actions
	};

	const reducerParts = {
		...customersRedux.reducerParts,
		...processorsRedux.reducerParts,
		...mainRedux.reducerParts
	};

	const { initialState } = mainRedux;
	initialState[filtersStatePropKey].customers = customersRedux.initialState;
	initialState[filtersStatePropKey].processors = processorsRedux.initialState;

	return {
		selectors,
		actions,
		reducerParts,
		initialState
	};
};

export default createFiltersRedux;
