/* eslint-disable camelcase */
import initialState_actionPlanDownloadErrors from './util/ActionPlanDownloadErrors/initialState';

export const initialState = {
	mapLines: {},
	data: [],
	appInitMessage: undefined,
	appInitError: undefined,
	expandedChart: null,
	isJobsModalVisible: false,
	isLoadingMapLines: false,
	isReadingsLoading: false,
	isReadingsInitialized: false,
	isUploadingFile: false,
	isDownloadingShapefile: false,
	isDownloadingReport: false,
	isFetchingReportData: false,
	isSavingReportData: false,
	isCheckingForChangesReportData: false,
	isGeneratingShareLink: false,
	isFetchingRating: false,
	isSavingRating: false,
	isCreatingProject: false,
	shouldGetAllReadings: false,
	surveys: [],
	surveysToLoad: [],
	surveysLoading: [],
	unsupportedSurveysToLoad: [],
	unsupportedSurveysToProcess: [],
	unsupportedSurveyS3KeysToLoad: {},
	unsupportedSurveyS3KeysLoading: {},
	surveysLoadErrorMessage: undefined,
	loadingReadings: {},
	loadingUnsupportReadings: {},
	loadingUnsupportReadingsHeaders: {},
	simplifiedReadings: {},
	chartMeta: {},
	selectedReading: undefined,
	selectedReadingSurvey: undefined,
	allComponentsLocked: false,
	isMapVisible: true,
	chartsBcSeriesDs: {},
	remediationActions: {},
	defaultSurveyJobId: null,
	defaultSurvey: null,

	// external data
	countPendingParseFileRequests: 0,
	isSurveyUploaderOpen: false,

	// readings data structures
	readings: {},
	readingsWithGeoGaps: {},
	readingsWithChartGaps: {},
	readingsMap: {},
	readingsIndexMap: {},
	readingsMeta: {},
	readingsWithChartGapsIndexMap: {},
	readingsWithGeoGapsIndexMap: {},

	// interpolated readings data structures
	interpReadings: {},
	interpReadingsWithChartGaps: {},
	interpReadingsMap: {},
	interpReadingsIndexMap: {},
	interpReadingsWithChartGapsIndexMap: {},
	interpReadingsStationIdIntegers: {},

	// readings data structures
	readingsNormalized: {},
	readingsNormalizedWithGeoGaps: {},
	readingsNormalizedWithChartGaps: {},
	readingsNormalizedMap: {},
	readingsNormalizedIndexMap: {},
	readingsNormalizedMeta: {},
	readingsNormalizedWithChartGapsIndexMap: {},
	readingsNormalizedStationIdIntegers: {},

	// interpolated readingsNormalized data structures
	interpReadingsNormalized: {},
	interpReadingsNormalizedWithChartGaps: {},
	interpReadingsNormalizedMap: {},
	interpReadingsNormalizedIndexMap: {},
	interpReadingsNormalizedWithChartGapsIndexMap: {},
	interpReadingsNormalizedStationIdIntegers: {},

	// series (to be used directly in charts)
	series: {},
	seriesIndexMap: {},
	readingsStationIdIntegers: {},
	interpSeries: {},
	interpSeriesIndexMap: {},
	seriesNormalized: {},
	seriesNormalizedIndexMap: {},
	interpSeriesNormalized: {},
	interpSeriesNormalizedIndexMap: {},

	// temp readings data structures
	preNormalizedReadings: {},

	// charts
	focusedChart: undefined,
	hoveredChart: undefined,
	boundaryXMinXMax: {},
	minimum: 0,
	maximum: Infinity,
	startIndexes: {},
	endIndexes: {},
	zoomFactor: 1,
	zoomPosition: 0,
	isLoadingChartIds: [],
	isRenderingChartIds: [],
	useInterpolatedDepol: {},
	useNormalizedDepol: {},

	...initialState_actionPlanDownloadErrors,

	// PROJECT MANAGERS
	projectManagers: undefined,
	isFetchingProjectManagers: false,

	// VIEW AS
	viewAs: undefined,
	viewAsList: [],
	isLoadingViewAsList: false,
	viewAsErrorMessage: undefined,

	// alignedReadings 2.0
	alignedReadings: {},
	alignedReadingsFiltered: {},
	alignedReadingsRibbonsVisible: {}, // TODO: this should actually live inside `alignedReadings` - but we need to refactor `alignedReadings` to be based on the primaryId
	alignedReadingsThreshold: {}, // TODO: this should actually live inside `alignedReadings` - but we need to refactor `alignedReadings` to be based on the primaryId
	alignedReadingsStats: {}, // TODO: this should actually live inside `alignedReadings` - but we need to refactor `alignedReadings` to be based on the primaryId

	// filtered reading datasets - currently only used for ILI
	readingsFiltered: {},

	// managing aligned readings to be included in ILI ribbon
	alignedReadingsControl: {},

	// Data download messages
	isDataDownloadMessagesExpanded: true,
	readingsDownloadMessagesStore: {},
	readingsDownloadMessages: [],
	surveysDownloadMessagesStore: {},
	surveysDownloadMessages: []
};

export default {
	initialState
};
