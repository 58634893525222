import { getChartId } from '../../../../../utils/chart';
import { selectDefaultSurvey } from '../../../../selectors/surveys';
import { cleanContent, sortSurveyIds } from './helpers';

// PRIVATE
const computeAssumedSurveyId = (pageLoadState, appState) => {
	const defaultSurvey = selectDefaultSurvey(appState) || {};
	const { remediationActions = {} } = pageLoadState;

	const surveyIds = sortSurveyIds(Object.keys(remediationActions));
	const assumedSurveyId = surveyIds[0] || defaultSurvey.id;

	return assumedSurveyId;
};

export const transformPageLoadStateFromServer = (
	serverPageLoadState,
	getState
) => {
	const state = getState();
	const { app } = state;
	const { content = {} } = serverPageLoadState;
	const assumedSurveyId = computeAssumedSurveyId(content, app);

	const surveyId = content.surveyId || assumedSurveyId;
	const chartId = content.chartId || getChartId({ id: surveyId });

	return {
		...cleanContent(content),
		surveyId,
		chartId,
		version: '2.0',
		// @deprecated
		actionPlanVersion: '2.0'
	};
};

export default {
	transformPageLoadStateFromServer
};
