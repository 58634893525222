/* eslint-disable import/no-cycle */
import scanlineUtils from '../../../scanlineUtils';
import {
	BATCH_REQUEST_SIZE,
	BATCH_REQUEST_LIMIT,
	BATCH_REQUEST_EVENT_TYPES
} from './constants';

const {
	TOTAL_COUNT,
	LOADING_COUNT,
	LOADED_COUNT,
	COMPLETED
} = BATCH_REQUEST_EVENT_TYPES;

class BatchRequestManager {
	constructor({ onEvent = () => {}, onError = () => {} }) {
		this.initProps({ onEvent, onError });
	}

	initProps({ onEvent, onError }) {
		this.onEvent = onEvent;
		this.onError = onError;
		this.totalCount = undefined;
		this.totalBatchCount = undefined;
		this.countPendingBatchRequests = 0;
		this.previousBatchRequest = -1;
		this.data = [];
		this.error = undefined;
	}

	canLoadNextBatch = () =>
		!this.error &&
		this.countPendingBatchRequests < BATCH_REQUEST_LIMIT &&
		this.previousBatchRequest < this.totalBatchCount - 1;

	isLoaded = () =>
		!this.error &&
		this.previousBatchRequest === this.totalBatchCount - 1 &&
		this.countPendingBatchRequests === 0;

	handleError = e => {
		this.error = e;
		this.onError(e);
	};

	fireEvent(eventType, payload) {
		this.onEvent(eventType, payload, this.data);
	}

	fireTotalCountEvent() {
		this.fireEvent(TOTAL_COUNT, { count: this.totalCount });
	}

	fireLoadingCountEvent() {
		this.fireEvent(LOADING_COUNT, {
			count: this.countPendingBatchRequests * BATCH_REQUEST_SIZE
		});
	}

	fireLoadedCountEvent() {
		const loadedCount = this.isLoaded()
			? this.totalCount
			: (this.previousBatchRequest + 1 - this.countPendingBatchRequests) *
			  BATCH_REQUEST_SIZE;
		this.fireEvent(LOADED_COUNT, {
			count: loadedCount
		});
	}

	fireCompletedEvent() {
		this.fireEvent(COMPLETED, true);
	}

	async loadAllData() {
		try {
			this.reset({ onEvent: this.onEvent, onError: this.onError });
			const { count: totalCount } = await scanlineUtils.getReportsCountV2();
			this.totalCount = Number.parseInt(totalCount, 10);
			this.totalBatchCount = Math.ceil(this.totalCount / BATCH_REQUEST_SIZE);
			this.fireTotalCountEvent();
			this.requestAllBatches();
		} catch (e) {
			this.handleError(e);
		}
	}

	requestAllBatches() {
		while (this.canLoadNextBatch()) {
			this.nextBatch();
		}
	}

	onNextBatchComplete() {
		this.fireLoadingCountEvent();
		this.fireLoadedCountEvent();
		if (this.isLoaded()) {
			this.fireCompletedEvent();
		} else {
			this.nextBatch();
		}
	}

	nextBatch() {
		if (this.canLoadNextBatch()) {
			const batchRequest = this.previousBatchRequest + 1;
			const limit = BATCH_REQUEST_SIZE;
			const offset = batchRequest * BATCH_REQUEST_SIZE;
			this.countPendingBatchRequests += 1;
			this.previousBatchRequest = batchRequest;

			this.fireLoadingCountEvent();
			// this.logState('START NEXT BATCH');
			scanlineUtils.listReportsV2({ limit, offset }, (err, res) => {
				this.countPendingBatchRequests -= 1;
				if (err) {
					this.handleError(err);
				} else {
					this.pushData(res.reports, { offset });
				}
				// this.logState('END NEXT BATCH');
				this.onNextBatchComplete();
			});
		}
	}

	pushData(data, { offset }) {
		let idx = offset;
		data.forEach(v => {
			this.data[idx] = v;
			idx += 1;
		});
	}

	reset({ onEvent, onError }) {
		this.initProps({ onEvent, onError });
	}

	// logState = calledFrom => {
	// 	console.log(`CALLED FROM -> ${calledFrom}`);
	// 	console.log({
	// 		totalCount: this.totalCount,
	// 		totalBatchCount: this.totalBatchCount,
	// 		countPendingBatchRequests: this.countPendingBatchRequests,
	// 		previousBatchRequest: this.previousBatchRequest,
	// 		dataLength: this.data.length,
	// 		data: this.data
	// 	});
	// };
}

export default new BatchRequestManager({});
