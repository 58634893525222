import { computeMvTransform } from '../../../util/readings.parse.computeProps';

const voltPercentThreashold = 5;

export const shouldStandardizeToMV = (cntReadings, cntVoltReadings) => {
	const voltPercent = (cntReadings / cntVoltReadings) * 100;
	return voltPercent >= voltPercentThreashold;
};

export const standardizeToMV = reading => {
	computeMvTransform(reading, 'on');
	computeMvTransform(reading, 'off');
	computeMvTransform(reading, 'sawtooth');
};
