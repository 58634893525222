// eslint-disable-next-line import/no-cycle
import { createFiltersRedux } from './createFiltersRedux';
import { ROOT_SLICE_SURVEYS_NO_READINGS } from '../constants';

const filtersRedux = createFiltersRedux({
	namespace: 'dit-surveys-no-readings-filters',
	rootSliceKey: ROOT_SLICE_SURVEYS_NO_READINGS,
	filtersStatePropKey: 'surveysNoReadingsFilters'
});

export default filtersRedux;
