import L from 'leaflet';

import { point as turfPoint } from '@turf/turf';
import mapUtils from '../../../../../scanline/mapUtils';

export const getStationNumGPSReading = gpsReading => {
	return gpsReading.StationId;
};

export const getStationNumPoint = ({ point }) => {
	return getStationNumGPSReading(point.properties.gpsReading);
};

// We should be checking if the the point is in the previous smoothed points
export const getDatClosestGPSReading = ({
	dat,
	mapBounds,
	latlng,
	movedPoints = {},
	gpsReadings
}) => {
	const gpsReadingsInExtent = gpsReadings
		.filter(gpsReading => {
			const coord = [gpsReading.coordinates[0], gpsReading.coordinates[1]];
			const latLong = L.latLng(coord);
			return mapBounds.contains(latLong);
		})
		.map(gpsReading => {
			let pnt;
			const datMovedPoints = movedPoints[dat.fileName] || [];

			const [datMovePoint] = datMovedPoints.filter(
				mp =>
					getStationNumPoint({ point: mp }) ===
					getStationNumGPSReading(gpsReading)
			);
			// todo:
			if (datMovePoint) {
				pnt = turfPoint(
					[datMovePoint.editLatLng.lng, datMovePoint.editLatLng.lat],
					{ gpsReading, dat }
				);
			} else {
				pnt = turfPoint(
					[gpsReading.coordinates[1], gpsReading.coordinates[0]],
					{ gpsReading, dat }
				);
			}
			return pnt;
		});
	const targetPoint = turfPoint([latlng.lng, latlng.lat]);
	const nearest = mapUtils.nearestPoint(targetPoint, gpsReadingsInExtent);

	const { point } = nearest;
	if (!point) {
		return null;
	}
	const stationNum = getStationNumPoint(nearest);
	const gpsIndx = dat.gpsreadings.indexOf(nearest.point.properties.gpsReading);
	return { ...point, dat, stationNum, gpsIndx };
};
