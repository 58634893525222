import { createSelector } from 'reselect';
import scanlineMapUtils from '../../../../mapUtils';
import { arrayToMapExists } from '../../../../utils/array';
import { getSurveyIdFromChartId } from '../../../../utils/chart';
import { selectMapSelectedSurveys } from '../../../selectors/surveys';
import { EMPTY_OBJECT, EMPTY_ARRAY } from './constants';
import { isDefined } from './util';

// ************************
// CHART IDs
// ************************

export const selectChartIds = (stateApp = EMPTY_OBJECT) => {
	const { chartIds } = stateApp;
	return isDefined(chartIds) ? chartIds : EMPTY_ARRAY;
};

export const selectMapChartIds = createSelector(
	selectChartIds,
	(chartIds = EMPTY_ARRAY) => arrayToMapExists(chartIds)
);

// ************************
// CIS CHART IDs
// ************************

export const selectCisChartIds = createSelector(selectChartIds, chartIds =>
	chartIds.filter(chartId => {
		const surveyId = getSurveyIdFromChartId(chartId);
		return scanlineMapUtils.isCis(surveyId);
	})
);

export const selectMapCisChartIds = createSelector(
	selectChartIds,
	(chartIds = EMPTY_ARRAY) => arrayToMapExists(chartIds)
);

// ************************
// SELECTED CHART IDs
// ************************

export const selectVisibleChartIds = createSelector(
	selectChartIds,
	selectMapSelectedSurveys,
	(chartIds, mapSelectedSurveys) => {
		return chartIds.filter(id => {
			const surveyId = getSurveyIdFromChartId(id);
			return !!mapSelectedSurveys[surveyId];
		});
	}
);

export const selectMapVisibleChartIds = createSelector(
	selectVisibleChartIds,
	(chartIds = EMPTY_ARRAY) => arrayToMapExists(chartIds)
);

export const selectVisibleChartPrimarySurveyIds = createSelector(
	selectVisibleChartIds,
	(visibleChartIds = EMPTY_ARRAY) => {
		return visibleChartIds.map(id => getSurveyIdFromChartId(id));
	}
);

export default {
	selectChartIds,
	selectVisibleChartIds,
	selectMapVisibleChartIds,
	selectVisibleChartPrimarySurveyIds
};
