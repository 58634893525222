/* eslint-disable import/prefer-default-export */
import { isTrueReading } from '../../../../../../../utils/reading';
import { coreComputeBelowCriterions, getComparator } from './core';

export const computeBelowCriterionsByThreshold = (
	surveyType,
	readings,
	readingsWithGaps,
	readingsWithGapsIndexMap,
	key,
	threshold,
	comparatorKey
) => {
	const getThreshold = () => threshold;
	const canCompare = reading => isTrueReading(reading) && reading[key] !== 0;
	const calcIsZeroReading = reading =>
		isTrueReading(reading) && reading[key] === 0;

	const comparator = getComparator(comparatorKey);
	return coreComputeBelowCriterions(
		surveyType,
		readings,
		readingsWithGaps,
		readingsWithGapsIndexMap,
		key,
		getThreshold,
		canCompare,
		calcIsZeroReading,
		comparator
	);
};
