/* eslint-disable import/no-cycle */
/* eslint-disable import/prefer-default-export */
import { set, setIsLoadingLoaded, clear } from './actions';
import { listCogCustomers } from '../../../../../api/customers';

const thunkLoad = () => dispatch => {
	dispatch(clear());
	dispatch(
		setIsLoadingLoaded({
			isLoading: true,
			isLoaded: false
		})
	);
	listCogCustomers().then(data => {
		dispatch(set(data));
		dispatch(
			setIsLoadingLoaded({
				isLoading: false,
				isLoaded: true
			})
		);
	});
};

export { thunkLoad };
