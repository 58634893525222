"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

function _typeof(obj) {
  "@babel/helpers - typeof";

  return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (obj) {
    return typeof obj;
  } : function (obj) {
    return obj && "function" == typeof Symbol && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj;
  }, _typeof(obj);
}

var colorDiff = 120;

var shallowCloneArr = function shallowCloneArr() {
  var arr = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : [];
  var cntr = arr.length;
  var clone = new Array(arr.length);
  cntr -= 1;

  while (cntr >= 0) {
    clone[cntr] = arr[cntr];
    cntr -= 1;
  }

  return clone;
};

var shallowClone = function shallowClone(v) {
  if (v instanceof Array) {
    return shallowCloneArr(v);
  }

  if (_typeof(v) !== 'object') {
    return v;
  }

  var keys = Object.keys(v);
  var clone = {};
  var idx = 0;

  while (idx < keys.length) {
    clone[keys[idx]] = v[keys[idx]];
    idx += 1;
  }

  return clone;
};

var MiscUtils = {
  // Can be used for DatFiles or anything else we need
  colors: ['lime', // 'aqua', This is the same as the pipeline layers
  // 'salmon', This looks too similar to GPS AutoCorrected line
  'gold', 'darkorchid', 'hotpink', 'coral', 'cornflowerblue', 'crimson', 'darkgoldenrod', // 'purple', This is the same color as the powerline layer
  'brown', 'forestgreen', 'midnightblue', 'orangered'],
  _randomHex: function _randomHex() {
    return (Math.floor(colorDiff * Math.random()) + (255 - colorDiff)).toString(16);
  },
  getRandomColor: function getRandomColor() {
    return "#".concat(MiscUtils._randomHex()).concat(MiscUtils._randomHex()).concat(MiscUtils._randomHex());
  },
  getColorByIndex: function getColorByIndex(index) {
    return MiscUtils.colors[index];
  },
  getColorByIndexOrRandom: function getColorByIndexOrRandom(index, reverse) {
    var idx = !reverse ? index % MiscUtils.colors.length : MiscUtils.colors.length - 1 - index % MiscUtils.colors.length;
    var colorByIndex = MiscUtils.colors[idx];

    if (colorByIndex) {
      return colorByIndex;
    }

    return MiscUtils.getRandomColor();
  },
  checkProdOnNode: function checkProdOnNode() {
    var stage = process.env.STAGE;

    if (stage && stage.toLowerCase() === 'prod') {
      return true;
    }

    return false;
  },
  checkProdOnBrowser: function checkProdOnBrowser() {
    var testDomainContains = ['test', 'dev', 'local', 'beta']; // TODO: Obviously this won't work in node

    var isTest = testDomainContains.some(function (domain) {
      return window.location.hostname.indexOf(domain) > -1;
    });
    return !isTest;
  },
  isProd: function isProd() {
    try {
      return MiscUtils.checkProdOnBrowser();
    } catch (_unused) {
      try {
        return MiscUtils.checkProdOnNode();
      } catch (_unused2) {
        return true; // Default to true if we are using somesort of headless browser
      }
    }
  },
  roundToDigitPlace: function roundToDigitPlace(number, decimalPlaceToRound) {
    var stringWithDecimals = (number || 0).toFixed(decimalPlaceToRound);
    var roundedNumber = +stringWithDecimals;
    return roundedNumber;
  },
  shallowCopy: function shallowCopy(obj) {
    var newObj = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {}; // eslint-disable-next-line prefer-object-spread

    return Object.assign({}, obj, newObj);
  }
};
var _default = MiscUtils;
exports["default"] = _default;