/* eslint-disable no-use-before-define */
import { createCookie, getCookie, deleteCookie } from './cookies';
import { goodEnoughUUID } from './uuid';

const KEY_ACCESS_TOKEN = 'access_token';
const KEY_REFRESH_TOKEN = 'refresh_token';
const KEY_ID_TOKEN = 'id_token';

// client only
export const generateClientToken = () => goodEnoughUUID();

// general
export const hasAccessTokens = () => hasAccessToken() || hasRefreshToken();

export const destroyAllTokens = () => {
	destroyAccessToken();
	destroyIdToken();
	destroyRefreshToken();
};

// access token
export const hasAccessToken = () => hasToken(KEY_ACCESS_TOKEN);
export const getAccessToken = () => getCookie(KEY_ACCESS_TOKEN);
export const saveAccessToken = (token, expires = null) =>
	createCookie(KEY_ACCESS_TOKEN, token, expires, '/');
export const destroyAccessToken = () => deleteCookie(KEY_ACCESS_TOKEN, '/');

// refresh token
export const hasRefreshToken = () => hasToken(KEY_REFRESH_TOKEN);
export const getRefreshToken = () => getCookie(KEY_REFRESH_TOKEN);
export const saveRefreshToken = (token, expires = null) =>
	createCookie(KEY_REFRESH_TOKEN, token, expires, '/');
export const destroyRefreshToken = () => deleteCookie(KEY_REFRESH_TOKEN, '/');

// id token
export const hasIdToken = () => hasToken(KEY_ID_TOKEN);
export const getIdToken = () => getCookie(KEY_ID_TOKEN);
export const saveIdToken = (token, expires = null) =>
	createCookie(KEY_ID_TOKEN, token, expires, '/');
export const destroyIdToken = () => deleteCookie(KEY_ID_TOKEN, '/');

// is logged in
export const isLoggedIn = () => hasAccessToken();

// helpers
const hasToken = KEY => {
	const val = getCookie(KEY);
	return val && val !== '';
};
