import { SET_DEVICE } from './actionTypes';

const initialState = {
	device: {}
};

const reducer = (state = initialState, action) => {
	const { type, payload } = action;
	switch (type) {
		case SET_DEVICE:
			return { ...state, device: payload };
		default:
			return state;
	}
};

export default reducer;
