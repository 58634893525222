const EMPTY_ARRAY = [];

export const selectEditableAssetList = (state, key) => {
	const {
		assetview: {
			manager: { editList: { [key]: list = EMPTY_ARRAY } = {} }
		}
	} = state;

	return list;
};

export default {
	selectEditableAssetList
};
