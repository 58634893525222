import InvalidStatsError from './Cache.ErrorClasses/InvalidStatsError';

export const removeQueryParams = url => {
	const uri = new URL(url);

	return url.replace(uri.search, '');
};

export const cleanUrlForStats = url => {
	const urlWithoutQueryParams = removeQueryParams(url);

	const endOfBaseString = 'amazonaws.com/';
	const locOfBase = urlWithoutQueryParams.indexOf(endOfBaseString);
	const indexToSplit = locOfBase + endOfBaseString.length;
	const urlBase = urlWithoutQueryParams.slice(0, indexToSplit);
	const other = urlWithoutQueryParams.slice(indexToSplit);

	const filePathPieces = other.split('/');

	const s3KeyBaseFilePath = filePathPieces.slice(0, -1).join('/');
	const s3KeyBase = `${s3KeyBaseFilePath}/`;
	const filename = filePathPieces.slice(-1)[0];

	return {
		urlBase,
		s3KeyBase,
		filename
	};
};

const _validateStatsUrlField = (cisvJobCacheStats, fieldName, fieldValue) => {
	if (!cisvJobCacheStats[fieldName]) {
		// This stat has not been set yet so nothing to validate
		return;
	}

	if (cisvJobCacheStats[fieldName] !== fieldValue) {
		throw new InvalidStatsError(
			`Expected "${fieldName}" value to equal "${cisvJobCacheStats[fieldName]}" but got "${fieldValue}. Two or more objects have different bases."`
		);
	}
};

export const validateStatsUrlFields = (cisvJobCacheStats, objectToValidate) => {
	const fieldsToValidate = ['urlBase', 's3KeyBase'];

	fieldsToValidate.forEach(fieldName => {
		_validateStatsUrlField(
			cisvJobCacheStats,
			fieldName,
			objectToValidate[fieldName]
		);
	});
};

export const getBucketFromHostname = hostname => {
	return hostname.split('.s3')[0];
};

const expectedEnvironmets = ['dev', 'test', 'beta', 'prod', 'local'];

export const getEnvironmentFromBucket = bucketName => {
	return expectedEnvironmets.filter(
		environent => bucketName.search(`-${environent}-`) > -1
	)[0];
};

const piecesFromPathName = pathname => {
	const firstForwardSlashRegex = /^\//;
	const cleanedPathname = pathname
		.replace(firstForwardSlashRegex, '')
		.replace('/dats', '');
	const pieces = cleanedPathname.split('/');

	const [timeuuid, fileKey, fileName] = pieces;

	return { timeuuid, fileKey, fileName };
};

const keyPiecesFromUrl = url => {
	const uri = new URL(url);

	const { hostname, pathname } = uri;

	const bucket = getBucketFromHostname(hostname);
	const environment = getEnvironmentFromBucket(bucket);

	const { timeuuid, fileKey, fileName } = piecesFromPathName(pathname);

	return [environment, timeuuid, fileKey, fileName];
};

export const createStorageKey = url => {
	const cleanedUrl = removeQueryParams(url);
	const base = 'aegion-s3-cache';

	const piecesFromUrl = keyPiecesFromUrl(cleanedUrl);

	return `${base}-${piecesFromUrl.join('||')}`;
};

export const getDatFilenameFromUrl = url => {
	const { filename, s3KeyBase } = cleanUrlForStats(url);

	const filenamePieces = filename.split('_');

	const datFileName = `${s3KeyBase}_${filenamePieces.slice(0, -1).join('_')}`;

	return datFileName;
};
