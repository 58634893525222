/* eslint-disable no-param-reassign */
const push = (
	reading,
	geoSegment,
	readingsWithGeoGaps,
	bcReadingsWithGeoGapsMap,
	readingsWithGeoGapsIndexMap,
	readingsWithGeoGapsUuidMap
) => {
	const currSegmentIdx = readingsWithGeoGaps.length;
	const currReadingIdx = geoSegment.length;

	geoSegment.push(reading);
	bcReadingsWithGeoGapsMap[reading.id] = reading;
	readingsWithGeoGapsIndexMap[reading.uuid] = [currSegmentIdx, currReadingIdx];
	readingsWithGeoGapsUuidMap[reading.uuid] = reading;
};

export const createDerivedBcDatasources = (
	primaryReadings = [],
	primaryReadingProp,
	bcReadings = []
) => {
	const areaBcReadings = [];
	const bcReadingsWithGeoGaps = [];
	const bcReadingsWithGeoGapsMap = {};
	const bcReadingsWithGeoGapsIndexMap = {};
	const bcReadingsWithGeoGapsUuidMap = {};

	const pushGeoReading = (reading, geoSegment) => {
		push(
			reading,
			geoSegment,
			bcReadingsWithGeoGaps,
			bcReadingsWithGeoGapsMap,
			bcReadingsWithGeoGapsIndexMap,
			bcReadingsWithGeoGapsUuidMap
		);
	};

	const pushAreaReading = reading => {
		areaBcReadings.push(reading);
	};

	let mapSegment = [];
	let readingToPush;
	const min = 0;
	let bcIdx = 0;

	// helper for managing map segments
	// directly changes value of "mapSegment" when reseting
	const pushSegmentAndReset = () => {
		if (mapSegment.length > 0) {
			bcReadingsWithGeoGaps.push(mapSegment);
			mapSegment = [];
		}
	};

	const getFirstNonZero = (reading, keys = [], defaultValue) => {
		const key = keys.find(k => !!reading[k]);
		return !key ? defaultValue : reading[key] || defaultValue;
	};

	primaryReadings.forEach(r => {
		if (!r.isGap) {
			const bc = bcReadings[bcIdx];
			const matchesBc = !!bc && r.id === bc.id;
			const prevBcIsGap =
				areaBcReadings.length > 0 &&
				!!areaBcReadings[areaBcReadings.length - 1].isGap;

			if (matchesBc) {
				bcIdx += 1;
				const bcValue = getFirstNonZero(
					bc,
					[primaryReadingProp, 'off', 'on'],
					min
				); // custom selected readings should not have a bc value
				bc.bc = bcValue; // @todo - figure out a better place to mutate bc
				readingToPush = bc;
				if (prevBcIsGap) {
					pushAreaReading({ id: bc.id - 0.0001, bc: min, isGap: true });
				}
				pushGeoReading(readingToPush, mapSegment);
			} else {
				// eslint-disable-next-line no-param-reassign
				r.bc = undefined;
				if (areaBcReadings.length > 0 && !prevBcIsGap) {
					readingToPush = { id: r.id, isGap: true, bc: min };
					pushSegmentAndReset(); // bc didn't match current reading, let's commit pending segment and set it to an empty array once done
				} else {
					readingToPush = undefined;
				}
			}

			if (readingToPush) {
				pushAreaReading(readingToPush);
			}
		}
	});

	pushSegmentAndReset(); // commit any pending segment before return results

	return {
		areaBcReadings,
		bcReadingsWithGeoGaps,
		bcReadingsWithGeoGapsMap,
		bcReadingsWithGeoGapsIndexMap,
		bcReadingsWithGeoGapsUuidMap
	};
};

export default { createDerivedBcDatasources };
