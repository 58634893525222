export const transformHttpProcessors = ({ list = [] }) =>
	list.map(item => ({
		email: item.key,
		name: item.value,
		displayName: item.value === '' ? item.key : item.value
	}));

export const isEquivalent = (item1 = {}, item2 = {}) =>
	item1.email === item2.email && item1.name === item2.name;

export default {
	transformHttpProcessors
};
