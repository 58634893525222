/* eslint-disable consistent-return */
/* eslint-disable import/prefer-default-export */
/* eslint-disable import/no-cycle */
import { getUserName } from '../../util/user';
import scanlineUtils from '../scanlineUtils';
import { parseQueryString } from '../utils/location';
import { setViewAsErrorMessage } from './viewAs';

export const hasValidViewAsPermissions = () =>
	new Promise((resolve, reject) => {
		const location = window && window.location;

		// if no window object ESCAPE
		if (!location) {
			reject(new Error('window.location does not exist'));
		} else {
			// if no need to check views SUCCESS
			const { viewAs } = parseQueryString(location.search);
			if (!viewAs || viewAs === getUserName()) {
				resolve(true);
			} else {
				scanlineUtils
					.canIViewAs(viewAs)
					.then(result => resolve(result))
					.catch(() => {
						resolve(false);
					});
			}
		}
	});

export const handleFailedViewAsPermissions = dispatch => {
	let message = 'You do not have proper permissions to view this data.';

	if (window && window.location) {
		const { viewAs } = parseQueryString(window.location.search);
		if (viewAs && viewAs !== getUserName()) {
			message = `You do not have proper permissions to view ${viewAs}'s data.`;
		}
	}

	dispatch(setViewAsErrorMessage(message));
};
