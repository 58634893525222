/* eslint-disable no-use-before-define */
// Utility functions To aid in working with window.location

const EMPTY_LOCATION = {};

export const getLocation = () => {
	let location = EMPTY_LOCATION;
	if (window && window.location) {
		location = window.location;
	}

	if (document && document.location) {
		location = document.location;
	}

	return location;
};

export const parseQueryString = queryString => {
	const query = {};

	if (queryString && !isEmptyString(queryString)) {
		const pairs = (queryString[0] === '?'
			? queryString.substr(1)
			: queryString
		).split('&');
		for (let i = 0; i < pairs.length; i += 1) {
			const pair = pairs[i].split('=');
			query[decodeURIComponent(pair[0])] = decodeURIComponent(pair[1] || '');
		}
	}
	return query;
};

export const composeQueryString = (objQueryParams = {}) => {
	const keys = Object.keys(objQueryParams);
	const newQueryString = keys
		.reduce((acc, k) => {
			const newKey = encodeURIComponent(k);
			const newVal = encodeURIComponent(objQueryParams[k] || '');
			acc.push(`${newKey}=${newVal}`);
			return acc;
		}, [])
		.join('&');
	return `?${newQueryString}`;
};

export const createNewQueryString = ([key, value], queryString = '') => {
	const currentParams = parseQueryString(queryString);
	const newParams = { ...currentParams, [key]: value };
	if (!value || isEmptyString(value)) {
		delete newParams[key];
	}
	const newQueryString = Object.keys(newParams).reduce(
		(result, queryKey, idx) => {
			const delimeter = idx > 0 ? '&' : '';
			return `${result}${delimeter}${encodeURIComponent(
				queryKey
			)}=${encodeURIComponent(newParams[queryKey])}`;
		},
		''
	);

	return `?${newQueryString}`;
};

export const clearFromQueryString = (key, queryString = '') => {
	const currentParams = parseQueryString(queryString);
	const newParams = { ...currentParams, [key]: '' };
	delete newParams[key];
	const newQueryString = Object.keys(newParams).reduce(
		(result, queryKey, idx) => {
			const delimeter = idx > 0 ? '&' : '';
			return `${result}${delimeter}${encodeURIComponent(
				queryKey
			)}=${encodeURIComponent(newParams[queryKey])}`;
		},
		''
	);

	return `?${newQueryString}`;
};

const isEmptyString = str =>
	str && typeof str === 'string' && str.trim() === '';

export const isScanlineShareUrl = () => {
	const { pathname } = getLocation();
	return pathname.toLowerCase() === '/scanline/share';
};

export const ENV = {
	LOCALHOST: 'LOCALHOST',
	TEST: 'TEST',
	DEV: 'DEV',
	BETA: 'BETA',
	UAT: 'UAT',
	PROD: 'PROD'
};

const HOST_TO_ENV = {
	localhost: ENV.LOCALHOST,
	'test.aip.aegion.com': ENV.TEST,
	'dev.aip.aegion.com': ENV.DEV,
	'beta.aip.aegion.com': ENV.BETA,
	'uat.aip.aegion.com': ENV.UAT,
	'aip.aegion.com': ENV.PROD
};

export const isEnv = paramKey => {
	const key = (paramKey || '').toUpperCase();
	const location = getLocation();
	const { host: _host } = location;
	const host = (_host.split(':')[0] || '').toLowerCase();

	return HOST_TO_ENV[host] === key;
};

export const isProd = () => {
	return isEnv(ENV.PROD);
};
