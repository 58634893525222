/* eslint-disable import/prefer-default-export */
import {
	VALID_KEYS,
	ERROR_DICTIONARY,
	ERROR_KEY_UNKNOWN_ERROR
} from './constants';

export const isValidKey = k => VALID_KEYS.indexOf(k) > -1;

export const getErrorMessage = errorKey =>
	ERROR_DICTIONARY[errorKey] || ERROR_DICTIONARY[ERROR_KEY_UNKNOWN_ERROR];
