import _ from 'lodash';
import initialState from './initialState';
import { CLEAR, SET_CHECKING_FOR_IMAGES, SET_IMAGE_FLAGS } from './actionTypes';

const reducer = (state = initialState, action) => {
	const { type, payload } = action;
	switch (type) {
		case CLEAR:
			return _.cloneDeep(initialState);

		case SET_CHECKING_FOR_IMAGES:
			if (state.isCheckingForImages === payload) {
				return state;
			}
			return { ...state, isCheckingForImages: payload };

		case SET_IMAGE_FLAGS:
			return {
				...state,
				byAsset: {
					...state.byAsset,
					[payload.assetId]: payload.imageFlags || {}
				},
				imageFlags: {
					...state.imageFlags,
					...payload.imageFlags
				}
			};

		default:
			return state;
	}
};

export default reducer;
