/* eslint-disable import/no-cycle */
// eslint-disable-next-line import/no-cycle
import {
	SET_NEW_INSPECTIONS,
	SET_EXISTING_INSPECTION_DATE,
	SET_IS_EXTENT_FILTER,
	SET_SHOW_MAP,
	SET_ROW_NUM,
	SET_CALC_MODE,
	SET_CALC_COLUMN,
	SET_SHOW_COLUMN_SETTINGS,
	SET_COLUMN_ORDER,
	SET_ROWS,
	SET_SELECTED_FLAT_ROWS,
	SET_SELETED_STRUCTURE_ITEM,
	SETUP_MANAGER,
	SET_EDIT_LIST,
	SET_UPDATES,
	SET_IS_SAVING,
	SET_LOAD_ALL,
	SET_IS_LOADING,
	SET_SHOW_DELETE,
	SET_DELETING
} from '../actions/manager';

import actions from '../actions/actions';

const defaultState = {
	newInspections: undefined,
	existingInspectionDate: undefined,
	isExtentFilter: true,
	tableState: undefined,
	showMap: false,
	rowNum: 1,
	isCalcMode: false,
	calcColumn: undefined,
	columnOrder: {},
	rows: undefined,
	selectedFlatRows: undefined,
	selectedStructureItem: undefined,
	showColumnSettings: undefined,
	columns: [],
	emptyColumns: [],
	columnLengths: [],
	columnLabels: [],
	editList: {},
	updates: {},
	isSaving: false,
	loadAll: [],
	loading: false,
	showDelete: false,
	deleting: false
};

const reducer = (state = defaultState, action) => {
	const { type, payload } = action;

	switch (type) {
		case SET_NEW_INSPECTIONS:
			return { ...state, newInspections: payload };
		case SET_EXISTING_INSPECTION_DATE:
			return { ...state, existingInspectionDate: payload };
		case SET_IS_EXTENT_FILTER:
			return { ...state, isExtentFilter: payload };

		case SET_SHOW_MAP:
			return { ...state, showMap: payload };
		case SET_ROW_NUM:
			return { ...state, rowNum: payload };
		case SET_CALC_MODE:
			return {
				...state,
				isCalcMode: payload
			};
		case SET_CALC_COLUMN:
			return {
				...state,
				calcColumn: payload
			};

		case SET_COLUMN_ORDER:
			return {
				...state,
				columnOrder: payload
			};
		case SET_ROWS:
			return { ...state, rows: payload };
		case SET_SELECTED_FLAT_ROWS:
			return { ...state, selectedFlatRows: payload };
		case SET_SELETED_STRUCTURE_ITEM:
			return { ...state, selectedStructureItem: payload };
		case SET_SHOW_COLUMN_SETTINGS:
			return { ...state, showColumnSettings: payload };

		case SETUP_MANAGER:
			return {
				...state,
				emptyColumns: payload.hiddenColumns,
				columnLengths: payload.columnLengths,
				columnLabels: payload.columnLabels,
				columns: payload.columns
			};
		case SET_EDIT_LIST:
			return {
				...state,
				editList: {
					...state.editList,
					[payload.name]: payload.list,
					total: payload.total
				}
			};
		case SET_UPDATES:
			return {
				...state,
				updates: payload
			};

		case SET_IS_SAVING:
			return {
				...state,
				isSaving: payload
			};
		case SET_LOAD_ALL:
			return {
				...state,
				loadAll: payload
			};
		case SET_IS_LOADING:
			return {
				...state,
				loading: payload
			};
		case actions.SET_SELECTION:
		case actions.SET_APPROVAL_MODE:
			return { ...state, loadAll: [] };
		case SET_SHOW_DELETE:
			return { ...state, showDelete: payload, deleting: false };
		case SET_DELETING:
			return { ...state, deleting: payload };
		default:
			return state;
	}
};

export default reducer;
