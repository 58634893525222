/** ***********
 * MAIN - ALIGN BY COMMENTS AND GPS COORDINATES
 */
import { getIdsForSegmentsToAlign } from './alignReadingsByComments.misc';

import {
	getLast,
	cloneAndAlignTo,
	removeDuplicateIds,
	copyDepolValue as copyDepolValue__,
	copyOriginalDepolValue as copyOriginalDepolValue__
} from './misc';

const createSegmentsFromIdsMapAndReadingsArray = (ids, readings) => {
	const segments = [];

	for (let s = 0, i = 1, n = readings.length; i < n; i += 1) {
		if (ids[readings[i].id] || i + 1 === n) {
			segments.push(readings.slice(s, i + 1));
			s = i;
		}
	}

	return segments;
};

export const createSegmentsToAlignFromIds = (
	cisRefIds,
	targetRefIds,
	cisReadings,
	targetReadings
) => {
	let cisSegments = createSegmentsFromIdsMapAndReadingsArray(
		cisRefIds,
		cisReadings
	);
	let targetSegments = createSegmentsFromIdsMapAndReadingsArray(
		targetRefIds,
		targetReadings
	);

	// TODO: Make sure that this logic is solid
	const shortestSegmentLength = Math.min(
		cisSegments.length,
		targetSegments.length
	);

	// Sometimes, when a survey's last refId is also the last reading, it may be shorter than the other segment
	cisSegments = cisSegments.slice(0, shortestSegmentLength);
	targetSegments = targetSegments.slice(0, shortestSegmentLength);

	return {
		cisSegments,
		targetSegments
	};
};

const alignSegmentByComments = (
	targetSegment,
	cisSegment,
	_copyOriginalDepolValue,
	_copyDepolValue,
	targetRefIds,
	depolFlagKey
) => {
	const normalizedSegment = [];
	let alignedTarget;

	// cache the first id of cis and target segments
	const cisFirstId = cisSegment[0].id;
	const targetFirstId = targetSegment[0].id;
	// cache the last id of target segment
	const targetLastId = getLast(targetSegment).id;

	// always align first target segment reading
	_copyOriginalDepolValue(targetSegment[0]);
	alignedTarget = cloneAndAlignTo(
		targetSegment[0],
		cisSegment[0],
		depolFlagKey
	);
	normalizedSegment.push(alignedTarget);
	_copyDepolValue(cisSegment[0], alignedTarget);

	// then we align all readings in between the first (done above) and last (done below)
	let lastAlignedReading = 1;
	for (let i2 = 1, n = targetSegment.length - 1; i2 < n; i2 += 1) {
		const targetReading = targetSegment[i2];
		const targetId = targetReading.id - targetFirstId;

		// We are only going up to the second-to-last cis segment
		for (let j = lastAlignedReading, m = cisSegment.length - 1; j < m; j += 1) {
			const cisReading = cisSegment[j];
			const cisId = cisReading.id - cisFirstId;
			if (targetId <= cisId) {
				_copyOriginalDepolValue(targetReading);
				alignedTarget = cloneAndAlignTo(
					targetReading,
					cisReading,
					depolFlagKey
				);
				normalizedSegment.push(alignedTarget);
				_copyDepolValue(cisReading, alignedTarget);
				lastAlignedReading = j;
				break;
			}
		}
	}

	// if the last target segment reading is a matched feature
	// then always align last target segment reading
	// else align using last aligned cis segment reading (for gaps)
	if (targetRefIds[targetLastId]) {
		_copyOriginalDepolValue(getLast(targetSegment));
		alignedTarget = cloneAndAlignTo(
			getLast(targetSegment),
			getLast(cisSegment),
			depolFlagKey
		);
		normalizedSegment.push(alignedTarget);
		_copyDepolValue(getLast(cisSegment), alignedTarget);
	} else {
		// There is an issue that exists when there are only two readings in cisSegment. The following can only work if there are more than 2 cis readings
		let cisReadingIndex;
		if (cisSegment.length > 2) {
			cisReadingIndex = lastAlignedReading + 1;
		} else {
			cisReadingIndex = lastAlignedReading;
		}

		_copyOriginalDepolValue(getLast(targetSegment));
		alignedTarget = cloneAndAlignTo(
			getLast(targetSegment),
			cisSegment[cisReadingIndex],
			depolFlagKey
		);
		normalizedSegment.push(alignedTarget);
		_copyDepolValue(cisSegment[cisReadingIndex], alignedTarget);
	}

	return normalizedSegment;
};

// So, the problem is that we are mapping two ways - ids are shared on the target and primary

/** Main - this will align readings by comments and gps coordinates */
const alignReadingsByComments = (
	_cisReadings,
	cisReadingsMap,
	_targetReadings,
	subtype,
	{ depolKey, depolFlagKey, targetValueKey },
	shouldCopyOriginalDepol,
	requireComments = true
) => {
	const _copyDepolValue = (cisReading, depolReading) => {
		copyDepolValue__(
			cisReading,
			depolReading,
			subtype,
			depolKey,
			targetValueKey,
			depolFlagKey
		);
	};

	const _copyOriginalDepolValue = depolReading => {
		copyOriginalDepolValue__(
			depolReading,
			subtype,
			shouldCopyOriginalDepol,
			cisReadingsMap,
			targetValueKey
		);
	};

	const cisReadings = removeDuplicateIds(_cisReadings);
	const targetReadings = removeDuplicateIds(_targetReadings);

	// This actually aligns by gps coordinates
	const { cisRefIds, targetRefIds } = getIdsForSegmentsToAlign(
		targetReadings,
		cisReadings,
		requireComments
	);

	// A "segment" is a group of readings split by perfectly matched readings
	const { cisSegments, targetSegments } = createSegmentsToAlignFromIds(
		cisRefIds,
		targetRefIds,
		cisReadings,
		targetReadings
	);

	const alignedSegments = targetSegments.map((targetSegment, i) => {
		const cisSegment = cisSegments[i];

		return alignSegmentByComments(
			targetSegment,
			cisSegment,
			_copyOriginalDepolValue,
			_copyDepolValue,
			targetRefIds,
			depolFlagKey
		);
	});

	let normalizedTargetSurvey = alignedSegments[0] || [];
	for (let i = 1, n = alignedSegments.length; i < n; i += 1) {
		const segment = alignedSegments[i].slice(1);
		normalizedTargetSurvey = normalizedTargetSurvey.concat(segment);
	}

	return normalizedTargetSurvey;
};

export default alignReadingsByComments;
