import TokenUtil from '../TokenUtil';
// eslint-disable-next-line import/no-cycle
import ajax from '../ajax';

export const jobAjax = (
	url,
	data,
	method,
	callback,
	isAnonymous,
	targetAPI
) => {
	const cookieName = targetAPI === 'mlServerApi' ? 'id_token' : 'access_token';
	const accessToken = TokenUtil.getCookie(cookieName);
	if (accessToken !== null || isAnonymous) {
		const headers = {
			'Content-Type': 'application/json'
		};
		if (!isAnonymous) {
			const authenticationSchemePrefix =
				targetAPI === 'mlServerApi' ? 'Bearer ' : '';
			headers.Authorization = `${authenticationSchemePrefix}${accessToken}`;
		}
		return ajax({
			aip: targetAPI || 'cisview',
			data,
			url,
			headers,
			method
		})
			.then(res => {
				callback(null, res);
				return res;
			})
			.catch(err => {
				callback(err, null);
			});
	}
	if (callback) {
		callback(new Error('Not Logged In'));
	}

	return Promise.reject(new Error('Not Logged In'));
};

export const jobAjaxReadOnly = (
	url,
	data,
	method,
	callback,
	isAnonymous,
	targetAPI
) => {
	const cookieName = targetAPI === 'mlServerApi' ? 'id_token' : 'access_token';
	const accessToken = TokenUtil.getCookie(cookieName);
	if (accessToken !== null || isAnonymous) {
		const headers = {
			'Content-Type': 'application/json'
		};
		if (!isAnonymous) {
			const authenticationSchemePrefix =
				targetAPI === 'mlServerApi' ? 'Bearer ' : '';
			headers.Authorization = `${authenticationSchemePrefix}${accessToken}`;
		}
		return ajax({
			aip: 'cisviewreadonly',
			data,
			url,
			headers,
			method
		})
			.then(res => {
				callback(null, res);
				return res;
			})
			.catch(err => {
				callback(err, null);
			});
	}
	if (callback) {
		callback(new Error('Not Logged In'));
	}

	return Promise.reject(new Error('Not Logged In'));
};

export const jobAjaxforExtended = (
	url,
	data,
	method,
	callback,
	isAnonymous
) => {
	const accessToken = TokenUtil.getCookie('access_token');
	if (accessToken !== null || isAnonymous) {
		const headers = {
			'Content-Type': 'application/json'
		};
		if (!isAnonymous) {
			headers.Authorization = accessToken;
		}
		return ajax({ aip: 'cisviewextended', data, url, headers, method })
			.then(res => {
				if (callback) {
					callback(null, res);
				}
				return res;
			})
			.catch(err => {
				callback(err);
			});
	}
	callback(new Error('Not Logged In'));
	return Promise.reject(new Error('Not Logged In'));
};

export const getUploadPolicy = (file, jobguid, callback) => {
	jobAjax(
		`/getuploadpolicy?filename=${encodeURIComponent(
			file.name
		)}&jobguid=${jobguid}`,
		null,
		'GET',
		(err, res) => {
			callback(err, res);
		}
	);
};

export const getUploadPolicySave = (
	fileName,
	fileKey,
	jobguid,
	folder,
	callback
) => {
	jobAjax(
		`${'/getuploadpolicy?filename='}${encodeURIComponent(
			fileName
		)}&fileKey=${encodeURIComponent(fileKey)}&folder=${encodeURIComponent(
			folder
		)}&jobguid=${encodeURIComponent(jobguid)}`,
		null,
		'GET',
		(err, res) => {
			callback(err, res);
		}
	);
};

export const update = (data, callback) => {
	jobAjax('/update', JSON.stringify(data), 'POST', (err, res) => {
		callback(err, res);
	});
};

export const _getCookie = cname => {
	const name = `${cname}=`;
	const decodedCookie = decodeURIComponent(document.cookie);
	const ca = decodedCookie.split(';');
	for (let i = 0; i < ca.length; i += 1) {
		let c = ca[i];
		while (c.charAt(0) === ' ') {
			c = c.substring(1);
		}
		if (c.indexOf(name) === 0) {
			return c.substring(name.length, c.length);
		}
	}
	return '';
};
