/* eslint-disable import/no-cycle */
import { createActions } from 'redux-actions';
import jobListUtils from '../utils/utils';
import { fetchJobsIfNeeded } from './jobs';

const {
	flSelectCustomer,
	flInvalidateCustomer,
	flReceiveCustomers,
	flRequestCustomers
} = createActions({
	FL_SELECT_CUSTOMER: customer => ({ customer }),
	FL_INVALIDATE_CUSTOMER: customer => ({ customer }),
	FL_RECEIVE_CUSTOMERS: json => {
		return { customers: json.customers };
	},
	FL_REQUEST_CUSTOMERS: () => ({})
});

const doFetchCustomers = urlFilters => {
	return dispatch => {
		dispatch(flRequestCustomers());

		return jobListUtils.getCustomers((err, res) => {
			if (err) {
				console.log(err); // eslint-disable-line no-console
			} else {
				dispatch(flReceiveCustomers(res));
				dispatch(fetchJobsIfNeeded(urlFilters));
			}
		});
	};
};

const shouldFetchCustomers = state => {
	const { fieldlineJobs } = state;
	return fieldlineJobs.availableCustomers.length === 0;
};

const fetchCustomersIfNeeded = urlFilters => {
	return (dispatch, getState) => {
		if (shouldFetchCustomers(getState())) {
			dispatch(doFetchCustomers(urlFilters));
		}
	};
};

export {
	flSelectCustomer,
	flInvalidateCustomer,
	fetchCustomersIfNeeded,
	flReceiveCustomers
};
