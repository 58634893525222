import { SET_CARD_COLOR } from './actionTypes';

import { VALID_CARD_COLORS, DEFAULT_CARD_COLOR } from './constants';

const initialState = {
	color: DEFAULT_CARD_COLOR
};

const getValidCardColor = newColor => {
	const color =
		newColor && newColor.trim && newColor.trim().length > 0
			? newColor.toUpperCase()
			: DEFAULT_CARD_COLOR;
	return VALID_CARD_COLORS.indexOf(color) > -1 ? color : DEFAULT_CARD_COLOR;
};

const cardColor = (state = initialState, action) => {
	const { type, payload } = action;

	switch (type) {
		case SET_CARD_COLOR:
			return {
				...state,
				color: getValidCardColor(payload)
			};

		default:
			return state;
	}
};

export default cardColor;
