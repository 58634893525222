/* eslint-disable import/no-cycle */
import {
	setApplications,
	setIsLoadingLoadedApplications,
	setHasLoadingErrorApplications,
	setSelectedApplication,
	setSelectedApplicationChangesClone
} from './actions';
import { ROOT_SLICE } from './constants';
import {
	selectRootSlice,
	selectIsLoadingApplications,
	selectIsLoadedApplications,
	selectHasLoadingErrorApplications
} from './selectors';

const isDefined = v => v !== undefined && v !== null;

// ************
// PARTS
// ************
const reducerParts = {
	[setApplications]: (state, { payload: { applications } }) => {
		const rootSlice = selectRootSlice(state);
		return {
			...state,
			[ROOT_SLICE]: { ...rootSlice, applications }
		};
	},

	[setHasLoadingErrorApplications]: (state, { payload: { hasError } }) => {
		const rootSlice = selectRootSlice(state);
		const currentHasError = selectHasLoadingErrorApplications(state);

		if (hasError === currentHasError) {
			return state;
		}

		return {
			...state,
			[ROOT_SLICE]: {
				...rootSlice,
				hasLoadingErrorApplications: hasError
			}
		};
	},

	[setSelectedApplication]: (state, { payload: { selectedApplication } }) => {
		const rootSlice = selectRootSlice(state);
		return {
			...state,
			[ROOT_SLICE]: { ...rootSlice, selectedApplication }
		};
	},

	[setSelectedApplicationChangesClone]: (
		state,
		{ payload: { selectedApplicationChangesClone } }
	) => {
		const rootSlice = selectRootSlice(state);
		return {
			...state,
			[ROOT_SLICE]: { ...rootSlice, selectedApplicationChangesClone }
		};
	},

	[setIsLoadingLoadedApplications]: (
		state,
		{
			payload: {
				isLoadingApplications: newIsLoadingApplications,
				isLoadedApplications: newIsLoadedApplications
			}
		}
	) => {
		const rootSlice = selectRootSlice(state);
		const isLoadingApplications = selectIsLoadingApplications(state);
		const isLoadedApplications = selectIsLoadedApplications(state);

		const isLoadingChanged =
			isDefined(newIsLoadingApplications) &&
			newIsLoadingApplications !== isLoadingApplications;
		const isLoadedChanged =
			isDefined(newIsLoadedApplications) &&
			newIsLoadedApplications !== isLoadedApplications;

		if (!isLoadingChanged && !isLoadedChanged) {
			return state;
		}

		return {
			...state,
			[ROOT_SLICE]: {
				...rootSlice,
				isLoadingApplications: isLoadingChanged
					? newIsLoadingApplications
					: isLoadingApplications,
				isLoadedApplications: isLoadedChanged
					? newIsLoadedApplications
					: isLoadedApplications
			}
		};
	}
};

export default reducerParts;
