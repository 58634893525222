/* eslint-disable import/prefer-default-export */
export const ROOT_SLICE = 'actionPlanMessages';

// messages - download & save
export const MSG_DAS_COLLECTING_CHANGE_NOTES =
	'Collecting change notes from user';

export const MSG_DAS_CHECKING_FOR_CHANGES =
	'Checking to see if the action plan has changed.';

export const MSG_DAS_COMPUTING_MD5_KEYS = 'Computing md5 keys for surveys';

export const MSG_DAS_ARCHIVING_SURVEYS = 'Archiving Surveys';

export const MSG_DAS_SAVING_PROJECT = 'Saving Project';

export const MSG_DAS_SAVING_REPORT = 'Saving Report';

export const MSG_DAS_DOWNLOADING_REPORT = 'Downloading Report';
