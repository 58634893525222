import { capitalize } from '../util';

const EMPTY_OBJECT = {};

export const buildSelectors = (rootSliceKey, filtersStatePropKey) => {

  const propKey = filtersStatePropKey;
  const capPropKey = capitalize(propKey);

  const selectSliceState = (rootState = {}) => rootState[rootSliceKey] || EMPTY_OBJECT;

  const selectFiltersState = (stateSlice = {}) => {
    return stateSlice[propKey] || EMPTY_OBJECT;
  };
  
  const selectIsLoadingFilters = stateSlice => {    
    const key = `isLoading${capPropKey}`;    
    return stateSlice[key] || false;
  }
  
  const selectIsLoadedFilters = stateSlice => {
    const key = `isLoaded${capPropKey}`;    
    return stateSlice[key] || false;
  }
  
  const selectLoadFiltersErrorMessage = stateSlice => {
    const key = `load${capPropKey}ErrorMessage`;    
    return stateSlice[key] || '';
  }
  
  const selectIsAnyFilterLoading = stateSlice => {
    const filtersStore = selectFiltersState(stateSlice);
    const filters = Object.keys(filtersStore).map(k => filtersStore[k]);
    return !!filters.find(f => f.isLoading);
  };
  
  return {
    selectSliceState,
    selectFiltersState,
    selectIsLoadingFilters,
    selectIsLoadedFilters,
    selectLoadFiltersErrorMessage,
    selectIsAnyFilterLoading,
  }
};

export default {
  buildSelectors
}
