/* eslint-disable camelcase */
/* eslint-disable import/prefer-default-export */
import _ from 'lodash';
import { handleActions } from 'redux-actions';
import { APP_REDUX_ROOT_KEY } from './constants';

import { SLICE_ROOT_KEY as UPLOADEDFILES_SLICE_ROOT_KEY } from './slices/UploadedFiles/constants';
import uploadedFiles_initialState from './slices/UploadedFiles/initialState';
import uploadedFiles_reducerParts from './slices/UploadedFiles/reduceParts';

import { SLICE_ROOT_KEY as DIFFOUTPUTS_SLICE_ROOT_KEY } from './slices/DiffOutputs/constants';
import diffOutputs_initialState from './slices/DiffOutputs/initialState';
import diffOutputs_reducerParts from './slices/DiffOutputs/reduceParts';

import { SLICE_ROOT_KEY as COMPUTEDIFFS_SLICE_ROOT_KEY } from './slices/ComputeDiffs/constants';
import computeDiffs_initialState from './slices/ComputeDiffs/initialState';
import computeDiffs_reducerParts from './slices/ComputeDiffs/reduceParts';

const initialState = {
	[UPLOADEDFILES_SLICE_ROOT_KEY]: _.cloneDeep(uploadedFiles_initialState),
	[DIFFOUTPUTS_SLICE_ROOT_KEY]: _.cloneDeep(diffOutputs_initialState),
	[COMPUTEDIFFS_SLICE_ROOT_KEY]: _.cloneDeep(computeDiffs_initialState)
};

const reducerParts = {
	...uploadedFiles_reducerParts,
	...diffOutputs_reducerParts,
	...computeDiffs_reducerParts
};

export const FILE_DIFF_REDUX_ROOT_KEY = APP_REDUX_ROOT_KEY;

export default handleActions(reducerParts, initialState);
