import _ from 'lodash';

import {
	OPTIMUM_ZOOM_IN_FEET,
	TRACES_TO_ALWAYS_SHOW,
	TRACES_TO_SHOW_IN_OPTIMUM_ZOOM,
	COMMENTS_TRACES,
	DEFAULT_MIN_Y_RANGE_MV,
	DEFAULT_MIN_Y_RANGE_VOLTS,
	LINE_STYLE_OPTIONS,
	DEFAULT_SELECT_ACTIONS,
	DEFAULT_MINIMUM_CRITERIA_LINE_TRACE,
	DEFAULT_MAXIMUM_CRITERIA_LINE_TRACE,
	FILE_TYPE
} from '../util/spike-editor-config';

import { createRgbString } from '../../../../commons/util/colors';
import { isValidReadingValue } from '../../../components/TabPages/utils/spikeeditor';

export const getUpdatedCriteriaLineTrace = (
	criteriaLine,
	originalCriteriaTrace
) => {
	const { color, width, lineStyle, value } = criteriaLine;

	const { x } = criteriaLine;

	const updatedCriteriaTrace = {
		...originalCriteriaTrace,
		line: {
			...originalCriteriaTrace.line,
			color: createRgbString(color),
			width,
			dash: lineStyle
		},
		x,
		y: x.map(() => {
			return value;
		})
	};

	return updatedCriteriaTrace;
};

const _getSubsetOfTraces = (traces, traceNamesToShow) => {
	const tracesToShow = {};

	traceNamesToShow.forEach(traceName => {
		tracesToShow[traceName] = traces[traceName];
	});

	return tracesToShow;
};

const _getCommentTracesInOptimumZoom = selectedCommentConfig => {
	switch (selectedCommentConfig) {
		case 'never':
			return [];
		case 'always':
		case 'optimum-zoom':
			return COMMENTS_TRACES;
		default:
			throw new Error(
				`Unknown option to show comments ${selectedCommentConfig}`
			);
	}
};

const _getCommentTracesToAlwaysShow = selectedCommentConfig => {
	switch (selectedCommentConfig) {
		case 'optimum-zoom':
		case 'never':
			return [];
		case 'always':
			return COMMENTS_TRACES;
		default:
			throw new Error(
				`Unknown option to show comments ${selectedCommentConfig}`
			);
	}
};

const _getCommentTraces = (selectedCommentConfig, isInOptimumZoom) => {
	if (isInOptimumZoom) {
		return _getCommentTracesInOptimumZoom(selectedCommentConfig);
	}

	return _getCommentTracesToAlwaysShow(selectedCommentConfig);
};

const _getTraceNamesToShowInOptimumZoom = () => {
	const allTracesToShowByDefault = [
		...TRACES_TO_SHOW_IN_OPTIMUM_ZOOM,
		...TRACES_TO_ALWAYS_SHOW
	];

	return allTracesToShowByDefault;
};

const _getChartMode = (useChartDotsConfig, showMarkersByDefault = false) => {
	if (useChartDotsConfig) {
		return {
			mode: 'lines+markers'
		};
	}

	const mode = showMarkersByDefault ? 'lines+markers' : 'lines';
	const chartModeOptions = {
		mode
	};

	// We want there to markers to select but not visible
	if (showMarkersByDefault) {
		chartModeOptions.marker = { opacity: 0 };
	}

	return chartModeOptions;
};

const _getOldDataMode = useChartDotsConfig => {
	return _getChartMode(useChartDotsConfig, false);
};

const _getNewDataMode = useChartDotsConfig => {
	return _getChartMode(useChartDotsConfig, true);
};

const _getTraceNamesToAlwaysShow = () => {
	return TRACES_TO_ALWAYS_SHOW;
};

const createTrace = (
	color,
	name,
	{ width, mode = 'lines', dash, marker, showlegend = true } = {},
	yaxis
) => {
	const line = {
		color
	};

	if (width) {
		line.width = width;
	}
	if (dash) {
		line.dash = dash;
	}
	const defaultTrace = {
		x: [],
		y: [],
		stationIds: [],
		type: 'scatter',
		mode,
		name,
		line,
		showlegend,
		yaxis
	};

	// it looks like, if we place `marker` into the default trace as `undefined` the chart will break
	if (marker) {
		defaultTrace.marker = marker;
	}

	return defaultTrace;
};

const createTraceByName = (state, traceName, propOptions) => {
	const { traceOptionsByName } = state;
	const traceOptions = traceOptionsByName[traceName];

	if (!traceOptions) {
		throw new Error(`Unknown trace "${traceName}"`);
	}

	const { color, display, options = propOptions, yaxis } = traceOptions;

	if (!color) {
		throw new Error(`Could not find a color for trace "${traceName}"`);
	}
	if (!display) {
		throw new Error(`Could not find a display for trace "${traceName}"`);
	}

	return createTrace(color, display, options, yaxis);
};

function inText(needle, haystack) {
	return haystack.toUpperCase().indexOf(needle.toUpperCase()) > -1;
}

function getUserInteractionTrace(legendText, color, size, yaxis) {
	return createTrace(
		color,
		legendText,
		{
			mode: 'markers',
			marker: { size },
			showlegend: false
		},
		yaxis
	);
}

function sortDataArrayByObjectProperty(array, property) {
	return _.orderBy(array, [property], ['asc']);
}

function getCommentColor(comment) {
	if (inText('TEST', comment)) {
		return {
			color: 'GREEN',
			size: 10
		};
	}

	if (inText('RECTIFIER', comment)) {
		return {
			color: 'PURPLE',
			size: 10
		};
	}

	if (inText('EDGE', comment)) {
		return {
			color: 'ORANGE',
			size: 7
		};
	}

	return {
		color: 'GRAY',
		size: 7
	};
}

function truncate(input) {
	return input.length > 40 ? `${input.substring(0, 40)}...` : input;
}

function checkAndFillMissingRange(array, index, arrayToPush, interval) {
	const nextReading = array[index + 1];
	if (nextReading !== undefined) {
		const currentStationId = array[index].stationId;
		const nextStationId = nextReading.stationId;
		if (nextStationId - currentStationId > interval) {
			const missingRange = _.range(currentStationId, nextStationId, interval);
			missingRange.shift();
			missingRange.forEach(sId => {
				arrayToPush.x.push(sId);
				arrayToPush.y.push(null);
			});
		}
	}
}

const populateTraceData = (
	data,
	onTrace,
	offTrace,
	acOnTrace,
	acOffTrace,
	datFile,
	commentOptions = {},
	fileType
) => {
	if (!data || !datFile || Object.keys(datFile).length === 0) {
		return;
	}

	const { interval, surveyType } = datFile;

	const isGPSSync = fileType === FILE_TYPE.ON_OFF_GPS_SYNC;

	sortDataArrayByObjectProperty(data, 'stationId').forEach(
		(d, index, array) => {
			const { commentTrace, commentY, commentAnnotations } = commentOptions;

			const { stationId, originalStationId = d.stationId } = d;

			if (commentTrace && d.comment) {
				const { color, size } = getCommentColor(d.comment);
				commentTrace.marker.color.push(color);
				commentTrace.marker.size.push(size);

				commentTrace.x.push(stationId);
				commentTrace.y.push(commentY);
				commentTrace.text.push(d.comment);

				// comment annotations
				commentAnnotations.push({
					x: stationId,
					y: commentY,
					xref: 'x',
					yref: 'y',
					showarrow: false,
					text: truncate(d.comment),
					textangle: '-90',
					yanchor: 'bottom',
					borderpad: 10
				});
			}

			if (typeof stationId !== 'number') {
				return;
			}
			// ToDo fix the 0 for depol on, etc
			if (isValidReadingValue(d, 'on', surveyType, isGPSSync)) {
				onTrace.x.push(stationId);
				onTrace.y.push(d.on);
				onTrace.stationIds.push(originalStationId);
				checkAndFillMissingRange(array, index, onTrace, interval);
			}
			if (isValidReadingValue(d, 'off', surveyType, isGPSSync)) {
				offTrace.x.push(stationId);
				offTrace.y.push(d.off);
				offTrace.stationIds.push(originalStationId);
				checkAndFillMissingRange(array, index, offTrace, interval);
			}
			if (
				acOnTrace !== undefined &&
				isValidReadingValue(d, 'acOn', surveyType, isGPSSync)
			) {
				acOnTrace.x.push(stationId);
				acOnTrace.y.push(d.acOn);
				acOnTrace.stationIds.push(originalStationId);
				checkAndFillMissingRange(array, index, acOnTrace, interval);
			}
			if (
				acOffTrace !== undefined &&
				isValidReadingValue(d, 'acOff', surveyType, isGPSSync)
			) {
				acOffTrace.x.push(stationId);
				acOffTrace.y.push(d.acOff);
				acOffTrace.stationIds.push(originalStationId);
				checkAndFillMissingRange(array, index, acOffTrace, interval);
			}
			if (surveyType === 'ON-OFF' && !d.on && !d.off) {
				// nulls are added to make gaps...only add nulls if there is preceding data
				if (onTrace.x.length > 0) {
					onTrace.x.push(stationId);
					onTrace.y.push(null);
					onTrace.stationIds.push(originalStationId);
				}
				checkAndFillMissingRange(array, index, onTrace, interval);
				if (offTrace.x.length > 0) {
					offTrace.x.push(stationId);
					offTrace.y.push(null);
					offTrace.stationIds.push(originalStationId);
				}
				checkAndFillMissingRange(array, index, offTrace, interval);
			}
		}
	);
};

function createUserInteractionTraces(points, datFile, traceInfo, fileType) {
	if (traceInfo.length !== 4) {
		throw new Error('Expected exactly 4 options for a user interaction trace');
	}
	const [onTraces, offTraces, onAcTraces, offAcTraces] = traceInfo.map(
		option => {
			const { color, display, options, yaxis } = option;
			const { size } = options;
			if (!size || !color || !display) {
				throw new Error(
					'Expected size, color, and display for a user interaction trace'
				);
			}

			return getUserInteractionTrace(display, color, size, yaxis);
		}
	);

	populateTraceData(
		points,
		onTraces,
		offTraces,
		onAcTraces,
		offAcTraces,
		datFile,
		undefined,
		fileType
	);

	return [onTraces, offTraces, onAcTraces, offAcTraces];
}
function getHighlightedPointDataForTrace(highlightedPoint, newData) {
	const data = newData[highlightedPoint];
	if (!data) {
		return [];
	}

	return [data];
}

function getSelectedReadingPointsDataForTrace(selectedPoints = [], newData) {
	const data = Object.keys(selectedPoints).map(index => newData[index]);
	if (!data) {
		return {};
	}

	return data;
}

function getSelectedReadingRowPoints(state) {
	const { selectedDatFileIndex, readingRowsSelectedByDatFile } = state;
	if (!readingRowsSelectedByDatFile) {
		return {};
	}

	const readingRows = readingRowsSelectedByDatFile[selectedDatFileIndex];

	if (!readingRows) {
		return {};
	}

	return readingRows;
}

export const calculateCommentY = yRange => {
	const [startYRange, endYRange] = yRange;

	const commentY = (endYRange - startYRange) * 0.05 + startYRange;
	return commentY;
};

export const getReadingRowSelectedTraces = (
	state,
	readingRowPoints,
	newData,
	datFile,
	fileType
) => {
	const { traceOptionsByName } = state;
	const readingRowData = getSelectedReadingPointsDataForTrace(
		readingRowPoints,
		newData
	);
	const highlightedTraceNames = [
		'readingRowSelectedOnTraces',
		'readingRowSelectedOffTraces',
		'readingRowSelectedAcOnTraces',
		'readingRowSelectedAcOffTraces'
	];
	const traceInfo = highlightedTraceNames.map(name => {
		return traceOptionsByName[name];
	});

	const [
		readingRowSelectedOnTraces,
		readingRowSelectedOffTraces,
		readingRowSelectedAcOnTraces,
		readingRowSelectedAcOffTraces
	] = createUserInteractionTraces(readingRowData, datFile, traceInfo, fileType);
	return {
		readingRowSelectedOnTraces,
		readingRowSelectedOffTraces,
		readingRowSelectedAcOnTraces,
		readingRowSelectedAcOffTraces
	};
};

const _getCommentAnnotationsForOptimumZoom = (
	selectedCommentConfig,
	annotations
) => {
	switch (selectedCommentConfig) {
		case 'never':
			return null;
		case 'always':
		case 'optimum-zoom':
			return annotations;
		default:
			throw new Error(
				`Unknown option to show comments ${selectedCommentConfig}`
			);
	}
};

const _getCommentAnnotationsForNonOptimumZoom = (
	selectedCommentConfig,
	annotations
) => {
	switch (selectedCommentConfig) {
		case 'never':
		case 'optimum-zoom':
			return null;
		case 'always':
			return annotations;
		default:
			throw new Error(
				`Unknown option to show comments ${selectedCommentConfig}`
			);
	}
};

const _getCommentAnnotationsPerSetting = (
	selectedCommentConfig,
	annotations,
	inOptimalZoomRange = false
) => {
	if (inOptimalZoomRange) {
		return _getCommentAnnotationsForOptimumZoom(
			selectedCommentConfig,
			annotations
		);
	}

	return _getCommentAnnotationsForNonOptimumZoom(
		selectedCommentConfig,
		annotations
	);
};

export const getXRange = state => {
	const { currentXRange, layout } = state;
	let [startXRange, endXRange] = currentXRange;

	if (startXRange === undefined || endXRange === undefined) {
		// Probably trying to zoom with default layout
		[startXRange, endXRange] = layout.xaxis.range;
		if (startXRange === undefined || endXRange === undefined) {
			return null;
		}
	}

	return [startXRange, endXRange];
};

const _getTraceNamesPerConfig = (
	showAllLinesAlways,
	isInOptimumZoom,
	selectedCommentConfig
) => {
	const commentTraces = _getCommentTraces(
		selectedCommentConfig,
		isInOptimumZoom
	);
	let traceNamesToShow;
	if (showAllLinesAlways || isInOptimumZoom) {
		traceNamesToShow = _getTraceNamesToShowInOptimumZoom();
	} else {
		traceNamesToShow = _getTraceNamesToAlwaysShow();
	}

	return [...traceNamesToShow, ...commentTraces];
};

export const _getTracesFromXRange = (
	state,
	allTraces,
	allAnnotations,
	selectedCommentConfigProps
) => {
	const { selectedOriginalLineConfig } = state;
	const traces = allTraces || state.allTraces;
	const annotations = allAnnotations || state.allAnnotations;
	const selectedCommentConfig =
		selectedCommentConfigProps || state.selectedCommentConfig;
	const xRange = getXRange(state);

	const [startXRange, endXRange] = xRange;

	const feetDisplayed = endXRange - startXRange;

	const isInOptimumZoom = feetDisplayed <= OPTIMUM_ZOOM_IN_FEET;

	const showAllLinesAlways = selectedOriginalLineConfig === 'always';

	const traceNamesToShow = _getTraceNamesPerConfig(
		showAllLinesAlways,
		isInOptimumZoom,
		selectedCommentConfig
	);

	const newTraces = _getSubsetOfTraces(traces, traceNamesToShow);
	const arrTraces = Object.values(newTraces);

	// TODO: Limit items by xRange here
	return {
		traces: newTraces,
		arrTraces,
		annotations: _getCommentAnnotationsPerSetting(
			selectedCommentConfig,
			annotations,
			isInOptimumZoom
		)
	};
};

export const getHighlightedTrace = (
	state,
	highlightedPoint,
	newData,
	datFile,
	fileType
) => {
	const highlightedPointData = getHighlightedPointDataForTrace(
		highlightedPoint,
		newData
	);
	const { traceOptionsByName } = state;

	const highlightedTraceNames = [
		'highlightedOnTrace',
		'highlightedOffTrace',
		'highlightedAcOnTrace',
		'highlightedAcOffTrace'
	];
	const traceIno = highlightedTraceNames.map(name => {
		return traceOptionsByName[name];
	});
	const [
		highlightedOnTrace,
		highlightedOffTrace,
		highlightedAcOnTrace,
		highlightedAcOffTrace
	] = createUserInteractionTraces(
		highlightedPointData,
		datFile,
		traceIno,
		fileType
	);
	return {
		highlightedOnTrace,
		highlightedOffTrace,
		highlightedAcOnTrace,
		highlightedAcOffTrace
	};
};

export const getMvValue = (fieldValue, useMv) => {
	let multiplier;
	if (!useMv) {
		multiplier = 0.001; // We want MV to become Volts so we divide by 1000
	} else {
		multiplier = 1000; // We want Volts to become MV so we multiply by 1000
	}

	return fieldValue * multiplier;
};

const _criteriaLineValueFromMvChange = (criteriaLine, useMv) => {
	const { value } = criteriaLine;

	// Probably nothing to do here but maybe this is too big of an assumption
	if (useMv && value < -100) {
		return value;
	}
	if (!useMv && value > -100) {
		return value;
	}

	// We need to multiply the value due to a mv change
	return getMvValue(value, useMv);
};

const getCriteriaLineWithNewState = (state, x, useMv, type = 'minimum') => {
	const { criteriaLines } = state;

	const criteriaLine = criteriaLines[type];
	const value = _criteriaLineValueFromMvChange(criteriaLine, useMv);

	if (criteriaLine.x === x && criteriaLine.value === value) {
		return criteriaLine;
	}

	// If there are any changes, let's go ahead and replace all values
	return {
		...criteriaLine,
		x,
		value
	};
};

const getMinMaxX = allReadings => {
	const allMinMaxPoints = [];
	const allReadingsWithValues = allReadings.filter(t => (t || []).length);
	allReadingsWithValues.forEach(readings => {
		const firstPoint = readings[0].stationId;
		const lastPoint = readings[readings.length - 1].stationId;

		allMinMaxPoints.push(firstPoint, lastPoint);
	});

	const minX = Math.min(...allMinMaxPoints);
	const maxX = Math.max(...allMinMaxPoints);

	return {
		minX,
		maxX
	};
};

export const getMinMaxXfromState = state => {
	const {
		newData,
		preProcessedGPSReadings,
		previousChartData,
		nextChartData,
		oldData
	} = state;
	return getMinMaxX([
		newData,
		preProcessedGPSReadings,
		previousChartData,
		nextChartData,
		oldData
	]);
};

function getChartData(state, settings, surveyType, fileType) {
	if (!surveyType) {
		throw new Error('surveyType must be included to compute traces');
	}
	const {
		preProcessedGPSReadings,
		oldData,
		newData,
		currentYRange,
		previousChartData,
		nextChartData,
		highlightedPoint,
		selectedCommentConfig,
		selectedDatFile,
		nextDatFile,
		previousDatFile
	} = state;

	const selectedReadingRowPoints = getSelectedReadingRowPoints(state);

	const { useMv } = state;

	const { useChartDotsConfig = false } = settings;

	const preprocessedOnTrace = createTraceByName(state, 'preprocessedOnTrace');

	const preprocessedOffTrace = createTraceByName(state, 'preprocessedOffTrace');

	const oldDataOptions = _getOldDataMode(useChartDotsConfig);
	const oldOnTrace = createTraceByName(state, 'oldOnTrace', oldDataOptions);

	const oldOffTrace = createTraceByName(state, 'oldOffTrace', oldDataOptions);

	const oldOnCommentTrace = {
		x: [],
		y: [],
		type: 'scatter',
		mode: 'markers',
		text: [],
		hovertemplate: '%{text}<extra></extra>',
		marker: {
			size: [],
			color: []
		},
		showlegend: false
	};

	const newDataOptions = _getNewDataMode(useChartDotsConfig);
	const newOnTrace = createTraceByName(state, 'newOnTrace', newDataOptions);

	const newOffTrace = createTraceByName(state, 'newOffTrace', newDataOptions);

	const newAcOnTrace = createTraceByName(state, 'newAcOnTrace', newDataOptions);

	const newAcOffTrace = createTraceByName(
		state,
		'newAcOffTrace',
		newDataOptions
	);

	const oldAcOnTrace = createTraceByName(state, 'oldAcOnTrace', oldDataOptions);

	const oldAcOffTrace = createTraceByName(
		state,
		'oldAcOffTrace',
		oldDataOptions
	);

	const previousOnTrace = createTraceByName(state, 'previousOnTrace');
	const previousOffTrace = createTraceByName(state, 'previousOffTrace');

	const nextOnTrace = createTraceByName(state, 'nextOnTrace');
	const nextOffTrace = createTraceByName(state, 'nextOffTrace');

	const previousAcOnTrace = createTraceByName(state, 'previousAcOnTrace');
	const previousAcOffTrace = createTraceByName(state, 'previousAcOffTrace');

	const nextAcOnTrace = createTraceByName(state, 'nextAcOnTrace');
	const nextAcOffTrace = createTraceByName(state, 'nextAcOffTrace');

	populateTraceData(
		previousChartData,
		previousOnTrace,
		previousOffTrace,
		previousAcOnTrace,
		previousAcOffTrace,
		previousDatFile,
		undefined,
		fileType
	);
	populateTraceData(
		nextChartData,
		nextOnTrace,
		nextOffTrace,
		nextAcOnTrace,
		nextAcOffTrace,
		nextDatFile,
		undefined,
		fileType
	);

	const commentAnnotations = [];

	populateTraceData(
		oldData,
		oldOnTrace,
		oldOffTrace,
		oldAcOnTrace,
		oldAcOffTrace,
		selectedDatFile,
		undefined,
		fileType
	);

	const commentY = calculateCommentY(currentYRange);
	populateTraceData(
		newData,
		newOnTrace,
		newOffTrace,
		newAcOnTrace,
		newAcOffTrace,
		selectedDatFile,
		{
			commentTrace: oldOnCommentTrace,
			commentY,
			commentAnnotations
		},
		fileType
	);

	// TODO: Why is this trace not showing up when reversed == false?
	// TODO: Pretty much nothing looks right after reversing and then immediately going to the spike editor (before refreshing)
	populateTraceData(
		preProcessedGPSReadings,
		preprocessedOnTrace,
		preprocessedOffTrace,
		undefined,
		undefined,
		selectedDatFile,
		undefined,
		fileType
	);

	const { minX, maxX } = getMinMaxXfromState(state);

	const criteriaX = [minX, maxX];

	const minCriteriaLine = getCriteriaLineWithNewState(
		state,
		criteriaX,
		useMv,
		'minimum'
	);

	const minCriteriaLineTrace = getUpdatedCriteriaLineTrace(
		minCriteriaLine,
		DEFAULT_MINIMUM_CRITERIA_LINE_TRACE
	);

	const maxCriteriaLine = getCriteriaLineWithNewState(
		state,
		criteriaX,
		useMv,
		'maximum'
	);

	const maxCriteriaLineTrace = getUpdatedCriteriaLineTrace(
		maxCriteriaLine,
		DEFAULT_MAXIMUM_CRITERIA_LINE_TRACE
	);

	const {
		highlightedOnTrace,
		highlightedOffTrace,
		highlightedAcOnTrace,
		highlightedAcOffTrace
	} = getHighlightedTrace(
		state,
		highlightedPoint,
		newData,
		selectedDatFile,
		fileType
	);

	const {
		readingRowSelectedOnTraces,
		readingRowSelectedOffTraces,
		readingRowSelectedAcOnTraces,
		readingRowSelectedAcOffTraces
	} = getReadingRowSelectedTraces(
		state,
		selectedReadingRowPoints,
		newData,
		selectedDatFile,
		fileType
	);

	const allTraces = {
		preprocessedOnTrace,
		preprocessedOffTrace,
		oldOnTrace,
		oldOffTrace,
		newOnTrace,
		newOffTrace,
		oldAcOnTrace,
		oldAcOffTrace,
		newAcOnTrace,
		newAcOffTrace,
		oldOnCommentTrace,
		minCriteriaLine: minCriteriaLineTrace,
		maxCriteriaLine: maxCriteriaLineTrace,
		nextOnTrace,
		nextOffTrace,
		previousOnTrace,
		previousOffTrace,
		nextAcOnTrace,
		nextAcOffTrace,
		previousAcOnTrace,
		previousAcOffTrace,
		readingRowSelectedOnTraces,
		readingRowSelectedOffTraces,
		readingRowSelectedAcOnTraces,
		readingRowSelectedAcOffTraces,
		highlightedOnTrace,
		highlightedOffTrace,
		highlightedAcOnTrace,
		highlightedAcOffTrace
	};

	const { traces, arrTraces, annotations } = _getTracesFromXRange(
		state,
		allTraces,
		commentAnnotations,
		selectedCommentConfig
	);

	return {
		traces,
		arrTraces,
		annotations,
		allTraces,
		allAnnotations: commentAnnotations,
		criteriaLines: {
			minimum: minCriteriaLine,
			maximum: maxCriteriaLine
		}
	};
}

// This mutates the data directly, so only do this with brand new traces
function mergeTraces(newTraces, oldTraces) {
	// These are fields which are changed at some point and need to be kept in state
	const fieldsToCopy = ['visible'];
	if (!oldTraces || !Object.keys(oldTraces).length) {
		return;
	}
	Object.keys(oldTraces).forEach(traceKey => {
		const oldTrace = oldTraces[traceKey];
		const newTrace = newTraces[traceKey];

		// This trace does not exist anymore, no need to copy
		if (!newTrace) {
			return;
		}
		fieldsToCopy.forEach(field => {
			newTrace[field] = oldTrace[field];
		});
	});
}

const extractExtremetiesFromData = data => {
	let smallestStationId = Infinity;
	let largestStationId = -Infinity;

	data.forEach(datum => {
		if (!datum) {
			return;
		}
		const stationIds = datum.x || datum || [];

		if (stationIds.length < 2) {
			return;
		}

		const extremeties = [...stationIds.slice(0, 1), ...stationIds.slice(-1)] // Get first and last items
			.sort((a, b) => a - b); // then sort ascending

		const [smallStation, largeStation] = extremeties;
		if (smallStation < smallestStationId) {
			smallestStationId = smallStation;
		}
		if (largeStation > largestStationId) {
			largestStationId = largeStation;
		}
	});

	return [smallestStationId, largestStationId];
};

const getExtremetiesFromTraces = traces => {
	const {
		newOnTrace,
		newOffTrace,
		nextOnTrace,
		nextOffTrace,
		previousOffTrace,
		previousOnTrace,
		nextAcOnTrace,
		nextAcOffTrace,
		previousAcOffTrace,
		previousAcOnTrace
	} = traces;
	const tracesToUse = [
		newOnTrace,
		newOffTrace,
		nextOnTrace,
		nextOffTrace,
		previousOffTrace,
		previousOnTrace,
		nextAcOnTrace,
		nextAcOffTrace,
		previousAcOffTrace,
		previousAcOnTrace
	];

	return extractExtremetiesFromData(tracesToUse);
};

const _getStationIds = state => {
	const { allTraces } = state;
	if (state.allTraces) {
		return getExtremetiesFromTraces(allTraces);
	}

	const { previousChartData, newData, nextChartData } = state;
	const allReadings = [previousChartData, newData, nextChartData];

	return extractExtremetiesFromData(allReadings);
};

const _getReadingByName = (data, name) => {
	if (!data) {
		return null;
	}
	return data
		.filter(d => {
			return typeof d[name] === 'number';
		})
		.map(d => {
			return d[name];
		});
};

// Format station id using engineering notation (2000 will be 20+00)
function formatStationId(val) {
	let result = val;

	const isNumberType = typeof val === 'number';
	if (isNumberType) {
		const numberSplit = val.toString().split('.');
		const numInteger = numberSplit[0];

		// format number
		const paddedNum = numInteger.padStart(3, 0);
		const prefix = paddedNum.substr(0, paddedNum.length - 2);
		const suffix = paddedNum.substr(paddedNum.length - 2, paddedNum.length);
		const formattedNum = `${prefix}+${suffix}`;
		result = formattedNum;
	}
	return result;
}

export function getNumberOfTicksToShowPerRange(xRange) {
	const distance = Math.abs(xRange[1] - xRange[0]);

	if (distance <= 50) {
		return 15;
	}
	if (distance <= 200) {
		return 12;
	}
	if (distance <= 500) {
		return 10;
	}
	if (distance <= 1000) {
		return 7;
	}
	if (distance <= 20000) {
		return 6;
	}

	return 5; // Really big numbers
}

export function roundToStationId(stationId, interval) {
	const remainder = stationId % interval;

	if (remainder < interval / 2) {
		return stationId - remainder;
	}
	const inverseRemainder = interval - remainder;
	return stationId + inverseRemainder;
}

export function getSelectedStationIdsForTicks(
	numberOfPages,
	ticksPerPage,
	stationIds,
	interval = 2.5
) {
	const [startingStation] = stationIds;
	const stationIdRange = stationIds[stationIds.length - 1] - startingStation;

	const selectedStationIds = [];

	const totalNumberOfTicks = numberOfPages * ticksPerPage;

	const increment = stationIdRange / totalNumberOfTicks;

	for (let i = 0; i < totalNumberOfTicks; i += 1) {
		const impreciseStationId = startingStation + increment * i;
		const nearestStationId = roundToStationId(impreciseStationId, interval);

		selectedStationIds.push(nearestStationId);
	}

	return selectedStationIds;
}

export function getTicksFromStep(step, allStationIds) {
	const lastStationId = Math.max(...allStationIds);

	const numberOfTicks = Math.ceil(lastStationId / step);

	const tickvals = [];
	const ticktext = [];
	for (let tickCount = 0; tickCount < numberOfTicks; tickCount += 1) {
		const tickVal = tickCount * step;
		const formattedTick = formatStationId(tickVal);
		tickvals.push(tickVal);
		ticktext.push(formattedTick);
	}
	return { tickvals, ticktext };
}

export function getTicksFromRange(
	xRange,
	allStationIds,
	step = undefined,
	interval = 2.5
) {
	// If we don't have any station Ids in the range, just return x range (ie [0, 1]) without formatting
	if (allStationIds.length === 0) {
		return {
			tickvals: xRange,
			ticktext: xRange
		};
	}

	// If a step was given, don't do anything fancy, just calculate ticks from step
	if (step) {
		return getTicksFromStep(step, allStationIds);
	}

	let numberOfTicksToShowInView = getNumberOfTicksToShowPerRange(xRange);

	const xStart = allStationIds[0];
	const xEnd = allStationIds[allStationIds.length - 1];
	const entireChartRangeDistance = xEnd - xStart;

	const visibleXRangeDistance = xRange[1] - xRange[0];

	const numberOfPagesAvailable =
		entireChartRangeDistance / visibleXRangeDistance;

	if (step) {
		numberOfTicksToShowInView = Math.round(xRange[1] / step);
	}

	// always select the first station Id in range
	const selectedStationIds = getSelectedStationIdsForTicks(
		numberOfPagesAvailable,
		numberOfTicksToShowInView,
		allStationIds,
		interval
	);

	// always select the last station Id in range
	const formattedStationIds = selectedStationIds.map(formatStationId);

	return {
		tickvals: selectedStationIds,
		ticktext: formattedStationIds
	};
}

function _getDefaultLayout(useMv, chartResizedAlready = false) {
	const defaultYRange = useMv
		? DEFAULT_MIN_Y_RANGE_MV
		: DEFAULT_MIN_Y_RANGE_VOLTS;

	return {
		title: 'New/Old On/Off Plot',
		yaxis: {
			range: [0, defaultYRange], // second number should be set based on MV versus Volts
			autorange: false
		},
		yaxis2: {
			// range: [0, defaultYRange], // second number should be set based on MV versus Volts
			// autorange: false,
			overlaying: 'y',
			side: 'right'
		},
		hovermode: 'closest',
		autosize: !chartResizedAlready,
		annotations: [],
		selectdirection: 'd', // Only allow to select diagonally
		dragmode: 'select' // Default drag will be "select" instead of "zoom"
	};
}

const _xRangesChanged = (oldXRange, newXRange) => {
	const [oldx1, oldx2] = oldXRange;
	const [newx1, newx2] = newXRange;

	if (oldx1 === newx1 && oldx2 === newx2) {
		return false;
	}

	const oldXRangeDistance = oldx2 - oldx1;
	const newXRangeDistance = newx2 - newx1;

	if (oldXRangeDistance === newXRangeDistance) {
		return false;
	}

	return true;
};

const _getXaxisStepFromState = state => {
	if (!state) {
		return null;
	}

	const { gridAxesSetting } = state;
	if (!gridAxesSetting) {
		return null;
	}

	const { xaxis } = gridAxesSetting;
	if (!xaxis) {
		return null;
	}

	return xaxis.step;
};

const _shouldRecomputeXAxisTicks = (state, currentXRange, step) => {
	if (!currentXRange) {
		return true;
	}

	const oldXStep = _getXaxisStepFromState(state);
	// Ignore step if it does not have a value (likely it was not passed in)
	if (step && oldXStep !== step) {
		return true;
	}

	const oldXRange = getXRange(state);

	return _xRangesChanged(currentXRange, oldXRange);
};

export const getMinYRange = ({ oldData, newData, useMv }) => {
	const oldDataOn = _getReadingByName(oldData, 'on');
	const oldDataOff = _getReadingByName(oldData, 'off');
	const newDataOn = _getReadingByName(newData, 'on');
	const newDataOff = _getReadingByName(newData, 'off');

	const defaultYRange = useMv
		? DEFAULT_MIN_Y_RANGE_MV
		: DEFAULT_MIN_Y_RANGE_VOLTS;

	const fieldArraysWithValues = [
		oldDataOn,
		oldDataOff,
		newDataOn,
		newDataOff,
		[defaultYRange]
	].filter(value => value !== null);

	const flattenedItems = fieldArraysWithValues.flat();

	const min = flattenedItems.reduce((acc, item) => {
		if (Number.isNaN(acc)) {
			return item;
		}
		if (Number.isNaN(item)) {
			return acc;
		}
		if (useMv) {
			if (item < acc) {
				return item;
			}
			return acc;
		}
		if (item > acc) {
			return item;
		}
		return acc;
	}, Number.NaN);

	if (min < defaultYRange) {
		return min + -100;
	}

	return defaultYRange;
};

// Takes flat list and determins if any of a sample of its values are NaN
const _doesRangeIncludeNaNs = list => {
	if (!list || !list.length) {
		return false;
	}

	const listLength = list.length;

	// Check the first 100 values or all of the values (whichever is smallest)
	const lastIndexToCheck = Math.min(listLength, 100);
	for (let i = 0; i < lastIndexToCheck; i += 1) {
		const val = list[i];
		if (Number.isNaN(val)) {
			return true;
		}
	}

	return false;
};

// This assumes that all x-axis values are already in order. If they are not, then this would definitely break
const _getXaxisRangeFromTraces = tracesObject => {
	const xValues = Object.values(tracesObject)
		// Filter out where there is not at least 2 x values
		.filter(trace => trace && (trace.x || []).length > 1)
		// Remove bad data (where stationIds are NaNs)
		.filter(trace => !_doesRangeIncludeNaNs(trace.x))
		// Only return x values
		.map(trace => trace.x);

	// Take the first and last x value
	const xValueRanges = xValues.map(x => [x[0], x[x.length - 1]]);

	const lowXValues = xValueRanges.map(xRange => xRange[0]);
	const highXValues = xValueRanges.map(xRange => xRange[1]);

	const smallestX = Math.min(...lowXValues);
	const largestX = Math.max(...highXValues);

	return [smallestX, largestX];
};

export const _getXFromStations = stationIds => {
	return [stationIds[0], stationIds.slice(-1)[0]];
};

export const getDefaultXRange = state => {
	if (state.traces) {
		return _getXaxisRangeFromTraces(state.traces);
	}

	return _getXFromStations(_getStationIds(state));
};

// TODO: Should take into account the xrange - only change if xrange changes
export const getXAxisWithTicks = (state, currentXRange, options = {}) => {
	const { step = undefined, force = false } = options;
	const { layout = {}, selectedDatFile = {} } = state;
	const { xaxis = {} } = layout;

	const { interval = 2.5 } = selectedDatFile;

	const shouldRecomputeTicks = _shouldRecomputeXAxisTicks(
		state,
		currentXRange,
		step
	);

	if (!force && !shouldRecomputeTicks) {
		return { xaxis };
	}

	const stationIds = _getStationIds(state);
	let xRange = currentXRange;

	// The only time that xRange would not exist is when we first initialize data or when we zoom to midpoint
	if (!xRange) {
		xRange = getDefaultXRange(state);
	}

	const { tickvals, ticktext } = getTicksFromRange(
		xRange,
		stationIds,
		step,
		interval
	);

	// This will set the tick names (xaxis labels) as engineering notation
	return {
		xaxis: {
			...xaxis,
			autorange: false,
			tickmode: 'array', // If "array", the placement of the ticks is set via `tickvals` and the tick text is `ticktext`.
			tickvals,
			ticktext,
			range: xRange.slice() // Make  a copy
		}
	};
};

export const getDefaultLayout = (state, force = false) => {
	const { useMv } = state;
	return {
		..._getDefaultLayout(useMv),
		...getXAxisWithTicks(state, null, { force })
	};
};

export function lineStyleIndexByValue(value) {
	for (let i = 0; i < LINE_STYLE_OPTIONS.length; i += 1) {
		const styleOption = LINE_STYLE_OPTIONS[i];
		if (styleOption.value === value) {
			return styleOption;
		}
	}

	return LINE_STYLE_OPTIONS[0];
}

export const getTraces = (state, settings = {}, options = {}) => {
	const { surveyType, fileType } = state;
	if (!surveyType) {
		throw new Error('surveyType must be included to compute traces');
	}

	const {
		traces,
		arrTraces,
		annotations,
		allAnnotations,
		allTraces,
		criteriaLines
	} = getChartData(state, settings, surveyType, fileType);

	const { shouldMergeTraces = true } = options;
	if (shouldMergeTraces) {
		const { traces: oldTraces } = state;
		// This mutates the data directly, so only do this with brand new traces

		mergeTraces(traces, oldTraces);
	}

	return {
		criteriaLines,
		allTraces,
		traces,
		arrTraces,
		annotations,
		allAnnotations
	};
};

export const getSelectActionsFromNewChartData = (
	state,
	autoCorrectionInProcess
) => {
	const { selectActions } = state;
	if (!selectActions) {
		return DEFAULT_SELECT_ACTIONS;
	}

	return selectActions.map(selectAction => {
		const { value, checked: previousChecked } = selectAction;

		if (value !== 'remove-autocorrection') {
			return {
				...selectAction,
				checked: previousChecked || !autoCorrectionInProcess
			};
		}

		const checked = autoCorrectionInProcess && previousChecked;

		return {
			...selectAction,
			disabled: !autoCorrectionInProcess,
			checked
		};
	});
};

export const getPaginationStatus = state => {
	const xRange = getXRange(state);

	// TODO: This is inneficitent, we should calculate (and store) minStationId and maxStationId as soon as we get new data
	const { minX, maxX } = getMinMaxXfromState(state);

	const [xStart, xEnd] = xRange;

	const forward = xEnd < maxX;
	const backward = xStart > minX;

	return { forward, backward };
};
