import { filterByStdDev } from '../../../workers/FilterByStdDev';

export const createThreshold = (start, end, color) => ({
	start,
	end,
	color,
	visible: true,
	border: { color, width: 1 }
});

export const defaultCreateSimplifiedReadings = ({
	readings,
	subtype,
	propsOverride
}) => filterByStdDev({ readings, subtype, propsOverride });
