export const ACTIONS_NAME_SPACE = 'fileDiff_uploadedFiles';

export const SLICE_ROOT_KEY = 'uploadedFiles';

export const VALID_KEYS = ['A', 'B'];

export const SUPPORTED_FILE_EXTENSIONS = ['geojson'];

// PROCESSING MESSAGES

export const MESSAGE_KEY_NO_MESSAGE = 'NO_MESSAGE';
export const MESSAGE_KEY_IS_READING_FILE = 'IS_READING_FILE';

export const PROCESSING_MESSAGE_DICTIONARY = {
	[MESSAGE_KEY_NO_MESSAGE]: undefined,
	[MESSAGE_KEY_IS_READING_FILE]: 'reading file'
};

// ERROR MESSAGES

export const ERROR_KEY_UNSUPPORTED_FILE_TYPE = 'UNSUPPORTED_FILE_TYPE';

export const ERROR_KEY_MISMATCH_FILE_TYPE = 'MISMATCH_FILE_TYPE';

export const ERROR_KEY_UNKNOWN_ERROR = 'UNKNOWN_ERROR';

export const ERROR_DICTIONARY = {
	[ERROR_KEY_UNSUPPORTED_FILE_TYPE]:
		'We do not support the type of file that was uploaded.',
	[ERROR_KEY_MISMATCH_FILE_TYPE]:
		'The two files uploaded are different types of files.  We cannot compare different file types.',
	[ERROR_KEY_UNKNOWN_ERROR]: 'An unknown error occured.'
};
