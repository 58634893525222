import { DEFAULT_KEY as INIT_GRID_KEY } from '../../../websiteExperimental/pages/Home/variants.Grid';
import { DEFAULT_KEY as INIT_LIST_KEY } from '../../../websiteExperimental/pages/Home/variants.ApplicationsList';
import { DEFAULT_KEY as INIT_DETAILS_KEY } from '../../../websiteExperimental/pages/Home/variants.Details';
import { DEFAULT_KEY as INIT_SELECTED_APP_KEY } from '../../../websiteExperimental/pages/Home/content';

export const initialState = {
	dev: {
		isSideNavExpanded: false,
		components: {
			gridKey: INIT_GRID_KEY,
			applicationsListKey: INIT_LIST_KEY,
			detailsKey: INIT_DETAILS_KEY
		}
	},
	selectedAppKey: INIT_SELECTED_APP_KEY
};

export default {
	initialState
};
