import {
	SURVEY_PRIORITY,
	SURVEY_ON_OFF,
	SURVEY_DEPOL
} from './survey.constants';

export const isOnOff = (survey = {}) => {
	return survey.type === SURVEY_ON_OFF;
};

export const isDepol = (survey = {}) => {
	return survey.type === SURVEY_DEPOL;
};

export const isHigherPrioritySurveyType = (surveyA = {}, surveyB = {}) => {
	const A = SURVEY_PRIORITY.indexOf(surveyA.type);
	const B = SURVEY_PRIORITY.indexOf(surveyB.type);
	return !surveyB.type || (A > -1 && A < B);
};

export const compareSurveyType = (surveyA = {}, surveyB = {}) => {
	const aType = typeof surveyA === 'string' ? surveyA : surveyA.type;
	const bType = typeof surveyB === 'string' ? surveyB : surveyB.type;
	const A = SURVEY_PRIORITY.indexOf(aType);
	const B = SURVEY_PRIORITY.indexOf(bType);
	return A - B;
};
