"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

var _numeric = require("../format/numeric");

var PapaConfig = {
  unparse: {
    quoteChar: '"',
    escapeChar: '"',
    delimiter: ',',
    header: true,
    newline: '\r\n',
    skipEmptyLines: false // other option is 'greedy', meaning skip delimiters, quotes, and whitespace.

  },
  parse: {
    header: true,
    encoding: 'utf-8',
    transform: function transform(v) {
      if ((0, _numeric.isStringPurelyNumeric)(v)) {
        return (0, _numeric.formatNumber)(v, {
          scale: 12
        });
      }

      return v;
    }
  }
};
var _default = PapaConfig;
exports["default"] = _default;