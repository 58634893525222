/* eslint-disable no-use-before-define */
const defaultInterval = 1;
const defaultSelector = v => v;
const defaultAdder = (v, i = defaultInterval) => v + i;
const defaultSubtractor = (v, i = defaultInterval) => v - i;

const hasOverlap = (g1, g2, selector = defaultSelector) => {
	if (g1 && g2) {
		const { 0: g1First, [g1.length - 1]: g1Last } = g1;
		const { 0: g2First, [g2.length - 1]: g2Last } = g2;

		const g1FirstVal = selector(g1First);
		const g1LastVal = selector(g1Last);
		const g2FirstVal = selector(g2First);
		const g2LastVal = selector(g2Last);

		const g1FirstOverLaps = g2FirstVal <= g1FirstVal && g1FirstVal <= g2LastVal;
		const g1LastOverLaps = g2FirstVal <= g1LastVal && g1LastVal <= g2LastVal;

		const g2FirstOverlaps = g1FirstVal <= g2FirstVal && g2FirstVal <= g1LastVal;
		const g2LastOverlaps = g1FirstVal <= g2LastVal && g2LastVal <= g1LastVal;

		return (
			g1FirstOverLaps || g1LastOverLaps || g2FirstOverlaps || g2LastOverlaps
		);
	}

	return false;
};

const groupIsLessThan = (g1, g2, selector = defaultSelector) => {
	if (g1 && g2) {
		const g1Last = g1[g1.length - 1];
		const g2First = g2[0];

		const g1LastVal = selector(g1Last);
		const g2FirstVal = selector(g2First);

		return g1LastVal < g2FirstVal;
	}

	return !g1 && g1 !== g2;
};

// const groupIsGreaterThan = (g1, g2, selector = defaultSelector) => {
// 	if (g1 && g2) {
// 		const g1First = g1[0];
// 		const g2Last = g2[g2.length - 1];

// 		const g1FirstVal = selector(g1First);
// 		const g2LastVal = selector(g2Last);

// 		return g1FirstVal > g2LastVal;
// 	}

// 	return !!g1;
// };

// assumes overlap
const combineGroups = (g1, g2, selector = defaultSelector) => {
	const { 0: g1First, [g1.length - 1]: g1Last } = g1;
	const { 0: g2First, [g2.length - 1]: g2Last } = g2;

	const i1 = getMinItem(g1First, g2First, selector);
	const i2 = getMaxItem(g1Last, g2Last, selector);

	return [i1, i2];
};

const cleanGroups = (groups, selector = defaultSelector) => {
	return groups.filter(g => {
		const { 0: i1, [g.length - 1]: i2 } = g;
		return selector(i1) <= selector(i2);
	});
};

const removeGroupFromGroup = (
	compareGroup,
	targetGroup,
	selector = defaultSelector,
	adder = defaultAdder,
	subtractor = defaultSubtractor,
	interval = defaultInterval
) => {
	let result;
	const { 0: cg1, [compareGroup.length - 1]: cg2 } = compareGroup;
	const { 0: tg1, [targetGroup.length - 1]: tg2 } = targetGroup;

	const cg1Val = selector(cg1);
	const cg2Val = selector(cg2);
	const tg1Val = selector(tg1);
	const tg2Val = selector(tg2);

	const cg1InBetween = tg1Val <= cg1Val && cg1Val <= tg2Val;
	const cg2InBetween = tg1Val <= cg2Val && cg2Val <= tg2Val;

	const tg1InBetween = cg1Val <= tg1Val && tg1Val <= cg2Val;
	const tg2InBetween = cg1Val <= tg2Val && tg2Val <= cg2Val;

	const compareGroupIsContained = cg1InBetween && cg2InBetween;
	if (compareGroupIsContained) {
		result = [[tg1, subtractor(cg1, interval)], [adder(cg2, interval), tg2]];
		return cleanGroups(result, selector);
	}

	const targetGroupIsContained = tg1InBetween && tg2InBetween;
	if (targetGroupIsContained) {
		return [];
	}

	if (cg1InBetween) {
		result = [[tg1, subtractor(cg1, interval)]];
		return cleanGroups(result, selector);
	}

	if (cg2InBetween) {
		result = [[adder(cg2, interval), tg2]];
		return cleanGroups(result, selector);
	}

	result = [[tg1, tg2]];
	return cleanGroups(result, selector);
};

const getMinItem = (i1, i2, selector = defaultSelector) => {
	const v1 = selector(i1);
	const v2 = selector(i2);

	if (v2 < v1) {
		return i2;
	}

	return i1;
};

const getMaxItem = (i1, i2, selector = defaultSelector) => {
	const v1 = selector(i1);
	const v2 = selector(i2);

	if (v2 > v1) {
		return i2;
	}

	return i1;
};

export const addGroupToRangeGroups = (
	group,
	rangeGroups = [],
	selector = defaultSelector
) => {
	const { 0: start, [group.length - 1]: end } = group;
	let compareGroup = [start, end];
	let accMod;
	let prevFoundOverlap = false;
	let isDoneLooking = false;

	const mergedRangeGroups = rangeGroups.reduce((acc, currentGroup) => {
		if (isDoneLooking) {
			accMod = [...acc, currentGroup];
			return accMod;
		}
		if (prevFoundOverlap) {
			if (hasOverlap(compareGroup, currentGroup, selector)) {
				accMod = [...acc];
				accMod.pop();
				compareGroup = combineGroups(compareGroup, currentGroup, selector);
				accMod.push(compareGroup);
				return accMod;
			}
			isDoneLooking = true;
			accMod = [...acc, currentGroup];
			return accMod;
		}
		if (groupIsLessThan(compareGroup, currentGroup, selector)) {
			isDoneLooking = true;
			accMod = [...acc, compareGroup, currentGroup];
			return accMod;
		}
		if (hasOverlap(compareGroup, currentGroup, selector)) {
			prevFoundOverlap = true;
			compareGroup = combineGroups(compareGroup, currentGroup, selector);
			accMod = [...acc, compareGroup];
			return accMod;
		}
		accMod = [...acc, currentGroup];
		return accMod;
	}, []);

	if (!isDoneLooking && !prevFoundOverlap) {
		mergedRangeGroups.push(compareGroup);
	}

	return mergedRangeGroups;
};

export const removeGroupFromRangeGroups = (
	group,
	rangeGroups = [],
	selector = defaultSelector,
	adder = defaultAdder,
	subtractor = defaultSubtractor,
	interval = defaultInterval
) => {
	const { 0: start, [group.length - 1]: end } = group;
	const compareGroup = [start, end];
	let groupsToPush;
	let prevFoundOverlap = false;
	let isDoneLooking = false;

	const mergedRangeGroups = rangeGroups.reduce((acc, currentGroup) => {
		if (isDoneLooking) {
			return [...acc, currentGroup];
		}
		if (prevFoundOverlap) {
			if (hasOverlap(compareGroup, currentGroup, selector)) {
				groupsToPush = removeGroupFromGroup(
					compareGroup,
					currentGroup,
					selector,
					adder,
					subtractor,
					interval
				);
				return [...acc, ...groupsToPush];
			}
			isDoneLooking = true;
			return [...acc, currentGroup];
		}
		if (groupIsLessThan(compareGroup, currentGroup, selector)) {
			isDoneLooking = true;
			return [...acc, currentGroup];
		}
		if (hasOverlap(compareGroup, currentGroup, selector)) {
			prevFoundOverlap = true;
			groupsToPush = removeGroupFromGroup(
				compareGroup,
				currentGroup,
				selector,
				adder,
				subtractor,
				interval
			);
			return [...acc, ...groupsToPush];
		}
		return [...acc, currentGroup];
	}, []);

	return mergedRangeGroups;
};
