/* eslint-disable import/prefer-default-export */
const {
	KEY_COMPANY,
	KEY_TEXT_SEARCH,
	BASE_URL_USERS,
	BASE_URL_CUSTOMERS
} = require('./constants');
const { createSearchFromUpdateFilter } = require('./filters');

export const createUsersLink = companyName => {
	const search = createSearchFromUpdateFilter(KEY_COMPANY, companyName, '');
	return `${BASE_URL_USERS}${search !== '?' ? search : ''}`;
};

export const createCompaniesLink = searchText => {
	const search = createSearchFromUpdateFilter(KEY_TEXT_SEARCH, searchText, '');
	return `${BASE_URL_CUSTOMERS}${search !== '?' ? search : ''}`;
};
