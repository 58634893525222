const promisifyAjax = fn =>
	new Promise((resolve, reject) => {
		const onComplete = (err, res) => {
			if (err) {
				reject(err);
			} else {
				resolve(res);
			}
		};

		fn({ resolve, reject, onComplete });
	});

export default promisifyAjax;
