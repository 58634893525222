export const addToTagRequestTracker = (tracker = {}, lineInternalId, tag) => {
	const tags = tracker[lineInternalId] || [];

	const hasTag = tags.indexOf(tag) > -1;
	if (!hasTag) {
		return {
			...tracker,
			[lineInternalId]: [...tags, tag]
		};
	}

	return tracker;
};

export const deleteFromTagRequestTracker = (
	tracker = {},
	lineInternalId,
	tag
) => {
	const tags = tracker[lineInternalId] || [];

	const hasTag = tags.indexOf(tag) > -1;
	if (hasTag) {
		return {
			...tracker,
			[lineInternalId]: tags.filter(v => v !== tag)
		};
	}

	return tracker;
};
