/* eslint-disable import/prefer-default-export */
/* eslint-disable import/no-cycle */
import { createActions } from 'redux-actions';
import { ROOT_SLICE } from '../constants';

const {
	clearAllSaveErrors,
	privateSetSaveError,
	privateClearItemSaveError
} = createActions(
	{
		CLEAR_ALL_SAVE_ERRORS: saveErrors => ({ saveErrors }),
		PRIVATE_SET_SAVE_ERROR: (type, itemId, message) => ({
			type,
			itemId,
			message
		}),
		PRIVATE_CLEAR_ITEM_SAVE_ERROR: (type, itemId) => ({ type, itemId })
	},
	{ prefix: ROOT_SLICE }
);

export { clearAllSaveErrors, privateSetSaveError, privateClearItemSaveError };
