import undoable from 'redux-undo';

import { SET_USER_HIDDEN_COLUMNS, SET_IS_HIDE_COLUMNS } from '../actionTypes';

import { getActionTypes } from '../utils';

const initialState = {
	isHideColumns: true
};

const reducer = exportType => (state = initialState, action = {}) => {
	const { payload = {}, type } = action;
	const { exportType: payloadExportType } = payload || {};
	switch (true) {
		case type === SET_IS_HIDE_COLUMNS && exportType === payloadExportType:
			return { ...state, isHideColumns: payload.isHideColumns };
		case type === SET_USER_HIDDEN_COLUMNS && exportType === payloadExportType:
			return { ...state, userHiddenColumns: payload.userHiddenColumns };
		default:
			return state;
	}
};

export default exportType => {
	const actionTypes = getActionTypes(exportType);
	return undoable(reducer(exportType), {
		...actionTypes,
		ignoreInitialState: true,
		limit: 5
	});
};
