/* eslint-disable import/no-cycle */
/* eslint-disable camelcase */
import {
	clearJobLines,
	requestJobLines,
	receiveJobLines,
	setTotalCount,
	setLoadJobLinesErrorMessage,
	clearLoadJobLinesErrorMessage
} from './actions';

// ************
// PARTS
// ************
const reducerParts = {
	[clearJobLines]: state => {
		return {
			...state,
			jobLines: [],
			totalCount: undefined,
			isLoadingJobLines: false,
			isLoadedJobLines: false,
			loadJobLinesErrorMessage: undefined
		};
	},
	[requestJobLines]: state => {
		return {
			...state,
			isLoadingJobLines: true,
			loadJobLinesErrorMessage: undefined
		};
	},
	[receiveJobLines]: (state, { payload: { jobLines } }) => {
		return {
			...state,
			jobLines: [...state.jobLines, ...jobLines],
			isLoadingJobLines: false,
			isLoadedJobLines: true
		};
	},
	[setTotalCount]: (state, { payload: { totalCount } }) => {
		return {
			...state,
			totalCount
		};
	},
	[setLoadJobLinesErrorMessage]: (state, { payload: { message } }) => {
		return {
			...state,
			isLoadingJobLines: false,
			loadJobLinesErrorMessage: message
		};
	},
	[clearLoadJobLinesErrorMessage]: state => {
		return {
			...state,
			loadJobLinesErrorMessage: undefined
		};
	}
};

export default reducerParts;
