import L from 'leaflet';
import * as esri from 'esri-leaflet';
import { Pbf, VectorTile } from 'leaflet.vectorgrid';
import 'leaflet.markercluster';
import 'leaflet-draw';

import injectPolylineMeasure from './Leaflet.InjectPolylineMeasure';
import injectCoordinates from './L.Control.Coordinates';

import injectAutoCorrectReadingsDrawTool from './L.Control.SelectAutoCorrectReadings';

// eslint-disable-next-line import/no-cycle
import MapsUtil from '../../commons/components/leaflet/util/MapsUtil';
import beautifyIcon from './L.BeautifyMarker';

// ****************
// index
// ****************
const Index = Pbf;

// ****************
// ESRI
// ****************
L.esri = L.esri || esri;

// ****************
// PolylineMeasure
// ****************
injectPolylineMeasure(L);

// ****************
// Coordinates
// ****************
injectCoordinates(L);

// ****************
// BeautifyIcon
// ****************
beautifyIcon(L);

// betterscale(L);

// Auto correct draw tool
injectAutoCorrectReadingsDrawTool(L);

// ****************
// AIP
// ****************
const { Credentials: AWSCredentials, S3 } = require('aws-sdk');

function getFillColorFromStructureOffPotential(structureOffPotential) {
	let fillColor;
	if (structureOffPotential === null || structureOffPotential === 0) {
		fillColor = '#AC9986';
	} else if (structureOffPotential < 0 && structureOffPotential > -850) {
		fillColor = 'red';
	} else if (structureOffPotential <= -850 && structureOffPotential > -1200) {
		fillColor = 'green';
	} else if (structureOffPotential <= -1200) {
		fillColor = 'purple';
	} else {
		fillColor = 'white';
	}
	return fillColor;
}

function redrawLayer(layer) {
	layer.redraw();
}

function S3Object(initCred) {
	const obj = {
		init(cred) {
			const {
				Credentials: {
					AccessKeyId,
					SecretAccessKey,
					SessionToken,
					Expiration
				} = {}
			} = cred;
			const credentials = new AWSCredentials({
				accessKeyId: AccessKeyId,
				secretAccessKey: SecretAccessKey,
				sessionToken: SessionToken
			});
			credentials.expireTime = new Date(Expiration);
			// AWS.config.update({region: 'us-west-2', credentials: credentials});
			this.s3 = new S3({ region: 'us-west-2', credentials });
			return this;
		},
		get(key, bucket = 'tiles.aip.aegion.com') {
			return new Promise((resolve, reject) => {
				if (!key) {
					resolve(null);
				} else {
					const params = {
						Bucket: bucket,
						Key: key
					};
					this.s3.getObject(params, (err, res) => {
						if (err) {
							reject(err);
						} else {
							resolve(res.Body);
						}
					});
				}
			});
		},
		list(bucket = 'aegionassetviewjobs') {
			return new Promise((resolve, reject) => {
				const params = {
					Bucket: bucket
				};
				this.s3.listObjects(params, (err, res) => {
					if (err) {
						reject(err);
					} else {
						resolve(res.Contents);
					}
				});
			});
		},
		getUrl(key, bucket = 'tiles.aip.aegion.com') {
			return new Promise((resolve, reject) => {
				const params = {
					Bucket: bucket,
					Key: key
				};
				this.s3.getSignedUrl('getObject', params, (err, url) => {
					if (err) {
						reject(err);
					} else {
						resolve(url);
					}
				});
			});
		},
		getUrlSync(key, bucket = 'tiles.aip.aegion.com') {
			const params = {
				Bucket: bucket,
				Key: key
			};
			return this.s3.getSignedUrl('getObject', params);
		}
	};
	obj.init(initCred);
	return obj;
}

L.LayerGroup.AIP = L.LayerGroup.extend({
	esLayers: [],
	dynamicLayers: [],
	initialize(options) {
		this._app = options.app;
		this.options = options;
		L.LayerGroup.prototype.initialize.call(this);
		this._authorize().then(res => {
			this._addLayers(res.map);
		});
	},
	refresh() {
		this.eachLayer(redrawLayer);
	},
	_authorize() {
		return new Promise((resolve, reject) => {
			MapsUtil.access(this._app, (err, res) => {
				if (err) {
					reject(err);
				} else {
					this._credentials = res;
					this._s3Object = new S3Object(res);
					resolve(res);
				}
			});
		});
	},
	_addLayers(mapJson) {
		this._mapJson = mapJson;
		Object.keys(mapJson.layers).forEach(layerName => {
			this._addLayer(layerName, mapJson.layers[layerName]);
		});
	},
	_addLayer(name, config) {
		let layer;
		this._getLayerStyles(config.stylesheets.web).then(res => {
			let styles;
			if (res) {
				styles = JSON.parse(res.toString());
				Object.keys(styles).forEach(attr => {
					if (styles[attr].func) {
						// eslint-disable-next-line no-new-func
						styles[attr] = new Function(
							'properties',
							'zoom',
							styles[attr].func
						);
					}
					if (styles[attr].icon) {
						// eslint-disable-next-line no-eval
						styles[attr].icon = eval(styles[attr].icon);
					}
				});
			}
			if (config.type === 'geojson') {
				layer = L.elasticSearchLayers(this.options).addTo(this);
				// var layer = L.elasticSearchGeoJSON({onClick:this.options.showAsset}).addTo(this)
				this.esLayers.push(layer);
			} else if (config.type === 'images') {
				layer = L.tileLayer
					.aip({
						app: this._app,
						name,
						config,
						credentials: this._credentials,
						vectorTileLayerStyles: styles,
						filter: this.options.filter
					})
					.addTo(this);
			} else if (config.type === 'dynamic') {
				layer = L.dynamicImage
					.aip({
						app: this._app,
						name,
						config,
						credentials: this._credentials,
						filter: this.options.filter
					})
					.addTo(this);
				this.dynamicLayers.push(layer);
			} else {
				layer = L.vectorGrid
					.aip({
						app: this._app,
						name,
						config,
						credentials: this._credentials,
						vectorTileLayerStyles: styles,
						filter: this.options.filter
					})
					.addTo(this);
			}
		});
	},
	_getLayerStyles(key) {
		return this._s3Object.get(key);
	},
	setSelected(selected) {
		this.esLayers.forEach(function setEsLayers(layer) {
			layer.setSelected(selected);
		});
		this.dynamicLayers.forEach(function setDynamicLayers(layer) {
			layer.setSelected(selected);
		});
	},
	set(params) {
		// not needed yet
		/* this.esLayers.forEach(layer => {
			layer.set(params);
		}); */
		this.dynamicLayers.forEach(layer => {
			layer.set(params);
		});
	}
});

L.DynamicImage = {
	AIP: L.Layer.extend({
		selected: {},
		visible: false,
		options: {
			updateInterval: 150
		},
		currentImage: null,
		initialize(options) {
			L.Util.setOptions(this, options);
			this._update = L.Util.throttle(
				this._update,
				this.options.updateInterval,
				this
			);
		},
		onAdd(map) {
			map.on('moveend', this._update, this);
			this._update();
		},
		onRemove(map) {
			map.off('moveend', this._update, this);
			if (this.currentImage) {
				this._map.removeLayer(this.currentImage);
			}
		},
		setSelected(selected) {
			this.selected = selected;
			this._update();
		},

		_update() {
			if (!this.visible || !this._map) {
				if (this.currentImage) {
					this._map.removeLayer(this.currentImage);
				}
				return;
			}
			const bounds = this._map.getBounds();

			const neProjected = this._map.options.crs.project(bounds.getNorthEast());
			const swProjected = this._map.options.crs.project(bounds.getSouthWest());

			// this ensures ne/sw are switched in polar maps where north/top bottom/south is inverted
			const imageSize = this._map.getSize();
			const boundsProjected = L.bounds(neProjected, swProjected);
			const coords = [
				boundsProjected.min.x,
				boundsProjected.min.y,
				boundsProjected.max.x,
				boundsProjected.max.y
			];
			MapsUtil.image(
				this.options.app,
				this.options.name,
				coords,
				imageSize,
				this.selected,
				undefined,
				(err, res = {}) => {
					if (err) {
						// reject(err)
					} else {
						const { image = '' } = res;
						const url = `data:png;base64,${image}`;
						if (this.currentImage) {
							this._map.removeLayer(this.currentImage);
						}
						this.currentImage = L.imageOverlay(url, bounds).addTo(this._map);
						// tile.src = tile.src + '&new=true'
					}
				}
			);
		},

		set(params) {
			const { dynamicLayerVisibility } = params;
			if (dynamicLayerVisibility) {
				this.setDynamicLayerVisibility(dynamicLayerVisibility);
			}
		},

		setDynamicLayerVisibility(dynamicLayerVisibility) {
			const { name } = this.options;
			const { [name]: isVisible = false } = dynamicLayerVisibility;
			this.visible = isVisible;
			this._update();
		}
	})
};

L.SVG.Tile.AIP = L.SVG.Tile.extend({
	_addPath(layer) {
		if (this.options.filter === undefined || this.options.filter(layer)) {
			this._rootGroup.appendChild(layer._path);
			this._layers[L.stamp(layer)] = layer;
		}
	}
});
L.svg.tile.aip = function setSvgTitle(tileCoord, tileSize, opts) {
	return new L.SVG.Tile.AIP(tileCoord, tileSize, opts);
};

L.TileLayer.AIP = L.TileLayer.extend({
	options: {},
	initialize(options) {
		this._s3Object = new S3Object(options.credentials);
		this.on('tileerror', this.tileError.bind(this));
		L.TileLayer.prototype.initialize.call(this, '', options);
	},
	getTileUrl(coords) {
		const url = this._s3Object.getUrlSync(
			`${this.options.config.path}/${coords.z}/${coords.x}/${coords.y}.png`
		);
		return url;
	},
	tileError(err) {
		if (err.tile.src.indexOf('new=true') === -1) {
			this._createTile(err.coords, err.tile);
		}
	},
	_createTile(coords, tile) {
		return new Promise((resolve, reject) => {
			const onRequestComplete = (err, res) => {
				if (err) {
					reject(err);
				} else {
					tile.src += '&new=true';
					resolve(res);
				}
			};

			const { app, name } = this.options;
			MapsUtil.imageTile(app, name, coords, onRequestComplete);
		});
	}
});

L.VectorGrid.AIP = L.VectorGrid.extend({
	options: { rendererFactory: L.svg.tile.aip },
	initialize(options) {
		this._s3Object = new S3Object(options.credentials);
		L.VectorGrid.prototype.initialize.call(this, options);
	},
	_getVectorTilePromise(coords) {
		return new Promise(resolve => {
			this._getObject(coords)
				.then(json => json)
				.catch(() => this._createTile(coords))
				.then(json => {
					if (json) {
						resolve(json);
					}
				});
		});
	},
	_getObject(coords) {
		const { customer } = window;
		const basePath =
			customer !== undefined
				? this.options.config.path.replace(/{customer}/i, customer)
				: this.options.config.path;
		const path = `${basePath}/${coords.z}/${coords.x}/${coords.y}.mvt`;
		return this._s3Object.get(path).then(function setLayers(res) {
			if (res.byteLengh > 0) {
				const pbf = new Index(res);
				const json = new VectorTile(pbf);
				Object.keys(json.layers).forEach(layerName => {
					const feats = [];

					for (let i = 0; i < json.layers[layerName].length; i += 1) {
						const feat = json.layers[layerName].feature(i);
						feat.geometry = feat.loadGeometry();
						feats.push(feat);
					}

					json.layers[layerName].features = feats;
				});
				return json;
			}
			return {};
		});
	},
	_createTile(coords) {
		return new Promise((resolve, reject) => {
			MapsUtil.vectorTile(this.options.app, this.options.name, coords, err => {
				if (err) {
					reject(err);
				} else {
					this._getObject(coords).then(res2 => {
						resolve(res2);
					});
				}
			});
		});
	}
});

L.ElasticSearchLayers = L.LayerGroup.extend({
	initialize(options) {
		this.options = options;
		L.LayerGroup.prototype.initialize.call(this);
		this.layers = [];
		this.layers.push(
			L.elasticSearchAggs({ onClick: this.options.showAsset }).addTo(this)
		);
		this.layers.push(
			L.elasticSearchAssets({ onClick: this.options.showAsset }).addTo(this)
		);
	},
	setSelected(selected) {
		this.layers.forEach(function setLayer(item) {
			item.setSelected(selected);
		});
	}
});

L.ElasticSearchAggs = L.GeoJSON.extend({
	initialize(options) {
		// this.filter = new Filters()
		const geojsonMarkerOptions = {
			radius: 8,
			fillColor: '#ff7800',
			color: '#FFF',
			weight: 1,
			opacity: 1,
			fillOpacity: 0.8
		};
		options.pointToLayer = (feature, latlng) => {
			return L.circleMarker(latlng, geojsonMarkerOptions);
		};
		options.onEachFeature = (feature, layer) => {
			// layer.bindPopup(feature.properties.MAP_LABEL);
			layer.bindTooltip(feature.properties.key, {
				direction: 'top',
				permanent: true
			});
			layer.on('click', this.onClick.bind(this));
		};
		this.updateLayer();
		L.GeoJSON.prototype.initialize.call(this, null, options);
		// this.on('add',this.add)
	},
	setSelected(selected) {
		this.selected = selected;
		this.updateLayer();
	},
	updateLayer() {
		// this.filter.aggsgeojson(this.selected,(function(err,res){
		// 	if (res) {
		// 		this.clearLayers()
		// 		this.addData(res)
		// 	}
		// }).bind(this))
	},
	onClick(e) {
		if (this.options.onClick) {
			this.options.onClick(e.target.feature.properties.key);
		}
	}
});

L.ElasticSearchAssets = L.MarkerClusterGroup.extend({
	initialize(options) {
		options.maxClusterRadius = 30;
		options.showCoverageOnHover = false;
		// options.zoomToBoundsOnClick = false;
		// this.filter = new Filters()
		const tankMarkerOptions = {
			radius: 8,
			fillColor: '#ffffff',
			color: '#BBB',
			weight: 0.5,
			opacity: 1,
			fillOpacity: 0.8
		};

		const groundBedDeepMarkerOptions = {
			iconUrl: '/Content/Images/AssetView/groundBedDeep.png'
		};
		const cmPanelMarkerOptions = {
			iconUrl: '/Content/Images/AssetView/cm-panel.png'
		};
		options.pointToLayer = function getAssignedLayer(feature, latlng) {
			switch (feature.properties.classCode) {
				case 'groundBedShallow':
					return L.marker(latlng, {
						icon: L.icon({
							iconUrl: '/Content/Images/AssetView/groundBedShallow.png'
						})
					});
				case 'wellCasing':
					return L.marker(latlng, {
						icon: L.icon({
							iconUrl: '/Content/Images/AssetView/wellCasing.png'
						})
					});
				case 'dcDecoupler':
					return L.marker(latlng, {
						icon: L.icon({
							iconUrl: '/Content/Images/AssetView/dcDecoupler.png'
						})
					});
				case 'rectTeg':
					return L.marker(latlng, {
						icon: L.icon({ iconUrl: '/Content/Images/AssetView/rectTeg.png' })
					});
				case 'tankBottomRefCell':
					return L.marker(latlng, {
						icon: L.icon({ iconUrl: '/Content/Images/AssetView/tank-brc.png' })
					});
				case 'tankProfileTube':
					return L.marker(latlng, {
						icon: L.icon({ iconUrl: '/Content/Images/AssetView/tank-prt.png' })
					});
				case 'bondCable':
					return L.marker(latlng, {
						icon: L.icon({ iconUrl: '/Content/Images/AssetView/bond.png' })
					});
				case 'sacrifice':
					return L.marker(latlng, {
						icon: L.icon({ iconUrl: '/Content/Images/AssetView/sacrifice.png' })
					});
				case 'cmPanel':
					return L.marker(latlng, { icon: L.icon(cmPanelMarkerOptions) });
				case 'junctionBox':
					return L.marker(latlng, { icon: L.icon(cmPanelMarkerOptions) });
				case 'tank':
					return L.circleMarker(latlng, tankMarkerOptions);
				case 'tankSkiddedPortable':
					return L.circleMarker(latlng, tankMarkerOptions);
				case 'groundBedDeep':
					return L.marker(latlng, { icon: L.icon(groundBedDeepMarkerOptions) });
				case 'rect':
					switch (feature.properties.item.repairrequired) {
						case null:
							return L.marker(latlng, {
								icon: L.icon({
									iconUrl: '/Content/Images/AssetView/deficiency.png'
								})
							});
						default: {
							let iconUrl;

							switch (feature.properties.item.companyRectifierStatus) {
								case 'Damaged - Can Repair':
									iconUrl = '/Content/Images/AssetView/rectifier-damage.png';
									break;
								case 'Off':
									iconUrl = '/Content/Images/AssetView/rectifier-off.png';
									break;
								default:
									iconUrl = '/Content/Images/AssetView/rectifier-on.png';
							}

							return L.marker(latlng, {
								icon: L.icon({
									iconUrl
								})
							});
						}
					}
				case 'permanentReferenceElectrode': {
					const iconUrl = '/Content/Images/AssetView/permRef.png';

					return L.marker(latlng, {
						icon: L.icon({
							iconUrl
						})
					});
				}

				case 'testpoint':
					switch (feature.properties.item.repairrequired) {
						case null:
							return L.marker(latlng, {
								icon: L.icon({
									iconUrl: '/Content/Images/AssetView/deficiency.png'
								})
							});
						default:
							return L.circleMarker(latlng, {
								radius: 8,
								color: '#FFF',
								weight: 1,
								opacity: 1,
								fillOpacity: 0.8,
								fillColor: getFillColorFromStructureOffPotential(
									feature.properties.item.structureOffPotential
								)
							});
					}

				case 'atmosphericAirSoilRiser': {
					const {
						insulationCondition,
						riserCorrosion
					} = feature.properties.item;
					let iconUrl;

					if (
						insulationCondition === 'Exposed' ||
						insulationCondition === 'Good' ||
						insulationCondition === 'No' ||
						riserCorrosion === 'No' ||
						riserCorrosion === 'Slight' ||
						riserCorrosion === null
					) {
						iconUrl = '/Content/Images/AssetView/riserGood.png';
					} else if (
						insulationCondition === 'Damaged' ||
						riserCorrosion === 'Heavy Corrosion'
					) {
						iconUrl = '/Content/Images/AssetView/riserBad.png';
					} else {
						iconUrl = '/Content/Images/AssetView/riserNeutral.png';
					}
					return L.marker(latlng, {
						icon: L.icon({
							iconUrl
						})
					});
				}
				default:
					return null;
			}
		};
		this.on('layeradd', evt => {
			evt.layer.on('click', this.onClick.bind(this));
			// console.log(evt)
		});
		this.on('add', this.onMapAdd.bind(this));
		L.MarkerClusterGroup.prototype.initialize.call(this, options);
		// this.on('add',this.add)
	},
	setSelected(selected) {
		this.selected = selected;
		this.updateMap();
	},
	onClick(e) {
		const {
			operatingAreaName,
			facilityName,
			jobNumVendor
		} = e.target.feature.properties.item;
		let key;
		if (operatingAreaName !== 'not set') {
			key = operatingAreaName;
		} else if (facilityName !== 'not set') {
			key = facilityName;
		} else {
			key = jobNumVendor;
		}
		if (this.options.onClick) {
			this.options.onClick(key, e.target.feature.properties._id);
		}
	},
	onMapAdd(evt) {
		evt.target._map.on('moveend', this.onZoomEnd.bind(this));
		this.updateMap();
	},
	onZoomEnd() {
		this.updateMap();
	},
	updateMap() {
		if (this._map.getZoom() < 18) {
			this.options.disableClusteringAtZoom = 0;
		} else {
			this.options.disableClusteringAtZoom = 20;
		}

		if (this._map.getZoom() > 11) {
			return;
		}
		this.clearLayers();
	}
});

L.elasticSearchLayers = function elasticSearchLayers(options) {
	return new L.ElasticSearchLayers(options);
};

L.elasticSearchAssets = function elasticSearchAssets(options) {
	return new L.ElasticSearchAssets(options);
};

L.elasticSearchAggs = function elasticSearchAggs(options) {
	return new L.ElasticSearchAggs(options);
};

L.dynamicImage = {
	aip(options) {
		return new L.DynamicImage.AIP(options);
	}
};

L.tileLayer.aip = function setTitleAip(options) {
	return new L.TileLayer.AIP(options);
};

L.vectorGrid.aip = function setVectorGridAip(options) {
	return new L.VectorGrid.AIP(options);
};

L.layerGroup.aip = function setLayerGroupAip(options) {
	return new L.LayerGroup.AIP(options);
};

export default L;
