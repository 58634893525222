/* eslint-disable import/no-cycle */
/* eslint-disable import/prefer-default-export */
import _ from 'lodash';

import { createEntityObject } from '../../CustomersV2/actions/util';

import { selectRootSliceAdmin } from '../../../Main/selectors';
// import {
// 	selectSelectedEntity,
// 	selectSelectedEntityChangesClone
// } from '../selectors';
import { setSelectedEntity, setSelectedEntityChangesClone } from './actions';

import {
	selectSelectedCustomer,
	selectSelectedCustomerChangesClone
} from '../../CustomersV2/selectors';
import { setSelectedCustomerChangesClone } from '../../CustomersV2/actions';

const isDefined = v => v !== undefined && v !== null;

const thunkSetSelectedEntity = entity => dispatch => {
	if (isDefined(entity)) {
		const clone = _.cloneDeep(entity);
		dispatch(setSelectedEntity(entity));
		dispatch(setSelectedEntityChangesClone(clone));
	} else {
		dispatch(setSelectedEntity(undefined));
		dispatch(setSelectedEntityChangesClone(undefined));
	}
};

const thunkSetNewSelectedEntity = () => (dispatch, getState) => {
	const stateAdmin = selectRootSliceAdmin(getState());
	const customerChangesClone = selectSelectedCustomerChangesClone(stateAdmin);
	const customerClone = _.cloneDeep(customerChangesClone);
	customerClone.entities = customerClone.entities || [];
	const newEntity = createEntityObject({ isNew: true });
	customerClone.entities.push(newEntity);
	dispatch(setSelectedCustomerChangesClone(customerClone));
	dispatch(thunkSetSelectedEntity(newEntity));
};

const thunkUpdateEntityName = (entity, name) => (dispatch, getState) => {
	const trimmedName = (name || '').trim();
	const stateAdmin = selectRootSliceAdmin(getState());
	const customerChangesClone = selectSelectedCustomerChangesClone(stateAdmin);
	const customerClone = _.cloneDeep(customerChangesClone);
	customerClone.entities = customerClone.entities || [];
	const { entities } = customerClone;
	const idx = entities.findIndex(e => e.clientId === entity.clientId);
	const hasNameConflict = entities.some(e => {
		const isThisEntity = e.clientId === entity.clientId;
		return (
			!isThisEntity &&
			e.active &&
			entity.active &&
			(e.newName || e.name).toUpperCase() ===
				(trimmedName || entity.name).toUpperCase()
		);
	});
	if (idx > -1) {
		entities[idx].newName = name;
		entities[idx].hasNameConflict = hasNameConflict || undefined;
		dispatch(setSelectedCustomerChangesClone(customerClone));
	}
};

const thunkDeleteEntity = entity => (dispatch, getState) => {
	const stateAdmin = selectRootSliceAdmin(getState());
	const customerChangesClone = selectSelectedCustomerChangesClone(stateAdmin);
	const customerClone = _.cloneDeep(customerChangesClone);
	customerClone.entities = customerClone.entities || [];
	const { entities } = customerClone;
	const idx = entities.findIndex(e => e.clientId === entity.clientId);
	if (idx > -1 && !!entities[idx].active) {
		if (!entities[idx].isNew) {
			entities[idx].active = false;
			dispatch(setSelectedCustomerChangesClone(customerClone));
		} else {
			customerClone.entities = customerClone.entities.filter(
				e => e.clientId !== entity.clientId
			);
			dispatch(setSelectedCustomerChangesClone(customerClone));
			dispatch(thunkSetSelectedEntity(undefined));
		}
	}
};

const thunkDoneEditingEntity = entity => (dispatch, getState) => {
	if (!entity.hasNameConflict) {
		const stateAdmin = selectRootSliceAdmin(getState());
		const customerChangesClone = selectSelectedCustomerChangesClone(stateAdmin);
		const customerClone = _.cloneDeep(customerChangesClone);
		customerClone.entities = customerClone.entities || [];
		const { entities } = customerClone;
		const idx = entities.findIndex(e => e.clientId === entity.clientId);
		if (idx > -1) {
			const newEntityIsEmpty =
				entity.isNew && (!entity.newName || entity.newName.trim() === 0);
			const existingEntityIsEmpty =
				!entity.isNew && (!entity.newName || entity.newName.trim() === 0);
			const existingEntityNoNameChange =
				!existingEntityIsEmpty &&
				!entity.isNew &&
				entity.newName &&
				entity.name.toUpperCase() === entity.newName.toUpperCase();
			if (newEntityIsEmpty) {
				dispatch(thunkDeleteEntity(entity));
			} else if (existingEntityIsEmpty || existingEntityNoNameChange) {
				entities[idx].newName = undefined;
				dispatch(setSelectedCustomerChangesClone(customerClone));
			} else if (
				entities[idx].newName.trim().length !== entities[idx].newName.length
			) {
				entities[idx].newName = entities[idx].newName.trim();
				dispatch(setSelectedCustomerChangesClone(customerClone));
			}
		}
		dispatch(thunkSetSelectedEntity(undefined));
	}
};

const thunkUndoEntityChanges = entity => (dispatch, getState) => {
	const stateAdmin = selectRootSliceAdmin(getState());
	const customer = selectSelectedCustomer(stateAdmin);
	const customerChangesClone = selectSelectedCustomerChangesClone(stateAdmin);
	const customerClone = _.cloneDeep(customerChangesClone);
	const idx = (customer.entities || []).findIndex(
		e => e.clientId === entity.clientId
	);
	const cloneIdx = (customerClone.entities || []).findIndex(
		e => e.clientId === entity.clientId
	);
	if (idx > -1 && cloneIdx > -1) {
		customerClone.entities[cloneIdx] = { ...customer.entities[idx] };
		dispatch(setSelectedCustomerChangesClone(customerClone));
		dispatch(thunkSetSelectedEntity(undefined));
	}
};

export {
	thunkSetSelectedEntity,
	thunkSetNewSelectedEntity,
	thunkDoneEditingEntity,
	thunkUpdateEntityName,
	thunkDeleteEntity,
	thunkUndoEntityChanges
};
