import { combineReducers } from 'redux';

import profileReducer from './Profiles/reducer';
import activeReducer from './Active/reducer';

export default exportType => {
	return combineReducers({
		active: activeReducer(exportType),
		profiles: profileReducer(exportType)
	});
};
