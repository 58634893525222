/* eslint-disable import/no-cycle */
/* eslint-disable camelcase */
import {
	clearItems,
	requestItems,
	receiveItems,
	setTotalCount,
	setLoadItemsErrorMessage,
	clearLoadItemsErrorMessage
} from './actions';
import { toFlatItems } from './transform';

// ************
// PARTS
// ************
const reducerParts = {
	[clearItems]: state => {
		const newItems = [];
		const newFlatItems = [];

		return {
			...state,
			items: newItems,
			flatItems: newFlatItems,
			totalCount: undefined,
			isLoadingItems: false,
			isLoadedItems: false,
			loadItemsErrorMessage: undefined
		};
	},
	[requestItems]: state => {
		return {
			...state,
			isLoadingItems: true,
			loadItemsErrorMessage: undefined
		};
	},
	[receiveItems]: (state, { payload: { items } }) => {
		const newItems = [...state.items, ...items];
		const newFlatItems = toFlatItems(newItems);

		return {
			...state,
			items: newItems,
			flatItems: newFlatItems,
			isLoadingItems: false,
			isLoadedItems: true
		};
	},
	[setTotalCount]: (state, { payload: { totalCount } }) => {
		return {
			...state,
			totalCount
		};
	},
	[setLoadItemsErrorMessage]: (state, { payload: { message } }) => {
		return {
			...state,
			isLoadingItems: false,
			loadItemsErrorMessage: message
		};
	},
	[clearLoadItemsErrorMessage]: state => {
		return {
			...state,
			loadItemsErrorMessage: undefined
		};
	}
};

export default reducerParts;
