/* eslint-disable import/no-cycle */
import { createActions } from 'redux-actions';
import { LOAD_ERROR_MESSAGE, BATCH_REQUEST_EVENT_TYPES } from './constants';
import { transformReports } from './transform';
import BatchRequestManager from './BatchRequestManager';

const {
	TOTAL_COUNT,
	LOADING_COUNT,
	LOADED_COUNT,
	COMPLETED
} = BATCH_REQUEST_EVENT_TYPES;

const {
	clearReports,
	requestReports,
	receiveReports,
	setTotalCount,
	setLoadingCount,
	setLoadedCount,
	setLoadReportsErrorMessage,
	clearLoadReportsErrorMessage,
	setTextSearch
} = createActions(
	{
		CLEAR_REPORTS: () => ({}),
		REQUEST_REPORTS: () => ({}),
		RECEIVE_REPORTS: reports => ({ reports }),
		SET_TOTAL_COUNT: count => ({ totalCount: count }),
		SET_LOADING_COUNT: count => ({ loadingCount: count }),
		SET_LOADED_COUNT: count => ({ loadedCount: count }),
		SET_LOAD_REPORTS_ERROR_MESSAGE: message => ({ message }),
		CLEAR_LOAD_REPORTS_ERROR_MESSAGE: () => ({}),
		SET_TEXT_SEARCH: search => ({ search })
	},
	{ prefix: 'ReportsListMain' }
);

const createOnBatchRequestManagerEvent = dispatch => (
	eventType,
	payload,
	data
) => {
	switch (eventType) {
		case TOTAL_COUNT:
			dispatch(setTotalCount(payload.count));
			if (payload.count === 0) {
				dispatch(clearLoadReportsErrorMessage());
				dispatch(receiveReports([]));
			}
			break;
		case LOADING_COUNT:
			dispatch(setLoadingCount(payload.count));
			break;
		case LOADED_COUNT:
			dispatch(setLoadedCount(payload.count));
			break;
		case COMPLETED:
			dispatch(clearLoadReportsErrorMessage());
			dispatch(receiveReports(transformReports(data)));
			break;
		default:
			console.error('Unknown event fired from BatchRequestManager');
	}
};

const createOnBatchRequestManagerError = dispatch => () => {
	dispatch(setLoadReportsErrorMessage(LOAD_ERROR_MESSAGE));
	dispatch(setTotalCount(0));
	dispatch(setLoadingCount(0));
	dispatch(setLoadedCount(0));
};

const loadReports = () => dispatch => {
	const onEvent = createOnBatchRequestManagerEvent(dispatch);
	const onError = createOnBatchRequestManagerError(dispatch);
	BatchRequestManager.reset({ onEvent, onError });
	dispatch(requestReports());
	BatchRequestManager.loadAllData();
};

export {
	loadReports,
	clearReports,
	requestReports,
	receiveReports,
	setTotalCount,
	setLoadingCount,
	setLoadedCount,
	setLoadReportsErrorMessage,
	clearLoadReportsErrorMessage,
	setTextSearch
};
