"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

var _config = require("./config");

var _UnknownUnitError = _interopRequireDefault(require("../ErrorClasses/UnknownUnitError"));

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    "default": obj
  };
}

var getAndValidateMultiplier = function getAndValidateMultiplier(from, to) {
  try {
    var multiplier = _config.TRANSFORM.FROM[from].TO[to];

    if (multiplier !== undefined) {
      return multiplier;
    }
  } catch (e) {// If there is an error, catch it and then throw a generic error below
  }

  throw new _UnknownUnitError["default"]("Cannot transform from \"".concat(from, "\" to \"").concat(to, "\""));
};

function transformDistanceToUnits(distance, from, to) {
  var multiplier = getAndValidateMultiplier(from, to);
  return distance * multiplier;
}

var _default = transformDistanceToUnits;
exports["default"] = _default;