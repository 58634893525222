import LocationType from 'aegion_common_utilities/lib/ReadingsUtil/LocationType';
import * as MapProcessingUtil from 'aegion_common_utilities/lib/MapProcessingUtil';

import JobUtil from '../../../utils/JobUtil/JobUtil';

import { _getNewDataFromChangedDatFileField } from './util/fileEdits';
import { save, onReadingsChanged } from './job.thunks.fileEdits';

import { setLastSaved } from '../editorToolbar';

import {
	setGlobalData,
	setAddGpsPoint,
	setMapEditInProcess,
	setIsUndoing
} from './job';

// TODO: I think this is bad
// This makes a copy, mutates the copy, and returns results
function _getNewGpsReadingsFromAddingGpsPoint(datFile, gpsReadingIndex) {
	const gpsreadingsShallowCopy = [...datFile.gpsreadings];
	const gpsReadingCopy = { ...gpsreadingsShallowCopy[gpsReadingIndex] };

	gpsReadingCopy.locationType = LocationType.GPS;
	gpsreadingsShallowCopy[gpsReadingIndex] = gpsReadingCopy;

	// remove elevation pdop for real gps that was earlier interpolated
	gpsreadingsShallowCopy[gpsReadingIndex].coordinates = [
		...gpsreadingsShallowCopy[gpsReadingIndex].coordinates.slice(0, 2)
	];

	// We may need to do this again for the previous item
	MapProcessingUtil.updateGPSMeasurements(gpsreadingsShallowCopy, {
		index: gpsReadingIndex,
		createCopy: true,
		isUpdateInterpolatedCoords: true
	});

	return gpsreadingsShallowCopy;
}

export const addGPSPoint = (datIndex, gpsReadingIndex, computedIndex) => (
	dispatch,
	getState
) => {
	const state = getState();
	const { cisview } = state;
	const { job } = cisview;
	const { data: originalData } = job;

	const datFile = originalData[datIndex];

	const gpsreadings = _getNewGpsReadingsFromAddingGpsPoint(
		datFile,
		gpsReadingIndex
	);

	const { data, globalData } = _getNewDataFromChangedDatFileField(
		datFile,
		getState,
		'gpsreadings',
		gpsreadings
	);

	onReadingsChanged(data, computedIndex)(dispatch, getState);
	dispatch(setAddGpsPoint(data, globalData));

	save({ data, globalData, setData: false })(dispatch, getState);
};

export const activateMoveTool = () => (dispatch, getState) => {
	const { cisview } = getState();
	const { job } = cisview;
	const { isMoveToolActivated, isSmoothToolAcitvated } = job;

	// Already set, nothing to do
	if (isMoveToolActivated) {
		return;
	}

	dispatch(
		setMapEditInProcess({
			isMoveToolActivated: true,
			isMapEditInProcess: true,
			isSmoothToolAcitvated
		})
	);
};

export const deactivateMoveTool = () => (dispatch, getState) => {
	const state = getState();
	const { cisview } = state;
	const { job } = cisview;
	const { isMoveToolActivated, isSmoothToolAcitvated } = job;

	// Already set, nothing to do
	if (!isMoveToolActivated) {
		return;
	}

	dispatch(
		setMapEditInProcess({
			isMoveToolActivated: false,
			isMapEditInProcess: isSmoothToolAcitvated,
			isSmoothToolAcitvated
		})
	);
};

export const activateSmoothTool = () => (dispatch, getState) => {
	const { cisview } = getState();
	const { job } = cisview;
	const { isSmoothToolActivated, isMoveToolAcitvated } = job;

	// Already set, nothing to do
	if (isSmoothToolActivated) {
		return;
	}

	dispatch(
		setMapEditInProcess({
			isSmoothToolActivated: true,
			isMapEditInProcess: true,
			isMoveToolAcitvated
		})
	);
};

export const deactivateSmoothTool = () => (dispatch, getState) => {
	const { cisview } = getState();
	const { job } = cisview;
	const { isSmoothToolActivated, isMoveToolAcitvated } = job;

	// Already set, nothing to do
	if (!isSmoothToolActivated) {
		return;
	}

	dispatch(
		setMapEditInProcess({
			isSmoothToolActivated: false,
			isMapEditInProcess: isMoveToolAcitvated,
			isMoveToolAcitvated
		})
	);
};

export const deactivateMapTools = () => dispatch => {
	dispatch(
		setMapEditInProcess({
			isSmoothToolActivated: false,
			isMapEditInProcess: false,
			isMoveToolAcitvated: false
		})
	);
};

export const undoMapTools = lastSaved => (dispatch, getState) => {
	dispatch(setIsUndoing(true));
	const { timeuuid, editedDats = [] } = lastSaved;
	const state = getState();
	const { cisview } = state;
	const { job } = cisview;
	const { globalData, data } = job;
	Promise.all(
		editedDats.map(d => {
			return JobUtil.undoMapEdit(timeuuid, d.datFile, d.fileKey).then(() => {
				// TODO: Is this correct? It appears as though this is saving to DynamoDB, which it should not - should only save to S3
				return JobUtil.getAndUpdateNewDatFileData({
					...globalData.MasterLST[d.datFile],
					gpsreadings: [...globalData.MasterLST[d.datFile].gpsreadings]
				});
			});
		})
	).then(updates => {
		let newGlobalData = globalData;
		let newData = data;
		updates.forEach(update => {
			const { datFile } = update;
			const dat = globalData.MasterLST[datFile];
			const res = _getNewDataFromChangedDatFileField(
				dat,
				getState,
				'gpsreadings',
				update.gpsreadings,
				{ globalData, data }
			);
			({ globalData: newGlobalData } = res);
			({ data: newData } = res);
		});
		dispatch(setGlobalData(newGlobalData, newData));
		dispatch(setLastSaved());
		dispatch(setIsUndoing(false));
	});
};
