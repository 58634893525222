const initialState = {
	fileMeta: {
		deleted: undefined,
		modified: undefined,
		added: undefined
	},
	fileContent: {
		deleted: undefined,
		modified: undefined,
		added: undefined
	},
	fileDocJson: {
		deleted: undefined,
		modified: undefined,
		added: undefined
	},
	fileErrors: {
		deleted: undefined,
		modified: undefined,
		added: undefined
	}
};

export default initialState;
