import {
	setGridKey,
	setApplicationsListKey,
	setDetailsKey,
	setIsSideNavExpanded,
	setSelectedApp
} from './actions';

const reducerParts = {
	[setGridKey]: (state, { payload: { key } }) => {
		return {
			...state,
			dev: {
				...state.dev,
				components: {
					...state.dev.components,
					gridKey: key
				}
			}
		};
	},

	[setApplicationsListKey]: (state, { payload: { key } }) => {
		return {
			...state,
			dev: {
				...state.dev,
				components: {
					...state.dev.components,
					applicationsListKey: key
				}
			}
		};
	},

	[setDetailsKey]: (state, { payload: { key } }) => {
		return {
			...state,
			dev: {
				...state.dev,
				components: {
					...state.dev.components,
					detailsKey: key
				}
			}
		};
	},

	[setIsSideNavExpanded]: (state, { payload: { isExpanded } }) => {
		return {
			...state,
			dev: {
				...state.dev,
				isSideNavExpanded: isExpanded
			}
		};
	},

	[setSelectedApp]: (state, { payload: { key } }) => {
		return {
			...state,
			selectedAppKey: key
		};
	}
};

export default reducerParts;
