"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.padNumStart = exports.padNumEnd = exports.isStringPurelyNumeric = exports.isNum = exports.isFraction = exports.formatVolt = exports.formatStrVolt = exports.formatStrStationId = exports.formatStrNumber = exports.formatStrMillivolt = exports.formatStrMiles = exports.formatStrGeoNum = exports.formatStationId = exports.formatPercent = exports.formatNumber = exports.formatMillivolt = exports.formatMiles = exports.formatGeoNum = exports["default"] = exports.TESTS = void 0;

function ownKeys(object, enumerableOnly) {
  var keys = Object.keys(object);

  if (Object.getOwnPropertySymbols) {
    var symbols = Object.getOwnPropertySymbols(object);
    enumerableOnly && (symbols = symbols.filter(function (sym) {
      return Object.getOwnPropertyDescriptor(object, sym).enumerable;
    })), keys.push.apply(keys, symbols);
  }

  return keys;
}

function _objectSpread(target) {
  for (var i = 1; i < arguments.length; i++) {
    var source = null != arguments[i] ? arguments[i] : {};
    i % 2 ? ownKeys(Object(source), !0).forEach(function (key) {
      _defineProperty(target, key, source[key]);
    }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) {
      Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key));
    });
  }

  return target;
}

function _defineProperty(obj, key, value) {
  if (key in obj) {
    Object.defineProperty(obj, key, {
      value: value,
      enumerable: true,
      configurable: true,
      writable: true
    });
  } else {
    obj[key] = value;
  }

  return obj;
}

function _toConsumableArray(arr) {
  return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _unsupportedIterableToArray(arr) || _nonIterableSpread();
}

function _nonIterableSpread() {
  throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method.");
}

function _iterableToArray(iter) {
  if (typeof Symbol !== "undefined" && iter[Symbol.iterator] != null || iter["@@iterator"] != null) return Array.from(iter);
}

function _arrayWithoutHoles(arr) {
  if (Array.isArray(arr)) return _arrayLikeToArray(arr);
}

function _slicedToArray(arr, i) {
  return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _unsupportedIterableToArray(arr, i) || _nonIterableRest();
}

function _nonIterableRest() {
  throw new TypeError("Invalid attempt to destructure non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method.");
}

function _unsupportedIterableToArray(o, minLen) {
  if (!o) return;
  if (typeof o === "string") return _arrayLikeToArray(o, minLen);
  var n = Object.prototype.toString.call(o).slice(8, -1);
  if (n === "Object" && o.constructor) n = o.constructor.name;
  if (n === "Map" || n === "Set") return Array.from(o);
  if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen);
}

function _arrayLikeToArray(arr, len) {
  if (len == null || len > arr.length) len = arr.length;

  for (var i = 0, arr2 = new Array(len); i < len; i++) {
    arr2[i] = arr[i];
  }

  return arr2;
}

function _iterableToArrayLimit(arr, i) {
  var _i = arr == null ? null : typeof Symbol !== "undefined" && arr[Symbol.iterator] || arr["@@iterator"];

  if (_i == null) return;
  var _arr = [];
  var _n = true;
  var _d = false;

  var _s, _e;

  try {
    for (_i = _i.call(arr); !(_n = (_s = _i.next()).done); _n = true) {
      _arr.push(_s.value);

      if (i && _arr.length === i) break;
    }
  } catch (err) {
    _d = true;
    _e = err;
  } finally {
    try {
      if (!_n && _i["return"] != null) _i["return"]();
    } finally {
      if (_d) throw _e;
    }
  }

  return _arr;
}

function _arrayWithHoles(arr) {
  if (Array.isArray(arr)) return arr;
}

var DEFAULT_SCALE = {
  GEONUM: 6,
  MILES: 4,
  MILLIVOLT: 2,
  NUMBER: 2,
  VOLT: 5,
  STATIONID: 2,
  PERCENT: 2
}; // expects boolean

var DEFAULT_PAD_SCALE = {
  GEONUM: undefined,
  MILES: undefined,
  MILLIVOLT: undefined,
  NUMBER: undefined,
  VOLT: undefined,
  STATIONID: undefined
}; // expects numeric (pad count)

var DEFAULT_PAD_START = {
  GEONUM: undefined,
  MILES: undefined,
  MILLIVOLT: undefined,
  NUMBER: undefined,
  VOLT: undefined,
  STATIONID: 3
}; // expects numeric (pad count)

var DEFAULT_PAD_END = {
  GEONUM: undefined,
  MILES: undefined,
  MILLIVOLT: undefined,
  NUMBER: undefined,
  VOLT: undefined,
  STATIONID: undefined
};

var isDefined = function isDefined(val) {
  return val !== null && val !== undefined;
};

var stripSpecialChars = function stripSpecialChars(val) {
  if (isDefined(val) && val.toString) {
    return val.toString().replace(/,|\+/gi, '');
  }

  return val;
};

var isEmptyString = function isEmptyString(val) {
  return typeof val === 'string' && val.trim() === '';
};

var isNum = function isNum(val) {
  return !Number.isNaN(parseFloat(val, 10));
};

exports.isNum = isNum;

var isStringPurelyNumeric = function isStringPurelyNumeric(v) {
  // eslint-disable-next-line no-useless-escape
  var reOnlyValidChars = /^[\-\,\.\d]+$/i;
  var reHasNum = /\d/i;

  if (typeof v === 'string') {
    return reOnlyValidChars.test(v) && reHasNum.test(v);
  }

  return typeof v === 'number';
};

exports.isStringPurelyNumeric = isStringPurelyNumeric;

var isFraction = function isFraction(v) {
  var isPossibleFraction = typeof v === 'string' && v.indexOf('/') > -1;

  if (!isPossibleFraction) {
    return false;
  }

  var parts = v.split('/');

  if (parts.length !== 2) {
    return false;
  }

  return isNum(parts[0]) && isNum(parts[1]);
};

exports.isFraction = isFraction;

var padNumStart = function padNumStart(val, cnt) {
  var _ref = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : {},
      _ref$char = _ref["char"],
      _char = _ref$char === void 0 ? 0 : _ref$char;

  if (!isDefined(val) || !val.toString || !isNum(val) || !isNum(cnt) || !isDefined(_char)) {
    return val;
  }

  var _stripSpecialChars$sp = stripSpecialChars(val).split('.'),
      _stripSpecialChars$sp2 = _slicedToArray(_stripSpecialChars$sp, 2),
      num = _stripSpecialChars$sp2[0],
      dec = _stripSpecialChars$sp2[1];

  var isNeg = Number.parseFloat(num) < 0;
  var strNum = Math.abs(Number.parseFloat(num)).toString();
  var result = strNum.padStart(cnt, _char);

  if (isDefined(dec)) {
    result = "".concat(result, ".").concat(dec);
  }

  if (isNeg) {
    result = "-".concat(result);
  }

  return result;
};

exports.padNumStart = padNumStart;

var padNumEnd = function padNumEnd(val, cnt) {
  var _ref2 = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : {},
      _ref2$char = _ref2["char"],
      _char2 = _ref2$char === void 0 ? 0 : _ref2$char;

  if (!isDefined(val) || !val.toString || !isNum(val) || !isNum(cnt) || !isDefined(_char2)) {
    return val;
  }

  var _stripSpecialChars$sp3 = stripSpecialChars(val).split('.'),
      _stripSpecialChars$sp4 = _slicedToArray(_stripSpecialChars$sp3, 2),
      _stripSpecialChars$sp5 = _stripSpecialChars$sp4[0],
      num = _stripSpecialChars$sp5 === void 0 ? 0 : _stripSpecialChars$sp5,
      _stripSpecialChars$sp6 = _stripSpecialChars$sp4[1],
      dec = _stripSpecialChars$sp6 === void 0 ? _char2 : _stripSpecialChars$sp6;

  var strNum = isEmptyString(num) ? '0' : num.toString();
  return "".concat(strNum, ".").concat(dec.toString().padEnd(cnt, _char2));
};

exports.padNumEnd = padNumEnd;

var formatNumber = function formatNumber(val) {
  var _ref3 = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {},
      _ref3$scale = _ref3.scale,
      scale = _ref3$scale === void 0 ? DEFAULT_SCALE.NUMBER : _ref3$scale;

  if (isFraction(val)) {
    var _val$split = val.split('/'),
        _val$split2 = _slicedToArray(_val$split, 2),
        numerator = _val$split2[0],
        denominator = _val$split2[1];

    var n = formatNumber(numerator, {
      scale: 12
    });
    var d = formatNumber(denominator, {
      scale: 12
    }); // eslint-disable-next-line no-param-reassign

    val = !d ? 0 : n / d;
  }

  if (isNum(val)) {
    return Number.parseFloat(Number.parseFloat(stripSpecialChars(val)).toFixed(scale));
  }

  return val;
}; // @todo - create automated tests for formatPercent
// @todo - create implementation and tests for formatStrPercent


exports.formatNumber = formatNumber;

var formatPercent = function formatPercent(val) {
  var _ref4 = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {},
      _ref4$scale = _ref4.scale,
      scale = _ref4$scale === void 0 ? DEFAULT_SCALE.PERCENT : _ref4$scale;

  if (isFraction(val)) {
    return formatNumber(val, {
      scale: scale + 2
    }) * 100;
  }

  return formatNumber(val, {
    scale: scale
  });
};

exports.formatPercent = formatPercent;

var withFraction = function withFraction(fn) {
  return function () {
    for (var _len = arguments.length, args = new Array(_len), _key = 0; _key < _len; _key++) {
      args[_key] = arguments[_key];
    }

    var v = args[0],
        params = args.slice(1);

    if (isFraction(v)) {
      var _v$split = v.split('/'),
          _v$split2 = _slicedToArray(_v$split, 2),
          numerator = _v$split2[0],
          denominator = _v$split2[1];

      var n = fn.apply(void 0, [numerator].concat(_toConsumableArray(params)));
      var d = fn.apply(void 0, [denominator].concat(_toConsumableArray(params)));
      return "".concat(n, "/").concat(d);
    }

    return fn.apply(void 0, [v].concat(_toConsumableArray(params)));
  };
};

var formatStrNumber = function formatStrNumber(val) {
  var _ref5 = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {},
      scale = _ref5.scale,
      _ref5$padScale = _ref5.padScale,
      padScale = _ref5$padScale === void 0 ? false : _ref5$padScale,
      padStart = _ref5.padStart,
      padEnd = _ref5.padEnd;

  var strVal = isDefined(val) ? val.toString().trim() : '';
  var strParts = strVal.split(' ').filter(function (v) {
    return isDefined(v);
  }).map(function (v) {
    return (// eslint-disable-next-line no-shadow
      withFraction(function (v, _ref6) {
        var scale = _ref6.scale;
        return formatNumber(v, {
          scale: scale
        });
      })(v, {
        scale: scale
      })
    );
  });

  if (padScale && scale) {
    strParts = strParts.map(function (v) {
      return (// eslint-disable-next-line no-shadow
        withFraction(function (v, scale) {
          return isNum(v) ? padNumEnd(v, scale) : v;
        })(v, scale)
      );
    });
  } else if (isDefined(padEnd)) {
    strParts = strParts.map(function (v) {
      return (// eslint-disable-next-line no-shadow
        withFraction(function (v, padEnd) {
          return isNum(v) ? padNumEnd(v, padEnd) : v;
        })(v, padEnd)
      );
    });
  }

  if (isDefined(padStart)) {
    strParts = strParts.map(function (v) {
      return (// eslint-disable-next-line no-shadow
        withFraction(function (v, padStart) {
          return isNum(v) ? padNumStart(v, padStart) : v;
        })(v, padStart)
      );
    });
  }

  return strParts.join(' ');
};

exports.formatStrNumber = formatStrNumber;

var formatGeoNum = function formatGeoNum(val) {
  var _ref7 = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {},
      _ref7$scale = _ref7.scale,
      scale = _ref7$scale === void 0 ? DEFAULT_SCALE.GEONUM : _ref7$scale;

  return formatNumber(val, {
    scale: scale
  });
};

exports.formatGeoNum = formatGeoNum;

var formatStrGeoNum = function formatStrGeoNum(val) {
  var _ref8 = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {},
      _ref8$scale = _ref8.scale,
      scale = _ref8$scale === void 0 ? DEFAULT_SCALE.GEONUM : _ref8$scale,
      _ref8$padScale = _ref8.padScale,
      padScale = _ref8$padScale === void 0 ? DEFAULT_PAD_SCALE.GEONUM : _ref8$padScale,
      _ref8$padStart = _ref8.padStart,
      padStart = _ref8$padStart === void 0 ? DEFAULT_PAD_START.GEONUM : _ref8$padStart,
      _ref8$padEnd = _ref8.padEnd,
      padEnd = _ref8$padEnd === void 0 ? DEFAULT_PAD_END.GEONUM : _ref8$padEnd;

  return formatStrNumber(val, {
    scale: scale,
    padScale: padScale,
    padStart: padStart,
    padEnd: padEnd
  });
};

exports.formatStrGeoNum = formatStrGeoNum;

var formatMillivolt = function formatMillivolt(val) {
  var _ref9 = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {},
      _ref9$scale = _ref9.scale,
      scale = _ref9$scale === void 0 ? DEFAULT_SCALE.MILLIVOLT : _ref9$scale;

  return formatNumber(val, {
    scale: scale
  });
};

exports.formatMillivolt = formatMillivolt;

var formatStrMillivolt = function formatStrMillivolt(val) {
  var _ref10 = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {},
      _ref10$scale = _ref10.scale,
      scale = _ref10$scale === void 0 ? DEFAULT_SCALE.MILLIVOLT : _ref10$scale,
      _ref10$padScale = _ref10.padScale,
      padScale = _ref10$padScale === void 0 ? DEFAULT_PAD_SCALE.MILLIVOLT : _ref10$padScale,
      _ref10$padStart = _ref10.padStart,
      padStart = _ref10$padStart === void 0 ? DEFAULT_PAD_START.MILLIVOLT : _ref10$padStart,
      _ref10$padEnd = _ref10.padEnd,
      padEnd = _ref10$padEnd === void 0 ? DEFAULT_PAD_END.MILLIVOLT : _ref10$padEnd;

  return formatStrNumber(val, {
    scale: scale,
    padScale: padScale,
    padStart: padStart,
    padEnd: padEnd
  });
};

exports.formatStrMillivolt = formatStrMillivolt;

var formatVolt = function formatVolt(val) {
  var _ref11 = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {},
      _ref11$scale = _ref11.scale,
      scale = _ref11$scale === void 0 ? DEFAULT_SCALE.VOLT : _ref11$scale;

  return formatNumber(val, {
    scale: scale
  });
};

exports.formatVolt = formatVolt;

var formatStrVolt = function formatStrVolt(val) {
  var _ref12 = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {},
      _ref12$scale = _ref12.scale,
      scale = _ref12$scale === void 0 ? DEFAULT_SCALE.VOLT : _ref12$scale,
      _ref12$padScale = _ref12.padScale,
      padScale = _ref12$padScale === void 0 ? DEFAULT_PAD_SCALE.VOLT : _ref12$padScale,
      _ref12$padStart = _ref12.padStart,
      padStart = _ref12$padStart === void 0 ? DEFAULT_PAD_START.VOLT : _ref12$padStart,
      _ref12$padEnd = _ref12.padEnd,
      padEnd = _ref12$padEnd === void 0 ? DEFAULT_PAD_END.VOLT : _ref12$padEnd;

  return formatStrNumber(val, {
    scale: scale,
    padScale: padScale,
    padStart: padStart,
    padEnd: padEnd
  });
};

exports.formatStrVolt = formatStrVolt;

var formatMiles = function formatMiles(val) {
  var _ref13 = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {},
      _ref13$scale = _ref13.scale,
      scale = _ref13$scale === void 0 ? DEFAULT_SCALE.MILES : _ref13$scale;

  return formatNumber(val, {
    scale: scale
  });
};

exports.formatMiles = formatMiles;

var formatStrMiles = function formatStrMiles(val) {
  var _ref14 = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {},
      _ref14$scale = _ref14.scale,
      scale = _ref14$scale === void 0 ? DEFAULT_SCALE.MILES : _ref14$scale,
      _ref14$padScale = _ref14.padScale,
      padScale = _ref14$padScale === void 0 ? DEFAULT_PAD_SCALE.MILES : _ref14$padScale,
      _ref14$padStart = _ref14.padStart,
      padStart = _ref14$padStart === void 0 ? DEFAULT_PAD_START.MILES : _ref14$padStart,
      _ref14$padEnd = _ref14.padEnd,
      padEnd = _ref14$padEnd === void 0 ? DEFAULT_PAD_END.MILES : _ref14$padEnd;

  return formatStrNumber(val, {
    scale: scale,
    padScale: padScale,
    padStart: padStart,
    padEnd: padEnd
  });
};

exports.formatStrMiles = formatStrMiles;

var formatStationId = function formatStationId(val) {
  var _ref15 = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {},
      _ref15$scale = _ref15.scale,
      scale = _ref15$scale === void 0 ? DEFAULT_SCALE.STATIONID : _ref15$scale;

  return formatNumber(val, {
    scale: scale
  });
};

exports.formatStationId = formatStationId;

var formatStrStationId = function formatStrStationId(val) {
  var _ref16 = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {},
      _ref16$scale = _ref16.scale,
      scale = _ref16$scale === void 0 ? DEFAULT_SCALE.STATIONID : _ref16$scale,
      _ref16$padScale = _ref16.padScale,
      padScale = _ref16$padScale === void 0 ? DEFAULT_PAD_SCALE.STATIONID : _ref16$padScale,
      _ref16$padStart = _ref16.padStart,
      paramPadStart = _ref16$padStart === void 0 ? DEFAULT_PAD_START.STATIONID : _ref16$padStart,
      _ref16$padEnd = _ref16.padEnd,
      padEnd = _ref16$padEnd === void 0 ? DEFAULT_PAD_END.STATIONID : _ref16$padEnd;

  var padStart = Math.max(DEFAULT_PAD_START.STATIONID, paramPadStart);
  var baseResult = formatStrNumber(val, {
    scale: scale,
    padScale: padScale,
    padStart: padStart,
    padEnd: padEnd
  });
  var strParts = baseResult.split(' ').filter(function (v) {
    return !!v;
  }).map(function (v) {
    if (!isNum(v)) {
      return v;
    }

    var _v$toString$split = v.toString().split('.'),
        _v$toString$split2 = _slicedToArray(_v$toString$split, 2),
        num = _v$toString$split2[0],
        dec = _v$toString$split2[1];

    var strNum = num.toString(); // format number

    var numPrePlus = strNum.substr(0, strNum.length - 2);
    var numPostPlus = strNum.substr(strNum.length - 2, strNum.length);
    var formattedNum = "".concat(numPrePlus, "+").concat(numPostPlus); // format decimal

    if (dec) {
      return "".concat(formattedNum, ".").concat(dec);
    }

    return formattedNum;
  });
  return strParts.join(' ');
};

exports.formatStrStationId = formatStrStationId;
var defaultExport = {
  isNum: isNum,
  isFraction: isFraction,
  isStringPurelyNumeric: isStringPurelyNumeric,
  formatStrNumber: formatStrNumber,
  formatNumber: formatNumber,
  formatPercent: formatPercent,
  formatGeoNum: formatGeoNum,
  formatStrGeoNum: formatStrGeoNum,
  formatVolt: formatVolt,
  formatStrVolt: formatStrVolt,
  formatMillivolt: formatMillivolt,
  formatStrMillivolt: formatStrMillivolt,
  formatMiles: formatMiles,
  formatStrMiles: formatStrMiles,
  formatStationId: formatStationId,
  formatStrStationId: formatStrStationId,
  padNumStart: padNumStart,
  padNumEnd: padNumEnd
};

var TESTS = _objectSpread(_objectSpread({}, defaultExport), {}, {
  DEFAULT_SCALE: DEFAULT_SCALE
});

exports.TESTS = TESTS;
var _default = defaultExport;
exports["default"] = _default;