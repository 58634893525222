export const getLast = arr => {
	if (arr && arr.length > 0) {
		return arr[arr.length - 1];
	}

	return undefined;
};

export const cloneAndAlignTo = (target, source, flagKey) => {
	const to = { ...target };
	if (source.id === to.id) {
		return to;
	}

	to.id = source.id;
	// TODO: formattedStationId is a scaline thing - we need this to work for any id
	to.formattedStationId = source.formattedStationId;

	if (flagKey) {
		to[flagKey] = true;
	}
	return to;
};

// probably an edge case, but sometimes a survey may contain readings
// with same station ids so we need to remove those
export const removeDuplicateIds = readings => {
	const uniqueIds = {};
	const filteredSurvey = readings.filter(({ id }) => {
		const isUnique = !uniqueIds[id];
		uniqueIds[id] = true;
		return isUnique;
	});
	return filteredSurvey;
};

// Updates cisReading to include depol id - will first try to use the passed in "map"
// TODO: I don't think this ever actually works unless by some chance the cis survey ids matched with the depol ids
export const copyOriginalDepolValue = (
	depolReading,
	subtype,
	shouldCopyOriginalDepol,
	cisReadingsMap,
	targetValueKey
) => {
	if (subtype !== 'DEPOL') {
		return;
	}
	if (!shouldCopyOriginalDepol) {
		return;
	}
	const cisReading = cisReadingsMap[depolReading.id];
	if (!cisReading) {
		return;
	}

	cisReading.depol = depolReading[targetValueKey];
};

// Updates cisReading to include depol reading value and sets
export const copyDepolValue = (
	cisReading,
	depolReading,
	subtype,
	depolKey,
	targetValueKey,
	depolFlagKey
) => {
	if (subtype !== 'DEPOL') {
		return;
	}

	// eslint-disable-next-line no-param-reassign
	cisReading[depolKey] = depolReading[targetValueKey];
	if (!depolFlagKey) {
		return;
	}

	// eslint-disable-next-line no-param-reassign
	cisReading[depolFlagKey] = true;
};
