/* eslint-disable import/prefer-default-export */
/* eslint-disable import/no-cycle */
import { createActions } from 'redux-actions';
import { ROOT_SLICE } from '../constants';

const { setSelectedEntity, setSelectedEntityChangesClone } = createActions(
	{
		SET_SELECTED_ENTITY: selectedEntity => ({ selectedEntity }),
		SET_SELECTED_ENTITY_CHANGES_CLONE: selectedEntityChangesClone => ({
			selectedEntityChangesClone
		})
	},
	{ prefix: ROOT_SLICE }
);

export { setSelectedEntity, setSelectedEntityChangesClone };
