import Sockette from 'sockette';

function send(message) {
	this.sockette.send(message);
}

function close() {
	this.sockette.close();
}

function reconnect() {
	const promise = new Promise(resolve => {
		this.onConnectionIdChanged = () => {
			resolve();
			delete this.onConnectionIdChanged;
		};
		this.sockette.reconnect();
	});
	return promise;
}

function onMessage(e) {
	if (e.data.search('key') === -1) {
		this.connectionId = e.data;
		if (this.onConnectionIdChanged) {
			this.onConnectionIdChanged(e.data);
		}
	}
}

function isOpen() {
	return this.websocket.readyState === this.websocket.OPEN;
}

function SocketteWrapper(
	url,
	{ onopen, onmessage, onreconnect, onmaximum, onclose, onerror, ...options }
) {
	const sockette = new Sockette(url, {
		onopen: e => {
			this.websocket = e.target;
			if (onopen) {
				onopen(e);
			}
		},
		onmessage: e => {
			this.onMessage(e);
			if (onmessage) {
				onmessage(e);
			}
		},
		onreconnect: e => {
			if (onreconnect) {
				onreconnect(e);
			}
		},
		onmaximum: e => {
			if (onmaximum) {
				onmaximum(e);
			}
		},
		onclose: e => {
			if (onclose) {
				onclose(e);
			}
		},
		onerror: e => {
			if (onerror) {
				onerror(e);
			}
		},
		...options
	});
	this.sockette = sockette;
	this.send = send;
	this.close = close;
	this.onMessage = onMessage;
	this.reconnect = reconnect;
	this.isOpen = isOpen;
	// return { sockette, send, close };
}

export default SocketteWrapper;
