import isDefined from '../isDefined';

export const isWsErrorMessage = (wsMessage = {}) => {
	return isDefined(wsMessage.error);
};

export const selectWsMessage = (wsEvent = {}) => {
	let data = isDefined(wsEvent.data) ? wsEvent.data : undefined;
	try {
		data = JSON.parse(data);
	} catch (err) {
		// do nothing as undefined or a string value which is not json was passed.
	}

	return data;
};

export default {
	selectWsMessage,
	isWsErrorMessage
};
