import { fetch } from 'whatwg-fetch';

import getAipUrls from '../../config/getAipUrls';
// eslint-disable-next-line import/no-cycle
import AccountUtil from '../../account/components/utils/AccountUtil';

const ajax = passedOptions => {
	const options = { ...passedOptions };
	return new Promise((resolve, reject) => {
		if (options.data) {
			options.body = options.data;
			delete options.data;
		}
		const aipurls = getAipUrls();
		fetch(
			(aipurls[options.aip] ? aipurls[options.aip] : '') + options.url,
			options
		)
			.then(res => {
				if (res.ok) {
					if (res.status === 204) {
						resolve(null);
					} else {
						res
							.json()
							.then(data => {
								resolve(data);
							})
							.catch(err => {
								reject(err);
							});
					}
				} else {
					return res
						.json()
						.then(data => {
							res.responseJSON = data;
							if (res.status === 401 && data.message === 'Unauthorized') {
								AccountUtil.refresh((err, res2) => {
									if (err) {
										reject(res);
									} else {
										options.headers.Authorization =
											res2.AuthenticationResult.AccessToken;
										ajax(options)
											.then(res3 => {
												resolve(res3);
											})
											.catch(() => {
												reject(res);
											});
									}
								});
								// console.log('getnew token')
							} else {
								reject(res);
							}
						})
						.catch(e => reject(e));
				}
			})
			.catch(err => {
				reject(err);
			});
	});
};

export default ajax;
