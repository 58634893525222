const SET_OPERATIONS = {
	// Get all members of a that are not in b (A−B)
	difference(a, b) {
		const namesToExpclude = {};
		b.forEach(fieldName => {
			namesToExpclude[fieldName] = true;
		});

		return a.filter(fieldName => !namesToExpclude[fieldName]);
	}
	// TODO: Intersection (A ∩ B), union (A ∪ B), complement (A¯)
};

export default SET_OPERATIONS;
