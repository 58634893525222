import { SLICE_ROOT_KEY } from './constants';

export const selectSliceState = appState => appState[SLICE_ROOT_KEY];

// IS COMPUTING DIFF
export const selectIsComputingDiffs = appState => {
	const { isComputingDiffs } = selectSliceState(appState);
	return !!isComputingDiffs;
};

// ERRORS
export const selectErrors = appState => {
	const { errors } = selectSliceState(appState);
	return errors;
};

export const selectHasErrors = appState => {
	const errors = selectErrors(appState);
	return !!errors && errors.length > 0;
};

// PROCESSING MESSAGES
export const selectProcessingMessage = appState => {
	const { processingMessage } = selectSliceState(appState);
	return processingMessage;
};

export const selectHasProcessingMessage = (appState, key) => {
	const processingMessage = selectProcessingMessage(appState, key);
	return !!processingMessage && processingMessage.length > 0;
};
