import { loadUsers, reset } from '../../redux/slices/Users/actions';
import { loadCompanies } from '../../redux/slices/Companies/actions';
import { loadPermissionDefinitions } from '../../redux/slices/PermissionDefinitions/actions';

export const loadAppData = () => dispatch => {
	dispatch(loadUsers());
	dispatch(loadCompanies());
	dispatch(loadPermissionDefinitions());
};

export const reloadUsers = () => dispatch => {
	dispatch(reset());
	dispatch(loadUsers());
};

export default { loadAppData, reloadUsers };
