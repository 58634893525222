import {
	config,
	calculateDestination
} from 'aegion_common_utilities/lib/MapProcessingUtil';

/* eslint-disable import/prefer-default-export */
const getCountOfReadingsMoved = (
	stationIdRange,
	interval,
	previousCount = 0
) => {
	const [station1, station2] = stationIdRange;
	const stationIdDiff = Math.abs(station2 - station1);

	const newCount = stationIdDiff / interval;

	return previousCount + newCount;
};

export const getInterpolatedPoint = (firstPoint, distance, bearing) => {
	// Will return [x, y]
	return calculateDestination(firstPoint, distance, bearing, {
		from: config.UNITS.DISTANCE.KILOMETERS,
		reverseResponse: false
	});
};

export const getSmoothedPointsInfo = existingSmoothPoints => {
	const statsPerDatFile = {};
	const datFilesByFileName = {};

	existingSmoothPoints.forEach(smoothedPointsGroup => {
		const rangeOfStationIdsSmoothed = [];
		if (smoothedPointsGroup.length < 2) {
			// eslint-disable-next-line no-console
			console.error('Found a group without enough points to make a range');
			return;
		}

		let fileName;
		let datFile;
		let smallestStation = Number.POSITIVE_INFINITY;
		let largestStation = Number.NEGATIVE_INFINITY;
		smoothedPointsGroup.forEach(smoothedPoint => {
			const { dat, stationNum } = smoothedPoint.point;
			fileName = dat.fileName;
			datFile = dat;

			if (!datFilesByFileName[fileName]) {
				datFilesByFileName[fileName] = dat;
			}

			if (stationNum < smallestStation) {
				smallestStation = stationNum;
			}
			if (stationNum > largestStation) {
				largestStation = stationNum;
			}

			rangeOfStationIdsSmoothed.push(stationNum);
		});
		if (!statsPerDatFile[fileName]) {
			statsPerDatFile[fileName] = {
				idRanges: [],
				numberOfReadings: 0
			};
		}

		const stats = statsPerDatFile[fileName];
		stats.idRanges = [...stats.idRanges, rangeOfStationIdsSmoothed];

		const previousCount = stats.numberOfReadings;

		stats.numberOfReadings = getCountOfReadingsMoved(
			[smallestStation, largestStation],
			datFile.interval,
			previousCount
		);
	});

	const datFiles = Object.values(datFilesByFileName);
	const statsPerFile = Object.keys(statsPerDatFile).map(key => ({
		fileName: key,
		...statsPerDatFile[key]
	}));

	const allMovedCount = statsPerFile.reduce(
		(count, stat) => count + stat.numberOfReadings,
		0
	);

	const stats = {
		statsPerFile,
		allMovedCount
	};

	return {
		datFiles,
		stats
	};
};
