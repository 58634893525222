export const initialState = {
  list: [],
  listCount: 0,
  selected: [],
  selectedCount: 0,
  isLoading: false,
  isLoaded: false,
  loadErrorMessage: undefined
};

export default { initialState };
