import {
	setDataWrappers,
	setDataWrapper,
	deleteDataWrapper,
	deleteAllDataWrappers,
	clearStore
} from './actions';

import { thunkDecimateCisSurvey } from './thunks.cisDecimate';

export {
	// core
	setDataWrappers,
	setDataWrapper,
	deleteDataWrapper,
	deleteAllDataWrappers,
	clearStore,
	// DECIMATE
	thunkDecimateCisSurvey
};

export default {
	// core
	setDataWrappers,
	setDataWrapper,
	deleteDataWrapper,
	deleteAllDataWrappers,
	clearStore,

	// DECIMATE
	thunkDecimateCisSurvey
};
