/* eslint-disable import/no-cycle */
import { createActions } from 'redux-actions';
import { getCustomer } from '../../util/user';
import fieldlineUtils from '../scanlineUtils';

const {
	clearProjectManagers,
	receiveProjectManagers,
	requestProjectManagers
} = createActions({
	CLEAR_PROJECT_MANAGERS: () => ({}),
	RECEIVE_PROJECT_MANAGERS: projectManagers => {
		return { projectManagers };
	},
	REQUEST_PROJECT_MANAGERS: () => ({})
});

const doFetchProjectManagers = (dispatch, getState) => {
	const { app: { defaultSurvey } = {} } = getState();
	const { customer = getCustomer() } = defaultSurvey || {};
	dispatch(clearProjectManagers());
	dispatch(requestProjectManagers());

	return fieldlineUtils.getProjectManagers(customer, (err, res) => {
		if (err) {
			// console.error(err);
		} else {
			const { projectManagers = [] } = res;
			dispatch(receiveProjectManagers(projectManagers));
		}
	});
};

export {
	clearProjectManagers,
	receiveProjectManagers,
	requestProjectManagers,
	doFetchProjectManagers
};
