/* eslint-disable import/no-cycle */
import {
	parseQueryString,
	createNewQueryString,
	clearFromQueryString
} from '../../../../scanline/utils/location';

import { browserHistory as history } from '../../../../scanline/store';

const DYNAMIC_FILTERS_URL_PARAM_KEY = 'dfilters';

export const capitalize = str => {
	return str && str[0].toUpperCase() + str.slice(1);
};

const getStrSearch = () => ((window || {}).location || {}).search || undefined;

export const createGetStateFromUrl = (
	namespace,
	rootSliceKey,
	filtersStatePropKey,
	filterStatePropKey
) => () => {
	try {
		const strSearch = getStrSearch();
		const urlParams = parseQueryString(strSearch) || {};
		const serializedDFilters = urlParams[DYNAMIC_FILTERS_URL_PARAM_KEY] || '';
		const dfilters = JSON.parse(serializedDFilters || '{}');

		return (
			((((dfilters || {})[namespace] || {})[rootSliceKey] || {})[
				filtersStatePropKey
			] || {})[filterStatePropKey] || undefined
		);
	} catch {
		console.error(
			`incorrectly formatted ${DYNAMIC_FILTERS_URL_PARAM_KEY} url param.  json parse failed`
		);

		return undefined;
	}
};

export const createPushStateToUrl = (
	namespace,
	rootSliceKey,
	filtersStatePropKey,
	filterStatePropKey
) => ({ selected = [], selectedCount = 0 }) => {
	let strSearch = getStrSearch();
	const urlParams = parseQueryString(strSearch) || {};
	strSearch = clearFromQueryString(DYNAMIC_FILTERS_URL_PARAM_KEY, strSearch); // clear the key so we make sure to push dfilters to end of query string
	const serializedDFilters = urlParams[DYNAMIC_FILTERS_URL_PARAM_KEY] || '';
	const dfilters = JSON.parse(serializedDFilters || '{}');

	const stateNamespace = (dfilters || {})[namespace] || {};
	const stateRootSliceKey = stateNamespace[rootSliceKey] || {};
	const stateFiltersStatePropKey = stateRootSliceKey[filtersStatePropKey] || {};
	const stateFilterStatePropKey =
		stateFiltersStatePropKey[filterStatePropKey] || {};

	const newState = {
		...dfilters,
		[namespace]: {
			...stateNamespace,
			[rootSliceKey]: {
				...stateRootSliceKey,
				[filtersStatePropKey]: {
					...stateFiltersStatePropKey,
					[filterStatePropKey]: {
						...stateFilterStatePropKey,
						selected,
						selectedCount
					}
				}
			}
		}
	};

	const newStrSearch = createNewQueryString(
		[DYNAMIC_FILTERS_URL_PARAM_KEY, JSON.stringify(newState)],
		strSearch
	);

	history.push(newStrSearch);
};
