// eslint-disable-next-line import/prefer-default-export
export function isValidReadingValue(
	reading,
	readingFieldName,
	surveyType,
	isGPSSync = false
) {
	const value = reading[readingFieldName];
	if (value === undefined) {
		return false;
	}
	if (value === null) {
		return false;
	}
	if (value === '') {
		return false;
	}
	if (surveyType === 'ON-OFF' && value === 0 && !isGPSSync) {
		return false;
	}
	return true;
	/* return (
		(surveyType !== 'ON-OFF' && reading[readingFieldName] !== undefined) ||
		reading[readingFieldName]
	); */
}
