import { getStringWithoutUrlPostscript } from './JobUtil.misc';

export const DAT_MAP_URL_PROPERTIES = [];

export const DAT_NON_MAP_URL_PROPERTIES = [
	'gpsreadingsurl',
	'preprocessedGPSReadingsurl'
];

const ALL_DAT_URL_PROPERTIES = DAT_MAP_URL_PROPERTIES.concat(
	DAT_NON_MAP_URL_PROPERTIES
);

export const FILE_INFO_KEY_BY_URL = {
	gpsreadingsurl: 'gpsReadingsMeta',
	preprocessedGPSReadingsurl: 'preprocessedGPSReadingsMeta'
};

export const ALL_DAT_PROPERTIES_FROM_URL_PROPERTIES = ALL_DAT_URL_PROPERTIES.map(
	getStringWithoutUrlPostscript
);
