"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.DOWNLOADING_TEXT = exports.APPROVAL_MODE = void 0; // eslint-disable-next-line import/prefer-default-export

var APPROVAL_MODE = {
  unapproved: 'u',
  approved: 'a',
  mixed: 'm'
};
exports.APPROVAL_MODE = APPROVAL_MODE;
var DOWNLOADING_TEXT = 'Downloading...';
exports.DOWNLOADING_TEXT = DOWNLOADING_TEXT;