export const IntakeAction = {
	UPLOAD: 'Uploads',
	EDIT_FILE: 'Edit',
	ISSUE_GUIDE: 'ISSUE_GUIDE'
};

export function getColumnSize(template) {
	let columnSize = '';
	if (template === 'scanline') {
		columnSize = 6;
	} else {
		columnSize = 8;
	}
	return columnSize;
}

export function getPreviousAction(action) {
	switch (action) {
		case IntakeAction.EDIT_FILE:
			return IntakeAction.UPLOAD;
		case IntakeAction.ISSUE_GUIDE:
			return IntakeAction.EDIT_FILE;
		default:
			throw new Error(`Cannot go back from ${action}`);
	}
}
