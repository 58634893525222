import {
	clearAllSaveErrors,
	privateSetSaveError,
	privateClearItemSaveError
} from './actions';
import { ROOT_SLICE } from './constants';
import { selectRootSlice, selectSaveErrorsByType } from './selectors';
import { initialState } from './initialState';

// ************
// PARTS
// ************
const reducerParts = {
	[clearAllSaveErrors]: state => {
		return {
			...state,
			[ROOT_SLICE]: { ...initialState }
		};
	},

	[privateSetSaveError]: (state, { payload: { type, itemId, message } }) => {
		const rootSlice = selectRootSlice(state);
		const saveErrors = selectSaveErrorsByType(state, { type });
		return {
			...state,
			[ROOT_SLICE]: {
				...rootSlice,
				[type]: {
					...saveErrors,
					[itemId]: message
				}
			}
		};
	},

	[privateClearItemSaveError]: (state, { payload: { type, itemId } }) => {
		const rootSlice = selectRootSlice(state);
		const saveErrors = { ...selectSaveErrorsByType(state, { type }) };
		delete saveErrors[itemId];
		return {
			...state,
			[ROOT_SLICE]: {
				...rootSlice,
				[type]: saveErrors
			}
		};
	}
};

export default reducerParts;
