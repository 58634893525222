/* eslint import/no-cycle:0 */
import { CLEAR } from '../../actions/editorToolbar';

import {
	SET_SMOOTH_CLOSEST_POINT,
	SET_SMOOTH_POINTS,
	EXIST_SMOOTH_POINTS
} from '../../actions/smoothTool';

const defaultState = {
	smoothClosestPoint: null /* the closest coordinate on the line for moveGPSTool
	{
		position: [ latitude, longitude ]
	}
*/,

	closestSmooth: null, // leaftlet feature
	currentSmoothPoints: [],
	/* 
		[
			{
				point:{
					// leaflet feature 
				},
				center: [lat, lng]
			},
			{
				point: 
				{ 
					// leaflet feature 
				},
				center: [lat, lng],
				distance: "5.18"
			}
		]
	*/ existingSmoothPoints: [],
	/* 
		[
			[
				{
					point:{
						// leaflet feature 
					},
					center: [lat, lng]
				},
				{
					point: 
					{ 
						// leaflet feature 
					},
					center: [lat, lng],
					distance: "5.18"
				}
			]
		] */
	smoothLine: null,
	/*
	[
		[lat,lng],
		[lat,lng],
		[lat,lng]
	]
	*/
	commentPoints: [],
	/*
	[
		{
			position: [ lat, lng],
			test: "This is a comment"
		}
	]
	*/
	existingCommentPoints: [],
	/*
	[
		{
			position: [ lat, lng],
			test: "This is a comment"
		}
	]
	*/
	existingSmoothLines: null
	/*
	[
		[
			[lat,lng],
			[lat,lng],
			[lat,lng]
		]
	]
	*/
};

const reducer = (state = defaultState, action) => {
	const { type, payload } = action;
	switch (type) {
		case SET_SMOOTH_CLOSEST_POINT:
			return {
				...state,
				smoothClosestPoint: payload.smoothClosestPoint,
				closestSmooth: payload.closestSmooth
			};
		case SET_SMOOTH_POINTS:
			return {
				...state,
				currentSmoothPoints: [...payload.points],
				smoothLine: payload.smoothLine,
				commentPoints: payload.commentPoints
			};
		case EXIST_SMOOTH_POINTS:
			return {
				...state,
				existingSmoothPoints: payload.existingSmoothPoints,
				existingSmoothLines: payload.existingSmoothLines,
				currentSmoothPoints: [],
				smoothLine: null,
				commentPoints: null,
				existingCommentPoints: [
					...state.existingCommentPoints,
					...state.commentPoints
				]
			};
		case CLEAR:
			return {
				...state,
				currentSmoothPoints: [],
				smoothLine: null,
				commentPoints: [],
				existingSmoothPoints: [],
				existingSmoothLines: null,
				existingCommentPoints: []
			};
		default:
			return state;
	}
};

export default reducer;
