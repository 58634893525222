/* eslint-disable camelcase */
import _ from 'lodash';

import { initialState } from './initialState';

// ************
// PARTS
// ************
export const buildReducerParts = (
	{
		reInitStateCustomers,
		requestCustomers,
		receiveCustomers,
		clearSelectedCustomers,
		setSelectedCustomers,
		addSelectedCustomer,
		removeSelectedCustomer,
		setLoadCustomersErrorMessage,
		clearLoadCustomersErrorMessage
	},
	filtersStatePropKey,
	filterStatePropKey = 'customers'
) => {
	const KEY_filters = filtersStatePropKey;
	const KEY_filter = filterStatePropKey;

	return {
		[reInitStateCustomers]: state => {
			return {
				...state,
				[KEY_filters]: {
					...state[KEY_filters],
					[KEY_filter]: { ...initialState }
				}
			};
		},

		[requestCustomers]: state => {
			return {
				...state,
				[KEY_filters]: {
					...state[KEY_filters],
					[KEY_filter]: {
						...state[KEY_filters][KEY_filter],
						isLoading: true,
						loadErrorMessage: undefined
					}
				}
			};
		},

		[receiveCustomers]: (state, { payload: { list } }) => {
			const newList = [...state[KEY_filters][KEY_filter].list, ...list];
			return {
				...state,
				[KEY_filters]: {
					...state[KEY_filters],
					[KEY_filter]: {
						...state[KEY_filters][KEY_filter],
						list: newList,
						listCount: newList.length,
						isLoading: false,
						isLoaded: true
					}
				}
			};
		},

		[clearSelectedCustomers]: state => {
			return {
				...state,
				[KEY_filters]: {
					...state[KEY_filters],
					[KEY_filter]: {
						...state[KEY_filters][KEY_filter],
						selected: [],
						selectedCount: 0
					}
				}
			};
		},

		[setSelectedCustomers]: (state, { payload: { selectedList = [] } }) => {
			return {
				...state,
				[KEY_filters]: {
					...state[KEY_filters],
					[KEY_filter]: {
						...state[KEY_filters][KEY_filter],
						selected: selectedList,
						selectedCount: selectedList.length
					}
				}
			};
		},

		[addSelectedCustomer]: (state, { payload: { item } }) => {
			const newSelectedList = _.union(state[KEY_filters][KEY_filter].selected, [
				item
			]);

			return {
				...state,
				[KEY_filters]: {
					...state[KEY_filters],
					[KEY_filter]: {
						...state[KEY_filters][KEY_filter],
						selected: newSelectedList,
						selectedCount: newSelectedList.length
					}
				}
			};
		},

		[removeSelectedCustomer]: (state, { payload: { item } }) => {
			const newSelectedList = _.without(
				state[KEY_filters][KEY_filter].selected,
				item
			);
			return {
				...state,
				[KEY_filters]: {
					...state[KEY_filters],
					[KEY_filter]: {
						...state[KEY_filters][KEY_filter],
						selected: newSelectedList,
						selectedCount: newSelectedList.length
					}
				}
			};
		},

		[setLoadCustomersErrorMessage]: (state, { payload: { message } }) => {
			return {
				...state,
				[KEY_filters]: {
					...state[KEY_filters],
					[KEY_filter]: {
						...state[KEY_filters][KEY_filter],
						loadErrorMessage: message
					}
				}
			};
		},

		[clearLoadCustomersErrorMessage]: state => {
			return {
				...state,
				[KEY_filters]: {
					...state[KEY_filters],
					[KEY_filter]: {
						...state[KEY_filters][KEY_filter],
						loadErrorMessage: undefined
					}
				}
			};
		}
	};
};

export default {
	buildReducerParts
};
