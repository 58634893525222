/* eslint-disable no-param-reassign */
import { defaultSeriesConfig } from './chart.config';
import { formatStationId } from '../../../utils/stationId';

export const RESeriesClick = /_series_\d+(_|$)/i;
export const REPointClick = /_point_\d+(_|$)/i;

const isDefined = v => v !== undefined && v !== null;

export const getSeriesKey = (series = {}) => {
	const { dataSource: readings = [] } = series;
	return readings.length
		? `${readings[0].id}-${readings[readings.length - 1].id}`
		: undefined;
};

export const printGroupName = remediationGroup => {
	const [s, e] = remediationGroup;
	return `${formatStationId(s.id)} - ${formatStationId(e.id)}`;
};

export const getSeriesDataSource = (series = {}) => {
	const { dataSource } = series;
	return dataSource;
};

export const isSameSeries = (s1, s2) => {
	return getSeriesKey(s1) === getSeriesKey(s2);
};

export const getSelectedSeries = chart => {
	const { selectionModule: { selectedDataIndexes = [] } = {} } = chart;
	let index = -1;
	if (selectedDataIndexes.length) {
		index = isDefined(selectedDataIndexes[0].series)
			? selectedDataIndexes[0].series
			: -1;
	}
	return index > -1 ? chart.series[index] : undefined;
};

export const tempGetSelectedSeriesKey = chart => {
	const series = getSelectedSeries(chart);
	return getSeriesKey(series);
};

export const createSeries = (dataSource, config = {}) => {
	return { ...defaultSeriesConfig, ...config, dataSource };
};

export const enableAnimation = (chart, enable = false) => {
	// eslint-disable-next-line no-param-reassign
	if (chart.animateSeries !== enable) {
		chart.animateSeries = enable;
	}
	chart.series
		.filter(s => s.animation.enable !== enable)
		.forEach(s => {
			// eslint-disable-next-line no-param-reassign
			s.animation.enable = enable;
		});
};

export const selectSeries = (chart, index) => {
	const isIndexDefined = index !== null && index !== undefined && index > -1;
	const selectedIndexes = isIndexDefined ? [{ series: index, point: NaN }] : [];

	enableAnimation(chart, false);
	// eslint-disable-next-line no-param-reassign
	chart.selectionModule.selectedDataIndexes = [...selectedIndexes];
	// eslint-disable-next-line no-param-reassign
	chart.selectedDataIndexes = [...selectedIndexes];
	chart.selectionModule.invokeSelection(chart);
	chart.refresh();
	enableAnimation(chart, false);
};

export const deselectSeries = chart => {
	selectSeries(chart, null);
};

export const setSelectedSeries = (chart, dataSource, index) => {
	const currentSeries = getSelectedSeries(chart);
	const ignore =
		(!dataSource && !currentSeries) ||
		getSeriesKey(currentSeries) === getSeriesKey({ dataSource });
	let didUpdate = false;

	if (!ignore) {
		if (!dataSource) {
			deselectSeries(chart);
			didUpdate = true;
		} else {
			const assumedSeries = chart.series[index];
			const matches =
				getSeriesKey(assumedSeries) === getSeriesKey({ dataSource });
			if (matches) {
				selectSeries(chart, index);
				didUpdate = true;
			} else {
				// something went wrong, which should not happen
			}
		}
	}

	return didUpdate;
};
