// eslint-disable-next-line import/no-cycle
import { createFiltersRedux } from './createFiltersRedux';
import { ROOT_SLICE_AP_CLOSURE_REPORTS } from '../constants';

const filtersRedux = createFiltersRedux({
	namespace: 'dit-action-plan-closure-reports-filters',
	rootSliceKey: ROOT_SLICE_AP_CLOSURE_REPORTS,
	filtersStatePropKey: 'aPClosureReportsFilters'
});

export default filtersRedux;
