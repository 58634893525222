import { createMultiModifierKey } from 'aegion_common_utilities/lib/ComplexReadingStoreUtil/util/computeModifierKey';
import { initializeDataWrapper } from '../util/dataWrapper';
import { STATUS } from '../constants';
import { setDataWrappers } from './actions';
import { defaultCreateSimplifiedReadings } from '../../../../utils/SurveyMeta/util/general';
import {
	isNormalizeReferenceSurvey,
	isInterpolateReferenceSurvey,
	selectInterpolateTargetSurveys,
	selectNormalizeTargetSurveys,
	selectNormalizeReferenceSurvey,
	selectInterpolateReferenceSurvey,
	selectSeries,
	selectInterpSeries,
	selectSeriesNormalized,
	selectInterpSeriesNormalized
} from '../../../util/readings.interpolate';
import {
	_selectAllOriginalFilteredDataSets,
	explicitSelectAlignedFilteredSeries,
	explicitSelectAlignedSeries,
	explicitSelectAllReadingFilterKeys
} from '../../../selectors/alignedReadings';
import { selectSurveys } from '../../../selectors/surveys';
import { selectHasSurveyDecimatedSeries } from '../selectors';

// 	parentSurvey,
// 	alignmentFlags: { useAligned = false, useInterpolated = false } = {},
// 	belowCriterionFlags: {
// 		useShadow = false,
// 		useThreshold = false,
// 		threshold
// 	} = {},
// 	hasPropFlags: { prop },
// 	decimateFlags: { decimate = true } = {}
// }) => {
// 	const targetKey = targetSurvey.id;
// 	const parentKey = parentSurvey && parentSurvey.id ? parentSurvey.id : 'NONE';
// 	const modifierKey = createMultiModifierKey({
// 		alignmentFlags: { useAligned, useInterpolated },
// 		belowCriterionFlags: { useShadow, useThreshold, threshold },
// 		hasPropFlags: { prop },
// 		decimateFlags: { decimate }
// 	});
// 	// INITIALIZE DATA WRAPPER AND PERSIST
// 	const dataWrapper = initializeDataWrapper();
// 	dataWrapper.status = STATUS.PROCESSING;

// 	return { targetKey, parentKey, modifierKey, dataWrapper };
// };

const createDecimatedDataWrapper = (
	series,
	{
		targetSurvey,
		parentSurvey,
		alignmentFlags: { useAligned = false, useInterpolated = false } = {},
		belowCriterionFlags: {
			useShadow = false,
			useThreshold = false,
			threshold
		} = {},
		hasPropFlags: { prop },
		decimateFlags: { decimate = true } = {},
		filterIsFlags = {}
	}
) => {
	// COMPUTE KEYS
	const targetKey = targetSurvey.id;
	const parentKey = parentSurvey && parentSurvey.id ? parentSurvey.id : 'NONE';
	const modifierKey = createMultiModifierKey({
		alignmentFlags: { useAligned, useInterpolated },
		belowCriterionFlags: { useShadow, useThreshold, threshold },
		hasPropFlags: { prop },
		decimateFlags: { decimate },
		filterIsFlags
	});
	// INITIALIZE DATA WRAPPER
	const dataWrapper = initializeDataWrapper();
	dataWrapper.dataset = defaultCreateSimplifiedReadings({
		readings: series,
		propsOverride: [prop]
	});
	dataWrapper.status = STATUS.AVAILABLE;
	return { targetKey, parentKey, modifierKey, dataWrapper };
};

// ******************************
// HELPERS - select FLAGS for derived datasets
// ******************************

// normalize
const _selectNormalizeFlags = (survey, getState) => {
	const blnIsNormalizeReferenceSurvey = isNormalizeReferenceSurvey(survey);
	const reduxNormalizeReferenceSurvey = selectNormalizeReferenceSurvey(
		survey,
		getState().app
	);
	const blnIsNormalizeTargetSurvey =
		!!reduxNormalizeReferenceSurvey &&
		reduxNormalizeReferenceSurvey.id !== survey.id;

	return { blnIsNormalizeReferenceSurvey, blnIsNormalizeTargetSurvey };
};

// interpolate
const _selectInterpolateFlags = (survey, getState) => {
	const blnIsInterpolateReferenceSurvey = isInterpolateReferenceSurvey(survey);
	const reduxInterpolateReferenceSurvey = selectInterpolateReferenceSurvey(
		survey,
		getState().app
	);
	const blnIsInterpolateTargetSurvey =
		!!reduxInterpolateReferenceSurvey &&
		reduxInterpolateReferenceSurvey.id !== survey.id;

	return { blnIsInterpolateReferenceSurvey, blnIsInterpolateTargetSurvey };
};

// ILI FLAGS
// @todo - this could be more dynamic - original implementation pressurized by customer deadline requirement.
const _selectILIFlags = survey => {
	const ILI_SURVEY_SUBTYPES = ['ILI-exMtlLoss', 'ILI-inMtlLoss'];
	const blnIsILISurvey =
		ILI_SURVEY_SUBTYPES.indexOf(survey.survey_subtype) > -1;
	return { blnIsILISurvey };
};

// ******************************
// DECIMATE ORIGINAL SERIES
// ******************************

const _thunkDecimateSurveySeries = survey => (dispatch, getState) => {
	const surveyReadingProps = survey.meta.getReadingKeyProps({ all: true });

	const dataWrappers = surveyReadingProps.map(readingProp => {
		const series = selectSeries(survey, readingProp, getState().app);

		return createDecimatedDataWrapper(series, {
			targetSurvey: survey,
			hasPropFlags: { prop: readingProp }
		});
	});
	dispatch(setDataWrappers(dataWrappers));
};

// ******************************
// DECIMATE DERIVED SERIES - NORMALIZED
// ******************************

const _thunkDecimateNormalizedSeries = survey => (dispatch, getState) => {
	const computedSeriesDataWrappers = [];
	const {
		blnIsNormalizeReferenceSurvey,
		blnIsNormalizeTargetSurvey
	} = _selectNormalizeFlags(survey, getState);

	if (blnIsNormalizeReferenceSurvey) {
		const surveysToNormalize = selectNormalizeTargetSurveys(
			survey,
			getState().app
		);
		surveysToNormalize.forEach(targetSurvey => {
			const targetReadingProps = targetSurvey.meta.getReadingKeyProps({
				all: true
			});
			targetReadingProps.forEach(targetReadingProp => {
				const seriesNormalized = selectSeriesNormalized(
					targetSurvey,
					targetReadingProp,
					getState().app
				);
				computedSeriesDataWrappers.push(
					createDecimatedDataWrapper(seriesNormalized, {
						targetSurvey,
						parentSurvey: survey,
						alignmentFlags: { useAligned: true },
						hasPropFlags: { prop: targetReadingProp }
					})
				);
			});
		});
	} else if (blnIsNormalizeTargetSurvey) {
		const surveyRef = selectNormalizeReferenceSurvey(survey, getState().app);
		const surveyReadingProps = survey.meta.getReadingKeyProps({ all: true });
		surveyReadingProps.forEach(readingProp => {
			const seriesNormalized = selectSeriesNormalized(
				survey,
				readingProp,
				getState().app
			);
			computedSeriesDataWrappers.push(
				createDecimatedDataWrapper(seriesNormalized, {
					targetSurvey: survey,
					parentSurvey: surveyRef,
					alignmentFlags: { useAligned: true },
					hasPropFlags: { prop: readingProp }
				})
			);
		});
	}

	// persist
	if (computedSeriesDataWrappers.length) {
		dispatch(setDataWrappers(computedSeriesDataWrappers));
	}
};

// ******************************
// DECIMATE DERIVED SERIES - INTERPOLATED
// ******************************

const _thunkDecimateInterpolatedSeries = survey => (dispatch, getState) => {
	const computedSeriesDataWrappers = [];
	const {
		blnIsInterpolateReferenceSurvey,
		blnIsInterpolateTargetSurvey
	} = _selectInterpolateFlags(survey, getState);

	if (blnIsInterpolateReferenceSurvey) {
		const surveysToInterpolate = selectInterpolateTargetSurveys(
			survey,
			getState().app
		);
		surveysToInterpolate.forEach(targetSurvey => {
			const targetReadingProps = targetSurvey.meta.getReadingKeyProps({
				all: true
			});
			targetReadingProps.forEach(targetReadingProp => {
				const interpSeries = selectInterpSeries(
					targetSurvey,
					targetReadingProp,
					getState().app
				);
				computedSeriesDataWrappers.push(
					createDecimatedDataWrapper(interpSeries, {
						targetSurvey,
						parentSurvey: survey,
						alignmentFlags: { useInterpolated: true },
						hasPropFlags: { prop: targetReadingProp }
					})
				);
			});
		});
	} else if (blnIsInterpolateTargetSurvey) {
		const surveyRef = selectInterpolateReferenceSurvey(survey, getState().app);
		const surveyReadingProps = survey.meta.getReadingKeyProps({ all: true });
		surveyReadingProps.forEach(readingProp => {
			const interpSeries = selectInterpSeries(
				survey,
				readingProp,
				getState().app
			);
			computedSeriesDataWrappers.push(
				createDecimatedDataWrapper(interpSeries, {
					targetSurvey: survey,
					parentSurvey: surveyRef,
					alignmentFlags: { useInterpolated: true },
					hasPropFlags: { prop: readingProp }
				})
			);
		});
	}

	// persist
	if (computedSeriesDataWrappers.length) {
		dispatch(setDataWrappers(computedSeriesDataWrappers));
	}
};

// ******************************
// DECIMATE DERIVED SERIES - NORMALIZED-INTERPOLATED
// ******************************
const _thunkDecimateNormalizedInterpolatedSeries = survey => (
	dispatch,
	getState
) => {
	const computedSeriesDataWrappers = [];
	const {
		blnIsNormalizeReferenceSurvey,
		blnIsNormalizeTargetSurvey
	} = _selectNormalizeFlags(survey, getState);
	const {
		blnIsInterpolateReferenceSurvey,
		blnIsInterpolateTargetSurvey
	} = _selectInterpolateFlags(survey, getState);

	if (blnIsNormalizeReferenceSurvey && blnIsInterpolateReferenceSurvey) {
		const surveysToInterpolate = selectInterpolateTargetSurveys(
			survey,
			getState().app
		);
		const surveysToNormalize = selectNormalizeTargetSurveys(
			survey,
			getState().app
		);
		const surveysToInterpNorm = surveysToInterpolate.filter(
			s => surveysToNormalize.indexOf(s) > -1
		);
		surveysToInterpNorm.forEach(targetSurvey => {
			const targetReadingProps = targetSurvey.meta.getReadingKeyProps({
				all: true
			});
			targetReadingProps.forEach(targetReadingProp => {
				const interpSeriesNormalized = selectInterpSeriesNormalized(
					targetSurvey,
					targetReadingProp,
					getState().app
				);
				computedSeriesDataWrappers.push(
					createDecimatedDataWrapper(interpSeriesNormalized, {
						targetSurvey,
						parentSurvey: survey,
						alignmentFlags: { useAligned: true, useInterpolated: true },
						hasPropFlags: { prop: targetReadingProp }
					})
				);
			});
		});
	} else if (blnIsNormalizeTargetSurvey && blnIsInterpolateTargetSurvey) {
		const surveyRef = selectNormalizeReferenceSurvey(survey, getState().app);
		const surveyReadingProps = survey.meta.getReadingKeyProps({ all: true });

		surveyReadingProps.forEach(readingProp => {
			const interSeriesNormalized = selectInterpSeriesNormalized(
				survey,
				readingProp,
				getState().app
			);
			computedSeriesDataWrappers.push(
				createDecimatedDataWrapper(interSeriesNormalized, {
					targetSurvey: survey,
					parentSurvey: surveyRef,
					alignmentFlags: { useAligned: true, useInterpolated: true },
					hasPropFlags: { prop: readingProp }
				})
			);
		});
	}

	// persist
	if (computedSeriesDataWrappers.length) {
		dispatch(setDataWrappers(computedSeriesDataWrappers));
	}
};

const _thunkDecimateILIFilteredSeries = survey => (dispatch, getState) => {
	const { blnIsILISurvey } = _selectILIFlags(survey, getState);
	if (blnIsILISurvey) {
		const computedSeriesDataWrappers = [];
		const surveyReadingProps = survey.meta.getReadingKeyProps({ all: true });
		const filteredReadingsDataStructures =
			_selectAllOriginalFilteredDataSets(survey, getState().app) || {};

		surveyReadingProps.forEach(readingProp => {
			const keys = Object.keys(filteredReadingsDataStructures);
			keys.forEach(k => {
				const dataStructures = filteredReadingsDataStructures[k];
				const { series } = dataStructures;
				const s = series[readingProp];
				if (s && s.length) {
					computedSeriesDataWrappers.push(
						createDecimatedDataWrapper(s, {
							targetSurvey: survey,
							hasPropFlags: { prop: readingProp },
							filterIsFlags: { [k]: true }
						})
					);
				}
			});
		});

		// persist
		if (computedSeriesDataWrappers.length) {
			dispatch(setDataWrappers(computedSeriesDataWrappers));
		}
	}
};

// ******************************
// DECIMATE ORIGINAL SERIES
// ******************************

const _thunkDecimateAlignedILISurvey = (iliSurvey, refSurvey) => (
	dispatch,
	getState
) => {
	const hasDecimatedSeries = selectHasSurveyDecimatedSeries(getState().app, {
		targetSurvey: iliSurvey,
		parentSurvey: refSurvey,
		alignmentFlags: { useAligned: true },
		decimateFlags: { decimate: true }
	});

	if (!hasDecimatedSeries) {
		const surveyReadingProps = iliSurvey.meta.getReadingKeyProps({ all: true });

		const dataWrappers = surveyReadingProps.map(readingProp => {
			const series = explicitSelectAlignedSeries(
				refSurvey,
				iliSurvey,
				readingProp,
				getState().app
			);

			return createDecimatedDataWrapper(series, {
				targetSurvey: iliSurvey,
				parentSurvey: refSurvey,
				hasPropFlags: { prop: readingProp },
				alignmentFlags: { useAligned: true },
				decimateFlags: { decimate: true }
			});
		});
		dispatch(setDataWrappers(dataWrappers));
	}
};

const _thunkDecimateAlignedFilteredILISurvey = (iliSurvey, refSurvey) => (
	dispatch,
	getState
) => {
	const filterKeys = explicitSelectAllReadingFilterKeys(
		iliSurvey,
		refSurvey,
		getState().app
	);

	const hasDecimatedSeries =
		filterKeys
			.map(k =>
				selectHasSurveyDecimatedSeries(getState().app, {
					targetSurvey: iliSurvey,
					parentSurvey: refSurvey,
					alignmentFlags: { useAligned: true },
					decimateFlags: { decimate: true },
					filterIsFlags: { [k]: true }
				})
			)
			.filter(v => !!v).length > 0;

	const computedSeriesDataWrappers = [];
	if (!hasDecimatedSeries) {
		const surveyReadingProps = iliSurvey.meta.getReadingKeyProps({ all: true });

		surveyReadingProps.forEach(readingProp => {
			filterKeys.forEach(k => {
				const series = explicitSelectAlignedFilteredSeries(
					refSurvey,
					iliSurvey,
					readingProp,
					k,
					getState().app
				);
				if (series && series.length) {
					computedSeriesDataWrappers.push(
						createDecimatedDataWrapper(series, {
							targetSurvey: iliSurvey,
							parentSurvey: refSurvey,
							hasPropFlags: { prop: readingProp },
							alignmentFlags: { useAligned: true },
							decimateFlags: { decimate: true },
							filterIsFlags: { [k]: true }
						})
					);
				}
			});
		});
	}

	// persist
	if (computedSeriesDataWrappers.length) {
		dispatch(setDataWrappers(computedSeriesDataWrappers));
	}
};

// ******************************
// MAIN
// ******************************
export const thunkDecimateCisSurvey = survey => dispatch => {
	dispatch(_thunkDecimateSurveySeries(survey));
	dispatch(_thunkDecimateNormalizedSeries(survey));
	dispatch(_thunkDecimateInterpolatedSeries(survey));
	dispatch(_thunkDecimateNormalizedInterpolatedSeries(survey));
	dispatch(_thunkDecimateILIFilteredSeries(survey));
};

export const thunkDecimateAlignedILISurvey = (
	iliSurvey,
	refSurvey
) => dispatch => {
	dispatch(_thunkDecimateAlignedILISurvey(iliSurvey, refSurvey));
	dispatch(_thunkDecimateAlignedFilteredILISurvey(iliSurvey, refSurvey));
};

export const thunkDecimateDerivedSurveys = () => (dispatch, getState) => {
	const surveys = selectSurveys(getState().app);
	const derivedSurveysToDecimate = surveys
		.filter(s => s.isDerived && !s.isSpatial)
		.filter(s => {
			const hasDecimatedSeries = selectHasSurveyDecimatedSeries(
				getState().app,
				{ targetSurvey: s }
			);
			return !hasDecimatedSeries;
		});
	derivedSurveysToDecimate.forEach(s =>
		dispatch(_thunkDecimateSurveySeries(s))
	);
};

export default { thunkDecimateCisSurvey, thunkDecimateAlignedILISurvey };
