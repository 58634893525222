/* eslint-disable import/prefer-default-export */
/* eslint-disable import/no-cycle */
import { createActions } from 'redux-actions';
import { ROOT_SLICE } from './constants';
import { KEY_TEXT_SEARCH, updateFilter } from '../../../util/filters';

const { setSearchText } = createActions(
	{
		SET_SEARCH_TEXT: searchText => ({ searchText })
	},
	{ prefix: ROOT_SLICE }
);

const thunkSetSearchText = searchText => dispatch => {
	updateFilter(KEY_TEXT_SEARCH, searchText);
	dispatch(setSearchText(searchText));
};

export { setSearchText, thunkSetSearchText };
