import jQuery from 'jquery';

const urlPattern = new RegExp(/(\S*)(url)$/);
export const getStringWithoutUrlPostscript = text => {
	const matches = urlPattern.exec(text);

	return matches[1];
};

const doCallGetJsonFromDatFileUrl = (url, type) => {
	return new Promise(resolve => {
		jQuery
			.ajax({
				url,
				type
			})
			.done((res, status, xhr) => {
				// TODO: if header is not found - getContentLength function - serialize data
				const contentLength = xhr.getResponseHeader('content-length');
				let json = null;
				if (typeof res === 'string') {
					json = JSON.parse(res);
				} else {
					json = res;
				}

				return resolve({
					json,
					contentLength,
					// This is only allowed after adding `"ExposeHeaders": ["x-amz-version-id"]` to the bucket's CORS policy (in permissions tab)
					fileVersion: xhr.getResponseHeader('x-amz-version-id')
				});
			});
	});
};

export const getJsonFromDatFileUrl = async (url, type = 'GET') => {
	switch (type) {
		case 'OPTIONS':
		case 'GET':
		case 'HEAD':
			return doCallGetJsonFromDatFileUrl(url, type);
		default:
			throw new Error(`Unknown HTTP Method ${type}`);
	}
};
