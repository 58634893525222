import { NAME_SPACE as ns } from './constants';

export const SET_CUSTOMER = `${ns}_SET_CUSTOMER`;

export const SET_CUSTOMERS = `${ns}_SET_CUSTOMERS`;

export const SET_SITE = `${ns}_SET_SITE`;

export const SET_SITES = `${ns}_SET_SITES`;

export const SET_ZONE = `${ns}_SET_ZONE`;

export const SET_ZONES = `${ns}_SET_ZONES`;

export const SET_READINGS = `${ns}_SET_READINGS`;

export const SET_TIME_PARAMETERS = `${ns}_SET_TIME_PARAMETERS`;
