/* eslint-disable import/no-cycle */

import { setIsEdited } from './editorToolbar';
import {
	getSingleEditLineFromMovedPoints,
	isPointInMovedPoints,
	getNewMovedPointsFromLatLng,
	getNewPointWithEditLatLng,
	getPixelDistance,
	getCommentPoints
} from './util/moveGPSTool';

import { getClosestGPSReading } from '../../components/leaflet/utils/ToolbarUtils';
import { getDatFilesAlreadySmoothedByState } from '../../utils/DatFiles';

const NS_MAPS = 'cisview_editorToolbar_';

export const SET_MOVE_GPS_POINT = `${NS_MAPS}SET_MOVE_GPS_POINT`;
const setMoveGPSPoint = point => {
	return { type: SET_MOVE_GPS_POINT, payload: point };
};

export const SET_EDIT_LINE = `${NS_MAPS}SET_EDIT_LINE`;
const setEditLine = (movedPoints, dats) => {
	const editLine = getSingleEditLineFromMovedPoints(movedPoints, dats);
	const commentPoints = getCommentPoints(movedPoints, dats);
	return {
		type: SET_EDIT_LINE,
		payload: { editLine, movedPoints, commentPoints }
	};
};

export const SET_DRAGGING = `${NS_MAPS}SET_DRAGGING`;
function setDragging(dragging) {
	return {
		type: SET_DRAGGING,
		payload: dragging
	};
}

const updateMovedPoints = latlng => (dispatch, getState) => {
	const state = getState();
	const {
		cisview: {
			editorToolbar: {
				moveGPSTool: { moveGPSPoint: { readingWithGPS } = {}, movedPoints }
			},
			job: { data: dats }
		}
	} = state;
	const { lat, lng } = latlng;

	const { dat: { fileName } = {} } = readingWithGPS;

	const movedPointsDatFile = movedPoints[fileName] || [];

	const newReadingWithGPS = getNewPointWithEditLatLng(readingWithGPS, latlng);

	// If point is already in the movedPoints then edit the existing movedPoint
	if (isPointInMovedPoints(readingWithGPS, movedPointsDatFile)) {
		const newMovedPoints = getNewMovedPointsFromLatLng(
			readingWithGPS,
			movedPoints,
			fileName,
			latlng
		);

		dispatch(setEditLine({ ...movedPoints, [fileName]: newMovedPoints }, dats));
		dispatch(
			setMoveGPSPoint({
				coordinate: [lat, lng],
				readingWithGPS: newReadingWithGPS
			})
		);

		return;
	}

	// add new edit point to list
	const updatedMovedPoints = {
		...movedPoints,
		[fileName]: [...movedPointsDatFile, newReadingWithGPS].sort(
			(a, b) => a.gpsIndx - b.gpsIndx
		)
	};

	dispatch(setIsEdited(true));
	dispatch(setEditLine(updatedMovedPoints, dats));
	dispatch(
		setMoveGPSPoint({
			readingWithGPS: newReadingWithGPS,
			coordinate: [lat, lng]
		})
	);
};

export const moveGPSDragging = evt => dispatch => {
	dispatch(updateMovedPoints(evt.latlng));
};

export const moveGPSStartDragging = () => dispatch => {
	dispatch(setDragging(true));
};

export const moveGPSEndDragging = () => dispatch => {
	dispatch(setDragging(false));
};

export const moveGPSMouseMove = latlng => (dispatch, getState) => {
	const state = getState();
	const { cisview } = state;
	const {
		maps,
		editorToolbar: { moveGPSTool }
	} = cisview;
	const { dragging, movedPoints } = moveGPSTool;

	// No need to proceed if point is already being dragged
	if (dragging) {
		return;
	}

	const { map } = maps;

	const { data, boundsByFile } = getDatFilesAlreadySmoothedByState(state);

	const mapBounds = map.getBounds();

	const point = getClosestGPSReading({
		dats: data,
		mapBounds,
		latlng,
		movedPoints,
		boundsByFile
	});

	// Return if no point found
	if (!point) {
		return;
	}

	const pixelDistance = getPixelDistance(point, latlng, map);

	if (pixelDistance < 15.0) {
		const [lng, lat] = point.geometry.coordinates;
		dispatch(
			setMoveGPSPoint({
				coordinate: [lat, lng],
				readingWithGPS: point
			})
		);
	} else {
		// reset moveGPSPoint
		dispatch(setMoveGPSPoint());
	}
};
