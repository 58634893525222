/* eslint-disable import/prefer-default-export */
const getAVLayers = layers => {
	const [avLayer] = Object.keys(layers)
		.map(key => layers[key])
		.filter(
			({ _layers, options: { app } = {} }) =>
				app === 'AssetView' && _layers !== undefined
		);
	return avLayer;
};

export const refreshAVLayers = map => {
	const {
		leafletElement: { _layers: layers }
	} = map;

	const avLayer = getAVLayers(getAVLayers(layers)._layers);
	Object.keys(avLayer._layers).forEach(key => {
		const layer = avLayer._layers[key];
		layer.updateLayer();
	});
};
