/* eslint-disable import/no-cycle */
import Sockette from 'sockette';
import getAipUrls from '../../config/getAipUrls';
import {
	selectWsMessage,
	isWsErrorMessage
} from '../../commons/util/aip-tools/messageUtil.ws';
import { getAccessToken } from '../../scanline/utils/token';
import { isTokenExpired } from '../../util/user';
import AccountUtil from '../../account/components/utils/AccountUtil';

const DOWNLOAD_ACTION = 'export-xls';

const isConnectionOpenedMessage = e => {
	const { data } = e;
	return data === 'Message Received';
};

const ignoreMessage = e => isConnectionOpenedMessage(e);

const checkAssetToken = () => {
	if (isTokenExpired()) {
		return AccountUtil.refresh();
	}
	return Promise.resolve();
};

class ExportWebSocket {
	constructor() {
		this.ws = undefined;
	}

	startDownload = (
		payload,
		{ handleResponse = () => {}, onMessage = () => {} }
	) => {
		if (this.ws) {
			// window.alert('File download already in progress.');
			return;
		}
		checkAssetToken().then(() => {
			const accessToken = getAccessToken();

			const websocketURL = `${getAipUrls().assetviewWS}?token=${accessToken}`;
			this.ws = new Sockette(websocketURL, {
				maxAttempts: 5,
				onopen: e => this._send(e, payload),
				onmessage: e => this._receiveMessage(e, { handleResponse, onMessage }),
				onreconnect: e => console.log('Reconnecting...', e),
				onmaximum: e => console.log('Stop Attempting!', e),
				onerror: e => console.log('Error:', e)
			});
		});
	};

	destroy = () => {
		this._destroyWebSocket();
	};

	_send = (_e, params) => {
		const payload = {
			action: DOWNLOAD_ACTION,
			...params
		};

		this.ws.send(JSON.stringify(payload));
		const intervalID = setInterval(() => {
			if (!this.ws) {
				clearInterval(intervalID);
				return;
			}
			this.ws.send('ping');
		}, 20000);
	};

	_receiveMessage = (wsEvent, { handleResponse, onMessage }) => {
		if (ignoreMessage(wsEvent)) {
			return;
		}

		const wsMessage = selectWsMessage(wsEvent);

		const error = isWsErrorMessage(wsMessage) ? wsMessage : undefined;
		const result = isWsErrorMessage(wsMessage) ? undefined : wsMessage;

		if (result) {
			onMessage(result);
		}

		const { url } = result;
		if (url !== undefined) {
			this._destroyWebSocket();
			this.zipsCount -= 1;
			handleResponse(error, result);
		}
	};

	_destroyWebSocket = () => {
		if (this.ws) {
			this.ws.close();
			this.ws = undefined;
			this.zipsCount = undefined;
		}
	};
}

export default new ExportWebSocket();
