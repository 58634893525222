import { getUserName } from '../../../util/user';

const isDefined = v => v !== undefined && v !== null && v.trim() !== '';

const injectSessionUser = (users = []) => {
	const sessionUser = {
		userId: getUserName()
	};

	return [sessionUser, ...users];
};

const toUniqueUsers = users => {
	return users.filter((user, idx, src) => {
		const isEquivalentUsers = u1 => u2 => {
			return u1.userId === u2.userId;
		};

		return src.findIndex(isEquivalentUsers(user)) === idx;
	});
};

const enrichUsers = users => {
	return users.map((user = {}) => {
		const { userId, fullName } = user;
		const hasUserId = isDefined(userId);
		const hasFullName = isDefined(fullName);

		let displayName = '';
		if (userId === getUserName()) {
			displayName = `Myself (${userId})`;
		} else if (hasUserId && hasFullName) {
			displayName = `${fullName} (${userId})`;
		} else if (hasUserId) {
			displayName = userId;
		} else if (hasFullName) {
			displayName = fullName;
		}

		return {
			userId: userId || undefined,
			fullName: fullName || undefined,
			displayName: displayName || undefined
		};
	});
};

export default {
	injectSessionUser,
	toUniqueUsers,
	enrichUsers
};
