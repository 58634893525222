/* eslint-disable import/no-cycle */
import { handleActions } from 'redux-actions';

// MAIN
import apClosureReportsReducerParts from './main/reducerParts';
import { initialState as apClosureReportsInitialState } from './main/initialState';

// FILTERS
import jobLineFiltersReducerParts from './filters/reducerParts';
import jobLineFiltersInitialState from './filters/initialState';

const initialState = {
	...apClosureReportsInitialState,
	...jobLineFiltersInitialState
};

const reducerParts = {
	...apClosureReportsReducerParts,
	...jobLineFiltersReducerParts
};

export default handleActions(reducerParts, initialState);
