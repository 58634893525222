export const CUSTOMER_UNKNOWN = '';
export const CUSTOMER_KINDER_MORGAN = 'kinder morgan';
export const CUSTOMER_ENERGY_TRANSFER = 'energy transfer';
export const CUSTOMER_TALLGRASS = 'tallgrass';

export const customerKeys = [
	CUSTOMER_UNKNOWN,
	CUSTOMER_KINDER_MORGAN,
	CUSTOMER_ENERGY_TRANSFER,
	CUSTOMER_TALLGRASS
];
