import setOperations from '../../set-operations/set-operations';

export const getArrayKeys = allStatsByKeys => {
	return Object.keys(allStatsByKeys).filter(key => {
		const statValue = allStatsByKeys[key];

		return Array.isArray(statValue);
	});
};

export const getSummaryNameForArrayFieldName = arrayFieldName => {
	return `${arrayFieldName}Count`;
};

export const filterForArrayKeysWithValues = (arrayKeys, allStatsByKeys) => {
	return arrayKeys.filter(
		fieldname => (allStatsByKeys[fieldname] || []).length > 0
	);
};

export const summarizeArrayInfo = (arrayKeys, allStatsByKeys) => {
	const arraySummary = {};

	const arrayKeysWithValues = filterForArrayKeysWithValues(
		arrayKeys,
		allStatsByKeys
	);

	arrayKeysWithValues.forEach(fieldName => {
		const arraySummaryFieldName = getSummaryNameForArrayFieldName(fieldName);
		const fieldValue = allStatsByKeys[fieldName];
		arraySummary[arraySummaryFieldName] = fieldValue.length;
	});

	return arraySummary;
};

export const createObjectFromKeys = (keys, allStatsByKeys) => {
	const objectToReturn = {};

	keys.forEach(fieldName => {
		objectToReturn[fieldName] = allStatsByKeys[fieldName];
	});

	return objectToReturn;
};

export const summarizeStatistics = allStatsByKeys => {
	const arrayKeys = getArrayKeys(allStatsByKeys);
	const allKeys = Object.keys(allStatsByKeys);
	const otherKeys = setOperations.difference(allKeys, arrayKeys);

	return {
		...summarizeArrayInfo(arrayKeys, allStatsByKeys),
		...createObjectFromKeys(otherKeys, allStatsByKeys)
	};
};

export default summarizeStatistics;
