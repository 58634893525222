/* eslint-disable import/no-cycle */
import { setSearchText, setCompanyName } from './actions';
import { ROOT_SLICE } from './constants';
import { selectRootSlice } from './selectors';

// ************
// PARTS
// ************
const reducerParts = {
	[setSearchText]: (state, { payload: { searchText } }) => {
		const rootSlice = selectRootSlice(state);
		return {
			...state,
			[ROOT_SLICE]: { ...rootSlice, searchText }
		};
	},

	[setCompanyName]: (state, { payload: { companyName } }) => {
		const rootSlice = selectRootSlice(state);
		return {
			...state,
			[ROOT_SLICE]: { ...rootSlice, companyName }
		};
	}
};

export default reducerParts;
