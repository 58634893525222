/* eslint-disable import/prefer-default-export */
const isDefined = v => v !== undefined && v !== null;

const cleanValue = (curr, orig) => {
	if (curr === null) {
		return undefined;
	}
	if (curr === undefined) {
		return isDefined(orig) ? orig : undefined;
	}
	return curr;
};

export const createXYMinMaxObject = (
	{ xMin, xMax, yMin, yMax },
	originalXYMinMaxObject = {}
) => {
	return {
		xMin: cleanValue(xMin, originalXYMinMaxObject.xMin),
		xMax: cleanValue(xMax, originalXYMinMaxObject.xMax),
		yMin: cleanValue(yMin, originalXYMinMaxObject.yMin),
		yMax: cleanValue(yMax, originalXYMinMaxObject.yMax)
	};
};
