// eslint-disable-next-line import/no-cycle
import ajax from '../../../../../scanline/utils/ajax';
import { hasAccessToken } from '../../../../../scanline/utils/token';

import { transformHttpProcessors } from './util';

export const fetchProcessors = callback => {
	if (hasAccessToken()) {
		ajax(
			`/assignTos`,
			JSON.stringify({ role: 'DP' }),
			'POST',
			(err, res) => {
				if (err) {
					callback(err);
				} else {
					const processors = transformHttpProcessors({ list: res });

					callback(null, processors);
				}
			},
			undefined,
			'cisviewreadonly'
		);
	}
};

export default { fetchProcessors };
