// this should be called cookie util and have another class tokenUtil which uses it.

const TokenUtil = {
	getCookie(cname) {
		const regexp = new RegExp(`(?:^${cname}|;\\s*${cname})=(.*?)(?:;|$)`, 'g');
		const result = regexp.exec(document.cookie);
		return result === null ? null : result[1];
	},
	createCookie(name, value, expires, path, domain) {
		let cookie = `${name}=${escape(value)};`;

		if (expires) {
			// If it's a date
			cookie += `expires=${expires.toGMTString()};`;
		}

		if (path) {
			cookie += `path=${path};`;
		}
		if (domain) {
			cookie += `domain=${domain};`;
		}

		document.cookie = cookie;
	},
	deleteCookie(name, path, domain) {
		// If the cookie exists
		if (this.getCookie(name)) {
			this.createCookie(name, '', -1, path, domain);
		}
	}
};

export default TokenUtil;
