/* eslint-disable jsx-a11y/control-has-associated-label */
import React, {
	useRef,
	useEffect,
	useMemo,
	useState,
	useCallback
} from 'react';
import apiUtils from '../../../../util/api';

import { checkIfUWebChatDomainIsBlocked } from './FallbackEmailModal/util';

import FallbackEmailModal from './FallbackEmailModal';
import './Box-Chat.scss';

const AEGION_TEAMS_ID = '63726ac0-c3fc-4f81-92fe-978d399bdff1';
const CUSTOMER_PARAM = `customer=${AEGION_TEAMS_ID}`;

// agent=45e9f671-5a9c-4ddb-8171-a2582b7bca97
// group=1b6a0034-1262-436d-a0d9-4a49ff1cb21f

const INITIAL_FALLBACK_PROPS = {
	didFailToLoad: false,
	showFallbackModal: false
};

const BoxChat = ({
	left,
	top,

	children,
	appName = 'AIM',
	groupId,
	agentId,
	wrapperClassName: propWrapperClassName = ''
}) => {
	const [isOpen, setIsOpen] = useState(false);
	const [fallbackProps, setFallbackProps] = useState(INITIAL_FALLBACK_PROPS);

	useEffect(() => {
		checkIfUWebChatDomainIsBlocked().then(isBlocked => {
			setFallbackProps({
				...fallbackProps,
				didFailToLoad: isBlocked
			});
		});
	}, []);

	const contactParam = useMemo(() => {
		if (groupId) {
			return `group=${groupId}`;
		}
		return `agent=${agentId}`;
	}, [groupId, agentId]);

	const wrapperClassName = useMemo(() => {
		return isOpen
			? `${propWrapperClassName} col-static aegion-webchat-wrapper webchat-is-open`
			: `${propWrapperClassName} col-static aegion-webchat-wrapper webchat-is-closed`;
	}, [isOpen, propWrapperClassName]);

	const toggleWebChat = useCallback(() => {
		if (!isOpen) {
			apiUtils.sendNotificationOpenedInAppChat(appName);
		}
		if (fallbackProps.didFailToLoad) {
			setFallbackProps({
				...fallbackProps,
				showFallbackModal: !fallbackProps.showFallbackModal
			});
		} else {
			setIsOpen(!isOpen);
		}
	}, [isOpen, setIsOpen, fallbackProps]);

	const closeFallbackModal = useCallback(() => {
		setFallbackProps({
			...fallbackProps,
			showFallbackModal: false
		});
	}, [fallbackProps]);

	const style = {};

	const dragPlaceHolder = useRef();

	return (
		<div
			ref={dragPlaceHolder}
			style={{ ...style, left, top }}
			key={`in-app-chat-${appName}`}
			className={wrapperClassName}
		>
			{children}
			<button
				type="button"
				id="uwebchat-open-button"
				className="uwebchat-open-button"
				onClick={toggleWebChat}
				title="Customer Support Chat 8am-5pm CST"
			>
				<i className="fa fa-comments-o" />
			</button>
			<div id="uwebchat-popup" className="uwebchat-chat-popup">
				<button
					type="button"
					className="uwebchat-close-button"
					onClick={toggleWebChat}
				>
					X
				</button>
				<iframe
					title="Customer Support"
					className="uwebchat-window-style"
					src={`https://pool01.uwebchat.com/uwebchat.html?${contactParam}&${CUSTOMER_PARAM}`}
				/>
			</div>
			<FallbackEmailModal
				showModal={fallbackProps.showFallbackModal}
				handleClose={closeFallbackModal}
			/>
		</div>
	);
};

export default BoxChat;
