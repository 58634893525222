/* eslint-disable import/prefer-default-export */
import {
	CORR_UNDO,
	CORR_REDO,
	MAN_UNDO,
	MAN_REDO,
	XLS_UNDO,
	XLS_REDO,
	MAN_CLEAR_HISTORY,
	CORR_CLEAR_HISTORY,
	XLS_CLEAR_HISTORY
} from './actionTypes';
import { FILE_EXPORT_TYPE } from '../../components/Downloads/utils/fileExport';

function getActionTypes(exportType) {
	switch (exportType) {
		case FILE_EXPORT_TYPE.MANAGER:
			return {
				undoType: MAN_UNDO,
				redoType: MAN_REDO,
				clearHistoryType: MAN_CLEAR_HISTORY
			};
		case FILE_EXPORT_TYPE.CORRELATOR:
			return {
				undoType: CORR_UNDO,
				redoType: CORR_REDO,
				clearHistoryType: CORR_CLEAR_HISTORY
			};
		case FILE_EXPORT_TYPE.EXCEL:
			return {
				undoType: XLS_UNDO,
				redoType: XLS_REDO,
				clearHistoryType: XLS_CLEAR_HISTORY
			};
		default:
			return undefined;
	}
}

function getDeltasByStructureName(structure, deltas = [], isCreate = true) {
	const [structHiddenColumns] = deltas.filter(
		({ name: exName }) => structure === exName
	);
	if (isCreate && structHiddenColumns === undefined) {
		return { name: structure, columns: [] };
	}
	return structHiddenColumns;
}

function getUserColumnsSettings(exportType, state) {
	const {
		assetview: { managerSettings, xlsExportColumns, corrExportColumns }
	} = state;
	if (exportType === FILE_EXPORT_TYPE.EXCEL) {
		return xlsExportColumns;
	}
	if (exportType === FILE_EXPORT_TYPE.MANAGER) {
		return managerSettings;
	}
	return corrExportColumns;
}

function getUserHiddenColumns(exportType, state) {
	const {
		active: {
			present: { userHiddenColumns }
		}
	} = getUserColumnsSettings(exportType, state);
	return userHiddenColumns;
}

function getIsOthers(exportType, state) {
	const {
		active: {
			present: { isOthers }
		}
	} = getUserColumnsSettings(exportType, state);
	return isOthers;
}

function getColumnProfiles(exportType, state) {
	const {
		profiles: { profiles }
	} = getUserColumnsSettings(exportType, state);
	return profiles;
}

export {
	getActionTypes,
	getDeltasByStructureName,
	getUserHiddenColumns,
	getColumnProfiles,
	getUserColumnsSettings,
	getIsOthers
};
