import { addMessageBox } from './message-box';

import { setBetaTestingTabsFromUrlParams } from './tabs';

import { createMessageFromFoundSettings } from '../../utils/DeveloperModeUtils';

export const setDeveloperSettings = settings => ({
	type: 'CISV_DEVELOPER_MODE_ADD_SETTINGS',
	settings
});

export const addDeveloperSettings = settings => dispatch => {
	dispatch(setDeveloperSettings(settings));

	const message = `Developer mode activated. ${createMessageFromFoundSettings(
		settings
	)}`;

	dispatch(setBetaTestingTabsFromUrlParams(settings));

	dispatch(addMessageBox(message, 'info'));
};

export default setDeveloperSettings;
