"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.isDate = exports["default"] = void 0;

var isDefined = function isDefined(v) {
  return v !== undefined && v !== null;
};

var isEmptyString = function isEmptyString(v) {
  return typeof v === 'string' && v.trim().length === 0;
};

var isDate = function isDate(v) {
  return isDefined(v) && !isEmptyString(v) && new Date(v).toString() !== 'Invalid Date';
};

exports.isDate = isDate;
var DateUtil = {
  isDate: isDate
};
var _default = DateUtil;
exports["default"] = _default;