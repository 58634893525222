"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = exports.DocJSON = void 0;

var _CSV = _interopRequireDefault(require("./DocTypes/CSV"));

var _GEOJSON = _interopRequireDefault(require("./DocTypes/GEOJSON"));

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    "default": obj
  };
}

var DocJSON = {
  CSV: _CSV["default"],
  GEOJSON: _GEOJSON["default"]
};
exports.DocJSON = DocJSON;
var _default = DocJSON;
exports["default"] = _default;