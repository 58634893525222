/* eslint-disable import/prefer-default-export */
import { ROOT_SLICE } from './constants';

export const selectRootSlice = stateAdmin => stateAdmin[ROOT_SLICE];

export const selectCompanies = stateAdmin => {
	const slice = selectRootSlice(stateAdmin);
	const { companies } = slice;
	return companies;
};

export const selectIsLoadingCompanies = stateAdmin => {
	const slice = selectRootSlice(stateAdmin);
	const { isLoadingCompanies } = slice;
	return isLoadingCompanies;
};

export const selectIsLoadedCompanies = stateAdmin => {
	const slice = selectRootSlice(stateAdmin);
	const { isLoadedCompanies } = slice;
	return isLoadedCompanies;
};

export const selectHasLoadingErrorCompanies = stateAdmin => {
	const slice = selectRootSlice(stateAdmin);
	const { hasLoadingErrorCompanies } = slice;
	return hasLoadingErrorCompanies;
};
