import { ajaxNew as ajax } from '../../../scanline/utils/ajax';

// eslint-disable-next-line import/prefer-default-export
export function sendEdits(id, edits) {
	return ajax({
		aip: 'cisviewedits',
		url: `/edits/${id}`,
		data: JSON.stringify(edits),
		method: 'POST'
	});
}
