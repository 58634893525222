import { ROOT_SLICE } from './constants';

export const selectRootSlice = stateAdmin => stateAdmin[ROOT_SLICE];

export const selectData = stateAdmin => {
	const slice = selectRootSlice(stateAdmin);
	const { data } = slice;
	return data;
};

export const selectBoolMap = stateAdmin => {
	const slice = selectRootSlice(stateAdmin);
	const { boolMap } = slice;
	return boolMap;
};

export const selectIsLoading = stateAdmin => {
	const slice = selectRootSlice(stateAdmin);
	const { isLoading } = slice;
	return isLoading;
};

export const selectIsLoaded = stateAdmin => {
	const slice = selectRootSlice(stateAdmin);
	const { isLoaded } = slice;
	return isLoaded;
};

export const selectErrorMessage = stateAdmin => {
	const slice = selectRootSlice(stateAdmin);
	const { errorMessage } = slice;
	return errorMessage;
};

export const selectDomainMap = stateAdmin => {
	const slice = selectRootSlice(stateAdmin);
	const { domainMap = {} } = slice;
	return domainMap;
};

export const selectDomain = (stateAdmin, { customer }) => {
	const domainMap = selectDomainMap(stateAdmin);
	return domainMap[customer];
};

export default {
	selectData,
	selectBoolMap,
	selectDomainMap,
	selectIsLoading,
	selectIsLoaded,
	selectErrorMessage,
	selectDomain
};
