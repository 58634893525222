/* eslint-disable camelcase */
const VALID_PRIMARY_SURVEYS = [
	'ONOFF',
	'ON-OFF',
	'ON_OFF',
	'ON',
	'DEPOL',
	'NATIVE'
];

const VALID_TARGET_SURVEYS = ['ILI', 'ILI-exMtlLoss', 'ILI-inMtlLoss'];
// TODO: Add back once approved by KM - 'ILI-swl', 'ILI-dent',

export const _isValidPrimarySurvey = (survey = {}) => {
	const { survey_type, survey_subtype } = survey;
	return (
		VALID_PRIMARY_SURVEYS.includes(survey_type) ||
		VALID_PRIMARY_SURVEYS.includes(survey_subtype)
	);
};

export const _isValidTargetSurvey = (survey = {}) => {
	const { survey_type, survey_subtype } = survey;
	return (
		VALID_TARGET_SURVEYS.includes(survey_type) ||
		VALID_TARGET_SURVEYS.includes(survey_subtype)
	);
};
