const initialState = {
	fileMeta: {
		A: undefined,
		B: undefined
	},
	fileContent: {
		A: undefined,
		B: undefined
	},
	fileDocJson: {
		A: undefined,
		B: undefined
	},
	fileErrors: {
		A: undefined,
		B: undefined
	},
	fileProcessingMessages: {
		A: undefined,
		B: undefined
	}
};

export default initialState;
