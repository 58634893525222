/* eslint-disable import/no-cycle */
import Sockette from 'sockette';
import getAipUrls from '../../../../config/getAipUrls';
import {
	selectWsMessage,
	isWsErrorMessage
} from '../../../../commons/util/aip-tools/messageUtil.ws';
import { getViewAsUserId } from '../../../utils/ajax';
import { getAccessToken, hasAccessToken } from '../../../utils/token';
import { getCustomer, getUserName } from '../../../../util/user';

const DOWNLOAD_ACTION = 'actionPlan';

const isConnectionOpenedMessage = e => {
	const { data } = e;
	return data === 'Message Received';
};

const ignoreMessage = e => isConnectionOpenedMessage(e);

class FileDownloadWebSocket {
	constructor() {
		this.ws = undefined;
	}

	startDownload = (payload, additionalProps, handleResponse) => {
		if (this.ws) {
			window.alert('File download already in progress.');
			return;
		}

		let websocketURL = getAipUrls().scanlineReportV2WS;
		if (hasAccessToken()) {
			websocketURL = `${websocketURL}?auth=${getAccessToken()}`;
		}

		this.ws = new Sockette(websocketURL, {
			onopen: e => this._send(e, payload, additionalProps),
			onmessage: e => this._receiveMessage(e, handleResponse)
		});
	};

	destroy = () => {
		this._destroyWebSocket();
	};

	_send = (_e, paramPayload, additionalProps = {}) => {
		const {
			downloadAction,
			reportTypeKey,
			lineGuid,
			projectId,
			surveyGuid
		} = additionalProps;
		const payload = {
			...paramPayload,
			action: downloadAction || DOWNLOAD_ACTION,
			reportTypeKey,
			lineGuid,
			projectId,
			surveyGuid,
			authParams: {
				token: hasAccessToken() ? getAccessToken() : undefined,
				customer: getCustomer(),
				userId: getUserName(),
				viewAsUserId: getViewAsUserId()
			}
		};

		this.ws.send(JSON.stringify(payload));
	};

	_receiveMessage = (wsEvent, handleResponse) => {
		if (ignoreMessage(wsEvent)) {
			return;
		}

		const wsMessage = selectWsMessage(wsEvent);

		const error = isWsErrorMessage(wsMessage) ? wsMessage : undefined;
		const result = isWsErrorMessage(wsMessage) ? undefined : wsMessage;

		handleResponse(error, result);
		this._destroyWebSocket();
	};

	_destroyWebSocket = () => {
		if (this.ws) {
			this.ws.close();
			this.ws = undefined;
		}
	};
}

export default new FileDownloadWebSocket();
