/* eslint-disable no-use-before-define */
import { getReadingMapper } from './mapTo';
import getMetaByApp from './getMetaByApp';

const isValidReading = reading => {
	return typeof reading.id === 'number' && !Number.isNaN(reading.id);
};

// eslint-disable-next-line import/prefer-default-export
export const transformServerReadings = (
	{ readings, comments = {}, additionalProps = {}, surveyType },
	options = {}
) => {
	const { appName = 'scanline' } = options;
	const { constants } = getMetaByApp(appName);
	const { BYTE_ARRAY_INTERVAL } = constants;
	const byteArray = _base64ToArrayBuffer(readings);
	const dataView = new DataView(byteArray);

	const mapToReading = getReadingMapper(surveyType, appName);

	let index = 0;
	const transformedReadings = [];
	while (index < byteArray.byteLength) {
		const reading = mapToReading(dataView, index);
		if (isValidReading(reading)) {
			transformedReadings.push({
				...(additionalProps[transformedReadings.length] || {}),
				...reading,
				cmt: comments[transformedReadings.length]
			});
		}

		index += BYTE_ARRAY_INTERVAL;
	}

	return transformedReadings;
};

const _base64ToArrayBuffer = base64 => {
	const binaryString = global.atob(base64);

	const len = binaryString.length;
	const bytes = new Uint8Array(len);
	for (let i = 0; i < len; i += 1) {
		bytes[i] = binaryString.charCodeAt(i);
	}
	return bytes.buffer;
};
