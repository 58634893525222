export const removeFromSpikeEditorUserChangesByFile = datFile => ({
	type: 'READINGS_AUTO_CORRECTION_REVERT_USER_ACTIONS',
	datFile
});

export const addToSpikeEditorStackChanges = (changes, datFile) => ({
	type: 'READINGS_AUTO_CORRECTION_ADD_USER_ACTION',
	changes,
	datFile
});

// Note that Readings Auto Correction always clears by file (not entire clear)
export const clearAutoCorrectedReadingsData = datFile => ({
	type: 'READINGS_AUTO_CORRECTION_RECEIVE_CLEAR_BY_FILE',
	datFile
});

export const updatedReadingLedgerBasedOnUpdate = payload => ({
	type: 'READINGS_AUTO_CORRECTION_UPDATE',
	payload
});

export const setReadingLedger = (datFile, ledgers) => ({
	type: 'READINGS_AUTO_CORRECTION_SET_LEDGER',
	datFile,
	ledgers
});

export const setReadingsChanged = datFile => ({
	type: 'READINGS_AUTO_CORRECTION_READINGS_CHANGED',
	datFile
});
