import { ROOT_SLICE } from './constants';

const EMPTY_OBJECT = {};

export const selectRootSlice = stateAdmin => stateAdmin[ROOT_SLICE];

export const selectData = stateAdmin => {
	const slice = selectRootSlice(stateAdmin);
	const { data } = slice;
	return data;
};

export const selectAssetviewSettingsMap = stateAdmin => {
	const slice = selectRootSlice(stateAdmin);
	const { assetviewSettingsMap } = slice;
	return assetviewSettingsMap;
};

export const selectAvMatchTermMap = stateAdmin => {
	const slice = selectRootSlice(stateAdmin);
	const { avMatchTermMap } = slice;
	return avMatchTermMap;
};

export const selectDataByCustomer = (stateAdmin, { customer }) => {
	const data = selectData(stateAdmin);
	return data[customer] || EMPTY_OBJECT;
};

export const selectIsLoading = stateAdmin => {
	const slice = selectRootSlice(stateAdmin);
	const { isLoading } = slice;
	return isLoading;
};

export const selectIsLoaded = stateAdmin => {
	const slice = selectRootSlice(stateAdmin);
	const { isLoaded } = slice;
	return isLoaded;
};

export const selectErrorMessage = stateAdmin => {
	const slice = selectRootSlice(stateAdmin);
	const { errorMessage } = slice;
	return errorMessage;
};

export default {
	selectData,
	selectDataByCustomer,
	selectAssetviewSettingsMap,
	selectAvMatchTermMap,
	selectIsLoading,
	selectIsLoaded,
	selectErrorMessage
};
