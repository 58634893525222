import { SET_TEMPLATES, SET_IS_DOWNLOADING } from '../actions/reports';

const defaultState = {
	templates: [],
	isDownloading: false
};

const reducer = (state = defaultState, action) => {
	const { type, payload } = action;
	switch (type) {
		case SET_IS_DOWNLOADING:
			return { ...state, isDownloading: payload };
		case SET_TEMPLATES:
			return { ...state, templates: payload };
		default:
			return state;
	}
};

export default reducer;
