// eslint-disable-next-line import/no-cycle
// import { store } from '../../../scanline/store/index';

import { MiscUtils } from 'aegion_common_utilities';
import Cache from '../../../commons/util/cache';
import { getContentLengthOfJson } from '../../../commons/util/misc';

import {
	cleanUrlForStats,
	validateStatsUrlFields,
	createStorageKey,
	getDatFilenameFromUrl
} from './jobcache.utils';

// eslint-disable-next-line import/no-cycle
import sendAnalyticsEvent from './jobcache.analytics';

const INDEXEDDB_DATABASE_NAME = 'CISV_DATA';
const INDEXEDDB_STORE_NAME = 'readingsJson';

const isProd = MiscUtils.isProd();

let indexedbCache;

const onCleanup = cleanupStats => {
	sendAnalyticsEvent(null, 'CISV-cleanup-job-data-with-cache', {
		cleanupStats
	});
};

const onError = error => {
	// eslint-disable-next-line no-use-before-define
	sendAnalyticsEvent(getDb(), 'CISV-error-load-job-data-with-cache', {
		error
	});
};

const thirtyDays = 1000 * 60 * 60 * 24 * 30;

const _createIndexdbInstance = () => {
	indexedbCache = Cache.initIndexedDb(
		INDEXEDDB_DATABASE_NAME,
		INDEXEDDB_STORE_NAME,
		'id',
		{
			// verbose: !isProd,
			// We want the errors to bubble up in development environments
			returnRejectedPromise: !isProd,
			onError,
			onCleanup,
			maxAge: thirtyDays
		}
	);
};

const getDb = () => {
	if (!indexedbCache) {
		_createIndexdbInstance();
	}

	return indexedbCache;
};

const createJobCacheStatsSkeleton = () => {
	return {
		urlBase: null,
		s3KeyBase: null,
		found: [],
		notFound: [],
		deleted: [],
		set: [],
		contentLengthTotalFromCache: 0,
		contentLengthTotalSet: 0,
		contentLengthTotalDeleted: 0
	};
};

// TODO: This makes heap analytics super bloated - make a simple array with simple objects
const cisvJobCacheStatsByFileName = {
	// Will look like: 'tiny.dat': { urlBase, set, etc...}
};

// TODO: Clean this up a lot more...include in the key: aegion-s3-cache-{dev|test|prod}-timeuuid-filekey-filename
// "aegion-s3-cache-https://aip-cisline-dev-file-upload.s3.us-west-2.amazonaws.com/246cb2d0-c327-4310-aa55-3c4b38a2340e/dats/278b64/07_CBV6-1.DAT_envelope.json"

const getStatsByUrl = url => {
	const datFilename = getDatFilenameFromUrl(url);
	if (!cisvJobCacheStatsByFileName[datFilename]) {
		cisvJobCacheStatsByFileName[datFilename] = createJobCacheStatsSkeleton();
	}

	return cisvJobCacheStatsByFileName[datFilename];
};

const _createCacheStats = statsRaw => {
	const { url, ...otherStats } = statsRaw;

	if (!url) {
		throw new Error('Expected a url for cache stats');
	}

	const { urlBase, s3KeyBase, filename } = cleanUrlForStats(url);

	const cisvJobCacheStats = getStatsByUrl(url);

	validateStatsUrlFields(cisvJobCacheStats, { urlBase, s3KeyBase });

	if (!cisvJobCacheStats.urlBase) {
		cisvJobCacheStats.urlBase = urlBase;
	}
	if (!cisvJobCacheStats.s3KeyBase) {
		cisvJobCacheStats.s3KeyBase = s3KeyBase;
	}

	// TODO: Also validate "fileVersion" as passed-in stat
	return {
		filename,
		...otherStats
	};
};

export const setCacheJson = (
	url,
	json,
	fileVersion,
	contentLength,
	options = {}
) => {
	const key = createStorageKey(url);

	const object = {
		id: key,
		json,
		contentLength,
		fileVersion,
		url
	};

	const {
		oldFileVersion,
		updateReason,
		lastModified,
		replace = false
	} = options;

	if (oldFileVersion) {
		object.oldFileVersion = oldFileVersion;
	}
	if (updateReason) {
		object.updateReason = updateReason;
	}

	const cisvJobCacheStats = getStatsByUrl(url);

	cisvJobCacheStats.contentLengthTotalSet += contentLength;
	// TODO: Should not store updatedDate once fileVersion can be validated
	cisvJobCacheStats.set.push(
		_createCacheStats({
			url,
			fileVersion,
			lastModified,
			oldFileVersion,
			contentLength,
			updateReason
		})
	);

	return getDb().set(object, { replace });
};

export const getCacheJson = (url, newestFileVersion) => {
	if (!newestFileVersion) {
		return Promise.reject(
			new Error(
				`Expected a valid file version but got ${newestFileVersion} while trying to fetch ${url} from indexeddb`
			)
		);
	}
	const key = createStorageKey(url);
	const cisvJobCacheStats = getStatsByUrl(url);

	const db = getDb();
	return db.get(key).then(object => {
		if (!object) {
			cisvJobCacheStats.notFound.push(_createCacheStats({ url }));
			return null;
		}

		const { json, fileVersion, contentLength: foundContentLength } = object;
		const contentLength = foundContentLength || getContentLengthOfJson(json);

		cisvJobCacheStats.contentLengthTotalFromCache += contentLength;

		cisvJobCacheStats.found.push(
			_createCacheStats({
				url,
				newestFileVersion,
				fileVersion,
				contentLength
			})
		);

		if (fileVersion !== newestFileVersion) {
			// We can't use this as the dat file has been updated since
			cisvJobCacheStats.contentLengthTotalDeleted += contentLength;
			cisvJobCacheStats.deleted.push(
				_createCacheStats({
					url,
					newestFileVersion,
					fileVersion,
					contentLength
				})
			);
			return getDb().delete(key);
		}

		return json;
	});
};

const splitStatsByFile = () => {
	const stats = Object.keys(cisvJobCacheStatsByFileName).map(filename => {
		const statsForFilename = cisvJobCacheStatsByFileName[filename];

		return {
			filename,
			...statsForFilename
		};
	});

	return stats;
};

const getAggregateStats = statsByFile => {
	let contentLengthTotalFromCache = 0;
	let contentLengthTotalSet = 0;
	let contentLengthTotalDeleted = 0;

	statsByFile.forEach(stats => {
		contentLengthTotalFromCache += stats.contentLengthTotalFromCache;
		contentLengthTotalSet += stats.contentLengthTotalSet;
		contentLengthTotalDeleted += stats.contentLengthTotalDeleted;
	});

	return {
		contentLengthTotalFromCache,
		contentLengthTotalSet,
		contentLengthTotalDeleted
	};
};

const splitTimesByFile = timePassedStatsByFileName => {
	return Object.keys(timePassedStatsByFileName).map(filename => ({
		filename,
		...timePassedStatsByFileName[filename]
	}));
};

const getAggregateTimes = timePassedStatsByFileName => {
	const timePassedStatsAggregate = {};

	timePassedStatsByFileName.forEach(timePassedStats => {
		const { filename, ...otherTimeStats } = timePassedStats;

		Object.keys(otherTimeStats).forEach(timeStatName => {
			const statValue = otherTimeStats[timeStatName];
			if (!timePassedStatsAggregate[timeStatName]) {
				timePassedStatsAggregate[timeStatName] = 0;
			}

			timePassedStatsAggregate[timeStatName] += statValue;
		});
	});

	const totalTimePassed = Object.values(timePassedStatsAggregate).reduce(
		(prev, next) => prev + next,
		0
	);
	timePassedStatsAggregate.totalTimePassed = totalTimePassed;

	return timePassedStatsAggregate;
};

const validateAggregateStats = aggregateStats => {
	if (aggregateStats.contentLengthTotalFromCache === undefined) {
		throw new Error(
			'Must have the field "contentLengthTotalFromCache" to provide validation'
		);
	}
};

const STATS_FLAGS = {
	ALL_CACHE: 'allCache',
	ALL_HTTP: 'allHttp',
	SHARED: 'both',
	NONE: 'none'
};

const getStatsFlag = aggregateStats => {
	validateAggregateStats(aggregateStats);

	const {
		contentLengthTotalFromCache: fromCache,
		contentLengthTotalSet: fromHttp
	} = aggregateStats;

	const someFromCache = fromCache > 0;
	const someFromHttp = fromHttp > 0;
	if (someFromCache && fromHttp === 0) {
		return STATS_FLAGS.ALL_CACHE;
	}
	if (someFromHttp && fromCache === 0) {
		return STATS_FLAGS.ALL_HTTP;
	}
	if (someFromHttp) {
		return STATS_FLAGS.SHARED;
	}

	return STATS_FLAGS.NONE;
};

export const onLoadAll = timePassedStatsByFileName => {
	const statsByFile = splitStatsByFile();
	const aggregateStats = getAggregateStats(statsByFile);
	const timesByFile = splitTimesByFile(timePassedStatsByFileName);
	const aggregateTimes = getAggregateTimes(timesByFile);
	const statsDataLoadedFrom = getStatsFlag(aggregateStats);

	sendAnalyticsEvent(getDb(), 'CISV-load-job-data-with-cache', {
		statsByFile,
		aggregateStats,
		statsDataLoadedFrom,
		timesByFile,
		aggregateTimes
	});
};

export const onUpdateSuccess = (
	oldFileVersion,
	lastModified,
	newFileVersion,
	contentLength
) => {
	sendAnalyticsEvent(getDb(), 'CISV-update-job-data-cache', {
		update: { oldFileVersion, lastModified, newFileVersion, contentLength }
	});
};

export const onUpdateError = (oldFileVersion, error) => {
	sendAnalyticsEvent(getDb(), 'CISV-update-job-data-cache-error', {
		oldFileVersion,
		error
	});
};

export const onGetError = (oldFileVersion, error) => {
	sendAnalyticsEvent(getDb(), 'CISV-get-job-data-cache-error', {
		oldFileVersion,
		error
	});
};
