/* eslint-disable import/prefer-default-export */
import { computeBelowCriterionsByThreshold } from './byThreshold';
import { computeBelowCriterionsByShadowLine } from './byShadow';

const isDefined = v => v !== null && v !== undefined;

export const computeBelowCriterionDataSets = ({
	primary: {
		surveyType,
		readings,
		readingsWithGaps,
		readingsWithGapsIndexMap,
		readingKey
	},
	secondary: {
		surveyType: secondarySurveyType,
		// eslint-disable-next-line no-unused-vars
		readings: secondaryReadings,
		readingsWithGaps: secondaryReadingsWithGaps,
		readingsWithGapsMap: secondaryReadingsWithGapsMap,
		// eslint-disable-next-line no-unused-vars
		readingKey: secondaryReadingKey // not used currently - here for likely future use - will likely need to compute by 2 datasets one of which may not have 'sh'
	} = {},
	threshold,
	useThreshold,
	useShadow,
	comparatorkey
}) => {
	const EMPTY_RESULTS = {
		bcReadings: [],
		bcReadingsIndexMap: {},
		bcReadingsWithGaps: [],
		bcReadingsWithGapsIndexMap: {},
		bcReadingsWithGeoGaps: [],
		bcReadingsWithGeoGapsIndexMap: {}
	};

	// ******************
	// THRESHOLD
	// ******************

	if (useThreshold && isDefined(threshold)) {
		return computeBelowCriterionsByThreshold(
			surveyType,
			readings,
			readingsWithGaps,
			readingsWithGapsIndexMap,
			readingKey,
			threshold,
			comparatorkey
		);
	}

	// ******************
	// SHADOW
	// ******************
	if (useShadow && secondaryReadingsWithGaps && secondaryReadingsWithGapsMap) {
		return computeBelowCriterionsByShadowLine(
			surveyType,
			readings,
			readingsWithGaps,
			readingsWithGapsIndexMap,
			readingKey,
			secondarySurveyType,
			secondaryReadingsWithGaps,
			secondaryReadingsWithGapsMap,
			comparatorkey
		);
	}

	return EMPTY_RESULTS;
};
