/* eslint-disable import/prefer-default-export */
import { createMailToString } from '../../../../../../scanline/utils/mailto';
import { getUserName } from '../../../../../../util/user';
import { EMAIL_CUSTOMER_SUPPORT } from '../../../../../constants/emails';

export const createMailToHref = () =>
	createMailToString({
		mailTo: EMAIL_CUSTOMER_SUPPORT,
		subject: 'Chat blocked - need assistance',
		body: [
			'Hello,',
			`Could you help me with the below issue:`,
			'',
			'',
			`My username is ${getUserName()}.`,
			'Thank you,'
		]
	});

export const checkIfUWebChatDomainIsBlocked = async () =>
	new Promise(resolve => {
		const imageFound = () => {
			resolve(false); // if found return false, because it is not blocked
		};

		const imageNotFound = () => {
			resolve(true); // if found return true, because we assume the domain is blocked by IT
		};

		const testImage = URL => {
			const tester = new window.Image();
			tester.onload = imageFound;
			tester.onerror = imageNotFound;
			tester.src = URL;
		};

		testImage('https://pool01.uwebchat.com/uWebChat-icon.png');
	});
