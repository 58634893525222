/* eslint-disable import/no-cycle */
import _ from 'lodash';
import { set, setIsLoadingLoaded, setErrorMessage, clear } from './actions';
import { ROOT_SLICE } from './constants';
import { initialState } from './initialState';
import {
	selectRootSlice,
	selectIsLoading,
	selectIsLoaded,
	selectErrorMessage
} from './selectors';

const createMaps = (customers = []) => {
	return customers.reduce(
		(acc, c) => {
			const { name, domain } = c;
			acc.boolMap[name] = true;
			acc.domainMap[name] = domain;
			return acc;
		},
		{ boolMap: {}, domainMap: {} }
	);
};

const isDefined = v => v !== undefined && v !== null;

// ************
// PARTS
// ************
const reducerParts = {
	[clear]: state => {
		return {
			...state,
			[ROOT_SLICE]: _.cloneDeep(initialState)
		};
	},

	[set]: (state, { payload: { data } }) => {
		const rootSlice = selectRootSlice(state);
		const { boolMap, domainMap } = createMaps(data);
		return {
			...state,
			[ROOT_SLICE]: {
				...rootSlice,
				data,
				boolMap,
				domainMap
			}
		};
	},

	[setIsLoadingLoaded]: (
		state,
		{ payload: { isLoading: newIsLoading, isLoaded: newIsLoaded } }
	) => {
		const rootSlice = selectRootSlice(state);
		const isLoading = selectIsLoading(state);
		const isLoaded = selectIsLoaded(state);

		const CHANGED = {
			isLoading: isDefined(newIsLoading) && newIsLoading !== isLoading,
			isLoaded: isDefined(newIsLoaded) && newIsLoaded !== isLoaded
		};

		if (!CHANGED.isLoaded && !CHANGED.isLoading) {
			return state;
		}

		return {
			...state,
			[ROOT_SLICE]: {
				...rootSlice,
				isLoading: CHANGED.isLoading ? newIsLoading : isLoading,
				isLoaded: CHANGED.isLoaded ? newIsLoaded : isLoaded
			}
		};
	},

	[setErrorMessage]: (
		state,
		{ payload: { errorMessage: newErrorMessage } }
	) => {
		const rootSlice = selectRootSlice(state);
		const errorMessage = selectErrorMessage(state);

		if (newErrorMessage === errorMessage) {
			return state;
		}

		return {
			...state,
			[ROOT_SLICE]: {
				...rootSlice,
				errorMessage: newErrorMessage
			}
		};
	}
};

export default reducerParts;
