/* eslint-disable no-unused-vars */
/* eslint-disable no-template-curly-in-string */
/* eslint-disable no-param-reassign */
import createBaseSurveyMeta from './_BASE';
// import colors from '../../Colors';
import {
	computeUuid,
	computeFormattedStationId,
	computeAC,
	computeDistance
} from '../util/readings.parse.computeProps';
import { createComputeYMinYMaxWithZeroYMin } from '../util/yMinYMax';
import {
	createReadingKeyProps,
	createGetReadingKeys,
	createGetReadingKeyProps,
	createGetColumns,
	createGetSurveyColor,
	createTransformToCsvJson
} from './util';

/**
 * LEAVING THE MANUAL AC.tooltipRender code here for future ref incase we need it again.
 */

/*
import tooltipRender from './AC.tooltipRender';

const createTooltipRender = (chart, externalFn) => (...args) => {
	externalFn(...args);
	const event = args[0];
	tooltipRender(event, chart);
};
 */

const INVERT_Y_AXIS = false;

const isDefined = v => v !== undefined && v !== null;

// eslint-disable-next-line no-unused-vars
export default _survey => {
	// CONSTANTS

	const gapThreshold = 200;

	// READING KEYS

	const readingKeys = [
		{
			label: 'AC',
			value: 'ac',
			seriesType: 'bar',
			isPercentage: true,
			color: '#00bdae', // ignore - colors.getColor() - use teal
			preventCharting: true
		},
		{
			label: 'Low AC',
			value: 'acPure',
			seriesType: 'bar',
			isPercentage: true,
			color: '#00bdae' // ignore - colors.getColor() - use teal
		},
		{
			label: 'AC Warning',
			value: 'acWarning',
			seriesType: 'bar',
			isPercentage: true,
			color: '#ffc000' // ignore - colors.getColor() - use yellow
		},
		{
			label: 'AC Problem',
			value: 'acProblem',
			seriesType: 'bar',
			isPercentage: true,
			color: '#cc0000' // ignore - colors.getColor() - use red
		}
	];

	const readingKeyProps = createReadingKeyProps(readingKeys);

	const computeYMinYMax = createComputeYMinYMaxWithZeroYMin(readingKeys);

	// SKIP READING

	const isSkipReading = reading =>
		reading && readingKeys.every(rk => reading[rk.value] === 0);

	// HAS GAP

	const hasGap = (currReading, prevReading) => {
		return !!prevReading && currReading.id - prevReading.id > gapThreshold;
	};

	// INTERPOLATE

	const interpolate = (
		// eslint-disable-next-line no-unused-vars
		_prevDepolReading,
		// eslint-disable-next-line no-unused-vars
		_nextDepolReading,
		// eslint-disable-next-line no-unused-vars
		_alignToReading
	) => {};

	const computeHilo = r => {
		r.low = r.low || 0;
	};

	// COMPUTE PROPS - to be used by readings.parse
	const computeProps = (survey, prevReading, currReading, currIdx) => {
		computeUuid(currReading, currIdx);
		computeFormattedStationId(currReading);
		computeAC(currReading);
		computeHilo(currReading);
		computeDistance(prevReading, currReading);
		computeYMinYMax(survey, currReading);
	};

	const computePropsForDerivedSurvey = (
		survey,
		prevReading,
		currReading,
		_currIdx
	) => {
		computeDistance(prevReading, currReading);
		computeYMinYMax(survey, currReading);
	};

	// SIMPLIFIED READINGS - creates a smaller data set to be used for rendering polyline on map
	// most survey types will not have simplified readings
	// eslint-disable-next-line no-unused-vars
	const createSimplifiedReadings = ({ readings, subtype }) => undefined;

	const columns = [
		{
			label: 'ID',
			value: 'formattedStationId',
			checked: true,
			width: 64,
			required: true
		},
		{
			label: 'AC',
			value: 'ac',
			checked: true,
			width: 54,
			showInTooltip: true
		},
		{
			label: 'Warning',
			value: 'acWarning',
			checked: true,
			width: 108,
			showInTooltip: true
		},
		{
			label: 'Problem',
			value: 'acProblem',
			checked: true,
			width: 108,
			showInTooltip: true
		},
		{ label: 'DISTANCE', value: 'dst', checked: true, width: 80 },
		{
			label: 'LAT',
			value: 'lat',
			checked: true,
			width: 65,
			getCsvValue: r => {
				if (isDefined(r.lat)) {
					return r.lat;
				}
				if (isDefined(r.loc)) {
					return r.loc[0];
				}
				return undefined;
			}
		},
		{
			label: 'LNG',
			value: 'lng',
			checked: true,
			width: 70,
			getCsvValue: r => {
				if (isDefined(r.lng)) {
					return r.lng;
				}
				if (isDefined(r.loc)) {
					return r.loc[1];
				}
				return undefined;
			}
		},
		{
			label: 'COMMENTS',
			value: 'cmt',
			checked: true,
			width: 255,
			type: 'text'
		}
	];

	// MAIN
	const getReadingKeys = createGetReadingKeys(readingKeys);
	const getColumns = createGetColumns(columns);
	const transformToCsvJson = createTransformToCsvJson(getColumns);

	const meta = {
		...createBaseSurveyMeta(_survey),
		META_TYPE: 'AC',
		isChartInversed: INVERT_Y_AXIS,
		getReadingKeys,
		getReadingKeyProps: createGetReadingKeyProps(readingKeyProps),
		getColumns,
		transformToCsvJson,
		getSurveyColor: createGetSurveyColor(getReadingKeys),
		fkey: 'ac',
		isSkipReading,
		hasGap,
		interpolate,
		computeProps,
		computePropsForDerivedSurvey,
		computeYMinYMax,
		createSimplifiedReadings,
		tooltip: {
			enable: true,
			shared: false,
			displayMode: 'Always',
			header: '${point.x}',
			format: '${series.name}: ${point.y}' // eslint-disable-line no-template-curly-in-string
		}

		// , createTooltipRender
	};
	return meta;
};
