/* eslint-disable camelcase */
import { isDate } from 'aegion_common_utilities/lib/DateUtil';
import { MM_DD_YYYY } from 'aegion_common_utilities/lib/format/datetimeless';
import { mapToClientJobProps } from './job.server';
import { isHigherPrioritySurveyType, isOnOff, isDepol } from './survey';

const findMatchingDepolSurvey = (line, survey) => {
	const { jobId } = survey;
	const job = line.jobs.find(j => j.id === jobId);

	if (job) {
		const { surveys } = job;
		return surveys.find(s => isDepol(s));
	}

	return undefined;
};

export const getDefaultSurveys = line => {
	const jobs = line.jobs || [];
	let primarySurvey;
	let secondarySurvey;
	let mostRecentSurvey;

	jobs
		.filter(job => job.scanlineEnabled)
		.forEach(job => {
			const surveys = job.surveys || [];
			surveys.forEach(survey => {
				mostRecentSurvey = mostRecentSurvey || survey;
				if (survey && isHigherPrioritySurveyType(survey, primarySurvey)) {
					primarySurvey = survey;
					secondarySurvey = undefined;

					if (isOnOff(primarySurvey)) {
						secondarySurvey = findMatchingDepolSurvey(line, survey);
					}
				}
			});
		});

	return [primarySurvey, secondarySurvey, mostRecentSurvey];
};

const createJobCount = line => {
	const jobs = line.jobs || [];
	return jobs.length;
};

const createSurveyCount = (serverLine = {}) => {
	const { stats = {} } = serverLine;
	const { surveys = {} } = stats;
	const { total } = surveys;

	return total;
};

const createRecentSurveyCount = (serverLine = {}) => {
	const { stats = {} } = serverLine;
	const { surveys = {} } = stats;
	const { recent } = surveys;

	return recent;
};

const createServeyTypeList = line => {
	const jobs = line.jobs || [];

	const surveyTypes = jobs
		.filter(job => job.scanlineEnabled)
		.reduce((result, job) => {
			const surveys = job.surveys || [];
			const stypes = surveys.reduce((r2, survey) => {
				return survey && survey.type ? [...r2, survey.type] : r2;
			}, []);

			return [...result, ...stypes];
		}, []);

	return [...new Set(surveyTypes)].join(', ');
};

const getStartEndRange = survey => {
	const { start, end } = survey;
	const hasStartDate = isDate(start);
	const hasEndDate = isDate(end);

	if (hasStartDate && hasEndDate) {
		return `${MM_DD_YYYY(start)} - ${MM_DD_YYYY(end)}`;
	}
	if (hasStartDate) {
		return `${MM_DD_YYYY(start)} - TBD`;
	}
	if (hasEndDate) {
		return `unknown - ${MM_DD_YYYY(end)}`;
	}

	return '';
};

const formatMostRecentSurvey = mostRecentSurvey => {
	if (mostRecentSurvey) {
		return {
			...mostRecentSurvey,
			start: isDate(mostRecentSurvey.start)
				? MM_DD_YYYY(mostRecentSurvey.start)
				: undefined,
			end: isDate() ? MM_DD_YYYY(mostRecentSurvey.end) : undefined,
			startEndRange: getStartEndRange(mostRecentSurvey)
		};
	}
	return mostRecentSurvey;
};

const mapToClientTagProps = serverTag => {
	const { tag_name } = serverTag;

	return {
		name: tag_name
	};
};

const mapServerTagsToClientTags = (tags = []) => {
	return tags.map(tag => {
		return mapToClientTagProps(tag);
	});
};

export const mapToClientLineProps = serverLine => {
	const clientLine = {
		id: serverLine.id,
		internalId: serverLine.internal_id,
		name: serverLine.name,
		customer: serverLine.customer,
		jobs: (serverLine.jobs || []).map(job =>
			mapToClientJobProps(job, serverLine.id)
		),
		tags: mapServerTagsToClientTags(serverLine.tags || undefined) // null values break default param vals
	};

	const [primarySurvey, secondarySurvey, mostRecentSurvey] = getDefaultSurveys(
		clientLine
	);
	const defaultJobId = primarySurvey ? primarySurvey.jobId : undefined;

	const additionalProps = {
		defaultJobId,
		scanlineEnabled: !!defaultJobId,
		primarySurvey, // assumed CIS / ON_OFF
		secondarySurvey, // if primary is ON_OFF then secondary will be DEPOL, else undefined
		mostRecentSurvey: formatMostRecentSurvey(mostRecentSurvey),
		jobCount: createJobCount(clientLine),
		surveyCount: createSurveyCount(serverLine),
		recentSurveyCount: createRecentSurveyCount(serverLine),
		surveyTypesList: createServeyTypeList(clientLine),
		bcpercentage: serverLine.stats.surveys.bc_percentage,
		totalcount: serverLine.stats.surveys.total_count,
		bccount: serverLine.stats.surveys.bc_count
	};

	return {
		...clientLine,
		...additionalProps
	};
};
