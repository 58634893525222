import Sockette from 'sockette';
import getAipUrls from '../../../../config/getAipUrls';
import {
	selectWsMessage,
	isWsErrorMessage
} from '../../../../commons/util/aip-tools/messageUtil.ws';

const DOWNLOAD_ACTION = 'closure';

const isConnectionOpenedMessage = e => {
	const { data } = e;
	return data === 'Message Received';
};

const ignoreMessage = e => isConnectionOpenedMessage(e);

class ClosureFileDownloadWebSocket {
	constructor() {
		this.ws = undefined;
	}

	startDownload = (payload, handleResponse) => {
		if (this.ws) {
			window.alert('File download already in progress.');
			return;
		}

		const websocketURL = getAipUrls().scanlineReportV2WS;
		this.ws = new Sockette(websocketURL, {
			onopen: e => this._send(e, payload),
			onmessage: e => this._receiveMessage(e, handleResponse)
		});
	};

	destroy = () => {
		this._destroyWebSocket();
	};

	_send = (_e, { downloadType, extensionType, lineName, state }) => {
		const payload = {
			action: DOWNLOAD_ACTION,
			downloadType,
			extensionType,
			lineName,
			state
		};

		this.ws.send(JSON.stringify(payload));
	};

	_receiveMessage = (wsEvent, handleResponse) => {
		if (ignoreMessage(wsEvent)) {
			return;
		}

		const wsMessage = selectWsMessage(wsEvent);

		const error = isWsErrorMessage(wsMessage) ? wsMessage : undefined;
		const result = isWsErrorMessage(wsMessage) ? undefined : wsMessage;

		handleResponse(error, result);
		this._destroyWebSocket();
	};

	_destroyWebSocket = () => {
		if (this.ws) {
			this.ws.close();
			this.ws = undefined;
		}
	};
}

export default new ClosureFileDownloadWebSocket();
