// eslint-disable-next-line import/no-cycle
import { store } from '../../../scanline/store/index';

// eslint-disable-next-line import/no-cycle
import AutoCorrectionUtil from '../AutoCorrectionUtil';
import serializeError from '../../../commons/util/errors/serializeError';

const _getDataFromState = () => {
	const state = store.getState();
	const { cisview } = state;
	const { job } = cisview;
	const { data, globalData } = job;

	return { data, globalData };
};

const _getStorageEstimate = () => {
	try {
		return navigator.storage.estimate();
	} catch {
		return Promise.resolve();
	}
};

const sendAnalyticsEvent = async (indexedbCache, eventName, data = {}) => {
	const { data: datFiles, globalData } = _getDataFromState();

	const storageEstimate = await _getStorageEstimate();

	const { error, ...otherData } = data;

	const indexDbStats = indexedbCache && indexedbCache.getStats();

	const eventProperties = {
		indexDbStats,
		storageEstimate,
		...otherData
	};

	if (error) {
		eventProperties.error = serializeError(error);
		eventProperties.verboseStats = indexedbCache.getStats(true);
	}

	AutoCorrectionUtil.analyticsTrackMisc(
		eventName,
		{ globalData, datFiles },
		eventProperties
	);
};

export default sendAnalyticsEvent;
