import jQuery from 'jquery';

// eslint-disable-next-line import/no-cycle
import JobUtil from './JobUtil';
// eslint-disable-next-line import/no-cycle
import {
	setCacheJson,
	onUpdateSuccess,
	onUpdateError
} from '../Cache/JobCache';
// eslint-disable-next-line import/no-cycle
import { store } from '../../../scanline/store/index';
import { getContentLengthOfJson } from '../../../commons/util/misc';

// eslint-disable-next-line import/no-cycle
import { messageBoxActions } from '../../redux/actions';

import { FILE_INFO_KEY_BY_URL } from './JobUtil.constants';

// eslint-disable-next-line import/no-cycle
import {
	getUploadPolicySave,
	update,
	jobAjax,
	jobAjaxReadOnly
} from './JubUtil.api';

const getFileVersionKey = () => {
	return FILE_INFO_KEY_BY_URL.gpsreadingsurl;
};

const getErrorTextFromErrorObject = errorObject => {
	if (!errorObject) {
		return null;
	}
	if (errorObject.message) {
		return errorObject.message;
	}
	if ((errorObject.responseJSON || {}).error) {
		return errorObject.responseJSON.error;
	}

	if (errorObject.responseText) {
		return errorObject.responseText;
	}

	return errorObject;
};

const showErrorMessage = errorText => {
	const errorMessage = `Error When Setting Cache: ${errorText}`;

	store.dispatch(messageBoxActions.addMessageBox(errorMessage, 'danger'));
};

const getGpsReadingsUploadPolicy = (item, jobguid) => {
	return new Promise((res, rej) => {
		return getUploadPolicySave(
			`${item.fileName}_gpsReadings.json`,
			item.fileKey,
			jobguid,
			'dats',
			(err, policyData) => {
				if (err) {
					rej(err);
					return;
				}

				const updateInfo = {
					job: jobguid,
					updatedDate: new Date().toISOString(),
					dat: item.fileName
				};
				update(updateInfo, () => {
					return res(policyData);
				});
			}
		);
	});
};

const createFormDataForSave = (policyData, item, gpsreadings) => {
	const fd = new FormData();
	fd.append('key', policyData.key);
	fd.append('acl', 'private');
	fd.append('Content-Type', 'application/json');
	fd.append('AWSAccessKeyId', policyData.access_key);
	fd.append('policy', policyData.encoded_policy);
	fd.append('signature', policyData.signature);
	const blob = new Blob([JSON.stringify(gpsreadings, null, 2)], {
		type: 'application/json'
	});
	fd.append('file', blob, `${item.fileName}_gpsReadings.json`);

	return fd;
};

const uploadUsingJquery = (url, fd) => {
	return new Promise((res, rej) => {
		jQuery
			.ajax({
				url,
				type: 'POST',
				data: fd,
				processData: false,
				contentType: false
			})
			.done(response => {
				res(response);
			})
			.fail(response => {
				rej(response);
			});
	});
};

const uploadGpsReadings = (item, flattenReadings, jobguid) =>
	getGpsReadingsUploadPolicy(item, jobguid).then(policyData => {
		const fd = createFormDataForSave(policyData, item, flattenReadings);
		return uploadUsingJquery(policyData.upload_url, fd)
			.then(res => {
				return res;
			})
			.catch(e => {
				throw e;
			});
	});

const cleanUrl = url => {
	const uri = new URL(url);
	const urlRoot = url.replace(uri.search, '');
	// The url that we have is already encoded, we actually need to decode it as will automatically be encoded in lower-level ajax code
	const decodedURI = decodeURI(urlRoot);

	return decodedURI;
};

const fetchNewFileVersion = (url, oldFileVersion) => {
	const cleanedUrl = cleanUrl(url);
	return new Promise((res, rej) => {
		jobAjaxReadOnly(
			`/getNewFileVersion?fileVersion=${oldFileVersion}&url=${cleanedUrl}`,
			null,
			'GET',
			(err, response) => {
				if (err) {
					rej(err);
					return;
				}

				res(response);
			}
		);
	});
};

const updateDynamoDbMetaDataForFirstGPS = async (
	timeuuid,
	fileName,
	fileKey,
	flattenReadings
) => {
	const firstGPS = flattenReadings[0].coordinates;

	const gpsList = [firstGPS[0], firstGPS[1], firstGPS[2], firstGPS[3]];

	const updatedData = {
		job: timeuuid,
		attribute: 'firstGPS',
		value: gpsList,
		dat: fileName,
		fileKey
	};
	JobUtil.update(updatedData, error => {
		if (error) {
			console.log(error);
			// dispatch(displayDatSaveMessage(error, null, fileName));
		}
	});
};

const updateDynamoDbMetaDataForLastGPS = async (
	timeuuid,
	fileName,
	fileKey,
	flattenReadings
) => {
	const lastGPS = flattenReadings[flattenReadings.length - 1].coordinates;

	const gpsList = [lastGPS[0], lastGPS[1], lastGPS[2], lastGPS[3]];

	const updatedData = {
		job: timeuuid,
		attribute: 'lastGPS',
		value: gpsList,
		dat: fileName,
		fileKey
	};
	JobUtil.update(updatedData, error => {
		if (error) {
			console.log(error);
			// dispatch(displayDatSaveMessage(error, null, fileName));
		}
	});
};

const updateCacheWithNewFileVersion = async (
	url,
	oldFileVersion,
	flattenReadings,
	readings
) => {
	const {
		fileVersion: newFileVersion,
		lastModified
	} = await fetchNewFileVersion(url, oldFileVersion);

	const contentLength = getContentLengthOfJson(flattenReadings);

	await setCacheJson(
		url,
		readings,
		newFileVersion,
		parseInt(contentLength, 10),
		{
			updateReason: 'saveGpsReadings',
			oldFileVersion,
			lastModified,
			replace: true
		}
	);

	await onUpdateSuccess(
		oldFileVersion,
		lastModified,
		newFileVersion,
		contentLength
	);
};

export const postSaveGpsReadings = async (
	item,
	flattenReadingsFromProps,
	jobguid
) => {
	const flattenReadings = flattenReadingsFromProps || item.gpsreadings;
	let oldFileVersion;
	const url = item.gpsreadingsurl;

	try {
		oldFileVersion = item[getFileVersionKey()].fileVersionId;
		await updateDynamoDbMetaDataForFirstGPS(
			jobguid,
			item.datFile,
			item.fileKey,

			flattenReadings
		);
		await updateDynamoDbMetaDataForLastGPS(
			jobguid,
			item.datFile,
			item.fileKey,
			flattenReadings
		);
		await updateCacheWithNewFileVersion(
			url,
			oldFileVersion,
			flattenReadings,
			item.gpsreadings
		);
	} catch (e) {
		const errorText = getErrorTextFromErrorObject(e);
		showErrorMessage(errorText);
		onUpdateError(oldFileVersion, errorText);
	}
};

const saveGpsReadings = async (item, jobguid) => {
	// const nestedGpsReadings = ReadingUtil.nestGpsReadings(item.gpsreadings);
	await uploadGpsReadings(item, item.gpsreadings, jobguid);

	await postSaveGpsReadings(item, item.gpsreadings, jobguid);
};

export default saveGpsReadings;
