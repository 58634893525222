const getStationId = readingObject => {
	// stationId comes from "_transformReading" (when we "convert readings")
	// TODO: We may want to specify which stationId field we want to pull from
	const { StationId, stationId } = readingObject;

	if (stationId !== undefined) {
		return stationId;
	}
	if (StationId !== undefined) {
		return StationId;
	}

	return 0;
};

export const binarySearchReadingsWithIndexes = (
	readings,
	stationIdToFind,
	options = {}
) => {
	let { startIndex: left = 0, endIndex: right = readings.length - 1 } = options;

	if (!readings) {
		return null;
	}

	let pivotIndex = null;

	while (left <= right) {
		pivotIndex = Math.floor((left + right) / 2);
		const readingValue = readings[pivotIndex].value || readings[pivotIndex];
		const station = getStationId(readingValue);

		if (station === stationIdToFind) {
			return { reading: readings[pivotIndex], index: pivotIndex };
		}

		if (stationIdToFind < station) {
			right = pivotIndex - 1;
		} else {
			left = pivotIndex + 1;
		}
	}

	return null;
};

const countReadingsInDatFile = datFile => {
	const { gpsreadings = [], readingsCount } = datFile;
	return gpsreadings.length || readingsCount || 0;
};

const countReadingsInDatFiles = datFiles => {
	if (datFiles.length === 0) {
		return 0;
	}

	return datFiles.reduce(
		(count, datFile) => count + countReadingsInDatFile(datFile),
		0
	);
};

export const getStartEndIndexesFromDatFiles = (datFiles, datFileIndex) => {
	const datFile = datFiles[datFileIndex];

	const previousDatFiles = datFiles.slice(0, datFileIndex);

	const startIndex = countReadingsInDatFiles(previousDatFiles);

	const readingsCount = countReadingsInDatFile(datFile);
	const endIndex = startIndex + readingsCount - 1;

	return { startIndex, endIndex };
};

export const binarySearchWrapper = (
	readings,
	stationId,
	datFiles = [],
	datFileIndex
) => {
	if (!datFiles.length || datFileIndex === undefined) {
		return binarySearchReadingsWithIndexes(readings, stationId);
	}

	const { startIndex, endIndex } = getStartEndIndexesFromDatFiles(
		datFiles,
		datFileIndex
	);

	return binarySearchReadingsWithIndexes(readings, stationId, {
		startIndex,
		endIndex
	});
};

export const binarySearchReadings = (
	readings,
	stationId,
	datFiles,
	datFileIndex
) => {
	const results = binarySearchWrapper(
		readings,
		stationId,
		datFiles,
		datFileIndex
	);

	if (!results) {
		return null;
	}

	return results.reading;
};

export const binarySearchIndex = (
	readings,
	stationId,
	datFiles,
	datFileIndex
) => {
	const results = binarySearchWrapper(
		readings,
		stationId,
		datFiles,
		datFileIndex
	);

	if (!results) {
		return null;
	}

	return results.index;
};
