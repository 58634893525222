import { constants as scanlineConstants } from './scanline/constants';
import { PARSERS as scanlineParsers } from './scanline/parsers';

import { constants as fieldlineConstants } from './fieldline/constants';
import { PARSERS as fieldlineParsers } from './fieldline/parsers';

const getMetaByApp = (appName = 'scanline') => {
	switch (appName) {
		case 'fieldline':
			return {
				constants: fieldlineConstants,
				parsers: fieldlineParsers
			};
		default:
			return {
				constants: scanlineConstants,
				parsers: scanlineParsers
			};
	}
};

export default getMetaByApp;
