/* Most of these functions are ingested by a worker */

// Taken from https://github.com/bvaughn/react-virtualized/blob/bd71003d9bcc087ae76f13ed6ec10988f25a023d/source/Table/SortDirection.js
const SortDirection = {
	/**
	 * Sort items in ascending order.
	 * This means arranging from the lowest value to the highest (e.g. a-z, 0-9).
	 */
	ASC: 'ASC',

	/**
	 * Sort items in descending order.
	 * This means arranging from the highest value to the lowest (e.g. z-a, 9-0).
	 */
	DESC: 'DESC'
};

// There are three states that sort can be, in this order: null, asc, desc
const getNewSortDirection = sortDirection => {
	switch (sortDirection) {
		case SortDirection.ASC:
			return SortDirection.DESC;
		case SortDirection.DESC:
			return null;
		case null:
		default:
			return SortDirection.ASC;
	}
};

export const compareReading = (reading1, reading2)=> reading1.stationId === reading2.stationId
 && reading1.datIndex === reading2.datIndex && reading1.gpsIndex === reading2.gpsIndex;

// TODO future enhancements for filter + skip screen
export default {
	SortDirection,
	getNewSortDirection
};
