import { createActions } from 'redux-actions';
import {
	ACTIONS_NAME_SPACE,
	MESSAGE_KEY_NO_MESSAGE,
	MESSAGE_KEY_IS_READING_FILE,
	ERROR_KEY_UNSUPPORTED_FILE_TYPE
} from './constants';
import { getErrorMessage, isSupportedFileExtension } from './util';
import { parseFileName } from '../../../util.file';

const {
	setFileMeta,
	setFileContent,
	setFileDocJson,
	setFileErrors,
	setFileProcessingMessage,
	clearFile,
	clearAllFiles
} = createActions(
	{
		SET_FILE_META: (key, meta) => ({ key, meta }),
		SET_FILE_CONTENT: (key, content) => ({ key, content }),
		SET_FILE_DOC_JSON: (key, docJson) => ({ key, docJson }),
		SET_FILE_ERRORS: (key, errors) => ({ key, errors }),
		SET_FILE_PROCESSING_MESSAGE: (key, processingMessageKey) => ({
			key,
			processingMessageKey
		}),
		CLEAR_FILE: key => ({ key }),
		CLEAR_ALL_FILES: () => ({})
	},
	{ prefix: ACTIONS_NAME_SPACE }
);

const thunkSetUploadedFile = (key, file) => dispatch => {
	dispatch(setFileErrors(key, undefined));

	if (!file) {
		dispatch(clearFile(key));
	} else {
		const { name, ext } = parseFileName(file.name);
		const meta = {
			name,
			ext,
			size: file.size / 1000 / 1000,
			lastModified: file.lastModified,
			type: file.type
		};
		dispatch(setFileMeta(key, meta));

		if (!isSupportedFileExtension(ext)) {
			const message = getErrorMessage(ERROR_KEY_UNSUPPORTED_FILE_TYPE);
			dispatch(clearFile(key));
			dispatch(setFileErrors(key, [message]));
		} else {
			const reader = new FileReader();
			dispatch(setFileProcessingMessage(key, MESSAGE_KEY_IS_READING_FILE));
			reader.onload = e => {
				const content = e.target.result;
				dispatch(setFileContent(key, content));
				dispatch(setFileProcessingMessage(key, MESSAGE_KEY_NO_MESSAGE));
			};

			reader.readAsText(file);
		}
	}
};

export {
	setFileMeta,
	setFileContent,
	setFileDocJson,
	setFileErrors,
	setFileProcessingMessage,
	clearFile,
	clearAllFiles,
	thunkSetUploadedFile
};
