/* eslint-disable import/no-cycle */
import { createPayloadMetaAction as cpma } from '../../../scanline/redux/util';

import avActions from '../../actions/actions';

import {
	SET_VIEWPORT,
	SET_ASSET,
	SET_PIN,
	SET_CLUSTER,
	SET_MAP,
	SET_IS_GRID_ENABLED,
	SET_WIDTH,
	SET_EXTENT,
	SET_GROUP_LAYER_UPDATING,
	SET_ASSET_LAYER_UPDATING,
	SET_HEATMAP_LAYER_UPDATING
} from './actionTypes';

const acSetViewport = cpma(SET_VIEWPORT);
const acSetExtent = cpma(SET_EXTENT);
const acSetAsset = cpma(SET_ASSET);
const acSetPin = cpma(SET_PIN);

export const setMap = map => dispatch => {
	dispatch({
		type: SET_MAP,
		payload: map
	});
};

export const setViewport = (payload, isInitial) => (d, getState) => {
	const {
		assetview: {
			map: { map }
		}
	} = getState();
	if (isInitial) {
		map.leafletElement.setView(payload.center, payload.zoom);
	}
	const extent = map.leafletElement.getBounds();
	if (
		extent.getNorth() !== extent.getSouth() &&
		extent.getEast() !== extent.getWest()
	) {
		d(acSetExtent(extent));
	}
	if (payload.center) {
		d(acSetViewport(payload));
	}
};

export const setPin = payload => d => {
	if (payload.zoomTo) {
		d(setViewport(payload));
	}
	d(acSetPin(payload));
};

export const setAsset = payload => d => {
	d(acSetAsset(payload));
};

export const setCluster = selected => {
	return {
		type: SET_CLUSTER,
		payload: selected
	};
};

export const setIsGridEnabled = selected => {
	return {
		type: SET_IS_GRID_ENABLED,
		payload: selected
	};
};

export const setWidth = () => (dispatch, getState) => {
	const {
		assetview: {
			map: { map }
		}
	} = getState();
	const size = map.leafletElement.getSize();
	dispatch({ type: SET_WIDTH, payload: size.x });
};

export const assetGroupClick = field => dispatch => {
	dispatch(avActions.filterSectionChanged('Field/Area', field));
};

export const setGroupLayerUpdating = updating => {
	return { type: SET_GROUP_LAYER_UPDATING, payload: updating };
};

export const setAssetLayerUpdating = updating => {
	return { type: SET_ASSET_LAYER_UPDATING, payload: updating };
};

export const setHeatmapLayerUpdating = updating => {
	return { type: SET_HEATMAP_LAYER_UPDATING, payload: updating };
};
