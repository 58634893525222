import {
	HIDE_EXPORT_PANEL,
	SHOW_EXPORT_PANEL,
	SET_PROFILE_NAME,
	SET_SELECTED,
	SET_PROFILE,
	RESET_RECTIFIERS_REPORTS,
	ADD_RECTIFIERS_REPORT,
	UPDATE_RECTIFIERS_REPORT
} from './actionTypes';

const initialState = {
	showExportPanel: false,
	profiles: [],
	selected: 'New Profile',
	profile: {},
	rectifiersReports: []
};

function updateRectifiersReport(rectifiersReports, url, index) {
	const newRectifiersReports = [...rectifiersReports];
	newRectifiersReports.splice(index, 1, { url });
	return newRectifiersReports;
}

const reducer = (state = initialState, action) => {
	const { type, payload } = action;
	switch (type) {
		case SHOW_EXPORT_PANEL:
			return { ...state, showExportPanel: true };
		case HIDE_EXPORT_PANEL:
			return { ...state, showExportPanel: false };
		case SET_PROFILE_NAME:
			return { ...state, profiles: payload };
		case SET_SELECTED:
			return { ...state, selected: payload };
		case SET_PROFILE:
			return { ...state, profile: payload };
		case RESET_RECTIFIERS_REPORTS:
			return { ...state, rectifiersReports: [] };
		case ADD_RECTIFIERS_REPORT:
			return { ...state, rectifiersReports: [...state.rectifiersReports, {}] };
		case UPDATE_RECTIFIERS_REPORT:
			return {
				...state,
				rectifiersReports: updateRectifiersReport(
					state.rectifiersReports,
					payload.url,
					payload.index
				)
			};
		default:
			return state;
	}
};

export default reducer;
