import { NAME_SPACE as ns } from './constants';

export const XLS_UNDO = `${ns}_COLUMNS_UNDO`;

export const XLS_REDO = `${ns}_XLS_REDO`;

export const XLS_CLEAR_HISTORY = `${ns}_XLS_CLEAR_HISTORY`;

export const CORR_REDO = `${ns}_CORR_REDO`;

export const CORR_UNDO = `${ns}_CORR_UNDO`;

export const CORR_CLEAR_HISTORY = `${ns}_XLS_CLEAR_HISTORY`;

export const MAN_REDO = `${ns}_MAN_REDO`;

export const MAN_UNDO = `${ns}_MAN_UNDO`;

export const MAN_CLEAR_HISTORY = `${ns}_XLS_CLEAR_HISTORY`;

export const SET_COLUMNS = `${ns}_SET_COLUMNS`;

export const SET_IS_HIDE_COLUMNS = `${ns}_SET_IS_HIDE_COLUMNS`;

export const SET_USER_HIDDEN_COLUMNS = `${ns}_SET_USER_HIDDEN_COLUMNS`;
