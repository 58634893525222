/* eslint-disable import/prefer-default-export */
import { createSelector } from 'reselect';
import { selectSliceCisview } from './HELPERS';

export const JSON_TYPE_GPSREADINGS = 'gpsReadings';
export const JSON_TYPE_PREPROCESSEDGPSREADINGS = 'preprocessedGPSReadings';

export const JSON_TYPES = [
	JSON_TYPE_GPSREADINGS,
	JSON_TYPE_PREPROCESSEDGPSREADINGS
];

export const mapJsonTypeToStateProp = {
	[JSON_TYPE_GPSREADINGS]: 'gpsreadings',
	[JSON_TYPE_PREPROCESSEDGPSREADINGS]: JSON_TYPE_PREPROCESSEDGPSREADINGS
};

const EMPTY_OBJECT = {};

export const selectSliceJob = state => {
	const sliceCisview = selectSliceCisview(state);
	const { job } = sliceCisview;
	return job;
};

export const selectGlobalData = state => {
	const sliceJob = selectSliceJob(state);
	const { globalData } = sliceJob;
	return globalData;
};

export const selectS3Data = state => {
	const sliceJob = selectSliceJob(state);
	const { data } = sliceJob;
	return data;
};

export const selectGlobalDataAndS3Data = createSelector(
	selectGlobalData,
	selectS3Data,
	(globalData, data) => ({ globalData, data })
);

export const selectJobId = state => {
	const globalData = selectGlobalData(state);
	const { timeuuid } = globalData;
	return timeuuid;
};

export const selectMasterLST = state => {
	const globalData = selectGlobalData(state);
	const { MasterLST } = globalData;
	return MasterLST;
};

export const selectSegmentState = (state, segmentFileName) => {
	const MasterLST = selectMasterLST(state);
	return MasterLST[segmentFileName] || EMPTY_OBJECT;
};

export const selectIsJsonLoaded = (state, segmentFileName, jsonType) => {
	const segmentState = selectSegmentState(state, segmentFileName);
	const prop = mapJsonTypeToStateProp[jsonType];
	return !!segmentState[prop];
};

export const selectIsUnloaded = (state, segmentFileName) =>
	JSON_TYPES.every(type => !selectIsJsonLoaded(state, segmentFileName, type));

export const selectIsFullyLoaded = (state, segmentFileName) =>
	JSON_TYPES.every(type => selectIsJsonLoaded(state, segmentFileName, type));

export const selectIsPartiallyLoaded = (state, segmentFileName) =>
	!selectIsFullyLoaded(state, segmentFileName) &&
	!!JSON_TYPES.find(type => selectIsJsonLoaded(state, segmentFileName, type));
