/* eslint-disable import/prefer-default-export */
/* eslint-disable import/no-cycle */
import { createActions } from 'redux-actions';
import { ROOT_SLICE } from './constants';
import {
	KEY_COMPANY,
	KEY_TEXT_SEARCH,
	updateFilter
} from '../../../util/filters';

const { setSearchText, setCompanyName } = createActions(
	{
		SET_SEARCH_TEXT: searchText => ({ searchText }),
		SET_COMPANY_NAME: companyName => ({
			companyName
		})
	},
	{ prefix: ROOT_SLICE }
);

const thunkSetSearchText = searchText => dispatch => {
	updateFilter(KEY_TEXT_SEARCH, searchText);
	dispatch(setSearchText(searchText));
};

const thunkSetCompanyName = companyName => dispatch => {
	updateFilter(KEY_COMPANY, companyName);
	dispatch(setCompanyName(companyName));
};

export {
	setSearchText,
	setCompanyName,
	thunkSetSearchText,
	thunkSetCompanyName
};
