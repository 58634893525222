/* eslint import/no-cycle:0 */
import loadImageData from '../../data/loadImages';
import videoData from '../../data/videos';
import {
	setMapTrackerImage,
	setCenter,
	findSelectedManholes,
	findSelectedSewerLine
} from './maps';

export const NS_MAIN = 'videoline_main_';

export const SET_IMAGES = `${NS_MAIN}SET_IMAGES`;
const setImages = images => ({
	type: SET_IMAGES,
	payload: images
});

export const SET_SELECTED_IMAGE = `${NS_MAIN}SET_SELECTED_IMAGE`;
const setSelectedImage = (image, nextImage) => ({
	type: SET_SELECTED_IMAGE,
	payload: { selectedImage: image, nextImage }
});

export const SET_NEW_VIDEO_TIME = `${NS_MAIN}SET_NEW_VIDEO_TIME`;
export const setNewVideoTime = time => ({
	type: SET_NEW_VIDEO_TIME,
	payload: time
});

export const SET_VIDEOS = `${NS_MAIN}SET_VIDEOS`;
const setVideos = videos => ({
	type: SET_VIDEOS,
	payload: videos
});

export const SET_VIDEO = `${NS_MAIN}SET_VIDEO`;
const setVideo = video => ({
	type: SET_VIDEO,
	payload: video
});

export const carouselChanged = image => (dispatch, getState) => {
	const state = getState();
	const {
		videoline: {
			main: { images }
		}
	} = state;
	const idx = images.indexOf(image);
	const nextImage = images[idx + 1];
	if (image) {
		dispatch(setSelectedImage(image, nextImage));
		dispatch(setMapTrackerImage(image));
	}
};

export const selectVideo = video => (dispatch, getState) => {
	const images = loadImageData(video.imageIndex);
	dispatch(setVideo({ video, images }));
	dispatch(setCenter(video.center));

	const state = getState();
	const {
		videoline: { maps }
	} = state;

	const { manholes: geojson } = maps;
	if (geojson) {
		dispatch(findSelectedManholes());
		dispatch(findSelectedSewerLine());
	}
	// dispatch(setCenter(video.center));
};

export const loadVideos = () => dispatch => {
	const videos = videoData();
	dispatch(setVideos(videos));
	const defaultVideo = videos[1];
	dispatch(selectVideo(defaultVideo));

	const images = loadImageData(defaultVideo.imageIndex);
	dispatch(setImages(images));
	const [firstImage] = images;
	dispatch(carouselChanged(firstImage));
};
