export const initialState = {
	reports: [],
	totalCount: undefined,
	loadingCount: undefined,
	loadedCount: undefined,
	isLoadingReports: false,
	isLoadedReports: false,
	loadReportsErrorMessage: undefined,
	textSearch: undefined
};

export default { initialState };
