import { SET_USER_HIDDEN_COLUMNS, SET_USER_APP_SETTINGS } from './actionTypes';

const initialState = {
	userHiddenColumns: undefined,
	userAppSettings: undefined
};

const reducer = (state = initialState, action) => {
	const { type, payload } = action;
	switch (type) {
		case SET_USER_HIDDEN_COLUMNS:
			return { ...state, userHiddenColumns: payload };
		case SET_USER_APP_SETTINGS:
			return { ...state, userAppSettings: payload };
		default:
			return state;
	}
};

export default reducer;
