import { MiscUtils } from 'aegion_common_utilities/';
import { getUserName, hasCognitoGroupName } from '../../util/user';
// eslint-disable-next-line import/no-cycle
import { SPIKE_EDITOR_MAGIC_WAND_BETA_TESTERS } from './userSettings';
import { AWS_GROUP_NAMES } from '../../util/permissions/constants';

export const isSpikeEditorMagicButtonBetaTester = () => {
	return SPIKE_EDITOR_MAGIC_WAND_BETA_TESTERS.includes(getUserName());
};

export const canUseSpikeEditorMagicButton = () => {
	if (!MiscUtils.isProd()) {
		return true;
	}

	// Users who can use the magic button in prod
	return isSpikeEditorMagicButtonBetaTester();
};

export const canUseStationAlignmentTool = () =>
	[AWS_GROUP_NAMES.AEGION_DATAPROCESSOR].some(gn => hasCognitoGroupName(gn));
