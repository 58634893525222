/* eslint-disable import/no-cycle */
import ajax from '../../../../scanline/utils/ajax';
import { hasAccessToken } from '../../../../scanline/utils/token';

// const isDefined = v => v !== undefined && v !== null;

export const listItems = requestPayload =>
	new Promise((resolve, reject) => {
		const url = `/getCompletedSurveys`;
		if (hasAccessToken()) {
			const handleHttpResponse = (err, res) => {
				if (err) {
					reject(err);
				} else {
					resolve(res);
				}
			};

			ajax(
				url,
				requestPayload,
				'POST',
				handleHttpResponse,
				undefined,
				'dashboard'
			);
		}
	});

const _exports = {
	listItems
};

export default {
	..._exports
};
