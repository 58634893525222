/* eslint-disable no-use-before-define */
import {
	SET_FILTERS,
	ADD_FILTER_TO_LIST,
	REMOVE_FILTER_FROM_LIST,
	REMOVE_ALL_FILTERS_FROM_LIST,
	ADD_HIDDEN_FILTER_TO_LIST,
	REMOVE_HIDDEN_FILTER_FROM_LIST,
	REMOVE_ALL_HIDDEN_FILTERS_FROM_LIST,
	SET_SEARCH_TERM,
	RESET_SEARCH,
	SEARCH_FILTER_SET_TOKEN,
	SEARCH_FILTER_START,
	SEARCH_FILTER_SUCCESS,
	SEARCH_FILTER_FAIL
} from './actionTypes';

const initialState = {
	filters: [],
	hiddenFilters: [], // to be used with toggles - ex: My Jobs toggle - results in filters that are not visible in filter list.
	searchTerm: '',
	searchRequestToken: undefined,
	filtersMatchingSearch: [],
	isSearchingFilters: false,
	searchFiltersFailed: false,
	hideIsSearchingFilters: false,
	hideEmptyResultsMessage: true
};

const projectFilters = (state = initialState, action) => {
	const { type, payload } = action;
	const { filters, hiddenFilters } = state;

	switch (type) {
		case SET_FILTERS:
			return {
				...state,
				filters: payload || []
			};

		case ADD_FILTER_TO_LIST:
			return {
				...state,
				filters:
					isNonEmptyString(payload) && !hasFilter(payload, filters)
						? [...filters, payload]
						: filters
			};

		case REMOVE_FILTER_FROM_LIST:
			return {
				...state,
				filters: !hasFilter(payload, filters)
					? filters
					: filters.filter(f => f !== payload)
			};

		case REMOVE_ALL_FILTERS_FROM_LIST:
			return {
				...state,
				filters: []
			};

		case ADD_HIDDEN_FILTER_TO_LIST:
			return {
				...state,
				hiddenFilters:
					isNonEmptyString(payload) && !hasFilter(payload, hiddenFilters)
						? [...hiddenFilters, payload]
						: hiddenFilters
			};

		case REMOVE_HIDDEN_FILTER_FROM_LIST:
			return {
				...state,
				hiddenFilters: !hasFilter(payload, hiddenFilters)
					? hiddenFilters
					: hiddenFilters.filter(f => f !== payload)
			};

		case REMOVE_ALL_HIDDEN_FILTERS_FROM_LIST:
			return {
				...state,
				hiddenFilters: []
			};

		case SET_SEARCH_TERM:
			return {
				...state,
				searchTerm: payload,
				filtersMatchingSearch: [],
				hideEmptyResultsMessage: !isString(payload) || payload.trim().length < 3
			};

		case RESET_SEARCH:
			return {
				...state,
				isSearchingFilters: false,
				searchFiltersFailed: false,
				hideEmptyResultsMessage: true,
				filtersMatchingSearch: [],
				searchTerm: ''
			};

		case SEARCH_FILTER_SET_TOKEN:
			return {
				...state,
				searchRequestToken: payload
			};

		case SEARCH_FILTER_START:
			return {
				...state,
				isSearchingFilters: true,
				searchFiltersFailed: false
			};

		case SEARCH_FILTER_SUCCESS:
			return {
				...state,
				isSearchingFilters: false,
				filtersMatchingSearch: payload
			};

		case SEARCH_FILTER_FAIL:
			return {
				...state,
				isSearchingFilters: false,
				searchFiltersFailed: true
			};

		default:
			return state;
	}
};

const isString = val => typeof val === 'string';

const isNonEmptyString = val => isString(val) && val.trim().length > 0;

const hasFilter = (filter, filters) => filters.indexOf(filter) > -1;

export default projectFilters;
