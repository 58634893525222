/* eslint-disable import/prefer-default-export */
const DELIMITER = '~';

const isDefined = v => v !== undefined && v !== null;

const parseValue = v => {
	if (v === undefined) {
		return 'undefined';
	}

	if (v === null) {
		return 'null';
	}

	if (v === true) {
		return 'true';
	}

	if (v === false) {
		return 'false';
	}

	return v;
};

const isValidBaseKey = baseKey => {
	return typeof baseKey === 'string';
};

const isValidQualifiers = qualifiers => {
	if (!isDefined(qualifiers)) {
		return false;
	}

	if (typeof qualifiers !== 'object' || Array.isArray(qualifiers)) {
		return false;
	}

	const keys = Object.keys(qualifiers);
	return (
		keys.length === 0 ||
		!isDefined(
			keys.find(
				k => isDefined(qualifiers[k]) && typeof qualifiers[k] === 'object'
			)
		)
	);
};

export const generateBoundaryKey = (baseKey, qualifiers = {}) => {
	if (!isValidBaseKey(baseKey)) {
		throw Error(
			'parameter baseKey for function generateBoundaryKey must be a string.'
		);
	}

	if (!isValidQualifiers(qualifiers)) {
		throw Error(
			'parameter qualifiers for function generateBoundaryKey must be a flat json object containing only primitive data types as properties.'
		);
	}

	const qualifierParts = Object.keys(qualifiers)
		.sort()
		.reduce((acc, k) => {
			const key = k.toLowerCase();
			const value = parseValue(qualifiers[k])
				.toString()
				.toLowerCase();
			acc.push(`${key}:${value}`);
			return acc;
		}, []);
	return [baseKey, ...qualifierParts].join(DELIMITER);
};
