export const createSurveyExternalIds = (surveys = []) =>
	surveys.map(s => s.survey_guid);

export const createS3BySurveyExternalIdsMap = (surveyExternalIds = []) =>
	surveyExternalIds.reduce((acc, surveyExternalId) => {
		return {
			...acc,
			[surveyExternalId]: []
		};
	}, {});
