"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.deleteSingleReading = exports.deleteSequentialReadings = exports["default"] = void 0;

var _MapProcessingUtil = require("../../MapProcessingUtil");

function ownKeys(object, enumerableOnly) {
  var keys = Object.keys(object);

  if (Object.getOwnPropertySymbols) {
    var symbols = Object.getOwnPropertySymbols(object);
    enumerableOnly && (symbols = symbols.filter(function (sym) {
      return Object.getOwnPropertyDescriptor(object, sym).enumerable;
    })), keys.push.apply(keys, symbols);
  }

  return keys;
}

function _objectSpread(target) {
  for (var i = 1; i < arguments.length; i++) {
    var source = null != arguments[i] ? arguments[i] : {};
    i % 2 ? ownKeys(Object(source), !0).forEach(function (key) {
      _defineProperty(target, key, source[key]);
    }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) {
      Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key));
    });
  }

  return target;
}

function _defineProperty(obj, key, value) {
  if (key in obj) {
    Object.defineProperty(obj, key, {
      value: value,
      enumerable: true,
      configurable: true,
      writable: true
    });
  } else {
    obj[key] = value;
  }

  return obj;
}

function _getNewReadingsFromDeleteReadings(datFile, readingIndex, numberOfReadings) {
  var interval = datFile.interval,
      gpsreadings = datFile.gpsreadings;
  var readingFoundAndDeleted = false;
  var newGpsReadings = [];
  gpsreadings.forEach(function (gpsreading, i) {
    var StationId = gpsreading.StationId;
    var newStationId = StationId;

    if (readingIndex === i) {
      readingFoundAndDeleted = true;
      return;
    }

    if (readingFoundAndDeleted && readingIndex + (numberOfReadings - 1) >= i) {
      readingFoundAndDeleted = true;
      return;
    }

    if (readingFoundAndDeleted || i > readingIndex) {
      newStationId = StationId - numberOfReadings * interval;
    }

    newGpsReadings.push( // eslint-disable-next-line prefer-object-spread
    Object.assign({}, gpsreading, {
      StationId: newStationId
    })
    /* {
    ...gpsreading,
    StationId: newStationId
    } */
    );
    readingFoundAndDeleted = false;
  });
  (0, _MapProcessingUtil.updateGPSMeasurements)(newGpsReadings, {
    isUpdateInterpolatedCoords: true,
    index: readingIndex
  });
  return newGpsReadings;
}

var deleteSequentialReadings = function deleteSequentialReadings(globalData, data, datIndex, numberOfReadings, readingIndex) {
  var datFile = data[datIndex];
  var fileName = datFile.fileName,
      originalEndStn = datFile.endStn,
      originalCalcEnd = datFile.calcEnd,
      interval = datFile.interval,
      originalReadingsCount = datFile.readingsCount;
  var endStn = originalEndStn - numberOfReadings * interval;
  var calcEnd = originalCalcEnd - numberOfReadings * interval;
  var readingsCount = originalReadingsCount - numberOfReadings;
  var timeuuid = globalData.timeuuid;

  var newDatFile = _objectSpread(_objectSpread({}, datFile), {}, {
    gpsreadings: _getNewReadingsFromDeleteReadings(datFile, readingIndex, numberOfReadings),
    endStn: endStn,
    calcEnd: calcEnd,
    readingsCount: readingsCount,
    isDeleted: true
  }); // Inject edited dat file into dat files


  var newData = data.map(function (dat, i) {
    if (i !== datIndex) {
      return dat;
    }

    return newDatFile;
  });

  var newGlobalData = _objectSpread(_objectSpread({}, globalData), {}, {
    MasterLST: _objectSpread(_objectSpread({}, globalData.MasterLST), {}, _defineProperty({}, fileName, newDatFile))
  });

  var update = {
    job: timeuuid,
    readingsCount: readingsCount,
    calcEnd: calcEnd,
    endStn: endStn,
    dat: fileName
  };
  return [newData, newGlobalData, update];
};

exports.deleteSequentialReadings = deleteSequentialReadings;

var deleteSingleReading = function deleteSingleReading(globalData, data, datIndex, gpsReadingIndex) {
  return deleteSequentialReadings(globalData, data, datIndex, 1, gpsReadingIndex);
};

exports.deleteSingleReading = deleteSingleReading;
var _default = deleteSequentialReadings;
exports["default"] = _default;