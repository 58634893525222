/* eslint-disable import/prefer-default-export */
const EMPTY_OBJECT = {};

const isRootState = state => {
	const keyMap = Object.keys(state).reduce((acc, k) => {
		acc[k] = true;
		return acc;
	}, {});

	const checkForKeys = ['assetview', 'cisview'];
	return checkForKeys.every(k => keyMap[k]);
};

export const selectSliceCisview = (state = {}) => {
	if (isRootState(state)) {
		const { cisview = EMPTY_OBJECT } = state;
		return cisview;
	}

	// assume we have cisview slice
	return state;
};
