// eslint-disable-next-line import/no-cycle
import { SUBMIT_JOB, SET_ADMIN_DATA, SEARCH_JOB } from '../actions/admin';

const defaultState = {
	adminData: {
		data: {}
	}
};

const reducer = (state = defaultState, action) => {
	const { type, payload } = action;
	switch (type) {
		case SET_ADMIN_DATA:
			return { ...state, adminData: payload.adminData, data: payload.data };

		case SUBMIT_JOB:
			return {
				...state
			};
		case SEARCH_JOB:
			return {
				...state,
				adminData: {
					...(state.adminData || {}),
					data: payload.data
				}
			};
		default:
			return state;
	}
};

export default reducer;
