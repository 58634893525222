/* eslint-disable import/prefer-default-export */
/* eslint-disable import/no-cycle */
import { createActions } from 'redux-actions';
import { ROOT_SLICE } from '../constants';

const {
	setUsers,
	setLoadMeta,
	setHasLoadingErrorUsers,
	setSelectedUser,
	setSelectedUserChangesClone,
	setIsSavingUser,
	reset,
	setNewUser,
	setIsSavingNewUser,
	setErrorMessageSaveNewUser,
	setIsSavingEditedUser,
	setErrorMessageSaveEditedUser,
	setIsDeletingUser,
	setErrorMessageDeleteUser,
	setShowConfirmation,
	setConfirmationMessageKey
} = createActions(
	{
		SET_USERS: users => ({ users }),
		SET_LOAD_META: ({ isLoadingUsers, isLoadedUsers, userTotalCount }) => ({
			isLoadingUsers,
			isLoadedUsers,
			userTotalCount
		}),
		SET_HAS_LOADING_ERROR_USERS: hasError => ({ hasError }),
		SET_SELECTED_USER: user => ({ user }),
		SET_SELECTED_USER_CHANGES_CLONE: clone => ({ clone }),
		SET_IS_SAVING_USER: isSaving => ({ isSaving }),
		RESET: () => ({}),
		SET_NEW_USER: newUser => ({ newUser }),
		SET_IS_SAVING_NEW_USER: isSavingNewUser => ({ isSavingNewUser }),
		SET_ERROR_MESSAGE_SAVE_NEW_USER: errorMessageSaveNewUser => ({
			errorMessageSaveNewUser
		}),
		SET_IS_SAVING_EDITED_USER: isSavingEditedUser => ({ isSavingEditedUser }),
		SET_ERROR_MESSAGE_SAVE_EDITED_USER: errorMessageSaveEditedUser => ({
			errorMessageSaveEditedUser
		}),
		SET_IS_DELETING_USER: isDeletingUser => ({
			isDeletingUser
		}),
		SET_ERROR_MESSAGE_DELETE_USER: errorMessageDeleteUser => ({
			errorMessageDeleteUser
		}),
		SET_SHOW_CONFIRMATION: showConfirmation => {
			return { showConfirmation };
		},
		SET_CONFIRMATION_MESSAGE_KEY: confirmationMessageKey => {
			return { confirmationMessageKey };
		}
	},
	{ prefix: ROOT_SLICE }
);

export {
	setUsers,
	setLoadMeta,
	setHasLoadingErrorUsers,
	setSelectedUser,
	setSelectedUserChangesClone,
	setIsSavingUser,
	reset,
	setNewUser,
	setIsSavingNewUser,
	setErrorMessageSaveNewUser,
	setIsSavingEditedUser,
	setErrorMessageSaveEditedUser,
	setIsDeletingUser,
	setErrorMessageDeleteUser,
	setShowConfirmation,
	setConfirmationMessageKey
};
