/* eslint-disable no-confusing-arrow */
const isDefined = v => v !== undefined && v !== null;

export const filterOutBadReadings = (readings = []) =>
	readings.filter(r => r.id != null && r.loc);

export const isTrueReading = reading =>
	!!reading && !reading.isGap && !reading.isFauxDataPoint;

export const isGap = reading => reading && reading.isGap;

export const cloneReading = reading => {
	if (!reading) {
		return reading;
	}

	const result = { ...reading };
	if (result.loc) {
		result.loc = [...reading.loc];
	}
	return result;
};

export const cloneReadings = readings => {
	if (!readings) {
		return readings;
	}

	return readings.map(r => cloneReading(r));
};

// should ignore gap readings
// should ignore faux data point readings
// @return - index of reading matching stationId or just to the right of the stationId
export const fuzzyFindIndexOfStart = (readings = [], stationId) => {
	let done = readings.length === 0;
	let bestMatchIdx = -1;
	let bestMatch;
	let currentIdx = -1; // allows us to ignore faux readings
	let current;
	while (!done) {
		currentIdx += 1;
		current = readings[currentIdx];

		if (isTrueReading(current)) {
			bestMatchIdx = currentIdx;
			bestMatch = current;
		}

		if (bestMatch.id >= stationId) {
			done = true;
		} else if (currentIdx === readings.length - 1) {
			current = undefined;
			currentIdx = -1;
			bestMatch = current;
			bestMatchIdx = currentIdx;
			done = true;
		}
	}
	return bestMatchIdx;
};

// should ignore gap readings
// should ignore faux data point readings
// @return - index of reading matching stationId or just to the left of the stationId
export const fuzzyFindIndexOfEnd = (readings = [], stationId) => {
	let done = readings.length === 0;
	let currentIdx = -1;
	let bestMatchIdx = -1;

	const updateIndexes = (reading, index) => {
		currentIdx = index;
		if (isTrueReading(reading)) {
			bestMatchIdx = index;
		}
	};

	while (!done) {
		const idx = currentIdx + 1;
		const r = readings[idx];
		if (r.id === stationId) {
			updateIndexes(r, idx);
			done = true;
		} else if (r.id > stationId) {
			done = true;
		} else if (idx === readings.length - 1) {
			updateIndexes(r, idx);
			done = true;
		} else {
			updateIndexes(r, idx);
		}
	}

	return bestMatchIdx;
};

const getId = v => {
	if (typeof v === 'object') {
		return v.id;
	}
	return v;
};

const getFirstItem = (arr = []) => (arr && arr.length ? arr[0] : undefined);

const getLastItem = (arr = []) =>
	arr && arr.length > 1 ? arr[arr.length - 1] : undefined;

const _filterReadingsWithinBounds = (readings, groupRange) => {
	const firstId = getId(getFirstItem(groupRange));
	const lastId = getId(getLastItem(groupRange));

	const filteredReadings = [];
	for (let i = 0, n = readings.length; i < n; i += 1) {
		const reading = readings[i];
		if (reading.id >= firstId && reading.id <= lastId) {
			filteredReadings.push(reading);
		}
		// assuming we are incrementing values we can break at this point
		if (reading.id > lastId) {
			break;
		}
	}

	return filteredReadings;
};

const _fuzzyfilterReadingsWithinBounds = (readings, groupRange) => {
	const firstId = getId(getFirstItem(groupRange));
	const lastId = getId(getLastItem(groupRange));

	const shouldAbandon =
		!isDefined(firstId) ||
		!isDefined(lastId) ||
		readings.length === 0 ||
		groupRange.length === 0;

	if (shouldAbandon) {
		return undefined;
	}

	const startIndex = fuzzyFindIndexOfStart(readings, firstId);
	const endIndex = fuzzyFindIndexOfEnd(readings, lastId);

	const isValidIndex = i => isDefined(i) && i !== -1;
	if (!isValidIndex(startIndex) || !isValidIndex(endIndex)) {
		return undefined;
	}

	return readings.slice(startIndex, endIndex + 1);
};

export const filterReadingsWithinBounds = (
	readings = [],
	groupRange = [],
	{ useFuzzy = false } = {}
) => {
	if (useFuzzy) {
		return _fuzzyfilterReadingsWithinBounds(readings, groupRange);
	}

	return _filterReadingsWithinBounds(readings, groupRange);
};

// partial work towards a BINARY SEARCH, abandoning as time does not allow for a perfect implementation for ticket AIT-4859
// const fuzzyFindIndexOfStart = (readings = [], stationId) => {
// 	let done = readings.length === 0;
// 	let bestMatch = undefined;
// 	let sliceStart = 0;
// 	let sliceMiddle = readings.length ? Math.floor(readings.length / 2) : 0;
// 	let sliceEnd = readings.length - 1;
// 	let slice = readings;

// 	while (!done) {
// 		bestMatch = slice[readings[sliceMiddle]];
// 		console.log(`bestMatch --> ${bestMatch.id}`);
// 		if (bestMatch.id === stationId) {
// 			done = true;
// 		} else if (slice.length === 2) {
// 			if (slice[0].id > stationId) {
// 			}
// 		} else if (bestMatch.id < stationId) {
// 			slice = slice.slice(sliceMiddle);
// 			sliceMiddle = slice.length ? Math.floor(slice.length / 2) : 0;
// 			sliceEnd = slice.length - 1;
// 		} else if (bestMatch.id > stationId) {
// 			slice = slice.slice(0, sliceMiddle); // will not include sliceMiddleValue, "slice extracts up to but not including end", https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Array/slice
// 			sliceMiddle = slice.length ? Math.floor(slice.length / 2) : 0;
// 			sliceEnd = slice.length - 1;
// 		}
// 	}

// 	return bestMatch;
// };
