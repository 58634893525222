import { ACTION_PLAN_STATE_SLICE_KEY } from './constants';

export const initialState = {
	isFetchingActionPlanState: false,
	isActionPlanStateLoaded: false,
	[ACTION_PLAN_STATE_SLICE_KEY]: {},
	// @todo - these should be tracked from within the actionPlanState object
	actionReportThreshold: -850,
	actionReportUseShadow: true,
	actionReportCustomGroups: []
};

export default initialState;
