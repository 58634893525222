/* eslint-disable no-param-reassign */
import createBaseSurveyMeta from './_BASE';
import colors from '../../Colors';

import {
	computeUuid,
	computeFormattedStationId
} from '../util/readings.parse.computeProps';

import { defaultCreateSimplifiedReadings } from '../util/general';
import { createComputeYMinYMax } from '../util/yMinYMax';
import {
	createReadingKeyProps,
	createGetReadingKeys,
	createGetReadingKeyProps,
	createGetColumns,
	createGetSurveyColor,
	createTransformToCsvJson
} from './util';

const INVERT_Y_AXIS = false;

const isDefined = v => v !== undefined && v !== null;

// eslint-disable-next-line no-unused-vars
export default _survey => {
	// CONSTANTS

	const gapThreshold = 200;

	// READING KEYS

	const readingKeys = [
		{
			label: 'PCT',
			value: 'ir_pct',
			seriesType: 'bar',
			isPercentage: true,
			color: colors.getColor()
		}
	];
	const readingKeyProps = createReadingKeyProps(readingKeys);
	const computeYMinYMax = createComputeYMinYMax(readingKeys);

	// SKIP READING

	const isSkipReading = reading =>
		reading && readingKeys.every(rk => reading[rk.value] === 0);

	// HAS GAP

	const hasGap = (currReading, prevReading) => {
		return !!prevReading && currReading.id - prevReading.id > gapThreshold;
	};

	// INTERPOLATE

	const interpolate = (
		// eslint-disable-next-line no-unused-vars
		_prevDepolReading,
		// eslint-disable-next-line no-unused-vars
		_nextDepolReading,
		// eslint-disable-next-line no-unused-vars
		_alignToReading
	) => {};

	// COMPUTE PROPS - to be used by readings.parse

	const computeHilo = r => {
		r.low = r.low || 0;
	};

	const computeProps = (survey, _prevReading, currReading, currIdx) => {
		computeUuid(currReading, currIdx);
		computeFormattedStationId(currReading);
		computeHilo(currReading);
		computeYMinYMax(survey, currReading);
	};

	// SIMPLIFIED READINGS - creates a smaller data set to be used for rendering polyline on map
	const createSimplifiedReadings = defaultCreateSimplifiedReadings;

	const filterReadingsOfUnsupportedSurveyType = readings => {
		if (!readings) {
			return readings;
		}

		return readings.filter(r => r.ir_pct !== null && r.ir_pct !== undefined);
	};

	const columns = [
		{ label: 'ID', value: 'id', checked: true, width: 64, required: true },
		{ label: 'PCT', value: 'ir_pct', checked: true, width: 44 },
		{ label: 'DISTANCE', value: 'dst', checked: true, width: 80 },
		{
			label: 'LAT',
			value: 'lat',
			checked: true,
			width: 65,
			getCsvValue: r => {
				if (isDefined(r.lat)) {
					return r.lat;
				}
				if (isDefined(r.loc)) {
					return r.loc[0];
				}
				return undefined;
			}
		},
		{
			label: 'LNG',
			value: 'lng',
			checked: true,
			width: 70,
			getCsvValue: r => {
				if (isDefined(r.lng)) {
					return r.lng;
				}
				if (isDefined(r.loc)) {
					return r.loc[1];
				}
				return undefined;
			}
		},
		{
			label: 'COMMENTS',
			value: 'cmt',
			checked: true,
			width: 85,
			type: 'text'
		}
	];

	// MAIN
	const getReadingKeys = createGetReadingKeys(readingKeys);
	const getColumns = createGetColumns(columns);
	const transformToCsvJson = createTransformToCsvJson(getColumns);

	const meta = {
		...createBaseSurveyMeta(_survey),
		isChartInversed: INVERT_Y_AXIS,
		getReadingKeys,
		getReadingKeyProps: createGetReadingKeyProps(readingKeyProps),
		getColumns,
		transformToCsvJson,
		getSurveyColor: createGetSurveyColor(getReadingKeys),

		fkey: 'ir_pct',
		thresholds: [
			{
				start: 50,
				end: 50.01,
				color: '#ff512f',
				visible: true,
				border: { color: '#ff512f', width: 1 }
			}
		],
		isSkipReading,
		hasGap,
		interpolate,
		computeProps,
		computeYMinYMax,
		createSimplifiedReadings,
		filterReadingsOfUnsupportedSurveyType
	};

	return meta;
};
