import { handleActions, combineActions } from 'redux-actions';

import {
	selectCustomer,
	invalidateCustomer,
	receiveCustomers
} from '../actions/customers';

const jobsInitialState = {
	availableCustomers: [],
	currentCustomer: {},
	isLoadingCustomers: false
};

const jobsReducer = handleActions(
	{
		[combineActions(selectCustomer, invalidateCustomer)](
			state,
			{
				payload: { customer }
			}
		) {
			return {
				...state,
				currentCustomer: customer
			};
		},

		[receiveCustomers]: (state, { payload: { customers } }) => {
			return {
				...state,
				availableCustomers: customers,
				currentCustomer: customers[0]
			};
		}
	},
	jobsInitialState
);

export default jobsReducer;
