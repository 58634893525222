import { ROOT_NAME_SPACE } from '../constants';

export const NAME_SPACE = `${ROOT_NAME_SPACE} Filters`;

export const defaultCustomer = {
	name: 'No customer found',
	customerId: undefined
};

export const defaultSite = { name: 'No sites found', siteId: undefined };

export const defaultZone = { unitName: 'No zones found', unitId: undefined };
