/* eslint-disable camelcase */
import _ from 'lodash';

import createOnOff from './types/ONOFF';
import createOn from './types/ON';
import createDepol from './types/DEPOL';
import createNativeAsDepol from './types/NATIVE.as.DEPOL';
import createNative from './types/NATIVE';
import createAC from './types/AC';
import createDOC from './types/DOC';
import createSpatial from './types/SPATIAL';
import createACVG from './types/ACVG';
import createDCVG from './types/DCVG';
import createACCA from './types/ACCA';
import createILI from './types/ILI';
import createDCPS from './types/DCPS';
import createSRES from './types/SRES';
import createREL from './types/REL';

class SurveyMeta {
	getSurveyMeta = survey => {
		const {
			survey_type,
			survey_subtype,
			jobId,
			isNative = false,
			isTiedToNative = false
		} = survey;
		return this._getSurveyMeta(
			`${survey_type}~${survey_subtype}~${jobId}~${isNative}~${isTiedToNative}`,
			survey,
			isNative
		);
	};

	_getSurveyMeta = _.memoize((uniqueKey, survey, isNative) => {
		const parts = uniqueKey.split('~');
		const [, subtype] = parts;

		switch (subtype) {
			case 'ON_OFF':
			case 'ON-OFF':
				return createOnOff(survey);

			case 'ON':
				return createOn(survey);

			case 'NATIVE':
				return createNative();

			case 'DEPOL':
				if (isNative) {
					return createNativeAsDepol();
				}
				return createDepol();

			case 'AC':
				return createAC();

			case 'DOC':
				return createDOC();

			case 'SPATIAL':
				return createSpatial();

			case 'ACVG':
				return createACVG();

			case 'DCVG':
				return createDCVG();

			case 'ACCA':
				return createACCA();

			// TODO: add case 'ILI'
			case 'ILI-exMtlLoss':
			case 'ILI-inMtlLoss':
				// case 'ILI-swl': // add back once approved by KM
				// case 'ILI-dent': // add back once approved by KM
				return createILI(survey);

			case 'DCPS':
				return createDCPS(survey);

			case 'ACCD':
			case 'ACPS':
			case 'DCCD':
			case 'SRES':
				return createSRES(survey);

			case 'REL':
				return createREL(survey);

			default:
				return createOnOff(survey);
		}
	});

	reset = () => {
		this._getSurveyMeta.cache.clear();
	};
}

export default new SurveyMeta();
