/* eslint-disable import/no-cycle */
/* eslint-disable camelcase */
import {
	clearReports,
	requestReports,
	receiveReports,
	setTotalCount,
	setLoadingCount,
	setLoadedCount,
	setLoadReportsErrorMessage,
	clearLoadReportsErrorMessage,
	setTextSearch
} from './actions';

// ************
// PARTS
// ************
const reducerParts = {
	[clearReports]: state => {
		return {
			...state,
			reports: [],
			totalCount: undefined,
			isLoadingReports: false,
			isLoadedReports: false,
			loadReportsErrorMessage: undefined
		};
	},
	[requestReports]: state => {
		return {
			...state,
			isLoadingReports: true,
			loadReportsErrorMessage: undefined
		};
	},
	[receiveReports]: (state, { payload: { reports } }) => {
		return {
			...state,
			reports: [...state.reports, ...reports],
			isLoadingReports: false,
			isLoadedReports: true
		};
	},
	[setTotalCount]: (state, { payload: { totalCount } }) => {
		return {
			...state,
			totalCount
		};
	},
	[setLoadingCount]: (state, { payload: { loadingCount } }) => {
		return {
			...state,
			loadingCount
		};
	},
	[setLoadedCount]: (state, { payload: { loadedCount } }) => {
		return {
			...state,
			loadedCount
		};
	},

	[setLoadReportsErrorMessage]: (state, { payload: { message } }) => {
		return {
			...state,
			isLoadingReports: false,
			loadReportsErrorMessage: message
		};
	},
	[clearLoadReportsErrorMessage]: state => {
		return {
			...state,
			loadReportsErrorMessage: undefined
		};
	},
	[setTextSearch]: (state, { payload: { search } }) => {
		return {
			...state,
			textSearch: search || undefined
		};
	}
};

export default reducerParts;
