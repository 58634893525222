import { SET_SUGGESTIONS, SET_SEARCHES } from './actionTypes';

const initialState = {
	suggestions: [],
	searches: []
};

const reducer = (state = initialState, action) => {
	const { type, payload } = action;
	switch (type) {
		case SET_SUGGESTIONS:
			return { ...state, suggestions: payload };
		case SET_SEARCHES:
			return { ...state, searches: payload };
		default:
			return state;
	}
};

export default reducer;
