import React, { useState, useMemo, useEffect } from 'react';
import { Tooltip, OverlayTrigger } from 'react-bootstrap';
import { isTokenValid } from '../../../util/user';
import { browserHistory } from '../../../scanline/store';

import './LoginButton.scss';

const HIDDEN_FONT_COLOR = '#fd9e00';
const VISIBLE_FONT_COLOR = 'white';

const isAccountLoginPage = location => {
	const pathname = (location.pathname || '')
		.toLowerCase()
		.split('/')
		.filter(v => v)
		.join('/');
	return pathname === 'account/login';
};

const tooltip = <Tooltip id="tooltip">Login to AIM</Tooltip>;
const LoginButton = () => {
	const [showText, setShowText] = useState(false);

	const textColor = useMemo(
		() => (showText ? VISIBLE_FONT_COLOR : HIDDEN_FONT_COLOR),
		[showText]
	);

	// HACK - temp hide text on page load.  There was a problem with font files loading slowly which caused the CSS to break on load.
	// We solved this by delaying the rendering of the font.
	useEffect(() => {
		setTimeout(() => {
			setShowText(true);
		}, 100);
	}, []);

	if (isTokenValid() || isAccountLoginPage(browserHistory.location)) {
		return null;
	}

	return (
		<li>
			<a style={{ paddingTop: '1px' }} href="/Account/Login">
				<OverlayTrigger placement="bottom" overlay={tooltip}>
					<button
						style={{
							color: textColor,
							fontSize: '14px',
							overflow: 'hidden',
							whiteSpace: 'nowrap'
						}}
						type="button"
						ID="login-icon-button"
					>
						<i className="fa fa-sign-in" />
						&nbsp; LOGIN
					</button>
				</OverlayTrigger>
			</a>
		</li>
	);
};

export default LoginButton;
