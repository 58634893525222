const defaultState = {
	useMv: true,
	selectedDatFile: 0, // select the first item by default - this will always be an index, later, against the datFiles
	previousChartData: null,
	nextChartData: null,
	oldData: null,
	newData: null,
	shouldRefresh: false,
	fileType: undefined,
	readingsEditType: undefined
};

const setDefaultStateFromProps = (
	state,
	{
		oldData,
		preProcessedGPSReadings,
		newData,
		useMv,
		previousChartData,
		nextChartData,
		fileType,
		readingsEditType
	}
) => {
	return {
		...state,
		oldData,
		preProcessedGPSReadings,
		newData,
		useMv,
		previousChartData,
		nextChartData,
		shouldRefresh: false,
		fileType,
		readingsEditType
	};
};

const receiveNewChartData = (
	state,
	oldData,
	preProcessedGPSReadings,
	newData,
	previousChartData,
	nextChartData
) => ({
	...state,
	oldData,
	preProcessedGPSReadings,
	newData,
	previousChartData,
	nextChartData,
	shouldRefresh: false
});

const setSelectedDatFile = (
	state,
	index,
	oldData,
	preProcessedGPSReadings,
	newData,
	previousChartData,
	nextChartData,
	fileType,
	readingsEditType
) => {
	return {
		...state,
		selectedDatFile: index,
		oldData,
		preProcessedGPSReadings,
		newData,
		previousChartData,
		nextChartData,
		fileType,
		readingsEditType
	};
};

const onMvChanged = (state, newState) => ({
	...state,
	...newState
});

const spikeEditor = (state = defaultState, action) => {
	switch (action.type) {
		case 'CISV_SPIKE_EDITOR_SET_SELECTED_DAT_FILE': {
			const {
				index,
				oldData,
				preProcessedGPSReadings,
				newData,
				previousChartData,
				nextChartData,
				fileType,
				readingsEditType
			} = action;
			return setSelectedDatFile(
				state,
				index,
				oldData,
				preProcessedGPSReadings,
				newData,
				previousChartData,
				nextChartData,
				fileType,
				readingsEditType
			);
		}

		case 'CISV_SPIKE_EDITOR_SET_DEFAULT_STATE':
			return setDefaultStateFromProps(state, action.props);
		case 'CISV_SPIKE_EDITOR_RECEIVE_NEW_CHART_DATA':
			return receiveNewChartData(
				state,
				action.oldData,
				action.preProcessedGPSReadings,
				action.newData,
				action.previousChartData,
				action.nextChartData
			);

		case 'CISV_SPIKE_EDITOR_USE_MV_SETTING':
			return onMvChanged(state, action.newState);
		case 'SPIKE_EDITOR_SET_SHOULD_CHANGE':
			return {
				...state,
				shouldRefresh: true
			};
		case 'CISV_SPIKE_EDITOR_SET_READINGS_EDIT_TYPE':
			return { ...state, readingsEditType: action.payload };
		default:
			return state;
	}
};

export default spikeEditor;
