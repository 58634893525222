"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.pullCoordsFromObject = exports.formatCoords = exports["default"] = void 0;

var pullCoordsFromObject = function pullCoordsFromObject(coordsObject) {
  if (!coordsObject.geometry) {
    return coordsObject;
  }

  return coordsObject.geometry.coordinates;
};

exports.pullCoordsFromObject = pullCoordsFromObject;

var formatCoords = function formatCoords(coordsObject, reverse) {
  var coords = pullCoordsFromObject(coordsObject);

  if (!reverse) {
    return [coords[0], coords[1]];
  }

  return [coords[1], coords[0]];
};

exports.formatCoords = formatCoords;
var _default = formatCoords;
exports["default"] = _default;