// This is primarily used to update data for the readings tab

// eslint-disable-next-line import/no-cycle
import {
	SET_CONVERTED_READINGS,
	SET_CONVERTED_READINGS_SORT,
	SET_FILTERS,
	RESET_COMPUTED_INDEX,
	SET_FILTER_TEXT_VALUE,
	SET_SCROLL_POSITION,
	RESET_SCROLL_POSITION,
	SET_READING_TABLE_SCROLL_INDEX,
	SET_UNCONVERTED_READINGS
} from '../actions/readings';
import sortviewAction from '../../components/misc/ReadingsTable/SortActions';
import { SORT_VIEW } from '../../components/misc/ReadingsTable/SortViewOptions';

const defaultState = {
	convertedReadingsByFile: [],
	convertedReadings: [],
	filteredReadings: [],
	skipReadings: [],
	sortDirection: null,
	indexOfSavingReading: null,
	filter: '',
	readingsVersion: 0, // To keep track of changes -> Force reading table to be recomputed
	readingViewIndexPositions: {
		/**
		 ** Will look like:
		 * spikeeditor: {scrollToIndex: 101, readingIndex: 100, scrollUserText: 'STATION'},
		 * SKIPS: {scrollToIndex: 201, readingIndex: 200, scrollUserText: 'STATION'},
		 */
	},
	readingViewStopIndexes: {
		/**
		 ** Will look like:
		 * spikeeditor: 19,
		 * SKIPS: 207,
		 */
	},
	boundsByFile: [],
	buffersByFile: []
};

// Sets a user-defined scroll position for each tab (spike editor, skips, etc)
const _setScrollPosition = (
	state,
	readingsView,
	scrollUserText,
	scrollToIndex,
	readingIndex,
	surveyType
) => {
	const { readingViewIndexPositions } = state;

	const readingsInViewType = readingViewIndexPositions[readingsView] || {};

	return {
		...state,
		readingViewIndexPositions: {
			...readingViewIndexPositions,
			[readingsView]: {
				...readingsInViewType,
				[surveyType]: {
					ignoreNextScrollReset: true,
					scrollUserText,
					scrollToIndex,
					readingIndex
				}
			}
		}
	};
};

// We actually just want to remove the readingIndex so it will not be highlighted in the table
const _resetScrollPosition = (state, readingsView, surveyType) => {
	const { readingViewIndexPositions: oldReadingViewIndexPositions } = state;

	const readingViewIndexPositions = {};

	Object.keys(oldReadingViewIndexPositions).forEach(_readingsView => {
		const oldOptions = oldReadingViewIndexPositions[_readingsView];
		let newIndexPositionOption = {
			ignoreNextScrollReset: false
		};
		if (_readingsView !== readingsView) {
			newIndexPositionOption = oldOptions;
		}
		if (!readingViewIndexPositions[_readingsView]) {
			readingViewIndexPositions[_readingsView] = {};
		}

		readingViewIndexPositions[_readingsView][
			surveyType
		] = newIndexPositionOption;
	});
	return {
		...state,
		readingViewIndexPositions
	};
};

const setReadingTableScrollIndex = (state, readingsView, stopIndex) => {
	const { readingViewStopIndexes } = state;
	return {
		...state,
		readingViewStopIndexes: {
			...readingViewStopIndexes,
			[readingsView]: stopIndex
		}
	};
};

const reducer = (state = defaultState, action) => {
	const { type, payload } = action;

	switch (type) {
		case SET_UNCONVERTED_READINGS:
			return {
				...state,
				unsortedConvertedReadingsByFile: payload.unsortedConvertedReadingsByFile
			};

		case SET_CONVERTED_READINGS:
			return {
				...state,
				convertedReadings: payload.convertedReadings,
				indexOfSavingReading: payload.indexOfSavingReading,
				filteredReadings: payload.filteredReadings,
				skipReadings: payload.skipReadings,
				convertedReadingsByFile: payload.convertedReadingsByFile,
				unsortedConvertedReadings: payload.unsortedConvertedReadings,
				boundsByFile: payload.boundsByFile,
				buffersByFile: payload.buffersByFile,
				unsortedConvertedReadingsByFile:
					payload.unsortedConvertedReadingsByFile,
				readingsVersion: state.readingsVersion + 1
			};
		case SET_CONVERTED_READINGS_SORT:
			return {
				...state,
				convertedReadings: payload.convertedReadings,
				sortDirection: payload.sortDirection,
				filteredReadings: payload.filteredReadings,
				convertedReadingsByFile: payload.convertedReadingsByFile,
				unsortedConvertedReadingsByFile:
					payload.unsortedConvertedReadingsByFile,
				readingsVersion: state.readingsVersion + 1
			};
		case SET_FILTERS:
			return {
				...state,
				filteredReadings: payload.filteredReadings,
				convertedReadingsByFile: payload.convertedReadingsByFile,
				readingsVersion: state.readingsVersion + 1
			};
		case SET_FILTER_TEXT_VALUE:
			return {
				...state,
				filter: payload.filter
			};
		case RESET_COMPUTED_INDEX:
			return {
				...state,
				indexOfSavingReading: null
			};
		case SET_SCROLL_POSITION:
			return _setScrollPosition(
				state,
				payload.readingsView,
				payload.scrollUserText,
				payload.scrollToIndex,
				payload.readingIndex,
				payload.surveyType
			);
		case RESET_SCROLL_POSITION:
			return _resetScrollPosition(
				state,
				payload.readingsView,
				payload.surveyType
			);
		case SET_READING_TABLE_SCROLL_INDEX:
			return setReadingTableScrollIndex(
				state,
				payload.readingsView,
				payload.stopIndex
			);
		case sortviewAction.SET_SORT_VIEW:
				return { ...state, sortView: payload, listFrom: 0 };
		case sortviewAction.SET_SORT_DIRECTION:
					return { ...state, sortDirection: payload, listFrom: 0 };
		 case sortviewAction.SET_TEST_STATION:
					return { ...state, isTestStation: payload, listFrom: 0 };
		default:
			return state;
	}
};

export default reducer;
