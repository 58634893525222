/* eslint-disable import/no-cycle */
import { handleActions } from 'redux-actions';

// MAIN
import jobLinesReducerParts from './JobLines/reducerParts';
import { initialState as jobLinesInitialState } from './JobLines/initialState';

// JOBLINE FILTERS
import jobLineFiltersReducerParts from './JobLineFilters/reducerParts';
import jobLineFiltersInitialState from './JobLineFilters/initialState';

const initialState = {
  ...jobLinesInitialState,
  ...jobLineFiltersInitialState
};

const reducerParts = {
  ...jobLinesReducerParts,
  ...jobLineFiltersReducerParts
};

export default handleActions(reducerParts, initialState);
