/* eslint-disable no-param-reassign */
/* eslint-disable import/prefer-default-export */
const _helpIsCIS = survey =>
	survey && ['ON', 'ON_OFF', 'ON-OFF'].indexOf(survey.survey_subtype) > -1;

const _helpIsMergeable = survey =>
	survey && ['DEPOL', 'NATIVE'].indexOf(survey.survey_subtype) > -1;

const computeIsMergedWithCIS = (survey1, survey2) => {
	let cisSurvey;
	let toMergeSurvey;

	if (_helpIsCIS(survey1)) {
		cisSurvey = survey1;
	} else if (_helpIsMergeable(survey1)) {
		toMergeSurvey = survey1;
	}

	if (_helpIsCIS(survey2)) {
		cisSurvey = survey2;
	} else if (_helpIsMergeable(survey2)) {
		toMergeSurvey = survey2;
	}

	if (cisSurvey && toMergeSurvey) {
		toMergeSurvey.isMergedWithCIS = true;
	}
};

const createHelperMaps = sortedSurveys => {
	const surveys = sortedSurveys;
	const bestCISLinkMatches = {};
	const surveyMap = {};

	let idx1 = 0;
	while (idx1 < sortedSurveys.length) {
		const currentSurvey = surveys[idx1];
		const isCurrentCIS = _helpIsCIS(currentSurvey);

		// we only care about surveys from CISV.  we do not care about external surveys nor derived surveys.
		if (currentSurvey.survey_guid) {
			surveyMap[currentSurvey.survey_guid] = currentSurvey;
		}

		if (isCurrentCIS && !bestCISLinkMatches[currentSurvey.survey_guid]) {
			if (currentSurvey.linked_to) {
				bestCISLinkMatches[currentSurvey.survey_guid] = currentSurvey.linked_to;
				bestCISLinkMatches[currentSurvey.linked_to] = currentSurvey.survey_guid;
			} else {
				let idx2 = idx1 + 1;
				while (
					idx2 < surveys.length &&
					!bestCISLinkMatches[currentSurvey.survey_guid]
				) {
					const subSurvey = surveys[idx2];
					const shouldLink =
						_helpIsMergeable(subSurvey) &&
						currentSurvey.job_number &&
						currentSurvey.job_number === subSurvey.job_number &&
						!bestCISLinkMatches[subSurvey.survey_guid];

					if (shouldLink) {
						bestCISLinkMatches[currentSurvey.survey_guid] =
							subSurvey.survey_guid;
						bestCISLinkMatches[subSurvey.survey_guid] =
							currentSurvey.survey_guid;
					}
					idx2 += 1;
				}
			}
		}

		idx1 += 1;
	}

	return {
		bestCISLinkMatches,
		surveyMap
	};
};

export const hackJobId = (sortedSurveys = []) => {
	const surveys = [...sortedSurveys];
	const { surveyMap, bestCISLinkMatches } = createHelperMaps(surveys);

	surveys.forEach(survey => {
		if (!survey.job_id) {
			survey.job_id = survey.survey_guid;
			survey.jobId = survey.job_id;
		}

		if (_helpIsCIS(survey)) {
			const surveyToLink = surveyMap[bestCISLinkMatches[survey.survey_guid]];

			if (surveyToLink && !surveyToLink.isMergedWithCIS) {
				surveyToLink.job_id = survey.job_id;
				surveyToLink.jobId = survey.job_id;
				surveyToLink.job_number = survey.job_number;
				if (surveyToLink.survey_subtype === 'NATIVE') {
					survey.isTiedToNative = true;
					surveyToLink.survey_subtype = 'DEPOL';
					surveyToLink.isNative = true;
				}
				computeIsMergedWithCIS(survey, surveyToLink);
			}
		}
	});

	return sortedSurveys;
};
