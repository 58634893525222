import { selectSliceCisview } from '../../selectors/HELPERS';
import { ROOT_SLICE } from './constants';

export const selectRootSlice = state => {
	const appSlice = selectSliceCisview(state);
	return appSlice[ROOT_SLICE] || state;
};

export const selectData = state => {
	const slice = selectRootSlice(state);
	const { data } = slice;
	return data;
};

export const selectIsLoading = state => {
	const slice = selectRootSlice(state);
	const { isLoading } = slice;
	return isLoading;
};

export const selectIsLoaded = state => {
	const slice = selectRootSlice(state);
	const { isLoaded } = slice;
	return isLoaded;
};

export const selectErrorMessage = state => {
	const slice = selectRootSlice(state);
	const { errorMessage } = slice;
	return errorMessage;
};

export const selectDomainMap = state => {
	const slice = selectRootSlice(state);
	const { domainMap = {} } = slice;
	return domainMap;
};

export const selectDomain = (state, { customer }) => {
	const domainMap = selectDomainMap(state);
	return domainMap[customer];
};

export default {
	selectData,
	selectDomainMap,
	selectIsLoading,
	selectIsLoaded,
	selectErrorMessage,
	selectDomain
};
