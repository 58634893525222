import { getUserName } from '../../../util/user';

const EMPTY_ARRAY = [];

export const selectViewAs = stateApp => {
	const { viewAs } = stateApp;

	if (viewAs && viewAs.userId !== getUserName()) {
		return viewAs;
	}

	return undefined;
};

export const selectViewAsList = stateApp => {
	const { viewAsList } = stateApp;
	return viewAsList || EMPTY_ARRAY;
};

export const selectIsViewAsListLoading = stateApp => {
	const { isViewAsListLoading } = stateApp;
	return !!isViewAsListLoading;
};

export const isViewAsMe = stateApp => {
	const viewAs = selectViewAs(stateApp);
	return !viewAs || viewAs.userId === getUserName();
};

export const selectViewAsUserId = stateApp => {
	const viewAs = selectViewAs(stateApp) || {};
	return viewAs.userId;
};

export const selectViewAsErrorMessage = stateApp => {
	return stateApp.viewAsErrorMessage;
};

export const selectHasViewAsErrorMessage = stateApp => {
	const viewAsErrorMessage = selectViewAsErrorMessage(stateApp);
	return viewAsErrorMessage && viewAsErrorMessage.trim().length > 0;
};

export const selectIsPm = (stateApp = {}) => {
	const { isPm } = stateApp;
	return !!isPm;
};

export const selectIsRequestingIsPm = (stateApp = {}) => {
	const { isRequestingIsPm } = stateApp;
	return !!isRequestingIsPm;
};
