import { ROOT_SLICE } from './constants';

/* eslint-disable no-use-before-define */
export const selectStateSlice = state => {
	const { app } = state;
	return app;
};

export const selectActionPlanSyncSlice = slice => {
	const actionPlanSync = (slice || {})[ROOT_SLICE];
	return actionPlanSync;
};

export const selectIsSyncEnabled = slice => {
	const { isSyncEnabled } = selectActionPlanSyncSlice(slice);
	return isSyncEnabled;
};
