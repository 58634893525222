/* eslint-disable import/no-cycle */
/* eslint-disable camelcase */
import { setNotes, setShowModal, setSideEffects } from './actions';
import { EMPTY_OBJECT } from './constants';
import { createInitialChartSettings } from './util';

const selectHelper = (state, chartId) => {
	const { actionPlanChangeNotes: stateAllChangeNotes = EMPTY_OBJECT } = state;

	const stateChangeNotes =
		stateAllChangeNotes[chartId] || createInitialChartSettings();

	return [stateAllChangeNotes, stateChangeNotes];
};

// ************
// PARTS
// ************
const reducerParts = {
	[setNotes]: (state, { payload: { chartId, notes } }) => {
		const [allActionPlanChangeNotes, actionPlanChangeNotes] = selectHelper(
			state,
			chartId
		);

		return {
			...state,
			actionPlanChangeNotes: {
				...allActionPlanChangeNotes,
				[chartId]: {
					...actionPlanChangeNotes,
					notes
				}
			}
		};
	},
	[setShowModal]: (state, { payload: { chartId, show } }) => {
		const [allActionPlanChangeNotes, actionPlanChangeNotes] = selectHelper(
			state,
			chartId
		);

		return {
			...state,
			actionPlanChangeNotes: {
				...allActionPlanChangeNotes,
				[chartId]: {
					...actionPlanChangeNotes,
					showModal: show
				}
			}
		};
	},
	[setSideEffects]: (
		state,
		{ payload: { chartId, onCancelChangeNotes, onSaveChangeNotes } }
	) => {
		const [allActionPlanChangeNotes, actionPlanChangeNotes] = selectHelper(
			state,
			chartId
		);

		return {
			...state,
			actionPlanChangeNotes: {
				...allActionPlanChangeNotes,
				[chartId]: {
					...actionPlanChangeNotes,
					sideEffects: {
						onCancelChangeNotes,
						onSaveChangeNotes
					}
				}
			}
		};
	}
};

export default reducerParts;
