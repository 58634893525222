"use strict";

function _typeof(obj) {
  "@babel/helpers - typeof";

  return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (obj) {
    return typeof obj;
  } : function (obj) {
    return obj && "function" == typeof Symbol && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj;
  }, _typeof(obj);
}

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

var _BadLocationError = _interopRequireDefault(require("../ErrorClasses/BadLocationError"));

var _LocationType = _interopRequireDefault(require("./LocationType"));

var _misc = _interopRequireDefault(require("./misc"));

var MapProcessingUtil = _interopRequireWildcard(require("../MapProcessingUtil"));

var _excluded = ["code", "readingReplaced", "StationId", "time", "comments", "locationType", "coordinates", "prevDistance", "prevGap", "prevAzimuth", "nextDistance", "nextGap", "nextAzimuth", "oldStns", "reading1", "reading2", "originalReading1", "originalReading2", "ir", "doc", "ss", "flaggedSS", "flaggedStn", "olre", "clock", "prev_coordinate_before_move", "next_coordinate_before_move", "interpolatedReading1", "interpolatedReading2", "acOn", "acOff", "gpsType", "triggerType", "docTop"];

function _getRequireWildcardCache(nodeInterop) {
  if (typeof WeakMap !== "function") return null;
  var cacheBabelInterop = new WeakMap();
  var cacheNodeInterop = new WeakMap();
  return (_getRequireWildcardCache = function _getRequireWildcardCache(nodeInterop) {
    return nodeInterop ? cacheNodeInterop : cacheBabelInterop;
  })(nodeInterop);
}

function _interopRequireWildcard(obj, nodeInterop) {
  if (!nodeInterop && obj && obj.__esModule) {
    return obj;
  }

  if (obj === null || _typeof(obj) !== "object" && typeof obj !== "function") {
    return {
      "default": obj
    };
  }

  var cache = _getRequireWildcardCache(nodeInterop);

  if (cache && cache.has(obj)) {
    return cache.get(obj);
  }

  var newObj = {};
  var hasPropertyDescriptor = Object.defineProperty && Object.getOwnPropertyDescriptor;

  for (var key in obj) {
    if (key !== "default" && Object.prototype.hasOwnProperty.call(obj, key)) {
      var desc = hasPropertyDescriptor ? Object.getOwnPropertyDescriptor(obj, key) : null;

      if (desc && (desc.get || desc.set)) {
        Object.defineProperty(newObj, key, desc);
      } else {
        newObj[key] = obj[key];
      }
    }
  }

  newObj["default"] = obj;

  if (cache) {
    cache.set(obj, newObj);
  }

  return newObj;
}

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    "default": obj
  };
}

function _slicedToArray(arr, i) {
  return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _unsupportedIterableToArray(arr, i) || _nonIterableRest();
}

function _nonIterableRest() {
  throw new TypeError("Invalid attempt to destructure non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method.");
}

function _unsupportedIterableToArray(o, minLen) {
  if (!o) return;
  if (typeof o === "string") return _arrayLikeToArray(o, minLen);
  var n = Object.prototype.toString.call(o).slice(8, -1);
  if (n === "Object" && o.constructor) n = o.constructor.name;
  if (n === "Map" || n === "Set") return Array.from(o);
  if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen);
}

function _arrayLikeToArray(arr, len) {
  if (len == null || len > arr.length) len = arr.length;

  for (var i = 0, arr2 = new Array(len); i < len; i++) {
    arr2[i] = arr[i];
  }

  return arr2;
}

function _iterableToArrayLimit(arr, i) {
  var _i = arr == null ? null : typeof Symbol !== "undefined" && arr[Symbol.iterator] || arr["@@iterator"];

  if (_i == null) return;
  var _arr = [];
  var _n = true;
  var _d = false;

  var _s, _e;

  try {
    for (_i = _i.call(arr); !(_n = (_s = _i.next()).done); _n = true) {
      _arr.push(_s.value);

      if (i && _arr.length === i) break;
    }
  } catch (err) {
    _d = true;
    _e = err;
  } finally {
    try {
      if (!_n && _i["return"] != null) _i["return"]();
    } finally {
      if (_d) throw _e;
    }
  }

  return _arr;
}

function _arrayWithHoles(arr) {
  if (Array.isArray(arr)) return arr;
}

function _objectWithoutProperties(source, excluded) {
  if (source == null) return {};

  var target = _objectWithoutPropertiesLoose(source, excluded);

  var key, i;

  if (Object.getOwnPropertySymbols) {
    var sourceSymbolKeys = Object.getOwnPropertySymbols(source);

    for (i = 0; i < sourceSymbolKeys.length; i++) {
      key = sourceSymbolKeys[i];
      if (excluded.indexOf(key) >= 0) continue;
      if (!Object.prototype.propertyIsEnumerable.call(source, key)) continue;
      target[key] = source[key];
    }
  }

  return target;
}

function _objectWithoutPropertiesLoose(source, excluded) {
  if (source == null) return {};
  var target = {};
  var sourceKeys = Object.keys(source);
  var key, i;

  for (i = 0; i < sourceKeys.length; i++) {
    key = sourceKeys[i];
    if (excluded.indexOf(key) >= 0) continue;
    target[key] = source[key];
  }

  return target;
} // TODO: We should only try to pull DCVG data if surveyType=DCVG


var _extractGpsReadingData = function _extractGpsReadingData(flattenedGpsReading) {
  var code = flattenedGpsReading.code,
      readingReplaced = flattenedGpsReading.readingReplaced,
      StationId = flattenedGpsReading.StationId,
      time = flattenedGpsReading.time,
      comments = flattenedGpsReading.comments,
      locationType = flattenedGpsReading.locationType,
      coordinates = flattenedGpsReading.coordinates,
      prevDistance = flattenedGpsReading.prevDistance,
      prevGap = flattenedGpsReading.prevGap,
      prevAzimuth = flattenedGpsReading.prevAzimuth,
      nextDistance = flattenedGpsReading.nextDistance,
      nextGap = flattenedGpsReading.nextGap,
      nextAzimuth = flattenedGpsReading.nextAzimuth,
      oldStns = flattenedGpsReading.oldStns,
      reading1 = flattenedGpsReading.reading1,
      reading2 = flattenedGpsReading.reading2,
      originalReading1 = flattenedGpsReading.originalReading1,
      originalReading2 = flattenedGpsReading.originalReading2,
      ir = flattenedGpsReading.ir,
      doc = flattenedGpsReading.doc,
      ss = flattenedGpsReading.ss,
      flaggedSS = flattenedGpsReading.flaggedSS,
      flaggedStn = flattenedGpsReading.flaggedStn,
      olre = flattenedGpsReading.olre,
      clock = flattenedGpsReading.clock,
      prev_coordinate_before_move = flattenedGpsReading.prev_coordinate_before_move,
      next_coordinate_before_move = flattenedGpsReading.next_coordinate_before_move,
      interpolatedReading1 = flattenedGpsReading.interpolatedReading1,
      interpolatedReading2 = flattenedGpsReading.interpolatedReading2,
      acOn = flattenedGpsReading.acOn,
      acOff = flattenedGpsReading.acOff,
      gpsType = flattenedGpsReading.gpsType,
      triggerType = flattenedGpsReading.triggerType,
      docTop = flattenedGpsReading.docTop,
      otherProps = _objectWithoutProperties(flattenedGpsReading, _excluded);

  var reading = {
    code: code,
    value: {
      time: time,
      comments: comments,
      StationId: StationId,
      readingReplaced: readingReplaced,
      oldStns: oldStns,
      // ON-OFF
      reading1: reading1,
      reading2: reading2,
      originalReading1: originalReading1,
      originalReading2: originalReading2,
      // DCVG
      ir: ir,
      doc: doc,
      ss: ss,
      flaggedSS: flaggedSS,
      flaggedStn: flaggedStn,
      olre: olre,
      clock: clock,
      interpolatedReading1: interpolatedReading1,
      interpolatedReading2: interpolatedReading2,
      acOn: acOn,
      acOff: acOff,
      gpsType: gpsType,
      triggerType: triggerType,
      docTop: docTop
    }
  };

  _misc["default"].validateEmptyProps('flattened gpsreading', otherProps);

  switch (locationType) {
    case _LocationType["default"].INTERPOLATED:
      return {
        reading: reading
      };

    case _LocationType["default"].GPS:
      return {
        coordinates: coordinates,
        nextDistance: nextDistance,
        nextGap: nextGap,
        nextAzimuth: nextAzimuth,
        prevDistance: prevDistance,
        prevGap: prevGap,
        prevAzimuth: prevAzimuth,
        readings: [reading]
      };

    default:
      throw new _BadLocationError["default"]("Unknown location type ".concat(locationType));
  }
};

var removeEmptyValuesFromEntireTree = function removeEmptyValuesFromEntireTree(gpsReadings) {
  gpsReadings.forEach(function (gpsReading) {
    _misc["default"].cleanEmptyValues(gpsReading);

    gpsReading.readings.forEach(function (reading) {
      var value = reading.value;

      _misc["default"].cleanEmptyValues(reading);

      _misc["default"].cleanEmptyValues(value);
    });
  });
};

var cleanEntireTree = function cleanEntireTree(gpsReadings) {
  removeEmptyValuesFromEntireTree(gpsReadings);
  MapProcessingUtil.updateGPSMeasurements(gpsReadings);
};

var nestGpsReadings = function nestGpsReadings(flattenedGpsReadings) {
  var _flattenedGpsReadings = _slicedToArray(flattenedGpsReadings, 1),
      _flattenedGpsReadings2 = _flattenedGpsReadings[0];

  _flattenedGpsReadings2 = _flattenedGpsReadings2 === void 0 ? {} : _flattenedGpsReadings2;
  var code = _flattenedGpsReadings2.code,
      locationType = _flattenedGpsReadings2.locationType; // check to see if data already nested

  if (flattenedGpsReadings.length > 0 && code === undefined && locationType === undefined) {
    return flattenedGpsReadings;
  }

  var nestedGpsReadings = [];
  var currentNestedGpsReading = null;
  flattenedGpsReadings.forEach(function (flattenedGpsReading) {
    var locationType = flattenedGpsReading.locationType;

    switch (locationType) {
      case _LocationType["default"].GPS:
        {
          if (currentNestedGpsReading) {
            nestedGpsReadings.push(currentNestedGpsReading);
          }

          currentNestedGpsReading = _extractGpsReadingData(flattenedGpsReading);
          break;
        }

      case _LocationType["default"].INTERPOLATED:
        {
          var _extractGpsReadingDat = _extractGpsReadingData(flattenedGpsReading),
              reading = _extractGpsReadingDat.reading;

          currentNestedGpsReading.readings.push(reading);
          break;
        }

      default:
        throw new _BadLocationError["default"]("Unkown location type ".concat(locationType));
    }
  });

  if (currentNestedGpsReading) {
    nestedGpsReadings.push(currentNestedGpsReading);
  }

  cleanEntireTree(nestedGpsReadings);
  return nestedGpsReadings;
};

var _default = nestGpsReadings;
exports["default"] = _default;