/* eslint-disable import/prefer-default-export */
import scanlineMapUtils from '../mapUtils';

const USABLE_SURVEYS = [
	'ON_OFF',
	'ON-OFF',
	'DEPOL',
	'NATIVE',
	'ON',
	'DOC',
	'AC',
	'DCVG',
	'ACVG',
	'ACCA',
	'ACCD',
	'ACPS',
	'DCCD',
	'DCPS',
	'SRES',
	'ILI-exMtlLoss',
	'ILI-inMtlLoss'
];

// TODO: Add 'ACCA' back in
// TODO: Add back once approved by KM - 'ILI-swl', 'ILI-dent',

export const isChartPrimarySurvey = (survey, surveys) => {
	const isUsable = USABLE_SURVEYS.indexOf(survey.survey_subtype) !== -1;
	const surveyMeta = scanlineMapUtils.getSurveyMeta(survey);
	return isUsable && !surveyMeta.hasMasterSurvey(survey, surveys);
};
