/* eslint-disable import/prefer-default-export */
const isDefined = v =>
	v !== undefined && v !== null && !(typeof v === 'string' && v.trim() === '');

export const addDepolValuesToCIS = (depolReadings, cisReadingsMap) => {
	depolReadings.forEach(depolReading => {
		const cisReading = cisReadingsMap[depolReading.id];
		if (
			cisReading &&
			depolReading &&
			isDefined(depolReading.on) &&
			!isDefined(cisReading.depol)
		) {
			cisReading.depol = depolReading.on;
		}
	});
};

export const addNormalizedDepolValuesToCIS = (
	depolReadings,
	cisReadingsMap
) => {
	depolReadings.forEach(depolReading => {
		const cisReading = cisReadingsMap[depolReading.id];
		if (
			cisReading &&
			depolReading &&
			isDefined(depolReading.on) &&
			!isDefined(cisReading.nDepolVal)
		) {
			if (depolReading.isNormalized) {
				cisReading.isNormalized = depolReading.isNormalized;
			}
			cisReading.nDepolVal = depolReading.on;
		}
	});
};
