/* eslint-disable import/no-cycle */

import {
	getKMZsRequest,
	getUploadURLRequest,
	putKMZFileRequest,
	postKMZUrlRequest
} from '../../utils/KMZ/kmz.api';
import {
	selectSelectedKMZ,
	selectSelectedKMZs
} from '../selectors/kmz.selector';

export const NS_MAPS = 'cisview_kmz_';

export const SET_IS_UPLOADING = `${NS_MAPS}SET_IS_UPLOADING`;
export const setIsUploading = isUploading => {
	return {
		type: SET_IS_UPLOADING,
		payload: isUploading
	};
};

export const SET_CUSTOMERS_KMZS = `${NS_MAPS}SET_CUSTOMERS_KMZS`;
export const setCustomersKMZs = customersKMZs => {
	return {
		type: SET_CUSTOMERS_KMZS,
		payload: customersKMZs
	};
};

export const SET_SELECTED_KMZS = `${NS_MAPS}SET_SELECTED_KMZS`;
const setSelectedKMZs = seletedKMZs => {
	return {
		type: SET_SELECTED_KMZS,
		payload: seletedKMZs
	};
};

const replaceKMZ = (key, newKMZ) => (dispatch, getState) => {
	const oldSelectedKMZs = selectSelectedKMZs(getState());
	const oldKMZ = selectSelectedKMZ(getState(), key);
	const index = oldSelectedKMZs.indexOf(oldKMZ);
	const newSelectedKMZs = [...oldSelectedKMZs];

	if (oldKMZ === undefined) {
		newSelectedKMZs.push(newKMZ);
	} else {
		newSelectedKMZs.splice(index, 1, newKMZ);
	}
	dispatch(setSelectedKMZs(newSelectedKMZs));
};

export const uploadKMZ = (files, url) => (dispatch, getState) => {
	const {
		cisview: {
			kmz: { selectedCustomer }
		}
	} = getState();
	dispatch(setIsUploading(true));
	const fileReader = new FileReader();
	fileReader.onload = e => {
		const splitUrl = url.split(/\\|\//);
		getUploadURLRequest(selectedCustomer, splitUrl[splitUrl.length - 1]).then(
			uploadUrl => {
				return putKMZFileRequest(uploadUrl, e.target.result)
					.then(() => {
						dispatch(setIsUploading(false));
					})
					.catch(() => {
						dispatch(setIsUploading(false));
					});
			}
		);
	};
	fileReader.readAsArrayBuffer(files[0]);
};

const getKMZUrl = key => (dispatch, getState) => {
	postKMZUrlRequest(key)
		.then(res => {
			const kmz = selectSelectedKMZ(getState(), key);
			dispatch(replaceKMZ(key, { ...kmz, url: res }));
		})
		.catch(err => {
			// eslint-disable-next-line no-console
			console.log(err);
		});
};

export const checkKMZ = (isChecked, key) => (dispatch, getState) => {
	const {
		cisview: {
			kmz: { selectedKMZs }
		}
	} = getState();
	const newSelectedKMZs = [...selectedKMZs];
	const index = newSelectedKMZs.findIndex(({ key: k }) => k === key);
	if (isChecked && index === -1) {
		newSelectedKMZs.push({ key });
		dispatch(getKMZUrl(key));
	}
	if (!isChecked && index > -1) {
		newSelectedKMZs.splice(index, 1);
	}
	dispatch(setSelectedKMZs(newSelectedKMZs));
};

export const setKMZLoaded = (isLoaded, key) => (dispatch, getState) => {
	const {
		cisview: {
			kmz: { selectedKMZs }
		}
	} = getState();
	const newSelectedKMZs = [...selectedKMZs];
	const index = newSelectedKMZs.findIndex(({ key: k }) => k === key);
	if (index !== -1) {
		const newKMZ = { ...newSelectedKMZs[index], loaded: isLoaded };
		newSelectedKMZs.splice(index, 1, newKMZ);
		dispatch(setSelectedKMZs(newSelectedKMZs));
	}
};

export const getKMZs = () => dispatch => {
	getKMZsRequest()
		.then(res => {
			const customersKMZs = res.reduce((acc, file) => {
				const { customer, fileName, key } = file;
				return {
					...acc,
					[customer]: [...(acc[customer] || []), { fileName, key }]
				};
			}, {});
			dispatch(setCustomersKMZs(customersKMZs));
		})
		.catch(err => {
			// eslint-disable-next-line no-console
			console.log(err);
		});
};

export const SET_SELECTED_CUSTOMER = `${NS_MAPS}SET_SELECTED_CUSTOMER`;
export const setSelectedCustomer = customer => dispatch => {
	dispatch({
		type: SET_SELECTED_CUSTOMER,
		payload: customer
	});
};

export const SET_SHOW_KMZ_MODAL = `${NS_MAPS}SET_SHOW_KMZ_MODAL`;
export const setShowKMZModal = showKMZModal => {
	return {
		type: SET_SHOW_KMZ_MODAL,
		payload: showKMZModal
	};
};
