/* eslint-disable no-param-reassign */
import { isDate } from 'aegion_common_utilities/lib/DateUtil';
import { MM_DD_YYYY } from 'aegion_common_utilities/lib/format/datetimeless';
import createBaseSurveyMeta from './_BASE';
import colors from '../../Colors';
import {
	computeUuid,
	computeFormattedStationId,
	computePipeDepth,
	computeAC,
	computeSpatial,
	computeDistance,
	computeMvTransform,
	assumeIsInMV
} from '../util/readings.parse.computeProps';
import { defaultCreateSimplifiedReadings } from '../util/general';
import { formatMillivolt, formatStrMillivolt } from '../../formatNumber';
import { createComputeYMinYMax } from '../util/yMinYMax';
import {
	createReadingKeyProps,
	createGetReadingKeys,
	createGetReadingKeyProps,
	createGetColumns,
	createGetSurveyColor,
	createTransformToCsvJson
} from './util';
import { toDisplayLabel as nativeToDisplayLabel } from './NATIVE.as.DEPOL';

const INVERT_Y_AXIS = true;

const isDefined = v => v !== undefined && v !== null;

export default _survey => {
	const { isTiedToNative = false } = _survey || {};

	// CONSTANTS

	const gapThreshold = 20;
	const voltPercentThreashold = 5;

	// READING KEYS

	const readingKeys = [
		{
			label: 'ON',
			value: 'on',
			seriesType: 'Line',
			color: colors.getColor(),
			bcPriority: 0,
			getValue: r => formatMillivolt(r.on)
		}
	];
	const readingKeyProps = createReadingKeyProps(readingKeys);
	const readingKeysByBcPriority = [...readingKeys].sort(
		(a, b) => a.bcPriority - b.bcPriority
	);
	const computeYMinYMax = createComputeYMinYMax(readingKeys);

	// SKIP READING

	const isSkipReading = reading =>
		reading && readingKeys.every(rk => !reading[rk.value]);

	// HAS GAP

	const hasGap = (currReading, prevReading) => {
		const hasGapByStationId =
			!!prevReading && currReading.id - prevReading.id > gapThreshold;
		const hasGapByDistance = +currReading.dst > gapThreshold;
		return hasGapByStationId || hasGapByDistance;
	};

	// INTERPOLATE

	const interpolate = (
		// eslint-disable-next-line no-unused-vars
		_prevDepolReading,
		// eslint-disable-next-line no-unused-vars
		_nextDepolReading,
		// eslint-disable-next-line no-unused-vars
		_alignToReading
	) => {};

	const computeHilo = r => {
		r.low = r.low || 0;
	};

	const shouldStandardizeToMV = (cntReadings, cntVoltReadings) => {
		const voltPercent = (cntReadings / cntVoltReadings) * 100;
		return voltPercent >= voltPercentThreashold;
	};

	const standardizeToMV = reading => {
		computeMvTransform(reading, 'on');
	};

	// @note - @tobedeleted once mvfuzzy logic is removed;
	const computeAggregates = (currReading, aggregates = {}) => {
		aggregates.cntVoltReadings =
			(aggregates.cntVoltReadings || 0) +
			(assumeIsInMV(currReading.on) ? 0 : 1);

		return aggregates;
	};

	const computeProps = (survey, prevReading, currReading, currIdx) => {
		computeUuid(currReading, currIdx);
		computeFormattedStationId(currReading);
		computePipeDepth(currReading);
		computeAC(currReading);
		computeSpatial(currReading);
		computeDistance(prevReading, currReading);
		computeHilo(currReading);
		computeYMinYMax(survey, currReading);
	};

	// SIMPLIFIED READINGS - creates a smaller data set to be used for rendering polyline on map
	const createSimplifiedReadings = defaultCreateSimplifiedReadings;

	const columns = [
		{
			label: 'ID',
			value: 'formattedStationId',
			checked: true,
			width: 100,
			required: true
		},
		{ label: 'ON', value: 'on', checked: true, width: 44 },
		{
			label: 'DEPOL',
			value: 'depol',
			checked: !!_survey.withDepol,
			width: 80,
			getValue: (r, props) => {
				const { useInterpolatedDepol, useNormalizedDepol } = props;

				const computeDepol = () => {
					if (useInterpolatedDepol && useNormalizedDepol) {
						if (r.isNiDepolValNormalized && r.isNiDepolValInterpolated) {
							return `(n)(i) ${r.niDepolVal}`;
						}
						if (r.isNiDepolValNormalized) {
							return `(n) ${r.niDepolVal}`;
						}
						if (r.isNiDepolValInterpolated) {
							return `(i) ${r.niDepolVal}`;
						}
						return r.niDepolVal;
					}

					if (useInterpolatedDepol) {
						if (r.isInterpolated) {
							return `(i) ${r.iDepolVal}`;
						}
						return r.iDepolVal;
					}

					if (useNormalizedDepol) {
						if (r.isNormalized) {
							return `(n) ${r.nDepolVal}`;
						}
						return r.nDepolVal;
					}

					if (!useInterpolatedDepol && !useNormalizedDepol) {
						return r.depol;
					}

					return undefined;
				};

				return formatStrMillivolt(computeDepol());
			}
		},
		{ label: 'DISTANCE', value: 'dst', checked: true, width: 80 },
		{
			label: 'LAT',
			value: 'lat',
			checked: true,
			width: 65,
			getCsvValue: r => {
				if (isDefined(r.lat)) {
					return r.lat;
				}
				if (isDefined(r.loc)) {
					return r.loc[0];
				}
				return undefined;
			}
		},
		{
			label: 'LNG',
			value: 'lng',
			checked: true,
			width: 70,
			getCsvValue: r => {
				if (isDefined(r.lng)) {
					return r.lng;
				}
				if (isDefined(r.loc)) {
					return r.loc[1];
				}
				return undefined;
			}
		},
		{
			label: 'DATE',
			value: 'date',
			checked: false,
			width: 120,
			getValue: r => (isDate(r.date) ? MM_DD_YYYY(r.date) : '')
		},
		{
			label: 'COMMENTS',
			value: 'cmt',
			checked: true,
			width: 255,
			type: 'text'
		}
	];

	// MAIN
	const getReadingKeys = createGetReadingKeys(readingKeys);
	const getColumns = createGetColumns(columns);
	const transformToCsvJson = createTransformToCsvJson(getColumns);

	const meta = {
		...createBaseSurveyMeta(_survey),
		isChartInversed: INVERT_Y_AXIS,

		getReadingKeys,
		getReadingKeyProps: createGetReadingKeyProps(readingKeyProps),
		selectReadingKeysByBcPriority: () => readingKeysByBcPriority,
		getColumns,
		transformToCsvJson,
		getSurveyColor: createGetSurveyColor(getReadingKeys),
		fkey: 'on',
		isSkipReading,
		hasGap,
		canUseInterpolate: false,
		canUseNormalize: false,
		isInterpolateReference: true,
		isNormalizeReference: true,
		interpolate,
		computeProps,
		computeYMinYMax,
		shouldCheckMVOnLoad: true,
		shouldStandardizeToMV,
		standardizeToMV,
		computeAggregates,
		createSimplifiedReadings,
		canHaveCompositeDepol: true
	};

	if (isTiedToNative) {
		meta.toDisplayLabel = nativeToDisplayLabel;
	}

	return meta;
};
