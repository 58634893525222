export const ACTIONS_NAME_SPACE = 'fileDiff_computeDiffs';

export const SLICE_ROOT_KEY = 'computeDiffs';

// PROCESSING MESSAGES

export const MESSAGE_KEY_NO_MESSAGE = 'NO_MESSAGE';
export const MESSAGE_KEY_IS_COMPUTING_DIFFS = 'IS_COMPUTING_DIFFS';

export const PROCESSING_MESSAGE_DICTIONARY = {
	[MESSAGE_KEY_NO_MESSAGE]: undefined,
	[MESSAGE_KEY_IS_COMPUTING_DIFFS]: 'reading file'
};

// ERROR MESSAGES

export const ERROR_KEY_UNKNOWN_ERROR = 'UNKNOWN_ERROR';

export const ERROR_DICTIONARY = {
	[ERROR_KEY_UNKNOWN_ERROR]: 'An unknown error occured.'
};
