/* eslint-disable no-param-reassign */
// helpers for creating selectors which need no special logic
const returnCurry = v => () => v;

export const createGetReadingKeys = returnCurry;

export const createReadingKeyProps = readingKeys =>
	readingKeys.map(k => k.value);
export const createGetReadingKeyProps = returnCurry;

export const createGetColumns = returnCurry;

export const createGetSurveyColor = getReadingKeys => flags =>
	(getReadingKeys(flags) || [])[0]?.color;

const filterfallback = () => true;
const isValidFallback = r => r && !r.isFauxDataPoint && !r.isGap;
export const createTransformToCsvJson = (
	getColumns,
	defaultFilter,
	defaultIsValid
) => (
	data,
	{
		colConfig = {},
		surveyState = {},
		filter = defaultFilter || filterfallback,
		isValid = defaultIsValid || isValidFallback,
		// eslint-disable-next-line no-unused-vars
		injectProps = (currCsvItem, prevCsvItem, idx) => {}
	} = {}
) => {
	const columns = getColumns(colConfig);
	return data.reduce((acc, o) => {
		if (isValid(o) && filter(o)) {
			const csvItem = columns.reduce((acc2, c) => {
				if (c.getCsvValue) {
					acc2[c.label] = c.getCsvValue(o, surveyState);
				} else if (c.getValue) {
					acc2[c.label] = c.getValue(o, surveyState);
				} else if (c.value) {
					acc2[c.label] = o[c.value];
				}
				return acc2;
			}, {});
			csvItem.SEQ = acc.length + 1;
			const prevCsvItem = acc[acc.length - 1];
			injectProps(csvItem, prevCsvItem, acc.length);
			acc.push(csvItem);
		}
		return acc;
	}, []);
};
