/* eslint-disable import/no-cycle */
/* eslint-disable no-inner-declarations */
import { hasAccessToken } from '../../../../../../utils/token';
import ajax, { createHandleResolveReject } from '../../../../../../utils/ajax';

export const createProject = (jobId, data, lineName, callback) => {
	// @wbnote - this is in use
	if (hasAccessToken()) {
		const url = '/createProject';
		const postData = JSON.stringify({ jobId, projectData: data, lineName });

		function callCreateProject() {
			return new Promise((resolve, reject) => {
				const handleResponse = createHandleResolveReject(resolve, reject);
				ajax(url, postData, 'POST', handleResponse);
			});
		}

		callCreateProject()
			.then(res => {
				callback(null, res);
			})
			.catch(err => {
				callback(err);
			});
	}
};

export default { createProject };
