const FILE_FORMAT_ALIAS = {
	REV: 'DAT'
};

export function getFileFormat(fileName) {
	const fileFormat = /[^.]+$/.exec(fileName)[0].toUpperCase();
	return FILE_FORMAT_ALIAS[fileFormat] || fileFormat;
}

export function getFileName(key) {
	return key.replace(/^.*(\\|\/|:)/, '');
}

export function getFile(name, files) {
	const fileFormat = getFileFormat(name);
	if (files[fileFormat] === undefined) {
		return undefined;
	}
	const [matchFile] = files[fileFormat].filter(f => f.name === name);
	return matchFile;
}

export function replaceFile(file, files) {
	const { name } = file;
	const fileFormat = getFileFormat(name);
	if (files[fileFormat] === undefined || file === undefined) {
		return files;
	}

	const newFiles = { ...files };
	newFiles[fileFormat] = [...newFiles[fileFormat]];
	const matchFile = getFile(name, newFiles);

	if (matchFile === undefined) {
		return files;
	}

	newFiles[fileFormat].splice(newFiles[fileFormat].indexOf(matchFile), 1, file);
	return newFiles;
}

export function convertToBool(obj) {
	if (obj === undefined) {
		return false;
	}
	if (Array.isArray(obj)) {
		return obj.length > 0;
	}
	return String(obj).toLowerCase() === 'true';
}

export function cleanpUpMetadata(res) {
	const {
		Metadata: { errors, warnings }
	} = res;
	return {
		...res,
		Metadata: {
			...res.Metadata,
			warnings: convertToBool(warnings),
			errors: convertToBool(errors)
		}
	};
}
