class SubscriptionManager {
	constructor() {
		this.subscribed = [];
	}

	getSubscribed({ ignore = [] } = {}) {
		return this.subscribed.filter(({ o }) => {
			return ignore.indexOf(o) === -1;
		});
	}

	subscribe(obj, fnReceiveUpdate) {
		this.unsubscribe(obj);
		this.subscribed.push({ o: obj, f: fnReceiveUpdate });
	}

	unsubscribe(obj) {
		let currentIndex = Infinity;
		while (currentIndex > -1) {
			currentIndex = this.subscribed.findIndex(({ o }) => o === obj);
			if (currentIndex > -1) {
				this.subscribed.splice(currentIndex, 1);
			}
		}
	}
}

export default SubscriptionManager;
