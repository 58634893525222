import { createSelector } from 'reselect';

import { ID_KEY_APPLICATION, ROOT_SLICE } from './constants';

/* eslint-disable no-use-before-define */
export const selectStateSlice = state => {
	const { app } = state;
	return app;
};

export const selectStoreXYMinMaxSlice = appState => {
	const YMinYMax = (appState || {})[ROOT_SLICE];
	return YMinYMax;
};

// DATA

export const selectStoreDataXYMinMaxSlice = appState => {
	const storeXYMinMax = selectStoreXYMinMaxSlice(appState);
	return storeXYMinMax.data;
};

export const selectDataXYMinMax = (appState, key) => {
	const storeXYMinMax = selectStoreDataXYMinMaxSlice(appState);
	return storeXYMinMax[key];
};

// ZOOM

export const selectStoreZoomXYMinMaxSlice = appState => {
	const storeXYMinMax = selectStoreXYMinMaxSlice(appState);
	return storeXYMinMax.zoom;
};

export const selectZoomXYMinMax = (appState, key) => {
	const storeXYMinMax = selectStoreZoomXYMinMaxSlice(appState);
	return storeXYMinMax[key];
};

export const selectAppZoomXMinMax = appState => {
	const { xMin, xMax } = selectZoomXYMinMax(appState, ID_KEY_APPLICATION) || {};
	return { xMin, xMax };
};

// INPUT

export const selectStoreInputXYMinMaxSlice = appState => {
	const storeXYMinMax = selectStoreXYMinMaxSlice(appState);
	return storeXYMinMax.input;
};

export const selectInputXYMinMax = (appState, key) => {
	const storeXYMinMax = selectStoreInputXYMinMaxSlice(appState);
	return storeXYMinMax[key];
};

export const selectCurrentXYMinMax = (appState, key) => {
	const { yMin: ZoomYMin, yMax: ZoomYMax } =
		selectZoomXYMinMax(appState, key) || {};
	const { yMin: DataYMin, yMax: DataYMax } =
		selectDataXYMinMax(appState, key) || {};
	return {
		yMax: ZoomYMax === undefined ? DataYMax : ZoomYMax,
		yMin: ZoomYMin === undefined ? DataYMin : ZoomYMin
	};
};

const getXMin = (x, newX) => {
	if (x === undefined) {
		return newX;
	}
	return Math.min(x, newX);
};
const getXMax = (x, newX) => {
	if (x === undefined) {
		return newX;
	}
	return Math.max(x, newX);
};

export const selectHiddenControlSeries = createSelector(
	appState => selectStoreDataXYMinMaxSlice(appState),
	storeXYMinMax => {
		const xBoundaries = Object.keys(storeXYMinMax).reduce(
			(acc, key) => {
				const { xMin, xMax } = storeXYMinMax[key];
				return { xMin: getXMin(acc.xMin, xMin), xMax: getXMax(acc.xMax, xMax) };
			},
			{ xMin: undefined, xMax: undefined }
		);
		const { xMin, xMax } = xBoundaries;
		const series = {
			data: [
				{ id: xMin, value: 0 },
				{ id: xMax, value: 0 }
			],
			dataSetId: 'hiddenControlData',
			parsing: { xAxisKey: 'id', yAxisKey: 'value' },
			backgroundColor: 'rgba(0, 0, 0, 0)',
			borderColor: 'rgba(0, 0, 0, 0)',
			type: 'line',
			hiddenControlData: true
		};
		return [{ ...series }, { ...series, type: 'scatter' }];
	}
);
