/* eslint-disable import/prefer-default-export */
import { ROOT_SLICE } from './constants';
import { getEntityType, getId } from './util/itemGetters';

const isDefined = v => v !== undefined && v !== null;

export const selectRootSlice = stateAdmin => stateAdmin[ROOT_SLICE];

export const selectSaveErrorsByType = (
	stateAdmin,
	{ type: paramType, item }
) => {
	const type = isDefined(paramType) ? paramType : getEntityType(item);
	const slice = selectRootSlice(stateAdmin);
	return slice[type];
};

export const selectSaveError = (
	stateAdmin,
	{ type: paramType, itemId: paramItemId, item }
) => {
	const type = isDefined(paramType) ? paramType : getEntityType(item);
	const itemId = isDefined(paramItemId) ? paramItemId : getId(item);
	const saveErrors = selectSaveErrorsByType(stateAdmin, { type });
	return saveErrors[itemId];
};
