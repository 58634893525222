import ajax from '../../../../scanline/utils/ajax';
import { hasAccessToken } from '../../../../scanline/utils/token';

const isDefined = v => v !== undefined && v !== null;

export const listActionPlanClosureReports = ({ offset, limit } = {}, callback) => {
	const url =
		isDefined(offset) && isDefined(limit)
			? `/actionPlanClosureReport/${encodeURIComponent(
					'Customer'
			  )}/offset/${offset}/limit/${limit}`
			: `/actionPlanClosureReport/${encodeURIComponent('Customer')}`;

	if (hasAccessToken()) {
		ajax(url, 'dataIntegrity', 'POST', (err, res) => {
			if (err) {
				callback(err);
			} else {
				callback(null, res);
			}
		});
	}
};

const _exports = {
	listActionPlanClosureReports
};

export default {
	..._exports
};
