"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.calculateDirection = calculateDirection;
exports["default"] = void 0;
exports.getTurfDirection = getTurfDirection;

var _geographiclib = _interopRequireDefault(require("geographiclib"));

var _bearing = _interopRequireDefault(require("@turf/bearing"));

var _mapUtilsMisc = require("./mapUtilsMisc");

var _config = require("./config");

var _UnknownDistanceAlgorithmError = _interopRequireDefault(require("../ErrorClasses/UnknownDistanceAlgorithmError"));

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    "default": obj
  };
}

function _toConsumableArray(arr) {
  return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _unsupportedIterableToArray(arr) || _nonIterableSpread();
}

function _nonIterableSpread() {
  throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method.");
}

function _unsupportedIterableToArray(o, minLen) {
  if (!o) return;
  if (typeof o === "string") return _arrayLikeToArray(o, minLen);
  var n = Object.prototype.toString.call(o).slice(8, -1);
  if (n === "Object" && o.constructor) n = o.constructor.name;
  if (n === "Map" || n === "Set") return Array.from(o);
  if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen);
}

function _iterableToArray(iter) {
  if (typeof Symbol !== "undefined" && iter[Symbol.iterator] != null || iter["@@iterator"] != null) return Array.from(iter);
}

function _arrayWithoutHoles(arr) {
  if (Array.isArray(arr)) return _arrayLikeToArray(arr);
}

function _arrayLikeToArray(arr, len) {
  if (len == null || len > arr.length) len = arr.length;

  for (var i = 0, arr2 = new Array(len); i < len; i++) {
    arr2[i] = arr[i];
  }

  return arr2;
}

var geod = _geographiclib["default"].Geodesic.WGS84; // Taken from https://github.com/Turfjs/turf/blob/5a029b206c832d11e7a6a2d3e16e57628ae14ffb/packages/turf-helpers/index.ts#L688

function bearingToAzimuth(bearing) {
  var angle = bearing % 360;

  if (angle < 0) {
    angle += 360;
  }

  return angle;
}

function getTurfDirection(fromCoords, toCoords) {
  var options = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : {};
  var _options$useNegatives = options.useNegatives,
      useNegatives = _options$useNegatives === void 0 ? true : _options$useNegatives,
      _options$reverse = options.reverse,
      reverse = _options$reverse === void 0 ? true : _options$reverse;
  var bearing = (0, _bearing["default"])((0, _mapUtilsMisc.formatCoords)(fromCoords, reverse), (0, _mapUtilsMisc.formatCoords)(toCoords, reverse)); // Bearing is a number between -180 and 180

  if (useNegatives) {
    return bearing;
  } // Will return a number between 0 and 360


  return bearingToAzimuth(bearing);
}

var getGeodCoords = function getGeodCoords(coords1, coords2) {
  return [].concat(_toConsumableArray((0, _mapUtilsMisc.formatCoords)(coords1, true)), _toConsumableArray((0, _mapUtilsMisc.formatCoords)(coords2, true)));
};

function getGeodDirection(currentCoords, otherCoords, options) {
  var _options$useNegatives2 = options.useNegatives,
      useNegatives = _options$useNegatives2 === void 0 ? true : _options$useNegatives2; // expects y1, x1, y2, x2, so we always reverse (even if redundent)

  var r = geod.Inverse.apply(geod, _toConsumableArray(getGeodCoords(currentCoords, otherCoords)));

  if (useNegatives) {
    return r.azi1;
  }

  return bearingToAzimuth(r.azi1);
}

function getDirectionFromAlgo(currentCoords, otherCoords, options) {
  var _options$algorithm = options.algorithm,
      algorithm = _options$algorithm === void 0 ? _config.GEO_ALGORITHMS.GEOD : _options$algorithm;

  switch (algorithm) {
    case _config.GEO_ALGORITHMS.TURF:
      return getTurfDirection(currentCoords, otherCoords, options);

    case _config.GEO_ALGORITHMS.GEOD:
      return getGeodDirection(currentCoords, otherCoords, options);

    default:
      throw new _UnknownDistanceAlgorithmError["default"]("Unkown algorithm \"".concat(algorithm, "\""));
  }
}

function calculateDirection(currentCoords, otherCoords) {
  var options = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : {};
  var _options$reverse2 = options.reverse,
      reverse = _options$reverse2 === void 0 ? true : _options$reverse2; // Note that "from" is ignored from "options" as it is implied from the algorithm

  var direction = getDirectionFromAlgo((0, _mapUtilsMisc.formatCoords)(currentCoords, reverse), (0, _mapUtilsMisc.formatCoords)(otherCoords, reverse), options);
  return direction;
}

var _default = calculateDirection;
exports["default"] = _default;