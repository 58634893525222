/* eslint-disable no-use-before-define */
function noOp() {}

if (typeof window.URL.createObjectURL === 'undefined') {
	Object.defineProperty(window.URL, 'createObjectURL', { value: noOp });
}

/**
 * @description A class which generates a worker on call and destroys the worker once a message has been passed to the callback
 * @warning - IMPORTANT LIMITATION - the "processMessage" function must be serializable via .toString() - this means it cannot have any external references in function - this means it cannot reference any variables/functions defined outside of "processMessage".
 */
class MyWorker {
	constructor(processMessage) {
		this.workerBlob = createWorkerBlob(processMessage);
	}

	call(message, processResponse) {
		if (this.workerBlob) {
			// eslint-disable-next-line no-undef
			const worker = new Worker(this.workerBlob);

			worker.onmessage = resp => {
				processResponse(resp);
				worker.terminate();
			};

			worker.postMessage(message);
		}
	}
}

/**
 * @description Creates a worker blob which can be passed to new Worker(blob)
 * @param {*} processMessage A function that receives a message, parses it, processes it, and returns a response.
 * @warning - IMPORTANT LIMITATION - the "processMessage" function must be serializable via .toString() - this means it cannot have any external references in function - this means it cannot reference any variables/functions defined outside of "processMessage".
 */
const createWorkerBlob = (_processMessage = () => '') => {
	const workercode = () => {
		// eslint-disable-next-line no-unused-vars
		const onmessage = message => {
			const processMessage = _processMessage;
			const result = processMessage(message.data);
			// eslint-disable-next-line no-undef
			postMessage(result);
		};
	};

	let code = workercode.toString();
	code = code.substring(code.indexOf('{') + 1, code.lastIndexOf('}'));
	code = code.replace('_processMessage', _processMessage.toString());

	const blob = new Blob([code], { type: 'application/javascript' });
	return URL.createObjectURL(blob);
};

export default MyWorker;
