import { createActions } from 'redux-actions';
import { ACTIONS_NAME_SPACE } from './constants';

const {
	setFileMeta,
	setFileContent,
	setFileDocJson,
	setFileErrors,
	clearFile,
	clearAllFiles
} = createActions(
	{
		SET_FILE_META: (key, meta) => ({ key, meta }),
		SET_FILE_CONTENT: (key, content) => ({ key, content }),
		SET_FILE_DOC_JSON: (key, docJson) => ({ key, docJson }),
		SET_FILE_ERRORS: (key, errors) => ({ key, errors }),
		CLEAR_FILE: key => ({ key }),
		CLEAR_ALL_FILES: () => ({})
	},
	{ prefix: ACTIONS_NAME_SPACE }
);

export {
	setFileMeta,
	setFileContent,
	setFileDocJson,
	setFileErrors,
	clearFile,
	clearAllFiles
};
