import {
	selectAppStateDepolSurveyId,
	selectAppStatePrimaryChartId,
	selectAppStatePrimarySurveyId,
	selectAppStateThreshold,
	selectAppStateUseThreshold,
	selectAppStateUseShadow,
	selectAppStateRemediationActions,
	selectAppStateCustomGroups,
	selectAppStateUseInterpolatedDepol,
	selectAppStateUseNormalizedDepol,
	selectAppStateSeriesVisibility
} from '../reducers/slices/AppStateV2/selectors';
import { thunkComputeBelowCriterions } from '../reducers/slices/Charts/slices/ChartsBelowCriterions/actions';
import {
	setRemediationsActions,
	thunkClearCustomGroupRanges,
	thunkSetCustomGroupRanges
} from '../reducers/slices/Charts/slices/ChartsRemediations/actions';
import { addChartSettings } from '../reducers/slices/Charts/slices/ChartsSettings/actions';
import { createInitialChartSettings } from '../reducers/slices/Charts/slices/ChartsSettings/util';
import { selectDataXYMinMax } from '../reducers/slices/StoreXYMinMax/selectors';

export const loadChartSettings = (dispatch, getState) => {
	const stateApp = getState().app;

	const chartId = selectAppStatePrimaryChartId(stateApp);
	const primarySurveyId = selectAppStatePrimarySurveyId(stateApp);
	const depolSurveyId = selectAppStateDepolSurveyId(stateApp);
	const threshold = selectAppStateThreshold(stateApp);
	const useThreshold = selectAppStateUseThreshold(stateApp);
	const useShadow = selectAppStateUseShadow(stateApp);
	const allRemediationsActions = selectAppStateRemediationActions(stateApp);
	const remediationsActions =
		(allRemediationsActions || {})[primarySurveyId] || {};

	const customGroups = selectAppStateCustomGroups(stateApp);
	const useInterpolatedDepol = selectAppStateUseInterpolatedDepol(stateApp);
	const useNormalizedDepol = selectAppStateUseNormalizedDepol(stateApp);

	const seriesVisibility = selectAppStateSeriesVisibility(stateApp) || {};

	if (primarySurveyId) {
		// @todo  - find a better place for this transform to live
		// @note - it should likely live in app state actions or chart settings actions
		dispatch(
			addChartSettings(chartId, {
				...createInitialChartSettings(),
				chartId,
				thresholds: [threshold],
				useThreshold,
				useShadow,
				useInterpolatedDepol,
				useNormalizedDepol,
				primarySurveyId,
				depolSurveyId,
				seriesVisibility,
				boundaryXYMinMax: selectDataXYMinMax(stateApp, chartId)
			})
		);
		dispatch(thunkClearCustomGroupRanges(chartId));
		dispatch(thunkComputeBelowCriterions(chartId));
		dispatch(thunkSetCustomGroupRanges(chartId, customGroups));
		dispatch(setRemediationsActions(chartId, remediationsActions || {}));
	}
};

export default { loadChartSettings };
