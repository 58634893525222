import UrlRoutingUtils from './UrlRoutingUtils';

// There are query string parameters which can be added to trigger certain internal processes
// Example: http://localhost:3000/CISLine/Job/Show%20File/f05a56a9-b201-407d-a4af-ef8efb7afb69?tabSize=1&model=a&magic=true&stationAlignment=true

const isBoolean = fieldValue => {
	try {
		if (fieldValue && Boolean(fieldValue)) {
			return true;
		}
	} catch (e) {
		return false;
	}

	return false;
};

const allowMagicWandButton = magicToken => {
	return isBoolean(magicToken);
};

const allowStationAlignmentTab = stationAlignment => {
	return isBoolean(stationAlignment);
};

const userSpecifiedModel = model => {
	try {
		if (!model) {
			return undefined;
		}
		const modelName = model.toUpperCase();
		if (model && ['A', 'B'].includes(modelName)) {
			return modelName;
		}

		return undefined;
	} catch {
		// eslint-disable-next-line no-console
		console.error('Could not determine if manual model was set');
		return undefined;
	}
};

const DEVELOPER_PARAMS = {
	model: {
		fn: userSpecifiedModel, // (A|B) Allows to overwrite the default machine learning model
		message: model => `Using model "${model}".`
	},
	magic: {
		fn: allowMagicWandButton, // (bool) Provides the magic wand button (for spike editor)
		message: () => 'Allowing magic wand button.'
	},
	stationAlignment: {
		fn: allowStationAlignmentTab, // (bool) Provides access to the station alignment tab
		message: () => 'Allowing station alignment tab.'
	}
};

export const createMessageFromFoundSettings = settings => {
	const messages = [];
	const developerParamKeys = Object.keys(DEVELOPER_PARAMS);
	developerParamKeys.forEach(key => {
		const settingForKey = settings[key];
		if (!settingForKey) {
			return;
		}

		const { message } = DEVELOPER_PARAMS[key];

		messages.push(message(settingForKey));
	});

	return messages.join(' ');
};

export const getDeveloperSettingsFromUrl = () => {
	const developerSettings = {};
	const urlParams = UrlRoutingUtils.getQueryStringObject() || {};

	const developerParamKeys = Object.keys(DEVELOPER_PARAMS);
	developerParamKeys.forEach(key => {
		const queryValue = urlParams[key];
		if (queryValue === undefined) {
			return;
		}

		const { fn } = DEVELOPER_PARAMS[key];
		const settingValue = fn(queryValue);

		developerSettings[key] = settingValue;
	});

	return developerSettings;
};

export default getDeveloperSettingsFromUrl;
