/* eslint import/no-cycle:0 */
import {
	bearing as turfBearing,
	destination as turfDestination,
	distance as turfDistance
} from '@turf/turf';
import manholesData from '../../data/manholes';
import sewerLinesData from '../../data/sewerlines';
import { carouselChanged, setNewVideoTime } from './main';

export const NS_MAPS = 'videoline_maps_';

export const SET_CENTER = `${NS_MAPS}SET_CENTER`;
export const setCenter = center => ({
	type: SET_CENTER,
	payload: center
});

export const SET_TRACKER = `${NS_MAPS}SET_TRACKER`;
export const setTracker = geojson => ({
	type: SET_TRACKER,
	payload: geojson
});

export const setMapTrackerImage = ({ feet }) => (dispatch, getState) => {
	const state = getState();
	const {
		videoline: { maps }
	} = state;
	if (!maps.selectedManholes) {
		return;
	}
	const {
		selectedManholes: { upstream, downstream }
	} = maps;

	const {
		geometry: { coordinates: upstreamCoords }
	} = upstream;
	const {
		geometry: { coordinates: downstreamCoords }
	} = downstream;

	const bearing = turfBearing(downstreamCoords, upstreamCoords);
	const destination = turfDestination(
		downstreamCoords,
		Math.abs(feet) * 0.0003048,
		bearing
	);

	dispatch(setTracker(destination));
};

export const SET_MANHOLES = `${NS_MAPS}SET_MANHOLES`;
export const setManholes = geojson => ({
	type: SET_MANHOLES,
	payload: geojson
});

export const SET_SELECTED_MANHOLES = `${NS_MAPS}SET_SELECTED_MANHOLES`;
export const setSelectedManholes = selected => ({
	type: SET_SELECTED_MANHOLES,
	payload: selected
});

export const SET_SEWER_LINES = `${NS_MAPS}SET_SEWER_LINES`;
export const setSewerLines = geojson => ({
	type: SET_SEWER_LINES,
	payload: geojson
});

export const findSelectedManholes = () => (dispatch, getState) => {
	const state = getState();
	const {
		videoline: { main, maps }
	} = state;
	const {
		video: { manholes },
		selectedImage
	} = main;

	const { manholes: geojson } = maps;
	const { downstream, upstream } = manholes;
	const { features } = geojson;
	const filteredManholes = features.filter(
		({ properties: { Manhole_Id: manholeId } }) =>
			manholeId === downstream || manholeId === upstream
	);
	const [upManhole] = filteredManholes.filter(
		({ properties: { Manhole_Id: manholeId } }) => manholeId === upstream
	);
	const [downManhole] = filteredManholes.filter(
		({ properties: { Manhole_Id: manholeId } }) => manholeId === downstream
	);
	dispatch(
		setSelectedManholes({ upstream: upManhole, downstream: downManhole })
	);
	if (selectedImage) {
		dispatch(setMapTrackerImage(selectedImage));
	}
};

export const findSelectedSewerLine = () => (dispatch, getState) => {
	const state = getState();
	const {
		videoline: { main, maps }
	} = state;
	const {
		video: { manholes }
	} = main;

	const { sewerLines: geojson } = maps;
	const { downstream, upstream } = manholes;
	const { features } = geojson;
	const [sewerLine] = features.filter(
		({ properties: { Pipe_Id: pipeId } }) =>
			pipeId === `${downstream}-${upstream}`
	);

	dispatch(setSelectedManholes({ sewerLine }));
};

export const loadMapData = () => dispatch => {
	manholesData().then(geojson => {
		dispatch(setManholes(geojson));
		dispatch(findSelectedManholes());
	});
	sewerLinesData().then(geojson => {
		dispatch(setSewerLines(geojson));
		dispatch(findSelectedSewerLine());
	});
};

export const updateTracker = time => (dispatch, getState) => {
	const state = getState();
	const {
		videoline: { main }
	} = state;
	const { selectedImage, nextImage, images } = main;
	if (selectedImage.videoLocation <= time && nextImage.videoLocation >= time) {
		const timeInterval = nextImage.videoLocation - selectedImage.videoLocation;
		const timeSpanLoc = selectedImage.videoLocation - time;
		const distanceInterval = nextImage.feet - selectedImage.feet;
		const feet =
			(timeSpanLoc / timeInterval) * distanceInterval - selectedImage.feet;
		dispatch(setMapTrackerImage({ feet }));
		return;
	}
	const [trackImage] = images.filter((img, idx) => {
		const [nextImg] = images.slice(idx + 1, idx + 2);

		return (
			img.videoLocation <= time && nextImg && nextImg.videoLocation >= time
		);
	});
	if (trackImage) {
		dispatch(carouselChanged(trackImage));
	}
};

export const sewerLineClick = coordinates => (dispatch, getState) => {
	const {
		videoline: { maps, main }
	} = getState();
	const { selectedManholes = {} } = maps;

	const { downstream } = selectedManholes;
	const {
		geometry: { coordinates: downstreamCoords }
	} = downstream;
	const { images } = main;
	const feet = turfDistance(downstreamCoords, coordinates) * 3280.84;
	// setMapTrackerImage({ feet });
	// console.log(feet);
	const [image] = images.filter(({ feet: imgFeet }, idx) => {
		return (
			idx < images.length - 1 &&
			Math.abs(imgFeet) <= feet &&
			Math.abs(images[idx + 1].feet) > feet
		);
	});
	if (image) {
		const [nextImage] = images.slice(images.indexOf(image) + 1);
		const { feet: imgFeet, videoLocation } = image;
		const { feet: nextImgFeet, videoLocation: nextVideoLocation } = nextImage;
		const pos = (feet - Math.abs(imgFeet)) / (imgFeet - nextImgFeet);
		const time = (nextVideoLocation - videoLocation) * pos + videoLocation;

		dispatch(setNewVideoTime(time));
		// dispatch(updateTracker(time));
	}
};
