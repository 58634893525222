import { SLICE_ROOT_KEY } from './constants';
import { isValidKey } from './util';

export const selectSliceState = appState => appState[SLICE_ROOT_KEY];

export const selectAllFileMeta = appState => {
	const sliceState = selectSliceState(appState);
	const { fileMeta } = sliceState;
	return fileMeta;
};

export const selectFileMeta = (appState, key) => {
	if (!isValidKey) {
		return undefined;
	}

	const fileMeta = selectAllFileMeta(appState);
	return fileMeta[key];
};

export const selectAllFileContent = appState => {
	const sliceState = selectSliceState(appState);
	const { fileContent } = sliceState;
	return fileContent;
};

export const selectFileContent = (appState, key) => {
	if (!isValidKey) {
		return undefined;
	}

	const fileContent = selectAllFileContent(appState);
	return fileContent[key];
};

export const selectAllFileDocJson = appState => {
	const sliceState = selectSliceState(appState);
	const { fileDocJson } = sliceState;
	return fileDocJson;
};

export const selectFileDocJson = (appState, key) => {
	if (!isValidKey) {
		return undefined;
	}

	const fileDocJson = selectAllFileDocJson(appState);
	return fileDocJson[key];
};

export const selectAllFileErrors = appState => {
	const sliceState = selectSliceState(appState);
	const { fileErrors } = sliceState;
	return fileErrors;
};

export const selectFileErrors = (appState, key) => {
	if (!isValidKey) {
		return undefined;
	}

	const fileErrors = selectAllFileErrors(appState);
	return fileErrors[key];
};
