import scanlineMapUtils from '../../../mapUtils';

const isDefined = v => v !== null && v !== undefined;
export const computeRemediationGroups = (
	survey,
	readings,
	depolReadings,
	bcSeriesDs,
	criterionInfo,
	bcReadingById,
	computedBcMap,
	customExceptionsMap
) => {
	// eslint-disable-next-line no-unused-vars
	const { withDepol } = survey;

	const exceptions = scanlineMapUtils.getExceptionsInfo(
		readings,
		depolReadings,
		criterionInfo,
		bcSeriesDs,
		bcReadingById,
		computedBcMap,
		customExceptionsMap
	);

	const groupsRanges = !exceptions
		? []
		: exceptions.list
				.map(group => [
					{
						id: group.start.id,
						uuid: group.start.uuid,
						y: 0.5
					},
					{
						id: group.end.id,
						uuid: group.end.uuid,
						y: 0.5
					}
				])
				.filter(g => g[0] && isDefined(g[0].id) && g[1] && isDefined(g[1].id));

	return groupsRanges;
};

export default { computeRemediationGroups };
