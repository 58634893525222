/* eslint-disable import/no-cycle */
import { createActions } from 'redux-actions';
import { createGetStateFromUrl, createPushStateToUrl } from '../util';
import { fetchProcessors } from './api';
import { isEquivalent } from './util';

export const buildActions = (
	namespace,
	rootSliceKey,
	mainRedux,
	mySelectors,
	filtersStatePropKey,
	filterStatePropKey = 'processors'
) => {
	const { setIsLoadingFilters, setIsLoadedFilters } = mainRedux.actions;
	const { selectSliceState, selectIsAnyFilterLoading } = mainRedux.selectors;
	const { selectProcessorSelectedList } = mySelectors;

	const getStateFromUrl = createGetStateFromUrl(
		namespace,
		rootSliceKey,
		filtersStatePropKey,
		filterStatePropKey
	);

	const pushStateToUrl = createPushStateToUrl(
		namespace,
		rootSliceKey,
		filtersStatePropKey,
		filterStatePropKey
	);

	const {
		reInitStateProcessors,
		requestProcessors,
		receiveProcessors,
		clearSelectedProcessors,
		setSelectedProcessors,
		addSelectedProcessor,
		removeSelectedProcessor,
		setLoadProcessorsErrorMessage,
		clearLoadProcessorsErrorMessage
	} = createActions(
		{
			RE_INIT_STATE_PROCESSORS: () => ({}),
			REQUEST_PROCESSORS: () => ({}),
			RECEIVE_PROCESSORS: list => ({ list }),
			CLEAR_SELECTED_PROCESSORS: () => ({}),
			SET_SELECTED_PROCESSORS: (selectedList = []) => ({ selectedList }),
			ADD_SELECTED_PROCESSOR: item => ({ item }),
			REMOVE_SELECTED_PROCESSOR: item => ({ item }),
			SET_LOAD_PROCESSORS_ERROR_MESSAGE: message => ({ message }),
			CLEAR_LOAD_PROCESSORS_ERROR_MESSAGE: () => ({})
		},
		{ prefix: namespace }
	);

	const getSelectedFromUrl = getState => {
		const sliceState = selectSliceState(getState());
		const list = mySelectors.selectProcessorList(sliceState);
		const urlSelected = (getStateFromUrl() || {}).selected || [];
		const selectedList = urlSelected
			.map(i => list.find(i2 => isEquivalent(i, i2)))
			.filter(v => !!v);
		return selectedList;
	};

	const thunkLoadProcessors = () => (dispatch, getState) => {
		dispatch(reInitStateProcessors());
		dispatch(requestProcessors());
		dispatch(setIsLoadingFilters(true));

		fetchProcessors((err, list) => {
			dispatch(receiveProcessors(list));
			const selectedList = getSelectedFromUrl(getState);
			dispatch(setSelectedProcessors(selectedList));
			const sliceState = selectSliceState(getState());
			if (!selectIsAnyFilterLoading(sliceState)) {
				dispatch(setIsLoadingFilters(false));
				dispatch(setIsLoadedFilters(true));
			}
		});
	};

	const _toggleProcessor = item => (dispatch, getState) => {
		const sliceState = selectSliceState(getState());
		const selected = selectProcessorSelectedList(sliceState);
		const isSelected = selected.indexOf(item) > -1;
		if (isSelected) {
			dispatch(removeSelectedProcessor(item));
		} else {
			dispatch(addSelectedProcessor(item));
		}
	};

	const createWithUrlSideAffect = fnAction => (...args) => (
		dispatch,
		getState
	) => {
		dispatch(fnAction(...args));
		const sliceState = selectSliceState(getState());
		const filterState = mySelectors.selectProcessorFilterState(sliceState);
		pushStateToUrl(filterState);
	};

	const thunkClearSelectedProcessors = createWithUrlSideAffect(
		clearSelectedProcessors
	);
	const thunkSetSelectedProcessors = createWithUrlSideAffect(
		setSelectedProcessors
	);
	const thunkAddSelectedProcessor = createWithUrlSideAffect(
		addSelectedProcessor
	);
	const thunkRemoveSelectedProcessor = createWithUrlSideAffect(
		removeSelectedProcessor
	);
	const toggleProcessor = createWithUrlSideAffect(_toggleProcessor);

	return {
		reInitStateProcessors,
		requestProcessors,
		receiveProcessors,
		clearSelectedProcessors,
		setSelectedProcessors,
		addSelectedProcessor,
		removeSelectedProcessor,
		setLoadProcessorsErrorMessage,
		clearLoadProcessorsErrorMessage,
		toggleProcessor,
		thunkLoadProcessors,
		thunkClearSelectedProcessors,
		thunkSetSelectedProcessors,
		thunkAddSelectedProcessor,
		thunkRemoveSelectedProcessor
	};
};

export default {
	buildActions
};
