/* eslint-disable import/no-cycle */
import {
	SET_SELECTED_CUSTOMER,
	SET_SHOW_KMZ_MODAL,
	SET_IS_UPLOADING,
	SET_CUSTOMERS_KMZS,
	SET_SELECTED_KMZS
} from '../actions/kmz.actions';

const defaultState = {
	showKMZModal: false,
	selectedCustomer: undefined,
	isUploading: false,
	customersKMZs: undefined,
	selectedKMZs: []
};

const reducer = (state = defaultState, action) => {
	const { type, payload } = action;
	switch (type) {
		case SET_SELECTED_CUSTOMER:
			return { ...state, selectedCustomer: payload };
		case SET_SHOW_KMZ_MODAL:
			return { ...state, showKMZModal: payload };
		case SET_IS_UPLOADING:
			return { ...state, isUploading: payload };
		case SET_CUSTOMERS_KMZS:
			return { ...state, customersKMZs: payload };
		case SET_SELECTED_KMZS:
			return { ...state, selectedKMZs: payload };
		default:
			return state;
	}
};

export default reducer;
