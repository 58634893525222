export function convert(value) {
	if (value === undefined || value === null) {
		return value;
	}
	if (typeof value === 'string') {
		if (/\s*/.test(value)) {
			return value;
		}
		return Number(value).valueOf();
	}
	return value;
}

export function getValue(original, value, showOriginal) {
	if (showOriginal && original !== undefined) {
		return convert(original);
	}
	return convert(value);
}

export function getSpikeEditorReadingFieldValue(
	readingField,
	spikeEditorData,
	index,
	useMv,
	readingValue
) {
	if (!spikeEditorData) {
		return null;
	}

	let spikeEditorField;
	// let originalReadingField;
	let reading;

	switch (readingField) {
		case 'on': {
			spikeEditorField = getValue(
				spikeEditorData[index].on,
				readingValue.reading1,
				true
			);
			reading = getValue(
				readingValue.originalReading1,
				readingValue.reading1,
				true
			);
			break;
		}
		case 'off': {
			spikeEditorField = getValue(
				spikeEditorData[index].off,
				readingValue.reading2,
				true
			);
			reading = getValue(
				readingValue.originalReading2,
				readingValue.reading2,
				true
			);
			break;
		}
		case 'acOn': {
			spikeEditorField = getValue(
				spikeEditorData[index].acOn,
				readingValue.acOn,
				true
			);
			reading = getValue(readingValue.originalAcOn, readingValue.acOn, true);
			break;
		}
		case 'acOff': {
			spikeEditorField = getValue(
				spikeEditorData[index].acOff,
				readingValue.acOff,
				true
			);
			reading = getValue(readingValue.originalAcOff, readingValue.acOff, true);
			break;
		}
		default:
			throw new Error(`Unknown reading field ${readingField}`);
	}

	// Only use the value if it is different from the original value
	if (spikeEditorField === reading) {
		return null;
	}

	if (!spikeEditorField) {
		return null;
	}

	// The reading might have many decimal places, which we do not want to display
	if (useMv && (readingField === 'on' || readingField === 'off')) {
		return Math.round(spikeEditorField);
	}

	return spikeEditorField;
}

export function showOn(on, onOffMethod, fileName) {
	if (on !== 0) {
		return true;
	}
	if (fileName.toLowerCase().indexOf('.csv') > -1 && on === 0) {
		return true;
	}
	if (onOffMethod === 'GpsSync') {
		return true;
	}

	return false;
}
export function showOff(on, onOff, onOffMethod, fileName) {
	if (fileName.toLowerCase().indexOf('.csv') > -1) {
		return true;
	}
	if (onOffMethod === 'GpsSync') {
		return true;
	}
	if (onOff !== 'T') {
		return false;
	}
	if (on === 0) {
		return true;
	}
	return false;
}

export function getSpikeEditorReadingClass(
	readingField,
	spikeEditorData,
	index,
	readingValue
) {
	if (spikeEditorData === undefined || spikeEditorData === null) {
		return '';
	}

	let spikeEditorField;
	let originalReadingField;
	let reading;

	switch (readingField) {
		case 'on': {
			spikeEditorField = spikeEditorData[index].on;
			reading = readingValue.reading1;
			originalReadingField = readingValue.originalReading1;
			break;
		}
		case 'off': {
			spikeEditorField = spikeEditorData[index].off;
			reading = readingValue.reading2;
			originalReadingField = readingValue.originalReading2;
			break;
		}
		case 'acOn': {
			spikeEditorField = convert(spikeEditorData[index].acOn);
			reading = convert(readingValue.acOn);
			originalReadingField = convert(readingValue.originalAcOn);
			break;
		}
		case 'acOff': {
			spikeEditorField = convert(spikeEditorData[index].acOff);
			reading = convert(readingValue.acOff);
			originalReadingField = convert(readingValue.originalAcOff);
			break;
		}
		default:
			throw new Error(`Unknown reading field ${readingField}`);
	}
	if (spikeEditorField === undefined) {
		return '';
	}
	if (spikeEditorField !== reading) {
		return 'saving';
	}
	if (originalReadingField !== undefined) {
		return 'saved';
	}
	return '';
}
