import {
	LINES_NO_LINES_MESSAGE,
	LINES_NO_LINES_MATCHING_FILTERS_MESSAGE
} from '../constants';

export const createKey = (prefix, line) => {
	return `${prefix}-${line.defaultJobId}-${line.name}`;
};

export const addTagToLine = (line = {}, tagName) => {
	const { tags = [] } = line;

	const hasTag = tags.findIndex(tag => tag.name === tagName) > -1;

	if (!hasTag) {
		return {
			...line,
			tags: [
				...tags,
				{
					name: tagName
				}
			]
		};
	}

	return line;
};

export const removeTagFromLine = (line, tagName) => {
	const { tags = [] } = line;
	return {
		...line,
		tags: tags.filter(tag => tag.name !== tagName)
	};
};

export const replaceLineInArray = (arr = [], line1, line2) => {
	const idx = arr.indexOf(line1);
	if (idx > -1) {
		const clone = [...arr];
		clone.splice(idx, 1, line2);
		return clone;
	}
	return arr;
};

export const replaceLineInLinesByPage = (linesByPage = {}, line1, line2) => {
	const pages = Object.keys(linesByPage);

	const page = pages.reduce((acc, p) => {
		if (acc > 0) {
			return acc;
		}

		const idx = linesByPage[p].indexOf(line1);
		if (idx > -1) {
			return p;
		}

		return acc;
	}, 0);

	if (page > 0) {
		return {
			...linesByPage,
			[page]: replaceLineInArray(linesByPage[page], line1, line2)
		};
	}

	return linesByPage;
};

export const getNoLinesMessage = linesState => {
	const { filters, lines } = linesState;

	if (lines.length > 0) {
		return undefined;
	}

	if (filters.length > 0) {
		return LINES_NO_LINES_MATCHING_FILTERS_MESSAGE;
	}

	return LINES_NO_LINES_MESSAGE;
};
