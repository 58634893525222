/* eslint-disable no-use-before-define */
import MyWorker from './MyWorker';

export const MAX_RECORDS = 7500;

export const filterByStdDev = messageData => {
	const ON_OFF = 'ON_OFF';
	const ON = 'ON';
	const OFF = 'OFF';
	const DEPOL = 'DEPOL';
	const NATIVE = 'NATIVE';
	const AC = 'AC';
	const DOC = 'DOC';
	const ACVG = 'ACVG';
	const DCVG = 'DCVG';
	const ACCA = 'ACCA';

	const KEYS_BY_SUBTYPE = {
		[ON_OFF]: ['on', 'off'],
		[ON]: ['on'],
		[OFF]: ['off'],
		[DEPOL]: ['on', 'sh'],
		[NATIVE]: ['on', 'sh'],
		[AC]: ['acPure', 'acWarning', 'acProblem'],
		[DOC]: ['topDep', 'copDep', 'elcDep', 'prbDep'],
		[ACVG]: ['decMicroV', 'normDecMicroV'],
		[DCVG]: ['ir_pct'],
		[ACCA]: ['ir_pct']
	};

	const isReadingWithinRange = (reading, keys, minsMaxs) => {
		if (reading) {
			const defaultResult = false;
			return keys.reduce((result, key, idx) => {
				const value = reading[key];
				const { min, max } = minsMaxs[idx];
				return value >= min && value <= max ? true : result;
			}, defaultResult);
		}
		return true;
	};

	const getStatistics = (data, keys) => {
		return keys.reduce((result, key) => {
			const values = data
				.map(item => item[key])
				.filter(value => value !== undefined);
			const avg = getAverage(values);
			const squareDiffs = values.map(value => {
				const diff = value - avg;
				return diff * diff;
			});
			const avgSquareDiff = getAverage(squareDiffs);
			const stdDev = Math.sqrt(avgSquareDiff);
			return [...result, { avg, avgSquareDiff, stdDev }];
		}, []);
	};

	const getAverage = data => {
		const sum = data.reduce((acc, value) => acc + value, 0);
		const avg = sum / data.length;
		return avg;
	};

	const _filterByStdDev = (readings, subtype, propsOverride, maxRecords) => {
		const keys =
			propsOverride && propsOverride.length > 0
				? propsOverride
				: KEYS_BY_SUBTYPE[subtype];

		if (readings.length <= maxRecords || !keys) {
			return readings;
		}

		// if we use only floor(n/max) the filtered data will
		// often fall below max, so we add -1 to prevent this
		const groupCount = Math.max(
			Math.floor(readings.length / maxRecords) - 1,
			2
		);
		const stats = getStatistics(readings, keys);
		const filteredReadings = [];

		let idx = 0;
		const secondToLastIdx = Math.max(readings.length - 2, 0);
		while (idx <= secondToLastIdx) {
			const reading = readings[idx];

			if (reading) {
				filteredReadings.push(reading);

				// find range
				const minsMaxs = keys.reduce((result, key, idx2) => {
					const min = reading[key] - stats[idx2].stdDev * 1.5;
					const max = reading[key] + stats[idx2].stdDev * 1.5;
					return [...result, { min, max }];
				}, []);

				// check if the all readings in group are within range
				const idxEndOfGroup = Math.min(idx + groupCount, secondToLastIdx);
				const allWithinRange = readings
					.slice(idx, idxEndOfGroup + 1)
					.every(r => isReadingWithinRange(r, keys, minsMaxs));

				// increment idx by 1 if out of range, by group count if all readings within range
				const iter = allWithinRange ? groupCount : 1;
				idx += iter;
			} else {
				idx += 1;
			}
		}

		// always include last reading (just as first reading will always be included)
		if (idx > 0 && idx === readings.length - 1 && readings[idx]) {
			filteredReadings.push(readings[idx]);
		}
		return filteredReadings;
	};

	// MAIN
	const {
		readings = [],
		subtype,
		propsOverride,
		maxRecords = MAX_RECORDS
	} = messageData;
	return _filterByStdDev(readings, subtype, propsOverride, maxRecords);
};

export default new MyWorker(filterByStdDev);
