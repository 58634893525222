/* eslint-disable import/prefer-default-export */
import { createActions } from 'redux-actions';

const {
	setDataWrappers,
	setDataWrapper,
	deleteDataWrapper,
	deleteAllDataWrappers,
	clearStore
} = createActions(
	{
		SET_DATA_WRAPPERS: dataWrappers => {
			return { dataWrappers };
		},
		SET_DATA_WRAPPER: (targetKey, parentKey, modifierKey, dataWrapper) => {
			return { targetKey, parentKey, modifierKey, dataWrapper };
		},
		DELETE_DATA_WRAPPER: (targetKey, parentKey, modifierKey) => {
			return { targetKey, parentKey, modifierKey };
		},
		DELETE_ALL_DATA_WRAPPERS: targetKey => {
			return { targetKey };
		},
		CLEAR_STORE: () => ({})
	},
	{ prefix: 'ReadingsStore' }
);

export {
	setDataWrappers,
	setDataWrapper,
	deleteDataWrapper,
	deleteAllDataWrappers,
	clearStore
};
