/* eslint-disable no-unused-vars */
/**
 * @NOTE = copy/pasted/modified from src/assetView/components/Manager/Correlator/utils/cognito.js
 */

/* eslint-disable import/no-cycle */
/* eslint-disable import/prefer-default-export */
import AWS from 'aws-sdk';
import _ from 'lodash';
import { getUserSession, isTokenValid } from './user';
import AccountUtil from '../account/components/utils/AccountUtil';
import getAipUrls from '../config/getAipUrls';
import scanlineUtils from '../scanline/scanlineUtils';
import { asyncTimeout } from './persist/utils/s3.api/helpers';

const aipUrls = getAipUrls();

const renderAsText = val => {
	if (typeof val === 'string') {
		return val;
	}

	try {
		return JSON.stringify(val, undefined, 1);
	} catch (err) {
		return val;
	}
};

class TokenRefresher {
	constructor() {
		this.refreshPromise = undefined;
	}

	handleResponse = res => {
		this.refreshPromise = undefined;
		return res;
	};

	handleErrorResponse = err => {
		this.refreshPromise = undefined;
		scanlineUtils.emailCustomerSupport(
			{
				appendToSubject: 'AIP - refresh token request failed.',
				content: `
          <div>
            <h2>AIP - refresh token request failed.</h2>
            <div>${renderAsText(err)}</div>
          <div>
        `,
				docType: 'HTML'
			},
			{ ignoreError: true }
		);

		throw err;
	};

	async refresh() {
		if (this.refreshPromise) {
			return this.refreshPromise;
		}

		this.refreshPromise = AccountUtil.refresh()
			.then(this.handleResponse)
			.catch(this.handleErrorResponse);
		return this.refreshPromise;
	}
}

export const tokenRefresher = new TokenRefresher();

let credentials;
let credentialsKey;

const clearCredentials = () => {
	credentials = undefined;
	credentialsKey = undefined;
};

const getCredentials = (idToken, fifteenMinInterval) => {
	const newCredentialsKey = JSON.stringify([idToken, fifteenMinInterval]);
	if (credentialsKey !== newCredentialsKey) {
		credentialsKey = newCredentialsKey;
		credentials = new AWS.CognitoIdentityCredentials({
			IdentityPoolId: aipUrls.identifypoolid,
			Logins: {
				[aipUrls.poolid]: idToken
			}
		});
	}
	return credentials;
};

const emailCustomerSupportAuthFailed = (error, attempt) => {
	const title = `Auth s3 credentials attempt ${attempt} failed`;
	scanlineUtils.emailCustomerSupport(
		{
			appendToSubject: title,
			content: `
        <div>
          <h2>${title}</h2>
          <div>Error: ${renderAsText(error)}</div>
        <div>
      `,
			docType: 'HTML'
		},
		{ ignoreError: true }
	);
};

export const assumeCognitoUser = async () => {
	// @note used to force memoize to get newly authenticated credentials atleast every 15 mins
	const fifteenMinInterval = Math.round(new Date().getMinutes() / 15);
	if (!isTokenValid()) {
		await tokenRefresher.refresh();
	}
	const userSession = getUserSession();
	const {
		idToken: { jwtToken: idToken }
	} = userSession;
	AWS.config.region = 'us-west-2'; // Region
	AWS.config.credentials = getCredentials(idToken, fifteenMinInterval);
	if (!AWS.config.credentials.cognito) {
		await AWS.config.credentials.getPromise().catch(async error => {
			clearCredentials();
			emailCustomerSupportAuthFailed(error, 1);

			await asyncTimeout(300);
			AWS.config.credentials = getCredentials(idToken, fifteenMinInterval);
			return AWS.config.credentials.getPromise().catch(error2 => {
				emailCustomerSupportAuthFailed(error2, 2);
				throw error2;
			});
		});
	}
	return AWS;
};
