/* eslint-disable import/prefer-default-export */
/* eslint-disable import/no-cycle */
import { createActions } from 'redux-actions';
import { ROOT_SLICE } from '../constants';

const {
	setPermissionDefinitions,
	setIsLoadingLoadedPermissionDefinitions,
	setHasLoadingErrorPermissionDefinitions
} = createActions(
	{
		SET_PERMISSION_DEFINITIONS: ({
			permissionDefinitions,
			mapPermissionsByGroupName,
			listPermissionDefinitions,
			mapPermissionsListByAppKey
		}) => ({
			permissionDefinitions,
			mapPermissionsByGroupName,
			listPermissionDefinitions,
			mapPermissionsListByAppKey
		}),
		SET_IS_LOADING_LOADED_PERMISSION_DEFINITIONS: ({
			isLoadingPermissionDefinitions,
			isLoadedPermissionDefinitions
		}) => ({ isLoadingPermissionDefinitions, isLoadedPermissionDefinitions }),
		SET_HAS_LOADING_ERROR_PERMISSION_DEFINITIONS: hasError => ({ hasError })
	},
	{ prefix: ROOT_SLICE }
);

export {
	setPermissionDefinitions,
	setIsLoadingLoadedPermissionDefinitions,
	setHasLoadingErrorPermissionDefinitions
};
