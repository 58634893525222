import { selectSliceCisview } from '../selectors/HELPERS';

const { createSlice, createSelector } = require('@reduxjs/toolkit');

const EMPTY = {};

const skipSlice = createSlice({
	name: 'skips',
	initialState: {
		isExpanded: true,
		skips: [],
		unfilteredSkips: []
	},
	reducers: {
		setup: (state, action) => {
			const { payload } = action;
			const { skips } = payload;

			if (!state.isExpanded) {
				state.skips = skips.filter(r => r.code !== 4);
			}
			if (state.isExpanded) {
				state.skips = skips;
			}
			state.unfilteredSkips = skips;
		},
		setIsExpanded: (state, action) => {
			const { payload } = action;
			state.isExpanded = payload;
			if (payload) {
				state.skips = state.unfilteredSkips;
			} else {
				state.skips = state.unfilteredSkips.filter(r => r.code !== 4);
			}
		}
	}
});

export const { setup, setIsExpanded } = skipSlice.actions;

export const { selectGroups } = skipSlice.selectors;

const selectSkipsSlice = createSelector(
	[selectSliceCisview],
	cisview => cisview.skips
);

export const selectSkips = createSelector(
	[selectSkipsSlice],
	slice => slice.skips
);
export const selectIsExpanded = createSelector(
	[selectSkipsSlice],
	slice => slice.isExpanded
);

export default skipSlice.reducer;
