export const FETCH_PROJECTS_START = 'FETCH_PROJECTS_START';
export const FETCH_PROJECTS_SUCCESS = 'FETCH_PROJECTS_SUCCESS';
export const FETCH_PROJECTS_NAMELIST_SUCCESS =
	'FETCH_PROJECTS_NAMELIST_SUCCESS';
export const FETCH_PROJECTS_FAIL = 'FETCH_PROJECTS_FAIL';
export const PROJECTS_SET_FILTERS = 'PROJECTS_SET_FILTERS';

export const FETCH_PROJECTS_META_START = 'FETCH_PROJECTS_META_START';
export const FETCH_PROJECTS_META_SUCCESS = 'FETCH_PROJECTS_META_SUCCESS';
export const FETCH_PROJECTS_META_FAIL = 'FETCH_PROJECTS_META_FAIL';

export const SET_CURRENT_PROJECTS_PAGE = 'SET_CURRENT_PROJECTS_PAGE';

export const CLEAR_CACHED_PROJECTS_DATA = 'CLEAR_CACHED_PROJECTS_DATA';

export const SET_IS_STANDALONE_PROJECT = 'SET_IS_STANDALONE_PROJECT';
