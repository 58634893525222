"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

var getStationId = function getStationId(dat, reading) {
  var StationId = reading.StationId;
  var reverse = dat.reverse,
      endStn = dat.endStn,
      calcEnd = dat.calcEnd,
      startStn = dat.startStn,
      calcStart = dat.calcStart;

  if (reverse) {
    if (endStn !== undefined) {
      return endStn - StationId;
    }

    return calcEnd - StationId;
  }

  if (startStn !== undefined) {
    return startStn + StationId;
  }

  return calcStart + StationId;
};

var _default = getStationId;
exports["default"] = _default;