/* eslint-disable import/no-cycle */
/* eslint-disable camelcase */
import {
	addChartSettings,
	removeChartSettings,
	setChartThresholds,
	setChartShowDecimatedData,
	setShowConfirmationForCustomGroups,
	setConfirmationMessageKey,
	incrementInputThresholdResetToken,
	incrementSeriesVisibilityResetToken,
	setChartPendingThresholds,
	clearChartPendingThresholds,
	setChartUseThreshold,
	setChartUseShadow,
	setChartUseSawtooth,
	setChartHasSawtoothData,
	setChartUseNormalizedDepol,
	setChartUseInterpolatedDepol,
	setShowSpatial,
	setChartPrimarySurveyId,
	setChartDepolSurveyId,
	setSelectedRemediationGroup,
	setSeriesVisibilityDataStore,
	setSeriesVisibility,
	setSeriesCache
} from './actions';
import { DEFAULT_THRESHOLDS, EMPTY_OBJECT } from './constants';
import { hasThresholds, createInitialChartSettings } from './util';

const selectHelper = (state, chartId) => {
	const { chartsSettings = EMPTY_OBJECT } = state;

	const chartSettings = chartsSettings[chartId] || createInitialChartSettings();

	return [chartsSettings, chartSettings];
};

// ************
// PARTS
// ************
const reducerParts = {
	[addChartSettings]: (
		state,
		{ payload: { chartId, settings = createInitialChartSettings() } }
	) => {
		const { chartsSettings } = state;
		return {
			...state,
			chartsSettings: {
				...chartsSettings,
				[chartId]: settings
			}
		};
	},

	[removeChartSettings]: (state, { payload: { chartId } }) => {
		const { chartsSettings } = state;
		const newSettings = { ...chartsSettings };
		delete newSettings[chartId];
		return {
			...state,
			chartsSettings: newSettings
		};
	},

	[setChartThresholds]: (
		state,
		{ payload: { chartId, thresholds = DEFAULT_THRESHOLDS } }
	) => {
		const [chartsSettings, chartSettings] = selectHelper(state, chartId);

		return {
			...state,
			chartsSettings: {
				...chartsSettings,
				[chartId]: {
					...chartSettings,
					thresholdsPendingChangeRequiresConfirmation: false,
					thresholdsPendingThresholdsValue: undefined,
					thresholds: hasThresholds(thresholds)
						? thresholds
						: DEFAULT_THRESHOLDS
				}
			}
		};
	},

	[setChartShowDecimatedData]: (
		state,
		{ payload: { chartId, showDecimatedData = true } }
	) => {
		const [chartsSettings, chartSettings] = selectHelper(state, chartId);

		return {
			...state,
			chartsSettings: {
				...chartsSettings,
				[chartId]: {
					...chartSettings,
					showDecimatedData
				}
			}
		};
	},

	[setShowConfirmationForCustomGroups]: (
		state,
		{ payload: { chartId, showConfirmation } }
	) => {
		const [chartsSettings, chartSettings] = selectHelper(state, chartId);

		return {
			...state,
			chartsSettings: {
				...chartsSettings,
				[chartId]: {
					...chartSettings,
					showConfirmation
				}
			}
		};
	},

	[setConfirmationMessageKey]: (
		state,
		{ payload: { chartId, confirmationMessageKey } }
	) => {
		const [chartsSettings, chartSettings] = selectHelper(state, chartId);

		return {
			...state,
			chartsSettings: {
				...chartsSettings,
				[chartId]: {
					...chartSettings,
					confirmationMessageKey
				}
			}
		};
	},

	[incrementInputThresholdResetToken]: (
		state,
		{ payload: { chartId, token } }
	) => {
		const [chartsSettings, chartSettings] = selectHelper(state, chartId);

		return {
			...state,
			chartsSettings: {
				...chartsSettings,
				[chartId]: {
					...chartSettings,
					inputThresholdResetToken: token
				}
			}
		};
	},

	[incrementSeriesVisibilityResetToken]: (
		state,
		{ payload: { chartId, token } }
	) => {
		const [chartsSettings, chartSettings] = selectHelper(state, chartId);

		return {
			...state,
			chartsSettings: {
				...chartsSettings,
				[chartId]: {
					...chartSettings,
					seriesVisibilityResetToken: token
				}
			}
		};
	},

	[setChartPendingThresholds]: (
		state,
		{ payload: { chartId, thresholds = DEFAULT_THRESHOLDS } }
	) => {
		const [chartsSettings, chartSettings] = selectHelper(state, chartId);

		return {
			...state,
			chartsSettings: {
				...chartsSettings,
				[chartId]: {
					...chartSettings,
					thresholdsPendingChangeRequiresConfirmation: true,
					thresholdsPendingThresholdsValue: hasThresholds(thresholds)
						? thresholds
						: DEFAULT_THRESHOLDS
				}
			}
		};
	},

	[clearChartPendingThresholds]: (state, { payload: { chartId } }) => {
		const [chartsSettings, chartSettings] = selectHelper(state, chartId);

		return {
			...state,
			chartsSettings: {
				...chartsSettings,
				[chartId]: {
					...chartSettings,
					thresholdsPendingChangeRequiresConfirmation: false,
					thresholdsPendingThresholdsValue: undefined
				}
			}
		};
	},

	[setChartUseThreshold]: (state, { payload: { chartId, useThreshold } }) => {
		const [chartsSettings, chartSettings] = selectHelper(state, chartId);

		return {
			...state,
			chartsSettings: {
				...chartsSettings,
				[chartId]: {
					...chartSettings,
					useThreshold,
					useShadow: !useThreshold
				}
			}
		};
	},

	[setChartUseShadow]: (state, { payload: { chartId, useShadow } }) => {
		const [chartsSettings, chartSettings] = selectHelper(state, chartId);

		return {
			...state,
			chartsSettings: {
				...chartsSettings,
				[chartId]: {
					...chartSettings,
					useThreshold: !useShadow,
					useShadow
				}
			}
		};
	},

	[setChartUseSawtooth]: (state, { payload: { chartId, useSawtooth } }) => {
		const [chartsSettings, chartSettings] = selectHelper(state, chartId);

		return {
			...state,
			chartsSettings: {
				...chartsSettings,
				[chartId]: {
					...chartSettings,
					useSawtooth
				}
			}
		};
	},

	[setChartHasSawtoothData]: (
		state,
		{ payload: { chartId, hasSawtoothData } }
	) => {
		const [chartsSettings, chartSettings] = selectHelper(state, chartId);

		return {
			...state,
			chartsSettings: {
				...chartsSettings,
				[chartId]: {
					...chartSettings,
					hasSawtoothData
				}
			}
		};
	},

	[setChartUseNormalizedDepol]: (
		state,
		{ payload: { chartId, useNormalizedDepol } }
	) => {
		const [chartsSettings, chartSettings] = selectHelper(state, chartId);

		return {
			...state,
			chartsSettings: {
				...chartsSettings,
				[chartId]: {
					...chartSettings,
					useNormalizedDepol
				}
			}
		};
	},

	[setChartUseInterpolatedDepol]: (
		state,
		{ payload: { chartId, useInterpolatedDepol } }
	) => {
		const [chartsSettings, chartSettings] = selectHelper(state, chartId);

		return {
			...state,
			chartsSettings: {
				...chartsSettings,
				[chartId]: {
					...chartSettings,
					useInterpolatedDepol
				}
			}
		};
	},

	[setChartPrimarySurveyId]: (state, { payload: { chartId, surveyId } }) => {
		const [chartsSettings, chartSettings] = selectHelper(state, chartId);

		return {
			...state,
			chartsSettings: {
				...chartsSettings,
				[chartId]: {
					...chartSettings,
					primarySurveyId: surveyId
				}
			}
		};
	},

	[setChartDepolSurveyId]: (state, { payload: { chartId, surveyId } }) => {
		const [chartsSettings, chartSettings] = selectHelper(state, chartId);

		return {
			...state,
			chartsSettings: {
				...chartsSettings,
				[chartId]: {
					...chartSettings,
					depolSurveyId: surveyId
				}
			}
		};
	},

	[setSeriesVisibilityDataStore]: (
		state,
		{ payload: { chartId, dataStore = EMPTY_OBJECT } }
	) => {
		const [chartsSettings, chartSettings] = selectHelper(state, chartId);

		return {
			...state,
			chartsSettings: {
				...chartsSettings,
				[chartId]: {
					...chartSettings,
					seriesVisibility: dataStore
				}
			}
		};
	},

	[setSelectedRemediationGroup]: (
		state,
		{ payload: { chartId, group, groupIndex } }
	) => {
		const [chartsSettings, chartSettings] = selectHelper(state, chartId);
		return {
			...state,
			chartsSettings: {
				...chartsSettings,
				[chartId]: {
					...chartSettings,
					selectedRemediationGroup: group,
					selectedRemediationGroupIndex: groupIndex
				}
			}
		};
	},

	[setSeriesVisibility]: (
		state,
		{
			payload: {
				chartId,
				dataSetKey,
				visibilityFlagKey,
				propKey,
				isVisible,
				overwriteChildStore = false
			}
		}
	) => {
		const [chartsSettings, chartSettings] = selectHelper(state, chartId);
		const { seriesVisibility = {} } = chartSettings || {};
		const dataSetVisibility = seriesVisibility[dataSetKey] || {};
		const key = visibilityFlagKey || propKey;

		const newDataSetVisibility = overwriteChildStore
			? { [key]: isVisible }
			: { ...dataSetVisibility, [key]: isVisible };

		return {
			...state,
			chartsSettings: {
				...chartsSettings,
				[chartId]: {
					...chartSettings,
					seriesVisibility: {
						...seriesVisibility,
						[dataSetKey]: newDataSetVisibility
					}
				}
			}
		};
	},

	[setSeriesCache]: (state, { payload: { chartId, series } }) => {
		const [chartsSettings, chartSettings] = selectHelper(state, chartId);
		return {
			...state,
			chartsSettings: {
				...chartsSettings,
				[chartId]: {
					...chartSettings,
					seriesCache: series
				}
			}
		};
	},

	[setShowSpatial]: (state, { payload: { chartId, showSpatial } }) => {
		const [chartsSettings, chartSettings] = selectHelper(state, chartId);
		return {
			...state,
			chartsSettings: {
				...chartsSettings,
				[chartId]: {
					...chartSettings,
					showSpatial
				}
			}
		};
	}
};

export default reducerParts;
