const AWS = require('aws-sdk');

const s3Bucket = cred => {
	const credentials = new AWS.Credentials({
		accessKeyId: cred.AccessKeyId,
		secretAccessKey: cred.SecretAccessKey,
		sessionToken: cred.SessionToken
	});
	const s3 = new AWS.S3({ region: 'us-west-2', credentials });

	s3.validToken = () => {
		const date = new Date();
		const expirationDate = new Date(cred.Expiration);
		expirationDate.setMinutes(expirationDate.getMinutes() - 5);
		const isValid = expirationDate > date;
		return isValid;
	};

	return s3;
};

export default s3Bucket;
