/* eslint-disable import/prefer-default-export */
import _ from 'lodash';
import { createSelector } from 'reselect';

import { ROOT_SLICE } from './constants';

export const selectRootSlice = stateAdmin => stateAdmin[ROOT_SLICE];

export const selectApplications = stateAdmin => {
	const slice = selectRootSlice(stateAdmin);
	const { applications } = slice;
	return applications;
};

export const selectIsLoadingApplications = stateAdmin => {
	const slice = selectRootSlice(stateAdmin);
	const { isLoadingApplications } = slice;
	return isLoadingApplications;
};

export const selectIsLoadedApplications = stateAdmin => {
	const slice = selectRootSlice(stateAdmin);
	const { isLoadedApplications } = slice;
	return isLoadedApplications;
};

export const selectHasLoadingErrorApplications = stateAdmin => {
	const slice = selectRootSlice(stateAdmin);
	const { hasLoadingErrorApplications } = slice;
	return hasLoadingErrorApplications;
};

export const selectSelectedApplication = stateAdmin => {
	const slice = selectRootSlice(stateAdmin);
	const { selectedApplication } = slice;
	return selectedApplication;
};

export const selectSelectedApplicationChangesClone = stateAdmin => {
	const slice = selectRootSlice(stateAdmin);
	const { selectedApplicationChangesClone } = slice;
	return selectedApplicationChangesClone;
};

export const selectHasChanges = createSelector(
	selectSelectedApplication,
	selectSelectedApplicationChangesClone,
	(selectedApplication, selectedClone) => {
		const application = _.cloneDeep(selectedApplication) || {};

		const clone = _.cloneDeep(selectedClone) || {};

		return application && clone && !_.isEqual(application, clone);
	}
);

export const selectIsSavingEditedApplication = stateAdmin => {
	const slice = selectRootSlice(stateAdmin);
	const { isSavingEditedApplication } = slice;
	return isSavingEditedApplication;
};
