/* eslint-disable no-use-before-define */
import { createActions } from 'redux-actions';
import fieldlineUtils from '../scanlineUtils';

const { downloadShapefile, receiveShapefile, requestShapefile } = createActions(
	{
		DOWNLOAD_SHAPEFILE: data => doDownloadShapefile(data),
		RECEIVE_SHAPEFILE: json => ({ json }),
		REQUEST_SHAPEFILE: () => ({})
	}
);

const doDownloadShapefile = data => {
	return dispatch => {
		dispatch(requestShapefile());

		return fieldlineUtils.downloadShapefile(data, (err, res) => {
			if (err) {
				// console.error(err);
			} else {
				dispatch(receiveShapefile(res));
			}
		});
	};
};

export {
	downloadShapefile,
	receiveShapefile,
	requestShapefile,
	doDownloadShapefile
};
