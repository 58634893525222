import { mapToClientSurveyProps } from './survey.server';

export const mapToClientJobProps = (serverJob, lineId) => {
	const jobId = serverJob.job;

	return {
		lineId,
		id: jobId,
		scanlineEnabled: serverJob.scanline,
		entity: serverJob.entity,
		corrosionTechnician: serverJob.ct,
		projectManager: serverJob.pm,
		surveys: (serverJob.surveys || []).map(survey =>
			mapToClientSurveyProps(survey, lineId, jobId)
		)
	};
};

export default { mapToClientJobProps };
