/* eslint-disable jsx-a11y/control-has-associated-label */
import React from 'react';
import { withRouter } from 'react-router';
import { isLoggedIn } from '../../../../../scanline/utils/token';
import { getUserName } from '../../../../../util/user';

import './HelpButton.scss';

const parseRootPath = (urlPath = '') => urlPath.split('/').filter(v => !!v)[0];
const parseSubPath = (urlPath = '') =>
	urlPath.substring(urlPath.lastIndexOf('/') + 1);

const openInNewTab = url => {
	const newWindow = window.open(url, '_blank', 'noopener,noreferrer');
	if (newWindow) {
		newWindow.opener = null;
	}
};

const HelpButton = ({ history }) => {
	const rootPath = (
		parseRootPath(history.location.pathname) || ''
	).toLowerCase();

	const subPath = (parseSubPath(history.location.pathname) || '').toLowerCase();

	let appUrl;
	let appName;
	let clazzName;

	switch (rootPath) {
		case 'fieldlinereact':
			appUrl = 'FieldLine';
			appName = 'FieldLine®';
			clazzName = '';
			break;
		case 'scanline':
			appUrl = 'ScanLine';
			appName = 'ScanLine®';
			clazzName = '';
			break;
		case 'assetview':
			appUrl = 'AssetView';
			appName = 'AssetView®';
			clazzName = '';
			break;
		case 'chargeline':
			appUrl = 'ChargeLine';
			appName = 'ChargeLine™';
			clazzName = '';
			break;
		case 'liveline':
			appUrl = '';
			appName = 'LiveLine™';
			clazzName = 'wiki-icon-liveline';
			break;
		case 'cisline':
			switch (subPath) {
				case 'spikeeditor':
					appUrl = 'CISView/Tutorial/17SpikeEditor';
					appName = 'CISView™ Spike Editor';
					clazzName = '';
					break;
				case 'surveyfiles':
					appUrl = 'CISView/Tutorial/06ViewSurveys';
					appName = 'CISView™ Survey Files';
					clazzName = '';
					break;

				default:
					appUrl = 'CISView';
					appName = 'CISView™';
					clazzName = '';
			}

			break;
		default:
			appUrl = '';
			appName = 'AIM';
			clazzName = '';
	}

	if (isLoggedIn()) {
		return (
			<button
				type="button"
				id="wiki-icon-button"
				className={`col-static ${clazzName}`.trim()}
				onClick={e => {
					e.preventDefault();
					openInNewTab(
						`https://wiki.aip.aegion.com/${appUrl}?user=${getUserName()}`
					);
				}}
				title={`Click to Visit the ${appName} Wiki`}
			>
				<i className="fa fa-question" />
			</button>
		);
	}
	return <button type="button" id="wiki-icon-button" className="logged-out" />;
};

export default withRouter(HelpButton);
