/* eslint-disable import/prefer-default-export */
import {
	VALID_KEYS,
	SUPPORTED_FILE_EXTENSIONS,
	ERROR_DICTIONARY,
	ERROR_KEY_UNKNOWN_ERROR,
	PROCESSING_MESSAGE_DICTIONARY
} from './constants';

export const isValidKey = k => VALID_KEYS.indexOf(k) > -1;

export const isSupportedFileExtension = ext =>
	SUPPORTED_FILE_EXTENSIONS.indexOf((ext || '').toLowerCase()) > -1;

export const getErrorMessage = errorKey =>
	ERROR_DICTIONARY[errorKey] || ERROR_DICTIONARY[ERROR_KEY_UNKNOWN_ERROR];

export const getProcessingMessage = messageKey =>
	PROCESSING_MESSAGE_DICTIONARY[messageKey] || undefined;
