/* eslint import/no-cycle:0 */
import { combineReducers } from 'redux';
import {
	SET_ACTIVE_TOOL,
	SET_IS_EDITED,
	CLEAR,
	SET_LAST_SAVED
} from '../../actions/editorToolbar';

import moveGPSTool from './moveGPSTool';
import smoothTool from './smoothTool';

const defaultState = {
	activeTool: null /* ActiveTool.SMOOTH or ActiveTool.GPS_MOVE */,
	isEdited: false,
	lastSaved: undefined
};

const reducer = (state = defaultState, action) => {
	const { type, payload } = action;
	switch (type) {
		case SET_ACTIVE_TOOL:
			return { ...state, activeTool: payload };

		case SET_IS_EDITED:
			return { ...state, isEdited: payload };
		case CLEAR:
			return {
				...state,
				isEdited: false
			};
		case SET_LAST_SAVED:
			return {
				...state,
				lastSaved: payload
			};
		default:
			return state;
	}
};

export default combineReducers({ main: reducer, moveGPSTool, smoothTool });
