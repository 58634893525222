/* eslint-disable import/no-cycle */
import {
	setCompanies,
	setIsLoadingLoadedCompanies,
	setHasLoadingErrorCompanies
} from './actions';
import { loadCompanies } from './thunks';

export {
	setCompanies,
	setIsLoadingLoadedCompanies,
	setHasLoadingErrorCompanies,
	loadCompanies
};
