import {
	FETCH_PROJECTS_START,
	FETCH_PROJECTS_SUCCESS,
	FETCH_PROJECTS_FAIL,
	FETCH_PROJECTS_NAMELIST_SUCCESS,
	FETCH_PROJECTS_META_START,
	FETCH_PROJECTS_META_SUCCESS,
	FETCH_PROJECTS_META_FAIL,
	SET_CURRENT_PROJECTS_PAGE,
	CLEAR_CACHED_PROJECTS_DATA,
	SET_IS_STANDALONE_PROJECT
} from './actionTypes';

import {
	convertProjectsByPageToArray,
	getLastPage,
	getLargestPage
} from './util/projects.paging';

const initialState = {
	projects: [],
	projectsByPage: {},
	lastPage: undefined, // contains last requestable page from server if loaded, otherwise undefined
	largestPage: undefined, // contains the current largest page which has been loaded from server (largest in terms of index, not items)
	isFetchingProjects: false,
	fetchProjectsFailed: false,
	currentPage: 1,
	metaByProjectId: {},
	isStandaloneProject: false,
	projectsNameList: []
};

const projects = (state = initialState, action) => {
	const { type, payload } = action;

	switch (type) {
		case FETCH_PROJECTS_START:
			return {
				...state,
				isFetchingProjects: true,
				fetchProjectsFailed: false
			};

		case FETCH_PROJECTS_SUCCESS: {
			const projectsByPage = {
				...state.projectsByPage,
				...payload
			};
			const convertedProjects = convertProjectsByPageToArray(projectsByPage);

			return {
				...state,
				isFetchingProjects: false,
				projectsByPage,
				projects: convertedProjects,
				lastPage: getLastPage({ projectsByPage }),
				largestPage: getLargestPage({ projectsByPage })
			};
		}

		case FETCH_PROJECTS_FAIL:
			return {
				...state,
				isFetchingProjects: false,
				fetchProjectsFailed: true
			};
		case FETCH_PROJECTS_NAMELIST_SUCCESS:
			return {
				...state,
				projectsNameList: payload
			};

		case FETCH_PROJECTS_META_START:
			return state;

		case FETCH_PROJECTS_META_SUCCESS:
			return {
				...state,
				metaByProjectId: {
					...state.metaByProjectId,
					...payload
				}
			};

		case FETCH_PROJECTS_META_FAIL:
			return state;

		case SET_CURRENT_PROJECTS_PAGE:
			return {
				...state,
				currentPage: payload
			};

		case CLEAR_CACHED_PROJECTS_DATA:
			return {
				...state,
				...initialState
			};

		case SET_IS_STANDALONE_PROJECT:
			return {
				...state,
				isStandaloneProject: payload
			};
		default:
			return state;
	}
};

export default projects;
