/** ***********
 * MAIN - EXPAND
 * This spreads out the ids of readings (does not create additional readings)
 */

import { getLast, cloneAndAlignTo } from './misc';

const expandReadings = (
	cisReadings,
	cisReadingsMap,
	targetReadings,
	subtype,
	{ depolKey, depolFlagKey, targetValueKey },
	shouldCopyOriginalDepol
) => {
	if (targetReadings.length === 0) {
		return [...targetReadings];
	}

	const normalizedTargetSurvey = [];
	let alignedTarget;

	const copyDepolValue = (cisReading, depolReading) => {
		if (subtype === 'DEPOL') {
			// eslint-disable-next-line no-param-reassign
			cisReading[depolKey] = depolReading[targetValueKey];
			if (depolFlagKey) {
				// eslint-disable-next-line no-param-reassign
				cisReading[depolFlagKey] = true;
			}
		}
	};

	const copyOriginalDepolValue = depolReading => {
		if (subtype === 'DEPOL' && shouldCopyOriginalDepol) {
			const cisReading = cisReadingsMap[depolReading.id];
			if (cisReading) {
				cisReading.depol = depolReading[targetValueKey];
			}
		}
	};

	if (targetReadings.length === 1) {
		copyOriginalDepolValue(targetReadings[0]);
		alignedTarget = cloneAndAlignTo(
			targetReadings[0],
			cisReadings[0],
			depolFlagKey
		);
		normalizedTargetSurvey.push(alignedTarget);
		copyDepolValue(cisReadings[0], alignedTarget);
		return normalizedTargetSurvey;
	}

	// align first reading
	copyOriginalDepolValue(targetReadings[0]);
	alignedTarget = cloneAndAlignTo(
		targetReadings[0],
		cisReadings[0],
		depolFlagKey
	);
	normalizedTargetSurvey.push(alignedTarget);
	copyDepolValue(cisReadings[0], alignedTarget);

	// align middle readings;
	const ratio = (cisReadings.length - 1) / (targetReadings.length - 1);
	for (let i = 1, n = targetReadings.length - 1; i < n; i += 1) {
		const cisIndex = Math.floor(i * ratio);
		copyOriginalDepolValue(targetReadings[i]);
		alignedTarget = cloneAndAlignTo(
			targetReadings[i],
			cisReadings[cisIndex],
			depolFlagKey
		);
		normalizedTargetSurvey.push(alignedTarget);
		copyDepolValue(cisReadings[cisIndex], alignedTarget);
	}

	// align last reading
	copyOriginalDepolValue(getLast(targetReadings));
	alignedTarget = cloneAndAlignTo(
		getLast(targetReadings),
		getLast(cisReadings),
		depolFlagKey
	);
	normalizedTargetSurvey.push(alignedTarget);
	copyDepolValue(getLast(cisReadings), alignedTarget);

	return normalizedTargetSurvey;
};

export default expandReadings;
