const colorsList = [
	/**
	 * material theme
	 */
	'#00bdae', // teal
	'#357cd2', // blue
	'#f8b883', // lt orange
	'#e56590', // md pink
	'#70ad47', // pea green
	'#dd8abd', // lt pink
	'#7f84e8', // lavender
	'#7bb4eb', // sky blue
	'#ea7a57', // md orange
	/**
	 * high contrast theme
	 */
	'#79ECE4', // aqua
	'#E98272', // mat orange
	'#DFE6B6', // dirty white
	'#C6E773', // kiwi or lime
	'#BA98FF', // pastel purple
	'#FA83C3', // pastel pink
	'#00C27A', // pastel green
	'#43ACEF', // pastel lt blue
	'#D681EF', // pastel lt purple
	'#D8BC6E', // pastel gold
	/**
	 * fabric theme
	 */
	'#4472c4', // mat blue
	'#ed7d31', // orange
	'#ffc000', // yellow
	'#70ad47', // pea green
	'#5b9bd5', // lt blue
	'#6f6fe2', // lt indigo
	'#e269ae', // pink
	'#9e480e', // muddy brown
	'#997300' // dirty gold
];

class Colors {
	colors = colorsList;

	colorIndex = 0;

	getFirstColor = () => {
		return this.colors[0];
	};

	getColor = () => {
		const idx = this.colorIndex;
		const total = this.colors.length;
		this.colorIndex += 1;
		return this.colors[idx % total];
	};

	resetColorIndex = () => {
		this.colorIndex = 0;
	};
}

export default new Colors();
