import { SET_STREAM, SET_TRENDS, RESET } from './actionTypes';

const initialState = {
	stream: [],
	trends: { data: [], channels: [] }
};

const reducer = (state = initialState, action) => {
	const { type, payload } = action;
	switch (type) {
		case SET_STREAM:
			return { ...state, stream: payload };
		case SET_TRENDS:
			return { ...state, trends: payload };
		case RESET:
			return initialState;
		default:
			return state;
	}
};

export default reducer;
