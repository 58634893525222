export const ACTIONS_NAME_SPACE = 'fileDiff_diffOutputs';

export const SLICE_ROOT_KEY = 'diffOutput';

export const VALID_KEYS = ['deleted', 'modified', 'added'];

// ERROR MESSAGES

export const ERROR_KEY_UNKNOWN_ERROR = 'UNKNOWN_ERROR';

export const ERROR_DICTIONARY = {
	[ERROR_KEY_UNKNOWN_ERROR]: 'An unknown error occured.'
};
