"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

var _lodash = _interopRequireDefault(require("lodash.isequal"));

var _papaparse = _interopRequireDefault(require("papaparse"));

var _PapaConfig = _interopRequireDefault(require("../../../PapaConfig"));

var _constants = require("../../constants");

var _BASE = _interopRequireDefault(require("../BASE"));

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    "default": obj
  };
}

function ownKeys(object, enumerableOnly) {
  var keys = Object.keys(object);

  if (Object.getOwnPropertySymbols) {
    var symbols = Object.getOwnPropertySymbols(object);
    enumerableOnly && (symbols = symbols.filter(function (sym) {
      return Object.getOwnPropertyDescriptor(object, sym).enumerable;
    })), keys.push.apply(keys, symbols);
  }

  return keys;
}

function _objectSpread(target) {
  for (var i = 1; i < arguments.length; i++) {
    var source = null != arguments[i] ? arguments[i] : {};
    i % 2 ? ownKeys(Object(source), !0).forEach(function (key) {
      _defineProperty(target, key, source[key]);
    }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) {
      Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key));
    });
  }

  return target;
}

function _defineProperty(obj, key, value) {
  if (key in obj) {
    Object.defineProperty(obj, key, {
      value: value,
      enumerable: true,
      configurable: true,
      writable: true
    });
  } else {
    obj[key] = value;
  }

  return obj;
}

function _typeof(obj) {
  "@babel/helpers - typeof";

  return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (obj) {
    return typeof obj;
  } : function (obj) {
    return obj && "function" == typeof Symbol && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj;
  }, _typeof(obj);
}

var CSV_DESCRIPTION = _constants.FILE_TYPE_DESCRIPTIONS[_constants.KEY_CSV];

var isDefined = function isDefined(v) {
  return v !== undefined && v !== null;
};

var isDocumentPropertyValid = function isDocumentPropertyValid(doc) {
  // handle undefined
  if (doc === undefined) {
    return true;
  } // handle wrong data type


  if (_typeof(doc) !== 'object') {
    return false;
  } // check root props


  var requiredRootProps = ['data', 'meta', 'errors'];
  var docRootProps = Object.keys(doc);
  var hasRequiredRootProps = requiredRootProps.every(function (p) {
    return docRootProps.indexOf(p) > -1;
  });

  if (!hasRequiredRootProps) {
    return false;
  } // no items are ok, just means empty doc


  var hasData = doc.data instanceof Array && doc.data.length > 0;

  if (!hasData) {
    return true;
  } // check first item for format, if passes assume following items will pass


  var firstItem = doc.data[0];
  return _typeof(firstItem) === 'object';
};

var isValidFileName = function isValidFileName(fname) {
  var parts = (fname || '').trim().split('.');
  return parts.length > 1 && parts[parts.length - 1].toLowerCase() === 'csv';
};

var CSVDocType = _objectSpread(_objectSpread({}, _BASE["default"]), {}, {
  // @required overwrite
  toFileText: function toFileText() {
    var _ref = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {},
        _ref$document = _ref.document,
        doc = _ref$document === void 0 ? {} : _ref$document;

    return _papaparse["default"].unparse(doc, _PapaConfig["default"].unparse).trim();
  },
  // @required overwrite
  getFileParams: function getFileParams(docJson) {
    if (CSVDocType.isValidDocJson(docJson)) {
      var text = CSVDocType.toFileText(docJson);
      var name = CSVDocType.getFileName(docJson);
      var ext = CSVDocType.getFileExtension(docJson);
      var filename = [name, ext].filter(function (v) {
        return !!v;
      }).join('.');
      return {
        filename: filename,
        content: text,
        mimeType: 'text/csv'
      };
    }

    return undefined;
  },
  // @required overwrite
  toDocJson: function toDocJson(_ref2) {
    var fileName = _ref2.fileName,
        content = _ref2.content;
    var papaCsvJson;

    if (isDefined(content)) {
      papaCsvJson = typeof content === 'string' ? _papaparse["default"].parse(content, _PapaConfig["default"].parse) : content;
    }

    if (isValidFileName(fileName)) {
      var parts = (fileName || '').trim().split('.');
      var ext = parts.splice(parts.length - 1, 1)[0].toLowerCase();
      var name = parts.join('.').trim();
      return {
        type: _constants.KEY_CSV,
        fileName: name,
        fileExtension: ext,
        document: papaCsvJson
      };
    }

    return undefined;
  },
  isValidDocJson: function isValidDocJson() {
    var docJson = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    var docType = (CSVDocType.getDocType(docJson) || '').toUpperCase();
    var fileExtension = (CSVDocType.getFileExtension(docJson) || '').toLowerCase();
    var doc = CSVDocType.getDocument(docJson);
    return _BASE["default"].isValidDocJson(docJson) && docType === CSV_DESCRIPTION.fileType && fileExtension === CSV_DESCRIPTION.fileExtension && isDocumentPropertyValid(doc);
  },
  createItemDocId: function createItemDocId(item, _ref3) {
    var prop = _ref3.prop,
        props = _ref3.props;
    var idProps;

    if (prop !== undefined) {
      idProps = [prop];
    } else if (props !== undefined) {
      idProps = props; // assume an array of props
    }

    return idProps.map(function (p) {
      return item[p];
    }).join('~');
  },
  createItemMap: function createItemMap() {
    var _ref4 = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {},
        _ref4$document = _ref4.document,
        doc = _ref4$document === void 0 ? {} : _ref4$document;

    var _ref5 = arguments.length > 1 ? arguments[1] : undefined,
        idProp = _ref5.idProp,
        idProps = _ref5.idProps;

    var _doc$data = doc.data,
        data = _doc$data === void 0 ? [] : _doc$data;
    return data.reduce(function (acc, item) {
      var docId = CSVDocType.createItemDocId(item, {
        prop: idProp,
        props: idProps
      });
      acc[docId] = item;
      return acc;
    }, {});
  },
  computeDiffItemSets: function computeDiffItemSets(docJsonA, docJsonB, _ref6) {
    var idProp = _ref6.idProp,
        idProps = _ref6.idProps;
    var itemMapA = CSVDocType.createItemMap(docJsonA, {
      idProp: idProp,
      idProps: idProps
    });
    var docIdsA = Object.keys(itemMapA);
    var itemMapB = CSVDocType.createItemMap(docJsonB, {
      idProp: idProp,
      idProps: idProps
    });
    var docIdsB = Object.keys(itemMapB);

    var _docIdsA$reduce = docIdsA.reduce(function (acc, idA) {
      if (itemMapB[idA] === undefined) {
        acc.deletedItems.push(itemMapA[idA]);
      } else {
        acc.sharedIds.push(idA);
      }

      return acc;
    }, {
      deletedItems: [],
      sharedIds: []
    }),
        deletedItems = _docIdsA$reduce.deletedItems,
        sharedIds = _docIdsA$reduce.sharedIds;

    var addedItems = docIdsB.reduce(function (acc, idB) {
      if (itemMapA[idB] === undefined) {
        acc.push(itemMapB[idB]);
      }

      return acc;
    }, []);
    var modifiedItems = sharedIds.reduce(function (acc, id) {
      if (!(0, _lodash["default"])(itemMapA[id], itemMapB[id])) {
        acc.push(itemMapB[id]);
      }

      return acc;
    }, []);
    return {
      deleted: deletedItems,
      modified: modifiedItems,
      added: addedItems
    };
  },
  computeDiffDocsByItemSets: function computeDiffDocsByItemSets(docJsonA, docJsonB, _ref7) {
    var idProp = _ref7.idProp,
        idProps = _ref7.idProps;
    var fileNameA = docJsonA.fileName;
    var fileNameB = docJsonB.fileName;
    var fileNameDeleted = "DELETED-FROM-".concat(fileNameA, "-TO-").concat(fileNameB, ".csv");
    var fileNameModified = "MODIFIED-FROM-".concat(fileNameA, "-TO-").concat(fileNameB, ".csv");
    var fileNameAdded = "ADDED-FROM-".concat(fileNameA, "-TO-").concat(fileNameB, ".csv");
    var itemDiffs = CSVDocType.computeDiffItemSets(docJsonA, docJsonB, {
      idProp: idProp,
      idProps: idProps
    });
    var docJsonDeleted = CSVDocType.toDocJson({
      fileName: fileNameDeleted,
      content: {
        meta: docJsonA.document.meta,
        errors: [],
        data: itemDiffs.deleted
      }
    });
    var docJsonModified = CSVDocType.toDocJson({
      fileName: fileNameModified,
      content: {
        meta: docJsonB.document.meta,
        errors: [],
        data: itemDiffs.modified
      }
    });
    var docJsonAdded = CSVDocType.toDocJson({
      fileName: fileNameAdded,
      content: {
        meta: docJsonB.document.meta,
        errors: [],
        data: itemDiffs.added
      }
    });
    return {
      deleted: docJsonDeleted,
      modified: docJsonModified,
      added: docJsonAdded
    };
  }
});

var _default = CSVDocType;
exports["default"] = _default;