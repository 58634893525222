import { EMPTY_OBJECT, LAMBDA } from './constants';
import { isDefined, isEmptyString } from './util';

const selectAllActionPlanChangeNotesState = (stateApp = EMPTY_OBJECT) => {
	const { actionPlanChangeNotes = EMPTY_OBJECT } = stateApp;
	return actionPlanChangeNotes;
};

export const selectActionPlanChangeNotesState = (
	stateApp = EMPTY_OBJECT,
	{ chartId } = {}
) => {
	const allActionPlanChangeNotes = selectAllActionPlanChangeNotesState(
		stateApp
	);
	const actionPlanChangeNotes =
		allActionPlanChangeNotes[chartId] || EMPTY_OBJECT;

	return actionPlanChangeNotes;
};

export const selectNotes = (stateApp = EMPTY_OBJECT, { chartId } = {}) => {
	const state = selectActionPlanChangeNotesState(stateApp, { chartId });
	const { notes } = state;

	return notes;
};

export const selectHasNotes = (stateApp = EMPTY_OBJECT, { chartId } = {}) => {
	const notes = selectNotes(stateApp, { chartId });
	return isDefined(notes) && !isEmptyString(notes);
};

export const selectShowModal = (stateApp = EMPTY_OBJECT, { chartId } = {}) => {
	const state = selectActionPlanChangeNotesState(stateApp, { chartId });
	const { showModal } = state;

	return showModal;
};

const selectSideEffects = (stateApp = EMPTY_OBJECT, { chartId } = {}) => {
	const state = selectActionPlanChangeNotesState(stateApp, { chartId });
	const { sideEffects = EMPTY_OBJECT } = state;

	return sideEffects;
};

export const selectOnCancelChangeNotes = (
	stateApp = EMPTY_OBJECT,
	{ chartId } = {}
) => {
	const { onCancelChangeNotes = LAMBDA } = selectSideEffects(stateApp, {
		chartId
	});
	return onCancelChangeNotes;
};

export const selectOnSaveChangeNotes = (
	stateApp = EMPTY_OBJECT,
	{ chartId } = {}
) => {
	const { onSaveChangeNotes = LAMBDA } = selectSideEffects(stateApp, {
		chartId
	});
	return onSaveChangeNotes;
};

export default {
	selectNotes,
	selectHasNotes,
	selectShowModal,
	selectOnCancelChangeNotes,
	selectOnSaveChangeNotes
};
