export const FETCH_LINES_START = 'FETCH_LINES_START';
export const FETCH_LINES_SUCCESS = 'FETCH_LINES_SUCCESS';
export const FETCH_LINES_FAIL = 'FETCH_LINES_FAIL';

export const SET_CURRENT_LINES_PAGE = 'SET_CURRENT_LINES_PAGE';

export const LINES_SET_FILTERS = 'LINES_SET_FILTERS';
export const CLEAR_CACHED_LINES_DATA = 'CLEAR_CACHED_LINES_DATA';

export const LINES_SET_CUSTOMER = 'LINES_SET_CUSTOMER';

export const REQUEST_ADD_TAG = 'REQUEST_ADD_TAG';
export const RECEIVE_ADD_TAG = 'RECEIVE_ADD_TAG';
export const ADD_TAG = 'ADD_TAG';

export const REQUEST_DELETE_TAG = 'REQUEST_DELETE_TAG';
export const RECEIVE_DELETE_TAG = 'RECEIVE_DELETE_TAG';
export const DELETE_TAG = 'DELETE_TAG';

export const REQUEST_GET_TAGS = 'REQUEST_GET_TAGS';
export const RECEIVE_GET_TAGS = 'RECEIVE_GET_TAGS';
