import L from 'leaflet';

import LocationType from 'aegion_common_utilities/lib/ReadingsUtil/LocationType';
import * as MapProcessingUtil from 'aegion_common_utilities/lib/MapProcessingUtil';

// eslint-disable-next-line import/no-cycle
import { store } from '../../../scanline/store';
import { binarySearchReadingsWithIndexes } from '../../../commons/util/binary-search';

const GpsAutoCorrectionUtil = {
	isGpsReadingInRectangle(coords, boundsOfRectangle) {
		const latlng = L.latLng([coords[0], coords[1]]);

		return boundsOfRectangle.contains(latlng);
	},

	setDistanceMovedOnReading(
		reading,
		readings,
		previousGpsReading,
		nextGpsReading,
		n
	) {
		reading.distanceMoved = MapProcessingUtil.computeDistance(
			n,
			n,
			readings,
			false,
			true,
			{ previousGpsReading }
		);

		// How far current point is from previous point
		reading.distanceFromPreviousPointMoved = MapProcessingUtil.computeDistance(
			n,
			n - 1,
			readings,
			false,
			false,
			{ previousGpsReading }
		);

		// How far current point is from next point
		reading.distanceFromNextPointMoved = MapProcessingUtil.computeDistance(
			n,
			n + 1,
			readings,
			false,
			false,
			{ nextGpsReading, previousGpsReading }
		);

		// How far original point is from previous point
		reading.distanceFromPreviousPointOriginal = MapProcessingUtil.computeDistance(
			n,
			n - 1,
			readings,
			true,
			true,
			{ nextGpsReading, previousGpsReading }
		);
		// How far original point is from next point
		reading.distanceFromNextPointOriginal = MapProcessingUtil.computeDistance(
			n,
			n + 1,
			readings,
			true,
			true,
			{ nextGpsReading, previousGpsReading }
		);
	},

	calculateDistanceMovedGpsSmoothed(readings) {
		const gpsIndexes = MapProcessingUtil.indexesOfGpsReadings(readings);

		gpsIndexes.forEach((previousIndex, i) => {
			const nextIndex = gpsIndexes[i + 1];
			if (!nextIndex) {
				return;
			}

			const previousGpsReading = readings[previousIndex];
			const nextGpsReading = readings[nextIndex];

			for (let n = previousIndex + 1; n < nextIndex; n += 1) {
				const reading = readings[n];
				const isMoved = reading.is_moved;
				const isLastReading = i === nextIndex - 1;

				if (isMoved && reading.StationId !== 0 && !isLastReading) {
					this.setDistanceMovedOnReading(
						reading,
						readings,
						previousGpsReading,
						nextGpsReading,
						n
					);
				}
			}
		});
	},

	setGpsReadingsMoved(originalGpsReadings, coordinateLedger) {
		const gpsReadings = [];

		// Use Hash Trick:
		const coordinatesFlagHash = {};
		coordinateLedger.forEach(value => {
			coordinatesFlagHash[value] = true;
		});

		originalGpsReadings.forEach((gpsReading, n) => {
			const {
				coordinates: originalCoordinates,
				editLatLng,
				locationType
			} = gpsReading;

			let reading = { ...gpsReading }; // make a copy
			const isFirstItem = n === 0;
			const isLastItem = n === originalGpsReadings.length - 1;

			const isMoved = coordinatesFlagHash[n];
			const isGps = locationType === LocationType.GPS;

			const { coordinates: prevOriginalCoordinates } =
				originalGpsReadings[n - 1] || {};
			const { coordinates: nextOriginalCoordinates } =
				originalGpsReadings[n + 1] || {};

			// These are items that are auto smoothed (first and last items cannot be auto smoothed)
			// TODO: We should also confirm that this reading has "isGps=true", that would require rewriting logic after this if block too
			if (isMoved && !isFirstItem && !isLastItem) {
				// Make a copy
				reading = {
					...reading,
					is_moved: true,
					coordinates: [...originalCoordinates],
					locationType: LocationType.INTERPOLATED,
					coordinate_before_moved: originalCoordinates,
					prev_coordinate_before_move: prevOriginalCoordinates,
					next_coordinate_before_move: nextOriginalCoordinates
				};
			}

			if (isGps) {
				// These are actual smoothed points (were gps and now are interpolated)
				const newGpsReading = {
					...reading,
					coordinates: [...originalCoordinates]
				};

				if (editLatLng) {
					newGpsReading.editLatLng = editLatLng;
				}
				gpsReadings.push(newGpsReading);
				return;
			}

			// The rest of the logic below is for autocorrected readings, the first reading and the last reading
			gpsReadings.push(reading);
		});

		return gpsReadings;
	},

	getUserActions(fileName) {
		const { cisview } = store.getState();
		const { gpsAutoCorrection } = cisview;
		const { autoCorrectionsByFileName } = gpsAutoCorrection;

		const autoCorrections = autoCorrectionsByFileName[fileName];

		// This may have been hit before anything is stored, we can assume there are no userActions
		if (!autoCorrections) {
			return [];
		}
		const { userActions } = autoCorrections;

		return userActions;
	},

	performAutoCorrectionActionsFromStack(
		originalDatFile,
		gpsReadings,
		_userInteractions
	) {
		const userActions =
			_userInteractions ||
			GpsAutoCorrectionUtil.getUserActions(originalDatFile.fileName);

		userActions.forEach(actionSet => {
			actionSet.forEach(action => {
				// This is quite inneficient (n^2)
				// TODO: Only recompute GPS measurements if at least one item has changed
				GpsAutoCorrectionUtil.deleteCorrection(gpsReadings, action.reading);
			});
		});
	},

	deleteCorrection(newGpsReadings, _reading) {
		const { StationId } = _reading;
		const binarySearchResults = binarySearchReadingsWithIndexes(
			newGpsReadings,
			StationId
		);
		if (!binarySearchResults) {
			throw new Error(`Could not find reading with Station Id ${StationId}`);
		}

		const { reading, index } = binarySearchResults;
		GpsAutoCorrectionUtil.unsmoothItem(reading, newGpsReadings, index);
	},

	unsmoothItem(reading) {
		// Get original coordinate of this reading
		const { coordinate_before_moved: coordinates } = reading;

		reading.locationType = LocationType.GPS;
		reading.coordinates = coordinates;
		reading.is_moved = false;
	},

	mergeCoordinatesWithFlags(originalDatFile, coordinateLedger, userActions) {
		// This makes a copy of the data
		const newGpsReadings = GpsAutoCorrectionUtil.setGpsReadingsMoved(
			originalDatFile.gpsreadings, // this should not be edited
			coordinateLedger
		);

		GpsAutoCorrectionUtil.performAutoCorrectionActionsFromStack(
			originalDatFile,
			newGpsReadings,
			userActions
		);

		MapProcessingUtil.updateGPSMeasurements(newGpsReadings, {
			isUpdateInterpolatedCoords: true
		});

		GpsAutoCorrectionUtil.calculateDistanceMovedGpsSmoothed(newGpsReadings);

		return newGpsReadings;
	},

	getAutoCorrectionWithinShapeByFile(
		boundsOfRectangle,
		autoCorrectionsByFileName
	) {
		const readingsToDeleteFromCorrectionsByFile = {};

		let firstDatEdited;
		const stationIdsRemovedByDatFile = {};
		let anyReadingsRemoved = false;

		Object.keys(autoCorrectionsByFileName).forEach(fileName => {
			readingsToDeleteFromCorrectionsByFile[fileName] = [];
			const autoCorrectionCollection = autoCorrectionsByFileName[fileName];
			if (
				!autoCorrectionCollection ||
				!autoCorrectionCollection.autoGpsCorrectionInProcess
			) {
				return;
			}
			const { datFile } = autoCorrectionCollection;

			if (!firstDatEdited) {
				firstDatEdited = datFile;
			}
			const gpsreadings = autoCorrectionCollection.cachedMergedGpsCoordinates;

			const gpsRecordsWithinShape = gpsreadings.filter(r =>
				GpsAutoCorrectionUtil.isGpsReadingInRectangle(
					r.coordinates,
					boundsOfRectangle
				)
			);

			// finally we unsmooth all the readings that have been moved
			gpsRecordsWithinShape.forEach(reading => {
				const isMoved = reading.is_moved;
				if (isMoved) {
					readingsToDeleteFromCorrectionsByFile[fileName].push({
						reading,
						timestamp: new Date()
					});
					if (!anyReadingsRemoved) {
						anyReadingsRemoved = true;
					}
				}
			});
			if (!stationIdsRemovedByDatFile[fileName]) {
				stationIdsRemovedByDatFile[fileName] = [];
			}

			const stationsRemoved = readingsToDeleteFromCorrectionsByFile[
				fileName
			].map(r => r.reading.StationId);

			stationIdsRemovedByDatFile[fileName] = [
				...stationIdsRemovedByDatFile[fileName],
				...stationsRemoved
			];
		});

		// TODO: I think that the data we return needs to have more information
		return {
			anyReadingsRemoved,
			firstDatEdited,
			stationIdsRemovedByDatFile,
			readingsToDeleteFromCorrectionsByFile
		};
	}
};

export default GpsAutoCorrectionUtil;
