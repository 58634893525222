/* eslint-disable camelcase */
import scanlineMapUtils from '../../../../mapUtils';
import { createIsValidReading, deriveDataSetsFromProps } from './_BASE';

export const createDerivedSurveys = (
	processedReadings,
	processedReadingsWithChartGaps,
	processedReadingsWithGeoGaps,
	type,
	surveyReadings,
	jobId,
	meta,
	end_date,
	parent_survey_subtype,
	job_number
) => {
	const readings = {};
	const readingsWithChartGaps = {};
	const readingsWithGeoGaps = {};
	const readingsMap = {};
	const readingsIndexMap = {};
	const readingsWithChartGapsIndexMap = {};
	const readingsWithGeoGapsIndexMap = {};
	const readingsStationIdIntegers = {};

	const series = {};
	const seriesIndexMap = {};

	const readingsMeta = {};
	const surveys = [];
	const { s3Key } = meta || {};

	const isValidOptions = { requireNumeric: false };
	const survey = scanlineMapUtils.createSurveyTemplate(type, 'SPATIAL', {
		primaryXAxis: {
			minimum: surveyReadings[0].id,
			maximum: surveyReadings[surveyReadings.length - 1].id
		},
		jobId,
		s3Key,
		end_date,
		parent_survey_subtype,
		job_number
	});
	/**
	 * NOTE and HACK - computeYMinYMax
	 * spatial surveys & charts don't behave exactly like the other survey types
	 *     - the yMin yMax never changes
	 *     - computeProps is never called when creating spatial surveys
	 *     - we need the survey to have yMin yMax defined so it can be treated like all other surveys
	 *     - thus, we are defining it here.
	 *
	 * also note that the y-axis is inverted for spatial charts, so ymin/ymax are inverted as well.
	 */
	survey.yMin = 80;
	survey.yMax = -15;
	survey.xMin = survey.primaryXAxis.minimum;
	survey.xMax = survey.primaryXAxis.maximum;

	survey.isDerived = true;
	scanlineMapUtils.initSurveyMeta(survey);
	const readingKeyProps = survey.meta.getReadingKeyProps();
	const isValidReading = createIsValidReading(readingKeyProps, {
		isValidOptions
	});

	if (processedReadings.filter(isValidReading).length) {
		const {
			readings: thisReadings,
			readingsIndexMap: thisReadingsIndexMap,
			readingsMap: thisReadingsMap,
			readingsWithChartGaps: thisReadingsWithChartGaps,
			readingsWithChartGapsIndexMap: thisReadingsWithChartGapsIndexMap,
			readingsWithGeoGapsIndexMap: thisReadingsWithGeoGapsIndexMap,
			readingsWithGeoGaps: thisReadingsWithGeoGaps,
			series: thisSeries,
			seriesIndexMap: thisSeriesIndexMap,
			readingsStationIdIntegers: thisReadingsStationIdIntegers
		} = deriveDataSetsFromProps(
			survey,
			readingKeyProps,
			processedReadings,
			processedReadingsWithChartGaps,
			processedReadingsWithGeoGaps,
			{ isValidOptions }
		);

		readings[survey.id] = thisReadings;
		readingsWithChartGaps[survey.id] = thisReadingsWithChartGaps;
		readingsWithGeoGaps[survey.id] = thisReadingsWithGeoGaps;
		readingsIndexMap[survey.id] = thisReadingsIndexMap;
		readingsWithChartGapsIndexMap[
			survey.id
		] = thisReadingsWithChartGapsIndexMap;
		readingsWithGeoGapsIndexMap[survey.id] = thisReadingsWithGeoGapsIndexMap;
		readingsMap[survey.id] = thisReadingsMap;
		series[survey.id] = thisSeries;
		seriesIndexMap[survey.id] = thisSeriesIndexMap;
		readingsMeta[survey.id] = meta;
		readingsStationIdIntegers[survey.id] = thisReadingsStationIdIntegers;

		surveys.push(survey);
	}

	return {
		readings,
		readingsWithChartGaps,
		readingsWithGeoGaps,
		readingsMap,
		readingsIndexMap,
		readingsWithChartGapsIndexMap,
		readingsWithGeoGapsIndexMap,
		readingsMeta,
		surveys,
		series,
		seriesIndexMap,
		readingsStationIdIntegers
	};
};

export default { createDerivedSurveys };
