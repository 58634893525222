/* eslint import/no-cycle:0 */
import {
	SET_GPS_READINGS_STILL_LOADING,
	SET_GLOBAL_DATA,
	REMOVE_DAT,
	SET_DAT_VISIBILITY,
	SET_DAT_TRIMS,
	SET_READING_COMMENT,
	SET_SAVING_COMPLETE,
	SET_REPLACE_READING,
	SET_DELETE_READING,
	SET_DELETE_SKIP,
	SET_ADD_GPS_POINT,
	SET_ADD_SKIP,
	ADD_DAT_FILE_EDITED_COUNT,
	REMOVE_DAT_FILE_EDITED_COUNT,
	SET_MAP_EDIT_IN_PROCESS,
	SET_IS_UNDOING,
	SET_READINGS_CHANGED_CACHED_DATA
} from '../actions/job';

import { SET_GLOBAL_JOB_STATUS } from '../actions/globalJobStatus';
import { SET_HISTORICAL_JOBS } from '../actions/historicalJobs';
import { SET_GLOBAL_ASSISTING_DP } from '../actions/globalAssistingDP';

const defaultState = {
	historicalJobs: undefined,
	globalData: undefined,
	data: undefined,
	isSaving: false,
	isUndoing: false,
	datFilesBeingSavedCount: 0,
	isMapEditInProcess: false,
	isMoveToolActivated: false,
	isSmoothToolAcitvated: false,
	gpsReadingsLoading: true
};

const reducer = (state = defaultState, action) => {
	const { type, payload } = action;
	switch (type) {
		case SET_GPS_READINGS_STILL_LOADING:
			return { ...state, gpsReadingsLoading: payload.stillLoading };

		case SET_HISTORICAL_JOBS:
			return {
				...state,
				historicalJobs: payload.historicalJobs
			};

		case SET_GLOBAL_DATA:
			return { ...state, globalData: payload.globalData, data: payload.data };
		case SET_READINGS_CHANGED_CACHED_DATA:
			return {
				...state,
				data: payload.readingsCachedData
			};
		case REMOVE_DAT:
			return {
				...state,
				data: payload.data,
				globalData: payload.globalData
			};
		case SET_DAT_VISIBILITY:
			return {
				...state,
				data: payload.data,
				globalData: payload.globalData
			};
		case SET_DAT_TRIMS:
			return {
				...state,
				data: payload.data,
				globalData: payload.globalData
			};
		case SET_READING_COMMENT:
			return {
				...state,
				data: payload.data,
				globalData: payload.globalData
			};
		case SET_SAVING_COMPLETE:
			return {
				...state,
				data: payload.data,
				globalData: payload.globalData
			};
		case SET_REPLACE_READING:
			return {
				...state,
				data: payload.data,
				globalData: payload.globalData
			};
		case SET_DELETE_READING:
			return {
				...state,
				data: payload.data,
				globalData: payload.globalData
			};
		case SET_DELETE_SKIP:
			return {
				...state,
				data: payload.data,
				globalData: payload.globalData
			};

		case SET_ADD_GPS_POINT:
			return {
				...state,
				data: payload.data,
				globalData: payload.globalData
			};
		case SET_ADD_SKIP:
			return {
				...state,
				data: payload.data,
				globalData: payload.globalData
			};
		case ADD_DAT_FILE_EDITED_COUNT: {
			return {
				...state,
				datFilesBeingSavedCount: state.datFilesBeingSavedCount + 1,
				isSaving: true
			};
		}
		case REMOVE_DAT_FILE_EDITED_COUNT: {
			return {
				...state,
				datFilesBeingSavedCount: payload.datFilesBeingSavedCount,
				isSaving: payload.isSaving
			};
		}
		case SET_MAP_EDIT_IN_PROCESS: {
			const {
				isMapEditInProcess,
				isMoveToolActivated,
				isSmoothToolAcitvated
			} = payload;

			return {
				...state,
				isMapEditInProcess,
				isMoveToolActivated,
				isSmoothToolAcitvated
			};
		}

		case SET_GLOBAL_JOB_STATUS:
			return {
				...state,
				globalData: {
					...(state.globalData || {}),
					jobStatus: payload.status
				}
			};
		case SET_GLOBAL_ASSISTING_DP:
			return {
				...state,
				globalData: {
					...(state.globalData || {}),
					assistingDP: payload.assistingDP
				}
			};

		case SET_IS_UNDOING:
			return { ...state, isUndoing: payload };
		default:
			return state;
	}
};

export default reducer;
