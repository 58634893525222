/* eslint-disable import/prefer-default-export */
/* eslint-disable import/no-cycle */
import { createActions } from 'redux-actions';
import { ROOT_SLICE } from '../constants';

const {
	setApplications,
	setIsLoadingLoadedApplications,
	setHasLoadingErrorApplications,
	setSelectedApplication,
	setSelectedApplicationChangesClone,
	setErrorMessageSaveEditedApplication,
	setIsSavingEditedApplication
} = createActions(
	{
		SET_APPLICATIONS: applications => ({ applications }),
		SET_IS_LOADING_LOADED_APPLICATIONS: ({
			isLoadingApplications,
			isLoadedApplications
		}) => ({ isLoadingApplications, isLoadedApplications }),
		SET_HAS_LOADING_ERROR_APPLICATIONS: hasError => ({ hasError }),
		SET_SELECTED_APPLICATION: selectedApplication => ({ selectedApplication }),
		SET_SELECTED_APPLICATION_CHANGES_CLONE: selectedApplicationChangesClone => ({
			selectedApplicationChangesClone
		}),
		SET_ERROR_MESSAGE_SAVE_EDITED_APPLICATION: errorMessageSaveEditedApplication => ({
			errorMessageSaveEditedApplication
		}),
		SET_IS_SAVING_EDITED_APPLICATION: isSavingEditedApplication => ({
			isSavingEditedApplication
		})
	},
	{ prefix: ROOT_SLICE }
);

export {
	setApplications,
	setIsLoadingLoadedApplications,
	setHasLoadingErrorApplications,
	setSelectedApplication,
	setSelectedApplicationChangesClone,
	setErrorMessageSaveEditedApplication,
	setIsSavingEditedApplication
};
