/* eslint-disable no-param-reassign */
// copy / pasted / refactored from fieldline TokenUtil

export const getCookie = cname => {
	const regexp = new RegExp(`(?:^${cname}|;\\s*${cname})=(.*?)(?:;|$)`, 'g');
	const result = regexp.exec(document.cookie);
	return result ? result[1] : result;
};

export const createCookie = (name, value, expires, path, domain) => {
	let cookie = `${name}=${escape(value)};`;

	if (expires) {
		// If it's a date
		if (expires instanceof Date) {
			// If it isn't a valid date
			if (Number.isNaN(expires.getTime())) {
				expires = new Date();
			}
		} else {
			expires = new Date(
				new Date().getTime() + parseInt(expires, 10) * 1000 * 60 * 60 * 24
			);
		}

		cookie += `expires=${expires.toGMTString()};`;
	}

	if (path) {
		cookie += `path=${path};`;
	}
	if (domain) {
		cookie += `domain=${domain};`;
	}

	document.cookie = cookie;
};

export const deleteCookie = (name, path, domain) => {
	const cookieExists = getCookie(name);
	if (cookieExists) {
		createCookie(name, '', -1, path, domain);
	}
};
