/* eslint-disable no-use-before-define */
import {
	KNOWN_SURVEYS,
	SURVEY_ON_OFF,
	SURVEY_UNKNOWN
} from './survey.constants';

const badTypeFormatToValidFormatMap = {
	'ON-OFF': SURVEY_ON_OFF
};

export const mapToClientSurveyProps = (survey, lineId, jobId) => {
	return {
		lineId,
		jobId,
		type: getTypeText(survey),
		typeKey: getTypeKey(survey),
		isKnownType: isKnownSurvey(survey),
		status: getStatus(survey),
		start: getStart(survey),
		end: getEnd(survey)
	};
};

const getTypeText = (survey = {}) => {
	const result = (survey.type || '').toUpperCase();
	return badTypeFormatToValidFormatMap[result] || result;
};

/**
 * @param {*} serverSurvey - survey object matching survey format from surver
 * @returns String - surveyTypeKey - returns matching key found in KNOWN_SURVEYS. If no match is found, returns 'UNKNOWN'.
 */
const getTypeKey = (survey = {}) => {
	const typeText = getTypeText(survey);
	return isKnownSurvey(typeText) ? typeText : SURVEY_UNKNOWN;
};

const isKnownSurvey = typeText => {
	return KNOWN_SURVEYS.indexOf(typeText) > -1;
};

const getStatus = survey => {
	const { status } = survey;
	return status ? status.toUpperCase() : status;
};

const getStart = survey => {
	return getDate(survey.start);
};

const getEnd = survey => {
	return getDate(survey.end);
};

const getDate = date => {
	const result = date ? new Date(date) : undefined;
	return result && !Number.isNaN(result.getTime()) ? result : undefined;
};

export default { mapToClientSurveyProps };
