"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.calculateDistance = calculateDistance;

var _geographiclib = _interopRequireDefault(require("geographiclib"));

var _distance = _interopRequireDefault(require("@turf/distance"));

var _config = require("./config");

var _mapUtilsMisc = require("./mapUtilsMisc");

var _transformDistanceWithOptions = _interopRequireDefault(require("./transformDistanceWithOptions"));

var _UnknownDistanceAlgorithmError = _interopRequireDefault(require("../ErrorClasses/UnknownDistanceAlgorithmError"));

var _excluded = ["algorithm", "to", "reverse"];

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    "default": obj
  };
}

function ownKeys(object, enumerableOnly) {
  var keys = Object.keys(object);

  if (Object.getOwnPropertySymbols) {
    var symbols = Object.getOwnPropertySymbols(object);
    enumerableOnly && (symbols = symbols.filter(function (sym) {
      return Object.getOwnPropertyDescriptor(object, sym).enumerable;
    })), keys.push.apply(keys, symbols);
  }

  return keys;
}

function _objectSpread(target) {
  for (var i = 1; i < arguments.length; i++) {
    var source = null != arguments[i] ? arguments[i] : {};
    i % 2 ? ownKeys(Object(source), !0).forEach(function (key) {
      _defineProperty(target, key, source[key]);
    }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) {
      Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key));
    });
  }

  return target;
}

function _defineProperty(obj, key, value) {
  if (key in obj) {
    Object.defineProperty(obj, key, {
      value: value,
      enumerable: true,
      configurable: true,
      writable: true
    });
  } else {
    obj[key] = value;
  }

  return obj;
}

function _objectWithoutProperties(source, excluded) {
  if (source == null) return {};

  var target = _objectWithoutPropertiesLoose(source, excluded);

  var key, i;

  if (Object.getOwnPropertySymbols) {
    var sourceSymbolKeys = Object.getOwnPropertySymbols(source);

    for (i = 0; i < sourceSymbolKeys.length; i++) {
      key = sourceSymbolKeys[i];
      if (excluded.indexOf(key) >= 0) continue;
      if (!Object.prototype.propertyIsEnumerable.call(source, key)) continue;
      target[key] = source[key];
    }
  }

  return target;
}

function _objectWithoutPropertiesLoose(source, excluded) {
  if (source == null) return {};
  var target = {};
  var sourceKeys = Object.keys(source);
  var key, i;

  for (i = 0; i < sourceKeys.length; i++) {
    key = sourceKeys[i];
    if (excluded.indexOf(key) >= 0) continue;
    target[key] = source[key];
  }

  return target;
}

function _toConsumableArray(arr) {
  return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _unsupportedIterableToArray(arr) || _nonIterableSpread();
}

function _nonIterableSpread() {
  throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method.");
}

function _unsupportedIterableToArray(o, minLen) {
  if (!o) return;
  if (typeof o === "string") return _arrayLikeToArray(o, minLen);
  var n = Object.prototype.toString.call(o).slice(8, -1);
  if (n === "Object" && o.constructor) n = o.constructor.name;
  if (n === "Map" || n === "Set") return Array.from(o);
  if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen);
}

function _iterableToArray(iter) {
  if (typeof Symbol !== "undefined" && iter[Symbol.iterator] != null || iter["@@iterator"] != null) return Array.from(iter);
}

function _arrayWithoutHoles(arr) {
  if (Array.isArray(arr)) return _arrayLikeToArray(arr);
}

function _arrayLikeToArray(arr, len) {
  if (len == null || len > arr.length) len = arr.length;

  for (var i = 0, arr2 = new Array(len); i < len; i++) {
    arr2[i] = arr[i];
  }

  return arr2;
}

var _UNITS$DISTANCE = _config.UNITS.DISTANCE,
    FEET = _UNITS$DISTANCE.FEET,
    KILOMETERS = _UNITS$DISTANCE.KILOMETERS,
    METERS = _UNITS$DISTANCE.METERS;
var geod = _geographiclib["default"].Geodesic.WGS84;

function getTurfDistance(currentCoords, otherCoords) {
  // expects [x, y]
  return (0, _distance["default"])(currentCoords, otherCoords);
}

var getGeodCoords = function getGeodCoords(coords1, coords2) {
  return [].concat(_toConsumableArray((0, _mapUtilsMisc.formatCoords)(coords1, true)), _toConsumableArray((0, _mapUtilsMisc.formatCoords)(coords2, true)));
};

function getGeodDistance(currentCoords, otherCoords) {
  // expects y1, x1, y2, x2, so we always reverse (even if redundent)
  var r = geod.Inverse.apply(geod, _toConsumableArray(getGeodCoords(currentCoords, otherCoords)));
  return r.s12;
}

function getDistanceFromAlgo(currentCoords, otherCoords, algo) {
  switch (algo) {
    case _config.GEO_ALGORITHMS.TURF:
      return getTurfDistance(currentCoords, otherCoords);

    case _config.GEO_ALGORITHMS.GEOD:
      return getGeodDistance(currentCoords, otherCoords);

    default:
      throw new _UnknownDistanceAlgorithmError["default"]("Unkown algorithm \"".concat(algo, "\""));
  }
}

function getUnitOutputedFromAlgorithm(algo) {
  switch (algo) {
    case _config.GEO_ALGORITHMS.TURF:
      return KILOMETERS;

    case _config.GEO_ALGORITHMS.GEOD:
      return METERS;

    default:
      throw new _UnknownDistanceAlgorithmError["default"]("Unkown algorithm \"".concat(algo, "\""));
  }
} // Note that coords are supposed to be passed in as [lon, lat] - if they need to be reversed, they can be done so with { reversed: true }
// TODO: We should use lat/lon parameters instead of a coords array


function calculateDistance(currentCoords, otherCoords) {
  var options = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : {};

  var _options$algorithm = options.algorithm,
      algorithm = _options$algorithm === void 0 ? _config.GEO_ALGORITHMS.GEOD : _options$algorithm,
      _options$to = options.to,
      to = _options$to === void 0 ? FEET : _options$to,
      _options$reverse = options.reverse,
      reverse = _options$reverse === void 0 ? true : _options$reverse,
      otherOptions = _objectWithoutProperties(options, _excluded); // Note that "from" is ignored from "options" as it is implied from the algorithm


  var from = getUnitOutputedFromAlgorithm(algorithm);
  var distance = getDistanceFromAlgo((0, _mapUtilsMisc.formatCoords)(currentCoords, reverse), (0, _mapUtilsMisc.formatCoords)(otherCoords, reverse), algorithm);
  return (0, _transformDistanceWithOptions["default"])(distance, _objectSpread({
    to: to,
    from: from
  }, otherOptions));
}