const AWS_GROUP_NAMES = {
	// PLATFORM PERMISSIONS
	CUSTOMER_CORROSION_TECHNICIAN: 'CorrosionTechnician',
	CUSTOMER_PROJECT_MANAGER: 'ProjectManager',
	CUSTOMER_ADMINS: 'CustomerAdmins',
	AIP_ADMINS: 'AIPAdmins',
	AIP_POWER_USER: 'Permission:AIP.Power.User',

	// CISV PERMISSIONS
	AEGION_DATAPROCESSOR: 'Role:DP',
	AEGION_PROJECT_MANAGER: 'Role:PM',

	// ASSET_VIEW:
	AV_VIEWER: 'Permission:Av.Viewer',
	AV_MANAGER: 'Permission:Av.AssetView_Manager',
	AV_ADMINISTRATOR: 'Permission:Av.Administrator',
	AV_SYSTEM_ADMINISTRATOR: 'Permission:Av.System_Administrator'
};

export { AWS_GROUP_NAMES };

export default AWS_GROUP_NAMES;
