import React from 'react';
import UserMenu from './UserMenu';
import LoginButton from './LoginButton';
import LogoButton from './LogoButton';
import HeaderRouter from '../routing/HeaderRouter';

const Header = ({ children }) => {
	return (
		<header className="header clearfix navbar-default navbar-fixed-top">
			<button type="button" id="toggleMenu" className="toggle_menu">
				<i className="fa fa-bars" />
			</button>
			<div className="container-fluid">
				<div className="navbar-header">
					<button
						type="button"
						className="navbar-toggle collapsed"
						data-toggle="collapse"
						data-target="#app-navbar-collapse"
					>
						<span className="sr-only">Toggle Navigation</span>
						<span className="icon-bar" />
						<span className="icon-bar" />
						<span className="icon-bar" />
					</button>
					<LogoButton />
				</div>
				<div className="collapse navbar-collapse" id="app-navbar-collapse">
					<ul className="nav navbar-nav">&nbsp;</ul>
					<HeaderRouter />
					<ul className="nav navbar-nav navbar-right">
						<UserMenu />
						<LoginButton />
						{children}
					</ul>
				</div>
			</div>
		</header>
	);
};

export default Header;
