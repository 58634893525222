import { createSelector } from 'reselect';
import { selectSliceCisview } from './HELPERS';

export const selectGlobalDataAndData = state => {
	const stateCisview = selectSliceCisview(state);
	const { job: { globalData, data } = {} } = stateCisview;
	return { globalData, data };
};

export const selectGlobalTimeuuid = createSelector(
	selectGlobalDataAndData,
	({ globalData = {} } = {}) => {
		const { timeuuid: globalTimeuuid } = globalData;
		return globalTimeuuid;
	}
);

export const selectGlobalAlignedTo = createSelector(
	selectGlobalDataAndData,
	({ globalData = {} } = {}) => {
		const { alignedTo: globalAlignedTo } = globalData;
		return globalAlignedTo;
	}
);

export const selectGlobalLinkedTo = createSelector(
	selectGlobalDataAndData,
	({ globalData = {} } = {}) => {
		const { linkedTo: globalLinkedTo } = globalData;
		return globalLinkedTo;
	}
);

export const selectGlobalJobNumber = createSelector(
	selectGlobalDataAndData,
	({ globalData = {} } = {}) => {
		const { job: globalJobNumer } = globalData;
		return globalJobNumer;
	}
);

export const selectGlobalSurveyType = createSelector(
	selectGlobalDataAndData,
	({ globalData = {} } = {}) => {
		const { surveyType: globalSurveyType } = globalData;
		return globalSurveyType;
	}
);
