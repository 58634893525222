import { NAME_SPACE as ns, ROOT_STATE_KEY as sk } from './constants';

export const SHOW_EXPORT_PANEL = `${ns}_${sk}_SHOW_EXPORT_PANEL`;

export const HIDE_EXPORT_PANEL = `${ns}_${sk}_HIDE_EXPORT_PANEL`;

export const SET_PROFILE_NAME = `${ns}_${sk}_SET_PROFILE_NAME`;

export const SET_SELECTED = `${ns}_${sk}_SET_SELECTED`;

export const SET_PROFILE = `${ns}_${sk}_SET_PROFILE`;

export const RESET_RECTIFIERS_REPORTS = `${ns}_${sk}_RESET_RECTIFIER_REPORTS`;

export const ADD_RECTIFIERS_REPORT = `${ns}_${sk}_ADD_RECTIFIERS_REPORT`;

export const UPDATE_RECTIFIERS_REPORT = `${ns}_${sk}_UPDATE_RECTIFIERS_REPORT`;
