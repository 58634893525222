"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.UNITS = exports.TRANSFORM = exports.METER_TO_KILOMETER = exports.METER_TO_FEET = exports.KILOMETER_TO_METER = exports.KILOMETER_TO_FEET = exports.GEO_ALGORITHMS = exports.FEET_TO_METER = exports.FEET_TO_KILOMETER = void 0;

var _TO, _TO2, _TO3, _FROM;

function _defineProperty(obj, key, value) {
  if (key in obj) {
    Object.defineProperty(obj, key, {
      value: value,
      enumerable: true,
      configurable: true,
      writable: true
    });
  } else {
    obj[key] = value;
  }

  return obj;
}

var UNITS = {
  DISTANCE: {
    FEET: 'feet',
    METERS: 'meters',
    KILOMETERS: 'kilometers'
  }
};
exports.UNITS = UNITS;
var GEO_ALGORITHMS = {
  TURF: 'turf',
  GEOD: 'geod'
};
exports.GEO_ALGORITHMS = GEO_ALGORITHMS;
var _UNITS$DISTANCE = UNITS.DISTANCE,
    FEET = _UNITS$DISTANCE.FEET,
    METERS = _UNITS$DISTANCE.METERS,
    KILOMETERS = _UNITS$DISTANCE.KILOMETERS;
var METER_TO_FEET = 3.28084;
exports.METER_TO_FEET = METER_TO_FEET;
var KILOMETER_TO_METER = 1000;
exports.KILOMETER_TO_METER = KILOMETER_TO_METER;
var KILOMETER_TO_FEET = KILOMETER_TO_METER * METER_TO_FEET;
exports.KILOMETER_TO_FEET = KILOMETER_TO_FEET;
var FEET_TO_METER = 0.3048;
exports.FEET_TO_METER = FEET_TO_METER;
var METER_TO_KILOMETER = 0.001;
exports.METER_TO_KILOMETER = METER_TO_KILOMETER;
var FEET_TO_KILOMETER = 0.0003048; // Cannot multiply the above two variables without getting weird JS rounding issues

exports.FEET_TO_KILOMETER = FEET_TO_KILOMETER;
var TRANSFORM = {
  FROM: (_FROM = {}, _defineProperty(_FROM, KILOMETERS, {
    TO: (_TO = {}, _defineProperty(_TO, FEET, KILOMETER_TO_FEET), _defineProperty(_TO, METERS, KILOMETER_TO_METER), _defineProperty(_TO, KILOMETERS, 1), _TO)
  }), _defineProperty(_FROM, FEET, {
    TO: (_TO2 = {}, _defineProperty(_TO2, KILOMETERS, FEET_TO_KILOMETER), _defineProperty(_TO2, METERS, FEET_TO_METER), _defineProperty(_TO2, FEET, 1), _TO2)
  }), _defineProperty(_FROM, METERS, {
    TO: (_TO3 = {}, _defineProperty(_TO3, FEET, METER_TO_FEET), _defineProperty(_TO3, KILOMETERS, METER_TO_KILOMETER), _defineProperty(_TO3, METERS, 1), _TO3)
  }), _FROM)
};
exports.TRANSFORM = TRANSFORM;