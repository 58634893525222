import { SET_PROFILES } from './actionTypes';

const initialState = {
	profiles: {}
};

const reducer = exportType => (state = initialState, action = {}) => {
	const { payload = {}, type } = action;
	const { exportType: payloadExportType } = payload || {};
	switch (true) {
		case type === SET_PROFILES && exportType === payloadExportType:
			return { ...state, profiles: payload.profiles };
		default:
			return state;
	}
};

export default exportType => {
	return reducer(exportType);
};
