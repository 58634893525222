import Columns from 'aegion_common_utilities/lib/AVDataUtil/Columns';

import {
	SET_IS_HIDE_COLUMNS,
	SET_IS_OTHERS,
	SET_USER_HIDDEN_COLUMNS
} from './actionTypes';
import {
	getActionTypes,
	getDeltasByStructureName,
	getUserHiddenColumns
} from './utils';
import { setProfiles } from './Profiles/actions';
import { FILE_EXPORT_TYPE } from '../../components/Downloads/utils/fileExport';

const { getStructureSettings } = Columns;

export function getNewProfile(settings, exportType) {
	const deltas = settings.map(({ name, columns }) => {
		return {
			name,
			columns: columns
				.flat()
				.filter(({ xlsDefault = true, corDefault = true }) => {
					if (exportType === FILE_EXPORT_TYPE.CORRELATOR) {
						return !corDefault;
					}
					if (exportType === FILE_EXPORT_TYPE.EXCEL) {
						return !xlsDefault;
					}
					return false;
				})
				.map(({ name: colName, regEx }) => colName || regEx)
		};
	});

	return { deltas };
}

export const clearHistory = exportType => {
	const actionType = getActionTypes(exportType);
	return {
		type: actionType.clearHistoryType
	};
};

export const setUserHiddenColumns = (
	userHiddenColumns,
	exportType,
	resetHistory = false
) => dispatch => {
	if (resetHistory) {
		dispatch(clearHistory(exportType));
	}
	dispatch({
		type: SET_USER_HIDDEN_COLUMNS,
		payload: { userHiddenColumns, exportType }
	});
};

export const setIsOthers = (isOthers, exportType, structure) => (
	dispatch,
	getState
) => {
	const userHiddenColumns = getUserHiddenColumns(exportType, getState());
	const { deltas: oldDeltas = [] } = userHiddenColumns;
	const deltas = [...oldDeltas];
	const oldStructHiddenColumns = getDeltasByStructureName(structure, deltas);

	const structHiddenColumns = {
		...oldStructHiddenColumns,
		columns: [...(oldStructHiddenColumns.columns || [])],
		isOthers
	};
	if (deltas.indexOf(oldStructHiddenColumns) === -1) {
		deltas.push(structHiddenColumns);
	} else {
		deltas.splice(
			deltas.indexOf(oldStructHiddenColumns),
			1,
			structHiddenColumns
		);
	}
	dispatch(setUserHiddenColumns({ ...userHiddenColumns, deltas }, exportType));
};

export const setUserColumnSettings = (
	userColumnSettings,
	settings,
	exportType
) => dispatch => {
	const newProfile = getNewProfile(settings, exportType);
	const {
		profiles: oldProfiles = { 'Profile 1': userColumnSettings }
	} = userColumnSettings;
	const profiles = { ...oldProfiles, 'New Profile': newProfile };
	dispatch(setProfiles(profiles, exportType));
	dispatch(
		setUserHiddenColumns(
			profiles[
				exportType === FILE_EXPORT_TYPE.MANAGER ? 'Profile 1' : 'New Profile'
			] || [],
			exportType
		)
	);
};

export const toggleUserHiddenColumn = ({
	structure,
	name,
	add,
	exportType
}) => (dispatch, getState) => {
	const userHiddenColumns = getUserHiddenColumns(exportType, getState());
	const { deltas: oldDeltas = [] } = userHiddenColumns;

	const deltas = [...oldDeltas];
	const oldStructHiddenColumns = getDeltasByStructureName(structure, deltas);

	const structHiddenColumns = {
		...oldStructHiddenColumns,
		columns: [...(oldStructHiddenColumns.columns || [])]
	};

	if (deltas.indexOf(oldStructHiddenColumns) === -1) {
		deltas.push(structHiddenColumns);
	} else {
		deltas.splice(
			deltas.indexOf(oldStructHiddenColumns),
			1,
			structHiddenColumns
		);
	}

	if (!add) {
		if (structHiddenColumns.columns.indexOf(name) === -1) {
			structHiddenColumns.columns.push(name);
		}
	} else if (structHiddenColumns.columns.indexOf(name) !== -1) {
		structHiddenColumns.columns.splice(
			structHiddenColumns.columns.indexOf(name),
			1
		);
	}
	dispatch(setUserHiddenColumns({ ...userHiddenColumns, deltas }, exportType));
};

const updateUserHiddenColumns = (userHiddenColumns, structure, attr, value) => {
	const { deltas: oldDeltas = [] } = userHiddenColumns;

	const deltas = [...oldDeltas];
	const oldStructHiddenColumns = getDeltasByStructureName(structure, deltas);

	const structHiddenColumns = {
		...oldStructHiddenColumns,
		[attr]: value
	};

	if (deltas.indexOf(oldStructHiddenColumns) === -1) {
		deltas.push(structHiddenColumns);
	} else {
		deltas.splice(
			deltas.indexOf(oldStructHiddenColumns),
			1,
			structHiddenColumns
		);
	}
	return deltas;
};

export const resetUserHiddenColumns = (structure, exportType) => (
	dispatch,
	getState
) => {
	const userHiddenColumns = getUserHiddenColumns(exportType, getState());
	const deltas = updateUserHiddenColumns(
		userHiddenColumns,
		structure,
		'columns',
		[]
	);
	dispatch(setUserHiddenColumns({ ...userHiddenColumns, deltas }, exportType));
};

export const overrideUserHiddenColumns = (structure, exportType, override) => (
	dispatch,
	getState
) => {
	const userHiddenColumns = getUserHiddenColumns(exportType, getState());
	const deltas = updateUserHiddenColumns(
		userHiddenColumns,
		structure,
		'isHidden',
		override
	);
	dispatch(setUserHiddenColumns({ ...userHiddenColumns, deltas }, exportType));
};

export const overrideHiddenEmptyColumns = (structure, exportType, override) => (
	dispatch,
	getState
) => {
	const userHiddenColumns = getUserHiddenColumns(exportType, getState());
	const deltas = updateUserHiddenColumns(
		userHiddenColumns,
		structure,
		'isHideEmpty',
		override
	);
	dispatch(setUserHiddenColumns({ ...userHiddenColumns, deltas }, exportType));
};

export const hideAllUserHiddenColumns = (structure, exportType) => (
	dispatch,
	getState
) => {
	const {
		assetViewMain: { managerSettings }
	} = getState();
	const { columns: settingsColumn = [] } = getStructureSettings(
		managerSettings,
		structure
	);
	const hiddenColumns = settingsColumn
		.flatMap(col => col)
		.map(({ name, regEx }) => name || regEx);
	const userHiddenColumns = getUserHiddenColumns(exportType, getState());
	const deltas = updateUserHiddenColumns(
		userHiddenColumns,
		structure,
		'columns',
		hiddenColumns
	);
	dispatch(setUserHiddenColumns({ ...userHiddenColumns, deltas }, exportType));
};

export const setIsHideColumns = (isHideColumns, exportType) => {
	return {
		type: SET_IS_HIDE_COLUMNS,
		payload: { isHideColumns, exportType }
	};
};

export const undo = exportType => {
	const actionType = getActionTypes(exportType);
	return {
		type: actionType.undoType
	};
};

export const redo = exportType => {
	const actionType = getActionTypes(exportType);
	return {
		type: actionType.redoType
	};
};
