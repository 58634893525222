export const selectIsPageLoadStateLoaded = stateApp => {
	const { isPageLoadStateLoaded } = stateApp;
	return isPageLoadStateLoaded;
};

export const selectIsPageLoadStateLoading = stateApp => {
	const { isPageLoadStateLoading } = stateApp;
	return isPageLoadStateLoading;
};

export const selectPageLoadState = stateApp => {
	const { pageLoadState } = stateApp;
	return pageLoadState;
};

export default {
	selectIsPageLoadStateLoaded,
	selectIsPageLoadStateLoading,
	selectPageLoadState
};
