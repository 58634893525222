/* eslint-disable import/no-cycle */
import {
	setCompanies,
	setIsLoadingLoadedCompanies,
	setHasLoadingErrorCompanies
} from './actions';
import { ROOT_SLICE } from './constants';
import {
	selectRootSlice,
	selectIsLoadingCompanies,
	selectIsLoadedCompanies,
	selectHasLoadingErrorCompanies
} from './selectors';

const isDefined = v => v !== undefined && v !== null;

// ************
// PARTS
// ************
const reducerParts = {
	[setCompanies]: (state, { payload: { companies } }) => {
		const rootSlice = selectRootSlice(state);
		return {
			...state,
			[ROOT_SLICE]: { ...rootSlice, companies }
		};
	},

	[setHasLoadingErrorCompanies]: (state, { payload: { hasError } }) => {
		const rootSlice = selectRootSlice(state);
		const currentHasError = selectHasLoadingErrorCompanies(state);

		if (hasError === currentHasError) {
			return state;
		}

		return {
			...state,
			[ROOT_SLICE]: {
				...rootSlice,
				hasLoadingErrorCompanies: hasError
			}
		};
	},

	[setIsLoadingLoadedCompanies]: (
		state,
		{
			payload: {
				isLoadingCompanies: newIsLoadingCompanies,
				isLoadedCompanies: newIsLoadedCompanies
			}
		}
	) => {
		const rootSlice = selectRootSlice(state);
		const isLoadingCompanies = selectIsLoadingCompanies(state);
		const isLoadedCompanies = selectIsLoadedCompanies(state);

		const isLoadingChanged =
			isDefined(newIsLoadingCompanies) &&
			newIsLoadingCompanies !== isLoadingCompanies;
		const isLoadedChanged =
			isDefined(newIsLoadedCompanies) &&
			newIsLoadedCompanies !== isLoadedCompanies;

		if (!isLoadingChanged && !isLoadedChanged) {
			return state;
		}

		return {
			...state,
			[ROOT_SLICE]: {
				...rootSlice,
				isLoadingCompanies: isLoadingChanged
					? newIsLoadingCompanies
					: isLoadingCompanies,
				isLoadedCompanies: isLoadedChanged
					? newIsLoadedCompanies
					: isLoadedCompanies
			}
		};
	}
};

export default reducerParts;
