import Columns from 'aegion_common_utilities/lib/AVDataUtil/Columns';

const { getStructureSettings } = Columns;

const FILE_EXPORT_TYPE = {
	CORRELATOR: 'cor',
	EXCEL: 'xls',
	MANAGER: 'manager'
};

function getSettingsName(type) {
	switch (type) {
		case FILE_EXPORT_TYPE.CORRELATOR:
			return 'assetViewCORRExport';
		case FILE_EXPORT_TYPE.EXCEL:
			return 'assetViewXLSExport';
		case FILE_EXPORT_TYPE.MANAGER:
			return 'assetViewManagerCol';
		default:
			throw new Error('Not implemented');
	}
}

function getStructureTypes(managerSettings, structures, selected = []) {
	if (!managerSettings) {
		return [];
	}

	return structures
		.filter(
			({ count, item }) =>
				!(count === 0 || (selected.length > 0 && selected.indexOf(item) === -1))
		)
		.map(({ item }) => {
			return { ...getStructureSettings(managerSettings, item), name: item };
		});
}

export { FILE_EXPORT_TYPE, getSettingsName, getStructureTypes };
