import ajax from '../../../utils/ajax';

const jobListUtils = {
	_ajax(url, data, method, callback, isAnonymous, targetAPI = 'fieldline') {
		ajax(url, data, method, callback, isAnonymous, targetAPI);
	},

	getJobs(customer, callback) {
		const accessToken = this._getCookie('access_token');
		if (accessToken !== '') {
			this._ajax(`/jobs/${encodeURI(customer)}`, null, 'GET', (err, res) => {
				if (err) {
					callback(err);
				} else {
					callback(null, res);
				}
			});
		}
	},

	getJobMetadata(jobId, lineName, callback) {
		const accessToken = this._getCookie('access_token');
		if (accessToken !== '') {
			this._ajax(
				`/jobMetadata/${encodeURI(jobId)}/${encodeURIComponent(lineName)}`,
				null,
				'GET',
				(err, res) => {
					if (err) {
						callback(err);
					} else {
						callback(null, res);
					}
				}
			);
		}
	},

	getJobsMetadata(jobs, callback) {
		const accessToken = this._getCookie('access_token');
		if (accessToken !== '') {
			this._ajax('/jobsmetadata', JSON.stringify(jobs), 'POST', (err, res) => {
				if (err) {
					callback(err);
				} else {
					callback(null, res);
				}
			});
		}
	},

	getLines(jobId, lineName, index, callback) {
		const accessToken = this._getCookie('access_token');
		if (accessToken !== '') {
			this._ajax(
				`/lines/${encodeURI(jobId)}/${encodeURIComponent(
					lineName
				)}?index=${encodeURI(index)}`,
				null,
				'GET',
				(err, res) => {
					if (err) {
						callback(err);
					} else {
						callback(null, res);
					}
				}
			);
		}
	},

	getZip(job, type, callback) {
		const accessToken = this._getCookie('access_token');
		if (accessToken !== '') {
			this._ajax(
				'/zipfiles',
				JSON.stringify({ job, type }),
				'POST',
				(err, res) => {
					if (err) {
						callback(err);
					} else {
						callback(null, res);
					}
				}
			);
		}
	},

	getCustomers(callback) {
		const accessToken = this._getCookie('access_token');
		if (accessToken !== '') {
			this._ajax(
				'/customers',
				null,
				'GET',
				(err, res) => {
					if (err) {
						callback(err);
					} else {
						callback(null, res);
					}
				},
				false,
				'scanline'
			);
		}
	},

	getMyProjects(callback) {
		const accessToken = this._getCookie('access_token');
		if (accessToken !== '') {
			this._ajax(
				'/getProjects',
				null,
				'GET',
				(err, res) => {
					if (err) {
						callback(err);
					} else {
						callback(null, res);
					}
				},
				false,
				'scanline'
			);
		}
	},

	_getCookie(cname) {
		const name = `${cname}=`;
		const decodedCookie = decodeURIComponent(document.cookie);
		const ca = decodedCookie.split(';');
		for (let i = 0; i < ca.length; i += 1) {
			let c = ca[i];
			while (c.charAt(0) === ' ') {
				c = c.substring(1);
			}
			if (c.indexOf(name) === 0) {
				return c.substring(name.length, c.length);
			}
		}
		return '';
	}
};

export default jobListUtils;
