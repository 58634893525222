/* eslint-disable import/prefer-default-export */
/* eslint-disable import/no-cycle */
import { createActions } from 'redux-actions';
import { ROOT_SLICE } from '../constants';

const { set, setIsLoadingLoaded, setErrorMessage, clear } = createActions(
	{
		SET: data => ({ data }),
		SET_IS_LOADING_LOADED: ({ isLoading, isLoaded }) => ({
			isLoading,
			isLoaded
		}),
		SET_ERROR_MESSAGE: errorMessage => ({ errorMessage }),
		CLEAR: () => ({})
	},
	{ prefix: ROOT_SLICE }
);

export { set, setIsLoadingLoaded, setErrorMessage, clear };
