export const SURVEY_ON_OFF = 'ON_OFF';
export const SURVEY_DEPOL = 'DEPOL';
export const SURVEY_ON = 'ON';
export const SURVEY_OFF = 'OFF';
export const SURVEY_NATIVE = 'NATIVE';
export const SURVEY_DOC = 'DOC';
export const SURVEY_REL = 'REL';
export const SURVEY_DCVG = 'DCVG';
export const SURVEY_ACCA = 'DCVG';
export const SURVEY_ACVG = 'ACVG';
export const SURVEY_UNKNOWN = 'UNKNOWN';

export const KNOWN_SURVEYS = [
	SURVEY_ON_OFF,
	SURVEY_DEPOL,
	SURVEY_ON,
	SURVEY_OFF,
	SURVEY_NATIVE,
	SURVEY_ACVG,
	SURVEY_DCVG,
	SURVEY_ACCA,
	SURVEY_DOC,
	SURVEY_REL
];
export const SURVEY_PRIORITY = [
	SURVEY_ON_OFF,
	SURVEY_DEPOL,
	SURVEY_ON,
	SURVEY_OFF,
	SURVEY_NATIVE,
	SURVEY_ACVG,
	SURVEY_DCVG,
	SURVEY_ACCA,
	SURVEY_DOC,
	SURVEY_REL
];

// @note-supported-constant-needs-abstraction
