import { FILE_TYPE } from './spike-editor-config';

// eslint-disable-next-line import/prefer-default-export
export function getFileType(datFile) {
	const { onOff, onOffMethod, surveyType } = datFile;
	if (surveyType !== 'ON-OFF' && surveyType !== 'ON') {
		return FILE_TYPE.OTHER;
	}
	if (onOff !== 'T') {
		return FILE_TYPE.ON;
	}
	if (onOffMethod === 'GpsSync') {
		return FILE_TYPE.ON_OFF_GPS_SYNC;
	}
	return FILE_TYPE.ON_OFF;
}
