/* eslint-disable import/prefer-default-export */
import { isTrueReading } from '../../../../../../../utils/reading';
import { coreComputeBelowCriterions, getComparator } from './core';

export const computeBelowCriterionsByShadowLine = (
	surveyType,
	readings,
	readingsWithGaps,
	readingsWithGapsIndexMap,
	key,
	secondarySurveyType,
	shadowReadings,
	shadowMap,
	comparatorKey
) => {
	const comparator = getComparator(comparatorKey);
	const shadowReadingsLength = shadowReadings ? shadowReadings.length : 0;

	if (
		!readingsWithGaps ||
		readingsWithGaps.length === 0 ||
		shadowReadingsLength === 0
	) {
		return [];
	}

	const getThreshold = reading => {
		return shadowMap[reading.id].sh;
	};
	const canCompare = reading =>
		isTrueReading(reading) &&
		reading[key] !== 0 &&
		// eslint-disable-next-line no-prototype-builtins
		shadowMap.hasOwnProperty(reading.id);
	const calcIsZeroReading = reading =>
		isTrueReading(reading) && reading[key] === 0;

	return coreComputeBelowCriterions(
		surveyType,
		readings,
		readingsWithGaps,
		readingsWithGapsIndexMap,
		key,
		getThreshold,
		canCompare,
		calcIsZeroReading,
		comparator
	);
};
