import { buildSelectors } from './buildSelectors';
import { buildActions } from './buildActions';
import { buildReducerParts } from './buildReducerParts';
import { buildInitialState } from './initialState';

const buildRedux = ({ namespace, rootSliceKey, filtersStatePropKey }) => {
  const selectors = buildSelectors(rootSliceKey, filtersStatePropKey);
  const actions = buildActions(namespace);
  const reducerParts = buildReducerParts(actions, filtersStatePropKey);
  const initialState = buildInitialState(filtersStatePropKey);

  return {
    selectors,
    actions,
    reducerParts,
    initialState
  };
};

export default buildRedux;