/* eslint-disable import/no-cycle */
import JobUtil from '../../utils/JobUtil/JobUtil';
import awsApi from '../../../commons/redux/awsHelper/data/api';
import s3Bucket from '../../../commons/redux/awsHelper';
import { getFileName } from '../../../commons/components/surveyloader/redux/utils';

const NS_UPLOADER = 'cisv_uploader';

export const SET_MISSING_FILES = `${NS_UPLOADER}SET_MISSING_FILES`;
export const setMissingFiles = masterLST => {
	return { type: SET_MISSING_FILES, payload: masterLST };
};

export const SET_BUCKET = `${NS_UPLOADER}SET_BUCKET`;
export const setBucket = bucket => {
	return { type: SET_BUCKET, payload: bucket };
};

export const SET_S3 = `${NS_UPLOADER}SET_S3`;
export const setS3 = s3 => {
	return { type: SET_S3, payload: s3 };
};

export const DESTROY = `${NS_UPLOADER}DESTROY`;
export const destroy = () => {
	return { type: DESTROY, payload: undefined };
};

const getFolder = () => (_, getState) => {
	const {
		cisview: {
			job: {
				globalData: { job: jobNumber, lineName, customer, surveyType }
			}
		}
	} = getState();
	const folder = `uploader/${customer}${
		jobNumber ? `/${jobNumber}/` : '/'
	}${lineName}/${surveyType}`;
	return folder;
};

const checkS3 = () => (dispatch, getState) => {
	const {
		cisview: {
			uploader: { s3, folder, bucket }
		}
	} = getState();
	const isValidToken = s3.validToken();
	if (!isValidToken) {
		// eslint-disable-next-line no-use-before-define
		return dispatch(initS3(folder, bucket));
	}
	return Promise.resolve();
};

const processS3Content = content => (dispatch, getState) => {
	const {
		cisview: {
			uploader: { s3, bucket }
		}
	} = getState();

	const params = {
		Bucket: bucket,
		Key: content.Key
	};
	return dispatch(checkS3()).then(() => {
		return s3
			.headObject(params)
			.promise()
			.then(res => {
				const {
					Metadata: { ishidden = 'false', issubmit = 'false' },
					LastModified
				} = res;
				const file = {
					name: getFileName(content.Key),
					ishidden: ishidden === 'true',
					issubmit: issubmit === 'true',
					date: LastModified
				};

				return file;
			});
	});
};

export const getS3Files = () => (dispatch, getState) => {
	const {
		cisview: {
			uploader: { s3, bucket }
		}
	} = getState();
	const folder = dispatch(getFolder());
	const params = {
		Bucket: bucket,
		Prefix: `${folder}/`
	};
	// only the first 1000 this maybe an issue
	return dispatch(checkS3()).then(() => {
		return s3
			.listObjects(params)
			.promise()
			.then(({ Contents }) => {
				const processFiles = Contents.map(content => {
					return dispatch(processS3Content(content));
				});
				return Promise.all(processFiles).then(res => {
					return res.filter(file => file.issubmit);
					// finished processing files
				});
			});
	});
	// replace initAWS from index.js
	// debugger;
};

const initS3 = bucket => dispatch => {
	dispatch(setBucket(bucket));
	return awsApi.getAccess().then(res => {
		const s3 = s3Bucket(res.Credentials);
		dispatch(setS3(s3));

		const files = dispatch(getS3Files());
		return files;
	});
	// dispatch(getS3Files(folder, bucket));
};

export const init = bucket => (dispatch, getState) => {
	const {
		cisview: {
			job: {
				globalData: { timeuuid }
			}
		}
	} = getState();
	Promise.all([
		dispatch(initS3(bucket)),
		JobUtil.get(timeuuid, undefined, true)
	]).then(([s3Res, ddbRes]) => {
		const {
			Item: { MasterLST }
		} = ddbRes;
		const missingFiles = s3Res.filter(
			({ name }) => MasterLST[name] === undefined
		);
		dispatch(setMissingFiles(missingFiles));
	});
};
