/* eslint-disable import/no-cycle */
import { getMovedPointsInfo } from './moveGPSTool';
import { smoothToolUtil } from './SmoothTool';

import {
	prepareSaveMovedPoints,
	prepareSaveSmoothedPoints
} from '../../../components/leaflet/utils/ToolbarUtils';

import DatFileUtil from '../../../utils/DatFiles/DatFileUtil';
import { ActiveTools } from '../../../components/leaflet/utils/constants';

export const getMovedAndSmoothedData = getState => {
	const {
		cisview: {
			editorToolbar: {
				moveGPSTool: { movedPoints },
				smoothTool: { existingSmoothPoints, currentSmoothPoints }
			}
		}
	} = getState();
	// TODO: Needs to merge existingSmoothPoints and currentSmoothPoints
	return { movedPoints, existingSmoothPoints, currentSmoothPoints };
};

export const getAllData = getState => {
	const state = getState();
	const { cisview } = state;
	const { job } = cisview;
	const { data, globalData } = job;

	return {
		data,
		globalData
	};
};

const combineSmoothPoints = (existingSmoothPoints, currentSmoothPoints) => {
	const allSmoothedPoints = [...existingSmoothPoints, [...currentSmoothPoints]];

	return allSmoothedPoints.filter(arr => arr.length > 0);
};

export const trackMovedAndSmoothedEvents = (
	movedPoints,
	existingSmoothPoints = [],
	currentSmoothPoints = [],
	globalData
) => {
	const allSmoothedPoints = combineSmoothPoints(
		existingSmoothPoints,
		currentSmoothPoints
	);

	if ((allSmoothedPoints || []).length > 0) {
		const { datFiles, stats } = smoothToolUtil.getSmoothedPointsInfo(
			allSmoothedPoints
		);
		DatFileUtil.smoothingStarted(datFiles, stats, globalData);
	}

	if (Object.keys(movedPoints || {}).length > 0) {
		const { datFiles, stats } = getMovedPointsInfo(movedPoints);
		DatFileUtil.movingStarted(datFiles, stats, globalData);
	}
};

export const getDataForSave = getState => {
	const { data, globalData } = getAllData(getState);

	const {
		cisview: {
			editorToolbar: {
				main: { activeTool },
				moveGPSTool: { movedPoints },
				smoothTool: { existingSmoothPoints, currentSmoothPoints }
			}
		}
	} = getState();

	switch (activeTool) {
		case ActiveTools.SMOOTH:
			return prepareSaveSmoothedPoints(
				[...existingSmoothPoints, [...currentSmoothPoints]],
				data,
				globalData
			);
		case ActiveTools.MOVE_GPS:
			return prepareSaveMovedPoints({ ...movedPoints }, data, globalData);
		default:
			throw new TypeError(
				`Unknown tool ${activeTool}. This is a programming error`
			);
	}
};
