/* eslint-disable import/no-cycle */
/* eslint-disable no-console */
/* eslint-disable import/prefer-default-export */
import { createActions } from 'redux-actions';
// eslint-disable-next-line import/no-cycle
import {
	doFetchAllMissingSurveys,
	doFetchUnsupportedSurveysS3Keys,
	initMissingChartsSettings,
	fixMissingHasSawtoothData
} from './surveys';
// eslint-disable-next-line import/no-cycle
import {
	doFetchMissingReadings,
	doFetchMissingVisibleReadings
} from './readings';
// eslint-disable-next-line import/no-cycle
import { doFetchMissingUnsupportedReadings } from './unsupportedReadings';
// eslint-disable-next-line import/no-cycle
import { loadAppStateV2FromPageLoadState } from '../reducers/slices/AppStateV2/actions';

import { thunkComputeBelowCriterions } from '../reducers/slices/Charts/slices/ChartsBelowCriterions/actions';

import { loadActionPlanStateV2 } from '../reducers/slices/ActionPlanStateV2/actions';

// eslint-disable-next-line import/no-cycle
import { doFetchProjectManagers } from './projectManagers';
import { loadPageLoadState as acLoadPageLoadState } from '../reducers/slices/AppStateV2PageLoadState/actions';
import { loadChartSettings } from './app.loadChartSettings';
import { getChartId } from '../utils/chart';

import {
	hasValidViewAsPermissions,
	handleFailedViewAsPermissions
} from './app.viewAs';
import { doFetchViewAsIsPm } from './viewAs';
import { selectSurveys } from '../reducers/selectors/surveys';
import { thunkSetCustomGroupRanges } from '../reducers/slices/Charts/slices/ChartsRemediations/actions';
import { thunkRecomputeAllDataXYMinsMaxes } from '../reducers/slices/StoreXYMinMax/actions';
import { thunkComputeAllShowDecimatedData } from '../reducers/slices/Charts/slices/ChartsSettings/actions';

export const ERR_MSG_UNABLE_TO_RETRIEVE_SURVEYS =
	'Unable to retrieve any surveys for this line, please select another line or try again.';

export const ERR_MSG_NOT_SCANLINE_ENABLED =
	'The line you requested is not Scanline enabled, \n please contact Support to have this line enabled.';

export const ERR_MSG_UNABLE_TO_RETRIEVE_UPLOADED_SURVEYS =
	'Unable to retrieve uploaded surveys with this job, please select another job or try again.';

const {
	resetScanlineState,
	setAppInitMessage,
	setAppInitError,
	incrementPendingParseFileRequests,
	decrementPendingParseFileRequests,
	resetPendingParseFileRequests,
	setIsSurveyUploaderOpen
} = createActions({
	RESET_SCANLINE_STATE: () => ({}),
	SET_APP_INIT_MESSAGE: message => ({ message }),
	SET_APP_INIT_ERROR: error => ({ error }),
	INCREMENT_PENDING_PARSE_FILE_REQUESTS: () => ({}),
	DECREMENT_PENDING_PARSE_FILE_REQUESTS: () => ({}),
	RESET_PENDING_PARSE_FILE_REQUESTS: () => ({}),
	SET_IS_SURVEY_UPLOADER_OPEN: open => ({ open })
});

const getUniqueInternalIds = (surveys = []) => {
	const jobIds = surveys.map(s => s.jobId).filter(jobId => jobId);
	return [...new Set(jobIds)];
};

const computeIsInitialPageLoad = state => {
	const { surveys = [] } = state;
	const surveyIds = getUniqueInternalIds(surveys) || [];
	return surveyIds.length === 0;
};

const handleError = err => {
	console.info('ERROR');
	console.info(err);
};

const initAppMessaging = (projectId, dispatch) => {
	const itemToLoad = projectId ? 'project' : 'line';
	const message = `Loading ${itemToLoad}...`;
	dispatch(setAppInitMessage(message));
	dispatch(setAppInitError(undefined));
	return Promise.resolve();
};

const clearAppInitMessaging = dispatch => {
	dispatch(setAppInitMessage(undefined));
	return Promise.resolve();
};

const clearAppInitError = dispatch => {
	dispatch(setAppInitError(undefined));
	return Promise.resolve();
};

const getSurveys = (
	lineId,
	lineName,
	projectId,
	projectName,
	isInitialPageLoad,
	dispatch
) =>
	new Promise((resolve, reject) => {
		const errMsg = ERR_MSG_UNABLE_TO_RETRIEVE_SURVEYS;
		dispatch(
			doFetchAllMissingSurveys(
				{ lineId, lineName, projectId, projectName },
				(err, res) => {
					if (err) {
						if (isInitialPageLoad) {
							clearAppInitMessaging(dispatch);
							dispatch(setAppInitError({ message: errMsg }));
						}
						reject(err);
					} else {
						resolve(res);
					}
				}
			)
		);
	});

const getUnsupportedSurveysS3Keys = dispatch =>
	new Promise((resolve, reject) => {
		dispatch(
			doFetchUnsupportedSurveysS3Keys((err, res) => {
				if (err) {
					reject(err);
				} else {
					resolve(res);
				}
			})
		);
	});

const loadPageLoadState = (lineId, projectId, dispatch) =>
	new Promise((resolve, reject) => {
		dispatch(
			acLoadPageLoadState(lineId, projectId, (err, res) => {
				if (err) {
					dispatch(loadAppStateV2FromPageLoadState());
					reject(err);
				} else {
					dispatch(loadAppStateV2FromPageLoadState());
					resolve(res);
				}
			})
		);
	});

const getActionPlanStateV2 = (jobId, lineId, projectid, dispatch) =>
	new Promise((resolve, reject) => {
		dispatch(
			loadActionPlanStateV2(jobId, lineId, projectid, (err, res) => {
				if (err) {
					reject(err);
				} else {
					resolve(res);
				}
			})
		);
	});

// const getAppState = (jobId, lineId, projectId, dispatch) =>
// 	new Promise((resolve, reject) => {
// 		dispatch(
// 			loadAppState(jobId, lineId, projectId, (err, res) => {
// 				if (err) {
// 					reject(err);
// 				} else {
// 					resolve(res);
// 				}
// 			})
// 		);
// 	});

const getVisibleReadings = (linename, dispatch) =>
	new Promise((resolve, reject) => {
		// console.log(linename);
		// debugger;
		dispatch(
			doFetchMissingVisibleReadings(linename, (err, res) => {
				if (err) {
					reject(err);
				} else {
					resolve(res);
				}
			})
		);
	});

const getReadings = (linename, dispatch) =>
	new Promise((resolve, reject) => {
		// console.log(linename);
		// debugger;
		dispatch(
			doFetchMissingReadings(linename, (err, res) => {
				if (err) {
					reject(err);
				} else {
					resolve(res);
				}
			})
		);
	});

const getUnsupportedReadings = (lineId, projectId, dispatch) =>
	new Promise((resolve, reject) => {
		dispatch(
			doFetchMissingUnsupportedReadings(lineId, projectId, (err, res) => {
				if (err) {
					reject(err);
				} else {
					resolve(res);
				}
			})
		);
	});

const computePrimaryChartBelowCriterions = () => (dispatch, getState) => {
	const { app } = getState();
	const { defaultSurvey } = app;
	const chartId = getChartId(defaultSurvey);

	dispatch(thunkComputeBelowCriterions(chartId));
};

const s3StandaloneProjectInitPrimaryChartRemediations = () => (
	dispatch,
	getState
) => {
	const { app } = getState();
	const { defaultSurvey } = app;
	const chartId = getChartId(defaultSurvey);

	dispatch(thunkSetCustomGroupRanges(chartId, undefined));
};

// eslint-disable-next-line no-unused-vars
const doLoadAllMissingData = ({ lineId, lineName, projectId, projectName }) => (
	dispatch,
	getState
) => {
	const isInitialPageLoad = computeIsInitialPageLoad(getState());

	// const logSurveys = () =>
	// 	console.log({
	// 		surveys: getState().app.surveys,
	// 		defaultSurvey: getState().app.defaultSurvey
	// 	});

	if (isInitialPageLoad) {
		dispatch(doFetchViewAsIsPm());
		initAppMessaging(projectId, dispatch)
			.then(() => hasValidViewAsPermissions())
			.then(async hasViewAsPermissions => {
				if (hasViewAsPermissions) {
					doFetchProjectManagers(dispatch, getState);
					return getSurveys(
						lineId,
						lineName,
						projectId,
						projectName,
						isInitialPageLoad,
						dispatch
					).then(() => {
						const { app } = getState();
						const surveys = selectSurveys(app);

						if (surveys && surveys.length) {
							const { defaultSurvey } = app;
							const { jobId } = defaultSurvey || {};

							if (jobId) {
								getActionPlanStateV2(jobId, lineId, projectId, dispatch);
							}
							return loadPageLoadState(lineId, projectId, dispatch)
								.then(() => getUnsupportedSurveysS3Keys(dispatch))
								.then(() =>
									getVisibleReadings(lineName || projectName, dispatch)
								)
								.then(() => loadChartSettings(dispatch, getState))
								.then(() => clearAppInitMessaging(dispatch))
								.then(() => clearAppInitError(dispatch))
								.then(() => getReadings(lineName || projectName, dispatch))
								.then(() => dispatch(initMissingChartsSettings()))
								.then(() => dispatch(thunkRecomputeAllDataXYMinsMaxes()))
								.then(() => dispatch(thunkComputeAllShowDecimatedData()))
								.then(() => dispatch(fixMissingHasSawtoothData()))
								.then(() => dispatch(computePrimaryChartBelowCriterions()))
								.then(() => getUnsupportedReadings(lineId, projectId, dispatch))
								.then(() => dispatch(initMissingChartsSettings()))
								.then(() => dispatch(thunkRecomputeAllDataXYMinsMaxes()))
								.then(() => dispatch(thunkComputeAllShowDecimatedData()))
								.then(() => dispatch(fixMissingHasSawtoothData()));
						}

						return getUnsupportedSurveysS3Keys(dispatch)
							.then(() => getReadings(lineName || projectName, dispatch))
							.then(() => getUnsupportedReadings(lineId, projectId, dispatch))
							.then(() => loadPageLoadState(lineId, projectId, dispatch))
							.then(() => loadChartSettings(dispatch, getState))
							.then(() => clearAppInitMessaging(dispatch))
							.then(() => clearAppInitError(dispatch))
							.then(() => dispatch(initMissingChartsSettings()))
							.then(() => dispatch(fixMissingHasSawtoothData()))
							.then(() => dispatch(thunkRecomputeAllDataXYMinsMaxes()))
							.then(() => dispatch(thunkComputeAllShowDecimatedData()))
							.then(() => dispatch(computePrimaryChartBelowCriterions()))
							.then(() =>
								dispatch(s3StandaloneProjectInitPrimaryChartRemediations())
							)
							.then(() => clearAppInitError(dispatch))
							.catch(handleError);
					});
				}

				handleFailedViewAsPermissions(dispatch);
				clearAppInitMessaging(dispatch);
				return undefined;
			})
			.catch(err => {
				clearAppInitMessaging(dispatch);
				handleError(err);
			});
	} else {
		getSurveys(
			lineId,
			lineName,
			projectId,
			projectName,
			isInitialPageLoad,
			dispatch
		)
			.then(() => getUnsupportedSurveysS3Keys(dispatch))
			.then(() => getReadings(lineName || projectName, dispatch))
			.then(() => getUnsupportedReadings(lineId, projectId, dispatch))
			.then(() => dispatch(initMissingChartsSettings()))
			.then(() => dispatch(fixMissingHasSawtoothData()))
			.then(() => dispatch(thunkRecomputeAllDataXYMinsMaxes()))
			.then(() => dispatch(thunkComputeAllShowDecimatedData()))
			.then(() => clearAppInitError(dispatch))
			.catch(handleError);
	}
};

export {
	resetScanlineState,
	setAppInitMessage,
	setAppInitError,
	doLoadAllMissingData,
	incrementPendingParseFileRequests,
	decrementPendingParseFileRequests,
	resetPendingParseFileRequests,
	setIsSurveyUploaderOpen
};
