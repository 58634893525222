// eslint-disable-next-line import/no-cycle
import ajax from '../../../../../scanline/utils/ajax';
import { hasAccessToken } from '../../../../../scanline/utils/token';

import { transformHttpCustomers } from './util';

export const fetchCustomers = callback => {
	if (hasAccessToken()) {
		ajax(
			`/customers`,
			null,
			'POST',
			(err, res) => {
				if (err) {
					callback(err);
				} else {
					const customers = transformHttpCustomers({ list: res });

					callback(null, customers);
				}
			},
			undefined,
			'cisviewextended'
		);
	}
};

export default { fetchCustomers };
