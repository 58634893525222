/* eslint-disable import/no-cycle */
import {
	setSelectedCustomer,
	setSelectedCustomerChangesClone,
	setIsSavingEditedCustomer,
	setIsDeletingCustomer,
	setErrorMessageSaveEditedCustomer,
	setShowConfirmation,
	setConfirmationMessageKey
} from './actions';
import { ROOT_SLICE } from './constants';
import { selectRootSlice } from './selectors';

// ************
// PARTS
// ************
const reducerParts = {
	[setSelectedCustomer]: (state, { payload: { selectedCustomer } }) => {
		const rootSlice = selectRootSlice(state);
		return {
			...state,
			[ROOT_SLICE]: { ...rootSlice, selectedCustomer }
		};
	},

	[setSelectedCustomerChangesClone]: (
		state,
		{ payload: { selectedCustomerChangesClone } }
	) => {
		const rootSlice = selectRootSlice(state);
		return {
			...state,
			[ROOT_SLICE]: { ...rootSlice, selectedCustomerChangesClone }
		};
	},

	[setIsSavingEditedCustomer]: (
		state,
		{ payload: { isSavingEditedCustomer } }
	) => {
		const rootSlice = selectRootSlice(state);
		return {
			...state,
			[ROOT_SLICE]: {
				...rootSlice,
				isSavingEditedCustomer: !!isSavingEditedCustomer
			}
		};
	},

	[setIsDeletingCustomer]: (state, { payload: { isDeletingCustomer } }) => {
		const rootSlice = selectRootSlice(state);
		return {
			...state,
			[ROOT_SLICE]: {
				...rootSlice,
				isDeletingCustomer: !!isDeletingCustomer
			}
		};
	},

	[setErrorMessageSaveEditedCustomer]: (
		state,
		{ payload: { errorMessageSaveEditedCustomer } }
	) => {
		const rootSlice = selectRootSlice(state);
		return {
			...state,
			[ROOT_SLICE]: {
				...rootSlice,
				errorMessageSaveEditedCustomer
			}
		};
	},

	[setShowConfirmation]: (state, { payload: { showConfirmation } }) => {
		const rootSlice = selectRootSlice(state);
		return {
			...state,
			[ROOT_SLICE]: { ...rootSlice, showConfirmation }
		};
	},

	[setConfirmationMessageKey]: (
		state,
		{ payload: { confirmationMessageKey } }
	) => {
		const rootSlice = selectRootSlice(state);
		return {
			...state,
			[ROOT_SLICE]: { ...rootSlice, confirmationMessageKey }
		};
	}
};

export default reducerParts;
