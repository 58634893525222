/*
* TODO:
* - When we clear auto correction for file
* - When we clear all auto correction
* - Set When a corrected point is hovered
* - Remove autocorrected point

*/

// eslint-disable-next-line import/no-cycle
import AutoCorrectionUtil from '../../utils/AutoCorrectionUtil';

const defaultState = {
	spikeEditorUserChangesByFile: {
		/* Will look like:
		someFileName: {
			autoGpsCorrectionInProcess: true,
			coordinateLedger: [],
			abTestingInfoCoordinates: {},
			cachedMergedGpsCoordinates,
			userActions: [],
			datFile
		}
		*/
	},
	readingsLedgersByFile: {},
	autoCorrectedReadingsDataByFile: {
		/* Will look like the following
		'tiny.dat': GPSREADINGS
		*/
	}
};

const _getSpikedEditorChangesByDatFile = (state, fileName) => {
	// spikeEditorUserChanges should be stored by datFilename
	const { spikeEditorUserChangesByFile } = state;

	const spikeEditorUserChanges = spikeEditorUserChangesByFile[fileName];
	return spikeEditorUserChanges || [];
};

const prepareReadingsForSpikeEditor = (
	data,
	readingsLedgersByFile,
	spikeEditorUserChangesByFile
) => {
	if (!data || !readingsLedgersByFile) {
		return {};
	}
	const mLAutoCorrectedGpsReadings = data.map(datFile =>
		AutoCorrectionUtil.mergeReadingsWithFlagsAndUserChanges(
			datFile,
			readingsLedgersByFile,
			spikeEditorUserChangesByFile
		)
	);

	const autoCorrectedReadingsDataByFile = {};

	mLAutoCorrectedGpsReadings.forEach((gpsreadings, i) => {
		const datFile = data[i];
		if (datFile && datFile.fileName) {
			autoCorrectedReadingsDataByFile[datFile.fileName] = gpsreadings;
		}
	});

	return autoCorrectedReadingsDataByFile;
};

// TODO: Call prepareReadingsForSpikeEditor
const addToSpikeEditorStackChanges = (state, changes, datFile) => {
	const {
		spikeEditorUserChangesByFile,
		readingsLedgersByFile,
		autoCorrectedReadingsDataByFile
	} = state;
	const { fileName } = datFile;
	const spikeEditorUserChanges = _getSpikedEditorChangesByDatFile(
		state,
		fileName
	);
	const newUserChangesByFile = {
		...spikeEditorUserChangesByFile,
		[fileName]: [...spikeEditorUserChanges, changes]
	};
	const autoCorrectedReadingsDataForThisFile = prepareReadingsForSpikeEditor(
		[datFile],
		readingsLedgersByFile,
		newUserChangesByFile
	)[fileName];

	return {
		...state,
		autoCorrectedReadingsDataByFile: {
			...autoCorrectedReadingsDataByFile,
			[fileName]: autoCorrectedReadingsDataForThisFile
		},
		spikeEditorUserChangesByFile: newUserChangesByFile
	};
};

// TODO: Call prepareReadingsForSpikeEditor
const removeFromSpikeEditorUserChangesByFile = (state, datFile) => {
	const {
		spikeEditorUserChangesByFile,
		readingsLedgersByFile,
		autoCorrectedReadingsDataByFile
	} = state;
	const { fileName } = datFile;
	const thisFilesNewUserChanges = _getSpikedEditorChangesByDatFile(
		state,
		fileName
	).slice(0, -1);
	const newSpikeEditorUserChanges = {
		...spikeEditorUserChangesByFile,
		[fileName]: thisFilesNewUserChanges
	};

	const autoCorrectedReadingsDataForThisFile = prepareReadingsForSpikeEditor(
		[datFile],
		readingsLedgersByFile,
		newSpikeEditorUserChanges
	)[fileName];

	return {
		...state,
		spikeEditorUserChangesByFile: newSpikeEditorUserChanges,
		autoCorrectedReadingsDataByFile: {
			...autoCorrectedReadingsDataByFile,
			[fileName]: autoCorrectedReadingsDataForThisFile
		}
	};
};

const updatedReadingLedgerBasedOnUpdate = (state, payload) => {
	const { datFile, newReadings1Ledger, newReadings2Ledger } = payload;
	const {
		readingsLedgersByFile,
		spikeEditorUserChangesByFile,
		autoCorrectedReadingsDataByFile
	} = state;
	const { fileName } = datFile;

	const autoCorrectedReadingsDataForThisFile = prepareReadingsForSpikeEditor(
		[datFile],
		readingsLedgersByFile,
		spikeEditorUserChangesByFile
	)[fileName];

	return {
		...state,
		readingsLedgersByFile: {
			...readingsLedgersByFile,
			[fileName]: {
				readings1: newReadings1Ledger,
				readings2: newReadings2Ledger
			}
		},
		autoCorrectedReadingsDataByFile: {
			...autoCorrectedReadingsDataByFile,
			[fileName]: autoCorrectedReadingsDataForThisFile
		}
	};
};

const setReadingLedger = (state, datFile, ledgers) => {
	// TODO: Verify `datFile` and future items
	const { fileName } = datFile;
	const {
		readingsLedgersByFile,
		spikeEditorUserChangesByFile,
		autoCorrectedReadingsDataByFile
	} = state;
	const newReadingsLedgersByFile = {
		...readingsLedgersByFile,
		[fileName]: ledgers
	};

	const autoCorrectedReadingsDataForThisFile = prepareReadingsForSpikeEditor(
		[datFile],
		newReadingsLedgersByFile,
		spikeEditorUserChangesByFile
	)[fileName];

	return {
		...state,
		readingsLedgersByFile: newReadingsLedgersByFile,
		spikeEditorUserChangesByFile: {
			...spikeEditorUserChangesByFile,
			[fileName]: []
		},
		autoCorrectedReadingsDataByFile: {
			...autoCorrectedReadingsDataByFile,
			[fileName]: autoCorrectedReadingsDataForThisFile
		}
	};
};

const clearAutoCorrectedReadingsData = (state, datFile) => {
	const { fileName } = datFile;
	const {
		readingsLedgersByFile,
		spikeEditorUserChangesByFile,
		autoCorrectedReadingsDataByFile
	} = state;
	return {
		...state,
		readingsLedgersByFile: {
			...readingsLedgersByFile,
			[fileName]: null
		},
		spikeEditorUserChangesByFile: {
			...spikeEditorUserChangesByFile,
			[fileName]: []
		},
		autoCorrectedReadingsDataByFile: {
			...autoCorrectedReadingsDataByFile,
			[fileName]: null
		}
	};
};

const setReadingsChanged = (state, data) => {
	const { readingsLedgersByFile, spikeEditorUserChangesByFile } = state;

	return {
		...state,
		autoCorrectedReadingsDataByFile: prepareReadingsForSpikeEditor(
			data,
			readingsLedgersByFile,
			spikeEditorUserChangesByFile
		)
	};
};

const readingsAutoCorrection = (state = defaultState, action) => {
	switch (action.type) {
		// case 'READINGS_AUTO_CORRECTION_SET_NEW_RESPONSE':
		case 'READINGS_AUTO_CORRECTION_RECEIVE_CLEAR_BY_FILE':
			return clearAutoCorrectedReadingsData(state, action.datFile);
		case 'READINGS_AUTO_CORRECTION_REVERT_USER_ACTIONS':
			return removeFromSpikeEditorUserChangesByFile(state, action.datFile);
		case 'READINGS_AUTO_CORRECTION_ADD_USER_ACTION':
			return addToSpikeEditorStackChanges(
				state,
				action.changes,
				action.datFile
			);
		case 'READINGS_AUTO_CORRECTION_UPDATE':
			return updatedReadingLedgerBasedOnUpdate(state, action.payload);

		case 'READINGS_AUTO_CORRECTION_SET_LEDGER':
			return setReadingLedger(state, action.datFile, action.ledgers);

		case 'READINGS_AUTO_CORRECTION_READINGS_CHANGED':
			return setReadingsChanged(state, action.data);

		default:
			return state;
	}
};

export default readingsAutoCorrection;
