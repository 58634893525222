import {
	SET_CUSTOMER,
	SET_CUSTOMERS,
	SET_SITE,
	SET_SITES,
	SET_ZONE,
	SET_ZONES,
	SET_READINGS,
	SET_TIME_PARAMETERS
} from './actionTypes';
import { defaultCustomer, defaultSite, defaultZone } from './constants';

import { getFilterSpecs, TimeFilterTypeList } from './utils/time';

const initialState = {
	customer: defaultCustomer,
	customers: [defaultCustomer],
	sites: [defaultSite],
	site: defaultSite,
	zones: [defaultZone],
	zone: defaultZone,
	readings: [],
	timeFilterType: 'last7Days',
	timeFilterBegin: getFilterSpecs('last7Days').timeFilterBegin,
	timeFilterEnd: getFilterSpecs('last7Days').timeFilterEnd,
	timeFilterBGColor: TimeFilterTypeList.find(f => f.value === 'last7Days')
		.bgColor
};

const reducer = (state = initialState, action) => {
	const { type, payload } = action;
	switch (type) {
		case SET_CUSTOMER:
			return { ...state, customer: payload };
		case SET_CUSTOMERS:
			return { ...state, customers: payload };
		case SET_SITE:
			return { ...state, site: payload };
		case SET_SITES:
			return { ...state, sites: payload };
		case SET_ZONE:
			return { ...state, zone: payload };
		case SET_ZONES:
			return { ...state, zones: payload };
		case SET_READINGS:
			return { ...state, readings: payload };
		case SET_TIME_PARAMETERS:
			return { ...state, ...payload };
		default:
			return state;
	}
};

export default reducer;
