/* eslint import/no-cycle:0 */
import {
	SET_MOVE_GPS_POINT,
	SET_EDIT_LINE,
	SET_DRAGGING
} from '../../actions/moveGPSTool';

import { CLEAR } from '../../actions/editorToolbar';

const defaultState = {
	moveGPSPoint: undefined, // leaftlet feature
	dragging: false,
	movedPoints: {} /*  This is done as an object instead of an array
                        so it can be split into different segments and 
                        store the edit point
    {
        123:{},
        124:{editLatLng:{lat:,lng}},
        125:{},
        230:{},
        234:{editLatLng:{lat:,lng}},
        235:{}
    }
    */,
	editLine: null,
	commentPoints: undefined
};

const reducer = (state = defaultState, action) => {
	const { type, payload } = action;
	switch (type) {
		case SET_MOVE_GPS_POINT:
			return { ...state, moveGPSPoint: { ...payload } };
		case SET_EDIT_LINE:
			return {
				...state,
				editLine: payload.editLine,
				movedPoints: payload.movedPoints,
				commentPoints: payload.commentPoints
			};
		case SET_DRAGGING:
			return {
				...state,
				dragging: payload
			};
		case CLEAR:
			return {
				...state,
				editLine: null,
				movedPoints: {},
				moveGPSPoint: undefined,
				dragging: false,
				commentPoints: undefined
			};
		default:
			return state;
	}
};

export default reducer;
