import { DateTimeless } from 'aegion_common_utilities/lib/DateTimeless';
import { isDate } from 'aegion_common_utilities/lib/DateUtil';
import { MM_DD_YYYY } from 'aegion_common_utilities/lib/format/datetimeless';

/* eslint-disable no-unused-vars */
export const TODAY = 'TODAY';
export const PAST_7_DAYS = 'PAST_7_DAYS';
export const PAST_30_DAYS = 'PAST_30_DAYS';
export const PAST_90_DAYS = 'PAST_90_DAYS';
export const YEAR_TO_DATE = 'YEAR_TO_DATE';
export const PRIOR_YEAR = 'PRIOR_YEAR';
export const OLDER_THAN_15_MONTHS = 'OLDER_THAN_15_MONTHS';
export const BEFORE_DATE = 'BEFORE_DATE';
export const DATE_RANGE = 'DATE_RANGE';

const DEFAULT_TYPE = OLDER_THAN_15_MONTHS;

const isDefined = v => v !== undefined && v !== null;

const returnUndefined = () => undefined;

const addDays = (date, delta) => {
	date.setDate(date.getDate() + delta);
	return date;
};

const addMonths = (date, delta) => {
	date.setMonth(date.getMonth() + delta);
	return date;
};

const addYears = (date, delta) => {
	date.setFullYear(date.getFullYear() + delta);
	return date;
};

const METAS = {
	TODAY: {
		order: 0,
		key: TODAY,
		label: 'Today',
		autoUpdateUrl: true,
		tooltipText: () => 'Today',
		computeNewFrom: (currFrom, currTo) => {
			return new DateTimeless();
		},
		computeNewTo: returnUndefined
	},

	PAST_7_DAYS: {
		order: 1,
		key: PAST_7_DAYS,
		label: 'Past 7 Days',
		autoUpdateUrl: true,
		tooltipText: () => 'Within 7 days',
		computeNewFrom: (currFrom, currTo) => {
			return addDays(new DateTimeless(), -7);
		},
		computeNewTo: returnUndefined
	},

	PAST_30_DAYS: {
		order: 2,
		key: PAST_30_DAYS,
		label: 'Past 30 Days',
		autoUpdateUrl: true,
		tooltipText: () => 'Within 30 days',
		computeNewFrom: (currFrom, currTo) => {
			return addDays(new DateTimeless(), -30);
		},
		computeNewTo: returnUndefined
	},

	PAST_90_DAYS: {
		order: 3,
		key: PAST_90_DAYS,
		label: 'Past 90 Days',
		autoUpdateUrl: true,
		tooltipText: () => 'Within 90 days',
		computeNewFrom: (currFrom, currTo) => {
			return addDays(new DateTimeless(), -90);
		},
		computeNewTo: returnUndefined
	},

	YEAR_TO_DATE: {
		order: 4,
		key: YEAR_TO_DATE,
		label: 'Year to Date',
		autoUpdateUrl: true,
		tooltipText: () => 'Year to Date',
		computeNewFrom: (currFrom, currTo) => {
			return new DateTimeless(`${new Date().getFullYear()}-01-01`);
		},
		computeNewTo: returnUndefined
	},

	PRIOR_YEAR: {
		order: 5,
		key: PRIOR_YEAR,
		label: 'Prior Year',
		autoUpdateUrl: true,
		tooltipText: () => 'Prior Year',
		computeNewFrom: (currFrom, currTo) => {
			const previousYear = new Date().getFullYear() - 1;
			return new DateTimeless(`${previousYear}-01-01`);
		},
		computeNewTo: (currFrom, currTo) => {
			const previousYear = new Date().getFullYear() - 1;
			return new DateTimeless(`${previousYear}-12-31`);
		}
	},

	OLDER_THAN_15_MONTHS: {
		order: 6,
		key: OLDER_THAN_15_MONTHS,
		label: 'Older than 15 Months',
		autoUpdateUrl: true,
		tooltipText: () => 'Older than 15 months.',
		computeNewFrom: returnUndefined,
		computeNewTo: (currFrom, currTo) => {
			return addMonths(new DateTimeless(), -15);
		}
	},

	BEFORE_DATE: {
		order: 7,
		key: BEFORE_DATE,
		label: 'Before Date',
		tooltipText: (from, to) => `Before ${MM_DD_YYYY(to)}`,
		computeNewFrom: returnUndefined,
		computeNewTo: (currFrom, currTo) => {
			return isDefined(currTo) && isDate(currTo) ? currTo : new DateTimeless();
		}
	},

	DATE_RANGE: {
		order: 8,
		key: DATE_RANGE,
		label: 'Date Range',
		tooltipText: (from, to) => {
			if (isDate(from) && isDate(to)) {
				return `${MM_DD_YYYY(from)} - ${MM_DD_YYYY(to)}`;
			}
			if (isDate(from)) {
				return `After ${MM_DD_YYYY(from)}`;
			}
			if (isDate(to)) {
				return `Before ${MM_DD_YYYY(to)}`;
			}
			return ''; // this case should not happen
		},
		computeNewFrom: (currFrom, currTo) => {
			const newFrom = addMonths(new DateTimeless(), -1);
			return isDefined(currFrom) && isDate(currFrom)
				? new DateTimeless(currFrom)
				: newFrom;
		},
		computeNewTo: (currFrom, currTo) => {
			const newTo = new DateTimeless();
			return isDefined(currTo) && isDate(currTo)
				? new DateTimeless(currTo)
				: newTo;
		}
	}
};

const LIST = Object.keys(METAS)
	.sort((k1, k2) => {
		return METAS[k1].order > METAS[k2].order;
	})
	.map(k => METAS[k]);

export const DATE_RANGE_TYPE_METAS = METAS;

export const LIST_DATE_RANGE_TYPE_METAS = LIST;

export const sanitizeDateRangeTypeKey = paramKey => {
	const key = (paramKey || '').toUpperCase();
	return METAS[key] ? key : DEFAULT_TYPE;
};

export const getDateRangeTypeMeta = paramKey => {
	const key = sanitizeDateRangeTypeKey(paramKey);
	return METAS[key];
};
