import { MiscUtils } from 'aegion_common_utilities';

const { roundToDigitPlace } = MiscUtils;

const firstWithValue = newStationIds => {
	for (let i = 0; i < newStationIds.length; i += 1) {
		if (newStationIds[i] !== null) {
			return i;
		}
	}

	return -1;
};

const realignStationsBackwards = (old, newStationIds) => {
	const lastIndex = firstWithValue(newStationIds);
	const weighted = [];
	let previousValue;
	for (let i = lastIndex; i > -1; i -= 1) {
		const oldVal = old[i];
		const diff = oldVal - old[i + 1];
		const newVal = newStationIds[i];
		let weightedVal = newVal;
		if (weightedVal === null) {
			weightedVal = previousValue + diff;
		}
		weighted.push(weightedVal);
		previousValue = weightedVal;
	}

	return weighted.reverse();
};

// Get the indexes where newStationIds are not null
const findNotNullIndexes = (newStationIds, idsFoundCount) => {
	let start = 0;
	if (idsFoundCount > 0) {
		start = idsFoundCount - 1;
	}

	const indexes = [];
	for (let i = start; i < newStationIds.length; i += 1) {
		if (newStationIds[i] !== null) {
			indexes.push(i);
		}
	}

	return indexes;
};

// This will include the surrounding (start/end) points
export const realignStationsBetween = (old, newStationIds, start, end) => {
	const newStationIdsStart = newStationIds[start];
	const newStationIdsEnd = newStationIds[end];
	const newStationIdsDiff = newStationIdsEnd - newStationIdsStart;

	const oldStart = old[start];
	const oldEnd = old[end];
	const oldDiffAll = oldEnd - oldStart;

	const weighted = [newStationIdsStart];

	let previousValue = newStationIdsStart;
	for (let i = start + 1; i < end; i += 1) {
		const oldValue = old[i];
		const previousOldValue = old[i - 1];

		const oldDiff = oldValue - previousOldValue;
		const magicDiff = newStationIdsDiff / oldDiffAll;
		const newStationIdsY = previousValue + oldDiff * magicDiff;
		previousValue = newStationIdsY;

		weighted.push(newStationIdsY);
	}
	weighted.push(newStationIdsEnd);
	return weighted;
};

// This will include the surrounding (start/end) points
export const realignStationsBetweenAll = (old, newStationIds, indexes) => {
	let weighted = [];
	for (let i = 0; i < indexes.length - 1; i += 1) {
		const start = indexes[i];
		const end = indexes[i + 1];

		const newWeights = realignStationsBetween(old, newStationIds, start, end);
		if (weighted.length > 0) {
			newWeights.splice(0, 1); // Remove first item as it is already included
		}
		weighted = [...weighted, ...newWeights];
	}

	return weighted;
};

// Starts after previous set (does not include previousValueStart)
const realignForward = (previousValueStart, start, old, newStationIds) => {
	const weighted = [];
	let previousValue = previousValueStart;
	for (let i = start; i < old.length; i += 1) {
		const oldVal = old[i];
		const diff = oldVal - old[i - 1];
		const newVal = newStationIds[i];
		let weightedVal = newVal;
		if (weightedVal === null) {
			weightedVal = previousValue + diff;
		}
		weighted.push(weightedVal);
		previousValue = weightedVal;
	}

	return weighted;
};

export const realignStations = (old, newStationIds) => {
	let weighted = [];
	if (newStationIds[0] === null) {
		weighted = realignStationsBackwards(old, newStationIds);
	}

	const newStationIdsValues = findNotNullIndexes(
		newStationIds,
		weighted.length
	);
	if (newStationIdsValues.length > 1) {
		weighted = [
			...weighted.slice(0, -1), // remove the last item here as it is included in the following spread
			...realignStationsBetweenAll(old, newStationIds, newStationIdsValues)
		];
	}

	const previousValue = weighted[weighted.length - 1];
	const weightsAfter = realignForward(
		previousValue,
		weighted.length,
		old,
		newStationIds
	);

	return [...weighted, ...weightsAfter];
};

export const createDummyStationsBefore = readingSelected => {
	const { readingIndex, sourceStationId } = readingSelected;

	const dummyStations = [];
	for (let i = 0; i < readingIndex; i += 1) {
		dummyStations.push(null);
	}

	dummyStations.push(sourceStationId);
	return dummyStations;
};

export const createDummyStationsBetween = (
	readingSelectedBefore,
	readingSelectedAfter
) => {
	const { readingIndex: startIndex } = readingSelectedBefore;
	const {
		readingIndex: endIndex,
		sourceStationId: lastStationId
	} = readingSelectedAfter;

	const dummyStations = [];
	for (let i = startIndex + 1; i < endIndex; i += 1) {
		dummyStations.push(null);
	}

	dummyStations.push(lastStationId);

	return dummyStations;
};

export const createDummyStationsAfter = (readingSelected, countStations) => {
	const { readingIndex: startIndex, sourceStationId } = readingSelected;

	const dummyStations = [sourceStationId];
	for (let i = startIndex + 1; i < countStations; i += 1) {
		dummyStations.push(null);
	}

	return dummyStations;
};

const createAllDummyStationsBetween = readingsSelected => {
	let dummyStations = [];
	for (let i = 0; i < readingsSelected.length - 1; i += 1) {
		const readingSelected = readingsSelected[i];
		const nextReadingSelected = readingsSelected[i + 1];
		dummyStations = [
			...dummyStations,
			...createDummyStationsBetween(readingSelected, nextReadingSelected)
		];
	}

	return dummyStations.slice(0, -1); // Remove last item as it is already included
};

export const createDummyStations = (readingsSelected, oldStations) => {
	if ((readingsSelected || []).length === 0) {
		return null;
	}
	const firstDummyStations = createDummyStationsBefore(readingsSelected[0]);

	const lastReadingSelected = readingsSelected[readingsSelected.length - 1];
	const lastDummyStations = createDummyStationsAfter(
		lastReadingSelected,
		oldStations.length
	);

	if (readingsSelected.length === 1) {
		const lastDummiesWithoutFirst = lastDummyStations.slice(1);
		return [...firstDummyStations, ...lastDummiesWithoutFirst];
	}

	const dummyStationsBetween = createAllDummyStationsBetween(readingsSelected);

	return [...firstDummyStations, ...dummyStationsBetween, ...lastDummyStations];
};

export const getFlattenedStationIds = convertedReadings => {
	return convertedReadings.map(r => r.stationId);
};

export const extendReadingWithStationIdAndInterval = (
	reading,
	stationId,
	stationFieldName = 'stationId',
	surveyInterval
) => {
	return {
		...reading,
		[stationFieldName]: roundToDigitPlace(stationId, 2),
		surveyInterval: roundToDigitPlace(surveyInterval, 2)
	};
};

const getSurveyInterval = (stationId1, stationId2) => {
	return Math.abs(stationId2 - stationId1);
};

export const extendReadingsWithStationids = (
	readings,
	stationIds,
	stationFieldName = 'stationId'
) => {
	let previousStationId = null;
	return readings.map((reading, i) => {
		const stationId = stationIds[i];
		let surveyInterval = 0;
		if (previousStationId !== null) {
			surveyInterval = getSurveyInterval(previousStationId, stationId);
		}

		previousStationId = stationId;

		return extendReadingWithStationIdAndInterval(
			reading,
			stationId,
			stationFieldName,
			surveyInterval
		);
	});
};

const filterGoodSelectedReadings = readingsSelected => {
	return readingsSelected.filter(r => r.sourceStationId !== undefined);
};

export const realignReadingsAndStations = (
	convertedReadings,
	readingsSelected,
	stationFieldName = 'stationId'
) => {
	const goodReadingsSelected = filterGoodSelectedReadings(readingsSelected);
	if ((goodReadingsSelected || []).length === 0) {
		return [];
	}
	const stationIds = getFlattenedStationIds(convertedReadings);
	const dummyStationIds = createDummyStations(goodReadingsSelected, stationIds);

	const realignedStationIds = realignStations(stationIds, dummyStationIds);

	const alignedReadings = extendReadingsWithStationids(
		convertedReadings,
		realignedStationIds,
		stationFieldName
	);

	return alignedReadings;
};
