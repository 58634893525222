import React from 'react';
import { Switch, Route } from 'react-router-dom';
import { hasAccessToken } from '../../../scanline/utils/token';
import { isTokenValid } from '../../../util/user';

const LogoImage = () => {
	return (
		<img
			alt="Aegion® AIP"
			className="applogo"
			src="/Content/Images/corrpro_logo.png?ts=1714511316"
		/>
	);
};

const LogoButton = () => {
	return (
		<Switch>
			<Route path="/Account/Login">
				<a className="navbar-brand" href="/">
					<LogoImage />
				</a>
			</Route>
			{!isTokenValid && [
				<Route path="/Index">
					<a className="navbar-brand" href="/Account/Login">
						<LogoImage />
					</a>
				</Route>,
				<Route exact path="/">
					<a className="navbar-brand" href="/Account/Login">
						<LogoImage />
					</a>
				</Route>
			]}
			{isTokenValid &&
				hasAccessToken() && [
					<Route key="route_app_logo" path="/Applications">
						<a className="navbar-brand" href="/Index">
							<LogoImage />
						</a>
					</Route>,
					<Route key="route_blank_logo">
						<a className="navbar-brand" href="/Applications">
							<LogoImage />
						</a>
					</Route>
				]}
			{isTokenValid &&
				!hasAccessToken() && [
					<Route path="/Index">
						<a className="navbar-brand" href="/">
							<LogoImage />
						</a>
					</Route>,
					<Route exact path="/">
						<a className="navbar-brand" href="/">
							<LogoImage />
						</a>
					</Route>
				]}
			<Route>
				<a className="navbar-brand" href="/">
					<LogoImage />
				</a>
			</Route>
		</Switch>
	);
};

export default LogoButton;
