/* eslint-disable import/prefer-default-export */
import { createSelector } from 'reselect';

import { createMultiModifierKey } from 'aegion_common_utilities/lib/ComplexReadingStoreUtil/util/computeModifierKey';
import { ROOT_SLICE, STATUS } from './constants';
import { readDataWrapper } from './util/rwdDataWrapper';
import { selectAlignedReadingsControl } from '../../selectors/alignedReadings';
import { selectSurvey } from '../../selectors/surveys';

export const selectRootSlice = appState => appState[ROOT_SLICE];

export const selectDataWrapper = createSelector(
	appState => selectRootSlice(appState),
	(state, props) => props,
	(rootSlice, { targetKey, parentKey, modifierKey }) => {
		const { data = {} } = rootSlice;
		const dataWrapper = readDataWrapper(data, {
			targetKey,
			parentKey,
			modifierKey
		});
		return dataWrapper;
	},
	{
		memoizeOptions: {
			equalityCheck: (a, b) => {
				if (a === b) {
					return true;
				}
				const {
					modifierKey: modifierKeyA,
					parentKey: parentKeyA,
					targetKey: targetKeyA
				} = a;
				const {
					modifierKey: modifierKeyB,
					parentKey: parentKeyB,
					targetKey: targetKeyB
				} = b;
				if (modifierKeyA !== undefined && modifierKeyB !== undefined) {
					return (
						modifierKeyA === modifierKeyB &&
						parentKeyA === parentKeyB &&
						targetKeyA === targetKeyB
					);
				}
				return false;
			},
			maxSize: 10
		}
	}
);

export const selectReadings = (
	appState,
	{ targetKey, parentKey, modifierKey }
) => {
	const dataWrapper = selectDataWrapper(appState, {
		targetKey,
		parentKey,
		modifierKey
	});

	if (!dataWrapper || dataWrapper.status !== STATUS.AVAILABLE) {
		return undefined;
	}

	return dataWrapper.dataset;
};

const _computeParentKey = ({
	parentSurvey,
	alignmentFlags: { useAligned = false, useInterpolated = false } = {},
	belowCriterionFlags: { useShadow = false, useThreshold = false } = {}
}) => {
	if (
		!parentSurvey ||
		!parentSurvey.id ||
		(!useAligned && !useInterpolated && !useShadow && !useThreshold)
	) {
		return 'NONE';
	}

	return parentSurvey.id;
};

export const selectSurveyReadings = (
	appState,
	{
		targetSurvey,
		parentSurvey,
		alignmentFlags: { useAligned = false, useInterpolated = false } = {},
		belowCriterionFlags: {
			useShadow = false,
			useThreshold = false,
			threshold
		} = {},
		hasPropFlags: { prop = undefined },
		filterIsFlags = {},
		decimateFlags: { decimate = true } = {}
	}
) => {
	if (!targetSurvey) {
		return undefined;
	}

	// COMPUTE KEYS
	const targetKey = targetSurvey.id;
	const parentKey = _computeParentKey({
		parentSurvey,
		alignmentFlags: { useAligned, useInterpolated },
		belowCriterionFlags: { useShadow, useThreshold }
	});
	const modifierKey = createMultiModifierKey({
		alignmentFlags: { useAligned, useInterpolated },
		belowCriterionFlags: { useShadow, useThreshold, threshold },
		hasPropFlags: { prop },
		decimateFlags: { decimate },
		filterIsFlags
	});

	return selectReadings(appState, { targetKey, parentKey, modifierKey });
};

export const selectSurveySeries = (
	appState,
	{
		targetSurvey,
		parentSurvey,
		alignmentFlags,
		belowCriterionFlags,
		decimateFlags,
		filterIsFlags,
		useSawtooth
	}
) => {
	const control = selectAlignedReadingsControl(targetSurvey, appState) || {};
	const { renderToSurvey, filterKey } = control;
	const pSurvey = parentSurvey || selectSurvey(appState, renderToSurvey);
	const filters =
		filterIsFlags || (filterKey ? { [filterKey]: true } : undefined);
	const aFlags =
		alignmentFlags || (renderToSurvey ? { useAligned: true } : undefined);
	const { meta } = targetSurvey;
	return meta.getReadingKeys({ useSawtooth }).reduce((acc, { value }) => {
		acc[value] = selectSurveyReadings(appState, {
			targetSurvey,
			parentSurvey: pSurvey,
			alignmentFlags: aFlags,
			belowCriterionFlags,
			hasPropFlags: {
				prop: value
			},
			filterIsFlags: filters,
			decimateFlags
		});
		return acc;
	}, {});
};

export const selectHasSurveyDecimatedSeries = (
	appState,
	{
		targetSurvey,
		parentSurvey,
		alignmentFlags,
		belowCriterionFlags,
		decimateFlags: { decimate = true } = {},
		filterIsFlags = {},
		useSawtooth
	}
) => {
	const ds = selectSurveySeries(appState, {
		targetSurvey,
		parentSurvey,
		alignmentFlags,
		belowCriterionFlags,
		decimateFlags: { decimate },
		filterIsFlags,
		useSawtooth
	});

	return Object.keys(ds).filter(k => (ds[k] || []).length > 0).length > 0;
};

export const selectSurveyDataset = createSelector(
	appState => appState,
	(state, props) => props,
	(
		rootSlice,
		{
			targetSurvey,
			parentSurvey,
			alignmentFlags,
			belowCriterionFlags,
			decimateFlags
		}
	) => {
		const { meta } = targetSurvey;
		return {
			datasets: meta.getReadingKeys().map(readingKey => {
				const { value, color } = readingKey;
				return {
					...readingKey,
					attr: value,
					parsing: { yAxisKey: value },
					backgroundColor: 'rgba(53, 162, 235, 0.5)',
					borderColor: color,
					borderWidth: 2,
					pointRadius: 0,
					type: 'BrushChart',
					data: selectSurveyReadings(rootSlice, {
						targetSurvey,
						parentSurvey,
						alignmentFlags,
						belowCriterionFlags,
						hasPropFlags: {
							prop: value
						},
						decimateFlags
					})
				};
			}, [])
		};
	},
	{
		memoizeOptions: {
			equalityCheck: (a, b) => {
				if (a === b) {
					return true;
				}
				const {
					ReadingsStore: ReadingsStoreA,
					targetSurvey: targetSurveyA
				} = a;
				const {
					ReadingsStore: ReadingsStoreB,
					targetSurvey: targetSurveyB
				} = b;
				if (ReadingsStoreA !== undefined && ReadingsStoreB !== undefined) {
					return ReadingsStoreA === ReadingsStoreB;
				}
				if (targetSurveyA !== undefined && targetSurveyB !== undefined) {
					return targetSurveyA === targetSurveyB;
				}
				return false;
			},
			maxSize: 10
		}
	}
);
