import { selectActionPlanMessageSlice } from './selectors';
import { setActionPlanMessage, setNotificationExpanded } from './actions';
import { ROOT_SLICE } from './constants';

// ************
// PARTS
// ************
const reducerParts = {
	[setActionPlanMessage]: (state, { payload: { message } }) => {
		const actionPlanMessageSlice = selectActionPlanMessageSlice(state);

		return {
			...state,
			[ROOT_SLICE]: {
				...actionPlanMessageSlice,
				message
			}
		};
	},
	[setNotificationExpanded]: (state, { payload: { expanded } }) => {
		const actionPlanMessageSlice = selectActionPlanMessageSlice(state);

		return {
			...state,
			[ROOT_SLICE]: {
				...actionPlanMessageSlice,
				isNotificationExpanded: expanded
			}
		};
	}
};

export default reducerParts;
