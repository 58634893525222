const EMPTY_OBJECT = {};
const EMPTY_ARRAY = [];

export const buildSelectors = (mainRedux, filterStatePropKey = 'customers') => {
	const { selectFiltersState } = mainRedux.selectors;

	const selectCustomerFilterState = sliceState => {
		const filtersState = selectFiltersState(sliceState);
		const customers = filtersState[filterStatePropKey] || EMPTY_OBJECT;
		return customers;
	};

	const selectCustomerList = sliceState => {
		const state = selectCustomerFilterState(sliceState);
		const { list = EMPTY_ARRAY } = state;
		return list;
	};
	const selectCustomerListCount = sliceState => {
		const state = selectCustomerFilterState(sliceState);
		const { listCount = 0 } = state;
		return listCount;
	};

	const selectCustomerSelectedList = sliceState => {
		const state = selectCustomerFilterState(sliceState);
		const { selected = EMPTY_ARRAY } = state;
		return selected;
	};

	const selectCustomerSelectedListCount = sliceState => {
		const state = selectCustomerFilterState(sliceState);
		const { selectedCount = 0 } = state;
		return selectedCount;
	};

	const selectCustomerIsLoading = sliceState => {
		const state = selectCustomerFilterState(sliceState);
		const { isLoading = false } = state;
		return isLoading;
	};

	const selectCustomerIsLoaded = sliceState => {
		const state = selectCustomerFilterState(sliceState);
		const { isLoaded = false } = state;
		return isLoaded;
	};

	const selectCustomerLoadErrorMessage = sliceState => {
		const state = selectCustomerFilterState(sliceState);
		const { loadErrorMessage } = state;
		return loadErrorMessage;
	};

	return {
		selectCustomerFilterState,
		selectCustomerList,
		selectCustomerListCount,
		selectCustomerSelectedList,
		selectCustomerSelectedListCount,
		selectCustomerIsLoading,
		selectCustomerIsLoaded,
		selectCustomerLoadErrorMessage
	};
};

export default {
	buildSelectors
};
