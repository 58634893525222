import { createActions } from 'redux-actions';

const {
	setAllComponentsLocked,
	setComponentLockAlertVisibility
} = createActions({
	SET_ALL_COMPONENTS_LOCKED: locked => ({ locked }),
	SET_COMPONENT_LOCK_ALERT_VISIBILITY: show => ({ show })
});

export { setAllComponentsLocked, setComponentLockAlertVisibility };
