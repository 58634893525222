/* eslint-disable import/prefer-default-export */
import {
	ERROR_DICTIONARY,
	ERROR_KEY_UNKNOWN_ERROR,
	PROCESSING_MESSAGE_DICTIONARY
} from './constants';

export const getErrorMessage = errorKey =>
	ERROR_DICTIONARY[errorKey] || ERROR_DICTIONARY[ERROR_KEY_UNKNOWN_ERROR];

export const getProcessingMessage = messageKey =>
	PROCESSING_MESSAGE_DICTIONARY[messageKey] || undefined;
