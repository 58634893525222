/* eslint-disable react/button-has-type */
import React, { useMemo } from 'react';
import { connect } from 'react-redux';

import { selectSelectedAppKey } from '../../../../../../../scanline/redux/WebsiteExperimental/selectors';
import { detailsMap } from '../../../../content';

import './Original.scss';

const VARIANT = 'original';

const mapStateToProps = state => {
	const { websiteExperimental } = state;
	return {
		appKey: selectSelectedAppKey(websiteExperimental)
	};
};

const Original = ({ appKey }) => {
	const obj = useMemo(() => detailsMap[appKey], [appKey]);
	return (
		<div className={`details-${VARIANT}-wrapper`}>
			<h1 className="title">{obj.title}</h1>
			<hr />
			<p className="body">{obj.details}</p>
			<div className="actions">
				<button>Schedule Free Trial</button>
			</div>
		</div>
	);
};

export default connect(mapStateToProps)(Original);
