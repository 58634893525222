/* eslint-disable import/no-cycle */
import {
	SET_MISSING_FILES,
	SET_BUCKET,
	DESTROY,
	SET_S3
} from '../actions/uploader';

const defaultState = {
	bucket: undefined,
	missingFiles: undefined,
	s3: undefined,
	updating: true
};

const reducer = (state = defaultState, action) => {
	const { type, payload } = action;
	switch (type) {
		case SET_MISSING_FILES:
			return { ...state, missingFiles: payload, updating: false };
		case SET_BUCKET:
			return { ...state, bucket: payload };
		case SET_S3:
			return { ...state, s3: payload };
		case DESTROY:
			return { ...defaultState };
		default:
			return state;
	}
};

export default reducer;
