export const BATCH_REQUEST_SIZE = 100;
export const BATCH_REQUEST_LIMIT = 20;
export const BATCH_REQUEST_EVENT_TYPES = {
	TOTAL_COUNT: 'TOTAL COUNT',
	LOADED_COUNT: 'LOADED COUNT',
	LOADING_COUNT: 'LOADING COUNT',
	COMPLETED: 'COMPLETED'
};

export const LOAD_ERROR_MESSAGE =
	'An error occured when trying to load scanline reports.';

export default {
	BATCH_REQUEST_SIZE,
	BATCH_REQUEST_LIMIT,
	LOAD_ERROR_MESSAGE,
	BATCH_REQUEST_EVENT_TYPES
};
