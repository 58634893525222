import {
	CognitoIdToken,
	CognitoUserSession,
	CognitoAccessToken,
	CognitoRefreshToken,
	CognitoUserPool,
	CognitoUser,
	AuthenticationDetails
} from 'amazon-cognito-identity-js';

import {
	getAccessToken,
	getRefreshToken,
	getIdToken
} from '../../scanline/utils/token';

const getCognitoIdToken = () => {
	return new CognitoIdToken({ IdToken: getIdToken() });
};
const getCognitoAccessToken = () => {
	return new CognitoAccessToken({ AccessToken: getAccessToken() });
};
const getCognitoRefreshToken = () => {
	return new CognitoRefreshToken({ AccessToken: getRefreshToken() });
};

export const getUserSession = () => {
	const userSession = new CognitoUserSession({
		IdToken: getCognitoIdToken(),
		AccessToken: getCognitoAccessToken(),
		RefreshToken: getCognitoRefreshToken()
	});
	return userSession;
};

export const isTokenValid = () => {
	const userSession = getUserSession();
	return userSession.isValid();
};

export const isTokenExpired = () => {
	const userSession = getUserSession();
	const expireDate = new Date(userSession.accessToken.getExpiration() * 1000);
	return new Date() > expireDate;
};

export const getLoggedInUser = () => {
	const { payload: userData = {} } = getCognitoIdToken();
	const {
		'cognito:username': username = '',
		'cognito:groups': arrCognitoGroups = [],
		email = '',
		given_name: firstName = '',
		family_name: lastName = '',
		sub: uuid = '',
		customer
	} = userData;

	return {
		uuid,
		customer,
		username,
		email,
		firstName,
		lastName,
		fullName: `${firstName} ${lastName}`.trim(),
		cognitoGroups: arrCognitoGroups.reduce((acc, key) => {
			acc[key] = true;
			return acc;
		}, {})
	};
};

export const getUserName = () => {
	const { username } = getLoggedInUser();
	return username;
};

export const getUserUUID = () => {
	const { uuid } = getLoggedInUser();
	return uuid;
};

export const getEmail = () => {
	const { email } = getLoggedInUser();
	return email;
};

export const getCustomer = () => {
	const { customer } = getLoggedInUser();
	return customer;
};

export const getFullName = () => {
	const { fullName } = getLoggedInUser();
	return fullName;
};

export const hasCognitoGroupName = groupName => {
	const { cognitoGroups = {} } = getLoggedInUser();
	return cognitoGroups[groupName];
};

const getSettingJson = settingString => {
	if (!settingString) {
		return {};
	}

	if (typeof settingString !== 'string') {
		return settingString;
	}

	try {
		return JSON.parse(settingString);
	} catch (e) {
		if (e instanceof SyntaxError) {
			return {};
		}

		throw e;
	}
};

export const getAppSettings = app => {
	const idToken = getCognitoIdToken();

	const appSettingsRaw = idToken.payload[app];
	return getSettingJson(appSettingsRaw);
};

export const isUserInGroup = groupName => {
	const idToken = getCognitoIdToken();
	const { payload: { 'cognito:groups': groups = [] } = {} } = idToken;
	return groups.indexOf(groupName) !== -1;
};

export const getUserSettings = app => {
	const idToken = getCognitoIdToken();
	const {
		payload: { [app]: setting, userSettings: strUserSettings = '{}' } = {}
	} = idToken;
	if (setting) {
		return setting;
	}
	const userSettings = getSettingJson(strUserSettings);
	const { [app]: userAppSettings = {} } = userSettings;
	return userAppSettings;
};

const getUserPool = poolData => {
	return new CognitoUserPool(poolData);
};

const getCognitoUser = (username, poolData) => {
	const userData = {
		Username: username,
		Pool: getUserPool(poolData)
	};
	return new CognitoUser(userData);
};

export const login = (authenticationData, poolData) => {
	return new Promise((resolve, reject) => {
		const { Username: username } = authenticationData;
		const cognitoUser = getCognitoUser(username, poolData);
		const authenticationDetails = new AuthenticationDetails(authenticationData);
		cognitoUser.authenticateUser(authenticationDetails, {
			onSuccess: result => {
				resolve(result);
			},
			onFailure: error => {
				reject(error);
			}
		});
	});
};
