/* eslint-disable import/prefer-default-export */
export const insertReadings = (
	dataStore = {},
	{ targetKey, parentKey = 'NONE', modifierKey = 'NONE', data = {} }
) => {
	const targetStore = dataStore[targetKey] || {};
	const parentStore = targetStore[parentKey] || {};
	const modifierStore = parentStore[modifierKey] || {};

	return {
		...dataStore,
		[targetKey]: {
			...targetStore,
			[parentKey]: {
				...parentStore,
				[modifierKey]: {
					...modifierStore,
					...data
				}
			}
		}
	};
};

export const deleteReadings = (
	dataStore = {},
	{ targetKey, parentKey = 'NONE', modifierKey = 'NONE' }
) => {
	const targetStore = dataStore[targetKey] || {};
	const parentStore = targetStore[parentKey] || {};
	const modifierStore = parentStore[modifierKey] || {};

	const clone = {
		...dataStore,
		[targetKey]: {
			...targetStore,
			[parentKey]: {
				...parentStore,
				[modifierKey]: {
					...modifierStore
				}
			}
		}
	};

	delete clone[targetKey][parentKey][modifierKey];

	if (Object.keys(clone[targetKey][parentKey]).length === 0) {
		delete clone[targetKey][parentKey];

		if (Object.keys(clone[targetKey]).length === 0) {
			delete clone[targetKey];
		}
	}

	return clone;
};

export const flattenS3KeyStore = s3Store => {
	const targetKeys = Object.keys(s3Store || {}).filter(v => !!v);

	targetKeys.reduce((acc, tk) => {
		const targetStore = s3Store[tk] || {};
		const parentKeys = Object.keys(targetStore).filter(v => !!v);

		parentKeys.forEach(pk => {
			const parentStore = targetStore[pk] || {};
			const modifierKeys = Object.keys(parentStore).filter(v => !!v);

			modifierKeys.forEach(mk => {
				const modifierStore = targetStore[mk] || {};
				const s3Props = Object.keys(modifierStore);

				if (s3Props && s3Props.length) {
					const { s3Bucket, s3Key, s3KeyVersion } = modifierStore;

					acc.push({
						targetKey: tk,
						parentKey: pk,
						modfierKey: mk,
						s3Bucket,
						s3Key,
						s3KeyVersion
					});
				}
			});
		});

		return acc;
	}, []);
};
