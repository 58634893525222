import { setTimeOnPageMeta } from './actions';

const reducerParts = {
	[setTimeOnPageMeta]: (state, { payload: { obj: newState } }) => {
		return {
			...newState
		};
	}
};

export default reducerParts;
