/* eslint-disable import/prefer-default-export */
/* eslint-disable import/no-cycle */
import { createActions } from 'redux-actions';

const { setIsSyncEnabled } = createActions(
	{
		SET_IS_SYNC_ENABLED: enable => ({ enable })
	},
	{ prefix: 'ActionPlanSync' }
);

export { setIsSyncEnabled };
