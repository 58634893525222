/* eslint-disable import/no-cycle */
// eslint-disable-next-line import/no-cycle
import {
	getSelectedDatFileIndex,
	getSelectedDatFile,
	getChartDataFromDatFile,
	getNextChartData,
	getPreviousChartData,
	determineIfAutoCorrectionIsInProcess,
	getNewDataOrDefault,
	changeVoltage
} from '../../components/TabPages/MagicSpikeEditor/utils/spike-editor';
import AutoCorrectionUtil from '../../utils/AutoCorrectionUtil';
import {
	magicSpikeEditorActions,
	magicSpikeEditorSpikeChartActions,
	spikeEditorActions
} from './index';
import { FILE_TYPE, EDIT_TYPE } from './util/spike-editor-config';

import { getFileType } from './util/spike-editor';

function getDefaultReadingsEditType(fileType) {
	if (fileType === FILE_TYPE.ON_OFF_GPS_SYNC) {
		return EDIT_TYPE.ON;
	}
	return undefined;
}

export const setSelectedDatFile = (
	selectedDatFile,
	index,
	oldData,
	preProcessedGPSReadings,
	newData,
	previousChartData,
	nextChartData,
	autoCorrectionInProcess,
	previousDatFile,
	nextDatFile,
	fileType,
	readingsEditType
) => dispatch => {
	dispatch(
		magicSpikeEditorActions.setSelectedDatFile(
			index,
			oldData,
			preProcessedGPSReadings,
			newData,
			previousChartData,
			nextChartData,
			fileType,
			readingsEditType
		)
	);
	dispatch(
		magicSpikeEditorSpikeChartActions.setSelectedDatFile(
			selectedDatFile,
			index,
			oldData,
			preProcessedGPSReadings,
			newData,
			previousChartData,
			nextChartData,
			autoCorrectionInProcess,
			previousDatFile,
			nextDatFile
		)
	);
};

export const setDefaultStateFromProps = props => dispatch => {
	dispatch(magicSpikeEditorActions.setDefaultStateFromProps(props));

	dispatch(
		magicSpikeEditorSpikeChartActions.updateDefaultStateFromProps(props)
	);
};

export const receiveNewChartData = (
	oldData,
	preProcessedGPSReadings,
	newData,
	previousChartData,
	nextChartData,
	autoCorrectionInProcess,
	selectedDatFile,
	selectedDatFileIndex,
	useMv,
	previousDatFile,
	nextDatFile
) => dispatch => {
	dispatch(
		magicSpikeEditorActions.receiveNewChartData(
			oldData,
			preProcessedGPSReadings,
			newData,
			previousChartData,
			nextChartData,
			autoCorrectionInProcess
		)
	);

	dispatch(
		magicSpikeEditorSpikeChartActions.updateDefaultStateFromProps({
			oldData,
			preProcessedGPSReadings,
			newData,
			previousChartData,
			nextChartData,
			autoCorrectionInProcess,
			selectedDatFile,
			selectedDatFileIndex,
			useMv,
			previousDatFile,
			nextDatFile
		})
	);
};

export const setSelectedDatFileName = datFileName => (dispatch, getState) => {
	const {
		cisview: {
			job: { data: datFiles, globalData },
			readingsAutoCorrection: {
				autoCorrectedReadingsDataByFile,
				readingsLedgersByFile
			},
			spikeEditor: { useMv, selectedDatFile: selectedDatFileIndx },
			readings: { unsortedConvertedReadingsByFile = [] }
		}
	} = getState();

	const prevSelectedDatFile = getSelectedDatFile(datFiles, selectedDatFileIndx);

	const index = getSelectedDatFileIndex(datFiles, datFileName);
	const selectedDatFile = getSelectedDatFile(datFiles, index);

	const unsortedConvertedReadingsForSelectedFile =
		unsortedConvertedReadingsByFile[index];

	const {
		oldData,
		preProcessedGPSReadings,
		newData /* ,
    defaultMv */
	} = getChartDataFromDatFile(
		selectedDatFile,
		unsortedConvertedReadingsForSelectedFile,
		autoCorrectedReadingsDataByFile,
		useMv
	);

	const { nextDatFile, nextChartData } = getNextChartData(
		datFiles,
		index,
		useMv
	);
	const { previousDatFile, previousChartData } = getPreviousChartData(
		datFiles,
		index,
		useMv
	);

	const isAutoCorrectionInProcess = determineIfAutoCorrectionIsInProcess(
		selectedDatFile,
		readingsLedgersByFile
	);

	AutoCorrectionUtil.analyticsTrackReadings(
		'analytics-set-selected-dat-file-readings-auto-correction',
		{ datFile: selectedDatFile, globalData },
		{
			previousDatFile: (prevSelectedDatFile || {}).fileName,
			nextDatFile: (selectedDatFile || {}).fileName
		}
	);
	const fileType = getFileType(selectedDatFile);
	const readingsEditType = getDefaultReadingsEditType(fileType);

	dispatch(
		setSelectedDatFile(
			selectedDatFile,
			index,
			oldData,
			preProcessedGPSReadings,
			getNewDataOrDefault(newData, oldData),
			previousChartData,
			nextChartData,
			isAutoCorrectionInProcess,
			previousDatFile,
			nextDatFile,
			fileType,
			readingsEditType
		)
	);
};

export const mvToggleChanged = () => (dispatch, getState) => {
	const {
		cisview: {
			spikeEditor: {
				useMv,
				oldData,
				preProcessedGPSReadings,
				newData,
				previousChartData,
				nextChartData
			},
			readings: { unsortedConvertedReadingsByFile = [] }
		}
	} = getState();

	// To change Y axis readings in data according to volts or millivolts
	dispatch(
		magicSpikeEditorActions.onMvChanged(
			oldData,
			preProcessedGPSReadings,
			getNewDataOrDefault(newData, oldData),
			previousChartData,
			nextChartData,
			unsortedConvertedReadingsByFile,
			!useMv
		)
	);
};

export const init = () => (dispatch, getState) => {
	const {
		cisview: {
			job: { data: datFiles },
			readingsAutoCorrection: { autoCorrectedReadingsDataByFile },
			spikeEditor: { useMv = true, selectedDatFile: selectedDatFileIndex },
			readings: { unsortedConvertedReadingsByFile = [] }
		}
	} = getState();

	const selectedDatFile = getSelectedDatFile(datFiles, selectedDatFileIndex);

	const unsortedConvertedReadingsForSelectedFile =
		unsortedConvertedReadingsByFile[selectedDatFileIndex];

	const {
		oldData,
		preProcessedGPSReadings,
		newData,
		defaultMv
	} = getChartDataFromDatFile(
		selectedDatFile,
		unsortedConvertedReadingsForSelectedFile,
		autoCorrectedReadingsDataByFile,
		useMv
	);

	const { nextDatFile, nextChartData } = getNextChartData(
		datFiles,
		selectedDatFileIndex,
		useMv
	);
	const { previousDatFile, previousChartData } = getPreviousChartData(
		datFiles,
		selectedDatFileIndex,
		useMv
	);

	const fileType = getFileType(selectedDatFile);
	const readingsEditType = getDefaultReadingsEditType(fileType);

	dispatch(
		setDefaultStateFromProps({
			selectedDatFile,
			selectedDatFileIndex,
			oldData,
			preProcessedGPSReadings,
			newData: getNewDataOrDefault(newData, oldData), // We always want to show newData now so that the user can interpolate points
			useMv: defaultMv,
			defaultMv,
			previousChartData,
			nextChartData,
			previousDatFile,
			nextDatFile,
			fileType,
			readingsEditType
		})
	);
};

export const update = () => (dispatch, getState) => {
	const {
		cisview: {
			job: { data: datFiles },
			readingsAutoCorrection: {
				autoCorrectedReadingsDataByFile,
				readingsLedgersByFile
			},

			spikeEditor: { useMv = true, selectedDatFile: selectedDatFileIndex },
			readings: { unsortedConvertedReadingsByFile = [] }
		}
	} = getState();

	const selectedDatFile = getSelectedDatFile(datFiles, selectedDatFileIndex);
	const unsortedConvertedReadingsForSelectedFile =
		unsortedConvertedReadingsByFile[selectedDatFileIndex];

	const isAutoCorrectionInProcess = determineIfAutoCorrectionIsInProcess(
		selectedDatFile,
		readingsLedgersByFile
	);

	const {
		oldData,
		preProcessedGPSReadings,
		newData /* ,
    defaultMv */
	} = getChartDataFromDatFile(
		selectedDatFile,
		unsortedConvertedReadingsForSelectedFile,
		autoCorrectedReadingsDataByFile,
		useMv
	);

	changeVoltage(newData, useMv);

	const { nextDatFile, nextChartData } = getNextChartData(
		datFiles,
		selectedDatFileIndex,
		useMv
	);
	const { previousDatFile, previousChartData } = getPreviousChartData(
		datFiles,
		selectedDatFileIndex,
		useMv
	);

	dispatch(
		spikeEditorActions.receiveNewChartData(
			oldData,
			preProcessedGPSReadings,
			getNewDataOrDefault(newData, oldData),
			previousChartData,
			nextChartData,
			isAutoCorrectionInProcess,
			selectedDatFile,
			selectedDatFileIndex,
			useMv,
			previousDatFile,
			nextDatFile
		)
	);
};

export const setReadingsEditType = () => (dispatch, getState) => {
	const {
		cisview: {
			spikeEditor: { readingsEditType }
		}
	} = getState();
	if (readingsEditType === EDIT_TYPE.ON) {
		return dispatch({
			type: 'CISV_SPIKE_EDITOR_SET_READINGS_EDIT_TYPE',
			payload: EDIT_TYPE.OFF
		});
	}
	return dispatch({
		type: 'CISV_SPIKE_EDITOR_SET_READINGS_EDIT_TYPE',
		payload: EDIT_TYPE.ON
	});
};
