import { createBrowserHistory } from 'history';

import { matchPath } from 'react-router-dom';
import { path } from './routepath';

export default class HistoryListener {
	constructor() {
		this.history = createBrowserHistory({ forceRefresh: false });
	}

	listen() {
		return new Promise(resolve => {
			this.unlisten = this.history.listen(location => {
				const match = matchPath(location.pathname, {
					path,
					exact: true,
					strict: false
				});
				if (match && match.params) {
					resolve(match.params);
				}
			});
		});
	}
}
