export const renderAsText = val => {
	if (typeof val === 'string') {
		return val;
	}

	try {
		return JSON.stringify(val, undefined, 1);
	} catch (err) {
		return val;
	}
};

export const forceError = () => Promise.reject(new Error('forced error'));

export const asyncTimeout = async ms =>
	new Promise(resolve => {
		setTimeout(() => resolve(true), Math.max(1, ms));
	});
