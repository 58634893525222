import { OPEN, CLOSE } from './actionTypes';

export const initialState = {
	isBlurModalOpen: false
};

const project = (state = initialState, action) => {
	const { type } = action;

	switch (type) {
		case OPEN: {
			return {
				...state,
				isOpen: true
			};
		}

		case CLOSE: {
			return {
				...state,
				isOpen: false
			};
		}

		default:
			return state;
	}
};

export default project;
