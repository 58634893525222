/* eslint-disable import/prefer-default-export */
/* eslint-disable import/no-cycle */
import { createActions } from 'redux-actions';
import { ROOT_SLICE } from '../constants';

const { setDevFeaturesEnabled } = createActions(
	{
		SET_DEV_FEATURES_ENABLED: enable => ({ enable })
	},
	{ prefix: ROOT_SLICE }
);

export { setDevFeaturesEnabled };
