/* eslint-disable import/prefer-default-export */

import { SET_USER_APP_SETTINGS } from './actionTypes';

export const setUserAppSettings = userAppSettings => dispatch => {
	dispatch({
		type: SET_USER_APP_SETTINGS,
		payload: userAppSettings
	});
};
