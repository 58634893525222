// allows us to pass results from one reducer to the next
// essentially combining multiple reducers into a single flat reducer
export const chainReducers = reducers => (state, action) =>
	reducers.reduce((newState, reducer) => reducer(newState, action), state);

// helper for generating action creators
export const createPayloadMetaAction = type => (payload, meta) => ({
	type,
	payload,
	meta
});

// helper for parsing http responses.
export const parseHttpResponse = res => {
	// if (res.status < 200 || res.status >= 300) {
	//     throw new Error('some http error occured');
	// }

	return res && res.json ? res.json() : JSON.parse(res);
};

// A helper function to abstract POST param boiler plate
// You should manually build your POST params if you need more options
export const createHttpPostParams = data => ({
	method: 'POST',
	headers: { 'Content-Type': 'application/json' },
	body: JSON.stringify(data)
});

// A helper function to abstract PUT param boiler plate
// You should manually build your PUT params if you need more options
export const createHttpPutParams = data => ({
	method: 'POST',
	headers: { 'Content-Type': 'application/json' },
	body: JSON.stringify(data)
});
