import Analytics from 'analytics';
import segmentPlugin from '@analytics/segment';

import { MiscUtils } from 'aegion_common_utilities/';

const segmentKeyOther = '7Pi9JAv85otv0L3gej3YODxl8Mv2Gl44';
const segmentKeyProd = 'nTPuCaWkrknCbBokOEvQ1KYk1jcTsmAd';

function getSegmentKey() {
	if (MiscUtils.isProd()) {
		return segmentKeyProd;
	}
	return segmentKeyOther;
}

const analytics = Analytics({
	app: 'aip',
	plugins: [
		segmentPlugin({
			writeKey: getSegmentKey()
		})
	]
});

export const page = () => {
	analytics.page();
};

export const track = (name, payload) => {
	analytics.track(name, payload);
};

export const identify = (uuid, payload) => {
	analytics.identify(uuid, payload);
};
