export const NAME_SPACE = 'ASSET_VIEW';

export const DETAILS_MODE = {
	panels: 'panels',
	fullwindow: 'fullwindow'
};

export const ASSET_PANELS = {
	details: 'Asset Details',
	history: 'Asset History',
	group: 'Asset Group'
};

export const FILTER_ORDER = {
	numericAsc: 'numericAsc',
	numericDesc: 'numericDesc',
	alphaAsc: 'alphaAsc',
	alphaDesc: 'alphaDesc'
};

export const APPROVAL_MODE = {
	unapproved: 'u',
	approved: 'a',
	mixed: 'm'
};

export const MANAGER_LIST_PAGE = 200;
export const MANAGER_LIST_PAGING = 75;

export const DOWNLOADING_TEXT = 'Downloading...';
