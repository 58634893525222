import { cloneReadings } from './utils/reading';

import expandReadings from '../commons/util/alignReadings/expandReadings';
import shrinkReadings from '../commons/util/alignReadings/shrinkReadings';
import mapStationId from '../commons/util/alignReadings/mapStationId';
import alignReadingsByComments from '../commons/util/alignReadings/alignReadingsByComments';

const _alignReadingsByComments = (
	cisReadings,
	cisReadingsMap,
	targetReadings,
	subtype,
	keys,
	shouldCopyOriginalDepol
) => {
	return alignReadingsByComments(
		cisReadings,
		cisReadingsMap,
		targetReadings,
		subtype,
		keys,
		shouldCopyOriginalDepol
	);
};

/** ***********
 * MAIN
 */
const alignReadings = (
	cisReadings,
	cisReadingsMap,
	paramTargetReadings,
	subtype,
	{ depolKey = 'depol', depolFlagKey, targetValueKey = 'on' } = {},
	shouldCopyOriginalDepol
) => {
	const targetReadings = cloneReadings(paramTargetReadings);

	const keys = {
		depolKey,
		depolFlagKey,
		targetValueKey
	};

	// add specialized alignment logic for depol
	if (['DEPOL'].includes(subtype)) {
		return _alignReadingsByComments(
			cisReadings,
			cisReadingsMap,
			targetReadings,
			subtype,
			keys,
			shouldCopyOriginalDepol
		);
	}

	// else normalize by full length
	if (cisReadings.length > targetReadings.length) {
		return expandReadings(
			cisReadings,
			cisReadingsMap,
			targetReadings,
			subtype,
			keys,
			shouldCopyOriginalDepol
		);
	}
	if (cisReadings.length < targetReadings.length) {
		return shrinkReadings(
			cisReadings,
			cisReadingsMap,
			targetReadings,
			subtype,
			keys,
			shouldCopyOriginalDepol
		);
	}
	return mapStationId(
		cisReadings,
		cisReadingsMap,
		targetReadings,
		subtype,
		keys,
		shouldCopyOriginalDepol
	);
};

export default alignReadings;
