/* eslint-disable import/prefer-default-export */
import { STATUS } from '../constants';

export const initializeDataWrapper = (
	dataset,
	status = STATUS.WRAPPER_INITIALIZED
) => ({
	dataset,
	status
});

export const cloneDataWrapper = wrapper => {
	if (!wrapper) {
		return wrapper;
	}
	return { ...wrapper };
};
