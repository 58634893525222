import { selectActionPlanSyncSlice } from './selectors';
import { setIsSyncEnabled } from './actions';
import { ROOT_SLICE } from './constants';

// ************
// PARTS
// ************
const reducerParts = {
	[setIsSyncEnabled]: (state, { payload: { enable } }) => {
		const actionPlanSlice = selectActionPlanSyncSlice(state);

		return {
			...state,
			[ROOT_SLICE]: {
				...actionPlanSlice,
				isSyncEnabled: enable
			}
		};
	}
};

export default reducerParts;
