/* eslint-disable import/no-cycle */
import { createActions } from 'redux-actions';
import { fetchCustomers } from './api';
import { createGetStateFromUrl, createPushStateToUrl } from '../util';
import { isEquivalent } from './util';

export const buildActions = (
	namespace,
	rootSliceKey,
	mainRedux,
	mySelectors,
	filtersStatePropKey,
	filterStatePropKey = 'customers'
) => {
	const { setIsLoadingFilters, setIsLoadedFilters } = mainRedux.actions;
	const { selectSliceState, selectIsAnyFilterLoading } = mainRedux.selectors;
	const { selectCustomerSelectedList } = mySelectors;

	const getStateFromUrl = createGetStateFromUrl(
		namespace,
		rootSliceKey,
		filtersStatePropKey,
		filterStatePropKey
	);

	const pushStateToUrl = createPushStateToUrl(
		namespace,
		rootSliceKey,
		filtersStatePropKey,
		filterStatePropKey
	);

	const {
		reInitStateCustomers,
		requestCustomers,
		receiveCustomers,
		clearSelectedCustomers,
		setSelectedCustomers,
		addSelectedCustomer,
		removeSelectedCustomer,
		setLoadCustomersErrorMessage,
		clearLoadCustomersErrorMessage
	} = createActions(
		{
			RE_INIT_STATE_CUSTOMERS: () => ({}),
			REQUEST_CUSTOMERS: () => ({}),
			RECEIVE_CUSTOMERS: list => ({ list }),
			CLEAR_SELECTED_CUSTOMERS: () => ({}),
			SET_SELECTED_CUSTOMERS: (selectedList = []) => ({ selectedList }),
			ADD_SELECTED_CUSTOMER: item => ({ item }),
			REMOVE_SELECTED_CUSTOMER: item => ({ item }),
			SET_LOAD_CUSTOMERS_ERROR_MESSAGE: message => ({ message }),
			CLEAR_LOAD_CUSTOMERS_ERROR_MESSAGE: () => ({})
		},
		{ prefix: namespace }
	);

	const getSelectedFromUrl = getState => {
		const sliceState = selectSliceState(getState());
		const list = mySelectors.selectCustomerList(sliceState);
		const urlSelected = (getStateFromUrl() || {}).selected || [];
		const selectedList = urlSelected
			.map(i => list.find(i2 => isEquivalent(i, i2)))
			.filter(v => !!v);
		return selectedList;
	};

	const thunkLoadCustomers = () => (dispatch, getState) => {
		dispatch(reInitStateCustomers());
		dispatch(requestCustomers());
		dispatch(setIsLoadingFilters(true));

		fetchCustomers((err, list) => {
			dispatch(receiveCustomers(list));
			const selectedList = getSelectedFromUrl(getState);
			dispatch(setSelectedCustomers(selectedList));
			const sliceState = selectSliceState(getState());
			if (!selectIsAnyFilterLoading(sliceState)) {
				dispatch(setIsLoadingFilters(false));
				dispatch(setIsLoadedFilters(true));
			}
		});
	};

	const _toggleCustomer = item => (dispatch, getState) => {
		const sliceState = selectSliceState(getState());
		const selected = selectCustomerSelectedList(sliceState);
		const isSelected = selected.indexOf(item) > -1;
		if (isSelected) {
			dispatch(removeSelectedCustomer(item));
		} else {
			dispatch(addSelectedCustomer(item));
		}
	};

	const createWithUrlSideAffect = fnAction => (...args) => (
		dispatch,
		getState
	) => {
		dispatch(fnAction(...args));
		const sliceState = selectSliceState(getState());
		const filterState = mySelectors.selectCustomerFilterState(sliceState);
		pushStateToUrl(filterState);
	};

	const thunkClearSelectedCustomers = createWithUrlSideAffect(
		clearSelectedCustomers
	);
	const thunkSetSelectedCustomers = createWithUrlSideAffect(
		setSelectedCustomers
	);
	const thunkAddSelectedCustomer = createWithUrlSideAffect(addSelectedCustomer);
	const thunkRemoveSelectedCustomer = createWithUrlSideAffect(
		removeSelectedCustomer
	);
	const toggleCustomer = createWithUrlSideAffect(_toggleCustomer);
	return {
		reInitStateCustomers,
		requestCustomers,
		receiveCustomers,
		clearSelectedCustomers,
		setSelectedCustomers,
		addSelectedCustomer,
		removeSelectedCustomer,
		setLoadCustomersErrorMessage,
		clearLoadCustomersErrorMessage,
		toggleCustomer,
		thunkLoadCustomers,
		thunkClearSelectedCustomers,
		thunkSetSelectedCustomers,
		thunkAddSelectedCustomer,
		thunkRemoveSelectedCustomer
	};
};

export default {
	buildActions
};
