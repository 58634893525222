/* eslint-disable import/no-cycle */
import { handleActions } from 'redux-actions';

// MAIN
import itemsReducerParts from './CompletedSurveys/reducerParts';
import { initialState as itemsInitialState } from './CompletedSurveys/initialState';

// JOBLINE FILTERS
import itemFiltersReducerParts from './CompleteSurveysFilters/reducerParts';
import itemFiltersInitialState from './CompleteSurveysFilters/initialState';

const initialState = {
	...itemsInitialState,
	...itemFiltersInitialState
};

const reducerParts = {
	...itemsReducerParts,
	...itemFiltersReducerParts
};

export default handleActions(reducerParts, initialState);
