import {compareReading} from './util/readings'
const NS_MAPS = 'CISVIEW_TESTSTATIONS_';
 
function matchTestStationFromComment(comment) {
	const match =
		/TEST/gi.exec(comment) ||
		/FG\//g.exec(comment) ||
		/NG\//g.exec(comment) ||
		/CASING VENT /gi.exec(comment) ||
		/CASING VENT /gi.exec(comment) ||
		/IR\//g.exec(comment) ||
		/bad/gi.exec(comment) ||
		/no good/gi.exec(comment) ||
		/ TS/gi.exec(comment) ||
		/ TP/gi.exec(comment) ||
		/ FLC/gi.exec(comment) ||
		/ FLX/gi.exec(comment) ||
		/ PS/gi.exec(comment) ||
		/ VALVE/gi.exec(comment) ||
		/ RISER/gi.exec(comment) ||
		/ xing/gi.exec(comment) ||
		/ crossing/gi.exec(comment);
		return match && match.length && match[0];
}
export const SET_TEST_STATIONS = `${NS_MAPS}SET_TEST_STATIONS`;
export const setDefaultTestStations = () =>(dispatch, getState) =>{
	const state = getState();
	const { cisview } = state;
	const { readings } = cisview;
	const { convertedReadings } = readings;
	const testStations = convertedReadings;	
	dispatch({type : SET_TEST_STATIONS ,payload: testStations.filter((ts)=> matchTestStationFromComment(ts.comments))});
};
export const addTestStation = (reading) => (dispatch, getState) =>{
	const state = getState();
	const { cisview } = state;
	const { testStations } = cisview;
	const selected = [...testStations.selected,reading]
	dispatch({type : SET_TEST_STATIONS ,payload: selected});
}
export const removeTestStation = (reading) => (dispatch, getState) =>{
	const state = getState();
	const { cisview } = state;
	const { testStations } = cisview;
	const selected = [...testStations.selected]
	selected.splice(selected.findIndex((ts)=> compareReading(ts,reading)),1)
	dispatch({type : SET_TEST_STATIONS ,payload: selected});
}