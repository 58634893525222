/* eslint-disable import/no-cycle */
import { createActions } from 'redux-actions';

import { selectActionPlanChangeNotesState } from './selectors';
import { isEmptyObject } from './util';

const { setNotes, setShowModal, setSideEffects } = createActions(
	{
		// NOTES
		SET_NOTES: (chartId, notes) => {
			return { chartId, notes };
		},
		SET_SHOW_MODAL: (chartId, show) => {
			return { chartId, show };
		},
		SET_SIDE_EFFECTS: (chartId, onCancelChangeNotes, onSaveChangeNotes) => {
			return { chartId, onCancelChangeNotes, onSaveChangeNotes };
		}
	},
	{
		prefix: 'ActionPlanChangeNotesV2'
	}
);

const thunkInitState = chartId => (dispatch, getState) => {
	const { app } = getState();
	const state = selectActionPlanChangeNotesState(app, { chartId });
	if (isEmptyObject(state)) {
		dispatch(setNotes(chartId));
	}
};

const thunkClearNotes = chartId => dispatch => dispatch(setNotes(chartId));

const thunkClearSideEffects = chartId => dispatch =>
	dispatch(setSideEffects(chartId));

// eslint-disable-next-line import/prefer-default-export
export {
	thunkInitState,
	setNotes,
	thunkClearNotes,
	setShowModal,
	setSideEffects,
	thunkClearSideEffects
};
