import { YYYY_MM_DD } from 'aegion_common_utilities/lib/format/datetime';
import { getDateRangeTypeMeta } from './util/DateRangeTypes';

const PAST_7_DAYS = getDateRangeTypeMeta('PAST_7_DAYS');

const stringifyDate = date => {
	if (date instanceof Date) {
		return YYYY_MM_DD(date);
	}
	return date;
};

const initialState = {
	type: PAST_7_DAYS.key,
	from: stringifyDate(PAST_7_DAYS.computeNewFrom()),
	to: stringifyDate(PAST_7_DAYS.computeNewTo())
};

export default initialState;
