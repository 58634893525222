import {
	ERROR_ARRAY_UNDEFINED,
	ERROR_INJECT_ITEM_EXISTS,
	ERROR_UPDATE_ITEM_DOES_NOT_EXIST
} from './constants';

export const isDefined = v => v !== null && v !== undefined;

export const removeItemFromArray = (arr, item) => {
	if (!isDefined(arr) || arr.length === 0) {
		return arr;
	}

	const currIdx = arr.indexOf(item);
	if (currIdx === -1) {
		return arr;
	}

	return [...arr.slice(0, currIdx), ...arr.slice(currIdx + 1, arr.length)];
};

export const injectItemIntoArray = (arr, item, index) => {
	if (!isDefined(arr)) {
		throw new Error(ERROR_ARRAY_UNDEFINED);
	}

	if (arr.indexOf(item) !== -1) {
		throw new Error(ERROR_INJECT_ITEM_EXISTS);
	}

	const idx = Math.max(0, Math.min(index, arr.length));
	return [...arr.slice(0, idx), item, ...arr.slice(idx, arr.length)];
};

export const updateArrayItemOrder = (arr, item, newIdx) => {
	if (!isDefined(arr)) {
		throw new Error(ERROR_ARRAY_UNDEFINED);
	}

	const currIdx = arr.indexOf(item);
	if (arr.length === 0 || currIdx === -1) {
		throw new Error(ERROR_UPDATE_ITEM_DOES_NOT_EXIST);
	}

	const _newIdx = Math.max(0, Math.min(newIdx, arr.length - 1));
	return injectItemIntoArray(removeItemFromArray(arr, item), item, _newIdx);
};

export default { isDefined };
