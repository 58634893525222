/* eslint-disable import/no-cycle */
import ajax from '../../../scanline/utils/ajax';

export default (
	url,
	data,
	method,
	callback,
	isAnonymous,
	aip = 'cisviewreadonly'
) => {
	return ajax(url, data, method, callback, isAnonymous, aip);
};
