import AccountUtil from '../../account/components/utils/AccountUtil';

const NS = 'MAIN_';

export const SET_APPLICATIONS = `${NS}SET_APPLICATIONS`;

const setApplications = apps => {
	return {
		type: SET_APPLICATIONS,
		payload: apps
	};
};

export const getApplications = () => dispatch => {
	AccountUtil.applications().then(res => {
		dispatch(setApplications(res));
	});
};
