/* eslint-disable import/no-cycle */
/* eslint-disable import/prefer-default-export */
import { createPromiseRequest } from './util/createPromiseRequest';
import { hasAccessToken } from '../../scanline/utils/token';

export const listDdbCustomersSettings = () => {
	const url = '/doc/customers/settings';

	if (hasAccessToken()) {
		const fetch = createPromiseRequest({
			url,
			method: 'GET'
		});

		return fetch();
	}
	return Promise.reject(new Error('Missing token'));
};

export const getDdbCustomersSettings = customerName => {
	const url = `/doc/customers/settings/${customerName}`;

	if (hasAccessToken()) {
		const fetch = createPromiseRequest({
			url,
			method: 'GET'
		});

		return fetch();
	}
	return Promise.reject(new Error('Missing token'));
};
