"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;
exports.transformDistanceWithOptions = transformDistanceWithOptions;

var _MiscUtils = _interopRequireDefault(require("../MiscUtils"));

var _config = require("./config");

var _transformDistanceToUnits = _interopRequireDefault(require("./transformDistanceToUnits"));

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    "default": obj
  };
}

function transformDistanceWithOptions(distanceFromProps) {
  var options = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
  var _UNITS$DISTANCE = _config.UNITS.DISTANCE,
      FEET = _UNITS$DISTANCE.FEET,
      KILOMETERS = _UNITS$DISTANCE.KILOMETERS;
  var _options$roundTo = options.roundTo,
      roundTo = _options$roundTo === void 0 ? undefined : _options$roundTo,
      _options$to = options.to,
      to = _options$to === void 0 ? FEET : _options$to,
      _options$from = options.from,
      from = _options$from === void 0 ? KILOMETERS : _options$from;
  var distance = distanceFromProps;
  distance = (0, _transformDistanceToUnits["default"])(distance, from, to);

  if (roundTo !== undefined) {
    distance = _MiscUtils["default"].roundToDigitPlace(distance, roundTo);
  }

  return distance;
}

var _default = transformDistanceWithOptions;
exports["default"] = _default;