/* eslint-disable import/prefer-default-export */
const {
	generateBoundaryKey
} = require('../slices/StoreXYMinMax/util/generateBoundaryKey');

const getFirstAndLastIds = (arr = []) => {
	const first = (arr[0] || {}).id || undefined;
	const last = (arr[arr.length - 1] || {}).id || undefined;
	return [first, last];
};

const computeXYMinMax = (survey, arr) => {
	const [xMin, xMax] = getFirstAndLastIds(arr);
	return {
		xMin,
		xMax,
		yMin: survey.yMin,
		yMax: survey.yMax
	};
};

export const selectXYMinMaxPayloadsForReadingsFiltered = (survey, stateApp) => {
	const surveyId = survey.id;
	const { readingsFiltered } = stateApp;
	const store = readingsFiltered[surveyId];
	const filterKeys = Object.keys(store);

	return filterKeys.map(filterKey => {
		const { readings = [] } =
			(readingsFiltered[surveyId] || {})[filterKey] || {};

		return {
			key: generateBoundaryKey(surveyId, { filterKey }),
			boundaries: computeXYMinMax(survey, readings)
		};
	});
};

export const selectXYMinMaxPayloadsForAlignedReadings = (
	targetSurvey,
	stateApp
) => {
	const targetSurveyId = targetSurvey.id;
	const { alignedReadings } = stateApp;
	const renderToSurveys = Object.keys(alignedReadings).filter(
		sId => Object.keys(alignedReadings[sId]).indexOf(targetSurveyId) > -1
	);

	return renderToSurveys.map(renderToSurvey => {
		const { readings = [] } =
			(alignedReadings[renderToSurvey] || {})[targetSurveyId] || {};

		return {
			key: generateBoundaryKey(targetSurveyId, { renderToSurvey }),
			boundaries: computeXYMinMax(targetSurvey, readings)
		};
	});
};

export const selectXYMinMaxPayloadsForAlignedReadingsFiltered = (
	targetSurvey,
	stateApp
) => {
	const targetSurveyId = targetSurvey.id;
	const { alignedReadingsFiltered } = stateApp;
	const renderToSurveys = Object.keys(alignedReadingsFiltered).filter(
		sId =>
			Object.keys(alignedReadingsFiltered[sId]).indexOf(targetSurveyId) > -1
	);

	return renderToSurveys.reduce((acc, renderToSurvey) => {
		const store =
			(alignedReadingsFiltered[renderToSurvey] || {})[targetSurveyId] || {};
		const filterKeys = Object.keys(store);

		return [
			...acc,
			...filterKeys.map(filterKey => {
				const { readings = [] } =
					((alignedReadingsFiltered[renderToSurvey] || {})[targetSurveyId] ||
						{})[filterKey] || {};

				return {
					key: generateBoundaryKey(targetSurveyId, {
						renderToSurvey,
						filterKey
					}),
					boundaries: computeXYMinMax(targetSurvey, readings)
				};
			})
		];
	}, []);
};
