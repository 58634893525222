import moment from 'moment';

export const LLFilterActiveColor = 'hsla(39,100%,60%,0.6)';
export const LLFilterInactiveColor = 'white';

export const TimeFilterTypeList = [
	{
		value: 'today',
		label: 'Today',
		deltaUnit: 'day',
		delta: 0,
		bgColor: LLFilterInactiveColor
	},
	{
		value: 'last1Hour',
		label: 'Last 1 Hour',
		deltaUnit: 'hour',
		delta: 1,
		bgColor: LLFilterInactiveColor
	},
	{
		value: 'last7Days',
		label: 'Last 7 Days',
		deltaUnit: 'day',
		delta: 7,
		bgColor: LLFilterInactiveColor
	},
	{
		value: 'last30Days',
		label: 'Last 30 Days',
		deltaUnit: 'day',
		delta: 30,
		bgColor: LLFilterInactiveColor
	},
	{
		value: 'last90Days',
		label: 'Last 90 Days',
		deltaUnit: 'day',
		delta: 90,
		bgColor: LLFilterInactiveColor
	},
	{
		value: 'thisYear',
		label: 'This Year',
		deltaUnit: 'year',
		delta: 0,
		bgColor: LLFilterInactiveColor
	},
	{
		value: 'lastYear',
		label: 'Last Year',
		deltaUnit: 'year',
		delta: 1,
		deltaEnd: 1,
		bgColor: LLFilterInactiveColor
	},
	{ value: 'custom', label: 'Custom', bgColor: LLFilterActiveColor }
];

export const getFilterSpecs = (tft /* = dataStores.timeFilterType */) => {
	const timeFilter = TimeFilterTypeList.find(f => {
		return f.value === tft;
	});
	let timeFilterBegin;
	let timeFilterEnd;
	if (timeFilter && tft !== 'custom') {
		timeFilterBegin = moment()
			.subtract(timeFilter.delta, timeFilter.deltaUnit)
			.startOf(timeFilter.deltaUnit)
			.valueOf();
		timeFilterEnd = moment()
			.subtract(timeFilter.deltaEnd, timeFilter.deltaUnit)
			.endOf(timeFilter.deltaUnit)
			.valueOf();
	} else {
		throw new Error('No dataSores');
		/* timeFilterBegin = dataStores.timeFilterBegin;
		timeFilterEnd = dataStores.timeFilterEnd; */
	}
	return { timeFilter, timeFilterBegin, timeFilterEnd };
};
