import React, { useMemo } from 'react';
import { connect } from 'react-redux';
import { selectIsSideNavExpanded } from '../../../../../../../scanline/redux/WebsiteExperimental/selectors';
import ApplicationsList from '../../../ApplicationsList';
import Details from '../../../Details';

import './Original.scss';

const mapStateToProps = state => {
	const { websiteExperimental } = state;
	return {
		isLocalHostSideNavExpanded: selectIsSideNavExpanded(websiteExperimental)
	};
};

const BASE_CLASSNAME = 'home-page grid-original';

const Original = ({ isLocalHostSideNavExpanded }) => {
	const className = useMemo(
		() =>
			`${BASE_CLASSNAME} ${
				isLocalHostSideNavExpanded ? 'local-host-side-nav-expanded' : ''
			}`,
		[isLocalHostSideNavExpanded]
	);
	return (
		<div className={className}>
			<div className="hp-grid-container">
				<div className="hp-applications-list">
					<ApplicationsList />
				</div>
				<div className="hp-application-details">
					<Details />
				</div>
			</div>
		</div>
	);
};

export default connect(mapStateToProps)(Original);
