import { setSelectedApp } from './actions';

const reducerParts = {
	[setSelectedApp]: (state, { payload: { key } }) => {
		return {
			...state,
			selectedAppKey: key
		};
	}
};

export default reducerParts;
