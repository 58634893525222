import {
	getCustomerMeta,
	getCustomerRemediationActions,
	getCustomerRemediationActionTypes,
	getCustomerRemediationActionFields,
	getCustomerRemediationActionsKeys
} from '../../../util/customer/CustomerConfig';
import { getCustomer } from '../../../util/user';

// HELPERS
export const getCustomerKey = (state = {}, textTransform = 'none') => {
	const { surveys = [] } = state;

	const surveyCustomer =
		surveys.length > 0 ? ((surveys[0] || {}).customer || '').trim() : '';
	const result = surveyCustomer || getCustomer() || '';

	if (textTransform === 'toLowerCase') {
		return result.toLowerCase();
	}

	return result;
};

const createCustomerSelector = getter => {
	return (state = {}) => {
		const customerKey = getCustomerKey(state);
		return getter(customerKey);
	};
};

export const selectCustomerMeta = createCustomerSelector(getCustomerMeta);
export const selectCustomerRemediationActions = createCustomerSelector(
	getCustomerRemediationActions
);
export const selectCustomerRemediationActionTypes = createCustomerSelector(
	getCustomerRemediationActionTypes
);
export const selectCustomerRemediationActionFields = createCustomerSelector(
	getCustomerRemediationActionFields
);
export const selectCustomerRemediationActionsKeys = createCustomerSelector(
	getCustomerRemediationActionsKeys
);
