import cloneDeep from 'lodash/cloneDeep';

import { onSpikeGraphMvChanged } from './spike-editor-spike-graph';
// eslint-disable-next-line import/no-cycle
import { setUnsortedConvertedReadings } from './readings';

export const setSelectedDatFile = (
	index,
	oldData,
	preProcessedGPSReadings,
	newData,
	previousChartData,
	nextChartData,
	fileType,
	readingsEditType
) => ({
	type: 'CISV_SPIKE_EDITOR_SET_SELECTED_DAT_FILE',
	index,
	oldData,
	preProcessedGPSReadings,
	newData,
	previousChartData,
	nextChartData,
	fileType,
	readingsEditType
});

export const receiveNewChartData = (
	oldData,
	preProcessedGPSReadings,
	newData,
	previousChartData,
	nextChartData,
	autoCorrectionInProcess
) => ({
	type: 'CISV_SPIKE_EDITOR_RECEIVE_NEW_CHART_DATA',
	oldData,
	preProcessedGPSReadings,
	newData,
	previousChartData,
	nextChartData,
	autoCorrectionInProcess
});

export const setDefaultStateFromProps = props => ({
	type: 'CISV_SPIKE_EDITOR_SET_DEFAULT_STATE',
	props
});

export const readingMultiplierByName = (
	data,
	multiplier,
	readingNames = ['on', 'off']
) => {
	if (!data) {
		return null;
	}

	return data.map(d => {
		const object = cloneDeep(d);
		readingNames.forEach(name => {
			if (object[name]) {
				object[name] *= multiplier;
			}
		});
		return { ...object };
	});
};

const setMvChanged = newState => ({
	type: 'CISV_SPIKE_EDITOR_USE_MV_SETTING',
	newState
});

export const onMvChanged = (
	oldData,
	preProcessedGPSReadings,
	newData,
	previousChartData,
	nextChartData,
	unsortedConvertedReadingsByFile,
	newUseMv
) => dispatch => {
	let multiplier;
	if (!newUseMv) {
		multiplier = 0.001; // We want MV to become Volts so we divide by 1000
	} else {
		multiplier = 1000; // We want Volts to become MV so we multiply by 1000
	}

	const changedOldData = readingMultiplierByName(oldData, multiplier);
	const changedNewData = readingMultiplierByName(newData, multiplier);

	const changedPreviousChartData = readingMultiplierByName(
		previousChartData,
		multiplier
	);
	const changedNextChartData = readingMultiplierByName(
		nextChartData,
		multiplier
	);

	const changedPreProcessedGPSReadings = readingMultiplierByName(
		preProcessedGPSReadings,
		multiplier
	);

	const changeUnsortedConvertedReadingsByFile = unsortedConvertedReadingsByFile.map(
		unsortedConvertedReadings =>
			readingMultiplierByName(unsortedConvertedReadings, multiplier, [
				'reading1',
				'reading2',
				'originalReading1',
				'originalReading2'
			])
	);

	const newState = {
		oldData: changedOldData,
		preProcessedGPSReadings: changedPreProcessedGPSReadings,
		newData: changedNewData,
		previousChartData: changedPreviousChartData,
		nextChartData: changedNextChartData,
		useMv: newUseMv
	};

	dispatch(setMvChanged(newState));
	dispatch(onSpikeGraphMvChanged(newState));
	dispatch(setUnsortedConvertedReadings(changeUnsortedConvertedReadingsByFile));
};

export const setSpikeEditorShouldChange = () => ({
	type: 'SPIKE_EDITOR_SET_SHOULD_CHANGE'
});
