/* eslint-disable prefer-const */
export const formatStationId = val => {
	let result = val;

	const isNumberType = typeof val === 'number';
	if (isNumberType) {
		let [num, dec] = val.toString().split('.');

		// format number
		const paddedNum = num.padStart(3, 0);
		const formattedNum = `${paddedNum.substr(
			0,
			paddedNum.length - 2
		)}+${paddedNum.substr(paddedNum.length - 2, paddedNum.length)}`;
		result = formattedNum;

		// format decimal
		if (dec) {
			dec = dec.padEnd(2, '0');
			result = `${result}.${dec}`;
		}
	}

	return result;
};

export default { formatStationId };
