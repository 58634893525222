import { getFileExtension } from '../../../../util/misc';

export const shouldDisableFile = (
	handleFile,
	{ disableWhenErrors = false }
) => {
	if (!disableWhenErrors) {
		return false;
	}

	return (handleFile.errors || []).length > 0;
};

export const shouldNormalizeFile = handleFile => {
	// TODO 3488: Why are "true" and "false" strings?
	return handleFile.issubmit === 'false' && handleFile.ishidden !== 'true';
};

export const getFileFormatFromFileObject = file => {
	return getFileExtension(file.name);
};

export const getFileFormatsFromFileObjects = files => {
	return (
		files
			// TODO 3488: What is this doing?
			.map(getFileFormatFromFileObject)
	);
};

export const getFilenameFormats = files => {
	const allFormats = getFileFormatsFromFileObjects(files);
	const upperCaseformats = allFormats.map(f => f.toUpperCase());
	const unqiueFilenameFormats = [...new Set(upperCaseformats)];

	return unqiueFilenameFormats;
};

export const normalizeFiles = (files, { disableWhenErrors = false } = {}) => {
	const filesWithFormats = {};
	let fileCount = 0;

	const formats = getFilenameFormats(files);
	formats.forEach(f => {
		filesWithFormats[f] = [];
	});

	// TODO 3488: SCANLine only hits this if there are more than one file format
	files.forEach(file => {
		const handleFile = file;
		const format = getFileFormatFromFileObject(file).toUpperCase();

		if (!shouldNormalizeFile(handleFile)) {
			return;
		}

		if (shouldDisableFile(handleFile, { disableWhenErrors })) {
			// TODO 3488: Do not mutate
			handleFile.disabled = true;
		} else {
			// TODO 3488: Do not mutate
			handleFile.disabled = false;
		}
		// TODO 3488: Do not mutate
		handleFile.selected = false;
		fileCount += 1;
		filesWithFormats[format].push(handleFile);
	});

	return {
		filesWithFormats,
		fileCount
	};
};

export const deleteMe = () => {};
