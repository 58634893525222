import { LINES_PER_PAGE, LINES_QUERY_ITEM_LIMIT } from '../constants';
import { mapToClientLineProps } from './line.server';
import scanlineUtils from '../../../scanlineUtils';

// expects an array of strings formatted as key:value pairs
// example - ["line:some line name", "pm:some project manager name"]
// above example would return - { line: ["some line name"], pm: ["some project manager name"] }
const mapPropToServerProp = {
	linename: 'line',
	corrosiontechnician: 'technician',
	subtype: 'type',
	projectmanager: 'pm',
	aegion_job_id: 'job'
};
const convertFiltersArrayToObjectOfFilters = (filters = []) => {
	return filters.reduce((result, filter) => {
		const [prop, ...vals] = filter.split(':');
		const val = vals.join(':');
		const propFilters = result[prop] || [];
		return {
			...result,
			[mapPropToServerProp[prop] || prop]: [
				...propFilters,
				encodeURIComponent(val)
			]
		};
	}, {});
};

export const fetchLines = (reqObj = {}, callback) => {
	// for some reason old code was implemented with error param as first param, response is second param
	// for this initial pass we're ignoring errors
	const _callback = (err, res) => {
		if (err) {
			// console.log(err);
			return callback(undefined, err);
		}
		return callback(res);
	};
	scanlineUtils.fetchLines(reqObj, _callback);
};

export const createFetchLinesRequestObject = ({
	filters,
	customer: paramCustomer,
	pageStart = 1,
	pageEnd
}) => {
	const customer =
		paramCustomer && paramCustomer !== 'Aegion' ? paramCustomer : undefined;
	const offset = LINES_PER_PAGE * (pageStart - 1);
	const end = pageEnd || pageStart + 5;
	const limit =
		(Math.max(pageStart, end) - pageStart) * LINES_PER_PAGE || LINES_PER_PAGE;
	const requestFilters = convertFiltersArrayToObjectOfFilters(filters);

	return {
		limit,
		offset,
		customer,
		...requestFilters
	};
};

export const mapServerLinesToClientLines = (lines = []) => {
	return lines.map(line => {
		return mapToClientLineProps(line);
	});
};

export const getPagesFromFetchLinesRequestObject = requestObject => {
	const { limit = LINES_QUERY_ITEM_LIMIT, offset = 0 } = requestObject;
	const pageCount = limit / LINES_PER_PAGE;
	const startingPage = offset / LINES_PER_PAGE + 1;
	const result = [];

	for (let x = 0; x < pageCount; x += 1) {
		result.push(x + startingPage);
	}
	return result;
};

const isPreviousPageTheLastPage = (page, linesByPage, pages) => {
	return (
		pages.indexOf(page) > 0 && linesByPage[page - 1].length < LINES_PER_PAGE
	);
};

export const createLinesByPage = (lines, pages) => {
	const firstPage = pages[0];
	let foundLastPage = false;
	return pages.reduce((linesByPage, page) => {
		const startIndex = (page - firstPage) * LINES_PER_PAGE;
		const endIndex = startIndex + LINES_PER_PAGE;

		foundLastPage =
			foundLastPage || isPreviousPageTheLastPage(page, linesByPage, pages);
		if (foundLastPage) {
			return linesByPage;
		}

		return { ...linesByPage, [page]: lines.slice(startIndex, endIndex) };
	}, {});
};
