import randomColor from 'randomcolor';

export const setData = (appdata, payload) => {
	const { data, name, template } = appdata;
	payload.data.sort((a, b) => {
		if (
			parseFloat(a['item.inspectionDate']) >
			parseFloat(b['item.inspectionDate'])
		) {
			return 1;
		}
		return -1;
	});
	data[`${template}_${name}`] = payload;
	return data;
};

export const checkChanged = (params, payload) => {
	const { type, template, name } = payload;
	const chart = params[type][template][name];

	if (chart.selected === undefined) {
		chart.selected = [];
	}
	const key = `${payload.y}_${payload.entry}`;
	if (chart.selected.indexOf(key) === -1) {
		chart.selected.push(key);
	} else {
		chart.selected.splice(chart.selected.indexOf(key), 1);
	}
	return { ...params };
};

export const resetSelected = (params, payload) => {
	const { type, template, name, y } = payload;
	const chart = params[type][template][name];

	chart.selected = chart.selected.filter(item => {
		return item.indexOf(y.key) !== 0;
	});
	return { ...params };
};

export const highlight = (params, payload) => {
	const { type, template, name, highlighted } = payload;
	const chart = params[type][template][name];
	chart.highlighted = { ...highlighted };
	return { ...params };
};

export const getParams = (params, payload) => {
	const { type, name, template, charts } = payload;
	const outParams = { ...params };
	outParams[type] = { ...outParams[type] };
	outParams[type][template] = { ...outParams[type][template] };
	outParams[type][template][name] = {
		selected: [],
		...outParams[type][template][name]
	};
	if (charts) {
		outParams[type][template][name].charts = charts;
	}

	return outParams;
};

export const cleanData = res => {
	const { settings, data } = res;
	const { series } = settings;
	if (series) {
		res.lines = res.data
			.reduce((acc, item) => {
				if (item[series.key] && acc.indexOf(item[series.key]) === -1) {
					acc.push(item[series.key]);
				}
				return acc;
			}, [])
			.map(item => {
				return {
					value: item,
					color: randomColor({ luminosity: 'dark', format: 'rgba', alpha: 1.0 })
				};
			});
	}
	if (data) {
		data.sort((a, b) => {
			if (
				parseFloat(a['item.inspectionDate']) >
				parseFloat(b['item.inspectionDate'])
			) {
				return 1;
			}
			return -1;
		});
	}
};
