/* eslint-disable import/no-cycle */
/* eslint-disable camelcase */
import { ROOT_SLICE } from './constants';
import {
	clearReadings,
	receiveReadings,
	setIsLoaded,
	requestReadings
} from './actions';
import { selectRootSlice } from './selectors';
import { insertReadings } from './util';
import { initialState } from './initialState';

// ************
// PARTS
// ************
const reducerParts = {
	[clearReadings]: state => {
		return { ...state, [ROOT_SLICE]: { ...initialState } };
	},

	[requestReadings]: state => {
		const slice = selectRootSlice(state);
		return {
			...state,
			[ROOT_SLICE]: {
				...slice,
				isLoading: true,
				isLoaded: false
			}
		};
	},

	[setIsLoaded]: state => {
		const slice = selectRootSlice(state);
		return {
			...state,
			[ROOT_SLICE]: {
				...slice,
				isLoading: false,
				isLoaded: true
			}
		};
	},

	[receiveReadings]: (
		state,
		{ payload: { targetKey, parentKey, modifierKey, data } }
	) => {
		const slice = selectRootSlice(state);
		return {
			...state,
			[ROOT_SLICE]: {
				...slice,
				data: insertReadings(slice.data, {
					targetKey,
					parentKey,
					modifierKey,
					data
				})
			}
		};
	}
};

export default reducerParts;
