import ajax from '../../../../scanline/utils/ajax';

export const name = 'report ajax';

export const templates = () => {
	return ajax(
		'/templates',
		undefined,
		'GET',
		undefined,
		false,
		'downloadReport'
	);
};

export const finalReport = (jobId, template) => {
	return ajax(
		`/createFinalReport/${jobId}/${template}`,
		undefined,
		'GET',
		undefined,
		false,
		'downloadReport'
	);
};
