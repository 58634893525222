/* eslint-disable import/no-cycle */
/* eslint-disable import/prefer-default-export */
import { createPromiseRequest } from './util/createPromiseRequest';
import { hasAccessToken } from '../../scanline/utils/token';

export const getPsqlCustomerApplications = customerId => {
	const url = `/customers/applications/${customerId}`;

	if (hasAccessToken()) {
		const fetch = createPromiseRequest({
			url,
			method: 'GET'
		});

		return fetch();
	}
	return Promise.reject(new Error('Missing token'));
};

export const listPsqlCustomersApplications = () => {
	const url = '/rel/customers/applications';

	if (hasAccessToken()) {
		const fetch = createPromiseRequest({
			url,
			method: 'GET'
		});

		return fetch();
	}
	return Promise.reject(new Error('Missing token'));
};

export const insertPsqlCustomerApplication = (customerId, applicationId) => {
	const url = `/customers/applications/${customerId}/${applicationId}`;

	if (hasAccessToken()) {
		const put = createPromiseRequest({
			url,
			method: 'PUT'
		});

		return put();
	}
	return Promise.reject(new Error('Missing token'));
};

export const deletePsqlCustomerApplication = (customerId, applicationId) => {
	const url = `/customers/applications/${customerId}/${applicationId}`;

	if (hasAccessToken()) {
		const del = createPromiseRequest({
			url,
			method: 'DELETE'
		});

		return del();
	}
	return Promise.reject(new Error('Missing token'));
};
