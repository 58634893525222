import {SET_TEST_STATIONS} from '../actions/testStations'
const defaultState = {selected:[]}
const testStations = (state = defaultState, action) => {
	switch (action.type) {
		case SET_TEST_STATIONS: {
			return {...state, selected:action.payload};
		}
		default:
			return state;
	}
};

export default testStations;