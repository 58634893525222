/* eslint-disable import/prefer-default-export */
import { ColumnsUtil } from 'aegion_common_utilities/lib/AVDataUtil/Columns';
// import Columns from 'aegion_common_utilities/lib/AVDataUtil/Columns';

const {
	getSourceField,
	getRowFields,
	getFields,
	findColumnSettings,
	findEmptyColumns: findEmptyColumnsCommns,
	getColumns: getColumnsCommons,
	getColumnLabels: getColumnLabelsCommons,
	getColumnLengths: getColumnLengthsCommons
} = ColumnsUtil;

export const findEmptyColumns = (...args) => {
	return findEmptyColumnsCommns(...args);
};

export const getColumnLengths = (...args) => {
	return getColumnLengthsCommons(...args);
};

export const getColumnLabels = (...args) => {
	return getColumnLabelsCommons(...args);
};

export const getColumns = (...args) => {
	return getColumnsCommons(...args);
};

export { getSourceField, getRowFields, getFields, findColumnSettings };
