import { combineReducers } from 'redux';

import filters from './Filters/reducer';
import zones from './Zones/reducer';
import charts from './Charts/reducer';
import maps from './Maps/reducer';
import configure from './Configure/reducer';

// date range filter
import { ROOT_SLICE_KEY as DateRangeFilterKey } from './DateRangeFilter/constants';
import DateRangeFilterReducer from './DateRangeFilter/reducer';

export default combineReducers({
	filters,
	zones,
	charts,
	maps,
	configure,
	[DateRangeFilterKey]: DateRangeFilterReducer
});
