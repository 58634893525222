const regEx = new RegExp(
	'PI|EDGE|LINE MARKER|MLV|RISER|GATE|VLV|VALVE| TS |TEST STATION|RSR|LAUNCHER|RECEIVER|PIGTRAP|TAP|FENCE|MATCH POINT|MATCHPOINT'
);

const setNewOverlapData = overlapDataByDatFile => {
	return { type: 'CISV_OVERLAP_NEW_DATA', overlapDataByDatFile };
};

const _getOverlapStationNumber = (startStn, stationId) => {
	const numericalStartStn = +startStn;
	const numericalStationid = +stationId;
	return numericalStartStn + numericalStationid;
};

const _randomString = () => {
	return `${Date.now()}-${Math.random()}`;
};

const _trimWithKey = trim => {
	if (trim.key) {
		return trim;
	}

	return {
		...trim,
		key: _randomString()
	};
};

const _getCommentByStationId = (datFile, commentsByStationId, stationId) => {
	if (!datFile) {
		return null;
	}

	const { startStn, endStn, gpsreadings, reverse } = datFile;
	if ((gpsreadings || []).length === 0) {
		return null;
	}
	let stationIdToFind = stationId;
	if (reverse) {
		stationIdToFind = endStn - stationIdToFind;
	} else if (startStn) {
		stationIdToFind -= startStn;
	}

	return commentsByStationId[stationIdToFind];
};

const _trimsWithKeys = (datFile, trims, commentsByStationId) => {
	return trims.map(trim => {
		const trimWithKey = _trimWithKey(trim);
		const { start, end } = trimWithKey;
		return {
			...trimWithKey,
			start: {
				...start,
				comments: _getCommentByStationId(
					datFile,
					commentsByStationId,
					start.number
				)
			},
			end: {
				...end,
				comments: _getCommentByStationId(
					datFile,
					commentsByStationId,
					end.number
				)
			}
		};
	});
};

const _createTrimForState = (startComment, endComment) => {
	return {
		start: startComment,
		end: endComment,
		key: _randomString()
	};
};

const _createDefaultTrim = (startComment, endComment, datFile) => {
	const start = startComment || { number: datFile.startStn, comment: null };
	const end = endComment || { number: datFile.endStn, comment: null };

	return _createTrimForState(start, end);
};

// This just used for Overlaps to tie comments to a stationId (number)
export const createCommentStationObject = (comments, number) => {
	return {
		comments,
		number
	};
};

// Will return a flattened array of all readings, but just their comments and formatted stationid
const _getCommentsForDatFile = (dat, ends) => {
	let comments = [];
	let startStn = 0;
	startStn = dat.startStn;

	comments = dat.gpsreadings
		.filter(reading => {
			const overlapStationId = _getOverlapStationNumber(
				startStn,
				reading.StationId
			);
			return (
				(ends &&
					(dat.startStn === overlapStationId ||
						dat.endStn === overlapStationId)) ||
				(reading.comments !== null && regEx.test(reading.comments))
			);
		})
		.map(reading => {
			const overlapStationId = _getOverlapStationNumber(
				startStn,
				reading.StationId
			);
			return createCommentStationObject(reading.comments, overlapStationId);
		});

	return comments;
};

const _getComment = (stationId, commentsWithStationNotInState) => {
	return (
		(commentsWithStationNotInState || []).filter(item => {
			return item.number === stationId;
		})[0] || null
	);
};

export const getTrimValue = (stationId, commentsWithStationNotInState) => {
	const commentWithStation = _getComment(
		stationId,
		commentsWithStationNotInState
	);
	if (commentWithStation) {
		// See if we already have a comment-station object to use
		return commentWithStation;
	}

	// Otherwise, create a dummy comment-station object (No comment)
	return createCommentStationObject(null, stationId);
};

const _reverseOverlapMarks = (datFile, marks) => {
	const { endStn, startStn } = datFile;

	const reverseMarks = {};
	Object.keys(marks)
		.sort((a, b) => b - a)
		.forEach(position => {
			const reversePositionOffset = endStn - position;
			const reversePosition = startStn + reversePositionOffset;
			reverseMarks[reversePosition] = marks[position];
		});

	return reverseMarks;
};
const _getCommentsByStationId = datFile => {
	if (!datFile) {
		return {};
	}

	const { gpsreadings = [] } = datFile;

	if (gpsreadings.length === 0) {
		return {};
	}

	const commentsByStationId = {};

	gpsreadings.forEach(gpsreading => {
		const { StationId, comments } = gpsreading;
		if ([undefined, null].includes(comments)) {
			return;
		}

		commentsByStationId[StationId] = comments;
	});

	return commentsByStationId;
};

const _compileNewOverlapDataForFile = datFile => {
	const formattedCommentsWithStations = _getCommentsForDatFile(datFile, true);
	const startComment = getTrimValue(
		datFile.startStn,
		formattedCommentsWithStations
	);
	const endComment = getTrimValue(
		datFile.endStn,
		formattedCommentsWithStations
	);

	const defaultTrim = _createDefaultTrim(startComment, endComment, datFile);

	const marks = {};
	formattedCommentsWithStations.forEach(item => {
		marks[item.number] = { style: {} };
	});

	const commentsByStationId = _getCommentsByStationId(datFile);

	// TODO: Pass in commentsByStationId
	const trims = _trimsWithKeys(
		datFile,
		datFile.trims || [],
		commentsByStationId
	);
	return {
		trims,
		marks,
		startComment,
		endComment,
		defaultTrim,
		datFile,
		commentsByStationId,
		reverseMarks: _reverseOverlapMarks(datFile, marks)
	};
};

const _getOverlapDataByFileFromState = getState => {
	const state = getState();
	const { cisview } = state;
	const { overlap } = cisview;
	const { overlapDataByDatFile } = overlap;

	return overlapDataByDatFile;
};

export const updateOverlapData = datFiles => (dispatch, getState) => {
	const overlapDataByDatFile = _getOverlapDataByFileFromState(getState);

	const newOverlapDataByFileName = {};
	datFiles.forEach(datFile => {
		const { fileName } = datFile;
		const oldOverlapData = overlapDataByDatFile[fileName] || {};
		const oldOverlapDatFile = oldOverlapData.datFile;
		if (oldOverlapDatFile === datFile) {
			newOverlapDataByFileName[fileName] = oldOverlapData;
			return; // The dat file has not changed
		}
		// TODO: Update
		newOverlapDataByFileName[fileName] = _compileNewOverlapDataForFile(datFile);
	});

	dispatch(setNewOverlapData(newOverlapDataByFileName));
};

const updateOverlapDataByField = (getState, datFile, fieldName, fieldValue) => {
	const overlapDataByDatFile = _getOverlapDataByFileFromState(getState);

	const { fileName } = datFile;
	const overlapData = overlapDataByDatFile[fileName];
	const newOverlapData = {
		...overlapData,
		[fieldName]: fieldValue
	};

	const newOverlapDataByFileName = {
		...overlapDataByDatFile,
		[fileName]: newOverlapData
	};

	return newOverlapDataByFileName;
};

export const updateWithTrims = (datFile, trims) => (dispatch, getState) => {
	const overlapDataByDatFile = _getOverlapDataByFileFromState(getState);

	const overlapData = overlapDataByDatFile[datFile.fileName];
	const { commentsByStationId } = overlapData;

	const newOverlapDataByFileName = updateOverlapDataByField(
		getState,
		datFile,
		'trims',
		_trimsWithKeys(datFile, trims, commentsByStationId)
	);

	dispatch(setNewOverlapData(newOverlapDataByFileName));
};

export const updateWithEndComment = (datFile, endComment) => (
	dispatch,
	getState
) => {
	const newOverlapDataByFileName = updateOverlapDataByField(
		getState,
		datFile,
		'endComment',
		endComment
	);

	dispatch(setNewOverlapData(newOverlapDataByFileName));
};
