export const CARD_GRAY = 'GRAY';
export const CARD_GRAY_FLAT = 'GRAY_FLAT';
export const CARD_BLUE = 'BLUE';
export const CARD_BLUE_FLAT = 'BLUE_FLAT';
// export const CARD_LIGHT_BLUE = 'LIGHT_BLUE';

export const VALID_CARD_COLORS = [
	CARD_GRAY,
	CARD_GRAY_FLAT,
	CARD_BLUE,
	CARD_BLUE_FLAT
];
export const DEFAULT_CARD_COLOR = CARD_GRAY_FLAT;
