import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { Router } from 'react-router-dom';

import 'font-awesome/scss/font-awesome.scss';

import * as serviceWorker from './serviceWorker';
import { store, browserHistory } from './scanline/store';
import MainRouter from './main/components/routing/MainRouter';

import CustomerSupport from './commons/components/CustomerSupport';
import AppTitle from './main/components/AppTitle';
import Header from './main/components/layout/Header';

import './styles/legacy-override-patches.scss';

ReactDOM.render(
	<>
		<Provider store={store}>
			<>
				<Router history={browserHistory}>
					<Header>
						<AppTitle />
						<CustomerSupport />
					</Header>
					<MainRouter />
				</Router>
			</>
		</Provider>
	</>,
	document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
