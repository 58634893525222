/* eslint-disable import/no-cycle */
import { createActions } from 'redux-actions';
import { goodEnoughUUID } from '../../../../../../utils/uuid';

const {
	addChartSettings,
	removeChartSettings,
	setChartThresholds,
	setChartPendingThresholds,
	clearChartPendingThresholds,
	setChartUseThreshold,
	setChartShowDecimatedData,
	setChartUseShadow,
	setChartUseSawtooth,
	setChartHasSawtoothData,
	setChartUseNormalizedDepol,
	setChartUseInterpolatedDepol,
	setShowSpatial,
	setChartSurveys,
	addChartSurvey,
	removeChartSurvey,
	setChartPrimarySurveyId,
	setChartDepolSurveyId,
	setSelectedRemediationGroup,
	setSeriesVisibilityDataStore,
	setSeriesVisibility,
	setShowConfirmationForCustomGroups,
	setConfirmationMessageKey,
	incrementInputThresholdResetToken,
	incrementSeriesVisibilityResetToken,
	setSeriesCache
} = createActions(
	{
		// BASE
		ADD_CHART_SETTINGS: (chartId, settings) => {
			return { chartId, settings };
		},
		REMOVE_CHART_SETTINGS: chartId => {
			return { chartId };
		},
		// THRESHOLDS
		SET_CHART_THRESHOLDS: (chartId, thresholds) => {
			return { chartId, thresholds };
		},
		// PENDING THRESHOLDS
		SET_CHART_PENDING_THRESHOLDS: (chartId, thresholds) => {
			return { chartId, thresholds };
		},
		CLEAR_CHART_PENDING_THRESHOLDS: chartId => {
			return { chartId };
		},
		// THRESHOLD
		SET_CHART_USE_THRESHOLD: (chartId, useThreshold) => {
			return { chartId, useThreshold };
		},
		// DECIMATED DATA
		SET_CHART_SHOW_DECIMATED_DATA: (chartId, showDecimatedData) => {
			return { chartId, showDecimatedData };
		},
		// SHADOW
		SET_CHART_USE_SHADOW: (chartId, useShadow) => {
			return { chartId, useShadow };
		},
		// SAWTOOTH
		SET_CHART_USE_SAWTOOTH: (chartId, useSawtooth) => {
			return { chartId, useSawtooth };
		},
		SET_CHART_HAS_SAWTOOTH_DATA: (chartId, hasSawtoothData) => {
			return { chartId, hasSawtoothData };
		},
		// USE NORMALIZED
		SET_CHART_USE_NORMALIZED_DEPOL: (chartId, useNormalizedDepol) => {
			return { chartId, useNormalizedDepol };
		},
		// USE INTERPOLATED
		SET_CHART_USE_INTERPOLATED_DEPOL: (chartId, useInterpolatedDepol) => {
			return { chartId, useInterpolatedDepol };
		},
		// SHOW SPATIAL
		SET_SHOW_SPATIAL: (chartId, showSpatial) => {
			return { chartId, showSpatial };
		},
		// SURVEYS
		SET_CHART_SURVEYS: (chartId, surveys) => {
			return { chartId, surveys };
		},
		ADD_CHART_SURVEY: (chartId, survey) => {
			return { chartId, survey };
		},
		REMOVE_CHART_SURVEY: (chartId, survey) => {
			return { chartId, survey };
		},
		SET_CHART_PRIMARY_SURVEY_ID: (chartId, surveyId) => {
			return { chartId, surveyId };
		},
		SET_CHART_DEPOL_SURVEY_ID: (chartId, surveyId) => {
			return { chartId, surveyId };
		},
		SET_SELECTED_REMEDIATION_GROUP: (chartId, group, groupIndex) => {
			return { chartId, group, groupIndex };
		},
		SET_SERIES_VISIBILITY_DATA_STORE: (chartId, dataStore) => {
			return { chartId, dataStore };
		},

		// @note - its possible for the same data set to be plotted via multiple series
		// @example - on/off surveys
		SET_SERIES_VISIBILITY: (
			chartId,
			{
				dataSetKey, // could be a survey id - could be the name of a series
				propKey, // name of property which is plotted on chart
				visibilityFlagKey, // an override prop for propKey // allows us to track vis for BC without tying it specificially to an on or off prop.
				isVisible
			},
			{ overwriteChildStore } = {}
		) => {
			return {
				chartId,
				dataSetKey,
				visibilityFlagKey,
				propKey,
				isVisible,
				overwriteChildStore
			};
		},

		SET_SHOW_CONFIRMATION_FOR_CUSTOM_GROUPS: (chartId, showConfirmation) => {
			return { chartId, showConfirmation };
		},

		SET_CONFIRMATION_MESSAGE_KEY: (chartId, confirmationMessageKey) => {
			return { confirmationMessageKey, chartId };
		},
		INCREMENT_INPUT_THRESHOLD_RESET_TOKEN: chartId => {
			const token = goodEnoughUUID();
			return { chartId, token };
		},
		INCREMENT_SERIES_VISIBILITY_RESET_TOKEN: chartId => {
			const token = goodEnoughUUID();
			return { chartId, token };
		},
		SET_SERIES_CACHE: (chartId, series) => {
			return { chartId, series };
		}
	},
	{
		prefix: 'ChartsSettings'
	}
);

// eslint-disable-next-line import/prefer-default-export
export {
	addChartSettings,
	removeChartSettings,
	setChartThresholds,
	setChartPendingThresholds,
	clearChartPendingThresholds,
	setChartUseThreshold,
	setChartShowDecimatedData,
	setChartUseShadow,
	setChartUseSawtooth,
	setChartHasSawtoothData,
	setChartUseNormalizedDepol,
	setShowConfirmationForCustomGroups,
	setConfirmationMessageKey,
	incrementInputThresholdResetToken,
	incrementSeriesVisibilityResetToken,
	setSeriesCache,
	setChartUseInterpolatedDepol,
	setShowSpatial,
	setChartSurveys,
	addChartSurvey,
	removeChartSurvey,
	setChartPrimarySurveyId,
	setChartDepolSurveyId,
	setSelectedRemediationGroup,
	setSeriesVisibilityDataStore,
	setSeriesVisibility
};
