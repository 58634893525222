/** ***********
 * MAIN - Align Readings By GPS Coordinates
 */

import { memCreateKnnCluster } from './knnCluster';

import { computeReadingDistance } from '../mapUtils/mapUtils.computeReadingDistance';

export const calculatedInterpolatedPoint = (
	totalNumber,
	index,
	point1,
	point2
) => {
	// y = mx+b
	const m = (point2 - point1) / (totalNumber + 1);
	const b = point1;

	const x = index + 1;

	return m * x + b;
};

const interpolateReadingsBetweenNormalizedReadings = (
	readingsToInterpolate,
	normalizedIliReadings,
	nextId
) => {
	const interpolatedReadings = [];
	readingsToInterpolate.forEach((readingToInterpolate, i) => {
		const previousGoodReading =
			normalizedIliReadings[normalizedIliReadings.length - 1];

		const previousId = previousGoodReading.id;
		const id = calculatedInterpolatedPoint(
			readingsToInterpolate.length,
			i,
			previousId,
			nextId
		);

		interpolatedReadings.push({
			...readingToInterpolate,
			id,
			isInterpolated: true,
			isNormalized: true
		});
	});

	return interpolatedReadings;
};

const setCisReadingIndexesToFind = (cisReadings, startIndex) => {
	const cisReadingsLength = cisReadings.length;

	const cisIndexesToFind = {};
	for (let i = startIndex + 1; i < cisReadingsLength; i += 1) {
		cisIndexesToFind[i] = true;
	}

	return cisIndexesToFind;
};

const calculatePrimaryReadingsLost = (indexes, lastIndex) => {
	let indexesLostBetween = 0;
	let indexesLostAfter = 0;

	Object.keys(indexes).forEach(index => {
		if (index <= lastIndex) {
			indexesLostBetween += 1;
		} else {
			indexesLostAfter += 1;
		}
	});

	return {
		indexesLostBetween,
		indexesLostAfter
	};
};

/** ***********
 * MAIN - ALIGN BY GPS COORDINATES
 * This function actually uses ALL the target readings to create the cluster (whereas depol uses only some of the CISReadings to create the cluster)
 * Note that this function memoizes KNN - which should be fine but is something a developer should be aware of
 */

export const alignReadingsByCoordinates = (
	cisReadings,
	targetReadings,
	options = {}
) => {
	const {
		threshold = 20,
		verbose = false,
		ensureAscendingIndexes = false,
		algorithm
	} = options;
	const maxDistance = threshold * 0.3048; // feet to meters

	const spatialIndex = memCreateKnnCluster(cisReadings);

	const alignedTargetReadings = [];

	let readingsToInterpolate = [];
	const stats = {
		target: {
			countReadingsLostBefore: 0,
			countReadingsInterpolatedBetween: 0,
			countFoundBetween: 0,
			countReadingsLostAfter: 0,
			total: targetReadings.length
		},
		primary: {
			countReadingsLostBefore: 0,
			countReadingsLostBetween: 0,
			countFoundBetween: 0,
			countReadingsLostAfter: 0,
			total: cisReadings.length
		},
		distances: []
	};

	let cisIndexesToFind = {};
	let lastCisReadingIndex = -1;
	targetReadings.forEach(targetReading => {
		const nearestCisReadings = spatialIndex(
			targetReading.loc[0],
			targetReading.loc[1],
			Infinity,
			maxDistance
		);

		if (nearestCisReadings.length === 0) {
			if (alignedTargetReadings.length > 0) {
				readingsToInterpolate.push(targetReading);
			} else {
				stats.target.countReadingsLostBefore += 1;
			}
			return;
		}

		const { index } = nearestCisReadings[0];
		if (alignedTargetReadings.length === 0) {
			stats.primary.countReadingsLostBefore = index;
			cisIndexesToFind = setCisReadingIndexesToFind(cisReadings, index);
		}

		stats.primary.countFoundBetween += 1;
		stats.target.countFoundBetween += 1;

		delete cisIndexesToFind[index];
		const cisReading = cisReadings[index];
		const { id } = cisReading;
		lastCisReadingIndex = index;

		const distance = computeReadingDistance(cisReading, targetReading, -1, {
			useSpatialDistance: true,
			algorithm
		});

		stats.distances.push(Math.round(distance));

		// Interpolate any readings in between
		if (readingsToInterpolate.length) {
			const interpolatedReadings = interpolateReadingsBetweenNormalizedReadings(
				readingsToInterpolate,
				alignedTargetReadings,
				id
			);
			interpolatedReadings.forEach(r => alignedTargetReadings.push(r));

			stats.target.countReadingsInterpolatedBetween +=
				readingsToInterpolate.length;

			// Reset interpolated readings
			readingsToInterpolate = [];
		}

		alignedTargetReadings.push({
			...targetReading,
			id,
			isNormalized: true
		});
	});

	const { indexesLostBetween, indexesLostAfter } = calculatePrimaryReadingsLost(
		cisIndexesToFind,
		lastCisReadingIndex
	);

	stats.primary.countReadingsLostBetween = indexesLostBetween;
	stats.primary.countReadingsLostAfter = indexesLostAfter;

	stats.target.countReadingsLostAfter = readingsToInterpolate.length;

	if (verbose) {
		// eslint-disable-next-line no-console
		console.log('target: ', stats.target);
		// eslint-disable-next-line no-console
		console.log('primary: ', stats.primary);
	}
	if (ensureAscendingIndexes) {
		alignedTargetReadings.sort((a, b) => a.id - b.id);
	}

	return { alignedTargetReadings, stats };
};
