import { helperCleanCustomGroupRanges } from '../../../Charts/slices/ChartsRemediations/util';

// PRIVATE
export const sortSurveyIds = surveyIds => {
	const orderings = ['CIS-ON_OFF', 'CIS-ON-OFF', 'CIS-ON'];
	const computeIdOrder = surveyId => {
		let order = 99999;
		orderings.forEach((key, idx) => {
			if (order === 99999 && surveyId.indexOf(key) > -1) {
				order = idx;
			}
		});
		return order;
	};
	return surveyIds.sort((a, b) => {
		const aOrder = computeIdOrder(a);
		const bOrder = computeIdOrder(b);
		if (aOrder < bOrder) {
			return -1; // sort a first
		}

		if (aOrder > bOrder) {
			return 1; // sort b first
		}

		return 0;
	});
};

export const cleanContent = content => {
	if (!content) {
		return content;
	}

	const result = { ...content };
	if (result.customGroups) {
		result.customGroups = helperCleanCustomGroupRanges(result.customGroups);
	}
	return result;
};

export default {
	cleanContent,
	sortSurveyIds
};
