export const initialState = {
	// hard boundary - driven by highest / lowest y values from data sets
	data: {},
	// soft boundary - driven by user zooming a chart
	zoom: {},
	// soft boundary - driven by user changing the y minmax values in the chart text input fields.
	input: {}
};

export default initialState;
