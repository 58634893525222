/* eslint-disable import/no-cycle */
import { createActions } from 'redux-actions';
import { LOAD_ERROR_MESSAGE } from './constants';
import { transformItems } from './transform';
import { listItems } from '../../api/CompletedSurveys';

import CS_FILTERS_SELECTORS from '../CompleteSurveysFilters/selectors'; // import { selectIsLoadingItems, selectIsAllItemsLoaded } from './selectors';
import { goodEnoughUUID } from '../../../../../scanline/utils/uuid';

const {
	clearItems,
	requestItems,
	receiveItems,
	setTotalCount,
	setLoadItemsErrorMessage,
	clearLoadItemsErrorMessage
} = createActions(
	{
		CLEAR_ITEMS: () => ({}),
		REQUEST_ITEMS: () => ({}),
		RECEIVE_ITEMS: items => ({ items }),
		SET_TOTAL_COUNT: totalCount => ({ totalCount }),
		SET_LOAD_ITEMS_ERROR_MESSAGE: message => ({ message }),
		CLEAR_LOAD_ITEMS_ERROR_MESSAGE: () => ({})
	},
	{ prefix: 'DataDeliveryReportItems' }
);

const refLoadRequest = { token: undefined, cnt: 0 };
const isValidToken = token => {
	const result = refLoadRequest.token === token;
	// console.log(`>>> IS VALID TOKEN -> ${result}`);
	// console.log(`>>> ${JSON.stringify(refLoadRequest)}`);
	return result;
};
const createToken = () => {
	// console.log('>>> CREATE TOKEN');
	const token = goodEnoughUUID();
	refLoadRequest.token = token;
	refLoadRequest.cnt += 1;
	// console.log(`>>> ${JSON.stringify(refLoadRequest)}`);
	return token;
};

const thunkLoadItems = () => (dispatch, getState) => {
	const token = createToken();

	setTimeout(() => {
		if (isValidToken(token)) {
			dispatch(clearItems());
			dispatch(requestItems());

			setTimeout(() => {
				if (isValidToken(token)) {
					const { DataDeliveryReport: state } = getState();

					const requestPayload = {
						filters: {
							customers: CS_FILTERS_SELECTORS.selectCustomerSelectedList(state)
						}
					};

					listItems(JSON.stringify(requestPayload))
						.then(httpResponse => {
							if (isValidToken(token)) {
								dispatch(receiveItems(transformItems(httpResponse)));
							}
						})
						.catch(() => {
							if (isValidToken(token)) {
								dispatch(setLoadItemsErrorMessage(LOAD_ERROR_MESSAGE));
							}
						});
				}
			}, 1);
		}
	}, 1);
};

export {
	thunkLoadItems,
	clearItems,
	requestItems,
	receiveItems,
	setTotalCount,
	setLoadItemsErrorMessage,
	clearLoadItemsErrorMessage
};
