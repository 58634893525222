/* eslint-disable import/prefer-default-export */

import { getDeltasByStructureName } from '../../../redux/Columns/utils';

export const convertHiddenColumnsToSettings = (
	managerSettings,
	userHiddenColumns,
	structureTypes
) => {
	return managerSettings.map(({ name, columns }) => {
		const {
			columns: hiddenColumns = [],
			isHidden,
			isOthers
		} = getDeltasByStructureName(name, userHiddenColumns.deltas);
		const settings = { name, columns: [], isHidden, isOthers };
		const { count = -1 } =
			structureTypes.find(({ item }) => item === name) || {};
		if (isHidden || count === 0) {
			return { ...settings, isHidden: true };
		}

		return {
			...settings,
			columns: [
				...columns
					.flatMap(col => col)
					.filter(({ name: colName, regEx }) => {
						if (colName === undefined) {
							return hiddenColumns.indexOf(regEx) === -1;
						}
						return hiddenColumns.indexOf(colName) === -1;
					})
					.map(
						({
							corTitle,
							name: colName,
							xlsTitle,
							corDefault = true,
							xlsDefault = true,
							regEx
						}) => ({
							corTitle,
							name: colName,
							xlsTitle,
							corDefault,
							xlsDefault,
							regEx
						})
					)
			]
		};
	});
};
