import React, { useMemo } from 'react';
import { Button, Modal } from 'react-bootstrap';

import { EMAIL_CUSTOMER_SUPPORT } from '../../../../../constants/emails';
import { createMailToHref } from './util';

const FallbackEmailModal = ({ showModal, handleClose }) => {
	const mailToLink = useMemo(() => createMailToHref(), []);
	return (
		<Modal show={showModal} onHide={handleClose}>
			<Modal.Header closeButton>
				<Modal.Title>Email Customer Support</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				<h4>Chat Is Blocked by Your Network</h4>
				<div>
					Please email us directly with any concerns at{' '}
					<a href={mailToLink}>{EMAIL_CUSTOMER_SUPPORT}</a>.
				</div>
			</Modal.Body>
			<Modal.Footer>
				<Button onClick={handleClose}>Close</Button>
			</Modal.Footer>
		</Modal>
	);
};

export default FallbackEmailModal;
