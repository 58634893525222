// eslint-disable-next-line import/no-cycle
import { createFiltersRedux } from '../../../../../commons/redux/dynamic/Filters';
import { ROOT_SLICE } from '../constants';

const filtersRedux = createFiltersRedux({
	namespace: 'data-delivery-report-filters',
	rootSliceKey: ROOT_SLICE,
	filtersStatePropKey: 'itemsFilters'
});

export default filtersRedux;
