import { hasAccessToken } from '../../../../../../utils/token';
import ajax, { createHandleResolveReject } from '../../../../../../utils/ajax';

export const makeTempS3FilePermanent = (key, callback) => {
	// @wbnote - this is in use
	if (hasAccessToken()) {
		const url = `/moveTempS3File?key=${encodeURIComponent(key)}`;

		const callMakeTempS3FilePermanent = () =>
			new Promise((resolve, reject) => {
				const handelResponse = createHandleResolveReject(resolve, reject);
				ajax(url, null, 'GET', handelResponse);
			});

		callMakeTempS3FilePermanent()
			.then(res => callback(null, res))
			.catch(err => callback(err));
	}
};

export const getProjectData = (jobId, callback) => {
	// @wbnote - is this in use @inuse?
	debugger;
	if (hasAccessToken()) {
		const url = `/getProjectData/${encodeURI(jobId)}`;

		const callGetProjectData = () =>
			new Promise((resolve, reject) => {
				const handelResponse = createHandleResolveReject(resolve, reject);
				ajax(url, null, 'GET', handelResponse);
			});

		callGetProjectData()
			.then(res => callback(null, res))
			.catch(err => callback(err));
	}
};

export const getExternalProjectData = (projectId, callback) => {
	// @wbnote - is this in use @inuse?
	debugger;
	if (hasAccessToken()) {
		const url = `/getExternalProjectData/${encodeURI(projectId)}`;

		const callGetExternalProjectData = () =>
			new Promise((resolve, reject) => {
				const handelResponse = createHandleResolveReject(resolve, reject);
				ajax(url, null, 'GET', handelResponse);
			});

		callGetExternalProjectData()
			.then(res => callback(null, res))
			.catch(err => callback(err));
	}
};

export const saveProjectData = (jobId, projectId, projectData, callback) => {
	// @wbnote - is this in use @inuse?
	debugger;
	if (hasAccessToken()) {
		const url = `/saveProjectData/`;
		const postData = JSON.stringify({ jobId, projectId, projectData });

		const callSaveProjectData = () => {
			return new Promise((resolve, reject) => {
				const handelResponse = createHandleResolveReject(resolve, reject);
				ajax(url, postData, 'POST', handelResponse);
			});
		};

		callSaveProjectData()
			.then(res => callback(null, res))
			.catch(err => callback(err));
	}
};

export const deleteMyProject = (projectId, callback) => {
	// @wbnote - this is in use
	if (hasAccessToken()) {
		const url = `/deleteMyProject/`;
		const postData = JSON.stringify({ projectId });

		const callDeleteMyProject = () => {
			return new Promise((resolve, reject) => {
				const handleResponse = createHandleResolveReject(resolve, reject);
				ajax(url, postData, 'POST', handleResponse);
			});
		};

		callDeleteMyProject()
			.then(res => callback(null, res))
			.catch(err => callback(err));
	}
};

export const deleteProjectData = (externalSurveyIds, callback) => {
	// @wbnote - this is in use
	if (hasAccessToken()) {
		const url = `/deleteExternalSurveys`;
		const postData = JSON.stringify({ externalSurveyIds });

		const callDeleteProjectData = () => {
			return new Promise((resolve, reject) => {
				const handleResponse = createHandleResolveReject(resolve, reject);
				ajax(url, postData, 'POST', handleResponse);
			});
		};

		callDeleteProjectData()
			.then(res => callback(null, res))
			.catch(err => callback(err));
	}
};

export const getSurveysFromUploadedFile = (key, callback) => {
	// @wbnote - is this in use @inuse?
	debugger;
	if (hasAccessToken()) {
		const url = `/parseDatFile?key=${encodeURI(key)}`;

		const callGetSurveysFromUploadedFile = () =>
			new Promise((resolve, reject) => {
				const handleResponse = createHandleResolveReject(resolve, reject);
				ajax(url, null, 'GET', handleResponse);
			});

		callGetSurveysFromUploadedFile()
			.then(res => callback(null, res))
			.catch(err => callback(err));
	}
};
