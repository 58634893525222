import {
	templates as templatesRequest,
	finalReport as finalReportRequest
} from './util/reports';

const NS_MAPS = 'CISVIEW_REPORTS_';

export const SET_TEMPLATES = `${NS_MAPS}SET_TEMPLATES`;

const setTemplates = templates => {
	return {
		type: SET_TEMPLATES,
		payload: templates
	};
};

export const SET_IS_DOWNLOADING = `${NS_MAPS}SET_IS_DOWNLOADING`;

const setIsDownloading = isDownloading => {
	return {
		type: SET_IS_DOWNLOADING,
		payload: isDownloading
	};
};

export const getTemplates = () => dispatch => {
	dispatch(setIsDownloading(true));
	templatesRequest()
		.then(res => {
			dispatch(setTemplates(res));
			dispatch(setIsDownloading(false));
		})
		.catch(() => {
			dispatch(setIsDownloading(false));
		});
};

export const downloadReport = (jobId, report) => dispatch => {
	dispatch(setIsDownloading(true));
	finalReportRequest(jobId, report)
		.then(({ url }) => {
			if (url) {
				window.open(url);
			}
			dispatch(setIsDownloading(false));
		})
		.catch(() => {
			dispatch(setIsDownloading(false));
		});
};
