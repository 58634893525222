export const initialState = {
	data: [],
	boolMap: {},
	matchTermMap: {},
	isLoading: false,
	isLoaded: false,
	errorMessage: undefined
};

export default initialState;
