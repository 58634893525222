import React, { Suspense } from 'react';

import { Route, Switch } from 'react-router-dom';
import Loading from '../../../cisview/oldWebShared/aip/loading';
import { rootPath as assetViewPath } from '../../../assetView/redux/routing/util/routepath';

import { isTokenValid } from '../../../util/user';

import { HeaderAssetView } from './LazyLoading';
import './HeaderRouter.scss';

const HeaderRouter = () => {
	if (isTokenValid()) {
		return (
			<div className="app-header">
				<Suspense fallback={<Loading title="Loading..." />}>
					<Switch>
						<Route path={assetViewPath} component={HeaderAssetView} />
					</Switch>
				</Suspense>
			</div>
		);
	}
	return <></>;
};

export default HeaderRouter;
