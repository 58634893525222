import {
	setDataXYMinMax,
	bulkSetDataXYMinMax,
	setZoomXYMinMax,
	setInputXYMinMax,
	clearInputXYMinMax,
	setStoreZoomXYMinMaxSlice
} from './actions';
import {
	thunkSetDataXYMinMax,
	thunkBulkSetDataXYMinMax,
	thunkSetDataXYMinMaxFromSurvey,
	thunkRecomputeAllDataXYMinsMaxes
} from './thunks.data.actions';
import {
	thunkSetZoomXYMinMax,
	thunkSetAllZoomXMinMax
} from './thunks.zoom.actions';
import { thunkSetInputYMinYMax } from './thunks.input.actions';

export {
	// DATA
	setDataXYMinMax,
	bulkSetDataXYMinMax,
	thunkSetDataXYMinMax,
	thunkBulkSetDataXYMinMax,
	thunkSetDataXYMinMaxFromSurvey,
	thunkRecomputeAllDataXYMinsMaxes,
	// ZOOM
	setZoomXYMinMax,
	clearInputXYMinMax,
	setStoreZoomXYMinMaxSlice,
	thunkSetZoomXYMinMax,
	thunkSetAllZoomXMinMax,
	// INPUT
	setInputXYMinMax,
	thunkSetInputYMinYMax
};

export default {
	// DATA
	setDataXYMinMax,
	bulkSetDataXYMinMax,
	thunkSetDataXYMinMax,
	thunkBulkSetDataXYMinMax,
	thunkSetDataXYMinMaxFromSurvey,
	thunkRecomputeAllDataXYMinsMaxes,

	// ZOOM
	setZoomXYMinMax,
	clearInputXYMinMax,
	setStoreZoomXYMinMaxSlice,
	thunkSetZoomXYMinMax,
	thunkSetAllZoomXMinMax,
	// INPUT
	setInputXYMinMax,
	thunkSetInputYMinYMax
};
