/* eslint-disable import/no-cycle */
/* eslint-disable camelcase */
import { addChartBelowCriterions, removeChartBelowCriterions } from './actions';
import { EMPTY_OBJECT } from './constants';
import { createInitialChartBelowCriterionsDatasets } from './util/main';

// eslint-disable-next-line no-unused-vars
const selectHelper = (state, chartId) => {
	const { chartsBelowCriterions = EMPTY_OBJECT } = state;

	const chartBelowCriterionsDatasets =
		chartsBelowCriterions[chartId] ||
		createInitialChartBelowCriterionsDatasets();

	return [chartsBelowCriterions, chartBelowCriterionsDatasets];
};

// ************
// PARTS
// ************
const reducerParts = {
	[addChartBelowCriterions]: (
		state,
		{
			payload: {
				chartId,
				datasets = createInitialChartBelowCriterionsDatasets()
			}
		}
	) => {
		const { chartsBelowCriterions } = state;
		return {
			...state,
			chartsBelowCriterions: {
				...chartsBelowCriterions,
				[chartId]: datasets
			}
		};
	},

	[removeChartBelowCriterions]: (state, { payload: { chartId } }) => {
		const { chartsBelowCriterions } = state;
		const newChartsBelowCriterions = { ...chartsBelowCriterions };
		delete newChartsBelowCriterions[chartId];
		return {
			...state,
			chartsBelowCriterions: newChartsBelowCriterions
		};
	}
};

export default reducerParts;
