import EditType from 'aegion_common_utilities/lib/ReadingsUtil/editing/EditType';
import { setGlobalData } from './job';
import {
	processEdits,
	save,
	_updateJobByPropertyChanges
} from './job.thunks.fileEdits';
import { _getNewDataFromChangedDatFileField } from './util/fileEdits';
import { clearAllCoordinatesAutoCorrection } from '../gps-auto-correction';
import { clearAutoCorrectedReadingsData } from '../readings-auto-correction';
import { getUserEdits } from '../../../components/TabPages/MagicSpikeEditor/utils/spike-editor';

const _getMlChangesSavedByType = type => {
	switch (type) {
		case 'coordinates':
			return 'isMLCoordinatesSaved';
		case 'readings':
			return 'isMLReadingsSaved';
		default:
			throw new Error(`Uknown ML Change type "${type}"`);
	}
};

export const setMlChangesSaved = (datFile, type = 'coordinates') => (
	dispatch,
	getState
) => {
	const property = _getMlChangesSavedByType(type);
	const propertyChanges = {
		[property]: true
	};
	const serverChanges = propertyChanges;

	return _updateJobByPropertyChanges(
		datFile,
		propertyChanges,
		serverChanges
	)(dispatch, getState);
};

const _setMlChangesSavedForCoordinates = datFiles => (dispatch, getState) => {
	const promises = datFiles.map(datFile => {
		return setMlChangesSaved(datFile, 'coordinates')(dispatch, getState);
	});

	return Promise.all(promises);
};

const _setMlChangesSavedForReadings = datFile => (dispatch, getState) => {
	return setMlChangesSaved(datFile, 'readings')(dispatch, getState);
};

export const saveGpsAutoCorrection = datFilesWithNewGpsReadings => (
	dispatch,
	getState
) => {
	const state = getState();
	const { cisview } = state;
	const { job } = cisview;
	const { globalData: originalGlobalData, data: originalData } = job;

	let globalData = originalGlobalData;
	let data = originalData;

	const datFiles = [];

	datFilesWithNewGpsReadings.forEach(({ datFile, newGpsReadings }) => {
		// For each datFile (with edits), we create a new immutable object
		const {
			globalData: newGlobalData,
			data: newData
		} = _getNewDataFromChangedDatFileField(
			datFile,
			getState,
			'gpsreadings',
			newGpsReadings,
			{
				globalData,
				data
			}
		);

		const newDatFile = newGlobalData.MasterLST[datFile.fileName];
		datFiles.push(newDatFile);

		globalData = newGlobalData;
		data = newData;
	});
	dispatch(setGlobalData(globalData, data));
	// TODO: Do not add spinner for save
	save({ data, globalData, setData: false, disableSuccessMessage: true })(
		dispatch,
		getState
	).then(() => {
		dispatch(clearAllCoordinatesAutoCorrection());
		return _setMlChangesSavedForCoordinates(datFiles)(dispatch, getState);
	});
};

function convertUserEdits(userEdits, newGpsReadings) {
	return userEdits.reduce((acc, { stationIds }) => {
		const readingEdits = stationIds.map(station => {
			const index = newGpsReadings.findIndex(
				({ StationId }) => StationId === station
			);
			const reading = {};
			const { reading1: on, reading2: off, acOn, acOff } = newGpsReadings[
				index
			];
			if (
				on !== undefined &&
				newGpsReadings[index].originalReading1 !== undefined
			) {
				const newOn = parseFloat(on, 10);
				reading.reading1 = newOn;
				reading.originalReading1 = newGpsReadings[index].originalReading1;
			}
			if (
				off !== undefined &&
				newGpsReadings[index].originalReading2 !== undefined
			) {
				const newOff = parseFloat(off, 10);
				reading.reading2 = newOff;
				reading.originalReading2 = newGpsReadings[index].originalReading2;
			}
			if (
				acOn !== undefined &&
				newGpsReadings[index].originalAcOn !== undefined
			) {
				const newAcOn = parseFloat(acOn, 10);
				reading.acOn = newAcOn;
				reading.originalAcOn = newGpsReadings[index].originalAcOn;
			}
			if (
				acOff !== undefined &&
				newGpsReadings[index].originalAcOff !== undefined
			) {
				const newAcOff = parseFloat(acOff, 10);
				reading.acOff = newAcOff;
				reading.originalAcOff = newGpsReadings[index].originalAcOff;
			}

			return {
				type: EditType.READINGS,
				index,
				reading
			};
		});
		return [...acc, ...readingEdits];
	}, []);
}

export const saveReadingAutoCorrection = (datFile, newGpsReadings) => (
	dispatch,
	getState
) => {
	const {
		cisview: {
			readingsAutoCorrection: { spikeEditorUserChangesByFile }
		}
	} = getState();

	const userEdits = getUserEdits(datFile, spikeEditorUserChangesByFile);

	// For each datFile (with edits), we create a new immutable object
	const { globalData, data } = _getNewDataFromChangedDatFileField(
		datFile,
		getState,
		'gpsreadings',
		newGpsReadings
	);

	const newDatFile = globalData.MasterLST[datFile.fileName];

	dispatch(setGlobalData(globalData, data));

	const editsObj = convertUserEdits(userEdits, newGpsReadings);

	const edits = [
		{
			name: datFile.fileName,
			key: datFile.fileKey,
			edits: editsObj
		}
	];

	dispatch(processEdits(edits)).then(() => {
		dispatch(clearAutoCorrectedReadingsData(datFile));
		return _setMlChangesSavedForReadings(newDatFile)(dispatch, getState);
	});
};
