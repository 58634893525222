/* eslint-disable import/no-cycle */
/* eslint-disable camelcase */
import { ROOT_SLICE } from './constants';
import {
	setDataWrapper,
	setDataWrappers,
	deleteDataWrapper,
	deleteAllDataWrappers,
	clearStore
} from './actions';
import { selectRootSlice } from './selectors';
import {
	writeDataWrapper as utilWriteDataWrapper,
	deleteDataWrapper as utilDeleteDataWrapper,
	deleteAllDataWrappers as utilAllDeleteDataWrappers
} from './util/rwdDataWrapper';
import { initialState } from './initialState';

// ************
// PARTS
// ************
const reducerParts = {
	[clearStore]: state => {
		return { ...state, [ROOT_SLICE]: { ...initialState } };
	},

	[setDataWrappers]: (state, { payload: { dataWrappers = [] } }) => {
		const slice = selectRootSlice(state);
		const newDataWrapperStore = dataWrappers.reduce(
			(acc, { targetKey, parentKey, modifierKey, dataWrapper }) => {
				return utilWriteDataWrapper(acc, {
					targetKey,
					parentKey,
					modifierKey,
					dataWrapper
				});
			},
			{ ...(slice.data || {}) }
		);
		const res = {
			...state,
			[ROOT_SLICE]: {
				...slice,
				data: newDataWrapperStore
			}
		};
		return res;
	},

	[deleteDataWrapper]: (
		state,
		{ payload: { targetKey, parentKey, modifierKey } }
	) => {
		const slice = selectRootSlice(state);
		return {
			...state,
			[ROOT_SLICE]: {
				...slice,
				data: utilDeleteDataWrapper(slice.data, {
					targetKey,
					parentKey,
					modifierKey
				})
			}
		};
	},

	[setDataWrapper]: (
		state,
		{ payload: { targetKey, parentKey, modifierKey, dataWrapper } }
	) => {
		const slice = selectRootSlice(state);
		return {
			...state,
			[ROOT_SLICE]: {
				...slice,
				data: utilWriteDataWrapper(slice.data, {
					targetKey,
					parentKey,
					modifierKey,
					dataWrapper
				})
			}
		};
	},
	[deleteAllDataWrappers]: (state, { payload: { targetKey } }) => {
		const slice = selectRootSlice(state);
		return {
			...state,
			[ROOT_SLICE]: {
				...slice,
				data: utilAllDeleteDataWrappers(slice.data, { targetKey })
			}
		};
	}
};

export default reducerParts;
