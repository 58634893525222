// eslint-disable-next-line import/no-cycle
import ajax from '../../../../scanline/utils/ajax';

const MapsUtil = {
	vectorTile(app, layer, coords, callback) {
		ajax(
			'/vectorTile',
			JSON.stringify({ app, layer, coords }),
			'POST',
			callback,
			false,
			'maps'
		);
	},
	imageTile(app, layer, coords, callback) {
		ajax(
			'/imageTile',
			JSON.stringify({ app, layer, coords }),
			'POST',
			callback,
			false,
			'maps'
		);
	},
	access(app, callback) {
		ajax('/access', JSON.stringify({ app }), 'POST', callback, false, 'maps');
	},
	image(app, layer, coords, size, selected, searches, callback) {
		ajax(
			'/image',
			JSON.stringify({ app, layer, coords, size, selected, searches }),
			'POST',
			callback,
			false,
			'maps'
		);
	},
	markermap(x, y, height = 300, width = 300) {
		return ajax(
			'/markermap',
			JSON.stringify({ x, y, height, width }),
			'POST',
			undefined,
			false,
			'maps'
		);
	}
};

export default MapsUtil;
