/* eslint-disable consistent-return */
/* eslint-disable import/no-cycle */
/* eslint-disable import/prefer-default-export */
import { store } from '../../scanline/store';
import { createPromiseRequest } from './util/createPromiseRequest';
import { hasAccessToken } from '../../scanline/utils/token';
import { selectRootSliceAdmin } from '../redux/Main/selectors';
import { selectCompanyName } from '../redux/slices/UsersSearch/selectors';
import { goodEnoughUUID } from '../../scanline/utils/uuid';

let tokenGetUser;
export const getUsers = (params, callback) => {
	const stateAdmin = selectRootSliceAdmin(store.getState());
	const customer = selectCompanyName(stateAdmin);
	tokenGetUser = goodEnoughUUID();
	const url = `/users?customer=${customer}&withPermissions=true&requestToken=${encodeURIComponent(
		tokenGetUser
	)}`;

	if (hasAccessToken()) {
		const fetchUsers = createPromiseRequest({
			url,
			method: 'GET'
		});

		fetchUsers()
			.then(res => {
				const { requestToken: responseToken, users } = res;
				if (tokenGetUser === responseToken) {
					callback(null, users);
				}
			})
			.catch(err => {
				callback(err);
			});
	}
};

let tokenSendInvite;
export const sendInvite = ({ email } = {}, callback = () => {}) => {
	if (tokenSendInvite) {
		callback(new Error('Invite request already in progress'));
		return undefined;
	}

	const stateAdmin = selectRootSliceAdmin(store.getState());
	const company = selectCompanyName(stateAdmin);
	tokenSendInvite = goodEnoughUUID();
	const url = '/user/insert';
	const payload = {
		email: (email || '').trim().toLowerCase(),
		company
	};

	const postUserInvite = createPromiseRequest({
		url,
		method: 'POST',
		data: payload
	});

	postUserInvite()
		.then(res => {
			tokenSendInvite = undefined;
			callback(null, res);
		})
		.catch(err => {
			tokenSendInvite = undefined;
			callback(err);
		});
};

let tokenUpdateUser;
export const updateUser = ({ user } = {}, callback = () => {}) => {
	if (tokenUpdateUser) {
		callback(new Error('Update request already in progress'));
		return undefined;
	}

	if (!user || !user.username) {
		callback(
			new Error('Invalid user object passed to api/users .updateUser()')
		);
		return undefined;
	}

	const postUserInvite = createPromiseRequest({
		url: '/user/update',
		method: 'PUT',
		data: user
	});

	postUserInvite()
		.then(updatedUser => {
			tokenUpdateUser = undefined;
			callback(null, updatedUser);
		})
		.catch(err => {
			tokenUpdateUser = undefined;
			callback(err);
		});
};

let tokenDeleteUser;
export const deleteUser = ({ user } = {}, callback = () => {}) => {
	if (tokenDeleteUser) {
		callback(new Error('Invite request already in progress'));
		return undefined;
	}

	if (!user || !user.username) {
		callback(
			new Error('Invalid user object passed to api/users .deleteUser()')
		);
		return undefined;
	}

	const postDeleteUser = createPromiseRequest({
		url: '/user/delete',
		method: 'PUT',
		data: user
	});

	postDeleteUser()
		.then(result => {
			tokenDeleteUser = undefined;
			callback(null, result);
		})
		.catch(err => {
			tokenDeleteUser = undefined;
			callback(err);
		});
};

let tokenResetPassword;
export const resetPassword = ({ user } = {}, callback = () => {}) => {
	if (tokenResetPassword) {
		callback(new Error('Resetpassword request already in progress'));
		return undefined;
	}

	if (!user || !user.username) {
		callback(
			new Error('Invalid user object passed to api/users .resetPassword()')
		);
		return undefined;
	}

	const postResetpassword = createPromiseRequest({
		url: '/user/resetpassword',
		method: 'PUT',
		data: user
	});

	postResetpassword()
		.then(updatedUser => {
			tokenResetPassword = undefined;
			callback(null, updatedUser);
		})
		.catch(err => {
			tokenResetPassword = undefined;
			callback(err);
		});
};

export const listPsqlUsersV2 = userids => {
	const url = '/users/rel/check-users';

	if (hasAccessToken()) {
		const fetch = createPromiseRequest({
			url,
			method: 'POST',
			data: { userids }
		});

		return fetch();
	}
	return Promise.reject(new Error('Missing token'));
};
