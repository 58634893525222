import {
	SET_ASSET,
	SET_ASSET_DATA,
	HIGHLIGHT,
	CHECK_CHANGED,
	SET_PARAMS,
	SET_PARAM_DATA,
	RESET_SELECTED
} from './actionTypes';
import {
	setData,
	checkChanged,
	getParams,
	highlight,
	resetSelected
} from './utils';

const initialState = {
	asset: {
		template: null,
		name: null,
		data: {},
		selected: {}
	},
	group: {
		template: null,
		name: null,
		data: {},
		selected: {}
	},
	params: {}
};

const reducer = (state = initialState, action) => {
	const { type, payload } = action;
	switch (type) {
		case SET_PARAMS:
		case SET_PARAM_DATA:
			return { ...state, params: { ...getParams(state.params, payload) } };
		case SET_ASSET:
			return { ...state, asset: { ...state.asset, ...payload } };
		case SET_ASSET_DATA:
			return {
				...state,
				asset: {
					...state.asset,
					data: setData(state.asset, payload),
					selected: payload
				}
			};
		case HIGHLIGHT:
			return { ...state, params: { ...highlight(state.params, payload) } };
		case CHECK_CHANGED:
			return { ...state, params: { ...checkChanged(state.params, payload) } };
		case RESET_SELECTED:
			return { ...state, params: { ...resetSelected(state.params, payload) } };
		default:
			return state;
	}
};

export default reducer;
