"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
Object.defineProperty(exports, "EditType", {
  enumerable: true,
  get: function get() {
    return _EditType["default"];
  }
});
exports["default"] = void 0;

var _EditType = _interopRequireDefault(require("./EditType"));

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    "default": obj
  };
}

var Editing = {
  EditType: _EditType["default"]
};
var _default = Editing;
exports["default"] = _default;