/* eslint-disable import/no-named-as-default-member */
import _ from 'lodash';
import { createSortText } from 'aegion_common_utilities/lib/Sort';

import DDB_CUSTOMERS from '../../Sync/DdbCustomers/selectors';

import PSQL_APPLICATIONS from '../../Sync/PsqlCustomersApplications/selectors';

import DDB_ENTITIES from '../../Sync/DdbCustomersEntities/selectors';
import PSQL_ENTITIES from '../../Sync/PsqlCustomersEntities/selectors';

import DDB_SETTINGS from '../../Sync/DdbCustomersSettings/selectors';
import { goodEnoughUUID } from '../../../../../scanline/utils/uuid';
import { cleanName } from '../../../../api/util/customer';

const createApplicationsMap = (stateAdmin, customerName) => {
	const psqlAppMap = PSQL_APPLICATIONS.selectBoolMapByCustomer(stateAdmin, {
		customer: customerName
	});

	return { ...psqlAppMap };
};

const createEntityObject = ({ name = '', active = true, isNew } = {}) => {
	const result = {
		clientId: goodEnoughUUID(),
		name,
		newName: undefined,
		active
	};
	if (isNew) {
		result.isNew = true;
	}
	return result;
};

const sortEntities = createSortText('ASC', {
	caseSensitive: false
});

const createEntitiesArray = (stateAdmin, customerName) => {
	const ddbEntitiesMap = DDB_ENTITIES.selectBoolMapByCustomer(stateAdmin, {
		customer: customerName
	});
	const psqlEntitiesMap = PSQL_ENTITIES.selectBoolMapByCustomer(stateAdmin, {
		customer: customerName
	});

	const entitiesMap = { ...ddbEntitiesMap, ...psqlEntitiesMap };
	const keys = Object.keys(entitiesMap).sort(sortEntities);
	return keys.reduce((acc, k) => {
		acc.push(
			createEntityObject({
				name: k,
				active: !!entitiesMap[k]
			})
		);
		return acc;
	}, []);
};

const createSettingsMap = (stateAdmin, customerName) => {
	const settings = DDB_SETTINGS.selectDataByCustomer(stateAdmin, {
		customer: customerName
	});

	return _.cloneDeep(settings);
};

/* eslint-disable import/prefer-default-export */
const createCustomerObject = (stateAdmin, customerName) => {
	return {
		name: customerName,
		domain: DDB_CUSTOMERS.selectDomain(stateAdmin, { customer: customerName }),
		applications: createApplicationsMap(stateAdmin, customerName),
		entities: createEntitiesArray(stateAdmin, customerName),
		settings: createSettingsMap(stateAdmin, customerName)
	};
};

const overWriteAssetViewSettings = (
	selectedCustomerChangesClone,
	defaultMatchTerm,
	{ ignoreEnabled = false } = {}
) => {
	const obj = selectedCustomerChangesClone;
	const ASSETVIEW = 'ASSETVIEW®';
	const avAppkeys = ['ASSETVIEW', 'ASSETVIEW®'];
	avAppkeys.forEach(appKey => {
		obj.settings[appKey] = obj.settings[appKey] || {};
		obj.settings[appKey].companyName = cleanName(obj.name);
		if (!ignoreEnabled) {
			obj.settings[appKey].enabled = !!obj.applications[ASSETVIEW];
		}
		if (!obj.settings[appKey].elasticsearchKeyMatcher) {
			if (defaultMatchTerm) {
				obj.settings[appKey].elasticsearchKeyMatcher = defaultMatchTerm;
			} else {
				obj.settings[appKey].elasticsearchKeyMatcher = cleanName(obj.name);
			}
		}
	});
};

const enableAssetViewSettings = (selectedCustomerChangesClone, enable) => {
	const obj = selectedCustomerChangesClone;
	const avAppkeys = ['ASSETVIEW', 'ASSETVIEW®'];
	avAppkeys.forEach(appKey => {
		obj.settings[appKey].enabled = !!enable;
	});
};

export {
	createEntityObject,
	createCustomerObject,
	overWriteAssetViewSettings,
	enableAssetViewSettings
};
