import { ROOT_SLICE } from './constants';

/* eslint-disable no-use-before-define */
export const selectStateSlice = state => {
	const { app } = state;
	return app;
};

const isDefined = v => v !== undefined && v !== null;

const isEmptyString = v => typeof v === 'string' && v.trim().length === 0;

export const selectActionPlanMessageSlice = slice => {
	const actionPlanSync = (slice || {})[ROOT_SLICE];
	return actionPlanSync;
};

export const selectActionPlanMessage = slice => {
	const { message } = selectActionPlanMessageSlice(slice);
	return message;
};

export const selectHasActionPlanMessage = slice => {
	const message = selectActionPlanMessage(slice);
	return isDefined(message) && !isEmptyString(message);
};

export const selectIsNotificationExpanded = slice => {
	const { isNotificationExpanded } = selectActionPlanMessageSlice(slice);
	return isNotificationExpanded;
};
