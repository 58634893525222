import { NAME_SPACE as ns } from './constants';

export const SET_VIEWPORT = `${ns} SET_VIEWPORT`;
export const SET_EXTENT = `${ns} SET_EXTENT`;
export const SET_ASSET = `${ns} SET_ASSET`;
export const SET_PIN = `${ns} SET_PIN`;
export const SET_CLUSTER = `${ns} SET_CLUSTER`;
export const SET_MAP = `${ns} SET_MAP`;
export const SET_IS_GRID_ENABLED = `${ns} SET_IS_GRID_ENABLED`;
export const SET_IS_APPROVAL = `${ns} SET_IS_APPROVAL`;
export const SET_WIDTH = `${ns} SET_WIDTH`;
export const SET_GROUP_LAYER_UPDATING = `${ns} SET_GROUP_LAYER_UPDATING`;
export const SET_ASSET_LAYER_UPDATING = `${ns} SET_ASSET_LAYER_UPDATING`;
export const SET_HEATMAP_LAYER_UPDATING = `${ns} SET_HEATMAP_LAYER_UPDATING`;
