/* eslint-disable import/prefer-default-export */
/* eslint-disable import/no-cycle */
import { createActions } from 'redux-actions';
import { ROOT_SLICE } from '../constants';

const {
	setCompanies,
	setIsLoadingLoadedCompanies,
	setHasLoadingErrorCompanies
} = createActions(
	{
		SET_COMPANIES: companies => ({ companies }),
		SET_IS_LOADING_LOADED_COMPANIES: ({
			isLoadingCompanies,
			isLoadedCompanies
		}) => ({ isLoadingCompanies, isLoadedCompanies }),
		SET_HAS_LOADING_ERROR_COMPANIES: hasError => ({ hasError })
	},
	{ prefix: ROOT_SLICE }
);

export {
	setCompanies,
	setIsLoadingLoadedCompanies,
	setHasLoadingErrorCompanies
};
