export const createCustomGroupsFromCustomGroupRanges = (
	customGroupRanges,
	readings
) =>
	(customGroupRanges || []).map(group => {
		const sanitizedGroup = [];
		const { 0: first, [group.length - 1]: last } = group;
		const isValidGroup = first && last; // for some reason its possible for invalid groups to get stored, need to understand why

		if (isValidGroup) {
			// Now that we have the range of ids used (firstId and lastId), let's get the entire set of readings in this range
			for (let i = 0, n = readings.length; i < n; i += 1) {
				const reading = readings[i];
				if (reading.id >= first.id && reading.id <= last.id) {
					sanitizedGroup.push(reading);
				}
				// assuming we are incrementing values we can break at this point
				if (reading.id > last.id) {
					break;
				}
			}
		}

		return sanitizedGroup;
	});

export const createInitialChartRemediationState = () => ({
	// Below Criterions
	computedBcMap: {}, // a map impacted by compbination of BC & Custom Selected remediation readings

	// CUSTOM GROUPS - tracks custom selected data points chosen by user - ignorant of bc calcualtions
	customGroupRanges: [], // custom groups as selected by user, not yet applied to bc

	// CUSTOM EXCEPTIONS - tracks custom selected values which have had computed bc values removed
	customExceptions: [],
	customExceptionsMap: {},
	customExceptionsWithGaps: [],
	// customExceptionsWithGapsMap: {}, // @todo ?

	// REMEDIATION GROUPS & READINGS - tracks the combined groups for Custom Exceptions & Computed BC Readings
	remediationGroupRanges: [], // remediation groups computed on the combination of below criterion groups and custom group ranges as selected by user, not yet applied to bc
	remediationReadings: [] // hmmm - what to do with this one.... not quite sure yet - received from deriveNewDataSourcesFromNewExceptionsGroup... yada yada
});

const EMPTY_ARRAY = [];

// @note - customExceptions should be an array of readings.
export const helperCleanCustomExceptions = (customExceptions = EMPTY_ARRAY) => {
	const cleaned = customExceptions.filter(o => {
		if (o instanceof Array) {
			return false;
		}
		return !!o;
	});

	// to protect against unnecessary rerenders, let's not return a new array unless we absolutely have to.
	if (cleaned.length !== customExceptions) {
		return cleaned;
	}

	return customExceptions;
};

// @note - customGroupRanges should be an array of arrays. each item array should have length 2.  each item array should consist of a start & end reading.
export const helperCleanCustomGroupRanges = (
	customGroupRanges = EMPTY_ARRAY
) => {
	const cleaned = customGroupRanges.filter(customGroupRange => {
		if (!(customGroupRange instanceof Array)) {
			return false;
		}
		const [r1, r2] = customGroupRange;
		return r1 && r2;
	});

	// to protect against unnecessary rerenders, let's not return a new array unless we absolutely have to.
	if (cleaned.length !== customGroupRanges) {
		return cleaned;
	}

	return customGroupRanges;
};

export default {
	createInitialChartRemediationState,
	createCustomGroupsFromCustomGroupRanges,
	helperCleanCustomExceptions,
	helperCleanCustomGroupRanges
};
