import { ROOT_SLICE } from './constants';

export const selectRootSlice = stateAdmin => stateAdmin[ROOT_SLICE];

export const selectBoolMap = stateAdmin => {
	const slice = selectRootSlice(stateAdmin);
	const { boolMap } = slice;
	return boolMap;
};

export const selectIsLoading = stateAdmin => {
	const slice = selectRootSlice(stateAdmin);
	const { isLoading } = slice;
	return isLoading;
};

export const selectIsLoaded = stateAdmin => {
	const slice = selectRootSlice(stateAdmin);
	const { isLoaded } = slice;
	return isLoaded;
};

export const selectErrorMessage = stateAdmin => {
	const slice = selectRootSlice(stateAdmin);
	const { errorMessage } = slice;
	return errorMessage;
};

export default {
	selectBoolMap,
	selectIsLoading,
	selectIsLoaded,
	selectErrorMessage
};
