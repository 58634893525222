/* eslint-disable import/no-cycle */
import { ROOT_SLICE } from './constants';
import { selectRootSlice } from './selectors';
import { setDevFeaturesEnabled } from './actions';

// ************
// PARTS
// ************
const reducerParts = {
	[setDevFeaturesEnabled]: (state, { payload: { enable } }) => {
		const rootSlice = selectRootSlice(state);
		return {
			...state,
			[ROOT_SLICE]: { ...rootSlice, devFeaturesEnabled: enable }
		};
	}
};

export default reducerParts;
