const DEFAULT_EMAILS = ['aip@corrpro.com'];

const EMAIL_MAP = {
	AIM: DEFAULT_EMAILS,
	CISVIEW: DEFAULT_EMAILS,
	DIT: DEFAULT_EMAILS,
	SCANLINE: DEFAULT_EMAILS
};

export const getInAppChatToEmails = (appName = '') => {
	const key = appName.toUpperCase();
	return EMAIL_MAP[key] || DEFAULT_EMAILS;
};

export default {
	getInAppChatToEmails
};
