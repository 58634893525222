import { TYPE_KEY_COMPANY, TYPE_KEY_USER } from './constants';

export const initialState = {
	saveErrors: {
		[TYPE_KEY_COMPANY]: {},
		[TYPE_KEY_USER]: {}
	}
};

export default initialState;
