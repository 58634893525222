/* eslint import/no-cycle:0 */
import {
	readingsActions,
} from '../../../redux/actions';

const actionCreators = {
	sortReadings: readingsActions.updateReadingsSort,
}

class cisViewActions {

	SET_SORT_VIEW = `CIS_VIEW SET_SORT_VIEW`;
	SET_SORT_DIRECTION = `CIS_VIEW SET_SORT_DIRECTION`;
	SET_TEST_STATION = `CIS_VIEW SET_TEST_STATION`;
	
	setSortView = (sortView, sortDirection, isTestStation) => (dispatch) => {
		dispatch({
			type: this.SET_SORT_VIEW,
			payload: sortView
		});

		dispatch({
			type: this.SET_SORT_DIRECTION,
			payload: sortDirection
		});

		dispatch({
			type: this.SET_TEST_STATION,
			payload: isTestStation
		});

		dispatch(actionCreators.sortReadings());
	};
}
export default new cisViewActions();
