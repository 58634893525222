export const setGpsAutoCorrectionNewResponse = datFilePostProcessResults => ({
	type: 'GPS_AUTO_CORRECTION_SET_NEW_RESPONSE',
	datFilePostProcessResults
});

export const clearAutoCorrectionByFile = datFile => ({
	type: 'GPS_AUTO_CORRECTION_RECEIVE_CLEAR_BY_FILE',
	datFile
});

export const enableDrawAutoCorrectionShape = () => ({
	type: 'GPS_AUTO_CORRECTION_ENABLE_DRAW_CLEAR_SHAPE'
});

export const disableDrawAutoCorrectionShape = () => ({
	type: 'GPS_AUTO_CORRECTION_DISABLE_DRAW_CLEAR_SHAPE'
});

export const addGpsAutoCorrectionUserActions = correctionsByFileName => ({
	type: 'GPS_AUTO_CORRECTION_ADD_USER_ACTION',
	correctionsByFileName
});

export const hoverOverPointOnMap = reading => ({
	type: 'GPS_AUTO_CORRECTION_HOVER_OVER_POINT_ON_MAP',
	reading
});

export const blurOverPointOnMap = reading => ({
	type: 'GPS_AUTO_CORRECTION_BLUR_OVER_POINT_ON_MAP',
	reading
});

export const clearAllCoordinatesAutoCorrection = () => ({
	type: 'GPS_AUTO_CORRECTION_CLEAR_ALL'
});

export const setGpsAutoCorrectionStarted = datFiles => ({
	type: 'GPS_AUTO_CORRECTION_API_START',
	datFiles
});

export const setGpsAutoCorrectionFinishedWithoutResults = () => ({
	type: 'GPS_AUTO_CORRECTION_API_FINISHED_WITHOUT_RESULTS'
});

export const revertGpsAutoCorrectionUserAction = datFileName => ({
	type: 'GPS_AUTO_CORRECTION_REVERT_USER_ACTIONS',
	datFileName
});

export const setGpsAutoCorrectionApiFinished = datFileName => ({
	type: 'GPS_AUTO_CORRECTION_API_FINISHED',
	datFileName
});

export const updateCoordinateLedgerByFileName = (
	datFileName,
	coordinateLedger
) => ({
	type: 'GPS_AUTO_CORRECTION_UPDATE_LEDGER',
	datFileName,
	coordinateLedger
});

export const onNewGpsData = datFiles => ({
	type: 'GPS_AUTO_CORRECTION_REFRESH_GPS_DATA',
	datFiles
});
