export const EMPTY_OBJECT = {};

export const EMPTY_ARRAY = [];

export const LAMBDA = () => {};

export default {
	EMPTY_OBJECT,
	EMPTY_ARRAY,
	LAMBDA
};
