/* eslint-disable import/no-named-as-default-member */
/* eslint-disable import/no-cycle */
/* eslint-disable import/prefer-default-export */
import { createSelector } from 'reselect';
import { ROOT_SLICE } from './constants';
import {
	selectCustomers as customersSelectCustomers,
	selectCombinedEntitiesData,
	selectIsOutOfSync
} from '../CustomersV2/selectors';
import PSQL_APPLICATIONS from '../Sync/PsqlCustomersApplications/selectors';
import DDB_CUSTOMERS from '../Sync/DdbCustomers/selectors';
import { store } from '../../../../scanline/store';

export const selectRootSlice = stateAdmin => stateAdmin[ROOT_SLICE];

export const selectSearchText = stateAdmin => {
	const slice = selectRootSlice(stateAdmin);
	const { searchText } = slice;
	return searchText;
};

export const selectCustomers = createSelector(
	customersSelectCustomers,
	selectSearchText,
	PSQL_APPLICATIONS.selectData,
	// eslint-disable-next-line no-unused-vars
	(customers = [], paramSearchText = '', _psqlApplicationsData = []) => {
		const searchText = paramSearchText.toLowerCase().trim();
		if (!paramSearchText) {
			return customers;
		}

		const stateAdmin = store.getState().Admin;

		return customers.filter((c = '') => {
			const name = c.toLowerCase();
			const applications = (
				PSQL_APPLICATIONS.selectDataByCustomer(stateAdmin, {
					customer: c
				}) || []
			).map(a => a.toLowerCase());
			const isOutOfSync = selectIsOutOfSync(stateAdmin, { customer: c });

			const matcherParts = [name, ...applications];
			if (isOutOfSync) {
				matcherParts.push('outofsync');
			}

			if (applications.length === 0) {
				matcherParts.push('missingapplications');
			}

			const matchers = matcherParts.join(' ');

			const matches = matchers.indexOf(searchText) > -1;

			return matches;
		});
	}
);

export const selectCustomersForDownload = createSelector(
	selectCustomers,
	DDB_CUSTOMERS.selectDomainMap,
	PSQL_APPLICATIONS.selectData,
	selectCombinedEntitiesData,
	(customers = [], domainMap = {}, applicationsMap = {}, entities = {}) => {
		return customers.map(c => ({
			name: c,
			domain: domainMap[c],
			applications: (applicationsMap[c] || []).join(', '),
			entities: (entities[c] || []).join(', '),
			isOutOfSync:
				selectIsOutOfSync(store.getState().Admin, { customer: c }) || undefined
		}));
	}
);
