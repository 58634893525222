/* eslint-disable import/no-cycle */
/* eslint-disable camelcase */
import {
	receiveAppState,
	setAppStateFromChartSettings,
	setIsSavingAppState
} from './actions';

// ************
// PARTS
// ************
const reducerParts = {
	[receiveAppState]: (state, { payload: { appState } }) => {
		return {
			...state,
			appStateV2: appState,
			isAppStateV2Loaded: true
		};
	},

	[setAppStateFromChartSettings]: (state, { payload: { appState } }) => {
		return {
			...state,
			appStateV2: appState,
			isAppStateV2Loaded: true
		};
	},

	[setIsSavingAppState]: (state, { payload: { isSavingAppState } }) => {
		return {
			...state,
			isSavingAppStateV2: isSavingAppState
		};
	}
};

export default reducerParts;
