import { lazy } from 'react';

export const DevAppSelect = lazy(() =>
	import(
		/* webpackChunkName: "DevAppSelect" */ '../../../scanline/components/_devtools/DevAppSelect'
	)
);
export const AppAccount = lazy(() =>
	import(/* webpackChunkName: "AppAccount" */ '../../../account')
);

export const AppScanline = lazy(() =>
	import(/* webpackChunkName: "AppScanline" */ '../../../scanline')
);
export const AppCISView = lazy(() =>
	import(/* webpackChunkName: "AppCISView" */ '../../../cisview')
);

export const AppAssetView = lazy(() =>
	import(/* webpackChunkName: "AppAssetView" */ '../../../assetView')
);

export const HeaderAssetView = lazy(() =>
	import(
		/* webpackChunkName: "HeaderAssetView" */ '../../../assetView/components/Header'
	)
);

export const AppFieldLine = lazy(() =>
	import(/* webpackChunkName: "AppFieldLine" */ '../../../fieldline')
);

export const DataIntegrity = lazy(() =>
	import(/* webpackChunkName: "AppDataIntegrity" */ '../../../dataintegrity')
);

export const AppChargeLine = lazy(() =>
	import(/* webpackChunkName:"AppChargeLine" */ '../../../chargeline')
);
export const AppLiveLine = lazy(() =>
	import(/* webpackChunkName:"AppLiveLine" */ '../../../liveline')
);

export const AppVideoLine = lazy(() =>
	import(/* webpackChunkName:"AppVideoLine" */ '../../../videoline')
);

export const AppTemplateBuilder = lazy(() =>
	import(/* webpackChunkName: "TemplateBuilder" */ '../../../templateBuilder')
);

export const AppSettings = lazy(() =>
	import(/* webpackChunkName: "Applications" */ '../../../settings')
);

export const Applications = lazy(() =>
	import(/* webpackChunkName: "Applications" */ '../Applications')
);

export const WebsiteBridge = lazy(() =>
	import(/* webpackChunkName: "Website" */ '../../../websiteBridge')
);

export const DashboardsAndReports = lazy(() =>
	import(
		/* webpackChunkName: "DashboardsAndReports" */ '../../../dashboardsAndReports'
	)
);
