import { ROOT_SLICE } from './constants';

export const selectRootSlice = stateAdmin => stateAdmin[ROOT_SLICE];

export const selectSelectedEntity = stateAdmin => {
	const slice = selectRootSlice(stateAdmin);
	const { selectedEntity } = slice;
	return selectedEntity;
};

export const selectSelectedEntityChangesClone = stateAdmin => {
	const slice = selectRootSlice(stateAdmin);
	const { selectedEntityChangesClone } = slice;
	return selectedEntityChangesClone;
};
