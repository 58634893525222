/* eslint-disable import/prefer-default-export */
import { createActions } from 'redux-actions';

const { setTimeOnPageMeta } = createActions(
	{
		SET_TIME_ON_PAGE_META: obj => ({ obj })
	},
	{
		prefix: 'TimeOnPageMeta'
	}
);

export { setTimeOnPageMeta };
