/* eslint-disable import/no-cycle */
import { handleActions } from 'redux-actions';

// MAIN
import surveyNoReadingsReducerParts from './main/reducerParts';
import { initialState as surveyNoReadingsInitialState } from './main/initialState';

// FILTERS
import jobLineFiltersReducerParts from './filters/reducerParts';
import jobLineFiltersInitialState from './filters/initialState';

const initialState = {
	...surveyNoReadingsInitialState,
	...jobLineFiltersInitialState
};

const reducerParts = {
	...surveyNoReadingsReducerParts,
	...jobLineFiltersReducerParts
};

export default handleActions(reducerParts, initialState);
