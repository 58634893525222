"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
Object.defineProperty(exports, "REGEX_CIS_COMMENTS", {
  enumerable: true,
  get: function get() {
    return _Comments["default"];
  }
});
exports["default"] = void 0;

var _Comments = _interopRequireDefault(require("./CIS/Comments"));

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    "default": obj
  };
}

var _default = {
  REGEX_CIS_COMMENTS: _Comments["default"]
};
exports["default"] = _default;