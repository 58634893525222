/* eslint import/no-cycle: [2, { maxDepth: 1 }] */
import AutoCorrectionUtil from '../AutoCorrectionUtil';

const DatFileUtil = {
	urlPattern: new RegExp(/(\S*)(url)$/),
	map: null,
	showSegments: null,
	selectedSegment: null,
	selectedSegmentLayer: null,
	selectedReadingsLayer: null,
	autoCorrectedLayer: null,
	autoCorrectedPreviousPointLayer: null,
	vectorLayers: null,
	isBoundingBoxesOnly: false,
	boundingBox: null,
	mergeComponent: null,
	func: 'GPS',
	job: null,
	colorDiff: 120,
	isShowingStationNums: true,
	originalEditToolsState: null, // TODO: I think this should be actually synced with the toolbar
	onSaveStarted: () => {}, // Stubbed function to be replaced later
	onSaveCompleted: () => {}, // Stubbed function to be replaced later
	init(options) {
		const { job, datFileSavingStarted, datFileSavingCompleted } = options;
		this.job = job;

		this.onSaveStarted = datFileSavingStarted;
		this.onSaveCompleted = datFileSavingCompleted;

		this.vectorLayers = null;
		this.selectedReadingsLayer = null;
		this.selectedSegmentLayer = null;
	},

	addSkipStarted(reading, datFile, globalData) {
		AutoCorrectionUtil.analyticsTrackCoordinates(
			'manual-skip-added',
			{ datFile, globalData },
			{ reading }
		);
	},

	smoothingStarted(datFiles, stats, globalData) {
		AutoCorrectionUtil.analyticsTrackCoordinates(
			'manual-smoothing',
			{ datFiles, globalData },
			{ stats }
		);
	},

	movingStarted(datFiles, stats, globalData) {
		AutoCorrectionUtil.analyticsTrackCoordinates(
			'manual-moving',
			{ datFiles, globalData },
			{ stats }
		);
	},

	getVisibleFiles(globalData) {
		const files = [];
		Object.keys(globalData.MasterLST).forEach(f => {
			if (globalData.MasterLST[f].visible === true) {
				files.push({ name: f });
			}
		});

		return files;
	}
};

export default DatFileUtil;
