/* eslint-disable no-unused-vars */

/**
 * VERIFY CAN I USE - not supported browsers (we don't currently support IE)
 * array.prototype.find - ie11
 * array.prototype.findIndex - ie11
 */
const MAX_ACTIVE_REQUEST_COUNT = 40;

const flattenRequestObj = obj => {
	const { fnApiRequest, params = {} } = obj;
	return {
		fnApiRequest,
		...params
	};
};

const isSameRequestObj = (obj1 = {}, obj2 = {}) => {
	const flatObj1 = flattenRequestObj(obj1);
	const flatObj2 = flattenRequestObj(obj2);

	const keys1 = Object.keys(flatObj1).sort();
	const keys2 = Object.keys(flatObj2).sort();

	const sameProps = keys1.join(', ') === keys2.join(', ');

	if (!sameProps) {
		return false;
	}

	return keys1
		.filter(k => k !== 'callback')
		.every(key => flatObj1[key] === flatObj2[key]);
};

const getRequestObjIndex = (arr = [], obj = {}) =>
	arr.findIndex(arrItem => isSameRequestObj(arrItem, obj));

const getRequestObj = (arr = [], obj = {}) => {
	const idx = getRequestObjIndex(arr, obj);
	if (idx > -1) {
		return arr[idx];
	}

	return undefined;
};

const removeRequestObj = (arr = [], obj = {}) => {
	const idx = getRequestObjIndex(arr, obj);
	if (idx > -1) {
		arr.splice(idx, 1);
		return true;
	}
	return false;
};

const _createRequestObj = (
	self,
	fnApiRequest,
	{ callback, ...remainingParams }
) => {
	const onRequestComplete = (...args) => {
		self.removeProcessingRequest({
			fnApiRequest,
			params: { callback, ...remainingParams }
		});
		self.executeNext();
		callback(...args);
	};

	return {
		fnApiRequest,
		params: { callback: onRequestComplete, ...remainingParams }
	};
};

class ReadingsRequestQueue {
	constructor() {
		this.maxActiveRequestCount = MAX_ACTIVE_REQUEST_COUNT;
		this.pendingRequests = [];
		this.processingRequests = [];
	}

	// Private PENDING Request Methods

	getPendingRequest = requestObj =>
		getRequestObj(this.pendingRequests, requestObj);

	removePendingRequest = requestObj =>
		removeRequestObj(this.pendingRequests, requestObj);

	addPendingRequest = requestObj => this.pendingRequests.push(requestObj);

	shiftPendingRequest = () => this.pendingRequests.shift();

	// Private PROCESSING Request Methods

	getProcessingRequest = requestObj =>
		getRequestObj(this.processingRequests, requestObj);

	removeProcessingRequest = requestObj =>
		removeRequestObj(this.processingRequests, requestObj);

	addProcessingRequest = requestObj => this.processingRequests.push(requestObj);

	shiftProcessingRequest = () => this.processingRequests.shift();

	// Private GENERAL Helper Methods

	createRequestObj = (fnApiRequest, { callback, ...remainingParams }) =>
		_createRequestObj(this, fnApiRequest, { callback, ...remainingParams });

	// MAIN METHODS

	queueRequest = (fnApiRequest, { callback, ...remainingParams }) => {
		const requestObj = this.createRequestObj(fnApiRequest, {
			callback,
			...remainingParams
		});
		if (this.processingRequests.length < this.maxActiveRequestCount) {
			this.addProcessingRequest(requestObj);
			fnApiRequest(requestObj.params);
		} else {
			this.addPendingRequest(requestObj);
		}
	};

	executeNext = () => {
		if (this.pendingRequests.length > 0) {
			const requestObj = this.shiftPendingRequest();
			this.addProcessingRequest(requestObj);
			requestObj.fnApiRequest(requestObj.params);
		}
	};
}

export const TESTS = {
	isSameRequestObj,
	getRequestObjIndex,
	getRequestObj,
	removeRequestObj,
	_createRequestObj
};

export default new ReadingsRequestQueue();
