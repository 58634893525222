export const selectBoundaryXMinXMax = (state = {}) => {
	const { boundaryXMinXMax } = state;
	return boundaryXMinXMax || {};
};

export const selectZoomFactor = (state = {}) => {
	const { zoomFactor } = state;
	return zoomFactor || {};
};

export const selectZoomPosition = (state = {}) => {
	const { zoomPosition } = state;
	return zoomPosition || {};
};

export default { selectBoundaryXMinXMax, selectZoomFactor, selectZoomPosition };
