import { ROOT_SLICE } from './constants';
import { createXYMinMaxObject } from './util/createXYMinMaxObject';
import {
	selectStoreXYMinMaxSlice,
	selectStoreDataXYMinMaxSlice,
	selectDataXYMinMax,
	selectStoreZoomXYMinMaxSlice,
	selectZoomXYMinMax,
	selectStoreInputXYMinMaxSlice,
	selectInputXYMinMax
} from './selectors';
import {
	setDataXYMinMax,
	bulkSetDataXYMinMax,
	setZoomXYMinMax,
	setInputXYMinMax,
	clearInputXYMinMax,
	setStoreZoomXYMinMaxSlice
} from './actions/actions';

// ************
// PARTS
// ************
const reducerParts = {
	[setDataXYMinMax]: (state, { payload: { key, xMin, xMax, yMin, yMax } }) => {
		const XYMinMax = selectStoreXYMinMaxSlice(state);
		const DataYMinYMax = selectStoreDataXYMinMaxSlice(state);
		const current = selectDataXYMinMax(state, key);
		return {
			...state,
			[ROOT_SLICE]: {
				...XYMinMax,
				data: {
					...DataYMinYMax,
					[key]: createXYMinMaxObject({ xMin, xMax, yMin, yMax }, current)
				}
			}
		};
	},

	[bulkSetDataXYMinMax]: (state, { payload: { NewStoreKeyValues } }) => {
		if (!NewStoreKeyValues || Object.keys(NewStoreKeyValues).length === 0) {
			return state;
		}

		const XYMinMax = selectStoreXYMinMaxSlice(state);
		const DataYMinYMax = selectStoreDataXYMinMaxSlice(state);
		return {
			...state,
			[ROOT_SLICE]: {
				...XYMinMax,
				data: {
					...DataYMinYMax,
					...NewStoreKeyValues
				}
			}
		};
	},

	[setZoomXYMinMax]: (state, { payload: { key, xMin, xMax, yMin, yMax } }) => {
		const XYMinMax = selectStoreXYMinMaxSlice(state);
		const ZoomYMinYMax = selectStoreZoomXYMinMaxSlice(state);
		const current = selectZoomXYMinMax(state, key);

		return {
			...state,
			[ROOT_SLICE]: {
				...XYMinMax,
				zoom: {
					...ZoomYMinYMax,
					[key]: createXYMinMaxObject({ xMin, xMax, yMin, yMax }, current)
				}
			}
		};
	},

	[setInputXYMinMax]: (state, { payload: { key, xMin, xMax, yMin, yMax } }) => {
		const XYMinMax = selectStoreXYMinMaxSlice(state);
		const InputYMinYMax = selectStoreInputXYMinMaxSlice(state);
		const current = selectInputXYMinMax(state, key);

		return {
			...state,
			[ROOT_SLICE]: {
				...XYMinMax,
				input: {
					...InputYMinYMax,
					[key]: createXYMinMaxObject({ xMin, xMax, yMin, yMax }, current)
				}
			}
		};
	},

	[clearInputXYMinMax]: (state, { payload: { key } }) => {
		const XYMinMax = selectStoreXYMinMaxSlice(state);
		const InputYMinYMax = selectStoreInputXYMinMaxSlice(state);

		return {
			...state,
			[ROOT_SLICE]: {
				...XYMinMax,
				input: {
					...InputYMinYMax,
					[key]: undefined
				}
			}
		};
	},

	[setStoreZoomXYMinMaxSlice]: (state, { payload: { zoom } }) => {
		const XYMinMax = selectStoreXYMinMaxSlice(state);

		return {
			...state,
			[ROOT_SLICE]: {
				...XYMinMax,
				zoom
			}
		};
	}
};

export default reducerParts;
