import { getChartId } from '../../utils/chart';

import {
	selectUseNormalizedDepol,
	selectUseInterpolatedDepol
} from '../slices/Charts/slices/ChartsSettings/selectors';
import {
	selectDepolSeriesFromCisSurvey,
	selectDepolSeriesIndexMapFromCisSurvey
} from './series';

export const selectDepolReadings = (
	app,
	depolId,
	useInterpolatedDepol,
	useNormalizedDepol
) => {
	let prop = 'readings';
	if (useInterpolatedDepol && useNormalizedDepol) {
		prop = 'interpReadingsNormalized';
	} else if (useInterpolatedDepol) {
		prop = 'interpReadings';
	} else if (useNormalizedDepol) {
		prop = 'readingsNormalized';
	}

	return app[prop][depolId];
};

export const selectDepolReadingsMap = (
	app,
	depolId,
	useInterpolatedDepol,
	useNormalizedDepol
) => {
	let prop = 'readingsMap';
	if (useInterpolatedDepol && useNormalizedDepol) {
		prop = 'interpReadingsNormalizedMap';
	} else if (useInterpolatedDepol) {
		prop = 'interpReadingsMap';
	} else if (useNormalizedDepol) {
		prop = 'readingsNormalizedMap';
	}

	return app[prop][depolId] || {};
};

export const selectDepolReadingsIndexMap = (
	app,
	depolId,
	useInterpolatedDepol,
	useNormalizedDepol
) => {
	let prop = 'readingsIndexMap';
	if (useInterpolatedDepol && useNormalizedDepol) {
		prop = 'interpReadingsNormalizedIndexMap';
	} else if (useInterpolatedDepol) {
		prop = 'interpReadingsIndexMap';
	} else if (useNormalizedDepol) {
		prop = 'readingsNormalizedIndexMap';
	}

	return app[prop][depolId] || {};
};

export const selectDepolReadingsWithChartGaps = (
	app,
	depolId,
	useInterpolatedDepol,
	useNormalizedDepol
) => {
	let prop = 'readingsWithChartGaps';
	if (useInterpolatedDepol && useNormalizedDepol) {
		prop = 'interpReadingsNormalizedWithChartGaps';
	} else if (useInterpolatedDepol) {
		prop = 'interpReadingsWithChartGaps';
	} else if (useNormalizedDepol) {
		prop = 'readingsNormalizedWithChartGaps';
	}

	return app[prop][depolId];
};

export const selectDepolReadingsWithChartGapsIndexMap = (
	app,
	depolId,
	useInterpolatedDepol,
	useNormalizedDepol
) => {
	let prop = 'readingsWithChartGapsIndexMap';
	if (useInterpolatedDepol && useNormalizedDepol) {
		prop = 'interpReadingsNormalizedWithChartGapsIndexMap';
	} else if (useInterpolatedDepol) {
		prop = 'interpReadingsWithChartGapsIndexMap';
	} else if (useNormalizedDepol) {
		prop = 'readingsNormalizedWithChartGapsIndexMap';
	}

	return app[prop][depolId] || {};
};

export const selectMyDepolReadingDataStructures = (
	stateApp,
	primarySurvey,
	depolId
) => {
	const chartId = getChartId(primarySurvey);
	const useInterpolatedDepol = selectUseInterpolatedDepol(stateApp, {
		chartId
	});
	const useNormalizedDepol = selectUseNormalizedDepol(stateApp, { chartId });

	const depolSeries = selectDepolSeriesFromCisSurvey(
		stateApp,
		primarySurvey,
		useInterpolatedDepol,
		useNormalizedDepol
	);
	const depolSeriesIndexMap = selectDepolSeriesIndexMapFromCisSurvey(
		stateApp,
		primarySurvey,
		useInterpolatedDepol,
		useNormalizedDepol
	);

	const depolReadings = selectDepolReadings(
		stateApp,
		depolId,
		useInterpolatedDepol,
		useNormalizedDepol
	);
	const depolReadingsMap = selectDepolReadingsMap(
		stateApp,
		depolId,
		useInterpolatedDepol,
		useNormalizedDepol
	);
	const depolReadingsIndexMap = selectDepolReadingsIndexMap(
		stateApp,
		depolId,
		useInterpolatedDepol,
		useNormalizedDepol
	);
	const depolReadingsWithChartGaps = selectDepolReadingsWithChartGaps(
		stateApp,
		depolId,
		useInterpolatedDepol,
		useNormalizedDepol
	);
	const depolReadingsWithChartGapsIndexMap = selectDepolReadingsWithChartGapsIndexMap(
		stateApp,
		depolId,
		useInterpolatedDepol,
		useNormalizedDepol
	);

	return {
		depolSeries,
		depolSeriesIndexMap,
		depolReadings,
		depolReadingsMap,
		depolReadingsIndexMap,
		depolReadingsWithChartGaps,
		depolReadingsWithChartGapsIndexMap
	};
};
