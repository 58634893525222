/* eslint-disable import/no-cycle */
/* eslint-disable no-use-before-define */
import { createPayloadMetaAction as cpma } from '../util';
import * as api from './util/api';
import {
	FETCH_PROJECTS_START,
	FETCH_PROJECTS_SUCCESS,
	FETCH_PROJECTS_NAMELIST_SUCCESS,
	PROJECTS_SET_FILTERS,
	SET_CURRENT_PROJECTS_PAGE,
	FETCH_PROJECTS_META_START,
	FETCH_PROJECTS_META_SUCCESS,
	CLEAR_CACHED_PROJECTS_DATA,
	SET_IS_STANDALONE_PROJECT
} from './actionTypes';

import { getPrevPage, getNextPage } from './util/projects.paging';

export const fetchProjects = ({ pageStart, pageEnd } = {}) => dispatch => {
	const requestObject = api.createFetchProjectsRequestObject({
		pageStart,
		pageEnd
	});
	const onFetchSuccess = projects => {
		const pages = api.getPagesFromFetchProjectsRequestObject(requestObject);
		const projectsByPage = api.createProjectsByPage(projects, pages);
		if (ifLastPageIsEmpty(projectsByPage)) {
			dispatch(goPrevPage());
		}
		dispatch(fetchProjectsSuccess(projectsByPage));
		dispatch(fetchProjectsMetaData(projects));
	};

	dispatch(fetchProjectsStart());
	api.fetchProjects(requestObject, onFetchSuccess);
};

export const fetchProjectsNameList = () => dispatch => {
	const onFetchSuccess = projectsNameList => {
		dispatch(fetchProjectsNameListSuccess(projectsNameList));
	};
	api.fetchProjectsNameList(onFetchSuccess);
};

export const fetchProjectsWithFilters = (
	{ pageStart, pageEnd } = {},
	projectFilters
) => dispatch => {
	const requestObject = api.createFetchProjectsRequestObject({
		pageStart,
		pageEnd
	});
	const onFetchSuccess = projects => {
		const pages = api.getPagesFromFetchProjectsRequestObject(requestObject);
		const projectsByPage = api.createProjectsByPage(projects, pages);
		if (ifLastPageIsEmpty(projectsByPage)) {
			dispatch(goPrevPage());
		}
		dispatch(fetchProjectsSuccess(projectsByPage));
		dispatch(fetchProjectsMetaData(projects));
	};

	dispatch(fetchProjectsStart());
	api.fetchProjectsWithFilters(projectFilters, onFetchSuccess);
};

export const fetchProjectsMetaData = projects => dispatch => {
	const requestObject = api.createFetchProjectsMetaDataRequestObject(projects);
	const onFetchSuccess = projectsMeta => {
		const newMetaByProjectId = api.createMetaByProjectId(projectsMeta);
		dispatch(fetchProjectsMetaDataSuccess(newMetaByProjectId));
	};

	dispatch(fetchProjectsMetaDataStart());
	api.fetchProjectsMetaData(requestObject, onFetchSuccess);
};

export const setCurrentPage = page => (dispatch, getState) => {
	dispatch(acSetCurrentPage(page));

	const { projects } = getState();
	if (!projects.lastPage) {
		const nextPageToLoad = (projects.largestPage || 0) + 1;
		const pageDiff = nextPageToLoad - page;
		if (pageDiff > -6 && pageDiff < 6) {
			dispatch(fetchProjects({ pageStart: nextPageToLoad }));
		}
	}
};

export const goNextPage = () => (dispatch, getState) => {
	const { projects: state } = getState();
	dispatch(setCurrentPage(getNextPage(state)));
};

export const goPrevPage = () => (dispatch, getState) => {
	const { projects: state } = getState();
	dispatch(setCurrentPage(getPrevPage(state)));
};

export const projectsSetFilters = filters => dispatch => {
	if (filters) {
		dispatch(clearCachedProjectsData());
		dispatch(acProjectsSetFilters(filters));
		dispatch(fetchProjectsWithFilters({}, { filters }));
	}
};

export const setIsStandAloneProject = value => dispatch => {
	dispatch(setIsStandAloneProjectAs(value));
};

const ifLastPageIsEmpty = projectsByPage => {
	return projectsByPage[Object.keys(projectsByPage).length].length === 0;
};

const fetchProjectsStart = cpma(FETCH_PROJECTS_START);
const fetchProjectsSuccess = cpma(FETCH_PROJECTS_SUCCESS);
const fetchProjectsNameListSuccess = cpma(FETCH_PROJECTS_NAMELIST_SUCCESS);

const fetchProjectsMetaDataStart = cpma(FETCH_PROJECTS_META_START);
const fetchProjectsMetaDataSuccess = cpma(FETCH_PROJECTS_META_SUCCESS);

const acSetCurrentPage = cpma(SET_CURRENT_PROJECTS_PAGE);
const acProjectsSetFilters = cpma(PROJECTS_SET_FILTERS);

export const clearCachedProjectsData = cpma(CLEAR_CACHED_PROJECTS_DATA);

export const setIsStandAloneProjectAs = cpma(SET_IS_STANDALONE_PROJECT);
