/* eslint-disable import/no-cycle */
/* eslint-disable import/prefer-default-export */
import { createPromiseRequest } from '../../api.util';
import { hasAccessToken } from '../../../../../scanline/utils/token';

export const listDdbCustomersEntities = () => {
	const url = '/entities';

	if (hasAccessToken()) {
		const fetch = createPromiseRequest({
			url,
			method: 'GET'
		});

		return fetch();
	}
	return Promise.reject(new Error('Missing token'));
};
