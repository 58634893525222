/* eslint-disable import/no-cycle */
/* eslint-disable import/prefer-default-export */
import {
	setPermissionDefinitions,
	setIsLoadingLoadedPermissionDefinitions,
	setHasLoadingErrorPermissionDefinitions
} from './actions';
import { getPermissionDefinitions } from '../../../../api/permissionDefinitions';
import {
	transformCloudDefinitionsToClientDefinitions,
	createMapPermissionsByGroupName,
	createListPermissionDefinitions,
	createMapPermissionsListByAppKey
} from '../util.transform';

const loadPermissionDefinitions = () => dispatch => {
	const meta1 = {
		isLoadingPermissionDefinitions: true,
		isLoadedPermissionDefinitions: false
	};
	dispatch(
		setPermissionDefinitions({
			permissionDefinitions: {},
			mapPermissionsByGroupName: {},
			listPermissionDefinitions: [],
			mapPermissionsListByAppKey: {}
		})
	);
	dispatch(setIsLoadingLoadedPermissionDefinitions(meta1));

	getPermissionDefinitions(undefined, (err, permissionDefinitions) => {
		if (err) {
			dispatch(setHasLoadingErrorPermissionDefinitions(true));
			dispatch(
				setIsLoadingLoadedPermissionDefinitions({
					isLoadingPermissionDefinitions: false,
					isLoadedPermissionDefinitions: false
				})
			);
		} else {
			const formattedDefinitions = transformCloudDefinitionsToClientDefinitions(
				permissionDefinitions
			);
			const mapPermissionsByGroupName = createMapPermissionsByGroupName(
				formattedDefinitions
			);
			const listPermissionDefinitions = createListPermissionDefinitions(
				formattedDefinitions
			);
			const mapPermissionsListByAppKey = createMapPermissionsListByAppKey(
				formattedDefinitions
			);

			dispatch(
				setPermissionDefinitions({
					permissionDefinitions: formattedDefinitions,
					mapPermissionsByGroupName,
					listPermissionDefinitions,
					mapPermissionsListByAppKey
				})
			);
			dispatch(
				setIsLoadingLoadedPermissionDefinitions({
					isLoadingPermissionDefinitions: false,
					isLoadedPermissionDefinitions: true
				})
			);
		}
	});
};

export { loadPermissionDefinitions };
