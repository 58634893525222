"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;
var BaseDocType = {
  // @required overwrite
  toFileText: function toFileText() {
    var _ref = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {},
        _ref$document = _ref.document,
        doc = _ref$document === void 0 ? '' : _ref$document;

    return doc;
  },
  // @required overwrite
  getFileParams: function getFileParams(docJson) {
    if (BaseDocType.isValidDocJson(docJson)) {
      var text = BaseDocType.toFileText(docJson);
      var name = BaseDocType.getFileName(docJson);
      var ext = BaseDocType.getFileExtension(docJson);
      var filename = [name, ext].filter(function (v) {
        return !!v;
      }).join('.');
      return {
        filename: filename,
        content: text,
        mimeType: 'text/plain' // 'application/geo+json'

      };
    }

    return undefined;
  },
  // @required overwrite
  // eslint-disable-next-line no-unused-vars
  toDocJson: function toDocJson(fileName, content) {
    return undefined;
  },
  getDocType: function getDocType() {
    var docJson = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    return docJson.type;
  },
  getFileName: function getFileName() {
    var docJson = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    return docJson.fileName;
  },
  getFileExtension: function getFileExtension() {
    var docJson = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    return docJson.fileExtension;
  },
  getDocument: function getDocument() {
    var docJson = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    return docJson.document;
  },
  isValidDocJson: function isValidDocJson() {
    var docJson = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    var requiredKeys = ['type', 'fileExtension', 'document'];
    var docKeys = Object.keys(docJson);
    return requiredKeys.every(function (k) {
      return docKeys.indexOf(k) > -1;
    });
  }
};
var _default = BaseDocType;
exports["default"] = _default;