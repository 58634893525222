/* eslint-disable import/no-cycle */
import ajax from '../../../../scanline/utils/ajax';
import { hasAccessToken } from '../../../../scanline/utils/token';

// const isDefined = v => v !== undefined && v !== null;

export const listJobLines = requestPayload =>
	new Promise((resolve, reject) => {
		// const url =
		// 	isDefined(offset) && isDefined(limit)
		// 		? `/listReportsV2/offset/${offset}/limit/${limit}`
		// 		: '/listReportsV2';

		const url = `/filterSurveys`;
		console.log('ss');
		if (hasAccessToken()) {
			const handleHttpResponse = (err, res) => {
				if (err) {
					reject(err);
				} else {
					resolve(res);
				}
			};

			ajax(
				url,
				requestPayload,
				'POST',
				handleHttpResponse,
				undefined,
				'dashboard'
			);
		}
	});

const _exports = {
	listJobLines
};

export default {
	..._exports
};
