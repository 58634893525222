import { cloneAndAlignTo } from './misc';

/** ***********
 * MAIN - MAP STATION ID
 */

const mapStationId = (
	cisReadings,
	cisReadingsMap,
	targetReadings,
	subtype,
	{ depolKey, depolFlagKey, targetValueKey },
	shouldCopyOriginalDepol
) => {
	const copyDepolValue = (cisReading, depolReading) => {
		if (subtype === 'DEPOL') {
			// eslint-disable-next-line no-param-reassign
			cisReading[depolKey] = depolReading[targetValueKey];
			if (depolFlagKey) {
				// eslint-disable-next-line no-param-reassign
				cisReading[depolFlagKey] = true;
			}
		}
	};

	// TODO: Make sure this is used
	const copyOriginalDepolValue = depolReading => {
		if (subtype === 'DEPOL' && shouldCopyOriginalDepol) {
			const cisReading = cisReadingsMap[depolReading.id];
			if (cisReading) {
				cisReading.depol = depolReading[targetValueKey];
			}
		}
	};

	return targetReadings.map((reading, index) => {
		copyOriginalDepolValue(reading);
		copyDepolValue(cisReadings[index], reading);
		return cloneAndAlignTo(reading, cisReadings[index], depolFlagKey);
	});
};

export default mapStationId;
