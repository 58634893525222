import { DateTimeless } from 'aegion_common_utilities/lib/DateTimeless';
import { isDate } from 'aegion_common_utilities/lib/DateUtil';
import { MM_DD_YYYY } from 'aegion_common_utilities/lib/format/datetimeless';

/* eslint-disable no-unused-vars */
export const TODAY = 'TODAY';
export const PAST_1_HOUR = 'PAST_1_HOUR';
export const PAST_7_DAYS = 'PAST_7_DAYS';
export const PAST_30_DAYS = 'PAST_30_DAYS';
export const PAST_90_DAYS = 'PAST_90_DAYS';
export const YEAR_TO_DATE = 'YEAR_TO_DATE';
export const PRIOR_YEAR = 'PRIOR_YEAR';
// export const BEFORE_DATE = 'BEFORE_DATE';  // too much legacy code that did not account for this use case
export const DATE_RANGE = 'DATE_RANGE';

const DEFAULT_TYPE = PAST_7_DAYS;

const isDefined = v => v !== undefined && v !== null;

const returnUndefined = () => undefined;

const addHours = (date, delta) => {
	date.setHours(date.getHours() + delta);
	return date;
};

const addDays = (date, delta) => {
	date.setDate(date.getDate() + delta);
	return date;
};

const addMonths = (date, delta) => {
	date.setMonth(date.getMonth() + delta);
	return date;
};

const addYears = (date, delta) => {
	date.setFullYear(date.getFullYear() + delta);
	return date;
};

const toStartOfDay = (date, { ignoreTime = false } = {}) => {
	const _date = ignoreTime ? new DateTimeless(date) : new Date(date);
	_date.setHours(0);
	_date.setMinutes(0);
	_date.setSeconds(0);
	return _date;
};

const toEndOfDay = (date, { ignoreTime = false } = {}) => {
	const _date = ignoreTime ? new DateTimeless(date) : new Date(date);
	_date.setHours(23);
	_date.setMinutes(59);
	_date.setSeconds(59);
	return _date;
};

const METAS = {
	TODAY: {
		order: 0,
		key: TODAY,
		label: 'Today',
		autoUpdateUrl: true,
		tooltipText: () => 'Today',
		computeNewFrom: (currFrom, currTo) => {
			return new DateTimeless();
		},
		computeNewTo: returnUndefined,
		legacyKeyValue: 'today',
		computeLegacyValues: (currFrom, currTo) => {
			const timeFilterBegin = toStartOfDay(currFrom || new Date(), {
				ignoreTime: true
			}).getTime();
			const timeFilterEnd = toEndOfDay(currTo || new Date(), {
				ignoreTime: true
			}).getTime();
			return {
				timeFilterType: 'today',
				timeFilterBegin,
				timeFilterEnd
			};
		}
	},

	PAST_1_HOUR: {
		order: 1,
		key: PAST_1_HOUR,
		label: 'Past Hour',
		autoUpdateUrl: true,
		tooltipText: () => 'Within 1 hour',
		computeNewFrom: (currFrom, currTo) => {
			const date = new Date();
			return addHours(date, -1); // should return normal date as time is required
		},
		computeNewTo: returnUndefined,
		legacyKeyValue: 'last1Hour',
		computeLegacyValues: (currFrom, currTo) => {
			const timeFilterBegin = new Date(currFrom).getTime();
			const timeFilterEnd = toEndOfDay(currTo || new Date(), {
				ignoreTime: true
			}).getTime();
			return {
				timeFilterType: 'last1Hour',
				timeFilterBegin,
				timeFilterEnd
			};
		}
	},

	PAST_7_DAYS: {
		order: 1,
		key: PAST_7_DAYS,
		label: 'Past 7 Days',
		autoUpdateUrl: true,
		tooltipText: () => 'Within 7 days',
		computeNewFrom: (currFrom, currTo) => {
			return new DateTimeless(addDays(new Date(), -7));
		},
		computeNewTo: returnUndefined,
		legacyKeyValue: 'last7Days',
		computeLegacyValues: (currFrom, currTo) => {
			const timeFilterBegin = toStartOfDay(currFrom, {
				ignoreTime: true
			}).getTime();
			const timeFilterEnd = toEndOfDay(currTo || new Date(), {
				ignoreTime: true
			}).getTime();
			return {
				timeFilterType: 'last7Days',
				timeFilterBegin,
				timeFilterEnd
			};
		}
	},

	PAST_30_DAYS: {
		order: 2,
		key: PAST_30_DAYS,
		label: 'Past 30 Days',
		autoUpdateUrl: true,
		tooltipText: () => 'Within 30 days',
		computeNewFrom: (currFrom, currTo) => {
			return new DateTimeless(addDays(new DateTimeless(), -30));
		},
		computeNewTo: returnUndefined,
		legacyKeyValue: 'last30Days',
		computeLegacyValues: (currFrom, currTo) => {
			const timeFilterBegin = toStartOfDay(currFrom, {
				ignoreTime: true
			}).getTime();
			const timeFilterEnd = toEndOfDay(currTo || new Date(), {
				ignoreTime: true
			}).getTime();
			return {
				timeFilterType: 'last30Days',
				timeFilterBegin,
				timeFilterEnd
			};
		}
	},

	PAST_90_DAYS: {
		order: 3,
		key: PAST_90_DAYS,
		label: 'Past 90 Days',
		autoUpdateUrl: true,
		tooltipText: () => 'Within 90 days',
		computeNewFrom: (currFrom, currTo) => {
			return new DateTimeless(addDays(new DateTimeless(), -90));
		},
		computeNewTo: returnUndefined,
		legacyKeyValue: 'last90Days',
		computeLegacyValues: (currFrom, currTo) => {
			const timeFilterBegin = toStartOfDay(currFrom, {
				ignoreTime: true
			}).getTime();
			const timeFilterEnd = toEndOfDay(currTo || new Date(), {
				ignoreTime: true
			}).getTime();
			return {
				timeFilterType: 'last90Days',
				timeFilterBegin,
				timeFilterEnd
			};
		}
	},

	YEAR_TO_DATE: {
		order: 4,
		key: YEAR_TO_DATE,
		label: 'Year to Date',
		autoUpdateUrl: true,
		tooltipText: () => 'Year to Date',
		computeNewFrom: (currFrom, currTo) => {
			return new DateTimeless(`${new Date().getFullYear()}-01-01`);
		},
		computeNewTo: returnUndefined,
		legacyKeyValue: 'thisYear',
		computeLegacyValues: (currFrom, currTo) => {
			const timeFilterBegin = toStartOfDay(currFrom, {
				ignoreTime: true
			}).getTime();
			const timeFilterEnd = toEndOfDay(currTo || new Date(), {
				ignoreTime: true
			}).getTime();
			return {
				timeFilterType: 'thisYear',
				timeFilterBegin,
				timeFilterEnd
			};
		}
	},

	PRIOR_YEAR: {
		order: 5,
		key: PRIOR_YEAR,
		label: 'Prior Year',
		autoUpdateUrl: true,
		tooltipText: () => 'Prior Year',
		computeNewFrom: (currFrom, currTo) => {
			const previousYear = new Date().getFullYear() - 1;
			return new DateTimeless(`${previousYear}-01-01`);
		},
		computeNewTo: (currFrom, currTo) => {
			const previousYear = new Date().getFullYear() - 1;
			return new DateTimeless(`${previousYear}-12-31`);
		},
		legacyKeyValue: 'lastYear',
		computeLegacyValues: (currFrom, currTo) => {
			const timeFilterBegin = toStartOfDay(currFrom, {
				ignoreTime: true
			}).getTime();
			const timeFilterEnd = toEndOfDay(
				new DateTimeless(currTo, { ignoreTime: true })
			).getTime();
			return {
				timeFilterType: 'lastYear',
				timeFilterBegin,
				timeFilterEnd
			};
		}
	},

	// BEFORE_DATE: {
	// 	order: 7,
	// 	key: BEFORE_DATE,
	// 	label: 'Before Date',
	// 	tooltipText: (from, to) => `Before ${MM_DD_YYYY(to)}`,
	// 	computeNewFrom: returnUndefined,
	// 	computeNewTo: (currFrom, currTo) => {
	// 		return isDefined(currTo) && isDate(currTo) ? currTo : new DateTimeless();
	// 	}
	// },

	DATE_RANGE: {
		order: 8,
		key: DATE_RANGE,
		label: 'Date Range',
		tooltipText: (from, to) => {
			if (isDate(from) && isDate(to)) {
				return `${MM_DD_YYYY(from)} - ${MM_DD_YYYY(to)}`;
			}
			if (isDate(from)) {
				return `After ${MM_DD_YYYY(from)}`;
			}
			if (isDate(to)) {
				return `Before ${MM_DD_YYYY(to)}`;
			}
			return ''; // this case should not happen
		},
		computeNewFrom: (currFrom, currTo) => {
			const newFrom = addMonths(new DateTimeless(), -1);
			return isDefined(currFrom) && isDate(currFrom)
				? new DateTimeless(currFrom)
				: newFrom;
		},
		computeNewTo: (currFrom, currTo) => {
			const newTo = new DateTimeless();
			return isDefined(currTo) && isDate(currTo)
				? new DateTimeless(currTo)
				: newTo;
		},
		legacyKeyValue: 'custom',
		computeLegacyValues: (currFrom, currTo) => {
			const timeFilterBegin = toStartOfDay(currFrom, {
				ignoreTime: true
			}).getTime();
			const timeFilterEnd = toEndOfDay(currTo, { ignoreTime: true }).getTime();
			return {
				timeFilterType: 'custom',
				timeFilterBegin,
				timeFilterEnd
			};
		}
	}
};

const LIST = Object.keys(METAS)
	.sort((k1, k2) => {
		return METAS[k1].order > METAS[k2].order;
	})
	.map(k => METAS[k]);

export const DATE_RANGE_TYPE_METAS = METAS;

export const LIST_DATE_RANGE_TYPE_METAS = LIST;

export const sanitizeDateRangeTypeKey = paramKey => {
	const key = (paramKey || '').toUpperCase();
	return METAS[key] ? key : DEFAULT_TYPE;
};

export const getDateRangeTypeMeta = paramKey => {
	const key = sanitizeDateRangeTypeKey(paramKey);
	return METAS[key];
};
