export const EMPTY_OBJECT = {};

export const EMPTY_ARRAY = [];

export const DEFAULT_THRESHOLD = undefined;
export const DEFAULT_THRESHOLDS = [DEFAULT_THRESHOLD];

export const INITIAL_THRESHOLD = -850;
export const INITIAL_THRESHOLDS = [INITIAL_THRESHOLD];

export default {
	EMPTY_OBJECT,
	EMPTY_ARRAY,
	DEFAULT_THRESHOLD,
	DEFAULT_THRESHOLDS,
	INITIAL_THRESHOLD,
	INITIAL_THRESHOLDS
};
