const initialState = {
	oldAsset: undefined,
	newAsset: undefined,
	mergePreview: undefined,
	mergePreviewDiff: undefined,
	isMerging: false,
	isMerged: false,
	copyMissingProps: false,
	isMergeApproved: false,
	mergeErrorMessage: undefined
};

export default initialState;
