const _getLocalStorageKey = settingName => {
	const LOCAL_STORAGE_PREFIX = 'CISV_SPIKE_EDITOR_SETTING';
	return `${LOCAL_STORAGE_PREFIX}_${settingName}`;
};

const _getPersistantUserSetting = (settingName, defaultValue) => {
	const localStorageKey = _getLocalStorageKey(settingName);

	const value = localStorage[localStorageKey];

	if (value === undefined) {
		return defaultValue;
	}

	try {
		return JSON.parse(value);
	} catch (err) {
		// eslint-disable-next-line no-console
		console.error(err);
		return value;
	}
};

const LOCAL_STORAGE_KEY_SUFFIXES = {
	SHOW_GRAPH_DOTS: 'SHOW_GRAPH_DOTS'
};

// TODO: Add error handling (mostly for parsing JSON strings and stringify JSON)
const _setPersistantUserSetting = (settingName, settingValue) => {
	const localStorageKey = _getLocalStorageKey(settingName);
	try {
		localStorage[localStorageKey] = JSON.stringify(settingValue);
	} catch (err) {
		// eslint-disable-next-line no-console
		console.error(err);
		localStorage[localStorageKey] = settingValue;
	}
};

const DEFAULTS = {
	CHART_DART_VISIBILITY: false
};

const LocalStorageUtil = {
	spikeEditor: {
		getGraphDotVisibility() {
			const settingName = LOCAL_STORAGE_KEY_SUFFIXES.SHOW_GRAPH_DOTS;

			return _getPersistantUserSetting(
				settingName,
				DEFAULTS.CHART_DART_VISIBILITY
			);
		},
		setGraphDotVisibility(value) {
			const settingName = LOCAL_STORAGE_KEY_SUFFIXES.SHOW_GRAPH_DOTS;

			_setPersistantUserSetting(settingName, value);
		}
	}
};

export default LocalStorageUtil;
