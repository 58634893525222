/* eslint-disable no-param-reassign */

import { isFauxReading } from '../../../reducers/util/readings.parse';

// assumes sorted and valid prev/nextDepol with a desiredReading that is in between
export const calcInterpVal = (
	prevDepol,
	nextDepol,
	desiredReading,
	propKey
) => {
	const depolStationDelta = nextDepol.id - prevDepol.id;
	const readingLocation = desiredReading.id - prevDepol.id;
	const perc = readingLocation / depolStationDelta;
	const valueDelta = nextDepol[propKey] - prevDepol[propKey];
	const newValue = prevDepol[propKey] + valueDelta * perc;

	return newValue;
};

export const findValidPrev = (
	reading,
	readings,
	readingsIndexMap,
	isSkipReading = () => false
) => {
	const startingIdx = readingsIndexMap[reading.uuid];

	let idx = startingIdx;
	let result;
	while (idx > 0 && !result) {
		idx -= 1;
		if (!isSkipReading(readings[idx])) {
			result = readings[idx];
		}
	}

	return result;
};

export const findValidNext = (
	reading,
	readings,
	readingsIndexMap,
	isSkipReading = () => false
) => {
	const readingsLength = readings ? readings.length : 0;
	const startingIdx = readingsIndexMap[reading.uuid];

	let idx = startingIdx;
	let result;
	while (idx < readingsLength - 1 && !result) {
		idx += 1;
		if (!isSkipReading(readings[idx])) {
			result = readings[idx];
		}
	}

	return result;
};

const createGapReading = (r, idx) => {
	return { id: r.id + 0.01, uuid: `${r.uuid}~${idx}`, isGap: true };
};

export const injectInterpolatedValue = (
	prevDepol,
	nextDepol,
	desiredReading,
	propKeys = [],
	isSkipReading = () => false,
	readings,
	readingsIndexMap
) => {
	// if there is no prev or next depol, then we will not have 2 values to interpolate against.
	if (!prevDepol || !nextDepol) {
		return false;
	}

	// HELPERS

	const injectInterpVals = (validPrev, validNext, target) => {
		propKeys.forEach(key => {
			target[key] = calcInterpVal(validPrev, validNext, target, key);
			target.isInterpolated = true; // i depol means is depol interpolated = went with a short prop name to not waste memory as it will become an increasing concern
		});
	};

	const injectSkipProps = target => {
		propKeys.forEach(key => {
			delete target[key];
			target.isInterpolated = true; // i depol means is depol interpolated = went with a short prop name to not waste memory as it will become an increasing concern
		});
	};

	// MAIN

	const prevIsSkip = isSkipReading(prevDepol);
	const nextIsSkip = isSkipReading(nextDepol);

	// case 2 - if prev and next are skip then skip
	if (prevIsSkip && nextIsSkip) {
		injectSkipProps(desiredReading);
	}

	// case 3 - if prev is skip and next is not skip
	else if (prevIsSkip && !nextIsSkip) {
		const validPrev = findValidPrev(
			prevDepol,
			readings,
			readingsIndexMap,
			isSkipReading
		);
		if (validPrev) {
			injectInterpVals(validPrev, nextDepol, desiredReading);
		} else {
			return false;
		}
	}

	// case 4 - is inverted case 3;
	else if (!prevIsSkip && nextIsSkip) {
		const validNext = findValidNext(
			nextDepol,
			readings,
			readingsIndexMap,
			isSkipReading
		);
		if (validNext) {
			injectInterpVals(prevDepol, validNext, desiredReading);
		} else {
			return false;
		}
	}

	// case 1 - base case
	else {
		injectInterpVals(prevDepol, nextDepol, desiredReading);
	}

	return true;
};

const pushToNewReadingsStationIdIntegers = (
	reading,
	newReadingsStationIdIntegers
) => {
	if (!isFauxReading(reading)) {
		const { id } = reading;
		const idInteger = Math.floor(id);
		newReadingsStationIdIntegers[idInteger] =
			newReadingsStationIdIntegers[idInteger] || [];
		newReadingsStationIdIntegers[idInteger].push(id);
	}
};

export default (
	targetReadings = [],
	targetReadingsIndexMap = {},
	referenceReadings = [],
	propKeys = [],
	isSkipReading = () => false,
	hasGap = () => false
) => {
	const newTargetReadings = [];
	const newTargetReadingsWithChartGaps = [];
	const newTargetReadingsMap = {};
	const newTargetReadingsIndexMap = {};
	const newTargetReadingsWithChartGapsIndexMap = {};
	const newReadingsStationIdIntegers = {};

	const newTargetSeries = propKeys.reduce((acc, p) => {
		acc[p] = [];
		return acc;
	}, {});
	const newTargetSeriesIndexMap = propKeys.reduce((acc, p) => {
		acc[p] = {};
		return acc;
	}, {});

	const pushReadingToSeriesAndIndexMap = reading => {
		propKeys.forEach(prop => {
			let readingToPush = reading;
			if (readingToPush[prop] === 0) {
				readingToPush = { ...reading };
				delete readingToPush[prop];
			}

			newTargetSeries[prop].push(readingToPush);
			newTargetSeriesIndexMap[prop][readingToPush.uuid] =
				newTargetSeries[prop].length - 1;
		});
	};

	const pushNewTargetReading = (reading, prevReading) => {
		let tempGap;

		// handle gaps
		if (prevReading && hasGap(reading, prevReading)) {
			tempGap = createGapReading(prevReading);
			newTargetReadingsWithChartGaps.push(tempGap);
			newTargetReadingsWithChartGapsIndexMap[tempGap.uuid] =
				newTargetReadingsWithChartGaps.length - 1;
			pushReadingToSeriesAndIndexMap(tempGap);
		}

		newTargetReadings.push(reading);
		newTargetReadingsWithChartGaps.push(reading);
		newTargetReadingsMap[reading.id] = reading;
		newTargetReadingsIndexMap[reading.uuid] = newTargetReadings.length - 1;
		newTargetReadingsWithChartGapsIndexMap[reading.uuid] =
			newTargetReadingsWithChartGaps.length - 1;
		pushToNewReadingsStationIdIntegers(reading, newReadingsStationIdIntegers);
		pushReadingToSeriesAndIndexMap(reading);
	};

	let tIdx = 0;
	let rIdx = 0;

	while (tIdx < targetReadings.length) {
		const trueTargetReading = targetReadings[tIdx];
		const trueReferenceReading = referenceReadings[rIdx];

		if (trueTargetReading) {
			const nextDepol = trueTargetReading;
			const prevDepol =
				targetReadings[targetReadingsIndexMap[trueTargetReading.uuid] - 1] ||
				undefined;

			if (trueReferenceReading) {
				if (trueTargetReading.id === trueReferenceReading.id) {
					pushNewTargetReading(trueTargetReading, prevDepol);
					tIdx += 1;
					rIdx += 1;
				} else if (trueTargetReading.id <= trueReferenceReading.id) {
					pushNewTargetReading(trueTargetReading, prevDepol);
					tIdx += 1;

					// reference reading requires an interpolated value
				} else {
					const newDepol = { ...trueReferenceReading };

					const didInject = injectInterpolatedValue(
						prevDepol,
						nextDepol,
						newDepol,
						propKeys,
						isSkipReading,
						targetReadings,
						targetReadingsIndexMap
					);
					if (didInject) {
						pushNewTargetReading(newDepol, prevDepol);
					}
					rIdx += 1;
				}
			} else {
				pushNewTargetReading(trueTargetReading, prevDepol);
				tIdx += 1;
			}
		}
	}

	return [
		newTargetReadings,
		newTargetReadingsWithChartGaps,
		newTargetReadingsMap,
		newTargetReadingsIndexMap,
		newTargetReadingsWithChartGapsIndexMap,
		newTargetSeries,
		newTargetSeriesIndexMap,
		newReadingsStationIdIntegers
	];
};
