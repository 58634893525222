/*
* TODO:
* - When we clear auto correction for file
* - When we clear all auto correction
* - Set When a corrected point is hovered
* - Remove autocorrected point

*/

// eslint-disable-next-line import/no-cycle
import AutoCorrectionUtil from '../../utils/AutoCorrectionUtil';

const defaultState = {
	autoCorrectionsByFileName: {
		/* Will look like:
		someFileName: {
			autoGpsCorrectionInProcess: true,
			coordinateLedger: [],
			abTestingInfoCoordinates: {},
			cachedMergedGpsCoordinates,
			userActions: [],
			datFile
		}
		*/
	},
	autoGpsCorrectionInProcess: false,
	isAutoCorrectionDrawEnabled: false,
	gpsAutoCorrectionApiStarted: false,
	hoveredReadingPoint: null
};

const setGpsAutoCorrectionStarted = (state, datFiles) => {
	let newState = {
		...state,
		gpsAutoCorrectionApiStarted: true,
		autoGpsCorrectionInProcess: true
	};

	datFiles.forEach(datFile => {
		newState = {
			...newState,
			autoCorrectionsByFileName: {
				...newState.autoCorrectionsByFileName,
				[datFile.fileName]: {
					autoGpsCorrectionInProcess: true,
					coordinateLedger: [],
					cachedMergedGpsCoordinates: [],
					abTestingInfoCoordinates: {},
					userActions: [],
					datFile
				}
			}
		};
	});

	return newState;
};

const setGpsAutoCorrectionFinishedWithoutResults = state => ({
	...state,
	gpsAutoCorrectionApiStarted: false,
	autoGpsCorrectionInProcess: false
});

const setGpsAutoCorrectionApiFinished = state => ({
	...state,
	gpsAutoCorrectionApiStarted: false
});

const setGpsAutoCorrectionNewResponse = (state, datFilePostProcessResults) => {
	let newState = { ...state, autoGpsCorrectionInProcess: true };
	datFilePostProcessResults.forEach(datFileResults => {
		const { autoCorrectionsByFileName } = newState;
		const {
			cachedMergedGpsCoordinates,
			datFile,
			coordinateLedger,
			abTestingInfoCoordinates,
			datFileStatistics
		} = datFileResults;

		newState = {
			...newState,
			autoCorrectionsByFileName: {
				...autoCorrectionsByFileName,
				[datFile.fileName]: {
					coordinateLedger,
					abTestingInfoCoordinates,
					autoGpsCorrectionInProcess: true,
					cachedMergedGpsCoordinates,
					datFileStatistics,
					userActions: [],
					datFile
				}
			}
		};
	});

	return newState;
};

const clearAutoCorrectionByFile = (state, datFile) => ({
	...state,
	datFile
});

const clearAllCoordinatesAutoCorrection = state => ({
	...state,
	autoCorrectionsByFileName: {},
	autoGpsCorrectionInProcess: false
});

const enableDrawClearShape = state => ({
	...state,
	isAutoCorrectionDrawEnabled: true
});

const disableDrawClearShape = state => ({
	...state,
	isAutoCorrectionDrawEnabled: false
});

const addGpsAutoCorrectionUserActions = (state, newUserActionsByFileName) => {
	const { autoCorrectionsByFileName } = state;
	let newState = {
		...state,
		autoCorrectionsByFileName: { ...autoCorrectionsByFileName }
	};

	Object.keys(newUserActionsByFileName).forEach(fileName => {
		// If this is an empty array, then there were no userActions created for this file, skip
		if (!newUserActionsByFileName[fileName].length) {
			return;
		}

		const autoCorrectionData = autoCorrectionsByFileName[fileName];
		const { datFile, coordinateLedger, userActions } = autoCorrectionData;
		const newUserActions = userActions.concat([
			newUserActionsByFileName[fileName]
		]);

		const cachedMergedGpsCoordinates = AutoCorrectionUtil.mergeCoordinatesWithFlags(
			datFile,
			coordinateLedger,
			newUserActions
		);

		newState = {
			...newState,
			autoCorrectionsByFileName: {
				...newState.autoCorrectionsByFileName,
				[fileName]: {
					...autoCorrectionData,
					userActions: newUserActions,
					cachedMergedGpsCoordinates
				}
			}
		};
	});

	return newState;
};

const hoverOverPointOnMap = (state, hoveredReadingPoint) => {
	return {
		...state,
		hoveredReadingPoint
	};
};

const blurOverPointOnMap = (state, hoveredReadingPoint) => {
	const { hoveredReadingPoint: oldHoveredReadingPoint } = state;

	if (!hoveredReadingPoint) {
		return state;
	}

	// We actually want to compare the pointers of these objects (can be done if coordinates are immutable)
	if (oldHoveredReadingPoint.reading !== hoveredReadingPoint.reading) {
		return state;
	}

	return {
		...state,
		hoveredReadingPoint: null
	};
};

const revertGpsAutoCorrectionUserAction = (state, datFileName) => {
	const { autoCorrectionsByFileName } = state;
	const { datFile, userActions, coordinateLedger } = autoCorrectionsByFileName[
		datFileName
	];

	const newUserActions = userActions.slice(0, -1);

	const cachedMergedGpsCoordinates = AutoCorrectionUtil.mergeCoordinatesWithFlags(
		datFile,
		coordinateLedger,
		newUserActions
	);

	return {
		...state,
		autoCorrectionsByFileName: {
			...autoCorrectionsByFileName,
			[datFileName]: {
				...autoCorrectionsByFileName[datFileName],
				userActions: newUserActions, // return all but last item
				cachedMergedGpsCoordinates
			}
		}
	};
};

const updateCoordinateLedgerByFileName = (
	state,
	datFileName,
	coordinateLedger
) => {
	const { autoCorrectionsByFileName } = state;

	const { datFile, userActions } = autoCorrectionsByFileName[datFileName];

	const cachedMergedGpsCoordinates = AutoCorrectionUtil.mergeCoordinatesWithFlags(
		datFile,
		coordinateLedger,
		userActions
	);

	return {
		...state,
		autoCorrectionsByFileName: {
			...autoCorrectionsByFileName,
			[datFileName]: {
				...autoCorrectionsByFileName[datFileName],
				cachedMergedGpsCoordinates,
				coordinateLedger
			}
		}
	};
};

const onNewGpsData = (state, datFiles) => {
	const { autoCorrectionsByFileName } = state;
	if (!autoCorrectionsByFileName || !datFiles) {
		return state;
	}

	const newAutoCorrectionsByFileName = {};
	Object.keys(autoCorrectionsByFileName).forEach(datFileName => {
		const datFile = datFiles.filter(
			({ fileName }) => fileName === datFileName
		)[0];

		// This file does not have any autocorrections, continue as-is
		if (!datFile) {
			newAutoCorrectionsByFileName[datFileName] =
				autoCorrectionsByFileName[datFileName];
			return;
		}

		const { userActions, coordinateLedger } = autoCorrectionsByFileName[
			datFileName
		];

		const cachedMergedGpsCoordinates = AutoCorrectionUtil.mergeCoordinatesWithFlags(
			datFile,
			coordinateLedger,
			userActions
		);

		newAutoCorrectionsByFileName[datFileName] = {
			...autoCorrectionsByFileName[datFileName],
			cachedMergedGpsCoordinates,
			coordinateLedger,
			datFile
		};
	});

	return {
		...state,
		autoCorrectionsByFileName: newAutoCorrectionsByFileName
	};
};

const gpsAutoCorrection = (state = defaultState, action) => {
	switch (action.type) {
		case 'GPS_AUTO_CORRECTION_SET_NEW_RESPONSE':
			return setGpsAutoCorrectionNewResponse(
				state,
				action.datFilePostProcessResults
			);
		case 'GPS_AUTO_CORRECTION_RECEIVE_CLEAR_BY_FILE':
			return clearAutoCorrectionByFile(state, action.datFile);
		case 'GPS_AUTO_CORRECTION_ENABLE_DRAW_CLEAR_SHAPE':
			return enableDrawClearShape(state);
		case 'GPS_AUTO_CORRECTION_DISABLE_DRAW_CLEAR_SHAPE':
			return disableDrawClearShape(state);
		case 'GPS_AUTO_CORRECTION_ADD_USER_ACTION':
			return addGpsAutoCorrectionUserActions(
				state,
				action.correctionsByFileName
			);
		case 'GPS_AUTO_CORRECTION_HOVER_OVER_POINT_ON_MAP':
			return hoverOverPointOnMap(state, action.reading);
		case 'GPS_AUTO_CORRECTION_BLUR_OVER_POINT_ON_MAP':
			return blurOverPointOnMap(state, action.reading);

		case 'GPS_AUTO_CORRECTION_CLEAR_ALL':
			return clearAllCoordinatesAutoCorrection(state);
		case 'GPS_AUTO_CORRECTION_API_START':
			return setGpsAutoCorrectionStarted(state, action.datFiles);
		case 'GPS_AUTO_CORRECTION_API_FINISHED':
			return setGpsAutoCorrectionApiFinished(state);
		case 'GPS_AUTO_CORRECTION_API_FINISHED_WITHOUT_RESULTS':
			return setGpsAutoCorrectionFinishedWithoutResults(state);
		case 'GPS_AUTO_CORRECTION_REVERT_USER_ACTIONS':
			return revertGpsAutoCorrectionUserAction(state, action.datFileName);
		case 'GPS_AUTO_CORRECTION_UPDATE_LEDGER':
			return updateCoordinateLedgerByFileName(
				state,
				action.datFileName,
				action.coordinateLedger
			);
		case 'GPS_AUTO_CORRECTION_REFRESH_GPS_DATA':
			return onNewGpsData(state, action.datFiles);
		default:
			return state;
	}
};

export default gpsAutoCorrection;
