/* eslint-disable import/no-cycle */
import { createActions } from 'redux-actions';
import { LOAD_ERROR_MESSAGE } from './constants';
import { transformJobLines } from './transform';
import { listJobLines } from '../../api/JobLines';

import JL_FILTERS_SELECTORS from '../JobLineFilters/selectors'; // import { selectIsLoadingJobLines, selectIsAllJobLinesLoaded } from './selectors';

const {
	clearJobLines,
	requestJobLines,
	receiveJobLines,
	setTotalCount,
	setLoadJobLinesErrorMessage,
	clearLoadJobLinesErrorMessage
} = createActions(
	{
		CLEAR_JOB_LINES: () => ({}),
		REQUEST_JOB_LINES: () => ({}),
		RECEIVE_JOB_LINES: jobLines => ({ jobLines }),
		SET_TOTAL_COUNT: totalCount => ({ totalCount }),
		SET_LOAD_JOB_LINES_ERROR_MESSAGE: message => ({ message }),
		CLEAR_LOAD_JOB_LINES_ERROR_MESSAGE: () => ({})
	},
	{ prefix: 'DashboardCisvJobLines' }
);

const thunkLoadJobLines = () => (dispatch, getState) => {
	dispatch(clearJobLines());
	dispatch(requestJobLines());

	const { DashboardCISV: state } = getState();

	const requestPayload = {
		filters: {
			customers: JL_FILTERS_SELECTORS.selectCustomerSelectedList(state),
			processors: JL_FILTERS_SELECTORS.selectProcessorSelectedList(state)
		}
	};
	// SS
	listJobLines(JSON.stringify(requestPayload))
		.then(httpResponse => {
			dispatch(receiveJobLines(transformJobLines(httpResponse)));
		})
		.catch(() => {
			dispatch(setLoadJobLinesErrorMessage(LOAD_ERROR_MESSAGE));
		});
};

export {
	thunkLoadJobLines,
	clearJobLines,
	requestJobLines,
	receiveJobLines,
	setTotalCount,
	setLoadJobLinesErrorMessage,
	clearLoadJobLinesErrorMessage
};

/**
 * BATCH REQUEST IMPLEMENTATION
 */

// const loadInitialJobLines = () => dispatch => {
// 	dispatch(clearJobLines());
// 	dispatch(requestJobLines());

// 	BatchRequestManager.reset();
// 	BatchRequestManager.nextBatch()
// 		.then(res => {
// 			const { totalCount, jobLines } = res;
// 			dispatch(clearLoadJobLinesErrorMessage());
// 			dispatch(setTotalCount(totalCount));
// 			dispatch(receiveJobLines(transformJobLines(jobLines)));
// 		})
// 		.catch(err => {
// 			console.error(err);
// 			dispatch(setLoadJobLinesErrorMessage(LOAD_ERROR_MESSAGE));
// 		});
// };

// const loadNextJobLines = () => (dispatch, getState) => {
// 	const { scanJobLinessList: _state } = getState();
// 	const shouldIgnoreRequest =
// 		selectIsLoadingJobLines(_state) || selectIsAllJobLinesLoaded(_state);

// 	if (!shouldIgnoreRequest) {
// 		dispatch(requestJobLines());

// 		BatchRequestManager.nextBatch()
// 			.then(res => {
// 				const { jobLiness } = res;
// 				dispatch(clearLoadJobLinesErrorMessage());
// 				dispatch(receiveJobLines(transformJobLines(jobLiness)));
// 			})
// 			.catch(err => {
// 				console.error(err);
// 				dispatch(setLoadJobLinesErrorMessage(LOAD_ERROR_MESSAGE));
// 			});
// 	}
// };
