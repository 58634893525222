import { BETA_TESTING_TABS } from '../reducers/utils/tabIds';

export const small = 0;
export const medium = 1;
export const large = 2;
export const fullLeft = 3;

export const setTabIndex = tabIndex => ({
	type: 'CISV_UI_TABS_SET_TAB_INDEX',
	tabIndex
});

export const setHasFiles = hasFiles => ({
	type: 'CISV_UI_TABS_SET_HAS_FILES',
	hasFiles
});

export const setCISSurvey = cisSurvey => ({
	type: 'CISV_UI_TABS_SET_CIS_SURVEY',
	cisSurvey
});
export const setLeftPanelSize = size => ({
	type: 'CISV_UI_TABS_SET_TAB_SIZE',
	size
});

export const setTabById = tabId => ({
	type: 'CISV_UI_TABS_SET_TAB_BY_ID',
	tabId
});

const setUserChangedTabId = previousTabs => ({
	type: 'CISV_UI_TABS_SET_USER_CHANGED_TAB',
	previousTabs
});

const setAllowBetaTestingTabs = betaTestingTabsAllowed => ({
	type: 'CISV_UI_TABS_SET_BETA_TESTING_TABS_ALLOWED',
	betaTestingTabsAllowed
});

const getOldTabIdFromState = getState => {
	const state = getState();
	const { tabs } = state.cisview;
	const { mode } = tabs;

	return mode.id;
};

const getTimeSentOnPreviousTab = getState => {
	const state = getState();

	const { tabs } = state.cisview;
	const { startOfCurrentTab } = tabs;

	const timeEnd = new Date();
	const timeSpentMilliseconds = timeEnd - startOfCurrentTab;

	const timeSpentSecondsOnPreviousTab = parseInt(
		timeSpentMilliseconds / 1000,
		10
	);

	return timeSpentSecondsOnPreviousTab;
};

export const appendToPreviousTabsList = (getState, oldTabIdFromProps) => {
	let oldTabId;
	if (oldTabIdFromProps !== undefined) {
		oldTabId = oldTabIdFromProps;
	} else {
		oldTabId = getOldTabIdFromState(getState);
	}

	const state = getState();

	const { tabs } = state.cisview;
	const { previousTabs } = tabs;

	const timeSpentSecondsOnPreviousTab = getTimeSentOnPreviousTab(getState);

	const newPreviousTabObject = { tab: oldTabId, timeSpentSecondsOnPreviousTab };

	const newPreviousTabsList = [...previousTabs, newPreviousTabObject];
	return [newPreviousTabsList, timeSpentSecondsOnPreviousTab];
};

export const updateTabIdFromUserChange = () => (dispatch, getState) => {
	const [prevousTabs, timeSpentSecondsOnPreviousTab] = appendToPreviousTabsList(
		getState
	);

	dispatch(setUserChangedTabId(prevousTabs));

	return timeSpentSecondsOnPreviousTab;
};

export const setBetaTestingTabsFromUrlParams = developerSettings => dispatch => {
	const betaTestingTabsAllowed = BETA_TESTING_TABS.filter(
		tabId => developerSettings[tabId]
	);

	dispatch(setAllowBetaTestingTabs(betaTestingTabsAllowed));
};
