"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.parseComment = exports.hasComment = exports["default"] = exports.RETURN_TYPES = exports.COMMENT_REGEX = exports.COMMENT_KEYS = void 0;

var _COMMENTS, _RETURN_TYPES;

function _defineProperty(obj, key, value) {
  if (key in obj) {
    Object.defineProperty(obj, key, {
      value: value,
      enumerable: true,
      configurable: true,
      writable: true
    });
  } else {
    obj[key] = value;
  }

  return obj;
}

function _slicedToArray(arr, i) {
  return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _unsupportedIterableToArray(arr, i) || _nonIterableRest();
}

function _nonIterableRest() {
  throw new TypeError("Invalid attempt to destructure non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method.");
}

function _unsupportedIterableToArray(o, minLen) {
  if (!o) return;
  if (typeof o === "string") return _arrayLikeToArray(o, minLen);
  var n = Object.prototype.toString.call(o).slice(8, -1);
  if (n === "Object" && o.constructor) n = o.constructor.name;
  if (n === "Map" || n === "Set") return Array.from(o);
  if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen);
}

function _arrayLikeToArray(arr, len) {
  if (len == null || len > arr.length) len = arr.length;

  for (var i = 0, arr2 = new Array(len); i < len; i++) {
    arr2[i] = arr[i];
  }

  return arr2;
}

function _iterableToArrayLimit(arr, i) {
  var _i = arr == null ? null : typeof Symbol !== "undefined" && arr[Symbol.iterator] || arr["@@iterator"];

  if (_i == null) return;
  var _arr = [];
  var _n = true;
  var _d = false;

  var _s, _e;

  try {
    for (_i = _i.call(arr); !(_n = (_s = _i.next()).done); _n = true) {
      _arr.push(_s.value);

      if (i && _arr.length === i) break;
    }
  } catch (err) {
    _d = true;
    _e = err;
  } finally {
    try {
      if (!_n && _i["return"] != null) _i["return"]();
    } finally {
      if (_d) throw _e;
    }
  }

  return _arr;
}

function _arrayWithHoles(arr) {
  if (Array.isArray(arr)) return arr;
}
/* eslint-disable no-useless-escape */

/* ***************************
 * ENUMS & CONFIGS
 */


var STR_DELIMITER = '; ';
var STRING = 'string';
var NUMERIC = 'number';
var ARRAY = 'Array';
var FIRST_VALUE = 'FIRST_VALUE';
var COMMENT_KEYS = {
  RECTIFIER: 'RECTIFIER',
  AERIAL: 'AERIAL',
  VALVE: 'VALVE',
  TEST_STATION: 'TEST_STATION',
  LINE_MARKER: 'LINE_MARKER',
  FOREIGN_LINE: 'FOREIGN_LINE',
  PIPE_DEPTH: 'PIPE_DEPTH',
  AC: 'AC'
};
exports.COMMENT_KEYS = COMMENT_KEYS;

var defaultParser = function defaultParser(v) {
  return v;
};

var COMMENTS = (_COMMENTS = {}, _defineProperty(_COMMENTS, COMMENT_KEYS.RECTIFIER, {
  regex: /RECTIFIER/gi,
  valueType: STRING,
  parser: defaultParser
}), _defineProperty(_COMMENTS, COMMENT_KEYS.AERIAL, {
  regex: /AERIAL/gi,
  valueType: STRING,
  parser: defaultParser
}), _defineProperty(_COMMENTS, COMMENT_KEYS.VALVE, {
  regex: /VALVE/gi,
  valueType: STRING,
  parser: defaultParser
}), _defineProperty(_COMMENTS, COMMENT_KEYS.TEST_STATION, {
  regex: /TEST/gi,
  valueType: STRING,
  parser: defaultParser
}), _defineProperty(_COMMENTS, COMMENT_KEYS.LINE_MARKER, {
  regex: /LINE MARKER/gi,
  valueType: STRING,
  parser: defaultParser
}), _defineProperty(_COMMENTS, COMMENT_KEYS.FOREIGN_LINE, {
  regex: [/POWER LINE/gi, /RIVER/gi, /LINE CROSSING?/gi // Foreign Pipeline Crossing
  ],
  valueType: STRING,
  parser: defaultParser
}), _defineProperty(_COMMENTS, COMMENT_KEYS.PIPE_DEPTH, {
  regex: /PIPE ?DEPTH=\d+(\.\d+)?(?=(\s|$))/i,
  valueType: NUMERIC,
  parser: function parser(str) {
    var depth = parseFloat(str.split('=')[1], 10);
    var isValidDepth = !Number.isNaN(depth);

    if (isValidDepth) {
      var formattedDepth = +depth.toFixed(2);
      return formattedDepth;
    }

    return undefined;
  }
}), _defineProperty(_COMMENTS, COMMENT_KEYS.AC, {
  regex: [/(?<=(^|\s)\(?acv?(:|\/)\s*)\d+(\.(?=\d))?\d*(?=v\)?($|\s))/i, /(?<=RECONNECT AC:)( FG\/[\d\.]+(\/[\d\.]+)?)? NG\/[\d\.]+/i, /(?<=PS AC: )[\d\.]+/i],
  valueType: NUMERIC,
  parser: function parser(rawStr) {
    var str = rawStr;

    if (/ NG\//i.test(rawStr)) {
      var _rawStr$split$1$match = rawStr.split(/ NG\//i)[1].match(/[\d\.]+/i);

      var _rawStr$split$1$match2 = _slicedToArray(_rawStr$split$1$match, 1);

      str = _rawStr$split$1$match2[0];
    }

    var ac = Number.parseFloat(str, 10);

    if (!Number.isNaN(ac)) {
      return +ac.toFixed(4);
    }

    return undefined;
  }
}), _COMMENTS);
var COMMENT_REGEX = Object.keys(COMMENTS).reduce(function (acc, k) {
  acc[k] = COMMENTS[k].regex;
  return acc;
}, {});
exports.COMMENT_REGEX = COMMENT_REGEX;
var RETURN_TYPES = (_RETURN_TYPES = {}, _defineProperty(_RETURN_TYPES, STRING, STRING), _defineProperty(_RETURN_TYPES, ARRAY, ARRAY), _defineProperty(_RETURN_TYPES, FIRST_VALUE, FIRST_VALUE), _RETURN_TYPES);
/* ***************************
 * HELPERS & UTILS
 */

exports.RETURN_TYPES = RETURN_TYPES;

var isDefined = function isDefined(v) {
  return v !== undefined && v !== null;
};

var _arrWrap = function _arrWrap(val) {
  if (!val || val instanceof Array) {
    return val;
  }

  return [val];
};

var _selectRegexes = function _selectRegexes(key) {
  return _arrWrap((COMMENTS[key] || {}).regex);
};

var _selectParsers = function _selectParsers(key) {
  return _arrWrap((COMMENTS[key] || {}).parser || defaultParser);
};

var _selectValueType = function _selectValueType(key) {
  return (COMMENTS[key] || {}).valueType || STRING;
};
/* ***************************
 * boolean: HAS COMMENT = public
 */


var hasComment = function hasComment(_ref) {
  var text = _ref.text,
      commentKey = _ref.commentKey;

  var regexes = _selectRegexes(commentKey);

  if (!regexes || !text) {
    return false;
  }

  return regexes.some(function (re) {
    var _re = new RegExp(re); // clone the regular expression to prevent state bugs caused by .test()


    return _re.test(text);
  });
};
/* ***************************
 * string: SUB PARSER - private
 */


exports.hasComment = hasComment;

var parseStrComment = function parseStrComment(_ref2) {
  var text = _ref2.text,
      commentKey = _ref2.commentKey,
      _ref2$returnType = _ref2.returnType,
      returnType = _ref2$returnType === void 0 ? STRING : _ref2$returnType;

  if (!hasComment({
    text: text,
    commentKey: commentKey
  })) {
    return undefined;
  }

  var regexes = _selectRegexes(commentKey);

  var parsers = _selectParsers(commentKey);

  var result = regexes.map(function (re, idx) {
    var _re = new RegExp(re); // clone the regular expression to prevent state bugs caused by .exec()


    var parse = parsers[idx] || parsers[0];

    var matches = _re.exec(text);

    return matches ? parse(matches[0]) : undefined;
  }).filter(function (v) {
    return isDefined(v);
  });

  switch (returnType) {
    case FIRST_VALUE:
      return result[0];

    case ARRAY:
      return result;

    case STRING:
      return result.join(STR_DELIMITER);

    default:
      return result.join(STR_DELIMITER);
  }
};
/* ***************************
 * numeric: SUB PARSER - private
 */


var parseNumComment = function parseNumComment(_ref3) {
  var text = _ref3.text,
      commentKey = _ref3.commentKey,
      _ref3$returnType = _ref3.returnType,
      returnType = _ref3$returnType === void 0 ? FIRST_VALUE : _ref3$returnType;

  if (!hasComment({
    text: text,
    commentKey: commentKey
  })) {
    return undefined;
  }

  var regexes = _selectRegexes(commentKey);

  var parsers = _selectParsers(commentKey);

  var result = regexes.map(function (re, idx) {
    var _re = new RegExp(re); // clone the regular expression to prevent state bugs caused by .exec()


    var parse = parsers[idx] || parsers[0];

    var matches = _re.exec(text);

    return matches ? parse(matches[0]) : undefined;
  }).filter(function (v) {
    return isDefined(v);
  });

  switch (returnType) {
    case ARRAY:
      return result;

    case FIRST_VALUE:
      return result[0];

    default:
      return result[0];
  }
};
/* ***************************
 * MAIN = public
 */


var parseComment = function parseComment(_ref4) {
  var text = _ref4.text,
      commentKey = _ref4.commentKey,
      returnType = _ref4.returnType;

  var valueType = _selectValueType(commentKey);

  switch (valueType) {
    case NUMERIC:
      return parseNumComment({
        text: text,
        commentKey: commentKey,
        returnType: returnType
      });

    case STRING:
      return parseStrComment({
        text: text,
        commentKey: commentKey,
        returnType: returnType
      });

    default:
      // return string parse by default
      return parseStrComment({
        text: text,
        commentKey: commentKey,
        returnType: returnType
      });
  }
};

exports.parseComment = parseComment;
var _default = {
  COMMENT_KEYS: COMMENT_KEYS,
  // dictionary of keys that can be used to select desired regex
  COMMENT_REGEX: COMMENT_REGEX,
  // dictionary allowing direct access to regex by key
  RETURN_TYPES: RETURN_TYPES,
  // dictionary of possible return types FIRST_VALUE, ARRAY, STRING.  Numeric parsers only allow FIRST_VALUE & ARRAY.
  hasComment: hasComment,
  parseComment: parseComment
};
exports["default"] = _default;