const EMPTY_ARRAY = [];

export const splitArray = (array, fnCompare) => {
	if (!array) {
		return [undefined, undefined];
	}

	const array1 = array.filter(fnCompare);
	const array2 = array.filter((...args) => !fnCompare(...args));

	return [array1, array2];
};

export const arrayToMapExists = arr => {
	if (!arr) {
		return undefined;
	}

	return arr.reduce((acc, v) => {
		acc[v] = true;
		return acc;
	}, {});
};

const returnTrue = () => true;
export const arrayToMap = (arr = EMPTY_ARRAY, options = {}) => {
	const { key = 'id', filter = returnTrue } = options;
	return arr.reduce((acc, o) => {
		if (filter(o)) {
			acc[o[key]] = o;
		}
		return acc;
	}, {});
};

export default {
	splitArray
};
