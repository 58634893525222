import {
	EXTENT_CHANGED,
	SET_MAP,
	SET_HIGHLIGHT,
	SET_SHOW_COMMENTS_MODAL,
	SET_TOGGLE_COMMENT_ICONS,
	SET_TOGGLE_ALL_COMMENT_ICONS,
	SET_TOGGLE_EXCLUDE_COMMENT
} from '../actions/maps';

import piIcon from '../../images/CISView/PI-icon.jpg';
import edgeCommentIcon from '../../images/CISView/edge-orig.png';
import foreignCrossingCommentIcon from '../../images/CISView/foreignlinecrossing-orig.png';
import rectifierCommentIcon from '../../images/CISView/rectifier-orig.png';
import riserCommentIcon from '../../images/CISView/riser-orig.png';
import testStationCommentIcon from '../../images/CISView/teststation-orig.png';
import valveCommentIcon from '../../images/CISView/valve-orig.png';
import tsEdgeCommentIcon from '../../images/CISView/edge-cyan.png';
import TSPiIcon from '../../images/CISView/pi-cyan.png';
import TSForeignCrossingCommentIcon from '../../images/CISView/foreignlinecrossing-cyan.png';
import TSRectifierCommentIcon from '../../images/CISView/rectifier-cyan.png';
import TSRiserCommentIcon from '../../images/CISView/riser-cyan.png';
import TSTestStationCommentIcon from '../../images/CISView/teststation-cyan.png';
import TSValveCommentIcon from '../../images/CISView/valve-cyan.png';
import { getCommentsToExcludeRegex } from './utils/mapComments';

const edgeRegex =
	'((\\s|^)EDGE(\\s|$)|(\\s|^)START SKIP(\\s|$)|(\\s|^)BEGIN SKIP(\\s|$)|(\\s|^)END SKIP(\\s|$)|(\\s|^)START OFFSET(\\s|$)|(\\s|^)BEGIN OFFSET(\\s|$)|(\\s|^)END OFFSET(\\s|$))';

const piRegex = '(\\s|^)PI(\\s|$)';

const tsRegex = '(\\s|^)TEST STATION(\\s|$)';

const valveRegex = '(\\s|^)VALVE(\\s|$)';

const tapRegex = '(\\s|^)TAP(\\s|$)';

const rectifierRegex = '(\\s|^)RECTIFIER(\\s|$)';

const riserRegex = '(\\s|^)RECTIFIER(\\s|$)|(\\s|^)RECT(\\s|$)';

const foreignLineCrossingRegex = '((\\s|^)LINE CROSSING|(\\s|^)LINE XING)';

// Will match "PIPE DEPTH=4.25 P"
export const pipeDepthRegex =
	'(\\s|^)PIPE DEPTH\\s?=\\s?[0-9]?[.+]{0,1}[0-9]+\\s?((E|P))?(\\s|$)';

export const READINGS_MAP_COMMENTS_EXCLUDE = {
	pipeDepth: {
		label: 'PIPE DEPTH=',
		checked: false,
		pattern: pipeDepthRegex
	}
};

const READING_MAP_ICONS = {
	pi: {
		label: 'PI',
		show: true,
		pattern: piRegex,
		icon: piIcon
	},
	testStation: {
		label: 'Test Station',
		show: true,
		pattern: tsRegex,
		icon: testStationCommentIcon
	},
	valve: {
		label: 'Valve',
		show: true,
		pattern: valveRegex,
		icon: valveCommentIcon
	},
	tap: {
		label: 'Tap',
		show: true,
		pattern: tapRegex,
		icon: valveCommentIcon
	},
	rectifier: {
		label: 'Rectifier',
		show: true,
		pattern: rectifierRegex,
		icon: rectifierCommentIcon
	},
	edge: {
		label: 'Edge',
		show: true,
		pattern: edgeRegex,
		icon: edgeCommentIcon
	},
	TSedge: {
		label: 'Edge',
		show: true,
		pattern: edgeRegex,
		icon: tsEdgeCommentIcon
	},

	riser: {
		label: 'Riser',
		show: true,
		pattern: riserRegex,
		icon: riserCommentIcon
	},
	foreignLineCrossing: {
		label: 'Foreign Line Crossing',
		show: true,
		pattern: foreignLineCrossingRegex,
		icon: foreignCrossingCommentIcon
	},
	TSPi: {
		label: 'PI',
		show: true,
		pattern: piRegex,
		icon: TSPiIcon
	},
	TSTestStation: {
		label: 'Test Station',
		show: true,
		pattern: tsRegex,
		icon: TSTestStationCommentIcon
	},
	TSValve: {
		label: 'Valve',
		show: true,
		pattern: valveRegex,
		icon: TSValveCommentIcon
	},
	TSTap: {
		label: 'Tap',
		show: true,
		pattern: tapRegex,
		icon: TSValveCommentIcon
	},
	TSRectifier: {
		label: 'Rectifier',
		show: true,
		pattern: rectifierRegex,
		icon: TSRectifierCommentIcon
	},
	TSRiser: {
		label: 'Riser',
		show: true,
		pattern: riserRegex,
		icon: TSRiserCommentIcon
	},
	TSForeignLineCrossing: {
		label: 'Foreign Line Crossing',
		show: true,
		pattern: foreignLineCrossingRegex,
		icon: TSForeignCrossingCommentIcon
	}
};

const defaultState = {
	map: undefined,
	center: [40.0, -100.0],
	level: 3,
	maxZoom: 24,
	highlight: undefined,
	showcommentsModal: false,
	selectAllIcons: true,
	commentIcons: READING_MAP_ICONS,
	commentExclude: READINGS_MAP_COMMENTS_EXCLUDE,
	commentPatternToExclude: []
};

const toggleCommentIcons = (state, key) => {
	const { commentIcons } = state;
	return {
		...state,
		commentIcons: {
			...commentIcons,
			[key]: {
				...commentIcons[key],
				show: !commentIcons[key].show
			}
		}
	};
};

const toggleExcludeComment = (state, key) => {
	const { commentExclude } = state;
	const newCommentExclude = {
		...commentExclude,
		[key]: {
			...commentExclude[key],
			checked: !commentExclude[key].checked
		}
	};
	return {
		...state,
		commentExclude: newCommentExclude,
		commentPatternToExclude: getCommentsToExcludeRegex(newCommentExclude)
	};
};

const toggleAllIcons = state => {
	const { selectAllIcons, commentIcons } = state;

	const newCommentIcons = {};
	Object.keys(commentIcons).forEach(key => {
		newCommentIcons[key] = {
			...commentIcons[key],
			...{ show: !selectAllIcons }
		};
	});

	return {
		...state,
		selectAllIcons: !selectAllIcons,
		commentIcons: newCommentIcons
	};
};

const reducer = (state = defaultState, action) => {
	const { type, payload } = action;
	switch (type) {
		case SET_HIGHLIGHT:
			return { ...state, highlight: payload };
		case EXTENT_CHANGED:
			return { ...state, ...payload };
		case SET_MAP:
			return { ...state, map: payload };
		case SET_SHOW_COMMENTS_MODAL:
			return {
				...state,
				showcommentsModal: payload.showcommentsModal
			};
		case SET_TOGGLE_COMMENT_ICONS:
			return toggleCommentIcons(state, payload.key);
		case SET_TOGGLE_ALL_COMMENT_ICONS:
			return toggleAllIcons(state);
		case SET_TOGGLE_EXCLUDE_COMMENT:
			return toggleExcludeComment(state, payload.key);
		default:
			return state;
	}
};

export default reducer;
