/* eslint-disable import/no-cycle */
import L from 'leaflet';

import { setIsEdited } from './editorToolbar';

import {
	toolbarUtils,
	getClosestGPSReading
} from '../../components/leaflet/utils/ToolbarUtils';

import {
	getCommentPoints,
	getDistances,
	getSmoothLine
} from './util/SmoothTool';
import { getDatFilesAlreadySmoothedByState } from '../../utils/DatFiles';

const NS_MAPS = 'cisview_editorToolbar_';

export const SET_SMOOTH_CLOSEST_POINT = `${NS_MAPS}SET_SMOOTH_CLOSEST_POINT`;
const setSmoothClosestPoint = point => {
	return { type: SET_SMOOTH_CLOSEST_POINT, payload: point };
};

export const SET_SMOOTH_POINTS = `${NS_MAPS}SET_SMOOTH_POINTS`;
export const setcurrentSmoothPoints = (points, smoothLine, commentPoints) => {
	return {
		type: SET_SMOOTH_POINTS,
		payload: { points, smoothLine, commentPoints }
	};
};

export const smoothClick = () => (dispatch, getState) => {
	const state = getState();
	const { cisview } = state;
	const {
		editorToolbar: { smoothTool }
	} = cisview;

	const { data } = getDatFilesAlreadySmoothedByState(state);
	const {
		closestSmooth,
		currentSmoothPoints: statecurrentSmoothPoints
	} = smoothTool;

	const currentSmoothPoints = [...statecurrentSmoothPoints];
	if (closestSmooth == null) {
		return;
	}

	// We do not want to smooth from one dat file to another
	if (
		currentSmoothPoints.length > 0 &&
		closestSmooth.dat.fileName !== currentSmoothPoints[0].point.dat.fileName
	) {
		return;
	}

	const { stationNum: closestStationNum } = closestSmooth;

	const isNewAdditonalPoint = !currentSmoothPoints.some(pnt => {
		const stationNum = toolbarUtils.getStationNumPoint(pnt);
		return closestStationNum === stationNum;
	});

	if (isNewAdditonalPoint) {
		const [lng, lat] = closestSmooth.geometry.coordinates;
		currentSmoothPoints.push({ point: closestSmooth, center: [lat, lng] });
		currentSmoothPoints.sort((a, b) => a.point.gpsIndx - b.point.gpsIndx);
	}
	const smoothLine = getSmoothLine(currentSmoothPoints);
	const commentPoints = getCommentPoints(currentSmoothPoints, data);

	const smoothPointsWithDistance = getDistances(currentSmoothPoints, data);

	dispatch(setIsEdited(true));
	dispatch(
		setcurrentSmoothPoints(smoothPointsWithDistance, smoothLine, commentPoints)
	);
};

const getCurrentDistances = (currentSmoothPoints, point, lat, lng, data) => {
	if (currentSmoothPoints.length > 0) {
		const newSmoothPoint = { point, center: [lat, lng] };
		currentSmoothPoints.push(newSmoothPoint);
		currentSmoothPoints.sort((a, b) => a.point.gpsIndx - b.point.gpsIndx);
		const idx = currentSmoothPoints.indexOf(newSmoothPoint);
		const smoothPointsWithDistance = getDistances(
			currentSmoothPoints.slice(Math.max(0, idx - 1), idx + 2),
			data
		);
		return smoothPointsWithDistance
			.filter(({ prevDistance: distance }) => distance !== undefined)
			.map(({ prevDistance: distance }) => distance);
	}
	return undefined;
};

// Hammer Step 2 -> User moves while hammer is activated
export const smoothMouseMove = latlng => (dispatch, getState) => {
	const state = getState();
	const { cisview } = state;
	const { maps } = cisview;
	const { map } = maps;
	const {
		editorToolbar: { smoothTool }
	} = cisview;
	const { currentSmoothPoints: statecurrentSmoothPoints } = smoothTool;
	const currentSmoothPoints = [...statecurrentSmoothPoints];

	const { data, boundsByFile } = getDatFilesAlreadySmoothedByState(state);

	const mapBounds = map.getBounds();

	const point = getClosestGPSReading({
		dats: data,
		mapBounds,
		latlng,
		boundsByFile
	});

	if (!point) {
		return;
	}
	const [lng, lat] = point.geometry.coordinates;
	const rlatlng = L.latLng([lat, lng]);
	const nearestPixel = map.latLngToLayerPoint(rlatlng);
	const layerPoint = map.latLngToLayerPoint(latlng);
	const pixelDistance = Math.sqrt(
		(layerPoint.x - nearestPixel.x) ** 2 + (layerPoint.y - nearestPixel.y) ** 2
	);
	if (pixelDistance < 20.0) {
		const distances = getCurrentDistances(
			currentSmoothPoints,
			point,
			lat,
			lng,
			data
		);
		dispatch(
			setSmoothClosestPoint({
				smoothClosestPoint: { center: [lat, lng], distances },
				closestSmooth: point
			})
		);
	} else {
		dispatch(
			setSmoothClosestPoint({
				smoothClosestPoint: null,
				closestSmooth: null
			})
		);
	}
};

export const EXIST_SMOOTH_POINTS = `${NS_MAPS}EXIST_SMOOTH_POINTS`;
export const smoothDoubleClick = () => (dispatch, getState) => {
	const state = getState();
	const { cisview } = state;
	const {
		editorToolbar: { smoothTool }
	} = cisview;
	const {
		existingSmoothPoints: stateExistingSmoothPoints,
		currentSmoothPoints
	} = smoothTool;

	// Smoothed line needs at least 2 points to smooth a line
	if (currentSmoothPoints.length < 2) {
		return;
	}
	const existingSmoothPoints = [
		...stateExistingSmoothPoints,
		[...currentSmoothPoints]
	];

	const smoothLines = existingSmoothPoints.map(sp => getSmoothLine(sp));
	dispatch({
		type: EXIST_SMOOTH_POINTS,
		payload: { existingSmoothPoints, existingSmoothLines: smoothLines }
	});
};
