export const addSegmentGapSmooth = (datFile, gpsReading) => ({
	type: 'CISV_SEGMENT_GAP_ADD_GAP_SMOOTH',
	datFile,
	gpsReading
});

export const setHoveredPoint = hoveredPointCoords => ({
	type: 'CISV_SEGMENT_GAP_ADD_HOVERD_POINT',
	hoveredPointCoords
});

export const setSegmentGapReadingsShouldChange = data => ({
	type: 'CISV_SEGMENT_GAP_UDPATE_DATA',
	data
});

export const resetSegmentGapAnalysis = () => ({
	type: 'CISV_SEGMENT_GAP_RESET'
});

export const undoItem = gpsReading => ({
	type: 'CISV_SEGMENT_UNDO_ITEM',
	gpsReading
});
