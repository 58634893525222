export const SET_FILTERS = 'SET_FILTERS';

export const ADD_FILTER_TO_LIST = 'ADD_FILTER_TO_LIST';
export const REMOVE_FILTER_FROM_LIST = 'REMOVE_FILTER_FROM_LIST';
export const REMOVE_ALL_FILTERS_FROM_LIST = 'REMOVE_ALL_FILTERS_FROM_LIST';

export const ADD_HIDDEN_FILTER_TO_LIST = 'ADD_HIDDEN_FILTER_TO_LIST';
export const REMOVE_HIDDEN_FILTER_FROM_LIST = 'REMOVE_HIDDEN_FILTER_FROM_LIST';
export const REMOVE_ALL_HIDDEN_FILTERS_FROM_LIST =
	'REMOVE_ALL_HIDDEN_FILTERS_FROM_LIST';

export const SET_SEARCH_TERM = 'SET_SEARCH_TERM';
export const RESET_SEARCH = 'RESET_SEARCH';

export const SEARCH_FILTER_SET_TOKEN = 'SEARCH_FILTER_SET_TOKEN';
export const SEARCH_FILTER_START = 'SEARCH_FILTER_START';
export const SEARCH_FILTER_SUCCESS = 'SEARCH_FILTER_SUCCESS';
export const SEARCH_FILTER_FAIL = 'SEARCH_FILTER_FAIL';
