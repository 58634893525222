import {
	SET_IMAGES,
	SET_SELECTED_IMAGE,
	SET_NEW_VIDEO_TIME,
	SET_VIDEO,
	SET_VIDEOS
} from '../actions/main';

const defaultState = {
	images: [],
	selectedImage: undefined,
	nextImage: undefined,
	video: undefined,
	newVideoTime: undefined,
	videos: []
};

const reducer = (state = defaultState, action) => {
	const { type, payload } = action;
	switch (type) {
		case SET_IMAGES:
			return { ...state, images: payload };
		case SET_SELECTED_IMAGE:
			return {
				...state,
				selectedImage: payload.selectedImage,
				nextImage: payload.nextImage
			};
		case SET_NEW_VIDEO_TIME:
			return { ...state, newVideoTime: payload };
		case SET_VIDEO:
			return { ...state, video: payload.video, images: payload.images };
		case SET_VIDEOS:
			return { ...state, videos: payload };
		default:
			return state;
	}
};
export default reducer;
