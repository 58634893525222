export const EMPTY_OBJECT = {};

export const EMPTY_ARRAY = [];

export const ERROR_ARRAY_UNDEFINED =
	'Array manip failed: array undefined (reducers/slices/Charts)';
export const ERROR_INJECT_ITEM_EXISTS =
	'Array injection failed: item already exists (reducers/slices/Charts)';
export const ERROR_UPDATE_ITEM_DOES_NOT_EXIST =
	'Array update failed: item does not exist (reducers/slices/Charts)';

export default {
	EMPTY_OBJECT,
	EMPTY_ARRAY,
	ERROR_ARRAY_UNDEFINED,
	ERROR_INJECT_ITEM_EXISTS,
	ERROR_UPDATE_ITEM_DOES_NOT_EXIST
};
