/* eslint-disable import/no-cycle */
import { createActions } from 'redux-actions';
import { createThunkComputeBelowCriterions } from './actions.computeBelowCriterions';

const { addChartBelowCriterions, removeChartBelowCriterions } = createActions(
	{
		// BASE
		ADD_CHART_BELOW_CRITERIONS: (chartId, datasets) => {
			return { chartId, datasets };
		},
		REMOVE_CHART_BELOW_CRITERIONS: chartId => {
			return { chartId };
		}
	},
	{
		prefix: 'ChartsBelowCriterions'
	}
);

const thunkComputeBelowCriterions = createThunkComputeBelowCriterions(
	addChartBelowCriterions,
	removeChartBelowCriterions
);

// eslint-disable-next-line import/prefer-default-export
export {
	addChartBelowCriterions,
	removeChartBelowCriterions,
	thunkComputeBelowCriterions
};
