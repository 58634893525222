import { capitalize } from "../util";

export const buildInitialState = filtersStatePropKey => {
	const propKey = filtersStatePropKey;
	const capPropKey = capitalize(propKey);

	return {
		[propKey]: {},
		[`isLoading${capPropKey}`]: false,
		[`isLoaded${capPropKey}`]: false,
		[`load${capPropKey}ErrorMessage`]: undefined
	}
};

export default { buildInitialState };
