const { default: scanlineMapUtils } = require('../../../../mapUtils');

/* eslint-disable camelcase */
const CIS = 'CIS';
const ONOFF_A = 'ON_OFF';
const ONOFF_B = 'ON-OFF';
const ON = 'ON';
const DEPOL = 'DEPOL';

const POSSIBLE_LINKS = {
	[ONOFF_A]: [DEPOL],
	[ONOFF_B]: [DEPOL],
	[ON]: [DEPOL],
	[DEPOL]: [ONOFF_A, ONOFF_B, ON]
};

const isCISSurveyId = id => {
	if (typeof id !== 'string') {
		return false;
	}
	const parts = id.split('-');
	return parts[0].toUpperCase() === 'CIS';
};

const parseSurveyId = id => {
	if (!isCISSurveyId(id)) {
		return {};
	}

	const parts = id.split('-');
	parts.shift();
	if (parts[0].toUpperCase() === 'ON') {
		// handle ON-OFF
		if (parts[1].toUpperCase() === 'OFF') {
			parts.shift();
			parts.shift();
			return { survey_subtype: ONOFF_B, jobId: parts.join('-') || undefined };
		}
		// handle ON
		parts.shift();
		return { survey_subtype: ON, jobId: parts.join('-') || undefined };
	}
	// handle ON_OFF & DEPOL
	const survey_subtype = parts.shift();
	return { survey_subtype, jobId: parts.join('-') || undefined };
};

const computePossibleLinkedSurveyIds = surveyId => {
	if (!isCISSurveyId(surveyId)) {
		return [];
	}

	const { survey_subtype, jobId } = parseSurveyId(surveyId);
	const possibleSubTypes = POSSIBLE_LINKS[survey_subtype];
	if (!possibleSubTypes) {
		return [];
	}

	const result = possibleSubTypes.reduce((acc, pst) => {
		const possibleSurveyId = scanlineMapUtils.createSurveyId(CIS, pst, jobId);
		if (acc.indexOf(possibleSurveyId) < 0) {
			acc.push(possibleSurveyId);
		}
		return acc;
	}, []);

	return result;
};

export { isCISSurveyId, computePossibleLinkedSurveyIds };

export default {
	isCISSurveyId,
	computePossibleLinkedSurveyIds
};
