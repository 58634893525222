import {
	calculateDistance,
	config
} from 'aegion_common_utilities/lib/MapProcessingUtil';

/** ***********
 * MAIN - SHRINK
 */

import { cloneAndAlignTo } from './misc';

const getDistance = (currentCoords, otherCoords) => {
	return calculateDistance(currentCoords, otherCoords, {
		// We want to make sure that depol alignment always stays the same for the report
		algorithm: config.GEO_ALGORITHMS.TURF,
		to: config.UNITS.DISTANCE.KILOMETERS
	});
};

export const createRanks = targetReadings => {
	const ranks = [];
	for (let i = 1, n = targetReadings.length - 1; i < n; i += 1) {
		const prev = targetReadings[i - 1];
		const curr = targetReadings[i];
		const next = targetReadings[i + 1];

		const prevDistance = getDistance(prev.loc, curr.loc);
		const nextDistance = getDistance(curr.loc, next.loc);

		ranks.push({ i, rank: prevDistance + nextDistance });
	}
	ranks.sort((a, b) => a.rank - b.rank);
	return ranks;
};

const shrinkReadings = (
	cisReadings,
	cisReadingsMap,
	targetReadings,
	subtype,
	{ depolKey, depolFlagKey, targetValueKey },
	shouldCopyOriginalDepol
) => {
	const copyDepolValue = (cisReading, depolReading) => {
		if (subtype === 'DEPOL') {
			// eslint-disable-next-line no-param-reassign
			cisReading[depolKey] = depolReading[targetValueKey];
			if (depolFlagKey) {
				// eslint-disable-next-line no-param-reassign
				cisReading[depolFlagKey] = true;
			}
		}
	};

	const copyOriginalDepolValue = depolReading => {
		if (subtype === 'DEPOL' && shouldCopyOriginalDepol) {
			const cisReading = cisReadingsMap[depolReading.id];
			if (cisReading) {
				cisReading.depol = depolReading[targetValueKey];
			}
		}
	};

	const ranks = createRanks(targetReadings);

	const indexMap = {};
	const diff = targetReadings.length - cisReadings.length;
	for (let i = 0; i < diff; i += 1) {
		indexMap[ranks[i].i] = 1;
	}

	const data = targetReadings.filter((reading, index) => !indexMap[index]);

	return data.map((reading, index) => {
		copyOriginalDepolValue(reading);
		copyDepolValue(cisReadings[index], reading);
		return cloneAndAlignTo(reading, cisReadings[index], depolFlagKey);
	});
};

export default shrinkReadings;
