import { assumeCognitoUser } from '../../../cognito';
import getAipUrls from '../../../../config/getAipUrls';

const aipUrls = getAipUrls();

// eslint-disable-next-line import/prefer-default-export
export const putObject = async (key, data, versioned = false) => {
	const AWS = await assumeCognitoUser();
	const s3 = new AWS.S3({ apiVersion: '2006-03-01' });
	const bucket = versioned ? aipUrls.versionedBucket : aipUrls.bucket;
	const newKey = key.replace('{user}', AWS.config.credentials.identityId);
	const params = {
		Body: data,
		Bucket: bucket,
		Key: newKey
	};
	await s3.putObject(params).promise();

	if (!versioned) {
		return { bucket, key: newKey };
	}
	const {
		Versions: [{ VersionId: versionId }]
	} = await s3
		.listObjectVersions({ Bucket: bucket, Prefix: newKey, MaxKeys: '1' })
		.promise();

	return { versionId, bucket, key: newKey };
};
