import { NAME_SPACE as ns } from './constants';

export const CLEAR = `CLEAR`;

export const SET_OLD_ASSET = `${ns}_SET_OLD_ASSET`;

export const SET_NEW_ASSET = `${ns}_SET_NEW_ASSET`;

export const SET_MERGE_PREVIEW = `${ns}_SET_MERGE_PREVIEW`;

export const SET_MERGE_PREVIEW_DIFF = `${ns}_SET_MERGE_PREVIEW_DIFF`;

export const SET_IS_MERGING = `${ns}_SET_IS_MERGING`;

export const SET_IS_MERGED = `${ns}_SET_IS_MERGED`;

export const SET_MERGE_ERROR_MESSAGE = `${ns}_SET_MERGE_ERROR_MESSAGE`;

export const SET_COPY_MISSING_PROPS = `${ns}_SET_COPY_MISSING_PROPS`;

export const SET_IS_MERGE_APPROVED = `${ns}_SET_IS_MERGE_APPROVED`;
