import { SET_ASSET_DETAILS } from './actionTypes';

const initialState = {
	display: undefined,
	selected: {}
};

const reducer = (state = initialState, action) => {
	const { type, payload } = action;
	switch (type) {
		case SET_ASSET_DETAILS: {
			const rtn = { ...state, ...payload };
			return rtn;
		}
		default:
			return state;
	}
};

export default reducer;
