/* eslint-disable import/prefer-default-export */
import { TYPE_KEY_COMPANY, TYPE_KEY_USER } from '../constants';

export const getEntityType = (item = {}) => item.entityType;

const isUser = item => getEntityType(item) === TYPE_KEY_USER;
const getUserId = user => user.id;

const isCompany = item => getEntityType(item) === TYPE_KEY_COMPANY;
const getCompanyId = company => company.id;

export const getId = (item = {}) => {
	if (isUser(item)) {
		return getUserId(item);
	}

	if (isCompany(item)) {
		return getCompanyId(item);
	}

	return item.id;
};
