// import { maxBy, minBy } from 'lodash';
import _ from 'lodash';

import {
	OPTIMUM_ZOOM_IN_FEET,
	defaultState,
	getOnName
} from '../actions/util/spike-editor-config';

import {
	getDefaultLayout,
	getXAxisWithTicks,
	getXRange,
	getDefaultXRange,
	getTraces,
	_getTracesFromXRange,
	calculateCommentY,
	getHighlightedTrace,
	getReadingRowSelectedTraces,
	getSelectActionsFromNewChartData,
	getUpdatedCriteriaLineTrace,
	getMvValue,
	getMinYRange,
	getMinMaxXfromState,
	getPaginationStatus
} from '../actions/workers/spike-editor-spike-graph.worker.utils';
import LocalStorageUtil from '../../utils/LocalStorageUtil';

const { maxBy, minBy, cloneDeep } = _;

// Adds layout to a stack of changes
const getUpdateStateWithNewNavigationChanges = (state, event = {}) => {
	const {
		navigationChangesByDatFile,
		selectedDatFile,
		immutableLayout
	} = state;

	// Ignore when user just changes the drag mode (by clicking the "zoom" or "select" boxes for example)
	if (event.dragmode) {
		return navigationChangesByDatFile;
	}

	// Ignore the autosize event (happens on page load)
	if (event.autosize) {
		return navigationChangesByDatFile;
	}

	if (!selectedDatFile || !immutableLayout) {
		return navigationChangesByDatFile;
	}

	const { fileName } = selectedDatFile;

	if (!fileName) {
		return navigationChangesByDatFile;
	}

	const changesByDatFile = navigationChangesByDatFile[fileName] || [];

	const newChanges = [...changesByDatFile, immutableLayout];

	const newNavigationChangesByDatFile = {
		...navigationChangesByDatFile,
		[fileName]: newChanges
	};

	return newNavigationChangesByDatFile;
};

const _getUpdatedCommentTrace = (state, commentY) => {
	const {
		traces,
		allTraces,
		allAnnotations: oldAllAnnotations,
		annotations: oldAnnotations,
		layout
	} = state;
	const commentTrace = traces.oldOnCommentTrace;
	const newCommentTraceY = commentTrace.y.map(() => commentY);

	const oldOnCommentTrace = {
		...commentTrace,
		y: newCommentTraceY
	};

	const allAnnotations = oldAllAnnotations.map(annotation => ({
		...annotation,
		y: commentY
	}));

	const annotations = oldAnnotations ? allAnnotations : null;
	const newTraces = {
		...traces,
		oldOnCommentTrace
	};
	return {
		...state,
		arrTraces: Object.values(newTraces),
		traces: newTraces,
		allTraces: {
			...allTraces,
			oldOnCommentTrace
		},
		annotations,
		allAnnotations,
		layout: {
			...layout,
			annotations
		}
	};
};

const updateLayoutWithAnnotations = state => {
	return {
		...state,
		layout: {
			...state.layout,
			annotations: state.annotations
		}
	};
};

const _getUpdatedCommentTraceIfChanged = state => {
	const { traces, currentYRange } = state;

	if (!traces.oldOnCommentTrace || !currentYRange) {
		return updateLayoutWithAnnotations(state);
	}

	if (!(traces.oldOnCommentTrace.y || []).length) {
		return updateLayoutWithAnnotations(state);
	}

	// All of the Y values are the same, just get the first one
	const oldCommentY = traces.oldOnCommentTrace.y[0];

	const commentY = calculateCommentY(currentYRange);

	if (oldCommentY === commentY) {
		return updateLayoutWithAnnotations(state); // Nothing to change
	}

	return _getUpdatedCommentTrace(state, commentY);
};

const updateStateFromNewDimensions = (state, event) => {
	const { layout } = state;
	// this was an actual navigation change (zooming or panning)

	const startXRange = event['xaxis.range[0]'];
	const endXRange = event['xaxis.range[1]'];
	let startYRange = event['yaxis.range[0]'];
	let endYRange = event['yaxis.range[1]'];

	const { xaxis, yaxis } = layout;

	if (startXRange === undefined) {
		return state; // this is a bad relayout that happens when the chart is initialized
	}

	if (startYRange === undefined || endYRange === undefined) {
		[startYRange, endYRange] = yaxis.range;
	}

	const currentXRange = [startXRange, endXRange];
	const currentYRange = [startYRange, endYRange];

	const newState = {
		...state,
		feetPerPage: Math.round(endXRange - startXRange),
		currentXRange,
		currentYRange,
		manualYRange: [Math.round(startYRange, 2), Math.round(endYRange, 2)],
		layout: {
			...layout,
			xaxis: {
				...xaxis,
				// autorange should on be set with new data - setting to true will force plotly to redraw to fix entire x-axis
				autorange: false,
				range: currentXRange
			},
			yaxis: {
				...yaxis,
				range: currentYRange
			}
		}
	};

	return newState;
};

const derriveStateFromLayout = (state, layout) => {
	const { yaxis, xaxis } = layout;

	const currentYRange = yaxis.range;
	const currentXRange = xaxis.range;

	const stateFromRangeUpdates = {
		...state,
		feetPerPage: Math.round(currentXRange[1] - currentXRange[0]),
		currentYRange,
		currentXRange,
		layout
	};

	const tracesData = _getTracesFromXRange(stateFromRangeUpdates);

	const stateWithNewTraces = {
		...stateFromRangeUpdates,
		...tracesData
	};

	const stateFromCommentUpdate = _getUpdatedCommentTraceIfChanged(
		stateWithNewTraces
	);

	const { xaxis: xAxisWithTicks } = getXAxisWithTicks(
		stateFromCommentUpdate,
		currentXRange,
		{ force: true }
	);

	const stateWithXaxisTicks = {
		...stateFromCommentUpdate,
		layout: {
			...stateFromCommentUpdate.layout,
			xaxis: {
				...xaxis,
				...xAxisWithTicks
			}
		}
	};

	return stateWithXaxisTicks;
};

const updateStateWithAutoRange = state => {
	const [startXRange, endXRange] = getDefaultXRange(state);
	const { layout } = state;
	const { xaxis, yaxis } = layout;

	const minYRange = getMinYRange(state);

	const currentXRange = [startXRange, endXRange];
	const currentYRange = [0, minYRange];

	return {
		...state,
		currentXRange,
		currentYRange,
		layout: {
			...layout,
			xaxis: {
				...xaxis,
				range: currentXRange
			},
			yaxis: {
				...yaxis,
				range: currentYRange
			}
		}
	};
};

const getUpdatedStateFromRelayoutEvent = (state, originalLayout, event) => {
	const { layout } = state;

	if (event.autosize) {
		return state;
	}
	if (event['xaxis.autorange']) {
		return updateStateWithAutoRange(state);
	}
	if (event.dragmode) {
		return {
			...state,
			layout: {
				...layout,
				dragmode: event.dragmode
			}
		};
	}
	if (event['xaxis.range[0]']) {
		return updateStateFromNewDimensions(state, event);
	}

	return state;
};

const updateStateFromRelayoutEvent = (state, originalLayout, event) => {
	const newState = getUpdatedStateFromRelayoutEvent(
		state,
		originalLayout,
		event
	);
	if (!newState) {
		return null;
	}

	return newState;
};

const extendStateWithTicks = state => {
	const { currentXRange, gridAxesSetting } = state;
	const { step } = gridAxesSetting.xaxis;

	const { xaxis: xAxisWithTicks } = getXAxisWithTicks(state, currentXRange, {
		force: true,
		step
	});

	const { xaxis } = state.layout;

	const stateWithXaxisTicks = {
		...state,
		layout: {
			...state.layout,
			xaxis: {
				...xaxis,
				...xAxisWithTicks
			}
		}
	};

	return stateWithXaxisTicks;
};

// TODO: Need to make sure this is not causing too much work on the chart
const onRelayout = (oldState, event) => {
	const navigationChangesByDatFile = getUpdateStateWithNewNavigationChanges(
		oldState,
		event
	);

	const { layout: originalLayout } = oldState;

	const stateFromInitialRelayout = updateStateFromRelayoutEvent(
		oldState,
		originalLayout,
		event
	);

	// There is an autolayout event which we do not want to do anything with - we can just send back the original statee
	if (!stateFromInitialRelayout) {
		return oldState;
	}

	const stateFromDerrivedLayout = {
		...stateFromInitialRelayout,
		...derriveStateFromLayout(
			stateFromInitialRelayout,
			stateFromInitialRelayout.layout
		),
		navigationChangesByDatFile
	};

	const stateWithXaxisTicks = extendStateWithTicks(stateFromDerrivedLayout);

	return {
		...stateWithXaxisTicks,
		paginationStatus: getPaginationStatus(stateWithXaxisTicks)
	};
};

// This sets the step value but does not refresh the chart info (that is done below)
const changeAxisStepHandler = (prevState, axis, value) => {
	let stepValue = parseInt(value, 10);
	if (Number.isNaN(stepValue)) {
		stepValue = 0;
	}

	const { gridAxesSetting } = prevState;

	// TODO: If step value == '', set to "0"
	return {
		...prevState,
		gridAxesSetting: {
			...gridAxesSetting,
			[axis]: { ...gridAxesSetting[axis], step: stepValue, refreshNeeded: true }
		}
	};
};

const refreshChartDataFromStepChange = (prevState, axis) => {
	const {
		layout,
		layout: { [axis]: axisToGet },
		gridAxesSetting
	} = prevState;

	const { step } = gridAxesSetting[axis];

	if (axis === 'yaxis') {
		return {
			...prevState,
			layout: { ...layout, [axis]: { ...axisToGet, dtick: step } }
		};
	}

	// For x-axis
	const currentXRange = getXRange(prevState) || getDefaultXRange(prevState);
	const { xaxis: xAxisWithTicks } = getXAxisWithTicks(
		prevState,
		currentXRange,
		{ step, force: true }
	);

	return {
		...prevState,
		layout: { ...layout, [axis]: { ...axisToGet, ...xAxisWithTicks } }
	};
};

// This refreshes chart data after step has changed (step was updated above)
const performRelayoutFromStepHandlerChange = (prevState, axis) => {
	const { gridAxesSetting } = prevState;
	const { refreshNeeded } = gridAxesSetting[axis];

	// Step did not originally change so there is no need to change chart data
	if (!refreshNeeded) {
		return prevState;
	}

	const stateFromChartChange = refreshChartDataFromStepChange(prevState, axis);

	const stateFromRemovingRefreshFlag = {
		...stateFromChartChange,
		gridAxesSetting: {
			...gridAxesSetting,
			[axis]: {
				...gridAxesSetting[axis],
				refreshNeeded: false
			}
		}
	};

	return stateFromRemovingRefreshFlag;
};

function getCriteriaLinesWithMv(mv, criteriaLines) {
	return {
		minimum: {
			...criteriaLines.minimum,
			value: getMvValue(criteriaLines.minimum.value, mv)
		},
		maximum: {
			...criteriaLines.maximum,
			value: getMvValue(criteriaLines.maximum.value, mv)
		}
	};
}

const getCriteriaLineTraceName = type => {
	switch (type) {
		case 'minimum':
			return 'minCriteriaLine';
		case 'maximum':
			return 'maxCriteriaLine';
		default:
			throw new Error(`Unknow criteria line type "${type}"`);
	}
};

const _updateTracePropertyFromOption = (trace, optionName, optionValue) => {
	switch (optionName) {
		// TODO: Currently only one option (color) is updated, but we can update others such as "display" and "options"
		case 'color':
			return {
				...trace,
				line: {
					...trace.line,
					color: optionValue
				}
			};
		default:
			throw new Error(
				`Unknown Option "${optionName}". Each new option should be included in the switch case block`
			);
	}
};

const _updateTracesFromOptionsChange = (
	state,
	traceName,
	optionName,
	optionValue
) => {
	const { traces: oldTraces } = state;

	const oldTrace = oldTraces[traceName];
	const newTrace = _updateTracePropertyFromOption(
		oldTrace,
		optionName,
		optionValue
	);

	const traces = {
		...oldTraces,
		[traceName]: newTrace
	};

	const arrTraces = Object.values(traces);

	return {
		...state,
		traces,
		arrTraces
	};
};

const _updateCriteriaLineTrace = (state, type = 'minimum') => {
	const { traces, allTraces, criteriaLines, useMv } = state;

	const criteriaLine = criteriaLines[type];

	const criteriaTraceName = getCriteriaLineTraceName(type);

	const oldCriteriaTrace = traces[criteriaTraceName];

	const criteriaTrace = getUpdatedCriteriaLineTrace(
		criteriaLine,
		oldCriteriaTrace,
		useMv
	);

	const newTraces = {
		...traces,
		[criteriaTraceName]: criteriaTrace
	};

	const newAllTraces = {
		...allTraces,
		[criteriaTraceName]: criteriaTrace
	};

	const arrTraces = Object.values(newTraces);

	// TODO: Set defaul value for trace
	return {
		...state,
		traces: newTraces,
		allTraces: newAllTraces,
		arrTraces
	};
};

const _updateStateWithAllCriteriaLineTraces = state => {
	let newState = state;

	['minimum', 'maximum'].forEach(type => {
		newState = _updateCriteriaLineTrace(newState, type);
	});

	return newState;
};

const calculateYRangeOnMvChange = (state, useMv) => {
	const { currentYRange } = state;

	const [y1, y2] = currentYRange;

	return [getMvValue(y1, useMv), getMvValue(y2, useMv)];
};

function getStateFromNewMV(state, newDataState) {
	const { useMv } = newDataState;

	const stateWithNewData = { ...state, ...newDataState };
	if (useMv === state.useMv) {
		return stateWithNewData;
	}

	const yRange = calculateYRangeOnMvChange(state, useMv);

	const {
		layout,
		layout: { xaxis, yaxis },
		criteriaLines: criteriaLinesOld
	} = state;

	const criteriaLines = getCriteriaLinesWithMv(useMv, criteriaLinesOld);

	const updatedState = {
		...stateWithNewData,
		criteriaLines,
		manualYRange: yRange,
		currentYRange: yRange,
		layout: {
			...layout,
			yaxis: { ...yaxis, range: yRange },
			xaxis: { ...xaxis }
		},
		useMv
	};

	const settings = {
		useChartDotsConfig: LocalStorageUtil.spikeEditor.getGraphDotVisibility()
	};

	const options = {
		shouldMergeTraces: true
	};

	const traceState = getTraces(updatedState, settings, options);

	const newTracesState = {
		...updatedState,
		...traceState,
		layout: {
			...updatedState.layout,
			annotations: traceState.annotations
		}
	};

	const stateWithCriteriaLineTracesUpdate = _updateStateWithAllCriteriaLineTraces(
		newTracesState
	);

	return stateWithCriteriaLineTracesUpdate;
}

const updateCriteriaOption = (
	state,
	{ type = 'minimum', attribute, value }
) => {
	const { criteriaLines } = state;
	const attributeToUpdate = {};
	attributeToUpdate[attribute] = value;
	const newCriteriaLine = { ...criteriaLines[type], ...attributeToUpdate };
	const newCriteriaLines = { ...criteriaLines, [type]: newCriteriaLine };

	const stateWithCriteriaLineChange = {
		...state,
		criteriaLines: newCriteriaLines
	};

	return _updateCriteriaLineTrace(stateWithCriteriaLineChange, type);
};

const updateXAxisTicks = state => {
	const { newData } = state;
	const stationIds = newData.map(d => d.stationId);
	const xrange = [0, Math.max(...stationIds)];
	const newLayout = getXAxisWithTicks(state, xrange);

	const {
		layout,
		layout: { xaxis }
	} = state;

	const newState = {
		...state,
		layout: {
			...layout,
			xaxis: {
				...xaxis,
				...newLayout.xaxis
			}
		}
	};
	return newState;
};

const handleManualYRange = (state, index, value) => {
	const reg = /^(-)?[0-9\b]+(\.[0-9]+)?$/;
	if (value !== '' && !reg.test(value)) {
		return state;
	}

	const { layout } = state;
	const newLayout = { ...layout };
	newLayout.yaxis.autorange = false;
	newLayout.yaxis.range[index] = parseInt(value, 10);
	const { manualYRange } = state;

	manualYRange[index] = Number.isNaN(value) ? 0 : value;
	const stateFromManualYRangeChange = {
		...state,
		layout: newLayout,
		manualYRange
	};

	const stateFromCommentUpdate = _getUpdatedCommentTraceIfChanged(
		stateFromManualYRangeChange
	);

	return stateFromCommentUpdate;
};

const _getXStep = state => {
	const { gridAxesSetting } = state;
	if (!gridAxesSetting) {
		return null;
	}

	const { xaxis } = gridAxesSetting;
	if (!xaxis) {
		return null;
	}

	return xaxis.step;
};

const zoomToMidpoint = (state, midpoint, feet = OPTIMUM_ZOOM_IN_FEET) => {
	const halfOfOptimumZoom = feet / 2;
	const start = midpoint - halfOfOptimumZoom;
	const end = midpoint + halfOfOptimumZoom;

	const currentXRange = [start, end];

	const { layout: oldLayout } = state;

	const layout = oldLayout || getDefaultLayout(state);

	// The y-axis should not change - use what is currently in state
	const { yaxis } = state.layout;

	const xaxisStep = _getXStep(state);
	const { xaxis } = getXAxisWithTicks(state, currentXRange, {
		force: true,
		step: xaxisStep
	});

	const newLayout = { ...layout, xaxis, yaxis };

	const stateFromZoom = {
		...state,
		currentXRange,
		layout: newLayout,
		feetPerPage: Math.round(end - start)
	};

	const paginationStatus = getPaginationStatus(stateFromZoom);

	const traceInfo = _getTracesFromXRange(stateFromZoom);

	const zoomState = {
		...stateFromZoom,
		...traceInfo,
		paginationStatus
	};

	const stateWithCommentTraceUpdate = _getUpdatedCommentTraceIfChanged(
		zoomState
	);

	return stateWithCommentTraceUpdate;
};

const _getMidpoint = (state, feet, zoomToStart) => {
	// x range will be [startStn, feet]
	if (zoomToStart) {
		const { selectedDatFile } = state;
		const { startStn } = selectedDatFile;
		return feet / 2 + (startStn || 0);
	}

	const xRange = getXRange(state);
	if (!xRange) {
		return null;
	}

	const [startXRange, endXRange] = xRange;

	return startXRange + (endXRange - startXRange) / 2;
};

const zoomPerFeet = (state, feet, options = {}) => {
	const { zoomToStart } = options;

	const midpoint = _getMidpoint(state, feet, zoomToStart);

	if (!midpoint) {
		return state;
	}

	return zoomToMidpoint(state, midpoint, feet);
};

const feetChanged = (state, feet) => {
	if (feet >= 100) {
		return zoomPerFeet(state, feet);
	}
	return {
		...state,
		feetPerPage: feet
	};
};

const _getMaxStation = state => {
	const lastOnTrace = state.allTraces.newOnTrace.x.slice(-1)[0];
	const lastOffTrace = state.allTraces.newOffTrace.x.slice(-1)[0];

	return Math.max(lastOnTrace, lastOffTrace);
};

const _getMinStation = state => {
	const firstOnTrace = state.allTraces.newOnTrace.x[0];
	const firstOffTrace = state.allTraces.newOffTrace.x[0];

	return Math.min(firstOnTrace, firstOffTrace);
};

const _shouldZoomToStart = state => {
	const xRange = getXRange(state);
	const [startXRange, endXRange] = xRange;

	const maxStation = _getMaxStation(state);
	const minStation = _getMinStation(state);

	if (startXRange > minStation) {
		return false;
	}

	if (endXRange < maxStation) {
		return false;
	}

	// The zoom encompasses the entirety of the chart - zoom to start "0"
	return true;
};

const doOptimumZoom = state => {
	const navigationChangesByDatFile = getUpdateStateWithNewNavigationChanges(
		state
	);

	const zoomToStart = _shouldZoomToStart(state);

	const zoomState = zoomPerFeet(state, OPTIMUM_ZOOM_IN_FEET, { zoomToStart });

	return { ...zoomState, navigationChangesByDatFile };
};

const performPaginate = (state, dir = 'backward') => {
	const navigationChangesByDatFile = getUpdateStateWithNewNavigationChanges(
		state
	);

	const {
		layout,
		layout: { xaxis }
	} = state;

	const { minX, maxX } = getMinMaxXfromState(state);

	const newLayoutState = { ...layout };
	const [startX, endX] = newLayoutState.xaxis.range;
	let xaxisRange;
	const step = Math.abs(endX - startX);
	const startRange = startX - step; // backward direction
	const endRange = endX + step; // forward direction

	if (dir === 'forward') {
		if (endRange > maxX) {
			// We have gone past the last reading
			const newEnd = Math.max(endX, maxX);
			const newStart = newEnd - step;
			xaxisRange = [newStart, newEnd];
		} else {
			xaxisRange = [endX, endRange];
		}
	} else if (dir === 'backward') {
		if (startRange < minX) {
			// We have gone below the first reading
			const newStart = Math.min(startX, minX);
			const newEnd = newStart + step;
			xaxisRange = [newStart, newEnd];
		} else {
			xaxisRange = [startRange, startX];
		}
	}

	const stateFromPagination = {
		...state,
		currentXRange: xaxisRange,
		layout: { ...newLayoutState, xaxis: { ...xaxis, range: xaxisRange } }
	};

	const paginationStatus = getPaginationStatus(stateFromPagination);

	return {
		...stateFromPagination,
		paginationStatus,
		navigationChangesByDatFile
	};
};

const changeAxisColorHandler = (state, axis, name, value) => {
	const {
		layout,
		gridAxesSetting,
		layout: { [axis]: axisToGet }
	} = state;
	return {
		...state,
		layout: { ...layout, [axis]: { ...axisToGet, gridcolor: value } },
		gridAxesSetting: {
			...gridAxesSetting,
			[axis]: { ...gridAxesSetting[axis], [name]: value }
		}
	};
};

const changeAxisWidthHandler = (state, axis, name, value) => {
	const {
		layout,
		gridAxesSetting,
		layout: { [axis]: axisToGet }
	} = state;

	return {
		...state,
		layout: { ...layout, [axis]: { ...axisToGet, gridwidth: value } },
		gridAxesSetting: {
			...gridAxesSetting,
			[axis]: { ...gridAxesSetting[axis], [name]: value }
		}
	};
};

const changeAxisShowHideHandler = (state, axis, name, checked) => {
	const {
		layout,
		gridAxesSetting,
		layout: { [axis]: axisToGet }
	} = state;
	return {
		...state,
		layout: { ...layout, [axis]: { ...axisToGet, showgrid: checked } },
		gridAxesSetting: {
			...gridAxesSetting,
			[axis]: { ...gridAxesSetting[axis], [name]: checked }
		}
	};
};

const createDefaultXAxis = state => {
	const {
		layout,
		layout: { xaxis },
		newData
	} = state;

	const { stationId: maxStationId } = maxBy(newData, 'stationId');
	const { stationId: minStationId } = minBy(newData, 'stationId');

	return {
		...state,
		layout: {
			...layout,
			xaxis: {
				...xaxis,
				range: [minStationId, maxStationId]
			}
		}
	};
};

const setSelectedDatFile = (
	state,
	selectedDatFile,
	selectedDatFileIndex,
	oldData,
	preProcessedGPSReadings,
	newData,
	previousChartData,
	nextChartData,
	autoCorrectionInProcess,
	previousDatFile,
	nextDatFile
) => {
	const newState = {
		...state,
		selectedDatFile,
		selectedDatFileIndex,
		oldData,
		preProcessedGPSReadings,
		newData,
		previousChartData,
		nextChartData,
		previousDatFile,
		nextDatFile,
		selectActions: getSelectActionsFromNewChartData(
			state,
			autoCorrectionInProcess
		)
	};
	// This builds the x-axis from just newData
	const newStateWithDefaultXRange = createDefaultXAxis(newState);

	const settings = {
		useChartDotsConfig: LocalStorageUtil.spikeEditor.getGraphDotVisibility()
	};

	const options = {
		shouldMergeTraces: false
	};

	const traceState = getTraces(newStateWithDefaultXRange, settings, options);

	const stateFromTraces = {
		...newState,
		...traceState
	};

	// This calculates x-axis via all traces
	const stateWithXRange = updateStateWithAutoRange(stateFromTraces);

	// This recalculates x-axis ticks
	const stateFromRelayout = {
		...stateWithXRange,
		...derriveStateFromLayout(stateWithXRange, stateWithXRange.layout)
	};

	const stateWithXaxisTicks = extendStateWithTicks(stateFromRelayout);

	return stateWithXaxisTicks;
};

const setSelectAction = (state, selectedSelectActionValue) => {
	const { selectActions: oldSelectActions } = state;

	const selectActions = oldSelectActions.map(selectAction => {
		const { value } = selectAction;
		const checked = value === selectedSelectActionValue;

		return {
			...selectAction,
			checked
		};
	});

	return {
		...state,
		selectActions
	};
};

function turnOffTrace(trace) {
	return { ...trace, visible: false };
}

function getHighlightedTraces(
	attr,
	highlightedOnTrace,
	highlightedOffTrace,
	highlightedAcOnTrace,
	highlightedAcOffTrace
) {
	switch (attr) {
		case 'reading1':
			return {
				highlightedOnTrace,
				highlightedOffTrace: turnOffTrace(highlightedOffTrace),
				highlightedAcOnTrace: turnOffTrace(highlightedOffTrace),
				highlightedAcOffTrace: turnOffTrace(highlightedAcOffTrace)
			};
		case 'reading2':
			return {
				highlightedOnTrace: turnOffTrace(highlightedOnTrace),
				highlightedOffTrace,
				highlightedAcOnTrace: turnOffTrace(highlightedAcOnTrace),
				highlightedAcOffTrace: turnOffTrace(highlightedAcOffTrace)
			};
		case 'acOn':
			return {
				highlightedOnTrace: turnOffTrace(highlightedOnTrace),
				highlightedOffTrace: turnOffTrace(highlightedOffTrace),
				highlightedAcOnTrace,
				highlightedAcOffTrace: turnOffTrace(highlightedAcOffTrace)
			};
		case 'acOff':
			return {
				highlightedOnTrace: turnOffTrace(highlightedOnTrace),
				highlightedOffTrace: turnOffTrace(highlightedOffTrace),
				highlightedAcOnTrace: turnOffTrace(highlightedAcOnTrace),
				highlightedAcOffTrace
			};

		default:
			return {
				highlightedOnTrace,
				highlightedOffTrace,
				highlightedAcOnTrace,
				highlightedAcOffTrace
			};
	}
}

const setReadingPointHighlighted = (state, readingIndex, attr) => {
	const { newData, selectedDatFile, traces: oldTraces } = state;
	const highlightedPoint = readingIndex;
	const {
		highlightedOnTrace,
		highlightedOffTrace,
		highlightedAcOnTrace,
		highlightedAcOffTrace
	} = getHighlightedTrace(state, highlightedPoint, newData, selectedDatFile);
	const traces = {
		...oldTraces,
		...getHighlightedTraces(
			attr,
			highlightedOnTrace,
			highlightedOffTrace,
			highlightedAcOnTrace,
			highlightedAcOffTrace
		)
	};

	return {
		...state,
		highlightedPoint,
		traces,
		arrTraces: Object.values(traces)
	};
};

const createStateFromReadingRowsSelected = (
	state,
	datIndex,
	newReadingRowPoints,
	readingIndex
) => {
	const { newData, selectedDatFile, allTraces: oldTraces } = state;

	const {
		readingRowSelectedOnTraces,
		readingRowSelectedOffTraces,
		readingRowSelectedAcOnTraces,
		readingRowSelectedAcOffTraces
	} = getReadingRowSelectedTraces(
		state,
		newReadingRowPoints,
		newData,
		selectedDatFile
	);

	const allTraces = {
		...oldTraces,
		readingRowSelectedOnTraces,
		readingRowSelectedOffTraces,
		readingRowSelectedAcOnTraces,
		readingRowSelectedAcOffTraces
	};

	const stateFromAddingToAllTraces = {
		...state,
		allTraces,
		arrTraces: Object.values(allTraces)
	};

	if (!newData[readingIndex]) {
		return {
			...stateFromAddingToAllTraces,
			readingRowsSelectedByDatFile: newReadingRowPoints
		};
	}
	const { stationId } = newData[readingIndex];

	// This will get new traces and annotations
	// TODO: Add to zoom object thingy -> Only zoom in if greater than OPTIMUM_ZOOM
	const stateFromZoomingIntoSelectedPoint = zoomToMidpoint(
		stateFromAddingToAllTraces,
		stationId
	);

	return {
		...stateFromZoomingIntoSelectedPoint,
		readingRowsSelectedByDatFile: newReadingRowPoints
	};
};

const setReadingRowSelected = (state, datIndex, readingIndex, attr) => {
	const { readingRowsSelectedByDatFile = {} } = state;

	const newReadingRowPoints = {
		...readingRowsSelectedByDatFile,
		[datIndex]: { ...(readingRowsSelectedByDatFile[datIndex] || {}) }
	};
	newReadingRowPoints[datIndex][readingIndex] = [
		...(newReadingRowPoints[datIndex][readingIndex] || [])
	];
	if (newReadingRowPoints[datIndex][readingIndex].indexOf(attr) === -1) {
		newReadingRowPoints[datIndex][readingIndex].push(attr);
	}

	const newState = createStateFromReadingRowsSelected(
		state,
		datIndex,
		newReadingRowPoints,
		readingIndex
	);
	return newState;
};

const resetReadingRowSelected = (state, datIndex, readingIndex, attr) => {
	const { readingRowsSelectedByDatFile = {} } = state;

	const newReadingRowPoints = {
		...readingRowsSelectedByDatFile,
		[datIndex]: { ...(readingRowsSelectedByDatFile[datIndex] || {}) }
	};
	newReadingRowPoints[datIndex][readingIndex] = [
		...(newReadingRowPoints[datIndex][readingIndex] || [])
	];
	if (newReadingRowPoints[datIndex][readingIndex].indexOf(attr) !== -1) {
		newReadingRowPoints[datIndex][readingIndex].splice(
			newReadingRowPoints[datIndex][readingIndex].indexOf(attr),
			1
		);
		if (newReadingRowPoints[datIndex][readingIndex].length === 0) {
			delete newReadingRowPoints[datIndex][readingIndex];
		}
	}

	return createStateFromReadingRowsSelected(
		state,
		datIndex,
		newReadingRowPoints
	);
};

const _defaultZoomToPointFromReadings = (datFileName, stationId) => {
	return {
		datFileName,
		stationId,
		feet: OPTIMUM_ZOOM_IN_FEET
	};
};

// See if there was a previousZoom set. If it matches the stationId/Filename then double the zoom
const _getZoomPoint = (state, readingIndex) => {
	const { newData, selectedDatFile, zoomPointFromReadings } = state;
	if (!selectedDatFile) {
		return null;
	}
	const readingData = newData[readingIndex];

	const { fileName } = selectedDatFile;
	const { stationId } = readingData;

	if (!zoomPointFromReadings) {
		return _defaultZoomToPointFromReadings(fileName, stationId);
	}

	const {
		stationId: stationIdPreviousZoom,
		datFileName: datFileNamePreviousZoom,
		feet
	} = zoomPointFromReadings;

	if (
		datFileNamePreviousZoom !== fileName ||
		stationIdPreviousZoom !== stationId
	) {
		return _defaultZoomToPointFromReadings(fileName, stationId);
	}

	return {
		stationId: stationIdPreviousZoom,
		datFileName: datFileNamePreviousZoom,
		// Zoom in more to the same point
		feet: feet / 2
	};
};

const zoomToSpikeEditorPoint = (state, readingIndex) => {
	const zoomPointFromReadings = _getZoomPoint(state, readingIndex);
	if (!zoomPointFromReadings) {
		return state;
	}

	const { feet, stationId } = zoomPointFromReadings;

	const newState = zoomToMidpoint(state, stationId, feet);

	return {
		...newState,
		zoomPointFromReadings
	};
};

const switchVisibilityForTrace = trace => {
	if (trace.visible) {
		return {
			...trace,
			visible: undefined
		};
	}

	return {
		...trace,
		visible: 'legendonly'
	};
};

const changeTraceVisibility = (state, curveNumber) => {
	const { traces } = state;

	const traceKeys = Object.keys(traces);
	const newTraceObject = {};
	traceKeys.forEach((traceKey, i) => {
		const trace = traces[traceKey];
		if (i !== curveNumber) {
			newTraceObject[traceKey] = trace;
			return;
		}

		newTraceObject[traceKey] = switchVisibilityForTrace(trace);
	});
	return {
		...state,
		traces: newTraceObject,
		arrTraces: Object.values(traces)
	};
};

const setShowCommentsConfigOption = (state, selectedCommentConfig) => {
	const stateFromCommentsShowSelection = {
		...state,
		selectedCommentConfig
	};
	const statePropsFromTraces = _getTracesFromXRange(
		stateFromCommentsShowSelection
	);

	const stateFromCommentUpdate = _getUpdatedCommentTraceIfChanged({
		...stateFromCommentsShowSelection,
		...statePropsFromTraces
	});

	return stateFromCommentUpdate;
};

const setShowOriginalLineConfigOption = (state, selectedOriginalLineConfig) => {
	const stateFromOriginalLineShowSelection = {
		...state,
		selectedOriginalLineConfig
	};

	const statePropsFromTraces = _getTracesFromXRange(
		stateFromOriginalLineShowSelection
	);

	return { ...stateFromOriginalLineShowSelection, ...statePropsFromTraces };
};

const setGraphDotsShowOption = state => {
	const settings = {
		useChartDotsConfig: LocalStorageUtil.spikeEditor.getGraphDotVisibility()
	};

	const options = {
		shouldMergeTraces: true
	};

	const traceState = getTraces(state, settings, options);

	return { ...state, ...traceState };
};

const revertNavigationChange = state => {
	const { navigationChangesByDatFile, selectedDatFile } = state;

	if (!selectedDatFile) {
		return state;
	}

	const { fileName } = selectedDatFile;

	if (!fileName) {
		return state;
	}

	const changesByDatFile = navigationChangesByDatFile[fileName];

	// Technically, this should never happen
	if (!changesByDatFile || !changesByDatFile.length) {
		return state;
	}

	const newChanges = changesByDatFile.slice(0, -1);
	const previousLayout = changesByDatFile[changesByDatFile.length - 1];

	const stateFromNavigationRevert = {
		...state,
		...derriveStateFromLayout(state, previousLayout),
		layout: previousLayout,
		navigationChangesByDatFile: {
			...navigationChangesByDatFile,
			[fileName]: newChanges
		}
	};

	return stateFromNavigationRevert;
};

// This will be set every time the SpikeGraph is rendered
const setImmutableLayoutCopy = (state, layout) => {
	return {
		...state,
		immutableLayout: cloneDeep(layout)
	};
};

const setTraceNamesFromSurveyType = (state, surveyType) => {
	const { traceOptionsByName } = state;

	const firstReadingName = getOnName(surveyType);

	const {
		newOnTrace,
		previousOnTrace,
		nextOnTrace,
		previousAcOnTrace,
		nextAcOnTrace
	} = traceOptionsByName;

	return {
		...traceOptionsByName,
		newOnTrace: {
			...newOnTrace,
			display: `Current ${firstReadingName}`
		},
		previousOnTrace: {
			...previousOnTrace,
			display: `Previous ${firstReadingName}`
		},
		nextOnTrace: {
			...nextOnTrace,
			display: `Next ${firstReadingName}`
		},
		previousAcOnTrace: {
			...previousAcOnTrace,
			display: `Previous AC ${firstReadingName}`
		},
		nextAcOnTrace: {
			...nextAcOnTrace,
			display: `Next AC ${firstReadingName}`
		}
	};
};

const setSurveyType = (state, surveyType) => {
	return {
		...state,
		traceOptionsByName: setTraceNamesFromSurveyType(state, surveyType),
		surveyType
	};
};

const setTraceColor = (state, traceName, color) => {
	const { traceOptionsByName } = state;

	const traceOptions = traceOptionsByName[traceName];
	const stateWithTraceOption = {
		...state,
		traceOptionsByName: {
			...traceOptionsByName,
			[traceName]: {
				...traceOptions,
				color
			}
		}
	};

	return _updateTracesFromOptionsChange(
		stateWithTraceOption,
		traceName,
		'color',
		color
	);
};

const spikeEditorSpikeGraph = (state = defaultState, action) => {
	switch (action.type) {
		case 'CISV_SPIKE_CHART_ON_RELAYOUT':
			return onRelayout(state, action.event);
		case 'CISV_SPIKE_CHART_ZOOM_PER_FEET':
			return zoomPerFeet(state, action.feet);
		case 'CISV_SPIKE_CHART_FEET_CHANGED':
			return feetChanged(state, action.feet);
		case 'CISV_SPIKE_GRAPH_HANDLE_MANUAL_Y_RANGE':
			return handleManualYRange(state, action.index, action.value);
		case 'CISV_SPIKE_GRAPH_UPDATE_X_AXIS_TICKS':
			return updateXAxisTicks(state);
		case 'CISV_SPIKE_GRAPH_UPDATE_CRITERIA_OPTION':
			return updateCriteriaOption(state, action.payload);
		case 'CISV_SPIKE_GRAPH_CHANGE_STEP_HANDLER': {
			const { axis, value } = action;
			// TODO: This should be handeled more async in style. First set the value, and then recalculate chart data
			return changeAxisStepHandler(state, axis, value);
		}
		case 'CISV_SPIKE_GRAPH_RELAYOUT_FRROM_CHANGE_STEP_HANDLER':
			return performRelayoutFromStepHandlerChange(state, action.axis);
		case 'CISV_SPIKE_CHART_SET_DEFAULT':
			return action.newState;
		case 'CISV_SPIKE_CHART_SET_USE_MV':
			return getStateFromNewMV(state, action.newState);
		case 'CISV_SPIKE_CHART_DO_OPTIMUM_ZOOM':
			return doOptimumZoom(state);
		case 'CISV_SPIKE_CHART_PERFORM_PAGINATE':
			return performPaginate(state, action.dir);
		case 'CISV_SPIKE_CHART_CHANGE_AXIS_COLOR': {
			const { axis, name, value } = action;
			return changeAxisColorHandler(state, axis, name, value);
		}
		case 'CISV_SPIKE_CHART_CHANGE_AXIS_WIDTH': {
			const { axis, name, value } = action;
			return changeAxisWidthHandler(state, axis, name, value);
		}
		case 'CISV_SPIKE_CHART_CHANGE_AXIS_SHOW_HIDE': {
			const { axis, name, value } = action;
			return changeAxisShowHideHandler(state, axis, name, value);
		}
		case 'CISV_SPIKE_CHART_SET_SELECTED_DAT_FILE': {
			const {
				selectedDatFile,
				selectedDatFileIndex,
				oldData,
				preProcessedGPSReadings,
				newData,
				previousChartData,
				nextChartData,
				autoCorrectionInProcess,
				previousDatFile,
				nextDatFile
			} = action;
			return setSelectedDatFile(
				state,
				selectedDatFile,
				selectedDatFileIndex,
				oldData,
				preProcessedGPSReadings,
				newData,
				previousChartData,
				nextChartData,
				autoCorrectionInProcess,
				previousDatFile,
				nextDatFile
			);
		}
		case 'CISV_SPIKE_CHART_CLOSE_MODAL':
			return {
				...state,
				showSettingModal: false
			};
		case 'CISV_SPIKE_CHART_OPEN_MODAL':
			return {
				...state,
				showSettingModal: true
			};
		case 'CISV_SPIKE_CHART_SET_SELECT_ACTION':
			return setSelectAction(state, action.selectedSelectActionValue);
		case 'CISV_SPIKE_CHART_SET_READING_POINT_HIGHLIGHTED':
			return setReadingPointHighlighted(
				state,
				action.readingIndex,
				action.attr
			);
		case 'CISV_SPIKE_CHART_ON_ROW_UNSELECT':
			return resetReadingRowSelected(
				state,
				action.datIndex,
				action.readingIndex,
				action.attr
			);
		case 'CISV_SPIKE_CHART_UNSELECT_ALL_PER_DAT_FILE':
			return {
				...state,
				readingRowsSelectedByDatFile: {
					...state.readingRowsSelectedByDatFile,
					[state.selectedDatFileIndex]: {}
				}
			};
		case 'CISV_SPIKE_CHART_ON_ROW_SELECT':
			return setReadingRowSelected(
				state,
				action.datIndex,
				action.readingIndex,
				action.attr
			);
		case 'CISV_SPIKE_CHART_ZOOM_TO_READING_INDEX':
			return zoomToSpikeEditorPoint(state, action.readingIndex);
		case 'CISV_SPIKE_CHART_CHANGE_TRACE_VISIBILITY':
			return changeTraceVisibility(state, action.curveNumber);
		case 'CISV_SPIKE_CHART_UPDATE_SHOW_COMMENTS_CONFIG':
			return setShowCommentsConfigOption(state, action.selectedCommentConfig);
		case 'CISV_SPIKE_CHART_UPDATE_SHOW_GRAPH_DOTS_CONFIG':
			return setGraphDotsShowOption(state);
		case 'CISV_SPIKE_CHART_UPDATE_SHOW_ORIGINAL_LINES_CONFIG':
			return setShowOriginalLineConfigOption(
				state,
				action.selectedOriginalLineConfig
			);
		case 'CISV_SPIKE_CHART_REVERT_NAVIGATION_CHANGE':
			return revertNavigationChange(state);
		case 'CISV_SPIKE_CHART_SET_IMMUTABLE_LAYOUT':
			return setImmutableLayoutCopy(state, action.layout);
		case 'CISV_SPIKE_CHART_SET_SURVEY_TYPE':
			return setSurveyType(state, action.surveyType);
		case 'CISV_SPIKE_CHART_CHANGE_TRACE_COLOR':
			return setTraceColor(state, action.traceName, action.color);
		default:
			return state;
	}
};

export default spikeEditorSpikeGraph;
