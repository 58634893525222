/* eslint-disable no-use-before-define */
import _ from 'lodash';

export const newBrushState = (state, brush) => {
	const { minimum, maximum } = brush;
	let newState = state;

	if (minimum !== state.minimum || maximum !== state.maximum) {
		newState = newMinimum(newState, minimum);
		newState = newMaximum(newState, maximum);

		newState = newStartIndexes(newState);
		newState = newEndIndexes(newState);

		newState = newZoomFactor(newState);
		newState = newZoomPosition(newState);
	}

	return newState;
};

const newMinimum = (state, minimum) => {
	let newState = state;

	if (newState.minimum !== minimum) {
		newState = { ...state, minimum };
	}

	return newState;
};

const newMaximum = (state, maximum) => {
	let newState = state;

	if (newState.maximum !== maximum) {
		newState = { ...state, maximum };
	}

	return newState;
};

const newStartIndexes = state => {
	let newState = state;

	const { surveys, readings, startIndexes, minimum } = newState;

	const { hasChanges, newIndexes: newSIndexes } = _newIndexes(
		surveys,
		readings,
		startIndexes,
		minimum
	);

	if (hasChanges) {
		newState = {
			...newState,
			startIndexes: {
				...newState.startIndexes,
				...newSIndexes
			}
		};
	}

	return newState;
};

const newEndIndexes = state => {
	let newState = state;

	const { surveys, readings, endIndexes, maximum } = newState;

	const { hasChanges, newIndexes: newEIndexes } = _newIndexes(
		surveys,
		readings,
		endIndexes,
		maximum
	);

	if (hasChanges) {
		newState = {
			...newState,
			endIndexes: {
				...newState.endIndexes,
				...newEIndexes
			}
		};
	}

	return newState;
};

const _newIndexes = (surveys, readings, indexes, stationId) => {
	let hasChanges = false;
	const newIndexes = {};

	surveys.forEach(s => {
		const r = readings[s.id] || [];
		const currentIndex = indexes[s.id];

		// HANDLE EDGE CASES BEFORE TRAVERSING READINGS ARRAY FOR PERFOMRANCE REASONS
		const firstReading = r[0];
		const lastReading = r[r.length - 1];

		// CASE 1 - readings are not defined
		if (!firstReading || !lastReading) {
			if (currentIndex !== undefined) {
				hasChanges = true;
				newIndexes[s.id] = undefined;
			}
		} else {
			const firstStationId = firstReading.id;
			const lastStationId = lastReading.id;

			const isOutOfLeftBounds = stationId < firstStationId;
			const isOutOfRightBounds = stationId > lastStationId;

			// CASE 2 - OUT OF LEFT BOUNDS
			if (isOutOfLeftBounds) {
				if (currentIndex !== undefined) {
					hasChanges = true;
					newIndexes[s.id] = undefined;
				}

				// CASE 3 - OUT OF RIGHT BOUNDS
			} else if (isOutOfRightBounds) {
				if (currentIndex !== undefined) {
					hasChanges = true;
					newIndexes[s.id] = undefined;
				}
			} else {
				// CASE 4 - is within range - find index
				const fauxReading = { id: stationId };
				const idx = _.sortedIndexBy(r, fauxReading, obj => obj.id);
				const indexChanged = idx !== currentIndex;

				if (indexChanged) {
					hasChanges = true;
					newIndexes[s.id] = idx;
				}
			}
		}
	});

	return { hasChanges, newIndexes };
};

const newZoomFactor = state => {
	let newState = state;
	const _newZoomFactor = calculateZoomFactor(newState);
	if (!isZoomFactorEquivalent(newState.zoomFactor, _newZoomFactor)) {
		newState = {
			...state,
			zoomFactor: _newZoomFactor
		};
	}

	return newState;
};

const newZoomPosition = state => {
	let newState = state;

	const _newZoomPosition = calculateZoomPosition(newState);
	if (!isZoomPositionEquivalent(newState.zoomPosition, _newZoomPosition)) {
		newState = {
			...state,
			zoomPosition: _newZoomPosition
		};
	}

	return newState;
};

const calculateZoomFactor = state => {
	const { boundaryXMinXMax = {}, minimum, maximum } = state;
	const { xMin, xMax } = boundaryXMinXMax;

	if (
		xMin === undefined ||
		xMax === undefined ||
		minimum === undefined ||
		maximum === undefined ||
		maximum === Infinity
	) {
		return 1;
	}

	const delta = xMax - xMin;
	if (delta === 0) {
		return 1;
	}

	return (maximum - minimum) / (xMax - xMin);
};

const calculateZoomPosition = state => {
	const { boundaryXMinXMax = {}, minimum } = state;
	const { xMin, xMax } = boundaryXMinXMax;

	if (xMin === undefined || xMax === undefined || minimum === undefined) {
		return 0;
	}

	const delta = xMax - xMin;
	if (delta === 0) {
		return 0;
	}

	return (minimum - xMin) / (xMax - xMin);
};

export const isZoomFactorEquivalent = (v1, v2) => {
	if (v1 === undefined || v2 === undefined) {
		return v1 === v2;
	}

	return serializeZoomVal(v1) === serializeZoomVal(v2);
};

export const isZoomPositionEquivalent = (v1, v2) => {
	if (v1 === undefined || v2 === undefined) {
		return v1 === v2;
	}

	return serializeZoomVal(v1) === serializeZoomVal(v2);
};

const serializeZoomVal = val => {
	return val.toFixed(16).substr(0, 14);
};
