/* eslint-disable camelcase */
import { chainReducers } from '../util';

import { SET_LINE_ID } from './actionTypes';

const initialState = {
	lineId: undefined
};

const _line = (state = initialState, action) => {
	const { type, payload } = action;

	switch (type) {
		case SET_LINE_ID:
			return {
				...state,
				lineId: payload
			};

		default:
			return state;
	}
};

export default chainReducers([_line]);
