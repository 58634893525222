/* eslint-disable import/prefer-default-export */
import { createSelector } from 'reselect';
import { ROOT_SLICE } from './constants';
import { selectUsers as usersSelectUsers } from '../Users/selectors';
import {
	selectPermissionDefinitions,
	selectMapPermissionsByGroupName
} from '../PermissionDefinitions/selectors';

export const selectRootSlice = stateAdmin => stateAdmin[ROOT_SLICE];

export const selectSearchText = stateAdmin => {
	const slice = selectRootSlice(stateAdmin);
	const { searchText } = slice;
	return searchText;
};

export const selectCompanyName = stateAdmin => {
	const slice = selectRootSlice(stateAdmin);
	const { companyName } = slice;
	return companyName;
};

export const selectCompanyNameAsArray = createSelector(
	selectCompanyName,
	companyName => {
		return [companyName].filter(v => v);
	}
);

export const selectUsers = createSelector(
	usersSelectUsers,
	selectSearchText,
	selectPermissionDefinitions,
	selectMapPermissionsByGroupName,
	(
		users = [],
		paramSearchText = '',
		permissionDefinitions = {},
		mapPermissionsByGroupName = {}
	) => {
		const searchText = paramSearchText.toLowerCase().trim();
		if (!paramSearchText) {
			return users;
		}

		return users.filter((u = {}) => {
			const {
				givenName,
				familyName,
				username,
				hasGroupHashMap = {},
				userStatus = ''
			} = u;
			const name = [givenName, familyName]
				.filter(v => v)
				.join(' ')
				.toLowerCase();
			const fullName = [familyName, givenName]
				.filter(v => v)
				.join(', ')
				.toLowerCase();

			const permissionKeys = Object.keys(hasGroupHashMap).filter(
				k => hasGroupHashMap[k] === true
			);
			const permissionsList = permissionKeys.reduce((acc, k) => {
				const p = mapPermissionsByGroupName[k];
				if (p) {
					const app = permissionDefinitions[p.appKey];
					acc.push(`${app.name.toLowerCase()} ${p.name.toLowerCase()}`);
				}
				return acc;
			}, []);

			const matchName = name.indexOf(searchText) > -1;
			const matchFullName = fullName.indexOf(searchText) > -1;
			const matchUsername =
				(username || '').toLowerCase().indexOf(searchText) > -1;
			const matchPermissions = permissionsList.some(
				permissionName => permissionName.indexOf(searchText) > -1
			);
			const matchStatus = userStatus.toLowerCase().indexOf(searchText) > -1;

			return (
				matchName ||
				matchFullName ||
				matchUsername ||
				matchPermissions ||
				matchStatus
			);
		});
	}
);
