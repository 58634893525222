const EMPTY_OBJECT = {};
const EMPTY_ARRAY = [];

export const buildSelectors = (
	mainRedux,
	filterStatePropKey = 'processors'
) => {
	const { selectFiltersState } = mainRedux.selectors;

	const selectProcessorFilterState = sliceState => {
		const filtersState = selectFiltersState(sliceState);
		const processors = filtersState[filterStatePropKey] || EMPTY_OBJECT;
		return processors;
	};

	const selectProcessorList = sliceState => {
		const state = selectProcessorFilterState(sliceState);
		const { list = EMPTY_ARRAY } = state;
		return list;
	};
	const selectProcessorListCount = sliceState => {
		const state = selectProcessorFilterState(sliceState);
		const { listCount = 0 } = state;
		return listCount;
	};

	const selectProcessorSelectedList = sliceState => {
		const state = selectProcessorFilterState(sliceState);
		const { selected = EMPTY_ARRAY } = state;
		return selected;
	};

	const selectProcessorSelectedListCount = sliceState => {
		const state = selectProcessorFilterState(sliceState);
		const { selectedCount = 0 } = state;
		return selectedCount;
	};

	const selectProcessorIsLoading = sliceState => {
		const state = selectProcessorFilterState(sliceState);
		const { isLoading = false } = state;
		return isLoading;
	};

	const selectProcessorIsLoaded = sliceState => {
		const state = selectProcessorFilterState(sliceState);
		const { isLoaded = false } = state;
		return isLoaded;
	};

	const selectProcessorLoadErrorMessage = sliceState => {
		const state = selectProcessorFilterState(sliceState);
		const { loadErrorMessage } = state;
		return loadErrorMessage;
	};

	return {
		selectProcessorFilterState,
		selectProcessorList,
		selectProcessorListCount,
		selectProcessorSelectedList,
		selectProcessorSelectedListCount,
		selectProcessorIsLoading,
		selectProcessorIsLoaded,
		selectProcessorLoadErrorMessage
	};
};

export default {
	buildSelectors
};
