import { SLICE_ROOT_KEY } from './constants';
import {
	setFileMeta,
	setFileContent,
	setFileDocJson,
	setFileErrors,
	setFileProcessingMessage,
	clearFile,
	clearAllFiles
} from './actions';
import {
	selectSliceState,
	selectAllFileMeta,
	selectAllFileContent,
	selectAllFileDocJson,
	selectAllFileErrors,
	selectAllFileProcessingMessages
} from './selectors';
import { isValidKey, getProcessingMessage } from './util';

const reducerParts = {
	[setFileMeta]: (state, { payload: { key, meta } = {} }) => {
		if (!isValidKey(key)) {
			return state;
		}

		const slice = selectSliceState(state);
		const fileMeta = selectAllFileMeta(state);

		return {
			...state,
			[SLICE_ROOT_KEY]: {
				...slice,
				fileMeta: { ...fileMeta, [key]: meta }
			}
		};
	},

	[setFileContent]: (state, { payload: { key, content } = {} }) => {
		if (!isValidKey(key)) {
			return state;
		}

		const slice = selectSliceState(state);
		const fileContent = selectAllFileContent(state);

		return {
			...state,
			[SLICE_ROOT_KEY]: {
				...slice,
				fileContent: { ...fileContent, [key]: content }
			}
		};
	},

	[setFileDocJson]: (state, { payload: { key, docJson } = {} }) => {
		if (!isValidKey(key)) {
			return state;
		}

		const slice = selectSliceState(state);
		const fileDocJson = selectAllFileDocJson(state);

		return {
			...state,
			[SLICE_ROOT_KEY]: {
				...slice,
				fileDocJson: { ...fileDocJson, [key]: docJson }
			}
		};
	},

	[setFileErrors]: (state, { payload: { key, errors } = {} }) => {
		if (!isValidKey(key)) {
			return state;
		}

		const slice = selectSliceState(state);
		const fileErrors = selectAllFileErrors(state);

		return {
			...state,
			[SLICE_ROOT_KEY]: {
				...slice,
				fileErrors: { ...fileErrors, [key]: errors }
			}
		};
	},

	[setFileProcessingMessage]: (
		state,
		{ payload: { key, processingMessageKey } = {} }
	) => {
		if (!isValidKey(key)) {
			return state;
		}

		const message = getProcessingMessage(processingMessageKey);
		const slice = selectSliceState(state);
		const fileProcessingMessages = selectAllFileProcessingMessages(state);

		return {
			...state,
			[SLICE_ROOT_KEY]: {
				...slice,
				fileProcessingMessages: { ...fileProcessingMessages, [key]: message }
			}
		};
	},

	[clearFile]: (state, { payload: { key } = {} }) => {
		if (!isValidKey(key)) {
			return state;
		}

		const slice = selectSliceState(state);
		const fileMeta = selectAllFileMeta(state);
		const fileContent = selectAllFileContent(state);
		const fileDocJson = selectAllFileDocJson(state);
		const fileErrors = selectAllFileErrors(state);

		return {
			...state,
			[SLICE_ROOT_KEY]: {
				...slice,
				fileMeta: { ...fileMeta, [key]: undefined },
				fileContent: { ...fileContent, [key]: undefined },
				fileDocJson: { ...fileDocJson, [key]: undefined },
				fileErrors: { ...fileErrors, [key]: undefined }
			}
		};
	},

	[clearAllFiles]: state => {
		const slice = selectSliceState(state);
		return {
			...state,
			[SLICE_ROOT_KEY]: {
				...slice,
				fileMeta: { A: undefined, B: undefined },
				fileContent: { A: undefined, B: undefined },
				fileDocJson: { A: undefined, B: undefined },
				fileErrors: { A: undefined, B: undefined }
			}
		};
	}
};

export default reducerParts;
