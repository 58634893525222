/* eslint-disable import/no-named-as-default-member */
import { createActions } from 'redux-actions';
import { selectChartIds } from './selectors';
import {
	addChartSettings,
	removeChartSettings
} from '../slices/ChartsSettings/actions';
import {
	addChartBelowCriterions,
	removeChartBelowCriterions
} from '../slices/ChartsBelowCriterions/actions';
import ChartsRemediationsActions from '../slices/ChartsRemediations/actions';
import { createInitialChartSettings } from '../slices/ChartsSettings/util';
import { getChartId, getSurveyIdFromChartId } from '../../../../utils/chart';
import { selectSurvey } from '../../../selectors/surveys';

const { addChart, removeChart, updateChartOrder } = createActions(
	{
		ADD_CHART: (chartId, index) => {
			return { chartId, index };
		},
		REMOVE_CHART: chartId => {
			return { chartId };
		},
		UPDATE_CHART_ORDER: (chartId, idx) => {
			return { chartId, idx };
		}
	},
	{
		prefix: 'Charts'
	}
);

const isDefined = v => v !== undefined && v !== null;

const thunkAddChart = (chartId, index) => (dispatch, getState) => {
	const surveyId = getSurveyIdFromChartId(chartId);
	const survey = selectSurvey(getState().app, surveyId);
	const defaultChartSettings = createInitialChartSettings(chartId);
	if (isDefined(survey.withDepol)) {
		defaultChartSettings.useShadow = survey.withDepol;
	}
	if (isDefined(survey.withThreshold)) {
		defaultChartSettings.useThreshold = !!(
			!defaultChartSettings.useShadow && survey.withThreshold
		);
	}
	dispatch(addChart(chartId, index));
	dispatch(addChartSettings(chartId, defaultChartSettings));
	dispatch(addChartBelowCriterions(chartId));
	dispatch(ChartsRemediationsActions.setChartState(chartId));
};

const thunkRemoveChart = chartId => dispatch => {
	dispatch(removeChart(chartId));
	dispatch(removeChartSettings(chartId));
	dispatch(removeChartBelowCriterions(chartId));
	dispatch(ChartsRemediationsActions.destroyChartState(chartId));
};

const thunkChartSideEffectsForDeleteSurvey = survey => (dispatch, getState) => {
	const chartIds = selectChartIds(getState().app);
	const chartsIdsToRemove = chartIds.filter(chartId => {
		return (
			chartId === survey.CHART_ID ||
			chartId === getChartId(survey) ||
			chartId.indexOf(survey.jobId) > -1
		);
	});
	chartsIdsToRemove.forEach(chartId => dispatch(thunkRemoveChart(chartId)));
};

// eslint-disable-next-line import/prefer-default-export
export {
	thunkAddChart,
	addChart,
	thunkRemoveChart,
	removeChart,
	updateChartOrder,
	thunkChartSideEffectsForDeleteSurvey
};
