"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0; // This is taken from PostgreSQL table "location_types"

var LocationType = {
  INTERPOLATED: 1,
  GPS: 2
};
var _default = LocationType;
exports["default"] = _default;