import { createActions } from 'redux-actions';

const {
	setGridKey,
	setApplicationsListKey,
	setDetailsKey,
	setIsSideNavExpanded,
	setSelectedApp
} = createActions(
	{
		SET_GRID_KEY: key => ({ key }),
		SET_APPLICATIONS_LIST_KEY: key => ({ key }),
		SET_DETAILS_KEY: key => ({ key }),
		SET_IS_SIDE_NAV_EXPANDED: isExpanded => ({ isExpanded }),
		SET_SELECTED_APP: key => ({ key })
	},
	{
		prefix: 'WebsiteExperimental'
	}
);

export {
	setGridKey,
	setApplicationsListKey,
	setDetailsKey,
	setIsSideNavExpanded,
	setSelectedApp
};
