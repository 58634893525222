import ColorRgbError from './errors/ErrorClasses/ColorRgbError';

export const intFromHexadecimal = hexidecimal => {
	return parseInt(`0x${hexidecimal}`, 16);
};

export const hexidecimalColorToInts = colorCode => {
	let hexidecimalColor = colorCode.toLowerCase();
	if (colorCode[0] === '#') {
		hexidecimalColor = hexidecimalColor.slice(1);
	}
	if (hexidecimalColor.length !== 6) {
		throw new Error(
			`Expecting color string "${hexidecimalColor}" to be exactly 6 characters`
		);
	}

	// Get groups of two characters
	const colorGroups = [2, 4, 6].map(max =>
		hexidecimalColor.slice(max - 2, max)
	);

	return colorGroups.map(intFromHexadecimal);
};

export const intColorToHexidecimal = colorInt => {
	const colorString = colorInt.toString(16).toUpperCase();
	if (colorString.length === 1) {
		return `0${colorString}`;
	}

	return colorString;
};

export const intColorsToHexidecimal = colorInts => {
	return colorInts.map(intColorToHexidecimal).join('');
};

// Will decrease the int by a multiple of ratio
const fadeColors = (colorInts, ratio) => {
	return colorInts.map(color => parseInt(color * ratio, 10));
};

// This will fade hexidecimal colors (ie "#FFFFFF") times the ratio (ie 0.5 will make the color half as "bright")
export const darkenColor = (colorCode, ratio) => {
	if (!ratio) {
		throw new Error(
			'The "ratio" is required and should be a number between 0-1'
		);
	}

	const colorInts = hexidecimalColorToInts(colorCode);

	const darkenedColors = fadeColors(colorInts, ratio);

	const hexidecimalColor = intColorsToHexidecimal(darkenedColors);
	return `#${hexidecimalColor}`;
};

export const reverseColor = colorInt => {
	return 255 - colorInt;
};

export const reverseColors = colorInts => {
	return colorInts.map(reverseColor);
};

// This essentially does the opposite of "darkenColor" where we reverse the input and the output to derrive a brighter color
export const brightenColor = (colorCode, ratio) => {
	const colorInts = hexidecimalColorToInts(colorCode);
	const reverseColorInts = reverseColors(colorInts);

	const darkenedColors = fadeColors(reverseColorInts, ratio);

	const brightenedColors = reverseColors(darkenedColors);

	const hexidecimalColor = intColorsToHexidecimal(brightenedColors);
	return `#${hexidecimalColor}`;
};

const isObjectRgb = colorObject => {
	const requiredKeys = ['r', 'g', 'b'];

	return requiredKeys.every(key => {
		return colorObject[key] !== undefined;
	});
};

const createBlankRgbObject = () => {
	return { r: 0, g: 0, b: 0 };
};

const rgbFromHexadecimal = hexadecimalColor => {
	const [r, g, b] = hexidecimalColorToInts(hexadecimalColor);

	return { r, g, b };
};

const rgbInputError = color => {
	throw new ColorRgbError(`Cannot interpret color from ${color}`);
};

const createDefaultBlankRgbObject = (color, returnDefault = false) => {
	if (returnDefault) {
		return createBlankRgbObject();
	}

	return rgbInputError(color);
};

export const ensureRgbObject = (color, options = {}) => {
	const { returnDefault = false } = options;

	if (!color) {
		return createDefaultBlankRgbObject(color, returnDefault);
	}

	switch (typeof color) {
		case 'string':
			return rgbFromHexadecimal(color);
		case 'object':
			if (isObjectRgb(color)) {
				return color;
			}

			return createDefaultBlankRgbObject(color, returnDefault);
		default:
			return createDefaultBlankRgbObject(color, returnDefault);
	}
};

export const createRgbString = color => {
	const rgbObject = ensureRgbObject(color);
	const { r, g, b, a = 1 } = rgbObject;

	return `rgba(${r}, ${g}, ${b}, ${a})`;
};
