/* eslint-disable import/no-cycle */
/* eslint-disable import/prefer-default-export */
import {
	setCompanies,
	setIsLoadingLoadedCompanies,
	setHasLoadingErrorCompanies
} from './actions';
import { getCompanies } from '../../../../api/companies';

const loadCompanies = () => dispatch => {
	const meta1 = {
		isLoadingCompanies: true,
		isLoadedCompanies: false
	};
	dispatch(setCompanies([]));
	dispatch(setIsLoadingLoadedCompanies(meta1));

	getCompanies(undefined, (err, companies) => {
		if (err) {
			dispatch(setHasLoadingErrorCompanies(true));
			dispatch(
				setIsLoadingLoadedCompanies({
					isLoadingCompanies: false,
					isLoadedCompanies: false
				})
			);
		} else {
			dispatch(setCompanies(companies));
			dispatch(
				setIsLoadingLoadedCompanies({
					isLoadingCompanies: false,
					isLoadedCompanies: true
				})
			);
		}
	});
};

export { loadCompanies };
