/* eslint-disable react/button-has-type */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/alt-text */
import React, { useMemo, useCallback } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { setSelectedApp as acSetSelectedApp } from '../../../../../../../scanline/redux/WebsiteExperimental/actions';
import { selectSelectedAppKey } from '../../../../../../../scanline/redux/WebsiteExperimental/selectors';
import {
	applications,
	DEFAULT_KEY as DEFAULT_APP_KEY
} from '../../../../content';

import './Original.flex.scss';
import './Original.scss';

const VARIANT = 'original';

const mapStateToProps = state => {
	const { websiteExperimental } = state;
	return {
		appKey: selectSelectedAppKey(websiteExperimental)
	};
};

const actionCreators = {
	setSelectedApp: acSetSelectedApp
};
const mapDispatchToProps = dispatch =>
	bindActionCreators(actionCreators, dispatch);

const Original = ({ appKey, setSelectedApp }) => {
	const onClicks = useMemo(
		() =>
			applications.map(app => e => {
				e.preventDefault();
				e.stopPropagation();
				setSelectedApp(app.key);
			}),
		[]
	);
	const resetSelectedApp = useCallback(
		() => setSelectedApp(DEFAULT_APP_KEY),
		[]
	);
	return (
		<div
			className={`applications-list-${VARIANT}-wrapper`}
			onClick={resetSelectedApp}
		>
			<div className={`applications-list-${VARIANT}-grid`}>
				{applications.map((obj, idx) => {
					const { tile } = obj;
					const isSelected = obj.key === appKey;
					const className = `applications-list-${VARIANT}-item ${
						isSelected ? 'selected' : ''
					}`;
					return (
						<div className={className} onClick={onClicks[idx]}>
							<div className="image-wrapper">
								<img src={`${process.env.PUBLIC_URL}/${tile.imageSrc}`} />
							</div>
							<div className="appName">{tile.name}</div>
							<div className="appHero">{tile.hero}</div>
							<hr />
							<div className="appCopy">{tile.copy}</div>
							<div className="actions">
								<button>Learn More</button>
							</div>
						</div>
					);
				})}
			</div>
		</div>
	);
};

export default connect(mapStateToProps, mapDispatchToProps)(Original);
