const MAX_NUMBER_OF_MESSAGE_BOXES = 2;

const defaultState = {
	messageBoxes: []
};

const addMessageBox = (state, message, successState) => {
	const { messageBoxes } = state;
	const newMessageBox = {
		message,
		successState,
		id: Date.now()
	};

	const messageBoxList = [newMessageBox] // Stack, so this is first
		.concat(messageBoxes) // add the other message boxes
		.slice(0, MAX_NUMBER_OF_MESSAGE_BOXES); // possibly remove excess message boxes

	return {
		messageBoxes: messageBoxList
	};
};

const closeMessageBox = (state, id) => {
	const { messageBoxes } = state;

	return {
		messageBoxes: messageBoxes.filter(val => val.id !== id)
	};
};

const reducer = (state = defaultState, action) => {
	const { type } = action;
	switch (type) {
		case 'CISV_MESSAGE_BOX_ADD':
			return addMessageBox(state, action.message, action.successState);
		case 'CISV_MESSAGE_BOX_CLOSE':
			return closeMessageBox(state, action.id);
		default:
			return state;
	}
};

export default reducer;
