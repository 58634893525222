"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;
var EditsType = {
  COMMENTS: 1,
  READINGS: 2
};
var _default = EditsType;
exports["default"] = _default;