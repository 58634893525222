/* eslint-disable camelcase */
const formatUserName = (name, userid) => {
	return name || userid;
};

const selectIsProject = r => {
	const types = ['PROJECT', 'LINE_BASED_PROJECT'];
	return types.indexOf(r.parent_type_key) > -1;
};

const selectProjectId = r => {
	return !Number.isNaN(parseInt(r.project_id, 10))
		? parseInt(r.project_id, 10)
		: undefined;
};

const nonEmptyValues = arr => arr.filter(v => !!v);

export const transformReports = reports =>
	reports.map(r => {
		const parent_type = (r.parent_type_key || '').replace(/_/gi, ' ');
		const result = {
			...r,
			parent_type,
			name: r.parent_name,
			isProject: selectIsProject(r),
			line: {
				id: r.line_guid,
				name: r.line_name
			},
			project: {
				id: selectProjectId(r),
				name: r.project_name
			},
			reportType: r.report_type_name,
			status: r.report_status_name,
			technicianDisplayText: formatUserName(r.owner_full_name, r.owner_userid),
			technician: {
				name: r.owner_full_name,
				userid: r.owner_userid
			},
			projectManagers: [
				formatUserName(r.project_manager_full_name, r.project_manager_userid)
			].filter(v => !!v),
			primarySurvey: {
				type: r.survey_type,
				jobId: r.survey_guid
			}
		};
		result.searchRef = nonEmptyValues([
			result.name,
			result.status,
			result.technicianDisplayText,
			nonEmptyValues(result.projectManagers),
			result.primarySurvey.type,
			result.reportType
		])
			.join(' ')
			.toUpperCase();

		return result;
	});

export default {
	transformReports
};
