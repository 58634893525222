"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

var _lodash = _interopRequireDefault(require("lodash.isequal"));

var _constants = require("../constants");

var _BASE = _interopRequireDefault(require("./BASE"));

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    "default": obj
  };
}

function ownKeys(object, enumerableOnly) {
  var keys = Object.keys(object);

  if (Object.getOwnPropertySymbols) {
    var symbols = Object.getOwnPropertySymbols(object);
    enumerableOnly && (symbols = symbols.filter(function (sym) {
      return Object.getOwnPropertyDescriptor(object, sym).enumerable;
    })), keys.push.apply(keys, symbols);
  }

  return keys;
}

function _objectSpread(target) {
  for (var i = 1; i < arguments.length; i++) {
    var source = null != arguments[i] ? arguments[i] : {};
    i % 2 ? ownKeys(Object(source), !0).forEach(function (key) {
      _defineProperty(target, key, source[key]);
    }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) {
      Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key));
    });
  }

  return target;
}

function _defineProperty(obj, key, value) {
  if (key in obj) {
    Object.defineProperty(obj, key, {
      value: value,
      enumerable: true,
      configurable: true,
      writable: true
    });
  } else {
    obj[key] = value;
  }

  return obj;
}

function _typeof(obj) {
  "@babel/helpers - typeof";

  return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (obj) {
    return typeof obj;
  } : function (obj) {
    return obj && "function" == typeof Symbol && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj;
  }, _typeof(obj);
}

var GEOJSON_DESCRIPTION = _constants.FILE_TYPE_DESCRIPTIONS[_constants.KEY_GEOJSON];

var isDefined = function isDefined(v) {
  return v !== undefined && v !== null;
};

var isDocumentPropertyValid = function isDocumentPropertyValid(doc) {
  // handle undefined
  if (doc === undefined) {
    return true;
  } // handle wrong data type


  if (_typeof(doc) !== 'object') {
    return false;
  } // check root props


  var hasRequiredRootProps = doc.type === 'FeatureCollection' && (doc.features === undefined || doc.features instanceof Array);

  if (!hasRequiredRootProps) {
    return false;
  } // no features are ok, just means empty doc


  var hasFeatures = doc.features instanceof Array && doc.features.length > 0;

  if (!hasFeatures) {
    return true;
  } // check first feature for format, if passes assume following features will pass


  var firstFeature = doc.features[0];
  return firstFeature.type === 'Feature' && (firstFeature.properties === undefined || _typeof(firstFeature.properties) === 'object') && (firstFeature.geometry === undefined || _typeof(firstFeature.geometry) === 'object');
};

var isValidFileName = function isValidFileName(fname) {
  var parts = (fname || '').trim().split('.');
  return parts.length > 1 && parts[parts.length - 1].toLowerCase() === 'geojson';
};

var GEOJSONDocType = _objectSpread(_objectSpread({}, _BASE["default"]), {}, {
  toFileText: function toFileText() {
    var _ref = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {},
        _ref$document = _ref.document,
        doc = _ref$document === void 0 ? {} : _ref$document;

    return JSON.stringify(doc);
  },
  // @required overwrite
  getFileParams: function getFileParams(docJson) {
    if (GEOJSONDocType.isValidDocJson(docJson)) {
      var text = GEOJSONDocType.toFileText(docJson);
      var name = GEOJSONDocType.getFileName(docJson);
      var ext = GEOJSONDocType.getFileExtension(docJson);
      var filename = [name, ext].filter(function (v) {
        return !!v;
      }).join('.');
      return {
        filename: filename,
        content: text,
        mimeType: 'application/geo+json'
      };
    }

    return undefined;
  },
  // @required overwrite
  toDocJson: function toDocJson(_ref2) {
    var content = _ref2.content,
        fileName = _ref2.fileName;
    var geoJson;

    if (isDefined(content)) {
      geoJson = typeof content === 'string' ? JSON.parse(content) : content;
    }

    var fname = fileName;

    if (!isDefined(fileName)) {
      fname = [geoJson.name, 'geojson'].filter(function (v) {
        return !!v;
      }).join('.');
    }

    if (isValidFileName(fname)) {
      var parts = (fname || '').trim().split('.');
      var ext = parts.splice(parts.length - 1, 1)[0].toLowerCase();
      var name = parts.join('.').trim();
      return {
        type: _constants.KEY_GEOJSON,
        fileName: name,
        fileExtension: ext,
        document: typeof geoJson === 'string' ? JSON.parse(geoJson) : geoJson
      };
    }

    return undefined;
  },
  isValidDocJson: function isValidDocJson() {
    var docJson = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    var docType = (GEOJSONDocType.getDocType(docJson) || '').toUpperCase();
    var fileExtension = (GEOJSONDocType.getFileExtension(docJson) || '').toLowerCase();
    var doc = GEOJSONDocType.getDocument(docJson);
    return _BASE["default"].isValidDocJson(docJson) && docType === GEOJSON_DESCRIPTION.fileType && fileExtension === GEOJSON_DESCRIPTION.fileExtension && isDocumentPropertyValid(doc);
  },
  createFeatureDocId: function createFeatureDocId(feature, _ref3) {
    var prop = _ref3.prop,
        props = _ref3.props;
    var idProps;

    if (prop !== undefined) {
      idProps = [prop];
    } else if (props !== undefined) {
      idProps = props; // assume an array of props
    }

    var properties = feature.properties;
    return idProps.map(function (p) {
      return properties[p];
    }).join('~');
  },
  createFeatureMap: function createFeatureMap() {
    var _ref4 = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {},
        _ref4$document = _ref4.document,
        doc = _ref4$document === void 0 ? {} : _ref4$document;

    var _ref5 = arguments.length > 1 ? arguments[1] : undefined,
        idProp = _ref5.idProp,
        idProps = _ref5.idProps;

    var _doc$features = doc.features,
        features = _doc$features === void 0 ? [] : _doc$features;
    return features.reduce(function (acc, feature) {
      var docId = GEOJSONDocType.createFeatureDocId(feature, {
        prop: idProp,
        props: idProps
      });
      acc[docId] = feature;
      return acc;
    }, {});
  },
  computeDiffFeatureSets: function computeDiffFeatureSets(docJsonA, docJsonB, _ref6) {
    var idProp = _ref6.idProp,
        idProps = _ref6.idProps;
    var featureMapA = GEOJSONDocType.createFeatureMap(docJsonA, {
      idProp: idProp,
      idProps: idProps
    });
    var docIdsA = Object.keys(featureMapA);
    var featureMapB = GEOJSONDocType.createFeatureMap(docJsonB, {
      idProp: idProp,
      idProps: idProps
    });
    var docIdsB = Object.keys(featureMapB);

    var _docIdsA$reduce = docIdsA.reduce(function (acc, idA) {
      if (featureMapB[idA] === undefined) {
        acc.deletedFeatures.push(featureMapA[idA]);
      } else {
        acc.sharedIds.push(idA);
      }

      return acc;
    }, {
      deletedFeatures: [],
      sharedIds: []
    }),
        deletedFeatures = _docIdsA$reduce.deletedFeatures,
        sharedIds = _docIdsA$reduce.sharedIds;

    var addedFeatures = docIdsB.reduce(function (acc, idB) {
      if (featureMapA[idB] === undefined) {
        acc.push(featureMapB[idB]);
      }

      return acc;
    }, []);
    var modifiedFeatures = sharedIds.reduce(function (acc, id) {
      if (!(0, _lodash["default"])(featureMapA[id].properties, featureMapB[id].properties)) {
        acc.push(featureMapB[id]);
      }

      return acc;
    }, []);
    return {
      deleted: deletedFeatures,
      modified: modifiedFeatures,
      added: addedFeatures
    };
  },
  computeDiffDocsByFeatureSets: function computeDiffDocsByFeatureSets(docJsonA, docJsonB, _ref7) {
    var idProp = _ref7.idProp,
        idProps = _ref7.idProps;
    var fileNameA = docJsonA.fileName;
    var docNameA = (docJsonA.document || {}).name || fileNameA;
    var fileNameB = docJsonB.fileName;
    var docNameB = (docJsonB.document || {}).name || fileNameB;
    var fileNameDeleted = "DELETED-FROM-".concat(fileNameA, "-TO-").concat(fileNameB, ".geojson");
    var docNameDeleted = "DELETED-FROM-".concat(docNameA, "-TO-").concat(docNameB);
    var fileNameModified = "MODIFIED-FROM-".concat(fileNameA, "-TO-").concat(fileNameB, ".geojson");
    var docNameModified = "MODIFIED-FROM-".concat(docNameA, "-TO-").concat(docNameB);
    var fileNameAdded = "ADDED-FROM-".concat(fileNameA, "-TO-").concat(fileNameB, ".geojson");
    var docNameAdded = "ADDED-FROM-".concat(docNameA, "-TO-").concat(docNameB);
    var featureDiffs = GEOJSONDocType.computeDiffFeatureSets(docJsonA, docJsonB, {
      idProp: idProp,
      idProps: idProps
    });
    var docJsonDeleted = GEOJSONDocType.toDocJson({
      fileName: fileNameDeleted,
      content: {
        type: docJsonB.document.type,
        name: docNameDeleted,
        features: featureDiffs.deleted
      }
    });
    var docJsonModified = GEOJSONDocType.toDocJson({
      fileName: fileNameModified,
      content: {
        type: docJsonB.document.type,
        name: docNameModified,
        features: featureDiffs.modified
      }
    });
    var docJsonAdded = GEOJSONDocType.toDocJson({
      fileName: fileNameAdded,
      content: {
        type: docJsonB.document.type,
        name: docNameAdded,
        features: featureDiffs.added
      }
    });
    return {
      deleted: docJsonDeleted,
      modified: docJsonModified,
      added: docJsonAdded
    };
  }
});

var _default = GEOJSONDocType;
exports["default"] = _default;