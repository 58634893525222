import React, { useMemo } from 'react';
import { connect } from 'react-redux';
import { selectDetailsKey } from '../../../../../scanline/redux/WebsiteExperimental/selectors';
import { getDetailsComponent } from '../../variants.Details';

// import { variantsApplicationsListMap } from '../../variants.ApplicationsList';

const mapStateToProps = state => {
	const { websiteExperimental } = state;
	return {
		compKey: selectDetailsKey(websiteExperimental)
	};
};

const Details = ({ compKey }) => {
	const Component = useMemo(() => getDetailsComponent(compKey), [compKey]);
	return <Component />;
};

export default connect(mapStateToProps)(Details);
