function queryStringParamsToObject(queryStringParameters) {
	if (!queryStringParameters) {
		return {};
	}

	const urlParams = new URLSearchParams(queryStringParameters);

	const urlParamsJsonObject = Object.fromEntries(urlParams);

	return urlParamsJsonObject;
}

function getQueryStringObject() {
	try {
		return queryStringParamsToObject(window.location.search);
	} catch {
		return {};
	}
}

function jsonToQueryString(json) {
	const joinedString = Object.keys(json)
		.map(key => {
			const keyString = encodeURIComponent(key);
			const valueString = encodeURIComponent(json[key]);

			return `${keyString}=${valueString}`;
		})
		.join('&');

	return `?${joinedString}`;
}

function getPropertiesFromRoute(routeProps) {
	const { search, pathname } = routeProps.location;
	const { jobguid, tabId } = routeProps.match.params;
	const rootPath = pathname.split(jobguid)[0];

	return { search, jobguid, rootPath, tabId };
}

function updateSearchWithKeyValuePair(searchString, key, value) {
	if (!searchString) {
		const jsonToSerialize = { [key]: value };
		return jsonToQueryString(jsonToSerialize);
	}

	const queryParametersObject = queryStringParamsToObject(searchString);
	const jsonToSerialize = { ...queryParametersObject, [key]: value };

	return jsonToQueryString(jsonToSerialize);
}

// Note that this path is the default path and is defined in the Job.js component
function createPathWithoutTabId(rootPath, jobguid, search) {
	return `${rootPath}${jobguid}${search}`;
}

// Note that this path is defined in the Job.js component
function createPath(rootPath, jobguid, tabId, search) {
	if (!tabId) {
		return createPathWithoutTabId(rootPath, jobguid, search);
	}
	return `${rootPath}${jobguid}/${tabId}${search}`;
}

const RoutingUtils = {
	getQueryStringObject,
	addTabSizeToUrl(tabSize, routeProps, routeHistory) {
		const { search, jobguid, rootPath, tabId } = getPropertiesFromRoute(
			routeProps
		);

		const newQueryString = updateSearchWithKeyValuePair(
			search,
			'tabSize',
			tabSize
		);

		const newPath = createPath(rootPath, jobguid, tabId, newQueryString);

		routeHistory.replace(newPath);
	},
	goToTabById(tabId, routeProps, routeHistory) {
		const { search, jobguid, rootPath } = getPropertiesFromRoute(routeProps);

		const newPath = createPath(rootPath, jobguid, tabId, search);

		routeHistory.replace(newPath);
	},
	getTabSizeFromQueryParams(queryParamString) {
		if (!queryParamString) {
			return null;
		}
		const queryParamsObject = queryStringParamsToObject(queryParamString);
		if (!queryParamsObject) {
			return queryParamsObject;
		}

		return parseInt(queryParamsObject.tabSize, 10);
	}
};
export default RoutingUtils;
